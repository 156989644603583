import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import T from "prop-types";
import FormValidationErrorMessage from '../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import { isLengthLessthan, isMaxLengthLessthan, hasNumericDecimal } from '../../helpers/utils';
import { lostSalesQuestions } from '../../helpers/constants';

function UserFeedback(props) {

	let { itemPart, partNum, desc, vehicleInfo, setValidityBtn, chemicalDesc, chemicalPartNum } = props;

	const initializePriceValidity = () => {
		const priceValidity = {
			isPriceLimitLessThan: true,
			isPriceNumeric: true,
		};
		return priceValidity;
	};

	const initializeVendorValidity = () => {
		const vendorValidity = {
			isVendorMaxLimit: true,
		};
		return vendorValidity;
	}

	const initializeAvailableValidity = () => {
		const availableValidity = {
			isAvailableMaxLimit: true,
		};
		return availableValidity;
	}

	const initializeBrandValidity = () => {
		const brandValidity = {
			isBrandMaxLimit: true,
		};
		return brandValidity;
	}

	const initializeOtherValidity = () => {
		const otherValidity = {
			isOtherMaxLimit: true,
		};
		return otherValidity;
	}

	const [item, setItem] = useState(itemPart);
	const [priceValidity, setPriceValidity] = useState(initializePriceValidity);
	const [vendorValidity, setVendorValidity] = useState(initializeVendorValidity);
	const [availableValidity, setAvailableValidity] = useState(initializeAvailableValidity);
	const [brandValidity, setBrandValidity] = useState(initializeBrandValidity);
	const [otherValidity, setOtherValidity] = useState(initializeOtherValidity);

	const handleCheckboxChange = (item, checked, prop) => {
		let priceValidity = initializePriceValidity();
		let vendorValidity = initializeVendorValidity();
		let availableValidity = initializeAvailableValidity();
		let brandValidity = initializeBrandValidity();
		let otherValidity = initializeOtherValidity();
		setPriceValidity(priceValidity);
		setVendorValidity(vendorValidity);
		setAvailableValidity(availableValidity);
		setBrandValidity(brandValidity);
		setOtherValidity(otherValidity);
		switch(prop) {
			case 'availabilityChecked':
				item['propAvailability'] = [];
				break;
			case 'brandChecked':
				item['propBrand'] = [];
				break;
			case 'priceChecked':
				item['betterPrice'] = [];
				item['fromWD'] = [];
				break;
			case 'otherChecked':
				item['propOther'] = [];
				break;
			default:
				break;
		}
		setItem({ ...item, [prop]: checked })
	}

	const handleItemProps = (item, prop, value) => {
		item[prop] = [value];
		let priceValidity = initializePriceValidity();
		let vendorValidity = initializeVendorValidity();
		let availableValidity = initializeAvailableValidity();
		let brandValidity = initializeBrandValidity();
		let otherValidity = initializeOtherValidity();
		switch (prop) {
			case 'betterPrice':
				priceValidity.isPriceLimitLessThan = isLengthLessthan(value, 10);
				priceValidity.isPriceNumeric = hasNumericDecimal(value);
				setPriceValidity(priceValidity);
				setVendorValidity(vendorValidity);
				setValidityBtn(priceValidity);
				break;
			case 'fromWD':
				vendorValidity.isVendorMaxLimit = isMaxLengthLessthan(value, 50);
				setVendorValidity(vendorValidity);
				setPriceValidity(priceValidity);
				setPriceValidity(priceValidity);
				setValidityBtn(vendorValidity);
				break;
			case 'propAvailability':
				availableValidity.isAvailableMaxLimit = isMaxLengthLessthan(value, 50);
				setAvailableValidity(availableValidity);
				setValidityBtn(availableValidity);
				break;
			case 'propBrand':
				brandValidity.isBrandMaxLimit = isMaxLengthLessthan(value, 50);
				setBrandValidity(brandValidity);
				setValidityBtn(brandValidity);
				break;
			case 'propOther':
				otherValidity.isOtherMaxLimit = isMaxLengthLessthan(value, 100);
				setOtherValidity(otherValidity);
				setValidityBtn(otherValidity);
				break;
			default:
				break;
		}
		setItem({ ...item, [prop]: [value] })
	}

	const getPriceSectionHeight = () => {
		let priceSection = null;
		setTimeout(() => {
			priceSection = document.querySelector('.price_section');
			if (document.querySelector('.other_section')) { document.querySelector('.other_section').style.height = `${priceSection.offsetHeight}px` }
		}, 100);
		return priceSection ? `${priceSection.offsetHeight}px` : '120px';
	}

	useEffect(() => {
		window.onresize = getPriceSectionHeight;
	}, [])

	useEffect(() => {
		Object.assign(itemPart, item)
	}, [item])

	return (
		<div className="lost_sales_container">
			<div className="feedback_container">
				<div>
					<div className="row_line">
						<Col lg="2" md="2" sm="2" xs="12">
							<div className="vehicle_details">
								<span>{vehicleInfo}</span>
								{chemicalDesc ? <p>Part # {chemicalPartNum}, {chemicalDesc}</p> : <p>Part # {partNum}, {desc}</p>}
							</div>
						</Col>
						<Col lg="2" md="2" sm="2" xs="12" className="align-center">
							<span> <Form.Check label="Customer" id={`customer_not_interested`} name="notInterested" onClick={e => handleCheckboxChange(item, e.target.checked, 'notInterested')} /></span>

							<div style={{ marginTop: '10px' }}>
								<textarea id={`customer_not_interested`} name="notInterested" disabled={true} >
									My customer is no longer interested.
								</textarea>
							</div>
						</Col>
						<Col lg="2" md="2" sm="2" xs="12" className="align-center">
							<span><Form.Check label="Availability" id={`availability_${item.itemId}`} name="noAvailability" onClick={e => handleCheckboxChange(item, e.target.checked, 'availabilityChecked')} /> </span>
							<div>
								<label for={`availability_${item.itemId}`}>
									{lostSalesQuestions.NotAvailable} <input type="text" name="unAvailability" value={item.propAvailability} maxLength="50" onChange={(e) => { handleItemProps(item, 'propAvailability', e.target.value) }} disabled={item && item['availabilityChecked'] ? false : true} />
									<FormValidationErrorMessage
										condition={!availableValidity.isAvailableMaxLimit}
										errorMessage="Availability should not exceed more than 20 characters"
									/>
								</label>
							</div>
						</Col>
						<Col lg="2" md="2" sm="2" xs="12" className="align-center">
							<span><Form.Check label="Brand" id={`brand_${item.itemId}`} name="wrongBrand" onClick={e => handleCheckboxChange(item, e.target.checked, 'brandChecked')} /></span>
							<div>
								<label for={`brand_${item.itemId}`}>
									{lostSalesQuestions.WrongBrand} <input type="text" name="wrongBrand" value={item.propBrand} maxLength="50" onChange={(e) => { handleItemProps(item, 'propBrand', e.target.value) }} disabled={item && item['brandChecked'] ? false : true} />
									<FormValidationErrorMessage
										condition={!brandValidity.isBrandMaxLimit}
										errorMessage="Brand should not exceed more than 20 characters"
									/>
								</label>
							</div>
						</Col>
						<Col lg="2" md="2" sm="2" xs="12" className="align-center">
							<span><Form.Check label="Price" id={`price_${item.itemId}`} name="highPrice" onClick={e => handleCheckboxChange(item, e.target.checked, 'priceChecked')} /></span>
							<div className="price_section">
								<label>{lostSalesQuestions.HighPrice}</label>
								<label for={`price_${item.itemId}`}>
									<input type="text" name="betterPrice" value={item.betterPrice} maxLength="10" className="price_hight_input" onChange={(e) => { handleItemProps(item, 'betterPrice', e.target.value) }} disabled={item && item['priceChecked'] ? false : true} />
									<FormValidationErrorMessage
										condition={!priceValidity.isPriceNumeric && priceValidity.isPriceLimitLessThan}
										errorMessage="Price must be numeric or decimal only"
									/>
									<FormValidationErrorMessage
										condition={!priceValidity.isPriceLimitLessThan && priceValidity.isPriceNumeric}
										errorMessage="Price should not exceed more than 10 numbers"
									/>
									<p className="mb-0">{lostSalesQuestions.BetterPrice}</p> <input type="text" name="whoseBetter" value={item.fromWD} maxLength="50" onChange={(e) => { handleItemProps(item, 'fromWD', e.target.value) }} disabled={item && item['priceChecked'] ? false : true} />
									<FormValidationErrorMessage
										condition={!vendorValidity.isVendorMaxLimit}
										errorMessage="Vendor should not exceed more than 20 characters"
									/>
								</label>
							</div>
						</Col>
						<Col lg="2" md="2" sm="2" xs="12" className="align-center">
							<span><Form.Check label="Other" id={`other_${item.itemId}`} name="otherReason" onClick={e => handleCheckboxChange(item, e.target.checked, 'otherChecked')} /></span>
							<div className="other_section" style={{ height: getPriceSectionHeight() }}>
								<label for={`other_${item.itemId}`}>
									{lostSalesQuestions.OtherReason} <textarea className="other_input" type="text" value={item.propOther} name="otherReason" maxLength="100" onChange={(e) => { handleItemProps(item, 'propOther', e.target.value) }} disabled={item && item['otherChecked'] ? false : true} ></textarea>
									<FormValidationErrorMessage
										condition={!otherValidity.isOtherMaxLimit}
										errorMessage="Other reason should not exceed more than 100 characters"
									/>
								</label>
							</div>
						</Col>
					</div>
				</div>
			</div>
		</div>
	)
}

UserFeedback.propTypes = {
	item: T.object
}

export default UserFeedback;