import React, { useRef, useEffect, useState, Fragment } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { logUserStockActivity } from 'actions/user';

import XModal from '../XModal';
import {
	getPreferenceDataFromStorage,
	getDefaultDataBasedOnChemicalUSerPref,
	getImageURLCookieDetails,
	timeInUTC
} from '../../../helpers/utils';
import { getChemPartByGrp } from '../../../actions/parts';

import './styles.scss';
import { isEmpty } from 'lodash';
import moment from 'moment';

const headerStyle = {
	backgroundColor: '#0F5283',
	color: '#FFFFFF',
	'z-index': '999'
};

const OrderInvoicesPopUp = (props) => {
	let { openPopup, closePopup, selectedInvoice, data, activeInvoice } = props;
	useEffect(
		() => {
			constructGridData(data ? data : []);
		},
		[ data ]
	);
	const [ partInvoiceTableColumns, setPartInvoiceTableColumns ] = useState([]);
	const [ partInvoiceTableData, setPartInvoiceTableData ] = useState([]);
	const user = useSelector((state) => state.user);
	const userDetails = user.userDetails;
	const constructGridData = (invoiceData) => {
		setPartInvoiceTableColumns([]);
		setPartInvoiceTableData([]);

		const rowData = [];
		invoiceData.map((data, index) => {
			rowData.push({
				corePrice: data.corePrice,
				coreQtyAvailToCredit: data.coreQtyAvailToCredit,
				costPrice: data.costPrice,
				invoiceDate: data.invoiceDate,
				invoiceNumber: data.invoiceNumber,
				listPrice: data.listPrice,
				mfgCode: data.mfgCode,
				partNumber: data.partNumber,
				price: data.price,
				purchaseQty: data.purchaseQty,
				qtyAvailToCredit: data.qtyAvailToCredit,
				custPo: data.custPo,
				shipTo: data.shipTo,
				writer: data.writer,
				invoiceUrl: data.invoiceUrl,
				extSales: data.extSales,
				type: data.type
			});
			// }
		});
		const dateFormatter = (cell, row) => {
			if (!cell) {
				return '';
			}
			return `${moment(cell, 'YYYY-MM-DD').format('MM-DD-YYYY')
				? moment(cell, 'YYYY-MM-DD').format('MM-DD-YYYY')
				: moment(cell, 'YYYY-MM-DD').format('MM-DD-YYYY')}`;
		};
		const columns = [
			{
				dataField: 'invoiceDate',
				text: 'InvDate',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				width: '80',
				'z-index': '999',
				formatter: dateFormatter
			},
			{
				dataField: 'invoiceNumber',
				text: 'Inv#',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				width: '80',
				'z-index': '999',
				formatter: function(cell, row, index) {
					return (
						<Link
							onClick={() => {
								window.open(row.invoiceUrl, '_blank');
							}}
						>
							<span className="invoice_order_link">{row.invoiceNumber}</span>
						</Link>
					);
				}
			},

			{
				dataField: 'custPo',
				text: 'CustPO',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				'z-index': '999'
			},
			{
				dataField: 'type',
				text: 'Type',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				'z-index': '999'
			},
			{
				dataField: 'shipTo',
				text: 'ShipTo',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				width: '80',
				'z-index': '999'
			},
			{
				dataField: 'writer',
				text: 'Writer',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				width: '80',
				'z-index': '999'
			},
			// {
			//   dataField: "price",
			//   text: "price",
			//   headerStyle: headerStyle,
			//   align: "top",
			//   headerAlign: "center",
			//   width: "80",
			//   "z-index":"999",
			// },
			{
				dataField: 'purchaseQty',
				text: 'Qty',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				width: '80',
				'z-index': '999'
			},
			{
				dataField: 'listPrice',
				text: 'Unit Amount',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				width: '80',
				'z-index': '999',
				formatter: function(cell, row, index) {
					return <span>{`$${row.listPrice}`}</span>;
				}
			},
			{
				dataField: 'corePrice',
				text: 'Core Amount',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				'z-index': '999',
				formatter: function(cell, row, index) {
					return (
						<span>{`$${cell.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 })}`}</span>
					);
				}
			},
			{
				dataField: 'extSales',
				text: 'ExtSales Amount',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				width: '80',
				'z-index': '999',
				formatter: function(cell, row, index) {
					return <span>{`$${row.extSales}`}</span>;
				}
			}
		];
		setPartInvoiceTableColumns(columns);
		setPartInvoiceTableData(rowData);
	};

	return (
		<React.Fragment>
			<XModal
				title={
					<Row>
						<Col md="4">{`PART: ${activeInvoice.lineCode} ${activeInvoice.partSKU}`}</Col>
						<Col md="8">{`Invoice History (${userDetails.billTo.billToId})`}</Col>
					</Row>
				}
				show={openPopup}
				handleClose={closePopup}
				showButtons={false}
				dialogClassName="order-invoice-modal"
			>
				<Row className="view-container">
					<React.Fragment>
						{partInvoiceTableData.length > 0 && partInvoiceTableColumns.length > 0 ? (
							<Col sm={12}>
								<Row className="table-row">
									<Col>
										<BootstrapTable
											keyField="specDesc"
											data={partInvoiceTableData}
											columns={partInvoiceTableColumns}
											wrapperClasses="order-invoice-responsive"
											// rowClasses="custom-row-class"
										/>
									</Col>
								</Row>
							</Col>
						) : (
							<div className="no-data">
								<div>No Data</div>
							</div>
						)}
					</React.Fragment>
				</Row>
			</XModal>
		</React.Fragment>
	);
};

export default OrderInvoicesPopUp;
