import axios from 'axios';
import httpClient from "../../services/http";

const buildQueryUrl = (payload) => {
  const params = [];

  for (const k in payload) {
    if (payload.hasOwnProperty(k)) {
      const element = payload[k];
      params.push(encodeURIComponent(k) + "=" + encodeURIComponent(element));
    }
  }
  return params.join("&");
};

let cancelToken;
export const fetchSearch = (data) => {
  if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel('Operation is canceled due to new search request');
	}

	cancelToken = axios.CancelToken.source();
  return httpClient.get(`/catalog/chemical/search?${buildQueryUrl(data)}`, {cancelToken: cancelToken.token});
}

