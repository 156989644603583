import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { fetchUserDetails } from "../../../actions/auth";
import { Row, Col } from "react-bootstrap";

const UserAccountInformation = (props) => {
  const userDetailsArr = useSelector((state) => state.user);
  const smsUser = useSelector((state) => state.app.smsUser);
  const OSRDefaultBillTO = process.env.REACT_APP_OSR_DEFAULT_BILLTO;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!smsUser) {
      fetchUserDetails(dispatch);
    }
  }, []);

  return (
    !_isEmpty(userDetailsArr) &&
    !_isEmpty(userDetailsArr.userDetails) && (
      <div className="usermanagement_accountDetails">
        <Row>
          {/* {!smsUser && 
								<Col md="5">
									<div>
										<span className="user_management_label_color">First Name:</span><span className=""> {userDetailsArr.userDetails.firstName}</span>
									</div>
									<div>
										<span className="user_management_label_color">Last Name:</span> <span>{userDetailsArr.userDetails.lastName}</span>
									</div>
									<div>
										<span className="user_management_label_color">Mobile Number:</span> <span>{userDetailsArr.userDetails.mobileNumber}</span>
									</div>
									<div>
										<span className="user_management_label_color">User Type:</span> <span> {userDetailsArr.userDetails.userType}</span>
									</div>
									<div>
										<span className="user_management_label_color">Email:</span> <span>{userDetailsArr.userDetails.x2userName}</span>
									</div>
								</Col>} */}
          <div>
            <span className="user_management_label_color">TPH Account:</span>{" "}
            <span className="">
              {" "}
              {userDetailsArr.userDetails && userDetailsArr.userDetails.billTo
                ? `${userDetailsArr.userDetails.billTo.billToId} - ${userDetailsArr.userDetails.billTo.name}`
                : ""}
            </span>
          </div>
        </Row>

        {!_isEmpty(userDetailsArr.userDetails.shipTos) && (
          <Fragment>
            <Row>
              <div>
                <span className="user_management_label_color">ShipTo:</span>{" "}
                <span>{`${userDetailsArr.userDetails.shipTos[0].shipToId} - ${userDetailsArr.userDetails.shipTos[0].name}`}</span>
              </div>
            </Row>
            <Row>
              <div>
                <span className="user_management_label_color">
                  ShipTo Address:{" "}
                </span>
                <span>
                  {`${userDetailsArr.userDetails.shipTos[0].address}, ${userDetailsArr.userDetails.shipTos[0].city} ,`}
                </span>
                <span>
                  {`${userDetailsArr.userDetails.shipTos[0].state} , ${userDetailsArr.userDetails.shipTos[0].zip}`}
                </span>
              </div>
            </Row>
            <Row>
              <Col lg="4" md="4" sm="4" xs="12">
                <span className="shipTo_address1 user_management_label_color">TPH Store:</span>{" "}
                <span>
                  {" "}
                  {userDetailsArr.userDetails.shipTos[0].location &&
                    userDetailsArr.userDetails.shipTos[0].location.storeNumber}
                </span>
              </Col>
              <Col lg="8" md="8" sm="8" xs="12">
                <span className="user_management_label_color">
                  Store Phone#:
                </span>{" "}
                <span>
                  {" "}
                  {userDetailsArr.userDetails.shipTos[0].location &&
                    userDetailsArr.userDetails.shipTos[0].location.phoneNumber}
                </span>
              </Col>
            </Row>
            <Row>
              <span className="user_management_label_color">
                Store Address:
              </span>{" "}
              <span>
                {" "}
                {userDetailsArr.userDetails.shipTos[0].location &&
                  userDetailsArr.userDetails.shipTos[0].location.address}
                ,{" "}
                {userDetailsArr.userDetails.shipTos[0].location &&
                  userDetailsArr.userDetails.shipTos[0].location.city}
                ,{" "}
                {userDetailsArr.userDetails.shipTos[0].location &&
                  userDetailsArr.userDetails.shipTos[0].location.state}
                ,{" "}
                {userDetailsArr.userDetails.shipTos[0].location &&
                  userDetailsArr.userDetails.shipTos[0].location.zip}
              </span>
            </Row>
            <Row>
              <Col>
                <span className="shipTo_address1 user_management_label_color">
                  Ship Method:
                </span>{" "}
                <span> {userDetailsArr.userDetails.shipTos[0].shipMethod}</span>
              </Col>
              <Col>
                <span className="user_management_label_color">SLA%:</span>
                <span className="">
                  {" "}
                  {userDetailsArr.userDetails.shipTos[0].ddsInStockSLA === ""}
                  {userDetailsArr.userDetails.shipTos[0].ddsInStockSLA
                    ? userDetailsArr.userDetails.shipTos[0].ddsInStockSLA
                    : "N/A"}
                </span>
              </Col>
            </Row>
          </Fragment>
        )}
        <br></br>
        <Row>
          {!_isEmpty(userDetailsArr.userDetails.userType) &&
            (userDetailsArr.userDetails.userType === "IT_ADMIN" ||
              userDetailsArr.userDetails.userType === "IT_INTERNAL" ||
              userDetailsArr.userDetails.userType === "OSR" ||
              userDetailsArr.userDetails.userType === "DSM") &&
            userDetailsArr.userDetails.billTo.billToId !== OSRDefaultBillTO && (
              <div>
                <span className="Osr_label">
                  For Customer Employee view, Please emulate default account {" "}
                  <span className="user_management_label_color">{OSRDefaultBillTO}{" "}</span>
                </span>
              </div>
            )}
        </Row>
      </div>
    )
  );
};

export default UserAccountInformation;
