
import storage from '../helpers/storage';

const auth = {

    /**
     * @property {string} accessToken - Auth0 access token
     */
    get accessToken() {
        return storage.get('auth0UserId', 'local');
    },

    /**
     * @property {boolean} authFailed - Auth Failed flag
     */
    get isAuthFailed() {
        return (storage.get("authFailed", "local") === "true")?true:false;
    },

    /**
     * @property {string} smsUserSessionData - SMS User Session data
     */
     get smsUserSessionData() {
        const smsSession = storage.get("smsUserSessionData", "local") || "{}";
        const sessionData = JSON.parse(smsSession);
        return sessionData;
    }
}

export default auth;
