
import React, { useEffect, useState } from "react";
import { ChevronDown } from "../../StockOrder/dropdown.component";
import {
    FormControlLabel,
    FormGroup,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Switch,
    Button
} from "@mui/material";
import { formatAmount, formatAmountPrograms, getImageURLCookieDetails } from "../../../helpers/utils";
import { Row, Col, Card, OverlayTrigger, Tooltip, } from "react-bootstrap";
import { programDocumentDetails } from "../programbenefits.thunk";
import { useDispatch } from "react-redux";
const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };


const DocumentUpload = () => {

    const [documentsAnchorEl, setDocumentsAnchorEl] = useState(null);
    const openDocumentsMenu = Boolean(documentsAnchorEl);
    const [showImages, setShowImages] = useState(false);

    const [data,setData] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        //program document api call
        dispatch(programDocumentDetails()).then((action) => {
            setData(action?.payload);    
        }) 
    },[]);
    const handleDocuments = (event) => {
        setDocumentsAnchorEl(event.currentTarget);
    };
    const handleDocumentsClose = () => {
        setDocumentsAnchorEl(null);
    };

    return (
        <div className="upload-wrapper">
            <Button
                id="basic-button"
                aria-controls={openDocumentsMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDocumentsMenu ? "true" : undefined}
                onClick={handleDocuments}
                endIcon={<ChevronDown />}
            >
                Documents
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={documentsAnchorEl}
                open={openDocumentsMenu}
                onClose={handleDocumentsClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                style={{ minWidth: "36.5px" }}
            >
                <MenuItem onClick={handleDocumentsClose}>
                    {data&& data.map((item)=>{
                        return(
                            <a
                                href={
                                    item.linkURL && 
                                    `${
                                        item.linkURL.url
                                    }?${getImageURLCookieDetails()}`
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <ListItemText>{item && item.heading}</ListItemText>
                            </a>
                        )
                    })}
                </MenuItem>

            </Menu>
        </div>
    );
};

export default DocumentUpload;