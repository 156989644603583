import React,{useState,useEffect} from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import "./styles.scss"
import { currencyFormatter } from 'helpers/utils';
import { useSelector } from 'react-redux';
import _isEmpty from "lodash/isEmpty"
import moment from 'moment';

const FourthTable = () => {
  const [tableData,setTableData]=useState([])
  const [earnedYTD,setEarnedYTD]=useState(null)
  const [earnedLY,setEarnedLY]=useState(null)
  const { benefitData } = useSelector(
    (state) => state.ProgramAndBenefits
  );
  const yearlyEarnedRebate=benefitData && benefitData?.pvpRebateEarnedList

  useEffect(() => {
    if (!_isEmpty(benefitData && benefitData?.pvpRebateEarnedList)) {
      prepareyearlyEarnedRebate(yearlyEarnedRebate)
    }
  }, [yearlyEarnedRebate])

  const prepareyearlyEarnedRebate = (data) => {

    const currentYear = (moment().year()).toString()
    const previousYear = (moment().year() - 1).toString()

    const currentInfo = data.filter((obj) => obj.year === currentYear).reduce((acc, e) => acc.set(e.rebatesPer, (acc.get(e.rebatesPer) || 0) + 1), new Map());
    const previousInfo = data.filter((obj) => obj.year === previousYear).reduce((acc, e) => acc.set(e.rebatesPer, (acc.get(e.rebatesPer) || 0) + 1), new Map());

    setEarnedYTD([...currentInfo.entries()])

    setEarnedLY([...previousInfo.entries()])

  }


  useEffect(() => {
    if (!_isEmpty(benefitData && benefitData?.volumeTierItems)) {
      constrctTableData(benefitData?.volumeTierItems);
    }
  }, [benefitData]);

  const extractVolMinData = (obj) => {
    return Object.keys(obj).filter(key => /^vol\d+Min$/.test(key)).map(key => ({ key, value: obj[key] })).sort((a, b) => {
      const aNumber = parseInt(a.key.match(/\d+/)[0], 10);
      const bNumber = parseInt(b.key.match(/\d+/)[0], 10);
      return aNumber - bNumber;
    }).map(item => ({ ['vol']: item.value }));
  }
  const extractTierPerData = (obj) => {
    return Object.keys(obj).filter(key => /^tier\d+Per$/.test(key)).map(key => ({ key, value: obj[key] })).sort((a, b) => {
      const aNumber = parseInt(a.key.match(/\d+/)[0], 10);
      const bNumber = parseInt(b.key.match(/\d+/)[0], 10);
      return aNumber - bNumber;
    }).map(item => ({ ['tier']: item.value }));
  }

  const mergeVolTierData = (volMinArray, tierPerArray) => {
    return volMinArray.map((volObj, index) => ({ vol: volObj.vol, tier: tierPerArray[index].tier, })).filter(obj => obj.vol !== '' || obj.tier !== '');
  }

  const constrctTableData = (data = []) => {
    if (!_isEmpty(data)) {
      let pvpRowData = []
      let volMinArray = []
      let tierPerArray = []
      let mergedArray = []

      // data && data.map((item, i) => {
        volMinArray = extractVolMinData(data)
        tierPerArray = extractTierPerData(data)
      // })
      mergedArray = mergeVolTierData(volMinArray, tierPerArray)

      !_isEmpty(mergedArray) && mergedArray.map((item, i) => {
        pvpRowData.push({
          volume_tier: item.vol,
          rebate: item.tier,
          index: i,
        })
        setTableData(pvpRowData)
      })
    }
  }


  const noDataState=()=>{
    return <p align="center" style={{padding:"0",margin:"0"}}>No Data Found</p>
  }
  const headerStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
  };
  const columns=[
    {
        dataField: 'volume_tier',
        text: 'Volume Tier',
        headerStyle: headerStyle,
        classes:"pvp-rightalign",
        align: 'center',
        headerAlign: 'center',
        formatter:(cell,row,index)=>{
          if(row.volume_tier){
            console.log("row.volume_tier",row.volume_tier)
            return <span>{`${currencyFormatter(cell)}`}</span>
          }else{
            return <span style={{ color: "#0f5283" }}>-</span>
          }
       }
    },
    {
        dataField: 'rebate',
        text: 'Rebate',
        headerStyle: headerStyle,
        align: 'center',
        headerAlign: 'center',
        formatter:(cell,row,index)=>{
          if(row.rebate){
            return row.rebate
          }else{
            return <span style={{ color: "#0f5283" }}>-</span>
          }
          
        }
    },
    {
        dataField: 'earned_ytd',
        text: 'Earned YTD',
        headerStyle: headerStyle,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row, index) => {
        const earned_ytd = earnedYTD && earnedYTD.filter((arr) => row.rebate === arr[0].replace(/%/g, ""))
        if (!_isEmpty(earned_ytd) && earned_ytd[0][0]!=="0.00%") {
          return <span>{earned_ytd[0][1]}</span>
        } else {
          return <span style={{ color: "#0f5283" }}>-</span>
        }
      }
    },
    {
        dataField: 'earned_ly',
        text: 'Earned LY',
        headerStyle: headerStyle,
        align: 'center',
        headerAlign: 'center',
        formatter: (cell, row, index) => {
        const earned_ly = earnedLY && earnedLY.filter((arr) => row.rebate === arr[0].replace(/%/g, ""))
        if (!_isEmpty(earned_ly)&& earned_ly[0][0]!=="0.00%") {
          return <span>{earned_ly[0][1]}</span>
        } else {
          return <span style={{ color: "#0f5283" }}>-</span>
        }
      }
    },
  ]
  return (
    <BootstrapTable
    classes='pvp-table-styles'
    keyField="month"
    data={tableData}
    columns={columns}
    noDataIndication={ noDataState }
    />
  )
}

export default FourthTable