import _ from "lodash";
import _findIndex from "lodash/findIndex";
import _cloneDeep from "lodash/cloneDeep";
import _uniq from "lodash/uniq";
import _isEmpty from "lodash/isEmpty";
import _uniqBy from "lodash/uniqBy";
import _filter from "lodash/filter";

const parts = (
  state = {
    mixedPromo: false,
    hidePrice: false,
    partsAllDetails: [],
    selectedCatalogPartTypes: [],
    partsApiCall: false,
    categoryGroupMap: [],
    partsAllAvailability: [],
    partTypes: [],
    categorySuppliesMap: [],
    brandsView: false,
    lineItemData: [],
    headerId: "",
    suppliesReqGrpIds: [],
    suppliesReqBrandCodes: [],
    suppliesReqPimCategoryIds: [],
    createRecommendedJob: false,
    recommendedJobEditJob: [],
    specificConditions: [],
    catalogRequestGroups: { requestItems: [], filter: {} },
    selectedPartType: null,
    selectedProPack: {},
    isCategoryChanged: null,
    isChemicalCategoryChanged: null,
    partCatalogFilterSectionEnabled: true,
    cartShipMethod: null,
    cartStore: null,
    addToCartWarning: false,
    selectedAddCartData: [],
    sellerPartnerId: null,
    interchanges: [],
    selectedDropDown: "All",
    programUrl: "",
    isSearchedValuesAddedToCart:false,
    searchFailure : false,
    searchFailureForAddedToCart:false,
    isPartsNotFound: false

  },
  action
) => {
  let newState = {
    ...state,
  };
  switch (action.type) {
    case "CATEGORY_LIST_ALL":
      newState = {
        ...state,
        categoryList: action.payload.categoryList,
      };
      break;
    case "CATEGORY_GROUP_MAP":
      let { categoryGroupMap } = state;

      let singleCategory = {};
      let _categoryGroupMap = _cloneDeep(categoryGroupMap);
      //   let categoryGroupMap = [];
      let categoryIndex = _findIndex(_categoryGroupMap, {
        code: action.payload.categoryId,
      });
      if (categoryIndex === -1) {
        singleCategory.code = action.payload.categoryId;
        singleCategory.name = action.payload.categoryName;
        singleCategory.groups = action.payload.categoryGroupsList;
        singleCategory.imageUrl = action.payload.categoryimage;
        singleCategory.canSelect = action.payload.categoryCanSelect;
        _categoryGroupMap.push(singleCategory);
      } else {
        _categoryGroupMap[categoryIndex] = {
          code: action.payload.categoryId,
          name: action.payload.categoryName,
          groups: action.payload.categoryGroupsList,
          imageUrl: action.payload.categoryimage,
          canSelect: action.payload.categoryCanSelect,
        };
      }

      newState = {
        ...state,
        categoryGroupMap: Object.assign([], _categoryGroupMap),
      };
      break;
    case "GROUP_SELECTED":
      newState = {
        ...state,
        selectedGroupDetails: Object.assign(
          [],
          action.payload.selectedGroupDetails
        ),
      };
      break;
    case "PART_DIAGRAM_GROUP_SELECTED":
      newState = {
        ...state,
        selectedPartDiagramGroup: Object.assign(
          [],
          action.payload.selectedPartDiagramGroup
        ),
      };
      break;
    case "PART_LIST_DETAILS":
      let {
        payload: {
          allBrands,
          availabilities,
          partTypes,
          catalogPageNumber,
          catalogPageSize,
          catalogTotalPage,
          catalogTotalItems,
          getAllPartsByGroupApiCall,
          partsAllDetails,
          positions,
          maxPrice,
          minPrice,
        },
      } = action;
      newState = {
        ...state,
        catalogPageNumber: catalogPageNumber,
        catalogPageSize: catalogPageSize,
        catalogTotalPage: catalogTotalPage,
        catalogTotalItems: catalogTotalItems,
        partsAllAvailability: Object.assign([], availabilities.sort()),
        partsAllBrands:
          state.isCategoryChanged || state.isCategoryChanged == null
            ? Object.assign([], _uniq(allBrands.sort()))
            : state.partsAllBrands,
        partTypes: Object.assign([], _uniq(partTypes.sort())),
        partsAllDetails: Object.assign([], partsAllDetails),
        partsAllPosition: Object.assign([], positions.sort()),
        partsApiCall: getAllPartsByGroupApiCall,
        isCategoryChanged: state.isCategoryChanged,
        maxPrice: maxPrice,
        minPrice: minPrice,
      };
      break;
    case "PARTS_BY_GROUP_API_CALL":
      newState = {
        ...state,
        partsApiCall: action.payload.getAllPartsByGroupApiCall,
      };
      break;
    case "C2C_URL_FETCHED":
      let partDetails = _cloneDeep(state.partsAllDetails);
      let interChangeC2CUrl = "";
      let partIndex = _findIndex(partDetails, {
        partNumber: action.payload.partNumber,
      });
      if (action.payload.isInterchange) {
        interChangeC2CUrl = action.payload.c2cUrl;
      }
      if (partIndex >= 0) {
        partDetails[partIndex].c2cUrl = action.payload.c2cUrl;
      }
      newState = {
        ...state,
        partsAllDetails: [...partDetails],
        interChangeC2CUrl: interChangeC2CUrl,
      };
      break;
    case "BUYER_GUIDE_DATA_FETCHED":
      newState = {
        ...state,
        buyersGuideDetails: action.payload.buyersGuideData,
      };
      break;
    case "BUYER_GUIDE_BRAND_DATA_FETCHED":
      newState = {
        ...state,
        buyersGuideBrandDetails: action.payload.buyersGuideBrandData,
      };
      break;
    case "BUYERS_API_CALL":
      newState = {
        ...state,
        buyersApiCall: action.payload.buyersApiCallOnGoing,
      };
      break;
    case "LABOR_GUIDE_DATA_FETCHED":
      newState = {
        ...state,
        laborGuideDetails: action.payload.laborGuideData,
      };
      break;
    case "LABOR_API_CALL":
      newState = {
        ...state,
        laborApiCall: action.payload.laborApiCallOnGoing,
      };
      break;
    case "HIDE_ALL_PRICE":
      newState = {
        ...state,
        hidePrice: action.payload.hidePrice,
      };
      break;
    case "PART_CATALOG_TYPES":
      newState = {
        ...state,
        partTypes: action.payload.partTypes,
      };
      break;
    case "HOME_PAGE_PART_TYPES":
      newState = {
        ...state,
        homePagePartTypes: action.payload.homePagePartTypes,
      };
      break;
    case "SELECTED_PART_CATALOG_TYPES":
      newState = {
        ...state,
        selectedCatalogPartTypes: action.payload.selectedCatalogPartTypes,
      };
      break;
    case "PART_CATALOG_IMAGES":
      let partDetail = state.partsAllDetails
        ? _cloneDeep(state.partsAllDetails)
        : [];

      partDetail.map((part) => {
        if (part.alternate.length > 0) {
          part.alternate.map((alternatePart) => {
            let tumbnailFinded = action.payload.thumbnailData.find(
              (thumbnail) => {
                return thumbnail.partNumber == alternatePart.partNumber;
              }
            );
            if (tumbnailFinded && tumbnailFinded.partImages.length > 0) {
              alternatePart.images = tumbnailFinded.partImages
                ? tumbnailFinded.partImages
                : [];
              let defaultImage = tumbnailFinded.partImages.find((item) => {
                return item.primary === true;
              });
              if (!_isEmpty(defaultImage)) {
                alternatePart.image = defaultImage.url;
              } else {
                alternatePart.image = tumbnailFinded.partImages[0].url;
              }
            }
          });
        }

        if (part.substitute.length > 0) {
          part.substitute.map((substitutePart) => {
            let tumbnailFinded = action.payload.thumbnailData.find(
              (thumbnail) => {
                return thumbnail.partNumber == substitutePart.partNumber;
              }
            );
            if (tumbnailFinded && tumbnailFinded.partImages.length > 0) {
              substitutePart.images = tumbnailFinded.partImages
                ? tumbnailFinded.partImages
                : [];
              let defaultImage = tumbnailFinded.partImages.find((item) => {
                return item.primary === true;
              });
              if (!_isEmpty(defaultImage)) {
                substitutePart.image = defaultImage.url;
              } else {
                substitutePart.image = tumbnailFinded.partImages[0].url;
              }
            }
          });
        }
      });

      action.payload.thumbnailData.map((imageData) => {
        let partIndex = "";
        partIndex = _findIndex(partDetail, {
          partNumber: imageData.partNumber,
        });

        if (partIndex >= 0) {
          let defaultImage = imageData.partImages.find((item) => {
            return item.primary === true;
          });
          if (!_isEmpty(defaultImage)) {
            // partDetail[partIndex].image = defaultImage.url;
            partDetail.map((part) => {
              if (
                part.partNumber == imageData.partNumber &&
                _isEmpty(part.image)
              ) {
                part.image = defaultImage.url;
                part.images = imageData.partImages ? imageData.partImages : [];
              }
            });
          } else {
            if (imageData.partImages.length > 0) {
              // partDetail[partIndex].image = imageData.partImages[0].url;
              partDetail.map((part) => {
                if (
                  part.partNumber == imageData.partNumber &&
                  _isEmpty(part.image)
                ) {
                  part.image = imageData.partImages[0].url;
                  part.images = imageData.partImages
                    ? imageData.partImages
                    : [];
                }
              });
            }
          }
        }
      });
      newState = {
        ...state,
        partsAllDetails: [...partDetail],
      };
      break;
    case "PART_CATALOG_THUMBNAILS":
      let partAllDetail = state.partsAllDetails
        ? _cloneDeep(state.partsAllDetails)
        : [];
      action.payload.thumbnailData.map((imageData) => {
        // let partIndex = "";
        // partIndex = _findIndex(partAllDetail, {
        //   partNumber: imageData.partNum,
        // });
        // if (
        //   partAllDetail &&
        //   partAllDetail[partIndex] &&
        //   _isEmpty(partAllDetail[partIndex].image)
        // ) {
        //   partAllDetail[partIndex].thumbnail = imageData.image;
        // }
        if (partAllDetail) {
          partAllDetail.map((part) => {
            if (part.partNumber == imageData.partNum && _isEmpty(part.image)) {
              part.thumbnail = imageData.image;
            }
          });
        }
      });
      newState = {
        ...state,
        partsAllDetails: [...partAllDetail],
      };
      break;
    case "SUPPLIES_LIST_DETAILS":
      newState = {
        ...state,
        suppliesAllDetails: action.payload.suppliesAllDetails,
        suppliesPartsAllAvailability: action.payload.suppliesAvailability,
        suppliesAllBrands:
          state.isChemicalCategoryChanged ||
          state.isChemicalCategoryChanged == null
            ? action.payload.suppliesAllBrands
            : state.suppliesAllBrands,
        suppliesAllCaseQty: Object.assign([], action.payload.allCaseQty),
        suppliesAllSizeQty: Object.assign([], action.payload.allSizeQty),
        suppliesAllSpecs: Object.assign([], action.payload.specs),
        suppliesPageNumber: action.payload.pageNumber,
        suppliesTotalPages: action.payload.totalPages,
        suppliesPageSize: action.payload.pageSize,
        suppliesTotalItems: action.payload.totalItems,
        isChemicalCategoryChanged: state.isChemicalCategoryChanged,
        chemicalMinPrice: action.payload.minPrice,
        chemicalMaxPrice: action.payload.maxPrice,
      };
      break;
    case "BRANDS_CATEGORY_MAP":
      newState = {
        ...state,
        brandsCategoryMap: action.payload.brandsCategoryMap,
      };
      break;
    case "CATEGORY_SUPPLIES_MAP":
      let categorySuppliesMap = _cloneDeep(state.categorySuppliesMap);
      let _singleCategory = {};
      let supIndex = _findIndex(categorySuppliesMap, {
        code: action.payload.categoryId,
      });
      if (supIndex === -1) {
        _singleCategory.code = action.payload.categoryId;
        _singleCategory.name = action.payload.categoryName;
        _singleCategory.groups = action.payload.categorySuppliesList;
        _singleCategory.imageUrl = action.payload.categoryImage;
        _singleCategory.canSelectChemical = action.payload.categoryGroupSelect;
        categorySuppliesMap.push(_singleCategory);
      } else {
        categorySuppliesMap[supIndex] = {
          code: action.payload.categoryId,
          name: action.payload.categoryName,
          groups: action.payload.categorySuppliesList,
          imageUrl: action.payload.categoryImage,
          canSelectChemical: action.payload.categoryGroupSelect,
        };
      }

      newState = {
        ...state,
        categorySuppliesMap: [..._uniqBy(categorySuppliesMap, "code")],
      };
      break;
    case "GROUP_CHANGED":
      newState = {
        ...state,
        isGroupChanged: action.payload.bool,
      };
      break;
    case "RESET_SPECIFIC_CONDITIONS":
      newState = {
        ...state,
        specificConditions: [],
      };
      break;
    case "RESET_PARTS_ALL_DETAILS":
      newState = {
        ...state,
        partsAllDetails: [],
      };
      break;
    case "SPECIFIC_CONDITIONS":
      let uniques = [...state.specificConditions];
      // To maintain the specific Conditions state
      if (action.payload.concat) {
        uniques = uniques.concat(action.payload.specificConditions);
      } else {
        uniques = action.payload.specificConditions;
      }

      // code to remove if any empty specific Condition in a group
      uniques = uniques.map((u) => {
        if (u.specificCondition.length > 0) {
          return u;
        } else {
          return undefined;
        }
      });

      // filter out undefined from the array
      uniques = uniques.filter((u) => u);
      uniques = _.uniqBy(uniques, "groupCode");
      newState = {
        ...state,
        specificConditions: uniques,
      };
      break;
    case "EXTRA_SPECIFICS_FOR_PARTS":
      let uniqSpecificConditions = [];
      uniqSpecificConditions = uniqSpecificConditions.concat(
        action.payload.specificConditions
      );
      // Uniques based on groupCode
      uniqSpecificConditions = _.uniqBy(uniqSpecificConditions, "groupCode");

      newState = {
        ...state,
        extraSpecificsForParts: uniqSpecificConditions,
      };
      break;
    case "GFX_GLOBAL_FILTERS":
      newState = {
        ...state,
        globalFiltersForGfx: action.payload.globalFiltersForGfx,
        illustrationDataItemList: action.payload.illustrationDataItemList,
      };
      break;
    case "SUPPLIES_LIST_API_LOADING":
      newState = {
        ...state,
        supplyListApiCall: action.payload.supplyListApiCall,
      };
      break;
    case "PARTS_BY_GROUP_REQUEST":
      // TODO Look into this
      // On Custom Jobs click clearing the catalog request groups, so handling so persist the requestitems.
      let uniqueRequestItems = state.catalogRequestGroups.requestItems;

      if (
        action.payload.requestGroups &&
        action.payload.requestGroups.requestItems &&
        action.payload.requestGroups.requestItems.length > 0
      ) {
        uniqueRequestItems = uniqueRequestItems.concat(
          action.payload.requestGroups.requestItems
        );
      }

      uniqueRequestItems = _.uniqBy(uniqueRequestItems, "groupCode");

      // END

      newState = {
        ...state,
        catalogFilter: action.payload.catalogFilter,
        catalogRequestGroups: action.payload.requestGroups,
        catalogRequestVehicle: action.payload.requestVehicle,
        catalogPageNumber: action.payload.catalogPageNumber,
        catalogPageSize: action.payload.catalogPageSize,
        catalogSortBy: action.payload.catalogSortBy,
        catalogOrder: action.payload.catalogOrder,
      };
      break;
    case "CATALOG_FILTER_APPLIED":
      newState = {
        ...state,
        partsAllDetails: action.payload.filteredData,
        appliedFilters: action.payload.appliedFilters,
      };
      break;
    case "PROPACK_SELECTED":
      newState = {
        ...state,
        selectedProPack: action.payload.selectedProPack,
      };
      break;
    case "JOB_SELECTED":
      newState = {
        ...state,
        selectedJobDetails: action.payload.selectedJobDetails,
      };
      break;
    case "SUPPLIES_BY_PART_REQUEST":
      newState = {
        ...state,
        suppliesReqBrandCodes: Object.assign([], action.payload.reqBrands),
        suppliesReqPimCategoryIds: Object.assign(
          [],
          action.payload.reqCategories
        ),
        suppliesReqGrpIds: Object.assign(
          [],
          action.payload.suppliesRequestGroups
        ),
        suppliesPageNumber: action.payload.pageNumber,
        suppliesPageSize: action.payload.pageSize,
        suppliesSortBy: action.payload.sortBy,
        suppliesOrder: action.payload.order,
        suppliesReqBrands:
          action.payload.brand && action.payload.brand.toString(),
        suppliesOffers: Object.assign([], action.payload.misc),
        suppliesReqAvailability: Object.assign([], action.payload.availability),
        suppliesReqSpecs: Object.assign([], action.payload.specification),
        suppliesReqSizeQty: Object.assign([], action.payload.sizeQty),
        suppliesReqCaseQty: Object.assign([], action.payload.caseQty),
      };
      break;
    case "INTERCHANGE_BRANDS_DROPDOWN_OPTIONS_FETCH": {
      const { brandOptions } = action.payload;

      const _interchanges = _.cloneDeep(state.interchanges);
      let __interchanges = _.cloneDeep(state.interchanges);
      if (action.payload.partNumber) {
        __interchanges = [];
        __interchanges = _interchanges.map((_i) => {
          if (_i.partNum === action.payload.partNumber) {
            _i.brands = brandOptions;
          }

          return _i;
        });

        const found = _.find(
          _interchanges,
          (i) => i.partNum === action.payload.partNumber
        );
        if (!found) {
          __interchanges.push({
            partNum: action.payload.partNumber,
            brands: brandOptions,
          });
        }
      }

      newState = {
        ...state,
        brandOptions: action.payload.brandOptions,
        interchanges: __interchanges,
      };

      break;
    }
    case "INTERCHANGE_PART_TYPE_DROPDOWN_OPTIONS_FETCH": {
      const { partTypeOptions } = action.payload;

      const _interchanges = _.cloneDeep(state.interchanges);
      let __interchanges = _.cloneDeep(state.interchanges);
      if (action.payload.partNumber) {
        __interchanges = [];
        __interchanges = _interchanges.map((_i) => {
          if (_i.partNum === action.payload.partNumber) {
            _i.partTypes = partTypeOptions;
          }

          return _i;
        });

        const found = _.find(
          _interchanges,
          (i) => i.partNum === action.payload.partNumber
        );

        if (!found) {
          __interchanges.push({
            partNum: action.payload.partNumber,
            partTypes: partTypeOptions,
          });
        }
      }

      newState = {
        ...state,
        partTypeOptions: action.payload.partTypeOptions,
        interchanges: __interchanges,
      };
      break;
    }
    case "INTERCHANGE_DATA_FETCHED":
      const __interchanges = action.payload?.interchanges || [];
      const _interchanges_ = _.cloneDeep(state.interchanges);
      let clonedInterchanges = _.cloneDeep(state.interchanges);

      clonedInterchanges = _interchanges_?.map((_i) => {
        __interchanges.forEach((__i) => {
          if (_i.partNum === __i.partNumber) {
            _i.interchangeData = __i.interchangeData;
          }
        });
        return _i;
      });

      // check for the partnumbers are in state interchanges, if not add to them;
      __interchanges.forEach((__i) => {
        const found = _.find(
          _interchanges_,
          (c) => c.partNum === __i.partNumber
        );

        if (!found) {
          clonedInterchanges = clonedInterchanges.concat(__i);
        }
      });

      _.findIndex();

      let _interchanges = clonedInterchanges.concat(state.interchanges).flat();

      _interchanges = _.uniqBy(_interchanges, (i) => i.partNum);

      newState = {
        ...state,
        interchangeData: action.payload.interchangeData,
        interchanges: _interchanges,
      };
      break;
    case "INTERCHANGE_BULK_DATA_FETCHED":
      newState = {
        ...state,
        interchanges: [action.payload].concat(state.interchanges).flat(),
      };
      break;
    case "INTERCHANGES_RESET":
      newState = {
        ...state,
        interchanges: [],
      };
      break;
    case "INTERCHANGE_API_CALL":
      newState = {
        ...state,
        interchangeApiCall: action.payload.interchangeApiCallOnGoing,
      };
      break;
    case "INTERCHANGE_BRAND_API_CALL":
      newState = {
        ...state,
        interchangeBrandApiCall: action.payload.interchangeBrandApiCallOnGoing,
      };
      break;
    // case 'SUPPLIES_FILTER_APPLIED':
    //     newState = {
    //         ...state,
    //         suppliesFiltered: Object.assign([], action.payload.filteredData),
    //     }
    //     break;
    case "SUPPLIES_BRAND_VIEW":
      newState = {
        ...state,
        brandsView: action.payload.brandsView,
      };
      break;
    case "SUPPLIES_CATEGORIES_BRAND_MAP":
      newState = {
        ...state,
        suppCBMap: action.payload.suppCBMap,
      };
      break;
    case "RECOMMENDEDJOB_EDIT_JOB":
      newState = {
        ...state,
        recommendedJobEditJob: action.payload.recommendedJobEditJob,
      };
      break;

    case "CREATE_RECOMMENDEDJOB":
      newState = {
        ...state,
        createRecommendedJob: action.payload.createRecommendedJob,
      };
      break;
    case "SUPPLIES_GROUP_SELECTED":
      newState = {
        ...state,
        suppliesSelectedGroups: Object.assign(
          [],
          action.payload.selectedGroupDetails
        ),
      };
      break;
    case "SELECTED_PROMOTION":
      newState = {
        ...state,
        selectedPromotion: Object.assign({}, action.payload.singleItem),
      };
      break;
    case "PROMOTION_EVENT_STATUS":
      newState = {
        ...state,
        promotionEventStatus: action.payload.status,
      };
      break;
    case "MIXED_PROMOTION":
      newState = {
        ...state,
        mixedPromo: action.payload.mixedPromo,
      };
      break;
    case "SELECTED_PART_TYPE":
      newState = {
        ...state,
        selectedPartType: action.payload.selectedPartType,
      };
      break;
    case "STORE_DATA":
      newState = {
        ...state,
        lineItemData: action.payload.lineItemData,
      };
      break;
    case "STORE_HEADERID":
      newState = {
        ...state,
        headerId: action.payload.headerId,
      };
      break;
    case "CATALOG_VIEW_TOGGLE":
      const {
        data,
        data: { isEnable, isPrefenceSet },
      } = action;
      newState = {
        ...state,
        catalogToggleView: !_isEmpty(data) ? data : [],
      };
      break;
    case "CATEGORY_CHANGED":
      newState = {
        ...state,
        isCategoryChanged: action.payload.bool,
      };
      break;
    case "CHEMICAL_CATEGORY_CHANGED":
      newState = {
        ...state,
        isChemicalCategoryChanged: action.payload.bool,
      };
      break;
    case "UPDATE_PART_CATALOG_FILTER_SECTION":
      newState = {
        ...state,
        partCatalogFilterSectionEnabled: action.payload.filterEnabled,
      };
      break;
    case "CART_SHIPMETHOD":
      newState = {
        ...state,
        cartShipMethod: action.payload.shipMethod,
        cartStore: action.payload.cartStore,
        sellerPartnerId: action.payload.sellPartnerId,
      };
      break;
    case "ADDTO_CART_WARNING":
      newState = {
        ...state,
        addToCartWarning: action.payload.addToCartWarning,
      };
      break;
    case "SELECTED_ADDCART_DATA":
      newState = {
        ...state,
        selectedAddCartData: action.payload.selectedAddCartData,
        isSearchedValuesAddedToCart: true,
      };
      break;
    case "SEARCHED_ADDTOCART_DATA":
      newState = {
        ...state,
        isSearchedValuesAddedToCart: false,
      };
      break;
    case "SELECTED_OPTIONS":
      newState = {
        ...state,
        selectedDropDown: action.payload.selectedDropDown,
      };
      break;
    case "UPDATE_CATALOG_FILTER":
      newState = {
        ...state,
        catalogFilter: action.payload,
      };
      break;
    case "PROGRAM_BENEFITS_STATEMENT":
      newState = {
        ...state,
        programUrl: action.payload.programUrl,
      };
      break;
    case "SEARCH_FAILURE_ENABLED":
      newState = {
        ...state,
        searchFailure: action.payload.searchFailure,
      };
      break;
    case "SEARCH_FAILURE_ENABLED_FOR_ADDED_TO_CART":
      newState = {
        ...state,
        searchFailureForAddedToCart: action.payload.searchFailureForAddedToCart,
      };
      break;
    case 'PARTS_NOTFOUND':
        newState = {
          ...state,
          isPartsNotFound: action.payload.isPartsNotFound,
        };
        break;

    default:
      break;
  }
  return newState;
};
export default parts;
