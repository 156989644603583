// poly fills to support IE11
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import "./static/css/index.css";
import App from "./components/App";
import { Provider } from "react-redux";
import configureStore from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import * as serviceWorker from "./serviceWorker";

import "bootstrap-daterangepicker/daterangepicker.css";

import theme from "./theme";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={configureStore()}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
