import React, {Fragment} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _remove from 'lodash/remove';
import _isEmpty from 'lodash/isEmpty';
import { addItemToCart2} from '../../../actions/cart';
import { setPartHistory } from '../MyGarage/mygarage.thunks';
import { Button } from 'react-bootstrap';
import _find from 'lodash/find';
import _uniqBy from 'lodash/uniqBy';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _cloneDeep from 'lodash/cloneDeep';
import { timeInUTC, isStockOrder } from '../../../helpers/utils';
const actionTypes = {
	SELECTED_ADDCART_DATA: 'SELECTED_ADDCART_DATA',
	ADDTO_CART_WARNING: 'ADDTO_CART_WARNING'
};

const AddToCart = (props) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const mygarage = useSelector((state) => state.mygarage);
	const orderType = useSelector((state) => state.cart.orderType);

	const categoryGroupMap = useSelector((state) => state.parts.categoryGroupMap);

	const { model, modelId, make, makeId, engine, engineId, year, internalMakeId } = mygarage.selectedVehicle || {};

	let { disabled, selectedAvailabilityHours, source, buttonStyle, fromPage } = props;
	const addToCart = () => {
		dispatch({
			payload: {
				selectedAddCartData: _cloneDeep(selectedAvailabilityHours),
		    },
		   type: actionTypes.SELECTED_ADDCART_DATA,
		});
		if (isStockOrder(orderType)) {
			dispatch({
				payload: {
					addToCartWarning: true,
				},
				type: actionTypes.ADDTO_CART_WARNING,
			});
		} else {
			selectedAvailabilityHours = _remove(
				selectedAvailabilityHours,
				(a) => Number(a.qty) > 0
			);
			if (!_isEmpty(selectedAvailabilityHours) && user && user.userDetails) {
				const {
					userDetails: { x2userName, shipTos, billTo },
				} = user;

				addItemToCart2(selectedAvailabilityHours, dispatch, true, user, fromPage);
				let uniqsah = _uniqBy(selectedAvailabilityHours, 'descId');
				uniqsah.map((s, k) => {
					if(!_isEmpty(s.descId)) {
						const payload = {
							categoryId: Number(
								_find(categoryGroupMap, (c) => {
									return _includes(
										_map(c.groups, 'code'),
										Number(s.groupId)
									);
								})?.code
							),
							createdBy: x2userName,
							createdDate: timeInUTC(),
							customerId: billTo.billToId,
							engine: engine.trim(),
							engineId,
							groupId: s.groupId,
							lastOrderedDate: timeInUTC(),
							make: make.trim(),
							makeId,
							internalMakeId,
							model: model.trim(),
							modelId,
							orderedFlag: 'N',
							partTypeDesc: s.desc,
							partTypeDescId: s.descId,
							searchPartId: '',
							shipToId: shipTos[0].shipToId,
							source,
							updatedBy: x2userName,
							updatedDate: timeInUTC(),
							userFilter: 'selected',
							year: year.trim(),
							orderType: "REGULAR"
						};
							dispatch(setPartHistory(payload));
					}
				});
			}
		}
	};
	return (
		<Fragment>
		<Button
			className={buttonStyle ? buttonStyle : "add-to-cart-btn"}
			onClick={() => addToCart()}
			disabled={disabled}
		>
			Add to cart
		</Button>
		</Fragment>
	);
};
export default AddToCart;
