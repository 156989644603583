import React, { useState, useEffect, Fragment } from "react";
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import UpdateMobileNumber from '../../User/UserManagement/UpdateMobileNumber';
import UpdatePassword from '../../User/UserManagement/UpdatePassword';


const Profile = (props) => {
	const {
		handleInputChange,
		handleMobNumberOnChange,
		handleMobKeyDown,
		validity,
		mobValidity,
		fields,
		mobileNumber,
	} = props;

	const getKey = () => {
		let tempKey = 1;
		return tempKey;
	}
	const [key, setkey] = useState(getKey());


	return (
		<div className="user-profile-container">
			<Tab.Container id="left-tabs-example" defaultActiveKey={key}>
				<Row className="row">
					<Col lg={3} md={4} sm={4} xs={6} className="horizontal-tabs">
						<Nav variant="pills" className="flex-column">

							<Nav.Item>
								<Nav.Link eventKey={1}>Change Password</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey={2}>Change Mobile#</Nav.Link>
							</Nav.Item>

						</Nav>
					</Col>
					<Col lg={9} md={8} sm={8} xs={6} className="horizontal-tab-details-container">
						<Tab.Content className="horizontal-tab-details">

							<Tab.Pane eventKey={1}>
								<UpdatePassword
									handleInputChange={handleInputChange}
									validity={validity}
									fields={fields}
								/>
							</Tab.Pane>
							<Tab.Pane eventKey={2}>
								<UpdateMobileNumber
									handleMobNumberOnChange={handleMobNumberOnChange}
									validity={mobValidity}
									handleMobKeyDown={handleMobKeyDown}
									mobileNumber={mobileNumber}
								/>
							</Tab.Pane>


						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		</div>
	);
}
export default Profile;
