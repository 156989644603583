import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector} from "react-redux";
import _isEmpty from "lodash/isEmpty";
import {Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { triggerCustomUxCriticalError } from "../../../actions/error";
import {
    updateToken,
} from '../../../actions/auth';
import {
    updateUserPreferenceDetails,
    getUserPreferenceDetails,
    getListOfUsersByUserType
} from '../UserManagement/usermanagement.thunk';
import _ from "lodash";
import XSelect from '../../../components/shared/XSelect';


const ModifyUserITAdmin = () => {
    const [userModified, setuserModified] = useState(false);
    const [modifyUserPref, setModifyUserPref] = useState([]);
    const [grandAccessAnswer, setGrandAccessAnswer] = useState([]);
    const [grandAccessValue, setGrandAccessValue] =useState([])
    const [grandAccessOptions, setGrandAccessOptions] =useState([]);
    const dispatch = useDispatch();
    const [email, setEmail] = useState("")
    const [usersList, setUsersList] = useState([])
    const [userNameValue, setUserNameValue] = useState([])
  
    useEffect(() => {
      dispatch(getListOfUsersByUserType({type:"IT_INTERNAL"})).then((res) => {
        setUsersList(res.payload)
      });
    }, []);

    const createDropDownOptions = () => {
      let optionArr = [];
      if(!_isEmpty(usersList)) {
          usersList.map((user) => {
            optionArr.push({
                value: user.x2userName,
                label: user.x2userName,
            });
          });
      }
      return optionArr;
  }
  
    const handleGrandAccessChange = (value)=>{
        let tempArr = [];
        if(!_isEmpty(value)){
            value.map((val) => {
                tempArr.push(val);
            });
            setGrandAccessValue(tempArr);
        }else{
            setGrandAccessValue([]);
        }
        let updatedUserPref = _.cloneDeep(modifyUserPref);
        let grandAccessIndex = updatedUserPref.findIndex((item)=>{
            return(
             item.text == "Grant Access To IT Admin" &&
             item.code == "User Management"
            ) 
        })
        let grandAccessItemSet = updatedUserPref[grandAccessIndex];
        
        let multiAnswer = [];
        tempArr.map((option)=>{
            multiAnswer.push(option.value);
        })
        setGrandAccessAnswer([{id:grandAccessItemSet.id, code:grandAccessItemSet.code, answer:null, multiAnswer:multiAnswer}]);
    }

    const handleUserSelection = (event)=> {
      setUserNameValue([{label:event.value, value:event.value}])
      setEmail(event.value);
      setuserModified(true);
      dispatch({
        payload: {
          status: true,
        },
        type: "REFRESH_TOKEN_STATUS",
      });
      dispatch(getUserPreferenceDetails({username:event.value})).then((res) => {
        dispatch({
          payload: {
            status: false,
          },
          type: "REFRESH_TOKEN_STATUS",
        });
          setModifyUserPref(res.payload);
          createGrandAccessOptions(res.payload);
          setGrandAccessAnswer([]);
        });
      }

    const createGrandAccessOptions =(itemArray)=>{
        let grandAccessItemSet = itemArray.find((item)=>{
            return(
                item.text == "Grant Access To IT Admin" &&
                item.code == "User Management"
            ) 
        })

        if(grandAccessItemSet &&grandAccessItemSet.multiAnswer.length>0){
            let multiAnswer = [];
            grandAccessItemSet.multiAnswer.map((singleMultiAnswer)=>{
                let multiOptionValue= {
                    value: singleMultiAnswer.code,
                    label: singleMultiAnswer.text,
                    default : singleMultiAnswer.default
                   }
                   multiAnswer.push(multiOptionValue);
            })
            setGrandAccessValue(multiAnswer);
        }else{
            setGrandAccessValue([]);  
        }

        let options =[];
        grandAccessItemSet && grandAccessItemSet.possibleMultiAnswer.map((item)=>{
            let singleOption ={
             value: item.code,
             label: item.text,
             default : item.default
            }
            options.push(singleOption);
        })
        setGrandAccessOptions(options);
    }

    const handleSubmit = () => {
        let data = {requestItems:grandAccessAnswer, params:{username:email}}
        const statusMessageProps = {
            heading: 'Success',
            message: 'User preferences has been changed successfully.',
            type: 'success',
        };
        dispatch(updateUserPreferenceDetails(data)).then(() => {
            updateToken(dispatch).then(() => {
                triggerCustomUxCriticalError(statusMessageProps, dispatch);
                setEmail("")
                setUserNameValue([]);
                setuserModified(false);
                setGrandAccessAnswer([]);
                setGrandAccessValue([]);
            });

        });
    }

    return (
        <Fragment>
          <div className="label-dropdown-container custom_select_height">
                <Form.Label>User Name</Form.Label>
                <XSelect
                    options={!_isEmpty(usersList) && createDropDownOptions()}
                    placeholder="Select a User Name"
                    className="custom-dropdown"
                    onChange={(e)=>handleUserSelection(e)}
                    isDisabled = {_isEmpty(usersList)}
                    value ={!_isEmpty(userNameValue) && userNameValue}
                />
            </div>
            <div className="label-dropdown-container custom_select_height">
                <Form.Label>Grant Access To</Form.Label>
                <XSelect
                    options={grandAccessOptions}
                    value ={!_isEmpty(grandAccessValue) && grandAccessValue}
                    placeholder="Select Grant Access To"
                    className="custom-dropdown"
                    isMulti
                    onChange={(value)=>{handleGrandAccessChange(value)}}
                    isDisabled ={_isEmpty(userNameValue)}
                />
            </div>
            <Button
                disabled={!userModified}
                onClick={handleSubmit}
                className="user-management-submit-btn"
            >
                ok</Button>
        </Fragment>
    );
}

export default ModifyUserITAdmin;
