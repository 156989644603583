import React, { Fragment, useEffect, useState } from "react";
import { ListGroup, Col, Form, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import XModal from "../../shared/XModal";
import _keys from 'lodash/keys';
import _isEmpty from 'lodash/isEmpty';
import {
 timeInUTC
} from "../../../helpers/utils";
import VectorGfxIllustrationPopup from "./VectorGFXIllustration"
import { getAvailableVectorGfxFilter } from "./vectorgfx.thunks";
import { logUserStockActivity } from "../../../actions/user";

import "./styles.scss";

const VectorGfxPopup = (props) => {
  let {
    showPopUp,
    togglePopup,
    engine,
		group,
		make,
		model,
		year,
    descriptionID,
    selectedGroupDetails,
    SelectedVehicle,
    shippingInfo,
    isForCandK
  } = props;
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetails);
  const [globalFiltersForGfx, setGlobalFiltersForGfx] = useState([]);
  const [illustrationDataItemList, setIllustrationDataItemList] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState({});
  const [filteredAssetIds, setFilteredAssetIds] = useState([]);
  const [conditionSelectionDone, setConditionSelectionDone] = useState({});
  const [showVectorIllustrationPopup, setShowVectorIllustrationPopup] = useState(false);
  const [selectedDescriptionIds, setSelectedDescriptionIds] = useState(null);
  const [selectfilteredAsset , setSelectfilteredAsset] = useState([]);
  const [assetIds , setAssetIds] = useState([]);
  const [isButtonEnabled , setIsButtonEnabled] =useState(false);

  const initialGetAvailableGfxFilter = () => {
    const payload = {
      engine: engine,
      make: make,
      model: model,
      year: year,
      group: group,
      descCode: descriptionID,
      dispatch: dispatch
    };
    try {
      const availableGfxFilterAction = dispatch(getAvailableVectorGfxFilter(payload));
      availableGfxFilterAction.then((action) => {
        constructFlobalFilters(action.payload.globalfilterHeaderValuesMap);
        setIllustrationDataItemList(action.payload.illustrationDataItemList);
        fetchAssetIdsForFilter([], action.payload.illustrationDataItemList);
		  });
    } catch (error) {}
  };

  useEffect(() => {
    logUserActivity(true, "isPartDiagramClicked")
    initialGetAvailableGfxFilter();
  }, []);
  
  const logUserActivity = (isActivityEnabled, activityName) => {
    const { x2userName, shipTos, billTo } = userDetails;
    if (isActivityEnabled && !_isEmpty(userDetails) && !_isEmpty(billTo)) {
      let loginId = "";
      let shipToId = shipTos[0]?.shipToId;
      let userData = "";
      dispatch(
        logUserStockActivity(
          timeInUTC(),
          billTo.billToId,
          loginId,
          shipToId,
          activityName,
          userData,
          x2userName
        )
      );
      }
  }
  const constructFlobalFilters = (filters) => {
    let condtns = [];
    if (!_isEmpty(filters)) {
      let filterKeys = _keys(filters);
      filterKeys.map((singleKey) => {
        let condtnOptns = []
        filters[singleKey].map((singleFilter) => {
          condtnOptns.push({
            'code': singleFilter,
            'text': singleFilter
          })
        });
        condtnOptns.push({
          'code': '?',
          'text': "Don't Know"
        })
        condtns.push({
          'code': singleKey,
          'text': singleKey,
          'options': condtnOptns
        });
      });
    }
    setGlobalFiltersForGfx(condtns);
  }

  const handleClosePopup = () => {
    togglePopup('vectorGfx');
    setShowVectorIllustrationPopup(false);
    setIsButtonEnabled(false);
  }

  const openVectorGFXHandler = () => {
    if(filteredAssetIds.length > 0) {
      let isSelectedDescExists = illustrationDataItemList.find((dataItem) => {
        if(filteredAssetIds.length === 1){
          setAssetIds(filteredAssetIds[0]);
          return dataItem.assetId === filteredAssetIds[0];
        }else
        if(filteredAssetIds.length > 1){
          let filteredID = !_isEmpty(selectfilteredAsset) ? selectfilteredAsset : filteredAssetIds[0];
          setAssetIds(filteredID);
          return dataItem.assetId === filteredID;
        }       
      });
      setSelectedDescriptionIds(isSelectedDescExists.descriptionIds);
      setShowVectorIllustrationPopup(true);
      setIsButtonEnabled(false);
      // togglePopup('vectorGfx');
    } else {
      //TODO: show toast message, to filter to one asset id
    }
  }

  const handleSelectFilter = (e, question, answer) => {
    const key = question.code;
    const value = answer.code;
    selectedConditions[key] = value;
    if(value === '?') {
      delete selectedConditions[key];
    }
    conditionSelectionDone[key] = true;
    fetchAssetIdsForFilter(selectedConditions);
    console.log("selected conditions : " +JSON.stringify(selectedConditions));
  }

  const fetchAssetIdsForFilter = (selectedConditions, dataItemList) => {
    setFilteredAssetIds([]);
    const itemList = (dataItemList) ? dataItemList : illustrationDataItemList;
    const filteredAssetIdsObj = itemList.filter(
        (item) => {
          for (var key in selectedConditions) {
            if (item['filterHeaderValueMap'][key] === undefined || item['filterHeaderValueMap'][key] != selectedConditions[key])
              return false;
          }
          return true;
        }
    );
    const setObj = new Set([]);
    filteredAssetIdsObj.map((filterObj) => {
        setObj.add(filterObj.assetId);
    });

    setFilteredAssetIds([...setObj]);
  }

  return (
    <React.Fragment>
      {!showVectorIllustrationPopup ? (
      <XModal
        show={showPopUp}
        title="Please select a specific item to continue"
        handleClose={handleClosePopup}
        handleAction={openVectorGFXHandler}
        // submitType
        yesButtonText = "OK"
        noButtonText = "Cancel"
        isValidValidity = { filteredAssetIds.length === 1  ? true : isButtonEnabled}
        showButtons
      >
        <Container>
          {globalFiltersForGfx.length > 0 ? (
          <React.Fragment>
            <h4 class="vector-condition-title">Please select options below to make sure one asset id is selected</h4>
            <Row>
            {globalFiltersForGfx.map(
              (question, key) => {
                return (
                  <div className="dynamic-col">
                  <ListGroup>
                    <span className="bold">
                      {question.text}
                    </span>
                    <br />
                    {question.options.map(
                      (answer, key) => {
                        return (
                          <ListGroup.Item
            								action
                            key={answer.code}
                            href={`#${answer.code}`}
                            className={`${((conditionSelectionDone[question['code']] === undefined || conditionSelectionDone[question['code']] == false) && answer.code === '?') ? 'active' : ''}`}
                            onClick={(e) => {
            									handleSelectFilter(e, question, answer);
            								}}
            							>
            								{answer.text}
            							</ListGroup.Item>
                        );
                      }
                    )}
                  </ListGroup>
                  </div>
                );
              }
            )}
            </Row>
            {filteredAssetIds.length > 1 &&(
            <Form.Label className="bold">
            Please select one of the available diagrams to proceed
             </Form.Label>)
            }
            <Form.Group>
              <Row className="py-0">
                {filteredAssetIds.length > 0 ? (
                <Col sm={12}>
                  <Form.Label className="bold">
                    Available Diagrams:
                  </Form.Label>
                  {filteredAssetIds.length === 1 ? 
                   <Form.Label>
                   {filteredAssetIds.join(", ")}
                   </Form.Label> :                
                  (filteredAssetIds.map((asId , cIndex)=>( 
                    <div className="vector_radioWrapper vector_condition">   
                    <Form.Check
                    type="radio"
                    name={"assetIds"}
                    label={`${asId}`}
                    id={asId }
                    onClick={(event) => {
                      setIsButtonEnabled(true);
                      setSelectfilteredAsset(asId)}
                      }>
                   </Form.Check></div>    
                  ))) 
                }               
                </Col>
                ) : null}
              </Row>
            </Form.Group>
          </React.Fragment>
          ) : null}
        </Container>
      </XModal>
      ) : null}
      {showVectorIllustrationPopup ? (
        <VectorGfxIllustrationPopup
          openPopup={showVectorIllustrationPopup}
          groupId={group}
          // assetId={filteredAssetIds.join('')}
          assetId={assetIds}
          closePopup={() => handleClosePopup()}
          descriptionIds={selectedDescriptionIds}
          selectedGroupDetails={selectedGroupDetails}
          sv={SelectedVehicle}
          shippingInfo={shippingInfo}
          isForCandK={isForCandK ? isForCandK: false}
        />
      ) : null}
    </React.Fragment>
  );
};

export default VectorGfxPopup;
