/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AsyncTypeahead, MenuItem, Menu } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import favoriteIcon from "../../../static/images/icons/red/Select_Deselect_Favorite_1.png";
import { ReactComponent as FavoriteIcon } from "../../../static/images/icons/Select_Deselect_Favorite_1.svg";
import { setFavoriteVehicle } from "../MyGarage/mygarage.thunks";
import {
  getProductListFromSkuNumber,
  search,
  vinSearch,
} from "../../../components/Homepage/Search/globalSearch.thunk";
import { getGroupsForCategory } from "../../../components/Homepage/Search/globalSearch.slice";
import { getPartsByMultiGroupOrchestrator } from "../../../actions/parts";
import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import {
  buildQueryUrl,
  getCategoryFromGroupId,
  isLengthLessthan,
  timeInUTC,
} from "../../../helpers/utils";
import "./styles.scss";
import { useRef } from "react";
import VIN_Icon from "../../../static/images/VIN_Icon.svg";
import Fuse from "fuse.js";
import _isEmpty from "lodash/isEmpty";
import _head from "lodash/head";
import XModal from "../XModal";
import { logUserStockActivity } from "../../../actions/user";
import GlobalSearchWrapper from "components/Homepage/Search/GlobalSearchWrapper";
import axios from "axios";
import instance from "../../../services/http";
import short from "short-uuid";
import clsx from "clsx";

import {
  DEBOUNCE_RATE,
  SEARCH_API_WORD,
} from "components/Homepage/Search/helpers";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ItemsRender from "../Item Render";

const ITEM_HEIGHT = 32;

function VehicleBreadCrumb(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const parts = useSelector((state) => state.parts);
  const searchInput = useRef(null);
  const location = useLocation();

  const initializeValidity = () => {
    const validity = {
      globalSearchMaxLimit: true,
    };
    return validity;
  };

  const [validity, setValidity] = useState(initializeValidity);

  let { clearLists } = props;
  const mygarage = useSelector((state) => state.mygarage);
  const globalSearch = useSelector((state) => state.globalSearch);
  const sv = mygarage.selectedVehicle || search.selectedVehicle;
  const selectedVin = mygarage.selectedVehicle;
  const smsPartDescription = useSelector(
    (state) => state.app.smsPartDescription
  );
  const userDetails = useSelector((state) => state.user.userDetails);
  const smsChemicalPart = useSelector((state) => state.app.smsChemicalPart);
  const smsUser = useSelector((state) => state.app.smsUser);
  const [infoDetails, setInfoDetails] = useState("");
  const [showVinModal, setShowVinModal] = useState(false);
  const [showVinIcon, setShowVinIcon] = useState(false);
  const [searchFailureValue, setSearchFailure] = useState("");
  const [gloabalSearchStarted, setGlobalSearchStarted] = useState(false);
  const [updatedSearchValue, setUpdatedSearchValue] = useState("");
  const [sourceAlreadyCalled, setSourceAlreadyCalled] = useState({
    source: "",
    searchValue: "",
  });
  const [options, setOptions] = React.useState([]);
  let [matches, setMatches] = React.useState({});
  let [matchesLength, setMatchesLength] = React.useState(0);
  const [queues, setQueues] = useState({});
  const [defaultInputValue, setDefaultInputValue] = useState("");
  const [isProductListPage, setIsProductListPage] = useState(false);
  const [isPartsLoading, setIsPartsLoading] = React.useState(false);

  // API Call for Part Types by Vehicles
  const apiCall = (term, source, searchTermOrder, terms) => {
    instance
      .post(`${SEARCH_API_WORD}${buildQueryUrl(term)}`, null, {
        cancelToken: source.token,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        const results = resp;

        for (var j = 0; j < results.length; j++) {
          var resultTerm = results[j].name;
          if (matches[resultTerm]) {
            matches[resultTerm].matchCount += searchTermOrder; //earlier words receive the higher order
            if (matches[resultTerm].typeIdList.indexOf(results[j].id) < 0) {
              matches[resultTerm].typeIdList.push(results[j].id);
            }
            if (
              matches[resultTerm].groupNumberList.indexOf(results[j].groupNum) <
              0
            ) {
              matches[resultTerm].groupNumberList.push(results[j].groupNum);
            }
          } else {
            matchesLength++;
            matches[resultTerm] = {
              typeIdList: [results[j].id],
              groupNumberList: [results[j].groupNum],
              term: resultTerm,
              matchCount: searchTermOrder, //earlier words receive the higher order
              score: null,
            };
          }
        }

        for (var matchTerm in matches) {
          var index = 0;
          matches[matchTerm].score =
            matches[matchTerm].matchCount +
            matchTerm
              .toUpperCase()
              .split(/\s/)
              .reverse()
              // eslint-disable-next-line no-loop-func
              .map(function (e) {
                var termIndex = terms.indexOf(e),
                  score;

                if (terms.indexOf(e) < 0) {
                  score = 0;
                } else {
                  score = (termIndex + 1) * ++index;
                }
                return score;
              })
              .reduce((total, e) => total + e, 0);
        }

        let _finalResult = Object.entries(matches).sort(
          (a, b) => b[1].score - a[1].score
        );

        const finalResult = _finalResult.map((f) => f[1]);

        let searchResults = {};
        if (terms.length === 1) {
          searchResults = finalResult;
        } else {
          searchResults = {
            likelyMatches: finalResult.splice(0, 5),
            allMatches: finalResult.splice(0, 20),
          };
        }

        setOptions([{ parts: searchResults }]);

        setIsPartsLoading(false);
      })
      .catch((err) => {
        setIsPartsLoading(false);
      });
  };

  useEffect(() => {
    if (location) {
      if (location.pathname === "/product-list") {
        setIsProductListPage(true);
      } else {
        setIsProductListPage(false);
      }
    }
  }, [location]);

  const handleSearchPromises = {};
  let cancelToken, source;
  useEffect(() => {
    const path = location.pathname;
    if (parts.selectedPartType && sv && path !== "/product-list") {
      setDefaultInputValue(parts.selectedPartType.partTypeDesc);

      let payload = {
        year: sv.year,
        make: sv.makeId,
        model: sv.modelId,
        engine: sv.engineId,
      };

      const query = parts.selectedPartType.partTypeDesc;

      const terms = _.uniq(query?.trim().toUpperCase().split(/\s+/).reverse());

      for (var i = 0; i < terms.length; i++) {
        cancelToken = axios.CancelToken;
        source = cancelToken.source();

        const term = terms[i];
        const searchTermOrder = i + 1;
        payload.searchString = term;

        handleSearchPromises[short.generate()] = source;

        setQueues(handleSearchPromises);
        apiCall(payload, source, searchTermOrder, terms);
      }

      searchInput.current.setState({ text: query });
      searchInput.current.toggleMenu();
    }
  }, [parts.selectedPartType]);

  useEffect(() => {
    const handleSearchError = (query) => {
      const errorData = {
        source: "Part Catalog - Default",
        searchvalue: query,
        resultFound: false,
        selectedVehicle: sv
          ? `${sv?.year} ${sv?.make} ${sv?.model} ${sv?.engine}`
          : null,
        filters: {
          groupCode:
            parts?.specificCondition && parts?.specificCondition.length > 0
              ? parts?.specificCondition[0]?.groupCode
              : null,
          brand: parts?.catalogFilter?.brand,
          position: parts?.catalogFilter?.position,
          availablity: parts?.catalogFilter?.availablity,
        },
      };
      logUserSearchFailureActivity(errorData);
    };

    // Update the local state whenever globalSearch.options changes
    setTimeout(() => {
      const globalSearchResult = hasDataInOptions(globalSearch.options);
      if (
        !globalSearchResult &&
        gloabalSearchStarted &&
        updatedSearchValue === searchFailureValue
      ) {
        handleSearchError(searchFailureValue);
        setGlobalSearchStarted(false);
        setUpdatedSearchValue("");
      }
    }, 2000);
  }, [
    globalSearch.options,
    gloabalSearchStarted,
    searchFailureValue,
    updatedSearchValue,
  ]);

  const hasDataInOptions = (options) => {
    if (options.length > 0 && options[0] && typeof options[0] === "object") {
      const objectAtIndex0 = options[0];
      for (const key in objectAtIndex0) {
        if (objectAtIndex0[key] && objectAtIndex0[key].length > 0) {
          return true; // At least one key-value array has data
        }
      }
    }
    return false; // No key-value array has data
  };
  const handleToggleFavorite = (v, flag) => {
    const updated = {
      ...v,
      favoriteText: "",
      favoriteFlag: flag,
      favoriteUpdatedDate: timeInUTC(),
    };

    dispatch(setFavoriteVehicle(updated));
  };

  const handleVinDetails = () => {
    setShowVinModal(true);
  };

  const onOptionEmpty = (q) => {
    const stockCheck = {
      requestItems: [
        {
          mfgCode: "*",
          partNum: q,
          qty: 1,
        },
      ],
    };
    dispatch(getProductListFromSkuNumber(stockCheck));
  };

  useEffect(() => {
    if (!_isEmpty(sv.vin)) {
      let vinnum = sv.vin;
      const vinInformationData = dispatch(vinSearch({ vin: vinnum }));
      let finalArr = "";
      vinInformationData.then((action) => {
        if (action.payload) {
          const vinData = action.payload;
          finalArr = vinData.vehicleVINInfo.vehicleVINInfoFields;
        }
        setInfoDetails(finalArr);
        return finalArr;
      });
    }
  }, []);

  useEffect(() => {
    if (
      smsUser &&
      !_isEmpty(smsPartDescription) &&
      smsPartDescription.length > 1
    ) {
      handleSearch(smsPartDescription);
      searchInput.current.focus();
      dispatch({
        payload: {
          smsPageLoader: true,
        },
        type: "SMS_PAGE_LOADER",
      });
    } else {
      dispatch({
        payload: {
          smsPageLoader: false,
        },
        type: "SMS_PAGE_LOADER",
      });
    }
  }, [smsPartDescription]);

  const logUserSearchFailureActivity = (storedErrors) => {
    const { x2userName, shipTos, billTo } = userDetails;
    if (!_isEmpty(userDetails) && !_isEmpty(billTo)) {
      let loginId = "";
      let shipToId = shipTos[0]?.shipToId;
      let userData = JSON.stringify(storedErrors);
      let type = "Search - No Result";
      const { searchValue } = sourceAlreadyCalled;
      if (searchValue !== storedErrors.searchvalue) {
        setSourceAlreadyCalled({
          source: storedErrors.source,
          searchValue: storedErrors.searchvalue,
        });
        dispatch(
          logUserStockActivity(
            timeInUTC(),
            billTo.billToId,
            loginId,
            shipToId,
            type,
            userData,
            x2userName
          )
        );
      }
    }
  };

  const reset_matches = () => {
    setMatches({});
    setMatchesLength(0);
  };

  const handleSearch = (query) => {
    setUpdatedSearchValue(query);
    let multipleQuery = query.trim().split(" ");
    multipleQuery = [].concat(query.trim());

    multipleQuery = _.uniq(multipleQuery);

    let validity = initializeValidity();
    validity.globalSearchMaxLimit = isLengthLessthan(query, 100);
    setValidity(validity);

    let payload = {};

    if (sv) {
      reset_matches();
      payload = {
        year: sv.year,
        make: sv.makeId,
        model: sv.modelId,
        engine: sv.engineId,
        searchString: query,
      };

      const terms = _.uniq(query.trim().toUpperCase().split(/\s+/).reverse());

      for (var q in queues) {
        queues[q]?.cancel();

        delete queues[q];
        setQueues(queues);
      }

      for (var i = 0; i < terms.length; i++) {
        cancelToken = axios.CancelToken;
        source = cancelToken.source();

        const term = terms[i];
        const searchTermOrder = i + 1;
        payload.searchString = term;

        handleSearchPromises[short.generate()] = source;

        setQueues(handleSearchPromises);
        apiCall(payload, source, searchTermOrder, terms);
      }
    } else {
      multipleQuery.forEach((q) => {
        payload = { searchKey: q };

        dispatch(search({ payload }))
          .then((results) => {
            const {
              payload: { data },
            } = results;

            setTimeout(() => {
              if (results.payload && results.payload.data) {
                let oValues = Object.values(data);
                oValues = _.flatten(oValues.filter((v) => v));
                if (_.isEmpty(oValues)) {
                  onOptionEmpty(query);
                  setTimeout(() => {
                    // handleSearchError(q);
                    setGlobalSearchStarted(true);
                  }, 2000);
                  setSearchFailure(query);
                }
                // -------------------------------change for iframe integration----------------------
                if (!_isEmpty(smsPartDescription) && smsUser) {
                  // let partDesc = smsPartDescription;
                  let dropDownOptions = results.payload.data.partType;
                  if (dropDownOptions && dropDownOptions.length == 1) {
                    let findedPartType = _head(dropDownOptions);
                    let tempFindedPart = { ...findedPartType };
                    tempFindedPart["type"] = "parts";
                    handleChange([tempFindedPart]);
                    dispatch({
                      payload: {
                        smsPartDesc: null,
                      },
                      type: "SMS_PART_DESCRIPTION",
                    });
                    dispatch({
                      payload: {
                        smsPageLoader: false,
                      },
                      type: "SMS_PAGE_LOADER",
                    });
                  } else if (
                    (dropDownOptions && dropDownOptions.length > 1) ||
                    !smsChemicalPart
                  ) {
                    dispatch({
                      payload: {
                        smsPartDesc: null,
                      },
                      type: "SMS_PART_DESCRIPTION",
                    });
                    dispatch({
                      payload: {
                        smsPageLoader: false,
                      },
                      type: "SMS_PAGE_LOADER",
                    });
                  }
                }
                // ----------------------------------------------------------------------------------
              }
            }, 1000 * 1);
          })
          .catch((err) => {
            if (!_isEmpty(smsPartDescription) && smsUser) {
              dispatch({
                payload: {
                  smsPartDesc: null,
                },
                type: "SMS_PART_DESCRIPTION",
              });
              dispatch({
                payload: {
                  smsPageLoader: false,
                },
                type: "SMS_PAGE_LOADER",
              });
            }
          });
      });
    } // else vehicle is not selected
  };
  const filterBy = (option, { text }) => {
    return ["option"];
  };

  const getGroupFromCategory = (categoryName, groupName) => {
    const category = _.find(
      parts.categoryGroupMap,
      (c) => c.name.toLowerCase() === categoryName.toLowerCase()
    );
    const g = _.find(
      category.groups,
      (g) => g.name.toLowerCase() === groupName.toLowerCase()
    );
    return Object.assign({}, g);
  };

  const handleChange = (selected) => {
    const option = _.head(selected);
    if (!option) return;

    switch (option.type) {
      case "partSkus":
        history.push(
          `/stock-check?partSku=${option.partSku.partNum}&description=${option.option}&mfgCode=${option.partSku.mfgCode}`
        );
        break;
      case "parts":
        history.push("/product-list");

        const groupId = option.groupNumberList?.[0];
        const { group, category: _category } = getCategoryFromGroupId(
          groupId,
          parts.categoryGroupMap
        );

        // Dispatch Selected Group
        group.categoryId = _category.code;

        dispatch({
          payload: {
            selectedGroupDetails: [group],
          },
          type: "GROUP_SELECTED",
        });

        const payload = {
          requestItems: [
            {
              groupCode: groupId,
              specificCondition: [],
            },
          ],
        };

        // store the selected part type for the filter to show in the part type dropdown filter

        dispatch({
          payload: {
            selectedPartType: option,
          },
          type: "SELECTED_PART_TYPE",
        });

        let selecteddata = option || parts.selectedPartType;
        if (option) {
          selecteddata = {
            category: option?.groupAndCategories?.categoryDesc,
            group: option?.groupAndCategories?.groupDesc,
            partTypeDesc: option?.partTypeDesc,
          };
        }

        dispatch(
          getPartsByMultiGroupOrchestrator(
            sv.year,
            sv.makeId,
            sv.modelId,
            sv.engineId,
            payload,
            null,
            null,
            null,
            null,
            {
              exactMatch: true,
              partTypes: [{ id: option.partTypeId || option.typeIdList[0] }],
            },
            null,
            selecteddata,
            false,
            sv
          )
        );
        break;
      case "category":
        const categoryGroups = parts.categoryGroupMap;

        let category = _.filter(
          categoryGroups,
          (c) => c.name === option.categoryDesc
        );
        category = _.head(category);
        dispatch(getGroupsForCategory(category));

        searchInput.current.toggleMenu();

        break;
      case "group":
        history.push("/product-list");
        // Dispatch Selected Group
        let _group = getGroupFromCategory(
          option.categoryDesc,
          option.groupDesc
        );
        const __group = Object.assign(_group, {
          categoryId: +option.categoryId,
        });

        dispatch({
          payload: {
            selectedGroupDetails: [__group],
          },
          type: "GROUP_SELECTED",
        });
        let reqObj = {
          requestItems: [
            {
              groupCode: option.groupId,
              specificCondition: [],
            },
          ],
        };
        dispatch(
          getPartsByMultiGroupOrchestrator(
            sv.year,
            sv.makeId,
            sv.modelId,
            sv.engineId,
            reqObj,
            null,
            null,
            null,
            null,
            {},
            null,
            option || parts.selectedPartType,
            false,
            sv
          )
        );
        break;
      default:
        break;
    }
  };

  const fuzySearch = (options) => {
    let intermOptions = {};

    if (options.length > 0 && searchInput && searchInput.current) {
      const input = searchInput.current.getInput();

      intermOptions = Object.assign({}, _.head(options));
      if (sv) {
        intermOptions.parts = options[0].parts || {};
      } else {
        const fuzzyParts = new Fuse(options[0].parts, {
          keys: ["partTypeDesc"],
          threshold: 0.2,
          includeMatches: true,
          includeScore: true,
          findAllMatches: false,
        });
        let searchedParts = fuzzyParts
          .search(input.value.trim())
          .map((i) => i.item);
        const _parts = options[0].parts || [];
        searchedParts = _.uniqBy(
          _parts.concat(searchedParts),
          (p) => p.partTypeId
        );

        intermOptions.parts = searchedParts;
      }
    }

    return [intermOptions];
  };

  const getGroupLabels = (label) => {
    switch (label) {
      case "partSkus":
        return "Part SKUs - Go To Stock Check";
      case "group":
        return "Groups";
      case "category":
        return "Categories";
      case "parts":
        return "Part Descriptions";
      default:
        break;
    }
  };

  const getValue = (option, type) => {
    switch (type) {
      case "partSkus":
        return option.option;
      case "category":
        return option.categoryDesc;
      case "group":
        return option.groupDesc;
      case "parts":
        return `${option.term || option.partTypeDesc}`;
      default:
        break;
    }
  };

  const getLabel = (option) => {
    return getValue(option, option.type);
  };

  const setOption = (option, type) => {
    let _option = Object.assign({}, option);
    _option.type = type;
    return _option;
  };

  const discard = (obj) => {
    let _obj = Object.assign({}, obj);
    if (obj) {
      delete _obj.states;
      delete _obj.make;
      delete _obj.model;
      delete _obj.vehicles;
    }
    return _obj;
  };

  const onKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      const list = document.querySelectorAll(".dropdown-item");
      const firstItem = list[0];

      firstItem?.focus();
    }
  };

  const handleKeyDown = (e) => {

    const activeElement = e.target;
    const currTarget = e.target;
    const dataset = currTarget.dataset;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      activeElement.nextSibling.focus();
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      activeElement.previousSibling.focus();
    } else if (e.key === "ArrowRight") {
      const currId = Number(dataset.id);
      const parentElement = currTarget.parentElement;

      const height = parentElement.scrollHeight;

      const ITEM_PER_ROW = parseInt(height / ITEM_HEIGHT);
      const nextId = currId + ITEM_PER_ROW;
      const list = parentElement.querySelector(`[data-id="${nextId}"]`);
      if (list) {
        list.focus();
      }
    } else if (e.key === "ArrowLeft") {
      const currId = Number(dataset.id);
      const parentElement = currTarget.parentElement;

      const height = parentElement.scrollHeight;

      const ITEM_PER_ROW = parseInt(height / ITEM_HEIGHT);
      const nextId = currId - ITEM_PER_ROW;
      const list = parentElement.querySelector(`[data-id="${nextId}"]`);
      if (list) {
        list.focus();
      }
    } else if (e.key === "Enter") {
      const option = JSON.parse(dataset?.option);

      handleChange([option]);
    } else if (e.key === "Tab") {
      const parentNode = currTarget.parentNode;
      const grandParent = parentNode.parentNode;
      const grandParentSibling = grandParent.nextSibling;

      if (!grandParentSibling) {
        const greatGrandParent = grandParent.parentNode;
        const firstChild = greatGrandParent.firstChild;

        const item = firstChild?.querySelector(".dropdown-item");
        if (item) {
          item.focus();
        }
      }

      const listItem = grandParentSibling?.querySelector(".dropdown-item");
      if (listItem) {
        listItem.focus();
      }
    }
  };

  const renderMenu = (results, menuProps, state) => {
    if (!results.length)
      return (
        <Menu {...menuProps}>
          <MenuItem>Searching...</MenuItem>
        </Menu>
      );

    if (!globalSearch.loading && false) {
      const top = _.head(results);

      let flag = false;
      const values = Object.values(discard(top));
      for (let i = 0; i < values.length; i++) {
        const t = values[i];
        if (t.length > 0) {
          flag = true;

          break;
        }
      }

      if (!flag) {
        return (
          <Menu {...menuProps}>
            <MenuItem>No Matching results</MenuItem>
          </Menu>
        );
      }
    }

    return (
      <Menu {...menuProps} onKeyDown={handleKeyDown}>
        {results?.map((result, index) => {
          return Object.keys(discard(result))
            .sort()
            .map((label, i) => {
              return (
                (result[label].length > 0 || label === "parts"
                  ? true
                  : false) && (
                  <React.Fragment key={`${i}-main-${label}`}>
                    {i !== 0 && <Menu.Divider />}
                    <Menu.Header>
                      <h6>{getGroupLabels(label)}</h6>
                    </Menu.Header>
                    <React.Fragment>
                      {label === "parts" ? (
                        Array.isArray(result[label]) ? (
                          result[label].length > 0 ? (
                            result[label].map((value, oIndex) => {
                              return (
                                <MenuItem
                                  key={`${index}_${i}_${label}_${oIndex}`}
                                  option={setOption(value, label)}
                                  data-option={JSON.stringify(
                                    setOption(value, label)
                                  )}
                                  data-id={oIndex}
                                >
                                  {getValue(value, label)}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem disabled>No Matches Found</MenuItem>
                          )
                        ) : (
                          <div className="parts-desc">
                            {result[label]["likelyMatches"] &&
                            result[label]["likelyMatches"].length > 0 ? (
                              <React.Fragment>
                                {" "}
                                <div className="parts-title">
                                  Likely Matches
                                </div>
                                {result[label]["likelyMatches"]?.map(
                                  (value, oIndex) => {
                                    return (
                                      <MenuItem
                                        key={`${index}_${i}_${label}_${oIndex}`}
                                        option={setOption(value, label)}
                                        data-option={JSON.stringify(
                                          setOption(value, label)
                                        )}
                                        data-id={oIndex}
                                      >
                                        {getValue(value, label)}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            ) : (
                              <MenuItem disabled>No Matches Found</MenuItem>
                            )}

                            {result[label]["allMatches"] &&
                              result[label]["allMatches"].length > 0 && (
                                <React.Fragment>
                                  <div className="parts-title">All Matches</div>
                                  {result[label]["allMatches"]?.map(
                                    (value, oIndex) => {
                                      return (
                                        <MenuItem
                                          key={`${index}_${i}_${label}_${oIndex}`}
                                          option={setOption(value, label)}
                                          data-option={JSON.stringify(
                                            setOption(value, label)
                                          )}
                                          data-id={oIndex}
                                        >
                                          {getValue(value, label)}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </React.Fragment>
                              )}
                          </div>
                        )
                      ) : (
                        result[label]?.map((value, j) => {
                          return (
                            <MenuItem
                              key={`${label}-${i}-${j}`}
                              option={setOption(value, label)}
                              data-option={JSON.stringify(
                                setOption(value, label)
                              )}
                              data-id={j}
                            >
                              {getValue(value, label)}
                            </MenuItem>
                          );
                        })
                      )}
                    </React.Fragment>
                  </React.Fragment>
                )
              );
            });
        })}
      </Menu>
    );
  };

  return (
    <section
      className={clsx({
        "sticky-breadcrumb": true,
        "gsearch-product-list": isProductListPage,
      })}
    >
      <div className="breadcrumb_section">
        <div className="container-fluid">
          <div
            className={clsx({
              "breadcrumb-wrapper": true,
              "product-list-page": isProductListPage,
            })}
          >
            {!isProductListPage && (
              <div className="custom-breadcrumb">
                <div>
                  <div className="b-info">Selected vehicle: </div>
                  <ul>
                    <li className="selected_vehicle_line">
                      <a
                        href="#"
                        onClick={() => {
                          clearLists([], true);
                        }}
                      >
                        {sv && sv.year} {sv && sv.make} {sv && sv.model}{" "}
                        {sv && sv.engine}
                      </a>
                      {sv && sv.favoriteFlag === "N" && (
                        <FavoriteIcon
                          className="non__favorite favoriteIcon"
                          onClick={() => handleToggleFavorite(sv, "Y")}
                        />
                      )}

                      {sv && sv.favoriteFlag === "Y" && (
                        <img
                          src={favoriteIcon}
                          alt="favorite"
                          className="v__icon action favoriteIcon"
                          onClick={() => handleToggleFavorite(sv, "N")}
                        />
                      )}

                      {!showVinIcon &&
                        !_isEmpty(selectedVin) &&
                        selectedVin.vin && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="button-tooltip">
                                Click to view VIN Info
                              </Tooltip>
                            }
                          >
                            <img
                              src={VIN_Icon}
                              alt="vinIcon"
                              className="v__icon action favoriteIcon"
                              onClick={handleVinDetails}
                            />
                          </OverlayTrigger>
                        )}
                    </li>
                  </ul>
                </div>
                <div>
                  {sv && sv.plate && (
                    <>
                      <div className="b-info">License Plate:</div>
                      <ul>
                        <li className="selected_vehicle_line">
                          <a
                            href="#"
                            onClick={() => {
                              clearLists([], true);
                            }}
                          >
                            {sv && sv.plate}
                          </a>
                        </li>
                      </ul>
                    </>
                  )}

                  {sv && sv.vin && (
                    <>
                      <div className="b-info">Vin:</div>
                      <ul>
                        <li className="selected_vehicle_line">
                          <a
                            href="#"
                            onClick={() => {
                              clearLists([], true);
                            }}
                          >
                            {sv && sv.vin}
                          </a>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </div>
            )}
            {infoDetails ? (
              <XModal
                show={showVinModal}
                title=""
                customHeader={
                  <div className="vin-custom-header">
                    <span>VIN Information</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span>{sv?.vin}</span>
                    <div>
                      <div
                        className="close-btn"
                        onClick={() => setShowVinModal(false)}
                      >
                        <span className="material-icons">close</span>
                      </div>
                    </div>
                  </div>
                }
                className="showvinmodal"
                favoriteTextValidity={true}
                submitType={true}
                handleAction={() => setShowVinModal(false)}
                handleClose={() => setShowVinModal(false)}
                submitButtonText="OK"
              >
                <div className="modal-body_vin">
                  {infoDetails.map((item, index) => (
                    <ItemsRender
                      items={[
                        {
                          title: `${item.header}`,
                          value: `${item.information}`,
                        },
                      ]}
                    />
                  ))}
                </div>
              </XModal>
            ) : null}
            <div className="breadcrumb-input">
              <AsyncTypeahead
                id="category__search"
                useCache={false}
                ref={searchInput}
                filterBy={filterBy}
                labelKey={(option) => getLabel(option)}
                minLength="2"
                inputProps={{ className: "input__default input__search" }}
                isLoading={isPartsLoading || globalSearch.loading}
                delay={DEBOUNCE_RATE}
                onSearch={handleSearch}
                onChange={handleChange}
                options={
                  !globalSearch.loading
                    ? fuzySearch(sv ? options : globalSearch.options || [])
                    : []
                }
                placeholder="Search by Category, Group, Part Description, Part No..."
                renderMenu={renderMenu}
                onKeyDown={onKeyDown}
                defaultInputValue={
                  defaultInputValue
                    ? defaultInputValue
                    : !_isEmpty(smsPartDescription) &&
                      smsPartDescription.length > 1
                    ? smsPartDescription
                    : ""
                }
              />
              {/* <GlobalSearchWrapper placeHolder="Search by Category, Group, Part Description, Part No..."/> */}
              <FormValidationErrorMessage
                condition={!validity.globalSearchMaxLimit}
                errorMessage="Global search key should not exceed more than 100 characters"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default VehicleBreadCrumb;
