import React, { useEffect, useState, Fragment } from 'react';
import _isEmpty from "lodash/isEmpty";

import { Row, Col, Form } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import CarouselElement from './CarouselElement';
import { PlaceHolderImage } from '../../shared/Placeholder/PlaceHolderComponents';
import _findIndex from 'lodash/findIndex';
import { useDispatch, useSelector } from "react-redux";
import _remove from "lodash/remove";
import {
	getImageURLCookieDetails,vehicleSelectPrompt
} from '../../../helpers/utils';
import { triggerCustomUxCriticalError } from "../../../actions/error";
import _ from "lodash"

function CategoryCarousel(props) {
	let {
		category,
		handleClick,
		index,
		// selectedGroupCode,
		// selectedCategoryId,
		selectedGroupDetails,
		subCategories,
		brandsView,
		titleType,
	} = props;
	const dispatch = useDispatch();
	const parts = useSelector((state) => state.parts);
	const [ShowPartTypes , SetShowPartType] =  useState(null);
	const [groupPartMap, setGroupPartMap]  = useState([]);
	const partTypesData  = parts && parts.homePagePartTypes ;
	const selectedPartData = parts && parts.selectedCatalogPartTypes;
	let selectedPart = _.cloneDeep(selectedPartData);
	const sv = useSelector(state => state.mygarage.selectedVehicle);
	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
	const CustomLeftArrow = ({ onClick, ...rest }) => {
		const {
			onMove,
			carouselState: { currentSlide, deviceType },
		} = rest;
		return <a onClick={() => onClick()} className="arrow-left"></a>;
	};
	const CustomRightArrow = ({ onClick, ...rest }) => {
		const {
			onMove,
			carouselState: { currentSlide, deviceType },
		} = rest;
		return <a onClick={() => onClick()} className="arrow-right"></a>;
	};
  const imageURLCookie = getImageURLCookieDetails();


    const selectAllGroups = (category, e, brandsView) => {
		const isChecked = e.target.checked;
		vehicleSelectPrompt(isChecked, dispatch, sv, triggerCustomUxCriticalError);
		let singleCategoryGroups = singleCategory;
		singleCategoryGroups.enableSelectAll = isChecked;
		let specificGroup = brandsView && (subCategories && subCategories.some(value => value.groupSelect && value.groupSelect === true)) ? "chemicalCategories" : "groups"
		setSingleCategoriesGroupMap(singleCategoryGroups);
		singleCategoryGroups[specificGroup].map((group, index) => {
			let groupIndex;
			if (category.canSelect) {
				groupIndex = _findIndex(selectedGroupDetails, {
					code: Number(group.code),
				});
			}
			if (category.canSelectChemical) {
				groupIndex = _findIndex(selectedGroupDetails, {
					id: Number(group.id),
				});
			}
			if (group && group.groupSelect) {
				let matchingGroup = selectedGroupDetails.filter((item) => {
					return (category.supplier.code === item.brandCode) && (item.pimId == group.pimID)
				})
				groupIndex = matchingGroup.length == 0 ? -1 : 0;
			}
            if (isChecked) {
				if (groupIndex === -1) {
					handleClick(e, group, category);
				}
			} else {
				if (groupIndex !== -1) {
					handleClick(e, group, category);
				}
			}
     	});
		 setSingleCategoriesGroupMap(singleCategoryGroups);
	};

	const [singleCategory, setSingleCategoriesGroupMap] = useState([]);
	useEffect(() => {
		let singleCategoryGroups = { ...category, enableSelectAll: false }
		setSingleCategoriesGroupMap(singleCategoryGroups);
	}, [category]);

	const handleCustomClick = (e, data, category) => {
		let matchingCategory = [];
		let singleCategoryGroups;
		matchingCategory = selectedGroupDetails.filter((item) => {
			return item.categoryId == category.code;
		})
		if(data.groupSelect){
			matchingCategory = selectedGroupDetails.filter((item) => {
			  return item.brandCode == category.supplier.code;
			})
		}
		singleCategoryGroups = singleCategory;
		let categoryLength = data.groupSelect ? category.chemicalCategories.length : category.groups.length;
        if ((matchingCategory.length == categoryLength) || (matchingCategory.length >= (categoryLength - 1))) {
			let selectedIndex;
			if (category.canSelect) {
				selectedIndex = _findIndex(matchingCategory, {
					code: data.code,
				});
			}
			if (category.canSelectChemical) {
				selectedIndex = _findIndex(matchingCategory, {
					id: data.id,
				});
			}
			if(data.groupSelect){
				selectedIndex = _findIndex(matchingCategory, {
					pimId: data.pimID,
				});
			  }
			singleCategoryGroups.enableSelectAll = selectedIndex === -1 ? true : false;
		}
		let groupIndex=   selectedGroupDetails && selectedGroupDetails.findIndex((h) => h.code === data.code);
        let checked = (groupIndex >= 0 ) ? false : true;
		vehicleSelectPrompt(checked, dispatch, sv, triggerCustomUxCriticalError);

		setSingleCategoriesGroupMap(singleCategoryGroups);
		handleClick(e, data, category);
	}

	const renderCarouselelements = () => {
		let carouselElements = [];
		if (category && subCategories && subCategories.length) {
			subCategories.forEach((group, key) => {
				carouselElements.push(
					<CarouselElement
						group={group}
						handleClick={handleCustomClick}
						// selectedGroupCode={selectedGroupCode}
						// selectedCategoryId={selectedCategoryId}
						selectedGroupDetails={selectedGroupDetails}
						category={category}
						key={key}
					/>
				);
			});
		}
		return carouselElements;
	};
	return (
		<Row className="py-4 product-row">
			<Col sm={3} className="product-category p-0">
				{index === 0 && (
					<h3 className={brandsView === undefined ? "text-uppercase mb-4" : "text-uppercase brand_name mb-4"}>
						{brandsView ? 'Brands' : titleType === 'homecategory' ? 'Product Categories' : 'Categories'}
					</h3>
				)}
				<div className="product-category__wrapper">
					<div className="product-category__wrapper--inner">
						<div className="product-category__img">
							{
								<img
									src={brandsView ?  (category.supplier.imageUrl ? `${category.supplier.imageUrl}?${imageURLCookie}` : '/noImage.jpg') : (category.imageUrl ? `${category.imageUrl}?${imageURLCookie}` : '/noImage.jpg')}
									alt={brandsView ? category.supplier.name : "category-name"}
								></img>
							}
						</div>
						<div className="product-category__title">
							{brandsView
								? category.supplier.imageUrl ? '' : category.supplier.name
								: category.name}
						</div>
					</div>
				</div>
			</Col>
			<Col sm={9}>
				{index === 0 && (
					<h3 className="text-center text-uppercase mb-4">
						{brandsView ? 'Categories' : 'Groups'}
					</h3>
				)}
				{!_isEmpty(subCategories) ? (
					<Fragment>
						{(category.canSelect || category.canSelectChemical || (subCategories && subCategories.some(value => value.groupSelect && value.groupSelect === true)))  && (<Form.Check className="text-center center-select" type="checkbox"
							checked= {
								singleCategory && singleCategory.enableSelectAll
							}
							onChange={(e) => {
								selectAllGroups(category, e , brandsView);
							}} label="Select All" />)}
					<Carousel
						swipeable={false}
						draggable={false}
						showDots={false}
						responsive={responsive}
						infinite={true}
						autoPlaySpeed={1000}
						keyBoardControl={true}
						customLeftArrow={<CustomLeftArrow />}
						customRightArrow={<CustomRightArrow />}
						customTransition="all .5"
						transitionDuration={500}
						containerClass="carousel-container"
						dotListClass="custom-dot-list-style"
						itemClass="carousel-item-padding-40-px"
						shouldResetAutoplay={false}
					>
						{renderCarouselelements()}
					</Carousel>
					</Fragment>
				) : (
						<div className="placeholder-homepage">
							<PlaceHolderImage />
							<PlaceHolderImage />
						</div>
					)}
			</Col>
		</Row>
	);
}
export default CategoryCarousel;
