import React from 'react';
import { Container, Row, Spinner, Form, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import storage from '../../helpers/storage';

async function resendConfirmationCode(username) {
	try {
		await Auth.resendSignUp(username);
		console.log('code resent succesfully');
	} catch (err) {
		console.log('error resending code: ', err);
	}
}

class VerifyEmail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			attributes: {
				userEmail: storage.get('userEmail', 'local'),
				userName: storage.get('userEmail', 'local'),
				verificationCode: '',
			},
			redirectToLogin: false,
			userApiCall: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.userConfirmSignUp = this.userConfirmSignUp.bind(this);
		this.resendVerificationCode = this.resendVerificationCode.bind(this);
	}

	userConfirmSignUp(username, code) {
		Auth.confirmSignUp(username, code)
			.then((user) => {
				console.log(user);
				console.log('user confirmed redirecting to login');

				this.setState({
					redirectToLogin: true,
					userApiCall: false,
				});
			})
			.catch((error) => {
				this.setState({ userApiCall: false });
				console.log('error confirming sign up', error);
			});
	}

	handleInputChange(event, data) {
		event.preventDefault();
		let {
			target: { value, name },
		} = event;
		const { attributes } = this.state;
		attributes[name] = value;
		this.setState({
			attributes,
		});
	}

	handleSubmit(event) {
		event.preventDefault();
		const {
			attributes: { userName, verificationCode },
		} = this.state;
		this.setState({ userApiCall: true });
		this.userConfirmSignUp(userName, verificationCode);
	}

	resendVerificationCode() {
		const {
			attributes: { userName },
		} = this.state;
		resendConfirmationCode(userName);
	}

	render() {
		const {
			attributes: { userEmail, verificationCode },
            redirectToLogin,
            userApiCall
		} = this.state;
		if (redirectToLogin) {
			return <Redirect to="/email-verified" />;
		}
		return (
			<Container fluid className="login_container">
				<Row className="login_title">
					<span>Let’s Get Started!</span>
				</Row>
				<Row className="login_box">
					<span>Please Enter your verification code below</span>
					<div>
						<Form
							className="login_form"
							onSubmit={this.handleSubmit}
						>
							<div className="returning_customer_container">
								<div className="input_fields">
									<Form.Control
										size="sm"
										type="text"
										name="userEmail"
										value={userEmail}
										disabled={true}
										onChange={this.handleInputChange}
										placeholder="Your User ID*"
									/>
									<Form.Control
										size="sm"
										type="text"
										name="verificationCode"
										value={verificationCode}
										onChange={this.handleInputChange}
										placeholder="Please enter verification code*"
									/>
								</div>
								{userApiCall ? (
									<Button
										className="xlp-submit-btn"
										variant="primary"
										disabled
									>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
										Loading..
									</Button>
								) : (
									<Button
										className="xlp-submit-btn"
										variant="primary"
										type="submit"
									>
										Verify Email
									</Button>
								)}
								<p>
									Lost the verification code?{' '}
									<span
										onClick={this.resendVerificationCode}
										style={{ cursor: 'pointer' }}
										title="Click here"
									>
										Click here to resend
									</span>
								</p>
							</div>
						</Form>
					</div>
				</Row>
			</Container>
		);
	}
}
export default VerifyEmail;
