/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, MenuItem, ListItem, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "ui-library";
import XModal from "../shared/XModal";
import AddressForm from "./AddressForm";
import { getShippingAddress } from "../../actions/cart";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import deleteIcon from "../../static/images/deleteIcon.png";
import {deleteAddress} from "../../actions/cart";
import './styles.scss';
import storage from "../../helpers/storage";
import { triggerCustomUxCriticalError } from "../../actions/error";

const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const NewShipAddress = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showAddNew, setShowAddNew] = useState(false);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.savedAddressDeatils);
  const [addressLists, setAddressLists] = useState([]);
  const [newAdressInfo, setNewAddressInfo] = useState({isAddedNew: false, isSaveAddress: false, row: {} });
  const shipmentAddress = useSelector((state) => state.user.userDetails);
  let shipment = shipmentAddress.shipTos[0].shipmentAddress;
  let customerId = shipmentAddress.billTo.billToId;
  const [selectedId, setSelectedId] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [deleteAddressPopup,setdeleteAddressPopup] = useState(false);
  const [addressIdToDel,setAddressIdToDel] = useState("");
  const [isEdit,setIsEdit] = useState(false);
  const selectedAddressId = useSelector(
    (state) => state.cart.selectedAddressId
  );

  useEffect(() => {
    if (_isEmpty(newAdressInfo)) return;

    const { isSaveAddress, isAddedNew, row } = newAdressInfo;

    if (!isSaveAddress && isAddedNew && !_isEmpty(row)) {
      setNewAddressInfo({ isAddedNew: false, isSaveAddress: false, row: {} });
      const newRow = { ...row, id: generateRandomId() };
      handleLocalStorage([newRow]);
      checkLocalAddress();
    }
  }, [newAdressInfo]);

  const checkLocalAddress = () => {
    const localDeliveryAddresses = storage.get("deliveryaddressLists", "local");
    if (localDeliveryAddresses !== "undefined" && !_isEmpty(localDeliveryAddresses)) {
      const parsedLocalAddresses = JSON.parse(localDeliveryAddresses);
      const mergedAddresses = _isEmpty(cart) ? parsedLocalAddresses : mergeUniqueAddresses(cart, parsedLocalAddresses);
      setDefaultAddress(parsedLocalAddresses[0]);
      setAddressLists(mergedAddresses);
    }
  }

  useEffect(() => {

    const localDeliveryAddresses = storage.get("deliveryaddressLists", "local");

    if (localDeliveryAddresses !== "undefined" && !_isEmpty(localDeliveryAddresses)) {
      checkLocalAddress();
    } else {
      setAddressLists(cart);
    }
  },  [cart]);


  useEffect(() => {
    setSelectedId(selectedAddressId);
    const savedAddress = cart.find((s) => s.id === selectedAddressId);
    if (savedAddress) {
      setDefaultAddress(savedAddress);
    }
  }, [selectedAddressId, cart]);

  const setDefaultAddress = (savedAddress) => {
    if(!_isEmpty(savedAddress)) {
      const joinedAddress = `${savedAddress.companyName} - ${savedAddress.contactPersonFirstName} ${savedAddress.contactPersonLastName} ${savedAddress.streetAddress} ${savedAddress.city} ${savedAddress.state} ${savedAddress.country}  ${savedAddress.zipCode}=${savedAddress.id}`;
      setSelectedValue(joinedAddress);
    }
  }

  const generateRandomId = () => Math.floor(Math.random() * 1000000);


  const mergeUniqueAddresses = (cart, localAddresses) => {
    const combined = [...cart, ...localAddresses];
    const addressMap = new Map();
  
    combined.forEach(address => {
      addressMap.set(address.id, address);
    });
  
    return Array.from(addressMap.values());
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewAddress = () => {
    const localDeliveryAddresses = storage.get("deliveryaddressLists", "local");
    if (localDeliveryAddresses !== "undefined" && !_isEmpty(localDeliveryAddresses)) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    setShowAddNew(true);
    setAnchorEl(null);
  };

  const getAllDetails = () => {
    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });

    getShippingAddress(customerId, dispatch);

    dispatch({
      payload: {
        globalLoader: false,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
  };

  useEffect(() => {
    if (customerId) {
      getAllDetails();
    }
  }, [selectedId, customerId]);

  const handleMenuItemClick = async (event, item) => {
    if (!_isEmpty(event.currentTarget.innerText)) {
      let timeArr = event.currentTarget.innerText.split("=");
      setSelectedValue(event.currentTarget.innerText);
      setSelectedId(timeArr[1]);
      let cartTempDeliveryAddress = {};
      let addressId = timeArr[1];
      
      const idToDelete = cart.find((s) => s.id === Number(timeArr[1]));
      
      if (!idToDelete) {
        const addressToFind = addressLists.find((s) => s.id === Number(timeArr[1]));
        
        if (addressToFind) {
          let tempDeliveryAddressPayload = {
            companyName: addressToFind?.companyName,
            person: {
              firstName: addressToFind?.contactPersonFirstName,
              middleName: "",
              lastName: addressToFind?.contactPersonLastName,
              phone: "",
              fax: "",
              email: shipmentAddress?.x2userName
            },
            address: {
              street: addressToFind?.streetAddress,
              city: addressToFind?.city,
              state: addressToFind?.state,
              country: "USA",
              postalID: addressToFind?.zipCode
            }
          };
          cartTempDeliveryAddress = { ...tempDeliveryAddressPayload };
        }
        
        addressId = "";
      }
      dispatch({
        payload: {
          selectedAddressId: addressId,
          cartTempDeliveryAddress: cartTempDeliveryAddress
        },
        type: "SELECTED_ADDRESS_ID",
      });

     setAnchorEl(null);
    }
  };

  const handleLocalStorage = (list) => {
    storage.unset('deliveryaddressLists', 'local');
    storage.set("deliveryaddressLists", list || {}, "local");
  }

  const handleDeletionAddress = () =>{
    const idToDelete = cart.find((s) => s.id === addressIdToDel);
    const updatedList = addressLists.filter((s) => s.id !== addressIdToDel);
    setAddressLists(updatedList);
    if (!idToDelete) {
      const addressDeleted = {
        heading: `address is deleted`,
        message: "",
        type: "success",
      };
      const localDeliveryAddresses = JSON.parse(storage.get("deliveryaddressLists", "local"));
      const updatedList = localDeliveryAddresses && localDeliveryAddresses.filter((s) => s.id !== addressIdToDel);
      handleLocalStorage(updatedList);
      triggerCustomUxCriticalError(addressDeleted, dispatch);
    } else {
        deleteAddress(addressIdToDel, customerId, dispatch);
    } 
    setdeleteAddressPopup(false)
    setSelectedValue("");
  };
  

const handleCancel = () => {
  setShowAddNew(false);
  setNewAddressInfo({isAddedNew: false, isSaveAddress: false, row: {} })
}

  return (
    <React.Fragment>
      {showAddNew && (
        <XModal
          className="custom_xmodal_shipment"
          title="Delivery Address"
          show={showAddNew}
          showButtons={false}
          handleAction={() => setShowAddNew(false)}
          handleClose={() => setShowAddNew(false)}
        >
          <AddressForm handleCancel={() => handleCancel()} setNewAddressInfo={setNewAddressInfo} editableAddress={isEdit}  localAddress={JSON.parse(storage.get("deliveryaddressLists", "local"))} />
        </XModal>
      )}
      {shipment === true && (
        <Button
          variant="outlined"
          size="small"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <ListItemText
            primary="Delivery Address"
            secondary={selectedValue}
            className="menu-list"
          />
          <span class="material-icons">keyboard_arrow_down</span>
        </Button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Button onClick={handleNewAddress}>
          <span class="material-icons">add</span>Add / edit <span class="material-icons">edit</span>Address
        </Button>

        {!_isEmpty(addressLists) &&
          addressLists.map((item, i) => {
            return (
              <MenuItem
                selected={item.id === selectedId}
                onClick={(event) => handleMenuItemClick(event, item)}
                key={i}
              >
                <img
                  src={deleteIcon}
                  className="address_delete-icon"
                  onClick={(event) => {
                    event.stopPropagation(); // Prevents the menu item click event from triggering
                    setdeleteAddressPopup(true);
                    setAnchorEl(null);
                    setAddressIdToDel(item.id);
                  }}
                  alt="Delete"
                />
                <div className="menu-list" key={`${item.customerId}-${i}`}>
                  {item.companyName}&nbsp;-{item.contactPersonFirstName} {item.contactPersonLastName}
                  &nbsp;- &nbsp;
                  {item.streetAddress} &nbsp;
                  {item.state} &nbsp;{item.city} &nbsp; {item.country} &nbsp;
                  {item.zipCode}
                  &nbsp;={item.id}
                </div>
              </MenuItem>
            );            
          })}

      </Menu>
      {deleteAddressPopup && (
            <XModal
              show = {deleteAddressPopup}
              showButtons = {true}
              className="custom_xmodal"
              handleClose= {()=>setdeleteAddressPopup(false)}
            	yesButtonText="Yes"
						  noButtonText="No"
              handleAction={()=>handleDeletionAddress()}
              isValidValidity={true}
            >
              <div className="deletion_heading">
                <span className="deletion_text">
                Please confirm deletion of Address
                </span>
              </div>
             
            </XModal>
            
          )}
    </React.Fragment>
  );
};

export default NewShipAddress;
