/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Tab, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  numbersOnlyRegExp,
  alphaCharRegExp,
  populateSearchDropdownOptions,
} from "../../helpers/utils";

import "./styles.scss";
import PopperMenuList from "./MenuList";

const ITEM_HEIGHT = 31.2;
const ITEM_PER_ROW = 12;
const MENU_PADDING = 16;

const YmmeDropDowns = (props) => {
  const { onSelectChange, selectedVehicle } = props;

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [showYearMenu, setShowYearMenu] = useState(false);
  const [showMakeMenu, setShowMakeMenu] = useState(false);
  const [showModelMenu, setShowModelMenu] = useState(false);
  const [showEngineMenu, setShowEngineMenu] = useState(false);

  const [selectInput, setSelectInput] = useState();
  const search = useSelector((state) => state.search);
  const smsUser = useSelector((state) => state.app.smsUser);

  const _yearList = populateSearchDropdownOptions(search.yearListAll);
  const _makeList = populateSearchDropdownOptions(search.makesListForYear);
  const _modelList = populateSearchDropdownOptions(
    search.modelListForYearAndMake
  );
  const _engineList = populateSearchDropdownOptions(
    search.engineForYearMakeModel
  );

  const [yearListSearched, setYearListSearched] = useState(null);
  const [makeListSearched, setMakeListSearched] = useState(null);
  const [modelListSearched, setModelListSearched] = useState(null);
  const [engineListSearched, setEngineListSearched] = useState(null);

  const [engineSelected, setEngineSelected] = useState(false);

  const [yearValue, setYearValue] = useState(null);
  const [makeValue, setMakeValue] = useState(null);
  const [modelValue, setModelValue] = useState(null);
  const [engineValue, setEngineValue] = useState(null);

  const [outsideClicked, setOutsideClicked] = useState(false);
  const [openYear, setOpenYear] = useState(false);
  const [openMake, setOpenMake] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openEngine, setOpenEngine] = useState(false);

  const yearRef = useRef([]);
  const makeRef = useRef([]);
  const modelRef = useRef([]);
  const engineRef = useRef([]);
  const ymmeMenuRef = useRef(null);
  const [anchorRef, setAnchorRef] = React.useState(null);
  const anchorRefYear = React.useRef(null);
  const anchorRefMake = React.useRef(null);
  const anchorRefModal = React.useRef(null);
  const anchorRefEngine = React.useRef(null);

  const menuListRef = React.useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowYearMenu(false);
          setShowMakeMenu(false);
          setShowModelMenu(false);
          setShowEngineMenu(false);
          setOutsideClicked(true);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (!smsUser) {
      // yearRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setYearListSearched(_yearList);
    setMakeListSearched(_makeList);
    setModelListSearched(_modelList);
    setEngineListSearched(_engineList);

    if (
      _engineList &&
      _engineList.length === 1 &&
      !engineSelected &&
      selectedVehicle &&
      !selectedVehicle.engineId
    ) {
      onSelectChange(_engineList[0], {
        action: "select-option",
        name: "engine",
        option: undefined,
      });
      setEngineSelected(true);
    }
    if (outsideClicked) {
      handleOutsideClicked();
    }
  }, [search, outsideClicked]);

  const splitArrayEqually = (array, size) => {
    const items = array;
    const n = size;
    const result = [];
    for (let i = 0; i < size; i++) {
      result.push([]);
    }

    const wordsPerLine = Math.ceil(items.length / size);

    for (let line = 0; line < n; line++) {
      for (let i = 0; i < wordsPerLine; i++) {
        const value = items[i + line * wordsPerLine];
        if (!value) continue; //avoid adding "undefined" values
        result[line].push(value);
      }
    }
    return result;
  };

  const handleOutsideClicked = () => {
    if (selectedVehicle.year !== yearValue) {
      setYearValue(selectedVehicle.year);
    }
    if (selectedVehicle.make !== makeValue) {
      setMakeValue(selectedVehicle.make);
    }
    if (selectedVehicle.model !== modelValue) {
      setModelValue(selectedVehicle.model);
    }
    if (selectedVehicle.engine !== engineValue) {
      setEngineValue(selectedVehicle.engine);
    }

    setShowYearMenu(false);
    setShowMakeMenu(false);
    setShowModelMenu(false);
    setShowEngineMenu(false);

    setOutsideClicked(false);
  };

  const toggleYmmeMenu = (dropDownName) => {
    if (selectedVehicle.year !== yearValue) {
      setYearValue(selectedVehicle.year);
    }
    if (selectedVehicle.make !== makeValue) {
      setMakeValue(selectedVehicle.make);
    }
    if (selectedVehicle.model !== modelValue) {
      setModelValue(selectedVehicle.model);
    }
    if (selectedVehicle.engine !== engineValue) {
      setEngineValue(selectedVehicle.engine);
    }

    switch (dropDownName) {
      case "year":
        setShowYearMenu(!showYearMenu);
        setShowMakeMenu(false);
        setShowModelMenu(false);
        setShowEngineMenu(false);

        setAnchorRef(anchorRefYear);
        setOpenYear(true);
        break;
      case "make":
        setShowYearMenu(false);
        setShowMakeMenu(!showMakeMenu);
        setShowModelMenu(false);
        setShowEngineMenu(false);

        setAnchorRef(anchorRefMake);
        setOpenMake(true);
        break;
      case "model":
        setShowYearMenu(false);
        setShowMakeMenu(false);
        setShowModelMenu(!showModelMenu);
        setShowEngineMenu(false);

        setAnchorRef(anchorRefModal);
        setOpenModel(true);
        break;
      case "engine":
        setShowYearMenu(false);
        setShowMakeMenu(false);
        setShowModelMenu(false);
        setShowEngineMenu(!showEngineMenu);

        setAnchorRef(anchorRefEngine);
        setOpenEngine(true);
        break;
      default:
        break;
    }
  };

  const handleMenuItemClicked = (e, option, dropDownName) => {
    console.log(option, dropDownName);

    let data = {
      action: "select-option",
      option: undefined,
      name: dropDownName,
    };

    onSelectChange(option, data);
    switch (dropDownName) {
      case "year":
        setYearValue(option.label);
        setMakeValue("");
        setModelValue("");
        setEngineValue("");

        setOpenYear(!openYear);
        break;
      case "make":
        setMakeValue(option.label);
        setModelValue("");
        setEngineValue("");

        setOpenMake(!openMake);
        break;
      case "model":
        setModelValue(option.label);
        setEngineValue("");

        setOpenModel(!openModel);
        break;
      case "engine":
        setEngineValue(option.label);
        setEngineSelected(true);

        setOpenEngine(!openEngine);
        break;
      default:
        break;
    }

    setTimeout(() => {
      autoOpenMenu(dropDownName);
    }, 500);
  };

  const autoOpenMenu = (dropDownName) => {
    switch (dropDownName) {
      case "year":
        setShowYearMenu(!showYearMenu);
        setShowMakeMenu(true);
        makeRef.current.focus();
        break;
      case "make":
        setShowMakeMenu(!showMakeMenu);
        setShowModelMenu(true);
        modelRef.current.focus();
        break;
      case "model":
        setShowModelMenu(!showModelMenu);
        setShowEngineMenu(true);
        engineRef.current.focus();
        break;
      case "engine":
        setShowEngineMenu(!showEngineMenu);
        break;
      default:
        break;
    }
  };

  const handleKeyDown = (event, name) => {
    const key = event.key;
    let charCode = String.fromCharCode(event.which).toLowerCase();
    let charCodeValidation = !(
      (event.ctrlKey === true && charCode === "c") ||
      (event.ctrlKey === true && charCode === "a") ||
      (event.ctrlKey === true && charCode === "v")
    );

    switch (key) {
      case "ArrowDown":
        event.preventDefault();
        const li = document.querySelectorAll(
          "#composition-menu.MuiList-root .MuiMenuItem-root"
        );

        if (li) {
          const firstLi = li[0];

          if (firstLi) {
            firstLi.focus();
          }
        }
        break;
      default:
        break;
    }

    switch (name) {
      case "year":
        if (
          (numbersOnlyRegExp(event.key) === false ||
            (selectInput && selectInput.length >= 4)) &&
          handleOtherKeys(event.keyCode) &&
          charCodeValidation
        ) {
          event.preventDefault();
        }

        switch (key) {
          case "ArrowDown":
            setOpenYear(true);
            break;
          case "Enter":
            handleMenuItemClicked(event, yearListSearched[0], "year");
            break;
          default:
            break;
        }

        break;
      case "make":
        if (
          (alphaCharRegExp(event.key) === false ||
            (selectInput && selectInput.length >= 25)) &&
          (handleOtherKeys(event.keyCode) || _makeList === null)
        ) {
          event.preventDefault();
        }
        if (event.key === "Enter") {
          handleMenuItemClicked(event, makeListSearched[0], "make");
        } else if (event.key === "ArrowDown") {
        }
        break;
      case "model":
        if (
          selectInput &&
          selectInput.length >= 15 &&
          (handleOtherKeys(event.keyCode) || _modelList === null)
        ) {
          event.preventDefault();
        }
        if (event.key === "Enter") {
          handleMenuItemClicked(event, modelListSearched[0], "model");
        }
        break;
      case "engine":
        if (
          selectInput &&
          selectInput.length >= 15 &&
          (handleOtherKeys(event.keyCode) || _engineList === null)
        ) {
          event.preventDefault();
        }
        if (event.key === "Enter") {
          handleMenuItemClicked(event, engineListSearched[0], "engine");
        }
        break;
      default:
        break;
    }
  };

  const handleInputChange = (inputValue, menuArray, dropDownName) => {
    setSelectInput(inputValue);
    let filteredMenuArray = null;
    if (menuArray !== null) {
      filteredMenuArray = searchMatch(menuArray, inputValue);
    }

    switch (dropDownName) {
      case "year":
        setShowYearMenu(true);
        setYearValue(inputValue);
        setYearListSearched(filteredMenuArray);
        break;
      case "make":
        setShowMakeMenu(true);
        setMakeValue(inputValue);
        setMakeListSearched(filteredMenuArray);
        break;
      case "model":
        setShowModelMenu(true);
        setModelValue(inputValue);
        setModelListSearched(filteredMenuArray);
        break;
      case "engine":
        setShowEngineMenu(true);
        setEngineValue(inputValue);
        setEngineListSearched(filteredMenuArray);
        break;
      default:
        break;
    }
  };

  const searchMatch = (array, text) => {
    if (array !== null) {
      text = text.toLowerCase();
      return array.filter(function (o) {
        return ["label"].some(function (k) {
          return o[k].toString().toLowerCase().indexOf(text) !== -1;
        });
      });
    }
  };

  const handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46 &&
      code !== 17
    );
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenYear(false);
      setOpenMake(false);
      setOpenModel(false);
      setOpenEngine(false);
    } else if (event.key === "Escape") {
      setOpenYear(false);
      setOpenMake(false);
      setOpenModel(false);
      setOpenEngine(false);
    } else if (event.key === "ArrowRight") {
      const currElement = event.target;
      const parentElement = currElement.parentElement;
      const dataset = currElement.dataset;
      const id = Number(dataset.id);

      const currE = menuListRef.current || parentElement;

      const nextId = id + ITEM_PER_ROW;
      const list = currE.querySelector(`[data-id="${nextId}"]`);

      if (list) {
        list.focus();
      }
    } else if (event.key === "ArrowLeft") {
      const currElement = event.target;
      const parentElement = currElement.parentElement;
      const dataset = currElement.dataset;
      const id = Number(dataset.id);

      const currE = menuListRef.current || parentElement;

      const nextId = id - ITEM_PER_ROW;
      const list = currE.querySelector(`[data-id="${nextId}"]`);

      if (list) {
        list.focus();
      }
    } else if (event.key === "Enter") {
      console.log("enter key ==> ");
    }
  }

  const handleCloseYear = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenYear(false);
  };

  const handleCloseMake = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMake(false);
  };

  const handleCloseModel = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenModel(false);
  };

  const handleCloseEngine = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenEngine(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpenYear = React.useRef(openYear);
  React.useEffect(() => {
    if (prevOpenYear.current === true && openYear === false) {
      anchorRef.current.focus();
    }

    prevOpenYear.current = openYear;
  }, [openYear]);

  const prevOpenMake = React.useRef(openMake);
  React.useEffect(() => {
    if (prevOpenMake.current === true && openMake === false) {
      anchorRef.current.focus();
    }

    prevOpenMake.current = openMake;
  }, [openMake]);

  const prevOpenModel = React.useRef(openModel);
  React.useEffect(() => {
    if (prevOpenModel.current === true && openModel === false) {
      anchorRef.current.focus();
    }

    prevOpenModel.current = openModel;
  }, [openModel]);

  const prevOpenEngine = React.useRef(openEngine);
  React.useEffect(() => {
    if (prevOpenEngine.current === true && openEngine === false) {
      anchorRef.current.focus();
    }

    prevOpenEngine.current = openEngine;
  }, [openEngine]);

  const handleFocus = (ymmeId) => {
    switch (ymmeId) {
      case "year":
        setOpenMake(false);
        setOpenModel(false);
        setOpenEngine(false);
        toggleYmmeMenu(ymmeId);
        break;
      case "make":
        setOpenYear(false);
        setOpenModel(false);
        setOpenEngine(false);
        toggleYmmeMenu(ymmeId);
        break;
      case "model":
        setOpenYear(false);
        setOpenMake(false);
        setOpenEngine(false);
        toggleYmmeMenu(ymmeId);
        break;
      case "engine":
        setOpenYear(false);
        setOpenMake(false);
        setOpenModel(false);
        toggleYmmeMenu(ymmeId);
        break;
      default:
        break;
    }
  };

  return (
    <Tab.Pane eventKey="YMMET">
      <Row className="justify-content-center ymmet-wrapper">
        <Col className="select-container">
          <nav class="navbar-expand-md navbar-light main" ref={wrapperRef}>
            <button
              class="navbar-toggler"
              data-target="#navbarSupportedContent"
              data-toggle="collapse"
              type="button"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav navbar-nav-ymme">
                <li class="nav-item ymme-nav-item">
                  <div
                    class="nav-ymme"
                    data-toggle="dropdown"
                    id="navbarDropdown"
                    role="button"
                    ref={anchorRefYear}
                  >
                    <input
                      type="text"
                      className={
                        showYearMenu
                          ? "ymme-menu-item-input ymme-menu-focus-on"
                          : "ymme-menu-item-input"
                      }
                      placeholder="Year"
                      onChange={(e) => {
                        handleInputChange(e.target.value, _yearList, "year");
                      }}
                      onKeyDown={(event) => {
                        handleKeyDown(event, "year");
                      }}
                      defaultValue={selectedVehicle.year}
                      ref={yearRef}
                      value={yearValue}
                      onBlur={(e) => handleKeyDown(e, "year")}
                      onFocus={() => handleFocus("year")}
                    />
                    <div
                      class=" css-1hb7zxy-IndicatorsContainer down-arrow"
                      onClick={() => handleFocus("year")}
                    >
                      <div
                        aria-hidden="true"
                        class=" css-tlfecz-indicatorContainer"
                      >
                        {showYearMenu ? (
                          <i
                            class="fa fa-angle-up ymmeArrow"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            class="fa fa-angle-down ymmeArrow"
                            aria-hidden="true"
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <PopperMenuList
                    list={yearListSearched || []}
                    anchorRef={anchorRefYear}
                    open={openYear}
                    handleListKeyDown={handleListKeyDown}
                    handleMenuItemClicked={handleMenuItemClicked}
                    handleClose={handleCloseYear}
                    id="year"
                    paperStyles={{
                      maxHeight: `${
                        ITEM_HEIGHT * ITEM_PER_ROW + MENU_PADDING
                      }px`,
                    }}
                    menuStyles={{
                      maxHeight: `${
                        ITEM_HEIGHT * ITEM_PER_ROW + MENU_PADDING
                      }px`,
                    }}
                    menuListRef={menuListRef}
                    selectedValue={yearValue}
                  />
                  <ul
                    ref={ymmeMenuRef}
                    className={
                      showYearMenu && false
                        ? "dropdown-menu megamenu show yearDropdown"
                        : "dropdown-menu megamenu"
                    }
                  >
                    {yearListSearched && yearListSearched.length === 0 && (
                      <div class="row">
                        <li class="menu-no-option">No Options</li>
                      </div>
                    )}
                    {yearListSearched && yearListSearched.length > 0 && (
                      <div class="row">
                        <li class="ymme-columns ymme_year hover-on-first">
                          <ul className="menu-single-row">
                            {splitArrayEqually(yearListSearched, 6)[0].map(
                              (year, index) => {
                                return (
                                  <li
                                    tabIndex={index}
                                    className={
                                      selectedVehicle.year === year.label
                                        ? "selectedVehicleItem"
                                        : "menu-li-item"
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, year, "year");
                                    }}
                                  >
                                    {year.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="ymme-columns ymme_year">
                          <ul className="menu-single-row">
                            {splitArrayEqually(yearListSearched, 6)[1].map(
                              (year, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.year === year.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, year, "year");
                                    }}
                                  >
                                    {year.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="ymme-columns ymme_year">
                          <ul className="menu-single-row">
                            {splitArrayEqually(yearListSearched, 6)[2].map(
                              (year, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.year === year.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, year, "year");
                                    }}
                                  >
                                    {year.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="ymme-columns ymme_year">
                          <ul className="menu-single-row ">
                            {splitArrayEqually(yearListSearched, 6)[3].map(
                              (year, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.year === year.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, year, "year");
                                    }}
                                  >
                                    {year.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="ymme-columns ymme_year">
                          <ul className="menu-single-row">
                            {splitArrayEqually(yearListSearched, 6)[4].map(
                              (year, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.year === year.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, year, "year");
                                    }}
                                  >
                                    {year.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="ymme-columns ymme_year">
                          <ul className="menu-single-row">
                            {splitArrayEqually(yearListSearched, 6)[5].map(
                              (year, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.year === year.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, year, "year");
                                    }}
                                  >
                                    {year.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                      </div>
                    )}
                  </ul>
                </li>

                <li class="nav-item ymme-nav-item">
                  <div
                    class="nav-ymme"
                    data-toggle="dropdown"
                    id="navbarDropdown"
                    role="button"
                    onClick={() => {
                      toggleYmmeMenu("make");
                    }}
                    ref={anchorRefMake}
                  >
                    <input
                      type="text"
                      className={
                        showMakeMenu
                          ? "ymme-menu-item-input ymme-menu-focus-on"
                          : "ymme-menu-item-input"
                      }
                      placeholder="Make"
                      onChange={(e) => {
                        handleInputChange(e.target.value, _makeList, "make");
                      }}
                      onKeyDown={(event) => {
                        handleKeyDown(event, "make");
                      }}
                      defaultValue={selectedVehicle.make}
                      ref={makeRef}
                      value={makeValue}
                      onFocus={() => handleFocus("make")}
                    />
                    <div
                      class=" css-1hb7zxy-IndicatorsContainer down-arrow"
                      onClick={() => handleFocus("make")}
                    >
                      <div
                        aria-hidden="true"
                        class=" css-tlfecz-indicatorContainer"
                      >
                        {showMakeMenu ? (
                          <i
                            class="fa fa-angle-up ymmeArrow"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            class="fa fa-angle-down ymmeArrow"
                            aria-hidden="true"
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <PopperMenuList
                    list={makeListSearched || []}
                    anchorRef={
                      makeListSearched && makeListSearched.length > 36
                        ? anchorRefYear
                        : anchorRefMake
                    }
                    open={openMake}
                    handleListKeyDown={handleListKeyDown}
                    handleMenuItemClicked={handleMenuItemClicked}
                    handleClose={handleCloseMake}
                    id="make"
                    paperStyles={{
                      maxHeight: `${
                        ITEM_HEIGHT * ITEM_PER_ROW + MENU_PADDING
                      }px`,
                    }}
                    menuStyles={{
                      maxHeight: `${
                        ITEM_HEIGHT * ITEM_PER_ROW + MENU_PADDING
                      }px`,
                    }}
                    menuListRef={menuListRef}
                    selectedValue={makeValue}
                  />
                  <ul
                    className={
                      showMakeMenu && makeListSearched !== null && false
                        ? "dropdown-menu megamenu show makeDropdown"
                        : "dropdown-menu megamenu"
                    }
                  >
                    {makeListSearched && makeListSearched.length === 0 && (
                      <div class="row">
                        <li class="menu-no-option">No Options</li>
                      </div>
                    )}
                    {makeListSearched && makeListSearched.length > 0 && (
                      <div class="row">
                        <li class="ymme-columns5 hover-on-first">
                          <ul className="menu-single-row">
                            {splitArrayEqually(makeListSearched, 5)[0].map(
                              (make, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.make === make.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, make, "make");
                                    }}
                                  >
                                    {make.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="ymme-columns5">
                          <ul className="menu-single-row">
                            {splitArrayEqually(makeListSearched, 5)[1].map(
                              (make, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.make === make.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, make, "make");
                                    }}
                                  >
                                    {make.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="ymme-columns5">
                          <ul className="menu-single-row">
                            {splitArrayEqually(makeListSearched, 5)[2].map(
                              (make, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.make === make.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, make, "make");
                                    }}
                                  >
                                    {make.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="ymme-columns5">
                          <ul className="menu-single-row">
                            {splitArrayEqually(makeListSearched, 5)[3].map(
                              (make, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.make === make.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, make, "make");
                                    }}
                                  >
                                    {make.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="ymme-columns5">
                          <ul className="menu-single-row">
                            {splitArrayEqually(makeListSearched, 5)[4].map(
                              (make, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.make === make.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, make, "make");
                                    }}
                                  >
                                    {make.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                      </div>
                    )}
                  </ul>
                </li>

                <li class="nav-item ymme-nav-item">
                  <div
                    class="nav-ymme"
                    data-toggle="dropdown"
                    id="navbarDropdown"
                    role="button"
                    onClick={() => {
                      toggleYmmeMenu("model");
                    }}
                    ref={anchorRefModal}
                  >
                    <input
                      type="text"
                      className={
                        showModelMenu
                          ? "ymme-menu-item-input ymme-menu-focus-on"
                          : "ymme-menu-item-input"
                      }
                      placeholder="Model"
                      onChange={(e) => {
                        handleInputChange(e.target.value, _modelList, "model");
                      }}
                      onKeyDown={(event) => {
                        handleKeyDown(event, "model");
                      }}
                      defaultValue={selectedVehicle.model}
                      ref={modelRef}
                      value={modelValue}
                      onFocus={() => handleFocus("model")}
                    />
                    <div
                      class=" css-1hb7zxy-IndicatorsContainer down-arrow"
                      onClick={() => handleFocus("model")}
                    >
                      <div
                        aria-hidden="true"
                        class=" css-tlfecz-indicatorContainer"
                      >
                        {showModelMenu ? (
                          <i
                            class="fa fa-angle-up ymmeArrow"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            class="fa fa-angle-down ymmeArrow"
                            aria-hidden="true"
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <PopperMenuList
                    list={modelListSearched || []}
                    anchorRef={anchorRefModal}
                    open={openModel}
                    handleListKeyDown={handleListKeyDown}
                    handleMenuItemClicked={handleMenuItemClicked}
                    handleClose={handleCloseModel}
                    id="model"
                    paperStyles={{
                      maxHeight: `${
                        ITEM_HEIGHT * ITEM_PER_ROW + MENU_PADDING
                      }px`,
                    }}
                    menuStyles={{
                      maxHeight: `${
                        ITEM_HEIGHT * ITEM_PER_ROW + MENU_PADDING
                      }px`,
                      minWidth: "192px",
                    }}
                    menuListRef={menuListRef}
                    selectedValue={modelValue}
                  />
                  <ul
                    className={
                      showModelMenu && modelListSearched !== null && false
                        ? "dropdown-menu megamenu show modelDropdown"
                        : "dropdown-menu megamenu"
                    }
                  >
                    {modelListSearched && modelListSearched.length === 0 && (
                      <div class="row">
                        <li class="menu-no-option">No Options</li>
                      </div>
                    )}
                    {modelListSearched && modelListSearched.length > 0 && (
                      <div class="row">
                        <li class="col-md-3 ymme-columns ymme-columns-model hover-on-first">
                          <ul className="menu-single-row">
                            {splitArrayEqually(modelListSearched, 4)[0].map(
                              (model, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.model === model.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, model, "model");
                                    }}
                                  >
                                    {model.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="col-md-3 ymme-columns ymme-columns-model">
                          <ul className="menu-single-row">
                            {splitArrayEqually(modelListSearched, 4)[1].map(
                              (model, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.model === model.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, model, "model");
                                    }}
                                  >
                                    {model.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="col-md-3 ymme-columns ymme-columns-model">
                          <ul className="menu-single-row">
                            {splitArrayEqually(modelListSearched, 4)[2].map(
                              (model, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.model === model.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, model, "model");
                                    }}
                                  >
                                    {model.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                        <li class="col-md-3 ymme-columns ymme-columns-model">
                          <ul className="menu-single-row">
                            {splitArrayEqually(modelListSearched, 4)[3].map(
                              (model, index) => {
                                return (
                                  <li
                                    className={
                                      selectedVehicle.model === model.label
                                        ? "selectedVehicleItem"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      handleMenuItemClicked(e, model, "model");
                                    }}
                                  >
                                    {model.label}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </li>
                      </div>
                    )}
                  </ul>
                </li>

                <li class="nav-item ymme-nav-item">
                  <div
                    class="nav-ymme"
                    data-toggle="dropdown"
                    id="navbarDropdown"
                    role="button"
                    onClick={() => {
                      toggleYmmeMenu("engine");
                    }}
                    ref={anchorRefEngine}
                  >
                    <input
                      type="text"
                      className={
                        showEngineMenu
                          ? "ymme-menu-item-input ymme-menu-focus-on"
                          : "ymme-menu-item-input"
                      }
                      placeholder="Engine"
                      onChange={(e) => {
                        handleInputChange(
                          e.target.value,
                          _engineList,
                          "engine"
                        );
                      }}
                      onKeyDown={(event) => {
                        handleKeyDown(event, "engine");
                      }}
                      defaultValue={selectedVehicle.engine}
                      ref={engineRef}
                      value={engineValue}
                      onFocus={() => handleFocus("engine")}
                    />
                    <div
                      class=" css-1hb7zxy-IndicatorsContainer down-arrow"
                      onClick={() => handleFocus("engine")}
                    >
                      <div
                        aria-hidden="true"
                        class=" css-tlfecz-indicatorContainer"
                      >
                        {showEngineMenu ? (
                          <i
                            class="fa fa-angle-up ymmeArrow"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            class="fa fa-angle-down ymmeArrow"
                            aria-hidden="true"
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <PopperMenuList
                    list={engineListSearched || []}
                    anchorRef={anchorRefEngine}
                    open={openEngine}
                    handleListKeyDown={handleListKeyDown}
                    handleMenuItemClicked={handleMenuItemClicked}
                    handleClose={handleCloseEngine}
                    id="engine"
                    paperStyles={{
                      maxHeight: `${
                        ITEM_HEIGHT * ITEM_PER_ROW + MENU_PADDING
                      }px`,
                    }}
                    menuStyles={{
                      maxHeight: `${
                        ITEM_HEIGHT * ITEM_PER_ROW + MENU_PADDING
                      }px`,
                    }}
                    menuListRef={menuListRef}
                    selectedValue={engineValue}
                  />
                  <ul
                    className={
                      showEngineMenu && engineListSearched !== null && false
                        ? "dropdown-menu megamenu show engineDropdown"
                        : "dropdown-menu megamenu"
                    }
                  >
                    {engineListSearched && engineListSearched.length === 0 && (
                      <div class="row">
                        <li class="menu-no-option">No Options</li>
                      </div>
                    )}
                    {engineListSearched && engineListSearched.length > 0 && (
                      <div class="row">
                        <li class="ymme-columns ymme-column-engine hover-on-first">
                          <ul className="menu-single-row">
                            {engineListSearched.map((engine, index) => {
                              return (
                                <li
                                  className={
                                    selectedVehicle.engine === engine.label ||
                                    " " + selectedVehicle.engine ===
                                      engine.label
                                      ? "selectedVehicleItem engineLI"
                                      : "engineLI"
                                  }
                                  onClick={(e) => {
                                    handleMenuItemClicked(e, engine, "engine");
                                  }}
                                >
                                  {engine.label}
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      </div>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
        </Col>
      </Row>
    </Tab.Pane>
  );
};

export default YmmeDropDowns;

YmmeDropDowns.propTypes = {
  children: PropTypes.element.isRequired,
};
