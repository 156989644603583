import httpClient from "../../../services/http";
import { buildQueryUrl } from "../../../helpers/utils";

export const getKMVehicleMap = (data) =>
  httpClient.get(`/catalog/specs-pro/vehicle-map?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const getK1VehicleSpec = (data) =>
  httpClient.get(`/catalog/specs-pro/vehicle-specificationk1?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const getK2VehicleSpecInfo = (data) =>
  httpClient.get(`/catalog/specs-pro/vehicle-specificationk2?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export default httpClient;
