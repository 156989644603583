import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  getMyGarageQuotes,
  setMyGarageVehicles,
  getMyGarageVehicles,
  searchMyGarageVehicles,
  deleteMyGarageVehicle,
  deleteMyGarageQuote,
  getMyGarageRecommendedjJobs,
  viewMyGarageQuote,
  setMyGaragePartHistory,
  getMyGaragePartsHistory,
  searchMyGaragePartsHistory,
  deleteMyGaragePartHistory,
  getMyGarageOrders,
  addMyGarageJob,
  deleteMyGarageJob,
  searchMyGarageJobs,
  getMyGarageJobs,
  getMyGarageAllPartDescription,
  getMyGarageAllChemicalDescription,
  getMyGarageVehicleByAcesIds,
  getMyGarageStatementsUrl,
  getMyGarageStatementsChangedUrl,
  getMygarageReports,
  getMetabaseTokenInfo,
  getAllStoreData,
  getPaymentsByCustomer,
  getPaymentsByCustomerAndDate,
  getPaymentsByAllCustomersAndDate,
  getCustomerBalance,
  getAppVersion,
  getAllStore,
  getMyGarageReturn,
  createOrUpdateStore,
  getInvoiceStoreData,
  getMyGarageCreditsUrl,
  createReturn,
  getInvoice,
  getHomePagePartTypes
} from "./mygarage.service";

export const setVehicles = createAsyncThunk(
  "setVehicles",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await setMyGarageVehicles(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const setFavoriteVehicle = createAsyncThunk(
  "favoriteVehicles",
  async (payload, { signal, rejectWithValue }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await setMyGarageVehicles(payload, {
        cancelToken: source.token,
      });
      return response;
    } catch (error) {
      console.log("fav error ==> ", error);
      return rejectWithValue(error);
    }
  }
);

export const fetchVehicles = createAsyncThunk(
  "fetchVehicles",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGarageVehicles(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const searchVehicles = createAsyncThunk(
  "searchVehicles",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await searchMyGarageVehicles(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const deleteVehicles = createAsyncThunk(
  "deleteVehicles",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await deleteMyGarageVehicle(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

//get Allstoredata
export const getAllStoreNumber = createAsyncThunk(
  "getAllStoreNumber",
  async (payload) => {
    const response = await getAllStore(payload);
    return response.data;
  }
);

export const fetchQuotes = createAsyncThunk(
  "fetchQuotes",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGarageQuotes(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const searchQuotes = createAsyncThunk(
  "searchQuotes",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGarageQuotes(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const deleteQuote = createAsyncThunk(
  "deleteQuote",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await deleteMyGarageQuote(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const viewQuote = createAsyncThunk(
  "viewQuote",
  async (payload, { signal, rejectWithValue }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await viewMyGarageQuote(payload, {
        cancelToken: source.token,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setPartHistory = createAsyncThunk(
  "setPartHistory",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await setMyGaragePartHistory(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const deletePartHistory = createAsyncThunk(
  "deletePartHistory",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await deleteMyGaragePartHistory(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const fetchPartsHistory = createAsyncThunk(
  "fetchPartsHistory",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGaragePartsHistory(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const searchPartsHistory = createAsyncThunk(
  "searchPartsHistory",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await searchMyGaragePartsHistory(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const fetchOrders = createAsyncThunk(
  "fetchOrders",
  async (payload, { signal, rejectWithValue }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await getMyGarageOrders(payload, {
        cancelToken: source.token,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMetabaseInfo = createAsyncThunk(
  "fetchMetabaseInfo",
  async (payload, { signal, rejectWithValue }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await getMetabaseTokenInfo(payload, {
        cancelToken: source.token,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addJob = createAsyncThunk(
  "addJob",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await addMyGarageJob(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const deleteJob = createAsyncThunk(
  "deleteJob",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await deleteMyGarageJob(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const fetchRecommendedJobs = createAsyncThunk(
  "fetchRecommendedJobs",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel(); 
    });
    const response = await getMyGarageRecommendedjJobs(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const fetchJobs = createAsyncThunk(
  "fetchJobs",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGarageJobs(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const searchJobs = createAsyncThunk(
  "searchJobs",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await searchMyGarageJobs(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getAllPartDescription = createAsyncThunk(
  "getAllPartDescription",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGarageAllPartDescription(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getAllChemicalDescription = createAsyncThunk(
  "getAllChemicalDescription",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGarageAllChemicalDescription(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getVehicleByAcesIds = createAsyncThunk(
  "getVehicleByAcesIds",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGarageVehicleByAcesIds(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getStatementsUrl = createAsyncThunk(
  "getStatementsUrl",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGarageStatementsUrl(payload, {
      cancelToken: source.token,
    });
    return response;
  }
);
export const getStatementsChangedUrl = createAsyncThunk(
  "getStatementsChangedUrl",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGarageStatementsChangedUrl(payload, {
      cancelToken: source.token,
    });
    return response;
  }
);
export const getCreditUrl = createAsyncThunk(
  "getCreditUrl",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMyGarageCreditsUrl(payload, {
      cancelToken: source.token,
    });
    return response;
  }
);

export const verifyAppVersion = createAsyncThunk(
  "verifyAppVersion",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getAppVersion(payload, {
      cancelToken: source.token,
    });
    return response;
  }
);

//my garage Reports
export const getAllReports = createAsyncThunk(
  "getAllReports",
  async (payload) => {
    const response = await getMygarageReports();
    return response.data;
  }
);

//get stores
export const getAllStores = createAsyncThunk(
  "getAllStores",
  async (payload) => {
    const response = await getAllStoreData(payload);
    return response.data;
  }
);

export const getPayments = createAsyncThunk(
  "getPayments",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getPaymentsByCustomer(payload, {
      cancelToken: source.token,
    });
    return response;
  }
);

export const getCustomerPaymentsByDate = createAsyncThunk(
  "getCustomerPaymentsByDate",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getPaymentsByCustomerAndDate(payload, {
      cancelToken: source.token,
    });
    return response;
  }
);

export const getAllPaymentsByDate = createAsyncThunk(
  "getAllPaymentsByDate",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getPaymentsByAllCustomersAndDate(payload, {
      cancelToken: source.token,
    });
    return response;
  }
);

export const getBalance = createAsyncThunk(
  "getBalance",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getCustomerBalance(payload, {
      cancelToken: source.token,
    });
    return response;
  }
);
export const fetchReturn = createAsyncThunk("fetchReturn", async (data) => {
  const response = await getMyGarageReturn(data);
  return response.data;
});

export const fetchPartTypes  = createAsyncThunk(
  "fetchPartTypes",
  async (data) => {
    const response = await getHomePagePartTypes(data);
    return response.data;
  }
);

export const createReturns = createAsyncThunk(
  "createReturns",
  async (payload) => {
    const response = await createReturn(payload);
    return response.data;
  }
);

export const getInvoiceDetails = createAsyncThunk(
  "getInvoiceDetails",
  async (payload) => {
    const response = await getInvoice(payload);
    return response.data;
  }
);

export const createOrUpdateStoreUser = createAsyncThunk(
  "createOrUpdateStoreUser",
  async (payload) => {
    const response = await createOrUpdateStore(payload);
    return response.data;
  }
);
export const getInvoiceStoreDetails = createAsyncThunk(
  "getInvoiceStoreDetails",
  async (payload) => {
    const response = await getInvoiceStoreData(payload);
    return response.data;
  }
);
