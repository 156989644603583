/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useDispatch, useSelector } from "react-redux";
import EnhancedTable from "ui-library/table";
import { useToasts } from "react-toast-notifications";
import Button from "ui-library/Button";
import {
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import moment from "moment";
import XModal from "../../shared/XModal";
import Savedcards from "../savedCards";
import { digest, fetchInvoices, payerDetails, uploadInvoiceSheet } from "../payments.thunk";
import "./styles.scss";
import {
  changeInvoicesToAmountDue,
  changeOverride,
  closeMatched,
  resetDigest,
} from "../payments.slice";
import {
  getImageURLCookieDetails,
  isImageURLCookieValid,
  setImageURLCookie,
  getUserPreferenceItem,
  currencyFormatter,
} from "../../../helpers/utils";
import { updateToken } from "../../../actions/auth";
import { triggerCustomUxCriticalError } from "../../../actions/error";
import email from "../../../helpers/email/emailUtil";
import Axios from "axios";
import { getPartImageURLCookie, impersonateUserBillTo, getAllCustomers } from "../../User/UserManagement/usermanagement.thunk";
import { Form, Spinner, Row } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import { clearCart } from "../../../actions/cart";
import { setVehicleSelected } from "../../shared/MyGarage/mygarage.slice";
import Select from 'react-select';
const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const DEFAULT_DATE_FILTER = "";
const ERROR_IN_TOTAL_AMOUNTS_CALC =
  "Internal Error, Can you please select the invoices again";

function Invoices(props) {
  const { addToast } = useToasts();
  const [diff, setDiff] = useState(false);
  const [unMatched, setUnMatched] = useState(false);
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(null);
  const [dateFilter, setDateFilter] = useState("");
  const [dates, setDates] = useState(null);
  const [templateURL, setTemplateURL] = useState(null);
  const cartStatus = useSelector((state) => state.cart);
  const [selected, setSelected] = useState([]);
  const [profileType, setProfileType] = useState(0);
  const [isUploaded, setIsUploaded] = useState(false);
  const [hideMethods, setHideMethods] = useState(false);
  const [clickedNo, setClickedNo] = useState(false);
  const userDetailsArr = useSelector((state) => state.user);
  const [selectedBillTo, setSelectedBillTo] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector((state) => state.user);
  const payments = useSelector((state) => state.payments);
  const [invoices, setInvoices] = useState(payments.invoices);
  const [openInvoicesDue, setOpenInvoicesDue] = useState(0);
  const dispatch = useDispatch();
  const [superAdminUserList, setSuperAdminUserList] = useState([]);

  const [showBillTOclearModal, setshowBillTOclearModal] = useState(false);
  const [showChangeCustomerBillTo, SetShowChangeCustomerBillTo] =
    useState(false);
  const [showBillTOModal, setshowBillTOModal] = useState(false);
  const [billTo, setbillTo] = useState("");
  const [showLoader, setshowLoader] = useState(true);
  const [buttonState, setbuttonState] = useState(false);
  let billToStatus = useSelector((state) => state.userManagement.billToStatus);
  const isURLValid = isImageURLCookieValid();

  useEffect(() => {
    if (payments.cardDeleted) {
      addToast('Card deleted successfully', {
        appearance: "success",
        autoDismiss: true,
      });

      dispatch(payerDetails());
      dispatch(resetDigest());
    }
  }, [payments.cardDeleted])

  useEffect(() => {
    dispatch({
      payload: {
        globalLoader: !showLoader
      },
      type: actionTypes.GLOBAL_LOADER
    });
  }, [showLoader]);

  useEffect(() => {
    if (payments.invoices && !payments.loading && payments.invoices.length > 0) {
      setIsLoading(false);
    } else if (payments.loading && payments.invoices && payments.invoices.length === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [payments.invoices]);

  const getProfileType = () => {
    const customerType =
      user &&
      user.userDetails &&
      user.userDetails.billTo &&
      user.userDetails.billTo.customerTypes &&
      user.userDetails.billTo.customerTypes[0] &&
      user.userDetails.billTo.customerTypes[0].type;

    const modes = getUserPreferenceItem("payment-mode") || [];
    const modeType = modes.find((m) => m.code === customerType);

    let profileType = 0;
    if (modeType) {
      const mode = JSON.parse(modeType.text);

      if (mode && mode.creditCard === "Y" && mode.eCheck === "Y") {
        profileType = 0;
      } else if (mode && mode.creditCard === "N" && mode.eCheck === "N") {
        profileType = -1;
      } else if (mode && mode.creditCard === "Y" && mode.eCheck === "N") {
        profileType = 1;
      } else if (mode && mode.creditCard === "N" && mode.eCheck === "Y") {
        profileType = 2;
      }
    }

    return profileType;
  };


  const payloadBase = {
    customer_number:
      (user && user.userDetails && user.userDetails.xlPartAccountNumber) || "",
    start_date: "",
    end_date: "",
    // page_number: 1,
    // no_of_records: 1000,
  };

  const initialInvoiceFetch = (payload = payloadBase) => {
    dispatch(fetchInvoices(payload));
  };


  useEffect(() => {
    if (userDetailsArr.userDetails.userType === "SUPER_ADMIN") {
      dispatch(getAllCustomers());
    }

  }, []);

  useEffect(() => {
    dispatch(getAllCustomers()).then((result) => {
      let billToOptions = createBillToOptions(result.payload);
      setSelectedBillTo(billToOptions);
    });
  }, []);

  const createBillToOptions = (customerList) => {
    let optionArr = [];
    if (!_isEmpty(customerList)) {
      customerList.forEach((billToList) => {
        optionArr.push({
          value: billToList.billtoId,
          label: billToList.billtoId + " - " + billToList.name,
        });
      });
    }
    return optionArr;
  };

  useEffect(() => {
    if (payments.digestError) {
      setShow(false);
      dispatch(resetDigest());

      addToast(`${ERROR_IN_TOTAL_AMOUNTS_CALC}(after reloading the page)`, {
        appearance: "error",
        autoDismiss: true,
      });

      setTimeout(() => {
        window.location.reload(false);
      }, 1000 * 5);
    }
  }, [payments.digestError]);

  useEffect(() => {
    if (
      user &&
      user.userDetails &&
      !_.isEmpty(user.userDetails.xlPartAccountNumber)
    ) {
      initialInvoiceFetch(payloadBase);
    }
  }, [user.userDetails.xlPartAccountNumber]);

  useEffect(() => {
    if (dates) {
      const extraPayload = {
        start_date: moment(dates.startDate).format("MM/DD/YY"),
        end_date: moment(dates.endDate).format("MM/DD/YY"),
      };

      const p = Object.assign(payloadBase, extraPayload);

      initialInvoiceFetch(p);
    }
  }, [dates]);

  useEffect(() => {
    setInvoices(payments.invoices);
    if (payments.invoices.length > 0) {
      const tmd = payments.invoices.map((p) => Number(p.invoiceAmountDue));

      const totalAmountDue = tmd.reduce((a, b) => a + b, 0);
      setOpenInvoicesDue(Number(totalAmountDue).toFixed(2));
    } else {
      setOpenInvoicesDue(Number(0).toFixed(2));
    }

    const isMatched = _.filter(payments.invoices, (i) => i.isMatched === true);
    if (isMatched.length > 0) {
      setSelected(isMatched);
      setIsUploaded(true);
    } else {
      setIsUploaded(false);
    }

    if (payments.unMatchedInvoices.length > 0) {
      setUnMatched(true);
    } else {
      setUnMatched(false);
      checkDiff(payments.invoices);
    }
  }, [payments.invoices]);

  useEffect(() => {
    calculateSum();
  }, [selected, invoices]);

  const calculateSum = () => {
    let sum = 0;
    if (isUploaded) {
      const _sum = selected.map((sel) => {
        if (
          sel.considerUploadedAmount &&
          !(sel.uploadedAmount < 0) &&
          sel.uploadedAmount <= sel.invoiceAmountDue
        ) {
          return Number(sel.uploadedAmount);
        } else {
          return Number(sel.invoiceAmountDue);
        }
      });

      sum = _.sum(_sum);
    } else {
      const _sum = selected.map((sel) => {
        let _amount = 0;
        invoices.forEach((item) => {
          if (item.invoiceNumber === sel.invoiceNumber) {
            _amount = item.considerUploadedAmount
              ? Number(item.uploadedAmount)
              : sel.considerUploadedAmount
                ? Number(sel.uploadedAmount)
                : Number(sel.invoiceAmountDue);
          }
        });
        return _amount;
      });
      sum = _.sum(_sum);
    }

    setAmount(parseFloat(sum).toFixed(2));
  };

  const handleMagicWordSelection = (billTo) => {
    setbillTo(billTo);
  }

  const handleDateRange = (e, picker) => {
    const { startDate, endDate } = picker;

    const _startDate = startDate.toISOString();
    const _endDate = endDate.toISOString();

    setDates({ startDate: _startDate, endDate: _endDate });

    setDateFilter(
      `${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`
    );
    setSelected([]);
  };

  const handleConfirmPay = (paymentToken, isSetupPayment) => {
    const _amount = parseFloat(amount).toFixed(2);
    const payload = {
      amount: _amount,
      currency: "USD",
      paymentToken: isSetupPayment ? "" : paymentToken ? paymentToken : "",
      profileType: profileType,
      requestType: "new",
      invoiceDetails: selected,
    };

    // calculate invoice amount difference.
    let calcAmount = 0;
    if (selected.length > 0) {
      selected.forEach((s) => {
        calcAmount = calcAmount + Number(s.uploadedAmount);
      });
      calcAmount = parseFloat(calcAmount).toFixed(2);
    } else {
      calcAmount = parseFloat(0).toFixed(2);
    }

    if (_amount !== calcAmount) {
      setShow(!show);
      setHideMethods(false);

      addToast(ERROR_IN_TOTAL_AMOUNTS_CALC, {
        appearance: "error",
        autoDismiss: true,
      });

      setTimeout(() => {
        window.location.reload(false);
      }, 1000 * 2);
    } else {
      dispatch(digest(payload));
    }
  };

  const handlePay = () => {
    setShow(!show);
  };

  const handleSelected = (selected) => {
    setSelected(selected);
  };

  const selectPayerDetails = (details, profileType) => {
    setProfileType(profileType);
  };

  const clearDateFilter = () => {
    setDates(null);
    setDateFilter(DEFAULT_DATE_FILTER);
    initialInvoiceFetch();
    setSelected([]);
  };

  const handleInvoiceDelete = (invoice) => {
    const selectedIndex = selected.indexOf(invoice);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, invoice);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleUploadFile = (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0], event.target.files[0].name);
    dispatch(uploadInvoiceSheet({ formData: data, params: payloadBase }));
  };

  const handleOverrideChange = (event, row) => {
    const value = event.target.value;
    setClickedNo(true);
    const payload = { row, value };
    dispatch(changeOverride(payload));
  };

  const checkDiff = (debts) => {
    dispatch(closeMatched());
    setUnMatched(false);

    let isDiffShow = false;
    const isMatchedInvoices = _.filter(debts, (i) => i.isMatched);

    if (isMatchedInvoices && isMatchedInvoices.length > 0) {
      for (let i = 0; i < isMatchedInvoices.length; i++) {
        const mi = isMatchedInvoices[i];
        const amountDue = Number(mi.invoiceAmountDue);
        const uploadedAmount = Number(mi.uploadedAmount);

        if (uploadedAmount < amountDue) {
          isDiffShow = true;

          break;
        } else {
          isDiffShow = false;
        }
      }
    }

    if (clickedNo) {
      setDiff(false);
      setClickedNo(false);
    } else {
      setDiff(isDiffShow);
    }
  };

  const handleMatchedClose = () => {
    dispatch(closeMatched());
    setUnMatched(false);

    let isDiffShow = false;
    const isMatchedInvoices = _.filter(payments.invoices, (i) => i.isMatched);

    if (isMatchedInvoices && isMatchedInvoices.length > 0) {
      for (let i = 0; i < isMatchedInvoices.length; i++) {
        const mi = isMatchedInvoices[i];
        const amountDue = Number(mi.invoiceAmountDue);
        const uploadedAmount = Number(mi.uploadedAmount);

        if (uploadedAmount < amountDue) {
          isDiffShow = true;

          break;
        } else {
          isDiffShow = false;
        }
      }
    }

    setDiff(isDiffShow);
  };

  const debugDownloadTemplate = () => {
    Axios.get(getValidTemplateURL())
      .then((d) => {
        email.sendEmail(
          {
            message: `Success Testing Download Template - ${JSON.stringify(d)}`,
          },
          200,
          {
            dispatch,
            URL: "URL",
            headers: {},
            baseURL: "",
            subject:
              "DEBUG Purpose - please ignore - download template debugging",
          }
        );
      })
      .catch((e) => {
        email.sendEmail(
          { message: `Error Testing Download Template - ${JSON.stringify(e)}` },
          500,
          {
            dispatch,
            URL: "URL",
            headers: {},
            baseURL: "",
            subject:
              "DEBUG Purpose - please ignore - download template debugging",
          }
        );
      });
  };

  const getValidTemplateURL = () => {
    const isURLValid = isImageURLCookieValid();

    if (!isURLValid) {
      dispatch(getPartImageURLCookie()).then((resp) => {
        const imageURLCookie = resp.payload;
        if (resp && resp.payload) {
          const imageUrlCookieDetails = `Policy=${imageURLCookie["CloudFront-Policy"]}&Signature=${imageURLCookie["CloudFront-Signature"]}&Key-Pair-Id=${imageURLCookie["CloudFront-Key-Pair-Id"]}`;
          setImageURLCookie(resp.payload);
          return `${process.env.REACT_APP_INVOICE_TEMPLATE}?${imageUrlCookieDetails}`;
        }
      });
    }
    const url = `${process.env.REACT_APP_INVOICE_TEMPLATE
      }?${getImageURLCookieDetails()}`;
    setTemplateURL(url);
  };

  useEffect(() => {
    if (isURLValid) {
      const url = `${process.env.REACT_APP_INVOICE_TEMPLATE
        }?${getImageURLCookieDetails()}`;
      setTemplateURL(url);
    } else {
      getValidTemplateURL();
    }
  }, [isURLValid]);

  const handleDownloadDebug = () => {
    const wLocation = window.location;
    if (
      wLocation &&
      (wLocation.host.includes("uat-cep") ||
        wLocation.host.includes("localhost") ||
        wLocation.host.includes("dev-cep"))
    ) {
      debugDownloadTemplate();
    }
  };

  const handleNoClick = () => {
    dispatch(changeInvoicesToAmountDue());
    setDiff(false);
    setClickedNo(true);
  };

  const handleChangeAmount = (e, row) => {
    const value = e.target.value;

    const newInvoices = _.cloneDeep(invoices).map((invoice) => {
      if (invoice.invoiceNumber === row.invoiceNumber) {
        if (Number(value) > 0) {
          if (Number(value) > Number(invoice.invoiceAmountDue)) {
            invoice.error = true;
            invoice.uploadedAmount = invoice.invoiceAmountDue;
          } else {
            invoice.uploadedAmount = parseFloat(value).toFixed(2);
            invoice.considerUploadedAmount = true;

            // Reset the error, once it's applied.
            invoice.error = false;
          }
        } else if (value !== "" && Number(value) <= 0) {
          invoice.error = false;
          invoice.uploadedAmount = Number(invoice.invoiceAmountDue);
          e.target.value = Number(invoice.invoiceAmountDue);
        }
      } else {
        invoice.error = false;
      }
      return invoice;
    });

    const newSelected = _.cloneDeep(selected).map((item) => {
      if (item.invoiceNumber === row.invoiceNumber) {
        if (Number(value) > 0) {
          if (Number(value) > Number(item.invoiceAmountDue)) {
            item.uploadedAmount = item.invoiceAmountDue;
          } else {
            item.uploadedAmount = parseFloat(value).toFixed(2);
            if (!isUploaded) {
              item.considerUploadedAmount = false;
            }
          }
        } else if (value !== "" && Number(value) <= 0) {
          item.uploadedAmount = Number(item.invoiceAmountDue);
        }
      }
      return item;
    });

    setSelected(newSelected);
    setInvoices(newInvoices);
  };

  const handleSetupPayment = () => {
    setHideMethods(!hideMethods);
    setShow(!show);
  };

  const changeBillTo = () => {
    setshowBillTOclearModal(false);
    const payload = {
      billTo: billTo.value,
      x2UserName: userDetailsArr.userDetails.x2userName,
      userType: userDetailsArr.userDetails.userType,
      buyPartnerCreationFlag: true,
      dispatch,
    };
    dispatch(impersonateUserBillTo(payload)).then((action) => {
      setbillTo('');
      updateToken(dispatch).then(() => {
        let statusMessageProps = {
          heading: "",
          message: "",
          type: "",
        };
        if (action.payload === "Change BillTo successful") {
          dispatch(getAllCustomers()).then((result) => {
            setSuperAdminUserList(result.payload);
          });
          statusMessageProps.heading = 'BillTo modified successfully';
          statusMessageProps.type = 'success';
          triggerCustomUxCriticalError(statusMessageProps, dispatch);
          setbillTo('');
          setshowBillTOModal(false);
          setshowBillTOclearModal(false);
          setshowLoader(true);
          dispatch(setVehicleSelected(null));
          dispatch({
            payload: {
              selectedVehicle: null,
            },
            type: "VEHICLE_SELECTION_COMPLETED",
          });
        }

      })
      setshowLoader(true);
    })
  }

  const deleleteMethod = async () => {
    if (userDetailsArr.userDetails.userType === "SUPER_ADMIN" || showChangeCustomerBillTo) {
      setshowLoader(true);
      changeBillTo();
    }
    if (cartStatus.qtyInCart > 0) {
      await clearCart(dispatch);
    }
    if (userDetailsArr.userDetails.userType === "IT_ADMIN" ||
      userDetailsArr.userDetails.userType === "OSR" ||
      userDetailsArr.userDetails.userType === 'IT_INTERNAL' ||
      userDetailsArr.userDetails.userType === "DSM" ||
      userDetailsArr.userDetails.userType === 'OPERATIONS_MANAGER') {
      setTimeout(() => {
        updateToken(dispatch).then(() => {
          let statusMessageProps = {
            heading: "",
            message: "",
            type: "",
          };
          if (billToStatus === 'success' || billToStatus.payload?.errorCode === 'E1112') {
            statusMessageProps.heading = 'BillTo modified successfully';
            statusMessageProps.type = 'success';
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
            setbillTo('');
            setshowBillTOModal(false);
            setshowBillTOclearModal(false);
            setshowLoader(true);
            dispatch(setVehicleSelected(null));
            dispatch({
              payload: {
                selectedVehicle: null,
              },
              type: "VEHICLE_SELECTION_COMPLETED",
            });
          } else if (billToStatus === 'failed') {
            statusMessageProps.heading = 'BillTo modification Failed';
            statusMessageProps.type = 'error';
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
          }

        })
      }, 2000)
    }
  }

  const handleUpdate = () => {
    setshowLoader(false);
    if (cartStatus.qtyInCart > 0 && (userDetailsArr.userDetails.userType === "SUPER_ADMIN" || showChangeCustomerBillTo)) {
      setshowLoader(true);
      setshowBillTOclearModal(true);

    } else if (cartStatus.qtyInCart === 0 && (userDetailsArr.userDetails.userType === "SUPER_ADMIN" || showChangeCustomerBillTo)) {
      setshowLoader(false);
      changeBillTo();
    }
    else
      if (userDetailsArr.userDetails.userType === "IT_ADMIN" ||
        userDetailsArr.userDetails.userType === "OSR" ||
        userDetailsArr.userDetails.userType === 'IT_INTERNAL' ||
        userDetailsArr.userDetails.userType === "DSM" ||
        userDetailsArr.userDetails.userType === 'OPERATIONS_MANAGER') {
        const payload = {
          billTo,
          x2UserName: userDetailsArr.userDetails.x2userName,
          userType: userDetailsArr.userDetails.userType,
          buyPartnerCreationFlag: false,
          dispatch,
        };
        dispatch(impersonateUserBillTo(payload)).then((action) => {

          if (action.type === "impersonateUserBillTo/fulfilled") {
            setshowLoader(true);
            if (cartStatus.qtyInCart > 0) {
              setshowBillTOclearModal(true);
            }
            else {
              deleleteMethod();
            }
          } else if (action.payload.errorCode === 'E1112') {
            setshowLoader(true);
            setshowBillTOModal(true);
          }
          setshowLoader(true);
        })
      }
  }
  const handleUpdatecreate = () => {
    const payload = {
      billTo,
      x2UserName: userDetailsArr.userDetails.x2userName,
      userType: userDetailsArr.userDetails.userType,
      buyPartnerCreationFlag: true,
      dispatch,
    };
    setshowLoader(false);
    dispatch(impersonateUserBillTo(payload)).then((action) => {
      if (action.type === "impersonateUserBillTo/fulfilled") {
        setshowLoader(true);
        if (cartStatus.qtyInCart > 0) {
          setshowBillTOclearModal(true);
        }
        else {
          setshowBillTOModal(false)
          deleleteMethod();
        }
        //deleleteMethod();
      } setshowLoader(true);
    })
    setshowBillTOModal(false);
  }

  return (
    <div className="invoice-wrapper">
      <div className="title_file_row">
        <div className="page-title">Open Invoices</div>
      </div>
      <div className="mygarage__content bold">
        <div className="mygarage__account__links">
          <div className="left">
            <div>
              <a className="statement__link_account1" href="/statements">
                Statements & Invoices
              </a>{" "}
              <a className="statement__link_account" href="payment-history">
                PAYMENT HISTORY
              </a>
            </div>
            <div className="flex">
              <div className="flex-item">
                <div className="head-title">Open Invoices</div>
                <div className="head-value">{invoices.length}</div>
              </div>
              <div className="flex-item">
                <div className="head-title">Total Amount Due</div>
                <div className="head-value">{`${currencyFormatter(openInvoicesDue)}`}</div>
              </div>
            </div>
          </div>
          <div className="file_chooser">
            <span>Import file containing invoices list</span>
            <div className="import_file_section">
              <input
                type="file"
                onChange={handleUploadFile}
                accept=".xlsx, .xls"
              />
            </div>
            <a
              href={templateURL}
              target="_blank"
              download={true}
              onClick={handleDownloadDebug}
              rel="noreferrer"
            >
              Download template
            </a>
          </div>
        </div>
      </div>
      <div className="select-invoices-type" hidden>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Select Invoices Type
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              checked
              value="openInvoice"
              control={<Radio />}
              label="Open Invoices"
            />
            <FormControlLabel
              disabled
              value="statements-invoices"
              control={<Radio />}
              label="Statements & Invoices"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="filter-row">
        <div className="left">
          <div className="date-filter ">
            <DateRangePicker onApply={handleDateRange}>
              <span className="date-range-wrapper">
                <span className="d-label">Select Date Range</span>
                <span className="material-icons date__filter">date_range</span>
              </span>
            </DateRangePicker>
            <div className="dates">
              {dateFilter}{" "}
              {dateFilter && dates && (
                <span
                  className="material-icons clear__filter_btn"
                  onClick={clearDateFilter}
                >
                  highlight_off
                </span>
              )}
            </div>
          </div>
          <div className="mr-right-30 mr-left-30 separator" />
          <div className="data-filter">
            <div>Invoices Selected</div>
            <div className="num">{selected && selected.length}</div>
          </div>
        </div>

        <div className="billto-wrapper">
          {user.userDetails &&
            (user.userDetails.userType === "IT_ADMIN") && (
              <div className="label-input-container-payment">
                <label htmlFor="currentPassword">Change BillTo</label>
                <Form.Control
                  type="text"
                  onChange={(event) => setbillTo(event.target.value)}
                  name="currentPassword"
                  id="currentPassword"
                  value={billTo}
                />
                {showLoader ? (
                  <Button
                    onClick={handleUpdate}
                    disabled={_isEmpty(billTo)}
                    className="changeBillto_button_dropdown"
                    variant="contained"
                  >
                    ok
                  </Button>
                ) : (
                  <Button
                    className="changeBillto_button_dropdown"
                    variant="contained"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </Button>
                )}
              </div>
            )}

          {((userDetailsArr.userDetails && (userDetailsArr.userDetails.userType === "SUPER_ADMIN")) || showChangeCustomerBillTo)
            && (
              <div className="changeBillTo-dropdown">
                <label htmlFor="currentPassword">Change BillTo</label>
                <Select
                  className=""
                  placeholder="Select a Customer"
                  value={billTo}
                  name="changeBillTo"
                  onChange={handleMagicWordSelection}
                  options={!_isEmpty(selectedBillTo) && selectedBillTo}
                  // defaultValue={selectedBillTo.filter(op => op.value === defaultVal)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#FFC0C2",
                      primary50: "#aa0d10",
                      primary: "#aa0d10",
                    }
                  })}
                />

                {showLoader ? (
                  <Button
                    onClick={handleUpdate}
                    disabled={_isEmpty(billTo)}
                    className="changeBillto_button_dropdown"
                    variant="contained"
                  >
                    ok
                  </Button>
                ) : (
                  <Button
                    className="changeBillto_button_dropdown"
                    variant="contained"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </Button>
                )}
              </div>
            )}

          {user.userDetails &&
            (user.userDetails.userType === "IT_ADMIN" ||
              user.userDetails.userType === "SUPER_ADMIN") && (
              <div className="billto-details-wrapper">
                <Row>
                  <div>
                    <span className="user_management_label_color">
                      XL Parts Account:
                    </span>{" "}
                    <span className="">
                      {" "}
                      {user.userDetails && user.userDetails.billTo
                        ? `${user.userDetails.billTo.billToId} - ${user.userDetails.billTo.name}`
                        : ``}
                    </span>
                  </div>
                </Row>
                {!_isEmpty(user) &&
                  !_isEmpty(user.userDetails) &&
                  !_isEmpty(user.userDetails.shipTos) &&
                  !_isEmpty(user.userDetails.shipTos[0]) && (
                    <React.Fragment>
                      <Row>
                        <div>
                          <span className="user_management_label_color">
                            ShipTo:
                          </span>{" "}
                          <span>{`${user.userDetails.shipTos[0].shipToId} - ${user.userDetails.shipTos[0].name}`}</span>
                        </div>
                      </Row>
                      <Row>
                        <div>
                          <span className="user_management_label_color">
                            ShipTo Address:{" "}
                          </span>
                          <span>
                            {`${user.userDetails.shipTos[0].address}, ${user.userDetails.shipTos[0].city} ,`}
                          </span>
                          <span>
                            {`${user.userDetails.shipTos[0].state} , ${user.userDetails.shipTos[0].zip}`}
                          </span>
                        </div>
                      </Row>
                    </React.Fragment>
                  )}
              </div>
            )}
        </div>

        {showBillTOModal
          && (
            <XModal
              show={showBillTOModal}
              showButtons={false}
              title="Create Impersonate User"
              className="change_shipTo_save_quote_modal"
              handleClose={() => setshowBillTOModal(false)}
            >
              <div className="change_shipTo_modal_heading">
                <span className="shipTo_modal_text">
                  This Customer is not yet registered in RAPID. Do you want to really proceed?
                </span>
              </div>
              <div className="shipTo_modal_button_padding">
                <span className="shipTo_clear_cart_button">
                  {showLoader ? (
                    <Button
                      className="add_to_cart_button add_billTo"
                      disable={buttonState && !_isEmpty(billTo)}
                      onClick={handleUpdatecreate}
                    >
                      YES
                    </Button>) : (

                    <Button
                      className="user-management-submit-btn"
                      variant="primary"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </Button>
                  )}


                </span>
                <span className="shipTo_save_as_quote_button">
                  <Button
                    className="add_to_cart_button add_billTo"
                    onClick={() => setshowBillTOModal(false)}
                  >
                    NO
                  </Button>
                </span>
              </div>
            </XModal>
          )}
        {showBillTOclearModal
          && (
            <XModal
              show={showBillTOclearModal}
              showButtons={false}
              title="Create Impersonate User"
              className="change_shipTo_save_quote_modal"
              handleClose={() => setshowBillTOclearModal(false)}
            >
              <div className="change_shipTo_modal_heading">
                <span className="shipTo_modal_text">
                  Changing BillTo will empty your Cart. Do you really want to proceed?
                </span>
              </div>
              <div className="shipTo_modal_button_padding">
                <span className="shipTo_clear_cart_button">
                  {showLoader ? (
                    <Button
                      className="add_to_cart_button add_billTo"
                      disable={buttonState && !_isEmpty(billTo)}
                      onClick={deleleteMethod}
                    >
                      YES
                    </Button>) : (
                    <Button
                      className="user-management-submit-btn add_billTo"
                      variant="primary"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </Button>
                  )}
                </span>
                <span className="shipTo_save_as_quote_button">
                  <Button
                    className="add_to_cart_button add_billTo"
                    onClick={() => setshowBillTOclearModal(false)}
                  >
                    NO
                  </Button>
                </span>
              </div>
            </XModal>
          )}

        <div className="right">
          <div className="text-right">
            <a href="#" className="setup-payment" onClick={handleSetupPayment}>
              Setup Payment
            </a>
          </div>
          <form
            id="payment_confirmation"
            action={
              payments.digest
                ? `${process.env.REACT_APP_CYBERSOURCE_PAYMENT_URL}`
                : ""
            }
            method={payments.digest ? "post" : ""}
          >
            {payments.digest && (
              <React.Fragment>
                {Object.keys(payments.digest).map((k, i) => (
                  <input
                    key={`${i}_${k}`}
                    type="hidden"
                    id={k}
                    name={k}
                    value={payments.digest[k]}
                  />
                ))}
              </React.Fragment>
            )}
          </form>
          <div className="payments-wrapper">
            <div className="payment-info-input mr-right-15">
              {amount && amount > 0 && (
                <React.Fragment>
                  <div className="payment-info bold">
                    Amount you <br /> want to pay yesh
                  </div>
                  <div className="payment-input">
                    <div className="amount">{`$${amount}`}</div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="">
              <div>
                <Button
                  disabled={!(Number(amount) > 0)}
                  className="pay-btn"
                  size="large"
                  fullWidth
                  variant="contained"
                  onClick={handlePay}
                >{`Pay ${amount ? `($${amount})` : ""}`}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-wrapper">
        <EnhancedTable
          customerNumber={
            (user &&
              user.userDetails &&
              user.userDetails.xlPartAccountNumber) ||
            ""
          }
          isUploaded={isUploaded}
          rows={invoices}
          handleSelected={handleSelected}
          selected={selected}
          handleOverrideChange={handleOverrideChange}
          isLoading={isLoading}
          handleChange={handleChangeAmount}
        />
      </div>
      <XModal
        className="saved-cards-modal"
        title="Select Method of Payment"
        show={show}
        showButtons={false}
        handleClose={() => {
          setShow(!show);
          setHideMethods(false);
        }}
      >
        <Savedcards
          hideMethods={hideMethods}
          handleConfirmPay={handleConfirmPay}
          selectPayerDetails={selectPayerDetails}
          profileType={getProfileType()}
          subTotal={amount}
          selected={selected}
        />
      </XModal>

      <XModal
        className="unmatched-invoices"
        title="Unmatched Invoices"
        show={unMatched}
        showButtons={false}
        handleClose={() => handleMatchedClose()}
      >
        <div style={{ textAlign: "center" }}>
          <div className="msg">{`There are ${payments.unMatchedInvoices.length} / ${payments.totalUploadedInvoices} unmatched invoices.`}</div>
          <div className="unmatched-list">
            {payments.unMatchedInvoices.map((unMatched, i) => {
              return (
                <div className="item">
                  {unMatched}
                  {payments.unMatchedInvoices.length - 1 !== i && `, `}{" "}
                </div>
              );
            })}
          </div>
          <Button variant="contained" onClick={handleMatchedClose}>
            Ok
          </Button>
        </div>
      </XModal>

      <XModal
        className="amount-difference unmatched-invoices"
        title="Amount Difference"
        show={diff}
        showButtons={false}
        handleClose={() => setDiff(false)}
      >
        <div style={{ textAlign: "center" }}>
          <div>
            There is one or more invoices you want to upload that are less than
            the Amount Due in our system.
          </div>
          <div>
            Click <b>YES</b> if you would you like to use the amount(s) uploaded
            or <b>NO</b> to use the Amount Due shown in our system.
          </div>
          <div style={{ margin: "10px 0" }}>
            <Button variant="contained" onClick={() => setDiff(false)}>
              Yes
            </Button>
            <Button onClick={handleNoClick}>No</Button>
          </div>
        </div>
      </XModal>
    </div>
  );
}

export default Invoices;
