import React, { Fragment } from "react";
import {
  Container,
  Row,
  Card,
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import _remove from "lodash/remove";

import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { getChemPartByCat, getChemPartByGrp } from "../../../actions/parts";

import SingleSupplyItem from "./SingleSupplyItem";
import Placeholder from "../../shared/Placeholder";
import XModal from "../../shared/XModal";
import ChemicalFilters from "./ChemicalFilters";
import TopBar from "../../Listing/TopBar";
import PaginationComponent from "../../shared/Pagination";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import {
  isLengthLessthan,
  getDefaultDataBasedOnChemicalUSerPref,
  getPreferenceDataFromStorage,
  SupplierRegion,
  getChemicalsPreferredBrands,
  timeInUTC,
  setCookie,
  getCookie,
} from "../../../helpers/utils";
import { search } from "../chemicalsSearch.thunk";
import {
  getGroupsByCategory,
  setBrand,
  showBrandGroups,
} from "../chemicalsSearch.slice";
// import ShipInfoLocationHeader from "../../shared/ShipInfoHeader/ShipInfoLocationHeader";
import ShipInfoHeader from "components/shared/ShipInfoHeader";
import PromotionsTab from "../../shared/Promotions";
import clsx from "clsx";
import TableViewWrapper from "./tableview";
import _find from "lodash/find";
import _cloneDeep from "lodash/cloneDeep";
import AddToCartWarningPopup from "../../shared/AddToCartWarningPopup/AddToCartWarningPopup";
import _uniqBy from "lodash/uniqBy";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import Locations from "../../Locations";
import { logUserStockActivity } from "../../../actions/user";
import { addSearchedValue } from '../../../components/Homepage/Search/globalSearch.thunk';
import PickupDelivery from "components/shared/PickupDeliveryHeader";
import { prepareTableChemicalDataFromUtil } from "./chemicalUtils";


const actionTypes = { SEARCHED_ADDTOCART_DATA: 'SEARCHED_ADDTOCART_DATA' };
class ChemicalsSuppliesListingWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBrands: [],
      suppliesAllDetails: props.suppliesAllDetails
        ? props.suppliesAllDetails
        : [],
      redirectToChemicalsPage: false,
      showLocationsPopup: false,
      showRegionLocationsPopup: false,
      currentSort: "Part type (A to Z)",
      searchInput: React.createRef(),
      viewPromotions: false,
      userDetails: [],
      defaultSortChanged: false,
      defaultPageSizeChanged: false,
      showFullView: !_isEmpty(props.viewFullView)
        ? props.viewFullView.isEnable
        : true,
      userPrefView: "",
      filterEnabled: false,
      chemicalListTopArrowClicked: false,
      redirectLink:
        window.location.pathname === "/supplies-list"
          ? `/supplies-catalog`
          : `/`,
      enableDelivery:
      props.checkoutDetails && !_isEmpty(props.checkoutDetails)
			? (props.checkoutDetails[0].shipMethod === "0002" || props.checkoutDetails[0].shipMethod === "0001" || props.checkoutDetails[0].shipMethod === "FIWS")
			  ? false
			  : true
          : this.props.userDetails &&
            this.props.userDetails.shipTos &&
            this.props.userDetails.shipTos.length > 0 &&
            this.props.userDetails.shipTos[0].shipMethod &&
            this.props.userDetails.shipTos[0].shipMethod &&
            (this.props.userDetails.shipTos[0].shipMethod === "0002" ||
              this.props.userDetails.shipTos[0].shipMethod === "0001" || this.props.userDetails.shipTos[0].shipMethod === "FIWS")
          ? false
          : true,
      addToCartWarningPopup: false,
      selectedCartData: [],
      allStores: this.props.allStoreData,
      selectedStore:
        this.props.cartStore && this.props.cartStore != null
          ? this.props.cartStore
          : this.props.userDetails &&
            this.props.userDetails.shipTos &&
            this.props.userDetails.shipTos[0] &&
            this.props.userDetails.shipTos[0].location &&
            this.props.userDetails.shipTos[0].location.storeNumber
          ? this.props.userDetails.shipTos[0].location.storeNumber
          : "",
      sellPartnerId: this.getCartMoreInfo().sellPartnerId
        ? this.getCartMoreInfo().sellPartnerId
        : "",
      selectedStoreName: this.getCartMoreInfo().selectedStoreName
        ? this.getCartMoreInfo().selectedStoreName
        : "",
      shipMethod:
        this.props.cartShipMethod && this.props.cartShipMethod != null
          ? this.props.cartShipMethod
          : this.props.userDetails &&
            this.props.userDetails.shipTos &&
            this.props.userDetails.shipTos.length > 0 &&
            this.props.userDetails.shipTos[0].shipMethod &&
            this.props.userDetails.shipTos[0].shipMethod &&
            (this.props.userDetails.shipTos[0].shipMethod === "001" ||
              this.props.userDetails.shipTos[0].shipMethod === "0002")
          ? "0002"
          : this.props.userDetails.shipTos[0].shipMethod,
      customerShipMethod:
        this.props.userDetails &&
        this.props.userDetails.shipTos &&
        this.props.userDetails.shipTos.length > 0 &&
        this.props.userDetails.shipTos[0].shipMethod &&
        this.props.userDetails.shipTos[0].shipMethod &&
        (this.props.userDetails.shipTos[0].shipMethod === "001" ||
          this.props.userDetails.shipTos[0].shipMethod === "0002")
          ? "0002"
          : this.props.userDetails.shipTos[0].shipMethod,
      cartStore: this.props.cartStore ? this.props.cartStore : "",
      searchFailureValue: "",
      optionsWithDataGlobalSearch: false,
      gloabalSearchStarted: false,
      updatedSearchValue: "",
      sourceAlreadyCalled: { source: "", searchValue: "" },

    };
  }

  initializeValidity = () => {
    const validity = {
      globalSearchMaxLimit: true,
    };
    return validity;
  };

  getCartMoreInfo = () => {
    let { userDetails } = this.props;
    let cartData = [];
    let userData =
      this.props.userDetails &&
      this.props.userDetails.shipTos &&
      this.props.userDetails.shipTos[0] &&
      this.props.userDetails.shipTos[0].location &&
      this.props.userDetails.shipTos[0].location;
    if (
      this.props.cartStore &&
      this.props.cartStore != null &&
      this.props.allStores &&
      this.props.allStores.pickupStores &&
      this.props.cartShipMethod === "0002"
    ) {
      cartData = _find(this.props.allStores.pickupStores, {
        storeNumber: this.props.cartStore,
      });
    }
    if (
      this.props.cartStore &&
      this.props.cartStore != null &&
      this.props.allStores &&
      this.props.allStores.deliveryStores &&
      this.props.cartShipMethod != "0002"
    ) {
      cartData = _find(this.props.allStores.deliveryStores, {
        storeNumber: this.props.cartStore,
      });
    }
    let sellPartnerId = !_isEmpty(cartData)
      ? cartData.sellerPartnerId
      : !_isEmpty(userData) && userData.sellerPartnerId;
    let selectedStoreName = !_isEmpty(cartData)
      ? cartData.storeNumber + " - " + cartData.storeName
      : !_isEmpty(userData) &&
        userData.storeNumber + " - " + userData.storeName;
    return {
      sellerPartnerId: sellPartnerId,
      selectedStoreName: selectedStoreName,
    };
  };

  componentDidMount() {
    const brand = this.props.chemicalsGS.brand;
    if (brand) {
      this.setState({ selectedBrands: [brand.name] });
      let { dispatch, pageSize, pageNumber, sortBy, order, suppliesReqGrpIds } =
        this.props;
      // getSuppliesPartsByGroup(
      // 	suppliesReqGrpIds,
      // 	dispatch,
      // 	pageSize,
      // 	pageNumber,
      // 	brand.name,
      // 	sortBy,
      // 	order
      // );
    }
    const { dispatch, userDetails } = this.props;

    let currentStateSort = this.state.currentSort;
    const userPreferenceDetail = getPreferenceDataFromStorage();
    if (!_isEmpty(userPreferenceDetail)) {
      let { defaultSortChanged, defaultPageSizeChanged } = this.state;
      let currentSort = currentStateSort;
      let userPrefDetail =
        getDefaultDataBasedOnChemicalUSerPref(userPreferenceDetail);
      if (!defaultPageSizeChanged) {
        let currentPageSize = userPrefDetail.pageSize;
        this.setState({
          ...this.state,
          currentPageSize,
        });
      }
      if (!defaultSortChanged) {
        currentSort = userPrefDetail.currentSort;
      }
      let selectedViewType = {};
      selectedViewType = this.setDefaultPreference(
        "Product Information",
        "Presentation Mode"
      );
      const userPreferenceView = selectedViewType;
      let selectedPartTopArrowView = this.setDefaultPreference(
        "Product Information",
        "Page Header"
      );
      const userPreferenceArrowView = selectedPartTopArrowView;
      this.setState({
        ...this.state,
        currentSort,
        showFullView: userPreferenceView === "full",
        userPrefView: userPreferenceView,
        chemicalListTopArrowClicked: userPreferenceArrowView === "collapse",
      });
    }
    let { isTrue, stores } = SupplierRegion(
      userDetails,
      this.props.storeDetails
    );
    if (isTrue) {
      this.setState({ allStores: stores });
    }
    this.checkOptionsWithDataGlobalSearch();

    const viewPromotions = getCookie('viewPromotions') || "true";
    if(viewPromotions === "false") {
      dispatch({
        payload: {},
        type: "COLLAPSE_PROMOTIONS_TAB",
      });
    }
  }
  checkOptionsWithDataGlobalSearch() {
    // Update the local state whenever globalSearch.options changes
    setTimeout(() => {
      const { globalSearch } = this.props;
      const { updatedSearchValue, searchFailureValue, gloabalSearchStarted } = this.state;

      const optionsWithDataGlobalSearch = this.hasDataInOptions(globalSearch?.options);
      this.setState({ optionsWithDataGlobalSearch });

      if (!optionsWithDataGlobalSearch && gloabalSearchStarted && updatedSearchValue === searchFailureValue) {
        this.handleSearchError(searchFailureValue, "resultNotFound");
        this.setState({ gloabalSearchStarted: false });
      }
    }, 2000);
  }
  hasDataInOptions(options) {
    if (options.length > 0 && options[0] && typeof options[0] === "object") {
      const objectAtIndex0 = options[0];
      for (const key in objectAtIndex0) {
        if (objectAtIndex0[key] && objectAtIndex0[key].length > 0) {
          return true; // At least one key-value array has data
        }
      }
    }
    return false; // No key-value array has data
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(this.props, prevProps)) {
      let {
        suppliesAllDetails,
        addToCartWarningPopup,
        selectedCartData,
        shipMethod,
        selectedStore,
      } = this.state;
      let stateChanged = false;
      if (
        !_isEqual(this.props.suppliesAllDetails, prevProps.suppliesAllDetails)
      ) {
        suppliesAllDetails = this.props.suppliesAllDetails;
        stateChanged = true;
      }
      if (!_isEqual(this.props.selectedStore, prevProps.selectedStore)) {
        selectedStore = this.props.selectedStore;
      }
      if (!_isEqual(this.props.shipMethod, prevProps.shipMethod)) {
        shipMethod = this.props.shipMethod;
      }

      if (!_isEqual(this.props.addToCartWarning, prevProps.addToCartWarning)) {
        addToCartWarningPopup = this.props.addToCartWarning;
      }
      if (
        !_isEqual(this.props.selectedAddCartData, prevProps.selectedAddCartData)
      ) {
        selectedCartData = _cloneDeep(this.props.selectedAddCartData);
      }
      if (this.props.globalSearch.options !== prevProps.globalSearch.options) {
        this.checkOptionsWithDataGlobalSearch();
      }
      this.setState({
        ...this.state,
        addToCartWarningPopup: addToCartWarningPopup,
        selectedCartData: selectedCartData,
        shipMethod,
        selectedStore,
      });
      if (stateChanged) {
        this.setState({
          suppliesAllDetails,
        });
      }

      let userDetails = this.props.userDetails;
      let currentStateSort = this.state.currentSort;
      const userPreferenceDetail = getPreferenceDataFromStorage();
      if (!_isEmpty(userPreferenceDetail)) {
        let { defaultSortChanged, defaultPageSizeChanged } = this.state;
        let userPrefDetail =
          getDefaultDataBasedOnChemicalUSerPref(userPreferenceDetail);
        let currentSort = currentStateSort;
        if (!defaultPageSizeChanged) {
          let currentPageSize = userPrefDetail.pageSize;
          this.setState({
            ...this.state,
            currentPageSize,
          });
        }
        if (!defaultSortChanged) {
          currentSort = userPrefDetail.currentSort;
        }
        let selectedViewType = {};
        selectedViewType = this.setDefaultPreference(
          "Product Information",
          "Presentation Mode"
        );
        let selectedPartTopArrowView = this.setDefaultPreference(
          "Product Information",
          "Page Header"
        );
        const userPreferenceArrowView = selectedPartTopArrowView;
        const userPreferenceView = selectedViewType;

        if (
          !_isEmpty(this.props.viewFullView) &&
          !this.props.viewFullView.isPrefenceSet
        ) {
          this.props.dispatch({
            data: {
              isEnable: userPreferenceView === "full",
              isPrefenceSet: true,
            },
            type: "CATALOG_VIEW_TOGGLE",
          });
          this.setState({
            ...this.state,
            showFullView: userPreferenceView === "full",
            userPrefView: userPreferenceView,
            chemicalListTopArrowClicked: userPreferenceArrowView === "collapse",
          });
        }

        this.setState({
          ...this.state,
          currentSort,
        });
      }
    }
  }
  componentWillUnmount() {
    this.props.dispatch({
      payload: {
        selectedPartType: null,
      },
      type: "SELECTED_PART_TYPE",
    });
  }
  getCartMoreInfo = () => {
    let { userDetails } = this.props;
    let cartData = [];
    let userData =
      this.props.userDetails &&
      this.props.userDetails.shipTos &&
      this.props.userDetails.shipTos[0] &&
      this.props.userDetails.shipTos[0].location &&
      this.props.userDetails.shipTos[0].location;
    if (
      this.props.cartStore &&
      this.props.cartStore != null &&
      this.props.allStoreData &&
      this.props.allStoreData.pickupStores &&
      this.props.cartShipMethod === "0002"
    ) {
      cartData = _find(this.props.allStoreData.pickupStores, {
        storeNumber: this.props.cartStore,
      });
    }
    if (
      this.props.cartStore &&
      this.props.cartStore != null &&
      this.props.allStoreData &&
      this.props.allStoreData.deliveryStores &&
      this.props.cartShipMethod != "0002"
    ) {
      cartData = _find(this.props.allStoreData.deliveryStores, {
        storeNumber: this.props.cartStore,
      });
    }
    let sellPartnerId = !_isEmpty(cartData)
      ? cartData.sellerPartnerId
      : !_isEmpty(userData) && userData.sellerPartnerId;
    let selectedStoreName = !_isEmpty(cartData)
      ? cartData.storeNumber + " - " + cartData.storeName
      : !_isEmpty(userData) &&
        userData.storeNumber + " - " + userData.storeName;
    return {
      sellPartnerId: sellPartnerId,
      selectedStoreName: selectedStoreName,
    };
  };

  setDefaultPreference = (code, text) => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let selectedFilter = {};
    if (!_isEmpty(userPreferenceDetail)) {
      selectedFilter = _find(userPreferenceDetail, {
        code: code,
        text: text,
      });
    }
    return (
      selectedFilter && selectedFilter.answer && selectedFilter.answer.code
    );
  };

  handleViewTypeChange = (enableDlvery) => {
    let {
      selectedStore,
      sellPartnerId,
      selectedStoreName,
      customerShipMethod,
    } = this.state;
    let { userDetails, dispatch } = this.props;
    let shipMethod = enableDlvery !== "isPickup"
    ? userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos.length > 0 &&
      userDetails.shipTos[0].shipMethod &&
      userDetails.shipTos[0].shipMethod
    : "0002";
    let defaultSelectedStore =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeNumber
        ? userDetails.shipTos[0].location.storeNumber
        : "";
    let defaultSellPartnerId =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.sellerPartnerId
        ? userDetails.shipTos[0].location.sellerPartnerId
        : "";
    let defaultSelectedStoreName =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeName
        ? userDetails.shipTos[0].location.storeNumber +
          " - " +
          userDetails.shipTos[0].location.storeName
        : "Select Store";
    this.setState({
      ...this.state,
      shipMethod: shipMethod,
      enableDelivery: enableDlvery !== "isPickup" ? true : false,
      selectedStore: defaultSelectedStore,
      // selectedStoreName: defaultSelectedStoreName,
      sellPartnerId: defaultSellPartnerId,
    });
    //if(!enableDlvery) {
    this.handleByStore("Delivery", [], shipMethod);
    //}
  };

  handleByStore = (key, storeInfo, selectedShipMethod) => {
    let {
      suppliesReqAvailability,
      pageNumber,
      brandCodes,
      brandsSelected,
      brandsView,
      dispatch,
      pageSize,
      pimCategoryIds,
      order,
      sortBy,
      suppliesOffers,
      suppliesReqGrpIds,
      reqSpecs,
      reqSizeQty,
      reqCaseQty,
      userDetails,
    } = this.props;
    let { shipMethod, allStores } = this.state;
    // dispatch({
    // 	payload: {
    // 			shipMethod: shipMethod,
    // 	},
    // 	type: 'CART_SHIPMETHOD'
    // });
    let { enableDelivery } = this.state;
    let filteredStores =
      allStores && enableDelivery
        ? allStores.deliveryStores
        : allStores.pickupStores;
    let shipMethd = selectedShipMethod ? selectedShipMethod : shipMethod;
    let store =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeNumber
        ? userDetails.shipTos[0].location.storeNumber
        : "";
    let sellPartnerId =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.sellerPartnerId
        ? userDetails.shipTos[0].location.sellerPartnerId
        : "";
    if (key !== "Delivery") {
      sellPartnerId = key ? key : "6036530010347581";
      let filteredStore = _find(
        filteredStores,
        (storeData) => storeData.sellerPartnerId === key
      );
      store = filteredStore.storeNumber;
      this.setState({
        ...this.state,
        selectedStore: filteredStore.storeNumber,
        sellPartnerId: filteredStore.sellerPartnerId,
        selectedStoreName: filteredStore.storeName,
      });
    }
    if (brandsView) {
      dispatch(
        getChemPartByCat(
          pimCategoryIds,
          brandCodes,
          suppliesReqGrpIds,
          pageNumber,
          pageSize,
          sortBy,
          order,
          brandsSelected,
          suppliesReqAvailability,
          suppliesOffers,
          reqSpecs,
          reqSizeQty,
          reqCaseQty,
          sellPartnerId,
          store,
          shipMethd
        )
      );
    } else {
      dispatch(
        getChemPartByGrp(
          suppliesReqGrpIds,
          pageNumber,
          pageSize,
          sortBy,
          order,
          brandsSelected,
          suppliesReqAvailability,
          suppliesOffers,
          reqSpecs,
          reqSizeQty,
          reqCaseQty,
          [],
          sellPartnerId,
          store,
          shipMethd
        )
      );
    }
  };

  renderNoDataState = () => {
    return (
      <Card className="text-center">
        <Card.Header className="xlp-primary-banner">No Parts Found</Card.Header>
        <Card.Body>
          <Card.Title>
            We couldn't find the parts that you were looking for
          </Card.Title>

          <Button
            className="xlp-submit-btn"
            onClick={() => {
              this.setState({ redirectToChemicalsPage: true });
            }}
          >
            Back to Homepage
          </Button>
        </Card.Body>
      </Card>
    );
  };
  handlePageSizeDropdown = (k, e) => {
    let {
      brandCodes,
      brandsSelected,
      brandsView,
      dispatch,
      suppliesReqGrpIds,
      currentPage,
      sortBy,
      order,
      pimCategoryIds,
      suppliesReqAvailability,
      suppliesOffers,
      reqSpecs,
      reqSizeQty,
      reqCaseQty,
      catalogFilter,
    } = this.props;
    if (brandsView) {
      dispatch(
        getChemPartByCat(
          pimCategoryIds,
          brandCodes,
          suppliesReqGrpIds,
          currentPage,
          k,
          sortBy,
          order,
          brandsSelected,
          suppliesReqAvailability,
          suppliesOffers,
          reqSpecs,
          reqSizeQty,
          reqCaseQty,
          catalogFilter
        )
      );
    } else {
      let { defaultPageSizeChanged } = this.state;
      defaultPageSizeChanged = true;
      dispatch(
        getChemPartByGrp(
          suppliesReqGrpIds,
          currentPage,
          k,
          sortBy,
          order,
          brandsSelected,
          suppliesReqAvailability,
          suppliesOffers,
          reqSpecs,
          reqSizeQty,
          reqCaseQty
        )
      );
      this.setState({
        ...this.state,
        defaultPageSizeChanged: defaultPageSizeChanged,
      });
    }
  };

  handlePageChange = (clickedPage) => {
    let {
      suppliesReqAvailability,
      brandCodes,
      brandsSelected,
      brandsView,
      dispatch,
      pageSize,
      pimCategoryIds,
      order,
      sortBy,
      suppliesOffers,
      suppliesReqGrpIds,
      reqSpecs,
      reqSizeQty,
      reqCaseQty,
      catalogFilter,
    } = this.props;
    if (brandsView) {
      dispatch(
        getChemPartByCat(
          pimCategoryIds,
          brandCodes,
          suppliesReqGrpIds,
          clickedPage,
          pageSize,
          sortBy,
          order,
          brandsSelected,
          suppliesReqAvailability,
          suppliesOffers,
          reqSpecs,
          reqSizeQty,
          reqCaseQty,
          catalogFilter
        )
      );
    } else {
      dispatch(
        getChemPartByGrp(
          suppliesReqGrpIds,
          clickedPage,
          pageSize,
          sortBy,
          order,
          brandsSelected,
          suppliesReqAvailability,
          suppliesOffers,
          reqSpecs,
          reqSizeQty,
          reqCaseQty
        )
      );
    }
  };

  handleBrandSelect = async(value) => {
    let { selectedBrands } = this.state;
    let { dispatch, pageSize, pageNumber, sortBy, order, suppliesReqGrpIds } =
      this.props;
    selectedBrands.includes(value)
      ? _remove(selectedBrands, (b) => b === value)
      : selectedBrands.push(value);
      try{
        const response = await dispatch(getChemPartByGrp(
        suppliesReqGrpIds,
        pageNumber,
        pageSize,
        sortBy,
        order,
        selectedBrands.toString()
      )
    );
    const totalItems = response?.data?.totalItems || 0;
    setTimeout(()=>{
      if (totalItems <= 0) {
        this.handleSearchError(JSON.stringify(this.props.selectedPartType,"resultNotFound"));
      }else{
        this.props.dispatch(addSearchedValue(this.props.selectedPartType))
        this.isSearchValueAddedToCart(this.props.selectedPartType);
      }
    },[1000])
    }catch(error){
      console.log('error',error)
      this.handleSearchError(this.props.selectedPartType,"resultNotFound");
    }

    this.setState({
      ...this.state,
      selectedBrands,
    });
  };

  handleSortBy = (key, e) => {
    let {
      availability,
      brandCodes,
      brandsSelected,
      brandsView,
      currentPage,
      dispatch,
      pageSize,
      pimCategoryIds,
      suppliesReqGrpIds,
      suppliesOffers,
      reqSpecs,
      reqSizeQty,
      reqCaseQty,
      catalogFilter,
    } = this.props;
    let { currentSort, defaultSortChanged } = this.state;
    defaultSortChanged = true;
    let sortBy = "partType";
    let order = null;
    currentSort = e.target.innerText;
    switch (key) {
      case "MclDefault":
        sortBy = "mcl-default";
        order = "ASC";
        break;
      case "PartTypeASC":
        sortBy = "partType";
        order = "ASC";
        break;
      case "PartTypeDESC":
        sortBy = "partType";
        order = "DESC";
        break;
      case "BrandASC":
        sortBy = "brand";
        order = "ASC";
        break;
      case "BrandDESC":
        sortBy = "brand";
        order = "DESC";
        break;
      case "PriceASC":
        sortBy = "price";
        order = "ASC";
        break;
      case "PriceDESC":
        sortBy = "price";
        order = "DESC";
        break;
      default:
        break;
    }
    if (brandsView) {
      dispatch(
        getChemPartByCat(
          pimCategoryIds,
          brandCodes,
          suppliesReqGrpIds,
          currentPage,
          pageSize,
          sortBy,
          order,
          brandsSelected,
          availability,
          suppliesOffers,
          reqSpecs,
          reqSizeQty,
          reqCaseQty,
          catalogFilter
        )
      );
    } else {
      dispatch(
        getChemPartByGrp(
          suppliesReqGrpIds,
          currentPage,
          pageSize,
          sortBy,
          order,
          brandsSelected,
          availability,
          suppliesOffers,
          reqSpecs,
          reqSizeQty,
          reqCaseQty,
          catalogFilter
        )
      );
    }

    this.setState({
      ...this.state,
      currentSort: currentSort,
      defaultSortChanged: defaultSortChanged,
    });
  };

  getTypes = (type) => {
    switch (type) {
      case "chemicalBrands":
        return "brand";
      case "chemicalParts":
        return "partDescription";
      case "chemicalGroups":
        return "group";
      case "chemicalCategories":
        return "category";
      default:
        break;
    }
  };

  setOption = (option, type) => {
    let _option = Object.assign({}, option);
    _option.type = this.getTypes(type);
    return _option;
  };

  getGroupLabels = (label) => {
    switch (label) {
      case "chemicalBrands":
        return "Brands";
      case "chemicalParts":
        return "Part Descriptions";
      case "chemicalGroups":
        return "Groups";
      case "chemicalCategories":
        return "Categories";
      default:
        break;
    }
  };

  onKeyDown = (event) => {
    if (
      event.key !== "Backspace" &&
      event.key !== "Tab" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight" &&
      event.target.value.length >= 100
    ) {
      event.preventDefault();
    }
  };
  separtionCodeForBrand = (data, e) => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let userPrefDetail =
      getDefaultDataBasedOnChemicalUSerPref(userPreferenceDetail);
    let { currentSort, defaultSortChanged } = this.state;
    let rows = [];
    if (currentSort == "Brand (A to Z)" || currentSort == "Brand (Z to A)") {
      rows = this.brandSepartion(data);
    }
    if (
      currentSort == "Part type (A to Z)" ||
      currentSort == "Part type (Z to A)"
    ) {
      rows = this.partTypeSepartion(data);
    }
    if (currentSort == "Price (Low to High)") {
      rows = this.priceLowToHighTableView(data);
    }
    if (currentSort == "Price (High to Low)") {
      rows = this.priceHighToLowTableView(data);
    }

    return rows;
  };

  priceLowToHighTableView = (rowsdata) => {
    const { minPrice, maxPrice } = this.props;
    let minpriceCount = Math.floor(minPrice);
    let totalCount = (maxPrice + minPrice) / 5;
    let roundUptotalCount = Math.ceil(totalCount);
    let rowArray = [];
    let rowArray1st = [];
    let rowArray2nd = [];
    let rowArray3rd = [];
    let rowArray4th = [];
    let rowArray5th = [];
    let outofstock1starray = [];
    let outofstock2ndarray = [];
    let outofstock3rdarray = [];
    let outofstock4tharray = [];
    let outofstock5tharray = [];
    rowsdata.map((part, index) => {
      if (part.modifiedcostPrice <= roundUptotalCount) {
        if (part.sortAvailability.length <= 0) {
          outofstock1starray.push(part);
        } else {
          rowArray1st.push(part);
        }
      }
      if (
        part.modifiedcostPrice > roundUptotalCount &&
        part.modifiedcostPrice <= 2 * roundUptotalCount
      ) {
        if (part.sortAvailability.length <= 0) {
          outofstock2ndarray.push(part);
        } else {
          rowArray2nd.push(part);
        }
      }
      if (
        part.modifiedcostPrice > 2 * roundUptotalCount &&
        part.modifiedcostPrice <= 3 * roundUptotalCount
      ) {
        if (part.sortAvailability.length <= 0) {
          outofstock3rdarray.push(part);
        } else {
          rowArray3rd.push(part);
        }
      }
      if (
        part.modifiedcostPrice > 3 * roundUptotalCount &&
        part.modifiedcostPrice <= 4 * roundUptotalCount
      ) {
        if (part.sortAvailability.length <= 0) {
          outofstock4tharray.push(part);
        } else {
          rowArray4th.push(part);
        }
      }
      if (
        part.modifiedcostPrice > 4 * roundUptotalCount &&
        part.modifiedcostPrice <= 5 * roundUptotalCount
      ) {
        if (part.sortAvailability.length <= 0) {
          outofstock5tharray.push(part);
        } else {
          rowArray5th.push(part);
        }
      }
    });

    this.priceSeparationTableView(
      "$" + minpriceCount + " - " + "$" + roundUptotalCount,
      rowArray1st,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + roundUptotalCount + " - " + "$" + 2 * roundUptotalCount,
      rowArray2nd,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 2 * roundUptotalCount + " - " + "$" + 3 * roundUptotalCount,
      rowArray3rd,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 3 * roundUptotalCount + " - " + "$" + 4 * roundUptotalCount,
      rowArray4th,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 4 * roundUptotalCount + " - " + "$" + 5 * roundUptotalCount,
      rowArray5th,
      rowArray
    );

    this.priceSeparationTableView(
      "$" + minpriceCount + " - " + "$" + roundUptotalCount,
      outofstock1starray,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + roundUptotalCount + " - " + "$" + 2 * roundUptotalCount,
      outofstock2ndarray,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 2 * roundUptotalCount + " - " + "$" + 3 * roundUptotalCount,
      outofstock3rdarray,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 3 * roundUptotalCount + " - " + "$" + 4 * roundUptotalCount,
      outofstock4tharray,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 4 * roundUptotalCount + " - " + "$" + 5 * roundUptotalCount,
      outofstock5tharray,
      rowArray
    );
    return rowArray;
  };
  priceHighToLowTableView = (rowsdata) => {
    const { minPrice, maxPrice } = this.props;

    let minpriceCount = Math.floor(minPrice);
    let totalCount = (maxPrice + minPrice) / 5;
    let roundUptotalCount = Math.ceil(totalCount);
    let rowArray = [];
    let rowArray1st = [];
    let rowArray2nd = [];
    let rowArray3rd = [];
    let rowArray4th = [];
    let rowArray5th = [];
    let outofstock1starray = [];
    let outofstock2ndarray = [];
    let outofstock3rdarray = [];
    let outofstock4tharray = [];
    let outofstock5tharray = [];
    let outofstockMainArry = [];
    rowsdata.map((part, index) => {
      if (part.modifiedcostPrice <= roundUptotalCount) {
        if (part.sortAvailability && part.sortAvailability.length <= 0) {
          outofstock1starray.push(part);
        } else {
          rowArray1st.push(part);
        }
      }
      if (
        part.modifiedcostPrice > roundUptotalCount &&
        part.modifiedcostPrice <= 2 * roundUptotalCount
      ) {
        if (part.sortAvailability && part.sortAvailability.length <= 0) {
          outofstock2ndarray.push(part);
        } else {
          rowArray2nd.push(part);
        }
      }
      if (
        part.modifiedcostPrice > 2 * roundUptotalCount &&
        part.modifiedcostPrice <= 3 * roundUptotalCount
      ) {
        if (part.sortAvailability && part.sortAvailability.length <= 0) {
          outofstock3rdarray.push(part);
        } else {
          rowArray3rd.push(part);
        }
      }
      if (
        part.modifiedcostPrice > 3 * roundUptotalCount &&
        part.modifiedcostPrice <= 4 * roundUptotalCount
      ) {
        if (part.sortAvailability && part.sortAvailability.length <= 0) {
          outofstock4tharray.push(part);
        } else {
          rowArray4th.push(part);
        }
      }
      if (
        part.modifiedcostPrice > 4 * roundUptotalCount &&
        part.modifiedcostPrice <= 5 * roundUptotalCount
      ) {
        if (part.sortAvailability && part.sortAvailability.length <= 0) {
          outofstock5tharray.push(part);
        } else {
          rowArray5th.push(part);
        }
      }
    });

    this.priceSeparationTableView(
      "$" + 5 * roundUptotalCount + " - " + "$" + 4 * roundUptotalCount,
      rowArray5th,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 4 * roundUptotalCount + " - " + "$" + 3 * roundUptotalCount,
      rowArray4th,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 3 * roundUptotalCount + " - " + "$" + 2 * roundUptotalCount,
      rowArray3rd,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 2 * roundUptotalCount + " - " + "$" + roundUptotalCount,
      rowArray2nd,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + roundUptotalCount + " - " + "$" + minpriceCount,
      rowArray1st,
      rowArray
    );

    this.priceSeparationTableView(
      "$" + 5 * roundUptotalCount + " - " + "$" + 4 * roundUptotalCount,
      outofstock5tharray,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 4 * roundUptotalCount + " - " + "$" + 3 * roundUptotalCount,
      outofstock4tharray,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 3 * roundUptotalCount + " - " + "$" + 2 * roundUptotalCount,
      outofstock3rdarray,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + 2 * roundUptotalCount + " - " + "$" + roundUptotalCount,
      outofstock2ndarray,
      rowArray
    );
    this.priceSeparationTableView(
      "$" + roundUptotalCount + " - " + "$" + minpriceCount,
      outofstock1starray,
      rowArray
    );
    return rowArray;
  };
  priceSeparationTableView = (br, array, rowArray) => {
    if (array.length > 0) {
      let countItems = array.length;
      rowArray.push({
        items: countItems,
        groupby: true,
        availabilities: [],
        availability: countItems,
        brand: [
          {
            code: "",
            id: "",
            imageUrl: "",
            name: "",
          },
        ],
        description: "",
        extendedPrice: "",
        hiddenAvail: false,
        links: "",
        listprice: "",
        part: {
          alternate: [],
          brands: [
            {
              code: "",
              id: "",
              imageUrl: "",
              name: "",
            },
          ],
          caseQty: "",
          groupID: "",
          imageUrl: "",
          partDescription: "",
          partDescriptionID: "",
          partSKU: br,
          pimID: "",
          pricingAvailability: {
            availability: [
              {
                availableDuration: "",
                availableQty: "",
                availableTime: "",
                code: "",
                storeAvailabilities: [
                  {
                    store: "",
                    storeQty: "",
                    storeTime: "",
                  },
                ],
              },
            ],
            bundleToolTip: null,
            buyQtyWarning: "",
            costPrice: "",
            extendedCostPrice: "",
            extendedListPrice: "",
            familyToolTip: null,
            id: "",
            inStock: true,
            itemSpecific: "",
            listPrice: "",
            mfgCode: "",
            packCode: "",
            packQty: "",
            partNum: "",
            qtyBuyInc: "",
            qtyPerVeh: "",
            qtyPriceBreakUp: [],
            status: "",
            toolTips: [],
          },
          sizeQty: "",
          specification: "",
          substitute: [],
        },
        qtypervehicle: "",
        totalAvailable: "",
        validationRed: false,
        yourprice: "",
        rowMoreInfo: "",
      });
      array.map((v, index) => {
        rowArray.push(v);
      });
    }
  };
  defaultSepartionView = (rowsdata) => {
    let rowArray = [];
    rowsdata.map((part, index) => {
      rowArray.push(part);
    });

    return rowArray;
  };
  brandSepartion = (rowsdata) => {
    let sortedBrandArray = [];
    let rowArray = [];
    let outofStockRows = [];
    let outofstockmainarray = [];
    rowsdata.map((row, index) => {
      let brandName = row.brand[0].name.toUpperCase();
      if (
        sortedBrandArray === null ||
        sortedBrandArray === "" ||
        sortedBrandArray === undefined
      ) {
        sortedBrandArray.push(brandName);
      } else if (
        sortedBrandArray !== null &&
        row.sortAvailability.length > 0 &&
        !sortedBrandArray.includes(brandName)
      ) {
        sortedBrandArray.push(brandName);
      } else if (
        row.sortAvailability.length <= 0 &&
        outofStockRows !== null &&
        !outofStockRows.includes(brandName)
      ) {
        outofStockRows.push(brandName);
      }
    });
    sortedBrandArray.map((br, index) => {
      let countItems;
      const countBrandItems = rowsdata.filter(
        (item) =>
          item.sortAvailability.length > 0 &&
          item.brand[0].name.toUpperCase() === br
      ).length;
      countItems = countBrandItems;
      rowArray.push({
        items: countItems,
        groupby: true,
        availabilities: [],
        availability: countItems,
        brand: [
          {
            code: "",
            id: "",
            imageUrl: "",
            name: "",
          },
        ],
        description: "",
        extendedPrice: "",
        hiddenAvail: false,
        links: "",
        listprice: "",
        part: {
          alternate: [],
          brands: [
            {
              code: "",
              id: "",
              imageUrl: "",
              name: "",
            },
          ],
          caseQty: "",
          groupID: "",
          imageUrl: "",
          partDescription: "",
          partDescriptionID: "",
          partSKU: br,
          pimID: "",
          pricingAvailability: {
            availability: [
              {
                availableDuration: "",
                availableQty: "",
                availableTime: "",
                code: "",
                storeAvailabilities: [
                  {
                    store: "",
                    storeQty: "",
                    storeTime: "",
                  },
                ],
              },
            ],
            bundleToolTip: null,
            buyQtyWarning: "",
            costPrice: "",
            extendedCostPrice: "",
            extendedListPrice: "",
            familyToolTip: null,
            id: "",
            inStock: true,
            itemSpecific: "",
            listPrice: "",
            mfgCode: "",
            packCode: "",
            packQty: "",
            partNum: "",
            qtyBuyInc: "",
            qtyPerVeh: "",
            qtyPriceBreakUp: [],
            status: "",
            toolTips: [],
          },
          sizeQty: "",
          specification: "",
          substitute: [],
        },
        qtypervehicle: "",
        totalAvailable: "",
        validationRed: false,
        yourprice: "",
        rowMoreInfo: "",
      });

      rowsdata.map((part, index) => {
        let brandName = part.brand[0].name;
        if (br == brandName.toUpperCase() && part.sortAvailability.length > 0) {
          rowArray.push(part);
        }
      });
    });

    outofStockRows.map((br, index) => {
      let countItems;
      const countBrandItems = rowsdata.filter(
        (item) =>
          item.sortAvailability.length <= 0 &&
          item.brand[0].name.toUpperCase() === br
      ).length;
      countItems = countBrandItems;
      outofstockmainarray.push({
        items: countItems,
        groupby: true,
        availabilities: [],
        availability: countItems,
        brand: [
          {
            code: "",
            id: "",
            imageUrl: "",
            name: "",
          },
        ],
        description: "",
        extendedPrice: "",
        hiddenAvail: false,
        links: "",
        listprice: "",
        part: {
          alternate: [],
          brands: [
            {
              code: "",
              id: "",
              imageUrl: "",
              name: "",
            },
          ],
          caseQty: "",
          groupID: "",
          imageUrl: "",
          partDescription: "",
          partDescriptionID: "",
          partSKU: br,
          pimID: "",
          pricingAvailability: {
            availability: [
              {
                availableDuration: "",
                availableQty: "",
                availableTime: "",
                code: "",
                storeAvailabilities: [
                  {
                    store: "",
                    storeQty: "",
                    storeTime: "",
                  },
                ],
              },
            ],
            bundleToolTip: null,
            buyQtyWarning: "",
            costPrice: "",
            extendedCostPrice: "",
            extendedListPrice: "",
            familyToolTip: null,
            id: "",
            inStock: true,
            itemSpecific: "",
            listPrice: "",
            mfgCode: "",
            packCode: "",
            packQty: "",
            partNum: "",
            qtyBuyInc: "",
            qtyPerVeh: "",
            qtyPriceBreakUp: [],
            status: "",
            toolTips: [],
          },
          sizeQty: "",
          specification: "",
          substitute: [],
        },
        qtypervehicle: "",
        totalAvailable: "",
        validationRed: false,
        yourprice: "",
        rowMoreInfo: "",
      });

      rowsdata.map((part, index) => {
        let brandName = part.brand[0].name;
        if (
          br == brandName.toUpperCase() &&
          part.sortAvailability.length <= 0
        ) {
          outofstockmainarray.push(part);
        }
      });
    });
    return rowArray.concat(outofstockmainarray);
  };

  partTypeSepartion = (rowsdata) => {
    let rowArray = [];
    rowsdata.map((part, index) => {
      rowArray.push(part);
    });

    return rowArray;
  };
  renderTableItems = () => {
    let { currentPage, totalPages, suppliesAllDetails } = this.props;
    let { selectedStore, sellPartnerId, shipMethod } = this.state;
    if (!_isEmpty(suppliesAllDetails)) {
      let productArray = [];
      let rows = [];
      let rowsMoreDetails = [];
      let autofocusIndexNumber = 0;
      let iterator = true;
      if (suppliesAllDetails.length > 0) {
        suppliesAllDetails.map((magicValue, index) => {
          if (iterator) {
            autofocusIndexNumber = index;
            iterator = false;
          }
        });
        suppliesAllDetails.map((part, index) => {
          let appendPart =
            (part.alternate && part.alternate.length > 0) ||
            (part.substitute && part.substitute.length > 0);
          let altPartNumsArr = [];
          let subPartNumsArr = [];
          const altPartNums =
            part.alternate && part.alternate.length > 0
              ? part.alternate.map((alt) => {
                  altPartNumsArr.push(alt.partSKU);
                })
              : "";
          const subPartNums =
            part.substitute && part.substitute.length > 0
              ? part.substitute.map((sub) => {
                  subPartNumsArr.push(sub.partSKU);
                })
              : "";
          rows.push({
            availability: 1,
            part: part, //part,
            description: part.partDescription,
            brand: part.brands,
            links: "link",
            modifiedcostPrice:
              part.pricingAvailability && part.pricingAvailability.costPrice,
            sortAvailability:
              part.pricingAvailability && part.pricingAvailability.availability,
            yourprice:
              part.pricingAvailability && part.pricingAvailability.costPrice,
            listprice:
              part.pricingAvailability && part.pricingAvailability.listPrice,
            corePrice:
              part.pricingAvailability && part.pricingAvailability.corePrice,
            coreListPrice:
              part.pricingAvailability &&
              part.pricingAvailability.coreListPrice,
            extendedPrice: 0,
            qtypervehicle: part.sizeQty,
            qtyBuyInc:
              part.pricingAvailability && part.pricingAvailability.qtyBuyInc,
            availabilities: [],
            hiddenAvail: false,
            validationRed: false,
            autoFocus: index == autofocusIndexNumber ? true : false,
            totalAvailable: 0,
            rowMoreInfo: {
              partDetails: { part },
              partType: "chemical",
              appendPart: { appendPart },
              altPartNums: altPartNumsArr.join(","),
              subPartNums: subPartNumsArr.join(","),
            },
          });

          rowsMoreDetails.push({
            partDetails: { part },
            partType: "chemical",
            appendPart: { appendPart },
            altPartNums: altPartNumsArr.join(","),
            subPartNums: subPartNumsArr.join(","),
          });

          //alternate parts
          if (part.alternate && part.alternate.length > 0) {
            part.alternate.map((altPart, index) => {
              let customAltpart = _cloneDeep(altPart);
              customAltpart.brands[0].name = part.brands[0].name;
              customAltpart.modifiedcostPrice =
                part.pricingAvailability.costPrice;
              customAltpart.sortAvailability =
                part.pricingAvailability.availability;
              let appendAltPart =
                index !== part.alternate.length - 1 ||
                (part.substitute && part.substitute.length > 0);
              rows.push({
                availability: 1,
                part: altPart, //part,
                description: altPart.partDescription,
                brand: customAltpart.brands,
                modifiedcostPrice: customAltpart.modifiedcostPrice,
                sortAvailability: customAltpart.sortAvailability,
                links: "link",
                yourprice:
                  altPart.pricingAvailability &&
                  altPart.pricingAvailability.costPrice,
                listprice:
                  altPart.pricingAvailability &&
                  altPart.pricingAvailability.listPrice,
                corePrice:
                  altPart.pricingAvailability &&
                  altPart.pricingAvailability.corePrice,
                coreListPrice:
                  altPart.pricingAvailability &&
                  altPart.pricingAvailability.coreListPrice,
                extendedPrice: 0,
                qtypervehicle: altPart.sizeQty,
                qtyBuyInc:
                  altPart.pricingAvailability &&
                  altPart.pricingAvailability.qtyBuyInc,
                availabilities: [],
                hiddenAvail: false,
                validationRed: false,
                totalAvailable: 0,
                rowMoreInfo: {
                  partDetails: { altPart },
                  isAltType: true,
                  appendPart: appendAltPart,
                },
              });

              rowsMoreDetails.push({
                partDetails: { altPart },
                isAltType: true,
                appendPart: appendAltPart,
              });
            });
          }
          //substitute parts
          if (part.substitute && part.substitute.length > 0) {
            part.substitute.map((subPart, index) => {
              let customAltpart = _cloneDeep(subPart);
              customAltpart.brands[0].name = part.brands[0].name;
              customAltpart.modifiedcostPrice =
                part.pricingAvailability.costPrice;
              customAltpart.sortAvailability =
                part.pricingAvailability.availability;
              let appendSubPart =
                subPart.substitute && index !== subPart.substitute.length - 1;
              rows.push({
                availability: 1,
                part: subPart, //part,
                description: subPart.partDescription,
                brand: customAltpart.brands,
                sortAvailability: customAltpart.sortAvailability,
                modifiedcostPrice: customAltpart.modifiedcostPrice,
                links: "link",
                coreListPrice:
                  subPart.pricingAvailability &&
                  subPart.pricingAvailability.coreListPrice,
                corePrice:
                  subPart.pricingAvailability &&
                  subPart.pricingAvailability.corePrice,
                yourprice:
                  subPart.pricingAvailability &&
                  subPart.pricingAvailability.costPrice,
                listprice:
                  subPart.pricingAvailability &&
                  subPart.pricingAvailability.listPrice,
                extendedPrice: 0,
                qtypervehicle: subPart.sizeQty,
                qtyBuyInc:
                  subPart.pricingAvailability &&
                  subPart.pricingAvailability.qtyBuyInc,
                availabilities: [],
                hiddenAvail: false,
                validationRed: false,
                totalAvailable: 0,
                rowMoreInfo: {
                  partDetails: subPart,
                  isSubType: true,
                  appendPart: appendSubPart,
                },
              });
              rowsMoreDetails.push({
                partDetails: subPart,
                isSubType: true,
                appendPart: appendSubPart,
              });
            });
          }
        });
        let partTypeAndBrandSepartion = this.separtionCodeForBrand(rows);
        productArray.push(
          <TableViewWrapper
            userDetails={this.props.userDetails}
            rows={partTypeAndBrandSepartion}
            rowsMoreDetails={rowsMoreDetails}
            selectedStore={selectedStore}
            sellPartnerId={sellPartnerId}
            shipMethod={shipMethod}
          />
        );
        productArray.push(
          <PaginationComponent
            handlePageChange={this.handlePageChange}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        );
      } else {
        return this.renderNoDataState();
      }
      return productArray;
    } else {
      return this.renderNoDataState();
    }
  };
  separtionCodechemicalView = (data, e) => {
    let { currentSort, defaultSortChanged } = this.state;
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let userPrefDetail =
      getDefaultDataBasedOnChemicalUSerPref(userPreferenceDetail);
    let rows = [];
    if (currentSort == "Brand (A to Z)" || currentSort == "Brand (Z to A)") {
      rows = this.brandSepartionchemical(data);
    }
    if (
      currentSort == "Part type (A to Z)" ||
      currentSort == "Part type (Z to A)"
    ) {
      rows = this.partTypeSepartionchemical(data);
    }
    if (currentSort == "Price (Low to High)") {
      rows = this.priceLowToHigh(data);
    }
    if (currentSort == "Price (High to Low)") {
      rows = this.PriceHighToLow(data);
    }

    return rows;
  };

  priceLowToHigh = (rowsdata) => {
    const { minPrice, maxPrice } = this.props;
    // const totals = rowsdata.map(x => x.pricingAvailability.listPrice);
    // let maxPrice = Math.max(...totals);
    // let minPrice = Math.min(...totals);
    let minpriceCount = Math.floor(minPrice);
    let totalCount = (maxPrice + minPrice) / 5;
    let roundUptotalCount = Math.ceil(totalCount);
    let rowArray = [];
    let rowArray1st = [];
    let rowArray2nd = [];
    let rowArray3rd = [];
    let rowArray4th = [];
    let rowArray5th = [];
    let outofstock1starray = [];
    let outofstock2ndarray = [];
    let outofstock3rdarray = [];
    let outofstock4tharray = [];
    let outofstock5tharray = [];
    rowsdata.map((part, index) => {
      if (part.pricingAvailability.costPrice <= roundUptotalCount) {
        if (part.pricingAvailability.availability.length <= 0) {
          outofstock1starray.push(part);
        } else {
          rowArray1st.push(part);
        }
      }
      if (
        part.pricingAvailability.costPrice > roundUptotalCount &&
        part.pricingAvailability.costPrice <= 2 * roundUptotalCount
      ) {
        if (part.pricingAvailability.availability.length <= 0) {
          outofstock2ndarray.push(part);
        } else {
          rowArray2nd.push(part);
        }
      }
      if (
        part.pricingAvailability.costPrice > 2 * roundUptotalCount &&
        part.pricingAvailability.costPrice <= 3 * roundUptotalCount
      ) {
        if (part.pricingAvailability.availability.length <= 0) {
          outofstock3rdarray.push(part);
        } else {
          rowArray3rd.push(part);
        }
      }
      if (
        part.pricingAvailability.costPrice > 3 * roundUptotalCount &&
        part.pricingAvailability.costPrice <= 4 * roundUptotalCount
      ) {
        if (part.pricingAvailability.availability.length <= 0) {
          outofstock4tharray.push(part);
        } else {
          rowArray4th.push(part);
        }
      }
      if (
        part.pricingAvailability.costPrice > 4 * roundUptotalCount &&
        part.pricingAvailability.costPrice <= 5 * roundUptotalCount
      ) {
        if (part.pricingAvailability.availability.length <= 0) {
          outofstock5tharray.push(part);
        } else {
          rowArray5th.push(part);
        }
      }
    });
    this.priceSeparationSingleView(
      "(" + "$" + minpriceCount + " - " + "$" + roundUptotalCount + ")",
      rowArray1st,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" + "$" + roundUptotalCount + " - " + "$" + 2 * roundUptotalCount + ")",
      rowArray2nd,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" +
        "$" +
        2 * roundUptotalCount +
        " - " +
        "$" +
        3 * roundUptotalCount +
        ")",
      rowArray3rd,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" +
        "$" +
        3 * roundUptotalCount +
        " - " +
        "$" +
        4 * roundUptotalCount +
        ")",
      rowArray4th,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" +
        "$" +
        4 * roundUptotalCount +
        " - " +
        "$" +
        5 * roundUptotalCount +
        ")",
      rowArray5th,
      rowArray
    );

    this.priceSeparationSingleView(
      "(" + "$" + minpriceCount + " - " + "$" + roundUptotalCount + ")",
      outofstock1starray,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" + "$" + roundUptotalCount + " - " + "$" + 2 * roundUptotalCount + ")",
      outofstock2ndarray,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" +
        "$" +
        2 * roundUptotalCount +
        " - " +
        "$" +
        3 * roundUptotalCount +
        ")",
      outofstock3rdarray,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" +
        "$" +
        3 * roundUptotalCount +
        " - " +
        "$" +
        4 * roundUptotalCount +
        ")",
      outofstock4tharray,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" +
        "$" +
        4 * roundUptotalCount +
        " - " +
        "$" +
        5 * roundUptotalCount +
        ")",
      outofstock5tharray,
      rowArray
    );

    return rowArray;
  };
  PriceHighToLow = (rowsdata) => {
    const { minPrice, maxPrice } = this.props;
    // const totals = rowsdata.map(x => x.pricingAvailability.listPrice);
    // let maxPrice =Math.max(...totals);
    // let minPrice = Math.min(...totals);
    let minpriceCount = Math.floor(minPrice);
    let totalCount = (maxPrice + minPrice) / 5;
    let roundUptotalCount = Math.ceil(totalCount);
    let rowArray = [];
    let rowArray1st = [];
    let rowArray2nd = [];
    let rowArray3rd = [];
    let rowArray4th = [];
    let rowArray5th = [];
    let outofstock1starray = [];
    let outofstock2ndarray = [];
    let outofstock3rdarray = [];
    let outofstock4tharray = [];
    let outofstock5tharray = [];
    rowsdata.map((part, index) => {
      if (part.pricingAvailability.costPrice <= roundUptotalCount) {
        if (part.pricingAvailability.availability.length <= 0) {
          outofstock1starray.push(part);
        } else {
          rowArray1st.push(part);
        }
      }
      if (
        part.pricingAvailability.costPrice > roundUptotalCount &&
        part.pricingAvailability.costPrice <= 2 * roundUptotalCount
      ) {
        if (part.pricingAvailability.availability.length <= 0) {
          outofstock2ndarray.push(part);
        } else {
          rowArray2nd.push(part);
        }
      }
      if (
        part.pricingAvailability.costPrice > 2 * roundUptotalCount &&
        part.pricingAvailability.costPrice <= 3 * roundUptotalCount
      ) {
        if (part.pricingAvailability.availability.length <= 0) {
          outofstock3rdarray.push(part);
        } else {
          rowArray3rd.push(part);
        }
      }
      if (
        part.pricingAvailability.costPrice > 3 * roundUptotalCount &&
        part.pricingAvailability.costPrice <= 4 * roundUptotalCount
      ) {
        if (part.pricingAvailability.availability.length <= 0) {
          outofstock4tharray.push(part);
        } else {
          rowArray4th.push(part);
        }
      }
      if (
        part.pricingAvailability.costPrice > 4 * roundUptotalCount &&
        part.pricingAvailability.costPrice <= 5 * roundUptotalCount
      ) {
        if (part.pricingAvailability.availability.length <= 0) {
          outofstock5tharray.push(part);
        } else {
          rowArray5th.push(part);
        }
      }
    });
    this.priceSeparationSingleView(
      "(" +
        "$" +
        5 * roundUptotalCount +
        " - " +
        "$" +
        4 * roundUptotalCount +
        ")",
      rowArray5th,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" +
        "$" +
        4 * roundUptotalCount +
        " - " +
        "$" +
        3 * roundUptotalCount +
        ")",
      rowArray4th,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" +
        "$" +
        3 * roundUptotalCount +
        " - " +
        "$" +
        2 * roundUptotalCount +
        ")",
      rowArray3rd,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" + "$" + 2 * roundUptotalCount + " - " + "$" + roundUptotalCount + ")",
      rowArray2nd,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" + "$" + roundUptotalCount + " - " + "$" + minpriceCount + ")",
      rowArray1st,
      rowArray
    );

    this.priceSeparationSingleView(
      "(" +
        "$" +
        5 * roundUptotalCount +
        " - " +
        "$" +
        4 * roundUptotalCount +
        ")",
      outofstock5tharray,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" +
        "$" +
        4 * roundUptotalCount +
        " - " +
        "$" +
        3 * roundUptotalCount +
        ")",
      outofstock4tharray,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" +
        "$" +
        3 * roundUptotalCount +
        " - " +
        "$" +
        2 * roundUptotalCount +
        ")",
      outofstock3rdarray,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" + "$" + 2 * roundUptotalCount + " - " + "$" + roundUptotalCount + ")",
      outofstock2ndarray,
      rowArray
    );
    this.priceSeparationSingleView(
      "(" + "$" + roundUptotalCount + " - " + "$" + minpriceCount + ")",
      outofstock1starray,
      rowArray
    );

    return rowArray;
  };
  priceSeparationSingleView = (br, array, rowArray) => {
    if (array.length > 0) {
      let countItems = array.length;
      rowArray.push({
        items: countItems,
        alternate: [],
        caseQty: "",
        groupID: "",
        imageUrl: "",
        partDescription: br,
        partDescriptionID: "",
        partSKU: "",
        pimID: "",
        brands: [
          {
            code: "",
            id: "",
            imageUrl: "",
            name: "",
          },
        ],
        pricingAvailability: {
          availability: [
            {
              availableDuration: "",
              availableQty: "",
              availableTime: "",
              code: "",
            },
          ],

          bundleToolTip: null,
          buyQtyWarning: "",
          costPrice: "",
          extendedCostPrice: "",
          extendedListPrice: "",
          familyToolTip: null,
          id: "",
          inStock: true,
          itemSpecific: "",
          listPrice: "",
          mfgCode: "",
          packCode: "",
          packQty: "",
          partNum: "",
          qtyBuyInc: "",
          qtyPerVeh: "",
          qtyPriceBreakUp: [],
          status: "",
          toolTips: [],
        },
        sizeQty: "",
        specification: "",
        substitute: [],
      });

      array.map((v, index) => {
        rowArray.push(v);
      });
    }
  };

  brandSepartionchemical = (rowsdata) => {
    let sortedBrandArray = [];
    let rowArray = [];
    let outofStockRows = [];
    let outofstockmainarray = [];
    rowsdata.map((row, index) => {
      let brandName = row.brands[0].name.toUpperCase();
      if (
        sortedBrandArray === null ||
        sortedBrandArray === "" ||
        sortedBrandArray === undefined
      ) {
        sortedBrandArray.push(brandName);
      } else if (
        sortedBrandArray !== null &&
        row.pricingAvailability.availability.length > 0 &&
        !sortedBrandArray.includes(brandName)
      ) {
        sortedBrandArray.push(brandName);
      } else if (
        outofStockRows !== null &&
        row.pricingAvailability.availability.length <= 0 &&
        !outofStockRows.includes(brandName)
      ) {
        outofStockRows.push(brandName);
      }
    });
    sortedBrandArray.map((br, index) => {
      let countItems;
      const countBrandItems = rowsdata.filter(
        (item) =>
          item.pricingAvailability.availability.length > 0 &&
          item.brands[0].name.toUpperCase() === br
      ).length;
      countItems = countBrandItems;
      rowArray.push({
        items: countItems,
        alternate: [],
        caseQty: "",
        groupID: "",
        imageUrl: "",
        partDescription: br,
        partDescriptionID: "",
        partSKU: "",
        pimID: "",
        brands: [
          {
            code: "",
            id: "",
            imageUrl: "",
            name: "",
          },
        ],
        pricingAvailability: {
          availability: [
            {
              availableDuration: "",
              availableQty: "",
              availableTime: "",
              code: "",
            },
          ],

          bundleToolTip: null,
          buyQtyWarning: "",
          costPrice: "",
          extendedCostPrice: "",
          extendedListPrice: "",
          familyToolTip: null,
          id: "",
          inStock: true,
          itemSpecific: "",
          listPrice: "",
          mfgCode: "",
          packCode: "",
          packQty: "",
          partNum: "",
          qtyBuyInc: "",
          qtyPerVeh: "",
          qtyPriceBreakUp: [],
          status: "",
          toolTips: [],
        },
        sizeQty: "",
        specification: "",
        substitute: [],
      });

      rowsdata.map((part, index) => {
        let brandName = part.brands[0].name;
        if (
          br == brandName.toUpperCase() &&
          part.pricingAvailability.availability.length > 0
        ) {
          rowArray.push(part);
        }
      });
    });

    outofStockRows.map((br, index) => {
      let countItems;
      const countBrandItems = rowsdata.filter(
        (item) =>
          item.pricingAvailability.availability.length <= 0 &&
          item.brands[0].name.toUpperCase() === br
      ).length;
      countItems = countBrandItems;
      outofstockmainarray.push({
        items: countItems,
        alternate: [],
        caseQty: "",
        groupID: "",
        imageUrl: "",
        partDescription: br,
        partDescriptionID: "",
        partSKU: "",
        pimID: "",
        brands: [
          {
            code: "",
            id: "",
            imageUrl: "",
            name: "",
          },
        ],
        pricingAvailability: {
          availability: [
            {
              availableDuration: "",
              availableQty: "",
              availableTime: "",
              code: "",
            },
          ],

          bundleToolTip: null,
          buyQtyWarning: "",
          costPrice: "",
          extendedCostPrice: "",
          extendedListPrice: "",
          familyToolTip: null,
          id: "",
          inStock: true,
          itemSpecific: "",
          listPrice: "",
          mfgCode: "",
          packCode: "",
          packQty: "",
          partNum: "",
          qtyBuyInc: "",
          qtyPerVeh: "",
          qtyPriceBreakUp: [],
          status: "",
          toolTips: [],
        },
        sizeQty: "",
        specification: "",
        substitute: [],
      });

      rowsdata.map((part, index) => {
        let brandName = part.brands[0].name;
        if (
          br == brandName.toUpperCase() &&
          part.pricingAvailability.availability.length <= 0
        ) {
          outofstockmainarray.push(part);
        }
      });
    });
    return rowArray.concat(outofstockmainarray);
  };

  partTypeSepartionchemical = (rowsdata) => {
    let rowArray = [];
    rowsdata.map((part, index) => {
      rowArray.push(part);
    });

    return rowArray;
  };
  renderItems = () => {
    // let { suppliesAllDetails } = this.state;
    let { selectedStore, sellPartnerId, shipMethod } = this.state;
    let { currentPage, totalPages, suppliesAllDetails } = this.props;
    if (!_isEmpty(suppliesAllDetails)) {
      let productArray = [];
      let partTypeAndBrandSepartion =
        this.separtionCodechemicalView(suppliesAllDetails);
      // let validParts = suppliesAllDetails.filter(
      // 	(part) => Number(part.status) === 1
      // );
      if (partTypeAndBrandSepartion.length > 0) {
        partTypeAndBrandSepartion.map((part) => {
          let appendPart =
            (part.alternate && part.alternate.length > 0) ||
            (part.substitute && part.substitute.length > 0);
          let altPartNumsArr = [];
          let subPartNumsArr = [];
          const altPartNums =
            part.alternate && part.alternate.length > 0
              ? part.alternate.map((alt) => {
                  altPartNumsArr.push(alt.partSKU);
                })
              : "";
          const subPartNums =
            part.substitute && part.substitute.length > 0
              ? part.substitute.map((sub) => {
                  subPartNumsArr.push(sub.partSKU);
                })
              : "";
          productArray.push(
            <SingleSupplyItem
              partDetails={part}
              partType={"chemical"}
              appendPart={appendPart}
              altPartNums={`${altPartNumsArr.join(",")}`}
              subPartNums={`${subPartNumsArr.join(",")}`}
              selectedStore={selectedStore}
              sellPartnerId={sellPartnerId}
              shipMethod={shipMethod}
            />
          );
          //alternate parts
          if (part.alternate && part.alternate.length > 0) {
            part.alternate.map((altPart, index) => {
              let appendAltPart =
                index !== part.alternate.length - 1 ||
                (part.substitute && part.substitute.length > 0);
              productArray.push(
                <SingleSupplyItem
                  partDetails={altPart}
                  isAltType={true}
                  appendPart={appendAltPart}
                  selectedStore={selectedStore}
                  sellPartnerId={sellPartnerId}
                  shipMethod={shipMethod}
                />
              );
            });
          }
          //substitute parts
          if (part.substitute && part.substitute.length > 0) {
            part.substitute.map((subPart, index) => {
              let appendSubPart = index !== part.substitute.length - 1;
              productArray.push(
                <SingleSupplyItem
                  partDetails={subPart}
                  isSubType={true}
                  appendPart={appendSubPart}
                  selectedStore={selectedStore}
                  sellPartnerId={sellPartnerId}
                  shipMethod={shipMethod}
                />
              );
            });
          }
        });
        productArray.push(
          <PaginationComponent
            handlePageChange={this.handlePageChange}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        );
      } else {
        return this.renderNoDataState();
      }
      return productArray;
    } else {
      return this.renderNoDataState();
    }
  };

  renderMenu = (results, menuProps) => {
    if (!results.length)
      return (
        <Menu {...menuProps}>
          <MenuItem>Searching...</MenuItem>
        </Menu>
      );

    if (!this.props.chemicalsGS.loading) {
      const top = _.head(results);

      let flag = false;
      const values = Object.values(top);
      for (let i = 0; i < values.length; i++) {
        const t = values[i];
        if (t.length > 0) {
          flag = true;

          break;
        }
      }

      if (!flag) {
        return (
          <Menu {...menuProps}>
            <MenuItem>No Matching results</MenuItem>
          </Menu>
        );
      }
    }

    return (
      <Menu {...menuProps}>
        {results.map((result, index) => {
          return Object.keys(result)
            .sort()
            .map((label, i) => {
              return (
                result[label].length > 0 && (
                  <React.Fragment>
                    {i !== 0 && <Menu.Divider />}
                    <Menu.Header>
                      <h6>{this.getGroupLabels(label)}</h6>
                    </Menu.Header>
                    {result[label].map((value) => {
                      return (
                        <MenuItem option={this.setOption(value, label)}>
                          {value.name ||
                            `${value.partDescription} (${value.partSKU})`}
                        </MenuItem>
                      );
                    })}
                  </React.Fragment>
                )
              );
            });
        })}
      </Menu>
    );
  };

  filterBy = (option, { text }) => {
    if (option.name) {
      return option.name.toLowerCase().indexOf(text.toLowerCase()) !== -1;
    } else {
      return ["name"];
    }
  };

  labelKey = (option) => {
    if (this.props.chemicalsGS.brand && option.type === "category") {
      return `${this.props.chemicalsGS.brand.name} - ${
        option.name || option.partDescription
      }`;
    }
    return `${option.name || option.partDescription}`;
  };

  isArrayEmpty =(arr)=> {
    return arr.length === 0;
  }
  handleSearch = async(query) => {
    this.setState({updatedSearchValue:query})
    let validity = this.initializeValidity();
    validity.globalSearchMaxLimit = isLengthLessthan(query, 100);
    // setValidity(validity);
    const payload2 = { s: query };
    setTimeout(async()=>{
      try {
        const searchResponse = await this.props.dispatch(search(payload2));
        const {payload} = searchResponse;    
        const allEmpty = Object.values(payload).every((array) => this.isArrayEmpty(array));
  
        if (allEmpty) {
          // setTimeout(()=>{
          //   // handleSearchError(q);
          //   this.setState({gloabalSearchStarted:true});
    
          // },2000)
          // this.setState({searchFailureValue:query});

          this.handleSearchError(query,"ResultNotFound")
        } 
  
      } catch (error) {
        console.log("catch",error)
  
        this.handleSearchError(query,"ResultNotFound")
      }
    },1500)

  };
  handleSearchError = (query,resultfound) => {
    const sv= this.props.selectedVehicle;
    const parts = this.props.parts;
   const errorData = {
         source: "Chemicals - Default",
         searchvalue: query,
         resultFound: resultfound === "resultFound"? true : false,
         selectedVehicle  : sv ? `${sv?.year} ${sv?.make} ${sv?.model} ${sv?.engine}`: null,	

       filters:{
     groupCode: this.props.suppliesReqGrpIds,
     brand:this.props.brandsSelected,
     // position:parts?.catalogFilter?.position,
     availablity:this.props.suppliesReqAvailability,
 
     },

   };
   this.logUserSearchFailureActivity(errorData,resultfound)
 };
   logUserSearchFailureActivity = (storedErrors,resultfound) => {
   const { x2userName, shipTos, billTo } = this.props.userDetails;
   if (!_isEmpty(this.props.userDetails) && !_isEmpty(billTo)) {
     let loginId = "";
    let shipToId = shipTos[0]?.shipToId;
     let userData = JSON.stringify(storedErrors);
     let type=resultfound === "resultFound"? "Search Result Found - Not Added To Cart"  :"Search - No Result";
   // const {searchValue}= sourceAlreadyCalled;
     // if(searchValue !== storedErrors.searchvalue ){
     //   setSourceAlreadyCalled({source:storedErrors.source,searchValue:storedErrors.searchvalue})
     this.setState({
       sourceAlreadyCalled: {
         source: storedErrors.source,
         searchValue: storedErrors.searchvalue,
       },
     });
     
     this.props.dispatch(
     logUserStockActivity(
       timeInUTC(),
       billTo.billToId,
       loginId,
       shipToId,
       type,
       userData,
       x2userName
     )
     );
     // }
   }
   };

  handleChange = (selected) => {
    const option = _.head(selected);
    const { dispatch, parts } = this.props;
    if (!option) return;

    switch (option.type) {
      case "brand":
        this.props.dispatch(setBrand({ brand: option }));
        this.state.searchInput.current.toggleMenu();
        break;
      case "category":
        const partSupplies = this.props.parts.categorySuppliesMap;
        let category = _.filter(partSupplies, (p) => option.name === p.name);
        category = _.head(category);
        if (this.props.chemicalsGS.brand) {
          this.props.dispatch(showBrandGroups());
        } else {
          if (category) {
            this.props.dispatch(getGroupsByCategory(category));
          }
        }

        this.state.searchInput.current.toggleMenu();
        break;
      case "group":
        const groupIds = selected.map((single) => single.id);

        let _brand = "";

        if (this.props.chemicalsGS.brand) {
          _brand = this.props.chemicalsGS.brand.name;
        }

        setTimeout(async()=>{
          try{
            const response = await dispatch(
              getChemPartByGrp(
                groupIds,
                parts.suppliesPageNumber,
                parts.suppliesPageSize,
                parts.suppliesSortBy,
                parts.suppliesOrder,
                _brand
              )
            );
           const totalItems = response?.data?.totalItems || 0;
            setTimeout(()=>{
              if (totalItems <= 0) {
                this.handleSearchError(JSON.stringify(selected[0],"resultNotFound"));
              }else{
                this.props.dispatch(addSearchedValue(selected[0]))
                this.isSearchValueAddedToCart(selected[0]);
              }
            },[1000])
          }
          catch(error){
            this.handleSearchError(selected,"resultNotFound");
          }},1500);
  
        this.props.history.push("/supplies-list");
        break;
      case "partDescription":
        const ids = selected.map((single) => single.groupID);
        const partDescids = selected.map((single) => single.partDescriptionID);
        setTimeout(async()=>{
          try{
            const response = await dispatch(getChemPartByGrp(ids, partDescids));
            const totalItems = response?.data?.totalItems || 0;
            setTimeout(()=>{
              if (totalItems <= 0) {
                this.handleSearchError(JSON.stringify(selected[0],"resultNotFound"));
              }else{
                this.props.dispatch(addSearchedValue(selected[0]))
                this.isSearchValueAddedToCart(selected[0]);
              }
            },[1000])
          }
          catch(error){
            this.handleSearchError(selected[0],"resultNotFound");
          }},1500);
        this.props.history.push("/supplies-list");
        break;
      default:
        break;
    }
  };
  isSearchValueAddedToCart=(option)=>{
    const searchedValues = this.props?.searchedValues ;
		if (searchedValues.length === 0 ) {
			// Handle empty arrays as needed
			return false;
		  }
	if(searchedValues.length >= 1){
		if(this.props.parts.isSearchedValuesAddedToCart){
			this.props.dispatch({
				payload: {selectedAddCartData:this.props.parts.selectedAddCartData,

					isSearchedValuesAddedToCart: false,
				},
				type: actionTypes.SEARCHED_ADDTOCART_DATA,})
		}else{
			this.handleSearchError(JSON.stringify(searchedValues[searchedValues.length-1]),"resultFound");
		}
	 }

	}

  getFilterTitle = () => {
    let defaultTitle = "ALL";
    let { partTypes } = this.props;
    let selectedPartType = this.props.parts.selectedPartType;
    if (partTypes && selectedPartType) {
      const isFound = _.filter(partTypes, (pt) => {
        return pt.text === selectedPartType.partTypeDesc;
      });

      if (isFound && isFound.length > 0) {
        defaultTitle = selectedPartType.partTypeDesc;
      }
      if (
        isFound &&
        isFound.length == 0 &&
        selectedPartType.partTypeId === "Multiple Items"
      ) {
        defaultTitle = selectedPartType.partTypeDesc;
      }
    }
    return defaultTitle;
  };
  getMultiSelectArray = () => {
    let multiSelectArray = [];
    let { partTypes } = this.props;
    let selectedPartType = this.props.parts.selectedPartType;
    if (partTypes && selectedPartType) {
      const isFound = _.filter(partTypes, (pt) => {
        return pt.text === selectedPartType.partTypeDesc;
      });
      if (
        isFound &&
        isFound.length == 0 &&
        selectedPartType.partTypeId === "Multiple Items"
      ) {
        multiSelectArray = selectedPartType.partTypeDesc;
      }
    }
    return multiSelectArray;
  };

  handlePartTypeFilter = async(options, selectedOptions) => {
    let {
      suppliesRequestGroups,
      dispatch,
      partTypeIds,
      pageNumber,
      pageSize,
      sortBy,
      order,
      brand,
      availability,
      misc,
      specification,
      sizeQty,
      caseQty,
      sellPartnerId,
      store,
      shipMethd,
      brandsView,
      categoryCodes,
      brandCodes,
      groupCodes,
      groupCode,
      pageSiz,
      catalogFilter,
    } = this.props;
    let { currentFilter } = this.state;
    let key = 0;
    options = options.filter((o) => o.value !== "apply");
    let allOptions = options.filter((o) => o.value === "*");
    if (allOptions.length > 0) {
      currentFilter = "ALL";
      key = "ALL";
    } else if (options.length > 1) {
      currentFilter = options;
      key = "Multiple Items";
    } else {
      currentFilter = options[0].label;
      key = options[0].value;
    }
    const partTypeData = {
      partTypeId: key,
      partTypeDesc: currentFilter,
    };
    dispatch({
      payload: {
        selectedPartType: partTypeData,
      },
      type: "SELECTED_PART_TYPE",
    });
    let chemicalsRequestGroupClone = _cloneDeep(suppliesRequestGroups);
    let catalogFilterClone = _cloneDeep(catalogFilter);
    let partTypes = [];
    options.map((o) => {
      partTypes.push({ id: o.value });
    });
    catalogFilterClone = {
      ...catalogFilterClone,
      partTypes: partTypes,
    };
    if (brandsView) {
      dispatch(
        getChemPartByCat(
          categoryCodes,
          brandCodes,
          groupCodes,
          pageNumber,
          pageSiz,
          sortBy,
          order,
          brand,
          availability,
          misc,
          specification,
          sizeQty,
          caseQty,
          sellPartnerId,
          store,
          shipMethd,
          catalogFilterClone
        )
      );
    } else {
      try{
        const response = await dispatch(getChemPartByGrp(
          groupCode,
          pageNumber,
          pageSize,
          sortBy,
          order,
          brand,
          availability,
          misc,
          specification,
          sizeQty,
          caseQty,
          partTypeIds,
          sellPartnerId,
          store,
          shipMethd,
          catalogFilterClone
        )
        )
      }catch(error){
        console.log("error",error)
      }    }


    this.setState({
      ...this.state,
      currentFilter,
      catalogFilter,
      chemicalsRequestGroupClone,
    });
  };

  toggleView = (event) => {
    let { showFullView } = this.state;
    this.props.dispatch({
      data: { isEnable: !showFullView, isPrefenceSet: true },
      type: "CATALOG_VIEW_TOGGLE",
    });
    this.setState({
      ...this.state,
      showFullView: !showFullView,
    });
  };

  isBrandsSelected = () => {
    const prefBrands = getChemicalsPreferredBrands();

    if (
      prefBrands &&
      prefBrands.multiAnswer &&
      prefBrands.possibleMultiAnswer &&
      (prefBrands.multiAnswer.length ===
        prefBrands.possibleMultiAnswer.length ||
        prefBrands.multiAnswer.filter((m) => m.text !== "All").length === 0)
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    let {
      currentSort,
      redirectToChemicalsPage,
      showLocationsPopup,
      showRegionLocationsPopup,
      selectedBrands,
      showFullView,
      userPrefView,
      filterEnabled,
      catalogFilter,
      chemicalListTopArrowClicked,
      redirectLink,
      selectedCartData,
      customerShipMethod,
      addToCartWarningPopup,
      enableDelivery,
      selectedStoreName,
      shipMethod,
      selectedStore,
      sellPartnerId,
      allStores,
    } = this.state;
    let {
      brandsView,
      categoryMap,
      supplyListApiCall,
      currentPageSize,
      totalItems,
      suppliesReqGrpIds,
      currentPage,
      totalPages,
      addToCartWarning,
      selectedAddCartData,
    } = this.props;
    let filteredStore = [];
    if (
      !_isEmpty(allStores) &&
      allStores.deliveryStores &&
      allStores.pickupStores
    ) {
      filteredStore = enableDelivery
        ? allStores && _cloneDeep(allStores.deliveryStores)
        : _cloneDeep(allStores.pickupStores);
      if (!_isEmpty(allStores) && !_isEmpty(allStores.defaultStore)) {
        filteredStore.push(_cloneDeep(allStores.defaultStore));
      }
      filteredStore = _uniqBy(filteredStore, "storeNumber");
      // filteredStore = _filter(allStores, (store) => (
      // 	(store.mainLoc === userStoreMainLoc && ((store.locationClass !== 'DC') || (store.locationClass !== 'DSW')))
      // ));
    }
    if (redirectToChemicalsPage || _isEmpty(categoryMap)) {
      return (
        <Redirect
          to={{
            pathname: "/supplies-catalog",
          }}
        />
      );
    }
    return (
      <div
        className={clsx("chemicalsAndSuppliesContainer", {
          "promotions-active": this.props.viewPromotions,
        })}
      >
        {supplyListApiCall && (
          <div className="custom_spinner_container catalog_spinner">
            <Spinner className="custom_spinner" animation="border" />
          </div>
        )}
        <div className="chemicalSuppliesProductContainer">
          <Container>
            <div className="">
              <Row>
                <Col md="2" className="chemical-breadcrum-quarter">
                  <div className="bread_crum_section">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <span>Chemicals</span>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="10">
                  <div className="btn-filter-catalog-top d-flex">
                    <ShipInfoHeader
                      shipMethod={shipMethod}
                      storeName={selectedStore}
                    />
                    {/* <div className="store-filter ml-4">
                      {customerShipMethod && customerShipMethod != "0002" && (
                        <div className="radio-wrapper">
                          <label class="customized-radio-button">
                            <input
                              type="radio"
                              name="viewTypes"
                              checked={enableDelivery}
                              id="form-1"
                              onChange={(e) => {
                                this.handleViewTypeChange(e, enableDelivery);
                              }}
                            />
                            <span>Delivery</span>
                          </label>
                          <label class="customized-radio-button">
                            <input
                              type="radio"
                              name="viewTypes"
                              checked={!enableDelivery}
                              onChange={(e) => {
                                this.handleViewTypeChange(e, enableDelivery);
                              }}
                              id="form-2"
                            />
                            <span>Pick Up</span>
                          </label>
                        </div>
                      )}
                      {filteredStore && filteredStore.length > 1 && (
                        <div className="store-dropdown">
                          {customerShipMethod == "0002" ? (
                            <span>Pick Up Locations: </span>
                          ) : (
                            <span>Locations:</span>
                          )}
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 100, hide: 400 }}
                            overlay={
                              <Tooltip id="pickup-location">
                                You can {enableDelivery ? "deliver" : "pick up"}{" "}
                                your parts from a different store than your
                                primary store. Click the drop down to select a
                                different{" "}
                                {enableDelivery ? "stores" : "pick up location"}
                              </Tooltip>
                            }
                          >
                            <DropdownButton
                              className="xlp-btn"
                              id="dropdown-basic-button"
                              title={selectedStoreName}
                              onSelect={(key, e) => {
                                this.handleByStore(key, e);
                              }}
                            >
                              {filteredStore.map((store) => (
                                <Dropdown.Item eventKey={store.sellerPartnerId}>
                                  {store.storeNumber} - {store.storeName}
                                </Dropdown.Item>
                              ))}{" "}
                            </DropdownButton>
                          </OverlayTrigger>
                          {selectedStore && !_isEmpty(selectedStore) && (
                            <>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 100, hide: 400 }}
                                overlay={
                                  <Tooltip id="selected-location">
                                    {`Click this to locate the selected Store in Map`}
                                  </Tooltip>
                                }
                              >
                                <LocationOnIcon
                                  className="cursor-pointer"
                                  onClick={() => {
                                    this.setState({ showLocationsPopup: true });
                                  }}
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 100, hide: 400 }}
                                overlay={
                                  <Tooltip id="selected-location">
                                    {`Click this to locate all the stores in the region of selected store in Map`}
                                  </Tooltip>
                                }
                              >
                                <LocationCityIcon
                                  className="cursor-pointer"
                                  onClick={() => {
                                    this.setState({
                                      showRegionLocationsPopup: true,
                                    });
                                  }}
                                />
                              </OverlayTrigger>
                            </>
                          )}
                        </div>
                      )}
                    </div> */}
                    <div className={this.props.viewPromotions ? "ml-4" : "ml-5"}>
                      <PickupDelivery
                        handleViewTypeChange={this.handleViewTypeChange}
                        storeSellerId={this.handleByStore}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <Row>
              <Col md="1"></Col>
              <Col md="10">
                <div className="btn-filter-catalog d-flex new_customer_container">
                  <div class="listing-toggleview d-flex p-10 listing-toggle-margin mr-4">
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 100, hide: 400 }}
                      overlay={
                        <Tooltip id="toggle-view-full-compact">
                          {`${
                            showFullView
                              ? 'You are in "Full View", clicking on the button will switch to "Compact View"'
                              : 'You are in "Compact View", clicking on the button will switch to "Full View"'
                          }`}
                        </Tooltip>
                      }
                    >
                      <Form.Group controlId="toggle-view-id">
                        <Form.Check
                          type="switch"
                          id="toggle-view"
                          label={`${
                            userPrefView === "full"
                              ? "Compact View"
                              : "Full View"
                          }`}
                          onChange={(e) => this.toggleView(e)}
                        />
                      </Form.Group>
                    </OverlayTrigger>
                  </div>
                  {this.isBrandsSelected() && (
                    <div className="pref-brands">
                      Brands displayed are based on user selection
                    </div>
                  )}
                  <div className="chemical__search_input_wrapper">
                    <AsyncTypeahead
                      ref={this.state.searchInput}
                      renderMenu={this.renderMenu}
                      labelKey={this.labelKey}
                      filterBy={this.filterBy}
                      options={this.props.chemicalsGS.options}
                      onChange={this.handleChange}
                      onSearch={this.handleSearch}
                      placeholder="Search by Brand, Category, Group, Part Description, Part SKU..."
                      id="chemicals__search__catalog"
                      inputProps={{
                        className: "searchInput input__search",
                      }}
                      onKeyDown={this.onKeyDown}
                    />
                  </div>
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 100, hide: 400 }}
                    overlay={
                      <Tooltip id="toggle-view-full-compact">
                        {`Click here to clear this view and start a new search`}
                      </Tooltip>
                    }
                  >
                    <div className="topbar-buttons chemical-new-search">
                      <Link to={redirectLink}>
                        <Button className="new-search">New Search </Button>
                      </Link>{" "}
                    </div>
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>

            <div className="supplies-listing">
              <Row className="btn-filter-supply-list">
                <Col
                  md="2"
                  class="listing-toggleview d-flex"
                  id="filter-chemical-catalog"
                >
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 100, hide: 400 }}
                    overlay={
                      <Tooltip id="toggle-view-chemical-compact">
                        {`${
                          filterEnabled
                            ? "Click here to show filters"
                            : "Click here to hide filters"
                        }`}
                      </Tooltip>
                    }
                  >
                    <Button
                      className={
                        filterEnabled
                          ? "btn-filter btn-filter-grey"
                          : "btn-filter"
                      }
                      onClick={(e) =>
                        this.setState({ filterEnabled: !filterEnabled })
                      }
                    >
                      <i class="fa fa-filter" aria-hidden="true"></i>
                      <i class="fa fa-bars" aria-hidden="true"></i>
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col lg="10" md="9" sm="9" xs="12" className="right_section">
                  {!supplyListApiCall && (
                    <TopBar
                      handlePartTypeFilter={(key, e) =>
                        this.handlePartTypeFilter(key, e)
                      }
                      multiSelectArray={this.getMultiSelectArray()}
                      currentPageSize={currentPageSize}
                      currentSort={currentSort}
                      totalItems={totalItems}
                      isChemicalCatalog={false}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={this.handlePageChange}
                      handleDropDown={(key, e) => {
                        this.handlePageSizeDropdown(key, e);
                      }}
                      handleSortBy={(key, e) => this.handleSortBy(key, e)}
                      currentFilter={
                        catalogFilter &&
                        catalogFilter.partTypes &&
                        catalogFilter.partTypes.length == 0
                          ? "ALL"
                          : this.getFilterTitle()
                      }
                      handleJob={(key, e) => {
                        this.handleJob(key, e);
                      }}
                      handleMixPromo={(key, e) => {
                        this.handleMixPromo(key, e);
                      }}
                      userDetails={this.props.userDetails}
                      partTypes={this.props.partTypes}
                    />
                  )}
                </Col>
              </Row>
              <Row
                className={
                  this.state.filterEnabled
                    ? "supply-filter-collapsible active"
                    : "supply-filter-collapsible"
                }
              >
                <ChemicalFilters
                  shipMethod={shipMethod}
                  selectedStore={selectedStore}
                  sellPartnerId={sellPartnerId}
                />
                <Col className="right_section" md={9} lg={10}>
                  <div className="chemicalSuppliesProductList">
                    {showFullView ? (
                      !supplyListApiCall ? (
                        this.renderItems()
                      ) : (
                        <Fragment>
                          <Placeholder rows={4} />
                        </Fragment>
                      )
                    ) : !supplyListApiCall ? (
                      prepareTableChemicalDataFromUtil(this.props, this.state, this.separtionCodeForBrand, this.renderNoDataState, this.handlePageChange)
                      //this.renderTableItems()
                    ) : (
                      <Fragment>
                        <Placeholder rows={4} />
                      </Fragment>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            {/* <div className="supplies-listing">
							<div className="btn-filter-supply-list"><Button className="btn-filter" onClick={(e) => this.setState({ filterEnabled: !filterEnabled })}><i class="fa fa-filter" aria-hidden="true"></i><i class="fa fa-bars" aria-hidden="true"></i></Button>
							<div class="listing-toggleview d-flex p-10">
								<OverlayTrigger
									placement="right"
									delay={{ show: 100, hide: 400 }}
									overlay={
										<Tooltip id="toggle-view-full-compact">
											{`${showFullView
												? 'You are in "Full View", clicking on the button will switch to "Compact View"'
												: 'You are in "Compact View", clicking on the button will switch to "Full View"'
												}`}
										</Tooltip>
									}
								>
									<Form.Group controlId="toggle-view-id">
										<Form.Check
											type="switch"
											id="toggle-view"
											label={`${userPrefView === 'full'
												? 'Compact View'
												: 'Full View'
												}`}
											onChange={(e) => this.toggleView(e)}
										/>
									</Form.Group>
								</OverlayTrigger>
							</div>
							</div>
							<Row className={this.state.filterEnabled ? "supply-filter-collapsible active" : "supply-filter-collapsible" }>
								<ChemicalFilters />
								<Col className="right_section" md={9} lg={10}>
								<div className="chemicalSuppliesProductList">
									{showFullView ?
										(!supplyListApiCall ? (
											this.renderItems()
										) : (
												<Fragment>
													<Placeholder rows={4} />
												</Fragment>
										)): (!supplyListApiCall ? (
											this.renderTableItems()
										) : (
												<Fragment>
													<Placeholder rows={4} />
												</Fragment>
										))}
									</div>
								</Col>
							</Row>
						</div> */}
          </Container>
        </div>
        <PromotionsTab
          showSidebarPopUp={this.props.viewPromotions}
          promotions={this.props.userPromotionDetails}
          handlePromotions={() => {
            setCookie('viewPromotions', !this.props.viewPromotions, 365)
            this.props.dispatch({
              payload: {},
              type: "VIEWPROMOTIONS_TOGGLE",
            });
          }}
        />
        {addToCartWarning ? (
          <AddToCartWarningPopup
            openPopup={addToCartWarning}
            selectedAvailabilityHours={selectedAddCartData}
            source={"chemicals"}
			orderType="REGULAR"
            DefaultAddItemToCart={true}
            closePopup={() => {
              this.setState({
                addToCartWarningPopup: false,
              });
            }}
          />
        ) : null}
        {/* {(showLocationsPopup || showRegionLocationsPopup) && (
          <XModal
            show={showLocationsPopup || showRegionLocationsPopup}
            showButtons={false}
            title={`Store Location - Store # ${selectedStoreName}`}
            customHeader={
              <React.Fragment>
                <Modal.Title id="warning-modal-title" className="pull-left">
                  Store Location - Store # {selectedStoreName}
                </Modal.Title>
                <div
                  className="close-btn"
                  onClick={() => {
                    this.setState({ showLocationsPopup: false });
                    this.setState({ showRegionLocationsPopup: false });
                  }}
                >
                  <span className="material-icons">close</span>
                </div>
              </React.Fragment>
            }
            handleClose={() => {
              this.setState({ showLocationsPopup: false });
              this.setState({ showRegionLocationsPopup: false });
            }}
          >
            <Locations
              allCityStores={showRegionLocationsPopup ? true : false}
              selectedStore={selectedStore}
            />
          </XModal>
        )} */}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    minPrice: state.parts.chemicalMinPrice,
    maxPrice: state.parts.chemicalMaxPrice,
    categoryMap: state.parts.categorySuppliesMap,
    suppliesAllBrands: state.parts.suppliesAllBrands,
    suppliesAllDetails: state.parts.suppliesAllDetails,
    supplyListApiCall: state.parts.supplyListApiCall,
    totalItems: state.parts.suppliesTotalItems,
    currentPageSize: state.parts.suppliesPageSize,
    suppliesReqGrpIds: state.parts.suppliesReqGrpIds,
    currentPage: state.parts.suppliesPageNumber,
    totalPages: state.parts.suppliesTotalPages,
    pageSize: state.parts.suppliesPageSize,
    sortBy: state.parts.suppliesSortBy,
    order: state.parts.suppliesOrder,
    brandsSelected: state.parts.suppliesReqBrands,
    chemicalsGS: state.chemicalsGlobalSearch,
    parts: state.parts,
    suppliesOffers: state.parts.suppliesOffers,
    suppliesReqAvailability: state.parts.suppliesReqAvailability,
    brandsView: state.parts.brandsView,
    brandCodes: state.parts.suppliesReqBrandCodes,
    pimCategoryIds: state.parts.suppliesReqPimCategoryIds,
    reqSpecs: state.parts.suppliesReqSpecs,
    reqSizeQty: state.parts.suppliesReqSizeQty,
    reqCaseQty: state.parts.suppliesReqCaseQty,
    userPromotionDetails: state.user.userPromotionDetails,
    viewPromotions: state.app.viewPromotions,
    userDetails: state.user.userDetails,
    viewFullView: state.parts.catalogToggleView,
    allStoreData: state.userManagement.allStores,
    addToCartWarning: state.parts.addToCartWarning,
    selectedAddCartData: state.parts.selectedAddCartData,
    cartStore: state.parts.cartStore,
    cartShipMethod: state.parts.cartShipMethod,
    cart: state.cart,
    partTypes: state.parts.partTypes,
    pageNumber: state.parts.pageNumber,
    chemicalsRequestGroups: state.parts.chemicalsRequestGroups,
    catalogFilter: state.parts.catalogFilter,
    categoryCodes: state.parts.suppliesReqPimCategoryIds,
    groupCode: state.parts.suppliesReqGrpIds,
    partTypeIds: state.parts.partDescriptionID,
    storeDetails: state.mygarage.storeDetails,
    addItemcartFetching:  state.cart.addItemcartFetching,
    searchedValues: state.globalSearch.searchedValues,
    globalSearch:state.globalSearch,
    suppliesSelectedGroups: state.parts.suppliesSelectedGroups,
    selectedPartType: state.parts.selectedPartType,
    selectedVehicle:state.mygarage.selectedVehicle,
    checkoutDetails: state.cart.checkoutDetails,


  };
}
export default connect(mapStateToProps)(ChemicalsSuppliesListingWrapper);
