import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import "./styles.scss";
import ItemsRender from "pages/c_and_k/SingleView/items";
import { currencyFormatter } from "helpers/utils";

const headerStyle = {
  backgroundcolor: "#0F5283",
  color: "#FFFFFF",
  whiteSpace: "nowrap",
};
const PricingTable = (props) => {
  const pricingdata = useSelector((state) => state.ProgramAndBenefits);
  const programlist = pricingdata && pricingdata.programList;
  const [pricingStockTypeColoumns, setPricingStockTypeColoumns] = useState([]);
  const [pricingStockData, setPricingStockData] = useState([]);
  const [userDef2, setuserDef2] = useState(false);
  // const batteryStock = pricingdata && pricingdata.stockingBatteries;
  // console.log("batteryStoc",batteryStock);
  useEffect(() => {
    if (!_isEmpty(programlist)) constructPricingData(programlist);
  }, [programlist]);

  const constructPricingData = (data) => {
    setPricingStockTypeColoumns([]);
    setPricingStockData([]);
    const rowPricingData = [];
    data.forEach((item, index) => {
      if (item.userDef2.includes("BC")) {
        setuserDef2(true);
      }
    });
    data.forEach((item, index) => {
      if (item.userDef20 === "1EDLP") {
        rowPricingData.push({
          group: "Every Day Low Price",
          definition: "No requirement",
          monthlypurchase: "none",
        });
      } else if (item.userDef20 === "1PREF") {
        rowPricingData.push({
          group: "Preferred",
          definition: "6-8 Units",
          monthlypurchase: "8",
        });
      } else if (item.userDef20 === "1MKTR") {
        rowPricingData.push({
          group: "Marketer",
          definition: ">12 Units",
          monthlypurchase: "12",
        });
      }
    });

    const pricingStockTypeColoumns = [
      {
        dataField: "group",
        text: "Pricing Group",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "definition",
        text: "Pricing Definition",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "monthlypurchase",
        text: "Current Month Purchase",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",

        formatter: (cell) => {
          return currencyFormatter(cell);
        },
      },
    ];

    setPricingStockTypeColoumns(pricingStockTypeColoumns);
    setPricingStockData(rowPricingData);
  };

  const renderPricingTb = () => {
    let headers = Object.values(pricingStockTypeColoumns);
    return (
      // <Row>
      //   <Col>
      <table className="table table-bordered">
        <thead>
          <tr>
            {headers.map((header) => {
              return <th>{header.text}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {pricingStockData && pricingStockData.length === 0 && (
            <tr>
              <td colSpan={3}>No Data</td>
            </tr>
          )}
        </tbody>
      </table>
      //   </Col>
      // </Row>
    );
  };
  return (
    <Row>
      <Col hidden>
        <div className="stock_type">
          {userDef2 ? (
            <ItemsRender
              items={[
                {
                  label: "Stock Type",
                  value: "Consignment",
                },
              ]}
            />
          ) : (
            <ItemsRender
              items={[
                {
                  label: "Stock Type",
                  value: "Non Stocking",
                },
              ]}
            />
          )}
        </div>
      </Col>
      <Col md={12} className="xflex">
        <BootstrapTable
          bootstrap4
          keyField="specDesc"
          data={[{ consignment: userDef2 ? "Consignment" : "Non Stocking" }]}
          columns={[
            {
              dataField: "consignment",
              text: "Stock Type",
              headerStyle: headerStyle,
              align: "center",
              headerAlign: "center",
            },
          ]}
        />
        {pricingStockData &&
        pricingStockData.length > 0 &&
        pricingStockTypeColoumns.length > 0 ? (
          <BootstrapTable
            bootstrap4
            keyField="specDesc"
            data={pricingStockData}
            columns={pricingStockTypeColoumns}
            wrapperClasses="pricingDisplay"
            noDataIndication
          />
        ) : (
          renderPricingTb()
        )}
      </Col>
    </Row>
  );
};
PricingTable.propTypes = {};
export default PricingTable;
