import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  getAvailableGFXFilter,
  getGFXData,
  getAllPartByGroup,
  getOEPartByGroup,
  getPricingAvailabilityData
} from "./vectorgfx.service";

export const getAvailableVectorGfxFilter = createAsyncThunk(
  "getAvailableVectorGfxFilter",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getAvailableGFXFilter(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getVectorGfxData = createAsyncThunk(
  "getVectorGfxData",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getGFXData(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getAllPartByGroupData = createAsyncThunk(
  "getAllPartByGroupData",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getAllPartByGroup(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getOEPartByGroupData = createAsyncThunk(
  "getOEPartByGroupData",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getOEPartByGroup(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getPartsPricingAvailability = createAsyncThunk(
  "getPartsPricingAvailability",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getPricingAvailabilityData(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);
