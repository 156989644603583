import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
} from "@mui/material";

function Checkbox(props) {
  const { label, className, ...rest } = props;

  return <FormControlLabel className={className} control={<MuiCheckbox {...rest} />} label={label} />;
}

Checkbox.propTypes = {};

export default Checkbox;
