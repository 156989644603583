import React, { useState } from 'react';
import {  useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import fluidsAndFiltersIcon from "../../../static/images/fluidsAndFilters.png";
import vehicleMaintIcon from "../../../static/images/vehicleMaintenance.png";
import FluidsAndFiltersPopup from '../FluidsAndFilters';
import VehicleMaintenancePopup from '../VehicleMaintenance';

function VehicleSpecAndMaintananceIcons(props) {

    const userDetails = useSelector(state => state.user.userDetails);
    const mygarage = useSelector((state) => state.mygarage);
	const search = useSelector((state) => state.search);
	const sv = mygarage.selectedVehicle || search.selectedVehicle;

    const [showVehicleMaintenance, setShowVehicleMaintenance] = useState(false);
	const [showFluidsAndFilters, setShowFluidsAndFilters] = useState(false);

    const handleVehicleMaintenance = () => {
		setShowVehicleMaintenance(true);
	};

  const closeVehicleMaintenance = () => {
    setShowVehicleMaintenance(false);
  };

    const handleFluidSpecs = () => {
		setShowFluidsAndFilters(true);
	};

  const closeFluidSpecs = () => {
    setShowFluidsAndFilters(false);
  };


	return (
        <React.Fragment>
            	<div className="vehicle-spec-and-maintanance d-flex">
					{ sv && userDetails && (userDetails.userSecurityGroup ===
						null ||
						userDetails.userSecurityGroup
							.maintenanceSchedules) &&  (
							<OverlayTrigger
								placement="left"
								overlay={
									<Tooltip id="button-tooltip">
										Vehicle Maintenance
							</Tooltip>
								}
							>
								<input
									type="image"
									className="vehicle-maint-icon"
									// disabled={!sv}
									onClick={() => handleVehicleMaintenance()}
									src={vehicleMaintIcon}
									alt="Vehicle Maintenance Icon"
								/>
							</OverlayTrigger>
						)}
					{showVehicleMaintenance ? (
						<VehicleMaintenancePopup
							openPopup={showVehicleMaintenance}
							selectedVehicle={sv}
							closePopup={() => closeVehicleMaintenance()}
						/>
					) : null}
					{sv && userDetails && (userDetails.userSecurityGroup ===
						null ||
						userDetails.userSecurityGroup
							.fluidsFilters) &&(
							<OverlayTrigger
								placement="left"
								overlay={
									<Tooltip id="button-tooltip">
										Vehicle Specs
									</Tooltip>
								}
							>
								<input
									type="image"
									className="fluids-filters-icon"
									// disabled={!sv}
									onClick={() => handleFluidSpecs()}
									src={fluidsAndFiltersIcon}
									alt="Fluids and Filters Icon"
								/>
							</OverlayTrigger>
						)}


					{showFluidsAndFilters ? (
						<FluidsAndFiltersPopup
							openPopup={showFluidsAndFilters}
							selectedVehicle={sv}
							closePopup={() => closeFluidSpecs()}
						/>
					) : null}
				</div>
        </React.Fragment>
	);
}
export default VehicleSpecAndMaintananceIcons;
