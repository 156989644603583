import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import { Loader } from "@googlemaps/js-api-loader"
import { Link } from "react-router-dom";
import './styles.scss';

const Locations = (props) => {
  const defaultZoom = 10;
  const [google, setGoogle] = useState(null);
  const [storesShown, setStoresShown] = useState(null);
  const stores = useSelector((state) => state.mygarage.storeDetails);
  const loader = new Loader({
    apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    version: "weekly",
  });

  loader.load().then((google) => {
    let selStores = []
    if (!_isEmpty(props.availabilityStores)) {
      props.availabilityStores.map((v) => {
        let store = _find(stores, {
          storeNum: v.storeNum,
        })
        if (!_isEmpty(store)) {
          selStores.push(store);
        }
      })
    }
    if (!_isEmpty(props.selectedStore) && !_isEmpty(stores)) {
      const selStoreData = stores.find((store) => store.storeNum.trim() === props.selectedStore.trim())
      selStores = (props.allCityStores) ? stores.filter((store) => store.city.trim().toLowerCase() === selStoreData.city.trim().toLowerCase()) : [selStoreData];
    }
    if (!_isEmpty(selStores) && _isEmpty(storesShown)) {
      initMap(google, selStores);
      setStoresShown(selStores);
      console.log("Selected store : ", JSON.stringify(selStores));
    }

    setGoogle(google);
  });

  const initMap = (google, selStores) => {
    let isClicked = false;
    let lastStore;
    // The map, centered at Uluru
    let infoWindow = new google.maps.InfoWindow();
    let map = new google.maps.Map(document.getElementById('map'), { zoom: defaultZoom });

    // The marker, positioned at each stores
    let bounds = new google.maps.LatLngBounds();
    selStores.map((selStore) => {
      let data = [];
      data['addr'] = `Store# ${selStore.storeNum.trim()} - ${selStore.address.trim()}, ${selStore.city.trim()}, ${selStore.state.trim()}, ${selStore.zip.trim()}`;
      data['locationClass'] = selStore.locationClass;
      if (!selStore.latitude || !selStore.longitude) {
        return false;
      }
      const lat = selStore.latitude;
      const longtd = selStore.longitude;
      // const lat = 29.721092;
      // const longtd = -95.52764;
      const storeLocation = new google.maps.LatLng(lat, longtd);
      const markerLabel = 1;
      lastStore = storeLocation;
      const locationClass = selStore.locationClass;
      let marker = new google.maps.Marker({
        position: storeLocation, map: map,
        markerID: markerLabel, icon: getMapIcons('blue', 48, locationClass, google), locationClass: locationClass
      });
      (function (marker, data) {
        google.maps.event.addListener(marker, "mouseover", function (e) {
          marker.setIcon(getMapIcons('blue', 64, marker.locationClass, google));
        });
        google.maps.event.addListener(marker, "mouseout", function (e) {
          if (!isClicked) {
            marker.setIcon(getMapIcons('blue', 48, marker.locationClass, google));
          }
          isClicked = false;
        });
        google.maps.event.addListener(marker, "click", function (e) {
          isClicked = true;
          marker.setIcon(getMapIcons('blue', 64, marker.locationClass, google));
          var currentZoom = map.getZoom();
          map.panTo(marker.getPosition());
          infoWindow.setContent(data['addr']);
          infoWindow.open(map, marker);
          map.setZoom(currentZoom);
        });
      })(marker, data);
      bounds.extend(marker.getPosition());
    });
    map.setZoom(defaultZoom);
    if (selStores.length > 1) {
      map.fitBounds(bounds);
    } else {
      map.setCenter(lastStore);
    }
  }

  const getMapIcons = (color, size, locationClass, google) => {
    const markerUrl = "https://maps.google.com/mapfiles/ms/micons/" + color + ".png";
    return {
      url: markerUrl,
      scaledSize: new google.maps.Size(size, size)
    };
  }

  return (
    <React.Fragment>
      <div className="mapWrapper">
        <div id="map" >
        </div>
      </div>
    </React.Fragment>
  );
};

Locations.propTypes = {};

export default Locations;
