import React, { useState, useEffect } from "react";
import {useSelector } from "react-redux";
import Paper from '@mui/material/Paper';

const TotalParts = ({filteredParts}) => {
  const catalogTotalItems = useSelector((state) => state.parts.catalogTotalItems);
  let finalCount = filteredParts? filteredParts?.length : catalogTotalItems
  return (finalCount > 0 && 
    // <Paper
    //   elevation={4}
    //   sx={{ marginTop: '20px', p: '4px 0px 4px 20px', display: 'flex', alignItems: 'center', width: 100 }}
    // >
    //     {finalCount} parts
    // </Paper>
    (<span className="parts-count">Total Parts Count: <b>{finalCount}</b></span>)
  );
}

TotalParts.propTypes = {};

export default TotalParts;

