import httpClient from "../../../services/http";

const buildQueryUrl = (payload) => {
  const params = [];
  for (const k in payload) {
    if (payload.hasOwnProperty(k)) {
      const element = payload[k];
      params.push(encodeURIComponent(k) + "=" + encodeURIComponent(element));
    }
  }
  return params.join("&");
};

export const UpdateUserMobileNumber = (data) => {
    httpClient.put(`/user-service/user`, data)
}
//Driver
export const UpdateDriverDetailsInformation = (data) => {
  httpClient.put(`/user-service/driverdetails`, data);
};
  //OSR
  export const deleteUserOSR = (data) =>
  httpClient.delete(`/user-service/user?${buildQueryUrl(data)}`);

   //SUPER ADMIN 
export const getCustomers = () =>
httpClient.get(`/user-service/customer`);

export const getUsersDriverList = (data) =>
  httpClient.get(`/user-service/driverdetails/company?${buildQueryUrl(data)}`);

export const getUsersEmailList = (data) =>
  httpClient.get(`/user-service/customer/user?${buildQueryUrl(data)}`);

export const deleteUser = (data) =>
  httpClient.delete(`/user-service/user?${buildQueryUrl(data)}`);

export const getModifyUser = (data) =>
  httpClient.get(`/user-service/user?${buildQueryUrl(data)}`);

export const getUserPreference = (data) =>
  httpClient.get(`/user-service/user/preference?${buildQueryUrl(data)}`);

export const updateUserPreference = (data) => {
  if (data.requestItems) {
    return httpClient.post(`/user-service/user/preference`, data.requestItems, {
      params: data.params,
    });
  } else {
    return httpClient.post(`/user-service/user/preference`, data);
  }
};

export const updateUserBillTo = (data) =>
  httpClient.put(`/user-service/user/billTo`, data);

export const getPartImageCookie = () =>
  httpClient.get(`/user-service/cookie/part-image?${buildQueryUrl()}`);

export const getCompaniesList = () => httpClient.get(`/user-service/company`);

export const getCompanyDataBasedOnCompyAdmin = (data) =>
  httpClient.get(`/user-service/company/user?${buildQueryUrl(data)}`);

export const deleteDriver = (data) =>
  httpClient.put(
    `/user-service/driverdetails/pindelete?${buildQueryUrl(data)}`
  );

//DeleteDriver
export const getDeleteDriver = (data) =>
  httpClient.get(`/user-service/driverdetails/company?${buildQueryUrl(data)}`);

export const modifyCompny = (data) =>
  httpClient.put(`/user-service/company?${buildQueryUrl(data)}`);

//impersonate BillTo
export const impersonateBillTo = (data) =>
  httpClient.put(`user-service/user/billTo/impersonate`, data);

export const getListOfUsersByType = (data) =>
  httpClient.get(`/user-service/user/bytype?${buildQueryUrl(data)}`);

export const getJwtTockenUsingSession = (data) =>
  httpClient.post(`/user-service/sms/jwt/token?${buildQueryUrl(data)}`,{authType: 'basic'});

  // -------should remove after iframe development get complete------------------------------
 export const getGUIDusingUserInfo = (data) =>
  httpClient.post(`user-service/sms/session`,{...data,authType: 'basic'});
  // -----------------------------------------------------------------------------------------------
  
//getdeliverypickustores
export const getDelveryPickupDropdownData = () =>
httpClient.get(`/user-service/location`);

/* api for to get user program  benefits  enabling under myaccount */
export const getUserProgramAccess = (data) =>
  httpClient.get(`user-service/checkorlc?${buildQueryUrl(data)}`);

//AccountChangeRequest
  export const getAccountConfig = (queryParams) => httpClient.get(`utils-service/getAccountConfig`);

  /* api for getting all dsw account dropdown data*/
export const getUserDSWAccounts = (data) => httpClient.get(`user-service/user/additionalAccounts?x2Username=${data.x2userName}`);

