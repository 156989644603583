import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { Auth } from 'aws-amplify';
import { InputGroup, DropdownButton, Dropdown, Form, Button, Spinner } from 'react-bootstrap';
import { Otp } from 'react-otp-timer';
import {
    updateToken,
    fetchUserDetails,
} from '../../../actions/auth';
import _every from 'lodash/every';
import { triggerCustomUxCriticalError } from '../../../actions/error';
import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';

const UpdateMobileNumber = (props) => {
    const {
        handleMobNumberOnChange,
        validity,
        handleMobKeyDown,
        mobileNumber,
    } = props;
    const dispatch = useDispatch();
    const [countryCode, setcountryCode] = useState('+1');
    const [showOtp, setshowOtp] = useState(false);
    const [otp, setotp] = useState('');
    const userDetailsArr = useSelector((state) => state.user);
    const smsUser = useSelector((state) => state.app.smsUser);
    const [showLoader, setshowLoader] = useState(false);
    const [otptimer, setotptimer] = useState(2);
    const [showTimer, setshowTimer] = useState(false);
    const [buttonStatus, setbuttonStatus] = useState(true);
    let userMobileNumber = '';
    const timeInterval = () => {
        setTimeout(function () {
            setbuttonStatus(false);
        }, (60 * 2000));
    }
    if (!_isEmpty(userDetailsArr)) {
        userMobileNumber = userDetailsArr.userDetails.mobileNumber;
    }

    useEffect(() => {
        if(!smsUser){
        fetchUserDetails(dispatch);
        }
    }, []);

    useEffect(() => {
        if (buttonStatus === false && showTimer === true) {
            setotptimer(2)
        }
    }, [buttonStatus])

    const handleNewNumberChange = (event) => {
        if (showOtp) {
            setshowOtp(false)
        }
        setbuttonStatus(false);
        handleMobNumberOnChange(event);
    }

    const handleOtp = (event) => {
        if (!_isEmpty(event.target.value)) {
            setotp(event.target.value);
        }
        setshowTimer(false);
    }

    const handleUpdate = async () => {
        const {
            userDetails: {
                active,
                firstName,
                lastName,
                userType,
                x2userName,
                xlPartAccountNumber,
                shipTos,
            }
        } = userDetailsArr;
        setshowLoader(true);
        const shipToId = {};
        shipTos.map((data) => {
            shipToId[data.shipToId.toString()] = data.isDefault;
        });
        const payload = {
            active,
            email: x2userName,
            firstName,
            lastName,
            shipToIds: shipToId,
            userType,
            xlPartAccountNumber,
            mobileNumber: `${countryCode}${mobileNumber}`,
        };
        const statusMessageProps = {
            heading: 'Success',
            message: 'One Time Password has been sent to Your mobile number',
            type: 'success',
        };
        let errorMessage = {
            message: 'Failed',
            type: 'error'
        }
        setbuttonStatus(true);
        const currentUser = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(currentUser, {
            "phone_number": `${countryCode}${mobileNumber}`
        }).then(() => {
            updateToken(dispatch).then(() => {
                Auth.verifyCurrentUserAttribute("phone_number").then((result) => {
                    triggerCustomUxCriticalError(statusMessageProps, dispatch);
                    setshowOtp(true);
                    Auth.currentAuthenticatedUser().then((user)=>{
                        Auth.setPreferredMFA(user, 'NOMFA')
                    });
                }).catch((error) => {
                    if (!_isEmpty(error) && !_isEmpty(error.message)) {
                        errorMessage.message = error.message;
                    }
                    triggerCustomUxCriticalError(errorMessage, dispatch);
                });
            });
            setshowLoader(false);
            if (showTimer === false) {
                setshowTimer(true);
                timeInterval();
            } else {
                setshowTimer(false);
            }
        });
    }

    const updateCountryCode = (key, e) => {
        setcountryCode(e.target.name);
    }

    const updateOtp = () => {
        let errorMessage = {
            message: 'Failed',
            type: 'error'
        };
        const statusMessageProps = {
            heading: 'Success',
            message: 'Your mobile number is successfully modified',
            type: 'success',
        };
        setshowLoader(true);
        Auth.verifyCurrentUserAttributeSubmit("phone_number", otp).then(() => {
            Auth.currentAuthenticatedUser().then((user)=>{
                Auth.setPreferredMFA(user, 'SMS')
            });
            setshowOtp(false);
            setotp('');
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
        }).catch((error) => {
            if (!_isEmpty(error) && !_isEmpty(error.message)) {
                errorMessage.message = error.message;
            }
            triggerCustomUxCriticalError(errorMessage, dispatch);
        });
        setshowLoader(false);
    }

    let style = {
        resendBtn: {
            display: 'none'
        }
    }
    return (
        <Form autocomplete="off">
            <div className="label-input-container">
                <label htmlFor="cuurentMobile">Current Mobile</label>
                <Form.Control
                    type="text"
                    name="cuurentMobile"
                    id="cuurentMobile"
                    readOnly
                    defaultValue={userMobileNumber}
                />
            </div>
            <div>
                <span>Changing Mobile is a 2 step process.</span>
                <ol>
                    <li>Enter your new mobile number and click on "Get One Time Password".</li>
                    <li>You have to enter One Time Password code received on your new mobile number.</li>
                </ol>
                <span>Note:</span>
                <ul>
                    <li>If you have not received One Time Password click on "Get One Time Password" once again.</li>
                    <li>You can change your mobile number any number of times till you do One Time Password verification.</li>
                </ul>
            </div>
            <div className="label-input-container new_mobile_field">
                <label className="new_mobile_label" htmlFor="newMobile">New Mobile</label>
                <InputGroup className={`mobile-no-container ${!showTimer ? 'initial_mobile-no-container' : ''}`}>
                    <DropdownButton
                        as={InputGroup.Prepend}
                        variant="outline-secondary"
                        id="input-group-dropdown-1"
                        title={countryCode}
                        onSelect={(key, e) => {
                            updateCountryCode(key, e);
                        }}
                    >
                        <Dropdown.Item name="+91">+91</Dropdown.Item>
                        <Dropdown.Item name="+1">+1</Dropdown.Item>
                    </DropdownButton>
                    <Form.Control
                        type="text"
                        onChange={handleNewNumberChange}
                        onKeyDown={handleMobKeyDown}
                        name="newMobile"
                        id="newMobile"
                        value={mobileNumber}
                    />
                    <FormValidationErrorMessage
                        condition={!validity.isMobileNoNotNull && !_isEmpty(mobileNumber)}
                        errorMessage="Please enter your Mobile Number"
                    />
                    <FormValidationErrorMessage
                        condition={!validity.isMobileNoNumeric && validity.isMobileNoNotNull}
                        errorMessage="Mobile number must be numeric only"
                    />
                    <FormValidationErrorMessage
                        condition={!validity.isMobileNoLengthBelowLimit && validity.isMobileNoNotNull && validity.isMobileNoNumeric}
                        errorMessage="Mobile number should not exceed 10 numbers"
                    />
                    <FormValidationErrorMessage
                        condition={!validity.isMobileNoMinLength && validity.isMobileNoLengthBelowLimit && validity.isMobileNoNumeric && validity.isMobileNoNotNull && !_isEmpty(mobileNumber)}
                        errorMessage="Mobile number should be 10 numbers"
                    />
                </InputGroup>
            </div>

            <div className="get_otp_button">
                <Button
                    onClick={handleUpdate}
                    className={`user-management-submit-btn otp_button`}
                    disabled={buttonStatus}
                >
                    Get One Time Password
                </Button>
            </div>

            <div className="resend_otp_timer">
                        {showTimer
                            && (
                                <Fragment>
                                    <span>Resend One Time Password in</span>
                                    <Otp
                                        style={style}
                                        minutes={otptimer}
                                        resendEvent={() => { }}
                                    />
                                </Fragment>
                            )}
                    </div>
            {
                showOtp && (
                    <Fragment>
                        <div className={`label-input-container ${showTimer ? 'enter-otp-container' : 'enter-otp-container-margin'}`}>
                            <label htmlFor="otp">Enter One Time Password</label>
                            <Form.Control
                                type="text"
                                className={"updateMobileOTP"}
                                onChange={handleOtp}
                                name="otp"
                                maxLength="16"
                                value={otp}
                            />
                        </div>
                        <Button
                            onClick={updateOtp}
                            className="user-management-submit-btn"
                            disabled={_isEmpty(otp)}
                        >
                            ok
            </Button>
                    </Fragment>
                )
            }
            {
                showLoader
                && (
                    <div className="custom_spinner_container">
                        <Spinner className="custom_spinner" animation="border" />
                    </div>
                )
            }
        </Form >
    );
};

export default UpdateMobileNumber;
