import axios from "axios";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import auth from "./auth";
import { logOut, softLogout, getReduxState } from "../actions/auth";
import { triggerUxCritialErrors } from "../actions/error";
import storage from "../helpers/storage";
import emailUtil from "../helpers/email/emailUtil";
import { getPartImageURLCookie } from "../components/User/UserManagement/usermanagement.thunk";
import {
  setImageURLCookie,
  isImageURLCookieValid,
  timeInUTC,
  closeMitchelApp,
} from "../helpers/utils";
import Swal from "sweetalert2";

let smsToken = storage.get("SMSToken", "local");
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_SMS_DOMAIN}/${process.env.REACT_APP_ORDER_BASE}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Basic b3BzdHJheF9hZG1pbjpQeG1EZjM9eGJmQFVGWUNB",
    "sms-api-key": smsToken,
  },
});
instance.interceptors.request.use(
  function (config) {
    if (config.params) {
      config.dispatch = config.params.dispatch ? config.params.dispatch : null;
      config.params = _omit(config.params, ["dispatch"]);  
    }

    if (config.guid) {
      config.headers['sms-api-key'] = config.guid ? config.guid : null;
    }

    let smsToken = storage.get("SMSToken", "local");
    if (!_isEmpty(smsToken)) {
      config.headers['sms-api-key'] = smsToken;
    }
    
    if (config.dispatch && !isImageURLCookieValid()) {
      config.dispatch(getPartImageURLCookie()).then((resp) => {
        setImageURLCookie(resp.payload);
      });
    }

    // This is for Forbidden FIX
    // const state = config.dispatch(getReduxState());
    // if (!state.app.userAuthenticated) {
    // throw new axios.Cancel("User cancelled the request");
    // }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    console.log(error, "error in sms api");
    // remove the if condition in future when apis are handling error properly
    if (error.response) {
      const { config, data, status } = error.response;
      if (config.dispatch && !auth.isAuthFailed && status != 417) {
        if (status !== 401) {
          emailUtil.sendEmail(data.errors || data, status, config);
        }
        if (!_isEmpty(auth.smsUserSessionData)) {
          if (status === 401 || status === 403) {
            let customMsg = `Session is expired. Please link the RAPID catalog once again.`;
            const payload = {
              body: JSON.stringify(auth.smsUserSessionData),
              fromName: "The Parts House",
              subject: `Mitchel1 Session Expired`,
            };
            emailUtil.sendCustomEmail(payload, config);
            Swal.fire({
              html: `<b>${customMsg}</b>`,
              confirmButtonColor: '#0f5283',
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                closeMitchelApp();
              }
            });
          }             
        } else {
        triggerUxCritialErrors(data.errors || data || error, config.dispatch);
        }
      }
      if (status === 401 || status === 403) {
        softLogout(config.dispatch);
        storage.set("authFailed", true, "local");
        // return null;
      }
      return Promise.reject(error.response.data);
    }
  }
);
export default instance;
