import React, { useEffect, useState } from "react";
import { Tab, Row, Col } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import { numbersOnlyRegExp, alphaCharRegExp, populateSearchDropdownOptions } from '../../../helpers/utils';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from "react-redux";

const defaultFilterOptions = {
  ignoreCase: true,
  matchFrom: 'start'
};

function YmmeSearch(props) {
  const {
    yearList,
    onSelectChange,
    makeList,
    modelList,
    engineList,
    selectedVehicle,
    validity,
    makeListRef,
    yearListRef,
    modelListRef,
    engineListRef,
  } = props;

  const [selectInput, setSelectInput] = useState();
  const [engineSelected, setEngineSelected] = useState(false);
  const search = useSelector(state => state.search);

  const _yearList = populateSearchDropdownOptions(search.yearListAll);
  const _makeList = populateSearchDropdownOptions(search.makesListForYear);
  const _modelList = populateSearchDropdownOptions(search.modelListForYearAndMake);
  const _engineList = populateSearchDropdownOptions(search.engineForYearMakeModel);

  useEffect(() => {
    if (_engineList && _engineList.length === 1 && !engineSelected && selectedVehicle && !selectedVehicle.engineId) {
      onSelectChange(_engineList[0], { action: "select-option", name: "engine", option: undefined });
      setEngineSelected(true);
    }
  }, [_engineList]);

  const findValue = (list, prop) => {
    let value = null;
    if (list) {
      value = list.find((item) => {
        if (prop === 'make' && selectedVehicle && selectedVehicle[prop]) {
          return selectedVehicle.make.includes(item.label);
        } else if (selectedVehicle && selectedVehicle[prop]) {
          return item.label.trim() === selectedVehicle[prop].trim();
        }
      });
    }
    value = value === undefined || value === null ? null : value;
    return value;
  };

  const handleInputChange = (inputValue) => {
    setSelectInput(inputValue);
  }

  const handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (code !== 8 && code !== 9 && code !== 37 && code !== 38 && code !== 39 && code !== 40 && code !== 46);
  }

  const handleKeyDown = (event, name) => {
    switch (name) {
      case 'year':
        if ((numbersOnlyRegExp(event.key) === false || (selectInput && selectInput.length >= 4)) && handleOtherKeys(event.keyCode)) {
          event.preventDefault();
        }
        break;
      case 'make':
        if ((alphaCharRegExp(event.key) === false || (selectInput && selectInput.length >= 25)) && handleOtherKeys(event.keyCode)) {
          event.preventDefault();
        }
        break;
      case 'model':
        if (selectInput && selectInput.length >= 15 && handleOtherKeys(event.keyCode)) {
          event.preventDefault();
        }
        break;
      case 'engine':
        if (selectInput && selectInput.length >= 15 && handleOtherKeys(event.keyCode)) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  }

  return (
    <Tab.Pane eventKey="YMMET">
      <Row className="justify-content-center ymmet-wrapper">
        <Col className="select-container">
          <Select
            options={_yearList}
            name="year"
            placeholder="Year"
            onChange={onSelectChange}
            onKeyDown={(event) => { handleKeyDown(event, 'year') }}
            value={findValue(_yearList, "year")}
            onInputChange={handleInputChange}
            ref={yearListRef}
            inputRef={yearListRef}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#FFC0C2",
                primary50: "#aa0d10",
                primary: "#aa0d10",
              },
            })}
          />
          {/* <FormValidationErrorMessage
            condition={!validity.isYearLengthBelowLimit && validity.isYearMinLength}
            errorMessage={"Year should not exceed above 4 characters"}
          />
          <FormValidationErrorMessage
            condition={!validity.isYearMinLength && validity.isYearLengthBelowLimit}
            errorMessage={"Year should have atleast 4 characters"}
          />
          <FormValidationErrorMessage
            condition={!validity.isYearNumeric && validity.isYearMinLength}
            errorMessage={"Year must be numeric only"}
          /> */}
        </Col>
        <Col className="select-container">
          <Select
            options={_makeList}
            name="make"
            placeholder="Make"
            onChange={onSelectChange}
            onKeyDown={(event) => { handleKeyDown(event, 'make') }}
            isDisabled={!_makeList}
            value={findValue(_makeList, "make")}
            onInputChange={handleInputChange}
            ref={makeListRef}
            openMenuOnFocus={findValue(_makeList, "make") ? false : true}
            inputRef={makeListRef}
            filterOption={createFilter(defaultFilterOptions)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#FFC0C2",
                primary50: "#aa0d10",
                primary: "#aa0d10",
              },
            })}
          />
          {/* <FormValidationErrorMessage
            condition={!validity.isMakeLengthBelowLimit && validity.isMakeMinLength}
            errorMessage={"Make should not exceed above 25 characters"}
          />
          <FormValidationErrorMessage
            condition={!validity.isMakeMinLength && validity.isMakeLengthBelowLimit}
            errorMessage={"Make should have atleast 1 character"}
          />
          <FormValidationErrorMessage
            condition={!validity.isMakeAlphabetic && validity.isMakeMinLength}
            errorMessage={"Make must include alphabets and hypen only"}
          /> */}
        </Col>
        <Col className="select-container">
          <Select
            options={_modelList}
            name="model"
            placeholder="Model"
            onChange={onSelectChange}
            onKeyDown={(event) => { handleKeyDown(event, 'model') }}
            isDisabled={!_modelList}
            value={findValue(_modelList, "model")}
            ref={modelListRef}
            inputRef={modelListRef}
            filterOption={createFilter(defaultFilterOptions)}
            openMenuOnFocus={findValue(_modelList, "model") ? false : true}
            onInputChange={handleInputChange}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#FFC0C2",
                primary50: "#aa0d10",
                primary: "#aa0d10",
              },
            })}
          />
          {/* <FormValidationErrorMessage
            condition={!validity.isModelLengthBelowLimit && validity.isModelMinLength}
            errorMessage={"Model should not exceed above 15 characters"}
          />
          <FormValidationErrorMessage
            condition={!validity.isModelMinLength && validity.isModelLengthBelowLimit}
            errorMessage={"Model should have atleast 1 character"}
          />
          <FormValidationErrorMessage
            condition={!validity.isModelAlphaSpecialChar && validity.isModelMinLength}
            errorMessage={"Model must be alphanumeric and special characters only"}
          /> */}
        </Col>
        <Col className="select-container">
          <Select
            options={_engineList}
            name="engine"
            placeholder="Engine"
            onChange={onSelectChange}
            onKeyDown={(event) => { handleKeyDown(event, 'engine') }}
            isDisabled={!_engineList}
            value={findValue(_engineList, "engine")}
            onInputChange={handleInputChange}
            ref={engineListRef}
            inputRef={engineListRef}
            openMenuOnFocus={findValue(_engineList, "engine") ? false : true}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#FFC0C2",
                primary50: "#aa0d10",
                primary: "#aa0d10",
              },
            })}
          />
          {/* <FormValidationErrorMessage
            condition={!validity.isEngineLengthBelowLimit && validity.isEngineMinLength}
            errorMessage={"Engine should not exceed above 15 characters"}
          />
          <FormValidationErrorMessage
            condition={!validity.isEngineMinLength && validity.isEngineLengthBelowLimit}
            errorMessage={"Engine should have atleast 1 character"}
          />
          <FormValidationErrorMessage
            condition={!validity.isEngineAlphaSpecialChar && validity.isEngineMinLength}
            errorMessage={"Engine must include alphanumeric and special characters only"}
          /> */}
        </Col>
      </Row>
    </Tab.Pane>
  );
}
export default YmmeSearch;
