import React, { useState, Fragment, useEffect } from "react";
import { Container, Row, Col, Modal, Button, Form } from 'react-bootstrap';
import _isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import _uniqBy from "lodash//uniqBy";
import _every from "lodash/every";
import _pick from "lodash/pick";
import closeBlackThin from "../../static/images/closeBlackThin.png";
import Placeholder from "../shared/Placeholder";
import { hasMinLength, isLengthLessthan, hasNumericDecimal, formatAmount, timeInUTC } from '../../helpers/utils';
import FormValidationErrorMessage from '../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import { laborSkillHoverValues } from "../../helpers/constants";
import { logUserStockActivity } from "../../actions/user";

import questionMarkIcon from "../../static/images/questionMark.png";

function LaborGuide({ closePopup, openPopup, data, apiCallStatus }) {

	const initializeValidity = () => {
		const validity = {
			hasMaxLength: true,
			hasMinLength: true,
			isNumericDecimal: true,
			isValidMultipler: true,
		};
		return validity;
	}

	const [validity, setvalidity] = useState(initializeValidity);
	const [laborMultipler, setLaborMultipler] = useState(1);
	const userDetails = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();

	let refs = [];
	if (data && data.laborItems) {
		refs = data.laborItems.reduce((acc, item) => {
			acc[item.uniqueIDLabor] = React.createRef()
			return acc;
		}, {})
	}

	useEffect(() => {
    logUserActivity(true, "isLabourGuideClicked")
    
  }, []);
	
	
		const logUserActivity = (isActivityEnabled, activityName) => {
			const { x2userName, shipTos, billTo } = userDetails;
			if (isActivityEnabled && !_isEmpty(userDetails) && !_isEmpty(billTo)) {
				let loginId = "";
				let shipToId = shipTos[0]?.shipToId;
				let userData = "";
				dispatch(
					logUserStockActivity(
						timeInUTC(),
						billTo.billToId,
						loginId,
						shipToId,
						activityName,
						userData,
						x2userName
					)
				);
				}
		}
	
	const handleLaborMultiplier = (event) => {
		let validity = initializeValidity();
		let multiplerValue = event.target.value;
		validity.hasMaxLength = isLengthLessthan(multiplerValue, 5);
		validity.hasMinLength = hasMinLength(multiplerValue, 1);
		validity.isNumericDecimal = (isLengthLessthan(multiplerValue, 5) && hasNumericDecimal(multiplerValue));
		validity.isValidMultipler = _every(
			_pick(validity, [
				'hasMaxLength',
				'hasMinLength',
				'isNumericDecimal',
			]),
		);
		setvalidity(validity);
		if (validity) {
			setLaborMultipler(event.target.value);
		};
		if (event.target.value !== '') {
		}
	}

	const handleLabourMultiplierBlur = () => {
		let validity = initializeValidity();
		setvalidity(validity);
	}

	const handleRestBtn = () => {
		setLaborMultipler(1)
	}

	const handleJumpToSelection = (_e) => {

		var index = _e.target.selectedIndex;

		var optionElement = _e.target.childNodes[index]
		var option = optionElement.getAttribute('id');
		if (refs[option]) {
			const row = refs[option].current;
			row.scrollIntoViewIfNeeded(true)
			row.classList.add('animate__pulse');

			setTimeout(() => {
				row.classList.remove('animate__pulse');
			}, 2000);
		}
	}

	const createJumToList = () => {
		let jumptoList;
		if (!_isEmpty(data)) {
			let filteredList = _uniqBy(data.laborItems, 'operationObject')
			jumptoList = filteredList.map(item => {
				return (
					item.operationObject && item.operationVerb ?
						<option id={item.uniqueIDLabor}>
							{item.operationObject} - {
								item.operationVerb && item.extendedOperationVerb ?
									item.extendedOperationVerb
									: !item.operationVerb && item.extendedOperationVerb ?
										item.extendedOperationVerb
										: item.operationObject && !item.extendedOperationVerb ?
											item.operationVerb
											: null
							}
						</option>
						:
						null
				)
			})
		}
		return jumptoList;
	}

	console.log('labor items ==> ', data);

	return (
		<Modal show={openPopup} className="labor_guide_popup">
			<Modal.Header>
				<Modal.Title className="title_container">
					<span>labor result</span>
					<img src={closeBlackThin} alt="Close" onClick={() => closePopup('laborGuide')} />
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="form_elements">
					<div>
						<Form.Label>Jump To:</Form.Label>
						<Form.Control size="sm" as="select" onChange={_e => handleJumpToSelection(_e)} disabled={_isEmpty(data)}>
							{createJumToList()}
						</Form.Control>
					</div>
					{/* <Form.Check type="checkbox" label="Show Related Labor only" />
		    		<Form.Check type="checkbox" label="Show with Related Labor" /> */}
					<div className="laborMultiplierContainer">
						<span>Labor Multipler</span>
						<Form.Control size="sm" type="text" name="multipler" onChange={handleLaborMultiplier} onBlur={handleLabourMultiplierBlur} maxLength="6" value={laborMultipler} />
						<FormValidationErrorMessage
							condition={!validity.hasMaxLength && validity.hasMinLength}
							errorMessage="Labor Multipler should not exceed 5 numbers"
						/>
						<FormValidationErrorMessage
							condition={!validity.hasMinLength}
							errorMessage="Labor Multipler should have atleast 1 number"
						/>
						<FormValidationErrorMessage
							condition={!validity.isNumericDecimal && validity.hasMinLength && validity.hasMaxLength}
							errorMessage="Labor Multipler must be numeric or valid decimal value"
						/>
					</div>
					<Button onClick={handleRestBtn}>Reset</Button>
					<div className="questionMarkIcon_container">
						<img src={questionMarkIcon} alt="Question Mark" />
						<div className="question_mark_hover_content">
							<p>Enter a Labor Multipler Value to custom calculate Labor time in hours for all Labor items. Enter greater then 1.0 to increase the Labor hours. Enter less then 1.0 but greater then 0.0 to decrease the Labor hours.</p>
						</div>
					</div>
				</div>
				{
					!apiCallStatus ?
						<Fragment>
							{
								_isEmpty(data) ?
									<span>No Data Found</span>
									:
									<Container className="table__container">
										<Row className="tableContainer">
											<div className="table">
												<div className="tabelColumn">
													<Col md="8">
														<span>Component - Operation</span>
													</Col>
													<Col md="2" className="center_align">
														<span>Skill</span>
													</Col>
													<Col md="2" className="center_align">
														<span>Hours</span>
													</Col>
												</div>
												<p className="count_of_labor_item_text">{data.laborItems.length} labor items found</p>
												<div className="table__body">
													{
														data && data.laborItems ?
															data.laborItems.map((item, index) => {
																return (
																	<div key={index} className="tableRow animate__animated" ref={refs[item.uniqueIDLabor]}>
																		<Col md="8">
																			<Fragment>
																				<span className="operation_value">{item.operationObject} -</span>
																				{
																					item.operationVerb && item.extendedOperationVerb ?
																						<span className="operation_value"> {item.extendedOperationVerb}</span>
																						:
																						!item.operationVerb && item.extendedOperationVerb ?
																							<span className="operation_value"> {item.extendedOperationVerb}</span>
																							:
																							item.operationVerb && !item.extendedOperationVerb ?
																								<span className="operation_value"> {item.operationVerb}</span>
																								:
																								null
																				}
																			</Fragment>
																			<div className="description">
																				{
																					!!item.details || !_isEmpty(item.miscellaneousTexts) ?
																						<Fragment>
																							<p>{item.details}</p>
																							{
																								!_isEmpty(item.miscellaneousTexts) ?
																									item.miscellaneousTexts.map(text => {
																										return (
																											<p>{text}</p>
																										)
																									})
																									:
																									null
																							}
																						</Fragment>
																						:
																						<p>{item.sellText}</p>
																				}
																			</div>
																		</Col>
																		<Col md="2" className="center_align skillData">
																			{!_isEmpty(item.skillLevel) && (<React.Fragment><span className="skill_value">{item.skillLevel}</span>
																				<div className="skill_hover_content">
																					<span>{laborSkillHoverValues[item.skillLevel].heading}</span>
																					<p>{laborSkillHoverValues[item.skillLevel].description}</p>
																				</div></React.Fragment>)}
																		</Col>
																		<Col md="2" className="center_align">
																			<span className="hours_value">{laborMultipler ? formatAmount(laborMultipler * item.estimateTime) : item.estimateTime}</span>
																		</Col>
																	</div>
																)
															})
															:
															null
													}
												</div>
											</div>
										</Row>
									</Container>
							}
						</Fragment>
						:
						<Placeholder rows={3} />
				}
			</Modal.Body>
		</Modal>
	)
}

export default LaborGuide;

