import { combineReducers } from 'redux';
import search from './searchReducer';
import app from './app';
import parts from './parts';
import cart from './cart';
import user from './user';

import myGarageReducer from '../components/shared/MyGarage/mygarage.slice';
import globalSearchReducer from '../components/Homepage/Search/globalSearch.slice';
import fluidsAndFiltersReducer from '../components/shared/FluidsAndFilters/fluidsandfilters.slice';
import chemicalsGlobalSearch from '../components/ChemicalsAndSupplies/chemicalsSearch.slice';
import sitenotification from '../components/shared/SiteNotification/sitenotification.slice';
import getShipToIds from '../components/shared/ShipTo/shipTo.slice';
import userManagement from '../components/User/UserManagement/usermanagement.slice';
import stockCheck from '../components/StockCheck/stockcheck.slice';
import paymentsReducer from '../components/Payments/payments.slice';
import storeDashBoardReducer from '../components/StoreDashboard/storeDashboard.slice';
import expressCartReducer from '../components/Homepage/SimpleHomepage/simplehomepage.slice';
import paymentsPrintReducer from '../components/PaymentsPrint/payments-print.slice';
import stockOrderReducer from '../components/StockOrder/stockorder.slice';
import ProgramAndBenefits from './../components/ProgramAndBenefits/programbenefits.slice';
import candkReducer from "../pages/c_and_k/c_and_k.slice";

const root = combineReducers({
	app,
	cart,
	parts,
	search,
	user,
	mygarage: myGarageReducer,
	globalSearch: globalSearchReducer,
	fluidsandfilters: fluidsAndFiltersReducer,
	chemicalsGlobalSearch: chemicalsGlobalSearch,
	sitenotification: sitenotification,
	ShipToIds: getShipToIds,
	stockCheck: stockCheck,
	userManagement: userManagement,
	storeDashBoard: storeDashBoardReducer,
	payments: paymentsReducer,
	expressCart: expressCartReducer,
	paymentsPrint: paymentsPrintReducer,
	stockOrder: stockOrderReducer,
	ProgramAndBenefits:ProgramAndBenefits,
	candk: candkReducer,

});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT') {
		state = undefined;
	}

	return root(state, action);
};

export default rootReducer;
