import React from 'react';
import { Button as MuiButton } from "@mui/material";

/**
 * Primary UI component for user interaction
 */
const Button = (props) => {
  return <MuiButton {...props} />
};

export default Button;