import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import {
  setPartSearchText
} from "./simplehomepage.slice";

const PartSearch = (props) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(null);

  const handleSearch = (event) => {
    props.searchParts(searchValue);
    dispatch(setPartSearchText({ searchText: searchValue }));
  }

  const onChange = (event) => {
    const val = event.target.value;
    setSearchValue(val);
    props.searchParts(val);
    dispatch(setPartSearchText({ searchText: searchValue }));
  }

  return (
    <Paper
      elevation={4}
      // component="form"
      sx={{ marginTop: '10px', p: '2px 4px', display: 'flex', alignItems: 'center'}}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Parts"
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.keyCode === 13) {
            handleSearch(e);
          }
        }}
      />
      <IconButton sx={{ p: '10px' }} aria-label="search" onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

PartSearch.propTypes = {};

export default PartSearch;

