import { Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import "./dd.scss";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import T from 'prop-types';

function DropDownSearch(props) {
  const { value } = props;
  const searchOptions = ["All", "Part Catalog", "Chemicals"];
  const [selectedItem, setSelectedItem] = useState(value);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      payload: {
        selectedDropDown: value,
      },
      type: 'SELECTED_OPTIONS'
    });
  }, [])

  const handleSortBy = (key, e) => {
    let currentSort = e.target.innerText;
    setSelectedItem(currentSort);
    dispatch({
      payload: {
        selectedDropDown: currentSort,
      },
      type: 'SELECTED_OPTIONS'

    });
  };

  return (
    <div className="search ">
      <InputGroup className="btn-outline-secondary-global-sea ">
        <DropdownButton className="btn-global"
          as={InputGroup.Prepend}
          variant="btn-outline-secondary"
          id="input-group-dropdown-1"
          title={selectedItem}
          onSelect={(key, e) => {
            handleSortBy(key, e);
          }}
        >
          {searchOptions &&
            searchOptions.map((o, i) => {
              return <Dropdown.Item >{o}</Dropdown.Item>

            })}
        </DropdownButton>
      </InputGroup>
    </div>
  );
}

DropDownSearch.propTypes = {
  value: T.string
};

DropDownSearch.defaultProps = {
  value: 'All'
}

export default DropDownSearch;
