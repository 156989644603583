import React, { Fragment, useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import CartPartDetails from "./CartPartDetails.jsx";
import CartPricingDetails from "./CartPricingDetails.jsx";
import { useSelector } from "react-redux";
import { useHistory,useLocation } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";

const CK_Checkout = (props) => {
  const candk = useSelector((state) => state.candk);
  const history = useHistory();
  const {state}=useLocation()
  const warrantyData = state?.warrantyData ?? {};
  const candkCheckoutDetails = useSelector((state) =>state.cart.candkCheckoutDetails);
  const selectedVehicle = useSelector((state) =>state.mygarage.selectedVehicle);

  // Check if candk.ckorder is empty on component mount 

    useEffect(() => {
      if (_isEmpty(selectedVehicle)) {
        history.push("/");
      }
    }, []);
  
  useEffect(() => {
    if (!candk?.ckorder) {
      // Redirect to partsearch page
      history.push("/ck_partsearch");
    }
  }, [candk?.ckorder, history]);

  const clearUserCart = (implicitClear) => {
    console.log("clear user cart");
  };

  return (
    <div>
      <Container fluid className="ck_checkout_container pl-5 pr-5 ">
        {/* <div className="wk-shopping_cart_titles"> */}
        <>
          {/* <Container> */}
          {/* <Fragment>
												<div className="wc-ship-info">
													<div className="text-black">
														<span>
															You are in Check Out Page, please review items and place
															order
														</span>
													</div>
												</div>
											</Fragment> */}
          {/* </Container> */}
          {/* <div className="bread_crum_section_payment mt-2 mb-2">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <a href="/ck_partsearch?page=Engine">
                <span>Checkout</span>
              </a>
            </li>
          </ul>
        </div> */}
        </>
        <Row className="ck_shopping_details">
          <CartPartDetails
            clearUserCart={clearUserCart}
            warrantyData={warrantyData}
            isCallType={state && state.isCallType}
            warrantyId={state && state.warrantyId}
          />
          <CartPricingDetails
            clearUserCart={clearUserCart}
            warrantyData={warrantyData}
            isCallType={state && state.isCallType}
            warrantyId={state && state.warrantyId}
            candkCheckoutDetails={candkCheckoutDetails}
          />
        </Row>
      </Container>
    </div>
  );
};

export default CK_Checkout;
