import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./styles.scss";
import { Row, Col, Container } from "react-bootstrap";
import _ from "lodash";
import { setUserDefaultKey } from "../../User/UserManagement/usermanagement.slice";

const ProgramsAndBenefitsTab = (props) => {
  const dispatch = useDispatch();
  const userDetailsArr = useSelector((state) => state.user);
  const smsUser = useSelector((state) => state.app.smsUser);
  const history = useHistory();

  const handleBenifits = (profile) => {
    console.log("profile",profile);
    dispatch(setUserDefaultKey(profile));
    history.push("/program-benefits/" + profile);
    props.handleCloseOverlay();
  };
  return (
    !_.isEmpty(userDetailsArr) &&
    !_.isEmpty(userDetailsArr.userDetails) && (
      <Fragment>
        <div className="program-benefit-wrapper">
          <Container>
            <Row className="">
              <Col md="4">
                {" "}
                <a
                  className="text_links1 program-benefit-tab-link"
                  onClick={(e) => handleBenifits("OnlineOrderingRebate")}
                >
                  Online Ordering Rebate
                </a>{" "}
              </Col>
              <Col md="4">
                <a
                  className="text_links1 program-benefit-tab-link"
                  onClick={(e) => handleBenifits("LaborClaim")}
                >
                  Labor Claim
                </a>{" "}
              </Col>
            </Row>
            <Row>
              <Col md="4">
                {" "}
                <a
                  className="text_links1 program-benefit-tab-link"
                  onClick={(e) => handleBenifits("PFPVolumeRebate")}
                >
                  PFP Volume Rebate
                </a>{" "}
              </Col>
              <Col md="4">
                {" "}
                <a
                  className="text_links1 program-benefit-tab-link"
                  onClick={(e) => handleBenifits("ProntoSmartChoice")}
                >
                  Pronto Smart Choice
                </a>{" "}
              </Col>
              <Col md="4">
                {" "}
                <a
                  className="text_links1 program-benefit-tab-link"
                  onClick={(e) => handleBenifits("MTAPSN")}
                >
                  MTA PSN
                </a>{" "}
              </Col>
            </Row>
            <Row>
              <Col md="4">
                {" "}
                <a
                  className="text_links1 program-benefit-tab-link"
                  onClick={(e) => handleBenifits("ACDGMPartnerPerks")}
                >
                  ACD GM Partner Perks
                </a>{" "}
              </Col>
              <Col md="4">
                {" "}
                <a
                  className="text_links1 program-benefit-tab-link"
                  onClick={(e) => handleBenifits("BatteryConsignment")}
                >
                  Battery Consignment
                </a>{" "}
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    )
  );
};

export default ProgramsAndBenefitsTab;
