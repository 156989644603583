/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";

import "./styles.scss";
import { currencyFormatter, formatMonth } from "helpers/utils";
import _isEmpty from "lodash/isEmpty";

const headerStyle = {
  backgroundColor: "#0F5283",
  color: "#FFFFFF",
};

const EarnBackTable = (props) => {
  const data = useSelector((state) => state.ProgramAndBenefits);
  const benefitData = data && data.benefitData;
  const earnBackData = (benefitData && benefitData.earnBackList) || [];

  const [earnBackColoumns, setEarnBackColoumns] = useState([]);
  const [earnBackDataTable, setEarnBackDataTable] = useState([]);

  useEffect(() => {
    if (!_isEmpty(earnBackData)) {
      constructEarnBackData(earnBackData);
    }
  }, [earnBackData]);

  const constructEarnBackData = (data) => {
    setEarnBackColoumns([]);
    setEarnBackDataTable([]);
    const rawData = [];

    data.forEach((item, index) => {
      rawData.push({
        month: formatMonth(item.month),
        batteryUnits: item.batteryUnits ? item.batteryUnits : 0,
        goal: item.goal ? Number(item.goal).toFixed(2) : 0,
        testerCredit: item.testerCredit ? item.testerCredit : 0,
        creditdemo: item.invNumber ? item.invNumber : 0,
        invUrl: item.invUrl ? item.invUrl : 0,
      });
    });

    const _earnBackColoumns = [
      {
        dataField: "month",
        text: "Month",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
      {
        dataField: "batteryUnits",
        text: "Battery Units",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
      {
        dataField: "goal",
        text: "Goal",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
      {
        dataField: "testerCredit",
        text: "Tester Credit",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
        formatter: (cell) => {
          return currencyFormatter(cell);
        },
      },
      {
        dataField: "creditdemo",
        text: "Credit Memo",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
        formatter: (cell, row, index) => {
          if (row.creditdemo !== 0) {
            return (
              <a
                href={row.invUrl}
                style={{ textDecoration: "underline" }}
                target="_blank"
                rel="noreferrer"
              >
                {row.creditdemo}
              </a>
            );
          } else {
            return row.creditdemo;
          }
        },
      },
    ];

    setEarnBackColoumns(_earnBackColoumns);
    setEarnBackDataTable(rawData);
  };

  const renderEarnbackTb = () => {
    let headers = Object.values(earnBackColoumns);
    return (
      <Col md={12}>
        <table className="table table-bordered">
          <thead>
            <tr>
              {headers.map((header) => {
                return <th>{header.text}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {earnBackDataTable && earnBackDataTable.length === 0 && (
              <tr>
                <td colSpan={5}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
      </Col>
    );
  };

  return (
    <div>
      <Row>
        <Col md={12}>
          {earnBackDataTable &&
          earnBackDataTable.length > 0 &&
          earnBackColoumns.length > 0 ? (
            <BootstrapTable
              bootstrap4
              keyField="specDesc"
              data={earnBackDataTable}
              columns={earnBackColoumns}
              wrapperClasses="testerDisplay"
              noDataIndication
            />
          ) : (
            renderEarnbackTb()
          )}
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={12} style={{ padding: "26px" }}>
          <span className="header-xlp">Credit Memo</span>
          <br />
          <table className="earn_back_new_table">
            <thead>
              <tr className="upper_heading">
                <th
                  className="blank_coloumn"
                  scope="col"
                  style={{ backgroundColor: "white" }}
                ></th>
                <th scope="col">
                  Month <br />1
                </th>
                <th scope="col">
                  Month
                  <br /> 2
                </th>
                <th scope="col">
                  Month
                  <br /> 3
                </th>
                <th scope="col">
                  Month <br />4
                </th>
                <th scope="col">
                  Month <br />5
                </th>
                <th scope="col">
                  Month
                  <br /> 6
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="side_heading" scope="row">
                  Earn Back <br />
                  Earned
                </th>
                <td>$90</td>
                <td>$90</td>
                <td>$90</td>
                <td>$90</td>
                <td>$90</td>
                <td>$90</td>
              </tr>
              <tr>
                <th className="side_heading" scope="row">
                  Earn Back <br />
                  Remining
                </th>
                <td>$450</td>
                <td>$360</td>
                <td>$270</td>
                <td>$180</td>
                <td>$90</td>
                <td className="highlight_free">Free!</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

EarnBackTable.propTypes = {};
export default EarnBackTable;
