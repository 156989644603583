import React, { useRef, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
import _flatten from 'lodash/flatten';
import _sumBy from 'lodash/sumBy';
import _map from 'lodash/map';
import _every from 'lodash/every';

import './styles.scss';
import { formatAmount, deliveryPickupWarning, getExtendedTotalPrice, getDistributionForNonVehicle, getDistributionForVehicle, distributeAvailability2 } from '../../../helpers/utils';

import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import Placeholder from '../../shared/Placeholder';
import AddToCart from '../../shared/AddToCart/AddToCart';
import ProPackAccSingleCard from '../../shared/ProPack/ProPackAccSingleCard';
import ProPackAccSingleCardChem from '../../shared/ProPack/ProPackAccSingleCardChem';
import SingleSupplyItem from '../../ChemicalsAndSupplies/Listing/SingleSupplyItem';
import AddToCartWarningPopup from "../../shared/AddToCartWarningPopup/AddToCartWarningPopup";
import _cloneDeep from 'lodash/cloneDeep';

function PropackView(props) {
	const dispatch = useDispatch();
	let {
		chemicalJobItems,
		handlePartSelect,
		handleAccordionExpand,
		partsApiCall,
		partsCount,
		partIdMap,
		partJobItems,
		propackName,
		totalPartsCount,
		newTotalPrice,
		cartPrice,
		openAccTab,
		activeAccordion,
		isExpress,
	} = props;

	const accordionHeadersRef = useRef([]);
	const userDetails = useSelector((state) => state.user.userDetails);
	const parts = useSelector((state) => state.parts);
	const cart = useSelector((state) => state.cart);
	const addToCartWarning = false;//deliveryPickupWarning(userDetails, parts)
	const [addToCartWarningPopup, setaddToCartWarningPopup] = useState(false);
	const hideAllPrice =  parts  && parts.hidePrice;
	const addToCartForWarning = () => {
		setaddToCartWarningPopup(true);
	}

	const renderSumListItem = (singleItem, key, part = true) => {
		let summaryDetails = (
			<React.Fragment>
				<div className="propacks_summary_details">
					<span>
						Qty:
						<b>
							{partIdMap[key] ? partIdMap[key].totalOrdrQty : ''}
						</b>
					</span>
                  {!hideAllPrice  && (
					<span>
						Your Price:
						<b>{partIdMap[key] ? partIdMap[key].costPrice : ''}</b>
					</span>)}
					{!hideAllPrice  && (
					<span>
						Core Price:
						<b>{partIdMap[key] && partIdMap[key].selectedPartAvb[key] && partIdMap[key].selectedPartAvb[key].corePrice ? partIdMap[key].selectedPartAvb[key].corePrice : '0'}</b>
					</span>)}
					{!hideAllPrice  && (
					<span>
					Total Extended Price:{' '}
						{newTotalPrice === undefined || newTotalPrice === _sumBy(partIdMap, 'extendedPrice') ?
							(<b>
								{partIdMap[key]
									? 
									getExtendedTotalPrice((partIdMap[key].selectedPartAvb[key] && partIdMap[key].selectedPartAvb[key].corePrice ? partIdMap[key].selectedPartAvb[key].corePrice : 0), partIdMap[key].costPrice ,partIdMap[key].totalOrdrQty)
									: 0}
							</b>) :
							(<b><span style={{ textDecoration: "line-through" }}>
								{partIdMap[key]
									? 
									getExtendedTotalPrice((partIdMap[key].selectedPartAvb[key] && partIdMap[key].selectedPartAvb[key].corePrice ? partIdMap[key].selectedPartAvb[key].corePrice : 0), partIdMap[key].costPrice ,partIdMap[key].totalOrdrQty)
									: 0}
							</span></b>)}
					</span>)}
					{!hideAllPrice  && (
					<span>{cartPrice && cartPrice.map((item, index) => {
						if (index === key && partIdMap[key] && partIdMap[key].extendedPrice && partIdMap[key].extendedPrice !== item.extendedPrice) {
							return (<b>{item.extendedPrice}</b>)
						}
					})}</span>)}
				</div>
			</React.Fragment>
		);
		return (
			<React.Fragment>
				<span>
					{singleItem.name} (Minimum quantity:
					{part ? singleItem.pminSKU : singleItem.cminSKU})
				</span>
				<br />
				{summaryDetails}
				<br />
			</React.Fragment>
		);
	};

	const preparePropackRequestData = () => {
		let selectedAVailabilityHourPayload = [];
		//let selectedAvailData = _flatten(_map(partIdMap, "selectedPartAvb"));
		partIdMap.map((product) => {
			let selectedAvailData =
				product.selectedPartAvb && product.selectedPartAvb[0];
			let tempObj = {
				availabilityHour: selectedAvailData.availableDuration,
				availableQty: selectedAvailData.availableQty,
				cartFlag: "no",
				categoryId: selectedAvailData.categoryId,
				costPrice: selectedAvailData.costPrice,
				corePrice: selectedAvailData.corePrice,
				coreListPrice: selectedAvailData.coreListPrice,
				desc: selectedAvailData.desc,
				descId: selectedAvailData.descId,
				engineId: selectedAvailData.engineId,
				engine: selectedAvailData.engine,
				groupId: selectedAvailData.groupId,
				listPrice: selectedAvailData.listPrice,
				makeId: selectedAvailData.makeId ? selectedAvailData.makeId : null,
				internalMakeId: selectedAvailData.internalMakeId
					? selectedAvailData.internalMakeId
					: null,
				make: selectedAvailData.make ? selectedAvailData.make : null,
				modelId: selectedAvailData.modelId ? selectedAvailData.modelId : null,
				model: selectedAvailData.model ? selectedAvailData.model : null,
				mfgCode: selectedAvailData.mfgCode,
				packQty: selectedAvailData.packQty,
				partNum: selectedAvailData.partNum,
				partType: selectedAvailData.partType,
				qty: selectedAvailData.qty,
				qtyPerVeh: selectedAvailData.qtyPerVeh,
				toolTip: selectedAvailData.toolTip,
				vehicleInfo: selectedAvailData.vehicleInfo,
				yearId: selectedAvailData.yearId ? selectedAvailData.yearId : null,
				store: selectedAvailData.store,
				sellPartnerId: selectedAvailData.sellPartnerId,
				shipMethod: selectedAvailData.shipMethod,
				distStatus: "Completed",
				orderType: "REGULAR"
			};
			let orderArray = [];
			let distributionData = {};
			let cartData  = _cloneDeep(cart);
			cartData.checkoutDetails = selectedAVailabilityHourPayload.length > 0 ? selectedAVailabilityHourPayload : cartData.checkoutDetails;
			if (selectedAvailData.partType === "Regular") {
				distributionData = getDistributionForVehicle(
					selectedAvailData.partNum,
					selectedAvailData,
					selectedAvailData.availability,
					Number(product.totalOrdrQty),
					selectedAvailData.selectedVehicle,
					cartData
				);
			} else {
				distributionData =getDistributionForNonVehicle(
					selectedAvailData.partNum,
					selectedAvailData,
					selectedAvailData.availability,
					Number(product.totalOrdrQty),
					cartData
				);
			}
			orderArray = distributeAvailability2(
				Number(distributionData.totalQty),
				distributionData.filteredAvailability
			);
			selectedAVailabilityHourPayload = [];
			selectedAVailabilityHourPayload.push(
				...distributionData.alreadyAddedItem
			);
			orderArray.map((availabilityData) => {
				tempObj = {
					...tempObj,
					availabilityHour: availabilityData.availableDuration,
					availableQty: availabilityData.availableQty,
					qty: !_isEmpty(availabilityData.orderQty.toString())
						? Number(availabilityData.orderQty.toString())
						: Number("1"),
					distStatus: "Completed",
					orderType: "REGULAR"
				};
				selectedAVailabilityHourPayload.push(tempObj);
			});
		});
		/* below filter for remove duplicates todo later */
		 let uniqueAvailabilityData = selectedAVailabilityHourPayload.filter(
		 	(ele, ind) =>
		 		ind ===
		 		selectedAVailabilityHourPayload.findIndex(
					(elem) =>
					elem.partNum === ele.partNum &&
						elem.mfgCode === ele.mfgCode &&
		 				elem.vehicleInfo === ele.vehicleInfo &&
						elem.availabilityHour === ele.availabilityHour
		 		)
		 );
		 return uniqueAvailabilityData;
	};

	const renderSummary = (summaryKey) => {
		return (
			<Card>
				<Accordion.Toggle
					as={Card.Header}
					eventKey={`${summaryKey}accordion`}
					className="xlp-accordian-header"
					onClick={(e) => { handleAccordionExpand(e, summaryKey, accordionHeadersRef) }}
				>
					Check & Add to cart
				</Accordion.Toggle>
				<Accordion.Collapse eventKey={`${summaryKey}accordion`}>
					<Card.Body ref={el => accordionHeadersRef.current[summaryKey] = el}>
						<div className="propacks_summary">
							<h4 className="summary_heading">Summary</h4>
							<div className="propacks_summary_section">
								{partJobItems.map((singleItem, key) => {
									return renderSumListItem(
										singleItem,
										key,
										true
									);
								})}
								{/* Chem section */}
								{chemicalJobItems.map((singleItem, key) => {
									return renderSumListItem(
										singleItem,
										key + partsCount,
										false
									);
								})}
								{!_isEmpty(partIdMap) && (
									<div className="propack-total-section">
										<span>
											Total Quantity:{' '}
											<b>
												{_sumBy(
													partIdMap,
													'totalOrdrQty'
												)}
											</b>
										</span>
										{!hideAllPrice  && (
										<span>
											Your Total Price:{' '}
											{newTotalPrice === undefined ||
												newTotalPrice ===
												_sumBy(
													partIdMap,
													'extendedPrice'
												) ? (
												<b>
													{formatAmount(_sumBy(
														partIdMap,
														'extendedPrice'
													))}
												</b>
											) : (
												<Fragment>
													<b><span style={{ textDecoration: "line-through" }}>
														{formatAmount(_sumBy(
															partIdMap,
															'extendedPrice'
														))}</span>
													</b>
													<b>{newTotalPrice ? formatAmount(newTotalPrice) : ''}</b></Fragment>
											)}
										</span>)}
										{newTotalPrice && !hideAllPrice  ? (<span>
											Your Savings:{' '}
											<b>{newTotalPrice ? formatAmount(_sumBy(partIdMap, 'extendedPrice') - newTotalPrice) : 0}</b>
										</span>) : ''}
									</div>
								)}
								<FormValidationErrorMessage
									condition={
										partIdMap.length !== totalPartsCount ||
										!_every(_map(partIdMap, 'minQtyAdded'))
									}
									errorMessage={
										'Minimum unique SKUs to be selected is not completed. Please select required SKUs to add the parts to the cart'
									}
								/>
								{addToCartWarning ? (<div className="prod_quantity_section">
								 <Fragment>
		                            <Button
			                           className="add-to-cart-btn"
			                           onClick={() => addToCartForWarning()}
			                           disabled={
											partIdMap.length !==
											totalPartsCount ||
											!_every(
												_map(partIdMap, 'minQtyAdded')
											)
										}
		                            >
			                        Add to cart
		                            </Button>
		                         </Fragment>
								</div>) : (								
								<div className="prod_quantity_section">
									<AddToCart
										// selectedAvailabilityHours={_flatten(
										// 	_map(partIdMap, 'selectedPartAvb')
										// )}
										selectedAvailabilityHours={preparePropackRequestData()}
										disabled={
											partIdMap.length !==
											totalPartsCount ||
											!_every(
												_map(partIdMap, 'minQtyAdded')
											)
										}
										fromPage={"propack"}	
									/>
								</div>)}
							</div>

						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		);
	};

	const renderSingleItemCard = (singleItem, key) => {
		let { name, pminSKU } = singleItem;

		let accHeader = `Step${key + 1}: Select From ${!_isEmpty(name) ? name : `JobItem ${key + 1}`
			} (Minimum quantity:${pminSKU})`;
		return (
			<ProPackAccSingleCard
				singleItem={singleItem}
				pKey={key}
				partIdMap={partIdMap}
				accHeader={accHeader}
				handlePartSelect={handlePartSelect}
				handleAccordionExpand={handleAccordionExpand}
				activeAccordion={activeAccordion}
			/>
		);
	};

	const renderSingleChem = (item, key, jobCount) => {
		let { name, cminSKU, partDetails } = item;
		let count = jobCount + key;
		let accHeaderChem = `Step${count + 1}: Select From ${!_isEmpty(name) ? name : `JobItem ${count + 1}`
			} (Minimum quantity:${cminSKU})`;
		return (
			<ProPackAccSingleCardChem
				partDetails={partDetails}
				partCount={count}
				proPackKey={key}
				partIdMap={partIdMap}
				accHeader={accHeaderChem}
				handlePartSelect={handlePartSelect}
				handleAccordionExpand={handleAccordionExpand}
				activeAccordion={activeAccordion}
			/>
		);
	};

	const renderNoDataState = (singleItem, key, partType) => {
		let { name } = singleItem;
		let count = (partType === 'Chemicals') ? (partsCount + key) : key;
		let noDataTxt = !_isEmpty(name) ? `Step${count + 1}: Select From ${name}` : `Step${count + 1}: Select From JobItem ${count + 1}`;
		return (
			<Card>
				<Accordion.Toggle
					as={Card.Header}
					eventKey={`${partType}NoDataAccordion${key}`}
				>
					{noDataTxt} - No {partType} Found for this job item
				</Accordion.Toggle>
			</Card>
		);
	};
	return (
	  <Fragment>
			<section className={!isExpress ? `propacks_list_main_wrapper` : ``}>
			<Row>
				<Col lg={12} md={12}>
					<div className="right_section">
						{!isExpress && (<div className="propacks_header">
							{`Pro Packs - ${propackName}`}
						</div>)}
						{/* <p class="propacks_header_lbl"> {`Please complete the ${(partJobItems && chemicalJobItems) ? (partJobItems.length + chemicalJobItems.length) : 0} steps below under each grey bar`} </p> */
							<p class="propacks_header_lbl"> {((partJobItems && chemicalJobItems) && (partJobItems.length > 0 || chemicalJobItems.length > 0)) ? `Please complete the ` + (partJobItems.length + chemicalJobItems.length) + ` steps below under each grey bar` : `The Job Definition is incomplete`} </p>}
						{!partsApiCall ? (
							<div className={((partJobItems && chemicalJobItems) && (partJobItems.length > 0 || chemicalJobItems.length > 0)) ? `propacks_list_wrapper` : ``}>
								<Accordion activeKey={`${activeAccordion}accordion`}>
									{!_isEmpty(partJobItems) &&
										partJobItems.map((singleItem, key) => {
											if (
												!_isEmpty(
													singleItem.partDetails
												)
											) {
												return renderSingleItemCard(
													singleItem,
													key
												);
											} else {
												return renderNoDataState(
													singleItem,
													key,
													'Parts'
												);
											}
										})}
									{!_isEmpty(chemicalJobItems) &&
										chemicalJobItems.map((s, k) => {
											if (!_isEmpty(s.partDetails)) {
												return renderSingleChem(
													s,
													k,
													partsCount
												);
											} else {
												return renderNoDataState(
													s,
													k,
													'Chemicals'
												);
											}
										})}


									{((partJobItems && chemicalJobItems) && (partJobItems.length > 0 || chemicalJobItems.length > 0)) ?
										renderSummary(partJobItems.length + chemicalJobItems.length) : null
									}
								</Accordion>
							</div>
						) : (
							<React.Fragment>
								<Placeholder rows={4} />
							</React.Fragment>
						)}
					</div>
				</Col>
			</Row>
		</section>
		{addToCartWarningPopup ? (<AddToCartWarningPopup
			openPopup={addToCartWarningPopup}
			//addedToCartOrQuote={addedToCartOrQuote}
			selectedAvailabilityHours={_flatten(
				_map(partIdMap, 'selectedPartAvb')
			)}
			//source={'chemicals'}
			DefaultAddItemToCart={true}
			closePopup={() => {
				setaddToCartWarningPopup(false)
			}}
		/>) : null}
	  </Fragment>	
	);
}
export default PropackView;
