import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import _isEmpty from "lodash/isEmpty";
import {
	Row,
	Tabs,
	Tab,
	Col,
} from 'react-bootstrap';
import {
	fetchUserDetails,
} from '../../../actions/auth';
import { isInputBlank, isNumberLessthan, hasNumberMinLength, isNumeric, numbersOnlyRegExp } from '../../../helpers/utils';
import UserProfile from './userProfile';
import AddNewUser from '../Admin/AddNewUser';
import AddNewCompany from "../Admin/AddNewCompany";

const UserManagement = (props) => {
	const [key, setKey] = useState('userProfile');
	const userDetailsArr = useSelector((state) => state.user);
	const smsUser = useSelector((state) => state.app.smsUser);
	const OSRDefaultBillTO = process.env.REACT_APP_OSR_DEFAULT_BILLTO;
	const dispatch = useDispatch();
	let userType = '';
	if (!_isEmpty(userDetailsArr)) {
		userType = userDetailsArr.userDetails.userType;
	}
	useEffect(() => {
		if(!smsUser){
		fetchUserDetails(dispatch);
        }
	}, []);

	const initializeFields = () => {
		const fieldObj = {
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		};
		return fieldObj;
	}
	const initializeValidity = () => {
		const validity = {
			hasValidLength: true,
			isPasswordMatched: true,
			isValidPassword: true,
		};
		return validity;
	}
	const initializeMobValidation = () => {
		const mobValidity = {
			isMobileNoNotNull: true,
			isMobileNoLengthBelowLimit: true,
			isMobileNoMinLength: true,
			isMobileNoNumeric: true,
		};
		return mobValidity;
	};

	const [fields, setFields] = useState(initializeFields);
	const [validity, setvalidity] = useState(initializeValidity);
	const [mobValidity, setMobValidity] = useState(initializeMobValidation);
	const [mobileNumber, setMobileNumber] = useState('');
	const handleSelect = (key) => {
		let validityData = initializeValidity();
		if (key === 'manageUsers') {
			setvalidity(validityData);
			setFields(initializeFields);
			setMobValidity(initializeMobValidation);
			setMobileNumber('');
		}
		setKey(key);
	}
	const handleInputChange = (event) => {
		const passwordType = event.target.name;
		const { value } = event.target;
		const PasswordRegEx = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
		let validityData = initializeValidity();
		switch (passwordType) {
			case 'confirmPassword':
				validityData.isPasswordMatched = (fields.newPassword === value);
				setvalidity(validityData);
				break;
			case 'newPassword':
				validityData.hasValidLength = value.length >= 8;
				validityData.isValidPassword = (PasswordRegEx.test(value));
				validityData.isPasswordMatched = (fields.confirmPassword === value);
				setvalidity(validityData);
				break;
			default:
				break;
		}
		setFields({
			...fields,
			[event.target.id]: event.target.value,
		});
	}

	const handleMobNumberOnChange = (data) => {
		const number = data.currentTarget.value;
		const mobNumber = number.charAt(0);
		let value;
		if (mobNumber === ' ') {
			value = number.trim();
		}
		else {
			value = number;
			validateMobileNumber(value);
		}
	}

	const validateMobileNumber = (value) => {
		let validity = initializeMobValidation();
		validity.isMobileNoNotNull = !isInputBlank(value);
		validity.isMobileNoLengthBelowLimit = isNumberLessthan(value, 10);
		validity.isMobileNoMinLength = hasNumberMinLength(value, 10);
		validity.isMobileNoNumeric = isNumeric(value);
		setMobValidity(validity);
		if (validity) {
			setMobileNumber(value);
		};
	}

	const handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
		return (code !== 8 && code !== 9 && code !== 37 && code !== 38 && code !== 39 && code !== 40 && code !== 46);
	}

	const handleKeyDown = (event) => {
		if ((numbersOnlyRegExp(event.key) === false || (event.keyCode === 32) || (mobileNumber && mobileNumber.length >= 10)) && handleOtherKeys(event.keyCode)) {
			event.preventDefault();
		}
	}
	return (
		<div className="user-management-container">
			<div className="container">
				<div className="bread_crum_section user_management_detaild_bread_crum">
					<ul>
						<li>
							<Link to="/">Home</Link>
						</li>
						<li><span>User Management</span></li>
					</ul>
				</div><br></br><br></br><br></br>
				
				{!smsUser && 	
				<Tabs
					id="controlled-tab-example"
					activeKey={key}
					onSelect={handleSelect}
					className="vertical-tabs"
				>
					<Tab eventKey="userProfile" title="User Profile" className="user-management-tab-details">
						<UserProfile
							handleInputChange={handleInputChange}
							handleMobNumberOnChange={handleMobNumberOnChange}
							validity={validity}
							mobValidity={mobValidity}
							fields={fields}
							handleMobKeyDown={handleKeyDown}
							mobileNumber={mobileNumber}
						/>
					</Tab>
					{!_isEmpty(userType) && (userType === 'IT_ADMIN' || userType === 'CUSTOMER_ADMIN' || userType === 'OSR' ||  userType === 'DSM' || userType == 'SUPER_ADMIN')
						&& (
							<Tab eventKey="manageUsers" title="Manage Users">
								<AddNewUser />
							</Tab>
						)}

					{/* {!_isEmpty(userType) &&
            (userType === "IT_ADMIN") && (
              <Tab
                eventKey="manageDriversCompany"
                title="Manage Drivers Company"
              >
                <AddNewCompany />
              </Tab>
            )} */}

				</Tabs>}
			</div>
		</div>
	);
}

export default UserManagement;
