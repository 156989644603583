import React, { useState, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import _find from 'lodash/find';
import { storefilters } from "../storeDashboard.slice";
import { useDispatch } from "react-redux";


function StoreNumberMultiSelect({
	handleStoreFilter,
	storeNumberList,
	currentFilter,
	multiSelectArray,
	defaultFilter
}) {

	const [selectedOptions, setSelectedOptions] = useState([]);
	const [filterOptions, setFilterOptions] = useState([]);
	const dispatch = useDispatch();

	const filterByPartTypes = (partArr, selectedArr) => {
		let res = [];
		if (!_isEmpty(partArr) && !_isEmpty(selectedArr)) {
			res = partArr.filter(el => {
				return selectedArr.value === el.value
			});
			return res;
		}
	}
	useEffect(() => {
		dispatch(
			storefilters(selectedOptions)
		) 
		}, [selectedOptions])

	
		useEffect(() => {
			let options = [];
					let newselectlist = [{ label: "ALL", value: "*" }]
			if ((!_isEmpty(storeNumberList) && storeNumberList.length > 0) || (!_isEmpty(storeNumberList) && !_isEmpty(defaultFilter) && defaultFilter.length > 0)) {
				storeNumberList.map((value) => {
					options.push({ label: value.label, value: value.value });
				});
	
				let data = filterByPartTypes(storeNumberList, defaultFilter);
	
				!_isEmpty(data) && data.map((value) => {
					newselectlist.push({ label: value.label, value: value.value });
				});
	
				if (currentFilter === "ALL" && _isEmpty(data)) {
					setFilterOptions(options);
					setSelectedOptions(options);
	
				} else if (currentFilter === "ALL" && !_isEmpty(data)) {
					setFilterOptions(options);
					if (!_isEmpty(options) && !_isEmpty(newselectlist) && options.length !== newselectlist.length + 1) {
						setSelectedOptions(newselectlist.filter((o) => o.label !== "ALL"));
					} else {
						setSelectedOptions(options);
					}
				}
				else
					if (currentFilter === "Multiple Items") {
						setSelectedOptions(multiSelectArray);
					} else {
						setFilterOptions(options);
						if (typeof currentFilter === 'string') {
							let currentFiterArry = _find(options, {
								value: currentFilter
							});
							setSelectedOptions([currentFiterArry]);
						} else {
							if ((storeNumberList.length - 1) === currentFilter.length) {
								setSelectedOptions(storeNumberList);
							} else {
								setSelectedOptions(currentFilter.filter((o) => o.value));
							}
						}
						}
			}
		}, [storeNumberList, defaultFilter,currentFilter]);

	const onStoreFilterChange = (value, event) => {
		let selectedFilter = [];
		if (event.action === "select-option" && event.option.value === "*") {
			setSelectedOptions(filterOptions.filter((o) => o.value))
			currentFilter = event.option;
			selectedFilter = filterOptions.filter((o) => o.value !== "*");
		} else if (
			event.action === "deselect-option" &&
			event.option.value === "*"
		) {
			setSelectedOptions([]);
			selectedFilter = [];
			currentFilter = "0 selected";
		} else if (event.action === "deselect-option") {
			setSelectedOptions(value.filter((o) => o.value !== "*"));
			let removeAll = value.filter((o) => o.value !== "*")
			selectedFilter = removeAll.filter((o) => o.value !== event.option.value)
		} else if (value.length === filterOptions.length - 1) {
			setSelectedOptions(filterOptions);
			selectedFilter = filterOptions.filter((o) => o.value !== "*");
		} else {
			selectedFilter = value.filter((o) => o.value !== "*");
			setSelectedOptions(value);
		}

		handleStoreFilter(event, selectedFilter);
	}

	const getFilterDdwnBtnLbl = ({ placeholderButtonLabel, value }) => {
		if (value && value.some((o) => o.value === "*")) {
			return `All`;
		} else if (value.length == 0) {
			return `None selected`;
		} else if (value.length == 1) {
			return `${value[0].label}`;
		} else if (value.length > 1) {
			return `Multiple Items`;
		}
	}


	return (<div className="">
		{storeNumberList && storeNumberList.length > 0 && (
			<div class="store_multiSelect">
				<span> Search Store#:</span>{' '}
				<ReactMultiSelectCheckboxes
					className="dropdown-scroll"
					options={filterOptions}
					value={selectedOptions}
					getDropdownButtonLabel={getFilterDdwnBtnLbl}
					onChange={onStoreFilterChange}
					setState={setSelectedOptions}
					groupHeading={true}
					hideSearch={true}
					//hideSearch={!_isEmpty(storeNumberList) && storeNumberList.length < 9 ? true : false}
				/>
			</div>
		)}
	</div>
	);
}
export default StoreNumberMultiSelect;
