/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { benefitDetails } from "./../programbenefits.thunk";
import _isEmpty from "lodash/isEmpty";
import { formatAmount, formatMonth } from "../../../helpers/utils";
import BuyNinePayEight from "./buyNinepayEight.table";
import BatteryBonanza from "./batteryBonanza.table";

import "./styles.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import StockingBatteries from "./stocking-batteries.table";
import BatteryPurchaseTable from "./batteryPurchased.table";
import EarnBackTable from "./earnBack.table";
import PricingTable from "./pricing.table";
import TesterTable from "./tester.table";
import RenderIIcon from "pages/c_and_k/components/RenderIIcon";

const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const BatteryConsignment = (props) => {
  const battery_tables = [
    {
      id: "stock-pricing",
      headerTitle: "Stock Pricing",
      component: <PricingTable />,
    },
    {
      id: "battery-stocking",
      headerTitle: "Stocking Batteries",
      component: <StockingBatteries />,
    },
    {
      id: "battery-tester",
      headerTitle: "Battery Tester",
      component: <TesterTable />,
    },
    {
      id: "earn-back",
      headerTitle: (
        <RenderIIcon
          label="Earnback"
          className="h-title"
          hoverText={
            <div style={{ color: "white" }}>
              <ul className="tooltip-detail">
                <li>Purchase Tester at special price of $ 540</li>
                <li>
                  EarnBack $90/month with minimum of 12 batteries purchased each
                  month
                </li>
                <li>
                  Program runs for 6 months after battery tester\n purchase date
                </li>
                <li>
                  Catch up Earn Back:XL Parts will credit you for the\n earn
                  backs missed as long as a minimum 72 batteries\n where
                  purchased during the 6 months of the earn\n back program
                </li>
                <li>
                  Earn Back will be issed as a statement credit each\n month for
                  previous month's ACDelco battery purchases
                </li>
                <li>Earn Back cannot exceed price paid for the tester</li>
              </ul>
            </div>
          }
        />
      ),
      component: <EarnBackTable />,
    },
    {
      id: "battery-purchased",
      headerTitle: "Battery Purchased",
      component: <BatteryPurchaseTable />,
    },
    {
      id: "buy9Pay8",
      headerTitle: "Buy9Pay8",
      component: <BuyNinePayEight />,
    },
    {
      id: "batteryBonanza",
      headerTitle: "Battery Bonanza",
      component: <BatteryBonanza />,
    },
  ];

  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState("stock-pricing"); // initial accordion default expanded table id

  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;

  //show and hide logic based on part number
  const { batteryProgram } = useSelector((state) => state.ProgramAndBenefits);
  const {
    batteryBuy9Pay8s,
    batteryBonanzas,
    batteryTester,
    batteryPurchased,
    stockingBatteries,
  } = batteryProgram ?? [];

  const showAccordion = (id) => {
    console.log("show accordion id ===> ", id, stockingBatteries);
    switch (id) {
      case "buy9Pay8":
        return !_isEmpty(batteryBuy9Pay8s) ? true : false;
      case "batteryBonanza":
        return !_isEmpty(batteryBonanzas) ? true : false;
      case "earn-back":
        return !_isEmpty(batteryTester) ? true : false;
      // case "battery-purchased":
      //   return !_isEmpty(batteryPurchased) ? true : false;
      case "battery-stocking":
        return !_isEmpty(stockingBatteries) ? true : false;
      case "battery-tester":
        return !_isEmpty(batteryTester) ? true : false;
      default:
        return true;
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getAllBatteryDetails = () => {
    const { xlPartAccountNumber } = userDetails || {};
    let customerNumber = xlPartAccountNumber;
    const payload = {
      name: "batteryConsignment",
      customerId: customerNumber,
      noOfMonths: 12,
    };
    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
    dispatch(benefitDetails(payload))
      .then((action) => {
        constructGridData(action.payload);

        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      })
      .catch((error) => {
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      });
  };
  useEffect(() => {
    getAllBatteryDetails();
  }, []);

  const constructGridData = (data) => {
    const rowSalesData = [];
    if (!_isEmpty(data && data.batteryPurchased)) {
      data.batteryPurchased.forEach((item, index) => {
        rowSalesData.push({
          month: formatMonth(item.monthYear),
          purchaseQty: item.batteriesPurchased ? item.batteriesPurchased : 0,
          goal: formatAmount(item.towardsGoal),
          credit: formatAmount(
            item.creditTowardsTester ? item.creditTowardsTester : 0
          ),
          invoiceNumber: item.invoiceNumber ? item.invoiceNumber : "",
          invoiceUrl: item.invoiceUrl,
          index: index,
        });
      });
    }
  };

  return (
    <div className="consignment-container">
      <div className="accordion-container">
        {battery_tables.map((table, i) => {
          return (
            <React.Fragment>
               {showAccordion(table?.id)?
                <Accordion
                  expanded={expanded === table.id}
                  onChange={handleChange(table.id)}
                  key={`accordion-${i}`}
                  className="battery-accordion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore color="info" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="accordion-header"
                  >
                    <Typography className="h-title">
                      {table.headerTitle}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>{table.component}</AccordionDetails>
                </Accordion> : null}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default BatteryConsignment;
