import React, { Fragment } from "react";
import {
  Alert,
  Tab,
  Nav,
  Form,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  DropdownButton,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import {
  validateUserRegForm,
  populateUserDropDownOptions,
  numbersOnlyRegExp,
  alphaCharRegExp,
  getPreferenceDataFromStorage
} from "../../../helpers/utils";
import { getUsersList, updateUserPreferenceDetails} from "../UserManagement/usermanagement.thunk";
import { fetchShipToIds } from "../../shared/ShipTo/shipTo.thunk";
import { createUser } from "../../../actions/user";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

import _every from "lodash/every";
import Select from "react-select";
import MitchellUsers from "./Mitchell";

import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import DeleteUser from "./DeleteUser";
import ModifyUser from "./ModifyUser";
import ModifyUserITAdmin from "./ModifyUserITAdmin";
import CustomerAdmin from "./CustomerAdmin";
import {
  refreshOtherTabs,
} from '../UserManagement/usermanagement.slice';

const userList = [
  {
    value: "LOCATION_BUYER",
    label: "Location Buyer",
  },
  {
    value: "CUSTOMER_BUYER",
    label: "Customer Buyer",
  },
];

const userListCustAdmin = [
  {
    value: "LOCATION_BUYER",
    label: "Location Buyer",
  },
  {
    value: "CUSTOMER_BUYER",
    label: "Customer Buyer",
  },
  {
    value: "VISITOR",
    label: "Visitor",
  },
  {
    value: "SERVICE_WRITER",
    label: "Service Writer",
  },
];

class AddNewUser extends React.Component {
  constructor(props) {
    super(props);
    let shipToList = !_isEmpty(props.ShipToIds)
      ? populateUserDropDownOptions(props.ShipToIds)
      : [];
    let shipToIds = [];
    let locationsSelected = [];
    if (shipToList.length === 1) {
      locationsSelected = shipToList[0].value;
      shipToIds = [shipToList[0].id];
    }
    this.state = {
      formObject: {
        customerNumber: !_isEmpty(props.userDetails)
          ? props.userDetails.customerCode
          : "",
        firstName: "",
        lastName: "",
        title: "",
        xlpAccNo: !_isEmpty(props.userDetails)
          ? props.userDetails.billTo.billToId
          : "",
        companyName: "",
        xlpLastInvoiceNo: "",
        xlpLastInvoiceAmount: "",
        address: "",
        email: "",
        confirmEmail:"",
        phoneNo: "",
        mobileNo: "",
        comments: "",
        locationsSelected,
        userType: "",
        shipToIds,
      },
      grandAccessAnswer: "",
      grandAccessValue: "",
      grandAccessOptions:"",
      shipToList,
      validity: this.intializeValidations(),
      userCreation: "",
      popUpMessage: "",
      countryCode: "+1",
      defaultShipToSelected: "",
      userUpdated: false,
      userValue: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectBlur = this.handleSelectBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleDefaultSelect = this.handleDefaultSelect.bind(this);
    this.handleMultiSelectBlur = this.handleMultiSelectBlur.bind(this);
    this.handleGrandAccessChange = this.handleGrandAccessChange.bind(this);
    this.createGrandAccessOptions = this.createGrandAccessOptions.bind(this);
  }

  createGrandAccessOptions =(itemArray)=>{
    let userPreferenceDetail = getPreferenceDataFromStorage();
    let grandAccessItemSet=[];
      if (!_isEmpty(userPreferenceDetail)) {
        grandAccessItemSet = userPreferenceDetail.find((item) => {
          return (
            item.text === "Grant Access To" &&
            item.code === "User Management"
          )
        })
      }
    let options =[];
    grandAccessItemSet && grandAccessItemSet.possibleMultiAnswer && grandAccessItemSet.possibleMultiAnswer.map((item)=>{
        let singleOption ={
         value: item.code,
         label: item.text,
         default : item.default
        }
        options.push(singleOption);
    })
    this.setState({
      grandAccessOptions: options,
    });
}

handleGrandAccessChange = (value)=>{
  let tempArr = [];
  if(!_isEmpty(value)){
      value.map((val) => {
          tempArr.push(val);
      });
      this.setState({
              grandAccessValue: tempArr,
            });
  }else{
    this.setState({
          grandAccessValue: [],
        }); 
  }
   let updatedUserPref = getPreferenceDataFromStorage();
  let grandAccessIndex = updatedUserPref.findIndex((item)=>{
      return(
       item.text == "Grant Access To" &&
       item.code == "User Management"
      ) 
  })
  let grandAccessItemSet = updatedUserPref[grandAccessIndex];
  
  let multiAnswer = [];
  tempArr.map((option)=>{
      multiAnswer.push(option.value);
  })
  this.setState({
    grandAccessAnswer: [{id:grandAccessItemSet.id, code:grandAccessItemSet.code, answer:null, multiAnswer:multiAnswer}],
  }); 
}

  intializeValidations() {
    this.validity = {
      isFirstNameNotNull: true,
      isFirstnameLengthBelowLimit: true,
      isFirstnameMinLength: true,
      isFirstNameAlphabetic: true,
      isFirstNameValid: true,
      isLastNameNotNull: true,
      isLastnameLengthBelowLimit: true,
      isLastnameMinLength: true,
      isLastNameAlphabetic: true,
      isLastNameValid: true,
      isEmailIdNotNull: true,
      isEmailIdLengthBelowLimit: true,
      isEmailValidFormat: true,
      isEmailIdMinLength: true,
      isValidEmail: true,
      isValidConfirmEmail :true,
			isConfirmEmailIdNotNull :true,
      isMobileNoNotNull: true,
      isMobileNoLengthBelowLimit: true,
      isMobileNoMinLength: true,
      isMobileNoNumeric: true,
      isValidMobileNo: true,
      isLocationNotNull: true,
      isUserTypeNotNull: true,
      isDefaultlocationSelected: true,
    };
    return this.validity;
  }

  componentDidMount() {
    const {
      userDetails: {
        billTo: { billToId },
      },
    } = this.props;
    const payload = {
      billtoid: billToId,
    };
    fetchShipToIds(payload);
    this.createGrandAccessOptions();
  }

  componentDidUpdate(prevProps) {
    let { shipToList, formObject, userCreation, popUpMessage, userUpdated } =
      this.state;
    let stateChanged = false;
    if (!_isEqual(this.props, prevProps)) {
      if (!_isEqual(this.props.ShipToIds, prevProps.ShipToIds)) {
        shipToList = populateUserDropDownOptions(this.props.ShipToIds);
        if (!_isEmpty(shipToList)&&shipToList.length === 1) {
          formObject.locationsSelected = shipToList[0].value;
          formObject.shipToIds = [shipToList[0].id];
        }
        stateChanged = true;
      }
      if (!_isEqual(this.props.userDetails, prevProps.userDetails)) {
        formObject.companyName = this.props.userDetails.companyName;
        formObject.xlpAccNo = this.props.userDetails.billTo.billToId;
        formObject.customerNumber = this.props.userDetails.customerCode;
        stateChanged = true;
      }
      if (!_isEqual(this.props.messageFromAPi, prevProps.messageFromAPi)) {
        userCreation = this.props.userCreationSuccess;
        popUpMessage = this.props.messageFromAPi;
        window.scrollTo(0, 0);
        stateChanged = true;
        formObject.firstName = "";
        formObject.lastName = "";
        formObject.email = "";
        formObject.confirmEmail = "";
        formObject.mobileNo = "";
        userUpdated = false;
      }

      if (stateChanged) {
        this.setState({
          ...this.state,
          formObject,
          shipToList,
          userCreation,
          popUpMessage,
          userUpdated,
        });
      }
    }
  }

  validateForm() {
    let {
      formObject: {
        firstName,
        lastName,
        title,
        email,
        confirmEmail,
        phoneNo,
        mobileNo,
        userType,
        shipToIds,
        locationsSelected,
      },
      validity,
      defaultShipToSelected,
    } = this.state;
    validity = validateUserRegForm("firstName", firstName, validity);
    validity = validateUserRegForm("lastName", lastName, validity);
    validity = validateUserRegForm("email", email, validity);
    validity = validateUserRegForm('confirmEmail', confirmEmail, validity);
    validity.isValidConfirmEmail = _isEqual(email, confirmEmail);
    validity = validateUserRegForm("phoneNo", phoneNo, validity);
    validity = validateUserRegForm("mobileNo", mobileNo, validity);
    validity = validateUserRegForm(
      "locationsSelected",
      locationsSelected,
      validity
    );
    validity = validateUserRegForm("userType", userType, validity);
    // validity.isValidShipTo = _isEmpty(shipToIds);
    // validity = validateOnlineIdForm('comments', comments, validity);
    this.setState({ ...this.state, validity });
    return _every(validity);
  }

  handleSubmit(e) {
    e.preventDefault();
    let {
      formObject: {
        customerNumber,
        firstName,
        lastName,
        title,
        xlpAccNo,
        companyName,
        xlpLastInvoiceNo,
        xlpLastInvoiceAmount,
        address,
        email,
        phoneNo,
        mobileNo,
        userType,
        shipToIds,
      },
      countryCode,
      defaultShipToSelected,
      grandAccessAnswer
    } = this.state;
    const { userDetails } = this.props;
    const UserPayload = {
      customerId: userDetails.billTo.billToId,
    };
    let { dispatch } = this.props;
    let updatedShipToObj = {};
    if (!_isEmpty(shipToIds)) {
      if (shipToIds.length > 1) {
        shipToIds.map((id) => {
          updatedShipToObj[id.toString()] = id === defaultShipToSelected;
        });
      } else {
        updatedShipToObj[shipToIds[0]] = true;
      }
    }
    if (this.validateForm()) {
      let userObj = {
        address: address,
        companyName: companyName,
        customerNumber: customerNumber,
        email: email,
        firstName: firstName,
        invoiceAmount: xlpLastInvoiceAmount,
        invoiceNumber: xlpLastInvoiceNo,
        lastName: lastName,
        phone: `+${phoneNo}`,
        mobileNumber: `${countryCode}${mobileNo}`,
        shipToIds: updatedShipToObj,
        title,
        userType: userType,
        xlPartAccountNumber: userDetails.xlPartAccountNumber
          ? userDetails.xlPartAccountNumber
          : "",
        comment: "",
      };
      createUser(userObj, dispatch).then((result) => {
        dispatch(getUsersList(UserPayload));

        if(this.props.userCreationSuccess == "success" && !_isEmpty(grandAccessAnswer)){
          let userDetails = {username: email}  
          let data = {requestItems:grandAccessAnswer, params:userDetails}
          dispatch(updateUserPreferenceDetails(data))}

        this.clearCreateUserInputFileds();
      });
    }
  }
  clearCreateUserInputFileds() {
    let locationsSelected = "";
    if (this.state.shipToList.length == 1) {
      locationsSelected = this.state.formObject.locationsSelected;
    }

    this.setState({
      ...this.state,
      formObject: {
        ...this.state.formObject,
        firstName: "",
        lastName: "",
        mobileNo: "",
        userType: "",
        email: "",
        confirmEmail: "",
        locationsSelected,
        defaultShipToSelected: "",
      },
      grandAccessValue: "",
      userValue: null,
      validity: this.intializeValidations()
    });
  }
  handleInputChange(e, data) {
    const { name, value, label } = e.target;
    let { formObject, validity } = this.state;
    const formValue = value.charAt(0);
    let str;
    if (formValue === " ") {
      str = value.trim();
    } else {
      str = value;
      if(name === "confirmEmail"){
				validity.isValidConfirmEmail = _isEqual(formObject.email, str);
				validity = validateUserRegForm(name, str, validity);
			}else{
        validity = validateUserRegForm(name, str, validity);
			}
    }
    formObject[name] = str;
    this.setState({
      ...this.state,
      formObject: {
        ...this.state.formObject,
        ...formObject,
      },
      validity: {
        ...this.state.validity,
        ...validity,
      },
      userUpdated: true,
    });
  }

  handleSelectChange(selectedOption, data) {
    const { name } = data;
    let { formObject, shipToList, defaultShipToSelected, validity } =
      this.state;
    const { value, label } = selectedOption;
    formObject[name] = value;
    let userValue = null;
    let userUpdated = false;
    if (name === "userType") {
      userValue = selectedOption;
    }
    if (name === "locationsSelected") {
      formObject["shipToIds"] = [value];
      defaultShipToSelected = value;
    }
    if (name === "userType" && shipToList.length > 1) {
      formObject["shipToIds"] = [];
      formObject["locationsSelected"] = [];
      defaultShipToSelected = "";
    }
    if (name === "userType" && shipToList.length === 1) {
      userUpdated = true;
    }
    validity = validateUserRegForm(name, value, validity);
    this.setState({
      ...this.state,
      formObject,
      defaultShipToSelected,
      userValue,
      validity: {
        ...this.state.validity,
        ...validity,
      },
      userUpdated,
    });
  }

  handleSelectBlur() {
    // const { name } = event.target;
    let { formObject, validity } = this.state;
    const { userType } = formObject;
    validity = validateUserRegForm("userType", userType, validity);
    this.setState({
      ...this.state,
      formObject,
      validity: {
        ...this.state.validity,
        ...validity,
      },
    });
  }

  handleMultiSelectBlur() {
    let { formObject, validity } = this.state;
    const { userType } = formObject;
    validity = validateUserRegForm(
      "locationsSelected",
      formObject.locationsSelected,
      validity
    );
    this.setState({
      ...this.state,
      formObject,
      validity: {
        ...this.state.validity,
        ...validity,
      },
    });
  }

  handleMultiSelectChange(value, { action, removedValue, name }) {
    let {
      formObject: { locationsSelected, userType },
      defaultShipToSelected,
      validity,
    } = this.state;
    const multiValue = [];
    if (!_isEmpty(value) && value.length > 0) {
      value.map((selectedList) => {
        multiValue.push(selectedList.value);
      });
    }
    if (!_isEmpty(value) && value?.value) {
      multiValue.push(value.value);
    }

    let userUpdated = false;
    locationsSelected = value && value.length > 0 ? multiValue : userType === "LOCATION_BUYER" ? multiValue : [] ;
    if (locationsSelected.length === 0) {
      userUpdated = false;
    } else {
      userUpdated = true;
    }
    defaultShipToSelected =
      locationsSelected.length === 1 ? locationsSelected[0] : "";
    validity = validateUserRegForm(name, value, validity);
    this.setState({
      ...this.state,
      formObject: {
        ...this.state.formObject,
        locationsSelected: locationsSelected,
        shipToIds: locationsSelected,
      },
      validity: {
        ...this.state.validity,
        ...validity,
      },
      defaultShipToSelected,
      userUpdated,
    });
  }

  handleDefaultSelect(selectedOption, data) {
    const { name } = data;
    let { validity } = this.state;
    const { value } = selectedOption;
    this.setState({
      defaultShipToSelected: selectedOption.value,
      validity,
    });
  }

  renderUserTypeSelection() {
    let {
      shipToList,
      formObject: { locationsSelected, userType },
      validity,
    } = this.state;
    const {
      userDetails,
    } = this.props;
    return (
      <React.Fragment>
        {this.props.userCreationApiCall && (
          <div className="custom_spinner_container catalog_spinner">
            <Spinner className="custom_spinner" animation="border" />
          </div>
        )}
        <div className="label-dropdown-container">
          <Form.Label>Select a User Type</Form.Label>
          <div>
            <Select
               options= {(userDetails.userType === "CUSTOMER_ADMIN" ) ? userListCustAdmin : userList}
              name="userType"
              value={this.state.userValue}
              className="custom-dropdown"
              placeholder="Select a User Type"
              onChange={this.handleSelectChange}
              onBlur={this.handleSelectBlur}
            />
            <FormValidationErrorMessage
              condition={!validity.isUserTypeNotNull}
              errorMessage="Please select the type of user you want to create"
            />
          </div>
        </div>
        {userType === "CUSTOMER_BUYER" ? (
          <Fragment>
            <div className="label-dropdown-container">
              <Form.Label>Select Location</Form.Label>
              <div>
                <Select
                  options={shipToList}
                  name="locationsSelected"
                  placeholder="Select location"
                  className="custom-dropdown"
                  isMulti={shipToList.length > 1}
                  isDisabled={!userType || shipToList.length === 1}
                  onChange={this.handleMultiSelectChange}
                  onBlur={this.handleMultiSelectBlur}
                  value={shipToList.filter((shipTo) =>
                    locationsSelected.includes(shipTo.value)
                  )}
                />
                <FormValidationErrorMessage
                  condition={!validity.isLocationNotNull}
                  errorMessage="Please select the location"
                />
              </div>
            </div>
            {locationsSelected.length > 1 && shipToList.length > 1 && (
              <div className="label-dropdown-container">
                <Form.Label>Default Location</Form.Label>
                <div>
                  <Select
                    options={shipToList.filter((shipTo) =>
                      locationsSelected.includes(shipTo.value)
                    )}
                    name="DefaultlocationSelected"
                    placeholder="Select default location"
                    className="custom-dropdown"
                    isDisabled={locationsSelected.length < 1}
                    onChange={this.handleDefaultSelect}
                  />
                  <FormValidationErrorMessage
                    condition={!validity.isDefaultlocationSelected}
                    errorMessage="Please select the default location"
                  />
                </div>
              </div>
            )}
          </Fragment>
        ) : shipToList.length === 1 && userType === "LOCATION_BUYER" ? (
          <div className="label-dropdown-container">
            <Form.Label>Select Location</Form.Label>
            <Select
              options={shipToList}
              name="locationsSelected"
              placeholder="Select Location"
              className="custom-dropdown"
              isDisabled={!userType || shipToList.length === 1}
              onChange={this.handleSelectChange}
              value={shipToList.filter((shipTo) =>
                locationsSelected.includes(shipTo.value)
              )}
            />
          </div>
        ) : (
          <div className="label-dropdown-container">
            <Form.Label>Select Location</Form.Label>
            <div>
              <Select
                options={shipToList}
                name="locationsSelected"
                placeholder="Select location"
                className="custom-dropdown"
                isMulti={shipToList.length > 1 && userType !== "LOCATION_BUYER"}
                isDisabled={!userType || shipToList.length === 1}
                onChange={this.handleMultiSelectChange}
                onBlur={this.handleMultiSelectBlur}
                value={shipToList.filter((shipTo) =>
                  locationsSelected.includes(shipTo.value)
                )}
              />
              <FormValidationErrorMessage
                condition={!validity.isLocationNotNull}
                errorMessage="Please select the location"
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  updateCountryCode = (key, e) => {
    this.setState({
      countryCode: e.target.name,
    });
  };

  handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  handleKeyDown = (event, name) => {
    const {
      formObject: { firstName, lastName, mobileNo, email },
    } = this.state;
    switch (name) {
      case "firstName":
        if (
          (alphaCharRegExp(event.key) === false ||
            (firstName && firstName.length >= 16)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "lastName":
        if (
          (alphaCharRegExp(event.key) === false ||
            (lastName && lastName.length >= 16)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "mobileNo":
        if (
          (numbersOnlyRegExp(event.key) === false ||
            event.keyCode === 32 ||
            (mobileNo && mobileNo.length >= 10)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "email":
        if (
          (event.keyCode === 32 || (email && email.length >= 50)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  render() {
    const {
      validity,
      userCreation,
      popUpMessage,
      formObject,
      formObject: { firstName, lastName, email,confirmEmail, mobileNo, locationsSelected },
      defaultShipToSelected,
      countryCode,
      userUpdated,
      userValue,
      grandAccessOptions,
      grandAccessValue,
    } = this.state;
    const {
      dispatch,
      userDetails: { userType },
    } = this.props;
    return (
      <div className="manage-users-container">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row className="row">
            <Col lg={3} md={4} sm={4} xs={6} className="horizontal-tabs">
              <Nav variant="pills" className="flex-column">
                { (userType == "CUSTOMER_ADMIN" || userType == "SUPER_ADMIN" ) && (
                  <Fragment>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        onClick={() => {
                          this.clearCreateUserInputFileds();
                          dispatch(refreshOtherTabs("create"))
                        }}
                      >
                        Create Users
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second"
                      onClick={() => {
                        dispatch(refreshOtherTabs("modify"))
                      }}
                      >Modify Users</Nav.Link>
                    </Nav.Item>
                   
                  </Fragment>
                )}
                {(userType == "OSR" || userType == "DSM") && (
                  <Fragment>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        onClick={() => {
                          this.clearCreateUserInputFileds();
                          dispatch(refreshOtherTabs("create"))
                        }}
                      >
                        Create Users
                      </Nav.Link>
                    </Nav.Item>
                  </Fragment>
                )}
                <Nav.Item>
                  <Nav.Link
                    eventKey={`${userType !== "IT_ADMIN" ? "third" : "first"}`}
                    onClick={() => {
                      dispatch(refreshOtherTabs("delete"))
                    }}
                  >
                    Delete Users
                  </Nav.Link>
                </Nav.Item>
                {userType === "IT_ADMIN" && (
                  <Fragment>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={`${userType !== "IT_ADMIN" ? "" : "second"}`}
                      >
                        Create Users
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={`${userType !== "IT_ADMIN" ? "" : "third"}`}
                      >
                        Modify Users
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
									<Nav.Link 
                    eventKey={`${userType !== "IT_ADMIN" ? "" : "fourth"}`}>
                    Mitchell Users
                    </Nav.Link>
								</Nav.Item> 
                  </Fragment>
                )}
              </Nav>
            </Col>
            <Col
              lg={9}
              md={8}
              sm={8}
              xs={6}
              className="horizontal-tab-details-container"
            >
              <Tab.Content className="horizontal-tab-details">
                {(userType == "CUSTOMER_ADMIN" || userType == "OSR" || userType == "DSM" || userType == 'SUPER_ADMIN') && (
                  <Fragment>
                    <Tab.Pane eventKey="first">
                      <div className="label-input-container">
                        <label htmlFor="firstName">First Name</label>
                        <div>
                          <Form.Control
                            type="text"
                            onChange={this.handleInputChange}
                            onKeyDown={(event) =>
                              this.handleKeyDown(event, "firstName")
                            }
                            name="firstName"
                            maxLength="16"
                            value={firstName}
                            // placeholder="First Name"
                          />
                          <FormValidationErrorMessage
                            condition={!validity.isFirstNameNotNull}
                            errorMessage="Please enter your first name"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isFirstnameMinLength &&
                              validity.isFirstNameNotNull &&
                              validity.isFirstNameAlphabetic
                            }
                            errorMessage="First Name should be atleast 2 characters"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isFirstnameLengthBelowLimit &&
                              validity.isFirstNameNotNull
                            }
                            errorMessage="First Name should not exceed 16 characters"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isFirstNameAlphabetic &&
                              validity.isFirstNameNotNull
                            }
                            errorMessage="First name must be alpha characters only"
                          />
                        </div>
                      </div>
                      <div className="label-input-container">
                        <label htmlFor="lastName">Last Name</label>
                        <div>
                          <Form.Control
                            type="text"
                            onChange={this.handleInputChange}
                            onKeyDown={(event) =>
                              this.handleKeyDown(event, "lastName")
                            }
                            name="lastName"
                            maxLength="16"
                            value={lastName}
                            // placeholder="Last Name"
                          />
                          <FormValidationErrorMessage
                            condition={!validity.isLastNameNotNull}
                            errorMessage="Please enter your last name"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isLastnameMinLength &&
                              validity.isLastNameAlphabetic
                            }
                            errorMessage="Last Name should be atleast 2 characters"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isLastnameLengthBelowLimit &&
                              validity.isLastNameAlphabetic
                            }
                            errorMessage="Last Name should not exceed 16 characters"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isLastNameAlphabetic &&
                              validity.isLastNameNotNull
                            }
                            errorMessage="Last name must contain alpha characters only"
                          />
                        </div>
                      </div>
                      <div className="label-input-container">
                        <label htmlFor="emailId">Email</label>
                        <div>
                          <Form.Control
                            type="email"
                            onChange={this.handleInputChange}
                            onKeyDown={(event) =>
                              this.handleKeyDown(event, "email")
                            }
                            name="email"
                            maxLength="50"
                            value={email}
                            // placeholder="E-mail"
                          />
                          <FormValidationErrorMessage
                            condition={!validity.isEmailIdNotNull}
                            errorMessage="Please enter your Email"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isEmailIdLengthBelowLimit &&
                              validity.isEmailIdNotNull &&
                              validity.isEmailValidFormat
                            }
                            errorMessage="Email should not exceed 50 characters"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isEmailIdMinLength &&
                              validity.isEmailIdNotNull &&
                              validity.isEmailValidFormat
                            }
                            errorMessage="Email should be atleast 7 characters"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isEmailValidFormat &&
                              validity.isEmailIdNotNull
                            }
                            errorMessage="Email must be in username@domainname.com format"
                          />
                        </div>
                      </div>
                      <div className="label-input-container">
                        <label htmlFor="confirmEmailId">Confirm Email</label>
                        <div>
                          <Form.Control
                            type="email"
                            onChange={this.handleInputChange}
                            onKeyDown={(event) =>
                              this.handleKeyDown(event, "email")
                            }
                            name="confirmEmail"
                            maxLength="50"
                            autocomplete="off"
                            onPaste={(e)=>{e.preventDefault()}}
                            value={confirmEmail}
                            // placeholder="E-mail"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isValidConfirmEmail
                            }
                            errorMessage="Email and confirm email should be same"
                          />
                          <FormValidationErrorMessage
                            condition={
                              !validity.isConfirmEmailIdNotNull && validity.isValidConfirmEmail
                            }
                            errorMessage="Please confirm Email"
                          />
                        </div>
                      </div>
                      <div className="label-input-container">
                        <label htmlFor="mobile">
                          <span className="maroonColorTxt">Cell Phone </span>
                          Number
                        </label>
                        <div>
                          <InputGroup className="mobile-no-container">
                            <DropdownButton
                              as={InputGroup.Prepend}
                              variant="outline-secondary"
                              id="input-group-dropdown-1"
                              title={countryCode}
                              onSelect={(key, e) => {
                                this.updateCountryCode(key, e);
                              }}
                            >
                              <Dropdown.Item name="+91">+91</Dropdown.Item>
                              <Dropdown.Item name="+1">+1</Dropdown.Item>
                            </DropdownButton>
                            <Form.Control
                              type="text"
                              onChange={this.handleInputChange}
                              name="mobileNo"
                              onKeyDown={(event) =>
                                this.handleKeyDown(event, "mobileNo")
                              }
                              maxLength="11"
                              value={mobileNo}
                            />
                            <FormValidationErrorMessage
                              condition={!validity.isMobileNoNotNull}
                              errorMessage="Please enter your Cell Number"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isMobileNoNumeric &&
                                validity.isMobileNoNotNull
                              }
                              errorMessage="Cell number must be numeric only"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isMobileNoLengthBelowLimit &&
                                validity.isMobileNoNotNull &&
                                validity.isMobileNoNumeric
                              }
                              errorMessage="Cell number should not exceed 10 numbers"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isMobileNoMinLength &&
                                validity.isMobileNoLengthBelowLimit &&
                                validity.isMobileNoNumeric &&
                                validity.isMobileNoNotNull
                              }
                              errorMessage="Cell number should be atleast 10 numbers"
                            />
                          </InputGroup>
                        </div>
                      </div>
                      <p>
                        Please ensure this is a cell phone number; a code will
                        be sent to this number to finish registration
                      </p>
                      {this.renderUserTypeSelection()}
                      {userType == "CUSTOMER_ADMIN" && 
                      <div className="label-dropdown-container">
                      <Form.Label>Grant Access To</Form.Label>
                      <Select
                        options={userValue && userValue.value === "SERVICE_WRITER" ? grandAccessOptions : !_isEmpty(grandAccessOptions) && grandAccessOptions?.filter((item)=>  item.label !== "Order Placement")}
                        value={userValue && userValue.value === "SERVICE_WRITER" ? !_isEmpty(grandAccessValue) && grandAccessValue : !_isEmpty(grandAccessValue) && grandAccessValue?.filter((item)=>  item.label !== "Order Placement")}
                            placeholder="Select Grant Access To"
                            className="custom-dropdown"
                            isMulti
                            onChange={(value) => { this.handleGrandAccessChange(value) }}
                            //isDisabled={_isEmpty(selectedModifyUser)}
                        />
                      </div>}
                      <Button
                        type="submit"
                        className="user-management-submit-btn"
                        disabled={
                          this.props.userCreationApiCall ||
                          !_every(validity) ||
                          !userUpdated ||
                          _isEmpty(userValue) ||
                          _isEmpty(confirmEmail) || 
                          (formObject.userType === "CUSTOMER_BUYER" &&
                            Array.isArray(locationsSelected) &&
                            locationsSelected.length > 1 &&
                            _isEmpty(defaultShipToSelected))
                        }
                        onClick={this.handleSubmit}
                      >
                        Ok
                      </Button>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <ModifyUser />
                    </Tab.Pane>
                  </Fragment>
                )}
                <Tab.Pane
                  eventKey={`${userType !== "IT_ADMIN" ? "third" : "first"}`}
                >
                  <DeleteUser />
                </Tab.Pane>
                {userType === "IT_ADMIN" && (
                  <Fragment>
                    <Tab.Pane
                      eventKey={`${userType !== "IT_ADMIN" ? "" : "second"}`}
                    >
                      <CustomerAdmin />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey={`${userType !== "IT_ADMIN" ? "" : "third"}`}
                    >
                      <ModifyUserITAdmin />
                    </Tab.Pane>

                    <Tab.Pane
                      eventKey={`${userType !== "IT_ADMIN" ? "" : "fourth"}`}
                    >
                      <MitchellUsers />
                    </Tab.Pane>
                  </Fragment>
                )}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}

AddNewUser.defaultProps = {
  userDetails: {
    userType: "",
    billTo: {
      billToId: "",
    },
  },
};

function mapStateToProps(state) {
  return {
    userCreationSuccess: state.user.userCreationSuccess,
    userCreationApiCall: state.user.userCreationApiCall,
    userDetails: state.user.userDetails,
    messageFromAPi: state.user.messageFromAPi,
    ShipToIds: state.ShipToIds.shipToIds,
  };
}
export default connect(mapStateToProps)(AddNewUser);
