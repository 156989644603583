import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

function ShipInfoLocationHeader({ shipMethod, storeName }) {
  return (
    <div className="wc-ship-info-header maroonColorTxt">
      <span>
        Ship Method:
        <span className="text-black">
          {shipMethod && shipMethod != null && shipMethod == "0002"
            ? " Pickup "
            : " Delivery "}
        </span>
        From TPH Parts Location :{" "}
        <span className="text-black">{storeName}</span>
      </span>
    </div>
  );
}

export default ShipInfoLocationHeader;
