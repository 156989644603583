import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	getPayerDetails,
	getDigest,
	doTransactionService,
	fetchTransactionService,
	fetchInvoicesService,
	uploadInvoiceSheetService,
  deletePaymentCardService
} from './payments.service';

export const payerDetails = createAsyncThunk('payerDetails', async (payload) => {
	const response = await getPayerDetails();

	return response.data;
});

export const digest = createAsyncThunk('digest', async (payload, { rejectWithValue }) => {
	let response = {};
	try {
		response = await getDigest(payload);
	} catch (error) {
		return rejectWithValue(error);
	}

	return response.data;
});

export const doTransaction = createAsyncThunk('doTransaction', async (payload) => {
	const response = await doTransactionService(payload);

	return response.data;
});

export const fetchTransaction = createAsyncThunk('fetchTransaction', async (payload) => {
	const response = await fetchTransactionService(payload);

	return response.data;
});

export const fetchInvoices = createAsyncThunk('fetchInvoices', async (payload) => {
	const response = await fetchInvoicesService(payload);
	return response.data;
});

export const uploadInvoiceSheet = createAsyncThunk('uploadInvoiceSheet', async (payload) => {
	const response = await uploadInvoiceSheetService(payload.formData, payload.params);

	return response.data;
});

export const deletePaymentCard = createAsyncThunk('deletePaymentCard', async (payload) => {
  const response = await deletePaymentCardService(payload);

  return response.data;
})
