import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchSearch } from "./chemicalsSeach.service";

export const search = createAsyncThunk("chemicalsSearch", async (payload) => {
  const response = await fetchSearch(payload);
  return response.data;
});

export const chemicalsearch = createAsyncThunk("chemicalsSearch", async (payload) => {
  const response = await fetchSearch(payload);
  return response.data;
});