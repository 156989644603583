import React, { useState, useHistory } from "react";
import "./styles.scss";

export function PrivacyPolicy() {
  const policy_xlp_url = `${process.env.PUBLIC_URL}/PrivacyPolicies/TPHGeneralPolicy.pdf`;
  const policy_xlp_urll = `${process.env.PUBLIC_URL}/PrivacyPolicies/TPHCCPA.pdf`;

  return (
    <>
      <footer>
        <div class="ft-legal">
          <ul class="ft-legal-list-general">
            <li>
              <a
                href={policy_xlp_url}
                to="/general-privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
              >
                General Privacy Policy
              </a>

            </li>

            <li>

              <a
                href={policy_xlp_urll}
                to="/ccpa-privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
              >
                CCPA Privacy Policy
              </a>

            </li>
          </ul>
          <ul class="ft-legal-list">
            <li>&copy; 2020 The Parts House - All Rights Reserved.</li>
          </ul>
        </div>
      </footer>
    </>
  );
}
export default PrivacyPolicy;
