import React from "react";
import PropTypes from "prop-types";

import BuyerGuideComponent from "../shared/BuyingGuide";
import { Link } from "react-router-dom";

const BuyersGuide = (props) => {
  return (
    <React.Fragment>
      <div className="container">
        <div className="bread_crum_section">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>Buyers Guide</span>
            </li>
          </ul>
        </div>

        <BuyerGuideComponent />
      </div>
    </React.Fragment>
  );
};

BuyersGuide.propTypes = {};

export default BuyersGuide;
