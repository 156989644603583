import { createSlice } from "@reduxjs/toolkit";

import {
  getVehicleMap,
  getVehicleSpec,
  getVehicleSpecInfo
} from "./fluidsandfilters.thunks";

let initialState = {
  loading: false,
  error: false,
  vehicleMap: [],
  vehicleSpec: [],
  vehicleSpecInfo: null,
  currentRequestId: null,
  msg: null
};

const fluidsAndFiltersSlice = createSlice({
  name: "fluidsandfilters",
  initialState,
  reducers: {
    // setVehicleSelected(state, action) {
    //   state.selectedVehicle = action.payload;
    // },
    // setPartSelected(state, action) {
    //   state.selectedPart = action.payload;
    // },
  },
  extraReducers: {
    //Vehicle Map
    [getVehicleMap.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getVehicleMap.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.vehicleMap.push(action.payload);
        state.currentRequestId = null;
      }
    },
    [getVehicleMap.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = null;
      }
    },
    //Vehicle Spec
    [getVehicleSpec.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getVehicleSpec.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.vehicleSpec.push(action.payload);
        state.currentRequestId = null;
      }
    },
    [getVehicleSpec.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = null;
      }
    },
    //Vehicle Spec Info
    [getVehicleSpecInfo.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getVehicleSpecInfo.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.vehicleSpecInfo = action.payload;
        state.currentRequestId = null;
      }
    },
    [getVehicleSpecInfo.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = null;
      }
    }
  }
});

const { actions, reducer } = fluidsAndFiltersSlice;

export const {
  // getMyGarageVehicles,
  // setVehicleSelected,
  // setPartSelected,
} = actions;

export default reducer;
