import testSMS from "../services/testSMS";
import loginSMS from "../services/loginSMS";
import testSMSOrderAPI from "../services/testSMSOrderAPI";
import shipTos from "../services/shipTo";
import smsUserBase from "../services/smsUserBase";
import { triggerCustomUxCriticalError } from "./error";

export const actionTypes = {
  USER_CREATION_API_CALL: "USER_CREATION_API_CALL",
  USER_CREATION_COMPLETE: "USER_CREATION_COMPLETE",
  PASSWORD_RESET_API_CALL: "PASSWORD_RESET_API_CALL",
  PASSWORD_RESET_COMPLETE:"PASSWORD_RESET_COMPLETE"
};

const buildQueryUrl = (payload) => {
  const params = [];
  for (const k in payload) {
    if (payload.hasOwnProperty(k)) {
      const element = payload[k];
      params.push(encodeURIComponent(k) + "=" + encodeURIComponent(element));
    }
  }
  return params.join("&");
};

export const userLoginToken = (req) => {
  return (dispatch) => {
    return testSMS
      .post(`/sms/user/login`, req)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {});
  };
};

export const getSMSResults = (data) => {
  return (dispatch) => {
    return loginSMS
      .post(`/sms/parts/oem/pricing-availability`, data)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {});
  };
};

export const getSMSOrderStatus = (id) => {
  return (dispatch) => {
    return testSMSOrderAPI
      .get(`/sms/orders/${id}/status`, {})
      .then((result) => {
        return result.data;
      })
      .catch((err) => {});
  };
};

export const getSMSOrders = (data) => {
  return (dispatch) => {
    return testSMSOrderAPI
      .get(`/sms/orders`, data)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {});
  };
};

export const getShipToIds = (data) => {
  return (dispatch) => {
    return shipTos
      .get(`/shipto?${buildQueryUrl(data)}`)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {});
  };
};

export const registerMitchellUser = (userObj, dispatch) => {
  const statusMessageProps = {
    heading: "User Created Successfully",
    // message: `Email verification has been sent to ${userObj.user_email}`,
    type: "success",
  };
  let requestParams = {
    dispatch,
  };
  if (userObj.userType && userObj.userType === "COMPANY_ADMIN") {
    requestParams = {
      dispatch,
      companyId: userObj.companyId,
      updatedBy: userObj.updatedBy,
      updatedDate: userObj.updatedDate,
    };
  }
  dispatch({
    payload: {
      userCreationApiCall: true,
    },
    type: actionTypes.USER_CREATION_API_CALL,
  });
  return smsUserBase
    .post(`/sms/user/registerSMSPartner2`, userObj, {
      params: requestParams,
    })
    .then((result) => {
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      dispatch({
        payload: {
          userCreationSuccess: "success",
          message: result.data,
          userCreationApiCall: false,
        },
        type: actionTypes.USER_CREATION_COMPLETE,
      });
    })
    .catch((error) => {
      let statusMessageProps = {
        heading: error.errorMessage || "",
        message: "",
        type: "error",
      };
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      dispatch({
        payload: {
          userCreationSuccess: "failed",
          message: error.errorMessage || "",
          userCreationApiCall: false,
        },
        type: actionTypes.USER_CREATION_COMPLETE,
      });
    });
};
export const changeMitchellUserPassword = (userObj, dispatch) => {
  const statusMessageProps = {
    heading: "Password changed successfully",
    // message: `Email verification has been sent to ${userObj1.user_email}`,
    type: "success",
  };
  let requestParams = {
    dispatch,
  };
  if (userObj.userType && userObj.userType === "COMPANY_ADMIN") {
    requestParams = {
      dispatch,
      companyId: userObj.companyId,
      updatedBy: userObj.updatedBy,
      updatedDate: userObj.updatedDate,
    };
  }
  dispatch({
    payload: {
      passwordCreationApiCall: true,
    },
    type: actionTypes.PASSWORD_RESET_API_CALL,
  });
  return smsUserBase
  .post(`/sms/user/changePassword`, userObj, {
      params: requestParams,
    })
    .then((result) => {
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      dispatch({
        payload: {
          passwordCreationSuccess: "success",
          message: result.data,
          passwordCreationApiCall: false,
        },
        type: actionTypes.PASSWORD_RESET_COMPLETE,
      });
    })
    .catch((error) => {
      let statusMessageProps = {
        heading: error.errorMessage || "",
        message: "",
        type: "error",
      };
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      dispatch({
        payload: {
          passwordCreationSuccess: "failed",
          message: error.errorMessage || "",
          passwordCreationApiCall: false,
        },
        type: actionTypes.PASSWORD_RESET_API_CALL,
      });
    });
};

