import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { triggerCustomUxCriticalError } from '../../../actions/error';
import { createOrUpdateStoreUser } from '../storeDashboard.thunks';
import XModal from '../../shared/XModal';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';

const Escalate = (props) => {
	let {
		row,
		escalateModal,
		headerLineItems,
		handlePageChange,
		setEscalateLineId,
		setEscalateModal,
		ESCALATED,
		displayErrorMsg,
		setPartrow,
		clearHeaderId,
		flag,
		warrantyUpdatedData
	} = props;

	const dispatch = useDispatch();
	const [ escalateLoader, setEscalateLoader ] = useState(false);
	const [ multiPartValue, setMultiPartValue ] = useState('');

	const handleClose = () => {
		setEscalateLineId('');
		setMultiPartValue('');
		setEscalateModal(false);
	};
	const handleEscalateAprrove = (line, headderRows) => {
		setEscalateLoader(true);
		let updatedInvoiceData = warrantyUpdatedData.filter((obj) => obj.rowId === line.rowId);
		let singleData = updatedInvoiceData[0] || line;

		let data = [];
		let returnPayload = [];

		data.push({
			id: singleData.id || '',
			mfgCode: singleData.pickupMfgCode || '',
			partNumber: singleData.pickupPartNumber || '',
			qty: singleData.storeQty,
			status: ESCALATED,
			returnType: singleData.returnType,
			reason: singleData.reason || '',
			creditAmount: singleData.creditAmount || '',
			description: singleData.partDesc || '',
			reviewComment: multiPartValue || '',
			invoiceNumber: singleData.invoiceNumber || '',
			purchaseDate:
				singleData.invoiceDate && line.returnType === 'Warranty'
					? moment(singleData.invoiceDate).format('YYYY-MM-DD HH:mm:ss')
					: '',
			invoices:
				line.returnType === 'Warranty'
					? [
							{
								invoiceNumber: singleData.label,
								purchaseDate: moment(singleData.invoiceDate).format('YYYY-MM-DD HH:mm:ss')
							}
						]
					: []
		});
		returnPayload = {
			return_id: headderRows.id,
			action: 'save',
			payload: {
				lineItems: data
			}
		};
		dispatch({
			payload: {
				headerId: headderRows.id
			},
			type: 'STORE_HEADERID'
		});
		dispatch(createOrUpdateStoreUser(returnPayload)).then((action) => {
			let statusMessageProps = {
				heading: '',
				message: '',
				type: ''
			};
			if (action.type === 'createOrUpdateStoreUser/fulfilled') {
				setEscalateLoader(false);
				statusMessageProps.heading = 'Updated successfully';
				statusMessageProps.type = 'success';
				triggerCustomUxCriticalError(statusMessageProps, dispatch);
				handlePageChange('', false);
				handleClose();
				if (flag === false) {
					setPartrow([]);
				}
			} else {
				displayErrorMsg();
				clearHeaderId();
				setEscalateLoader(false);
			}
		});
	};

	return (
		<XModal
			show={escalateModal}
			showButtons={false}
			title="Escalate"
			className="change_store_save_quote_modal"
			favoriteTextValidity={true}
			submitType={true}
			handleAction={() => handleClose()}
			handleClose={() => handleClose()}
			submitButtonText="OK"
		>
			<div className="change_shipTo_modal_heading">
				<span className="escalate-textarea-msg">
					The Unlinked Return will be assigned to the{' '}
					<span className="marron_text">Area Manager / CC Manager</span> for Approval.
				</span>
				<span className="escalate-textarea-msg">
					Add your comments in below text box for Approver to see and click Yes
				</span>
				<div className="stock_check_textarea">
					<textarea
						id="textArea"
						rows="4"
						cols="45"
						maxlength="1000"
						style={{ overflowY: 'scroll', height: '130px', width: '95%' }}
						onChange={(event) => setMultiPartValue(event.target.value)}
						value={multiPartValue}
					/>
				</div>
			</div>
			<div className="shipTo_modal_button_padding">
				<span className="excalate_button">
					{escalateLoader ? (
						<Button className="store_button" variant="primary" disabled>
							{' '}
							<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
							Loading..
						</Button>
					) : (
						<Button
							className="store_savePost_button"
							// disabled={_isEmpty(multiPartValue)}
							onClick={(event) => {
								handleEscalateAprrove(row, headerLineItems);
							}}
						>
							YES
						</Button>
					)}
				</span>
			</div>
		</XModal>
	);
};
export default Escalate;
