import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import { Row, Form, Card, Col, OverlayTrigger, Tooltip, } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProPacksListing from "../../Listing/ProPacks";

const PropackDetails = (props) => {
  const dispatch = useDispatch();
  const expressCart = useSelector((state) => state.expressCart);
  const parts = useSelector((state) => state.parts);
  let partsAllDetails = parts.partsAllDetails;
  const [filteredPropackParts, setFilteredPropackParts] = useState([]);
  const propackData = props.propackData;
  const selectedProPackDetails = propackData.selectedExpressProPack;

  useEffect(() => {
    if (!_isEmpty(props.propackData)) {
      const partItem = !_isEmpty(partsAllDetails) && partsAllDetails.find((part) => {
        return (
          part.year === propackData.yearCode
          && part.make === propackData.makeCode
          && part.model === propackData.modelCode
          && part.engine === propackData.engineCode
          && parts.selectedProPack.id === selectedProPackDetails.id
        )
      });
      // && partItem.categoryId === partData.partCategoryCode
      if (partItem) {
        setFilteredPropackParts(parts);
      }
      if (_isEmpty(partsAllDetails) && !_isEmpty(parts.catalogRequestVehicle)) {
        if (parts.catalogRequestVehicle.year === propackData.yearCode
          && parts.catalogRequestVehicle.makeId === propackData.makeCode
          && parts.catalogRequestVehicle.modelId === propackData.modelCode
          && parts.catalogRequestVehicle.engineId === propackData.engineCode
          && parts.selectedProPack.id === selectedProPackDetails.id) {
          setFilteredPropackParts(parts);
        }
      }
    }
  }, [partsAllDetails]);

  return (
    <React.Fragment>
      {!_isEmpty(filteredPropackParts) ? (
        <React.Fragment>
          <Row>
            <Col lg={12}>
              <div className="text-center propack-detail-bredcrum">
                {propackData.yearCode}
                <span className="text-black">{" > "}</span>
                {propackData.make}
                <span className="text-black">{" > "}</span>
                {propackData.model}
                <span className="text-black">{" > "}</span>
                {propackData.engine}
                <span className="text-black">{" > "}</span>
                Propacks
                <span className="text-black">{" > "}</span>
                {selectedProPackDetails.jobName}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <ProPacksListing filteredPropackParts={filteredPropackParts} isExpress={true} expressNodeData={props.propackData} />
            </Col>
          </Row>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

PropackDetails.propTypes = {};

export default PropackDetails;
