import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from 'lodash/isEmpty';
import PaginationComponent from "../shared/Pagination";
import { Row, Col } from 'react-bootstrap';
import Filter from './storeFilter/Filter';
import { fetchReturn} from './storeDashboard.thunks';
import HomePage from './HomePage';
import Header from './storeProfile/Header';
import { triggerCustomUxCriticalError } from '../../actions/error';
import storage from "../../helpers/storage";
import {themeColor} from "../../helpers/utils";
import { getPartImageURLCookie } from "../User/UserManagement/usermanagement.thunk";
import { getAllStoreNumber} from "../../components/shared/MyGarage/mygarage.thunks";
import moment from 'moment';
import _cloneDeep from "lodash/cloneDeep";
import _ from "lodash";
import { getAllStoreNumberForManager } from "../../components/StoreDashboard/storeDashboard.thunks";


let url = "/store-management";
let urlName = "Store Management";

const STORE_USER =  "STORE_USER";
const AREA_MANAGER =  "AREA_MANAGER";
const CC_MANAGER =  "CC_MANAGER";
const OPERATIONS_MANAGER = "OPERATIONS_MANAGER";


const Manager = (props) => {
  
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetails);
  const storeBoard = useSelector((state) => state.storeDashBoard);
  const returnList = !_isEmpty(storeBoard) && storeBoard.returnList.partReturns;
  const UserRole = !_isEmpty(storeBoard) && storeBoard.userRoleName;
  const storeNumberList = !_isEmpty(storeBoard) && storeBoard.managerStoreNumList;
  const mygarage = useSelector((state) => state.mygarage);
  const storeNumberOptions = mygarage.storeNumberOptions;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [rowData, setRows] = useState([]);
  const returnsLoader = !_isEmpty(storeBoard) && storeBoard.returnsLoading;
  const [dates, setDates] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customerNum, setCustomerNum] = useState("");
  const [totalpages, setTotalPages] = useState("");
  const [partNum, setPartNum] = useState("");
  const [ACX, setACX] = useState("");
  const [REF, setREF] = useState("");
  const [status, setStatus] = useState("");
  const [ExpandRows, setExpandRows] = useState(true);
  const [APIloader, setAPILoader] = useState(false);
  const [storeNumberLists , setStoreNumberList] = useState([]);
  const [storeNum , setStoreNum] = useState([]);
  const [customerName, setCustomerName] = useState("");

  let option = [{ label: "Escalated", value: "Escalated" }]

  useEffect(() => {
    dispatch(getAllStoreNumberForManager());
    dispatch(getAllStoreNumber());
    dispatch(getPartImageURLCookie()).then((resp) => {
      storage.set("imageURLCookie", resp.payload || {}, "local");
    });
  }, [])

  useEffect(() => {
    dispatch(getAllStoreNumber());
 }, []);

  useEffect(() => {

    if( (!_isEmpty(storeNumberOptions) && userDetails.userType === OPERATIONS_MANAGER) || (userDetails.userType !== OPERATIONS_MANAGER && !_isEmpty(storeNumberOptions) && !_isEmpty(storeNumberList))){
      let cloneStoreList = _cloneDeep(storeNumberList);
      cloneStoreList.push(userDetails?.storeNumber);
      cloneStoreList = _.uniq(cloneStoreList);
      let store = [];
      let data=[];
      if(!_isEmpty(userDetails) && userDetails.userType === OPERATIONS_MANAGER){
       store = storeNumberOptions;
      } else{
        store =  filterByStoreNumber(storeNumberOptions , cloneStoreList);
            }
   if(!_isEmpty(store) && !_isEmpty(store[1])){
    data.push(store[1]);
    setStoreNum(data);
    setStoreNumberList(store);
    initialFetchStoreData(store[1]?.value);
    }}
  }, [storeBoard.managerStoreNumList,storeNumberOptions]);

  
  const displayErrorMsg = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = "Internal System Error";
    statusMessageProps.type = 'error';
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  }
  
  const initialFetchStoreData = (storeData) => {
    const payload = {
      page: 1,
      status: option[0].value,
      pageSize: pageSize,
      storeNumber : !_isEmpty(storeData) ? storeData : !_isEmpty(storeNumberList)  ? storeNumberList :  userDetails.storeNumber,
    }
    setAPILoader(true);  
    dispatch(fetchReturn(payload)).then((action) => {
      if (action.type === "fetchReturn/fulfilled") {
        if (!_isEmpty(action.payload)) {
          setCurrentPage(action.payload.page);
          setStatus(option[0]);
          setTotalPages(action.payload.totalPages);
          let oldArray = action.payload.partReturns;
          var newArray = Object.assign([], oldArray);
          setRows(newArray);
          setAPILoader(false);  
        }
      } else {
        displayErrorMsg();
        setAPILoader(false);  
      }
    })
  }

  const handleOnApplyButn = () => {
    dispatch({
      payload: {
        lineItemData: [],
      },
      type: "STORE_DATA",
    });
    dispatch({
      payload: {
        updatedInvoicesWarrantyDates: {},
      },
      type: "UPDATE_INVOICES_WARRANTY_DATES",
    });

    let storeArr = [];
    if (!_isEmpty(storeNum) && storeNum[0]) {
      storeNum.forEach((v) => {
        if (v.value !== "*") {
          storeArr.push(v.value);
        }
      });
    } else if (!_isEmpty(storeNum) && storeNum.value) {
      storeArr.push(storeNum.value);
    }
    const payload = {
      startTime: startDate ? startDate : "",
      endTime: endDate ? endDate : "",
      page: 1,
      pageSize: pageSize,
      customerId: customerNum ? customerNum : "",
      confNum: ACX ? ACX : "",
      refNum: REF ? REF : "",
      partNumber: partNum ? partNum : "",
      status: status.value ? status.value : option[0].value,
      storeNumber : !_isEmpty(storeNum) ? storeArr : [],
      customerName: customerName ? customerName : "",

    }
    setAPILoader(true) ; 
    dispatch(fetchReturn(payload)).then((action) => {
      if (!_isEmpty(action.payload)) {
        setCurrentPage(action.payload.page);
        setTotalPages(action.payload.totalPages);
        let oldArray = action.payload.partReturns;
        setRows(oldArray);
        setAPILoader(false) ; 
      }else{
        setAPILoader(false) ; 
      }
    })

  };

  const getPreviousMonthDate =()=>{
      let date = new Date();
      let startDates = date;
      var prev = moment(date).subtract(1, 'months').format('MM/DD/YYYY');
       setDates({ start_date: startDates, end_date: prev });
      setDateFilter(`${moment(startDates).format("MM/DD/YYYY")} - ${prev}`);
      setStartDate(moment(startDates).format("YYYY-MM-DD"));
      setEndDate(moment(prev).format("YYYY-MM-DD"));
  }

  const clearPreviousDate =()=>{
      setDates(null);
      setDateFilter(null);
      setStartDate("");
      setEndDate("");
  }
  const handleStoreNumber =(value , storeValues)=>{
    if(value && value?.option.label  === "ALL"){
      setStoreNum(storeValues)
    }else{
      setStoreNum(storeValues)
    }
    if(value?.option.label  === "ALL" && value.action !== "deselect-option" && status.value === "All"){
      getPreviousMonthDate();
    }else{
      clearPreviousDate();
    }
  }
  const handleStatusSelection = (statusSelected) => {
    setStatus(statusSelected);
    if(_isEmpty(storeNum) && statusSelected.value === "All"){
      getPreviousMonthDate();
    }else{
      clearPreviousDate();
    }
  }
 
  const handleExpandButton = () => {
    setExpandRows(!ExpandRows);
  }
  const handleDateRange = (e, picker) => {
    const { startDate, endDate } = picker;
    const _startDate = startDate.toISOString();
    const _endDate = endDate.toISOString();
    setDates({ start_date: _startDate, end_date: _endDate });
    setDateFilter(
      `${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`);
    setStartDate(startDate.format("YYYY-MM-DD"));
    setEndDate(endDate.format("YYYY-MM-DD"));
  };

  const clearDateFilter = () => {
    setDates(null);
    setStartDate("");
    setEndDate("");
    setDateFilter("");
  };

  const clearHeaderId =() =>{
    dispatch({
      payload: {
        headerId: "",
      },
      type: 'STORE_HEADERID',
    });
  } 

  const filterByStoreNumber = (storeArr, selectedArr) => {
		let res = [];
		if(!_isEmpty(storeArr) && !_isEmpty(selectedArr)){
		res = storeArr.filter(el => {
		   return selectedArr.find(element => {
			  return element == el.value;
		   });
		});
		return res;}
	 }

  const handlePageChange = (clickedPage , flag) => {
    if(flag !== false){
      setAPILoader(true) ;  
    }
    let storeArr =[];
    if(!_isEmpty(storeNum)&& storeNum[0]){
      storeNum.map((v)=> {if(v.value  !== "*"){storeArr.push(v.value)}});
    }else if(!_isEmpty(storeNum)&& storeNum.value){
      storeArr.push(storeNum.value)
    }

    let pageNum = clickedPage > totalpages ? "1" : clickedPage;
    let startTime = storeBoard && storeBoard.returnList.startTime ? storeBoard.returnList.startTime :"";
    let endTime  = storeBoard && storeBoard.returnList.endTime ? storeBoard.returnList.endTime :"";
    let customerId = storeBoard && storeBoard.returnList.customerId ? storeBoard.returnList.customerId :""; 
    let status = storeBoard && storeBoard.returnList.status ? storeBoard.returnList.status : "All" ; 
    let confNum = storeBoard && storeBoard.returnList.confNum ?  storeBoard.returnList.confNum  :"" ;
    let refNum = storeBoard && storeBoard.returnList.refNum ? storeBoard.returnList.refNum :"";
    let partNumber = storeBoard && storeBoard.returnList.partNumber ? storeBoard.returnList.partNumber :"";

    const payload = {
      startTime:startTime,
      endTime:endTime,
      page: pageNum ? pageNum : storeBoard.returnList.page ,
      pageSize:pageSize,
      customerId:customerId,
      status:status,
      confNum :confNum,
      refNum : refNum,
      partNumber :partNumber,
      storeNumber : !_isEmpty(storeNum) ? storeArr : [],
    } 
    dispatch(fetchReturn(payload)).then((action) => {
      if (!_isEmpty(action.payload)) {
        setCurrentPage(action.payload.page);
        setTotalPages(action.payload.totalPages);
        let oldArray = action.payload.partReturns;
        setRows(oldArray);
        if(returnsLoader === false){
          clearHeaderId();
          setAPILoader(false);
         }
        } else{
          if(returnsLoader === false){
            clearHeaderId();
            setAPILoader(false);
           }
           clearHeaderId()
      }
    })
  };

  const handleReset = () => {
    setCustomerName("");
    setCustomerNum("");
    setPartNum("");
    setStartDate("");
    setEndDate("");
    setREF("");
    setACX("");
    setStatus(option[0]);
    clearDateFilter();
    if(!_isEmpty(storeNumberLists) && !_isEmpty(storeNumberLists[1]))
    {setStoreNum([storeNumberLists[1]]);}
    initialFetchStoreData(storeNumberLists[1]?.value);
  }

  return (  
    ((!_isEmpty(userDetails) && userDetails.userType === OPERATIONS_MANAGER ) ||
     (!_isEmpty(userDetails.roles) && !_isEmpty(userDetails.roles[0]) && (userDetails.roles[0] === "AREA_MANAGER" || userDetails.roles[0] === "CC_MANAGER"))) && (
    <div className='store-dashboard-width-adjustment'>
      {!_isEmpty(userDetails) && !_isEmpty(userDetails.userType) && 
      (userDetails.userType === CC_MANAGER || userDetails.userType === AREA_MANAGER) 
     && (<Header
        storeURL={url}
        storeName={urlName}
        STORE_USER={STORE_USER}
        />)
      }
      <div className="store_searchBox">
        <Row>
          <Col >
            <Filter
             OPERATIONS_MANAGER={OPERATIONS_MANAGER}
              CC_MANAGER={CC_MANAGER}
              AREA_MANAGER={AREA_MANAGER}
              storeNumberList={storeNumberLists}
              storeNum={storeNum}
              setCustomerNum ={ setCustomerNum}
              handleStatusSelection={handleStatusSelection}
              handleStoreNumber={handleStoreNumber}
              setACX={setACX}
              setREF={setREF}
              setPartNum={setPartNum}
              handleDateRange={handleDateRange}
              clearDateFilter={clearDateFilter}
              handleOnApplyButn={handleOnApplyButn}
              dateFilter={dateFilter}
              handleReset={handleReset}
              customerNum={customerNum}
              partNum={partNum}
              status={status}
              ACX={ACX}
              REF={REF}
              ExpandRows={ExpandRows}
              handleExpandButton={handleExpandButton}
              rowData={rowData}
              userDetails={userDetails}
              STORE_USER={STORE_USER}
              setCustomerName={setCustomerName}
              customerName={customerName}
            />
          </Col>
        </Row>
      </div>
      <HomePage
        returnsLoading={APIloader}
        OPERATIONS_MANAGER={OPERATIONS_MANAGER}
        partLineItemRows={rowData}
        initialFetchStoreData={initialFetchStoreData}
        handlePageChange={handlePageChange}
        ExpandRows={ExpandRows}
        displayErrorMsg={displayErrorMsg}
        themeColor={themeColor}
        userType={userDetails && userDetails.userType}
        STORE_USER={STORE_USER}
        CC_MANAGER={CC_MANAGER}
        USERROLE ={  !_isEmpty(userDetails.roles) && !_isEmpty(userDetails.roles[0]) && userDetails.roles[0] }
        clearHeaderId={clearHeaderId}
        AREA_MANAGER={AREA_MANAGER}
      />
      <Col className="store_pagination_wrapper">
        {!_isEmpty(returnList)  && !APIloader  && (
          <PaginationComponent
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={totalpages}
          />)}
      </Col>

    </div>)
  )
}

export default Manager;
