import React, { PureComponent } from "react";
import T from "prop-types";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";

import "./styles.scss";
import { convertValue, currencyFormatter } from "helpers/utils";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="start">
        {value}
      </text>
    );
  }
}

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${name} ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const XCharts = (props) => {
  const {
    chartType,
    data,
    dataKeys,
    stackId,
    colors,
    pieActiveIndex,
    indexBy,
    gaugeLabel,
    isCurrency = false,
    customTooltip,
    axisBottom = "Months",
    trimXAxis,
    nivoBarPadding = 0.15,
  } = props;

  const renderLabel = (lProps) => {
    const { index, cx, cy, x, outerRadius, innerRadius, value } = lProps;

    const _x =
      index === 0
        ? x + (outerRadius + innerRadius) / 10
        : cx + 10 + (outerRadius + innerRadius) / 2;
    const _y = cy + 20;

    return (
      <text
        x={_x}
        y={_y}
        fill="black"
        textAnchor={index === 0 ? "start" : "end"}
      >
        {index === 0 ? "$0.00K" : convertValue(value)}
      </text>
    );
  };

  const formatLabelValuetoK = (value) => {
    return value > 99 ? `${Number(value / 1000).toFixed(1)}k` : value;
  };

  const stringEllipseFormat = (v) => {
    return trimXAxis && v.length > trimXAxis.labelLength
      ? v.slice(0, trimXAxis.labelLength).trim().concat("...")
      : v;
  };

  const renderChart = () => {
    switch (chartType) {
      case "line":
        return (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data}
              // width={800}
              height={400}
              margin={{
                top: 15,
                right: 30,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              {/* tooltip currency format */}
              <Tooltip
                formatter={(name) => {
                  return `${currencyFormatter(name)}`;
                }}
              />
              <Legend />
              {dataKeys &&
                dataKeys.map((d, i) => {
                  return (
                    <Line
                      key={i}
                      type="monotone"
                      dataKey={d.value}
                      stroke={d.color}
                      activeDot={{ r: 8 }}
                      strokeWidth={2}
                      label={<CustomizedLabel />}
                    />
                  );
                })}
            </LineChart>
          </ResponsiveContainer>
        );
      case "barChart":
        return (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} width={400}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              {customTooltip ? (
                <Tooltip content={customTooltip} />
              ) : (
                <Tooltip />
              )}
              <Legend align="left" />
              {dataKeys &&
                dataKeys.map((datakey, i) => {
                  return (
                    <Bar
                      stackId={stackId || null}
                      dataKey={datakey.value}
                      fill={datakey.color}
                      maxBarSize={50}
                      label={{ fill: "white" }}
                    />
                  );
                })}
            </BarChart>
          </ResponsiveContainer>
        );
      case "pieChart":
        return (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width="100%" height={300}>
              <Pie
                activeIndex={pieActiveIndex}
                activeShape={renderActiveShape}
                data={data}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#82ca9d"
                labelLine={false}
              />
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors && colors[index % colors.length]}
                />
              ))}
            </PieChart>
          </ResponsiveContainer>
        );
      case "nivoPieChart":
        return (
          <ResponsivePie
            data={data}
            sortByValue={false}
            margin={{ top: 15, right: 30, bottom: 20, left: -40 }}
            innerRadius={0.4}
            startAngle={-90}
            padAngle={0.7}
            cornerRadius={0}
            activeOuterRadiusOffset={8}
            colors={{ scheme: "nivo" }}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabel={(e) =>
              `${e.label} ${
                isCurrency ? `(${convertValue(e.value)})` : `(${e.value})`
              }`
            }
            arcLinkLabelsOffset={-8}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsDiagonalLength={18}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLinkLabelsStraightLength={9}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 3]],
            }}
            enableArcLabels={false}
            defs={[]}
            fill={[]}
            //tooltip currency format
            valueFormat={(value) => {
              return currencyFormatter(value);
            }}
            motionConfig="wobbly"
            legends={[
              {
                anchor: "right",
                direction: "column",
                justify: false,
                translateX: 50,
                translateY: 0,
                itemsSpacing: 5,
                itemWidth: 140,
                itemHeight: 20,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 15,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        );
      case "nivoBarChart":
        return (
          <ResponsiveBar
            data={data}
            keys={dataKeys}
            indexBy={indexBy}
            margin={{ top: 0, right: 10, bottom: 60, left: 60 }}
            padding={nivoBarPadding}
            groupMode="grouped"
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "category10" }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "#38bcb2",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "#eed312",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "fries",
                },
                id: "dots",
              },
              {
                match: {
                  id: "sandwich",
                },
                id: "lines",
              },
            ]}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: axisBottom,
              legendPosition: "middle",
              legendOffset: 40,
              format: (v) => stringEllipseFormat(v),
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Sales",
              legendPosition: "middle",
              legendOffset: -40,
              format: "~s",
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-left",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 60,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            //tooltip currency format
            valueFormat={(value) => {
              return currencyFormatter(value);
            }}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={(e) =>
              e.id + ": " + e.formattedValue + " in country: " + e.indexValue
            }
            label={(d) => formatLabelValuetoK(d.value)}
          />
        );
      case "pieGaugeChart":
        return (
          <ResponsiveContainer width="100%" height="100%" aspect={1}>
            <PieChart width="100%" height="100%">
              <Pie
                startAngle={180}
                endAngle={0}
                innerRadius="55%"
                data={data}
                dataKey="value"
                labelLine={false}
                blendStroke
                isAnimationActive={true}
                label={renderLabel}
              >
                {gaugeLabel && <Label value={gaugeLabel} position="center" />}
                <Cell fill="#1f77b4" />
                <Cell fill="#eaeaea" />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        );
      case "nivoRadialChart":
        return (
          <ResponsiveRadialBar
            data={data}
            valueFormat=" ^($.5s"
            colors={{ scheme: "accent" }}
            padding={0}
            startAngle={-90}
            endAngle={90}
            cornerRadius={2}
            enableRadialGrid={false}
            enableCircularGrid={false}
            margin={{ top: 0, right: 0, bottom: 60, left: 10 }}
            radialAxisStart={{ tickSize: 10, tickPadding: 8, tickRotation: 52 }}
            circularAxisOuter={null}
            enableLabels={true}
            legends={[]}
          />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div className="xlp-charts">{renderChart()}</div>
    </React.Fragment>
  );
};

XCharts.propTypes = {
  chartType: T.oneOf([
    "line",
    "barChart",
    "pieGaugeChart",
    "nivoBarChart",
    "nivoPieChart",
    "pieChart",
  ]),
  data: T.array,
};

export default XCharts;
