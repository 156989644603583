/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { search } from "../../Homepage/Search/globalSearch.thunk";
import { getPartsByMultiGroupOrchestrator } from "../../../actions/parts";
import { getDefaultDataBasedOnUSerPref, getPreferenceDataFromStorage, isLengthLessthan } from '../../../helpers/utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import XModal from '../XModal';
import SpecificConditionsReselect from '../../Homepage/SpecificConditionsReselect';
import _cloneDeep from 'lodash/cloneDeep';
function SpecificconditionIcon(props) {
    const dispatch = useDispatch();
    const parts = useSelector(state => state.parts);
    const [showSCReselect, setShowSCReselect] = useState(false);
    const [requestItems, setRequestItems] = useState(null);
    const userDetails = useSelector((state) => state.user.userDetails);
    const mygarage = useSelector((state) => state.mygarage);
    const sv = mygarage.selectedVehicle || search.selectedVehicle;


    const handleSCReselect = () => {
        setShowSCReselect(!showSCReselect);
    };

    const handleRequestItems = (items) => {
        setRequestItems(items);
    }

    const handleSubmit = () => {
        console.log('spc submit ==>', requestItems);
        const { year, makeId, modelId, engineId } = sv;
        const userPreferenceDetail = getPreferenceDataFromStorage();
        let userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);

        const catalogRequestGroups = _cloneDeep (parts.catalogRequestGroups);
        setShowSCReselect(false);

        setTimeout(() => {
            dispatch({
                payload: {
                    specificConditions: requestItems,
                    concat: false
                },
                type: "SPECIFIC_CONDITIONS",
            });
        }, 100);

        dispatch(
            getPartsByMultiGroupOrchestrator(
                year,
                makeId,
                modelId,
                engineId,
                { requestItems: requestItems },
                userPrefDetail.pageNumber,
                userPrefDetail.pageSize,
                userPrefDetail.catalogSortBy,
                userPrefDetail.catalogOrder,
                catalogRequestGroups.filter ? catalogRequestGroups.filter : {},
                true,
                userDetails.billTo.mclSupplier,
                parts.selectedGroupDetails?parts.selectedGroupDetails: parts.selectedPartType,
                true,
                sv  
        
            )
        );
    }

    return (
        <div className="specific-condition-icon">
            { parts.specificConditions && parts.specificConditions.length > 0 &&
                <OverlayTrigger
                    placement="auto"
                    delay={{ show: 100, hide: 400 }}
                    overlay={
                        <Tooltip id="toggle-view-part-compact">
                            Change specific conditions
                    </Tooltip>
                    }
                >
                    <span class="material-icons icon" onClick={handleSCReselect}>
                        published_with_changes
                </span>
                </OverlayTrigger>
            }

            <XModal
                show={showSCReselect}
                handleClose={handleSCReselect}
                className="specific__condition_modal"
                title="Change Specific Conditions"
                submitType
                favoriteTextValidity={requestItems && requestItems.length > 0 ? true : false}
                handleAction={handleSubmit}
            >
                <SpecificConditionsReselect handleRequestItems={handleRequestItems} />
            </XModal>
        </div>
    );
}
export default SpecificconditionIcon;
