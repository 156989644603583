import React, { useState, useEffect, Fragment, Component } from "react";
import { InputGroup, Form, Tooltip } from "react-bootstrap";
import XSelect from "../../../components/shared/XSelect";
import XSelectDropdown from "../../../components/shared/XSelect/styledXSelect";
import _isEmpty from "lodash/isEmpty";
import { remove } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import _find from "lodash/find";

const MultiSelectPreference = ({
  userPreferenceChangedData,
  prefDetail,
  handlePreferenceMultiSelectChange,
  allText,
}) => {
  //const storeData = useSelector((state) => state.mygarage.storeDetails);
  const user = useSelector((state) => state.user);
  const storeData = useSelector((state) => state.mygarage.storeList);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const supplier = (user && user.userDetails.billTo.mclSupplier) || "";

  const getDropDownList = (data) => {
    let options = [];
    if (
      data &&
      data.possibleMultiAnswer &&
      data.possibleMultiAnswer.length > 0
    ) {
      data.possibleMultiAnswer.map((item) => {
        const eachOption = {
          id: item.code,
          value: item.code,
          label: item.text,
        };
        options.push(eachOption);
      });
    }
    return options;
  };

  const StoreOptions = (data) => {
    let options = [];
    storeData?.map((store) => {
      const eachOption = {
        id: store.storeName.trim(),
        value: store.storeNum,
        label: store.storeNum + " - " + store.storeName,
      };
      options.push(eachOption);
    });
    return options;
  };

  const getValue = (value, event) => {
    let tempArr = [];
    if (!_isEmpty(value)) {
      if (event.action !== "remove-value" && event.name.code === "Price") {
        if (
          value.some((p) => p.value !== "All") &&
          event.option.value === "All"
        ) {
          value.length = 0;
          value.push({ id: "All", value: "All", label: "All" });
        }
        if (value.length > 1 && value.some((p) => p.value === "All")) {
          remove(value, (p) => p.value === "All");
        }
        if (value.length === event.name.possibleMultiAnswer?.length - 1) {
          value.length = 0;
          value.push({ id: "All", value: "All", label: "All" });
        }
      }
      value.map((val) => {
        tempArr.push(val);
      });
    }
    setSelectedOptions(tempArr);
  };

  const getDefaultStores = (data) => {
    if (prefDetail.code !== "Price") {
      let defaultOptions = StoreOptions(data);
      if (data && data.multiAnswer && data.multiAnswer.length > 0) {
        let multiAnswer = [];
        data.multiAnswer.map((singleMultiAnswer) => {
          let multiOptionValue = {
            value: singleMultiAnswer.code || singleMultiAnswer,
            label: _find(defaultOptions, {
              value: singleMultiAnswer.code || singleMultiAnswer,
            })?.label,
          };
          multiAnswer.push(multiOptionValue);
        });
        return multiAnswer;
      }
    }
  };

  const getInitialValue = (data) => {
    let singleAnswer = [];
    if (data && data.multiAnswer && data.multiAnswer.length > 0) {
      let multiAnswer = [];
      data.multiAnswer.map((singleMultiAnswer) => {
        let multiOptionValue = {
          value: singleMultiAnswer.code || singleMultiAnswer,
          label: singleMultiAnswer.text || singleMultiAnswer,
        };
        multiAnswer.push(multiOptionValue);
      });
      return multiAnswer;
    } else if (
      data &&
      data.possibleMultiAnswer &&
      data.possibleMultiAnswer.length > 0
    ) {
      data.possibleMultiAnswer.map((item) => {
        if (item.default) {
          singleAnswer.push({
            value: item.code,
            label: item.text,
          });
        }
      });
      return singleAnswer;
    }
  };
  const [updatePreference, setupdatePreference] = useState(
    getInitialValue(prefDetail)
  );
  // const [defaultLocation, setdefaultLocation] = useState(
  //     getDefaultStores(prefDetail)
  // );

  return (
    <Fragment>
      {prefDetail.code === "Price" ? (
        <Fragment>
          <Form.Label>
            {userPreferenceChangedData &&
              userPreferenceChangedData.length > 0 &&
              prefDetail.text}
          </Form.Label>
          <br />
          <XSelect
            options={getDropDownList(prefDetail)}
            onChange={(value, e) => {
              getValue(value, e);
              handlePreferenceMultiSelectChange(value, e);
              setupdatePreference([]);
            }}
            isMulti={true}
            value={
              (!_isEmpty(selectedOptions) && selectedOptions) ||
              (!_isEmpty(updatePreference) && updatePreference) ||
              allText
            }
            name={prefDetail}
            placeholder="Select the pages"
            className="multi-dropdown"
          />
        </Fragment>
      ) : (
        ""
      )}
      {prefDetail.code === "Locations" ? (
        <div>
          <span style={{ color: "#aaa", fontSize: "17px" }}>
            Please select one or more locations for which you want to
            impersonate the customer accounts
          </span>
          <XSelect
            options={StoreOptions(prefDetail)}
            onChange={(value, e) => {
              getValue(value, e);
              handlePreferenceMultiSelectChange(value, e);
              //setdefaultLocation([]);
            }}
            isMulti
            closeMenuOnSelect={false}
            value={
              (!_isEmpty(selectedOptions) && selectedOptions) ||
              getDefaultStores(prefDetail)
            }
            name={prefDetail}
            placeholder="Select Preferred locations"
            className="multi-dropdown-store"
          />
        </div>
      ) : (
        ""
      )}
      {prefDetail.code === "Parts Catalog" &&
      prefDetail.text.includes(supplier) ? (
        <React.Fragment>
          <Form.Label>
            {userPreferenceChangedData &&
              userPreferenceChangedData.length > 0 &&
              prefDetail.text.replace(`-${supplier}`, "")}
          </Form.Label>
          <br />
          <XSelectDropdown
            options={getDropDownList(prefDetail)}
            value={
              (!_isEmpty(selectedOptions) && selectedOptions) ||
              (!_isEmpty(updatePreference) && updatePreference) ||
              allText
            }
            handleDropdownChange={(value, e) => {
              setSelectedOptions(value);
              handlePreferenceMultiSelectChange(value, e);
              setupdatePreference([]);
            }}
            name={prefDetail}
            className="pref-brand-dropdown"
            classNameDropDown="pref-dropdown-btn"
          />
        </React.Fragment>
      ) : (
        ""
      )}
      {prefDetail.code === "Chemicals Catalog" ? (
        <Fragment>
          <Form.Label>
            {userPreferenceChangedData &&
              userPreferenceChangedData.length > 0 &&
              prefDetail.text}
          </Form.Label>
          <br />
          <XSelectDropdown
            options={getDropDownList(prefDetail)}
            value={
              (!_isEmpty(selectedOptions) && selectedOptions) ||
              (!_isEmpty(updatePreference) && updatePreference) ||
              allText
            }
            handleDropdownChange={(value, e) => {
              setSelectedOptions(value);
              handlePreferenceMultiSelectChange(value, e);
              setupdatePreference([]);
            }}
            name={prefDetail}
            className="pref-brand-dropdown"
            classNameDropDown="pref-dropdown-btn"
          />
        </Fragment>
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default MultiSelectPreference;
