import React, { useEffect, useState,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _, { set } from "lodash";
import storage from "../../helpers/storage";
import { getStockProductList } from "./storeDashboard.thunks";
import { getInvoiceStoreDetails } from "./storeDashboard.thunks";
import { getShipInfo, hasWhiteSpace } from "../../helpers/utils";
import _cloneDeep from "lodash/cloneDeep";
import Escalate from './popUp/Escalate';
import moment from 'moment';
import WarrantyDatePicker from './popUp/WarrantyDatePicker';
import InvoiceDetails from './popUp/InvoiceDetails';
import Placeholder from '../shared/Placeholder';
import { Row, Col, OverlayTrigger, Tooltip, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import searchIcon from "../../static/images/searchIcon.png";
import EscalateIcon from "../../static/images/escalateIcon.png";
import escalateDisabledIcon from "../../static/images/escalateDisabledIcon.png";
import deleteIcon from '../../static/images/deleteIcon.png';
import { handleKeyDown, formatAmount, handleKeyDownReturns, handleKeyDownAlphabet,handleKeyDownReturnsLineItems } from "../../helpers/utils";
import { ContactlessOutlined } from '@mui/icons-material';
import { current } from '@reduxjs/toolkit';

const LineItems = (props) => {
    let {
        options, STORE_INITIATED, rowdata,
        OPERATIONS_MANAGER,
        LineItemRowsData,
        headerLineItems,
        setRowsdata,
        ExpandRows, USERROLE,
        APPROVED, DECLINED,
        DRIVER_INITIATED,
        DRIVER_DELIVERED, ESCALATED,
        MANUALLY_PROCESSED,
        prepareInvoiceOptions,
        prepareMFGCodeDropDown,
        isSaveAndPostEnabled,
        handlePageChange,
        checkPartNumberIsValid,
        removedSupAndAltPart, clearHeaderId,
        displayErrorMsg, themeColor, STORE_USER,
        checkIsValidAvailableQty, AREA_MANAGER,
        userType, CUSTOMER_INITIATED, CC_MANAGER,
        INVOICE_ERROR_MSG,
        SetLineItemEditedData,
        STORE_REVIEWED
    } = props;

    const dispatch = useDispatch();
    const ref = useRef();
    const userDetails = useSelector((state) => state.user.userDetails);
    const storeUserType = !_isEmpty(userDetails) && userDetails.userType;
    const defualtManagerStore = !_isEmpty(userDetails) && userDetails.storeNumber;
    const storeBoard = useSelector((state) => state.storeDashBoard);
    const parts = useSelector((state) => state.parts);
    const shipInfo = getShipInfo(userDetails, parts);
    let lineItemData = _cloneDeep(parts.lineItemData);
    const [LineItemsRows, setLineItemRows] = useState([...LineItemRowsData]);
    const [escalateModal, setEscalateModal] = useState(false);
    const [escalateLineId, setEscalateLineId] = useState('');
    const[updatedPart,setUpdatedInvoices]=useState({});
    const[enablePropShare,SetEnablePropShare]=useState(false);
    const[warrantyUpdatedData,SetWarrantyUpdatedData]=useState([]);
    const[validatedId,SetValidatedId]=useState("");
    const [isStorePermission, setIsStorePermission] = useState(false);
    const {selectedStore} = useSelector((state) => state.storeDashBoard);
    let InvoiceSelectedUnlinked = storage.get("IsUnlinked", "local");

    const storeAccess = () => {
        if (!isStorePermission) {
          return storeUserType === STORE_USER || storeUserType === AREA_MANAGER
        } else {
          return storeUserType === STORE_USER || storeUserType === CC_MANAGER || storeUserType === AREA_MANAGER
    
        }
      }
      useEffect(() => {
        let arr =[];
        if (!_isEmpty(selectedStore)) {
          if(defualtManagerStore === headerLineItems.location){
            setIsStorePermission(true)
          }
        }
      }, [selectedStore])


    useEffect(() => {
        if (!_isEmpty(LineItemRowsData)) {
            const lRows = _cloneDeep(LineItemRowsData);

            setLineItemRows(lRows);
        }
    }, [rowdata, LineItemRowsData])

    useEffect(()=>{
        const lRows = _cloneDeep(LineItemRowsData);
        let res=lRows.map((item,i)=>{
            return {index:i,...item}
          })

          SetWarrantyUpdatedData(res);
          SetLineItemEditedData(res);
    },[rowdata])

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         console.log('event',event.target);
    //         console.log('ref?.current?',ref?.current);
    //       if (!ref?.current?.contains(event.target)) {
    //         SetEnableInvoicesPopUp(false);
    //       }
    //     };
    //     document.addEventListener("mousedown", handleClickOutside);
    //   }, [ref]);
    const handleMFGCodeSelection = (event, line, hRows) => {
        line.checkAvailToCredit = false;
        line.selectedInvoiceNumber = "";
        line.isInvoiceSelected = "";
        line.lineItemLoader = true;
        line.pickupMfgCode = event.mfgCode;
        line.driverMfgCode = event.mfgCode;
        line.pickupPartNumber = event.partNum;
        line.partNumb = event.partNum;
        line.isPLDropDownSelected = true;
        handleInvoiceAPICall(event, event.responseData, line, hRows, event.flag);
        setLineItemRows([...LineItemsRows]);
    }

    const handleMFGCode = (value, index, row) => {
        row.storeMfgCode = value.toUpperCase();
        setLineItemRows([...LineItemsRows]);
    }

    const handlePartNumber = (line, value) => {
        if (!_isEmpty(value) && value.length > 0) {
            line.lineSpaceError = hasWhiteSpace(value);
            line.mfgCodeDropDown = [];
        }
        line.storePartNumber = value.toUpperCase();
        line.checkPartNum = value;
        line.checkAvailToCredit = false;
        line.isValidPartNumberAV = false
        line.partNotFound = false;
        line.partNumberError = false;
        storage.set("IsUnlinked",false,"local");
        setLineItemRows([...LineItemsRows]);
    }

    const handleReturnTypeSelection = (event, line) => {
        storage.set("IsUnlinked",false,"local");
        line.checkAvailToCredit = false;
        let value = event.value;
        line.returnType = value;
        line.inVoiceOptions = prepareInvoiceOptions(line.invoices, value);
        line.purchaseDate="";
        if (!_isEmpty(line.inVoiceOptions)) {
            let data = line.inVoiceOptions.filter((v) => line.selectedInvoiceNumber === v.value);
            line.invoiceAvlQty = Number(data[0]?.availToCredit);
            line.checkAvailToCredit = checkIsValidAvailableQty(line) === false ? false : true;
        }
        let updatedDetails = warrantyUpdatedData && warrantyUpdatedData.map((item)=>{
            if (item.rowId === line.rowId) {
              return {...item,label:(_isEmpty(line.inVoiceOptions)?"Unlinked":"Select Invoice"),returnType:value}
            }
            else{
              return {...item}
            }

          });
          SetWarrantyUpdatedData(updatedDetails);
          SetLineItemEditedData(updatedDetails);
        setLineItemRows([...LineItemsRows]);
        dispatch({
            payload: {
              updatedInvoicesWarrantyDates: {},
            },
            type: "UPDATE_INVOICES_WARRANTY_DATES",
          });
        dispatch({
            payload: {
                selectedCoreQtyAvailToCredit:0,
                selectedQtyAvailToCredit:0,
                selectedInvoices:[],

            },
            type: 'SELECTED_QTY_AVAIL_TO_CREDIT',
        });
        setTimeout(() => {
            handleInvoiceOptions(event, line, false, "warranty");
          }, [300]);
    }

    const handleReturnReason = (event, line) => {
        line.reason = event.target.value;
        setLineItemRows([...LineItemsRows]);
    }
    const HandleTotalQtyAvailability=(line)=>{
        let coreQtyAvailToCredit=0;
        let qtyAvailToCredit=0;
        line.inVoiceOptions && line.inVoiceOptions.map((item)=>{
            if(line.returnType === "Core"){
                if(item.coreQtyAvailToCredit){
                    coreQtyAvailToCredit=coreQtyAvailToCredit+item.coreQtyAvailToCredit;
                }
            }
            else{
                if(item.qtyAvailToCredit){
                qtyAvailToCredit=qtyAvailToCredit+item.qtyAvailToCredit;
                }
            }
        })
        dispatch({
            payload: {
                coreQtyAvailToCredit: coreQtyAvailToCredit,
                qtyAvailToCredit:qtyAvailToCredit,
            },
            type: 'TOTAL_QTY_AVAIL_TO_CREDIT',
        });
        storage.set("coreQty",coreQtyAvailToCredit , "local");
        storage.set("Qty",qtyAvailToCredit , "local");
        return { totalCoreQtyAvailToCredit: coreQtyAvailToCredit,totalQtyAvailToCredit:qtyAvailToCredit,}

    }
    const handleInvoiceOptions = async(event,line,isOpen,UpdatedReturnType,) => {
        // line.InvoiceDetailsPopUp=""
        // SetEnableInvoicesPopUp(false)

        line.InvoiceDetailsPopUp = line.rowId;

        if (event.value !== undefined) {
            line.selectedInvoiceNumber = event.value;
        }
        line.lineSpaceError = hasWhiteSpace(line.partNumb);
        line.checkAvailToCredit = checkIsValidAvailableQty(line) !== false ? true : false;

        let coreQtyAvailToCredit=line.storeQty;
        let qtyAvailToCredit=line.storeQty;
        let selectedCoreQtyAvailToCredit=0;
        let selectedQtyAvailToCredit=0;
        let selectedData=[];
        const res = HandleTotalQtyAvailability(line);
        let totalCoreQtyAvailToCredit = storage.get("coreQty", "local");
        let totalQtyAvailToCredit = storage.get("Qty", "local");
        var updatedPart=line.inVoiceOptions && line.inVoiceOptions.map((invoice,i)=>{
            if(line.returnType !=="Warranty"){
                    if( (line.returnType === "New" && line.storeQty > totalQtyAvailToCredit)   || (line.returnType === "Core" && line.storeQty > totalCoreQtyAvailToCredit)){
                        if(invoice.label === "Unlinked"){
                            storage.set("IsUnlinked",true,"local");
                            selectedData.push(invoice);
                            return {...invoice,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:true}
                        }
                        // storage.set("IsUnlinked",false,"local");

                        return {...invoice,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false}

                    }else{


                    if(invoice.label === "Unlinked"){

                        if(invoice.hasOwnProperty('isCheckedUnlinked')){
                            storage.set("IsUnlinked",invoice.isCheckedUnlinked,"local");
                            return {...invoice,isCheckedUnlinked:invoice.isCheckedUnlinked,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false}

                        }
                        storage.set("IsUnlinked",false,"local");

                        return {...invoice,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false}
                    }
                    if(line.returnType === "Core"){

                        if(invoice.hasOwnProperty('isCheckedCoreQtyAvailToCredit')){
                            if(invoice.isCheckedCoreQtyAvailToCredit){
                                selectedCoreQtyAvailToCredit=selectedCoreQtyAvailToCredit+invoice.coreQtyAvailToCredit;
                            }
                            selectedData.push(invoice);
                            return {...invoice,isCheckedCoreQtyAvailToCredit:invoice.isCheckedCoreQtyAvailToCredit,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false}
                        } else if(invoice.coreQtyAvailToCredit !==0 &&(invoice.coreQtyAvailToCredit  <=coreQtyAvailToCredit || selectedCoreQtyAvailToCredit< line.storeQty)){
                            coreQtyAvailToCredit=coreQtyAvailToCredit-invoice.coreQtyAvailToCredit;
                            selectedCoreQtyAvailToCredit=selectedCoreQtyAvailToCredit+invoice.coreQtyAvailToCredit;
                            selectedData.push(invoice);
                                return {...invoice,isCheckedCoreQtyAvailToCredit:true,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false}
                        }
                        else{

                            return {...invoice,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false}
                        }
                    }
                    else{
                        if(invoice.hasOwnProperty('isCheckedQtyAvailToCredit')){

                            if(invoice.isCheckedQtyAvailToCredit){
                                selectedQtyAvailToCredit=selectedQtyAvailToCredit+invoice.qtyAvailToCredit;

                            }
                            selectedData.push(invoice);
                            return {...invoice,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:invoice.isCheckedQtyAvailToCredit,isCheckedUnlinked:false}
                        } else if(invoice.qtyAvailToCredit !==0 && (invoice.qtyAvailToCredit <=qtyAvailToCredit || selectedQtyAvailToCredit<line.storeQty)){
                            qtyAvailToCredit=qtyAvailToCredit-invoice.qtyAvailToCredit
                            selectedQtyAvailToCredit=selectedQtyAvailToCredit+invoice.qtyAvailToCredit;
                            selectedData.push(invoice);
                            return {...invoice,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:invoice.isCheckedQtyAvailToCredit?invoice.isCheckedQtyAvailToCredit:true,isCheckedUnlinked:false}
                        }
                        else{

                            return {...invoice,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false}
                        }
                    }
            }
            }else{
                if(invoice.hasOwnProperty('enableInvoiceRadio')){
                    return {...invoice,enableInvoiceRadio:invoice.enableInvoiceRadio,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false}
                 }else if( (!_isEmpty(parts.updatedInvoicesWarrantyDates)) && parts.updatedInvoicesWarrantyDates.invoiceNumber === invoice.invoiceNumber){
                    return {...invoice,enableInvoiceRadio:true,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false}
                 }
                 else{
                    return {...invoice,enableInvoiceRadio:false,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false}
                 }
            }
        })
        // if(enableInvoicesPopUp){
        //     line.InvoiceDetailsPopUp=""
        // }

        if(isOpen){
            line.enableInvoicesPopUp= !(line.enableInvoicesPopUp)
            // SetEnableInvoicesPopUp(!enableInvoicesPopUp)
        }else{
            line.InvoiceDetailsPopUp=""

        }
        let label="";

        if(line.returnType ==="New" || line.returnType ==="Core" ){
            const isSelected = updatedPart.map((invoice)=>{
                if(invoice.isCheckedUnlinked ||invoice.enableInvoiceRadio){
                    line.isUnlinkedSelected=true;
                    line.isOtherReturnTypeSelected=false;
                    label="Unlinked";
                    return true
                }
                if(invoice.isCheckedCoreQtyAvailToCredit || invoice.isCheckedQtyAvailToCredit ){
                    line.isOtherReturnTypeSelected=true;
                    line.isUnlinkedSelected=false;
                    label="Invoice Selected";

                    return true

                }
                return false

             })
            let isAllUnchecked = isSelected.every(v => v === false);
            if(isAllUnchecked){
                line.isOtherReturnTypeSelected=false;
                line.isUnlinkedSelected=false;
                label="";

            }
        }
        if (line.returnType === "Warranty") {
          updatedPart.map((invoice) => {
           if (invoice.label !== "Unlinked" && invoice.enableInvoiceRadio) {
             line.isOtherReturnTypeSelected = true;
             line.isUnlinkedSelected = false;
             label = "Invoice Selected";
             return true;
           }
           if (invoice.label === "Unlinked" && invoice.enableInvoiceRadio) {
             label = "Unlinked";
             line.isUnlinkedSelected = true;
             return true;
           }
           return false;
         });
         const allInvoiceDisabled = !_isEmpty(updatedPart) && updatedPart.every(item => !item.enableInvoiceRadio);
   
         if (allInvoiceDisabled) {
           line.isInvoiceSelected = "Select Invoice";
         }
       }
  
        let updatedDetails = warrantyUpdatedData && warrantyUpdatedData.map((item)=>{
            if (item.rowId === line.rowId) {
                if( line.returnType !=="Warranty") {
                    return {...item,updatedInvoices:updatedPart,label:label,selectedCoreQtyAvailToCredit: selectedCoreQtyAvailToCredit,selectedQtyAvailToCredit:selectedQtyAvailToCredit,totalCoreQtyAvailToCredit:res.totalCoreQtyAvailToCredit,totalQtyAvailToCredit:res.totalQtyAvailToCredit,...line}
                }
                if( line.returnType ==="Warranty") {
                  //label = item.isInvoiceSelected === "" || item.label === "Select Invoice" ? "Select Invoice": item.isInvoiceSelected;
                      if (UpdatedReturnType === "warranty") {
                       // label = "Select Invoice";
                    }
                    return { ...item, ...line, label: label };

                }
            }
            else{
              return {...item}
            }

          });
            SetWarrantyUpdatedData(updatedDetails);
            SetLineItemEditedData(updatedDetails)
        // if(line.returnType ==="Warranty"){
        //     const isSelected = updatedPart.map((invoice)=>{
        //     if(invoice.label === "Unlinked" && invoice.enableInvoiceRadio ){
        //         line.isUnlinkedSelected=true;
        //       }
        //       if(invoice.label !== "Unlinked" && invoice.enableInvoiceRadio ){
        //         line.isOtherReturnTypeSelected=true;

        //       }
        //     })
        // }
//    const updatedLineItemRows=LineItemsRows.map((lineItem)=>{
//             if(lineItem.rowId === line.rowId){
//                 return {...line,inVoiceOptions:updatedPartInvoices}
//             }
//             return {...lineItem}
//         })

        setUpdatedInvoices({...line,inVoiceOptions:updatedPart})
        SetEnablePropShare(true);
        dispatch({
            payload: {
                selectedCoreQtyAvailToCredit: selectedCoreQtyAvailToCredit,
                selectedQtyAvailToCredit:selectedQtyAvailToCredit,
                selectedInvoices:updatedPart,
                updatedInvoices:{...line,inVoiceOptions:updatedPart},

            },
            type: 'SELECTED_QTY_AVAIL_TO_CREDIT',
        });
        handleInvoice(null,selectedData,line)
        setLineItemRows([...LineItemsRows]);
    }

    const handleCloseDropdown = (e, line) => {
        line.InvoiceDetailsPopUp = "";
        // SetEnableInvoicesPopUp(false);
        line.enableInvoicesPopUp = false;

        setLineItemRows([...LineItemsRows]);
    }
    const handleInvoiceForStore =(e,option,line)=>{
        let coreQtyAvailToCredit=parts.selectedCoreQtyAvailToCredit;
        let qtyAvailToCredit=parts.selectedQtyAvailToCredit;
        let selectedData=[...parts.selectedInvoices];
        line.InvoiceDetailsPopUp = line.rowId;
        line.checkAvailToCredit = checkIsValidAvailableQty(line) !== false ? true : false;
        line.isCheckedCoreQtyAvailToCredit=false;
        line.isCheckedQtyAvailToCredit = false;
        const updatedPartInvoices=line && line.inVoiceOptions.map((invoice)=>{
                line.isInvoiceSelected = invoice.label;
                line.selectedInvoiceNumber = invoice.value;
                let indexOfSelectedInvoice=parts.selectedInvoices && parts.selectedInvoices.indexOf(invoice);
                    if(invoice.invoiceNumber === option.invoiceNumber ||invoice.label === option.label){
                        if(invoice.label === "Unlinked"){
                            if(LineItemsRows.returnType !== "Warranty"){
                                if(e.target.checked){
                                    storage.set("IsUnlinked",e.target.checked , "local");
                                    return {...invoice,isCheckedUnlinked:e.target.checked,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false}
                                }
                                else{
                                    storage.set("IsUnlinked",false , "local");

                                    return {...invoice,isCheckedUnlinked:false,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false}

                                }
                            }else{
                                storage.set("IsUnlinked",false , "local");

                                    return {...invoice,isCheckedUnlinked:false,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false,enableInvoiceRadio:true}

                            }

                            return {...invoice,isCheckedUnlinked:false,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false}

                        }
                        else{
                                if(line.returnType === "Core" && invoice.invoiceNumber === option.invoiceNumber){

                                    if(e.target.checked){
                                        coreQtyAvailToCredit=coreQtyAvailToCredit+option.coreQtyAvailToCredit
                                        if(!indexOfSelectedInvoice);
                                            selectedData.push(invoice);
                                    }
                                    else{
                                        let index = selectedData.findIndex(x => x.invoiceNumber ===invoice.invoiceNumber);
                                        if (index > -1) { // only splice array when item is found
                                            selectedData.splice(index, 1); // 2nd parameter means remove one item only
                                        }
                                        coreQtyAvailToCredit=coreQtyAvailToCredit-option.coreQtyAvailToCredit
                                    }
                                    return {...invoice,isCheckedCoreQtyAvailToCredit:e.target.checked,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false}
                                }
                                else if(line.returnType === "New" && invoice.invoiceNumber === option.invoiceNumber){
                                    storage.set("IsUnlinked",false , "local");
                                    if(e.target.checked){

                                        qtyAvailToCredit=qtyAvailToCredit+option.qtyAvailToCredit
                                        if(!indexOfSelectedInvoice);
                                            selectedData.push(invoice);

                                    }
                                    else{
                                        let index = selectedData.findIndex(x => x.invoiceNumber ===invoice.invoiceNumber);
                                        if (index > -1) { // only splice array when item is found
                                            selectedData.splice(index, 1); // 2nd parameter means remove one item only
                                        }
                                        qtyAvailToCredit=qtyAvailToCredit-option.qtyAvailToCredit

                                    }
                                return {...invoice,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:e.target.checked,isCheckedUnlinked:false}

                                }
                                else if(line.returnType === "Warranty" && invoice.invoiceNumber === option.invoiceNumber){
                                    return {...invoice,isCheckedCoreQtyAvailToCredit:false,isCheckedQtyAvailToCredit:false,isCheckedUnlinked:false,enableInvoiceRadio:true}

                                }
                         }

                         return {...invoice,enableInvoiceRadio:false}

                    }
                    else{

                        return {...invoice,enableInvoiceRadio:false}
                    }

        })
        let label = "";
        if(line.returnType ==="New" || line.returnType ==="Core"){
            const isSelected = updatedPartInvoices.map((invoice)=>{
            if(invoice.isCheckedUnlinked ||invoice.enableInvoiceRadio){
                line.isUnlinkedSelected=true;
                line.isOtherReturnTypeSelected=false;
                label="Unlinked"
                return true
            }
            if(invoice.isCheckedCoreQtyAvailToCredit || invoice.isCheckedQtyAvailToCredit ){
                line.isOtherReturnTypeSelected=true;
                line.isUnlinkedSelected=false;
                label="Invoice Selected"
                return true

            }
            return false

        })
        let isAllUnchecked = isSelected.every(v => v === false);
        if(isAllUnchecked){
            line.isOtherReturnTypeSelected=false;
            line.isUnlinkedSelected=false;
            label=""
        }
        }
        if(line.returnType ==="Warranty"){
            const isSelected = updatedPartInvoices.map((invoice)=>{
            if(invoice.label === "Unlinked" && invoice.enableInvoiceRadio ){
                line.isUnlinkedSelected=true;
              }
              if(invoice.label !== "Unlinked" && invoice.enableInvoiceRadio ){
                line.isOtherReturnTypeSelected=true;
              }
            })
        }
        let updatedDetails = warrantyUpdatedData && warrantyUpdatedData.map((item)=>{
            if (item.rowId === line.rowId) {
              return {...item,label:label,updatedInvoices:updatedPartInvoices,selectedCoreQtyAvailToCredit:coreQtyAvailToCredit,selectedQtyAvailToCredit:qtyAvailToCredit,...line}
            }
            else{
              return {...item}
            }

          });
          SetWarrantyUpdatedData(updatedDetails);
          SetLineItemEditedData(updatedDetails)
        const updatedLineItemRows=LineItemsRows.map((lineItem)=>{
            if(lineItem.rowId === line.rowId){
                return {...line,inVoiceOptions:updatedPartInvoices}
            }
            return {...lineItem}
        })

        setLineItemRows(updatedLineItemRows);
        setUpdatedInvoices({...line,inVoiceOptions:updatedPartInvoices})
        SetEnablePropShare(true);
        dispatch({
            payload: {
                selectedCoreQtyAvailToCredit:coreQtyAvailToCredit,
                selectedQtyAvailToCredit:qtyAvailToCredit,
                selectedInvoices:updatedPartInvoices,

            },
            type: 'SELECTED_QTY_AVAIL_TO_CREDIT',
        });


    }

    const handleInvoice = (e,option, line) => {

        if(Array.isArray(option)){
            option && option.map((item)=>{
                if (item.label === "Unlinked" || ((line.storeQty !== "" && Number(line.storeQty) !== 0) && ((line.returnType === "Core" && item.coreQtyAvailToCredit > 0 && line.storeQty <= item.coreQtyAvailToCredit) || (line.returnType !== "Core" && item.qtyAvailToCredit > 0 && line.storeQty <= item.qtyAvailToCredit)))) {
                    line.isInvoiceSelected = item.label;
                    line.selectedInvoiceNumber = item.value;
                    // line.InvoiceDetailsPopUp = line.rowId;
                    if (line.selectedInvoiceNumber === "") {
                        line.purchaseDate = "";
                    } else if (!_isEmpty(item.invoiceDate)) {
                        line.purchaseDate = moment(item.invoiceDate).format('MM/DD/YYYY');
                    }
                    line.checkAvailToCredit = checkIsValidAvailableQty(line) !== false ? true : false;
                 }
            })
        }
        else{
            if (option.label === "Unlinked" || ((line.storeQty !== "" && Number(line.storeQty) !== 0) && ((line.returnType === "Core" && option.coreQtyAvailToCredit > 0 && line.storeQty <= option.coreQtyAvailToCredit) || (line.returnType !== "Core" && option.qtyAvailToCredit > 0 && line.storeQty <= option.qtyAvailToCredit)))) {
                line.isInvoiceSelected = option.label;
                line.selectedInvoiceNumber = option.value;
                // line.InvoiceDetailsPopUp = line.rowId;
                if (line.selectedInvoiceNumber === "") {
                    line.purchaseDate = "";
                } else if (!_isEmpty(option.invoiceDate)) {
                    line.purchaseDate = moment(option.invoiceDate).format('MM/DD/YYYY');
                }
                // line.invoiceAvlQty = Number(option.availToCredit);
                line.checkAvailToCredit = checkIsValidAvailableQty(line) !== false ? true : false;
              }
        }
                setLineItemRows([...LineItemsRows]);
    }

    const handleVerifiedQty = (event, line) => {
      let qty = 0;
      let previousValidQty = line.storeQty > 0
        ? line.storeQty
        : line.pickUpQty
      if (line.returnType === 'Warranty' && event.target.value === "0") {
        event.target.value = previousValidQty;
        qty = previousValidQty;
      } else {
        qty = event.target.value ? Number(event.target.value) : "";
      }
  
        line.storeQty = qty;
            line.checkAvailToCredit =  checkIsValidAvailableQty(line) === false ? false : true ;

        if (!line.checkAvailToCredit) {
            line.inVoiceOptions = prepareInvoiceOptions(line.invoices, line.returnType);
        }
        let updatedDetails = warrantyUpdatedData && warrantyUpdatedData.map((item)=>{
          if (item.rowId === line.rowId) {
            return {...item,storeQty:qty}
          }
          else{
            return {...item}
          }

        });
        SetWarrantyUpdatedData(updatedDetails);
        SetLineItemEditedData(updatedDetails);
        // handleInvoiceOptions(event,line,false)
        setLineItemRows([...LineItemsRows]);
        setTimeout(() => {
            handleInvoiceOptions(event, line, false, "default");
          }, [300]);
    }

    const checkforSelectInvoice = (part) => {
      if(part.isInvoiceSelected && part.isInvoiceSelected === "Select Invoice") {
        return true;
      }
      return false;
    }
  

    // This method is used for validating icons
    const checkValidDataToAprrove = (part, status, v) => {
         let updatedInvoiceData = warrantyUpdatedData.filter(obj => obj.rowId ===part.rowId)
         let singleData =updatedInvoiceData[0] || part;
         let checkAvailToCredit = checkIsValidAvailableQty(singleData) === false ? false : true;
         let qty = (singleData.storeQty === "" || singleData.storeQty === 0) ? false : true;
         // var UpdatedWarrantyInvoices = parts.updatedInvoicesWarrantyDates && parts.updatedInvoicesWarrantyDates;
         let isWarrantyDateSelected = userDetails && userDetails.userType === STORE_USER && singleData.returnType === "Warranty" ? ( (!_isEmpty(singleData.invoiceDate)) ? true : false) : true;
         let isValid = qty  && singleData.lineSpaceError && !singleData.partNumberError  && !singleData.partNotFound && !singleData.isValidPartNumberAV && singleData.isPLDropDownSelected && (!singleData.isEditableField || singleData.customerInitiated) && ((singleData.returnType === "New"  &&  singleData.label==="Invoice Selected") || (singleData.returnType === "Core"  && singleData.label==="Invoice Selected") || (singleData.returnType === "Warranty"  && singleData.invoiceDate))? true : false;
        let validateInvoice = !_isEmpty(singleData.inVoiceOptions)&& ((singleData.returnType === "New" &&  singleData.label==="Invoice Selected") || (singleData.returnType === "Core" && singleData.label==="Invoice Selected") || (singleData.returnType === "Warranty" &&( singleData.invoiceDate)))?true:false;
        let validateUnlinked = ( singleData.label === "Unlinked")?true:false; // unlinked invoices  can be declined or escalated not approved by store user(UnLinked invoices means invoice drops doesn't have any values)
        // let isWarrantyDateSelected = userDetails && userDetails.userType === STORE_USER && part.returnType == "Warranty" ? (!_isEmpty(part.purchaseDate) ? true : false) : true;
        let isValidForDec = (qty  && !singleData.partNumberError && (!singleData.disableDriverInitiatedline ) && singleData.isPLDropDownSelected  ) ? true : false;
         // let isValidForDec = (qty  && !part.partNumberError && !part.disableDriverInitiatedline && part.isPLDropDownSelected && !part.disableDriverInitiatedline && validateUnlinked && ((part.returnType === "New" && parts.selectedQtyAvailToCredit >=1) || (part.returnType === "Core" && parts.selectedCoreQtyAvailToCredit >=1) || (part.returnType === "Warranty" && isWarrantyDateSelected))) ? true : false;
        let fieldValidationForApprove = ( !singleData.isEditableField && !checkAvailToCredit && !validateUnlinked && isValid && validateInvoice && status === APPROVED ) ? true : false;
        let fieldValidationForDeclined = (!singleData.isEditableField &&!checkAvailToCredit && isValidForDec && status === DECLINED && ((singleData.returnType === "New" && (singleData.label==="Invoice Selected" || validateUnlinked)) || ( singleData.returnType === "Core" && (singleData.label==="Invoice Selected" || validateUnlinked)) || ( singleData.returnType === "Warranty"  && ((singleData.invoiceDate)|| ((singleData.label ==="Unlinked") && (singleData.invoiceDate)) )) || ((_isEmpty(singleData.inVoiceOptions) && ((singleData.returnType === "Warranty" && singleData.invoiceDate) ||(singleData.returnType !== "Warranty")))))) ? true : false;
        let fieldValidationForEscalated =  !singleData.isEditableField  && isValidForDec  && status === ESCALATED && ((singleData.returnType === "Warranty" && validateUnlinked && singleData.invoiceDate) ||((singleData.returnType !== "Warranty" && validateUnlinked ))|| ((_isEmpty(singleData.inVoiceOptions)) && ((singleData.returnType === "Warranty" && singleData.invoiceDate) ||(singleData.returnType !== "Warranty")))) ? true : false;
        //         let fieldValidationForEscalated = (!part.checkAvailToCredit && isValidForDec && !validateInvoice && status === ESCALATED) ? true : false;

        // if (userDetails && userDetails.userType === STORE_USER) {
            if (fieldValidationForApprove) {
                return true;
            } else if (fieldValidationForDeclined) {
                return true;
            } else if (fieldValidationForEscalated) {
                return true;
            }
            return false;
        // } else if (userDetails && userDetails.userType !== STORE_USER) {
        //     return true;
        // }
    }

    const warrantyDateValidation = (part) => {
        let updatedInvoiceData = warrantyUpdatedData.filter(obj => obj.rowId ===part.rowId)
        let singleData =updatedInvoiceData[0] || part;


        let date = singleData.returnType === "Warranty" && (singleData.lineStatus === DRIVER_DELIVERED || singleData.lineStatus === "NEW_ITEM") && (( singleData.label === "Unlinked" && (singleData.purchaseDate ==="" && singleData.invoiceDate ==="" ))||(singleData.label !== "Unlinked" && singleData.invoiceDate ===""))  ? true : false;
        let validateInvoice = !_isEmpty(singleData.inVoiceOptions) && (singleData.invoiceNumber !== "" && singleData.invoiceNumber !==undefined)?true:false;
        let validateUnlinked = (_isEmpty(singleData.inVoiceOptions) || singleData.label === "Unlinked")?true:false;
        let isValid = (validateUnlinked || validateInvoice) && singleData.isPLDropDownSelected && (!singleData.isEditableField || singleData.customerInitiated) ? true : false;
        if (isValid && date) {

            return true;
        }
        else return false;
    }

    const onchangeDate = (e, line) => {
        let year = new Date(e);
        let result = moment(year).format('MM/DD/YYYY');
        line.purchaseDate = result;
        let updatedDetails = warrantyUpdatedData && warrantyUpdatedData.map((item)=>{
            if (item.rowId === line.rowId) {
              return {...item,invoiceDate:result}
            }
            else{
              return {...item}
            }

          });
          SetWarrantyUpdatedData(updatedDetails);
          SetLineItemEditedData(updatedDetails);

        dispatch({
            payload: {
              updatedInvoicesWarrantyDates: {...parts.updatedInvoicesWarrantyDates,invoiceDate:result},
            },
            type: "UPDATE_INVOICES_WARRANTY_DATES",
          });
        setLineItemRows([...LineItemsRows]);
    }

    const setListItemData = (data) => {
        dispatch({
            payload: {
                lineItemData: data,
            },
            type: 'STORE_DATA',
        });
    }

    const handleApproveItem = (value, line, headderRows, index) => {

        const format1 = "YYYY-MM-DD HH:mm:ss"
        let updatedInvoiceData = warrantyUpdatedData.filter(obj => obj.rowId ===line.rowId)
        let singleData =updatedInvoiceData[0]?updatedInvoiceData[0]:line;

        line.checkAvailToCredit = false;
        line.isEditableField = false;
        let statusValue = value;
        let SelectedInvoices=[];
        // var filteredInvoices = (singleData && singleData.invoiceOptions) ?singleData.invoiceOptions: parts.selectedInvoices.filter(e => e != null);
        let filteredInvoices = singleData.updatedInvoices;
        let storeQty=Number(singleData.storeQty);
        filteredInvoices && filteredInvoices.map((item,i)=>{
            if(item.isCheckedUnlinked || _isEmpty(filteredInvoices)){
                SelectedInvoices=[];
            }
            else if(line.returnType !== "Warranty"){
                    if(item.isCheckedQtyAvailToCredit){
                            if(item.qtyAvailToCredit <= storeQty){
                                    SelectedInvoices.push({invoiceNumber:item.invoiceNumber,qtyAvailToCredit:item.qtyAvailToCredit});
                                    storeQty=storeQty-item.qtyAvailToCredit
                        }else{
                            if(storeQty>0){
                                SelectedInvoices.push({invoiceNumber:item.invoiceNumber,qtyAvailToCredit:storeQty});
                            }
                            storeQty=storeQty-item.qtyAvailToCredit

                        }

                    }else if(item.isCheckedCoreQtyAvailToCredit){
                        if(item.coreQtyAvailToCredit <= storeQty){
                            SelectedInvoices.push({invoiceNumber:item.invoiceNumber,coreQtyAvailToCredit:item.coreQtyAvailToCredit});
                            storeQty=storeQty-item.coreQtyAvailToCredit
                        }else{
                            if(storeQty>0){
                            SelectedInvoices.push({invoiceNumber:item.invoiceNumber,coreQtyAvailToCredit:storeQty});
                            }
                            storeQty=storeQty-item.coreQtyAvailToCredit

                        }

                    }
                }

        })

        if(singleData.returnType === "Warranty"){
            SelectedInvoices=[];
            if(singleData.label === "Unlinked"){
                SelectedInvoices.push({invoiceNumber:singleData.label,purchaseDate:moment(singleData.invoiceDate).format(format1)});
            }else{

                SelectedInvoices.push({invoiceNumber:singleData.invoiceNumber,qtyAvailToCredit:singleData.storeQty?singleData.storeQty:singleData.qty,purchaseDate:moment(singleData.invoiceDate).format(format1)});
            }
        }
        if(singleData.label === "" || singleData.label ===undefined){
            try{

                SelectedInvoices=[];
                // const res=JSON.parse(singleData.invoiceNumber);
                // SelectedInvoices=[...res];
            }
            catch(e){
                SelectedInvoices=[];

            }

            }
        if (headderRows.id === line.returnId) {
            line.status = statusValue;
            headderRows.saveButtonEnabled = headderRows.id;
            headderRows.lineItems[index].status = statusValue;
            isSaveAndPostEnabled(headderRows.lineItems);
            lineItemData.push({
                id: line.id,
                rowId: line.rowId,
                headerId: headderRows.id,
                mfgCode:storeAccess()? singleData.pickupMfgCode : singleData.storeMfgCode,
                partNumber:storeAccess()? singleData.pickupPartNumber : singleData.storePartNumber,
                qty: singleData.storeQty || "",
                status: statusValue,
                returnType: singleData.returnType || "New",
                reason: singleData.reason || "",
                creditAmount: singleData.creditAmount,
                description: singleData.partDesc || "",
                invoices:SelectedInvoices,
                amComment:singleData.amComment || ""
                // invoiceNumber: line.selectedInvoiceNumber || "",
                // purchaseDate: (line.purchaseDate && line.returnType === "Warranty") ? moment(line.purchaseDate).format('YYYY-DD-MM HH:mm:ss') : ""
            })
        }
        if (!_isEmpty(lineItemData)) {
            line.mfgCodeDropDown = [];
            line.inVoiceOptions = [];
            line.pl =  storeAccess() ? singleData.pickupMfgCode : singleData.storeMfgCode;
                 line.isValidPartNumberAV = false;
            setListItemData(lineItemData);
        }
        if (line.returnType === "Warranty") {

            line.dateFlag = false;
            line.invoiceNumber= singleData.invoiceNumber || singleData.label;
            if(_isEmpty(filteredInvoices)){
                line.invoiceNumber= singleData.invoiceNumber;
            }
            line.purchaseDate=moment(singleData.invoiceDate).format(format1)
        }
        if (line.returnType !== "Warranty") {
            line.invoiceNumber= "Invoice Selected";
            if(_isEmpty(filteredInvoices)){
                line.invoiceNumber= singleData.invoiceNumber;
            }
        }

        updateNewRows(line); // this method is used to update the rows
        if (statusValue === DECLINED) {
            line.isEditableField = false;
            line.partNotFound = false;
            line.lineStatus = "Declined, Not saved yet "
        } if (statusValue === APPROVED) {
            line.lineStatus = "Approved, Not saved yet "
        }
        let updatedDetails = warrantyUpdatedData && warrantyUpdatedData.map((item)=>{
            if (item.rowId === line.rowId) {
                if(_isEmpty(filteredInvoices)){

                }
                if (statusValue === DECLINED) {
                    return {...item,isEditableField:false,partNotFound:false,lineStatus: "Declined, Not saved yet "}
                } if (statusValue === APPROVED) {
                    return {...item,lineStatus : "Approved, Not saved yet "}

                }
                return {...item}

            }
            else{
              return {...item}
            }

          });

        //    const updatedLineItemRows=LineItemsRows.map((lineItem)=>{
        //     if(lineItem.rowId === line.rowId){
        //         return {...line,}
        //     }
        //     return {...lineItem}
        // })
        SetWarrantyUpdatedData(updatedDetails);
        SetLineItemEditedData(updatedDetails);
        setLineItemRows([...LineItemsRows]);
    }

    const handlepricingCall = async(value, line, hRows, flag) => {
        if (!_isEmpty(value)) {
            line.checkPartNum = value;
        }
        let partValue = !_isEmpty(line.checkPartNum) ? line.checkPartNum.toUpperCase() : line.pickupPartNumber;
        line.disableDriverInitiatedline = false;
        line.lineSpaceError = true;
        line.lineItemLoader = true;
        line.inVoiceOptions = [];
        line.selectedInvoiceNumber = "";
        line.isInvoiceSelected = "";
        line.pickupPartNumber = partValue;
        line.partNumb = partValue;
        const payload = {
            billTo: hRows.customerId,
            shipTo: hRows.shipToId,
            request: {
                requestItems: [
                    {
                        mfgCode: line.storeMfgCode ? line.storeMfgCode : "*",
                        partNum: partValue,
                        qty: line.pickUpQty ? line.pickUpQty : "1",
                    },
                ],
                store: shipInfo.storeNumber ? shipInfo.storeNumber : "",
                dispatch: dispatch,
            },
            uniqId: Math.random(),
        }
        dispatch(getStockProductList(payload)).then((action) => {

            if (action.type === "getStockProductList/fulfilled") {
                line.hideNewItemAdded = false;
                let filteredData = (!_isEmpty(action.payload.responseData) && action.payload.responseData.length > 0) && removedSupAndAltPart(action.payload.responseData);
                let isValidPatNumberCheck = checkPartNumberIsValid(filteredData, partValue);

                if (!_isEmpty(filteredData) && _isEmpty(isValidPatNumberCheck)) {

                    if (filteredData.length === 1) {
                        filteredData.map((v) => {
                            line.pickupPartNumber = v.partNum;
                            line.isPLDropDownSelected = true;
                            line.partNumb = v.partNum;
                            handleInvoiceAPICall(v, filteredData, line, hRows, flag);
                        })
                    } else
                        if (filteredData.length > 1) {
                            line.isEditableField = false;
                            line.isPLDropDownSelected = false;
                            line.mfgCodeDropDown = prepareMFGCodeDropDown(filteredData, line, flag);
                            line.lineItemLoader = false;
                            setLineItemRows([...LineItemsRows]);
                        }
                }
                else
                    if (!_isEmpty(isValidPatNumberCheck) && !_isEmpty(filteredData)) {
                        line.isValidPartNumberAV = true;
                        line.isPLDropDownSelected = true;
                        line.lineItemLoader = false;
                        line.checkPartNumbInVision = !_isEmpty(isValidPatNumberCheck) && isValidPatNumberCheck;
                        line.mfgCodeDropDown = [];
                        line.inVoiceOptions = [];
                        setLineItemRows([...LineItemsRows]);
                    }
                    else {
                        if (_isEmpty(action.payload.responseData) || _isEmpty(filteredData)) {
                            line.mfgCodeDropDown = [];
                            line.inVoiceOptions = [];
                            line.pl = line.pickupMfgCode;
                            line.isPLDropDownSelected = true;
                            if (flag) {
                                line.hideNewItemAdded = true;
                                line.newItemAdded = true;
                                line.isEditableField = false;
                            }
                            line.partNotFound = true;
                            line.lineItemLoader = false;
                            setLineItemRows([...LineItemsRows]);
                        }
                    }
            }
            else {
                line.lineItemLoader = false;
                if (flag) {
                    line.hideNewItemAdded = true;
                    line.newItemAdded = true;
                    line.isEditableField = false;
                }
                setLineItemRows([...LineItemsRows]);
                displayErrorMsg();
            }
        })





    }

    const handleInvoiceAPICall = (value, payLoadLength, line, hrows, flag) => {
        const payload = {
            partNum: value.partNum,
            mfgCode: value.mfgCode,
            billTo: hrows.customerId,
        }
        dispatch(getInvoiceStoreDetails(payload)).then((action) => {
            line.lineItemLoader = false;
            if (action.type === 'getInvoiceStoreDetails/fulfilled') {
                line.invoices = action.payload;
                line.partDesc = value.itemSpecific;
                let lineLevelInvoices = prepareInvoiceOptions(action.payload, line.returnType);
                line.inVoiceOptions = lineLevelInvoices;
                setTimeout(() => {
                    handleInvoiceOptions(value, line, false, "default");
                  }, [300]);
                setLineItemRows([...LineItemsRows]);
                line.newItemAdded = false;
                if ((action.payload.length > 0) || (action.payload.length === 0)) {
                    line.pickupMfgCode = value.mfgCode;
                    line.pl = value.mfgCode;
                    line.creditAmount = value.costPrice;
                    line.isEditableField = false;
                    if (payLoadLength.length > 1) {
                        line.partDesc = value.desc;
                    }
                    else if (payLoadLength.length === 1 || payLoadLength.length === 0) {
                        line.mfgCodeDropDown = [];
                        line.partDesc = value.itemSpecific;
                    }
                    updateNewRows(line, hrows);
                    setLineItemRows([...LineItemsRows]);
                }
                const updatedDetails = warrantyUpdatedData.map((item)=>{
                    if (item.rowId === line.rowId) {
                        // let obj={
                        //     ...lineitem,
                        //     disableDriverInitiatedline:false,
                        //     partNumberError:false,
                        //     isPLDropDownSelected:true,
                        // }

                      return {...item,...line}
                    }
                    else{
                      return {...item}
                    }

                  });

                 SetWarrantyUpdatedData(updatedDetails);
                 SetLineItemEditedData(updatedDetails);
            } else {
                line.newItemAdded = false;
                if (flag) {
                    line.hideNewItemAdded = true;
                    line.newItemAdded = true;
                    line.isEditableField = false;
                }
                setLineItemRows([...LineItemsRows]);
                displayErrorMsg();
            }

        })

           }

    const updateNewRows = (line) => {
        let data = !_isEmpty(LineItemsRows) && LineItemsRows.findIndex((v) => v.rowId === line.rowId);
        if (data !== -1) {
            if (line.lineStatus === "NEW_ITEM") {
                _.remove(LineItemsRows, (v) => v.rowId === line.rowId);
                LineItemsRows.unshift(line);
            } else {
                let index = LineItemsRows.findIndex((v) => v.rowId === line.rowId);
                if (index !== -1) { LineItemsRows[index] = line; }
            }
        }
        let ind = !_isEmpty(rowdata) && rowdata.findIndex((v) => v.id === line.returnId);
        if (ind !== -1) { rowdata[ind].lineItems = LineItemsRows; }
        setRowsdata(rowdata);
        setLineItemRows([...LineItemsRows]);
    }

    const handleDelete = (line, headderRows) => {

        let clonedData = _.cloneDeep(lineItemData);
        let data = !_isEmpty(LineItemsRows) && LineItemsRows.findIndex((v) => v.rowId === line.rowId);
        _.remove(LineItemsRows, (v) => v.rowId === line.rowId);
        if (data !== -1) {
            let filteredData = _.filter(clonedData, (item) => item.rowId !== line.rowId);
            if (_isEmpty(filteredData)) {
                headderRows.saveButtonEnabled = "";
            } else {
                headderRows.saveButtonEnabled = headderRows.id;
            }
            setListItemData(filteredData);
        }
        let ind = !_isEmpty(rowdata) && rowdata.findIndex((v) => v.id === line.returnId);
        if (ind !== -1) { rowdata[ind].lineItems = LineItemsRows; }
        setRowsdata(rowdata);
        setLineItemRows([...LineItemsRows]);
    }

    const handleEscalateItem = (line) => {
        setEscalateModal(true);
        setEscalateLineId(line.rowId);
        setLineItemRows([...LineItemsRows]);
    }

    const clearDropdowns = (line) => {
        line.partNumberError = true;
        line.mfgCodeDropDown = [];
        line.inVoiceOptions = [];
        setLineItemRows([...LineItemsRows]);
    }
    const checkForDefaultInvoiceValue=(part)=>{
        let specificValuesFromArray = warrantyUpdatedData.filter(obj => obj.rowId ===part.rowId)
        specificValuesFromArray=specificValuesFromArray[0] || part;

        if(specificValuesFromArray.rowId === part.rowId){
                 if(specificValuesFromArray.label){
                    return specificValuesFromArray.label;
                }
                else {
                    return "Select Invoice"
                }
    }


    }
    const checkForJson=(str)=> {
        if( str === undefined || str === null){
            return "Unlinked"
        }
        if(str === "null" || !str || str ===""){
            return "Unlinked";
        }
        try {
            const res=JSON.parse(str);
                return res[0]?res[0].invoiceNumber:res;

        } catch (e) {
            return str;
        }

    }
    const checkForJsonDate=(str)=> {
        try {
            const res=JSON.parse(str);
            const formattedDate=!_isEmpty(res[0].purchaseDate) && moment(res[0].purchaseDate).format("MM/DD/YYYY");
            return formattedDate;


        } catch (e) {
            return "-";
        }
    }
    const handleSelectedInvoice=(part,invoiceNo)=>{
        if(invoiceNo){
            return invoiceNo ;
        }else if(!invoiceNo && InvoiceSelectedUnlinked !== "false"){
            return "Unlinked"
        }else if(!invoiceNo && part.returnType === "Warranty" && !_isEmpty(parts.UpdatedWarrantyInvoices)){
            return parts.UpdatedWarrantyInvoices.invoiceNumber || "test"
        } else if(part.isEditableField){
            return "-"
        }else if(part.returnType === "New" && part.returnType === "Core" && !part.isEditableField){
            return "Invoice Selected"
        }else{
            return "-"
        }

    }
    const checkForWarrantyDate=(line)=>{
        let specificValuesFromLineItems = warrantyUpdatedData && warrantyUpdatedData.filter(obj => obj.rowId ===line.rowId)
        let lineItemObject =!_isEmpty(specificValuesFromLineItems[0])?specificValuesFromLineItems[0]:line;

        if(((!_isEmpty(lineItemObject.invoiceDate) ||  !_isEmpty(lineItemObject.label)) && lineItemObject.returnType === "Warranty" && (lineItemObject.lineStatus !== DRIVER_DELIVERED && lineItemObject.lineStatus !== "NEW_ITEM" ))){
            return true
        }
        return false

    }
    const checkForWarrantyDateValue=(part)=>{
        let specificValuesFromLineItems = warrantyUpdatedData && warrantyUpdatedData.filter(obj => obj.rowId ===part.rowId)
        let lineItemObject =specificValuesFromLineItems[0] || part;
        try {
            const res=JSON.parse(part.invoiceNumber);
            const formattedDate=!_isEmpty(res[0].purchaseDate) && moment(res[0].purchaseDate).format("MM/DD/YYYY");
            return formattedDate;


        } catch (e) {
            if((lineItemObject.invoiceDate !=="") && lineItemObject.returnType === "Warranty" && (lineItemObject.lineStatus !== DRIVER_DELIVERED && lineItemObject.lineStatus !== "NEW_ITEM")) {
                return lineItemObject.invoiceDate?moment(lineItemObject.invoiceDate).format('MM/DD/YYYY'):moment(lineItemObject.purchaseDate).format('MM/DD/YYYY');
            }else{
                return "-";
            }
        }

    }
    const checkForErrors=(part)=>{
        let specificValuesFromLineItems = warrantyUpdatedData && warrantyUpdatedData.filter(obj => obj.rowId ===part.rowId)
        let lineItemObject =specificValuesFromLineItems[0] || part;

        let checkAvailToCredit = checkIsValidAvailableQty(part) !== false ? true : false;

        if(((lineItemObject.returnType !== "Warranty" && checkAvailToCredit)||((part.returnType === "Warranty" && !_isEmpty(lineItemObject) && lineItemObject.qtyAvailToCredit))) && (!escalateModal)) {
            return true;
        }
    }
    const handleReviewComment=(e,line)=>{
        line.amComment = e.target.value;
        console.log('.....',e.target.value)
        let updatedDetails = warrantyUpdatedData && warrantyUpdatedData.map((item)=>{
            if (item.rowId === line.rowId) {
              return {...item,amComment:e.target.value}
            }
            else{
              return {...item}
            }

          });
          let updatedLineitemData = lineItemData && lineItemData.map((item)=>{
            if (item.rowId === line.rowId) {
              return {...item,amComment:e.target.value}
            }
            else{
              return {...item}
            }

          });
          setListItemData(updatedLineitemData);
          SetWarrantyUpdatedData(updatedDetails);
          SetLineItemEditedData(updatedDetails);
        setLineItemRows([...LineItemsRows]);
    }
    const checkForStatus=(part)=>{
        if(headerLineItems.displayStatus === "Posted"){
            if(part.visionStatus === "RETURN_ACCEPTED"){
                return "Vision Approved"
            } else if(part.visionStatus === "RETURN_DECLINED"){
                return "Vision Declined"

            }
        }
        console.log("partNum=>",part.partNumb,part.partNumber)

        return part.lineStatus.replace(/_/g, " ")
    }
   
    return (
        !_isEmpty(LineItemRowsData) && LineItemsRows.map((part, i) => (
            part.lineItemLoader ?
                <div className='single-loading_content'>
                    <Placeholder rows={1} /></div> :
                <Box sx={{
                    width: "100%", flexWrap: 'wrap',
                    '& > :not(style)': { m: 1, width: "100%", height: "100%", },
                }}
                >
                    <Paper elevation={5}>
                    {(headerLineItems["isExpand"]) && (

                        <Row className="grey_box grey_box_width" key={part.rowId}>

                            <Col md={2}>
                                <div className="storeText">
                                    <span >P/L</span>
                                    {
                                        ((!_isEmpty(part.mfgCodeDropDown) && part.lineStatus === "NEW_ITEM") || ((headerLineItems.returnType === DRIVER_INITIATED || headerLineItems.returnType === CUSTOMER_INITIATED || headerLineItems.returnType === STORE_INITIATED) && part.status === DRIVER_DELIVERED && !_isEmpty(part.mfgCodeDropDown))) ?
                                            (<span>
                                                <Select
                                                    className="custom-dropdown-original_invoice partNumber_width"
                                                    options={part.mfgCodeDropDown}
                                                    defaultValue={!_isEmpty(part.driverMfgCode) ? part.mfgCodeDropDown.filter((p) => p.mfgCode === part.driverMfgCode) : ""}
                                                    placeholder="Select P/L"
                                                    onChange={(event) => handleMFGCodeSelection(event, part, headerLineItems)}
                                                    theme={(theme) => themeColor(theme)}
                                                /></span>) :
                                            (part.newItemAdded && part.lineStatus === "NEW_ITEM") ?
                                                (<span>
                                                    <input
                                                        style={{ marginLeft: "-2px", width: "100px" }}
                                                        className="form-control partNumber_width"
                                                        id="ex1"
                                                        defaultValue={part.storeMfgCode}
                                                        onChange={(e) => { handleMFGCode(e.target.value, i, part) }}
                                                        onKeyDown={(event) => { handleKeyDownAlphabet(event); }}
                                                        type="text" /></span>) :
                                                <span className='store-maroonColorTxt text_overFlow'>{part.pl || part.mfgCode}</span>
                                    }
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="storeText">
                                    <span >Part #</span>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 100, hide: 400 }}
                                        overlay={(!part.isEditableField && !_isEmpty(part.partNumb || part.partNumber)) ? <Tooltip>{part.partNumb || part.partNumber}</Tooltip> : <span></span>}>
                                             {(part.isEditableField && storeAccess()) ?
                                            (<span className='partNum_icon'>
                                                <Form.Control
                                                    maxLength="20"
                                                    disabled={storeAccess() ? false : true}
                                                    className={storeAccess() ? "partNumber" : "partNumber disabled_inputBox"}
                                                    type="text"
                                                    defaultValue={part.partNumb || part.partNumber}
                                                    onChange={(event) => {
                                                        if (storeAccess()) {
                                                            handlePartNumber(part, event.target.value)
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        if (storeAccess()) {
                                                            if (part.lineSpaceError && e.target.value !== part.partNumb && !_isEmpty(e.target.value)) {
                                                                handlepricingCall(e.target.value || part.partNumb || part.partNumber, part, headerLineItems)
                                                            } else if (_isEmpty(e.target.value)) {
                                                                clearDropdowns(part);
                                                            }
                                                        }
                                                    }}
                                                    onKeyDown={(event) => { handleKeyDownReturnsLineItems(event); }}
                                                    id="currentPassword"
                                                />
                                                {(
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 100, hide: 400 }}
                                                        overlay={(storeAccess()) ? <Tooltip>Click to validate the Part #</Tooltip> : <span></span>}>
                                                        <button 
                                                        class={storeAccess() ? " search_icon_button partNumber_width lens_icon_pointer":" search_icon_button partNumber_width search_icon"}
                                                        disabled={storeAccess() ? false : true}
                                                        onClick={(event) => {
                                                                if (!part.partNumberError && part.lineSpaceError && storeAccess()) {
                                                                    handlepricingCall(event.target.value || part.partNumb || part.partNumber, part, headerLineItems)
                                                                }
                                                            }}>
                                                            <img
                                                                class={storeAccess() ? "lens_icon_pointer":"search_icon"}
                                                                src={searchIcon}
                                                                alt="search" />
                                                        </button>
                                                    </OverlayTrigger>)} </span>) :
                                            (part.newItemAdded && part.hideNewItemAdded && part.lineStatus === "NEW_ITEM") ?
                                                <span>
                                                    <input
                                                        maxLength="20"
                                                        onChange={(event) => {
                                                            if (storeAccess()) {
                                                                handlePartNumber(part, event.target.value || part.partNumb || part.partNumber)
                                                            }
                                                        }}
                                                        className="form-control partNumber_width"
                                                        defaultValue={part.storePartNumber}
                                                        style={{ textTransform: "uppercase", marginLeft: "4px", width: "100px", }}
                                                        id="ex2"
                                                        onKeyDown={(event) => {
                                                            handleKeyDownReturnsLineItems(event);
                                                        }}
                                                        type="text" /></span> :
                                                <span className='store-maroonColorTxt text_overFlow'>{part.partNumb}</span>
                                        }
                                    </OverlayTrigger>
                                </div>
                                {(part.isValidPartNumberAV ?
                                    (<p className='driver_errormsg'>The part {" "}<span className='store-maroonColorTxt'>{part.partNumb}</span>{" "}entered is not available in Vision. Instead you can change this Part # to  {" "}
                                        {!_isEmpty(part.checkPartNumbInVision) && part.checkPartNumbInVision.map((v, k) => (
                                            <span className={part.checkPartNumbInVision.length > 1 ? 'store-maroonColorTxt error_msg_text' : "store-maroonColorTxt"}>
                                                {(part.checkPartNumbInVision.length > 1 && (k !== part.checkPartNumbInVision.length - 1)) ? v.partNum.concat(", ") : v.partNum}</span>
                                        ))} {" "}</p>) : " ")}

                                {(part.partNotFound) ? (
                                    <p className='error-message font_style'>Part number not found</p>
                                ) : ""}

                                {(part.partNumberError ?
                                    (<p className='error-message font_style'>Please enter Part #</p>) :
                                    "")}

                                {(!part.lineSpaceError ?
                                    (<p className='error-message font_style'>Part number should not contain space</p>) :
                                    "")}
                                {checkForErrors(part)?
                                (<p className='error-message font_style'>{checkIsValidAvailableQty(part)}</p>) : ""
                                }
                            </Col>
                            <Col md={2}>
                                {(part.newItemAdded && part.lineStatus === "NEW_ITEM") ?
                                    <div className="storeText">
                                        <span><span>
                                            <Button
                                                className="store_go_button"
                                                disabled={_isEmpty(part.storePartNumber)}
                                                onClick={(event) => {
                                                    if (!part.partNumberError && part.lineSpaceError && storeAccess()) {
                                                        handlepricingCall(event.target.value, part, headerLineItems, true)
                                                    }
                                                }}
                                            >GO </Button></span></span>
                                    </div> :
                                    <div className="storeText credit_text">
                                        <span >Expected Credit</span>
                                        <span className='store-maroonColorTxt'>${part.creditAmount !== undefined ? formatAmount(part.creditAmount) : formatAmount(0)}</span>
                                    </div>}
                            </Col>
                            <Col md={3}>
                                {((!part.hideNewItemAdded && part.isPLDropDownSelected) || part.lineStatus === DRIVER_DELIVERED) && (
                                    <div className="storeText">
                                        <span>Return Type</span>
                                        {(part.lineStatus !== DRIVER_DELIVERED && part.lineStatus !== "NEW_ITEM") ?
                                            <span className='store-maroonColorTxt' >{part.returnType}</span> :
                                            <span>
                                                <Select
                                                    className="custom-dropdown-store-returnType partNumber_width"
                                                    options={options}
                                                    isDisabled={storeAccess() ? false : true}
                                                    defaultValue={options.filter(op => op.value === part.returnType)}
                                                    onChange={(event) => handleReturnTypeSelection(event, part)}
                                                    theme={(theme) => themeColor(theme)}
                                                /></span>}
                                    </div>)}
                            </Col>

                            <Col md={2} className="icons_position">
                                {(userType !== OPERATIONS_MANAGER) && (((part.newlineItem) || (part.lineStatus === "NEW_ITEM" && !part.hideNewItemAdded && part.isPLDropDownSelected)) || (part.lineStatus === DRIVER_DELIVERED && storeAccess()) || (part.lineStatus === ESCALATED && !_isEmpty(USERROLE) && (USERROLE === CC_MANAGER || USERROLE === AREA_MANAGER))) &&
                                    (<div>
                                        {((part.lineStatus === "NEW_ITEM" && !part.hideNewItemAdded && part.isPLDropDownSelected) || (part.lineStatus === DRIVER_DELIVERED && storeAccess()) || (part.lineStatus === ESCALATED && !_isEmpty(USERROLE) && (USERROLE === CC_MANAGER || USERROLE === AREA_MANAGER))) && (
                                            <div className={checkValidDataToAprrove(part, APPROVED, headerLineItems) ? "approved_icon" : "approveDisabled_icon"}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 100, hide: 400 }}
                                                    overlay={
                                                        (!part.isPLDropDownSelected) ?
                                                            (<Tooltip>Please select P/L</Tooltip>) :
                                                            (!_isEmpty(part.inVoiceOptions) && _isEmpty(part.isInvoiceSelected) || checkforSelectInvoice(part)) ?
                                                                (<Tooltip>Please select Invoice  </Tooltip>) :
                                                                (userDetails.userType === STORE_USER && (_isEmpty(part.inVoiceOptions) || part.isInvoiceSelected === "Unlinked") && (!part.isEditableField || part.customerInitiated)) ?
                                                                    (<Tooltip>The unlinked invoice cannot be approved.Please Escalate or Decline the line item </Tooltip>) :
                                                                    (!part.isEditableField || !part.checkAvailToCredit) ?
                                                                        (<Tooltip>Click here to approve line Item</Tooltip>) :
                                                                        <span></span>
                                                    }>
                                                    <i class="fa fa-check-circle" onClick={(event) => {
                                                       if(!(!_isEmpty(part.inVoiceOptions) &&
                                                       _isEmpty(part.isInvoiceSelected) || checkforSelectInvoice(part))) {
                     
                                                        if (checkValidDataToAprrove(part, APPROVED, headerLineItems)) {
                                                            handleApproveItem(APPROVED, part, headerLineItems, i);
                                                        }
                                                      }
                                                    }} aria-hidden="true"></i>
                                                </OverlayTrigger>
                                            </div>)}

                                        {((part.lineStatus === DRIVER_DELIVERED && storeAccess()) || (part.lineStatus === ESCALATED && !_isEmpty(USERROLE) && (USERROLE === CC_MANAGER || USERROLE === AREA_MANAGER))) && (
                                            <div className={(checkValidDataToAprrove(part, DECLINED, headerLineItems)) ? "declined_icon" : "declined_disabled_icon"}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 100, hide: 400 }}
                                                    overlay={
                                                        (!part.isPLDropDownSelected) ?
                                                            (<Tooltip>Please select P/L</Tooltip>) :
                                                            (!_isEmpty(part.inVoiceOptions) && _isEmpty(part.isInvoiceSelected) || checkforSelectInvoice(part)) ?
                                                                (<Tooltip>Please select Invoice  </Tooltip>) :
                                                                (!part.disableDriverInitiatedline) ?
                                                                    (<Tooltip>Click here to decline lineItem</Tooltip>) :
                                                                    <span></span>
                                                    }>
                                                    <i class="fa fa-times-circle-o" onClick={(event) => {
                                                       if (!(!_isEmpty(part.inVoiceOptions) &&
                                                       _isEmpty(part.isInvoiceSelected) || checkforSelectInvoice(part))) {
                   
                                                        if (checkValidDataToAprrove(part, DECLINED, headerLineItems)) {
                                                            handleApproveItem(DECLINED, part, headerLineItems, i);
                                                        }
                                                      }
                                                    }} aria-hidden="true"></i>
                                                </OverlayTrigger>
                                            </div>)}

                                        {userDetails &&  storeAccess() && 
                                        ((part.lineStatus === "NEW_ITEM" && !part.hideNewItemAdded && part.isPLDropDownSelected) 
                                        || (part.lineStatus === DRIVER_DELIVERED && userType === STORE_USER)) && (
                                            <div className="exculate_icon">
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 100, hide: 400 }}
                                                    overlay={
                                                        checkValidDataToAprrove(part, ESCALATED, headerLineItems) ?
                                                            (<Tooltip>Escalate line Item</Tooltip>) :
                                                            <span></span>}>
                                                    <button class={part.lineStatus === "NEW_ITEM" ? "search_icon_button1 partNumber_width" : "search_icon_button partNumber_width"}
                                                        onClick={(event) => {
                                                            if (checkValidDataToAprrove(part, ESCALATED, headerLineItems)) {
                                                                handleEscalateItem(part);
                                                            }
                                                        }}>
                                                        <img
                                                            class='escalate_icon'
                                                            src={checkValidDataToAprrove(part, ESCALATED, headerLineItems) ? EscalateIcon : escalateDisabledIcon}
                                                            alt="escalate" />
                                                    </button>
                                                </OverlayTrigger>
                                            </div>)}
                                    </div>)}
                                {(part.newlineItem && part.newlineItem !== undefined) && (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 100, hide: 400 }}
                                        overlay={<Tooltip>Click here to Delete line Item</Tooltip>}>
                                        <img
                                            src={deleteIcon}
                                            className={part.newItemAdded ? "store-delete-icon1" : (part.lineStatus === "NEW_ITEM") ? "store-delete-icon" : "delete-icon-flag"}
                                            onClick={(event) => { handleDelete(part, headerLineItems); }}
                                            alt="Delete"
                                        />
                                    </OverlayTrigger>)}

                                {!_isEmpty(headerLineItems) && (headerLineItems.displayStatus === "Posted" && headerLineItems.status === MANUALLY_PROCESSED) && (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 100, hide: 400 }}
                                        overlay={
                                            <Tooltip>
                                                {!_isEmpty(headerLineItems.reviewRefNum) &&
                                                    ("Reference :" + headerLineItems.reviewRefNum)}
                                                <br></br>
                                                {!_isEmpty(headerLineItems.comment) && (
                                                    "Comment : " + headerLineItems.comment)}
                                            </Tooltip>
                                        }>
                                        <div className="storeText status_width line-width" >
                                            <span className='maroonColorTxt-status manual_process_status store_status_text'>{!_isEmpty(headerLineItems.status) ? headerLineItems.status.replace(/_/g, " ") : ""}</span>
                                        </div>
                                    </OverlayTrigger>
                                )}
                            </Col>
                                    <>
                                    <Col md={2}>
                                        <div className="storeText" >
                                            <span >Qty #</span>
                                            <span className='store-maroonColorTxt text_lign_items'>{part.pickUpQty > 0 ? part.pickUpQty : part.storeQty > 0 ? part.storeQty : part.originalQty}</span>
                                        </div>
                                    </Col>

                                    <Col md={3}>
                                        <div className="storeText line-width"  ref={ref}>
                                            <span style={{ fontSize: "14px" }}>Original Invoice</span>
                                            {(part.status === DRIVER_DELIVERED || part.lineStatus === "NEW_ITEM") ?
                                                ((_isEmpty(part.inVoiceOptions) || part.inVoiceOptions === undefined) ?
                                                    <span className="maroonColorTxt-invoice" >{checkForJson(part.invoiceNumber)}</span> :
                                                    <><Form.Control
                                                        type="text"
                                                        disabled={storeAccess() ? false : true}
                                                        onChange={(e) => handleInvoiceOptions(e, part,true, "manual")}
                                                        onClick={(e) => handleInvoiceOptions(e, part,true, "manual")}
                                                        value={checkForDefaultInvoiceValue(part)}
                                                        className={storeAccess() ? "partNumber_width invoice text_overFlow" : "partNumber_width invoice text_overFlow disabled_inputBox"}
                                                        placeholder="Select Invo.."
                                                        name="invoice"
                                                        id="invoice"

                                                    />
                                                        <div class=" css-1hb7zxy-IndicatorsContainer down-arrow-return" onClick={(e) => { if (storeAccess()) { handleInvoiceOptions(e, part,true, "manual") } }}>
                                                            <div aria-hidden="true" class=" css-tlfecz-indicatorContainer">
                                                                { part.enableInvoicesPopUp && (part.InvoiceDetailsPopUp === part.rowId)?
                                                                    <i class="fa fa-angle-up ymmeArrow" aria-hidden="true"></i> :
                                                                    <i class="fa fa-angle-down ymmeArrow" aria-hidden="true"></i>
                                                                }
                                                            </div></div> </>) :
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 100, hide: 400 }}
                                                    overlay={<Tooltip>{!_isEmpty(part.invoiceNumber) ? checkForJson(part.invoiceNumber):'-'}</Tooltip>}>
                                                    <span className='maroonColorTxt-invoice' >{checkForJson(part.invoiceNumber)}</span>
                                                </OverlayTrigger>}

                                            {( part.enableInvoicesPopUp )&& !_isEmpty(part.inVoiceOptions) && updatedPart && (part.InvoiceDetailsPopUp === part.rowId) &&(
                                                <div
                                                // onMouseLeave={(e) => handleCloseDropdown(e, part)}
                                                >
                                                    <InvoiceDetails
                                                        part={enablePropShare && updatedPart}
                                                        handleInvoice={handleInvoice}
                                                        INVOICE_ERROR_MSG={INVOICE_ERROR_MSG}
                                                        handleInvoiceForStore={handleInvoiceForStore}
                                                        setLineItemRows={setLineItemRows}
                                                        checkIsValidAvailableQty={checkIsValidAvailableQty}
                                                        LineItemsRows={LineItemsRows}
                                                        warrantyUpdatedData={warrantyUpdatedData}
                                                        SetWarrantyUpdatedData={SetWarrantyUpdatedData}
                                                        SetLineItemEditedData={SetLineItemEditedData}
                                                    /></div>)}
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="storeText line-width credit_text">
                                            <span >Verified Qty #</span>

                                            {
                                                ((part.lineStatus !== DRIVER_DELIVERED && part.lineStatus !== "NEW_ITEM")) ?
                                                    <span className='store-maroonColorTxt qty partNumber_width'>{part.storeQty ? part.storeQty : ""}</span>
                                                    :
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 100, hide: 400 }}
                                                        overlay={<span></span>}
                                                    ><span>
                                                            <input
                                                                disabled={storeAccess() ? false : true}
                                                                style={{ height: "36px", width: "61px", textAlign: "center" }}
                                                                type="text"
                                                                name="storeQty"
                                                                className={storeAccess() ? "form-control partNumber_width" : "form-control partNumber_width disabled_inputBox"}
                                                                id="storeQty"
                                                                defaultValue={part.storeQty > 0 ? part.storeQty : part.pickUpQty}
                                                                onChange={(e) => handleVerifiedQty(e, part, part.pickUpQty)}
                                                                onKeyDown={(event) => { handleKeyDown(event) }}
                                                            /></span></OverlayTrigger>}
                                        </div> </Col>
                                    <Col md={3}>
                                        <div className="storeText line-width">
                                            <span >Warranty Date</span>
                                            {part.dateFlag && (part.lineStatus === DRIVER_DELIVERED || part.lineStatus === "NEW_ITEM") ?
                                                (<WarrantyDatePicker
                                                    part={part}
                                                    DRIVER_DELIVERED={DRIVER_DELIVERED}
                                                    userType={userType}
                                                    STORE_USER={STORE_USER}
                                                    onchangeDate={onchangeDate}
                                                    warrantyUpdatedData={warrantyUpdatedData}
                                                    SetWarrantyUpdatedData={SetWarrantyUpdatedData}
                                                    SetLineItemEditedData={SetLineItemEditedData}

                                                />) :
                                                // <span className={(!_isEmpty(parts.updatedInvoicesWarrantyDates.invoiceDate) ||  !_isEmpty(part.invoiceNumber)) && part.returnType === "Warranty" && (part.lineStatus !== DRIVER_DELIVERED && part.lineStatus !== "NEW_ITEM" ) ? 'maroonColorTxt-status store_status_text' : "store-maroonColorTxt driver_initiated"}>
                                                <span className={checkForWarrantyDate(part) ? 'maroonColorTxt-status store_status_text' : "store-maroonColorTxt"}>

                                                    {part.returnType==="Warranty" ?checkForWarrantyDateValue(part):"-"}</span>
                                            }
                                        </div>
                                        {(warrantyDateValidation(part) ?
                                            (<p className='error-message font_style'>Please select date</p>) :
                                            "")}
                                    </Col>
                                    <Col md={2}>
                                        {part.lineStatus !== "NEW_ITEM" && (
                                            <div className="storeText status_width line-width" >
                                                <span >Status</span>
                                                <span className='maroonColorTxt-status store_status_text'>{!_isEmpty(part.lineStatus) ? checkForStatus(part) : ""}</span>
                                            </div>)}
                                    </Col>
                                    <Col md={5} >
                                        <div className="storeText line-width ">
                                            <span >Description</span>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 100, hide: 400 }}
                                                overlay={!_isEmpty(part.partDesc) ? (<Tooltip>{part.partDesc}</Tooltip>) : <span></span>}>
                                                <span className={!_isEmpty(part.partDesc) ? 'maroonColorTxt-description' : "store-maroonColorTxt driver_initiated"}>{part.partDesc || " - "}</span>
                                            </OverlayTrigger>
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="storeText line-width credit_text">
                                            <span >Return Reason</span>
                                            {(part.lineStatus !== DRIVER_DELIVERED && part.lineStatus !== "NEW_ITEM") ?
                                                (<OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 100, hide: 400 }}
                                                    overlay={!_isEmpty(part.reason) ? <Tooltip>{part.reason}</Tooltip> : <span></span>}>
                                                    <span className='maroonColorTxt-reason'>{part.reason || ""}</span>
                                                </OverlayTrigger>) :
                                                (<span>
                                                    <Form.Control
                                                        type="text"
                                                        defaultValue={part.reason}
                                                        onChange={(e) => handleReturnReason(e, part)}
                                                        disabled={storeAccess() ? false : true}
                                                        className={storeAccess() ? " partNumber_width" : " partNumber_width disabled_inputBox"}
                                                        name="reason"
                                                        id="reason"
                                                    />  </span>)}
                                        </div>
                                    </Col>
                                    <Col md={3}>
                    <div className="store-acx-text-lineitems">
                      <div className="storeText line-width credit_text">
                        <span className="marron_text">Store #</span>
                        <span className="text_fontsize store-acx-text-lineitems-value">
                          {headerLineItems.location
                            ? headerLineItems.location
                            : ""}
                        </span>
                      </div>
                      <div className="storeText line-width credit_text store-acx-text-lineitems-acx">
                        <span className="marron_text">ACX #</span>
                        {headerLineItems.acxConfNumber &&
                        headerLineItems.status !== STORE_REVIEWED ? (
                          <span className="text_fontsize store-acx-text-lineitems-value">
                            {headerLineItems.acxConfNumber}
                          </span>
                        ) : headerLineItems.oneStepConfNumber &&
                          headerLineItems.status === STORE_REVIEWED ? (
                          <span className="text_fontsize store-acx-text-lineitems-value">
                            {headerLineItems.oneStepConfNumber}
                          </span>
                        ) : (
                          <span>N/A</span>
                        )}
                      </div>
                    </div>
                  </Col>

{ userType === AREA_MANAGER ?((part.lineStatus === "Declined, Not saved yet " || part.lineStatus === "Approved, Not saved yet ")?
                               <Col md={2}>
                                   <div className="storeText line-width review_comment-input">
                                       <span >Review Comment </span>
                                           <span>
                                               <Form.Control
                                                   type="text"
                                                   defaultValue={part.amComment}
                                                   onChange={(e) => handleReviewComment(e, part)}
                                                   className={" partNumber_width"}
                                                   name="amComment"
                                                   id="amComment"
                                               />  </span>
                                   </div>
                               </Col>
                               :
                               <div className="storeText line-width review_comment">
                                     <span >Review Comment </span>
                                                 <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 100, hide: 400 }}
                                                    overlay={!_isEmpty(part.amComment) ? <Tooltip>{part.amComment}</Tooltip> : <span></span>}>
                                                    <span className='maroonColorTxt-reason'>{part.amComment || ""}</span>
                                                </OverlayTrigger>
                                        {/* <span >{part.amComment?part.amComment:""}</span> */}
                               </div>):""
                            }
                                    </>
                        </Row>
                    )}
                        {(escalateModal && escalateLineId === part.rowId && !_isEmpty(part)) &&
                            (<Escalate
                                row={part}
                                headerLineItems={headerLineItems}
                                escalateModal={escalateModal}
                                handlePageChange={handlePageChange}
                                setEscalateLineId={setEscalateLineId}
                                setEscalateModal={setEscalateModal}
                                ESCALATED={ESCALATED}
                                displayErrorMsg={displayErrorMsg}
                                clearHeaderId={clearHeaderId}
                                warrantyUpdatedData={warrantyUpdatedData}
                            />)}
                    </Paper>
                </Box>
        ))
    );
}
export default LineItems;
