import React from "react";
import T from "prop-types";
import { Alert, Button } from "react-bootstrap";

import "./styles.scss";

function AlertBrowser(props) {
  const { show, message, handleClose } = props;

  return (
    <React.Fragment>
      <Alert show={show} variant="warning" className="custom_alert">
        <Alert.Heading as="div" className="head_wrapper">
          <h5 className="head_text">Browser Update!</h5>
          <div className="close-icon" onClick={handleClose}>
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </Alert.Heading>
        <div className="message">{message}</div>
      </Alert>
    </React.Fragment>
  );
}

AlertBrowser.propTypes = {
  show: T.bool,
  message: T.string,
  handleClose: T.func,
};

export default AlertBrowser;
