import { createSlice} from "@reduxjs/toolkit";
import { fetchReturn ,getStockProductList ,getAllStoreNumberForManager ,getAllUserRoles } from "./storeDashboard.thunks";
import _isEmpty from 'lodash/isEmpty';
import _ from "lodash";

let initialState ={
loading: false,
returnsLoading : false,
managerStoreNumList:[],
returnList :[],
userRoleName :"",
selectedStore:null,

}
const storeDashBoard = createSlice({
    name:"storeDashBoard",
    initialState,
    reducers: {

      storefilters:(state,{payload}) => {
        state.selectedStore = payload;
      }
      
    },
    extraReducers: {

      [fetchReturn.pending]: (state, action) => {
        state.loading = true;
        state.returnsLoading =true;
      },
      [fetchReturn.fulfilled]: (state, action) => {
        state.loading = false;
        state.returnsLoading =false;
        state.returnList = action.payload;
      },
  
      [fetchReturn.rejected]: (state, action) => {
        state.loading = false;
        state.returnsLoading =false;
      },
      [getStockProductList.fulfilled] : (state ,action)=>{
        const responseArr = [];
        if (!_isEmpty(action.payload.responseData)) {
          action.payload.responseData.map((response) => {
            if (
              response.status === "8" ||
              response.status === "1" ||
              response.status === "6" ||
              response.status === "3"
            ) {
              responseArr.push(response);
            }
          });
        }
      },
      [getAllStoreNumberForManager.pending]: (state, action) => {
        state.loading = true;
      },
      [getAllStoreNumberForManager.fulfilled]: (state, action) => {
        state.loading = false;
        const storeList =[]
        if (!_isEmpty(action.payload)) {
          storeList.push("*");
         action.payload.map((store)=>{
          if(!_isEmpty(store.storeNumber)){
          storeList.push(store.storeNumber);
          state.managerStoreNumList = _.uniq(storeList);
          }
        })
      }
      }, 
      [getAllStoreNumberForManager.rejected]: (state, action) => {
        state.loading = false;
      },
      
      [getAllUserRoles.pending]: (state, action) => {
        state.loading = true;
      },
      [getAllUserRoles.fulfilled]: (state, action) => {
        state.loading = false;
        if (!_isEmpty(action.payload)) {
          action.payload.map((store)=>{
           if(!_isEmpty(store.roleName)){
           state.userRoleName = store.roleName;
           }
        })
      }
      }, 
      [getAllUserRoles.rejected]: (state, action) => {
        state.loading = false;
      },
    }
  });
  
  const { actions, reducer } = storeDashBoard;
  
  export const {
   storefilters
  } = actions;
  
  export default reducer;
