import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  getKMVehicleMap,
  getK1VehicleSpec,
  getK2VehicleSpecInfo
} from "./fluidsandfilters.service";

export const getVehicleMap = createAsyncThunk(
  "getVehicleMap",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getKMVehicleMap(payload, {
      cancelToken: source.token,
    });
    console.log("response vehicle mapping - km", response);
    return response.data;
  }
);

export const getVehicleSpec = createAsyncThunk(
  "getVehicleSpec",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getK1VehicleSpec(payload, {
      cancelToken: source.token,
    });
    console.log("response vehicle spec - k1", response);
    return response.data;
  }
);

export const getVehicleSpecInfo = createAsyncThunk(
  "getVehicleSpecInfo",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getK2VehicleSpecInfo(payload, {
      cancelToken: source.token,
    });
    console.log("response vehicle spec info - k2", response);
    return response.data;
  }
);
