/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import XSelect from "components/shared/XSelect";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "ui-library";
import { Link } from "react-router-dom";
import {
  Form,
  Image,
  Spinner,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import XModal from "../shared/XModal";
import "./styles.scss";
import { getBuyersGuideData } from "actions/parts";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import _, { isEmpty } from "lodash";
import {
  getStockOrderPartDetails,
  getInvoiceDetails,
  getAllLineCodes,
  getCategories,
  getROPDetails,
  getPartNumbers,
  getPartImages,
  stockOrderUpload,
  stockOrderPartAvailability,
  stockOrderHelpDocuments,
  stockOrderEmailROPToMe,
  stockOrderPreviewROP,
} from "./stockorder.thunk";
import { reset, setUploadFlag } from "./stockorder.slice";
import {
  getImageURLCookieDetails,
  distributeAvailability,
  SPECIAL_CHARACTERS,
  handleKeyDown,
} from "../../helpers/utils";
import OrderInvoicesPopUp from "../shared/OrderInvoices";
import _isEmpty from "lodash/isEmpty";
import { addItemToCart2 } from "../../actions/cart";
import AddToCartWarningPopup from "../shared/AddToCartWarningPopup/AddToCartWarningPopup";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import OrderAvailabilityPopUp from "../shared/OrderAvailablity";
import { triggerCustomUxCriticalError } from "../../actions/error";
import _cloneDeep from "lodash/cloneDeep";
import ImagesSlider from "../Listing/ImagesSlider";
import Dropdown, {
  ChevronDown,
  selectStyles,
  DropdownIndicator,
} from "./dropdown.component";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { AttachEmail, TableChart } from "@mui/icons-material";
import { createFilter } from "react-select";
import {
  getAllCustomers,
  impersonateUserBillTo,
} from "../User/UserManagement/usermanagement.thunk";
import { clearCart } from "../../actions/cart";
import { updateToken } from "../../actions/auth";
import { setVehicleSelected } from "../shared/MyGarage/mygarage.slice";
import { getPreferenceDataFromStorage } from "../../helpers/utils";
import OSRVisitFreequency from "./OsrVisitFreequency/index";
import PricingIcons from "components/Listing/PricingIcons";

const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const StockOrder = () => {
  const dispatch = useDispatch();
  const [showOrderInvoicesPopUp, SetShowOrderInvoicesPopUp] = useState(false);
  const [showOrderAvailabilityPopUp, SetShowOrderAvailabilityPopUp] =
    useState(false);

  const [invoiceData, SetInvoiceData] = useState([]);
  const orderType = useSelector((state) => state.cart.orderType);
  const qtyInCart = useSelector((state) => state.cart.qtyInCart);
  const stockRecords = useSelector((state) => state.stockOrder.stockRecords);
  const cart = useSelector((state) => state.cart);
  const cartStatus = useSelector((state) => state.cart);

  const [addToCartWarning, setAddToCartWarning] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [unmatchedData, setUnmatchedData] = useState([]);
  const [noStockOrders, setNoStockOrders] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [documentsAnchorEl, setDocumentsAnchorEl] = useState(null);
  const openDocumentsMenu = Boolean(documentsAnchorEl);
  const [qtyByIncPopup, setQtyByIncPopup] = useState(false);
  const [currentQtyByIncValue, setCurrentQtyByIncValue] = useState(0);

  const [showLoader, setshowLoader] = useState(true);
  const [showBillTOclearModal, setshowBillTOclearModal] = useState(false);
  const [showChangeCustomerBillTo, SetShowChangeCustomerBillTo] =
    useState(false);
  const [showBillTOModal, setshowBillTOModal] = useState(false);
  const [billTo, setbillTo] = useState("");
  const [buttonState, setbuttonState] = useState(false);
  const [superAdminUserList, setSuperAdminUserList] = useState([]);

  let billToStatus = useSelector((state) => state.userManagement.billToStatus);

  const resize = () => {
    if (window.innerWidth < 1025) {
      setShowImages(false);
    } else {
      setShowImages(true);
    }
  };

  const filterOptions = [
    {
      label: "Parts with Customer ROP",
      value: "customerROP",
    },
    {
      label: "Parts with No Customer ROP",
      value: "customerWithOutROP",
    },
    {
      label: "Parts with Customer Demand",
      value: "customerDemands",
    },
    {
      label: "Parts with No Customer Demand",
      value: "customerWithOutDemands",
    },
    {
      label: "Parts on Promotion now",
      value: "promotionParts",
    },
  ];

  const sortByOptions = [
    {
      label: "Line Part Number",
      value: "lineCode",
    },
    {
      label: "Customer Demand",
      value: "customerDemand",
    },
    {
      label: "Store Demand",
      value: "storeDemand",
    },
    {
      label: "Market Demand",
      value: "hubDemand",
    },
    {
      label: "Customer ROP Quantity",
      value: "rop",
    },
    {
      label: "Customer Last Invoice",
      value: "customerLastPurchase",
    },
    {
      label: "Unit Price (Low - High)",
      value: "unitPriceLowToHigh",
    },
    {
      label: "Unit Price (High - Low)",
      value: "unitPriceHighToLow",
    },
  ];

  const loadingStatus = Object.freeze({
    INITIAL: 0,
    LOADING: 1,
    LOADED: 2,
  });

  const all = [{ label: "All", value: null }];
  const searchInput = useRef(null);
  const [activeOrder, setActiveOrder] = useState(null);
  const [toggleBuyersGuide, setToggleBuyersGuide] = useState(false);

  const [bGuides, setBGuides] = useState([]);
  const stockOrder = useSelector((state) => state.stockOrder);
  const {
    stockOrders,
    categories,
    loading,
    isUpload,
    availabilities,
    documents,
    warningMessage,
  } = stockOrder;
  const lineCodes = stockOrder.lineCodes;

  const parts = useSelector((state) => state.parts);
  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;

  const [showImages, setShowImages] = useState(false);
  const [orders, setOrders] = useState(stockOrders);

  const [partSku, setPartSku] = useState(null);
  const [activeInvoice, SetActiveInvoice] = useState({});
  const [sortBy, setSortBy] = useState(sortByOptions[0]);
  const [filter, setFilter] = useState(filterOptions[0]);
  const [lineCodePayload, setLineCodePayload] = useState({
    categoryDetails: [],
  });
  const [showImagesSlider, setShowImagesSlider] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(all);
  const [selectedLineCodes, setSelectedLineCodes] = useState(lineCodes);
  const [selectedPartSKUs, setSelectedPartSKUs] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(25);

  const [fromMarket, setFromMarket] = useState(false);
  const [isLineCodeOpen, setIsLineCodeOpen] = useState(false);
  const [unmatched, setUnMatched] = useState(false);
  const stockOrderTemplate = useRef(null);

  const brands = parts.buyersGuideBrandDetails || [];
  const makes = parts.buyersGuideDetails || [];

  useEffect(() => {
    dispatch({
      payload: {
        globalLoader: !showLoader,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
  }, [showLoader]);

  useEffect(() => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    if (billToStatus === "failed") {
      statusMessageProps.heading = "BillTo modification Failed";
      statusMessageProps.type = "error";
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
    }
  }, [billToStatus]);

  useEffect(() => {
    if (user.userDetails.userType !== null) {
      let userPreferenceDetail = getPreferenceDataFromStorage();
      if (!_isEmpty(userPreferenceDetail)) {
        let grandAccessItemSet = userPreferenceDetail.find((item) => {
          return (
            item.text === "Personalize Linked Accounts" &&
            item.code === "User Management"
          );
        });

        if (grandAccessItemSet && grandAccessItemSet.multiAnswer) {
          grandAccessItemSet.multiAnswer.forEach((singleAnswer) => {
            if (singleAnswer.code === "enableUserEmulation") {
              if (
                user.userDetails.userType === "CUSTOMER_BUYER" ||
                user.userDetails.userType === "LOCATION_BUYER" ||
                user.userDetails.userType === "CUSTOMER_ADMIN"
              ) {
                SetShowChangeCustomerBillTo(true);
              }
            }
          });
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (
      stockRecords &&
      (stockRecords.unmatchedCount > 0 ||
        stockRecords.unmatchedRecords.length > 0)
    ) {
      setUnMatched(true);
    } else {
      setUnMatched(false);
    }
  }, [stockRecords]);
  useEffect(() => {
    const unmatchedData =
      stockRecords &&
      stockRecords.unmatchedRecords.filter((item, i) => item.partSKU !== "");
    setUnmatchedData(unmatchedData);
  }, [stockRecords]);

  const getPartImagesPayload = (partDetails) => {
    let imgReqItems = [];

    partDetails.forEach((part) => {
      let item = {
        partNumber: part.partSKU,
        supplier: part.mfgDesc,
        isChemicals: part.categoryCode === "Chemicals" ? true : false,
      };
      imgReqItems.push(item);
      if (part.alternate && part.alternate.length > 0) {
        part.alternate.forEach((alternatePart) => {
          let alternateItem = {
            partNumber: alternatePart.partSKU,
            supplier: alternatePart.mfgName || alternatePart.mfgDesc,
            isChemicals:
              alternatePart.categoryCode === "Chemicals" ? true : false,
          };
          imgReqItems.push(alternateItem);
        });
      }
      if (part.substitute && part.substitute.length > 0) {
        part.substitute.forEach((substitutePart) => {
          let substituteItem = {
            partNumber: substitutePart.partSKU,
            supplier: substitutePart.mfgName || substitutePart.mfgDesc,
            isChemicals:
              substitutePart.categoryCode === "Chemicals" ? true : false,
          };
          imgReqItems.push(substituteItem);
        });
      }
    });

    return imgReqItems;
  };

  // Initial Load
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getAllLineCodes(lineCodePayload));
    // dispatch(getPartNumbers({ searchTerm: "", data: getPayload() }));
    getHelpDocuments();

    resize();

    return () => {
      handleReset();
    };
  }, []);

  // on categories & line codes, fetch part skus
  useEffect(() => {
    const payload = getPayload();
    // dispatch(getPartNumbers({ searchTerm: "", data: payload }));
  }, [selectedCategories, selectedLineCodes]);

  useEffect(() => {
    setSelectedLineCodes(lineCodes);
  }, [lineCodes]);

  useEffect(() => {
    dispatch({
      payload: {
        globalLoader: loading,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
    let requestInfo = [];
    if (isUpload && stockRecords.unmatchedCount <= 0) {
      dispatch(setUploadFlag({ isUpload: false }));
      stockOrders.forEach((order) => {
        requestInfo = prepartRequestData(order.uploadedQty, order);
      });
      if (!_isEmpty(requestInfo)) {
        if (orderType !== "STOCK_ORDER" && qtyInCart > 0 && false) {
          setAddToCartWarning(true);
        } else {
          addItemToCart2(requestInfo, dispatch);
        }
      }
    }
  }, [loading, isUpload]);

  useEffect(() => {
    dispatch(getAllLineCodes(lineCodePayload));
  }, [lineCodePayload]);

  useEffect(() => {
    //if (cart.checkoutDetails && cart.checkoutDetails.length > 0) {
    setRequestData(_cloneDeep(cart.checkoutDetails));
    //}
  }, [cart.checkoutDetails]);

  const [stopLoad, setStopLoad] = useState(loadingStatus.INITIAL);
  useEffect(() => {
    setOrders(stockOrders);
    if (
      stockOrders &&
      stockOrders.length > 0 &&
      stopLoad === loadingStatus.LOADING
    ) {
      const payload = getPartImagesPayload(stockOrders);
      dispatch(getPartImages(payload));
      setStopLoad(
        stopLoad === loadingStatus.LOADING
          ? loadingStatus.LOADED
          : loadingStatus.LOADING
      );
    } else if (
      stockOrders.length === 0 &&
      partSku &&
      partSku !== "" &&
      !isReset
    ) {
      setStopLoad(
        stopLoad === loadingStatus.LOADING
          ? loadingStatus.LOADED
          : loadingStatus.LOADING
      );
    } else if (stockOrders.length === 0 && stopLoad === loadingStatus.LOADING) {
      setStopLoad(
        stopLoad === loadingStatus.LOADING
          ? loadingStatus.LOADED
          : loadingStatus.LOADING
      );
    }
  }, [stockOrders]);

  useEffect(() => {
    if (stockOrder.partImages && stockOrder.partImages.length > 0) {
      setOrders(stockOrders);
    }
  }, [stockOrder.partImages]);

  useEffect(() => {
    if (makes && makes.partGroup && makes.partGroup.length > 0) {
      const partGroups = makes.partGroup;
      const ymms = [];
      partGroups.forEach((g) => {
        const _g = {};
        _g.groupName = g.name;
        _g.fromYear = g.fromYear;
        _g.toYear = g.toYear;

        if (g.partDescriptions && g.partDescriptions.length > 0) {
          g.partDescriptions.forEach((d) => {
            _g.partDescription = d.name;

            if (d.makes && d.makes.length > 0) {
              let __g = {};

              d.makes.forEach((m) => {
                __g.make = m.makeName;

                if (m.models && m.models.length > 0) {
                  m.models.forEach((mo) => {
                    __g.model = mo.modelName;

                    if (mo.engines && mo.engines.length > 0) {
                      mo.engines.forEach((e) => {
                        __g.engine = e.engineName;
                        __g.fromYear = e.fromYear;
                        __g.toYear = e.toYear;

                        ymms.push(__g);
                        __g = {};
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });

      setBGuides(ymms);
    }
  }, [makes]);

  useEffect(() => {
    if (brands && brands.length > 0) {
      const __brand = {
        label: brands[0].manufacturer,
        value: brands[0].manufacturer,
      };
      handleBrandChange(__brand);
    }
  }, [brands]);

  const handleBrandChange = (brand) => {
    const { label } = brand;

    if (activeOrder) {
      getBuyersGuideData(activeOrder.partSKU, dispatch, label);
    }
  };

  const getHelpDocuments = () => {
    dispatch(stockOrderHelpDocuments());
  };

  const handlePreviewROP = () => {
    // dispatch(stockOrderPreviewROP(getPayload()));
    window.open(
      `preview-rop?customerCode=${userDetails.billTo.customerCode}&storeNum=${userDetails.shipTos[0].storeNumber}`,
      "_blank"
    );
  };

  useEffect(() => {
    if (stockOrder.EmailROPToMe) {
      const message = {
        heading: stockOrder.EmailROPToMe,
        message: "",
        type: "success",
      };

      triggerCustomUxCriticalError(message, dispatch);
    }
  }, [stockOrder.EmailROPToMe]);

  const handleROPChange = (e, p) => {
    const v = e.target.value;
    let ropValue = !_isEmpty(v) && Number(v) > 0 ? v : 0;
    const payload = {
      custCode: userDetails.billTo.customerCode,
      shipToNo: userDetails.shipTos[0].shipToId,
      line: p.lineCode,
      partNo: p.partSKU,
      rop: Number(ropValue),
    };
    const statusMessageProps = {
      heading: `ROP value updated for part# ${p.partSKU}`,
      message: "",
      type: "success",
    };
    try {
      const pattern = /[0-9]/;
      if (Number(p.rop) !== Number(v) && pattern.test(v)) {
        const getROPAction = dispatch(getROPDetails(payload));
        getROPAction.then((action) => {
          let matchingSKU = "";
          let orderData = _cloneDeep(orders);
          let updatatedData = [];
          orderData.forEach((order, index) => {
            if (order.partSKU === action.payload.partNo) {
              if (Number(ropValue) > 0) {
                order.rop = action.payload.rop;
                order.ropLastUpdatedUser = action.payload.userName;
                order.ropLastUpdatedDate = action.payload.lastUpdate;
              } else {
                matchingSKU = action.payload.partNo;
              }
            }
            updatatedData.push(order);
          });
          if (!_isEmpty(matchingSKU)) {
            updatatedData = updatatedData.filter(
              (order) => order.partSKU !== matchingSKU
            );
          }

          setOrders(updatatedData);
          if (action.payload) {
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
          }
        });
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const getMultipleOFQtyByINC = (value, qtyBuyInc) => {
    const remainder = Number(value) % Number(qtyBuyInc);
    if (remainder === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleQuantityChange = (e, p) => {
    const target = e.target;
    const value = target.value;
    let totalAvailqty = 0;
    p.availability &&
      p.availability.forEach((singleAvailability, key) => {
        if (singleAvailability.availableQty > 0) {
          totalAvailqty += singleAvailability.availableQty;
        }
      });
    const alreadyAdded = checkIsPartInCart(p);
    if (alreadyAdded > 0 && alreadyAdded === Number(value)) {
      return;
    }
    let remainingqtyforQtyInc = totalAvailqty - alreadyAdded;
    if (
      Number(p.qtyBuyInc) > 1 &&
      (totalAvailqty < Number(p.qtyBuyInc) ||
        remainingqtyforQtyInc < Number(p.qtyBuyInc))
    ) {
      return;
    }
    setCurrentQtyByIncValue(p.qtyBuyInc);
    if (Number(p.qtyBuyInc) > 1 && !getMultipleOFQtyByINC(value, p.qtyBuyInc)) {
      setQtyByIncPopup(true);
      return;
    }

    const pattern = /[0-9]/;
    if (!_isEmpty(value) && pattern.test(value) && Number(value) > 0) {
      const requestdata = prepartRequestData(value, p);

      if (orderType !== "STOCK_ORDER" && qtyInCart > 0) {
        setAddToCartWarning(true);
      } else {
        addItemToCart2(requestdata, dispatch);
      }
    }
  };

  const prepartRequestData = (value, p) => {
    let stocksData = [];
    stocksData = !isEmpty(orders) ? orders : stockOrders;
    let part = stocksData.find((order) => order.partSKU === p.partSKU);
    if (value && Number(value) > 0 && !isEmpty(stocksData)) {
      let orderArray = [];
      if (!isEmpty(part.availability)) {
        orderArray = distributeAvailability(value, part.availability);
      }
      let selectedIndex = _findIndex(requestData, {
        partNum: part.partSKU,
      });
      if (selectedIndex !== -1) {
        _remove(requestData, {
          partNum: part.partSKU,
        });
        //requestData[selectedIndex].qty = value;
      }
      orderArray.forEach((item) => {
        requestData.push({
          availabilityHour: item.availableDuration,
          availableQty: item.availableQty,
          cartFlag: "no",
          corePrice: part.corePrice,
          coreListPrice: part.coreListPrice,
          costPrice: part.costPrice,
          desc: part.partDesc,
          listPrice: part.listPrice,
          mfgCode: part.lineCode,
          qtyBuyInc: part.qtyBuyInc,
          partNum: part.partSKU,
          qty: item.orderQty,
          vehicleInfo: "Non-Vehicle Specific",
          shipMethod: userDetails.shipTos[0].shipMethod,
          store:
            userDetails &&
            userDetails.shipTos &&
            userDetails.shipTos[0] &&
            userDetails.shipTos[0].location &&
            userDetails.shipTos[0].location.storeNumber
              ? userDetails.shipTos[0].location.storeNumber
              : "",
          sellPartnerId:
            userDetails.shipTos &&
            userDetails.shipTos.length > 0 &&
            userDetails.shipTos[0].location &&
            userDetails.shipTos[0].location.sellerPartnerId &&
            userDetails.shipTos[0].location.sellerPartnerId
              ? userDetails.shipTos[0].location.sellerPartnerId
              : "",
          distStatus: "Completed",
          orderType: "STOCK_ORDER",
          toolTip: JSON.stringify(part.toolTip),
        });
        console.log("item=====>", part);
      });
    }
    return requestData;
  };

  const checkIsPartInCart = (part) => {
    let count = 0;
    let qty = 0;
    cart.cartAllDetails.forEach((item) => {
      if (item.partNum === part.partSKU && item.orderType === "STOCK_ORDER") {
        qty += item.qty;
        count = qty;
      }
    });
    return part.uploadedQty || count;
  };

  const handleUploadFile = (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0], event.target.files[0].name);
    data.append("customerCode", userDetails.billTo.customerCode);
    data.append("storeNum", userDetails.shipTos[0].storeNumber);
    dispatch(stockOrderUpload({ formData: data }));
  };

  const handleOrderInvoices = (data) => {
    const { lineCode, partSKU } = data;

    const payload = {
      billTo: userDetails.billTo.billToId,
      endDate: "",
      mfgCode: lineCode,
      partNum: partSKU,
      startDate: "",
      timeRange: false,
    };
    const str_payload = JSON.stringify(payload);

    try {
      const getInvoicesAction = dispatch(getInvoiceDetails(str_payload));
      getInvoicesAction.then((action) => {
        if (action.payload) {
          SetInvoiceData(action.payload);
          SetActiveInvoice(data);
          SetShowOrderInvoicesPopUp(true);
        }
      });
    } catch (error) {
      console.error("error", error);
    }
  };

  const closeInvoicesPopUp = () => {
    SetShowOrderInvoicesPopUp(false);
  };

  const handleOrderAvailabilityPopup = (order, bool) => {
    setFromMarket(bool);
    if (order) {
      setActiveOrder(order);
    }
    let availability = [];
    if (order.availability && order.availability.length > 0) {
      order.availability.forEach((a) => {
        a.storeAvailabilities.forEach((sa) => {
          const _sa = { store: sa.store, storeQty: sa.storeQty };

          availability.push(_sa);
        });
      });
    }
    const store = userDetails.shipTos[0].storeNumber;
    const defaultStore = availability.filter(
      (a) => Number(a.store) === Number(store)
    );

    if (defaultStore.length === 0) {
      availability.unshift({ store: Number(store).toString(), storeQty: 0 });
    }

    const payload = {
      partSKU: order.partSKU,
      lineCode: order.lineCode,
      availability,
    };

    dispatch(stockOrderPartAvailability(payload));

    SetShowOrderAvailabilityPopUp(!showOrderAvailabilityPopUp);
  };

  const handleToggleBuyersGuide = (order) => {
    const line = _.find(lineCodes, (l) => l.value === order.lineCode);
    if (order && order.partSKU && line) {
      getBuyersGuideData(order.partSKU, dispatch, line.mfgDesc);
      setActiveOrder(order);
    }

    setToggleBuyersGuide(!toggleBuyersGuide);
  };

  const handleReset = () => {
    setIsReset(true);
    setNoStockOrders(false);
    setSelectedCategories(all);
    setSelectedLineCodes(all);
    setSortBy(sortByOptions[0]);
    setFilter(filterOptions[0]);
    setSelectedPartSKUs([]);
    setCurrentPage(0);
    setStopLoad(loadingStatus.INITIAL);
    if (searchInput && searchInput.current) {
      searchInput.current.clear();
    }
    setPartSku("");

    dispatch(reset());
  };

  const getPayload = () => {
    //check is all present in selected line codes
    const isAll = selectedLineCodes.find((sel) => sel.label === "All");
    let lineCodeDetails = [];
    if (!isAll) {
      lineCodeDetails = selectedLineCodes.map((sl) => {
        if (sl.value) {
          return { lineCode: sl.value };
        } else {
          return null;
        }
      });
    }

    lineCodeDetails = lineCodeDetails.filter((l) => l);

    let categoryDetails = selectedCategories.map((sc) => {
      if (sc.value) {
        return { categoryDesc: sc.value };
      } else {
        return null;
      }
    });
    categoryDetails = categoryDetails.filter((c) => c);

    // eslint-disable-next-line array-callback-return
    let partSKUs = selectedPartSKUs.map((sp) => {
      const psku = { partSKU: sp.partNum };
      if (categoryDetails.length === 0 && lineCodeDetails.length === 0) {
        return psku;
      } else if (lineCodeDetails.length > 0) {
        psku.lineCode = sp.lineCode;
        return psku;
      } else {
        return psku;
      }
    });

    if (selectedPartSKUs.length === 0 && partSku && partSku !== "") {
      partSKUs = [{ partSKU: partSku }];
    }

    const payload = {
      customerCode: userDetails.billTo.customerCode,
      storeNum: userDetails.shipTos[0].storeNumber,
      partSKU: partSKUs.filter((sku) => sku), // clean is any null or undefined values in partSKUs
      categoryDetails: categoryDetails,
      lineCodeDetails: lineCodeDetails,
      filter: {
        customerROP: filter.value === "customerROP" ? true : false,
        customerDemands: filter.value === "customerDemands" ? true : false,
        promotionParts: filter.value === "promotionParts" ? true : false,
        customerWithOutROP:
          filter.value === "customerWithOutROP" ? true : false,
        customerWithOutDemands:
          filter.value === "customerWithOutDemands" ? true : false,
      },
      sortBy: sortBy.value,
      isSummary: false,
      dispatch,
    };

    return payload;
  };

  const handleApply = (cPage) => {
    setStopLoad(loadingStatus.LOADING);
    const payload = getPayload();
    dispatch(
      getStockOrderPartDetails({ payload, pageSize, pageNumber: cPage })
    );
  };

  const handleFocus = () => {};

  const handleSearch = (query) => {
    setPartSku(query);
    const payload = getPayload();
    // dispatch(getPartNumbers({ searchTerm: query, data: payload }));
  };

  const handleChange = (selected) => {
    setSelectedPartSKUs(selected);
  };

  const handlePartNum = (selected) => {
    setPartSku(selected.partNum);
    setSelectedPartSKUs([selected]);
    searchInput.current.toggleMenu();
  };
  const handleUnMatchedPopUpClose = () => {
    // stockOrderTemplate.current.value = null;
    // dispatch(stockOrderUpload({ formData: {} }));
    let requestInfo = [];
    if (isUpload) {
      dispatch(setUploadFlag({ isUpload: false }));
      stockOrders.forEach((order) => {
        requestInfo = prepartRequestData(order.uploadedQty, order);
      });
      if (!_isEmpty(requestInfo)) {
        if (orderType !== "STOCK_ORDER" && qtyInCart > 0) {
          setAddToCartWarning(true);
        } else {
          addItemToCart2(requestInfo, dispatch);
        }
      }
    }
    setUnMatched(false);
  };

  // eslint-disable-next-line no-unused-vars
  const renderMenu = (results, menuProps, state) => {
    return (
      <React.Fragment>
        <div className="part-numbers-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>Part #</th>
                <th>Description</th>
                <th>OnH</th>
                <th>YrDmd</th>
              </tr>
            </thead>
            <tbody>
              {stockOrder.partSKUsLoading ? (
                <tr>
                  <td
                    colSpan={4}
                    style={{ textAlign: "center", padding: "10px" }}
                  >
                    Loading...
                  </td>
                </tr>
              ) : stockOrder.partNumbers.length > 0 ? (
                stockOrder.partNumbers.map((p, i) => {
                  return (
                    <tr className="part-row" key={`partNum-${i}`}>
                      <td>
                        <Button
                          className="part-btn"
                          onClick={() => handlePartNum(p)}
                        >
                          {p.partNum}
                        </Button>
                      </td>
                      <td>{`${p.partTypeDesc} (${p.lineCode})`}</td>
                      <td>{p.onHand || 0}</td>
                      <td>{p.yearlyDemand || 0}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    style={{ textAlign: "center", padding: "10px" }}
                  >
                    No matching part SKUs found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  };

  const handleSortByChange = (option) => {
    setSortBy(option);
  };

  const handleFilterChange = (option) => {
    setFilter(option);
  };

  const handleCategoryChange = (selected) => {
    const p = [{ categoryDesc: selected.value }];
    setLineCodePayload({
      categoryDetails: selected && selected.value ? p : [],
    });
    setSelectedCategories([selected]);
    setStopLoad(loadingStatus.INITIAL);
    setSortBy(sortByOptions[0]);
    // setFilter(filterOptions[0]);
    setSelectedPartSKUs([]);
    setCurrentPage(0);
    setStopLoad(loadingStatus.INITIAL);
    if (searchInput && searchInput.current) {
      searchInput.current.clear();
    }
    setPartSku("");
  };

  const handleLineChange = (selected, single) => {
    const { option } = single;
    let selectedLineCodes = [];
    if (option.label === "All") {
      if (single.action === "deselect-option") {
        selectedLineCodes = [];
      } else {
        selectedLineCodes = lineCodes;
      }
    } else {
      selectedLineCodes = selected.filter((s) => {
        if (s.label !== "All") {
          return s;
        }
      });
    }

    setSelectedLineCodes(selectedLineCodes);
    setStopLoad(loadingStatus.INITIAL);
  };

  const handlePageChange = (clickedPage) => {
    const current = clickedPage - 1;
    setCurrentPage(current);
    handleApply(current);
  };

  const isSelectedMultiple = () => {
    const isFound = !!selectedLineCodes.find((s) => s.label === "All");

    return isFound;
  };

  const calculateAvailability = (order, type) => {
    let count = 0;
    const hub = userDetails.shipTos[0].location.mainLocation;
    if (order && order.availability && order.availability.length > 0) {
      order.availability.forEach((a) => {
        if (type === "store") {
          if (a.storeAvailabilities && a.storeAvailabilities.length > 0) {
            a.storeAvailabilities.forEach((s) => {
              if (
                Number(s.store) ===
                  Number(userDetails.shipTos[0].storeNumber) &&
                !(Number(s.store) > Number(hub))
              ) {
                count += s.storeQty;
              }
            });
          }
        } else {
          if (a.storeAvailabilities && a.storeAvailabilities.length > 0) {
            a.storeAvailabilities.forEach((s) => {
              if (!(Number(s.store) > Number(hub))) {
                count += s.storeQty;
              }
            });
          }
        }
      });
    }

    return type === "store" ? count : count ? count : "";
  };

  const handleEmailROPTOMe = () => {
    dispatch(stockOrderEmailROPToMe(getPayload()));
  };

  const handleDocuments = (event) => {
    setDocumentsAnchorEl(event.currentTarget);
  };

  const handleDocumentsClose = () => {
    setDocumentsAnchorEl(null);
  };
  const handleUpdate = () => {
    setshowLoader(false);
    if (
      cartStatus.qtyInCart > 0 &&
      (user.userDetails.userType === "SUPER_ADMIN" || showChangeCustomerBillTo)
    ) {
      setshowLoader(true);
      setshowBillTOclearModal(true);
    } else if (
      cartStatus.qtyInCart === 0 &&
      (user.userDetails.userType === "SUPER_ADMIN" || showChangeCustomerBillTo)
    ) {
      setshowLoader(false);
      changeBillTo();
    } else if (
      user.userDetails.userType === "IT_ADMIN" ||
      user.userDetails.userType === "OSR" ||
      user.userDetails.userType === "IT_INTERNAL" ||
      user.userDetails.userType === "DSM" ||
      user.userDetails.userType === "OPERATIONS_MANAGER"
    ) {
      const payload = {
        billTo,
        x2UserName: user.userDetails.x2userName,
        userType: user.userDetails.userType,
        buyPartnerCreationFlag: false,
        dispatch,
      };
      dispatch(impersonateUserBillTo(payload)).then((action) => {
        if (action.type === "impersonateUserBillTo/fulfilled") {
          setshowLoader(true);
          if (cartStatus.qtyInCart > 0) {
            setshowBillTOclearModal(true);
          } else {
            deleleteMethod();
          }
        } else if (action.payload.errorCode === "E1112") {
          setshowLoader(true);
          setshowBillTOModal(true);
        }
        setshowLoader(true);
      });
    }
  };
  const changeBillTo = () => {
    setshowBillTOclearModal(false);
    const payload = {
      billTo: billTo.value,
      x2UserName: user.userDetails.x2userName,
      userType: user.userDetails.userType,
      buyPartnerCreationFlag: true,
      dispatch,
    };
    dispatch(impersonateUserBillTo(payload)).then((action) => {
      setbillTo("");
      updateToken(dispatch).then(() => {
        let statusMessageProps = {
          heading: "",
          message: "",
          type: "",
        };
        if (action.payload === "Change BillTo successful") {
          dispatch(getAllCustomers()).then((result) => {
            setSuperAdminUserList(result.payload);
          });
          handleReset();
          statusMessageProps.heading = "BillTo modified successfully";
          statusMessageProps.type = "success";
          triggerCustomUxCriticalError(statusMessageProps, dispatch);
          setbillTo("");
          setshowBillTOModal(false);
          setshowBillTOclearModal(false);
          setshowLoader(true);
          dispatch(setVehicleSelected(null));
          dispatch({
            payload: {
              selectedVehicle: null,
            },
            type: "VEHICLE_SELECTION_COMPLETED",
          });
        }
      });
      setshowLoader(true);
    });
  };

  const deleleteMethod = async () => {
    if (
      user.userDetails.userType === "SUPER_ADMIN" ||
      showChangeCustomerBillTo
    ) {
      setshowLoader(true);
      changeBillTo();
    }
    if (cartStatus.qtyInCart > 0) {
      await clearCart(dispatch);
    }
    if (
      user.userDetails.userType === "IT_ADMIN" ||
      user.userDetails.userType === "OSR" ||
      user.userDetails.userType === "IT_INTERNAL" ||
      user.userDetails.userType === "DSM" ||
      user.userDetails.userType === "OPERATIONS_MANAGER"
    ) {
      setTimeout(() => {
        updateToken(dispatch).then(() => {
          let statusMessageProps = {
            heading: "",
            message: "",
            type: "",
          };
          if (
            billToStatus === "success" ||
            billToStatus.payload?.errorCode === "E1112"
          ) {
            handleReset();
            statusMessageProps.heading = "BillTo modified successfully";
            statusMessageProps.type = "success";
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
            setbillTo("");
            setshowBillTOModal(false);
            setshowBillTOclearModal(false);
            setshowLoader(true);
            dispatch(setVehicleSelected(null));
            dispatch({
              payload: {
                selectedVehicle: null,
              },
              type: "VEHICLE_SELECTION_COMPLETED",
            });
          } else if (billToStatus === "failed") {
            statusMessageProps.heading = "BillTo modification Failed";
            statusMessageProps.type = "error";
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
          }
        });
      }, 2000);
    }
  };
  const handleUpdatecreate = () => {
    const payload = {
      billTo,
      x2UserName: user.userDetails.x2userName,
      userType: user.userDetails.userType,
      buyPartnerCreationFlag: true,
      dispatch,
    };
    setshowLoader(false);
    dispatch(impersonateUserBillTo(payload)).then((action) => {
      if (action.type === "impersonateUserBillTo/fulfilled") {
        setshowLoader(true);
        if (cartStatus.qtyInCart > 0) {
          setshowBillTOclearModal(true);
        } else {
          setshowBillTOModal(false);
          deleleteMethod();
        }
        //deleleteMethod();
      }
      setshowLoader(true);
    });
    setshowBillTOModal(false);
  };

  window.addEventListener("resize", resize);

  return (
    <React.Fragment>
      <div className="stock-order">
        <div className="so-container">
          <div className="stock_check_wrapper">
            <div className="bread_crum_section">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>Stock Order</span>
                </li>
              </ul>
            </div>
            <div className="file_chooser_template">
              <div className="billto-wrapper-stockorder">
                <div>
                  <span>Import file containing stockorder list</span>
                  <div className="import_file_section">
                    <input
                      type="file"
                      onChange={handleUploadFile}
                      accept=".xlsx, .xls"
                      ref={stockOrderTemplate}
                    />
                  </div>
                </div>
                <div>
                  <div className="billto-wrapper">
                    <OSRVisitFreequency
                      dispatch={dispatch}
                      userDetails={userDetails}
                      fetchStockOrders={() => {
                        // fetch stock orders only when there are already stock orders in the list.
                        if (orders && orders.length > 0) {
                          setOrders([]);
                          handleApply(0);
                        }
                      }}
                    />
                    {user.userDetails &&
                      (user.userDetails.userType === "IT_ADMIN" ||
                        user.userDetails.userType === "IT_INTERNAL" ||
                        user.userDetails.userType === "OPERATIONS_MANAGER" ||
                        user.userDetails.userType === "OSR" ||
                        user.userDetails.userType === "DSM") && (
                        <div className="billto-controls">
                          <div>
                            <label htmlFor="currentPassword">
                              Change BillTo
                            </label>
                          </div>
                          <div className="billto-formcontrols">
                            <Form.Control
                              type="text"
                              onChange={(event) =>
                                setbillTo(event.target.value)
                              }
                              name="currentPassword"
                              id="currentPassword"
                              value={billTo}
                            />
                            {showLoader ? (
                              <Button
                                onClick={handleUpdate}
                                disabled={_isEmpty(billTo)}
                                className="billto-button ok-button"
                                variant="contained"
                              >
                                ok
                              </Button>
                            ) : (
                              <Button
                                className="billto-button ok-button"
                                variant="contained"
                                disabled
                              >
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Loading...
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    <div className="billto-details-wrapper">
                      <Row>
                        <div>
                          <span className="user_management_label_color">
                            XL Parts Account:
                          </span>{" "}
                          <span className="">
                            {" "}
                            {user.userDetails && user.userDetails.billTo
                              ? `${user.userDetails.billTo.billToId} - ${user.userDetails.billTo.name}`
                              : ``}
                          </span>
                        </div>
                      </Row>
                      {!_isEmpty(user) &&
                        !_isEmpty(user.userDetails) &&
                        !_isEmpty(user.userDetails.shipTos) &&
                        !_isEmpty(user.userDetails.shipTos[0]) && (
                          <React.Fragment>
                            <Row>
                              <div>
                                <span className="user_management_label_color">
                                  ShipTo:
                                </span>{" "}
                                <span>{`${user.userDetails.shipTos[0].shipToId} - ${user.userDetails.shipTos[0].name}`}</span>
                              </div>
                            </Row>
                            <Row>
                              <div>
                                <span className="user_management_label_color">
                                  ShipTo Address:{" "}
                                </span>
                                <span>
                                  {`${user.userDetails.shipTos[0].address}, ${user.userDetails.shipTos[0].city} ,`}
                                </span>
                                <span>
                                  {`${user.userDetails.shipTos[0].state} , ${user.userDetails.shipTos[0].zip}`}
                                </span>
                              </div>
                            </Row>
                          </React.Fragment>
                        )}
                    </div>
                  </div>

                  {showBillTOModal && (
                    <XModal
                      show={showBillTOModal}
                      showButtons={false}
                      title="Create Impersonate User"
                      className="change_shipTo_save_quote_modal"
                      handleClose={() => setshowBillTOModal(false)}
                    >
                      <div className="change_shipTo_modal_heading">
                        <span className="shipTo_modal_text">
                          This Customer is not yet registered in RAPID. Do you
                          want to really proceed?
                        </span>
                      </div>
                      <div className="shipTo_modal_button_padding">
                        <span className="shipTo_clear_cart_button">
                          {showLoader ? (
                            <Button
                              className="add_to_cart_button"
                              disable={buttonState && !_isEmpty(billTo)}
                              onClick={handleUpdatecreate}
                              variant="contained"
                            >
                              YES
                            </Button>
                          ) : (
                            <Button
                              className="user-management-submit-btn"
                              variant="contained"
                              disabled
                            >
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Loading...
                            </Button>
                          )}
                        </span>
                        <span className="shipTo_save_as_quote_button">
                          <Button
                            className="add_to_cart_button"
                            onClick={() => setshowBillTOModal(false)}
                            variant="contained"
                          >
                            NO
                          </Button>
                        </span>
                      </div>
                    </XModal>
                  )}
                  {showBillTOclearModal && (
                    <XModal
                      show={showBillTOclearModal}
                      showButtons={false}
                      title="Create Impersonate User"
                      className="change_shipTo_save_quote_modal"
                      handleClose={() => setshowBillTOclearModal(false)}
                    >
                      <div className="change_shipTo_modal_heading">
                        <span className="shipTo_modal_text">
                          Changing BillTo will empty your Cart. Do you really
                          want to proceed?
                        </span>
                      </div>
                      <div className="shipTo_modal_button_padding">
                        <span className="shipTo_clear_cart_button">
                          {showLoader ? (
                            <Button
                              className="add_to_cart_button"
                              disable={buttonState && !_isEmpty(billTo)}
                              onClick={deleleteMethod}
                              variant="contained"
                            >
                              YES
                            </Button>
                          ) : (
                            <Button
                              className="user-management-submit-btn"
                              variant="contained"
                              disabled
                            >
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Loading...
                            </Button>
                          )}
                        </span>
                        <span className="shipTo_save_as_quote_button">
                          <Button
                            className="add_to_cart_button"
                            onClick={() => setshowBillTOclearModal(false)}
                            variant="contained"
                          >
                            NO
                          </Button>
                        </span>
                      </div>
                    </XModal>
                  )}
                </div>
              </div>
              <div className="upload-actions-row">
                <div className="action-row-left">
                  <a
                    href={`${
                      process.env.REACT_APP_STOCKORDER_QTY_TEMPLATE
                    }?${getImageURLCookieDetails()}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download template for Qty
                  </a>
                  <a
                    href={`${
                      process.env.REACT_APP_STOCKORDER_ROP_TEMPLATE
                    }?${getImageURLCookieDetails()}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download template for ROP
                  </a>
                </div>
                <div className="action-row-right">
                  <FormGroup>
                    <FormControlLabel
                      className="show-images-label"
                      control={<Switch checked={showImages} />}
                      onChange={(e) => {
                        setShowImages(e.target.checked);
                      }}
                      label="Show Images"
                    />
                  </FormGroup>
                  <Button
                    id="basic-button"
                    aria-controls={openDocumentsMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openDocumentsMenu ? "true" : undefined}
                    onClick={handleDocuments}
                    endIcon={<ChevronDown />}
                  >
                    Documents
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={documentsAnchorEl}
                    open={openDocumentsMenu}
                    onClose={handleDocumentsClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    style={{ minWidth: "36.5px" }}
                  >
                    {documents &&
                      documents.map((doc) => {
                        return (
                          <MenuItem onClick={handleDocumentsClose}>
                            <a
                              href={
                                doc.linkURL &&
                                `${
                                  doc.linkURL.url
                                }?${getImageURLCookieDetails()}`
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <ListItemText>{doc.heading}</ListItemText>
                            </a>
                          </MenuItem>
                        );
                      })}
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <div className="stoc_check_header">
            <span>Stock Order</span>
            <div>
              <div>
                Customer: {userDetails.billTo.billToId} - {userDetails.userType}
              </div>
              <div>
                SHIP TO #: {userDetails.shipTos[0].shipToId} -{" "}
                {userDetails.shipTos[0].name}
              </div>
            </div>
          </div>

          <div className="row my-3">
            <div className="col col-md-4 col-lg-2">
              <div className="label">Categories</div>
              <XSelect
                customOption
                className="custom-stock-order-select"
                placeholder="Select Categories"
                options={categories}
                defaultValue={selectedCategories}
                type="checkbox"
                onChange={handleCategoryChange}
                value={selectedCategories}
              />
            </div>

            <div className="col col-md-4 col-lg-2">
              <div className="label">Line</div>
              <Dropdown
                isOpen={isLineCodeOpen}
                onClose={() => setIsLineCodeOpen(false)}
                target={
                  <Button
                    variant="contained"
                    endIcon={<ChevronDown />}
                    onClick={() => setIsLineCodeOpen((prev) => !prev)}
                    isSelected={isLineCodeOpen}
                    className="line-btn"
                  >
                    <span className="line-text">
                      {isSelectedMultiple()
                        ? `All`
                        : selectedLineCodes.length > 1
                        ? "Selected: Multiple"
                        : selectedLineCodes && selectedLineCodes.length === 1
                        ? `${selectedLineCodes[0].label}`
                        : "Select Line"}
                    </span>
                  </Button>
                }
              >
                <XSelect
                  autoFocus
                  isMulti
                  placeholder="Select Line"
                  options={lineCodes}
                  customOption
                  type="checkbox"
                  onChange={handleLineChange}
                  value={selectedLineCodes}
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  controlShouldRenderValue={false}
                  isClearable={false}
                  menuIsOpen
                  styles={selectStyles}
                  xcomponents={{ DropdownIndicator, IndicatorSeparator: null }}
                  filterOption={createFilter({ matchFrom: "start" })}
                />
              </Dropdown>
            </div>
            <div className="col col-md-4 col-lg-2">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="label">Search Part #</Form.Label>
                <TextField
                  onChange={(e) => setPartSku(e.target.value)}
                  fullWidth
                  size="small"
                  id="part__search"
                  className="part_input__search"
                  placeholder="Part #"
                  onBlur={(e) => {
                    var v = e.target.value;
                    if (v.length === 0) {
                      setPartSku(null);
                    }
                  }}
                  onKeyDown={(e) => {
                    var a = e.key;
                    if (SPECIAL_CHARACTERS.indexOf(a) > -1) {
                      e.preventDefault();
                    }
                  }}
                />
                {/* <AsyncTypeahead
                  useCache={false}
                  onFocus={handleFocus}
                  clearButton={true}
                  ref={searchInput}
                  inputProps={{ className: "part_input__search" }}
                  id="part__search"
                  onSearch={_.debounce(handleSearch, 0)}
                  placeholder="Part #"
                  onChange={handleChange}
                  onBlur={(e) => {
                    var v = e.target.value;
                    if (v.length === 0) {
                      setPartSku(null);
                    }
                  }}
                  onKeyDown={(e) => {
                    var a = e.key;
                    if (SPECIAL_CHARACTERS.indexOf(a) > -1) {
                      e.preventDefault();
                    }
                  }}
                  // renderMenu={renderMenu}
                  labelKey={(option) => option.partNum}
                  minLength={0}
                  selected={selectedPartSKUs}
                /> */}
              </Form.Group>
            </div>

            <div className="col col-md-4 col-lg-2">
              <div className="label">Filter</div>
              <XSelect
                className="custom-stock-order-select"
                placeholder="Select Filter"
                options={filterOptions}
                value={filter}
                type="checkbox"
                onChange={handleFilterChange}
              />
            </div>
            <div className="col col-md-4 col-lg-2">
              <div className="label">Sort</div>
              <XSelect
                className="custom-stock-order-select"
                placeholder="Select Sort"
                options={sortByOptions}
                value={sortBy}
                type="checkbox"
                onChange={handleSortByChange}
              />
            </div>

            <div className="col col-md-auto d-flex col-lg-2">
              <div className="actions">
                <div className="action">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleApply(0)}
                  >
                    Apply
                  </Button>
                </div>
                <div className="action">
                  <Button variant="contained" onClick={handleReset}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <React.Fragment>
                {!loading &&
                  stopLoad === loadingStatus.LOADED &&
                  orders &&
                  orders.length === 0 && (
                    <div className="so-no-records">
                      {warningMessage ? (
                        <div className="warning-msg">
                          Your search has too many results. Please select
                          specific filters in the Category, Line, or Part # drop
                          down lists.
                        </div>
                      ) : (
                        <div>No matching records found!</div>
                      )}
                    </div>
                  )}
              </React.Fragment>
              <React.Fragment>
                {orders && orders.length > 0 && (
                  <React.Fragment>
                    <div className="customer-rop-actions">
                      <div className="left">
                        {`Displaying few parts for the above selected criteria. (please filter by giving specific category, line, part#)`}
                      </div>
                      <div className="right">
                        {/* <FormGroup>
                          <FormControlLabel
                            className="show-images-label"
                            control={<Switch checked={showImages} />}
                            onChange={(e) => {
                              setShowImages(e.target.checked);
                            }}
                            label="Show Images"
                          />
                        </FormGroup> */}
                        {filter && filter.value === "customerROP" && (
                          <React.Fragment>
                            <Tooltip title="Preview ROP" placement="top">
                              <IconButton
                                color="primary"
                                onClick={handlePreviewROP}
                              >
                                <TableChart />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Email ROP to Me" placement="top">
                              <IconButton
                                color="primary"
                                onClick={handleEmailROPTOMe}
                              >
                                <AttachEmail />
                              </IconButton>
                            </Tooltip>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <table className="table">
                      <div className="table-stock-order-responsive">
                        <tr className="table-stock-order-responsive_1">
                          <th rowSpan="2" className="th-first-col">
                            Part #
                          </th>
                          <th rowSpan="2">Unit Price</th>
                          <th colSpan="3">Year Demand</th>
                          <th rowSpan="2">Last Invoice</th>
                          <th rowSpan="2" className="qty-col">
                            <div>ROP (max)</div>
                            <div>lowest uom</div>
                          </th>
                          <th rowSpan="2" className="qty-col">
                            <div>QTY (cart)</div>
                            <div>lowest uom</div>
                          </th>
                        </tr>
                        <tr className="table-stock-order-responsive_1">
                          <th>
                            <div>Market</div>
                            <div>
                              DC {userDetails.shipTos[0].location.mainLocation}
                            </div>
                          </th>
                          <th>
                            <div>Store</div>
                            <div>{userDetails.shipTos[0].storeNumber}</div>
                          </th>
                          <th>Cust</th>
                        </tr>
                      </div>
                      <tbody className="stockorder-tbody">
                        {orders &&
                          orders.map((order, index) => {
                            return (
                              <tr>
                                <td>
                                  <div className="part-desc">
                                    {showImages && (
                                      <div className="product_img">
                                        <div className="product-image-container">
                                          <div className="product-image-wrapper">
                                            <Image
                                              className={
                                                "image-thumbnail cursor-pointer"
                                              }
                                              src={
                                                order.image
                                                  ? `${
                                                      order.image
                                                    }?${getImageURLCookieDetails()}`
                                                  : "/noImage.jpg"
                                              }
                                              onClick={() => {
                                                if (order.image) {
                                                  setActiveOrder(order);
                                                  setShowImagesSlider(true);
                                                }
                                              }}
                                              fluid
                                            />
                                          </div>
                                          {order.images &&
                                            order.images.length > 0 && (
                                              <a
                                                className="link"
                                                onClick={() => {
                                                  if (order.images.length) {
                                                    setActiveOrder(order);
                                                    setShowImagesSlider(true);
                                                  }
                                                }}
                                              >
                                                See More ({order.images.length})
                                              </a>
                                            )}
                                        </div>
                                      </div>
                                    )}

                                    <div className="text-center first-col">
                                      <div className="line-1">
                                        {order.partDesc}{" "}
                                        {order.toolTip && (
                                          <PricingIcons
                                            toolTips={order.toolTip}
                                          />
                                        )}
                                      </div>
                                      <div className="line-2">
                                        {order.lineCode} {order.partSKU}
                                      </div>
                                      <div className="line-3">
                                        <div>{order.desc1}</div>
                                        <div>{order.superSeeded}</div>
                                        <div>{order.desc3}</div>
                                      </div>
                                      <div className="line-3">
                                        <Button
                                          className="guide-button"
                                          onClick={() =>
                                            handleToggleBuyersGuide(order)
                                          }
                                        >
                                          Buyers Guide
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>{`$${Number(order.costPrice).toFixed(
                                  2
                                )}`}</td>
                                <td>
                                  {/* {'N/A' || order.hubDemand} */}
                                  {order.hubDemand}
                                  {order.availability &&
                                  order.availability.length > 0 &&
                                  calculateAvailability(order) > 0 ? (
                                    <div className="available-wrapper">
                                      <Button
                                        className="avail-link maroonColorTxt info-icon"
                                        onClick={() =>
                                          handleOrderAvailabilityPopup(
                                            order,
                                            true
                                          )
                                        }
                                      >
                                        {calculateAvailability(order)}
                                        <span className="available-text">
                                          Available
                                        </span>
                                      </Button>
                                    </div>
                                  ) : (
                                    <div className="available-wrapper">
                                      <Button
                                        className="no-avail-link maroonColorTxt info-icon"
                                        onClick={() =>
                                          handleOrderAvailabilityPopup(
                                            order,
                                            true
                                          )
                                        }
                                      >
                                        N/A
                                      </Button>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {/* {'N/A' || order.storeDemand} */}
                                  {order.storeDemand}
                                  {order.availability &&
                                  order.availability.length > 0 &&
                                  calculateAvailability(order, "store") > 0 ? (
                                    <div className="available-wrapper">
                                      <Button
                                        className="avail-link maroonColorTxt info-icon"
                                        onClick={() =>
                                          handleOrderAvailabilityPopup(
                                            order,
                                            false
                                          )
                                        }
                                      >
                                        {calculateAvailability(order, "store")}
                                        <span className="available-text">
                                          Available
                                        </span>
                                      </Button>
                                    </div>
                                  ) : (
                                    <div className="available-wrapper">
                                      <Button
                                        className="no-avail-link maroonColorTxt info-icon"
                                        onClick={() =>
                                          handleOrderAvailabilityPopup(
                                            order,
                                            false
                                          )
                                        }
                                      >
                                        N/A
                                      </Button>
                                    </div>
                                  )}
                                </td>
                                <td>{order.customerDemand}</td>
                                <td>
                                  {order.invoiceDetails &&
                                  order.invoiceDetails.length > 0 &&
                                  order.invoiceDetails[0].latestInvoiceDate !==
                                    "null" &&
                                  order.invoiceDetails[0].quantity !==
                                    "null" ? (
                                    <React.Fragment>
                                      <div
                                        className="lastInvoiceDate"
                                        onClick={() =>
                                          handleOrderInvoices(order)
                                        }
                                      >
                                        {order.invoiceDetails &&
                                        order.invoiceDetails.length > 0 &&
                                        order.invoiceDetails[0]
                                          .latestInvoiceDate !== "null"
                                          ? order.invoiceDetails[0]
                                              .latestInvoiceDate
                                          : "-"}
                                      </div>
                                      <div>
                                        QTY:{" "}
                                        {order.invoiceDetails &&
                                        order.invoiceDetails.length > 0
                                          ? order.invoiceDetails[0].quantity
                                          : "-"}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    "-"
                                  )}
                                </td>

                                <td className="text-center">
                                  <input
                                    key={order.rop}
                                    className="inputs"
                                    defaultValue={order.rop}
                                    placeholder="ROP (max)"
                                    onBlur={(e) => handleROPChange(e, order)}
                                    onKeyDown={(event) => {
                                      handleKeyDown(event);
                                    }}
                                  />
                                  <div>
                                    <span>Sugg: </span> {order.suggestedROP}
                                  </div>
                                  <React.Fragment>
                                    {order.ropLastUpdatedUser &&
                                      order.ropLastUpdatedDate && (
                                        <div>
                                          <div>
                                            {order.ropLastUpdatedUser || ""}{" "}
                                          </div>
                                          <div>
                                            {moment(
                                              order.ropLastUpdatedDate
                                            ).format("DD-MMM-YYYY") || ""}
                                          </div>
                                        </div>
                                      )}
                                  </React.Fragment>
                                </td>
                                <td className="text-center">
                                  <input
                                    key={checkIsPartInCart(order)}
                                    tabIndex={index + 1}
                                    defaultValue={checkIsPartInCart(order)}
                                    className="inputs"
                                    placeholder="Enter Qty"
                                    onBlur={(e) =>
                                      handleQuantityChange(e, order)
                                    }
                                    onKeyDown={(event) => {
                                      handleKeyDown(event);
                                    }}
                                    disabled={
                                      order.availability &&
                                      order.availability.length === 0
                                    }
                                  />
                                  <div>
                                    <span>Sugg: </span> {order.suggestedQty}{" "}
                                    <br />
                                    <span>QtyByInc: </span> {order.qtyBuyInc}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </React.Fragment>
                )}
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
      {showOrderInvoicesPopUp ? (
        <OrderInvoicesPopUp
          openPopup={showOrderInvoicesPopUp}
          selectedInvoice={activeInvoice}
          closePopup={() => closeInvoicesPopUp()}
          data={invoiceData || []}
          activeInvoice={activeInvoice}
        />
      ) : null}

      {showOrderAvailabilityPopUp && activeOrder ? (
        <OrderAvailabilityPopUp
          openPopup={showOrderAvailabilityPopUp}
          closePopup={() => {
            setActiveOrder(null);
            SetShowOrderAvailabilityPopUp(false);
          }}
          partNo={activeOrder.partSKU}
          data={availabilities || []}
          activeOrder={activeOrder}
          fromMarket={fromMarket}
          market={userDetails.shipTos[0].location.mainLocation}
          store={userDetails.shipTos[0].storeNumber}
        />
      ) : null}

      <XModal
        title="Buyers Guide"
        showButtons={false}
        show={toggleBuyersGuide}
        handleClose={handleToggleBuyersGuide}
      >
        <div>{`Part ${
          activeOrder && activeOrder.partSKU
        } fits for vehicles:`}</div>
        <table className="table">
          <thead>
            <tr>
              <th>Make</th>
              <th>Model</th>
              <th>Engine</th>
              <th>Year</th>
            </tr>
          </thead>
          <tbody>
            {bGuides.map((b) => {
              return (
                <tr>
                  <td>{b.make}</td>
                  <td>{b.model}</td>
                  <td>{b.engine}</td>
                  <td>
                    {b.fromYear}-{b.toYear}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </XModal>

      {addToCartWarning ? (
        <AddToCartWarningPopup
          openPopup={addToCartWarning}
          selectedAvailabilityHours={requestData}
          source={"stockorder"}
          orderType={"STOCK_ORDER"}
          DefaultAddItemToCart={true}
          closePopup={() => {
            setAddToCartWarning(false);
          }}
        />
      ) : null}

      {showImagesSlider ? (
        <ImagesSlider
          openPopup={showImagesSlider}
          closePopup={() => {
            setShowImagesSlider(false);
          }}
          partDetails={activeOrder}
          setShowImagesSlider={setShowImagesSlider}
        />
      ) : null}
      <XModal
        className="unmatched-invoices"
        title="Unmatched Invoices"
        show={unmatched}
        showButtons={false}
        handleClose={() => handleUnMatchedPopUpClose()}
      >
        <div style={{ textAlign: "center" }}>
          <div className="msg">{`There are ${stockRecords.unmatchedCount} / ${stockRecords.totalCount} unmatched records.`}</div>
          <div className="unmatched-list">
            {stockRecords.unmatchedRecords &&
              unmatchedData &&
              unmatchedData.map((unMatched, i) => {
                return (
                  <div className="item">
                    {unMatched.partSKU}
                    {unmatchedData.length - 1 !== i && `, `}{" "}
                  </div>
                );
              })}
          </div>
          <Button variant="contained" onClick={handleUnMatchedPopUpClose}>
            Ok
          </Button>
        </div>
      </XModal>

      <XModal
        className="no-matching-records"
        title={`No Matching Records`}
        show={noStockOrders}
        showButtons={false}
        handleClose={() => setNoStockOrders(false)}
      >
        <div className="no-matching-parts">
          <div>No matching records found for the part SKU: #{partSku}</div>
          <div>
            <Button variant="contained" onClick={() => setNoStockOrders(false)}>
              Ok
            </Button>
          </div>
        </div>
      </XModal>
      {qtyByIncPopup && (
        <XModal
          className="no-matching-records"
          title="Warning"
          show={qtyByIncPopup}
          showButtons={false}
          handleClose={() => setQtyByIncPopup(false)}
        >
          <div className="no-matching-parts">
            <div>
              You must enter a QTY in multiples of {currentQtyByIncValue}
            </div>
            <div>
              <Button
                variant="contained"
                onClick={() => setQtyByIncPopup(false)}
              >
                Ok
              </Button>
            </div>
          </div>
        </XModal>
      )}
    </React.Fragment>
  );
};

export default StockOrder;
