/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from "react";
import "./styles.scss";
import { benefitDetails } from "./../programbenefits.thunk";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isEmpty } from "lodash";
import {
  currencyFormatter,
  formatAmount,
  formatMonth,
} from "../../../helpers/utils";
import moment from "moment";

import XCharts from "components/Charts";
const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const OnlineOrderRewards = (props) => {
  let { programName } = props;
  const [pieData, setPieData] = useState([]);
  const dispatch = useDispatch();
  const {
    loading = false,
    benefitData: {
      rebateDetails,
      totalOnlineSales,
      totalRebateCredited,
      ytdRebatesDetails,
      totalSales,
    },
  } = useSelector((state) => state.ProgramAndBenefits);
  const [rebateData, setRebateData] = useState([]);
  const [onlineRebateData, setOnlineRebateData] = useState(null);
  const currentYear = moment().year().toString();

  let totalOnlineSalesYTD = ytdRebatesDetails?.find(
    (m) => m.year === currentYear
  );
  totalOnlineSalesYTD = totalOnlineSalesYTD
    ? totalOnlineSalesYTD.totalOnlineSalesYTD
    : 0;
  let totalRebateYTD = ytdRebatesDetails?.find((m) => m.year === currentYear);
  totalRebateYTD = totalRebateYTD ? totalRebateYTD.totalRebateYTD : 0;

  let onlinePercentage = ytdRebatesDetails?.find((m) => m.year === currentYear);
  onlinePercentage = onlinePercentage ? onlinePercentage.onlinePercentage : 0;

  let totalSalesYTD = ytdRebatesDetails?.find((m) => m.year === currentYear);
  totalSalesYTD = totalSalesYTD ? totalSalesYTD.totalSalesYTD : 0;
  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;

  useEffect(() => {
    if (!isEmpty(rebateDetails)) {
      setRebateData(rebateDetails);
      getPieData();
    }
    dispatch({
      payload: {
        globalLoader: loading,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
  }, [rebateDetails, loading]);

  const getAllDetails = () => {
    const { xlPartAccountNumber } = userDetails || {};
    let customerNumber = xlPartAccountNumber;
    const payload = {
      name: "onlineOrderingRebate",
      customerId: customerNumber,
      noOfMonths: 12,
    };
    dispatch(benefitDetails(payload)).then((res) => {
      if (res && res?.payload) {
        setOnlineRebateData(res.payload);
      }
    });
  };
  useEffect(() => {
    if (!isEmpty(programName) && programName === "OnlineOrderingRebate") {
      getAllDetails();
    }
  }, []);

  const getPieData = () => {
    const data = [
      {
        id: "others",
        name: "Others",
        value: totalSalesYTD - totalOnlineSalesYTD || 0,
      },
      {
        id: "total-online-sales",
        name: "Total Online Sales",
        value: totalOnlineSalesYTD,
      },
    ].map((r, i) => ({
      id: r.name,
      label: r.name,
      value: Number(r.value),
    }));

    setPieData(data);
  };

  return (
    <>
      {loading ? (
        <div className="custom_spinner_container"></div>
      ) : (
        <div className="online-rebate-wrapper">
          {rebateData && rebateData.length > 0 && (
            <div className="chart-tile-wrapper">
              <div className="order-rebate-card">
                <div className="order-rebate-title">
                  Total Online Sales (YTD)
                </div>
                <div className="order-rebate-content">
                  <div className="order-rebate-text ">
                    {(totalOnlineSalesYTD &&
                      // onlineRebateData?.totalOnlineSales &&
                      currencyFormatter(totalOnlineSalesYTD)) ||
                      currencyFormatter(0)}
                  </div>
                </div>
              </div>
              <div className="order-rebate-card">
                <div className="order-rebate-title">
                  Total Rebate Earned (YTD)
                </div>
                <div className="order-rebate-content">
                  <div className="order-rebate-text ">
                    {(totalRebateYTD && currencyFormatter(totalRebateYTD)) ||
                      currencyFormatter(0)}
                  </div>
                </div>
              </div>
              <div className="order-rebate-card">
                <div className="order-rebate-title">Online Sales % (YTD)</div>
                <div className="order-rebate-content">
                  <div className="order-rebate-text ">
                    {(Number(Number(onlinePercentage).toFixed(2)) &&
                      Number(Number(onlinePercentage).toFixed(2))) ||
                      0}
                    %
                  </div>
                </div>
              </div>
              <div className="xchart-render">
                <XCharts data={pieData} chartType="nivoPieChart" isCurrency />
                <div className="xchart-info">
                  Total Sales: {currencyFormatter(totalSalesYTD)}{" "}
                </div>
              </div>
            </div>
          )}
          <table className="table table-bordered alternate">
            <thead>
              <tr>
                <th>Month</th>
                <th>Online Sales</th>
                <th>Threshold</th>
                <th>% Progress</th>
                <th>
                  Rebate Credited &nbsp;&nbsp;
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 100, hide: 400 }}
                    overlay={
                      <Tooltip
                        id="rebate-tooltip"
                        className="tooltip-rebate-credited"
                      >
                        <span className="font-weight-bold">
                          The Rebate Credit amount displayed is shown for the
                          prior month's online sales.
                        </span>
                      </Tooltip>
                    }
                  >
                    <a className="whiteColorTxt info-icon">
                      <i class="fa fa-info-circle"></i>
                    </a>
                  </OverlayTrigger>
                </th>
                <th>Credit Memos</th>
              </tr>
            </thead>
            <tbody>
              {rebateData &&
                rebateData.length > 0 &&
                rebateData.map((data, i) => (
                  <tr key={i}>
                    <td align="right">{formatMonth(data.monthYear)}</td>
                    <td align="right" className="maroonColorTxt">
                      {currencyFormatter(data.onlineSales)}
                    </td>
                    <td align="right" className="maroonColorTxt">
                      {currencyFormatter(data.threshold)}
                    </td>
                    <td align="right">{formatAmount(data.percentProgress)}%</td>
                    <td align="right">
                      {currencyFormatter(data.rebateCredited)}
                    </td>
                    <td align="center" className="maroonColorTxt">
                      {data.invoiceNumber !== "0" ? (
                        <a
                          className="maroonColorTxt link-over-programs"
                          onClick={() => {
                            window.open(data.invoiceUrl, "_blank");
                          }}
                        >
                          {data.invoiceNumber}
                        </a>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              {rebateData && rebateData.length > 0 && (
                <tr>
                  <td
                    align="left"
                    className="font-weight-bold td-background-totalrow"
                  >
                    Total
                  </td>
                  <td
                    align="right"
                    className="maroonColorTxt bolder td-background-totalrow"
                  >
                    {(onlineRebateData &&
                      onlineRebateData?.totalOnlineSales &&
                      currencyFormatter(onlineRebateData.totalOnlineSales)) ||
                      currencyFormatter(0)}
                  </td>
                  <td className="td-background-totalrow"></td>
                  <td className="td-background-totalrow"></td>
                  <td
                    align="right"
                    className="maroonColorTxt bolder td-background-totalrow"
                  >
                    {(onlineRebateData &&
                      onlineRebateData?.totalRebateCredited &&
                      currencyFormatter(
                        onlineRebateData.totalRebateCredited
                      )) ||
                      currencyFormatter(0)}
                  </td>
                  <td className="td-background-totalrow"></td>
                </tr>
              )}
              {isEmpty(rebateData) && rebateData.length === 0 && (
                <tr>
                  <td align="center" colSpan="12">
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
export default OnlineOrderRewards;
