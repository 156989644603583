import React from 'react';
import { Modal } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

function CoverToCoverPopup({ url, closePopup, openPopup }) {
	console.log(url, 'c2c url');

	return (
		<Modal size="lg" show={openPopup} className="cover_to_cover_modal">
			{url ? (
				<React.Fragment>
					<Modal.Header
						closeButton
						onClick={() => closePopup('coverToCover')}
					></Modal.Header>
					<Modal.Body>
						<iframe
							title="cover__to__cover"
							src={url}
							width="100%"
							height="800"
						></iframe>
					</Modal.Body>
				</React.Fragment>
			) : (
				<div className="custom_spinner_container">
					<Spinner className="custom_spinner" animation="border" />
				</div>
			)}
		</Modal>
	);
}

export default CoverToCoverPopup;
