import React, { useState } from "react";
import { Nav, Container,  Form} from "react-bootstrap";
import {  useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import XModal from "../XModal";
import { addFeedBack } from "../Header/header.thunks";
import { triggerUxCritialErrors, triggerCustomUxCriticalError, } from "../../../actions/error";
import _ from "lodash";


const FeedBackMenu = (props) => {
    const userDetails = useSelector((state) => state.user.userDetails);
    const history = useHistory();
    const [showFeedBackModal, setFeedBackModal] = useState(false);
    const dispatch = useDispatch();
    const [UploadFile, setUploadFile] = useState(null);
    const [comment, setComment] = useState("");

    const showFeedBack = () => {
        setFeedBackModal(true);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const submitFeedBack = () => {
        const { x2userName, firstName, lastName, billTo, shipTos } = userDetails;
        const shipToId = !_.isEmpty(shipTos) && shipTos.map((item) => item.shipToId);
        const payload = {
            email: x2userName,
            firstName: firstName,
            lastName: lastName,
            customerId: billTo.billToId,
            shipTo: shipToId.toString(),
            comment: comment,
        };
        const str_payload = JSON.stringify(payload);
        const blob_payload = new Blob([str_payload], {
            type: "application/json",
        });
        const formData = new FormData();
        if (UploadFile) {
            formData.append("file", UploadFile, UploadFile.name);
        }
        formData.append("feedback", blob_payload);
        // const modified_payload = {
        // 	formData,
        // 	dispatch,
        // };
        try {
            const addFeedBackAction = dispatch(addFeedBack(formData));
            addFeedBackAction.then((action) => {
                if (action.type && action.type === "addFeedBack/fulfilled") {
                    const statusMessageProps = {
                        heading: `Thank you for your Feedback`,
                        message: "",
                        type: "success",
                    };
                    triggerCustomUxCriticalError(statusMessageProps, dispatch);
                    setComment("");
                    setFeedBackModal(false);
                } else if (action.type && action.type === "addFeedBack/rejected") {
                    setComment("");
                    setFeedBackModal(false);
                }
            });
        } catch (error) {
            triggerUxCritialErrors(error, dispatch);
        } finally {
            setUploadFile(null);
        }
    };

    const commentTextLimit = () => {
        let limit = 500;
        let commentLength = comment.length;
        const commentCharLeft = limit - commentLength;
        if (commentCharLeft >= 0) {
            const commentText = `${commentCharLeft}/${limit}`;
            return commentText;
        }
        return "";
    };

    const handleUploadFile = (event) => {
        setUploadFile(event.target.files[0]);
    };

    return (
        <Nav.Item className="helpMenu_color">
            <span
                onClick={(event) => {
                    showFeedBack(event);
                }}
                className="material-icons feedback_icon"
            >
                feedback
            </span>
            {showFeedBackModal && (
                <XModal
                    title="Feedback"
                    show={showFeedBackModal}
                    className="custom_xmodal feedBackModal"
                    handleClose={() => {
                        setFeedBackModal(false);
                        setComment("");
                    }}
                    handleAction={() => {
                        submitFeedBack();
                    }}
                    yesButtonText="Ok"
                    noButtonText="Cancel"
                    isValidValidity={
                        !_.isEmpty(comment) ? true : false
                    }
                >
                    <section>
                        <Container>
                            <p className="comment_subheading">
                                We value your feedback..
                            </p>
                            <Form.Control
                                as="textarea"
                                className="input__control"
                                type="text"
                                name="comment"
                                onChange={handleCommentChange}
                                row={5}
                                maxLength="500"
                                placeholder="Please enter your comments"
                            />
                            <p className="comment_text">
                                {commentTextLimit()}
                            </p>
                            <div className="feedback_upload_file">
                                <span>
                                    Upload file to support your feedback
                                </span>
                                <br />
                                <input
                                    type="file"
                                    onChange={handleUploadFile}
                                    accept=".xlsx, .xls , .jpg, .jpeg, .png, .doc"
                                />
                            </div>
                        </Container>
                    </section>
                </XModal>
            )}
        </Nav.Item>
    );
};

export default FeedBackMenu;