/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { benefitDetails } from "./../programbenefits.thunk";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import "./styles.scss";
import _isEmpty from "lodash/isEmpty";
import { currencyFormatter, formatMonth } from "../../../helpers/utils";
import XCharts from "components/Charts";

const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const LaborClaim = (props) => {
  const dispatch = useDispatch();

  const [laborData, setLborData] = useState([]);

  const {
    loading = false,
    laborClaim: { totalSales, totalRebate, laborClaims, yearlyLaborClaims },
  } = useSelector((state) => state.ProgramAndBenefits);

  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;

  let laborConsolidateArr = [];

  useEffect(() => {
    dispatch({
      payload: {
        globalLoader: loading,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
  }, [loading]);

  useEffect(() => {
    if (!_isEmpty(laborClaims)) {
      const cartList = laborClaims.map((obj) => ({ ...obj }));
      cartList.forEach((one) => {
        let matched = false;
        if (_isEmpty(laborConsolidateArr)) {
          laborConsolidateArr.push(one);
        } else {
          matched = laborConsolidateArr.findIndex((two, index) => {
            return two.monthYear === one.monthYear;
          });
          if (matched >= 0) {
            laborConsolidateArr[matched].numLaborClaims =
              laborConsolidateArr[matched].numLaborClaims + one.numLaborClaims;
            laborConsolidateArr[matched].invNumber =
              laborConsolidateArr[matched].invNumber + "," + one.invNumber;
            laborConsolidateArr[matched].creditAmount =
              laborConsolidateArr[matched].creditAmount + one.creditAmount;
            laborConsolidateArr[matched].invUrl =
              laborConsolidateArr[matched].invUrl + "," + one.invUrl;
          } else {
            laborConsolidateArr.push(one);
          }
        }
      });
    }

    laborConsolidateArr &&
      laborConsolidateArr.length > 0 &&
      laborConsolidateArr.forEach((data, index) => {
        data.invNumber = data.invNumber ? data.invNumber.split(",") : "";
        data.invUrl = data.invUrl ? data.invUrl.split(",") : "";
      });

    setLborData(laborConsolidateArr);
  }, [laborClaims]);

  const getAllDetails = () => {
    const { xlPartAccountNumber } = userDetails || {};
    let customerNumber = xlPartAccountNumber;
    const payload = {
      name: "laborClaim",
      customerId: customerNumber,
      noOfMonths: 12,
    };
    dispatch(benefitDetails(payload)).then((action) => {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
    }).catch((error) => {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
    });
  };

  useEffect(() => {
    getAllDetails();
    barData();
  }, []);

  const barData = () => {
    const data = yearlyLaborClaims?.map((r) => ({
      name: r.year,
      "Labor Claim": Number(r.numOfLaborClaims),
      rebateCredited: currencyFormatter(r.totalCreditAmount),
      numLaborClaims: r.numOfLaborClaims,
      key: "Labor Claim",
    }));
    console.log("bar data ==> ", data);
    return data;
  };

  const CustomTooltip = (tProps) => {
    const { active, label, payload } = tProps;
    if (active) {
      return (
        <div className="custom-tooltip">
          <div className="label">{label}</div>
          <div>
            Total Credit Amount:{" "}
            <span className="credit-amount">
              {payload[0].payload.rebateCredited}
            </span>
          </div>
          <div>
            Number of LaborClaims:{" "}
            <span className="numOfLaborClaims">
              {payload[0].payload.numLaborClaims}
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="custom_spinner_container" />
      ) : (
        <div className="laborclaims-wrapper">
          {yearlyLaborClaims && yearlyLaborClaims.length > 0 && (
            <div className="labor-claim-report">
              <div className="report-card">
                <div className="rcard-title">Labor claims for last 3 years</div>
                <div className="labor-claim-content">
                  <div className="bolder theme-color same-font-size">
                    <XCharts
                      data={barData()}
                      chartType="barChart"
                      dataKeys={[{ value: "Labor Claim", color: "green" }]}
                      customTooltip={<CustomTooltip />}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Month</th>
                <th>Labor Claims</th>
                <th>Credit Amount</th>
                <th>Credit Memos</th>
              </tr>
            </thead>
            <tbody>
              {laborData &&
                laborData.length > 0 &&
                laborData.map((row, key) => (
                  <tr key={key}>
                    <td align="right">
                      <div className="month-year">
                        {formatMonth(row.monthYear)}
                      </div>{" "}
                    </td>
                    <td align="center">
                      {row.numLaborClaims ? row.numLaborClaims : "0"}
                    </td>
                    <td align="right" className="maroonColorTxt">
                      {row.creditAmount
                        ? currencyFormatter(row.creditAmount)
                        : currencyFormatter(0)}
                    </td>
                    <td align="center" className="maroonColorTxt">
                      <div className="credit-memos">
                        {row.invUrl &&
                          row.invUrl.length > 0 &&
                          row.invUrl.map((inv, index) => (
                            <button
                              className="maroonColorTxt link-over-programs credit-memo-link"
                              key={index}
                              onClick={() => {
                                window.open(inv, "_blank");
                              }}
                            >
                              {row.invNumber[index]}
                            </button>
                          ))}
                      </div>
                    </td>
                  </tr>
                ))}
              {laborData && laborData.length > 0 && (
                <tr>
                  <td
                    align="left"
                    className="font-weight-bold td-background-totalrow"
                  >
                    Total
                  </td>
                  <td
                    align="center"
                    className="maroonColorTxt bolder td-background-totalrow"
                  >
                    {totalRebate}
                  </td>
                  <td
                    align="right"
                    className="maroonColorTxt bolder td-background-totalrow"
                  >
                    {currencyFormatter(totalSales)}
                  </td>
                </tr>
              )}
              {isEmpty(laborData) && (
                <tr>
                  <td align="center" colSpan="6">
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );
};
export default LaborClaim;
