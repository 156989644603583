import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector} from "react-redux";
import Select from 'react-select';
import _isEmpty from "lodash/isEmpty";
import _find from 'lodash/find';
import { Form, Button,  Spinner } from 'react-bootstrap';
import {
    getUsersList,
    deleteUserEmail,
    deleteUserEmailOSR
} from '../UserManagement/usermanagement.thunk';
import {
	numbersOnlyRegExp,
} from '../../../helpers/utils';
import { triggerCustomUxCriticalError } from "../../../actions/error";
import { isEmpty } from "@aws-amplify/core";
import { logUserStockActivity } from '../../../actions/user';
import { timeInUTC } from '../../../helpers/utils'

const DeleteUser = () => {
    let usersList = useSelector((state) => state.userManagement.usersList);
    let userStatus = useSelector((state) => state.userManagement.userDeleted);
    let OSRStatus = useSelector((state) => state.userManagement.OSRDeleted);
    let [selectedEmail, setselectedEmail] = useState('');
    const [deleteUser, setdeleteUser] = useState('');
    const [billTo, setbillTo] = useState('');
    let user = useSelector((state) => state.user.userDetails);
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [showDeleteUserList, setshowDeleteUserList] = useState(false);
    const [buttonState, setbuttonState] = useState(false);
    const [errorMeassge ,SetErrormessage] = useState(false);
    const [showLoader, setshowLoader] = useState(true);
    const [showDeleteLoader, setshowDeleteLoader] = useState(true);
    const refreshModifyUser = useSelector((state)=>state.userManagement.refreshModifyUser);
    const dispatch = useDispatch();
    let optionArr = [];
    let OSRArr =[];
    let SuperAdminArr =[];
    if(!_isEmpty(usersList)) {
        usersList.map((user) => {
            let deletedUser =  user.x2userName && user.x2userName.split('|');
            let deletedUserName = deletedUser.length > 1 ? deletedUser[0] : '';
            if (user.userType !== "CUSTOMER_ADMIN" && (user.userType === 'LOCATION_BUYER' || user.userType === 'CUSTOMER_BUYER' || user.userType === 'SERVICE_WRITER' || user.userType === "VISITOR") &&(user.x2userName !== "deleted_user")) {
                if((deletedUserName !== "deleted_user" )) {
                    optionArr.push({
                        value: user.x2userName,
                        label: user.x2userName,
                    });
               }
            }
            if( (user.userType == "CUSTOMER_ADMIN" ||
            user.userType == 'LOCATION_BUYER' || user.userType == 'CUSTOMER_BUYER' ) && (user.x2userName !== "deleted_user")) {
                if((deletedUserName !== "deleted_user" ) &&  (user.x2userName !== "deleted_user") ) {
                    OSRArr.push({
                        value: user.x2userName ,
                        label: user.x2userName + "-" + user.userType,
                    });
                }              
            } 

            if( (
                user.userType == 'LOCATION_BUYER' || user.userType == 'CUSTOMER_BUYER' ) && (user.x2userName !== "deleted_user")) {
                    if((deletedUserName !== "deleted_user" ) &&  (user.x2userName !== "deleted_user") ) {
                        SuperAdminArr.push({
                            value: user.x2userName ,
                            label: user.x2userName + "-" + user.userType,
                        });
                    }              
                }
        });
    }
    useEffect(() => {
        if(!_isEmpty(user)) {
        const payload = {
            customerId: user.billTo.billToId,
        };
        dispatch(getUsersList(payload));
        }
    }, []);

    useEffect(() => {
        setbuttonState(false);
        setshowDeleteModal(false);
        setselectedEmail('');
        setdeleteUser('');
    }, [refreshModifyUser]);

    useEffect(() => {
        if (!_isEmpty(user)) {
        const payload = {
            customerId: user.billTo.billToId,
        };
        dispatch(getUsersList(payload));
        }
    }, [userStatus])

const handleEmailSelection = (event) => {
    let value = event.value;
    if(!_isEmpty(value)) {
        setselectedEmail(value);
    }
}


const handleDeleteEmail = () => {
    const deletePayload = {};
    const payload = {
        customerId: user.billTo.billToId,
    };
    let statusMessageProps = {
        heading: "",
        message: "",
        type: "",
        };
     
    if(!_isEmpty(selectedEmail)) {
        deletePayload.x2Username = selectedEmail;     
    } else if (!_isEmpty(deleteUser)) {
        deletePayload.x2Username = deleteUser;      
    }
    setbuttonState(true);
    setshowDeleteLoader(false);
    setshowLoader(false);
    if(!_isEmpty(deletePayload) && ( user && user.userType === "IT_ADMIN" || user.userType === "CUSTOMER_ADMIN" ||  user.userType === "SUPER_ADMIN")) {
        dispatch(deleteUserEmail(deletePayload)).then((result) => {
            if (result.payload && result.payload.data === 'UserEntity deleted') {
                statusMessageProps.heading = 'User Deleted Successfully';
                statusMessageProps.type = 'success';
                triggerCustomUxCriticalError(statusMessageProps, dispatch);
                dispatch(logUserStockActivity(timeInUTC(), user.billTo.billToId,"",user.shipTos[0].shipToId,"DELETE_USER","Deleted user: "+deletePayload.x2Username,user.x2userName))
            } else {
                statusMessageProps.heading = 'User Deletion Failed';
                statusMessageProps.type = 'error';
                triggerCustomUxCriticalError(statusMessageProps, dispatch);
            }
            setshowLoader(true);
            setbuttonState(false);
            setshowDeleteModal(false);
            setselectedEmail('');
            setbillTo('');
            setdeleteUser('');
            setshowDeleteUserList(false);
            dispatch(getUsersList(payload));
            setshowDeleteLoader(true);
        });
    }else
        if(!_isEmpty(deletePayload) && user && (user.userType === "OSR" || user.userType === "DSM")) {
            dispatch(deleteUserEmailOSR(deletePayload)).then((result) => {
                if (result.payload && result.payload === 'UserEntity deleted') {
                    statusMessageProps.heading = 'User Deleted Successfully';
                    statusMessageProps.type = 'success';
                    triggerCustomUxCriticalError(statusMessageProps, dispatch);
                    dispatch(logUserStockActivity(timeInUTC(), user.billTo.billToId,"",user.shipTos[0].shipToId,"DELETE_USER","Deleted user: "+deletePayload.x2Username,"Deleted user: "+user.x2userName))
                }else if(result.payload.errorCode === "E1023") {
                   statusMessageProps.heading = "User not found";
                   statusMessageProps.type = 'error';
                  triggerCustomUxCriticalError(statusMessageProps, dispatch);    
               }  else if(result.payload.errorCode === "E1111"){
                   statusMessageProps.heading = 'This customer is not linked to OSR';
                   statusMessageProps.type = 'error';
                  triggerCustomUxCriticalError(statusMessageProps, dispatch);
               }else 
               {
                statusMessageProps.heading = 'User Deletion Failed';
                statusMessageProps.type = 'error';
                triggerCustomUxCriticalError(statusMessageProps, dispatch);
               }
               setdeleteUser('');
               setselectedEmail('');
                setbillTo('');
                setshowLoader(true);
                setshowDeleteUserList(false);
               setbuttonState(false);
               setshowDeleteModal(false);
               SetErrormessage(false);
               setshowDeleteLoader(true);

               dispatch(getUsersList(payload));
            });
        }   
}

const handleDeleteOSR = (event) => {
    setbillTo(event.target.value);
    setshowDeleteUserList(false);
    SetErrormessage(false);
    setselectedEmail('');
}


const getUserList = () => {
    const payload = {
        customerId:billTo,
 };
let userNotFound =[]
setshowLoader(false);
dispatch(getUsersList(payload)).then((result) => {
if(result.type == 'getUsersList/fulfilled'){
result.payload.map((user1) => {
    let deletedUser =  user1.x2userName && user1.x2userName.split('|');
    let deletedUserName = deletedUser.length > 1 ? deletedUser[0] : '';
    if( (user1.userType == "CUSTOMER_ADMIN" || user1.userType == 'LOCATION_BUYER' || user1.userType == 'CUSTOMER_BUYER'  &&  (user1.x2userName !== "deleted_user") ) ) {
        if( ( deletedUserName !== "deleted_user" ) ) {
            userNotFound.push({
                value: user.x2userName,             
            });
        }              
    } 
})
if((result.payload && result.payload.length == 0 ) || (userNotFound && userNotFound.length == 0) ){
    SetErrormessage(true);
}
setshowLoader(true);
}
});
}

const handleDeleteOSRGet = () => {
    const payload = {
        customerId:billTo,
    };
setshowLoader(false);
dispatch(getUsersList(payload)).then((result) => {
if(result.type == 'getUsersList/fulfilled'){
setshowDeleteUserList(true);
if((result.payload && result.payload.length == 0 ) || (OSRArr && OSRArr.length == 0) || ( getUserList() )){
    SetErrormessage(true);
}
setshowLoader(true);
}
});
}

const onKeyDown = (event) => {
    const qtyValue = event.target.value;
if (((qtyValue && qtyValue.length >= 10) ||
	    event.keyCode === 32) &&
(event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' )		
		) {
			event.preventDefault();
		}
}

useEffect(() => {
    SetErrormessage(false);
  }, []);

const handleClick = () => {
    if (user && user.userType === "CUSTOMER_ADMIN" || user.userType === "SUPER_ADMIN" ) {
        handleDeleteEmail();
    }else  
    if( !selectedEmail && billTo && user && (user.userType === "OSR" || user.userType === "IT_ADMIN" || user.userType === "DSM")){
        handleDeleteOSRGet();
    }
    else if(selectedEmail && user && ( user.userType === "OSR" || user.userType === "IT_ADMIN" || user.userType === "DSM" ) ){
        handleDeleteEmail();
    }
}

return (
    <Fragment>
        {(user && user.userType === "CUSTOMER_ADMIN" )
        && (
        <div className="label-dropdown-container">
        <Form.Label>Users</Form.Label>
        <Select
            className="custom-dropdown"
            placeholder="Select a User"
            isDisabled={_isEmpty(usersList)}
            onChange={handleEmailSelection}
            options={!_isEmpty(usersList) && optionArr}
            value={optionArr.filter(
                (selectedUsr) => selectedEmail.includes(selectedUsr.value)
            )}

            theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#84bae2",
                  primary50: "#0F5283",
                  primary: "#0F5283",
                },
              })}
        />
    </div>
        )}

        
{(user && (user.userType === "SUPER_ADMIN" ) )
        && (
        <div className="label-dropdown-container">
        <Form.Label>Users</Form.Label>
        <Select
            className="custom-dropdown"
            placeholder="Select a User"
            isDisabled={_isEmpty(usersList)}
            onChange={handleEmailSelection}
            options={!_isEmpty(usersList) && SuperAdminArr}
            value={SuperAdminArr.filter(
                (selectedUsr) => selectedEmail.includes(selectedUsr.value)
            )}

            theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#84bae2",
                  primary50: "#0F5283",
                  primary: "#0F5283",
                },
              })}
        />
    </div>
        )}
     {(user && ( user.userType === "OSR" || user.userType === "IT_ADMIN" || user.userType === "DSM") )
    && (
        
        <div className="label-input-container">
            <label htmlFor="deleteUser">BillTo ID</label>
            <div>
                <Form.Control
                    type="text"
                    onChange={handleDeleteOSR}
                    name="deleteUser"
                    value={billTo}
                    onKeyDown={onKeyDown}
                    maxlength="10"
                />
            </div>
            </div>
            
    )}
 
{ ( !_isEmpty(billTo)  && !_isEmpty(OSRArr) && showDeleteUserList ) && (user.userType === "OSR" || user.userType === "IT_ADMIN" || user.userType === "DSM") && (
<div className="label-dropdown-container">
<Form.Label>Users</Form.Label>
<Select
    className="custom-dropdown"
    placeholder="Select a User"
    isDisabled={_isEmpty(usersList)}
    onChange={handleEmailSelection}
    options={!_isEmpty(usersList) && OSRArr}
    value={OSRArr.filter(
        (selectedUsr) => selectedEmail.includes(selectedUsr.value)
    )}
    theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#84bae2",
          primary50: "#0F5283",
          primary: "#0F5283",
        },
      })}
/>
</div>

 )}

 {
(errorMeassge && !_isEmpty(billTo) && OSRArr.length == 0 ) && (
        <span className ="error-message-BillTo">There are no users created in RAPID for the Customer Account {billTo}.</span> 
 ) 
 }
<br></br>
{ !_isEmpty(OSRArr)  && showDeleteUserList ?
(showDeleteLoader ?
    <Button
    disabled={_isEmpty(selectedEmail) || _isEmpty(billTo) }
    onClick={handleDeleteEmail}
    className="user-management-submit-btn"
    >
    ok
</Button> : 
 <Button
 className="user-management-submit-btn"
 variant="primary"
 disabled
>
 <Spinner
     as="span"
     animation="grow"
     size="sm"
     role="status"
     aria-hidden="true"
 />
Loading...
</Button>
):
(  showLoader ? 
<Button
    disabled={_isEmpty(selectedEmail) && _isEmpty(deleteUser) &&  ( _isEmpty(billTo) || billTo.length > 10 )}
    onClick={handleClick}
    className="user-management-submit-btn"
    >
    ok
</Button>:
 <Button
 className="user-management-submit-btn"
 variant="primary"
 disabled
>
 <Spinner
     as="span"
     animation="grow"
     size="sm"
     role="status"
     aria-hidden="true"
 />
Loading...
</Button>
)
}

    </Fragment>
);
}

export default DeleteUser;