import { Col, Accordion, Card, InputGroup, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';
import _findIndex from 'lodash/findIndex';
import _includes from 'lodash/includes';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import {
	getPreferenceDataFromStorage,
	getDefaultDataBasedOnUSerPref,
	getDefaultPreference,
	getPartsPreferredBrands
} from '../../helpers/utils';
import _uniq from "lodash/uniqBy";

import { getPartsByMultiGroupOrchestrator } from '../../actions/parts';

function FilterSection(props) {
	const dispatch = useDispatch();
	const parts = useSelector((state) => state.parts);
	const mygarage = useSelector((state) => state.mygarage);
	const sv = mygarage.selectedVehicle;  
	const userDetails = useSelector((state) => state.user.userDetails);


	const [categoryGroupMap, setCategoryGroupMap] = useState([]);
	let catalogRequestGroups = _cloneDeep(parts.catalogRequestGroups);
	let partsAllDetails = parts.partsAllDetails;
	let catalogPageSize = parts.catalogPageSize;
	let brandsList = parts.partsAllBrands;
	let positionList = parts.partsAllPosition;
	let catalogOrder = parts.catalogOrder;
	let catalogSortBy = parts.catalogSortBy;
	let partsAllAvailability = parts.partsAllAvailability;
	let catalogFilter = _cloneDeep(parts.catalogFilter);
	const inStockRef = useRef(null);
	const partAvailFilterRef = useRef([]);
	const availFilterRef = useRef([]);
	const [catalogFilterforgroup, setGroupCatalogFilter] =
    useState(catalogRequestGroups);
	const [partCategoryTg, setpartCategoryTg] = useState(true);
	const [brandTg, setbrandTg] = useState(false);
	const [positionTg, setpositionTg] = useState(false);
	const [availabilityTg, setavailabilityTg] = useState(false);
	const [casTg, setcasTg] = useState(false);
	const [selectedPos, setselectedPos] = useState([]);
	const [selAvlbs, setselAvlbs] = useState([]);
	const [selectedBrands, setSelectedBrands] = useState([]);
	const [selectedOffers, setselectedOffers] = useState([]);
	const [selGroups, setselGroups] = useState([]);
	const [selCatgs, setselCatgs] = useState([]);
	const [once, setOnce] = useState(false);
	const [filtersLoaded, setFiltersLoaded] = useState(false);
	const [defaultFilterCall, setDefaultFilterCall] = useState(false);
	const [catalogFilterforBrand, setBrandCatalogFilter] = useState(catalogFilter);
	
	useEffect(() => {
		setFiltersLoaded(true);
	  }, []);

	useEffect(() => {
		let defualtFilterValue = setDefaultAvailFilter();
		if (!defaultFilterCall && !isEmpty(defualtFilterValue) && partAvailFilterRef.current && partsAllAvailability && partsAllAvailability.length > 0 && Object.keys(partAvailFilterRef.current).length > 0) {
			let selectDefaultFilter = [];
			if(defualtFilterValue == 'all') {
			  defualtFilterValue = null;
			  selectDefaultFilter = partsAllAvailability.length > 0 && partsAllAvailability.filter(p => Number(p.code) == -1000);
			  setselAvlbs([...selectDefaultFilter]);
			}
			setDefaultFilterCall(true);
			setTimeout(() => {
				Object.keys(partAvailFilterRef.current).forEach(
					(index) => {
						if (defualtFilterValue != null && index.toString().toLowerCase() === defualtFilterValue.toLowerCase()) {
							if (!partAvailFilterRef.current[index].checked) {
								availFilterRef.current[0].click();
								partAvailFilterRef.current[index].click();
							}
						}
					}
				);
			}, 1000);
		}
	}, [partsAllAvailability]);

	useEffect(() => {
    //to make bydefault brand filter to select
    if (
      !_isEmpty(parts.partsAllBrands) &&
      (parts.isCategoryChanged === true || parts.isCategoryChanged == null)
    ) {
      const _b =
        (getPrefBrands() &&
          getPrefBrands().map((b) => {
            if (typeof b === "string") {
              return b;
            } else {
              return b.text;
            }
          })) ||
        [];

      let isPresent = false;
      const _p = _.intersection(brandsList, _b);
      if (_p.length > 0) {
        isPresent = true;
      }

      if (isPresent && !_b.includes('All')) {
        setSelectedBrands(_b);
      } else {
        setSelectedBrands([...brandsList, "all"]);
      }
      setFiltersLoaded(false);
    }
  }, [parts.partsAllBrands, parts.isCategoryChanged]);

  const getPrefBrands = () => {
    const supplier = userDetails.billTo.mclSupplier;
    const prefBrands = getPartsPreferredBrands(supplier);
    if (
      prefBrands &&
      prefBrands.multiAnswer &&
      prefBrands.multiAnswer.length > 0
    ) {
      const _selBrands = prefBrands.multiAnswer;
      const selBrands = _selBrands;

      return selBrands;
    }
  };

  const setDefaultAvailFilter = () => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    if (_isEmpty(userPreferenceDetail)) {
      return null;
    }
  
    const selectedFilter = _find(userPreferenceDetail, {
      code: "Product Information",
      text: "Stock Availability",
    });
  
    const selectedAnswer = selectedFilter?.answer?.code || null;
    const possibleAnswerData = selectedFilter?.possibleAnswer || [];
    const defaultAnswer = possibleAnswerData.find((v) => v.default);
  
    return selectedAnswer || defaultAnswer?.code || null;
  };

	useEffect(() => {
		let selectedGroups = catalogRequestGroups.requestItems.map(
			(g) => g.groupCode
		);
		let selectedCategories = _compact(
			parts.selectedGroupDetails.map((c) => {
				return +c.categoryId;
			})
		);

		let categoryGroups = parts.categoryGroupMap.map((category) => ({
			...category,
			active: selectedCategories.includes(category.code) ? true : false,
		}));

		setselGroups(setselGroups);
		setselCatgs(selectedCategories);
		setCategoryGroupMap(categoryGroups);

	}, [parts.selectedGroupDetails]);

	// DM
	useEffect(() => {
		const isAllFilled = catalogRequestGroups.requestItems.filter(r => !r.specificCondition);
		if (parts.isGroupChanged && isAllFilled.length === 0) {
			const updatedSearchData=parts.selectedGroupDetails && parts.selectedGroupDetails.map((item,i)=>{
				const result = parts.categoryList && parts.categoryList.find((category) => Number(category.code) === Number(item.categoryId));
				return {
				  category:result.name,
				  group:item.name
				}
		  
			  })
			dispatch(
				getPartsByMultiGroupOrchestrator(
					sv.year,
					sv.makeId,
					sv.modelId,
					sv.engineId,
					catalogRequestGroups,
					1,
					catalogPageSize,
					catalogSortBy,
					catalogOrder,
					catalogFilter,
					userDetails.billTo.mclSupplier,
					updatedSearchData,
					false,
					sv   
		   
							  
				)
			);

			dispatch({
				payload: {
					bool: false
				},
				type: 'GROUP_CHANGED'
			});

			setOnce(true);
		}
	}, [parts.isGroupChanged])

	const handleExpansionPanel = (event, singleItem) => {
		let matchingCategory = categoryGroupMap.find((data) => {
			return data.code === singleItem.code;
		});
		matchingCategory.active = !singleItem.active;

		if (selCatgs.includes(singleItem.code)) {
			const removedMatched = selCatgs.filter(s => s !== singleItem.code);
			setselCatgs(removedMatched);
		} else {
			setselCatgs([singleItem.code]);
		}

		setCategoryGroupMap([...categoryGroupMap]);
	};

	const handleFilter = (value, type, checked) => {
		let apiRequest = true; // api will call only if the existing request has any modification
		if (type === 'brand') {
			dispatch({
				payload: {
					bool: false
				},
				type: 'CATEGORY_CHANGED'
			});
			if (value === "all") {
				if (selectedBrands.includes(value)) {
					setSelectedBrands([...[]]);
					catalogFilter = {
						...catalogFilter,
						brand: [],
					};
				} else {
					const filters = _filter(selectedBrands, (b) => b !== value && b !== 'all');
					setSelectedBrands([...brandsList, value]);
					catalogFilter = {
						...catalogFilter,
						brand: brandsList,
					};
				}
			} else if (selectedBrands.includes(value)) {
				if (selectedBrands.length == 1) {
                    setSelectedBrands([...brandsList, 'all']);
					catalogFilter = {
						...catalogFilter,
						brand: brandsList,
					};
				}
				else {
					setSelectedBrands(_filter(selectedBrands, (b) => b !== value));
					setSelectedBrands(_filter(selectedBrands, (b) => b !== value && b !== 'all'));
					catalogFilter = {
						...catalogFilter,
						brand: _filter(selectedBrands, (b) => b !== value),
					};
				}
			}
			else {
				selectedBrands.push(value);
				if((parts.partsAllBrands.length) === _filter(selectedBrands, (b) => b !== 'all').length) {
					setSelectedBrands([...selectedBrands, 'all']);
				}
				catalogFilter = {
					...catalogFilter,
					brand: [...selectedBrands],
				};
			}
			setBrandCatalogFilter(catalogFilter);
		} else if (type === 'position') {
			if (selectedPos.includes(value)) {
				setselectedPos(_filter(selectedPos, (b) => b !== value));
				catalogFilter = {
					...catalogFilter,
					position: _filter(selectedPos, (b) => b !== value),
				};
			} else {
				setselectedPos([...selectedPos, value]);
				catalogFilter = {
					...catalogFilter,
					position: [...selectedPos, value],
				};
			}
		} else if (type === 'availability') {
			if (_includes(_map(selAvlbs, 'code'), value.code)) {
				apiRequest = selAvlbs.length > 1 ? true : false;
				if (value.code === 'inStock' && selAvlbs.length > 1) {
					setselAvlbs([...[]]);
					catalogFilter = {
						...catalogFilter,
						availability: [...[]],
					};
				} else if(selAvlbs.length > 1) {
					const filters = _filter(selAvlbs, (b) => b.code !== value.code && b.code !== 'inStock');
					setselAvlbs(filters);
					catalogFilter = {
						...catalogFilter,
						availability: filters,
					};
				}
			} else {
				if (value.code === 'inStock') {
					const excludeOutOfStock = partsAllAvailability.filter(p => Number(p.code) !== -100);

					setselAvlbs([value]);
					catalogFilter = {
						...catalogFilter,
						availability: [...excludeOutOfStock],
					};
				} else {
					const excludeOutOfStock = partsAllAvailability.filter(p => Number(p.code) !== -100);
					if (_.isEqual(excludeOutOfStock.sort(), [...selAvlbs, value].sort())) {
						setselAvlbs([{ code: 'inStock' }]);
					} else {
						setselAvlbs([value]);
					}
					catalogFilter = {
						...catalogFilter,
						availability: [value],
					};
				}
			}
		} else if (type === 'cas') {
			if (selectedOffers.includes(value)) {
				setselectedOffers(_filter(selectedOffers, (b) => b !== value));
				catalogFilter = {
					...catalogFilter,
					misc: _filter(selectedOffers, (b) => b !== value),
				};
			} else {
				setselectedOffers([...selectedOffers, value]);
				catalogFilter = {
					...catalogFilter,
					misc: [...selectedOffers, value],
				};
			}
		}
		if (type !== 'brand' && apiRequest) {
			const updatedSearchData=parts.selectedGroupDetails && parts.selectedGroupDetails.map((item,i)=>{
				const result = parts.categoryList && parts.categoryList.find((category) => Number(category.code) === Number(item.categoryId));
				return {
				  category:result.name,
				  group:item.name
				}
		
			  })
			dispatch(
				getPartsByMultiGroupOrchestrator(
					sv.year,
					sv.makeId,
					sv.modelId,
					sv.engineId,
					_cloneDeep(catalogRequestGroups),
					1,
					catalogPageSize,
					catalogSortBy,
					catalogOrder,
					_cloneDeep(catalogFilter),
					userDetails.billTo.mclSupplier,
					updatedSearchData,
					false,
					sv		  
			
				)
			);
		}
	};

	const applyBrandSelect = (e) => {
		const updatedSearchData=parts.selectedGroupDetails && parts.selectedGroupDetails.map((item,i)=>{
			const result = parts.categoryList && parts.categoryList.find((category) => Number(category.code) === Number(item.categoryId));
			return {
			  category:result.name,
			  group:item.name
			}
	  
		  })
		dispatch(
			getPartsByMultiGroupOrchestrator(
				sv.year,
				sv.makeId,
				sv.modelId,
				sv.engineId,
				catalogRequestGroups,
				1,
				catalogPageSize,
				catalogSortBy,
				catalogOrder,
				catalogFilterforBrand,
				userDetails.billTo.mclSupplier,
				updatedSearchData,
				false,
				sv		
		
			)
		);
	}

	const handleGroupChange = (category, group, e) => {
    let { shipMethod, selectedStore, sellPartnerId } = props;
    const checked = e.currentTarget.checked;
    let partTypes = [];
    catalogFilter = {
      ...catalogFilter,
      partTypes: partTypes,
      brand: []
    };
    setSelectedBrands([...[]]);
    const partTypeData = {
      partTypeId: "ALL",
      partTypeDesc: "ALL"
    };
    dispatch({
      payload: {
        selectedPartType: partTypeData,
      },
      type: 'SELECTED_PART_TYPE'
    });
    let singleGroup = {
      groupCode: group.code,
      specificCondition: [],
    };
    if (group !== "All") {
      if (
        _findIndex(catalogFilterforgroup.requestItems, {
          groupCode: group.code,
        }) !== -1
      ) {
        let groupCount = _filter(catalogFilterforgroup.requestItems, (b) => b.groupCode === group.code).length === 2;
        if(!checked) {
          _remove(catalogFilterforgroup.requestItems, { groupCode: group.code });
        }
        if (groupCount || checked) {
          catalogFilterforgroup.requestItems.push(singleGroup);
        }
        setGroupCatalogFilter(catalogFilterforgroup);
      } else {
        catalogFilterforgroup.requestItems.push(singleGroup);
        setGroupCatalogFilter(catalogFilterforgroup);
      }
    }
    if (group === "All" && checked) {
      category.groups.map((g) => {
        if (
          _findIndex(catalogFilterforgroup.requestItems, {
            groupCode: g.code,
          }) === -1
        ) {
          let singleGroup1 = {
            groupCode: g.code,
            specificCondition: [],
          };
          catalogFilterforgroup.requestItems.push(singleGroup1);
          setGroupCatalogFilter(catalogFilterforgroup);
        }
      });
    } else if (group === "All" && checked === false) {
      category.groups.map((g) => {
        if (
          _findIndex(catalogFilterforgroup.requestItems, {
            groupCode: g.code,
          }) !== -1
        ) {
          _remove(catalogFilterforgroup.requestItems, { groupCode: g.code });
          setGroupCatalogFilter(catalogFilterforgroup);
        }
      });
    }
    // Added this code to add groups to selectedGroupDetails state property.
    //  FIXED labor guide popup when new group is selected from filters in catalog

    catalogFilterforgroup.shipMethod = shipMethod;
    catalogFilterforgroup.store = selectedStore;
    catalogFilterforgroup.sellPartnerId = sellPartnerId;
    const selectedGroups = [...parts.selectedGroupDetails];

    if (group === "All" && checked) {
      category.groups.map((gr) => {
        let seectedGrp = { ...gr };
        seectedGrp["categoryId"] = category.code;
        if (_findIndex(selectedGroups, { code: gr.code }) === -1) {
          selectedGroups.push(seectedGrp);
        }
      });
    } else if (group === "All" && checked === false) {
      category.groups.map((gr) => {
        let seectedGrp = { ...gr };
        seectedGrp["categoryId"] = category.code;
        if (_findIndex(selectedGroups, { code: gr.code }) !== -1) {
          _remove(selectedGroups, { code: gr.code });
        }
      });
    }
    if (group !== "All") {
		if (checked) {
			let seectedGrp = { ...group };
			seectedGrp["categoryId"] = category.code;
			selectedGroups.push(seectedGrp);
		} else {
			let groupCount = _filter(selectedGroups, (b) => b.name === group.name).length === 2;
			let filtercheckedItems = [];
			if(!checked) {
				_.remove(selectedGroups, (s) => s.name === group.name && s.categoryId === category.code);
				filtercheckedItems =  _filter(selectedGroups, (s) => s.name !== group.name && s.categoryId !== category.code);
			}
			if (groupCount || checked) {
				selectedGroups.push(filtercheckedItems);
			}
		}
	}
		// if (
		// 	_findIndex(catalogRequestGroups.requestItems, {
		// 		groupCode: group.code,
		// 	}) !== -1
		// ) {
		// 	_remove(catalogRequestGroups.requestItems, {
		// 		groupCode: group.code,
		// 	});
		// } else {
		// 	catalogRequestGroups.requestItems.push(singleGroup);
		// }
		// // Added this code to add groups to selectedGroupDetails state property.
		// //  FIXED labor guide popup when new group is selected from filters in catalog
		// const checked = e.currentTarget.checked;
		// const selectedGroups = [...parts.selectedGroupDetails];
		// if (checked) {
		// 	let seectedGrp = { ...group };
		// 	seectedGrp['categoryId'] = category.code;
		// 	selectedGroups.push(seectedGrp);
		// } else {
		// 	_.remove(selectedGroups, s => s.name === group.name);
		// }
    setGroupCatalogFilter(catalogFilterforgroup);
    dispatch({
      payload: {
        selectedGroupDetails: selectedGroups,
      },
			type: 'GROUP_SELECTED',
    });
    // END ---

		dispatch({
			payload: {
				bool: false
			},
			type: 'GROUP_CHANGED'
		});

		dispatch({
			payload: {
				bool: true
			},
			type: 'CATEGORY_CHANGED'
		});
		
	}
	const handleGroupChangeAplyButton = () => {
		catalogFilter.partTypes = [];
		const updatedSearchData=parts.selectedGroupDetails && parts.selectedGroupDetails.map((item,i)=>{
			const result = parts.categoryList && parts.categoryList.find((category) => Number(category.code) === Number(item.categoryId));
			return {
			  category:result.name,
			  group:item.name
			}
	  
		  })
		dispatch(
			getPartsByMultiGroupOrchestrator(
			  sv.year,
			  sv.makeId,
			  sv.modelId,
			  sv.engineId,
			  _cloneDeep(catalogFilterforgroup),
			  1,
			  catalogPageSize,
			  catalogSortBy,
			  catalogOrder,
			  catalogFilter,
			  userDetails.billTo.mclSupplier,
			  updatedSearchData,
			  false,
			  sv	  
	  
			)
		);
	};

	const handleGroupAllCheck = (c, suppliesReqGrpIds) => {
		let k = [];
		suppliesReqGrpIds = _uniq(suppliesReqGrpIds);
		suppliesReqGrpIds = _.uniqBy(
      suppliesReqGrpIds,
      (s) => s.code
    );
		suppliesReqGrpIds.forEach((_sc) => {
		  c.groups.map((g, kc) => {
			if (g.code === _sc.code) {
			  k.push(g.code);
			}
		  });
		});
		if (k.length === c.groups.length) {
		  return true;
		}
		return false;
	  };
	  const catalogEnableApplyButton = (code) => {
		let isValid = parts.selectedGroupDetails
		  .filter((item) => item.categoryId)
		  .map((name) => name.categoryId);
		let isValidCheck = isValid.includes(code);
		if (isValidCheck) {
		  return true;
		}
		return false;
	  };
	
	const renderGroupAccordion = (singleItem) => {
		return (
		  <ul>
			<li>
			  <OverlayTrigger
				placement="right"
				delay={{ show: 100, hide: 400 }}
				overlay={<Tooltip>Apply your changes</Tooltip>}
			  >
				<Button
				  variant="outline-secondary"
				  className={
					catalogEnableApplyButton(singleItem.code)
					  ? "brand-apply-button"
					  : "disable-brand-apply"
				  }
				  onClick={(e) => {
					handleGroupChangeAplyButton(e);
				  }}
				>
				  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
				</Button>
			  </OverlayTrigger>
			</li>
	
			<li>
			  <InputGroup>
				<InputGroup.Checkbox
				  id="all"
				  name="all"
				  value="all"
				  onClick={(e) => {
					handleGroupChange(singleItem, "All", e);
				  }}
				  checked={handleGroupAllCheck(
					singleItem,
					parts.selectedGroupDetails
				  )}
				/>
				<label for="all">ALL</label>
			  </InputGroup>
			</li>
			{singleItem.groups.map((group) => {
			  return (
				<li>
				  <InputGroup>
					<InputGroup.Checkbox
					  id={group.code}
					  name={group.code}
					  value={group.code}
					  onClick={(e) => {
						handleGroupChange(singleItem, group, e);
					  }}
					  checked={
						!!_find(parts.selectedGroupDetails, (g) => {
						  return (
							+g.code === +group.code &&
							+g.categoryId === +singleItem.code
						  );
						})
					  }
					/>
					<label for={group.code}>{group.name}</label>
				  </InputGroup>
				</li>
			  );
			})}
		  </ul>
		);
	  };
	

	const isActiveClass = (group) => {
		const found = _.find(parts.selectedGroupDetails, g => { return +g.categoryId === +group.code })
		return found ? true : false;
	}
	const renderCategoryAccordion = () => {
		return categoryGroupMap.map((singleItem, index) => {
			return (
				<Accordion
					className="accordion xlp-accordian"
					activeKey={
						selCatgs.includes(singleItem.code)
							? singleItem.code
							: ''
					}
				>
					<Accordion.Toggle
						as={Card.Header}
						eventKey={singleItem.code}
						onClick={(e) => handleExpansionPanel(e, singleItem)}
						className={
							isActiveClass(singleItem) ? 'active' : null
						}
					>
						{singleItem.name}
						<span
							class={`${singleItem.active
								? 'filterSignMinus'
								: 'filterSignPlus'
								}`}
						></span>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey={singleItem.code}>
						<Card.Body>
							{renderGroupAccordion(singleItem)}
						</Card.Body>
					</Accordion.Collapse>
				</Accordion>
			);
		});
	};

	const renderAvailabilityAccordion = () => {
		partsAllAvailability = _.filter(partsAllAvailability, (p) => Number(p.code) !== Number(-1000));
		return (
			<ul className="part-availFilter">
				{/* <li>
					   <label class="customized-radio-button-avail-filter">
						<input
							ref={el => partAvailFilterRef.current['inStock'] = el}
							id={`inStock`}
							type="radio"
							label={'In Stock'}
							name={`inStock`}
							value={`Out of stock`}in
							onClick={(e) => {
								handleFilter({ code: 'inStock' }, 'availability', e);
							}}
							checked={_includes(_map(selAvlbs, 'code'), 'inStock')}
						/>
						<span>In Stock</span>
					  </label>
				</li> */}
				{partsAllAvailability.map((p, index) => {
					return p.availabilityText.toUpperCase() !== 'OUT OF STOCK' && (
						<li>
							   <label class="customized-radio-button-avail-filter">
								<input
									ref={el => partAvailFilterRef.current[p.availabilityText.replace(/ +/g, "").toLowerCase()] = el}
									id={p.availabilityText}
									type="radio"
									label={p.availabilityText}
									name={p.availabilityText}
									value={p.availabilityText}
									onClick={() => {
										handleFilter(p, 'availability');
									}}
									checked={_includes(_map(selAvlbs, 'code'), p.code)}
								/>
								<span>{p.availabilityText}</span>
							   </label>
						</li>
					);
				})}
			</ul>
		);
	};
	return (
		<Col className={props.isExpress ? "left-section" : "left-section lg-2 md-3 sm-3 xs-12"}>
			{!_isEmpty(partsAllDetails)
				&& (
					<Fragment>
						{/* Brands accordian */}
						<Accordion className="accordion xlp-accordian">
							<Card>
								<Accordion.Toggle
									as={Card.Header}
									eventKey="0"
									onClick={(e) => setbrandTg(!brandTg)}
									className={
										selectedBrands && selectedBrands.length > 0
											? 'active'
											: null
									}
								>
									Brand
								<span
										onClick={(e) => setbrandTg(!brandTg)}
										class={`${brandTg ? 'filterSignMinus' : 'filterSignPlus'
											}`}
									></span>
								</Accordion.Toggle>
								{!_isEmpty(brandsList) && (
									<Accordion.Collapse eventKey="0">
										<Card.Body>
											<ul>
											<li>
												<OverlayTrigger
													placement="right"
													delay={{ show: 100, hide: 400 }}
													overlay={
														<Tooltip>
                                                          Apply your changes
														</Tooltip>
													}
												>
												<Button variant="outline-secondary" className={selectedBrands.length == 0 ? 'disable-brand-apply' : 'brand-apply-button'} onClick={(e) => { applyBrandSelect(e); }}>
													<i class="fa fa-angle-double-right" aria-hidden="true"></i>
												</Button>
												</OverlayTrigger>
											</li>
											  <li>
												<InputGroup>
													<InputGroup.Checkbox
														id="all"
														name="all"
														value="all"
														onClick={() => {
															handleFilter(
																"all",
																'brand'
															);
														}}
														checked={selectedBrands.includes(
															"all"
														)}
													/>
													<label for="all">
														ALL
														</label>
												</InputGroup>
											   </li>
												{brandsList.map((brand) => {
													return (
														<li>
															<InputGroup>
																<InputGroup.Checkbox
																	id={brand}
																	name={brand}
																	value={brand}
																	onClick={() => {
																		handleFilter(
																			brand,
																			'brand'
																		);
																	}}
																	checked={selectedBrands.includes(
																		brand
																	)}
																/>
																<label for={brand}>
																	{brand}
																</label>
															</InputGroup>
														</li>
													);
												})}
											</ul>
										</Card.Body>
									</Accordion.Collapse>
								)}
							</Card>
						</Accordion>
						{/* Position accordian */}
						{!_isEmpty(positionList) && (
							<Accordion className="accordion xlp-accordian">
								<Card>
									<Accordion.Toggle
										as={Card.Header}
										eventKey="1"
										onClick={(e) => setpositionTg(!positionTg)}
										className={
											selectedPos && selectedPos.length > 0
												? 'active'
												: null
										}
									>
										Position
									<span
											onClick={(e) => setpositionTg(!positionTg)}
											class={`${positionTg
												? 'filterSignMinus'
												: 'filterSignPlus'
												}`}
										></span>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="1">
										<Card.Body>
											<ul>
												{positionList.map((p) => {
													return (
														<li>
															<InputGroup>
																<InputGroup.Checkbox
																	id={p}
																	name={p}
																	value={p}
																	onClick={() => {
																		handleFilter(
																			p,
																			'position'
																		);
																	}}
																	checked={selectedPos.includes(
																		p
																	)}
																/>
																<label for={p}>{p}</label>
															</InputGroup>
														</li>
													);
												})}
											</ul>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						)}
						{/* availability accordian */}
						{!_isEmpty(partsAllAvailability) && (
							<Accordion className="accordion xlp-accordian">
								<Card>
									<Accordion.Toggle
										onClick={(e) => setavailabilityTg(!availabilityTg)}
										as={Card.Header}
										eventKey="4"
										className={
											selAvlbs && selAvlbs.length > 0
												? 'active'
												: null
										}
									>
										Availability
									<span
											ref={el => availFilterRef.current[0] = el}
											onClick={(e) =>
												setavailabilityTg(!availabilityTg)
											}
											class={`${availabilityTg
												? 'filterSignMinus'
												: 'filterSignPlus'
												}`}
										></span>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="4">
										<Card.Body>
											{renderAvailabilityAccordion()}
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						)}
					</Fragment>
				)}
			<Fragment>
			{/* Combos and availability accordian */}
			<Accordion className="accordion xlp-accordian">
							<Card>
								<Accordion.Toggle
									onClick={(e) => setcasTg(!casTg)}
									as={Card.Header}
									eventKey="5"
									className={
										selectedOffers && selectedOffers.length > 0
											? 'active'
											: null
									}
								>
									Combos & Specials
								<span
										onClick={(e) => setcasTg(!casTg)}
										class={`${casTg ? 'filterSignMinus' : 'filterSignPlus'
											}`}
									></span>
								</Accordion.Toggle>
								<Accordion.Collapse eventKey="5">
									<Card.Body>
										<ul>
											<li>
												<InputGroup>
													<InputGroup.Checkbox
														id={`Bundle`}
														name={`Bundle`}
														value={`Bundle`}
														onClick={() => {
															handleFilter('Bundle', 'cas');
														}}
														checked={selectedOffers.includes(
															'Bundle'
														)}
													/>
													<label
														for={`Bundle`}
													>{`Bundle Pricing`}</label>
												</InputGroup>
											</li>
											<li>
												<InputGroup>
													<InputGroup.Checkbox
														id={`Family`}
														name={`Family`}
														value={`Family`}
														onClick={() => {
															handleFilter(
																'Family/Quantity',
																'cas'
															);
														}}
														checked={selectedOffers.includes(
															'Family/Quantity'
														)}
													/>
													<label
														for={`Family`}
													>{`Family/Quantity Pricing`}</label>
												</InputGroup>
											</li>
										</ul>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
			</Accordion>
			{/* category accordian */}
			{!props.isExpress && (
				<Accordion className="accordion xlp-accordian" defaultActiveKey="3">
					<Card>
						<Accordion.Toggle
							onClick={(e) => setpartCategoryTg(!partCategoryTg)}
							as={Card.Header}
							eventKey="3"
							className={
								selCatgs && selCatgs.length > 0 ? 'active' : null
							}
						>
							Part Category
							<span
								onClick={(e) => setpartCategoryTg(!partCategoryTg)}
								class={`${partCategoryTg
									? 'filterSignMinus'
									: 'filterSignPlus'
									}`}
							></span>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="3">
							<Card.Body>{renderCategoryAccordion()}</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
			)}
           </Fragment>
		</Col>
	);
}
export default FilterSection;
