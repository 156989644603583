/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { osrVisitFreequency } from "../stockorder.thunk";
import { triggerCustomUxCriticalError } from "../../../actions/error";

import _isEmpty from "lodash/isEmpty";
import { Button } from "ui-library";
import XSelect from "components/shared/XSelect";

const frequencies = [
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Bi-Weekly",
    value: "biweekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

const OSRVisitFreequency = ({ dispatch, userDetails, fetchStockOrders }) => {
  const [showLoader, setShowLoader] = useState(true);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState(frequencies[0]);
  const [frequency, setFrequency] = useState(null);

  useEffect(() => {
    if (!_isEmpty(userDetails) && userDetails.billTo) {
      const payload = {
        customerId: userDetails.billTo.customerCode,
        emailId: userDetails.x2userName,
      };
      dispatch(osrVisitFreequency(payload))
        .then((action) => {
          if (action.payload && action.payload.frequency) {
            const freq = action.payload.frequency;
            const foundFreq = frequencies.find((f) => f.value === freq);

            setFrequency(frequency);
            setSelectedFrequency(foundFreq);
          } else {
            setFrequency(frequencies[0].value);
            setSelectedFrequency(frequencies[0]);
          }
        })
        .catch((error) => {
          console.log("api failed", error);
        });
    }
  }, [userDetails]);

  // Update OSR visit frequency
  const handleUpdate = () => {
    const payload = {
      customerId: userDetails.billTo.customerCode,
      emailId: userDetails.x2userName,
      frequency: selectedFrequency.value,
    };
    setShowLoader(false);
    setIsEditEnabled(false);
    dispatch(osrVisitFreequency(payload))
      .then((action) => {
        const res = action.payload;
        setShowLoader(true);
        const statusMessageProps = {
          heading: "OSR Frequency updated Successfully",
          message: "",
          type: "success",
        };
        triggerCustomUxCriticalError(statusMessageProps, dispatch);
        setFrequency(res?.frequency);
        fetchStockOrders();
      })
      .catch((error) => {
        setShowLoader(true);
        console.error(error, "Error in API call");
      });
  };

  const handleFrequencyChange = (option) => {
    setSelectedFrequency(option);
    if (option.value !== frequency) {
      setIsEditEnabled(true);
    } else {
      setIsEditEnabled(false);
    }
  };

  return (
    <div className="osr-visit-freequency">
      <div>
        <label htmlFor="osrVisitFrequency">Change OSR Visit Frequency</label>
      </div>
      <div className="osr-visit-freequency-controls">
        <div>
          <XSelect
            customOption
            className="custom-stock-order-select"
            placeholder="Select Frequency"
            options={frequencies}
            defaultValue={selectedFrequency}
            onChange={handleFrequencyChange}
            value={selectedFrequency}
          />
        </div>
        <div>
          <Button
            onClick={handleUpdate}
            disabled={!isEditEnabled || !showLoader}
            className="frequency-button ok-button"
            variant="contained"
          >
            {showLoader ? (
              "OK"
            ) : (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OSRVisitFreequency;
