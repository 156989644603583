import React, { useState } from 'react';
import {Pagination} from 'react-bootstrap';

function TopBarPaginationComponent(props) {
    let{
        totalPages,
        currentPage,
        handlePageChange,
    } = props;

    const renderPaginationNumbers = () =>{
        let elements = [];
        let pageLowerLimit;
        let pageUpperLimit
        if(currentPage == 1){
            pageLowerLimit = 1;
            pageUpperLimit = totalPages > 2 ? 2 :totalPages;
        }else if(currentPage ==  totalPages){
            pageLowerLimit = totalPages <= 2 ? 1 : currentPage - 2
            pageUpperLimit = currentPage;
        }else{
            pageLowerLimit = currentPage - 1;
            pageUpperLimit = currentPage + 1;
        }
        for (let page = pageLowerLimit; page <= pageUpperLimit; page++) {
            elements.push(<Pagination.Item active={page === currentPage} onClick={()=>{handlePageChange(page)}}>{page}</Pagination.Item>)
        }
        return elements;
    }


	return (
		totalPages > 1 &&(<Pagination>
			<Pagination.First onClick={()=>{handlePageChange(1)}}/>
			<Pagination.Prev onClick={()=>{handlePageChange(currentPage -1)}}/>
            {
               renderPaginationNumbers()
            }
			<Pagination.Next onClick={()=>{handlePageChange(currentPage+1)}}/>
			<Pagination.Last onClick={()=>{handlePageChange(totalPages)}}/>
		</Pagination>)
	);
}
export default TopBarPaginationComponent;