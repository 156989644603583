import Axios from "axios";
import { buildQueryUrl } from "helpers/utils";
import httpClient from "../../services/http";

const service = "order-service";

export const getPayerDetails = () =>
  httpClient.get(`/${service}/pay/payerDetails`);

export const getDigest = (data) =>
  httpClient.post(`/${service}/pay/digest`, data);

export const saveTransaction = (data) =>
  httpClient.post(`/${service}/pay/transaction/save`, data);

export const doTransactionService = (data) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  return Axios.post(
    `${process.env.REACT_APP_CYBERSOURCE_PAYMENT_URL}`,
    formData
  );
};

export const fetchTransactionService = (id) => {
  return httpClient.get(`/${service}/pay/transactions/fetch/${id}`);
};

export const fetchInvoicesService = (data) => {
  return httpClient.get(
    `/${service}/pay/transaction/invoice?${buildQueryUrl(data)}`
  );
};

export const uploadInvoiceSheetService = (formData, params) => {
  return httpClient.post(
    `/${service}/pay/upload?${buildQueryUrl(params)}`,
    formData
  );
};

export const deletePaymentCardService = ({billTo, paymentToken}) => {
  return httpClient.delete(
    `/${service}/pay/payerDetails/${billTo}/${paymentToken}`
  );
}
