import React, { Fragment } from "react";
import { Image, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import _every from "lodash/every";
import _isEmpty from "lodash/isEmpty";
import _uniqBy from "lodash/uniqBy";
import _find from "lodash/find";
import _map from "lodash/map";
import _includes from "lodash/includes";
import _sumBy from "lodash/sumBy";
import _isEqual from "lodash/isEqual";
import Highlighter from "react-highlight-words";
import { isProgrammable, searchWords } from "../../helpers/constants";
import { triggerCustomUxCriticalError } from "../../actions/error";
import Warranty from "../../components/Warranty";
import warrantyIcon from "../../static/images/warranty.svg";

import {
  isInputBlank,
  isValidPositiveNumber,
  isValidAvailableQty,
  distributeAvailability,
  formatAmount,
  formatTimeAvb,
  numbersOnlyRegExp,
  getPreferenceDataFromStorage,
  getImageURLCookieDetails,
  getExtendedTotalPrice,
  getShipInfo,
  getPartPackQty,
  displayQtyChangeMsg,
  hidePrice,
  getDistributionForVehicle,
  distributeAvailability2,
  getUserDefaultShipInfo
} from "../../helpers/utils";
import _cloneDeep from "lodash/cloneDeep";
import { getAvailability } from "../../helpers/availabilityService";
import AvailabilityStoreView from "../shared/AvailabilityStoreView";
import {
  getC2CUrl,
  getBuyersGuideData,
  getBuyersGuideBrandData,
  getLaborGuideData,
  getLaborGroups
} from "../../actions/parts";

// import labourGuideIcon from '../../static/images/Labor_Guide.png';
// import vectorIllustrationIcon from '../../static/images/Vector_Illustration.png';
// import interchangeIcon from '../../static/images/Interchange.png';
// import buyerGuideIcon from '../../static/images/MY_GARAGE.png';

import labourGuideIcon from "../../static/images/icons/Labor_Guide.svg";
import vectorIllustrationIcon from "../../static/images/icons/Vector_Illustration.svg";
import interchangeIcon from "../../static/images/icons/Interchange.svg";
import buyerGuideIcon from "../../static/images/icons/MY_GARAGE.svg";

import VectorGfxPopup from "./VectorGfx/index";
import LaborGuide from "./LaborGuide";
import CoverToCoverPopup from "./CoverToCoverPopup";
import Interchange from "../shared/Interchange";
import FormValidationErrorMessage from "../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import AddToCart from "../shared/AddToCart/AddToCart";
import ImagesSlider from "./ImagesSlider/index";

import XModal from "../shared/XModal";
import BuyingGuideComponent from "../shared/BuyingGuide";
import LostSales from "../ShoppingCart/LostSales";
import PricingIcons from "./PricingIcons";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
const actionTypes = { GLOBAL_LOADER: 'GLOBAL_LOADER' };

class SingleProduct extends React.Component {
  constructor(props) {
    super(props);
    let partsQtySum = !_isEmpty(
      props.partDetails.pricingAvailability.availability
    )
      ? _sumBy(
        _uniqBy(
          this.props.partDetails.pricingAvailability.availability,
          "availableDuration"
        ),
        "availableQty"
      )
      : 0;
    this.state = {
      orderArray: [],
      preveousOrderQty: 0,
      showCoverToCover: false,
      showImagesSlider: false,
      selectedAvailabilityHours: [],
      showBuyersGuide: false,
      showLaborGuide: false,
      showInterchange: false,
      showVectorGfx: false,
      partsQtySum,
      appendPart: props.appendPart ? props.appendPart : false,
      totalOrderQty: 0,
      validity: this.intializeValidations(),
      showLostSales: false,
      extendedPriceHighlighter: false,
      laborGuideDetails: {},
      warrantyData: {},
      warrantyApiCall:"",
      selectedStore: props.selectedStore ? props.selectedStore : getUserDefaultShipInfo(this.props.userDetails).storeNumber,
      sellPartnerId: props.sellPartnerId ? props.sellPartnerId : getUserDefaultShipInfo(this.props.userDetails).sellerPartnerId,
      shipMethod: props.shipMethod ? props.shipMethod : getUserDefaultShipInfo(this.props.userDetails).shipMethod,
      singlePartData: [],
      selectedAvailabilityHoursForPropack: [],
      isMobileEnabled: this.props.userDetails?.shipTos[0]?.isMobileEnabled,
      showAvailabilityStoreView:false,
      storeData:"",
      hideAllPrice :  this.props.parts?.hidePrice
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    let { partDetails } = this.props;
    let defualtTotalQuantity = this.setDefalutProductQuantity();
    if (!_isEmpty(partDetails) && defualtTotalQuantity) {
      if (partDetails && !_isEmpty(partDetails.pricingAvailability)) {
        this.handleInputChange();
      }
    }
  }

  componentDidUpdate(prevProps) {
    let { partDetails } = this.props;
    let defualtTotalQuantity = this.setDefalutProductQuantity();
    if (!_isEqual(this.props, prevProps)) {
      if (
        !_isEqual(this.props.partDetails, prevProps.partDetails) &&
        defualtTotalQuantity
      ) {
        if (partDetails && !_isEmpty(partDetails.pricingAvailability)) {
          this.handleInputChange();
        }
      }
    }
  }

  intializeValidations() {
    this.validity = {
      doesQtyExist: true,
      isQtyMoreThanOne: true,
      isQtyLessThanAvailable: true,
    };
    return this.validity;
  }

  setDefalutProductQuantity() {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let selectedProductQty = {};
    if (!_isEmpty(userPreferenceDetail)) {
      selectedProductQty = _find(userPreferenceDetail, {
        code: "Product Information",
        text: "Default Order Qty to Qty Per Vehicle",
      });
    }
    return selectedProductQty &&
      selectedProductQty.answer &&
      selectedProductQty.answer.code === "N"
      ? false
      : true;
  }

  validateQty(quantity, availableQuantity, validity) {
    validity.doesQtyExist = !isInputBlank(quantity);
    validity.isQtyMoreThanOne = isValidPositiveNumber(quantity);
    validity.isQtyLessThanAvailable = isValidAvailableQty(
      quantity,
      availableQuantity
    );
    return validity;
  }

  handleInputChangeValue(event) {
    let { partsQtySum, totalOrderQty, preveousOrderQty, validity } = this.state;
    let value;
    if (!_isEmpty(event)) {
      value = event.target.value;
    }
    totalOrderQty = Number(value);
    preveousOrderQty = Number(value);
    validity = this.validateQty(value, partsQtySum, validity);
    this.setState({
      ...this.state,
      totalOrderQty,
      preveousOrderQty,
      validity,
    });
  }
  handleInputChange(event) {
    let {
      partsQtySum,
      totalOrderQty,
      preveousOrderQty,
      validity,
      selectedStore,
      sellPartnerId,
      shipMethod,
    } = this.state;
    let {
      partDetails: { pricingAvailability },
      selectedVehicle,
      selectedGroupDetails,
      userDetails,
      parts,
      cart
    } = this.props;
    let { partDetails, categoryGroupMap, proPack } = this.props;
    let selectedShipMethod = shipMethod;
    let selectdStore = selectedStore;
    let selectedSellerPartnerId = sellPartnerId;
    let { shipTos } = userDetails;
    if (proPack) {
      selectedShipMethod = getShipInfo(userDetails, parts).shipMethod
        ? getShipInfo(userDetails, parts).shipMethod
        : shipTos &&
        shipTos[0] &&
        shipTos[0].shipMethod &&
        shipTos[0].shipMethod;
      selectdStore = getShipInfo(userDetails, parts).storeNumber
        ? getShipInfo(userDetails, parts).storeNumber
        : shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.storeNumber &&
        shipTos[0].location.storeNumber;
      selectedSellerPartnerId = getShipInfo(userDetails, parts).sellerPartnerId
        ? getShipInfo(userDetails, parts).sellerPartnerId
        : shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.sellerPartnerId &&
        shipTos[0].location.sellerPartnerId;
    }
    let value;
    if (!_isEmpty(event)) {
      value = event.target.value;
    } else {
      value =
        partDetails && partDetails.pricingAvailability.qtyBuyInc > 1
          ? partDetails.pricingAvailability.qtyBuyInc
          : partDetails.perCarQty;
      totalOrderQty = Number(value);
    }
    if (!_isEmpty(event)) {
      if (!_isEmpty(value)) {
        let packQty = getPartPackQty(
          partDetails.pricingAvailability.qtyBuyInc,
          Number(value),
          partsQtySum
        );
        totalOrderQty = packQty;
        if (
          preveousOrderQty !== totalOrderQty &&
          partDetails.pricingAvailability.qtyBuyInc > 1
        ) {
          this.qtyByINCUpdateSuccessMessage();
          preveousOrderQty = totalOrderQty;
        }
      } else {
        totalOrderQty = Number(value);
        preveousOrderQty = Number(value);
      }
    }
    let distributionData = {};
    let selectedAvailabilityHours = [];
    let selectedAvailabilityHoursForPropack = [];
    let singlePartData = [];
    let clonePart = _cloneDeep(partDetails);
    clonePart.qty = totalOrderQty;
    singlePartData.push(clonePart);
    //checking for unique availability duration as the availability is repeating. temporary fix
    partsQtySum = _sumBy(
      _uniqBy(pricingAvailability.availability, "availableDuration"),
      "availableQty"
    );
    validity = this.validateQty(value, partsQtySum, validity);
    let orderArray;
    if (partDetails.pricingAvailability.qtyBuyInc > 1) {
      // orderArray = distributeAvailability(
      //   totalOrderQty,
      //   pricingAvailability.availability
      // )
      distributionData = getDistributionForVehicle(
        partDetails.partNumber,
        partDetails,
        partDetails.pricingAvailability &&
          partDetails.pricingAvailability.availability,
        totalOrderQty,
        selectedVehicle,
        cart
      );
      orderArray = distributeAvailability2(
        Number(distributionData.totalQty),
        distributionData.filteredAvailability
      );

    } else {
      // orderArray = distributeAvailability(
      //   value,
      //   pricingAvailability.availability
      // );
      distributionData = getDistributionForVehicle(
        partDetails.partNumber,
        partDetails,
        partDetails.pricingAvailability &&
          partDetails.pricingAvailability.availability,
        value,
        selectedVehicle,
        cart
      );
      orderArray = distributeAvailability2(
        Number(distributionData.totalQty),
        distributionData.filteredAvailability
      );

    }
    orderArray.map((item) => {
      selectedAvailabilityHours.push(
        this.getAvailabilityObject(pricingAvailability, item)
      );
    });
    selectedAvailabilityHours.push(...distributionData.alreadyAddedItem); // this will aplicable only for catalog
    selectedAvailabilityHoursForPropack.push(...selectedAvailabilityHours);
    /* filtering only propack item */
    selectedAvailabilityHoursForPropack =
      selectedAvailabilityHoursForPropack.filter(
        (value) => value.isCartItem === false
    );
    this.setState({
      ...this.state,
      orderArray,
      partsQtySum,
      selectedAvailabilityHours,
      selectedAvailabilityHoursForPropack,
      singlePartData,
      totalOrderQty,
      preveousOrderQty,
      validity,
      extendedPriceHighlighter: event ? true : false,
    });
    setTimeout(
      function (that) {
        that.setState({
          extendedPriceHighlighter: false,
        });
      },
      2000,
      this
    );
  }

  getAvailabilityObject = (pricingAvailability, item = {}) => {
    let { partDetails, categoryGroupMap, proPack, selectedVehicle } =
      this.props;
    return {
      ...(proPack ? { availability: pricingAvailability.availability } : {}),
      ...(proPack ? { selectedVehicle: selectedVehicle } : {}),
      // availability: proPack ? pricingAvailability.availability : null, //  todo property should not add every time
      // selectedVehicle: proPack ? selectedVehicle : null,  // todo property should not add every time
      availabilityHour: !_isEmpty(item) ? item.availableDuration : "",
      availableQty: !_isEmpty(item) ? item.availableQty : "",
      cartFlag: "no",
      categoryId: Number(
        _find(categoryGroupMap, (c) => {
          return _includes(
            _map(c.groups, "code"),
            Number(partDetails.groupNumber)
          );
        })?.code
      ),
      costPrice: pricingAvailability.costPrice,
      corePrice: pricingAvailability.corePrice,
      coreListPrice: pricingAvailability.coreListPrice,
      desc: partDetails.description,
      descId: partDetails.descriptionID,
      engineId: selectedVehicle.engineId,
      engine: selectedVehicle.engine,
      groupId: Number(partDetails.groupNumber),
      listPrice: pricingAvailability.listPrice,
      makeId: selectedVehicle.makeId,
      internalMakeId: selectedVehicle.internalMakeId,
      make: selectedVehicle.make,
      modelId: selectedVehicle.modelId,
      model: selectedVehicle.model,
      mfgCode: partDetails.lineCodes ? partDetails.lineCodes[0] : null,
      packQty: pricingAvailability.packQty,
      partNum: partDetails.partNumber,
      partType: "Regular",
      qty: !_isEmpty(item) ? item.orderQty : "",
      qtyPerVeh: partDetails.perCarQty,
      qtyBuyInc: pricingAvailability.qtyBuyInc,
      toolTip: JSON.stringify(pricingAvailability.toolTips),
      vehicleInfo: selectedVehicle
        ? `${selectedVehicle.year} ${selectedVehicle.make} ${
            selectedVehicle.model
          } ${selectedVehicle.engine.replace(/\s{2,}/g, " ").trim()}`
        : "",
      yearId: selectedVehicle ? selectedVehicle.year : "",
      store: this.getShipInfo().store,
      sellPartnerId: this.getShipInfo().sellerId,
      shipMethod: this.getShipInfo().shipMethod,
      distStatus: "Completed",
      orderType: "REGULAR",
      isCartItem: false,
    };
  };

  getShipInfo = () => {
    let { selectedStore, sellPartnerId, shipMethod } = this.state;
    let { proPack, userDetails, parts } = this.props;
    let selectedShipMethod = shipMethod;
    let selectdStore = selectedStore;
    let selectedSellerPartnerId = sellPartnerId;
    let { shipTos } = userDetails;
    if (proPack) {
      selectedShipMethod = getShipInfo(userDetails, parts).shipMethod
        ? getShipInfo(userDetails, parts).shipMethod
        : shipTos &&
          shipTos[0] &&
          shipTos[0].shipMethod &&
          shipTos[0].shipMethod;
      selectdStore = getShipInfo(userDetails, parts).storeNumber
        ? getShipInfo(userDetails, parts).storeNumber
        : shipTos &&
          shipTos[0] &&
          shipTos[0].location &&
          shipTos[0].location.storeNumber &&
          shipTos[0].location.storeNumber;
      selectedSellerPartnerId = getShipInfo(userDetails, parts).sellerPartnerId
        ? getShipInfo(userDetails, parts).sellerPartnerId
        : shipTos &&
          shipTos[0] &&
          shipTos[0].location &&
          shipTos[0].location.sellerPartnerId &&
          shipTos[0].location.sellerPartnerId;
    }
    return {
      sellerId: selectedSellerPartnerId,
      store: selectdStore,
      shipMethod: selectedShipMethod,
    };
  };
  prepareOrderRequestData = (selectedPart) => {
    if (selectedPart.length > 0) {
      let { shipMethod } = this.state;
      const selectedAVailabilityHourPayload = [];
      let { partDetails, userDetails, parts } = this.props;
      let {
        partDetails: { pricingAvailability },
        selectedVehicle,
        cart,
      } = this.props;

      let tempObj = this.getAvailabilityObject(pricingAvailability);
      let orderArray = [];
      let distributionData = {};
      let cloneCart = _cloneDeep(cart);
      if (shipMethod !== getShipInfo(userDetails, parts).shipMethod) {
        cloneCart.checkoutDetails = [];
      }
      distributionData = getDistributionForVehicle(
        partDetails.partNumber,
        partDetails,
        pricingAvailability.availability,
        Number(selectedPart[0].qty),
        selectedVehicle,
        cloneCart
      );
      distributionData.alreadyAddedItem =
        shipMethod !== getShipInfo(userDetails, parts).shipMethod
          ? cart.checkoutDetails
          : distributionData.alreadyAddedItem;
      orderArray = distributeAvailability2(
        Number(distributionData.totalQty),
        distributionData.filteredAvailability
      );
      selectedAVailabilityHourPayload.push(
        ...distributionData.alreadyAddedItem
      );
      orderArray.map((availabilityData) => {
        tempObj = {
          ...tempObj,
          availabilityHour: availabilityData.availableDuration,
          availableQty: availabilityData.availableQty,
          qty: !_isEmpty(availabilityData.orderQty.toString())
            ? Number(availabilityData.orderQty.toString())
            : Number("1"),
          distStatus: "Completed",
          orderType: "REGULAR"
        };
        selectedAVailabilityHourPayload.push(tempObj);
      });
      return selectedAVailabilityHourPayload;
    }
  };

  qtyByINCUpdateSuccessMessage = () => {
    let { dispatch } = this.props;
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = displayQtyChangeMsg();
    statusMessageProps.type = "success";
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  };
  togglePopup = (popupName) => {
    let {
      showBuyersGuide,
      showCoverToCover,
      showImagesSlider,
      showInterchange,
      showLaborGuide,
      showVectorGfx,
      laborGuideDetails,
      showWarrantyDetails,
    } = this.state;
    const { dispatch, partDetails, selectedVehicle, selectedGroupDetails } =
      this.props;

    switch (popupName) {
      case "buyersGuide":
        if (!showBuyersGuide) {
          getBuyersGuideData(
            partDetails.partNumber,
            dispatch,
            partDetails.mfgName
          );
          getBuyersGuideBrandData(partDetails.partNumber, dispatch);
        }
        getC2CUrl(partDetails.partNumber, partDetails.orderNumber, dispatch);
        showBuyersGuide = !showBuyersGuide;
        break;
      case "coverToCover":
        getC2CUrl(partDetails.partNumber, partDetails.orderNumber, dispatch);
        showCoverToCover = !showCoverToCover;
        break;
        case "laborGuide":
          if (!showLaborGuide) {
            let selectedGroupCategoryInfo = !_isEmpty(selectedGroupDetails) && _find(selectedGroupDetails, {
              code: Number(partDetails.groupNumber),
            });
            let groupData = []; 
            if(!_isEmpty(selectedGroupCategoryInfo) && selectedGroupCategoryInfo.categoryId) {
              dispatch({
                payload: {
                  globalLoader: true
                },
                type: actionTypes.GLOBAL_LOADER
              });
              getLaborGroups(
              Number(selectedGroupCategoryInfo.categoryId),
              dispatch
            ).then((response) => {
             let { data } = response;
             groupData = _find(data, {
              code: Number(partDetails.groupNumber),
            });
             if (
              groupData &&
              groupData.laborGroups &&
              groupData.laborGroups.length
            ) {
              let laborItems = [];
              let laborSpecificConditions = [];
              let laborGroupItemsRecd = 0;
              groupData.laborGroups.forEach((laborGroup, index) => {
                getLaborGuideData(
                  selectedVehicle.engineId,
                  groupData.laborGroups[index].textID,
                  selectedVehicle.makeId,
                  selectedVehicle.modelId,
                  selectedVehicle.year,
                  dispatch
                ).then((__response) => {
                  dispatch({
                    payload: {
                      globalLoader: false
                    },
                    type: actionTypes.GLOBAL_LOADER
                  });
                  if (__response && __response.data) {
                    laborGroupItemsRecd++;
                    laborItems.push(...__response.data.laborItems);
                    laborSpecificConditions.push(
                      ...__response.data.laborSpecificConditions
                    );
  
                    console.log(__response.data);
                  }
                  if (__response && __response.data == null) {
                    laborGroupItemsRecd++;
                  }
                  if (laborGroupItemsRecd === groupData.laborGroups.length) {
                    laborGuideDetails = {
                      laborItems: laborItems,
                      laborSpecificConditions: laborSpecificConditions,
                    };
                    this.setState({
                      laborGuideDetails,
                    });
                  }
                }).catch ((error) => {
                  dispatch({
                    payload: {
                      globalLoader: false
                    },
                    type: actionTypes.GLOBAL_LOADER
                  });
                  console.error('error', error);
                });
              });
            }
            }).catch ((error) => {
              dispatch({
                payload: {
                  globalLoader: false
                },
                type: actionTypes.GLOBAL_LOADER
              });
              console.error('error', error);
            });
          }
        }
  
          showLaborGuide = !showLaborGuide;
          break;
      case "interchange":
        showInterchange = !showInterchange;
        break;
      case "vectorGfx":
        showVectorGfx = !showVectorGfx;
        break;
      case "imagesSlider":
        showImagesSlider = !showImagesSlider;
        break;
        case "warranty":
          showWarrantyDetails = !showWarrantyDetails;
          break;
      default:
        break;
    }
    this.setState({
      showBuyersGuide,
      showCoverToCover,
      showImagesSlider,
      showLaborGuide,
      showInterchange,
      showVectorGfx,
      laborGuideDetails,
      showWarrantyDetails,
    });
  };

  handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  handleKeyDown = (event) => {
    const qtyValue = event.target.value;
    if (
      (numbersOnlyRegExp(event.key) === false ||
        (qtyValue && qtyValue.length >= 5) ||
        event.keyCode === 32) &&
      this.handleOtherKeys(event.keyCode)
    ) {
      event.preventDefault();
    }
  };

  checkIsPartInCart = (partNum, part) => {
    const { cartStatus } = this.props;
    let str = "";
    let qty = 0;
    cartStatus.map((item) => {
      if (item.partNum === partNum && item.mfgCode === part.lineCodes?.[0]) {
        qty += item.qty;
        str = qty + ` in Cart`;
      }
    });
    return str;
  };

  onclick = (tempData) => {
    this.setState({
      storeData: tempData,
    });
    this.setState({
      showAvailabilityStoreView: true,
    });
  }

  checkIfMobileEnabled = (tempData,text) =>{
    let {isMobileEnabled} = this.state
    return(
      isMobileEnabled ? 
    <u className="text-color" onClick={()=>{this.onclick(tempData)}}>{text}</u> :
    <u>{text}</u>
    )
  }

  renderAvailability = () => {
    let { partDetails, proPack, handlePartSelect, proPackKey, userDetails, selectedStore } = this.props;
    let {
      partsQtySum,
      orderArray,
      selectedAvailabilityHours,
      totalOrderQty,
      validity,
      extendedPriceHighlighter,
      singlePartData ,
      hideAllPrice
    } = this.state;
    let defualtTotalQuantity = this.setDefalutProductQuantity();
    let hideCostPricePref = hidePrice("Catalog", "costPrice");
    let availabilitySection = [];
    let totalAvailqty = 0;
    let uniqueData = _uniqBy(
      partDetails.pricingAvailability.availability,
      "availableDuration"
    );
    !_isEmpty(uniqueData) && uniqueData.map((singleAvailability) => {totalAvailqty += singleAvailability.availableQty;});
    availabilitySection = getAvailability(partDetails, {}, partDetails.pricingAvailability.availability, orderArray, userDetails, "FullView",this.onclick, 0, selectedStore);
    let fullDiv = [];
    fullDiv.push(
      <div className="availability-section tph-prod-quantity-section">
        {availabilitySection}
      </div>
    );
    availabilitySection = fullDiv;
    if (availabilitySection.length > 0 && totalAvailqty > 0) {
      availabilitySection.push(
        <div>
          <p className="maroonColorTxt mb-0">
            Enter the quantity below in <b>Total Qty</b> field
          </p>
          <div className="add_to_cart_section">
            <span>
              <span>Total </span>
              <span>QTY:</span>
            </span>
            <input
              type="text"
              name="totalQty"
              defaultValue={
                defualtTotalQuantity && partDetails && partDetails.perCarQty
                  ? partDetails.perCarQty
                  : ""
              }
              disabled={totalAvailqty === 0}
              value={totalOrderQty}
              onChange={(e) => this.handleInputChangeValue(e)}
              onBlur={(e) => this.handleInputChange(e)}
              onMouseLeave={(e) => this.handleInputChange(e)}
              onKeyDown={(event) => {
                this.handleKeyDown(event);
              }}
            />
            {proPack ? (
              <Button
               disabled={
                _isEmpty(partDetails.pricingAvailability.availability) ||
                !_every(validity) 
                }
                onClick={() => {
                  handlePartSelect(
                    selectedAvailabilityHours,
                    totalOrderQty,
                    partDetails.descriptionID,
                    partDetails.pricingAvailability.costPrice,
                    proPackKey,
                    proPackKey
                  );
                }}
              >
                Select
              </Button>
            ) : (
              <AddToCart
              description={partDetails.description}
              disabled={
                _isEmpty(partDetails.pricingAvailability.availability) ||
                !_every(validity) ||
                totalAvailqty === 0
              }
              selectedAvailabilityHours={this.prepareOrderRequestData(
                singlePartData
              )}
              validity={validity}
              groupNumber={partDetails.groupNumber}
              source={"partCategory"}
              fromPage={"fullview"}
            />
            )}
            {!this.props.cart.hideCostPrice && !hideCostPricePref && !hideAllPrice && (
              <div>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    <Tooltip
                      id="extended-price-tooltip"
                      className="tooltip-description-custom-corePrice"
                    >
                      <span className="font-weight-bold">
                        Total Extended Price = Qty * (Your Price + Core Price)
                      </span>
                    </Tooltip>
                  }
                >
                  <span>Total Extended Price : </span>
                </OverlayTrigger>
                <span
                  className={extendedPriceHighlighter ? "bg-highlighter" : ""}
                >
                  $
                  {formatAmount(
                    getExtendedTotalPrice(
                      partDetails.pricingAvailability.corePrice,
                      partDetails.pricingAvailability.costPrice,
                      totalOrderQty
                    )
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="mt-2">
            {/* <FormValidationErrorMessage
							condition={
								!validity.doesQtyExist ||
								!validity.isQtyMoreThanOne
							}
							errorMessage={'Please enter a quantity'}
						/> */}
            <FormValidationErrorMessage
              condition={
                !validity.isQtyLessThanAvailable &&
                validity.doesQtyExist &&
                validity.isQtyMoreThanOne
              }
              errorMessage={`Please enter a quantity less than ${partsQtySum + 1
                }`}
            />
            <span className="full-view-cartText maroonColorTxt">
              {this.checkIsPartInCart(partDetails.partNumber, partDetails)}
            </span>
          </div>
        </div>
      );
    } else {
      availabilitySection.push(
        <div>
          <div className="add_to_cart_section">
            <span>
              <b>Currently out of stock</b>
            </span>
          </div>
        </div>
      );
    }

    return availabilitySection;
  };

  setShowImagesSliderPopup = (show) => {
    this.setState({
      showImagesSlider: show,
    });
  }  

  render() {
    let {
      partDetails,
      selectedVehicle: { year, makeId, modelId, engineId },
      laborApiCall,
      dispatch,
      userDetails: { userSecurityGroup, x2userName },
      isAltType,
      isSubType,
      partType,
      altPartNums,
      subPartNums,
      warrantyApiCall,
    } = this.props;

    let {
      showCoverToCover,
      showImagesSlider,
      showBuyersGuide,
      showLaborGuide,
      showInterchange,
      showVectorGfx,
      showLostSales,
      laborGuideDetails,
      appendPart,
      showAvailabilityStoreView,
      storeData,
      showWarrantyDetails,
      hideAllPrice,
    } = this.state;
    const imageURLCookie = getImageURLCookieDetails();
    let hideListPrice = hidePrice("Catalog", "listPrice");
    let hideCostPrice = hidePrice("Catalog", "costPrice");
    let image = partDetails.image
      ? `${partDetails.image}?${imageURLCookie}`
      : "";
    // let image = partDetails.image ? partDetails.image : '';

    if (_isEmpty(image)) {
      image = partDetails.thumbnail
        ? `data:image/png;base64,${partDetails.thumbnail}`
        : "/noImage.jpg";
    }
    let imageLbl =
      partDetails.images && partDetails.images.length === 1
        ? "image"
        : "images";
    return partDetails.extendedDescription &&
      partDetails.extendedDescription !== "" &&
      partDetails.partNumber !== "" ? (
      <div className={appendPart ? "box append-part" : "box"}>
        {isAltType || isSubType ? (
          <div className="product_img">
            <p class="alt-sub">{isAltType ? `Alternative` : `Superseded`}</p>
            <div className="product-image-container">
              <div className="image-thumbnail-container">
                <Image
                  className={
                    "image-thumbnail cursor-pointer"
                  }
                  src={image}
                  onClick={() => {
                    if (partDetails.images && partDetails.images.length) {
                      this.setState({
                        showImagesSlider: true,
                      });
                    }
                  }}
                  fluid
                />
              </div>
              {partDetails.images && partDetails.images.length > 0 && (
                <a
                  onClick={() => {
                    if (partDetails.images.length) {
                      this.setState({
                        showImagesSlider: true,
                      });
                    }
                  }}
                >
                  {partDetails.images.length} {imageLbl}
                </a>
              )}
            </div>
          </div>
        ) : (
          <div className="product_img">
            <div className="product-image-container">
              <div className="image-thumbnail-container">
                <Image
                  className={
                     "image-thumbnail cursor-pointer"
                  }
                  src={image}
                  onClick={() => {
                    // if (partDetails.images.length) {
                    this.setState({
                      showImagesSlider: true,
                    });
                    // }
                  }}
                  fluid
                />
              </div>
              {partDetails.images && partDetails.images.length > 0 && (
                <a
                  onClick={() => {
                    // if (partDetails.images.length) {
                    this.setState({
                      showImagesSlider: true,
                    });
                    // }
                  }}
                >
                  {partDetails.images.length} {imageLbl}
                </a>
              )}
            </div>
            {partDetails.mediaTag && partDetails.mediaTag === "Y" && (
              <a
                onClick={() => {
                  this.togglePopup("coverToCover");
                }}
              >
                Cover to Cover
              </a>
            )}
          </div>
        )}
        <div>
          <div className="product_detail_upper_section">
            <div className="product_detail">
              <span className="product_name">{partDetails.description}</span>
              <div className="brand_costPrice_listPrice">
                <span>Brand:</span>
                <span>{partDetails.mfgName}</span>
              </div>
              {!this.props.cart.hideCostPrice && !hideCostPrice && !hideAllPrice && (
                <div className="brand_costPrice_listPrice">
                  <span>Your Price:</span>
                  <span className="maroonColorTxt">
                    ${formatAmount(partDetails.pricingAvailability.costPrice)}
                  </span>
                </div>
              )}
              {!this.props.cart.hideCostPrice && !hideCostPrice && !hideAllPrice && (
                <div className="brand_costPrice_listPrice">
                  <span>Core Price:</span>
                  <span className="maroonColorTxt">
                    ${formatAmount(partDetails.pricingAvailability.corePrice)}
                  </span>
                </div>
              )}
              {hideListPrice ||hideAllPrice ? (
                ""
              ) : (
                <div className="brand_costPrice_listPrice">
                  <span>List Price:</span>
                  <span className="maroonColorTxt">
                    ${formatAmount(partDetails.pricingAvailability.listPrice)}
                  </span>
                </div>
              )}
              {showAvailabilityStoreView ?
              (<AvailabilityStoreView
              openPopup={showAvailabilityStoreView}
              closePopup={() => this.setState({showAvailabilityStoreView:false})}
              storeData={storeData}
              />
              )
              : null }
              <span className="part_no">Part# {partDetails.partNumber}</span>
              <PricingIcons
                toolTips={partDetails.pricingAvailability.toolTips}
                isProgrammable={isProgrammable(
                  partDetails?.miscellaneousTexts?.toString() || ""
                )}
              />
            </div>

            
              {  (userSecurityGroup ||
                  userSecurityGroup === null ||
                  userSecurityGroup.laborGuide ||
                  userSecurityGroup.buyersGuide ||
                  userSecurityGroup.interchange ||
                  userSecurityGroup.warranty ||
                  userSecurityGroup.vectorIllustration) && (
                <div className="grey_box">
                  <ul>
                    {(userSecurityGroup === null ||
                      userSecurityGroup.laborGuide) && (
                        <li
                          onClick={() => {
                            this.togglePopup("laborGuide");
                          }}
                        >
                          <span>Labor Guide</span>
                          <ReactSVG src={labourGuideIcon} className="icons" />
                        </li>
                      )}
                    {(userSecurityGroup === null ||
                      userSecurityGroup.vectorIllustration) &&
                      partDetails.gfxAvailable && (
                        <li
                          onClick={() => {
                            this.togglePopup("vectorGfx");
                          }}
                        >
                          <span>Part Diagram</span>
                          <ReactSVG
                            src={vectorIllustrationIcon}
                            className="icons"
                          />
                        </li>
                      )}
                    {(userSecurityGroup === null ||
                      userSecurityGroup.interchange) && (
                        <li
                          onClick={() => {
                            this.togglePopup("interchange");
                          }}
                        >
                          <span>Interchange</span>
                          <ReactSVG src={interchangeIcon} className="icons" />
                        </li>
                      )}
                    {(userSecurityGroup === null ||
                      userSecurityGroup.buyersGuide) && (
                        <li
                          onClick={() => {
                            this.togglePopup("buyersGuide");
                          }}
                        >
                          <span>Buyers Guide</span>
                          <ReactSVG src={buyerGuideIcon} className="icons" />
                        </li>
                      )}
                      {(userSecurityGroup === null ||
                      userSecurityGroup.warranty) && (
                      <li
                        onClick={() => {
                          this.togglePopup("warranty");
                        }}
                      >
                        <div className="warranty_theme">
                          <span>Warranty</span>
                          <img src={warrantyIcon}
                          alt="Warranty"
                           />
                           </div>
                      </li>
                    )}
                  </ul>
                  </div>
                )}
                
          

            <div className="prod_quantity_section">
              {this.renderAvailability()}
              <button
                onClick={() => {
                  this.setState({ showLostSales: true });
                }}
              >
                Lost Sales
              </button>
            </div>
          </div>
          <div className="product_detail_lower_section">
            <div className="item_specifics">
              <span>Item Specifics: </span>
              <span>{partDetails.pricingAvailability.itemSpecific}</span>
            </div>
            <div className="per_veh_years_position_container">
              <div>
                <span>Qty per vehicle: </span>
                <span className="text-black">{partDetails.perCarQty}</span>
              </div>
              <div>
                <span>Years: </span>
                <span className="text-black">{partDetails.years}</span>
              </div>
              {partDetails.position && (
                <div>
                  <span>Position:</span>
                  <span className="text-black">{partDetails.position}</span>
                </div>
              )}
            </div>
            {!_isEmpty(partDetails.unansweredSpecifics) &&
              partDetails.unansweredSpecifics.length > 0 && (
                <div className="specific_condition_description">
                  <span>Don’t know: </span>
                  {partDetails.unansweredSpecifics.map((value, index) => {
                    return (
                      <span className="text-black">
                        {partDetails.unansweredSpecifics.length == 1
                          ? `${value}`
                          : `${value}; `}
                      </span>
                    );
                  })}
                </div>
              )}
            {!_isEmpty(partDetails.miscellaneousTexts) && (
              <div className="prod_description">
                <span>Description:</span>
                <Highlighter
                  caseSensitive={false}
                  highlightClassName="program-highlightTxt"
                  searchWords={searchWords}
                  autoEscape={true}
                  textToHighlight={
                    partDetails.miscellaneousTexts
                      ? partDetails.miscellaneousTexts.toString()
                      : ""
                  }
                ></Highlighter>
                {/* <p>
                  {partDetails.miscellaneousTexts
                    ? partDetails.miscellaneousTexts.toString()
                    : ""}
                </p> */}
              </div>
            )}
            {appendPart && partType === "part" && (
              <div
                className={
                  !_isEmpty(altPartNums) || !_isEmpty(subPartNums)
                    ? "col-6 prod_description superseded_alt_parts"
                    : "col-6 prod_description"
                }
              >
                <p className="maroonColorTxt">
                  {!_isEmpty(altPartNums)
                    ? `Alternate Part ${altPartNums} `
                    : ""}
                  {!_isEmpty(subPartNums)
                    ? `Superseded Part ${subPartNums}`
                    : ""}
                </p>
              </div>
            )}
          </div>
        </div>

        {showCoverToCover ? (
          <CoverToCoverPopup
            openPopup={showCoverToCover}
            url={partDetails.c2cUrl}
            closePopup={this.togglePopup}
          />
        ) : null}
        {showImagesSlider ? (
          <ImagesSlider
            openPopup={showImagesSlider}
            closePopup={this.togglePopup}
            partDetails={partDetails}
            setShowImagesSlider={this.setShowImagesSliderPopup}
          />
        ) : null}
        {showBuyersGuide ? (
          <XModal
            show={showBuyersGuide}
            showButtons={false}
            title="Buyers Guide"
            customHeader={
              <React.Fragment>
                <Modal.Title id="warning-modal-title" className="pull-left">
                  Buyers Guide
                </Modal.Title>
                <div
                  className="close-btn"
                  onClick={() => this.togglePopup("buyersGuide")}
                >
                  <span className="material-icons">close</span>
                </div>
              </React.Fragment>
            }
            handleClose={() => this.togglePopup("buyersGuide")}
          >
            <BuyingGuideComponent
              selectedPartNo={partDetails.partNumber}
              selectedBrand={partDetails.mfgName}
              partDetails={partDetails}
              selectedPartType={partDetails.extendedDescription}
              selectedGroup={
                this.props.selectedGroupDetails &&
                this.props.selectedGroupDetails[0] &&
                this.props.selectedGroupDetails[0].name
              }
              c2cUrl={partDetails.c2cUrl}
            />
          </XModal>
        ) : null}

        {showLaborGuide && laborGuideDetails.laborItems && (
          <LaborGuide
            partNumber={partDetails.partNumber}
            openPopup={showLaborGuide}
            closePopup={this.togglePopup}
            data={laborGuideDetails}
            apiCallStatus={laborApiCall}
          />
        )}
         {showWarrantyDetails && (
          <Warranty
            show={showWarrantyDetails}
            partNumber={partDetails.partNumber}
            partData={partDetails}
            apiCallStatus={warrantyApiCall}
            partBrand={partDetails.mfgName}
            openPopup={showWarrantyDetails}
            closePopup={this.togglePopup}


          />
        )}

        {showInterchange && (
          <XModal
            show={showInterchange}
            showButtons={false}
            title="Interchange"
            customHeader={
              <React.Fragment>
                <Modal.Title id="warning-modal-title" className="pull-left">
                  Interchange
                </Modal.Title>
                <div
                  className="close-btn"
                  onClick={() => this.togglePopup("interchange")}
                >
                  <span className="material-icons">close</span>
                </div>
              </React.Fragment>
            }
            handl
            handleClose={() => this.togglePopup("interchange")}
          >
            <Interchange
              partNumber={partDetails.partNumber}
              partBrand={partDetails.mfgName}
              partType={partDetails.description}
              partLevel={true}
              catalog={true}
              dualBoxView={false}
            />
          </XModal>
        )}
        {showVectorGfx ? (
          <VectorGfxPopup
            engine={engineId}
            group={partDetails.groupNumber}
            descriptionID={partDetails.descriptionID}
            make={makeId}
            model={modelId}
            showPopUp={showVectorGfx}
            togglePopup={this.togglePopup}
            year={year}
          // filterPopup={filterPopup}
          />
        ) : null}
        {showLostSales ? (
          <LostSales
            openPopup={showLostSales}
            closePopup={() => {
              this.setState({ showLostSales: false });
            }}
            selectedPartDetails={[
              {
                availabilityHour:
                  partDetails.pricingAvailability.availability &&
                    partDetails.pricingAvailability.availability.length > 0
                    ? partDetails.pricingAvailability.availability[0]
                      .availableDuration
                    : null,
                availableQty:
                  partDetails.pricingAvailability.availability &&
                    partDetails.pricingAvailability.availability.length > 0
                    ? partDetails.pricingAvailability.availability[0]
                      .availableQty
                    : 0,
                mfgCode: partDetails.pricingAvailability.mfgCode,
                costPrice: partDetails.pricingAvailability.costPrice,
                desc: partDetails.description,
                partNum: partDetails.partNumber,
                qty: 0,
                vehicleInfo: `${this.props.selectedVehicle.year} ${this.props.selectedVehicle.make} ${this.props.selectedVehicle.model} ${this.props.selectedVehicle.engine}`,
              },
            ]}
            dispatch={dispatch}
            userId={x2userName}
            source="catalogPage"
            selectedVehicle={this.props.selectedVehicle}
          />
        ) : null}
      </div>
    ) : (
      <div className="boxedBorder">
        <span className="product_name">
          {partDetails.description}
          {""}
          {" - "}
          {partDetails.pricingAvailability.listPrice} Item(s)
        </span>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    categoryGroupMap: state.parts.categoryGroupMap,
    cart: state.cart,
    parts: state.parts,
    cartStatus: state.cart.cartAllDetails,
  };
}
export default connect(mapStateToProps)(SingleProduct);
