import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector} from "react-redux";
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import _isEmpty from "lodash/isEmpty";
import UpdateMobileNumber from './UpdateMobileNumber';
import ShipTo from '../../shared/ShipTo';
import UpdatePassword from './UpdatePassword';
import BillTo from './BillTo';
import SetUserPreference from './SetUserPreference';
import {getPreferenceDataFromStorage} from '../../../helpers/utils';
import CreateSuperAdmin from './CreateSuperAdmin';
import {
	refreshOtherTabs,
  } from '../UserManagement/usermanagement.slice';

const UserProfile = (props) => {
	const {
		handleInputChange,
		handleMobNumberOnChange,
		handleMobKeyDown,
		validity,
		mobValidity,
		fields,
		mobileNumber,
	} = props;
	const userDetailsArr = useSelector((state) => state.user);
	const [showChangeCustomerBillTo, SetShowChangeCustomerBillTo] = useState(false);
	const getKey = () => {
		let tempKey = 2;
	if (!_isEmpty(userDetailsArr) && !_isEmpty(userDetailsArr.userDetails)) {
		if (userDetailsArr.userDetails.userType === 'IT_ADMIN' || userDetailsArr.userDetails.userType === 'OSR' || userDetailsArr.userDetails.userType === 'DSM') {
			tempKey = 0
		}
	}
	return tempKey;
	}
	const [key, setkey] = useState(getKey());
	const [showChangeBillTO, SetShowChangeBillTO] = useState(false);
	const [showChangeShipTO, SetShowChangeShipTO] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
        let tempKey = 2;
	if (!_isEmpty(userDetailsArr) && !_isEmpty(userDetailsArr.userDetails)) {
		if (userDetailsArr.userDetails.userType === 'IT_ADMIN' ||  userDetailsArr.userDetails.userType === 'IT_INTERNAL' || userDetailsArr.userDetails.userType === 'OSR' || userDetailsArr.userDetails.userType === 'DSM' ) {
			tempKey = 0
		}
		setkey(tempKey);
	}
	}, []);

	useEffect(() => {
	  let tempKey = 2;
	  if (!_isEmpty(userDetailsArr) && !_isEmpty(userDetailsArr.userDetails)) {
	    if (userDetailsArr.userDetails.userType === 'IT_ADMIN' ||  userDetailsArr.userDetails.userType === 'IT_INTERNAL'  || userDetailsArr.userDetails.userType === 'OSR' || userDetailsArr.userDetails.userType === 'DSM') {
	      tempKey = 0
	    }
	    setkey(tempKey);
	  }

	  if (userDetailsArr.userDetails.userType !== null) {
	    let userPreferenceDetail = getPreferenceDataFromStorage();
	    if (!_isEmpty(userPreferenceDetail)) {
	      let grandAccessItemSet = userPreferenceDetail.find((item) => {
	        return (
	          item.text === "Grant Access To IT Admin" &&
	          item.code === "User Management"
	        )
	      })

	      if (grandAccessItemSet && grandAccessItemSet.multiAnswer) {
	        grandAccessItemSet.multiAnswer.map((singleAnswer) => {
	          if (singleAnswer.code == "enableEmulation") {
	            SetShowChangeBillTO(true);
	          }
	        })
	      }
	    }
	  }

	  if (userDetailsArr.userDetails.userType !== null) {
	    let userPreferenceDetail = getPreferenceDataFromStorage();
	    if (!_isEmpty(userPreferenceDetail)) {
	      let grandAccessItemSet = userPreferenceDetail.find((item) => {
	        return (
				item.text === "Personalize Linked Accounts" && 
				item.code === "User Management" ) 
	      })

	      if (grandAccessItemSet && grandAccessItemSet.multiAnswer) {
	        grandAccessItemSet.multiAnswer.map((singleAnswer) => {
	          if (singleAnswer.code === "enableUserEmulation") {
				if(userDetailsArr.userDetails.userType === 'CUSTOMER_BUYER' ||
				userDetailsArr.userDetails.userType === 'IT_INTERNAL' || 
				 userDetailsArr.userDetails.userType === 'LOCATION_BUYER' ||
				 userDetailsArr.userDetails.userType === 'CUSTOMER_ADMIN' ){
				 SetShowChangeCustomerBillTo(true);
				}
	          }
	        })
	      }
	    }
	  }


	  if (userDetailsArr.userDetails.xlPartAccountNumber && userDetailsArr.userDetails.xlPartAccountNumber !== null) {
	    let userPreferenceDetail = getPreferenceDataFromStorage();
	    if (!_isEmpty(userPreferenceDetail)) {
	      let grandAccessItemShipTo = userPreferenceDetail.find((item) => {
	        return (
	          item.text === "customerShipToAccess" &&
	          item.code === "User Management"
	        )
	      })

	      if (grandAccessItemShipTo && grandAccessItemShipTo.possibleAnswer && !_isEmpty(grandAccessItemShipTo.possibleAnswer)) {
	        grandAccessItemShipTo.possibleAnswer.map((singleAnswer) => {
	          if (singleAnswer.code == "shipTo-customer-list") {
	            let enabledShipTos = singleAnswer.text.split(';');
	            if (enabledShipTos.includes(userDetailsArr.userDetails.xlPartAccountNumber)) {
	              SetShowChangeShipTO(true);
	            }
	          }
	        })
	      }
	    }
	  }
	}, [userDetailsArr]);

    return (
        <div className="user-profile-container">
				<Tab.Container id="left-tabs-example" defaultActiveKey={key}>
					<Row className="row">
						<Col lg={3} md={4} sm={4} xs={6} className="horizontal-tabs">
							<Nav variant="pills" className="flex-column">
							{( (!_isEmpty(userDetailsArr) && (userDetailsArr.userDetails.userType === 'CUSTOMER_ADMIN' || userDetailsArr.userDetails.userType === 'SUPER_ADMIN') ))
								&& (
							<Nav.Item>
								 	<Nav.Link eventKey={5}>
										 Add another Account
										 
										 </Nav.Link>
								 </Nav.Item>)}

								{((key === 0 ) || ( showChangeBillTO))
								&& (
									<Nav.Item>
										<Nav.Link eventKey={0}>Change BillTo</Nav.Link>
									</Nav.Item>
								)}
									{( ((!_isEmpty(userDetailsArr) &&  userDetailsArr.userDetails.userType === 'SUPER_ADMIN') || showChangeCustomerBillTo ))
								&& (
									<Nav.Item>
										<Nav.Link eventKey={6}
										  onClick={() => {
											dispatch(refreshOtherTabs("billTo"));
										  }}
										>Change Customer</Nav.Link>
									</Nav.Item>
								)}
								 <Nav.Item>
								 	<Nav.Link eventKey={1}>Change ShipTo</Nav.Link>
								 </Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey={2}>Change Password</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey={3}>Change Mobile#</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey={4}>User Preferences</Nav.Link>
								</Nav.Item>

								{/* <Nav.Item>
									<Nav.Link eventKey="third">Change MFA Method</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="fourth">Notifications</Nav.Link>
								</Nav.Item> */}
							</Nav>
						</Col>
						<Col lg={9} md={8} sm={8} xs={6} className="horizontal-tab-details-container">
							<Tab.Content className="horizontal-tab-details">
							{( ((!_isEmpty(userDetailsArr) && (userDetailsArr.userDetails.userType === 'CUSTOMER_ADMIN' || userDetailsArr.userDetails.userType === 'SUPER_ADMIN') ) ))
								&& (
								<Tab.Pane eventKey={5}>
									<CreateSuperAdmin />
								</Tab.Pane>
								)}
								{((key === 0 ) || (showChangeBillTO))
								&& (
								<Tab.Pane eventKey={0}>
									<BillTo />
								</Tab.Pane>
								)}
								{(((!_isEmpty(userDetailsArr) && userDetailsArr.userDetails.userType === 'SUPER_ADMIN') || showChangeCustomerBillTo))
								&& (
								<Tab.Pane eventKey={6}>
									<BillTo />
								</Tab.Pane>
								)}
								<Tab.Pane eventKey={1}>
									<ShipTo />
								</Tab.Pane>
								<Tab.Pane eventKey={2}>
									<UpdatePassword
										handleInputChange={handleInputChange}
										validity={validity}
										fields={fields}
									/>
								</Tab.Pane>
								<Tab.Pane eventKey={3}>
                                    <UpdateMobileNumber
										handleMobNumberOnChange={handleMobNumberOnChange}
										validity={mobValidity}
										handleMobKeyDown={handleMobKeyDown}
										mobileNumber={mobileNumber}
									/>
								</Tab.Pane>
							    <Tab.Content className="horizontal-tab-details_preference">
								     <Tab.Pane eventKey={4}>
									   <SetUserPreference />
								     </Tab.Pane>
							    </Tab.Content>
								{/* <Tab.Pane eventKey="third" className="currentMfaContainer">
									<span>Current MFA Method</span>
									<div>
										<span>Current MFA Method</span>
										<div>
											<label class="customized-radio-button">
												<input type="radio" />
												<span>Text to Email</span>
											</label>
											<label class="customized-radio-button">
												<input type="radio" />
												<span>SMS to Mobile</span>
											</label>
											<label class="customized-radio-button">
												<input type="radio" />
												<span>Mobile App</span>
											</label>
										</div>
									</div>
									<Button>ok</Button>
								</Tab.Pane> */}
								{/* <Tab.Pane eventKey="fourth" className="notifications-tab">
									<div>
										<span>Promotions Notifications</span>
										<label class="customized-radio-button">
												<input type="radio" />
												<span>Yes</span>
											</label>
										<label class="customized-radio-button">
												<input type="radio" />
												<span>No</span>
											</label>
									</div>
									<div>
										<span>News Letter Notifications</span>
										<label class="customized-radio-button">
												<input type="radio" />
												<span>Yes</span>
											</label>
										<label class="customized-radio-button">
												<input type="radio" />
												<span>No</span>
											</label>
									</div>
									<Button>ok</Button>
								</Tab.Pane> */}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
		</div>
    );
}

export default UserProfile;
