import React from 'react';
import CarouselElement from "../../../components/Homepage/Categories/CarouselElement";
import * as images from '../images';
import { setCandKPartsPrice } from "../c_and_k.slice";

const imageNames = {
  Belts: 'Serpentine Belt',
  EngineFiltersPCV: 'Engine Filters',
  FuelPumpTankTurboSystem: 'Fuel Pump',
  OilFilter: 'Oil Filter',
  RadiatorCapFanMotorSwitch: 'Radiator Cap',
  SparkPlugs: 'Spark Plug',
  ThermostatGasketHousing: 'Thermostat',
  ValveTrainCylinderHeadParts: 'PCV Valve',
  WaterPumpGasketFanClutch: 'Water Pump',
  TopAirFilters: 'Air Filter',
  TopFuelFilters : 'Fuel Filter',
  MotorsTransmissionOil: 'Motors & Transmission Oil',
  Antifreeze: 'Antifreeze',
  WiperBlades: 'Wiper Blades',
  Rotors: 'Rotors',
  BrakePads: 'BrakePads',
  Gaskets: 'Gaskets',
  Chassis: 'Chassis' 
};

const actionTypes = {
    PART_LIST_DETAILS: 'PART_LIST_DETAILS',
    SUPPLIES_LIST_DETAILS: 'SUPPLIES_LIST_DETAILS',
    PARTS_NOTFOUND: 'PARTS_NOTFOUND',
    CNK_CART_CHECKOUT_FETCHED: 'CNK_CART_CHECKOUT_FETCHED'
};

export const getImageUrl = (partName) => {
  for (const [imageName, description] of Object.entries(imageNames)) {
    if (partName.toLowerCase().includes(description.toLowerCase())) {
      return images[imageName];
    }
  }
  return null;
};

export const modifyArray = (arr) => {
  return arr.map(item => {
    if (item) {
      const imageUrl = getImageUrl(item.name);
      return {     
        name: item.name || null,
        id:  Math.floor(Math.random() * 1000000000),
        groupIds: item.groupIds,
        imageUrl: imageUrl,
        catalogItem: item.catalogItem,
      };
    } else {
      return null;
    }
  }).filter(item => item !== null);
};

export const CustomLeftArrow = ({ onClick }) => (
  <a onClick={onClick} className="arrow-left" />
);

export const CustomRightArrow = ({ onClick }) => (
  <a onClick={onClick} className="arrow-right" />
);

export const renderCarouselElements = (visibleItems, handleCustomClick, selectedGroupDetails) => {
  return visibleItems.map((group, key) => (
    <CarouselElement
      group={group}
      handleClick={handleCustomClick}
      selectedGroupDetails={selectedGroupDetails}
      key={key}
    />
  ));
};

export const clearPartData = (dispatch) => {
  dispatch({
    payload: {
      isPartsNotFound: false,
    },
    type: actionTypes.PARTS_NOTFOUND,
  });
    dispatch({
        payload: {
          allBrands: [],
          availabilities: [],
          catalogPageNumber: 0,
          catalogPageSize: 0,
          catalogTotalPage: 0,
          catalogTotalItems: 0,
          partsAllDetails: [],
          positions: [],
          partTypes: [],
          getAllPartsByGroupApiCall: false,
        },
        type: actionTypes.PART_LIST_DETAILS,
      });
};

export const clearChemicalData = (dispatch) => {
  dispatch({
    payload: {
      isPartsNotFound: false,
    },
    type: actionTypes.PARTS_NOTFOUND,
  });
    dispatch({
        payload: {
          suppliesAllDetails: [],
          suppliesAllBrands: [],
          suppliesAvailability: [],
          pageNumber: 1,
          totalPages: 0,
          totalItems: 0,
          pageSize: 1,
        },
        type: actionTypes.SUPPLIES_LIST_DETAILS,
      });
};

export const clearCnKParts = (dispatch) => {
  dispatch(setCandKPartsPrice(0));
  dispatch({
    payload: {
      candkCheckoutDetails: []
    },
    type: actionTypes.CNK_CART_CHECKOUT_FETCHED,
  });
};

