import httpClient from "../../services/http";

export const getBenefitDetailsService = (payload) =>
  httpClient.get(
    `/user-service/programBenefits/${payload.name}?customerId=${payload.customerId}&noOfMonths=${payload.noOfMonths}`
  );

export const getProgramsListDetails = (customerNumber) =>
  httpClient.get(`/utils-service/userDefineList/${customerNumber}`);

//Statement And Invoice
export const getProgramsAndStatmentService = (data) =>
  httpClient.post(
    `/utils-service/statement/programsBenefits?partType=${data.params}`,
    data.requestItems
  );

export const getBatteryStockDetailsService = ({ payload }) => {
  return httpClient.post(`/catalog/programBenefits/invoices`, payload);
};

export const getProgramBenefitsHelpService = () =>
  httpClient.get("/utils-service/programsBenefits/help");

// Customer Dashboard Service
export const getDashboardService = ({ customerId, noOfMonths, location }) =>
  httpClient.get(
    `user-service/programBenefits/customerDashBoard?customerId=${customerId}&noOfMonths=${noOfMonths}&location=${location}`
  );
