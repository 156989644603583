import _ from "lodash";
import {
  benefitDetails,
  getBatteryStockDetails,
  programsListDetails,
  programsStatementDetails,
  programDocumentDetails,
  getDashboard,
} from "./programbenefits.thunk";
import { currencyFormatter, getMonth } from "helpers/utils";
import moment from "moment";
const { createSlice } = require("@reduxjs/toolkit");

let initialState = {
  loading: false,
  isDashboardLoaded: false,
  benefitData: [],
  programList: [],
  statementsList: [],
  acdGmPartnerPerksGraphData: [],
  prontoChoicesGraphData: [],
  stockingBatteries: [],
  programDocument: [],
  mtaPsnYearlyGraphData: [],
  batteryProgram: {},
  laborClaim: null,
  xlpRewards: null,
  volumeRebate: null,
  prontoSmartChoice: null,
  dashboard: null,
};

const ProgramAndBenefits = createSlice({
  name: "ProgramAndBenefits",
  initialState,
  reducers: {
    resetDigest: (state) => {
      state.digest = null;
    },
    resetBenefitData: (state) => {
      state.benefitData = [];
    },
  },
  extraReducers: {
    [benefitDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [benefitDetails.fulfilled]: (state, action) => {
      state.loading = false;

      const {
        meta: {
          arg: { name },
        },
      } = action;

      switch (name) {
        case "acdGmPartner":
          const partnerPerks = Array.isArray(action.payload)
            ? _.head(action.payload)
            : action.payload;

          const partnerPerksYearly =
            partnerPerks?.acdGmPartnerYearlySales || [];

          const gmYears = _.uniq(_.map(partnerPerksYearly, (mly) => mly.year));
          const partnerPerksGroups = _.groupBy(partnerPerksYearly, "month");
          const acdCategorySalesGraphData =
            partnerPerks.acdGMLYCategorySalesList || [];
          const gmGroups = Object.entries(partnerPerksGroups).map((group) => {
            const key = group[0];
            const value = group[1];

            let _group = {};
            gmYears.forEach((year) => {
              const group = value.find((v) => v.year === year);
              if (group) {
                _group = {
                  ..._group,
                  [year]: Number(group.yearlySales),
                  name: getMonth(group.month),
                };
              } else {
                _group = {
                  ..._group,
                  [year]: null,
                  name: getMonth(key || key),
                };
              }
            });

            return _group;
          });

          state.acdGmPartnerPerksGraphData = gmGroups || [];
          state.acdCategorySalesGraphData = acdCategorySalesGraphData;
          state.gmPartnerPerks = action.payload;
          break;
        case "mtapsn":
          const payload = action.payload;
          const mtapsn = Array.isArray(payload)
            ? _.head(action.payload)
            : payload;
          const mtaPsnYearly = mtapsn.mtaYearlyResponses || [];
          const mtaCategorySalesGraphData =
            (mtapsn.mtaCategorySalesList &&
              mtapsn.mtaCategorySalesList.map((s) => {
                return { ...s, sales: Number(s.sales) };
              })) ||
            [];

          const yearlySales = mtapsn.mtalyYearlySales;
          const currYear = moment().year();

          const currentYearPSN = yearlySales?.find(
            (y) => Number(y.year) === currYear
          );

          const mtaTotalSales = currentYearPSN
            ? `${currencyFormatter(currentYearPSN?.yearlyMTASales)}`
            : yearlySales && yearlySales.length > 0
            ? `${currencyFormatter(
                yearlySales[yearlySales.length - 1].yearlyMTASales
              )}`
            : " No Data";

          const mtaYears = _.uniq(_.map(mtaPsnYearly, (mly) => mly.monthYear));
          const mtaGroups = _.groupBy(mtaPsnYearly, "mtaMonth");

          const mGroups = Object.entries(mtaGroups).map((group) => {
            const key = group[0];
            const value = group[1];

            let _group = {};

            mtaYears.forEach((year) => {
              const group = value.find((v) => v.monthYear === year);

              if (group) {
                _group = {
                  ..._group,
                  [year]: Number(group.mtaTotalSales),
                  name: getMonth(group.mtaAmount || group.mtaMonth),
                };
              } else {
                _group = {
                  ..._group,
                  [year]: null,
                  name: getMonth(key || key),
                };
              }
            });

            return _group;
          });

          state.mtaPsnYearlyGraphData = mGroups || [];
          state.mtaCategorySalesGraphData = mtaCategorySalesGraphData;
          state.mtapsn = { ...mtapsn, mtaTotalSales };
          break;
        case "batteryConsignment":
          state.batteryProgram = action.payload;
          break;
        case "laborClaim":
          const laborClaim = action.payload;
          const yearlyLaborClaims = laborClaim.yearlyLaborClaims;
          const currentYear = moment().year();

          const currentYearLabor = yearlyLaborClaims?.find(
            (y) => Number(y.year) === currentYear
          );

          const laborVsPurchases = currentYearLabor
            ? `${Number(currentYearLabor?.laborPercentage).toFixed(2)}%`
            : yearlyLaborClaims && yearlyLaborClaims.length > 0
            ? `${Number(
                yearlyLaborClaims[yearlyLaborClaims.length - 1].laborPercentage
              ).toFixed(2)}%`
            : " No Data";

          state.laborClaim = { ...laborClaim, laborVsPurchases };
          break;
        case "xlpRewards":
          state.xlpRewards = action.payload;
          break;
        case "pvpVolumeRebate":
          state.volumeRebate = action.payload;
          break;
        case "prontoChoice":
          const pronto = Array.isArray(action.payload)
            ? _.head(action.payload)
            : action.payload;

          const prontoYearly = pronto?.prontoChoiceYearlyGrowths || [];

          const prontoYears = _.uniq(_.map(prontoYearly, (mly) => mly.year));
          const prontoGroups = _.groupBy(prontoYearly, "month");

          const pGroups = Object.entries(prontoGroups).map((group) => {
            const key = group[0];
            const value = group[1];

            let _group = {};
            prontoYears.forEach((year) => {
              const group = value.find((v) => v.year === year);
              if (group) {
                _group = {
                  ..._group,
                  [year]: group.yearlySales,
                  name: getMonth(group.month),
                };
              } else {
                _group = {
                  ..._group,
                  [year]: null,
                  name: getMonth(key || key),
                };
              }
            });

            return _group;
          });

          state.prontoChoicesGraphData = pGroups || [];
          state.prontoSmartChoice = action.payload;
          break;
        default:
          break;
      }

      state.benefitData = action.payload || [];
    },
    [benefitDetails.rejected]: (state, action) => {
      state.loading = false;
      state.benefitData = [];
    },
    [programsListDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [programsListDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.programList = action.payload || [];
    },
    [programsListDetails.rejected]: (state, action) => {
      state.loading = false;
      state.programList = [];
    },
    [programsStatementDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [programsStatementDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.statementsList = action.payload || [];
    },
    [programsStatementDetails.rejected]: (state, action) => {
      state.loading = false;
      state.statementsList = [];
    },
    [getBatteryStockDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getBatteryStockDetails.fulfilled]: (state, action) => {
      const { payload } = action;
      const { totalElements, message } = payload;

      console.log("stocking batteries ==> ", payload);

      state.stockingBatteries = payload;
      state.totalElements = totalElements;
      state.warningMessage = message === "partial" ? true : false;
      state.loading = false;

      let data = action.payload || [];
      // Filter out the  false items.
      data = data.filter((d) => !d.inProgress);
      if (data && data.length === 1) {
        const d = data[0];

        if (
          d &&
          d.error &&
          (d.error === "Invalid invocation" ||
            d.error === "No ROP records found for the customer")
        ) {
          state.stockingBatteries = [];
        } else {
          state.stockingBatteries = data;
        }
      } else {
        state.stockingBatteries = data;
      }
    },
    [getBatteryStockDetails.rejected]: (state, action) => {
      state.stockingBatteries = [];
      state.loading = false;
    },
    [programDocumentDetails.pending]: (state, action) => {
      state.loading = true;
    },

    [programDocumentDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.programDocument = action.payload || [];
    },
    [programDocumentDetails.rejected]: (state, action) => {
      state.loading = false;
      state.programDocument = [];
    },
    [getDashboard.pending]: (state, action) => {
      state.loading = true;
      state.isDashboardLoaded = false;
    },
    [getDashboard.fulfilled]: (state, action) => {
      state.dashboard = action.payload;
      state.loading = false;
      state.isDashboardLoaded = true;
    },
    [getDashboard.rejected]: (state, action) => {
      state.dashboard = null;
      state.loading = false;
      state.isDashboardLoaded = false;
    },
  },
});

const { actions, reducer } = ProgramAndBenefits;

export const { resetBenefitData } = actions;

export default reducer;
