/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { benefitDetails } from "./../programbenefits.thunk";
import { useDispatch, useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import {
  formatAmount,
  formatAmountPrograms,
  getMonth,
} from "../../../helpers/utils";
import _isEmpty from "lodash/isEmpty";
import XCharts from "components/Charts";
import { currencyFormatter } from "../../../helpers/utils";

const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const ProntoProgram = (props) => {
  const { userDef } = props;
  const dispatch = useDispatch();
  const [prontoData, setProntoData] = useState([]);
  const {
    loading = false,
    prontoChoicesGraphData,
    prontoSmartChoice,
    benefitData: {
      totalSales,
      totalBilling,
      totalLYSales,
      prontoChoices,
      totalEarnBack,
    },
  } = useSelector((state) => state.ProgramAndBenefits);
  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;
  const thresholdPoins = [
    {
      monthminSales: "6000",
      monthlymaxSalesTier: "7999.99",
      growth: "> 0",
      earnBack: "35.00",
    },
    {
      monthminSales: "8000",
      monthlymaxSalesTier: "9999.99",
      growth: "> 0",
      earnBack: "70.00",
    },
    {
      monthminSales: "10000",
      monthlymaxSalesTier: "14999.99",
      growth: "> 0",
      earnBack: "105.00",
    },
    {
      monthminSales: "15000",
      monthlymaxSalesTier: "99999.00",
      growth: "No Growth Required",
      earnBack: "140.00",
    },
  ];

  useEffect(() => {
    if (!isEmpty(prontoChoices)) {
      setProntoData(prontoChoices);
    }
    dispatch({
      payload: {
        globalLoader: loading,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
  }, [prontoChoices, loading]);

  const getAllDetails = () => {
    const { xlPartAccountNumber } = userDetails || {};
    let customerNumber = xlPartAccountNumber;
    const payload = {
      name: "prontoChoice",
      customerId: customerNumber,
      noOfMonths: 11,
    };
    dispatch(benefitDetails(payload)).then((action) => {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
    }).catch((error) => {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
    });
  };

  useEffect(() => {
    getAllDetails();
  }, []);

  const getDataKeys = () => {
    const prontoChoiceYearlyGrowths =
      prontoSmartChoice?.prontoChoiceYearlyGrowths || [];
    const years = _.uniq(_.map(prontoChoiceYearlyGrowths, (a) => a.year));
    const colors = ["#36AD3F", "#D71958", "#1604d9"];
    const keys = years.map((y, i) => {
      return { value: y, color: colors[i] };
    });

    return keys;
  };
  const dollarRoundOff = (dollar) => {
    //converts dollar to number and rounds it off
    return currencyFormatter(Math.round(Number(dollar))).split('.')[0];
  };
  const getColorCode = (value) => {
    if (value < 6000) {
      return "#00000";
    } else if (value < 8000) {
      return "#ff0000";
    } else if (value < 10000) {
      return "#ffa500";
    } else if (value < 15000) {
      return "#ffff00";
    } else if (value >= 15000) {
      return "#008000";
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="custom_spinner_container">
          {/* <Spinner className="custom_spinner" animation="border" /> */}
        </div>
      ) : (
        <React.Fragment>
          {!_isEmpty(userDef) &&
            !_isEmpty(userDef.userDef9) &&
            userDef.userDef9.includes("PSC") && (
              <span className="thresholdname">PSC Standard Program</span>
            )}
          {!_isEmpty(userDef) &&
            !_isEmpty(userDef.userDef9) &&
            userDef.userDef9.includes("PLUS") && (
              <div className="thresholdpoints acd-info-details">
                <span className="thresholdname">
                  PSC PLUS Program Earn-Back Table
                </span>
                <div class="tooltip-custom">
                  <a className="info-icon">
                    <i class="fa fa-info-circle" />
                  </a>
                  <div class="tooltiptext acd-gm-tooltip">
                    <div className="xlprewards-wrapper">
                      <div className="container">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>MONTHLY MIN SALES</th>
                              <th>MONTHLY MAX SALES TIER</th>
                              <th>GROWTH VS LY MONTH</th>
                              <th>Earn Back</th>
                            </tr>
                          </thead>
                          <tbody>
                            {thresholdPoins &&
                              thresholdPoins.map((data, i) => (
                                <tr key={i}>
                                  <td className="maroonColorTxt">
                                    {currencyFormatter(data.monthminSales)}
                                  </td>
                                  <td className="maroonColorTxt">
                                    
                                    {currencyFormatter(
                                      data.monthlymaxSalesTier
                                    )}
                                  </td>
                                  <td className="">
                                    {data.growth}{" "}
                                    <span
                                      className="colorcode"
                                      style={{
                                        backgroundColor: getColorCode(
                                          data.monthminSales
                                        ),
                                      }}
                                    />
                                  </td>
                                  <td className="maroonColorTxt">
                                    {currencyFormatter(data.earnBack)}
                                  </td>
                                </tr>
                              ))}
                            {isEmpty(prontoData) && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="xlprewards-wrapper-pronto">
            {prontoData && prontoData.length > 0 && (
                <div className="psc-plus-xlcharts">
                  <div className="report-card">
                    <div className="rcard-title">Sales for last 3 years</div>
                    <div className="rcard-content">
                        <XCharts
                          chartType="line"
                          data={prontoChoicesGraphData || []}
                          dataKeys={getDataKeys()}
                        />
                      </div>
                    </div>
                  </div>
            )}

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Month</th>
                    {!_isEmpty(userDef) &&
                      !_isEmpty(userDef.userDef9) &&
                      userDef.userDef9.includes("PLUS") && <th>Billing</th>}
                    <th>Sales(Year)</th>
                    <th>Last Year Sales(Year)</th>
                    <th>Growth</th>
                    {!_isEmpty(userDef) &&
                      !_isEmpty(userDef.userDef9) &&
                      userDef.userDef9.includes("PLUS") && <th>Earn Back</th>}
                    {!_isEmpty(userDef) &&
                      !_isEmpty(userDef.userDef9) &&
                      userDef.userDef9.includes("PLUS") && (
                        <th>Credit Memos</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {prontoData &&
                    prontoData.length > 0 &&
                    prontoData.map((data, i) => (
                      <tr key={i}>
                        <td align="center">{getMonth(data.monthYear)}</td>
                        {!_isEmpty(userDef) &&
                          !_isEmpty(userDef.userDef9) &&
                          userDef.userDef9.includes("PLUS") && (
                            <td align="right" className="maroonColorTxt">
                              {dollarRoundOff(data.billing)}
                            </td>
                          )}
                        <td align="right" className="maroonColorTxt">
                          {dollarRoundOff(data.sales)} (
                          {data && data?.monthYear?.split("-")[1]})
                        </td>
                        <td align="right" className="maroonColorTxt">
                          {dollarRoundOff(data.salesLYMonth)} (
                          {Number(data && data?.monthYear?.split("-")[1]) - 1})
                        </td>
                        <td
                          align="right"
                          className={
                            Number(data.growth) > 0
                              ? "td-background-light"
                              : "td-background-lightred"
                          }
                        >
                          {formatAmountPrograms(
                            data.growth === "Infinity" || null ? 0 : data.growth
                          )}
                          %
                        </td>
                        {!_isEmpty(userDef) &&
                          !_isEmpty(userDef.userDef9) &&
                          userDef.userDef9.includes("PLUS") && (
                            <td align="right" className="maroonColorTxt">
                              {data.earnBack === null ? "-" : dollarRoundOff(data.earnBack)}
                            </td>
                          )}
                        {!_isEmpty(userDef) &&
                          !_isEmpty(userDef.userDef9) &&
                          userDef.userDef9.includes("PLUS") && (
                            <td align="center" className="maroonColorTxt">
                              {data.invNumber != "0" ? (
                                <a
                                  className="maroonColorTxt link-over-programs"
                                  onClick={() => {
                                    window.open(data.invUrl, "_blank");
                                  }}
                                >
                                  {data.invNumber}
                                </a>
                              ) : (
                                ""
                              )}
                            </td>
                          )}
                        {/* <a class="pointerPrgms"
												onClick={() => statements(getMonthAndYear(data.monthYear))}
												>
												{data.earnBack === null ? '-' : data.earnBack}
												</a>{' '} */}
                      </tr>
                    ))}
                  {prontoData && prontoData.length > 0 && (
                    <tr>
                      <td
                        align="left"
                        className="font-weight-bold td-background-totalrow"
                      >
                        Total
                      </td>
                      {!_isEmpty(userDef) &&
                        !_isEmpty(userDef.userDef9) &&
                        userDef.userDef9.includes("PLUS") && (
                      <td
                        align="right"
                        className="maroonColorTxt bolder td-background-totalrow"
                      >
                        {dollarRoundOff(totalBilling)}
                      </td>
                      )}
                      
                      <td
                        align="right"
                        className="maroonColorTxt bolder td-background-totalrow"
                      >
                        {dollarRoundOff(totalSales)}
                      </td>
                      <td
                        align="right"
                        className="maroonColorTxt bolder td-background-totalrow"
                      >
                        {dollarRoundOff(totalLYSales)}
                      </td>
                      <td
                        align="right"
                        className="maroonColorTxt bolder td-background-totalrow"
                      />
                      {!_isEmpty(userDef) &&
                        !_isEmpty(userDef.userDef9) &&
                        userDef.userDef9.includes("PLUS") && (
                          <td
                            align="right"
                            className="maroonColorTxt bolder td-background-totalrow"
                          >
                            {dollarRoundOff(totalEarnBack)}
                          </td>
                        )}
                    </tr>
                  )}
                </tbody>
              </table>

          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default ProntoProgram;
