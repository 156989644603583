import React, { Component } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';

class ToastMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  isToastDisplayed: false,
		};
	  }

	  componentDidUpdate(prevProps) {
		let { dispatch, toastManager } = this.props;
		const { isToastDisplayed } = this.state;
		if (!_isEqual(this.props, prevProps)) {
		  if (!_isEqual(this.props.errors, prevProps.errors)) {
			this.props.errors && this.props.errors.map((singleError) => {
			  const content = (
				<div>
				  <strong>{singleError.heading}</strong>
				  <div>{singleError.message}</div>
				</div>
			  );

			  if (!isToastDisplayed && singleError.message !== 'manualDismiss') {
				toastManager.add(content, {
				  appearance: singleError.type || 'error',
				  // autoDismiss: singleError.type !== 'error',
				  autoDismiss : true,
				  autoDismissTimeout : 15000,
				  onDismiss: () => {this.setToastDisplayed(false)},
				});
				this.setToastDisplayed(true)
			  } else if (singleError.message === 'manualDismiss' && isToastDisplayed) {
				// Remove all currently displayed toasts
				toastManager.removeAll();
				this.setToastDisplayed(false)
			  }

			  dispatch({
				payload: {
				  error: singleError,
				},
				type: 'DISMISS_UX_CRITICAL_ERROR',
			  });
			});
		  }
		}
	  }
	  render() {
		return null;
	  }

	  setToastDisplayed(isVisible) {
		let { dispatch, toastManager } = this.props;
		this.setState({ isToastDisplayed: isVisible })
		dispatch({
		  payload: {
			isToastDisplayed: isVisible,
		  },
		  type: 'SET_TOAST_DISPLAYED',
		});
	  }
	}

function mapStateToProps(state) {
	return {
    errors: state.app.errors,
  };
}
export default withToastManager(connect(mapStateToProps)(ToastMessage));

// const ToastMessage2 = (props) =>{

// }
