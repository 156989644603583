import React, { useState } from 'react';
import XModal from "./XModal";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import _find from "lodash/find";
import { cloneDeep } from 'lodash';
import Locations from "../Locations";
import LocationOnIcon from '@mui/icons-material/LocationOn';

function AvailabilityStoreView({ openPopup, closePopup, storeData, dispatch }) {
  //const storeDetails = useSelector((state) => state.mygarage.storeDetails);
  const storeOptions = useSelector((state) => state.mygarage.storeOptions);
  const [showLocationsPopup, setShowLocationsPopup] = useState(false);

  let modifiedStoreData = cloneDeep(storeData)
  modifiedStoreData?.sort((a, b) => a.store - b.store);
  modifiedStoreData?.forEach((v, i) => {
    let storeArr  = v.store?.split(" ")
    let storename = _find(storeOptions, {
      storeNum: storeArr?.[0],
    })?.storeName
    v.storeNum = storeArr?.[0];
    v.store = `#${v.store}, ${storename ? storename : ""}`
  })

  let columns = [
    {
      dataField: "store",
      text: "Store",
    },
    {
      dataField: "storeQty",
      text: "Qty",
    }]
  return (
    <React.Fragment>
      <XModal
        className="store-view-modal"
        title="Store Availability"
        show={openPopup}
        handleClose={closePopup}
        handleAction={closePopup}
        favoriteTextValidity={true}
        submitType={true}
        submitButtonText="OK"
      >
        <div style={{ textAlign: "center" }}>
          <div className="store-availability-table">
            <BootstrapTable
              keyField="id"
              data={modifiedStoreData}
              columns={columns}
            />
          </div>
        </ div>
        <div style={{ textAlign: "center" }}>
          <Button
            className='store-view-locations-btn'
            // style={{ marginLeft: "9px" }}
            onClick={() => {
              setShowLocationsPopup(true);
            }}
          >
            <LocationOnIcon/>
            {"  "} { } Stores in Map
          </Button>
        </ div>
      </XModal>
      {showLocationsPopup && (
        <XModal
          show={showLocationsPopup}
          showButtons={false}
          title={`Store Locations`}
          customHeader={
            <React.Fragment>
              <Modal.Title id="warning-modal-title" className="pull-left">
                Store Locations
              </Modal.Title>
              <div
                className="close-btn"
                onClick={() => { setShowLocationsPopup(false); }}
              >
                <span className="material-icons">close</span>
              </div>
            </React.Fragment>
          }
          handleClose={() => { setShowLocationsPopup(false); }}
        >
          <Locations availabilityStores={modifiedStoreData} />
        </XModal>
      )}
    </React.Fragment>
  )
}

export default AvailabilityStoreView;