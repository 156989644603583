import React, { useState } from 'react';

import { clearCart, submitLostSales, submitLostSalesFromQuotes, deleteItemFromCart2 } from '../../actions/cart';
import XModal from "../shared/XModal";
import UserFeedback from './UserFeedback';
import _every from 'lodash/every';
import _head from 'lodash/head';
import { useHistory } from 'react-router-dom';

const actionTypes = {
	CNK_CART_CHECKOUT_FETCHED: 'CNK_CART_CHECKOUT_FETCHED'
  };

function LostSales({ openPopup, closePopup, selectedPartDetails, dispatch, userId, source, selectedVehicle, handleQuoteRemove, quoteId, isCart = false, isCandK = false, candkCheckoutDetails= {} }) {
	const history = useHistory();

	const handleInputChange = (e, key) => {
		console.log('e.target.value', e.target.value, key)
	}


	const deleteItemFromCandk = (array, desc, partNum) => {		
		const filteredArray = array.filter(item => {
		  const match = !(item.desc === desc && item.partNum === partNum);
		  return match;
		});
		return filteredArray;
	  };


	const handleLostSaleSubmit = () => {
		if (isCandK && candkCheckoutDetails) {
			const updatedCheckoutDetails = deleteItemFromCandk(
			  candkCheckoutDetails,
			  selectedPartDetails[0]?.desc,
			  selectedPartDetails[0]?.partNum
			);
		  
			dispatch({
			  payload: {
				candkCheckoutDetails: updatedCheckoutDetails
			  },
			  type: actionTypes.CNK_CART_CHECKOUT_FETCHED,
			});
			closePopup(false);
			return;
		}

		const modified = selectedPartDetails.map(item => {
			return {
				availabilityDuration: item.availabilityHour,
				availableQty: item.availableQty,
				brandName: item.mfgCode,
				costPrice: item.costPrice,
				partDesc: item.desc,
				partNum: item.partNum,
				selectedQty: item.qty,
				defStoreQty :item.availabilityHour == "00:45:00" || item.availabilityHour == "Immediately" ? item.availableQty : 0,
				userId: userId,
				userResponse: {
					availability:item.availabilityChecked ? item.propAvailability : undefined,
					brand: item.brandChecked ? item.propBrand :  undefined,
					otherReason: item.otherChecked ? item.propOther : undefined,
					customer: item.notInterested ? ['My customer is no longer interested.'] : undefined,
					betterPrice: item.priceChecked ? item.betterPrice : undefined,
					fromWD: item.priceChecked ? item.fromWD : undefined
				},
				vehicle: item.vehicleInfo
			}
		})

		if (source === 'removeQuotes') {
			let params = {quoteId:quoteId}
			submitLostSalesFromQuotes(modified, params, dispatch);
		}else{
			submitLostSales(modified, dispatch);
		}

		if (source === 'catalogPage') {
			closePopup(false)
		} else if (source === 'removeQuotes') {
			handleQuoteRemove()
		} else {
			if (modified.length === 1) {
				const deletePart = {
					availabilityHour: _head(selectedPartDetails).availabilityHour,
					partNum: _head(selectedPartDetails).partNum,
					qty: _head(selectedPartDetails).qty,
					vehicleInfo: _head(selectedPartDetails).vehicleInfo,
					mfgCode: _head(selectedPartDetails).mfgCode,
					pricingType: _head(selectedPartDetails).pricingType,

				}
				deleteItemFromCart2(deletePart, dispatch, isCart)
			} else {
				clearCart(dispatch);
			}
			if (source === 'clearCartBtn') {
				history.push('/')
			}
		}

		closePopup(false)
	}

	const vehicleSelected = selectedVehicle ? `${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}` : ''
	const [validity, setValidity] = useState();

	const handleValidity = (typeValidity) => {
		setValidity(typeValidity);
	}
	return (
		<XModal
			show={openPopup}
			handleClose={closePopup}
			title="Lost Sales - Check all applicable reasons "
			handleAction={handleLostSaleSubmit}
			isValidValidity={_every(validity)}
			yesButtonText="Submit"
			noButtonText="Cancel"
		>
			<div className="lost_sales_modal_body">
				{
					selectedPartDetails.map((item, key) => (
						<UserFeedback
							setValidityBtn={handleValidity}
							itemPart={item}
							partNum={item.partNumber || item.partNum}
							chemicalPartNum={item.partSKU || item.partNum}
							desc={item.description ? item.description : item.desc}
							chemicalDesc={item.partDescription ? item.partDescription : ''}
							vehicleInfo={vehicleSelected ? vehicleSelected : item.vehicleInfo}
							handleInputChange={handleInputChange}
							key={key}
						/>
					))
				}
			</div>
		</XModal>
	)
}

export default LostSales;