import React, { useEffect, useState } from "react";
import T from "prop-types";

import "./styles.scss";
import OEMSingleView from "../SingleView";
import _ from "lodash";
import { useSelector,useDispatch } from "react-redux";
import CKReviewPopUp from "../Checkout/CK_ReviewPopUp";
import { getCKReviewSelectedOrder } from "../c_and_k.thunk";

function OEMFullView(props) {
  const { items, filter } = props;

  const candk = useSelector((state) => state.candk);
  const { ckorder } = candk;
  const [groups, setGroups] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isCallType,setIsCallType]=useState(false)
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      getCKReviewSelectedOrder({ ...ckorder, show: false, warrantyData: {} })
    );

  };

  const getGroupTitle = (key) => {
    switch (key) {
      case "RemanPowertrain":
        return "Reman Parts";
      default:
        return "Used Parts";
    }
  };

  useEffect(() => {
    switch (filter) {
      case "reman":
        const reman = items.filter(
          (item) => item.partCatalog === "RemanPowertrain"
        );
        const _groupsReman = _.groupBy(reman, (item) => item.partCatalog);
        setGroups(_groupsReman);
        break;
      case "used":
        const used = items.filter(
          (item) => item.partCatalog === "UsedPowertrain"
        );
        const _groupsUsed = _.groupBy(used, (item) => item.partCatalog);

        setGroups(_groupsUsed);
        break;
      case null:
        setGroups([]);
        break;
      default:
        const groups = _.groupBy(items, (item) => item.partCatalog);
        setGroups(groups);
        break;
    }
  }, [items, filter]);

  return (
    <div className="oem-full-view">
      <div className="view fv-left">
        {!filter ? (
          <div className="msg">
            Please select filter(Reman or Used Parts) from the above.
          </div>
        ) : _.isEmpty(groups || []) && candk.isLoadingComplete ? (
          <div className="msg">Parts not found</div>
        ) : (
          Object.entries(groups).map((group, i) => {
            const parts = group[1];
            const title = group[0];
            return (
              <div className="group" key={`group-${i}`}>
                <div className="group-title">
                  {getGroupTitle(title)}{" "}
                  <span className="small">({parts.length} parts)</span>
                </div>
                {parts &&
                  parts.map((item, j) => {
                    return (
                      <OEMSingleView partDetails={item} key={`item-${j}`} showPopup={showPopup} setShowPopup={setShowPopup} setIsCallType={setIsCallType} />
                    );
                  })}
              </div>
            );
          })
        )}
      </div>
      {showPopup && (
        <CKReviewPopUp show={ckorder?.show} handleClose={handleClose} isCallType={isCallType} />
      )}
    </div>
  );
}

OEMFullView.propTypes = {
  filter: T.oneOf(["both", "reman", "used"]),
  items: T.array,
};

export default OEMFullView;
