import httpClient from "../../../services/http";

const buildQueryUrl = (payload) => {
    const params = [];
    for (const k in payload) {
      if (payload.hasOwnProperty(k)) {
        const element = payload[k];
        params.push(encodeURIComponent(k) + "=" + encodeURIComponent(element));
      }
    }
    return params.join("&");
  };

export const getShipToIds = (data) =>
    httpClient.get(`/user-service/shipto?${buildQueryUrl(data)}`);

    export const getUserShipToIds = () =>
    httpClient.get(`/user-service/user/shipto`);