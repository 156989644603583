import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QueryString from 'query-string'
import storage from '../../helpers/storage'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
// import XLLogo from "../../static/images/xlp_logo_alt.png";

import XLLogo from "../../static/images/TPH Rapid.png";
import { trackOrder } from '../../actions/cart'
import Placeholder from '../shared/Placeholder'
import InvoiceList from './InvoiceList'
import { getSMSOrderStatus } from '../../actions/testsms'

const OrderDeliveryStatus = ({ props }) => {
  const dispatch = useDispatch()
  let params = useParams()
  const trackOrderDetails = useSelector((state) => state.cart.trackOrderDetails)
  const trackOrderApiCall = useSelector((state) => state.cart.trackOrderApiCall)
  const [SMSOrderTracking, setSMSOrderTracking] = useState(false)
  const queryStringParams = QueryString.parse(window.location.search)
  if (queryStringParams.trackId && queryStringParams.id) {
    const guid = queryStringParams.id;
    storage.set('SMSToken', guid, 'local');
  }

  useEffect(() => {    
    if (queryStringParams.trackId && queryStringParams.id) {
      setSMSOrderTracking(true);
      dispatch({
        payload: {
          orderTrackApiOnGoing: true,
        },
        type: "TRACK_ORDER_API_CALL",
      });
      setTimeout(() => {
        dispatch(getSMSOrderStatus(queryStringParams.trackId))
        .then((action) => {
          dispatch({
            payload: {
              orderTrackApiOnGoing: false,
            },
            type: "TRACK_ORDER_API_CALL",
          });
          dispatch({
            payload: {
              trackOrderData: action,
            },
            type: "SET_TRACK_ORDER_DATA",
          });
        })
        .catch((error) => {})
      }, 2000);      
    } else {
      trackOrder(params.orderID, dispatch)
    }
  }, [])

  if (!trackOrderApiCall) {
    if (!_isEmpty(trackOrderDetails)) {
      let orderNumber = ''
      let poNumber = ''
      let orderDate = ''
      let getInvoiceList = []
      if (!_isEmpty(trackOrderDetails.orderDeliveries)) {
        orderNumber = trackOrderDetails.orderDeliveries[0].orderNumber
        poNumber = trackOrderDetails.orderDeliveries[0].poNumber
        orderDate = trackOrderDetails.orderDeliveries[0].orderDate
        getInvoiceList.push(
          <InvoiceList
            invoiceData={trackOrderDetails.orderDeliveries}
            invoiceLength={trackOrderDetails.orderDeliveries.length}
          />,
        )
      }
      return (
        <div className="order_delivery_status_container">
          <div className="wrapper">
            <div className="order_delivery_status_header">
              <img src={XLLogo} alt="XL Parts" />
              <div className="product_delivery_detail">
                <span>welcome to the parts house</span>
                <div>
                  <div>
                    <span className="marron_text">Your Store#:</span>
                    <span>{trackOrderDetails.store}</span>
                  </div>
                  {trackOrderDetails.address ? (
                    <div>
                      <span className="marron_text">Address</span>
                      <span>{trackOrderDetails.address}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {!trackOrderDetails.salesManEmail &&
                  !trackOrderDetails.salesManPhone ? (
                    <div>
                      <span className="marron_text">Store Phone:</span>
                      <span>{trackOrderDetails.storePhone}</span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {trackOrderDetails.salesManName ? (
                    <div>
                      <span className="marron_text">Your Salesman Name:</span>
                      <span>{trackOrderDetails.salesManName}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {trackOrderDetails.salesManPhone ? (
                    <div>
                      <span className="marron_text">Phone:</span>
                      <span>{trackOrderDetails.salesManPhone}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {trackOrderDetails.salesManEmail ? (
                    <div>
                      <span className="marron_text">Email:</span>
                      <span>{trackOrderDetails.salesManEmail}</span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {trackOrderDetails.arName ? (
                    <div>
                      <span className="marron_text">Your A/R Contact:</span>
                      <span>{trackOrderDetails.arName}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {trackOrderDetails.arPhone ? (
                    <div>
                      <span className="marron_text">Tel:</span>
                      <span>{trackOrderDetails.arPhone}</span>
                    </div>
                  ) : (
                    ''
                  )}
                  {trackOrderDetails.arEmail ? (
                    <div>
                      <span className="marron_text">Email:</span>
                      <span>{trackOrderDetails.arEmail}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="order_delivery_status_box">
              <div className="order_details">
                <div className="order_date">
                  <span className="marron_text">Ordered Date:</span>
                  {!_isEmpty(orderDate) && <span>
                    {moment(orderDate).format('MMMM Do YYYY, h:mm:ss')} CST
                  </span>}
                </div>
                <div className="order_number">
                  <span className="marron_text">Order Number: </span>
                  <span>{orderNumber}</span>
                </div>
                <div className="customer_po">
                  <span className="marron_text">Customer PO: </span>
                  <span>{poNumber}</span>
                </div>
              </div>
              {getInvoiceList}
            </div>
          </div>
        </div>
      )
    }
  } else {
    return <Placeholder rows={2} />
  }

  return <span></span>
}

export default OrderDeliveryStatus
