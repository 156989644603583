import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getQuoteUniversalService,
  getQuoteShippingService,
  placeOrderService,
  getPreviousLookupService,
  getcandkcategoriesService,
} from "./c_and_k.service";

export const getQuoteUniversal = createAsyncThunk(
  "getQuoteUniversal",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const response = await getQuoteUniversalService(payload, {
      cancelToken: source.token,
    });

    return response.data;
  }
);
export const getReviewCKSearchData = createAsyncThunk(
  "candk/getReviewCKSearchData",
  async (payload) => {
    // No API call needed, just return the payload
    return payload;
  }
);
export const getCKReviewSelectedOrder = createAsyncThunk(
  "candk/getCKReviewSelectedOrder",
  async (payload) => {
    // No API call needed, just return the payload
    return payload;
  }
);
export const getCKReviewEnteredOrderDetails = createAsyncThunk(
  "candk/getCKReviewEnteredOrderDetails",
  async (payload) => {
    // No API call needed, just return the payload
    return payload;
  }
);

export const getQuoteShipping = createAsyncThunk(
  "getQuoteShipping",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const response = await getQuoteShippingService(payload, {
      cancelToken: source.token,
    });

    return response.data;
  }
);


export const postCKPlaceOrder = createAsyncThunk(
  "postCKPlaceOrder",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();

    signal.addEventListener("abort", () => {
      source.cancel("Request aborted");
    });

    try {
      const response = await placeOrderService(payload, {
        cancelToken: source.token,
      });

      return response.data; // Return the data property of the response
    } catch (error) {
      return error;
    }
  }
);



export const getReviewCKWarrantyData = createAsyncThunk(
  "candk/getReviewCKWarrantyData",
  async (payload) => {
    // No API call needed, just return the payload
    return payload;
  }
);

export const getPreviousLookups = createAsyncThunk(
  "getPreviousLookups",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const response = await getPreviousLookupService(payload);

    return response.data;
  }
);


export const getcandkcategories = createAsyncThunk(
  "getcandkcategories",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });

    const response = await getcandkcategoriesService(payload);

    return response.data;
  }
);