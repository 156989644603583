import React from 'react'
import { useState,useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import "./styles.scss"
import { currencyFormatter } from 'helpers/utils'
import { useSelector } from 'react-redux'
import _isEmpty from "lodash/isEmpty"
import { getMonthDaysLeft } from 'helpers/utils'

const SecondTable = () => {
  const [tableData,setTableData]=useState([])
  
  const { benefitData } = useSelector(
    (state) => state.ProgramAndBenefits
  );

  useEffect(() => {
    if (!_isEmpty(benefitData && benefitData?.volumeItems)) {
      constrctTableData();
    }
  }, [benefitData]);

  const compare=(sales)=>{
    //compare sales with volume tier
    console.log("sales",sales);
    const formattedSales= currencyFormatter(sales).replace('$','').replace(/,/g, "")
     if(+formattedSales<5000){
        return 5000
     }else if (+formattedSales >= 5000 && +formattedSales < 10000){
        return 10000
     }else if (+formattedSales >= 10000 && +formattedSales < 15000){
        return 15000
     }else{
        return 20000
     }
  }

  function purchaseRequiredToAchieveNextTier(sales){
    return compare(sales) - +sales
  }

  const noDataState = () => {
    return (
      <p align="center" style={{ padding: "0", margin: "0" }}>
        No Data Found
      </p>
    );
  };

  const constrctTableData=()=>{
     const data=[
        {
            first_column:"MTD Sales",
            second_column: !_isEmpty(benefitData?.volumeItems[0]?.sales) 
                ? benefitData?.volumeItems[0]?.sales 
                : "",
        },
        {
            first_column:"Days Left in Month",
            second_column:getMonthDaysLeft(),
        },
        {
            first_column:"Min Next Objective",
            second_column: !_isEmpty(benefitData?.volumeItems[0]?.sales)
                ? compare(benefitData?.volumeItems[0]?.sales)
                : compare('0')
        },
        {
            first_column:"Purchases Required to achieve next tier",
            second_column: !_isEmpty(benefitData?.volumeItems[0]?.sales)
                ? purchaseRequiredToAchieveNextTier(benefitData?.volumeItems[0]?.sales)
                : purchaseRequiredToAchieveNextTier('0')
        },
        {
            first_column:"Avg, Daily Purchases Required to achieve next",
            second_column: !_isEmpty(benefitData?.volumeItems[0]?.sales)
                ? getMonthDaysLeft() !== 0 ?
                    (purchaseRequiredToAchieveNextTier(benefitData?.volumeItems[0]?.sales) / getMonthDaysLeft())
                    : (purchaseRequiredToAchieveNextTier(benefitData?.volumeItems[0]?.sales))
                : getMonthDaysLeft() !== 0 ?
                (purchaseRequiredToAchieveNextTier('0') / getMonthDaysLeft())
                : (purchaseRequiredToAchieveNextTier('0')),
        },
      ]
      setTableData(data)
  }
  const columns=[
    {
        dataField:'first_column',
        text:'First Column',
        style:{fontWeight:'bold',paddingLeft:"5px"},
        headerAttrs: {
            hidden: true
        }
    },
    {
        dataField:'second_column',
        text:'Second Column',
        classes:"pvp-rightalign",
        headerAttrs: {
            hidden: true
        },
        formatter:(cell,row,index)=>{
            if(row.first_column!=="Days Left in Month"){
                return <span>{`${currencyFormatter(cell)}`}</span>
            }else{
               return cell
            }
        }
    }
  ]
  return (
     <BootstrapTable
      classes='pvp-second-table'
      keyField='first_column'
      data={tableData}
      columns={columns}
      noDataIndication={noDataState}
     />
  )
}

export default SecondTable