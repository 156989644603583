import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Card } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import SingleSupplyItem from '../../ChemicalsAndSupplies/Listing/SingleSupplyItem';

function ProPackAccSingleCardChem(props) {
  let {
    partDetails,
    partCount,
    proPackKey,
    partIdMap,
    accHeader,
    handlePartSelect,
    handleAccordionExpand,
    activeAccordion
    // accClass,
  } = props;
  const dispatch = useDispatch();
  const accordionChemHeadersRef = useRef([]);
  const user = useSelector((state) => state.user);
  const checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  const customerStore = user.userDetails && user.userDetails.shipTos && user.userDetails.shipTos[0] &&
                       user.userDetails.shipTos[0].location && user.userDetails.shipTos[0].location.storeNumber ? user.userDetails.shipTos[0].location.storeNumber : "";
  const selectedstore = checkoutDetails && checkoutDetails.length > 0 ?  checkoutDetails[0].store : customerStore;

  useEffect(() => {
    if(!_isEmpty(partIdMap) && partIdMap[partCount] && partIdMap[partCount].minQtyAdded && accordionChemHeadersRef.current.length > 0) {
      handleAccordionExpand(null, proPackKey);
    }
  }, partIdMap[partCount]);

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={`${partCount}accordion`}
        className={
          partIdMap[partCount] && partIdMap[partCount].minQtyAdded
            ? `min-qty-selected`
            : ''
        }
        onClick={(e)=>{handleAccordionExpand(e, partCount, accordionChemHeadersRef)}}
      >
        {accHeader}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={`${partCount}accordion`}>
        <Card.Body className="propacks_list_body" ref={el => accordionChemHeadersRef.current[partCount] = el}>
          {partDetails.map((part, k) => {
            let productArray = [];
            let appendPart = part.alternate && part.alternate.length > 0 || part.substitute && part.substitute.length > 0;
  					let altPartNumsArr = [];
  					let subPartNumsArr = [];
  					const altPartNums = (part.alternate && part.alternate.length > 0) ?
  																	part.alternate.map((alt) => {
  																		altPartNumsArr.push(alt.partSKU)
  																	}) : '';
  					const subPartNums = (part.substitute && part.substitute.length > 0) ?
  																	part.substitute.map((sub) => {
  																		subPartNumsArr.push(sub.partSKU)
  																	}) : '';
            productArray.push(
              <SingleSupplyItem
                partDetails={part}
                dispatch={dispatch}
                proPack={true}
                handlePartSelect={handlePartSelect}
                partCount={partCount}
                proPackKey={proPackKey}
                partType={'chemical'}
                appendPart={appendPart}
  							altPartNums={`${altPartNumsArr.join(',')}`}
  							subPartNums={`${subPartNumsArr.join(',')}`}
                selectedStore={selectedstore}
              />
            );

            //alternate parts
  					if (part.alternate && part.alternate.length > 0) {
  						part.alternate.map((altPart, index) => {
  							let appendAltPart = (index !== part.alternate.length - 1) || (part.substitute && part.substitute.length > 0);
  							productArray.push(
                  <SingleSupplyItem
                    partDetails={altPart}
                    dispatch={dispatch}
                    proPack={true}
                    handlePartSelect={handlePartSelect}
                    partCount={partCount}
                    proPackKey={proPackKey}
                    partType={'chemical'}
                    isAltType={true}
  									appendPart={appendAltPart}
                    selectedStore={selectedstore}
                  />
  							);
  						});
  					}
  					//substitute parts
  					if (part.substitute && part.substitute.length > 0) {
  						part.substitute.map((subPart, index) => {
  							let appendSubPart = index !== part.substitute.length - 1;
  							productArray.push(
                  <SingleSupplyItem
                    partDetails={subPart}
                    dispatch={dispatch}
                    proPack={true}
                    handlePartSelect={handlePartSelect}
                    partCount={partCount}
                    proPackKey={proPackKey}
                    partType={'chemical'}
                    isSubType={true}
  									appendPart={appendSubPart}
                    selectedStore={selectedstore}
                  />
  							);
  						});
  					}
            return productArray;
          })}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
export default ProPackAccSingleCardChem;
