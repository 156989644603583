import React, { useState, useEffect, Fragment, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _findIndex from 'lodash/findIndex';
import { InputGroup, DropdownButton, Form, Button } from 'react-bootstrap';

import { triggerCustomUxCriticalError } from "../../../actions/error";
import {
  getDriverList,
  updateDriverDetails
} from '../UserManagement/usermanagement.thunk';

import {
  setModifyUser
} from '../UserManagement/usermanagement.slice';
import { validateSearchWrapper, timeInUTC } from "../../../helpers/utils";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import _cloneDeep from 'lodash/cloneDeep';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';


const ActivateOrInactivateDriver = (props) => {

  let [rowData, setRowData] = useState([]);
  let [driverData, setDriverData] = useState([]);
  let [tableRowData, setTableRowData] = useState([]);
  let [tableColumnData, setTableColumnData] = useState([]);
  let [showTable, setShowTable] = useState(false);
  let [apiData, setApiData] = useState([]);


  const dispatch = useDispatch();
  let user = useSelector((state) => state.user.userDetails);
  const UserUpdate = useSelector((state) => state.userManagement.UserUpdate);
  useEffect(() => {
    if (!_isEmpty(props.companyData)) {
      getDriverData();
    }
  }, [(props.companyData) || UserUpdate]);

  useEffect(() => {
    let a = 0;
  }, [tableRowData]);

  const getDriverData = () => {
		if (!_isEmpty(props.companyData)) {
			try {
        const payload = {
          companyId : props.companyData && props.companyData[0].companyId,
        };
				const driverData = dispatch(getDriverList(payload));
				driverData.then((action) => {
					if (action.payload) {
						setDriverData(action.payload);
            setRowData(action.payload);
            let rowData = _cloneDeep(action.payload);
            rowData.map((value,index) => {
                  value.isEnabled = value.isActive;
                  if(value.pinValidDate === timeInUTC().substring(0,10)) {
                       value.isActive = true;
                  } else {
                    value.isActive = false;
                  }
            })
            setTableRowData(rowData);
            setTimeout(() => {
              constructTableData();
            })
					}
				});
			} catch (error) { }
		}
	};

  const handleSubmit = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    let apiOption = [];
    if(!_isEmpty(apiData)){
      apiData.map((value, index) => {
        apiOption.push(
          {
            driverId: value.driverId,
            fname: value.fname,
            lname: value.lname,
            companyId: value.companyId,
            storeNum: value.storeNum,
            pin: value.pin,
            pinValidDate: value.pinValidDate,
            mobileNumber: value.mobileNumber,
            isActive: value.isActive,
            createdBy: 'uday',
            createdDate: timeInUTC(),
            updatedBy: 'uday'
          }
        )
      })
      
    }
    
    

    let updatedrowDataToObj = {};
    if (!_isEmpty(rowData) && rowData.length > 1) {
    dispatch(updateDriverDetails(apiOption)).then((result) => {
      statusMessageProps.heading = 'Driver Modified Successfully';
      statusMessageProps.type = 'success';
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
    });
    }

  }

  const constructTableData = () => {
  const headerStyle = {
		//'backgroundColor': '#9D1D20',
		'color': 'black',
		//'border-color': '#9D1D20',
		'font-size': '12px',
		'width': '10px'
	};

  const columns = [{
    dataField: 'fname',
    text: 'First Name',
    headerStyle: headerStyle,
    sort: true,
    formatter: function (cell, row, index) {
      return (
        <div className="grid-input-box">
          <input
									type="text"
									name="field-name"
                  className="field-name"
									defaultValue={row.fname ? row.fname : ''}
								/>
        </div>
        )
    },
    validator: (newValue, row, column) => {
      if (_isEmpty(newValue)) {
        return {
          valid: false,
          message: 'First Name Cannot be Empty'
        };
      }
      return true;
    },
    filter: textFilter()
  },
   {
    dataField: 'lname',
    text: 'Last Name',
    sort: true,
    headerStyle: headerStyle,
    formatter: function (cell, row) {
      return (
        <div className="grid-input-box">
          <input
									type="text"
									name="field-name"
                  className="field-name"
									defaultValue={row.lname ? row.lname : ''}
								/>
        </div>)
    },
    validator: (newValue, row, column) => {
      if (_isEmpty(newValue)) {
        return {
          valid: false,
          message: 'Last Name Cannot be Empty'
        };
      }
      return true;
    },
    filter: textFilter()
  }, {
    dataField: 'mobileNumber',
    headerStyle: headerStyle,
    sort: true,
    text: 'Mobile Number',
    headerStyle: headerStyle,
    formatter: function (cell, row) {
      return (
        <div className="grid-input-box">
          <input
									type="text"
									name="field-name"
                  className="field-name"
									defaultValue={row.mobileNumber ? row.mobileNumber : ''}
								/>
        </div>)
    },
    validator: (newValue, row, column) => {
      if (_isEmpty(newValue)) {
        return {
          valid: false,
          message: 'Mobile Number Cannot be Empty'
        };
      }
      return true;
    },
    filter: textFilter()
  },
  {
    dataField: 'storeNum',
    text: 'Store Number',
    sort: true,
    headerStyle: headerStyle,
    formatter: function (cell, row) {
      return (
        <div className="grid-input-box">
          <input
                  type="text"
                  name="field-name"
                  className="field-name"
                  defaultValue={row.storeNum ? row.storeNum : ''}
                />
        </div>)
    },
    validator: (newValue, row, column) => {
      if (_isEmpty(newValue)) {
        return {
          valid: false,
          message: 'Store Number Cannot be Empty'
        };
      }
      if (isNaN(newValue)) {
        return {
          valid: false,
          message: 'Store Number Should be Numeric'
        };
      }
      return true;
    },
    filter: textFilter()
  },
  {
    dataField: 'pin',
    text: 'PIN',
    sort: true,
    headerStyle: headerStyle,
    formatter: function (cell, row) {
      return (
        <div className="grid-input-box">
          <input
                  type="text"
                  name="field-name"
                  className="field-name"
                  defaultValue={row.pin ? row.pin : ''}
                />
        </div>)
    },
    validator: (newValue, row, column) => {
      if (_isEmpty(newValue)) {
        return {
          valid: false,
          message: 'PIN Cannot be Empty'
        };
      }
      if (isNaN(newValue)) {
        return {
          valid: false,
          message: 'PIN Should be Numeric'
        };
      }
      return true;
    },
    filter: textFilter()
  },
  {
    dataField: 'pinValidDate',
    text: 'Pin Valid Date',
    sort: true,
    headerStyle: headerStyle,
    formatter: function (cell, row) {
      return (
        <div className="grid-input-box">
          <input
                  type="text"
                  name="field-name"
                  className="field-name"
                  disabled={true}
                  defaultValue={row.pinValidDate ? row.pinValidDate : ''}
                />
        </div>)
    },
    editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
    filter: textFilter()
  },
  {
    dataField: 'isActive',
    text: 'Active For Today',
    sort: true,
    headerStyle: headerStyle,
    formatter: function (cell, row) {
      return (
        <div className="grid-input-box">
               <input
                  type="checkbox"
                  name="field-name"
                  className="field-name"
                  checked={row.isActive? true : false}
                />
        </div>)
    },
    filter: textFilter()
  },
  {
    dataField: 'isEnabled',
    text: 'IsEnabled',
    headerStyle: headerStyle,
    formatter: function (cell, row) {
      return (
        <span className="grid-input-box">
          <input
                  type="checkbox"
                  name="field-name"
                  className="field-name"
                  checked={row.isEnabled ? true : false}
                />
        </span>)
    }
  }
];
//setTableRowData(RowData);
setTableColumnData(columns);
setShowTable(true);
  }

  const cellEdit = cellEditFactory({
    mode: 'click',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row, column) => { 
      let columnName = column.dataField
      if(column.dataField == 'isActive') {
        if(newValue == 'false') {
          newValue = false;
        } else {
          newValue = true;
          row.pinValidDate = timeInUTC().substring(0,10);
        }
      }
        row[columnName] = newValue;
        apiData.push(row);
     },
  })

  

  return (
    <Fragment>
    <div class="row grid-driver-usermanagement">
      {showTable ? (
      <div class="col-12">
        <BootstrapTable
          bootstrap4
          height='200px'
          width='400px'
          keyField="id"
          data={tableRowData}
          columns={tableColumnData}
          cellEdit={cellEdit}
          filter={ filterFactory()}
        />
      </div>
      ) : null}
    </div>
    <br/>
    <Button className="user-management-submit-btn"
      // disabled={!userModified}
      onClick={handleSubmit}
    >Save</Button>
    </Fragment> 
  ); 
};
export default ActivateOrInactivateDriver;