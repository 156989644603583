import React, { Fragment } from "react";
import moment from "moment";
import _isEmpty from 'lodash/isEmpty';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatAmount } from '../../helpers/utils';
import tickIcon from "../../static/images/tick.png"
import { useDispatch, useSelector } from "react-redux";


const InvoiceList = (props) => {
    const {
        invoiceData,
        invoiceLength,
    } = props;
    const user = useSelector((state) => state.user.userDetails);



    return (
        <div>
            {!_isEmpty(invoiceData) && invoiceData.map((data, key) => (
                <React.Fragment>
                    <div className='invoiceNumber_total_amount'>
                        <div className="invoice-detail">
                            <span className="marron_text">Invoice Number: </span>
                            {data.invoiceUrl && data.invoiceUrl != null ?
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="text-tooltip">
                                            View the invoice document by clicking on the invoice
						                </Tooltip>
                                    }
                                >
                                    <span className="details_link" onClick={() => {
                                        window.open(
                                            data.invoiceUrl,
                                            "_blank"
                                        );
                                    }}><p>{data.invoiceNumber}</p>
                                    </span>
                                </OverlayTrigger> : 
                                     <span className="details_no_link"><p>{data.invoiceNumber}</p>
                                    </span>
                                }
                        </div>
                        <div className="total_parts_total_amt">
                            <div className="total_amt">
                                <span className="marron_text">Total Amount</span>
                                <span className="circularBorder">${formatAmount(data.orderAmount)}</span>
                            </div>
                            <div className="total_parts">
                                <span className="marron_text">Total Parts</span>
                                <span className="circularBorder">{data.noOfPartSKUs}</span>
                            </div>
                            <div className="total_parts">
                                <span className="marron_text">Total Quantity</span>
                                <span className="circularBorder">{data.orderQty}</span>
                            </div>
                        </div>
                    </div>

                    <div className="delivery_status">
                        <div className={`single_status ${(data.created === 'Yes') ? 'active' : ''}`}>
                            <img src={tickIcon} alt="Checked" />
                            <span>Created</span>
                            <span>{data.createdTime ? moment(data.createdTime).format('MMMM Do YYYY, h:mm:ss') : ''}</span>
                        </div>

                        {((user && user.shipTos[0] &&  user.shipTos[0].shipMethod === undefined) ||(data.orderCancelled === null && user && (user.shipTos[0].shipMethod === "0002" || user.shipTos[0].shipMethod !== "0002"))) && (
                            <div className={`single_status ${(data.processing === 'Yes') ? 'active' : ''}`}>
                                <img src={tickIcon} alt="Checked" />
                                <span>Processing</span>
                                <span>{data.processingTime ? moment(data.processingTime).format('MMMM Do YYYY, h:mm:ss') : ''}</span>
                            </div>
                            )} 

                        {((user && user.shipTos[0] &&  user.shipTos[0].shipMethod === undefined && data.pickedUpByCustomer === 'Yes')|| (data.orderCancelled === null && user && user.shipTos[0].shipMethod === "0002")) && (
                            <div className={`single_status ${(data.pickedUpByCustomer === 'Yes') ? 'active' : ''}`}>
                                <img src={tickIcon} alt="Checked" />
                                <span>Picked Up <span>By Customer</span>
                                </span>
                                <span>{data.pickedUpByCustomerTime ? moment(data.pickedUpByCustomerTime).format('MMMM Do YYYY, h:mm:ss') : ''}</span>
                            </div>
                         )}

                        {((user && user.shipTos[0] &&  user.shipTos[0].shipMethod === undefined)|| (data.orderCancelled === null && user && user.shipTos[0].shipMethod !== "0002")) && (
                            <div className={`single_status ${(data.dispatched === 'Yes') ? 'active' : ''}`}>
                                <img src={tickIcon} alt="Checked" />
                                <span>Dispatched</span>
                                <span>{data.dispatchedTime ? moment(data.dispatchedTime).format('MMMM Do YYYY, h:mm:ss') : ''}</span>
                            </div>
                         )}

                        {((user && user.shipTos[0] &&  user.shipTos[0].shipMethod === undefined) || (data.orderCancelled === null && user && user.shipTos[0].shipMethod !== "0002")) && (
                            <div className={`single_status ${(data.enRoute === 'Yes') ? 'active' : ''}`}>
                                <img src={tickIcon} alt="Checked" />
                                <span>En Route</span>
                                <span></span>
                            </div>)}

                        {((user && user.shipTos[0] &&  user.shipTos[0].shipMethod === undefined) ||(data.orderCancelled === null && user && user.shipTos[0].shipMethod !== "0002")) && (
                            <div className={`single_status ${(data.delivered === 'Yes') ? 'active' : ''}`}>
                                <img src={tickIcon} alt="Checked" />
                                <span>Delivered</span>
                                <span>{data.deliveredTime ? moment(data.deliveredTime).format('MMMM Do YYYY, h:mm:ss') : ''}</span>
                            </div>)}

                        {((user && user.shipTos[0] &&  user.shipTos[0].shipMethod === undefined) ||(data.orderCancelled === null && user && user.shipTos[0].shipMethod !== "0002")) && (
                            <div className={`single_status ${(data.completed === 'Yes') ? 'active' : ''}`}>
                                <img src={tickIcon} alt="Checked" />
                                <span>Completed</span>
                                <span>{data.completedTime ? moment(data.completedTime).format('MMMM Do YYYY, h:mm:ss') : ''}</span>
                            </div>)}

                        {((user && user.shipTos[0] &&  user.shipTos[0].shipMethod === undefined && (data.orderCancelled === "Yes")) || (data.orderCancelled === "Yes")) && (
                            <div className={`single_status ${(data.orderCancelled === "Yes") ? 'active' : ''}`}>
                                <img src={tickIcon} alt="Checked" />
                                <span>Order Canceled</span>
                                <span>{data.orderCancelledTime ? moment(data.orderCancelledTime).format('MMMM Do YYYY, h:mm:ss') : ''}</span>
                            </div>)}

                        <hr />
                    </div>
                    <div className={invoiceLength > 1 && key !== invoiceLength - 1 ? 'invoice_underline' : ''}></div>
                </React.Fragment>
            ))}
        </div>
    );

}

export default InvoiceList;