import ReactDOM from "react-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import TreeView from "@mui/lab/TreeView";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import TreeItem from "@mui/lab/TreeItem";
import _ from "lodash";
import _flatten from "lodash/flatten";
import _map from "lodash/map";
import _uniq from "lodash/uniq";
import {
  getMakesForYear,
  getModelsForMake,
  getEnginesForModel,
  getPropackGroups,
  getPartCategoryGroups,
  getPartCategories,
} from "./simplehomepage.thunks";
import {
  setShowLoader,
  setGroupSelectedFromSearch,
} from "./simplehomepage.slice";
import {
  timeInUTC,
  getDefaultDataBasedOnUSerPref,
  getPreferenceDataFromStorage,
} from "../../../helpers/utils";
import {
  getPartsByMultiGroupOrchestrator,
  getChemPartByGrp,
} from "../../../actions/parts";
import { setVehicles } from "../../shared/MyGarage/mygarage.thunks";
import {
  setVehicleSelected,
  setJobSelected,
} from "../../shared/MyGarage/mygarage.slice";
import BreadCrumbInput from "../../shared/BreadCrumbInput";
import PartDetails from "./PartDetails";
import PropackDetails from "./PropackDetails";
import { triggerCustomUxCriticalError } from "../../../actions/error";

export default function PartSelect(props) {
  const dispatch = useDispatch();
  const [childNodes, setChildNodes] = useState(null);
  const [showGlobalSearch, setShowGlobalSearch] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [nodeExpanded, setNodeExpanded] = useState(false);
  const [shipInfo, setShipInfo] = useState(props.shipInfo);
  const [partData, setPartData] = useState(null);
  const [propackData, setPropackData] = useState(null);
  const mygarage = useSelector((state) => state.mygarage);
  const parts = useSelector((state) => state.parts);
  const shippingInformation = useSelector(
    (state) => state.expressCart.shippingInfo
  );
  const groupSelectedFromSearch = useSelector(
    (state) => state.expressCart.groupSelectedFromSearch
  );
  const userDetails = useSelector((state) => state.user.userDetails);
  const proPacksData = useSelector((state) => state.user.proPacksData);
  const sv = mygarage.selectedVehicle;
  const [fetchedMake, setFetchMake] = useState(false);
  const [fetchedModel, setFetchModel] = useState(false);
  const [fetchedEngine, setFetchEngine] = useState(false);
  const [fetchedGroup, setFetchGroup] = useState(false);
  const [nodeSelectedVehicle, setNodeSelectedVehicle] = useState(null);

  const NODE_IDS = {
    YEAR: "year",
    MAKE: "make",
    MODEL: "model",
    ENGINE: "engine",
    PROPACKS: "propacks",
    PROPACKS_GROUP: "propackGroup",
    PART_CATEGORY: "partCategory",
    GROUP: "group",
  };

  useEffect(() => {
    if (!_isEmpty(props.divid)) {
      const elementFrom = document.getElementById(`${props.divid}`);
      const elementTo = document.getElementById(`partsview-container`);
      elementTo.scrollTop = elementFrom.offsetTop - 80;
    }
  }, []);

  useEffect(() => {
    setShipInfo(props.shipInfo);
  }, [props.shipInfo]);

  useEffect(() => {
    if (!_isEmpty(props.id)) {
      let { id, yearCode } = JSON.parse(props.id);
      if (
        !_isEmpty(sv) &&
        id === NODE_IDS.YEAR &&
        sv.year === yearCode &&
        !nodeExpanded
      ) {
        autoExpand(NODE_IDS.YEAR);
        dispatch(setShowLoader({ showLoader: true }));
      }
    }
  }, [sv]);

  useEffect(() => {
    if (!_isEmpty(props.id)) {
      let { id } = JSON.parse(props.id);
      if (nodeExpanded && id === NODE_IDS.GROUP) {
        fetchChildNodes(props.id);
      }
    }
  }, [shippingInformation]);

  useEffect(() => {
    if (!_isEmpty(groupSelectedFromSearch) && !_isEmpty(props.id)) {
      let { id, partCategoryCode, engine, makeCode, model, yearCode } =
        JSON.parse(props.id);
      if (
        !_isEmpty(groupSelectedFromSearch.sv) &&
        groupSelectedFromSearch.sv.model === model &&
        groupSelectedFromSearch.sv.makeId === makeCode &&
        groupSelectedFromSearch.sv.year === yearCode &&
        engine &&
        groupSelectedFromSearch.sv.engine.replace(/\s+/g, "") ===
          engine.replace(/\s+/g, "") &&
        groupSelectedFromSearch.option.categoryId &&
        Number(groupSelectedFromSearch.option.categoryId) ===
          partCategoryCode &&
        id === NODE_IDS.PART_CATEGORY
      ) {
        autoExpand(NODE_IDS.PART_CATEGORY);
      }
    }
  }, [groupSelectedFromSearch]);

  useEffect(() => {
    if (!_isEmpty(groupSelectedFromSearch) && !_isEmpty(props.id)) {
      let {
        id,
        partCategoryCode,
        engine,
        makeCode,
        model,
        yearCode,
        groupCode,
      } = JSON.parse(props.id);
      if (
        !_isEmpty(groupSelectedFromSearch.sv) &&
        groupSelectedFromSearch.sv.model === model &&
        groupSelectedFromSearch.sv.makeId === makeCode &&
        groupSelectedFromSearch.sv.year === yearCode &&
        engine &&
        groupSelectedFromSearch.sv.engine.replace(/\s+/g, "") ===
          engine.replace(/\s+/g, "") &&
        groupSelectedFromSearch.option.categoryId &&
        Number(groupSelectedFromSearch.option.categoryId) ===
          partCategoryCode &&
        groupSelectedFromSearch.option.groupId &&
        Number(groupSelectedFromSearch.option.groupId) === groupCode &&
        id === NODE_IDS.GROUP
      ) {
        autoExpand(NODE_IDS.GROUP);
        dispatch(setGroupSelectedFromSearch({}));
      }
    }
  }, [groupSelectedFromSearch, fetchedGroup]);

  useEffect(() => {
    if (!_isEmpty(props.id)) {
      let { id, make, makeCode, yearCode } = JSON.parse(props.id);

      if (
        !_isEmpty(sv) &&
        id === NODE_IDS.MAKE &&
        (sv.make === make || Number(sv.internalMakeId) === makeCode) &&
        sv.year === yearCode
      ) {
        autoExpand(NODE_IDS.MAKE);
      }
    }
  }, [sv, fetchedMake]);

  useEffect(() => {
    if (!_isEmpty(props.id)) {
      let { id, make, makeCode, model, yearCode } = JSON.parse(props.id);
      if (
        !_isEmpty(sv) &&
        id === NODE_IDS.MODEL &&
        sv.model === model &&
        sv.makeId === makeCode &&
        sv.year === yearCode
      ) {
        autoExpand(NODE_IDS.MODEL);
      }
    }
  }, [sv, fetchedModel]);

  useEffect(() => {
    if (!_isEmpty(props.id)) {
      let { id, makeCode, model, yearCode, engine } = JSON.parse(props.id);
      if (fetchedEngine === true) {
        dispatch(setShowLoader({ showLoader: false }));
      }
      if (
        !_isEmpty(sv) &&
        id === NODE_IDS.ENGINE &&
        sv.model === model &&
        sv.makeId === makeCode &&
        sv.year === yearCode &&
        sv.engine.replace(/\s+/g, "") === engine.replace(/\s+/g, "")
      ) {
        autoExpand(NODE_IDS.ENGINE);
      }
    }
  }, [sv, fetchedEngine]);

  const showMakes = (data) => {
    return new Promise((resolve) => {
      const payload = { year: `${data.yearCode}` };
      dispatch(getMakesForYear(payload))
        .then((result) => {
          const makes = result.payload.map((make) => {
            const nodeData = {
              ...data,
              id: "make",
              makeCode: make.code,
              vehicleMake: make.vehicleMake,
              make: make.name,
            };
            return {
              id: JSON.stringify(nodeData),
              name: make.name,
            };
          });
          resolve({
            children: makes,
          });
          setTimeout(() => {
            setFetchMake(true);
          }, 1000);
        })
        .catch((err) => {
          dispatch(setShowLoader({ showLoader: false }));
        });
    });
  };

  const showModels = (data) => {
    return new Promise((resolve) => {
      const payload = { year: data.yearCode, make: data.vehicleMake };
      dispatch(getModelsForMake(payload))
        .then((result) => {
          const models = result.payload.map((model) => {
            const nodeData = {
              ...data,
              id: "model",
              makeCode: model.makeCode,
              modelCode: model.code,
              model: model.name,
            };
            return {
              id: JSON.stringify(nodeData),
              name: model.name,
            };
          });
          resolve({
            children: models,
          });
          setTimeout(() => {
            setFetchModel(true);
          }, 1000);
        })
        .catch((err) => {
          dispatch(setShowLoader({ showLoader: false }));
        });
    });
  };

  const showEngines = (data) => {
    return new Promise((resolve) => {
      const payload = {
        year: data.yearCode,
        make: data.makeCode,
        model: data.modelCode,
      };
      dispatch(getEnginesForModel(payload))
        .then((result) => {
          const engines = result.payload.map((engine) => {
            const nodeData = {
              ...data,
              id: "engine",
              engineCode: engine.code,
              engine: engine.name.replace(/\s+/g, ""),
            };
            return {
              id: JSON.stringify(nodeData),
              name: engine.name,
            };
          });
          resolve({
            children: engines,
          });
          setTimeout(() => {
            setFetchEngine(true);
          }, 1000);
        })
        .catch((err) => {
          dispatch(setShowLoader({ showLoader: false }));
        });
    });
  };

  const showPartCategories = (data) => {
    return new Promise((resolve) => {
      //Propacks as first category
      const propacksNodeData = {
        ...data,
        id: "propacks",
      };
      const propacks = {
        id: JSON.stringify(propacksNodeData),
        name: "Pro Packs",
      };
      if (!_isEmpty(props.categories)) {
        let ctgrs = [...props.categories].map((category) => {
          const ctgryId = JSON.parse(category.id);
          const nodeData = {
            ...data,
            id: "partCategory",
            partCategoryCode: ctgryId.partCategoryCode,
            categoryName: ctgryId.categoryName,
          };
          return {
            id: JSON.stringify(nodeData),
            name: ctgryId.categoryName,
          };
        });
        resolve({
          children: [propacks, ...ctgrs],
        });
      } else {
        const payload = {};
        dispatch(getPartCategories(payload)).then((result) => {
          const partCtgories = result.payload.map((partCategory) => {
            const nodeData = {
              ...data,
              id: "partCategory",
              partCategoryCode: partCategory.code,
              categoryName: partCategory.name,
            };
            return {
              id: JSON.stringify(nodeData),
              name: partCategory.name,
            };
          });
          resolve({
            children: [propacks, ...partCtgories],
          });
        });
      }
    });
  };

  const showPropackGroups = (data) => {
    return new Promise((resolve) => {
      if (!_isEmpty(proPacksData[0]) && !_isEmpty(proPacksData[0].jobs)) {
        let bundles = proPacksData[0].jobs.map((propack) => {
          const nodeData = {
            ...data,
            id: NODE_IDS.PROPACKS_GROUP,
            jobId: propack.id,
            jobName: propack.jobName,
            jobDetails: JSON.stringify(propack),
          };
          return {
            id: JSON.stringify(nodeData),
            name: propack.jobName,
          };
        });
        resolve({
          children: bundles,
        });
      } else {
        const { shipTos, billTo } = userDetails || {};
        const payload = {
          custNum: billTo.billToId,
          shipToID: shipTos[0].shipToId,
        };
        dispatch(getPropackGroups(payload)).then((result) => {
          const jobs = result.payload.data[0].jobs || [];
          const propackBundles = jobs.map((bundle) => {
            const nodeData = {
              ...data,
              id: NODE_IDS.PROPACKS_GROUP,
              jobId: bundle.id,
              jobName: bundle.jobName,
              jobDetails: JSON.stringify(bundle),
            };
            return {
              id: JSON.stringify(nodeData),
              name: bundle.jobName,
            };
          });
          resolve({
            children: propackBundles,
          });
        });
      }
    });
  };

  const showPartCategoryGroups = (data) => {
    return new Promise((resolve) => {
      const payload = { category: data.partCategoryCode };
      dispatch(getPartCategoryGroups(payload)).then((result) => {
        if(data.partCategoryCode == 10) {
          const isNotValidData = result.payload.length > 0 && result.payload.some(item => item.code === 7) ? false : true;
          if (isNotValidData) {
            let errorMessageProps = {
              heading:
                "We are experiencing intermittent System problems. Please try after 5 minutes.",
              message: "",
              type: "info",
            };
            triggerCustomUxCriticalError(errorMessageProps, dispatch);
          }
        }
        const groups = result.payload.map((group) => {
          const nodeData = {
            ...data,
            id: "group",
            groupCode: group.code,
            laborGroups: group.laborGroups,
            groupName: group.name,
          };
          return {
            id: JSON.stringify(nodeData),
            name: group.name,
          };
        });
        resolve({
          children: groups,
        });
        setTimeout(() => {
          setFetchGroup(true);
        }, 1000);
      });
    });
  };

  const showPropackParts = (nodeData) => {
    return new Promise((resolve) => {
      let { jobDetails } = nodeData;

      const selectedProPack = JSON.parse(jobDetails);
      dispatch({
        payload: {
          selectedProPack,
        },
        type: "PROPACK_SELECTED",
      });

      const userPreferenceDetail = getPreferenceDataFromStorage();
      let userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);

      dispatch(setJobSelected([]));

      let requestItems = [];
      let brand = [];
      let chemReqItems = [];
      selectedProPack.partJobItems.map((singleItem, key) => {
        if (!_isEmpty(singleItem.partGroup)) {
          requestItems = [
            ...requestItems,
            ..._map(_map(singleItem.partGroup, "code"), (c) => {
              return {
                groupCode: Number(c),
                specificCondition: [],
              };
            }),
          ];
        }
      });
      requestItems = _.uniqBy(requestItems, "groupCode");

      if (!_isEmpty(selectedProPack.brands)) {
        brand = _map(selectedProPack.brands, "code");
      }

      let bundleCodeArr = _map(selectedProPack.bundlePriceCodes, "name");

      if (!_isEmpty(selectedProPack.chemicalJobItems)) {
        selectedProPack.chemicalJobItems.map((c) => {
          if (!_isEmpty(c.chemicalGroup)) {
            chemReqItems = c.chemicalGroup.map((single) => single.id);
          }
        });

        const filterChem =
          _isEmpty(bundleCodeArr) &&
          selectedProPack.enableBundleLookup === false
            ? []
            : ["Bundle"];
        dispatch(
          getChemPartByGrp(
            _uniq(chemReqItems),
            userPrefDetail.pageNumber,
            userPrefDetail.pageSize,
            userPrefDetail.catalogSortBy,
            userPrefDetail.catalogOrder,
            null,
            null,
            filterChem,
            null,
            null,
            null,
            [],
            shippingInformation.sellerPartnerId,
            shippingInformation.storeNumber,
            shippingInformation.shipMethod
          )
        );
        dispatch({
          payload: {
            brandsView: false,
          },
          type: "SUPPLIES_BRAND_VIEW",
        });
      }
      dispatch({
        payload: {
          selectedGroupDetails: _map(
            _flatten(_map(selectedProPack.partJobItems, "partGroup")),
            (g) => {
              return { ...g, code: Number(g.code) };
            }
          ),
        },
        type: "GROUP_SELECTED",
      });
      const filterPart =
        _isEmpty(bundleCodeArr) && selectedProPack.enableBundleLookup === false
          ? []
          : ["Bundle"];

      dispatch(
        getPartsByMultiGroupOrchestrator(
          nodeData.yearCode,
          nodeData.makeCode,
          nodeData.modelCode,
          nodeData.engineCode,
          {
            requestItems,
            sellerPartnerId: shippingInformation.sellerPartnerId,
            store: shippingInformation.storeNumber,
            shipMethod: shippingInformation.shipMethod,
          },
          1,
          100,
          userPrefDetail.catalogSortBy,
          userPrefDetail.catalogOrder,
          { brand, misc: filterPart },
          userDetails.billTo.mclSupplier,
          {
            partCategoryCode: nodeData.partCategoryCode,
            categoryName: nodeData.categoryName,
            laborGroups: nodeData.laborGroups
          } || parts.selectedPartType,
          true,
          sv

        )
      ).then((res) => {
        setPropackData({
          ...nodeData,
          selectedExpressProPack: selectedProPack,
        });
        resolve({});
      });
    });
  };

  const showParts = (nodeData) => {
    return new Promise((resolve) => {
      let selectedGroup = {
        categoryId: nodeData.partCategoryCode,
        code: Number(nodeData.groupCode),
        name: nodeData.categoryName,
        engineCode: "Y",
        laborGroups: nodeData.laborGroups,
      };
      let requestItems = [
        { groupCode: nodeData.groupCode, specificCondition: [] },
      ];

      let filter = { ...parts.catalogFilter, exactMatch: true };

      dispatch(
        getPartsByMultiGroupOrchestrator(
          nodeData.yearCode,
          nodeData.makeCode,
          nodeData.modelCode,
          nodeData.engineCode,
          {
            requestItems,
            sellPartnerId: shippingInformation.sellerPartnerId,
            store: shippingInformation.storeNumber,
            shipMethod: shippingInformation.shipMethod,
          },
          1,
          100,
          null,
          null,
          filter,
          userDetails.billTo.mclSupplier,
          {
            partCategoryCode: nodeData.partCategoryCode,
            categoryName: nodeData.categoryName,
            laborGroups: nodeData.laborGroups
          } || parts.selectedPartType,
          true,
          sv

        )
      ).then((res) => {
        setPartData({
          ...nodeData,
          selectedGroupDetails: [selectedGroup],
        });
        resolve({});
      });
    });
  };

  const fetchChildNodes = (id) => {
    const nodeData = JSON.parse(id);
    const node_id = nodeData.id || "";
    if (!_isEmpty(parts.partsAllDetails)) {
      dispatch({
        payload: {},
        type: "RESET_PARTS_ALL_DETAILS",
      });
    }
    switch (node_id) {
      case NODE_IDS.YEAR:
        return showMakes(nodeData);
      case NODE_IDS.MAKE:
        return showModels(nodeData);
      case NODE_IDS.MODEL:
        return showEngines(nodeData);
      case NODE_IDS.ENGINE:
        setShowGlobalSearch(true);
        dispatch({
          payload: {
            selectedVehicle: {
              yearId: nodeData.yearCode,
              makeId: nodeData.makeCode,
              modelId: nodeData.modelCode,
              engineId: nodeData.engineCode,
              year: nodeData.yearCode,
              make: nodeData.make,
              model: nodeData.model,
              engine: nodeData.engine,
            },
          },
          type: "VEHICLE_SELECTION_COMPLETED",
        });
        const { x2userName, shipTos, billTo } = userDetails || {};
        const payload = {
          createdBy: x2userName,
          createdDate: timeInUTC(),
          customerId: billTo.billToId,
          engine: nodeData.engine ? nodeData.engine.trim() : "",
          engineId: nodeData.engineCode,
          favoriteFlag: "N",
          favoriteText: "",
          favoriteUpdatedDate: "",
          lastOrderedDate: "",
          make: nodeData.make ? nodeData.make.trim() : "",
          makeId: nodeData.makeCode,
          internalMakeId: nodeData.makeCode,
          model: nodeData.model ? nodeData.model.trim() : "",
          modelId: nodeData.modelCode,
          orderedFlag: "N",
          searchId: "",
          shipToId: shipTos[0].shipToId,
          updatedBy: x2userName,
          updatedDate: timeInUTC(),
          year: nodeData.yearCode ? nodeData.yearCode.trim() : "",
        };
        dispatch(setVehicles(payload));
        dispatch(setVehicleSelected(payload));
        setNodeSelectedVehicle(payload);
        return showPartCategories({ ...nodeData, selectedVehicle: payload });
      case NODE_IDS.PROPACKS:
        return showPropackGroups(nodeData);
      case NODE_IDS.PROPACKS_GROUP:
        return showPropackParts(nodeData);
      case NODE_IDS.PART_CATEGORY:
        return showPartCategoryGroups(nodeData);
      case NODE_IDS.GROUP:
        return showParts(nodeData);
      default:
        return "";
    }
  };

  const autoExpand = (nodeName) => {
    let nodeObject = props.id;
    handleChange("AUTO", [`${nodeObject}`]);
  };

  const handleChange = (event, nodes) => {
    const expandingNodes = nodes.filter((x) => !expanded.includes(x));
    setExpanded(nodes);
    setNodeExpanded(false);
    if (expandingNodes[0]) {
      setNodeExpanded(true);
      const childId = expandingNodes[0];
      fetchChildNodes(childId).then((result) => {
        if (!_isEmpty(result.children)) {
          setChildNodes(
            result.children.map((node, index) => (
              <PartSelect
                divid={index === 0 ? node.id : ""}
                categories={props.categories}
                key={node.id}
                {...node}
                shipInfo={shipInfo}
              />
            ))
          );
        }
      });
    }
  };

  const renderNodes = () => {
    if (childNodes) {
      return childNodes;
    } else {
      if (!_isEmpty(partData)) {
        return <PartDetails part={partData} />;
      } else if (!_isEmpty(propackData)) {
        return <PropackDetails propackData={propackData} />;
      } else {
        return (
          <React.Fragment>
            <CircularProgress color="gray" size="2rem" />
          </React.Fragment>
        );
      }
    }
  };

  return (
    <div id={props.divid}>
      <TreeView
        defaultCollapseIcon={<RemoveCircleOutlineIcon />}
        defaultExpandIcon={<AddCircleOutlineIcon />}
        expanded={expanded}
        onNodeToggle={handleChange}
      >
        {/*The node below should act as the root node for now */}
        <TreeItem nodeId={props.id} label={props.name}>
          {showGlobalSearch && (
            <React.Fragment>
              <BreadCrumbInput
                isExpress={true}
                selectedVehicle={nodeSelectedVehicle}
              />
            </React.Fragment>
          )}
          {renderNodes()}
        </TreeItem>
      </TreeView>
    </div>
  );
}
