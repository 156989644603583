import React, { useEffect, useState } from "react";
import ProntoProgram from "../ProntoProgram";
import BatteryConsignment from "../BatteryConsignment";
import OnlineOrderRewards from "../onlineOrderRebate";
import LaborClaim from "../labor Claim";
import AcdGmPartnerPerks from "../AcdGmPartnerPerks";
import PSNProgram from "../PSN";
import Placeholder from "components/shared/Placeholder";
import CustomerDashBoard from "../CustomerDashBoard";
import PVPVolumeRebate from "../PVPVolumeRebate";

function ProgramsData(props) {
  let { programName, userHasAccess, userDef, handleProgramTab,programListAcess} = props;
  if (!userHasAccess && programName !== "BatteryConsignment" && programName !== "CustomerDashBoard") {
    return (
      <React.Fragment>
        <div className="xlprewards-wrapper">
          You are currently not enrolled in this program. Please contact your
          sales representative for more information.
        </div>
        {programName === "CustomerDashBoard" && (
          <CustomerDashBoard handleProgramTab={handleProgramTab} programListAcess={programListAcess}/>
        )}
      </React.Fragment>
    );
  }

  switch (programName) {
    case "CustomerDashBoard":
      return (
        <div className="xlprewards-wrapper">
          <CustomerDashBoard handleProgramTab={handleProgramTab} programListAcess={programListAcess} />
        </div>
      );
    case "ProntoSmartChoice":
      return (
        <div className="xlprewards-wrapper">
          <ProntoProgram userDef={userDef} />
        </div>
      );
    case "BatteryConsignment":
      return (
        <div className="xlprewards-wrapper">
          <BatteryConsignment isEnrolled={userHasAccess} />
        </div>
      );
    case "OnlineOrderingRebate":
      return (
        <div className="xlprewards-wrapper">
          <OnlineOrderRewards programName={programName} />
        </div>
      );
    case "LaborClaim":
      return (
        <div className="xlprewards-wrapper">
          <LaborClaim />
        </div>
      );
    case "MTAPSN":
      return (
        <div className="xlprewards-wrapper">
          <PSNProgram />
        </div>
      );
    case "ACDGMPartnerPerks":
      return (
        <div className="xlprewards-wrapper">
          <AcdGmPartnerPerks userDef={userDef} />
        </div>
      );
    case "PVPVolumeRebate":
      return (
        <div className="xlprewards-wrapper">
          <PVPVolumeRebate />
        </div>
      );
    default:
      return (
        <div className="xlprewards-wrapper">
          <Placeholder rows={4} />
        </div>
      );
  }
}

export default ProgramsData;
