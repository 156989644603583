import React from 'react';
import { Container, Row, Form, Button, Spinner } from 'react-bootstrap';

import FormValidationErrorMessage from '../shared/FormValidationErrorMessage/FormValidationErrorMessage';

function ForgotPasswordVerify(props) {
	const {
		handleInputChange,
		handleInputBlur,
		verificationCode,
		newPassword,
		handleFormSubmit,
		userApiCall,
		validity,
	} = props;

	return (
		<Container fluid className="login_container">
			<Row className="login_box">
				<span>Reset Your Password?</span>
				<div>
					<Form className="login_form" onSubmit={handleFormSubmit}>
						<div className="returning_customer_container">
							<div className="input_fields">
								<Form.Control
									type="text"
									onChange={handleInputChange}
									onBlur={handleInputBlur}
									value={verificationCode}
									name="verificationCode"
									placeholder="Verification Code"
								/>
								<FormValidationErrorMessage
									condition={!validity.isConfirmationNotNull}
									errorMessage="Please enter your Confirmation Code"
								/>
								<list>Password should contain -
									<ul>
										<li>Minimum password length – 8.</li>
										<li>Atleast 1 uppercase.</li>
										<li>Atleast 1 lowercase.</li>
										<li>Atleast 1 number.</li>
										<li>A special character.</li>
									</ul>
								</list>
								<Form.Control
									onChange={handleInputChange}
									value={newPassword}
									name="newPassword"
									type="password"
									placeholder="New Password"
								/>
								<FormValidationErrorMessage
									condition={!validity.isPasswordNotNull}
									errorMessage="Please enter your Password"
								/>
								<FormValidationErrorMessage
									condition={!validity.doesPwdhaveFormat && validity.isPasswordNotNull}
									errorMessage="Your password does not follow the standards"
								/>
							</div>
							{userApiCall ? (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									disabled
								>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									Loading..
								</Button>
							) : (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									type="submit"
								>
									Change Password
								</Button>
							)}
						</div>
					</Form>
				</div>
			</Row>
		</Container>
	);
}
export default ForgotPasswordVerify;
