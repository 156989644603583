/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import FormValidationErrorMessage from '../../../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import { isAlphanumeric, hasMinLength } from '../../../../helpers/utils';
import { useHistory } from 'react-router-dom';

const StockOrders = (props) => {
	const { closeOverlay } = props;
	const initializeValidity = () => {
		const validity = {
			isSearchAlphanumeric: true,
			isSearchMinLimit: true
		};
		return validity;
	};
	const [ validity, setvalidity ] = useState(initializeValidity);
	const history = useHistory();

	// const stocks = () => {
	//     history.push({
	//       pathname: "/stock-order",
	//     });
	//     closeOverlay();
	//   };

	const handleStockOrder = () => {
		closeOverlay();
		history.push('/stock-order');
	};
	const handleSearch = (searchText) => {};

	const handleSearchOrderBlur = () => {
		let validity = initializeValidity();
		setvalidity(validity);
	};

	const handleSearchOrderChange = (event) => {
		const searchData = event.target.value;
		let validity = initializeValidity();
		validity.isSearchAlphanumeric = isAlphanumeric(searchData);
		validity.isSearchMinLimit = hasMinLength(searchData, 1);
		setvalidity(validity);
	};

	return (
		<React.Fragment>
			<Row>
				<Col md="8">
					<div className="search-container">
						<Form.Control
							placeholder="Search Part # "
							className="input__search input__control"
							onKeyUp={(e) => handleSearch(e.target.value)}
							onKeyDown={(e) => e.stopPropagation()}
							onClick={(e) => e.stopPropagation()}
							onFocus={(e) => e.stopPropagation()}
							onMouseOver={(e) => e.stopPropagation()}
							onChange={handleSearchOrderChange}
							onBlur={handleSearchOrderBlur}
						/>
						<FormValidationErrorMessage
							condition={!validity.isSearchAlphanumeric && validity.isSearchMinLimit}
							errorMessage="Search key must be alphanumeric only"
						/>
						<FormValidationErrorMessage
							condition={!validity.isSearchMinLimit}
							errorMessage="Search key should have atleast 1 character"
						/>
					</div>
				</Col>
				<Col md="3">
					<div className="mt-3">
						<Button className="maroonBtn return_xl_parts_btn" onClick={() => handleStockOrder()}>
							Create Stock Order
						</Button>
					</div>
				</Col>
				<Col md="2">
					<div className="mygarage__returns_link">
						<div>
							<a className="statement__link_stock_order">Proceed To Stock Orders</a>{' '}
						</div>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default StockOrders;
