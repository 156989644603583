import React from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import {
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button
} from "@mui/material";
import { Visibility, VisibilityOff, Login } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Amplify, { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { aws_config } from "../../helpers/aws_config";
import { Redirect } from "react-router-dom";
import { fetchUserDetails, updateToken } from "../../actions/auth";
import _, { isEmpty } from "lodash";
import _isEqual from "lodash/isEqual";
import _every from "lodash/every";
import { dismissAllUxCritialErrors } from "../../actions/error";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordVerify from "./ForgotPasswordVerify";
import NewPasswordSetup from "./NewPasswordSetup";
import MFALogin from "./MFALogin";
import { validateOnlineIdForm, timeInUTC } from "../../helpers/utils";
import { triggerUxCritialErrors } from "../../actions/error";
import {
  refreshShipTo,
  refreshLocation,
  logUserActivity,
  getPromotionForUser,
} from "../../actions/user";
import FormValidationErrorMessage from "../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import storage from "../../helpers/storage";
import { withToastManager } from "react-toast-notifications";
import {
  getUserPreferenceDetails,
  getPartImageURLCookie,
  impersonateUserBillTo,
} from "../User/UserManagement/usermanagement.thunk";
import { verifyAppVersion } from "../shared/MyGarage/mygarage.thunks";
import VerifyMobileMFALogin from "./VerifyMobileMFALogin";
import _isEmpty from "lodash/isEmpty";
import { triggerCustomUxCriticalError } from "../../actions/error";
import Moment from "react-moment";
import { apks, mobileInstructions } from "../../helpers/constants";
import jwt_decode from "jwt-decode";
import { processLogin } from "../shared/LoginHelper";
import Footer from "../PrivacyPolicy/index";
import { Accordion, Textbox, Typography } from "../../ui-library";
import QRCode from "react-qr-code";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";



const GoogleBadge = '/google-play-badge.png';
const Applebadge = 'app-store-badge.png';

const currentEnv = window.location.hostname.split('.')[0];

Amplify.configure(aws_config);
const OSRDefaultBillTO = process.env.REACT_APP_OSR_DEFAULT_BILLTO;
class userManagementWrapper extends React.Component {
	interval = null;
	constructor(props) {
		super(props);
		this.state = {
			newCustomer: false,
			authorizationDone: !!props.userAuthenticated,
			attributes: {
				password: '',
				username: '',
				customerId: '',
				locationId: '',
				emailId: '',
				newPassword: '',
				confirmPassword: '',
			},
			formObject: this.initializeForm(),
			redirectMfaLogin: false,
			redirectToVerifyEmail: false,
			redirectToForgotPassword: false,
			redirectToForgotPasswordVerify: false,
			redirectToNewPassword: false,
			userApiCall: false,
			validity: this.intializeValidations(),
			redirectToVerifyMobile: false,
			newPhoneSubmitting: false,
			newOtpSubmitting: false,
			currentOtpSubmitting: false,
			showPassword: false,
			currentMobileNumber: "",
			clientMetaData: {
				timeZone:
				  Intl && Intl.DateTimeFormat().resolvedOptions().timeZone
					? Intl.DateTimeFormat().resolvedOptions().timeZone
					: "",
			  },

		};
		this.inputRef = React.createRef();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleForgotPassword = this.handleForgotPassword.bind(this);
		this.handleForgotPasswordVerification = this.handleForgotPasswordVerification.bind(
			this
		);
		this.goBackToLogin = this.goBackToLogin.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.validateFormNewPassword = this.validateFormNewPassword.bind(this)
		this.handleShowOrHide = this.handleShowOrHide.bind(this)
		this.handleUpdateNewMob = this.handleUpdateNewMob.bind(this)
		this.handleMfaSubmitNewMob = this.handleMfaSubmitNewMob.bind(this)
		this.handleConfirmCurrentPhone = this.handleConfirmCurrentPhone.bind(this)
	}

	handleShowOrHide() {
		this.setState({ showPassword: !this.state.showPassword })
	}

	initializeForm() {
		this.formObject = {
			password: '',
			username: '',
			customerId: '',
			locationId: '',
			emailId: '',
			newPassword: '',
		};
		return this.formObject;
	}

	componentDidMount() {
		// this.inputRef.current.focus();
		const fiveMins = 1000 * 60 * 5;
		let { dispatch } = this.props;
		const payload = {
			dispatch: dispatch
		};
		dispatch(verifyAppVersion(payload));
		this.interval = setInterval(() => {
			dispatch(verifyAppVersion(payload));
		}, fiveMins);
	}

	componentDidUpdate(prevProps) {
		let { authorizationDone } = this.state;
		if (!_isEqual(this.props, prevProps)) {
			if (
				!_isEqual(
					this.props.userAuthenticated,
					prevProps.userAuthenticated
				)
			) {
				authorizationDone = this.props.userAuthenticated;
			}
			this.setState({
				authorizationDone: authorizationDone,
			});
		}
	}

	intializeValidations() {
		this.validity = {
			isUserNameNotNull: true,
			isUserNameLengthBelowLimit: true,
			isUserNameMinLength: true,
			isUserNameValidFormat: true,
			isValidUserName: true,
			isPasswordNotNull: true,
			isConfirmPasswordNotNull: true,
			isValidConfirmPassword: true,
			doesPwdhaveFormat: true,
			isValidPassword: true,
			isConfirmationNotNull: true,
		};
		return this.validity;
	}

	validateForm() {
		let {
			formObject: {
				password,
				username,
			},
			validity,
		} = this.state;
		validity = validateOnlineIdForm('password', password, validity);
		validity = validateOnlineIdForm('username', username, validity);
		this.setState({ ...this.state, validity });
		return _every(validity);
	}

	validateFormNewPassword() {
		let {
			formObject: {
				confirmPassword,
				newPassword
			},
			validity,
		} = this.state;
		validity = validateOnlineIdForm('newPassword', newPassword, validity);
		validity = validateOnlineIdForm('confirmPassword', confirmPassword, validity);
		validity.isValidConfirmPassword = _isEqual(newPassword, confirmPassword)
		this.setState({ ...this.state, validity });
		return _every(validity);
	}
	// to get the user preference details for each session added refresh token flag as common loader
	getUserPreference() {
		let { dispatch } = this.props;
		dispatch({
			payload: {
				status: true,
			},
			type: "REFRESH_TOKEN_STATUS",
		});
		dispatch(getUserPreferenceDetails()).then((res) => {
			storage.set('userPrefereces', res.payload || {}, 'local');
			dispatch({
				payload: {
					status: false,
				},
				type: "REFRESH_TOKEN_STATUS",
			});
		});
	}

	forgotPasswordCognito(username) {
		Auth.forgotPassword(username)
			.then((data) => {
				storage.set('userEmail', username, 'local');

				this.setState({
					userApiCall: false,
					redirectToForgotPasswordVerify: true,
					redirectToForgotPassword: false,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({ userApiCall: false })
				triggerUxCritialErrors(err, this.props.dispatch);
			});
	}

	forgotPasswordVerificationCognito(username, code, new_password) {
		return Auth.forgotPasswordSubmit(username, code, new_password)
			.then((data) => {
				this.setState({
					redirectToForgotPasswordVerify: false,
					redirectToForgotPassword: false,
					userApiCall: false,
				});

			})
			.catch((err) => {
				this.setState({ userApiCall: false });
				triggerUxCritialErrors(err, this.props.dispatch);
				console.log(err);
			});
	}

	handleForgotPasswordVerification(event) {
		event.preventDefault();
		const isValid = this.validateForm();
		// if (isValid) {
		const {
			attributes: { verificationCode, newPassword, username },
		} = this.state;

		this.setState({ userApiCall: true });
		this.forgotPasswordVerificationCognito(
			username,
			verificationCode,
			newPassword
		);
	}

	userSignIn(username, password, clientMetaData) {
		this.setState({
			userApiCall: true,
		});
		let { dispatch } = this.props;
		Auth.signIn(username, password, clientMetaData)
			.then((user) => {
				this.setState({
					userApiCall: false,
				});

				this.setState({
					cognitoUser: user,
					redirectMfaLogin: false,
				});

				/*if (
					user.challengeName === 'SMS_MFA' ||
					user.challengeName === 'SOFTWARE_TOKEN_MFA'
				) {
					this.setState({
						cognitoUser: user,
						redirectMfaLogin: false,
					});
				} else*/ if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
					const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
					// You need to get the new password and required attributes from the UI inputs
					// and then trigger the following function with a button click
					// For example, the email and phone_number are required attributes
					// const {username, email, phone_number} = getInfoFromUserInput();

					// const loggedUser = Auth.completeNewPassword(
					// 	user, // the Cognito User Object
					// 	'Welcome123$$' // the new password
					// 	// OPTIONAL, the required attributes
					// 	// {
					// 	//     email,
					// 	//     phone_number,
					// 	// }
					// );
					this.setState({
						cognitoUser: user,
						redirectToNewPassword: true,
					});
					// NEW password required
				} else if (user.challengeName === 'MFA_SETUP') {
					Auth.setupTOTP(user).then((code) => {
						Auth.currentSession().then((res) => {
							let accessToken = res.idToken.jwtToken;
							this.getUserPreference();
							// let jwt = accessToken.getJwtToken();
							//You can print them to see the full objects

							storage.set('authToken', accessToken, 'local');
							storage.set('showReleaseNotes', true, 'local');
						});

						this.setState({
							redirectToMFASetupStep: true,
							authorizationCode: code,
							cognitoUser: user,
						});
					});
					Auth.setPreferredMFA(user, 'SMS');
				} /*else if (user.preferredMFA && user.preferredMFA === "NOMFA") {
					this.setState({
						cognitoUser: user,
						redirectToVerifyMobile: false,
						currentMobileNumber: user.attributes.phone_number
					});
				}*/ else {
					Auth.currentSession().then((res) => {
						// --------------------------------------------------------------------------------
						let { dispatch } = this.props;
						processLogin(res, 'cognito', dispatch, this.props);
						// ------TODO:sms  iframe change
						this.setState({
							authorizationDone: true,
						});
						// ---------------------------------------------------------------------------------

						// ---------------------------------------------------------------------------------
						// let { dispatch } = this.props;
						// this.getUserPreference();

						// let idToken = res.getIdToken().getJwtToken();

						// let userDetails = JSON.parse(
						// 	res.getIdToken().payload.user_details
						// );

						// storage.set('authToken', idToken, 'local');
						// storage.set("showReleaseNotes", true, 'local');
						// storage.set('authFailed', false, 'local');
						// const { toastManager } = this.props;

						// // To Remove the toast messages after login if any.
						// dismissAllUxCritialErrors(dispatch);
						// toastManager.removeAll();
						// // --- END ---

						// dispatch({
						// 	payload: {
						// 		authorizationDone: true,
						// 	},
						// 	type: 'AUTHORIZATION_DONE',
						// });
						// dispatch({
						// 	payload: {
						// 		userDetails,
						// 	},
						// 	type: 'USER_DETAILS_FETCHED',
						// });
						// this.setState({
						// 	authorizationDone: true,
						// });
						// let {
						// 	shipTos,
						// 	x2userName: userName,
						// } = userDetails;
						// let type = "login"
						// let isCompanyAdmin =  userDetails.userType && userDetails.userType != "COMPANY_ADMIN" ? false : true;
						// // Promotions to be fetched APP Level, as it is present in most of the pages.
						// if (_.isEmpty(this.props.userPromotionDetails) && !isCompanyAdmin) {
						// 	getPromotionForUser(dispatch);
						// }
						// if(!isCompanyAdmin) {
						//   let { billTo: {
						// 		billToId: customerId
						// 	   }
						// 	} = userDetails;
						// 	dispatch(logUserActivity(type, customerId, timeInUTC(), shipTos[0].shipToId, userName))
						// }
						// if (userDetails.userType === "CUSTOMER_ADMIN") {
						// 	dispatch(refreshShipTo(userName, dispatch));
						// }
						// dispatch(refreshLocation({ dispatch }));
						// dispatch(getPartImageURLCookie()).then((resp) => {
						// 	storage.set('imageURLCookie', resp.payload || {}, 'local');
						// });

						// if (userDetails.userType === "OSR") {
						// 	const payload = {
						// 		billTo : OSRDefaultBillTO,
						// 		x2UserName:userDetails.x2userName,
						// 		userType: userDetails.userType,
						// 		buyPartnerCreationFlag: true,
						// 		dispatch,
						// 	};
						// 	dispatch(impersonateUserBillTo(payload)).then((action) => {
						// 		updateToken(dispatch).then(() => {
						// 		})

						// 	})}else if(userDetails.userType === "OSR"){
						// 		const payload = {
						// 			billTo : OSRDefaultBillTO,
						// 			x2UserName:userDetails.x2userName,
						// 			userType: userDetails.userType,
						// 			buyPartnerCreationFlag: false,
						// 			dispatch,
						// 		};
						// 		dispatch(impersonateUserBillTo(payload)).then((action) => {
						// 			updateToken(dispatch).then(() => {
						// 			})

						// 		})
						// 	}
						// -------------------------------------------------------------------------
					});
				}
			})
			.catch((error) => {
				triggerUxCritialErrors(error, dispatch);
				this.setState({
					userApiCall: false,
				});
			});
	}

	handleFormSubmit(event) {
		event.preventDefault();

		const isValid = this.validateForm();
		if (isValid) {
			let {
				attributes: { password, username },
				clientMetaData,
			} = this.state;
			this.userSignIn(username.toLowerCase(), password, clientMetaData);
		}
	}

	handleInputChange(event) {
		let {
			target: { value, name },
		} = event;
		let {
			attributes,
			formObject,
			validity,
		} = this.state;
		attributes[name] = value;
		formObject[name] = value;
		validity = validateOnlineIdForm(name, value, validity);
		if (name === "confirmPassword") {
			validity.isValidConfirmPassword = _isEqual(formObject.newPassword, value);
		}
		this.setState({
			formObject: {
				...this.state.formObject,
				...formObject,
			},
			validity: {
				...this.state.validity,
				...validity,
			},
			attributes,
			...this.state,
		});
	}

	handleInputBlur() {
		let { validity } = this.state;
		validity = this.intializeValidations();
		this.setState({
			validity: {
				...validity
			}
		})
	}

	handleNewPassword = (e) => {
		e.preventDefault();
		let {
			attributes: { newPassword },
			cognitoUser,
		} = this.state;
		let { dispatch } = this.props;
		const isValid = this.validateFormNewPassword();
		if (isValid) {
			if (newPassword && cognitoUser) {
				this.setState({ userApiCall: true });
				Auth.completeNewPassword(
					cognitoUser, // the Cognito User Object
					newPassword // the new password
					// OPTIONAL, the required attributes
					// {
					//     email,
					//     phone_number,
					// }
				).then((result) => {
					if (result.challengeName === 'SMS_MFA') {
						this.setState({
							redirectMfaLogin: false,
							userApiCall: false,
						});
					} else {
						this.setState({
							currentMobileNumber: result.challengeParam.userAttributes.phone_number,
							redirectToVerifyMobile: false,
							userApiCall: false,
						});
						Auth.currentSession()
								.then((res) => {
									this.getUserPreference();
									// this.setState({
									// 	userApiCall: false,
									// });
									let idToken = res.getIdToken().getJwtToken();

									storage.set('showReleaseNotes', true, 'local');
									storage.set('authToken', idToken, 'local');
									let userDetails = JSON.parse(
										res.getIdToken().payload.user_details
									);
									fetchUserDetails(dispatch);
									let {
										billTo: {
											billToId: customerId
										},
										shipTos,
										x2userName: userName,
									} = userDetails;
									// let type = "Phone_verified"
									// dispatch(logUserActivity(type, customerId, timeInUTC(), shipTos[0].shipToId, userName))
									this.setState({
										authorizationDone: true,
									});
								})
					}
				}).catch((err) => {
					triggerUxCritialErrors(err, dispatch);
					this.setState({ userApiCall: false });
				});
			}
		}
	};

	handleForgotPassword(event) {
		event.preventDefault();

		const {
			attributes: { username },
		} = this.state;
		this.setState({ userApiCall: true })
		this.forgotPasswordCognito(username);
	}

	goBackToLogin() {
		this.setState({
			redirectToForgotPassword: false,
			redirectToForgotPasswordVerify: false,
		});
	}
	handleMfaSubmit = (e) => {
		e.preventDefault();
		let {
			cognitoUser,
			attributes: { smsConfirmationCode },
			clientMetaData
		} = this.state;
		const isValid = this.validateForm();
		let { dispatch } = this.props;
		if (isValid) {
			if (smsConfirmationCode) {
				this.setState({
					userApiCall: true,
				});
				Auth.confirmSignIn(
					cognitoUser, // Return object from Auth.signIn()
					smsConfirmationCode, // Confirmation code
					'SMS_MFA', // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
					clientMetaData
				).then((result) => {
					Auth.currentSession()
						.then((res) => {
							this.getUserPreference();
							this.setState({
								userApiCall: false,
							});
							let idToken = res.getIdToken().getJwtToken();

							storage.set('showReleaseNotes', true, 'local');
							storage.set('authToken', idToken, 'local');
							let userDetails = JSON.parse(
								res.getIdToken().payload.user_details
							);
							fetchUserDetails(dispatch);
							let {
								billTo: {
									billToId: customerId
								},
								shipTos,
								x2userName: userName,
							} = userDetails;
							let type = "MFA"
							dispatch(logUserActivity(type, customerId, timeInUTC(), shipTos[0].shipToId, userName))
							this.setState({
								authorizationDone: true,
							});

						})
						.catch((error) => {
							this.setState({
								userApiCall: false,
								redirectMfaLogin: false,
							});
							triggerUxCritialErrors(error, dispatch);
							console.log('error in mfa current session', error);
						});
				})
					.catch((err) => {
						this.setState({
							userApiCall: false,
						});
						triggerUxCritialErrors(err, dispatch);
					});
			}
		}
	};

	handleUpdateNewMob = async (countryCode, mobileNumber) => {
		let { dispatch } = this.props;
		this.setState({ newPhoneSubmitting: true })
		const statusMessageProps = {
			heading: 'Success',
			message: 'One Time Password has been sent to Your mobile number',
			type: 'success',
		};
		let errorMessage = {
			message: 'Failed',
			type: 'error'
		}
		const currentUser = await Auth.currentAuthenticatedUser();
		await Auth.updateUserAttributes(currentUser, {
			"phone_number": `${countryCode}${mobileNumber}`
		}).then(() => {
			Auth.verifyCurrentUserAttribute("phone_number").then((result) => {
				triggerCustomUxCriticalError(statusMessageProps, dispatch);
			}).catch((error) => {
				if (!_isEmpty(error) && !_isEmpty(error.message)) {
					errorMessage.message = error.message;
				}
				triggerCustomUxCriticalError(errorMessage, dispatch);
			});
			this.setState({ newPhoneSubmitting: false })
		})
			.catch((error) => {
				triggerCustomUxCriticalError(errorMessage, dispatch);
				this.setState({ newPhoneSubmitting: false })
			});
		;
	}

	handleConfirmCurrentPhone = () => {
		const { dispatch } = this.props
		let { cognitoUser } = this.state;
		this.setState({ currentOtpSubmitting: true })
		Auth.setPreferredMFA(cognitoUser, 'SMS').then(res => {
			this.setState({ currentOtpSubmitting: false })
			Auth.currentSession()
				.then((res) => {
					this.getUserPreference();
					// this.setState({
					// 	userApiCall: false,
					// });
					let idToken = res.getIdToken().getJwtToken();

					storage.set('showReleaseNotes', true, 'local');
					storage.set('authToken', idToken, 'local');
					let userDetails = JSON.parse(
						res.getIdToken().payload.user_details
					);
					fetchUserDetails(dispatch);
					let {
						billTo: {
							billToId: customerId
						},
						shipTos,
						x2userName: userName,
					} = userDetails;
					let type = "Phone_verified"
					dispatch(logUserActivity(type, customerId, timeInUTC(), shipTos[0].shipToId, userName))
					this.setState({
						authorizationDone: true,
					});
				})
				.catch((error) => {
					this.setState({ currentOtpSubmitting: false })
				});
			this.setState({
				authorizationDone: true
			})
		}).catch((error) => {
			this.setState({ currentOtpSubmitting: false })
		});
	}

	handleMfaSubmitNewMob = async (otp) => {
		const { dispatch } = this.props
		let { cognitoUser, clientMetaData} = this.state;
		this.setState({ newOtpSubmitting: true })

		let errorMessage = {
			message: 'Failed',
			type: 'error'
		};
		const statusMessageProps = {
			heading: 'Success',
			message: 'Your mobile number is successfully verified',
			type: 'success',
		};
		let {
			attributes: { password, username, newPassword },
		} = this.state;
		let requiredPassword = newPassword;
		if (isEmpty(newPassword)) {
			requiredPassword = password;
		}
		Auth.verifyCurrentUserAttributeSubmit("phone_number", otp).then(() => {
			Auth.signIn(username.toLowerCase(), requiredPassword, clientMetaData).then(newUser => {
				Auth.setPreferredMFA(newUser, 'SMS').then(res => {
					this.setState({ newOtpSubmitting: false })
					triggerCustomUxCriticalError(statusMessageProps, dispatch);
					Auth.currentSession()
						.then((res) => {
							this.getUserPreference();
							// this.setState({
							// 	userApiCall: false,
							// });
							let idToken = res.getIdToken().getJwtToken();

							storage.set('showReleaseNotes', true, 'local');
							storage.set('authToken', idToken, 'local');
							let userDetails = JSON.parse(
								res.getIdToken().payload.user_details
							);
							fetchUserDetails(dispatch);
							let {
								billTo: {
									billToId: customerId
								},
								shipTos,
								x2userName: userName,
							} = userDetails;
							let type = "mobile"
							dispatch(logUserActivity(type, customerId, timeInUTC(), shipTos[0].shipToId, userName))
							this.setState({
								authorizationDone: true,
							});
						})
						.catch((error) => {
							this.setState({ newOtpSubmitting: false })
						});
					this.setState({
						authorizationDone: true
					})
				}).catch((error) => {
					this.setState({ newOtpSubmitting: false })
				});
			})
				.catch(err => {
					this.setState({ newOtpSubmitting: false })
				})
		}).catch((error) => {
			this.setState({ newOtpSubmitting: false })
			if (!_isEmpty(error) && !_isEmpty(error.message)) {
				errorMessage.message = error.message;
			}
			triggerCustomUxCriticalError(errorMessage, dispatch);
		});
	}

	render() {
		let {
			authorizationDone,
			attributes: { password, username, verificationCode, newPassword },
			redirectToVerifyEmail,
			redirectToForgotPassword,
			redirectToForgotPasswordVerify,
			redirectMfaLogin,
			redirectToNewPassword,
			userApiCall,
			validity,
			redirectToVerifyMobile,
			showPassword,
			newOtpSubmitting,
			newPhoneSubmitting,
			currentOtpSubmitting,
			currentMobileNumber
		} = this.state;
		if (authorizationDone) {
			return <Redirect to="/" />;
		} else if (redirectToVerifyEmail) {
			return <Redirect to="/verify-email" />;
		} else if (redirectToVerifyMobile) {
			return (
				<VerifyMobileMFALogin
					handleInputChange={this.handleInputChange}
					handleConfirmCurrentPhone={this.handleConfirmCurrentPhone}
					handleMfaSubmitNewMob={this.handleMfaSubmitNewMob}
					currentOtpSubmitting={currentOtpSubmitting}
					validity={validity}
					newPhoneSubmitting={newPhoneSubmitting}
					newOtpSubmitting={newOtpSubmitting}
					currentMobileNumber={currentMobileNumber}
					handleUpdateNewMob={this.handleUpdateNewMob}
				/>
			);
		} else if (redirectToForgotPassword) {
			return (
				<ForgotPassword
					handleFormSubmit={this.handleForgotPassword}
					username={username}
					handleInputChange={this.handleInputChange}
					handleInputBlur={this.handleInputBlur}
					goBackToLogin={this.goBackToLogin}
					userApiCall={userApiCall}
					validity={validity}
				/>
			);
		} else if (redirectToForgotPasswordVerify) {
			return (
				<ForgotPasswordVerify
					verificationCode={verificationCode}
					newPassword={newPassword}
					handleFormSubmit={this.handleForgotPasswordVerification}
					handleInputChange={this.handleInputChange}
					handleInputBlur={this.handleInputBlur}
					userApiCall={userApiCall}
					validity={validity}
				/>
			);
		} else if (redirectMfaLogin) {
			return (
				<MFALogin
					handleInputChange={this.handleInputChange}
					handleInputBlur={this.handleInputBlur}
					handleSubmit={this.handleMfaSubmit}
					userApiCall={userApiCall}
					validity={validity}
				/>
			);
		} else if (redirectToNewPassword) {
			return (
				<NewPasswordSetup
					handleSubmit={this.handleNewPassword}
					handleInputChange={this.handleInputChange}
					handleInputBlur={this.handleInputBlur}
					userApiCall={userApiCall}
					validity={validity}
				/>
			);
		}

		return (
      <>
        <Container fluid className="login_container">
		<Row className="login_title_new">
            <Col lg="6">
              <span className="rapid-title">NEW TO RAPID?</span>
            </Col>
            <Col lg="6">
              <span>Let’s Get Started!</span>
            </Col>
          </Row>
		  <Row>
            <Col lg="6">
              <Row className="login_box_copy">
                {/* <div>
                  <LoadingButton
                    sx={{ py: 1.5 }}
                    loadingPosition="start"
                    variant="contained"
                    size="small"
                  >
                    New to Rapid?
                  </LoadingButton>
                </div> */}
                <div className="login-left-wrapper">
                  <Typography
                    variant="body1"
                    paragraph={false}
                    className="login-left-header"
                  >
                    In addition to browsing our catalog and ordering parts,
                    chemicals and supplies, you can {" "}
                    <span>
                      {" "}
                      review your account balance, statements, invoices and
                      credits as well as make online payments {" "}
                    </span>
                     under <span className="login-my-account">
                      MY ACCOUNT
                    </span>{" "}
                    in RAPID.
                  </Typography>
                  <div className="login-myaccount-header">
                    <Col lg="5">
                      <Typography
                        variant="body1"
                        paragraph={false}
                        className="mb-10 login-leftwrapper-middletext"
                      >
                        Once logged in, click My Account on the home page.
                      </Typography>
                    </Col>
                    <Col lg="7">
                      <div className="my-garage-btn-wrapper login-btn-wrapper">
                        <div className="my-garage-btn-img-wrapper">
                          <PersonIcon />
                        </div>
                        <div className="my-account-btn-text">
                          <div className="title__line">
                            <span>MY ACCOUNT </span>
                            <i
                              className="fa fa-chevron-down"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <span>my shop name 001</span>
                        </div>
                      </div>
                    </Col>
                  </div>
                  <Typography variant="h6" paragraph={true} className="login-reactout-text">
                    Reach out to your The Parts House accounts receivable or sales
                    representative for questions or read more {" "}
                    <a
                    href={process.env.REACT_APP_FAQ_DOCUMENT}
                    target="_blank"
                    download={true} rel="noreferrer"
                  >
                    here</a>
					.
                  </Typography>
                </div>
              </Row>
            </Col>
            <Col lg="6">
              <Row className="login_box_copy">
                <span>Please Login Below</span>
                <Form
                  className="login_form"
                  onSubmit={(e) => {
                    this.handleFormSubmit(e);
                  }}
                >
                  <div className="returning_customer_container">
                    <div className="input_fields">
                      <Textbox
                        error={!validity.isUserNameNotNull}
                        id="username"
                        name="username"
                        label="Email Id"
                        fullWidth
                        value={username}
                        onChange={this.handleInputChange}
                        helperText={
                          !validity.isUserNameNotNull && "Username is required"
                        }
                      />

                      <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                          error={!validity.isPasswordNotNull}
                          id="password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleShowOrHide}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff color="primary" />
                                ) : (
                                  <Visibility color="primary" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          onChange={this.handleInputChange}
                          helperText={
                            !validity.isPasswordNotNull &&
                            "Password is required"
                          }
                        />
                      </FormControl>

                      {/* <label htmlFor="username">Email Id</label>

                  <Form.Control
                    ref={this.inputRef}
                    size="sm"
                    type="text"
                    name="username"
                    id="username"
                    value={username}
                    onChange={this.handleInputChange}
                    placeholder="Enter Your Email ID"
                  /> */}
                      {/* <FormValidationErrorMessage
                    condition={!validity.isUserNameNotNull}
                    errorMessage="Please enter your Username"
                  />
                  <FormValidationErrorMessage
                    condition={
                      !validity.isUserNameLengthBelowLimit &&
                      validity.isUserNameNotNull
                    }
                    errorMessage="Username should not exceed above 50 characters"
                  />
                  <FormValidationErrorMessage
                    condition={
                      !validity.isUserNameMinLength &&
                      validity.isUserNameNotNull
                    }
                    errorMessage="Username should have atleast 7 characters"
                  />
                  <FormValidationErrorMessage
                    condition={
                      !validity.isUserNameValidFormat &&
                      validity.isUserNameNotNull &&
                      validity.isUserNameMinLength
                    }
                    errorMessage="Username must be in username@domainname.com format"
                  /> */}
                      {/* <label htmlFor="password">Password</label>
                  <div className="password_container">
                    <Form.Control
                      size="sm"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      value={password}
                      onChange={this.handleInputChange}
                      placeholder="Enter Your Password"
                    />
                    {showPassword ? (
                      <i
                        class="fa fa-eye-slash eyeIcon"
                        onClick={() => {
                          this.handleShowOrHide();
                        }}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        class="fa fa-eye eyeIcon"
                        onClick={() => {
                          this.handleShowOrHide();
                        }}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div> */}
                      {/* <FormValidationErrorMessage
                    condition={!validity.isPasswordNotNull}
                    errorMessage="Please enter your Password"
                  /> */}
                    </div>
                    <LoadingButton
                      type="submit"
                      sx={{ py: 1.5 }}
                      onClick={this.handleFormSubmit}
                      loading={userApiCall}
                      loadingPosition="start"
                      variant="contained"
                      fullWidth
                      size="large"
                      style={{ fontSize: '18px' }}
                    >
                      {`${userApiCall ? "Logging in..." : "Login"}`}
                    </LoadingButton>
                    <Row>
                      <Col sm="6" className="pr-1">
                        <div
                          class="forgot-password_poc d-flex justify-content-center"
                          onClick={() => {
                            this.setState({
                              redirectToForgotPassword: true,
                            });
                          }}
                        >
                          Forgot Password?
                        </div>
                      </Col>
                      <Col sm="6" className="pl-1">
                        <Link to="/online-id-req-form">
                          <div class="request-access d-flex justify-content-center">
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              size="large"
                              fullWidth
                            >
                              REQUEST ACCESS / Sign Up
                            </Button>
                          </div>
                        </Link>
                      </Col>
                    </Row>
                    <div
                      className="forgot-password"
                      onClick={() => {
                        this.setState({
                          redirectToForgotPassword: true,
                        });
                      }}
                    >
                    </div>
                  </div>
                  {/* )} */}
                </Form>
              </Row>
              {/* This is for APK download section, do not remove and it will only visible in DEV */}
            </Col>{" "}
          </Row>{" "}

			  {/* This is for APK download section, do not remove and it will only visible in DEV */}
        {true && (
            <div
              style={{
                'max-width': "586px",
                margin: "25px auto 0",
              }}
            >
              <Accordion
                title={
                  <React.Fragment>
                    <div
                      style={{
                        display: "flex",
                        "flex-direction": "column",
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Typography variant="h6" paragraph={false}>
                        RAPID is now available on IOS and Android
                      </Typography>
                      <Typography variant="caption">
                        click to expand for installation instructions
                      </Typography>
                    </div>
                  </React.Fragment>
                }
                className="app-accordion"
              >
                <div className="instruction" style={{ display: 'flex', justifyContent: 'center' }}>scan below QR code from your mobile camera</div>
                <div className="mobile-instructions">
                  {mobileInstructions.map(m => {
                    return <div className="platform">
                      <div className="name">{m.platform}</div>
                      <div className="version">({m.version})</div>

                      {m.envs.filter(env => env.env.includes(currentEnv))
                        .map(env => {
                          return <div className="qr-code">
                            <QRCode value={env.link} size="110" />
                          </div>
                        })}
                      <div className="separator">
                        <div className="dash"></div> <div className="or">or</div>
                      </div>
                      {m.envs.filter(env => env.env.includes(currentEnv))
                        .map(env => {
                          return <React.Fragment>
                            <div className="badge">
                              <a href={env.link} target="_blank">
                                <img src={m.platform === 'iOS' ? Applebadge : GoogleBadge} />
                              </a>
                            </div>
                          </React.Fragment>
                        })}

                    </div>
                  })}
                </div>
                <div className="osType" hidden>
                  <h4>iOS</h4>
                  <p>Please check your email for instructions</p>

                  <p>or</p>
                  <p>
                    Please login into Expo Go app using credentials provided.
                  </p>
                  <ul>
                    <li>
                      Go to{" "}
                      <a
                        target="_blank"
                        href="https://expo.dev/@ynirman/xlparts-rapid"
                      >
                        {" "}
                        link{" "}
                      </a>{" "}
                      and open the phone camera and scan the QR code.
                    </li>
                  </ul>
                </div>
                <div className="apk-downloader" hidden>
                  <div className="osType">
                    {apks
                      .filter((apk) => apk.env.includes(currentEnv))
                      .map((apk) => {
                        return (
                          <React.Fragment>
                            <h4>Android</h4>
                            <p className="helper-text">
                              Click below icon to download the .apk for XLParts
                              Rapid App
                            </p>
                            <p>
                              Last Updated:{" "}
                              <strong>
                                {" "}
                                <Moment
                                  date={apk.lastUpdated}
                                  format="DD MMM YYYY HH:mm A zz"
                                  tz="America/Chicago"
                                />
                              </strong>
                            </p>
                            <p>
                              Build Environment:{" "}
                              <strong> {apk.caption} </strong>
                            </p>
                            <QRCode value={apk.link} />
                            <a className="apk-link" download href={apk.link}>
                              <img src="/apk-download.png" />
                            </a>
                          </React.Fragment>
                        );
                      })}
                  </div>

                </div>
              </Accordion>
            </div>
          )}
          <Footer></Footer>
        </Container>
      </>
    );
  }
}



function mapStateToProps(state) {
	return {
		userAuthenticated: state.app.userAuthenticated,
	};
}

export default withToastManager(connect(mapStateToProps)(userManagementWrapper));
