import React,{useEffect,useState} from "react"
import {Container,Row,Col} from "react-bootstrap"
import FirstTable from "./FirstTable";
import SecondTable from "./SecondTable";
import ThirdTable from "./ThirdTable";
import FourthTable from "./FourthTable";
import { useDispatch,useSelector } from "react-redux";
import { benefitDetails } from "./../programbenefits.thunk";
import _isEmpty from "lodash/isEmpty"


const PVPVolumeRebate=()=>{
  const user = useSelector((state) => state.user);
  const { programList } = useSelector((state) => state.ProgramAndBenefits);
  const userDetails = user.userDetails;
  const dispatch = useDispatch();
  const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };
  const [volRebateData, setVolRebateData] = useState(null);

  useEffect(() => {
    fetchPVPVolmeData();
  }, []);
 
  const fetchPVPVolmeData = () => {
    const { xlPartAccountNumber } = userDetails || {};
    let customerNumber = xlPartAccountNumber;
    const payload = {
      name: "pvpVolumeRebate",
      customerId: customerNumber,
      noOfMonths: 12,
    };
    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
    dispatch(benefitDetails(payload)).then((action) => {
      if (action && action?.payload) {
        setVolRebateData(action.payload);
      }
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
    }).catch((error) => {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
    });
  };



    return (
        <Container>
            <Row>
              <Col>
              <FirstTable/>
              </Col>
              <Col>
              <div>
            {/* {!_isEmpty(volRebateData) && volRebateData.volumeTierItems && ( */}
              <div className="report-card">
                <div className="rcard-title">Program Type</div>
                <div className="rcard-content">
                  <div className="cd-currency">
                    {!_isEmpty(programList[0]) &&
                      !_isEmpty(programList[0]?.userDef11) ?
                      programList[0]?.userDef11
                      : "-"}
                  </div>
                </div>
                <div className="report-card">
                  <div className="rcard-title">Payment Frequency</div>
                  <div className="rcard-content">
                  <div className="cd-currency">
                  {!_isEmpty(volRebateData) &&
                  !_isEmpty(volRebateData.volumeTierItems)
                    ? volRebateData.volumeTierItems.volPayoutTime
                    : "-"}
                    </div>
                  </div>
                </div>
                <div className="report-card">
                  <div className="rcard-title">Payment Type</div>
                  <div className="rcard-content">
                  <div className="cd-currency">
                  {!_isEmpty(volRebateData) &&
                  !_isEmpty(volRebateData.volumeTierItems)
                    ? volRebateData.volumeTierItems.volPayoutType
                    : "-"}
                    </div>
                  </div>
                </div>
              </div>
            {/* )} */}
          </div>
              <br/>
              <SecondTable/>
              <br/>
              <FourthTable/>
              </Col>
              <br/>
              {/* <Col>
              <FourthTable/>
              </Col> */}
            </Row>
            {/* <br/> */}
            {/* <Row>

            </Row> */}
            <br/>
            <Row>
              <Col>
              <ThirdTable/>
              </Col>
            </Row>
            <br/>
            <Row>
              {/* <Col>
              <FourthTable/>
              </Col> */}
            </Row>
        </Container>
    )
}

export default PVPVolumeRebate