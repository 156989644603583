import React, { Fragment, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Container,
  Spinner,
} from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";

import {
  formatAmount,
  getExtendedTotalPrice,
  isInputBlank,
  hasQuoteAlphaSpecialIntChar,
  isJobNameAlphanumeric,
  hasMinLength,
  isLengthLessthan,
  isNumeric,
  isMaxLengthLessthan,
  isMinLength,
  isValidAvailableQty,
  timeInUTC,
  noSpecialCharsRegExp,
  getPreferenceDataFromStorage,
  getPartPackQty,
  displayQtyChangeMsg,
  numbersOnlyRegExp,
} from "../../../helpers/utils";
import Placeholder from "../Placeholder";
import ReactToPrint from "react-to-print";
import clsx from "clsx";
import LostSales from "../../Checkout/LostSales";
import _every from "lodash/every";
import _findIndex from "lodash/findIndex";
import _pick from "lodash/pick";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import deleteIcon from "../../../static/images/deleteIcon.png";
import _cloneDeep from "lodash/cloneDeep";

import {
  addItemToCart2,
} from "../../../actions/cart";
import { triggerCustomUxCriticalError } from "../../../actions/error";

const CartPreview = ({ checkoutDetails, showLoader, userDetails }) => {
  //cartAllDetails = !_isEmpty(cartAllDetails) ? (!_isEmpty(cartAllDetails.cartItems) ? cartAllDetails.cartItems : cartAllDetails) : cartAllDetails;
  const dispatch = useDispatch();
  let componentRef = useRef([]);
  const cartApiFetching = useSelector((state) => state.cart.cartApiFetching);
  const [showLostSales, setshowLostSales] = useState(false);
  const [lostSaleData, setLostSaleData] = useState([]);
  const [product, setProducts] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [totalExtendedPrice, setTotalExtendedPrice] = useState(0);
  const parts = useSelector((state) => state.parts);
  const hideAllPrice =  parts  && parts.hidePrice;
  useEffect(() => {
    let cartAllDetails = checkoutDetails;
    let totalqty = 0;
    let totalCostPrice = 0;
    let totalListPrice = 0;
    let totalextendedprice = 0;
    let cartConsolidateArr = [];
    if (!_isEmpty(cartAllDetails)) {
      const cartList = cartAllDetails.map((obj) => ({ ...obj }));
      cartList.map((one) => {
        let matched = false;
        if (_isEmpty(cartConsolidateArr)) {
          cartConsolidateArr.push(one);
        } else {
          matched = cartConsolidateArr.findIndex((two, index) => {
            return (
              two.partNum === one.partNum &&
              two.mfgCode === one.mfgCode &&
              two.desc === one.desc
            );
          });
          if (matched >= 0) {
            cartConsolidateArr[matched].qty =
              cartConsolidateArr[matched].qty + one.qty;
          } else {
            cartConsolidateArr.push(one);
          }
        }
      });
    }
    if (cartConsolidateArr && cartConsolidateArr.length > 0) {
      cartConsolidateArr.map((item) => {
        totalqty = totalqty + item.qty;
        totalCostPrice = totalCostPrice + item.costPrice;
        totalListPrice = totalListPrice + item.listPrice;
        totalextendedprice =
          totalextendedprice +
          item.qty * item.costPrice +
          item.qty * item.corePrice;
        setTotalExtendedPrice(totalextendedprice);
        setTotalQty(totalqty);
      });
    }
    setProducts([]);
    let products = [];
    if (!_isEmpty(cartConsolidateArr)) {
      cartConsolidateArr.map((item, index) => {
        products.push({
          part: item,
          mfgCode: item.mfgCode,
          partNum: item.partNum,
          qty: item.qty,
          desc: item.desc,
          costPrice: item.costPrice,
          corePrice: item.corePrice,
          listPrice: item.listPrice,
          availabilityHour: item.availabilityHour,
          availability: item.distStatus,
          extendedPrice: getExtendedTotalPrice(
            item.corePrice,
            item.costPrice,
            item.qty
          ),
          originalQty: item.originalQty
        });
      });
      products.push({
        part: "Total",
        mfgCode: "",
        partNum: "",
        qty: totalqty,
        desc: "",
        costPrice: "",
        corePrice: "",
        listPrice: "",
        availabilityHour: "",
        availability: "",
        originalQty: "",
        extendedPrice: formatAmount(totalextendedprice),
      });
      setProducts(products);
    }
    const headerStyle = {
      backgroundColor: "#0F5283",
      color: "#FFFFFF",
      "border-color": "#0F5283",
      align: "center",
      headerAlign: "center",
      width: "70px",
      "font-size": "14px"
    };
    const orderQtyHeaderStyle = {
      backgroundColor: "#0F5283",
      color: "#FFFFFF",
      "border-color": "#0F5283",
      align: "center",
      headerAlign: "center",
      width: "140px",
      "font-size": "14px"
    };

    const extendedPriceHeaderStyle = {
      backgroundColor: "#0F5283",
      color: "#FFFFFF",
      "border-color": "#0F5283",
      align: "center",
      width: "100px",
      headerAlign: "center",
      "font-size": "14px",
    };
    const availabilityHeaderStyle = {
      backgroundColor: "#0F5283",
      color: "#FFFFFF",
      "border-color": "#0F5283",
      "font-size": "14px",
      width: "90px",
    };

    const extendedPriceHeader = () => {
      return (
        <div>
          <span>Extended Price </span>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 400 }}
            overlay={
              <Tooltip
                id="extended-price-tooltip"
                className="tooltip-description-custom-corePrice"
              >
                <span className="font-weight-bold">
                  Extended Price = Qty * (Your Price + Core Price)
                </span>
              </Tooltip>
            }
          >
            <a className="whiteColorTxt info-icon">
              <i class="fa fa-info-circle"></i>
            </a>
          </OverlayTrigger>
        </div>
      );
    }

    const columns = [
      {
        dataField: "mfgCode",
        text: "P/L",
        headerStyle: headerStyle,
        formatter: function (cell, row) {
          return !_isEmpty(row.part) && row.part == "Total" ? (
            <div className="">
              <span className="text-black font-weight-bold">{"Total"}</span>
            </div>
          ) : (
            <span className="text-black">{row.mfgCode}</span>
          );
        },
        align: "center",
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
      },
      {
        dataField: "partNum",
        text: "Part#",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
      },
      {
        dataField: "desc",
        text: "Description",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
      },
      {
        dataField: "qty",
        text: "Order QTY",
        align: "center",
        headerAlign: "center",
        headerStyle: orderQtyHeaderStyle,
        formatter: function (cell, row, index) {
          return !_isEmpty(row.part) && row.part != "Total" ? (
            <Fragment>
              <div className="tableview-order bold">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    row.validationRed ? (
                      <Tooltip
                        id="description-part-catalog-tooltip"
                        className="tooltip-description"
                      >
                        <span className="font-weight-bold">
                          Please enter a quantity less than {row.totalAvailable}
                        </span>
                      </Tooltip>
                    ) : (
                      <span></span>
                    )
                  }
                >
                  {row.availability !== "Out Of Stock" ? (
                    <Fragment>
                      <input
                        className={
                          row.validationRed
                            ? "table_qty_order boder-red"
                            : "table_qty_order"
                        }
                        type="text"
                        disabled={
                          row.availability == "Out Of Stock" ? true : false
                        }
                        name="totalQt"
                        autocomplete="off"
                        defaultValue={row.qty ? row.qty : ""}
                        onBlur={(e) => {
                          handleInputOnBlur(
                            e,
                            row.part,
                            row.partNum,
                            row.availabilityHour
                          );
                        }}
                        onKeyDown={(event) => {
                          handleKeyDown(event);
                        }}
                      />
                      {row.availability === "Partial" ? (
                        <div className="">

                          <b>
                            <span className="maroonColorTxt">
                              {row.qty}{" "}
                            </span>{" "}<span>
                              Available out of{" "}
                              <span className="maroonColorTxt">
                                {row.originalQty}
                              </span>{" "}
                              requested
                            </span>
                          </b>

                        </div>
                      ) : (
                        <span></span>
                      )}
                    </Fragment>
                  ) : (
                    <div>
                      <div className="cart_view-availbility">
                        <b>Currently out of stock</b>
                      </div>
                    </div>
                  )}
                </OverlayTrigger>
              </div>
            </Fragment>
          ) : (
            <span className="font-weight-bold">{row.qty}</span>
          );
        },
      },
      {
        dataField: "costPrice",
        text: "Your Price",
        align: "center",
        hidden :  hideAllPrice ,
        headerStyle: headerStyle,
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        formatter: function (cell, row) {
          return !_isEmpty(row.part) && row.part != "Total" ? (
            <div className="">
              <span className="maroonColorTxt">
                ${formatAmount(row.costPrice)}
              </span>
            </div>
          ) : (
            <span></span>
          );
        },
        headerStyle: headerStyle,
      },
      {
        dataField: "corePrice",
        text: "Core Price",
        headerStyle: headerStyle,
        hidden :  hideAllPrice ,
        headerAlign: "center",
        align: "center",
        classes: "bootstrap-table-bold-cell",
        formatter: function (cell, row) {
          return !_isEmpty(row.part) && row.part != "Total" ? (
            <div className="">
              <span className="maroonColorTxt">
                ${formatAmount(row.corePrice)}
              </span>
            </div>
          ) : (
            <span></span>
          );
        },
      },
      {
        dataField: "listPrice",
        text: "List Price",
        hidden :  hideAllPrice ,
        align: "center",
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        formatter: function (cell, row) {
          return !_isEmpty(row.part) && row.part != "Total" ? (
            <div className="">
              <span className="maroonColorTxt">
                ${formatAmount(row.listPrice)}
              </span>
            </div>
          ) : (
            <span></span>
          );
        },
        headerStyle: headerStyle,
      },
      {
        dataField: "extendedPrice",
        text: "Extended Price",
        align: "center",
        hidden :  hideAllPrice ,
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        headerStyle: extendedPriceHeaderStyle,
        headerFormatter: (column, colIndex) => { return extendedPriceHeader() },
        formatter: function (cell, row, index) {
          if (row.extendedPrice && row.part != "Total") {
            <span>{row.extendedPrice}</span>;
            return (
              <div className="">
                <span className="maroonColorTxt">
                  $
                  {getExtendedTotalPrice(row.corePrice, row.costPrice, row.qty)}
                </span>
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    <Tooltip
                      id="delete-tooltip"
                      className="cart-preview-delete"
                    >
                      <span className="font-weight-bold">
                        Remove this item from the cart.
                      </span>
                    </Tooltip>
                  }
                >
                  <img
                    src={deleteIcon}
                    className="delete-icon"
                    onClick={(event) => {
                      handleDeleteItem(event, row.part);
                    }}
                    alt="Delete"
                  />
                </OverlayTrigger>
              </div>
            );
          } else {
            return (
              <div>
                <span className="maroonColorTxt bolder">{row.extendedPrice}</span>
              </div>
            );
          }
        },
      },
      {
        dataField: "part",
        hidden: true,
      },
      {
        dataField: "availabilityHour",
        hidden: true,
      },
      {
        dataField: "originalQty",
        hidden: true
      }
    ];
    setColumns(columns);
  }, [checkoutDetails]);

  const handleDeleteItem = (e, part) => {
    const obj = [part];
    //let { userPrefViewForLostSale, warningMessage } = this.state;
    setshowLostSales(true);
    setLostSaleData(obj);
    // this.setState({
    //   showLostSales: t,
    //   lostSalesData: obj,
    //   sourceOfLostSales: "deleteIcon",
    //   openWarning: !userPrefViewForLostSale ? true : false,
    //   warningMessage: "You want to delete this cart item",
    // });
  };

  const handleInputOnBlur = (e, part, partNum, availabilityHour) => {
    let {
      target: { value },
    } = e;
    value = _isEmpty(value) ? 0 : value;
    //let { cartAllDetails, showLostSales, lostSalesData } = this.state;
    if ((Number(value) !== Number(part.qty))) {
      let cartData = _cloneDeep(checkoutDetails);
      let distributionCompleted = false;
      let partIndex = _findIndex(cartData, {
        availabilityHour: availabilityHour,
        partNum: partNum,
        itemId: part.itemId && part.itemId,
      });
      if (!_isEmpty(value) && Number(value) !== 0) {
        value = getPartPackQty(
          part.qtyBuyInc,
          Number(value),
          cartData[partIndex].availableQty
        );
        value = Number(value);
      }
      let defaultValue = cartData[partIndex].qty;
      cartData[partIndex].qty = Number(value);
      if (Number(value) === 0) {
        cartData[partIndex].qty = value;
        setshowLostSales(true);
        setLostSaleData([part]);
        e.target.value = defaultValue;
      } else {
        addItemToCart2(cartData, dispatch, distributionCompleted, userDetails, "cartpopup");
      }
    }
  };

  const handleKeyDown = (event) => {
    const qtyValue = event.target.value;
    if (
      (numbersOnlyRegExp(event.key) === false ||
        (qtyValue && qtyValue.length >= 4) ||
        event.keyCode === 32) &&
      handleOtherKeys(event.keyCode)
    ) {
      event.preventDefault();
    }
    if (event.key == 'Enter' && event.keyCode == 13) {
      handleInputOnBlur();
    }
  };

  const handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  return (
    <div
      className="order-preview-table dynamic-width"
      ref={(el) => (componentRef = el)}
    >
      {product && product.length === 0 ? (
        <span>There are no items in your cart.</span>
      ) : (
        <Fragment>
          <div className="order-text">You can modify quantity or remove from the cart before proceeding to Checkout</div>
          <div className="cart-preview-table table-padding">
            <BootstrapTable
              keyField="id"
              data={product}
              columns={columns}
            // cellEdit={cellEditFactory({ mode: "click" })}
            />
          </div>
        </Fragment>
      )}
      {cartApiFetching && (
        <div className="custom_spinner_container">
          <Spinner className="custom_spinner" animation="border" />
        </div>
      )}
      {showLostSales ? (
        <LostSales
          isCart={true}
          openPopup={showLostSales}
          closePopup={() => {
            setshowLostSales(false);
          }}
          selectedPartDetails={lostSaleData}
          dispatch={dispatch}
          userId={userDetails.x2userName}
          source={"deleteIcon"}
        />
      ) : null}
    </div>
  );
};

export default CartPreview;
