import httpClient from "../../../services/http";
import { buildQueryUrl } from "../../../helpers/utils";

export const getMyGarageVehicles  = (data) =>
httpClient.post(`/user-service/search/vehicle/byCustomer?`,data.requestItems,{ params: data.params });

// export const getMyGarageVehicles = (data) =>
//   httpClient.get(`/user-service/search/vehicle?${buildQueryUrl(data)}`);

export const setMyGarageVehicles = (data) =>
  httpClient.post(`/user-service/search/vehicle`, data);

// export const searchMyGarageVehicles = (data) =>
//   httpClient.get(`/user-service/search/vehicle/find?${buildQueryUrl(data)}`);

  export const searchMyGarageVehicles  = (data) =>
httpClient.post(`/user-service/search/vehicle/find/${data.searchKey}`, data.requestItems);

export const deleteMyGarageVehicle = (data) =>
  httpClient.delete(`/user-service/search/vehicle?${buildQueryUrl(data)}`);

export const getMyGarageQuotes = (data) =>
  httpClient.post(`/order-service/quotes`, data);

export const deleteMyGarageQuote = ({ id }) =>
  httpClient.delete(`/order-service/quotes/${id}`);

export const viewMyGarageQuote = ({ id, data }) =>
  httpClient.get(`/order-service/quotes/${id}?${buildQueryUrl(data)}`);

export const searchMyGarageQuote = (data) =>
  httpClient.post(`/order-service/quotes`, data);

// Parts History APIs

export const setMyGaragePartHistory = (data) =>
  httpClient.post(`/user-service/search/parts`, data);

  export const getMyGaragePartsHistory  = (data) =>
httpClient.post(`/user-service/search/parts/byCustomer?`,data.requestItems,{ params: data.params });

// export const getMyGaragePartsHistory = (data) =>
//   httpClient.get(`/user-service/search/parts?${buildQueryUrl(data)}`);

// export const searchMyGaragePartsHistory = (data) =>
//   httpClient.get(`/user-service/search/parts/find?${buildQueryUrl(data)}`);

  export const searchMyGaragePartsHistory  = (data) =>
httpClient.post(`/user-service/search/parts/find/${data.searchKey}`, data.requestItems);

// Return  APIs
export const getMyGarageReturn = (data) =>
  httpClient.get(`/order-service/return?${buildQueryUrl(data)}`);

export const createReturn = (data) =>
  httpClient.post(`/order-service/return/initiate`, data);

export const getInvoice = (data) =>
  httpClient.post(`/order-service/invoices/detail?typeFilter=Charge`, data);

export const getHomePagePartTypes = (data) =>
  httpClient.post(`/catalog/part/partTypes?year=${data.year}&make=${data.make}&model=${data.model}&engine=${data.engine}`, data.requestItems);
  
export const createOrUpdateStore = (data) =>
  httpClient.post(
    `/order-service/return/${data.return_id}?action=${data.action}`,
    data.payload
  );

export const getInvoiceStoreData = (data) =>
  httpClient.post(
    `/order-service/invoices/detail?billTo=${data.billTo}&shipTo=${data.shipTo}`,
    data.payload
  );

//getstores
export const getAllStore = () => httpClient.get(`/utils-service/stores`);

export const deleteMyGaragePartHistory = (data) =>
  httpClient.delete(`/user-service/search/parts?${buildQueryUrl(data)}`);

// Orders APIs
export const getMyGarageOrders = (data) =>
  httpClient.get(`/order-service/orders?${buildQueryUrl(data)}`);

//Credit
export const getMyGarageCreditsUrl = (data) =>
  httpClient.get(`/utils-service/credits`, { dispatch: data.dispatch });

// Metabase info api
export const getMetabaseTokenInfo = () =>
  httpClient.get(`/utils-service/metabase/token?${buildQueryUrl()}`);

// Jobs APIs
export const addMyGarageJob = (data) =>
  httpClient.post(`/user-service/search/jobs`, data);

export const getMyGarageJobs = (data) =>
  httpClient.get(`/user-service/search/getjobs?${buildQueryUrl(data)}`, {
    dispatch: data.dispatch,
  });

export const searchMyGarageJobs = (data) =>
  httpClient.get(`/user-service/search/jobs/find?${buildQueryUrl(data)}`, {
    dispatch: data.dispatch,
  });

export const deleteMyGarageJob = (data) =>
  httpClient.delete(`/user-service/search/jobs?${buildQueryUrl(data)}`, {
    dispatch: data.dispatch,
  });

export const getMyGarageAllPartDescription = (data) =>
  httpClient.get(`/catalog/part/description?${buildQueryUrl(data)}`, {
    dispatch: data.dispatch,
  });

export const getMyGarageVehicleByAcesIds = (data) =>
  httpClient.get(`/catalog/vehicle/aces?${buildQueryUrl(data)}`, {
    dispatch: data.dispatch,
  });

export const getMyGarageAllChemicalDescription = (data) =>
  httpClient.get(
    `/catalog/chemical/category/group/part?${buildQueryUrl(data)}`,
    { dispatch: data.dispatch }
  );

export const putQuoteName = (id, data) =>
  httpClient.delete(`/user-service/search/jobs?${buildQueryUrl(data)}`);

// Statements
export const getMyGarageStatementsUrl = (data) =>
  httpClient.get(`/utils-service/statement`, { dispatch: data.dispatch });

export const getMyGarageStatementsChangedUrl = (data) =>
  httpClient.get(`/utils-service/statement/${data.accountNum}`);

//Reports
export const getMygarageReports = () =>
  httpClient.get(`/utils-service/reports?${buildQueryUrl()}`);

//getstores
export const getAllStoreData = (data) =>
  httpClient.get(`/utils-service/stores/${data.storeNumber}`);

//Fetch payment by customer
export const getPaymentsByCustomer = (data) =>
  httpClient.get(`/user-service/accounts/payment/${data.code}`);

//Fetch  payment by start and end date with customer number
export const getPaymentsByCustomerAndDate = ({ id, data }) =>
  httpClient.get(`/user-service/accounts/payment/${id}?${buildQueryUrl(data)}`);

//Fetch all customer payment with start_date & end_date
export const getPaymentsByAllCustomersAndDate = (data) =>
  httpClient.get(`/user-service/accounts/payment?${buildQueryUrl(data)}`);

//Fetch balance by customer

export const getCustomerBalance = (data) =>
  httpClient.get(`/user-service/accounts/balance/${data.code}`);

export const getAppVersion = (data) =>
  httpClient.get(`/utils-service/rapid/version`, { dispatch: data.dispatch });

  export const getMyGarageRecommendedjJobs = (data) =>
  httpClient.get(`/user-service/search/getrecommendedjobs?${buildQueryUrl(data)}`, {
    dispatch: data.dispatch,
  });


export default httpClient;
