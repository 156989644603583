import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _compact from 'lodash/compact';
import _cloneDeep from 'lodash/cloneDeep';
import { Container, Carousel, Modal, Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Placeholder from '../../shared/Placeholder';
import SingleSupplyItem from '../../ChemicalsAndSupplies/Listing/SingleSupplyItem';
import ProPackAccSingleCard from '../../shared/ProPack/ProPackAccSingleCard';
// import VehicleBreadCrumb from '../../shared/VehicleBreadCrumb';
import _ from 'lodash';
import VechicleBread from '../../shared/VechicleBread';
import { formatAmount, deliveryPickupWarning ,getExtendedTotalPrice, getDistributionForVehicle, getDistributionForNonVehicle, distributeAvailability2 } from '../../../helpers/utils';
import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import AddToCart from '../../shared/AddToCart/AddToCart';
import { setJobEventStatus } from '../../shared/MyGarage/mygarage.slice';
import deleteIcon from '../../../static/images/deleteIcon.png';
import './styles.scss';
import _flatten from 'lodash/flatten';
import _sumBy from 'lodash/sumBy';
import _map from 'lodash/map';
import _every from 'lodash/every';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import { getstoreName } from '../../../helpers/utils';
import AddToCartWarningPopup from "../../shared/AddToCartWarningPopup/AddToCartWarningPopup";
import ShipInfoHeader from "components/shared/ShipInfoHeader";

const CustomJob = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const parts = useSelector((state) => state.parts);
	const cartShipMethod = useSelector((state) => state.parts.cartShipMethod);
	const cart = useSelector((state) => state.cart);
	// let partsAllDetails = parts.partsAllDetails;
	// const selectedJobDetails = parts.selectedJobDetails;
	// const suppliesAllDetails = parts.suppliesAllDetails,
	const [partsAllDetails, setPartsAllDetails] = useState([]);
	const [removeSelectedParts , setRemoveSelectedParts] = useState([]);
	const [jobItems, setJobItems] = useState({ 'partJobItems': [], 'chemicalJobItems': [] });
	const [partIdMap, setPartIdMap] = useState([]);
	const mygarage = useSelector((state) => state.mygarage);
	const search = useSelector((state) => state.search);
	const sv = mygarage.selectedVehicle || search.selectedVehicle;
	const userDetails = useSelector((state) => state.user.userDetails);
	const allStores = useSelector((state) =>state.userManagement.allStores);
	const hideAllPrice =  parts  && parts.hidePrice;
	const addToCartWarning = false;//deliveryPickupWarning(userDetails, parts)
	const [addToCartWarningPopup, setaddToCartWarningPopup] = useState(false);

	useEffect(() => {
		dispatch(setJobEventStatus({ status: false }));
		if (_isEmpty(sv)) {
			history.push("/");
		}
	}, []);

	useEffect(() => {
		dispatch(setJobEventStatus({ status: false }));
		let jobItemsTemp = _cloneDeep(jobItems);
		jobItemsTemp['partJobItems'] = [];
		jobItemsTemp['chemicalJobItems'] = [];
		setPartIdMap([]);
		if (!_isEmpty(parts.partsAllDetails) && parts.selectedJobDetails) {
			jobItemsTemp['partJobItems'] = _cloneDeep(_filter(parts.selectedJobDetails.jobsDetails, (j) =>
				j.partType === 'catalog'
			));
			jobItemsTemp.partJobItems.map((jI, key) => {
				jI.partDetails = _filter(parts.partsAllDetails, (p) =>
					p.descriptionID === jI.partTypeId
				);
			});

			if(parts && parts.selectedJobDetails && parts.selectedJobDetails.recommendedJob === "Y"){
			const newItem = new Map();
			jobItemsTemp.partJobItems.map((it)=>{
				let Id = it.categoryId + "-" + it.groupId;
			if(newItem.get(Id) === null || newItem.get(Id) === undefined){
				newItem.set(Id, it)
			}else{
				newItem.get(Id).partDetails.push(...it.partDetails);
			}
			})
			console.log(newItem)
			jobItemsTemp.partJobItems = [] ;
		    newItem.forEach((values)=>{
			if(!_isEmpty(values)){
			jobItemsTemp.partJobItems.push(values);
			}
		    }) 
		}
			setJobItems(jobItemsTemp);
			setPartsAllDetails(parts.partsAllDetails);
		}
		if (!_isEmpty(parts.suppliesAllDetails) && parts.selectedJobDetails) {
			jobItemsTemp['chemicalJobItems'] = _cloneDeep(_filter(parts.selectedJobDetails.jobsDetails, (j) =>
				j.partType === 'chemicals'
			));
			jobItemsTemp.chemicalJobItems.map((jI, key) => {
				jI.partDetails = _filter(parts.suppliesAllDetails, (p) =>
					p.partDescriptionID === Number(jI.partTypeId)
				);
			});
			if(parts && parts.selectedJobDetails && parts.selectedJobDetails.recommendedJob === "Y"){
			const newChemItem = new Map();
			jobItemsTemp.chemicalJobItems.map((it)=>{
				let Id = it.categoryId + "-" + it.groupId;
			if(newChemItem.get(Id) === null || newChemItem.get(Id) === undefined){
				newChemItem.set(Id , it)
			}else{
				(newChemItem.get(Id)).partDetails.push(...it.partDetails);
			}
			})
			jobItemsTemp.chemicalJobItems = [] ;
		    newChemItem.forEach((values)=>{
			if(!_isEmpty(values)){
			jobItemsTemp.chemicalJobItems.push(values);
			}
		    }) 
		}
			setJobItems(jobItemsTemp);
			// setPartsAllDetails(parts.partsAllDetails);
		}
	}, [parts.partsAllDetails, parts.suppliesAllDetails]);

	const addToCartForWarning = () => {
		setaddToCartWarningPopup(true);
	}

	const handleDeleteItem = (item) => {
		let partIdMapTemp = _cloneDeep(partIdMap);
		if(!_isEmpty(item) && item.uniqueId !== undefined){
		partIdMapTemp = partIdMapTemp.filter(part => part.uniqueId !== item.uniqueId);
		}
		setPartIdMap([...partIdMapTemp]);
	}

	const handleRecommendedJobCreate = () => {
		if(!_isEmpty(parts) && parts.selectedJobDetails){
			dispatch({
			  payload: {
				recommendedJobEditJob: parts.selectedJobDetails,
			  },
			  type: 'RECOMMENDEDJOB_EDIT_JOB',
			});
		  }
		  dispatch({
			payload: {
			  createRecommendedJob: true,
			},
			type: 'CREATE_RECOMMENDEDJOB',
		  });
		  history.push({
			pathname: "/job-create",
			search: '?recommended=1'
		  });
	  };
	const handlePartSelect = (
		selected,
		totalOrdrQty,
		descId,
		costPrice,
		proPackKey,
		partCount,
		part = true
	) => {
		 let partIdMapTemp = _cloneDeep(partIdMap);
		 
		let isAvailableInPartIdMap = false;
		totalOrdrQty = Number(totalOrdrQty);
        if(partIdMap.length > 0) {
			partIdMap.map((value, index) => {
			   let selectedValue = value.selectedPartAvb[0];
			   let selectedPartNum = selected && selected[0];
               if(!_isEmpty(selectedPartNum) && selectedPartNum.partNum === selectedValue.partNum) {
				isAvailableInPartIdMap = true;
				partIdMapTemp[index].totalOrdrQty =  totalOrdrQty;
				partIdMapTemp[index].extendedPrice = costPrice * totalOrdrQty;
				partIdMapTemp[index].minQtyAdded = part
				? totalOrdrQty >=
				jobItems.partJobItems[proPackKey].pminSKU
				: totalOrdrQty >=
				jobItems.chemicalJobItems[proPackKey].cminSKU;
			   }
			});
		}
		if(!isAvailableInPartIdMap) {
			partIdMapTemp.push({
				uniqueId: Math.random(),
				totalOrdrQty,
				costPrice,
				extendedPrice: costPrice * totalOrdrQty,
				descriptionId: descId,
				minQtyAdded: part
				? totalOrdrQty >=
				jobItems.partJobItems[proPackKey].pminSKU
				: totalOrdrQty >=
				jobItems.chemicalJobItems[proPackKey].cminSKU,
				selectedPartAvb: selected,
				name:  part
				? 
				jobItems.partJobItems[proPackKey].partTypeDesc
				: 
				jobItems.chemicalJobItems[proPackKey].partTypeDesc
			});
		}
		setPartIdMap(partIdMapTemp);
	};
	const renderSingleItemCard = (singleItem, key) => {
		let { name, pminSKU } = singleItem;
		let accClass = '';
		let categ = _find(parts.categoryGroupMap, (c) => {
			return _includes(_map(c.groups, 'code'), singleItem.groupId);
		});

		if (!categ) {
			categ = {};
		}

		let grp = {}
		if (categ && categ.groups) {
			grp = _find(categ.groups, (g) => {
				return g.code === singleItem.groupId;
			});
		}
		let accHeader = "" ;
		if(singleItem.recommendedjob === "Y"  || ( parts && parts.selectedJobDetails &&  parts.selectedJobDetails.recommendedJob === "Y" )) {
		  accHeader = `${categ.name}  - ${grp.name}`;
          }else{
		  accHeader = `${categ.name} - ${grp.name} - ${singleItem.partTypeDesc}`;
          }

		if (_isEmpty(singleItem.partDetails)) {
			accHeader += ` - No Parts Found for this vehicle`;
			accClass += ' disabled-acc';
		}

		if (partIdMap.some((selectedPart) => singleItem.partTypeDesc == selectedPart?.name)) {
			accClass += ' min-qty-selected';
		}

		return (
			<ProPackAccSingleCard
				singleItem={singleItem}
				pKey={key}
				partIdMap={partIdMap}
				accHeader={accHeader}
				accClass={accClass}
				handlePartSelect={handlePartSelect}
			/>
		);
	};

	const renderSingleChem = (item, key, jobCount) => {
		let {partTypeDesc, partDetails } = item;
		let accClass = '';
		let count = jobCount + key;
		let categ = _find(parts.categorySuppliesMap, (c) => {
			return _includes(_map(c.groups, 'id'), Number(item.groupId));
		});
		let grp = _find(categ.groups, (g) => {
			return g.id === Number(item.groupId);
		});
		let accHeader = "" ;

		if(item.recommendedjob === "Y"  || ( parts && parts.selectedJobDetails &&  parts.selectedJobDetails.recommendedJob === "Y" )) {
		 accHeader = ` ${categ.name}  - ${grp.name}`;
		 }else{
			accHeader = `${categ.name} - ${grp.name} - ${item.partTypeDesc}`;
		 }

		if (_isEmpty(item.partDetails)) {
			accHeader += ` - No Parts Found for this vehicle`;
			accClass += ' disabled-acc';
		}
		if (partIdMap.some((selectedPart) => partTypeDesc == selectedPart?.name)) {
			accClass += ' min-qty-selected';
		}
		// if (partIdMap[count] && partIdMap[count].totalOrdrQty > 0) {
		// 	accClass += ' min-qty-selected';
		// }

		return (
			<Card>
				<Accordion.Toggle
					as={Card.Header}
					eventKey={`${key}chemAccordion`}
					className={accClass}
				>
					{accHeader}
				</Accordion.Toggle>
				<Accordion.Collapse eventKey={`${key}chemAccordion`}>
					<Card.Body>
						{partDetails.map((part, k) => {
							return (
								<SingleSupplyItem
									partDetails={part}
									dispatch={dispatch}
									proPack={true}
									handlePartSelect={handlePartSelect}
									partCount={count}
									proPackKey={key}
								/>
							);
						})}
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		);
	};

	const renderSumListItem = (singleItem, key, part = true , partType) => {
		let grp = {}
		let categ = "";
		let recJob = "";
		let recommendedJob =  parts && parts.selectedJobDetails &&  parts.selectedJobDetails.recommendedJob === "Y" ? true : false ; 
		let summaryDetails = [];
		partIdMap.map((value, partMapIndex) => {
			if(!_isEmpty(value) && value !== undefined){
			 _.remove(removeSelectedParts,(p) => p === value.uniqueId);

			if(partType === "catalog" && recommendedJob){
				recJob =  !_isEmpty(singleItem.partDetails)  && singleItem.partDetails.find((v)=> Number(value?.descriptionId) === Number(v.descriptionID) && v.partNumber == value.selectedPartAvb[0]?.partNum && v.desc == value.selectedPartAvb[0]?.description );
			} else 
			if(partType === "chemical" && recommendedJob) {
				recJob = !_isEmpty(singleItem.partDetails)  && singleItem.partDetails.find((v)=> Number(value?.descriptionId) === Number(v.partDescriptionID) && v.partSKU == value.selectedPartAvb[0]?.partNum && v.partDescription == value.selectedPartAvb[0]?.desc );
			}	 		 
			 if ((!recommendedJob && Number(value.descriptionId) === Number(singleItem.partTypeId)) || (!_isEmpty(recJob) && recommendedJob && (Number(recJob.descriptionID) === Number(value.descriptionId) || Number(recJob.partDescriptionID) === Number(value.descriptionId)) )) {				
				
				removeSelectedParts.push(value.uniqueId);			   
				let selectedPartAvb =   !_isEmpty(value) && value.selectedPartAvb && value.selectedPartAvb[0] ;

				summaryDetails.push(
					<React.Fragment> 
						<div className="propacks_summary_details">
							<span>
								Part#:
								<b>
									{ !_isEmpty(selectedPartAvb) && selectedPartAvb.partNum ? selectedPartAvb.partNum : ''}
								</b>
							</span>
							<span>
								Qty:
								<b>
									{value.totalOrdrQty ? value.totalOrdrQty : ''}
								</b>
							</span>
                            {!hideAllPrice && ( 
							<span>
								Your Price:
								<b>{value.costPrice ? value.costPrice : ''}</b>
							</span>)}
							{!hideAllPrice && ( 
							<span>
								Core Price:
								<b>{ !_isEmpty(selectedPartAvb) && selectedPartAvb.corePrice ? selectedPartAvb.corePrice : 0 }</b>
							</span>)}
							{!hideAllPrice && ( 
							<span>
					         Total Extended Price :
						<b>
						{value && !_isEmpty(selectedPartAvb)
						? getExtendedTotalPrice(selectedPartAvb.corePrice, value.costPrice, value.totalOrdrQty)
						: 0}
						</b>
					</span>)}
							<span>
								<img
									src={deleteIcon}
									className="delete-propack-icon"
									onClick={(event) => {
										handleDeleteItem(value);
									}}
									alt="Delete"
								/>
							</span>
						</div>
					</React.Fragment>
				);
			}
		}
		});
		
		if(recommendedJob && !_isEmpty(partIdMap)){
		if(partType === "catalog"){
		 categ = _find(parts.categoryGroupMap, (c) => {
			return _includes(_map(c.groups, 'code'), singleItem.groupId);
		});	
		if (categ && categ.groups) {
			grp = _find(categ.groups, (g) => {
				return g.code === singleItem.groupId;
			});
		}
	} 
	  else 
	if(partType === "chemical"){
		categ = _find(parts.categorySuppliesMap, (c) => {
			return _includes(_map(c.groups, 'id'), Number(singleItem.groupId));
		});
		   grp = _find(categ.groups, (g) => {
			return g.id === Number(singleItem.groupId);
		});
	}
}

          let indexValue = !_isEmpty(removeSelectedParts) && !_isEmpty(partIdMap) &&
		        partIdMap.findIndex((j)=> {
				if(!_isEmpty(j) && j.uniqueId !== undefined){
	           return removeSelectedParts.find((item)=> {
	              return item === j.uniqueId ;
	            })
			     }
             })
      
       if (indexValue !== undefined && indexValue >= 0 && partIdMap[indexValue] && partIdMap[indexValue].totalOrdrQty > 0 ){
			return (
				<React.Fragment>
					<span>
						{recommendedJob && !_isEmpty(grp) ? (` ${categ.name}  - ${grp.name}`) : singleItem.partTypeDesc}
					</span>
					<br />
					{summaryDetails}
					<br />
				</React.Fragment>
			);
	}
	};

	const prepareJobRequestData = () => {
		let selectedAVailabilityHourPayload = [];
		!_isEmpty(partIdMap) && partIdMap.map((product) => {
			let selectedAvailData =
			product && product.selectedPartAvb && product.selectedPartAvb[0];
	        if(!_isEmpty(selectedAvailData)) {		
			let tempObj = {
				availabilityHour: selectedAvailData.availabilityHour,
				availableQty: selectedAvailData.availableQty,
				cartFlag: "no",
				categoryId: selectedAvailData.categoryId,
				costPrice: selectedAvailData.costPrice,
				corePrice: selectedAvailData.corePrice,
				coreListPrice: selectedAvailData.coreListPrice,
				desc: selectedAvailData.desc,
				descId: selectedAvailData.descId,
				engineId: selectedAvailData.engineId,
				engine: selectedAvailData.engine,
				groupId: selectedAvailData.groupId,
				listPrice: selectedAvailData.listPrice,
				makeId: selectedAvailData.makeId ? selectedAvailData.makeId : null,
				internalMakeId: selectedAvailData.internalMakeId
					? selectedAvailData.internalMakeId
					: null,
				make: selectedAvailData.make ? selectedAvailData.make : null,
				modelId: selectedAvailData.modelId ? selectedAvailData.modelId : null,
				model: selectedAvailData.model ? selectedAvailData.model : null,
				mfgCode: selectedAvailData.mfgCode,
				packQty: selectedAvailData.packQty,
				partNum: selectedAvailData.partNum,
				partType: selectedAvailData.partType,
				qty: selectedAvailData.qty,
				qtyPerVeh: selectedAvailData.qtyPerVeh,
				toolTip: selectedAvailData.toolTip,
				vehicleInfo: selectedAvailData.vehicleInfo,
				yearId: selectedAvailData.yearId ? selectedAvailData.yearId : null,
				store: selectedAvailData.store,
				sellPartnerId: selectedAvailData.sellPartnerId,
				shipMethod: selectedAvailData.shipMethod,
				distStatus: "Completed",
				orderType: "REGULAR"
			};
			let orderArray = [];
			let distributionData = {};
			let cartData  = _cloneDeep(cart);
			cartData.checkoutDetails = selectedAVailabilityHourPayload.length > 0 ? selectedAVailabilityHourPayload : cartData.checkoutDetails;
			if (selectedAvailData.partType === "Regular") {
				distributionData = getDistributionForVehicle(
					selectedAvailData.partNum,
					selectedAvailData,
					selectedAvailData.availability,
					Number(product.totalOrdrQty),
					selectedAvailData.selectedVehicle,
					cartData
				);
			} else {
				distributionData = getDistributionForNonVehicle(
					selectedAvailData.partNum,
					selectedAvailData,
					selectedAvailData.availability,
					Number(product.totalOrdrQty),
					cartData
				);
			}
			orderArray = distributeAvailability2(
				Number(distributionData.totalQty),
				distributionData.filteredAvailability
			);
			selectedAVailabilityHourPayload = [];
			selectedAVailabilityHourPayload.push(
				...distributionData.alreadyAddedItem
			);
			orderArray.map((availabilityData) => {
				tempObj = {
					...tempObj,
					availabilityHour: availabilityData.availableDuration,
					availableQty: availabilityData.availableQty,
					qty: !_isEmpty(availabilityData.orderQty.toString())
						? Number(availabilityData.orderQty.toString())
						: Number("1"),
					distStatus: "Completed",
				    orderType: "REGULAR"
				};
				selectedAVailabilityHourPayload.push(tempObj);
			});
		  }
		});
		/* below filter for remove duplicates todo later */
		let uniqueAvailabilityData = !_isEmpty(selectedAVailabilityHourPayload) && selectedAVailabilityHourPayload.filter(
			(ele, ind) =>
				ind ===
				selectedAVailabilityHourPayload.findIndex(
					(elem) =>
						elem.partNum === ele.partNum &&
						elem.mfgCode === ele.mfgCode &&
						elem.vehicleInfo === ele.vehicleInfo &&
						elem.availabilityHour === ele.availabilityHour
				)
		);
		return uniqueAvailabilityData;
	};

	const renderSummary = () => {
		return (
			<Card>
				<Accordion.Toggle
					as={Card.Header}
					eventKey="3"
					className="xlp-accordian-header"
				>
					Check & Add to cart
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="3">
					<Card.Body>
						<div className="propacks_summary">
							<h4 className="summary_heading">Summary</h4>
							<div className="propacks_summary_section">
								{!_isEmpty(jobItems.partJobItems) && jobItems.partJobItems.map((singleItem, key) => {
									return renderSumListItem(
										singleItem,
										key,
										true,
										"catalog"
									);
								})}
								{!_isEmpty(jobItems.chemicalJobItems) && jobItems.chemicalJobItems.map((singleItem, key) => {
									return renderSumListItem(
										singleItem,
										key + jobItems.partJobItems.length,
										false,
										"chemical"
									);
								})}
								{!_isEmpty(partIdMap) && (
									<div className="propack-total-section">
										<span>
											Total Quantity:{' '}
											<b>
												{_sumBy(
													partIdMap,
													'totalOrdrQty'
												)}
											</b>
										</span>
										{!hideAllPrice && (<span>
											Your Total Price:{' '}
											<b>
												{formatAmount(
													_sumBy(
														partIdMap,
														'extendedPrice'
													)
												)}
											</b>
										</span>)}
									</div>
								)}
								<FormValidationErrorMessage
									condition={
										_isEmpty(partIdMap)
									}
									errorMessage={
										'Please select required SKUs from above steps to add the parts to the cart'
									}
								/>
								 {addToCartWarning ? 
								 (<div className="prod_quantity_section">
									<Fragment>
		                            <Button
			                           className="add-to-cart-btn"
			                           onClick={() => addToCartForWarning()}
									   disabled={
										_isEmpty(partIdMap)
									}
		                            >
			                        Add to cart
		                            </Button>
		                         </Fragment>
								</div>) :								
								(<div className="prod_quantity_section">
									<AddToCart
										// selectedAvailabilityHours={_compact(_flatten(
										// 	_map(partIdMap, 'selectedPartAvb')
										// ))}
										selectedAvailabilityHours={prepareJobRequestData()}
										disabled={
											_isEmpty(partIdMap)
										}
										fromPage={"customeJob"}
									/>
								</div>)}
							</div>
						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		);
	};

	return (
	  <Fragment>
		<Container>
			<VechicleBread
				clearLists={() => {
					history.push("/", { vehicleSelectionDone: false });
				}}
			/>
				<div className="wc-ship-info mx-auto">
				<ShipInfoHeader/>
				</div>
			{/* {userDetails &&
              userDetails.shipTos &&
              userDetails.shipTos.length > 0 &&
              userDetails.shipTos[0].shipMethod ? (

              <div className="wc-ship-info mx-auto maroonColorTxt"> */}
                {/* <span>
                  {"All items are Pickup - please pick up at store # " +
                    userDetails.shipTos[0].storeNumber}
                </span> */}
                {/* <span >
									Ship Method:<span className="text-black">{cartShipMethod && cartShipMethod != null? (cartShipMethod == "0002" ? " Pickup " : " Delivery ") :(userDetails.shipTos[0] && userDetails.shipTos[0].shipMethod  === '0002' || userDetails.shipTos[0].shipMethod  === '0001' ? ' Pickup ' : ' Delivery ')} </span>From TPH Parts Location : <span className="text-black">{parts.cartStore ? parts.cartStore + " - " +  getstoreName(allStores,parts.cartStore , cartShipMethod ,userDetails)  : (userDetails.shipTos[0].storeNumber + " - " + userDetails.shipTos[0].location.storeName)}</span>
                </span>
              </div> */}
            {/* ) : (
              ""
            )} */}
			<section className="propacks_list_main_wrapper">
				<Row>
					<Col lg={12} md={12}>
						<div className="right_section">
						{ (parts && parts.selectedJobDetails && parts.selectedJobDetails.recommendedJob === "Y") ?  
				(<>
				<div className="jobName">
					 { parts.selectedJobDetails && parts.selectedJobDetails.jobName
						? parts.selectedJobDetails.jobName
						: ""
					 }
				  </div>
				  <br></br>
				<div className="recomjob_header_statement">
				Select the parts from the below part groups and review the summary in Check & Add to Cart section</div>
				</>):
				(<div className="propacks_header">
                  {`Select the parts from ${
                    parts.selectedJobDetails && parts.selectedJobDetails.jobName
                      ? parts.selectedJobDetails.jobName
                      : ""
                  }`}
                </div>)
				}

			{ (!_isEmpty( userDetails) &&  userDetails.userType === "IT_INTERNAL") && parts &&  parts.selectedJobDetails && 
			 (parts.selectedJobDetails.recommendedJob === "Y") && (
				<Button
				className="editJob_header"
				onClick={(e) => handleRecommendedJobCreate()}
			  >
				Edit
			  </Button>
			  
             )}
							{!parts.partsApiCall ? (
								<div className={`propacks_list_wrapper`}>
									<Accordion defaultActiveKey="0accordion">
										{!_isEmpty(jobItems.partJobItems) &&
											jobItems.partJobItems.map((singleItem, key) => {
												return renderSingleItemCard(
													singleItem,
													key
												);
											})}

										{!_isEmpty(jobItems.chemicalJobItems) &&
											jobItems.chemicalJobItems.map((s, k) => {
												return renderSingleChem(
													s,
													k,
													jobItems.partJobItems.length
												);
											})}


										{renderSummary()}
									</Accordion>
								</div>
							) : (
								<React.Fragment>
									<Placeholder rows={4} />
								</React.Fragment>
							)}
						</div>
					</Col>
				</Row>
			</section>
		</Container>
		{addToCartWarningPopup ? (<AddToCartWarningPopup
			openPopup={addToCartWarningPopup}
			//addedToCartOrQuote={addedToCartOrQuote}
			selectedAvailabilityHours={_compact(_flatten(
				_map(partIdMap, 'selectedPartAvb')
			))}
			//source={'chemicals'}
			DefaultAddItemToCart={true}
			closePopup={() => {
				setaddToCartWarningPopup(false)
			}}
		/>) : null}
	  </Fragment>
	);
}
export default CustomJob;
