import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Accordion, Card, InputGroup } from 'react-bootstrap';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';
import _findIndex from 'lodash/findIndex';

function JobFilterSection(props) {
  const { togglePartCategoryPanel,createRecommendedJob, selectedChemicalDetails, toggleChemicalCategoryPanel, partGroupSelection, chemicalGroupSelection, categoryGroupMap, categorySuppliesMap, selectedJobDetails, handleRecommendedPartGroupSelection, handleRecommendedChemicalSelection } = props;
  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;
  const [partCategoryToggle, setPartCategoryToggle] = useState(false);
  const [chemicalCategoryToggle, setChemicalCategoryToggle] = useState(false);

  const partCategoryHeaderRef = useRef();
  const partCategoryItemRef = useRef([]);

  const chemicalCategoryHeaderRef = useRef();
  const chemicalCategoryItemRef = useRef([]);

  const togglePartCategory = () => {
    setPartCategoryToggle(!partCategoryToggle);
  }

  const toggleChemicalCategory = () => {
    setChemicalCategoryToggle(!chemicalCategoryToggle);
  }

  useEffect(() => {
    if (categoryGroupMap && partCategoryHeaderRef.current) {
      partCategoryHeaderRef.current.click();
    }
  }, []);

  useEffect(() => {
    if (categorySuppliesMap && chemicalCategoryHeaderRef.current) {
      chemicalCategoryHeaderRef.current.click();
    }
  }, []);

  const renderAccordion = () => {
    return categoryGroupMap.map((singleItem, index) => {
      return (
        <Accordion className="accordion xlp-accordian">
          <Accordion.Toggle
            as={Card.Header}
            eventKey={singleItem.code}
            onClick={(e) => togglePartCategoryPanel(e, singleItem)}
            className={
              singleItem.active ? 'activeAccordionHeader' : null
            }
          >
            {singleItem.name}
            <span
              class={`${singleItem.active
                  ? 'filterSignMinus'
                  : 'filterSignPlus'
                }`}
            ></span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={singleItem.code}>
            <Card.Body>
              <ul>
                {singleItem.groups.map((group) => {
                  return (
                    createRecommendedJob ? (
                      <li>
                        <InputGroup>
                          <InputGroup.Checkbox
                            id={group.code}
                            name={group.code}
                            value={group.code}
                            onChange={(e) => handleRecommendedPartGroupSelection(e, singleItem, group, categoryGroupMap)}
                            checked={
                              !_isEmpty(selectedJobDetails) ?
                                (!!_find(selectedJobDetails, (g) => {
                                  return +g.groupId === +group.code && +g.categoryId === +singleItem.code
                                }
                                )) || group.checked
                                :
                                group.checked
                            }
                          />
                          <label for={group.code}>{group.name}</label>
                        </InputGroup>
                      </li>) : (
                      <li>
                        <InputGroup>
                          <InputGroup.Checkbox
                            id={group.code}
                            name={group.code}
                            value={group.code}
                            onChange={(e) => partGroupSelection(e, singleItem, group)}
                          />
                          <label for={group.code}>{group.name}</label>
                        </InputGroup>
                      </li>)
                  );
                })}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      );
    });
  };
  return (
    <Col lg="2" md="3" sm="3" xs="12" className="left-section">
      <Accordion className="accordion xlp-accordian">
        <Card>
          <Accordion.Toggle
            ref={el => partCategoryHeaderRef.current = el}
            onClick={(e) => togglePartCategory()} as={Card.Header}
            eventKey="3"
            className="filterCategoryAccordionHeader">
            Part Category<span
              class={`${partCategoryToggle ? 'filterSignMinus' : 'filterSignPlus'}`}></span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body><ul>{renderAccordion()}</ul></Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Accordion className="accordion xlp-accordian">
        <Card>
          <Accordion.Toggle
            ref={el => chemicalCategoryHeaderRef.current = el}
            onClick={(e) => toggleChemicalCategory()} as={Card.Header}
            eventKey="1"
            className="filterCategoryAccordionHeader">
            Chemical Category <span
              class={`${chemicalCategoryToggle ? 'filterSignMinus' : 'filterSignPlus'}`}></span>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <ul>
                {!_isEmpty(categorySuppliesMap) &&
                  categorySuppliesMap.map((c, k) => {

                    return (
                      <Accordion className="accordion xlp-accordian">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={c.code}
                          onClick={(e) =>
                            toggleChemicalCategoryPanel(e, c)
                          }
                          className={
                            c.active ? 'activeAccordionHeader' : null
                          }
                        >
                          {c.name}
                          <span
                            class={`${c.active
                                ? 'filterSignMinus'
                                : 'filterSignPlus'
                              }`}
                          />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={c.code}>
                          <Card.Body>
                            {(createRecommendedJob) ? (
                              <ul>
                                {!_isEmpty(c.groups) &&
                                  c.groups.map((g, kc) => {
                                    return (
                                      <li>
                                        <InputGroup>
                                          <InputGroup.Checkbox
                                            id={g.code}
                                            name={g.code}
                                            value={g.code}
                                            onChange={(e) => handleRecommendedChemicalSelection(e, c, g)}
                                            checked={
                                              !_isEmpty(selectedChemicalDetails) ?
                                                !!_find(selectedChemicalDetails, (j) => {
                                                  return +j.groupId === +g.id && +j.categoryId === +c.code
                                                })
                                                || g.checked
                                                :
                                                g.checked
                                            }
                                          />
                                          <label for={g.id}>{g.name}</label>
                                        </InputGroup>
                                      </li>
                                    );
                                  })}
                              </ul>) :
                              (<ul>
                                {!_isEmpty(c.groups) &&
                                  c.groups.map((g, kc) => {
                                    return (
                                      <li>
                                        <InputGroup>
                                          <InputGroup.Checkbox
                                            id={g.code}
                                            name={g.code}
                                            value={g.code}
                                            onChange={(e) => chemicalGroupSelection(e, c, g)}
                                          />
                                          <label for={g.id}>{g.name}</label>
                                        </InputGroup>
                                      </li>
                                    );
                                  })}
                              </ul>)}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                    );





                  })}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Col>
  );
}
export default JobFilterSection;
