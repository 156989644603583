import httpClient from '../../services/http';
import axios from 'axios';

export const getAllLineCodesService = (data) => httpClient.post(`/catalog/stockOrder/lineCode`, data);

export const getLineCodeService = (code) => httpClient.get(`/catalog/stockOrder/lineCode/${code}`);

export const getPartInvoiceDetails = (data) => httpClient.post(`order-service/invoices/partSearch`, data);

export const getStockOrderROPDetails = (data, urlName) => httpClient.post(`catalog/stockOrder/rop/` + urlName, data);

export const stockOrderPartDetailsService = ({ payload, pageNumber, pageSize }) => {
	const { sortBy } = payload;
	delete payload.sortBy;

	return httpClient.post(`/orchestrator-service/stockOrder/stockPartDetails?sortBy=${sortBy}`, payload);
};

export const getCategoriesService = () => httpClient.get(`/catalog/stockOrder/group`);

export const getPartImagesService = (payload) =>
  httpClient.post(`/catalog/stockOrder/partImages`, payload);

let cancelToken;
export const getPartNumbersService = ({ searchTerm, data, options }) => {
	if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel('Operation is canceled due to new search request');
	}

	cancelToken = axios.CancelToken.source();

	return httpClient.post(`/orchestrator-service/stockOrder/partNum/${searchTerm}`, data, {
		cancelToken: cancelToken.token
	});
};

export const stockOrderUploadService = (data) =>
	httpClient.post(`/orchestrator-service/stockOrder/stock/upload`, data.formData);

export const stockOrderPartAvailabilityService = (data) =>
	httpClient.post(`/catalog/stockOrder/store/availability`, data);

export const stockOrderEmailROPToMeService = (data) => {
	delete data.sortBy;
	return httpClient.post(`/orchestrator-service/stockOrder/mail/pdf`, data);
};

export const stockOrderPreviewROPService = (data) => {
	delete data.sortBy;
	return httpClient.post(`/orchestrator-service/stockOrder/get/pdf`, data);
};

export const stockOrderHelpDocumentsService = () => httpClient.get(`/utils-service/stockOrder/help`);

export const osrVisitFreequencyServiceGet = (data) => httpClient.post(`/catalog/stockOrder/osr/schedule/get`, data);

export const osrVisitFreequencyServicePost = (data) => httpClient.post(`/catalog/stockOrder/osr/schedule/save`, data);
