import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import "./styles.scss";
import { Row, Col, } from "react-bootstrap";
import _ from "lodash";
import { setUserDefaultKey } from '../../User/UserManagement/usermanagement.slice';

const UserManagementTab = (props) => {
  const dispatch = useDispatch();
  const userDetailsArr = useSelector((state) => state.user);
  const smsUser = useSelector((state) => state.app.smsUser);
  const history = useHistory();


  const handleUserManagement = (profile) => {
    dispatch(setUserDefaultKey(profile));
    history.push("/user-management");
    props.handleCloseOverlay();
  };
  return (
    !_.isEmpty(userDetailsArr) && !_.isEmpty(userDetailsArr.userDetails) && (
      <Fragment>
        <div className="usermanagement_menu">
          <div className="mygarage__content">
            <div className="mygarage__account__textLinks1  mygarage__account">
              <Col md="3">
                <a
                  className="text_links1 statement__link_account1"
                  onClick={(e) => handleUserManagement("userProfile")}
                >
                  User Profile
                </a>{" "}
              </Col>
            </div>
          </div>
          {!_.isEmpty(userDetailsArr.userDetails.userType) &&
              (userDetailsArr.userDetails.userType === "IT_ADMIN" ||
              userDetailsArr.userDetails.userType === "CUSTOMER_ADMIN" ||
              userDetailsArr.userDetails.userType === "OSR" ||
              userDetailsArr.userDetails.userType == "DSM" ||
              userDetailsArr.userDetails.userType == "SUPER_ADMIN") && (
          <div className="mygarage__content">
            <div className="mygarage__account__textLinks1  mygarage__account">
              <Col md="3">
                <a
                  className="text_links1 statement__link_account1"
                  onClick={(e) => handleUserManagement("manageUsers")}
                >
                  Manage Users
                </a>
              </Col>
            </div>
          </div>)}
        </div>
        <div className="mygarage__content">
          <div className="mygarage__account">
            <Col md="3">
              <div>User Details</div>
            </Col>
          </div>
        </div>
        {!smsUser && (
          <div className="mygarage__content">
            <div className="account__data">
              <Row>
                <Col md="4">
                  <div>
                    <span className="user_management_label_color_account">
                      First Name:
                    </span>
                    <span className="account_billToDetails user_management_label_color_span">

                      {userDetailsArr.userDetails.firstName}
                    </span>
                  </div>

                </Col>{" "}
                <Col md="4">
                  <div>
                    <span className="user_management_label_color_account">
                      Last Name:
                    </span>{" "}
                    <span className="account_billToDetails user_management_label_color_span">{userDetailsArr.userDetails.lastName}</span>
                  </div>

                </Col>
                <Col md="4">
                  <div>
                    <span className="user_management_label_color_account">
                      Mobile Number:
                    </span>{" "}
                    <span className="account_billToDetails user_management_label_color_span">{userDetailsArr.userDetails.mobileNumber}</span>
                  </div>

                </Col>{" "}
                <Col md="4">
                  <div>
                    <span className="user_management_label_color_account">
                      User Type:
                    </span>{" "}

                    <span className="account_billToDetails user_management_label_color_span"> {userDetailsArr.userDetails.userType}</span>
                  </div>
                </Col>
                <Col md="4">
                  <div>
                    <span className="user_management_label_color_account">
                      Email:
                    </span>{" "}
                    <span className="account_billToDetails user_management_label_color_span">{userDetailsArr.userDetails.x2userName}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>)}
        <div className="mygarage__content">
          <div className="mygarage__account">
            <Col md="7">
              <div>ACCOUNT PROFILE</div>
            </Col>
          </div>
        </div>
        <div className="mygarage__content">
          <div className="account__data">
            <Row>
              <Col md="4">
                <div>
                  <span className="user_management_label_color_account">
                    TPH Account {" "}
                  </span>

                  <span className="account_billToDetails user_management_label_color_span">
                    {userDetailsArr.userDetails &&
                      userDetailsArr.userDetails.billTo
                      ? `${userDetailsArr.userDetails.billTo.billToId} - ${userDetailsArr.userDetails.billTo.name}`
                      : ``}
                  </span>
                </div>
              </Col>
              <Col md="4">
                {!_.isEmpty(userDetailsArr.userDetails.shipTos) && !_.isEmpty(userDetailsArr.userDetails.shipTos[0]) && (
                  <div>
                    <span className="user_management_label_color_account">
                      ShipTo{" "}
                    </span>

                    <span className="account_billToDetails user_management_label_color_span">
                      {`${userDetailsArr.userDetails.shipTos[0].shipToId} - ${userDetailsArr.userDetails.shipTos[0].name}`}

                    </span>
                  </div>)}
              </Col>
              <Col md="4">
                {!_.isEmpty(userDetailsArr.userDetails.shipTos) && !_.isEmpty(userDetailsArr.userDetails.shipTos[0]) && (
                  <div>
                    <span className="user_management_label_color_account">
                      ShipTo Address:{" "}
                    </span>
                    <span className="account_billToDetails user_management_label_color_span">
                      <span>
                        {`${userDetailsArr.userDetails.shipTos[0].address}, ${userDetailsArr.userDetails.shipTos[0].city} ,
                    ${userDetailsArr.userDetails.shipTos[0].state} ,${userDetailsArr.userDetails.shipTos[0].zip}`}
                      </span>
                    </span>
                  </div>)}
              </Col>{" "}
              <Col md="4">
                {!_.isEmpty(userDetailsArr.userDetails.shipTos) && !_.isEmpty(userDetailsArr.userDetails.shipTos[0]) && (
                  <div>
                    <span className="user_management_label_color_account">
                      TPH Store{" "}
                    </span>

                    <span className="account_billToDetails user_management_label_color_span">
                      {userDetailsArr.userDetails.shipTos[0].location &&
                        userDetailsArr.userDetails.shipTos[0].location
                          .storeNumber}
                    </span>
                  </div>)}
              </Col>{" "}
              <Col md="4">
                {!_.isEmpty(userDetailsArr.userDetails.shipTos) && !_.isEmpty(userDetailsArr.userDetails.shipTos[0]) && (
                  <div>
                    <span className="user_management_label_color_account">
                      Store Phone #{" "}
                    </span>

                    <span className="account_billToDetails user_management_label_color_span">
                      {userDetailsArr.userDetails.shipTos[0].location &&
                        userDetailsArr.userDetails.shipTos[0].location
                          .phoneNumber}
                    </span>
                  </div>)}
              </Col>{" "}
              <Col md="4">
                {!_.isEmpty(userDetailsArr.userDetails.shipTos) && !_.isEmpty(userDetailsArr.userDetails.shipTos[0]) && (
                  <div>
                    <span className="user_management_label_color_account">
                      Store Address{" "}
                    </span>

                    <span className="account_billToDetails user_management_label_color_span">
                      {userDetailsArr.userDetails.shipTos[0].location &&
                        userDetailsArr.userDetails.shipTos[0].location
                          .address},
                      {userDetailsArr.userDetails.shipTos[0].location &&
                        userDetailsArr.userDetails.shipTos[0].location
                          .city},
                      {userDetailsArr.userDetails.shipTos[0].location &&
                        userDetailsArr.userDetails.shipTos[0].location
                          .state},
                      {userDetailsArr.userDetails.shipTos[0].location &&
                        userDetailsArr.userDetails.shipTos[0].location
                          .zip}
                    </span>

                  </div>)}
              </Col>{" "}
              <Col md="4">
                {!_.isEmpty(userDetailsArr.userDetails.shipTos) && !_.isEmpty(userDetailsArr.userDetails.shipTos[0]) && (
                  <div>
                    <span className="user_management_label_color_account">
                      Ship Method{" "}
                    </span>

                    <span className="account_billToDetails user_management_label_color_span">
                      {userDetailsArr.userDetails.shipTos[0].shipMethod}
                    </span>
                  </div>)}
              </Col>{" "}
              <Col md="4">
                {!_.isEmpty(userDetailsArr.userDetails.shipTos) && !_.isEmpty(userDetailsArr.userDetails.shipTos[0]) && (
                  <div>
                    <span className="user_management_label_color_account">
                      SLA%{" "}
                    </span>

                    <span className="account_billToDetails user_management_label_color_span">
                      {userDetailsArr.userDetails.shipTos[0]
                        .ddsInStockSLA === ""}
                      {userDetailsArr.userDetails.shipTos[0].ddsInStockSLA
                        ? userDetailsArr.userDetails.shipTos[0]
                          .ddsInStockSLA
                        : "N/A"}
                    </span>
                  </div>)}
              </Col>{" "}
            </Row>
          </div>
        </div>

      </Fragment>)
  );
};

export default UserManagementTab;