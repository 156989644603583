import React from "react";

import "./styles.scss";

const ItemsRender = (props) => {
  const { items, direction } = props;

  return (
    <div
      className={`items-render-wrapper ${
        direction === "col" ? "direction-col" : ""
      }`}
    >
      {items &&
        items.map((item, i) => {
          return (
            <div className="item-render" key={`item-render-${i}`}>
              <div className="item-render-title">{item.label}</div>
              <div className="item-render-value">{item.value}</div>
            </div>
          );
        })}
    </div>
  );
};

export default ItemsRender;
