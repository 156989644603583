/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import { benefitDetails } from "./../programbenefits.thunk";
import { formatAmountPrograms } from "../../../helpers/utils";
import _isEmpty from "lodash/isEmpty";
import XCharts from "components/Charts";
import { currencyFormatter } from "../../../helpers/utils";

const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const headerStyle = {
  backgroundColor: "#0f5283",
  color: "#FFFFFF",
};

const PSNProgram = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;
  const {
    loading = false,
    mtapsn,
    mtaPsnYearlyGraphData,
    mtaCategorySalesGraphData,
  } = useSelector((state) => state.ProgramAndBenefits);
  const [mtaItems, setMtaItems] = useState({});

  useEffect(() => {
    if (!isEmpty(mtapsn)) {
      constructGridData(mtapsn);
      const _mtaItems = mtapsn.mtaItems;
      if (_mtaItems && _mtaItems.length > 0) {
        setMtaItems(_mtaItems[0]);
      }
    }
    dispatch({
      payload: {
        globalLoader: loading,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
  }, [mtapsn, loading]);

  const getAllDetails = () => {
    const { billTo } = userDetails || {};
    let customerNumber = billTo.customerCode;
    const payload = {
      name: "mtapsn",
      customerId: customerNumber, //customerNumber,
      noOfMonths: 2,
    };
    dispatch(benefitDetails(payload))
      .then((action) => {
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      })
      .catch((error) => {
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      });
  };

  useEffect(() => {
    getAllDetails();
  }, []);

  const [tableColumns, setTableColumns] = useState([]);
  const [TableData, setTableData] = useState([]);
  const [tableLoyaltyColumns, setTableLoyaltyColumns] = useState([]);
  const [TableLoyaltyData, setTableLoyaltyData] = useState([]);

  const constructGridData = ({ mtaPsnResponses, mtaItems }) => {
    setTableColumns([]);
    setTableData([]);
    setTableLoyaltyColumns([]);
    setTableLoyaltyData([]);

    const rowData = [];
    if (!_isEmpty(mtaPsnResponses) && mtaPsnResponses.length > 0) {
      mtaPsnResponses.forEach((availDetails, index) => {
        rowData.push({
          quarter: availDetails.quarter,
          sales: "$" + formatAmountPrograms(availDetails.sales),
          mtaSales: "$" + formatAmountPrograms(availDetails.mtaSales),
          lastYrSameQuarterSales:
            "$" + formatAmountPrograms(availDetails.lastYrSameQuarterSales),
          lastYrSameQuarterMTASales:
            "$" + formatAmountPrograms(availDetails.lastYrSameQuarterMTASales),
          index: index,
        });
      });
    }
    const rowLoyalData = [];
    if (!_isEmpty(mtaItems) && mtaItems.length > 0) {
      mtaItems.forEach((availDetails, index) => {
        rowLoyalData.push({
          loyaltyId: availDetails.loyaltyId,
          purchased$7000: availDetails.purchased$7000Plus,
          purchased$10000: availDetails.purchased$10000Plus,
          currentGrowthEarnback: availDetails.currentGrowthEarnback,
          memberLevel: availDetails.memberLevel,
          totalEarnings: availDetails.totalEarnings,
          claimed: availDetails.claimed,
          loyatyEarnings: availDetails.loyatyEarnings,
          growthEarnings: availDetails.growthEarnings,
          powerStrokePrideEarnings: availDetails.powerStrokePrideEarnings,
          rebateEarnings: availDetails.rebateEarnings,
          index: index,
        });
      });
    }
    const columns = [
      {
        dataField: "quarter",
        text: "Quarter",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
      {
        dataField: "sales",
        text: "QTD Sales",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
        formatter: function (cell, row) {
          return (
            <div className="">
              <span className="maroonColorTxt">
                {currencyFormatter(row.sales)}
              </span>
            </div>
          );
        },
      },
      {
        dataField: "lastYrSameQuarterSales",
        text: "LY Same QTR Sales",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row) {
          return (
            <div className="">
              <span className="maroonColorTxt">
                {currencyFormatter(row.lastYrSameQuarterSales)}
              </span>
            </div>
          );
        },
      },
      {
        dataField: "mtaSales",
        text: "MTA QTD Sales",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row) {
          return (
            <div className="">
              <span className="maroonColorTxt">
                {currencyFormatter(row.mtaSales)}
              </span>
            </div>
          );
        },
      },
      {
        dataField: "lastYrSameQuarterMTASales",
        text: "MTA LY QTR Sales",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row) {
          return (
            <div className="">
              <span className="maroonColorTxt">
                {currencyFormatter(row.lastYrSameQuarterMTASales)}
              </span>
            </div>
          );
        },
      },
    ];
    const columnsLoyalty = [
      {
        dataField: "loyaltyId",
        text: "Loyalty Id",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
      {
        dataField: "purchased$7000",
        text: "Purchased $7000+?",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },

      {
        dataField: "purchased$10000",
        text: "Purchased $10000+?",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "currentGrowthEarnback",
        text: "Current Growth Earnback%",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "memberLevel",
        text: "Member Level",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "totalEarnings",
        text: "Total Earnings",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row) {
          return (
            <div className="">
              <span className="maroonColorTxt">
                {currencyFormatter(row.totalEarnings)}
              </span>
            </div>
          );
        },
      },
      {
        dataField: "claimed",
        text: "Claimed?Y/N",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "loyatyEarnings",
        text: "Loyaty Earnings",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row) {
          return (
            <div className="">
              <span className="maroonColorTxt">
                {currencyFormatter(row.loyatyEarnings)}
              </span>
            </div>
          );
        },
      },
      {
        dataField: "growthEarnings",
        text: "Growth Earnings",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row) {
          return (
            <div className="">
              <span className="maroonColorTxt">
                {currencyFormatter(row.growthEarnings)}
              </span>
            </div>
          );
        },
      },
      {
        dataField: "powerStrokePrideEarnings",
        text: "Power Stroke Pride Earnings",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row) {
          return (
            <div className="">
              <span className="maroonColorTxt">
                {currencyFormatter(row.powerStrokePrideEarnings)}
              </span>
            </div>
          );
        },
      },
      {
        dataField: "rebateEarnings",
        text: "Rebate Earnings",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row) {
          return (
            <div className="">
              <span className="maroonColorTxt">
                {currencyFormatter(row.rebateEarnings)}
              </span>
            </div>
          );
        },
      },
    ];
    setTableColumns(columns);
    setTableData(rowData);
    setTableLoyaltyColumns(columnsLoyalty);
    setTableLoyaltyData(rowLoyalData);
  };
  const renderNoDataState = () => {
    let headers = Object.values(tableColumns);
    return (
      <table className="table table-bordered alternate">
        <thead>
          <tr>
            {headers.map((header) => {
              return <th>{header.text}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {isEmpty(TableData) && TableData.length === 0 && (
            <tr>
              <td align="center" colspan="7">
                No Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };
  const renderNoDataLoyalty = () => {
    let headers = Object.values(tableLoyaltyColumns);
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            {headers.map((header) => {
              return <th>{header.text}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {isEmpty(TableLoyaltyData) && TableLoyaltyData.length === 0 && (
            <tr>
              <td align="center" colspan="12">
                No Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const getDataKeys = () => {
    const years = _.uniq(_.map(mtapsn?.mtaYearlyResponses, (a) => a.monthYear));

    const colors = ["#36AD3F", "#D71958", "#1604d9"];
    const keys = years.map((y, i) => {
      return { value: y, color: colors[i] };
    });

    return keys;
  };

  return (
    <div className="mtapsn-container">
      <div className="psn-report-wrapper">
        <div className="card-wrappers report-card-wrapper">
          <div className="reports-wrapper">
            <div className="report-card">
              <div className="rcard-title">Claimed</div>
              <div className="rcard-content">
                <div className="bolder theme-color same-font-size">
                  {mtaItems.claimed === "Y" ? "Yes" : "No"}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="report-card">
              <div className="rcard-title">Total Sales for last 3 years</div>
              <div className="rcard-content">
                {!_isEmpty(mtaPsnYearlyGraphData) ? (
                  <XCharts
                    chartType="line"
                    data={mtaPsnYearlyGraphData || []}
                    dataKeys={getDataKeys()}
                  />
                ) : (
                  <div className="cd-currency">-</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mta-psn-xlcharts">
          <div className="report-card">
            <div className="rcard-title">YTD Sales by Category</div>
            <div className="rcard-content">
              {!_isEmpty(mtaCategorySalesGraphData) ? (
                <XCharts
                  chartType="nivoBarChart"
                  data={mtaCategorySalesGraphData || []}
                  dataKeys={["sales"]}
                  indexBy="categories"
                  axisBottom="Categories"
                  // trimXAxis={{ labelLength: 20 }}
                  nivoBarPadding={0.7}
                />
              ) : (
                <div className="cd-currency">-</div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Row className="monthly-sales-view-container">
        <React.Fragment>
          {TableData.length > 0 && tableColumns.length > 0 ? (
            <Col sm={12}>
              <Row className="table-row">
                <Col>
                  <div className="right-align-table-dollar">
                    <BootstrapTable
                      keyField="specDesc"
                      data={TableData}
                      columns={tableColumns}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col>{renderNoDataState()}</Col>
          )}
        </React.Fragment>
      </Row>
      <Row
        className="monthly-sales-view-container"
        style={{ marginBottom: "25px" }}
      >
        <React.Fragment>
          {TableLoyaltyData.length > 0 && tableLoyaltyColumns.length > 0 ? (
            <Col sm={12}>
              <Row className="table-row table-condensed">
                <Col>
                  <BootstrapTable
                    bootstrap4
                    keyField="specDesc"
                    data={TableLoyaltyData}
                    columns={tableLoyaltyColumns}
                    className="alternate"
                  />
                </Col>
              </Row>
            </Col>
          ) : (
            <Col>{renderNoDataLoyalty()}</Col>
          )}
        </React.Fragment>
      </Row>
    </div>
  );
};

export default PSNProgram;
