import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import PartSelect from "./PartSelect";
import _isEmpty from "lodash/isEmpty";
import {
  getAllYears,
} from '../../../actions/search';
import {
  getPartCategories
} from "./simplehomepage.thunks";
import './styles.scss';
import { Typography } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Checkout from "../../Checkout/index";
import PartCatalogHeader from "./PartCatalogHeader";
import QuickCart from "./QuickCart";
import {
  getUserShipInfo
} from '../../../helpers/utils';
import {
  setShippingInfo,
  setExpressCheckoutClicked
} from "./simplehomepage.slice";
import AddToCartWarningPopup from "../../shared/AddToCartWarningPopup/AddToCartWarningPopup";
import ThankyouPopup from "../../Checkout/ThankyouPopup";
import RefreshDataPopup from "../../Checkout/RefreshDataPopup";
import XModal from "../../shared/XModal";
import FilterSection from '../../Listing/FilterSection';

function SimpleHomePage(props) {
  const dispatch = useDispatch();
  const search = useSelector((state) => state.search);
  const stores =  useSelector((state) => state.mygarage.storeDetails);
  const userDetails = useSelector((state) => state.user.userDetails);
  const selectedAddCartData = useSelector((state) => state.parts.selectedAddCartData);
  const checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  const isCartIsDifferent = useSelector((state) => state.parts.addToCartWarning);
  const [addtocartwarning, setAddtocartwarning] = useState(false);
  const [successPopUp, setSuccessPopup] = useState(false);
  const [cartDistributionWaringPopup, setCartDistributionWaringPopup] = useState(false);
  const yearList = search.yearListAll;
  const [partCategories, setPartCategories] = useState([]);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const express = useSelector((state) => state.expressCart);
  const showLoader = useSelector((state) => state.expressCart.showLoader);
  const expressHome = useSelector((state) => state.expressCart.redirectToExpressHome);
  const cartOrderPlaced = useSelector((state) =>  state.cart.cartOrderPlaced);
  const orderConfirmationNumber = useSelector((state) => state.cart.orderConfirmationNumber);
  const parts = useSelector((state) => state.parts);
  let partsApiCall = parts.partsApiCall;
  let partsAllDetails = parts.partsAllDetails;
  let brandsList = parts.partsAllBrands;
  let positionList = parts.partsAllPosition;
  let partsAllAvailability = parts.partsAllAvailability;

  const [shipInfo, setShipInfo] = useState({
    "sellerPartnerId": checkoutDetails.length > 0 ? checkoutDetails[0].sellPartnerId : getUserShipInfo(userDetails).sellerPartnerId,
    "storeNumber": checkoutDetails.length > 0 ? checkoutDetails[0].store : getUserShipInfo(userDetails).storeNumber,
    "shipMethod": checkoutDetails.length > 0 ? checkoutDetails[0].shipMethod : getUserShipInfo(userDetails).shipMethod
  });
  const partCatalogTab = 0;
  const quickCartTab = 1;

  useEffect(() => {
    if (_isEmpty(yearList)) {
      getAllYears(dispatch);
    }
    // dispatch(setShippingInfo({
    //   shippingInfo : {
    //     sellerPartnerId: checkoutDetails.length > 0 ? checkoutDetails[0].sellPartnerId : getUserShipInfo(userDetails).sellerPartnerId,
    //     storeNumber: checkoutDetails.length > 0 ? checkoutDetails[0].store : getUserShipInfo(userDetails).storeNumber,
    //     shipMethod: checkoutDetails.length > 0 ? checkoutDetails[0].shipMethod : getUserShipInfo(userDetails).shipMethod
    //   }
    // }))
  }, []);

  useEffect(() => {
    dispatch(setShippingInfo({
      shippingInfo: {
        sellerPartnerId: checkoutDetails.length > 0 ? checkoutDetails[0].sellPartnerId : getUserShipInfo(userDetails).sellerPartnerId,
        storeNumber: checkoutDetails.length > 0 ? checkoutDetails[0].store : getUserShipInfo(userDetails).storeNumber,
        shipMethod: checkoutDetails.length > 0 ? checkoutDetails[0].shipMethod : getUserShipInfo(userDetails).shipMethod
      }
    }))
  }, [checkoutDetails]);

  useEffect(() => {
      setAddtocartwarning(isCartIsDifferent);
  }, [isCartIsDifferent]);

  useEffect(() => {
    setSuccessPopup(cartOrderPlaced);
  }, [cartOrderPlaced]);


  useEffect(() => {
    if (expressHome) {
      setSelectedTab(partCatalogTab);
    }
  }, [expressHome]);

  const handleTabChange = (event, newValue) => {
    dispatch(
      setExpressCheckoutClicked({
        isExpressCheckoutClicked: quickCartTab == newValue,
      })
    );
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const payload = {};
    if (_isEmpty(partCategories)) {
      dispatch(getPartCategories(payload)).then((result) => {
        const partCtgories = result.payload.map((partCategory) => {
          const nodeData = {
            id: "partCategory",
            partCategoryCode: partCategory.code,
            categoryName: partCategory.name,
          };
          return {
            id: JSON.stringify(nodeData),
            name: partCategory.name,
          };
        });
        setPartCategories(partCtgories);
      });
    }
  }, []);

  const redirectToCart = (bool) => {
    if (bool) {
      setSelectedTab(quickCartTab);
    }
  };
  const getShipInfo = (sellerPartnerId, storeNumber, shipMethod) => {
    setShipInfo({
      sellerPartnerId: sellerPartnerId,
      storeNumber: storeNumber,
      shipMethod: shipMethod,
    });
    console.log(sellerPartnerId, storeNumber, shipMethod);
  };

  return (
    <Container fluid className="express-body-container pl-5 pr-5">
      <div className="express-filter">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="icon position tabs example"
          TabIndicatorProps={{
            style: {
              height: "6px",
            },
          }}
        >
          <Tab
            style={{ minHeight: "auto", padding: "10px 10px 10px" }}
            label={
              <span>
                <b>Express Catalog</b>
              </span>
            }
          />
          <Tab
            style={{ minHeight: "auto", padding: "10px 10px 10px" }}
            icon={<ShoppingCartIcon />}
            iconPosition="end"
            label={
              <span>
                <b>Express Checkout</b>
              </span>
            }
          />
        </Tabs>
        <div
          className={
            selectedTab === partCatalogTab ? "active-tab ml-5" : "hidden"
          }
        >
          <PartCatalogHeader
            storeInfo={stores}
            userDetails={userDetails}
            checkoutDetails={checkoutDetails}
            getShipInfo={getShipInfo}
          />
        </div>
      </div>
      <React.Fragment>
        <div
          className={selectedTab === partCatalogTab ? "active-tab" : "hidden"}
        >
          {/* <PartCatalogHeader
            storeInfo={stores}
            userDetails={userDetails}
            checkoutDetails={checkoutDetails}
            getShipInfo={getShipInfo}
          /> */}
          <Row>
            {(!_isEmpty(partsAllAvailability) ||
              !_isEmpty(positionList) ||
              !_isEmpty(brandsList)) && (
              <Col lg={2}>
                <FilterSection
                  shipMethod={shipInfo.shipMethod}
                  selectedStore={shipInfo.storeNumber}
                  sellPartnerId={shipInfo.sellerPartnerId}
                  isExpress={true}
                />
              </Col>
            )}
            <Col lg={!_isEmpty(partsAllDetails) ? 7 : 9}>
              <div
                id="partsview-container"
                className="part-selection box-container"
              >
                {/* <Typography align="center" variant="h6" component="div">
                Express Catalog
                </Typography> */}
                {partCategories &&
                  yearList &&
                  yearList.map((year) => {
                    const nodeData = {
                      id: "year",
                      yearCode: `${year.code}`,
                    };
                    return (
                      <React.Fragment>
                        <PartSelect
                          id={JSON.stringify(nodeData)}
                          name={year.name}
                          categories={partCategories}
                          shipInfo={shipInfo}
                        />
                      </React.Fragment>
                    );
                  })}
              </div>
            </Col>
            <Col lg={3}>
              <div className="quick-cart box-container">
                <Typography align="center" variant="h6" component="div">
                  Express Cart
                </Typography>
                <QuickCart redirectToCart={redirectToCart} />
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
      {selectedTab === quickCartTab && (
        <React.Fragment>
          <Checkout isExpressCheckoutTab={true} />
        </React.Fragment>
      )}
      {addtocartwarning ? (
        <AddToCartWarningPopup
          openPopup={true}
          selectedAvailabilityHours={selectedAddCartData}
          source={"partCategory"}
          orderType="REGULAR"
          DefaultAddItemToCart={false}
          closePopup={() => {
            setAddtocartwarning(false);
          }}
          fromPage={"expresswarningpopup"}
        />
      ) : null}
      <ThankyouPopup
        openPopup={successPopUp}
        orderConfirmationNumber={orderConfirmationNumber}
        closePopup={() => {
          setSuccessPopup(false);
          dispatch({
            payload: {
              orderPlaced: false,
              orderConfirmationNumber: "",
            },
            type: "CART_ORDER_PLACED",
          });
        }}
      />
      {(showLoader || partsApiCall) && (
        <div className="custom_spinner_container catalog_spinner">
          <Spinner className="custom_spinner" animation="border" />
        </div>
      )}
    </Container>
  );
}

SimpleHomePage.propTypes = {};

export default SimpleHomePage;
