import React, { useState, useEffect } from "react";
import { currencyFormatter } from "../../../helpers/utils";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import "./styles.scss";

const BuyNinePayEight = () => {
  const [tableData, setTableData] = useState([]);
  const headerStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
  };
  const data = useSelector((state) => state.ProgramAndBenefits);
  const benefitData = data && data.benefitData;
  const buy9pay8Data = (benefitData && benefitData.batteryBuy9Pay8s) || []

  useEffect(() => {
    if (!_isEmpty(buy9pay8Data)) {
      const buy9Pay8RowData = [];
      buy9pay8Data.forEach((item, index) => {
        buy9Pay8RowData.push({
          part_b9p8: item.partNumber,
          date_b9p8: item.invoiceDate,
          value_b9p8: item.value,
          cm_b9p8: item.cm,
          invUrl_b9p8: item.invUrl,
          index: index,
        });
      });
      setTableData(buy9Pay8RowData);
    }
  }, [buy9pay8Data]);

  const buy9Pay8Column = [
    {
      dataField: "part_b9p8",
      text: "Part#",
      headerStyle: headerStyle,
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "date_b9p8",
      text: "Date",
      headerStyle: headerStyle,
      classes: "buy9pay8_and_bonaza_date_row_width",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row, index) => {
        let unformattedDate = row.date_b9p8.split("-");
        return (
          unformattedDate[1] +
          "/" +
          unformattedDate[2] +
          "/" +
          unformattedDate[0]
        );
      },
    },
    {
      dataField: "value_b9p8",
      text: "Value",
      headerStyle: headerStyle,
      align: "center",
      classes:
        "buy9pay8_and_bonanza_rightalign buy9pay8_and_bonaza_value_row_width",
      headerAlign: "center",
      formatter: (cell) => {
        return `${currencyFormatter(cell)}`;
      },
    },
    {
      dataField: "cm_b9p8",
      text: "Credit Memo",
      headerStyle: headerStyle,
      align: "center",
      headerAlign: "center",
      formatter: (cell, row, index) => {
        return (
          <a
            href={row.invUrl_b9p8}
            style={{ textDecoration: "underline" }}
            target="_blank"
            rel="noreferrer"
          >
            {row.cm_b9p8}
          </a>
        );
      },
    },
  ];

  const noDataState = () => {
    return (
      <p align="center" style={{ padding: "0", margin: "0" }}>
        No Data Found
      </p>
    );
  };

  return (
    <>
      <BootstrapTable
        keyField="part_b9p8"
        classes="buy9pay8-and-bonanza-table-header"
        data={tableData}
        columns={buy9Pay8Column}
        noDataIndication={noDataState}
      />
    </>
  );
};

export default BuyNinePayEight;
