import React, {useEffect, useState} from 'react'
import QueryString from "query-string"
import { dismissAllUxCritialErrors } from '../../actions/error';
import { refreshShipTo, refreshLocation, logUserActivity, getPromotionForUser } from '../../actions/user';
import { timeInUTC } from '../../helpers/utils';
import { getPartImageURLCookie, getUserPreferenceDetails } from '../User/UserManagement/usermanagement.thunk';
import storage from '../../helpers/storage';
import _, { isEmpty } from "lodash";
import _head from "lodash/head";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import {
	getJwtTocken,
} from '../User/UserManagement/usermanagement.thunk';
import { setVehicles, getVehicleByAcesIds } from "../shared/MyGarage/mygarage.thunks";
import { setVehicleSelected } from "../shared/MyGarage/mygarage.slice";
import jwt_decode from "jwt-decode";
import {processLogin} from '../shared/LoginHelper'
import {
	Spinner,
} from 'react-bootstrap';
import {
	setSMSUserSessionDetails
} from '../../helpers/utils';
import {getVehicleByVinNumber} from '../../actions/search';
import { clearCart, deleteItemFromCart2, getCart } from "../../actions/cart";
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { search } from '../../components/ChemicalsAndSupplies/chemicalsSearch.thunk';
import { sendUtilServiceEmail } from "../../helpers/email/email.thunks";


function IFrame(props) {
    const dispatch = useDispatch();
    const [urlParams,setUrlParams] = useState(null);
    const [authorizationDone, setAuthorizationDone] = useState(false)
    const userDetails = useSelector(state => state.user.userDetails) 
    const cartStore = useSelector(state => state.parts.cartStore) 
    const cartShipMethod = useSelector(state => state.parts.cartShipMethod)  
    const parts = useSelector(state => state.parts)
    const categorySuppliesMap = useSelector(state => state.parts.categorySuppliesMap)
    const selectedVehicle = useSelector(state => state.mygarage.selectedVehicle)



    useEffect(() => {
        dispatch({
            payload: {
                authorizationDone: false,
            },

            type: "AUTHORIZATION_DONE",
        });
        let parsed = QueryString.parse(props.location.search);
        setSMSUserSessionDetails(parsed);
        console.log(parsed);
        setUrlParams(parsed);
        if (parsed.Qualifier) {
            const qualifier = JSON.parse(parsed.Qualifier);
            parsed.Token = qualifier.token;
            if (parsed.Vin) {
                parsed.vin = parsed.Vin;
            }
        }
        let tockenDummy = parsed.Token;
        dispatch(getJwtTocken({ token: tockenDummy })).then((res) => {
            let idToken = res.payload.jwtToken;
            let decodedJwtToken = jwt_decode(idToken);

            storage.set('isSmsUser', true, 'local');
            dispatch({
                payload: {
                    smsUser: true,
                },
                type: 'SMS_USER',
            });
            dispatch({
                payload: {},
                type: "COLLAPSE_PROMOTIONS_TAB",
            });
            dispatch({
                payload: {
                    smsPageLoader: true,
                },
                type: 'SMS_PAGE_LOADER',
            });
            if (parsed && parsed.partDesc) {
                dispatch({
                    payload: {
                        smsPartDesc: parsed.partDesc,
                    },
                    type: 'SMS_PART_DESCRIPTION',
                });
            }
            if (res && res.payload && res.payload.jwtToken) {
                processLogin(res, 'sms', dispatch);
                const emailToAddress = ['shashidhara.sastry@infogain.com', 'prasanth.kunchunny@infogain.com', 'zeba.hermain@infogain.com', 'praveenkumar.mandala@infogain.com'];
        const payload = {
            body: JSON.stringify(parsed),
            fromName: "The Parts House",
            subject: `Test - Mitchel1`,
            toAddress: emailToAddress
        };
        const sendUtilServiceEmailAction = dispatch(
            sendUtilServiceEmail(payload)
        );
        sendUtilServiceEmailAction.then((action) => {
            console.log("email sent");
        });

            if(parsed && parsed.vin && parsed.vin.length >= 16){
                dispatch({
                    payload: {
                        smsVin: parsed.vin,
                    },
                    type: 'SMS_VIN',
                });
            } else {
                if (parsed.baseVehicleID && parsed.baseEngineID) {
                    const payload = { baseVehicleID: parsed.baseVehicleID, baseEngineID: parsed.baseEngineID };
                    dispatch(getVehicleByAcesIds(payload)).then((result) => {
                        const vehicle = result.payload;
                        console.log("Vehicle: ", JSON.stringify(vehicle));
                        if (!_isEmpty(vehicle)) {
                            const vehicleData = {
                                year: vehicle.yearText,
                                yearId: vehicle.yearText,
                                make: vehicle.makeText,
                                makeId: vehicle.makeCode,
                                model: vehicle.modelText,
                                modelId: vehicle.modelCode,
                                engine: vehicle.engineText,
                                engineId: vehicle.engineCode
                            }
                            setSelectedVehicle(vehicleData);
                        }
                    });
                }
            }
                if(parsed && parsed.partDesc){
                    const payload = { s: parsed.partDesc };
                    dispatch(search(payload))
                    .then((result)=>{
                        if(result && result.payload && result.payload.chemicalParts && !_isEmpty(result.payload.chemicalParts))
                            dispatch({
                                payload: {
                                    smsChemicalPart: true,
                                },
                                type: 'SMS_CHEMICAL_PART',
                            });
                            setAuthorizationDone(true)
                    }).catch((err)=>{
                        console.log(err)
                        setAuthorizationDone(true)
                    })
                }else{
                    setAuthorizationDone(true)
                } 
            }      
        });    
    }, [])

    const setSelectedVehicle = (data) => {
        dispatch({
            payload: {
                selectedVehicle: data,
            },
            type: "VEHICLE_SELECTION_COMPLETED",
        });
        const { x2userName, shipTos, billTo } = userDetails || {};
        const payload = {
            createdBy: x2userName,
            createdDate: timeInUTC(),
            customerId: billTo.billToId,
            engine: data.engine ? data.engine.trim() : "",
            engineId: data.engineId,
            favoriteFlag: "N",
            favoriteText: "",
            favoriteUpdatedDate: "",
            lastOrderedDate: "",
            make: data.make ? data.make.trim() : "",
            makeId: data.makeId,
            internalMakeId: data.makeId,
            model: data.model ? data.model.trim() : "",
            modelId: data.modelId,
            orderedFlag: "N",
            searchId: "",
            shipToId: shipTos[0].shipToId,
            updatedBy: x2userName,
            updatedDate: timeInUTC(),
            year: data.year ? data.year.trim() : "",
        };
        dispatch(setVehicles(payload));
        dispatch(setVehicleSelected(payload));
    }

    if (authorizationDone) {
        console.log(parts);
        return <Redirect to="/" />;
    }else{
        return (
            <div>
                <div className="custom_spinner_container sms-user-spinner">
					<Spinner className="custom_spinner" animation="border" />
				</div>
            </div>
        )
    }
    
}

export default IFrame
