/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { getPaymentsPrintPDF } from './payments-print.thunk';

import './styles.scss';

const PaymentsPrint = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const params = queryString.parse(location.search);
	const [ pdfData, setPdfData ] = useState(null);
	const paymentPrint = useSelector((state) => state.paymentsPrint);

	useEffect(() => {
		console.log('print PDF ==> ', params);
		dispatch(getPaymentsPrintPDF(params.ref));
	}, []);

	useEffect(
		() => {
			if (paymentPrint && paymentPrint.pdf) {
				const p = 'data:application/pdf;base64, ' + encodeURI(paymentPrint.pdf);
				setPdfData(p);
			}
		},
		[ paymentPrint ]
	);

	return (
		<iframe
			name={`${params.ref}.pdf`}
			download={`${params.ref}.pdf`}
			className="print-frame"
			width="100%"
			src={pdfData}
		/>
	);
};

export default PaymentsPrint;
