import React, { useEffect, useState } from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Moment from "react-moment";
import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _filter from "lodash/filter";
import DateRangePicker from "react-bootstrap-daterangepicker";

import Placeholder from "../../Placeholder";
import XSelect from "../../XSelect";
import XModal from "../../XModal";
import PaginationComponent from "../../Pagination";

import {
  getMakesForYear,
  getModelsForMake,
  getEngineForYearMakeModel,
} from "../../../../actions/search";
import {
  getPartsByMultiGroupOrchestrator,
  getChemPartByGrp,
} from "../../../../actions/parts";
import {
  fetchJobs,
  fetchRecommendedJobs,
  searchJobs,
  deleteJob,
  setVehicles,
} from "../mygarage.thunks";
import { setVehicleSelected } from "../mygarage.slice";

import "./styles.scss";
import { Link, Redirect, useHistory } from "react-router-dom";

import Remove from "../Components/Remove";
import FormValidationErrorMessage from "../../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import {
  isAlphanumeric,
  hasMinLength,
  timeInUTC,
  getPreferenceDataFromStorage,
  getDefaultDataBasedOnChemicalUSerPref,
  getDefaultDataBasedOnUSerPref,
  getShipInfo,
  getCartShipDetails
} from "../../../../helpers/utils";
import orderedIcon from "../../../../static/images/icons/red/order.png";
import { setJobSelected, setJobEventStatus } from "../mygarage.slice";
import { triggerCustomUxCriticalError } from "../../../../actions/error";
import _cloneDeep from "lodash/cloneDeep";


const options = [
  { label: "Jobs", value: "N" },
  { label: "Recommended job", value: "Y" },
];

const viewSelectedJob = (jobToView, sv, history, dispatch) => {
  dispatch({
    payload: {
      selectedJobDetails: jobToView,
    },
    type: "JOB_SELECTED",
  });
  const shipInfo = jobToView.shipInfo ?  jobToView.shipInfo : null;
  let sellerPartnerID = shipInfo.sellerPartnerId ? shipInfo.sellerPartnerId : "";
  let shipMethod = shipInfo.shipMethod ? shipInfo.shipMethod : "";
  let store =  shipInfo.storeNumber ? shipInfo.storeNumber : "";
  const userPreferenceDetail = getPreferenceDataFromStorage();
  const userPrefChemPartByCatDtl = getDefaultDataBasedOnChemicalUSerPref(
    userPreferenceDetail
  );
  const userPrefPartDtl = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
  if (!_isEmpty(jobToView)) {
    let requestItems = [];
    let requestItemsForChemicals = [];

    jobToView.jobsDetails.map((job) => {
      if (job.partType === "catalog") {
        requestItems.push({ groupCode: job.groupId, specificCondition: [] });
      }
      if (job.partType === "chemicals") {
        requestItemsForChemicals.push(job.groupId);
      }
    });
    requestItems = _.uniqBy(requestItems, "groupCode");

    if (!_isEmpty(requestItems)) {
      let groupsSelected = {}
      if(shipInfo != null) {
        groupsSelected = { requestItems, sellPartnerId : sellerPartnerID, store : store, shipMethod : shipMethod };
      } else {
         groupsSelected = { requestItems };
      }
      let partTypes = _filter(
        jobToView.jobsDetails,
        (job) => job.partType === "catalog"
      ).map((item) => {
        return {
          id: item.partTypeId,
          text: item.partTypeDesc,
        };
      });
      dispatch(
        getPartsByMultiGroupOrchestrator(
          sv.year,
          sv.makeId,
          sv.modelId,
          sv.engineId,
          groupsSelected,
          userPrefPartDtl.pageNumber,
          userPrefPartDtl.pageSize,
          userPrefPartDtl.catalogSortBy,
          userPrefPartDtl.catalogOrder,
          { partTypes }
        )
      );
    }
    if (!_isEmpty(requestItemsForChemicals) && shipInfo != null) {
      dispatch(
        getChemPartByGrp(
          requestItemsForChemicals,
          userPrefChemPartByCatDtl.pageNumber,
          userPrefChemPartByCatDtl.pageSize,
          userPrefChemPartByCatDtl.catalogSortBy,
          userPrefChemPartByCatDtl.catalogOrder,
          null,
					null,
					null,
					null,
					null,
					null,
					[],
					sellerPartnerID,
          store,
          shipMethod
        )
      );
    }
    if (!_isEmpty(requestItemsForChemicals) && shipInfo == null) {
      dispatch(
        getChemPartByGrp(
          requestItemsForChemicals,
          userPrefChemPartByCatDtl.pageNumber,
          userPrefChemPartByCatDtl.pageSize,
          userPrefChemPartByCatDtl.catalogSortBy,
          userPrefChemPartByCatDtl.catalogOrder
        )
      );
    }
    history.push("/job-view");
  }
};

const Jobs = (props) => {
  const { closeOverlay } = props;

  const initializeValidity = () => {
    const validity = {
      isSearchKeyNotNull: true,
      isSearchAlphanumericSpecial: true,
      isSearchMinLimit: true,
    };
    return validity;
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const mygarage = useSelector((state) => state.mygarage);
  const sv = mygarage.selectedVehicle;
  const AllJobs = mygarage && mygarage.jobs && mygarage.jobs?.pagedItems ;
  const AllRecomJobs = mygarage && mygarage.jobs && mygarage.recommendedJobs?.pagedItems ;
  const [selectedJob, setSelectedJob] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);
  const [validity, setvalidity] = useState(initializeValidity);
  const [jobs, setJobs] = useState([]);
  const [vehicleModal, setvehicleModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({
    year: "",
    make: "",
    makeId: "",
    model: "",
    modelId: "",
    engine: "",
    engineId: "",
    vehicleSelectionDone: false,
  });

  const search = useSelector((state) => state.search);

  const yearList = search.yearListAll;
  const makeList = search.makesListForYear;
  const modelList = search.modelListForYearAndMake;
  const engineList = search.engineForYearMakeModel;
  const userDetails = user.userDetails;
  const checkoutDetails = useSelector((state) => state.cart.checkoutDetails);

  const [makeListOptions, setmakeListOptions] = useState([]);
  const [modelListOptions, setmodelListOptions] = useState([]);
  const [engineListOptions, setengineListOptions] = useState([]);
  const [isRecJob , setIsJob] = useState("N");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRecPage, setCurrentRecPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(null);
  const [totalRecPages, setTotalRecPages] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [showFiled, setShowField]  = useState(true);
  const [yearListOptions, setYearListOptions] = useState([]);
  const parts = useSelector((state) => state.parts);
  const shipInfo = getCartShipDetails(userDetails, checkoutDetails);
  const categoryGroupMap = parts && parts.categoryGroupMap ;
  const categorySuppliesMap = parts && parts.categorySuppliesMap ;


  const checkJob =(recJobs) =>{
    let recj = _cloneDeep(recJobs);
    if(!_isEmpty(recJobs)){
      recj.map((j)=>{
        j.jobsDetails.map((v)=>{
       if(v.partType === "chemicals"){
        !_isEmpty(categorySuppliesMap) && 
        categorySuppliesMap.map((ct) => { 
          if(ct.code === v.categoryId){
            ct.groups.map((g)=>{
              if(g.id ===  v.groupId){
              v["categoryName"] = g.name ;
              }
            })
         
        }
      })
       }else if(v.partType === "catalog"){
        !_isEmpty(categoryGroupMap) && categoryGroupMap.map((ct) => { 
          if(ct.code === v.categoryId){
            ct.groups.map((g)=>{
              if(g.code ===  v.groupId){
              v["categoryName"] = g.name ;
              }
            })
        }
      })
       }
      })})

    setJobs([...recj]);
    }
    else{
      setJobs(recj)
    }
  }
  useEffect(
    () =>
      setYearListOptions(
        yearList.map((singleYear) => ({
          value: singleYear.code,
          label: singleYear.name,
        }))
      ),
    [yearList]
  );
  useEffect(
    () =>
      setmakeListOptions(
        makeList.map((singleMake) => ({
          value: singleMake.code,
          label: singleMake.name,
        }))
      ),
    [makeList]
  );
  useEffect(
    () =>
      setmodelListOptions(
        modelList.map((singleModel) => ({
          value: singleModel.code,
          label: singleModel.name,
        }))
      ),
    [modelList]
  );
  useEffect(
    () =>
      setengineListOptions(
        engineList.map((singleEngine) => ({
          value: singleEngine.code,
          label: singleEngine.name,
        }))
      ),
    [engineList]
  );

  const handleSearchChange = (event) => {
    const searchData = event.target.value;
    let validity = initializeValidity();
    validity.isSearchAlphanumeric = isAlphanumeric(searchData);
    validity.isSearchMinLimit = hasMinLength(searchData, 1);
    setvalidity(validity);
  };

  const handleSearchBlur = () => {
    let validity = initializeValidity();
    setvalidity(validity);
  };

  const initialFetchJobs = (clickedPage) => {
    let pageNum = clickedPage ? clickedPage : currentPage;
    const payload = {
      customerId: user.userDetails && user.userDetails.billTo.billToId,
      shipToId:
        (user.userDetails && user.userDetails.shipTos[0].shipToId) || "001",
      pageNo: pageNum,
      pageSize: pageSize,
      dispatch: dispatch,
    };
    try {
      const jobsAction = dispatch(fetchJobs(payload));
      jobsAction.then((action) => {
        if (action.payload) {
          let recJobs =  !_isEmpty(action.payload.pagedItems ) && action.payload.pagedItems.filter((j)=> j.recommendedJob === "N" );
          setJobs(recJobs);
          setCurrentPage(action.payload.pageNumber);
          setTotalPages(action.payload.totalPages);
          setTotalItems(action.payload.totalItems);
        }
      });
    } catch (error) {}
  };

  const initialFetchRecommendedJobs = (clickedPage) => {
    let pageNum = clickedPage ? clickedPage : currentRecPage;
    const payload = {
      recommendedJob:"Y",
      pageNo: pageNum,
      pageSize: pageSize,
      dispatch: dispatch,
    };
    try {
      const jobsAction = dispatch(fetchRecommendedJobs(payload));
      jobsAction.then((action) => {
        if (action.payload) {
          let recJobs =  !_isEmpty(action.payload.pagedItems ) && action.payload.pagedItems.filter((j)=> j.recommendedJob === "Y" );
          checkJob(recJobs);
          setCurrentRecPage(action.payload.pageNumber);
          setTotalRecPages(action.payload.totalPages);
          setTotalItems(action.payload.totalItems);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if(isRecJob === "Y"){
    initialFetchRecommendedJobs();
    }else{
    initialFetchJobs(); 
    }
  }, [isRecJob]);

  const deleteJobHandler = () => {
    console.log("deleted selecte", selectedJob);
    const { jobSearchId } = selectedJob;
    const removeAction = dispatch(
      deleteJob({ jobSearchId, dispatch: dispatch })
    );
    setOpenWarning(false);

    removeAction.then((action) => {
      if(isRecJob === "Y"){
        initialFetchRecommendedJobs();
      }else{
      initialFetchJobs();
      }
    });
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm && searchTerm.length > 1) {
      const payload = {
        customerId: user.userDetails && user.userDetails.billTo.billToId,
        shipToId:(user.userDetails && user.userDetails.shipTos[0].shipToId) || "001",
        searchKey: searchTerm,
        recommendedJob: isRecJob,
        dispatch: dispatch,
      };
      try {
        const jobsAction = dispatch(searchJobs(payload));
        jobsAction.then((action) => {
          if(isRecJob === "Y"){
            checkJob(action.payload);
          }else{
          setJobs(action.payload);
          }
        });
      } catch (error) {}
    } else if (_.isEmpty(searchTerm)) {
      if(isRecJob === "Y"){
        initialFetchRecommendedJobs();
      }else{
        initialFetchJobs();
      }
    }
  };

  const handleWarningOpen = (jb) => {
    setSelectedJob(jb);
    setOpenWarning(true);
  };

  const handleWarningClose = () => {
    setOpenWarning(false);
    // setOpenViewJob(false); // TODO
  };

  const handleJobsSelect = (selectedJob) => {
    const statusMessageProps = {
      message: "Please select vehicle.",
      type: "info",
    };
    let selectedUpdatedJob = _cloneDeep(selectedJob)
    selectedUpdatedJob.shipInfo = shipInfo;
    setSelectedJob(selectedUpdatedJob);
    dispatch(setJobSelected(selectedUpdatedJob));
    dispatch(setJobEventStatus({ status: true }));
    if (sv && sv.makeId && sv.modelId && sv.engineId) {
      viewSelectedJob(selectedUpdatedJob, sv, history, dispatch);
      closeOverlay();
    } else {
      closeOverlay();
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      history.push("/");
    }
  };

  const handleJobCreate = () => {
    RecommendedJobSateData(false);
    closeOverlay();
    history.push("/job-create");
  };
  
  const handleRecommendedJobCreate = () => {
    RecommendedJobSateData(true);
    closeOverlay();
    history.push("/job-create");
  };

  const RecommendedJobSateData =(flag) =>{
    dispatch({
      payload: {
      recommendedJobEditJob: [],
      },
      type: 'RECOMMENDEDJOB_EDIT_JOB',
    });
    dispatch({
      payload: {
        createRecommendedJob: flag,
      },
      type: 'CREATE_RECOMMENDEDJOB',
    });
  }

  const submitVehicle = () => {
    setvehicleModal(false);
    viewSelectedJob();
  };
  const handleJobChange = (e) =>{
   if(e.value === "Y"){
    setJobs([]);
    setIsJob(e.value);
    setShowField(false);
   let recJobs =  !_isEmpty(AllRecomJobs) && AllRecomJobs.filter((j)=> j.recommendedJob === e.value) ;
   checkJob(recJobs);
   } else  
   if(e.value === "N"){
    setJobs([]);
    setIsJob(e.value);
    setShowField(true);
    let jobsData =  !_isEmpty(AllJobs ) && AllJobs.filter((j)=> j.recommendedJob === e.value) ;
    if(!_isEmpty(jobsData)){
    setJobs([...jobsData]);
    } else {
      setJobs([])
    }
   }
  }

  const showSelect = (jb) => {
    let isVisible = true; // TODO
    return isVisible;
  };

  const handlePageChange = (clickedPage) => {
    if(isRecJob === "Y"){
      setCurrentRecPage(clickedPage);
     initialFetchRecommendedJobs(clickedPage);
    } else { 
      setCurrentPage(clickedPage);
      initialFetchJobs(clickedPage);
    }
  };

  return (
    <React.Fragment>
      <div className="vehicles-wrapper">
        {jobs && (
          <Row>
            <Col md="4" className="mygarage_pagination_wrapper">
              <PaginationComponent
                handlePageChange={handlePageChange}
                currentPage={ isRecJob === "Y" ? currentRecPage : currentPage}
                totalPages={isRecJob === "Y" ? totalRecPages : totalPages}
              />
            </Col>
          </Row>
        )}
        <Row>
        <Col md={!_isEmpty( userDetails) && userDetails.userType === "IT_INTERNAL" ? "8" : "6"} sm="6">
            <Form.Control
              placeholder="Search Jobs"
              className="input__search input__control job_width"
              onKeyUp={(e) => handleSearch(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
              onMouseOver={(e) => e.stopPropagation()}
              onChange={handleSearchChange}
              onBlur={handleSearchBlur}
            />
            <FormValidationErrorMessage
              condition={
                !validity.isSearchAlphanumericSpecial &&
                validity.isSearchMinLimit
              }
              errorMessage="Search key must be alphanumeric or special characters"
            />
            <FormValidationErrorMessage
              condition={
                !validity.isSearchMinLimit &&
                validity.isSearchAlphanumericSpecial
              }
              errorMessage="Search key should have atleast 1 character"
            />
          </Col>
          <Col md="3" sm="4" className="padding-left-0">
            <XSelect
              isSearchable={false}
              options={options}
              defaultValue={options[0]}
              onChange={handleJobChange}
            />
          </Col>
          <Col md={!_isEmpty( userDetails) && userDetails.userType === "IT_INTERNAL" ? "6" : "3" }>
            <div className={userDetails.userType === "IT_INTERNAL" ? "addJob mt-3" :"mt-3" } >
              <Button
                className="maroonBtn xl_parts_btn"
                onClick={() => handleJobCreate()}
              >
                Create Job
              </Button>
            </div>
          </Col>
          {(!_isEmpty( userDetails) &&  userDetails.userType === "IT_INTERNAL" ) && (
        <Col md="5" >
            <div className="mt-3" >
              <Button
                className="maroonBtn job_button_text xl_parts_btn"
                onClick={() => handleRecommendedJobCreate()}
              >
              Create Recommended Job
              </Button>
            </div>
          </Col>)}
        </Row>
        <Row>
  
        </Row>
        {mygarage.loading ? (
          <Placeholder rows={1} />
        ) : jobs && jobs.length ? (
          <React.Fragment>
            <div className="mygarage__content">
              {jobs.map((jb, i) => {
                return (
                  <div className="mygarage__vehicle" key={i}>
                    <Row>
                      <Col md="4">
                      <div className="v__title">
                      <div className="v__icons_wrapper">
                        <div>
                          <span>{jb.jobName}</span>
                        </div>
                        <div className="v__icons"></div>
                      </div></div>
                      <div className="v__date">
                      Added: <Moment date={jb.createDate} format="MM-DD-YYYY" />
                    </div>
                    {!_isEmpty( userDetails) &&  userDetails.userType === "IT_INTERNAL" && (
                   <OverlayTrigger
                   placement="top"
                   delay={{ show: 100, hide: 400 }}
                   overlay={(!_isEmpty(jb.createdBy) && isRecJob === "Y") ?  <Tooltip>{jb.createdBy}</Tooltip> : <span></span>}>
                   <div className={isRecJob === "Y" ? "adduser_job v__mechanic" : "v__mechanic" }>User: {jb.createdBy}</div>
                 </OverlayTrigger>
                   
                    )}
                      </Col>
                            <Col md="5">
                            {!_isEmpty(jb.jobsDetails) && _.uniqBy(jb.jobsDetails,"categoryName").map((jd)=>(
                             !_isEmpty(jd.categoryName) && (
                             <div>                  
                             <span className="">{jd.categoryName}</span> 
                               </div> )
                                 ))}                 
                                                
                            </Col>
                        
                      <Col md="3">
                      <div className="v__title">
                      <div className="operation__links">
                        {showSelect(jb) && (
                          <a
                          className="b__link tph-cursor"

                            onClick={() => handleJobsSelect(jb)}
                          >
                            Select
                          </a>
                        )}
                        {(showFiled || (!_isEmpty( userDetails) &&  userDetails.userType === "IT_INTERNAL")) ? (
                        <Remove handleRemove={(e) => handleWarningOpen(jb)} />):""}
                      </div>
                    </div></Col>
                            </Row>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        ) : (
          <div className="no-quotes">
            You have not created any custom jobs, please start with creating a
            custom job
          </div>
        )}
      </div>
      {selectedJob && (
        <XModal
          show={openWarning}
          handleClose={handleWarningClose}
          handleAction={deleteJobHandler}
          className="custom_xmodal"
          isValidValidity={true}
        >
          <div className="delete__text text__center">
            You want to delete the job <br />
            <span className="bold">{`${selectedJob.jobName}`}</span>
          </div>
        </XModal>
      )}
    </React.Fragment>
  );
};

export { Jobs as default, viewSelectedJob };
