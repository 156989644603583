import { createSlice } from "@reduxjs/toolkit";
import _isEmpty from "lodash/isEmpty";
import {fetchShipToIds, fetchUserShipToIds} from "./shipTo.thunk";


const initialState = {
    shipToIds: [],
    shipToIdsError: false,
    userShipTos:[],
};

const getShipToIds = createSlice({
    name: "getShipToIds",
    initialState,
    extraReducers: {
        [fetchShipToIds.pending]: (state, action) => {
            state.shipToIdsError = false;
        },
        [fetchShipToIds.fulfilled]: (state, action) => {
            state.shipToIdsError = false;
            if (!_isEmpty(action.payload)) {
                let tempArr = []
                action.payload.map((shipTo) => {
                    tempArr.push({
                        ...shipTo,
                        value: shipTo.shipToId,
                        label: `${shipTo.shipToId} ${shipTo.address}`
                    });
                });
                action.payload = tempArr;
            }
            state.shipToIds = action.payload;
        },
        [fetchShipToIds.rejected]: (state, action) => {
            state.shipToIdsError = true;
        },
        [fetchUserShipToIds.pending]: (state, action) => {
            state.shipToIdsError = false;
        },
        [fetchUserShipToIds.fulfilled]: (state, action) => {
            state.shipToIdsError = false;
            if (!_isEmpty(action.payload)) {
                state.userShipTos = action.payload;
            }
        },
        [fetchUserShipToIds.rejected]: (state, action) => {
            state.shipToIdsError = true;
        },
    }
});


const { reducer } = getShipToIds;
export default reducer;