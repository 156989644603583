import React, {useState} from 'react';
import { Container, Row, Form, Button, Spinner } from 'react-bootstrap';
import FormValidationErrorMessage from '../shared/FormValidationErrorMessage/FormValidationErrorMessage';

function NewPasswordSetup({ handleSubmit, handleInputChange, userApiCall, validity, handleInputBlur }) {
	const [showConfirmPassword, setShowConfirmPassword ] = useState(false)
	const [showNewPassword, setShowNewPassword ] = useState(false)

	const handleShowOrHide = (field)=>{
		if(field === "newPassword"){
			setShowNewPassword(!showNewPassword)
		}else if(field === "confirmPassword"){
			setShowConfirmPassword(!showConfirmPassword)
		}
	}
	return (
		<Container fluid className="login_container">
			<Row className="login_title">
				<span>Change your password to a new one</span>
			</Row>
			<Row className="login_box">
				<span>New Password</span>
				<div>
					<Form
						className="login_form"
						onSubmit={(e) => handleSubmit(e)}
					>
						<div className="returning_customer_container">
							<div className="input_fields">
								<list>Password should contain -
									<ul>
										<li>Minimum password length – 8.</li>
										<li>Password should contain atleast 1 uppercase, 1 lowercase, 1 number and a special character.</li>
										<li>{`Special character should include - = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ \``}</li>
									</ul>
								</list>
								<label htmlFor="newPassword">New Password</label>
								<div className="password_container">
									<Form.Control
										size="sm"
										type={showNewPassword ? "text" :"password"}
										name="newPassword"
										onChange={handleInputChange}
										onBlur={handleInputBlur}
										onPaste={(e)=>{e.preventDefault()}}
									/>
									{showNewPassword 
									? <i class="fa fa-eye-slash eyeIcon" onClick={()=>{handleShowOrHide("newPassword")}} aria-hidden="true"></i>
									: <i class="fa fa-eye eyeIcon" onClick={()=>{handleShowOrHide("newPassword")}} aria-hidden="true"></i>
									}
								</div>
								<FormValidationErrorMessage
									condition={!validity.isPasswordNotNull}
									errorMessage="Please enter your Password"
								/>
								<FormValidationErrorMessage
									condition={!validity.doesPwdhaveFormat && validity.isPasswordNotNull}
									errorMessage="Your password does not follow the standards"
								/>
								<label htmlFor="newPassword">Confirm Password</label>
								<div className="password_container">
									<Form.Control
										size="sm"
										type={showConfirmPassword ? "text" :"password"}
										name="confirmPassword"
										onChange={handleInputChange}
										onBlur={handleInputBlur}
										autoComplete="off"
										onPaste={(e)=>{e.preventDefault()}}
									/>
									{showConfirmPassword 
									? <i class="fa fa-eye-slash eyeIcon" onClick={()=>{handleShowOrHide("confirmPassword")}} aria-hidden="true"></i>
									: <i class="fa fa-eye eyeIcon" onClick={()=>{handleShowOrHide("confirmPassword")}} aria-hidden="true"></i>
									}
								</div>
								
								<FormValidationErrorMessage
									condition={
										!validity.isValidConfirmPassword
									}
									errorMessage="New Password and confirm password should be same"
								/>
								<FormValidationErrorMessage
									condition={
										!validity.isConfirmPasswordNotNull && validity.isValidConfirmPassword
									}
									errorMessage="Please confirm Password"
								/>
							</div>
							{userApiCall ? (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									disabled
								>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									Loading..
								</Button>
							) : (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									type="submit"
								>
									Change Password
								</Button>
							)}
						</div>
					</Form>
				</div>
			</Row>
		</Container>
	);
}
export default NewPasswordSetup;
