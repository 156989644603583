import React, { useRef } from 'react';
import { useReactToPrint } from "react-to-print-nonce";
import BootstrapTable from "react-bootstrap-table-next";
import { Row, Col } from 'react-bootstrap';
import { formatAmount } from "../../../helpers/utils";
import XModal from '../../shared/XModal';
import _isEmpty from 'lodash/isEmpty';

const headerStyleConfirm = {
  textAlign: "center",
  padding: "10px",
  backgroundColor: "#0F5283",	
  color: "#FFFFFF",
  fontWeight: "500",
  "border-color": "#0F5283",	
  "font-size": "17px",
  width: "110px",
};

const CreditMemoTable = (props) => {
  let {
    showModal,
    handleCloseMethod,
    confirmNumber,
    confirmData,
  } = props;

  const printComponent = useRef();
  const handlePrint = useReactToPrint({
    content: () => printComponent.current,
  })
  const returnConfirmcolumns = [
    {
      dataField: "mfgCode",
      text: "P/L",
      editable: false,
      headerStyle: headerStyleConfirm,
    },
    {
      dataField: "partNum",
      text: "Part #",
      headerStyle: headerStyleConfirm,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      editable: false,
      headerStyle: headerStyleConfirm,
    },
    {
      dataField: "returnType",
      text: "Return Type",
      sort: true,
      editable: false,
      headerStyle: headerStyleConfirm,
    },
    {
      dataField: "qty",
      text: "Verified Qty#",
      sort: true,
      editable: false,
      headerStyle: headerStyleConfirm,
    },
    {
      dataField: "creditAmt",
      text: "Credit Amount",
      sort: true,
      editable: false,
      headerStyle: headerStyleConfirm,
      formatter: function (cell, row) {
        return (
          <div className="">
            {(row.creditAmt !== undefined) && (
              <span className="maroonColorTxt">
                ${formatAmount(row.creditAmt)}
              </span>
            )}
          </div>)
      }
    },
    {
      dataField: "msg",
      text: "Status",
      sort: true,
      editable: false,
      headerStyle: headerStyleConfirm,
    },
  ];
  return (
    <XModal
      show={showModal}
      title={"CREDIT MEMO SUCCESSFULLY CREATED"}
      className="vehicle-maintance-addcart-modal"
      favoriteTextValidity={true}
      submitType={true}
      handleAction={() => handleCloseMethod()}
      handleClose={() => handleCloseMethod()}
      submitButtonText="OK"
    >
      <div className="print_icon_return d-flex">
        <span className="material-icons" onClick={handlePrint}>
          print
        </span>
        <span onClick={handlePrint}>Print</span>
      </div>
      <div
        className="estimate_popup_container"
        ref={printComponent}
      >
        <div className="">
          <div className="print__logo">
            <img
              alt=""
              src="/public_images/Print_Logo.png"
              align="center"
            />
          </div>
        </div>
        <div className="return-confirm">
          Confirmation Number for this Credit Memo is{" "}
          <b> {confirmNumber}</b>
        </div>
        <Row className="return-confirm-popup confirm-data">
          <Col>
            <div>
              {!_isEmpty(confirmData) && confirmData.length > 0 && (
                <BootstrapTable
                  bootstrap4
                  keyField="invoice"
                  data={confirmData}
                  columns={returnConfirmcolumns}
                  bordered={true}
                  wrapperClasses="confirm-data"
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </XModal>
  );
}
export default CreditMemoTable;
