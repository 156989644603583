import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  search,
  vinSearch,
  licensePlateSearch,
  enginesSearch,
  getModelsSearch,
  getVehiclesByModel,
  getVehiclesByMake,
  getMakesByYear,
  getProductListFromSkuNumber,
  addSearchedValue,
} from "./globalSearch.thunk";

let initialState = {
  options: [{ vehicles: [], group: [], parts: [] }],
  loading: false,
  error: null,
  years: [],
  makes: [],
  models: [],
  states: [],
  vehicles: [],
  cgOptions: [],
  parts: [],
  searchedValues: [],
};

const globalSearch = createSlice({
  name: "globalsearch",
  initialState,
  reducers: {
    reset(state) {
      state.option = [];
      state.years = [];
      state.makes = [];
      state.models = [];
      state.vehicles = [];
      state.cgOptions = [];
      state.parts = [];
      state.states = [];
      state.searchedValues = [];
    },
    getGroupsForCategory(state, action) {
      const { payload } = action;

      if (payload) {
        const groups = payload.groups.map((g) => {
          return {
            categoryId: payload.code,
            categoryDesc: payload.name,
            groupId: g.code,
            groupDesc: g.name,
          };
        });

        state.options[0].category = [];
        state.options[0].group = groups;
        state.options[0].states = [];
        state.options[0].make = [];
        state.options[0].model = [];
        state.options[0].parts = [];
      }
    },
  },
  extraReducers: {
    [search.pending]: (state, action) => {
      state.loading = true;
    },
    [search.fulfilled]: (state, action) => {
      const {
        payload,
        payload: {
          data: { year, make, model, category, group, partType, ymm_list },
        },
      } = action;

      state.years = _.filter(
        _.unionBy(_.concat(state.years, year), "yearDesc"),
        (y) => y
      );

      if (year && year.length === 1) {
        state.years = year;
      }

      state.makes = _.filter(
        _.unionBy(_.concat(state.makes, make), "mainMakeCode"),
        (m) => m
      );
      state.models = _.filter(
        _.unionBy(_.concat(state.models, model), "modelId"),
        (m) => m
      );
      state.states = _.filter(
        _.unionBy(_.concat([], payload.data.state), "stateDesc"),
        (s) => s
      );
      state.categories = category;
      state.group = _.filter(
        _.unionBy(_.concat([], group), "groupId"),
        (s) => s
      );
      state.parts = _.filter(
        _.unionBy(_.concat([], partType), "partTypeDesc"),
        (p) => p
      );

      if (state.years.length > 0 || state.makes.length > 0) {
        state.vehicles = [];

        if (state.years.length > 0 && state.makes.length === 0) {
          state.years.forEach((_year) => {
            if (_year) {
              const veh = {
                option: ` ${_year.yearDesc}`,
                type: "vehicles",
                vehicleParams: {
                  year: _year.yearDesc,
                },
              };

              const found = _.find(
                state.vehicles,
                (v) => v.option === veh.option
              );
              if (!found) {
                state.vehicles.push(veh);
              }
            }
          });
        }

        if (state.years.length > 0 && state.makes.length > 0) {
          state.vehicles = [];
          state.years.forEach((_year) => {
            state.makes.forEach((_make) => {
              if (_year && _make) {
                const veh = {
                  option: ` ${_year.yearDesc} ${_make.makeDesc.trim()}`,
                  type: "vehicles",
                  vehicleParams: {
                    year: _year.yearDesc,
                    make: _make.mainMakeName.trim(),
                    mainMakeCode: _make.mainMakeCode,
                    makeId: _make.makeId,
                    makeInternalId: _make.makeInternalId,
                  },
                };

                const found = _.find(
                  state.vehicles,
                  (v) => v.option === veh.option
                );
                if (!found) {
                  state.vehicles.push(veh);
                }
              }
            });
          });
        }
      }

      if (ymm_list && ymm_list.length > 0) {
        state.vehicles = [];
        ymm_list.forEach((ymm) => {
          const veh = {
            option: `${ymm.yearId} ${ymm.makeDesc.trim()} ${
              ymm.modelDesc ? ymm.modelDesc : ""
            }`,
            type: "vehicles",
            vehicleParams: {
              year: ymm.yearId,
              make: ymm.mainMakeName
                ? ymm.mainMakeName.trim()
                : ymm.mainMakeName,
              mainMakeCode: ymm.mainMakeCode,
              makeId: ymm.makeId,
              makeInternalId: ymm.makeInternalId,
              modelId: ymm.modelId,
              model: ymm.modelDesc,
            },
          };

          state.vehicles.push(veh);
        });
      } else {
        state.vehicles = [];
      }

      const licensePlates = state.states.map((s) => {
        return {
          state: s.stateDesc,
          type: "state",
          plate: s,
        };
      });

      const options = [
        {
          vehicles: _.uniqBy(state.vehicles, (v) => v.option),
          states: licensePlates,
          category: state.categories || [],
          group: state.group,
          parts: state.parts,
          make: state.makes,
          model: state.models,
        },
      ];
      state.options = options;
      state.loading = false;
    },
    [search.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    // Global Search VIN
    [vinSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [vinSearch.fulfilled]: (state, action) => {
      const {
        payload: {
          internalMakeCode,
          yearText,
          makeText,
          makeCode,
          vehicleModels,
          vehicleEngines,
          vehicleVINInfo,
          vinCode,
        },
      } = action;
      const model = _.head(vehicleModels);
      const engine = _.head(vehicleEngines);
      const info = _.head(vehicleVINInfo);

      const v = {
        option: `${yearText} ${makeText} ${model.name} ${engine.name}`,
        type: "vehicles",
        ignoreFuzy: true,
        vehicleParams: {
          internalMakeId: internalMakeCode,
          year: yearText,
          make: makeText,
          makeId: makeCode,
          model: model.name,
          modelId: model.code,
          engine: engine.name,
          engineId: engine.code,
          vin: vinCode,
        },
      };

      state.options = [{}];

      state.options[0].vehicles = [v];
      state.options[0].group = [];
      state.options[0].states = [];
      state.options[0].make = [];
      state.options[0].model = [];
      state.options[0].parts = [];
      state.options[0].info = [];
      state.loading = false;
    },
    [vinSearch.rejected]: (state, action) => {
      state.loading = false;
    },

    // Global Search License Plate
    [licensePlateSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [licensePlateSearch.fulfilled]: (state, action) => {
      const { payload } = action;
      state.vehicles = [];
      if (payload) {
        payload.forEach((vehicle) => {
          const {
            internalMakeCode,
            yearText,
            makeText,
            makeCode,
            vehicleModels,
            vehicleEngines,
          } = vehicle;
          const model = _.head(vehicleModels);
          const engine = _.head(vehicleEngines);

          if (model && engine) {
            const v = {
              option: `${yearText} ${makeText} ${model.name} ${engine.name}`,
              type: "vehicles",
              ignoreFuzy: true,
              vehicleParams: {
                internalMakeId: internalMakeCode,
                year: yearText,
                make: makeText,
                makeId: makeCode,
                model: model.name,
                modelId: model.code,
                engine: engine.name,
                engineId: engine.code,
              },
            };

            state.vehicles.push(v);
            state.options[0].vehicles = [v];
            state.options[0].group = [];
            state.options[0].states = [];
            state.options[0].make = [];
            state.options[0].model = [];
            state.options[0].parts = [];
          }
        });
      }

      state.loading = false;
    },
    [licensePlateSearch.rejected]: (state, action) => {
      state.loading = false;
    },

    // Models Search for YM
    [getModelsSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getModelsSearch.fulfilled]: (state, action) => {
      const {
        payload,
        meta: { arg },
      } = action;
      let _vehicles = [];
      if (payload) {
        payload.forEach((model) => {
          const _veh = {
            option: `${arg.yearDesc || arg.year} ${arg.makeDesc || arg.make} ${
              model.name
            }`,
            type: "vehicles",
            vehicleParams: {
              year: arg.yearDesc || arg.year,
              yearId: arg.yearId || arg.year,
              make: model.make,
              makeId: model.makeCode,
              internalMakeId: +model.internalMakeCode,
              model: model.name,
              modelId: model.code,
            },
          };

          _vehicles.push(_veh);
        });
      }
      state.options[0].vehicles = _vehicles;
      state.loading = false;
    },
    [getModelsSearch.rejected]: (state, action) => {
      state.loading = false;
    },

    // Engines Search for YMM
    [enginesSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [enginesSearch.fulfilled]: (state, action) => {
      const {
        payload,
        meta: { arg },
      } = action;
      let _vehicles = [];
      payload.forEach((engine) => {
        const _veh = {
          option: `${arg.yearDesc || arg.year} ${arg.makeDesc || arg.make} ${
            arg.modelDesc || arg.model
          } ${engine.name}`,
          type: "vehicles",
          vehicleParams: {
            year: arg.yearDesc || arg.year,
            yearId: arg.yearId,
            make: arg.makeDesc || arg.make,
            makeId: arg.makeId,
            internalMakeId: arg.internalMakeId,
            model: arg.modelDesc || arg.model,
            modelId: arg.modelId,
            engine: engine.name,
            engineId: engine.code,
          },
        };

        _vehicles.push(_veh);
      });
      state.options[0].vehicles = _vehicles;
      state.loading = false;
    },
    [enginesSearch.rejected]: (state, action) => {
      state.loading = false;
    },

    // Vehicles By Make

    [getVehiclesByMake.pending]: (state, action) => {
      state.loading = true;
    },
    [getVehiclesByMake.fulfilled]: (state, action) => {
      const {
        payload,
        payload: { make },
      } = action;
      let _vehicles = [];
      if (payload) {
        _vehicles = make.map((m, i) => {
          const _veh = {
            option: `${m.yearDesc} ${m.makeDesc}`,
            vehicleParams: m,
            type: "vehicles",
          };
          return _veh;
        });

        state.options[0].vehicles = _vehicles;
        state.options[0].group = [];
        state.options[0].states = [];
        state.options[0].make = [];
        state.options[0].model = [];
        state.options[0].parts = [];
      }
      state.loading = false;
    },
    [getVehiclesByModel.rejected]: (state, action) => {
      state.loading = false;
    },

    // Vehicles By Model
    [getVehiclesByMake.pending]: (state, action) => {
      state.loading = true;
    },
    [getVehiclesByModel.fulfilled]: (state, action) => {
      const {
        payload,
        payload: { model },
      } = action;
      let _vehicles = [];
      if (payload) {
        _vehicles = model.map((m, i) => {
          const _veh = {
            option: `${m.yearDesc} ${m.mainMakeName} ${m.modelDesc}`,
            vehicleParams: m,
            type: "vehicles",
          };
          return _veh;
        });

        state.options[0].vehicles = _vehicles;
        state.options[0].group = [];
        state.options[0].states = [];
        state.options[0].make = [];
        state.options[0].model = [];
        state.options[0].parts = [];
      }
      state.loading = false;

      return state;
    },
    [getVehiclesByModel.rejected]: (state, action) => {
      state.loading = false;
    },

    // Vehicles By Year
    [getMakesByYear.pending]: (state, action) => {
      state.loading = true;
    },
    [getMakesByYear.fulfilled]: (state, action) => {
      const {
        payload,
        meta: { arg },
      } = action;
      let _vehicles = [];
      if (payload) {
        _vehicles = payload.map((m, i) => {
          const _veh = {
            option: `${arg.yearDesc || arg.year} ${m.name.trim()}`,
            vehicleParams: {
              yearId: arg.yearDesc || arg.year,
              year: arg.yearDesc || arg.year,
              make: m.name.trim(),
              makeId: +m.vehicleMake || m.code,
              internalMakeId: +m.vehicleMake,
            },
            type: "vehicles",
          };
          return _veh;
        });

        state.options[0].vehicles = _vehicles;
        state.options[0].group = [];
        state.options[0].states = [];
        state.options[0].make = [];
        state.options[0].model = [];
        state.options[0].parts = [];
      }
      state.loading = false;
    },
    [getMakesByYear.rejected]: (state, action) => {
      state.loading = false;
    },
    [getProductListFromSkuNumber.pending]: (state, action) => {
      state.loading = false;
    },
    [getProductListFromSkuNumber.fulfilled]: (state, action) => {
      const { payload } = action;

      const skus = payload.responseData.map((sku) => {
        return {
          option: `${sku.mfgCode} ${sku.partNum} - ${sku.itemSpecific}`,
          partSku: sku,
        };
      });

      if (state.options && state.options[0]) {
        state.options[0].partSkus = skus;
      } else {
        state.options[0] = { partSkus: skus };
      }
    },
    [getProductListFromSkuNumber.rejected]: (state, action) => {
      state.loading = false;
    },
    [addSearchedValue.pending]: (state, action) => {
      state.loading = false;
    },
    [addSearchedValue.fulfilled]: (state, action) => {
      return {
        ...state,
        searchedValues: [...state.searchedValues, action.payload],
      };
    },
    [addSearchedValue.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { actions, reducer } = globalSearch;

export const { getGroupsForCategory, reset } = actions;

export default reducer;
