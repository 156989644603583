import React from "react";

import { Container, Row } from "react-bootstrap";
import {
  PlaceHolderCard,
  PlaceHolderImage,
  PlaceholderHeading,
  PlaceholerTitle,
  PlaceholderLine,
} from "./PlaceHolderComponents";

function Placeholder(props) {
  let { rows } = props;
  let placeHolder = [];
  for (let i = 0; i < rows; i++) {
    placeHolder.push(<PlaceHolderCard key={i} />);
  }
  return placeHolder;
}

export default Placeholder;
