import React, { Fragment } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import {
  validateOnlineIdForm,
  noSpecialCharsRegExp,
  decimalNumbersOnlyRegExp,
  validateUserRegForm,
} from "../../../helpers/utils";
import { createUser } from "../../../actions/user";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import { fetchShipToIds } from "../../shared/ShipTo/shipTo.thunk";
import {
  registerMitchellUser,
  changeMitchellUserPassword,
} from "../../../actions/testsms";
import storage from "../../../helpers/storage";

import _every from "lodash/every";
import Select from "react-select";

import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";

class MitchellUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: this.initializeForm(),
      validity: this.intializeValidations(),
      errorPopUp: false,
      showPassword: false,
      shownewPassword: false,
      options: [],
      shipTo: [],
      userCreation: '',
      passwordCreation: '',
      selectedOptions: "Mitchell User",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    storage.set("authFailed", false, "local");
  }

  initializeForm() {
    this.formObject = {
      xlpAccNo: "",
      password: "",
      userId: "",
      newPassword: "",
    };
    return this.formObject;
  }

  intializeValidations() {
    this.validity = {
      isUserTypeNotNull: true,
      isXlpAccNoNotNull: true,
      isXlpAccNoAlphanumeric: true,
      isXlpAccNoLengthBelowLimit: true,
      isXlpAccNoMinLength: true,
      isValidXlpAccNo: true,
      isuserIdNoNotNull: true,
      isuserIdNoAlphanumeric: true,
      isuserIdNoLengthBelowLimit: true,
      isuserIdNoMinLength: true,
      isValiduserIdNo: true,
      isShipToNotNull: true,
      isPasswordNotNull: true,
      isValidPassword: true,
    };
    return this.validity;
  }

  componentDidUpdate(prevProps) {
    let {
      formObject,
      formErrorMessage,
      errorPopUp,
      userCreation,
      popUpMessage,
      passwordCreation,
    } = this.state;
    let { dispatch } = this.props;
    let stateChanged = false;

    if (!_isEqual(this.props, prevProps)) {

      if (!_isEqual(this.props.userCreation, prevProps.userCreation)) {
        if ((this.props.userCreation === "success") || (this.props.userCreation === "failed")) {
          formObject.xlpAccNo = "";
          formObject.password = "";
          this.state.shipTo = [];
          this.setState({
            ...this.state,
            formObject,
          });
          stateChanged = true;
        }
      }
      if (!_isEqual(this.props.passwordCreation, prevProps.passwordCreation)) {
        if ((this.props.passwordCreation === "success") || (this.props.passwordCreation === "failed")) {
          formObject.userId = "";
          formObject.newPassword = "";
          this.setState({
            ...this.state,
            formObject,
          });
          stateChanged = true;
        }
      }


      if (!_isEqual(this.props.messageFromApi, prevProps.messageFromApi)) {
        formErrorMessage = this.props.messageFromApi.replace(
          /The Parts House/g,
          '<span style="color:#0F5283;"><b>The Parts House</b></span>'
        );
        errorPopUp = true;

        dispatch({
          payload: {
            userCreationSuccess: this.props.userCreation,
            message: "",
            userCreationApiCall: this.props.userCreationApiCall,
          },
          type: "USER_CREATION_COMPLETE",
        });

        errorPopUp = true;

        dispatch({
          payload: {
            passwordCreationSuccess: this.props.passwordCreationSuccess,
            message: "",
            passwordCreationApiCall: this.passwordCreationApiCall,
          },
          type: "PASSWORD_RESET_COMPLETE",
        });
      }
      if (stateChanged) {
        this.setState({
          ...this.state,
          formObject: {
            ...this.state.formObject,
            ...formObject,
          },
          formErrorMessage,
          errorPopUp,
          popUpMessage,
          passwordCreation,
          userCreation
        });
      }
    }
  }

  validateForm() {
    let {
      formObject: { xlpAccNo, password, userId, newPassword },
      shipTo,
      validity,
    } = this.state;
    validity = validateOnlineIdForm("xlpAccNo", xlpAccNo, validity);
    validity = validateOnlineIdForm("userId", userId, validity);
    validity.isShipToNotNull = !_isEmpty(shipTo);
    validity = validateOnlineIdForm("password", password, validity);
    validity = validateOnlineIdForm("newPassword", newPassword, validity);
    this.setState({ ...this.state, validity });
    return _every(validity);
  }

  handleSubmit(e) {
    e.preventDefault();

    let {
      formObject: { xlpAccNo, password, userId, newPassword },
      selectedOptions,
      shipTo,
    } = this.state;

    let { dispatch } = this.props;

    if (true && selectedOptions == "Mitchell User") {
      let userObj = {
        password: password,
        customer_id: xlpAccNo,
        shipTo_id: shipTo.value,
        invoice_number: null,
        invoice_amount: null,
        user_email: "user_mail@gmail.com",
        sms_source: "SMS-MITCHELL1",
      };

      registerMitchellUser(userObj, dispatch);

    } else if (true && selectedOptions == "Password Reset") {
      let userObj = {
        password: newPassword,
        // customer_id: userId.split('#').pop(),
        customer_id:userId.substring(0, userId.indexOf('#')),
        shipTo_id: userId.slice(userId.length - 3),
        sms_source: "SMS-MITCHELL1",
        invoice_amount: null,
        invoice_number: null,
        user_email: "user_mail@gmail.com",
      };
      changeMitchellUserPassword(userObj, dispatch);

    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    let { formObject, validity } = this.state;

    let { dispatch } = this.props;

    const formValue = value.charAt(0);
    let str;
    if (formValue === " ") {
      str = value.trim();
    } else {
      str = value;
      if (name === "confirmEmail") {
        validity.isValidConfirmEmail = _isEqual(formObject.email, str);
        validity = validateOnlineIdForm(name, str, validity);
      } else {
        validity = validateUserRegForm(name, str, validity);
      }
    }
    formObject[name] = name === "xlpAccNo" ? str.toUpperCase() : str;

    if (name === "xlpAccNo") {
      this.changeShipTo(e);
    }

    this.setState({
      formObject: {
        ...this.state.formObject,
        ...formObject,
      },
      validity: {
        ...this.state.validity,
        ...validity,
      },
      ...this.state,
    });
  }

  handleInputBlur() {
    let { validity } = this.state;
    validity = this.intializeValidations();
    this.setState({
      validity: {
        ...validity,
      },
    });
  }

  changeShipTo = (e) => {
    const { name, value } = e.target;
    const formValue = value.charAt(0);
    let str;
    if (formValue === " ") {
      str = value.trim();
    } else {
      str = value;
    }
    if (str.length >= 4 && name === "xlpAccNo") {
      const payload = {
        billtoid: str,
      };
      this.props.dispatch(fetchShipToIds(payload)).then((action) => {
        let shipToData = action.payload;
        console.log("shipToData", shipToData);
        this.setState({ options: [] });
        this.setState({ shipTo: [] });
        !_isEmpty(shipToData) &&
          shipToData.map((data) => {
            this.state.options.push({
              value: data.value,
              label: data.label,
            });
          });
      });
      this.setState(this.state.options);
    }
  };

  handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  handleShowOrHide() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleShowOrHideNewPassword() {
    this.setState({ shownewPassword: !this.state.shownewPassword });
  }

  handleKeyDown = (event, name) => {
    const {
      formObject: { xlpAccNo, password, userId },
    } = this.state;
    switch (name) {
      case "xlpAccNo":
        if (
          (noSpecialCharsRegExp(event.key) ||
            event.keyCode === 32 ||
            (xlpAccNo && xlpAccNo.length >= 10)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "userId":
        if (
          (event.keyCode === 32 || (userId && userId.length >= 10)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  onChangeValue(event) {
    this.setState({
      selectedOptions: event.target.value,
    });
  }
  onChangeSelectValue = (shipTo) => {
    let { formObject, validity } = this.state;
    validity.isShipToNotNull = !_isEmpty(shipTo);
    this.setState({ shipTo });
    console.log(`Option selected:`, shipTo.value);
  };

  render() {
    const {
      validity,
      formObject: { xlpAccNo, userId, password, newPassword },
      options,
      selectedOptions,
      shipTo,
    } = this.state;
    const {
      userDetails: { userType },
    } = this.props;

    return (
      <div className="manage-users-containers">
        {userType === "IT_ADMIN" && (
          <Fragment>
            <div className="label-input-container">
              <label htmlFor="">Select </label>
              <div className="create-radioM">
                <div className="radio-wrapperM">
                  <label class="customized-radio-button-createUser-mitc">
                    <input
                      type="radio"
                      name="viewType"
                      value="Mitchell User"
                      id="form-3"
                      checked={this.state.selectedOptions === "Mitchell User"}
                      onChange={this.onChangeValue}
                    />
                    <span>Mitchell User</span>
                  </label>
                  <label class="customized-radio-button-createUser-mitc">
                    <input
                      type="radio"
                      name="viewType"
                      value="Password Reset"
                      onChange={this.onChangeValue}

                      id="form-4"
                    />
                    <span>Password Reset</span>
                  </label>
                </div>
              </div>
            </div>
            {selectedOptions == "Mitchell User" &&
              selectedOptions != "Password Reset" ? (
              <div>
                <div className="label-input-container">
                  <label htmlFor="xlpAccNo">
                    Your TPH Customer Number
                  </label>
                  <div>
                    <Form.Control
                      type="input"
                      onChange={this.handleInputChange}
                      onKeyDown={(event) =>
                        this.handleKeyDown(event, "xlpAccNo")
                      }
                      name="xlpAccNo"
                      maxLength="16"
                      value={xlpAccNo}
                      placeholder="Enter TPH Customer Number"
                    />

                    <FormValidationErrorMessage
                      condition={!validity.isXlpAccNoNotNull}
                      errorMessage="Please enter your Customer number"
                    />
                    <FormValidationErrorMessage
                      condition={
                        !validity.isXlpAccNoMinLength &&
                        validity.isXlpAccNoNotNull
                      }
                      errorMessage="Customer number should be atleast 1 characters"
                    />
                    <FormValidationErrorMessage
                      condition={
                        !validity.isXlpAccNoAlphanumeric &&
                        validity.isXlpAccNoNotNull
                      }
                      errorMessage="Customer number must be alphanumeric characters only"
                    />
                    <FormValidationErrorMessage
                      condition={
                        !validity.isXlpAccNoLengthBelowLimit &&
                        validity.isXlpAccNoNotNull
                      }
                      errorMessage="Customer number should not exceed 10 characters"
                    />
                  </div>
                </div>
                <div className="label-dropdown-container">
                  <Form.Label>Select a ShipTo</Form.Label>
                  <div>
                    <Select
                      options={options}
                      name="ShipTo"
                      value={shipTo}
                      className="custom-dropdown"
                      placeholder="Select a ShipTo address"
                      onChange={this.onChangeSelectValue}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#FFC0C2",
                          primary50: "#aa0d10",
                          primary: "#aa0d10",
                        },
                      })}
                    />
                    <FormValidationErrorMessage
                      condition={!validity.isShipToNotNull}
                      errorMessage="Please select the shipTo Number"
                    />
                  </div>
                </div>

                <div className="label-input-container">
                  <label htmlFor="password">Password</label>
                  <div className="password_container">
                    <Form.Control
                      size="sm"
                      type={this.state.showPassword ? "text" : "showPassword"}
                      name="password"
                      id="password"
                      value={password}
                      onChange={this.handleInputChange}
                      placeholder="Enter Your Password"

                    />
                    {this.state.showPassword ? (
                      <i
                        class="fa fa-eye-slash eyeIcon"
                        onClick={() => {
                          this.handleShowOrHide();
                        }}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        class="fa fa-eye eyeIcon"
                        onClick={() => {
                          this.handleShowOrHide();
                        }}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                  <FormValidationErrorMessage
                    condition={!validity.isPasswordNotNull}
                    errorMessage="Please enter your Password"
                  />
                </div>
              </div>
            ) : (
              <span></span>
            )}

            {(selectedOptions != "Mitchell User" &&
              selectedOptions == "Password Reset") ?
              <div>
                <div className="label-input-container">
                  <label htmlFor="userId">Account#</label>
                  <div>
                    <Form.Control
                      type="text"
                      onChange={this.handleInputChange}
                      onKeyDown={(event) => this.handleKeyDown(event, "userId")}
                      name="userId"
                      maxLength="16"
                      value={userId}
                      placeholder="Enter the Account#"
                    />
                    <FormValidationErrorMessage
                      condition={!validity.isuserIdNoNotNull}
                      errorMessage="Please enter your Account# as billTo#shipTo"
                    />

                    <FormValidationErrorMessage
                      condition={
                        !validity.isuserIdNoMinLength &&
                        validity.isuserIdNoNotNull &&
                        validity.isuserIdNoAlphanumeric
                      }
                      errorMessage="Account# should be billTo#shipTo"
                    />
                  </div>
                </div>

                <div className="label-input-container">
                  <label htmlFor="newPassword">New Password</label>
                  <div className="password_container">
                    <Form.Control
                      size="sm"
                      type={this.state.shownewPassword ? "text" : "shownewPassword"}
                      name="newPassword"
                      id="newPassword"
                      value={newPassword}
                      onChange={this.handleInputChange}
                      placeholder="Enter Your NewPassword"

                    />
                    {this.state.shownewPassword ? (
                      <i
                        class="fa fa-eye-slash eyeIcon"
                        onClick={() => {
                          this.handleShowOrHideNewPassword();
                        }}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        class="fa fa-eye eyeIcon"
                        onClick={() => {
                          this.handleShowOrHideNewPassword();
                        }}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                  <FormValidationErrorMessage
                    condition={!validity.isPasswordNotNull}
                    errorMessage="Please enter your Password"
                  />
                </div>
              </div>
              : <span></span>
            }

            {(selectedOptions == "Mitchell User" &&
              selectedOptions != "Password Reset") ?
              ((!this.props.userCreationApiCall) ? (
                <Button
                  type="submit"
                  className="user-management-submit-btn"
                  disabled={
                    this.props.userCreationApiCall ||
                    !_every(validity) ||
                    _isEmpty(shipTo) ||
                    _isEmpty(xlpAccNo) ||
                    _isEmpty(password)
                  }
                  onClick={this.handleSubmit}
                >
                  Ok
                </Button>
              ) : (
                <div className="custom_spinner_container">
                  <Spinner className="custom_spinner" animation="border" />
                </div>
              )
              ) : (!this.props.passwordCreationApiCall) ? (
                <Button
                  type="submit"
                  className="user-management-submit-btn"
                  disabled={
                    this.props.passwordCreationApiCall ||
                    _isEmpty(userId) ||
                    _isEmpty(newPassword)
                  }
                  onClick={this.handleSubmit}
                >
                  Ok
                </Button>
              ) : (
                <div className="custom_spinner_container">
                  <Spinner className="custom_spinner" animation="border" />
                </div>

              )}
          </Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userCreationSuccess: state.user.userCreationSuccess,
    userCreationApiCall: state.user.userCreationApiCall,
    passwordCreationApiCall: state.user.passwordCreationApiCall,
    userDetails: state.user.userDetails,
    messageFromAPi: state.user.messageFromAPi,
    ShipToIds: state.ShipToIds.shipToIds,
    userCreation: state.user.userCreationSuccess,
    passwordCreation: state.user.passwordCreationSuccess,
    messageFromApi: state.user.messageFromAPi,
  };
}
export default connect(mapStateToProps)(MitchellUsers);
