import {
	payerDetails,
	digest,
	doTransaction,
	fetchTransaction,
	fetchInvoices,
	uploadInvoiceSheet,
  deletePaymentCard
} from './payments.thunk';
import _ from 'lodash';
const { createSlice } = require('@reduxjs/toolkit');

let initialState = {
	loading: false,
	digest: null,
	digestError: null,
	payerDetails: [],
	fetchTransactionSuccess: null,
	invoices: [],
	unMatchedInvoices: [],
	totalUploadedInvoices: null,
  cardDeleted: false,
};

const payments = createSlice({
	name: 'payments',
	initialState,
	reducers: {
		resetDigest: (state) => {
			state.digestError = null;
			state.digest = null;
      state.cardDeleted = false;
		},
		resetFetchTransaction: (state) => {
			state.fetchTransactionSuccess = null;
		},
		changeOverride: (state, action) => {
			const invoices = state.invoices;
			const { row, value } = action.payload;

			const _invoices = invoices.map((invoice) => {
				if (value === 'amountDue' && invoice.invoiceNumber === row.invoiceNumber) {
					invoice.considerUploadedAmount = false;
				} else if (value === 'uploadedAmount' && invoice.invoiceNumber === row.invoiceNumber) {
					invoice.considerUploadedAmount = true;
				}

				return invoice;
			});

			state.invoices = _invoices;
		},
		changeInvoicesToAmountDue: (state) => {
			const _invoices = state.invoices.map((invoice) => {
				if (invoice.isMatched) {
					invoice.considerUploadedAmount = false;
				}

				return invoice;
			});

			state.invoices = _invoices;
		},
		closeMatched: (state) => {
			state.unMatchedInvoices = [];
		}
	},
	extraReducers: {
		[payerDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[payerDetails.fulfilled]: (state, action) => {
			let payload = action.payload;
			payload = _.sortBy(payload, [ '-paymentMethodType' ]);
			state.payerDetails = payload || [];

      state.loading = false;
		},
		[payerDetails.rejected]: (state, action) => {
			state.loading = false;
		},
		[digest.pending]: (state, action) => {
			state.loading = true;
		},
		[digest.fulfilled]: (state, action) => {
			state.loading = false;
			state.digest = action.payload;
		},
		[digest.rejected]: (state, action) => {
			state.digestError = action.payload;
			state.loading = false;
		},
		[doTransaction.pending]: (state, action) => {
			state.loading = true;
		},
		[doTransaction.fulfilled]: (state, action) => {
			state.loading = false;
		},
		[doTransaction.rejected]: (state, action) => {
			state.loading = false;
		},
		[fetchTransaction.pending]: (state, action) => {
			state.digest = null;
			state.loading = true;
		},
		[fetchTransaction.fulfilled]: (state, action) => {
			state.fetchTransactionSuccess = action.payload;
			state.loading = false;
		},
		[fetchTransaction.rejected]: (state, action) => {
			state.fetchTransactionSuccess = null;
			state.loading = false;
		},
		[fetchInvoices.pending]: (state, action) => {
			state.invoices = [];
			state.loading = true;
		},
		[fetchInvoices.fulfilled]: (state, action) => {
			const data = action.payload;
			if (data && data.length === 1) {
				const d = data[0];
				if (d && d.error && (d.error === 'No result' || !d.error || d.error === 'Read timed out')) {
					state.invoices = [];
				} else {
					state.invoices = data;
				}
			} else {
				state.invoices = data;
			}

			state.invoices = state.invoices.map((i) => {
				return { ...i, error: false, uploadedAmount: i.invoiceAmountDue };
			});

			state.loading = false;
		},
		[fetchInvoices.rejected]: (state, action) => {
			state.invoices = [];
			state.loading = false;
		},

		[uploadInvoiceSheet.pending]: (state, action) => {
			state.loading = true;
		},
		[uploadInvoiceSheet.fulfilled]: (state, action) => {
			let data = action.payload.invoiceResponse || [];

			// Filter out the in Progress false items.
			data = data.filter((d) => !d.inProgress);

			const resp = action.payload.unMatchedRecords || [];
			let unmatchedFound = _.filter(resp, (i) => i !== '');
			if (unmatchedFound.length > 0) {
				state.unMatchedInvoices = unmatchedFound;
			} else {
				state.unMatchedInvoices = [];
			}

			state.totalUploadedInvoices = action.payload.uploadedCount;
			if (data && data.length === 1) {
				const d = data[0];

				if (d && d.error && d.error === 'No result') {
					state.invoices = [];
				} else {
					state.invoices = data;
				}
			} else {
				state.invoices = data;
			}

			// Add considerUploadedAmount property to matched invoices;
			const modifiedInvoices = state.invoices.map((invoice) => {
				if (invoice.isMatched && Number(invoice.uploadedAmount) < Number(invoice.invoiceAmountDue)) {
					invoice.considerUploadedAmount = true;
				} else {
					invoice.considerUploadedAmount = false;
				}

				return invoice;
			});

			state.invoices = _.sortBy(modifiedInvoices, [ 'isMatched' ]);
			state.loading = false;
		},
		[uploadInvoiceSheet.rejected]: (state, action) => {
			state.loading = false;
		},
    [deletePaymentCard.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePaymentCard.fulfilled]: (state, action) => {
      state.cardDeleted = true;
      state.loading = false;
    },
    [deletePaymentCard.rejected]: (state, action) => {
      state.loading = false;
    }
	}
});

const { actions, reducer } = payments;

export const { resetDigest, resetFetchTransaction, changeOverride, closeMatched, changeInvoicesToAmountDue } = actions;

export default reducer;
