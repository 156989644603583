import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Nav, Form, Tab, Image } from "react-bootstrap";
import YmmeSearch from "./Ymme";
import YmmeDropDowns from "../../YmmeDropDowns";
import VinSearch from "./Vin";
import LicensePlateSearch from "./LicensePlate";
import GlobalSearch from "./GlobalSearch";
import VehicleBreadCrumb from "../../shared/VehicleBreadCrumb";
import isEmpty from "lodash/isEmpty";
import { handleDataAndCallApi } from "../../shared/Promotions";
import { viewSelectedJob } from "../../shared/MyGarage/Jobs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURLCookieDetails } from "../../../helpers/utils";

function SearchWrapper(props) {
  const {
    clearLists,
    handleInputOnBlur,
    handleInputOnChange,
    yearList,
    onSelectChange,
    makeList,
    modelList,
    engineList,
    selectedVehicle,
    vehicleSelectionDone,
    validity,
    stateValidity,
    selectedState,
    makeListRef,
    yearListRef,
    modelListRef,
    engineListRef,
    selectedPromotion,
    categoryGroupMap,
    myGarageSelectedVehicle,
    showYmmeOnly,
    isExpress,
    defaultActiveKey,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedJob = useSelector((state) => state.mygarage.selectedJob);
  const promotionStatus = useSelector(
    (state) => state.parts.promotionEventStatus
  );
  const JobEventStatus = useSelector((state) => state.mygarage.JobEventStatus);
  const proPacksData = useSelector((state) => state.user.proPacksData);
  const chemCatMap = useSelector((state) => state.parts.categorySuppliesMap);
  const [image_url, setimage_url] = useState("");
  const [androidQRcodeURL, setAndroidQRcodeURL] = useState("");
  const [iphoneQRcodeURL, setIphoneQRcodeURL] = useState("");
  const [leftPhoneURL, setLeftPhoneURL] = useState("");
  const [rightPhoneURL, setRightPhoneURL] = useState("");
  const [promoRedirect, setPromoRedirect] = useState(false);
  const [searchVehiclecontainerClassName, setSearchVehiclecontainerClassName] =
    useState(
      !isExpress ? "pt-4 pb-5 searchVehicle" : "pt-4 pb-0 searchVehicle"
    );

  useEffect(() => {
    if (
      vehicleSelectionDone &&
      !isEmpty(selectedPromotion) &&
      promotionStatus &&
      !promoRedirect
    ) {
      handleDataAndCallApi(
        selectedPromotion,
        categoryGroupMap,
        chemCatMap,
        proPacksData,
        myGarageSelectedVehicle,
        history,
        dispatch
      );
      setPromoRedirect(true);
    } else if (
      vehicleSelectionDone &&
      !isEmpty(selectedJob) &&
      JobEventStatus
    ) {
      viewSelectedJob(selectedJob, myGarageSelectedVehicle, history, dispatch);
    }
    setTimeout(() => {
      const banner_image_url = `${
        process.env.REACT_APP_IMAGE_S3_BUCKET_URL
      }/${"Banner/banner.png"}?${getImageURLCookieDetails()}`;
      setimage_url(banner_image_url);

      const android_qr_code = `${
        process.env.REACT_APP_IMAGE_S3_BUCKET_URL
      }/${"Banner/qr-android.png"}?${getImageURLCookieDetails()}`;
      setAndroidQRcodeURL(android_qr_code);
      const iphone_qr_code = `${
        process.env.REACT_APP_IMAGE_S3_BUCKET_URL
      }/${"Banner/qr-iOS.png"}?${getImageURLCookieDetails()}`;
      setIphoneQRcodeURL(iphone_qr_code);

      const left_phone = `${
        process.env.REACT_APP_IMAGE_S3_BUCKET_URL
      }/${"Banner/leftPhone.png"}?${getImageURLCookieDetails()}`;
      setLeftPhoneURL(left_phone);
      const right_phone = `${
        process.env.REACT_APP_IMAGE_S3_BUCKET_URL
      }/${"Banner/rightPhone.png"}?${getImageURLCookieDetails()}`;
      setRightPhoneURL(right_phone);
    }, 1000);
  }, [
    vehicleSelectionDone,
    selectedPromotion,
    promotionStatus,
    promoRedirect,
    selectedJob,
    JobEventStatus,
    categoryGroupMap,
    chemCatMap,
    proPacksData,
    myGarageSelectedVehicle,
    history,
    dispatch,
  ]);

  return (
    <React.Fragment>
      {vehicleSelectionDone ? (
        <VehicleBreadCrumb clearLists={clearLists} />
      ) : (
        <div
          className="banner-size-container"
          style={{
            backgroundImage: `url(${image_url})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            maxHeight: `${showYmmeOnly ? "none" : "220px"}`,
            position: `${showYmmeOnly ? "fixed" : "relative"}`,
            width: `${showYmmeOnly ? "100%" : "auto"}`,
            height: `${showYmmeOnly ? "100%" : "auto"}`,
            marginTop: `${showYmmeOnly ? "0px" : "0px"}`,
          }}
        >
          {/* {!showYmmeOnly ? (<div class="qr-wrapper left-qr" style={{ backgroundImage: `url(${leftPhoneURL})`, backgroundRepeat: 'no-repeat' }}>
              <div class="qr-message">
                RAPID Mobile is now available on <span className="mobile-banner-bold-text">Android</span> devices. Scan below to install on <span className="mobile-banner-bold-text">Samsung</span> or <span className="mobile-banner-bold-text">Google</span> devices.              </div>
              <div class="qr-image">
                <img src={androidQRcodeURL} alt="qr code to android store"/>
              </div>
            </div>) : (null)} */}
          <Container className={searchVehiclecontainerClassName}>
            {!isExpress && (
              <h2 className={"text-center heading-white"}>
                SELECT YOUR VEHICLE
              </h2>
            )}
            <div className="main-tabs">
              <Tab.Container defaultActiveKey={defaultActiveKey}>
                <Row
                  className={"justify-content-center"}
                  style={{ marginTop: "-10px" }}
                >
                  <Col sm={8} className="search-wrapper__main">
                    <Tab.Content>
                      {/* <YmmeSearch
                        yearList={yearList}
                        makeList={makeList}
                        modelList={modelList}
                        engineList={engineList}
                        onSelectChange={onSelectChange}
                        selectedVehicle={selectedVehicle}
                        validity={validity}
                        makeListRef={makeListRef}
                        yearListRef={yearListRef}
                        modelListRef={modelListRef}
                        engineListRef={engineListRef}
                      /> */}

                      {!isExpress && (
                        <YmmeDropDowns
                          onSelectChange={onSelectChange}
                          selectedVehicle={selectedVehicle}
                          showYmmeOnly={showYmmeOnly}
                        />
                      )}

                      <VinSearch
                        handleInputOnBlur={handleInputOnBlur}
                        handleInputOnChange={handleInputOnChange}
                      />
                      <LicensePlateSearch
                        onSelectChange={onSelectChange}
                        handleInputOnBlur={handleInputOnBlur}
                        selectedState={selectedState}
                        handleInputOnChange={handleInputOnChange}
                        stateValidity={stateValidity}
                      />
                    </Tab.Content>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Nav
                      className={"justify-content-center main-tab__control"}
                      style={{ marginTop: "-10px" }}
                      defaultActiveKey={defaultActiveKey}
                    >
                      <Fragment>
                        {!isExpress && (
                          <Nav.Item>
                            <Nav.Link eventKey="YMMET">Search By YMME</Nav.Link>
                          </Nav.Item>
                        )}
                        <Nav.Item>
                          <Nav.Link eventKey="VIN">Search By VIN</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="LicensePlate">
                            License Plate
                          </Nav.Link>
                        </Nav.Item>
                      </Fragment>
                    </Nav>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
            {!showYmmeOnly && !isExpress ? (
              <GlobalSearch dropdownValue="All" />
            ) : null}
          </Container>
        </div>
      )}
    </React.Fragment>
  );
}
export default SearchWrapper;
