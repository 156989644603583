import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';

function EmailVerified() {
	return (
		<Container fluid className="login_container">
			<Row className="login_title">
				<span>Let’s Get Started!</span>
			</Row>
			<Row className="login_box">
				<span>Email Address verified</span>
				<div>
					<p>
						{' '}
						<Link to="/login" style={{ cursor: 'pointer' }}>
							Click here
						</Link>{' '}
						to login
					</p>
				</div>
			</Row>
		</Container>
	);
}
export default EmailVerified;
