import React from "react";
import T from "prop-types";
import Select, { components } from "react-select";

import "./styles.scss";
import { Checkbox } from "ui-library";

const getlabelIcon = (value) => {
  let icon = "";
  switch (value) {
    case "datefilters":
      icon = <span className="material-icons">date_range</span>;
      break;
    case "selected":
    case "ordered":
      icon = <span className="material-icons">check_circle</span>;
      break;
    case "favorite":
      icon = <span className="material-icons">grade</span>;
      break;
    default:
      break;
  }

  return icon;
};

const formatOptionLabel = ({ value, label }) => (
  <div
    style={{
      display: "flex",
      widt: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <div className="bold">{label}</div>
    <div
      className="icon"
      style={{
        color: "#a30d0f",
        border: "1px solid #a30d0f",
        padding: "5px 5px 0",
      }}
    >
      {getlabelIcon(value)}
    </div>
  </div>
);

// const ControlComponent = (props) => (
// 	<div>
// 		{console.log('control component option ==> ', props)}
// 		<components.Control {...props} className={props.isMulti ? '' : 'xselect-control'} />
// 	</div>
// );

const XSelect = (props) => {
  const { customOption, isMulti, xcomponents } = props;
  const CustomOption = ({ innerProps, isDisabled, label, ...other }) =>
    customOption && isMulti ? (
      <components.Option className="custom-option" {...innerProps}>
        <Checkbox label={label} /> {console.log("others ==> ", other)}
      </components.Option>
    ) : (
      <div>{label}</div>
    );

  const CheckboxOption = (checkProps) => {
    const { innerProps, isDisabled, label, isSelected, ...other } = checkProps;
    return (
      <components.Option className="custom-option" {...checkProps}>
        <Checkbox label={label} checked={isSelected} />
      </components.Option>
    );
  };

  const ControlComponent = (controlProps) => {
    const { innerProps, isDisabled, label, ...other } = controlProps;
    return (
      <div>
        <components.Control
          {...controlProps}
          className={props.isMulti ? "" : "xselect-control"}
        />
      </div>
    );
  };

  const SelectComponents =
    customOption && isMulti
      ? { Option: CheckboxOption }
      : { Control: ControlComponent };

  if (xcomponents) {
    Object.assign(SelectComponents, xcomponents);
  }

  const { options, ...rest } = props;
  return (
    <React.Fragment>
      <div className="xselect">
        <Select
          classNamePrefix="xlp-xselect"
          options={options}
          components={SelectComponents}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#84bae2",
              primary50: "#0F5283",
              primary: "#0F5283",
            },
          })}
          {...rest}
        />
      </div>
    </React.Fragment>
  );
};

XSelect.propTypes = {
  options: T.object,
};

export default XSelect;
