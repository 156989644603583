import React from "react";
import T from "prop-types";
import { TextField } from "@mui/material";

function Textbox(props) {
  const { label, ...rest } = props;
  return <TextField label={label} {...rest} />;
}

Textbox.propTypes = {
  label: T.string,
};

export default Textbox;
