export const getSearchMatchScores = (results, searchTermOrder, matches, matchesLength) => {
  for (var j = 0; j < results.length; j++) {
    var resultTerm = results[j].name;
    if (matches[resultTerm]) {
      matches[resultTerm].matchCount += searchTermOrder; //earlier words receive the higher order
      if (matches[resultTerm].typeIdList.indexOf(results[j].id) < 0) {
        matches[resultTerm].typeIdList.push(results[j].id);
      }
      if (matches[resultTerm].groupNumberList.indexOf(results[j].groupNum) < 0) {
        matches[resultTerm].groupNumberList.push(results[j].groupNum);
      }
    } else {
      matchesLength++;
      matches[resultTerm] = {
        typeIdList: [results[j].id],
        groupNumberList: [results[j].groupNum],
        term: resultTerm,
        matchCount: searchTermOrder, //earlier words receive the higher order
        score: null
      }
    }
  }

  return { matches, matchesLength }
}


export const getMatches = (matches, terms) => {
  // const matches = _.cloneDeep(_matches);
  for (var matchTerm in matches) {
    var index = 0;
    matches[matchTerm].score = matches[matchTerm].matchCount + (matchTerm.toUpperCase().split(/\s/).reverse())
      // eslint-disable-next-line no-loop-func
      .map(function (e) {
        var termIndex = terms.indexOf(e), score;
        if (terms.indexOf(e) < 0){
          score = 0;
        } else {
          score = (termIndex + 1) * ++index;
        }

        return score;
      })
      .reduce((total, e) => total + e, 0);
  }

  let _finalResult = Object.entries(matches).sort((a,b) => b[1].score - a[1].score);

  const finalResult = _finalResult.map(f => f[1]);

  const searchResults = {likelyMatches: finalResult.splice(0, 5), allMatches: finalResult}
  return searchResults;
}

export const SEARCH_API = "/catalog/part/partTypesByVehicles?";
export const SEARCH_API_WORD = "/catalog/part/partTypesByWord?";

export const DEBOUNCE_RATE = 100;
