import React from "react";
import { Container } from "react-bootstrap";
import CategoryCarousel from "./CategoryCarousel";
import { connect } from "react-redux";

function CategoryWrapper(props) {
  const renderCategories = () => {
    let { categoryMap, selectedGroupDetails, partTypeAPICall,PayloadData, PartTypePayloadData } = props;
    let categoryRows = [];
    if (categoryMap && categoryMap.length) {
      categoryMap.forEach((category, index) => {
        categoryRows.push(
          <CategoryCarousel
            titleType='homecategory'
            category={category}
            subCategories={category.groups}
            partTypeAPICall ={partTypeAPICall}
            PayloadData  ={PayloadData }
            PartTypePayloadData ={PartTypePayloadData}
            // selectedCategoryId={category.code}
            index={index}
            selectedGroupDetails={selectedGroupDetails}
            handleClick={props.handleClick}
          // selectedGroupCode={selectedGroupDetails.code}
          />
        );
      });
    }
    return categoryRows;
  };
  return (
    <section className="section__products">
      <Container fluid className="pl-5 pr-5">{renderCategories()}</Container>
    </section>
  );
}
function mapStateToProps(state) {
  return {
    categoryMap: state.parts.categoryGroupMap,
    categoryList: state.parts.categoryList,
  };
}
export default connect(mapStateToProps)(CategoryWrapper);
