import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getSiteNotification
} from './sitenotification.service';

export const fetchsiteNotification = createAsyncThunk(
    "fetchsiteNotification",
    async () => {
        const response = await getSiteNotification();
        return response.data;
    }
);