import React, { Fragment } from 'react';
import {
  Form,
  Button,
  Spinner,
  InputGroup,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import {
  validateUserRegForm,
  populateUserDropDownOptions,
  numbersOnlyRegExp,
  alphaCharRegExp,
  validateOnlineIdForm,
  noSpecialCharsRegExp
} from '../../../helpers/utils';
import {
  getAllStoreNumber
} from "../../../components/shared/MyGarage/mygarage.thunks";
import {
  fetchShipToIds
} from "../../shared/ShipTo/shipTo.thunk";
import { createUser } from '../../../actions/user';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';

import _every from 'lodash/every';
import Select from 'react-select';

import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';



class CustomerAdmin extends React.Component {
  constructor(props) {
    super(props);
    let shipToList = !_isEmpty(props.ShipToIds)
      ? populateUserDropDownOptions(props.ShipToIds)
      : [];
    let shipToIds = [];
    let locationsSelected = [];
    if (shipToList.length === 1) {
      locationsSelected = shipToList[0].value;
      shipToIds = [shipToList[0].id];
    }
    this.state = {
      formObject: {
        customerNumber: !_isEmpty(props.userDetails)
          ? props.userDetails.customerCode
          : '',
        firstName: '',
        lastName: '',
        title: '',
        // xlpAccNo: !_isEmpty(props.userDetails)
        // 	? props.userDetails.billTo.billToId
        // 	: '',
        companyName: '',
        xlpLastInvoiceNo: '',
        xlpLastInvoiceAmount: '',
        address: '',
        email: '',
        confirmEmail :'',
        phoneNo: '',
        mobileNo: '',
        comments: '',
        locationsSelected,
        userType: '',
        shipToIds,
        invoiceCheck: false,
        xlpAccNo: '',
      },
      shipToList,
      validity: this.intializeValidations(),
      userCreation: '',
      popUpMessage: '',
      countryCode: '+1',
      defaultShipToSelected: '',
      userUpdated: false,
      storeNumber:[],
      selectedOption:'Customer Admin',
      options:[],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectBlur = this.handleSelectBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleDefaultSelect = this.handleDefaultSelect.bind(this);
    this.handleMultiSelectBlur = this.handleMultiSelectBlur.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);

  }



  onChangeSelectValue = storeNumber => {
    this.setState({ storeNumber});
    console.log(`Option selected:`, storeNumber.value);
  };

  intializeValidations() {
    this.validity = {
      isFirstNameNotNull: true,
      isFirstnameLengthBelowLimit: true,
      isFirstnameMinLength: true,
      isFirstNameAlphabetic: true,
      isFirstNameValid: true,
      isLastNameNotNull: true,
      isLastnameLengthBelowLimit: true,
      isLastnameMinLength: true,
      isLastNameAlphabetic: true,
      isLastNameValid: true,
      isEmailIdNotNull: true,
      isEmailIdLengthBelowLimit: true,
      isEmailValidFormat: true,
      isEmailIdMinLength: true,
      isValidEmail: true,
      isValidConfirmEmail :true,
			isConfirmEmailIdNotNull :true,
      isMobileNoNotNull: true,
      isMobileNoLengthBelowLimit: true,
      isMobileNoMinLength: true,
      isMobileNoNumeric: true,
      isValidMobileNo: true,
      isLocationNotNull: true,
      isUserTypeNotNull: true,

      isXlpAccNoNotNull: true,
      isXlpAccNoAlphanumeric: true,
      isXlpAccNoLengthBelowLimit: true,
      isXlpAccNoMinLength: true,
      isValidXlpAccNo: true,
    };
    return this.validity;
  }

  componentDidMount() {
    const payloadstore = {
      storeNumber: this.props.userDetails && this.props.userDetails.shipTos[0] &&
      this.props.userDetails.shipTos[0].location && this.props.userDetails.shipTos[0].location.storeNumber
    };

    const {
      userDetails: {
        billTo: {
          billToId,
        },
      },
    } = this.props;
    const payload = {
      billtoid: billToId,
    };
    fetchShipToIds(payload);
    this.props.dispatch(getAllStoreNumber()).then((action) => {
      let storeData = action.payload ;
      storeData.map((store)=>{
       this.state.options.push({
         value: store.fullStoreNumber,
          label: store.storeNum + " - " + store.storeName,
       });
      })
      });
  }

  componentDidUpdate(prevProps) {
    let { shipToList, formObject, userCreation, popUpMessage, userUpdated } = this.state;
    let stateChanged = false;


    if (!_isEqual(this.props, prevProps)) {
      if (!_isEqual(this.props.ShipToIds, prevProps.ShipToIds)) {
        shipToList = populateUserDropDownOptions(
          this.props.ShipToIds
        );
        if (!_isEmpty(shipToList)&&shipToList.length === 1) {
          formObject.locationsSelected = shipToList[0].value;
          formObject.shipToIds = [shipToList[0].id];
        }
        stateChanged = true;
      }
      if (!_isEqual(this.props.userDetails, prevProps.userDetails)) {
        formObject.companyName = this.props.userDetails.companyName;
        formObject.xlpAccNo = this.props.userDetails.billTo.billToId;
        formObject.customerNumber = this.props.userDetails.customerCode;
        stateChanged = true;
      }

      if (
        !_isEqual(this.props.messageFromAPi, prevProps.messageFromAPi)
      ) {
        userCreation = this.props.userCreationSuccess;
        popUpMessage = this.props.messageFromAPi;
        window.scrollTo(0, 0);
        if (userCreation === "success") {
          formObject.firstName = '';
          formObject.lastName = '';
          formObject.email = '';
          formObject.confirmEmail = '';
          formObject.mobileNo = '';
          formObject.xlpAccNo = '';
          this.state.storeNumber = [];
          userUpdated = false;

          this.setState({
            ...this.state,
            formObject,

          });

        }
      }



      if (stateChanged) {
        this.setState({
          ...this.state,
          shipToList,
          userCreation,
          popUpMessage,
          userUpdated,
        })
      }
    }
  }

  validateForm() {
    let {
      formObject: {
        firstName,
        lastName,
        title,
        email,
        confirmEmail,
        phoneNo,
        mobileNo,
        userType,
        shipToIds,
        xlpAccNo,
        locationsSelected,
      },
      validity,
    } = this.state;
    validity = validateUserRegForm('firstName', firstName, validity);
    validity = validateUserRegForm('lastName', lastName, validity);
    validity = validateUserRegForm('xlpAccNo', xlpAccNo, validity);
    validity = validateUserRegForm('email', email, validity);
    validity = validateUserRegForm('phoneNo', phoneNo, validity);
    validity = validateUserRegForm('mobileNo', mobileNo, validity);
    validity = validateUserRegForm('locationsSelected', locationsSelected, validity);
    validity = validateUserRegForm('userType', userType, validity);
  //  validity = validateUserRegForm('storeNumber', storeNumber, validity);
    validity = validateUserRegForm('locationsSelected', locationsSelected, validity);
    this.setState({ ...this.state, validity });
    return _every(validity);
  }

  handleSubmit(e) {
    e.preventDefault();
    let {
      formObject: {
        customerNumber,
        firstName,
        lastName,
        title,
        xlpAccNo,
        companyName,
        xlpLastInvoiceNo,
        xlpLastInvoiceAmount,
        address,
        email,
        confirmEmail,
        phoneNo,
        mobileNo,
        userType,
        shipToIds,
        invoiceCheck,
      },
      storeNumber,
      selectedOption,
      countryCode,
      defaultShipToSelected,
    } = this.state;
    const {
      userDetails,
    } = this.props;
    const UserPayload = {
      customerId: userDetails.billTo.billToId,
    };
    let { dispatch } = this.props;
    let updatedShipToObj = {};
    if (!_isEmpty(shipToIds)) {
      if (shipToIds.length > 1) {
        shipToIds.map((id) => {
          updatedShipToObj[id.toString()] = (id === defaultShipToSelected);
        });
      } else {
        updatedShipToObj[shipToIds[0]] = true;
      }
    }
    if (true && selectedOption == 'Customer Admin') {
      let userObj = {
        address: address,
        companyName: companyName,
        customerNumber: customerNumber,
        email: email,
        firstName: firstName,
        invoiceAmount: xlpLastInvoiceAmount,
        invoiceNumber: xlpLastInvoiceNo,
        invoiceCheck: false,
        lastName: lastName,
        phone: `+${phoneNo}`,
        mobileNumber: `${countryCode}${mobileNo}`,
        shipToIds: updatedShipToObj,
        title,
        userType: 'CUSTOMER_ADMIN',
        xlPartAccountNumber: xlpAccNo,
        comment: '',
      };
      createUser(userObj, dispatch)
    }else
    if (true && selectedOption == 'Store User') {
      let userObj = {
        address: address,
        companyName: companyName,
        customerNumber: customerNumber,
        email: email,
        firstName: firstName,
        storeNumber:storeNumber.value,
        invoiceAmount: xlpLastInvoiceAmount,
        invoiceNumber: xlpLastInvoiceNo,
        invoiceCheck: false,
        lastName: lastName,
        phone: `+${phoneNo}`,
        mobileNumber: `${countryCode}${mobileNo}`,
        shipToIds: updatedShipToObj,
        title,
        userType: 'STORE_USER',
        xlPartAccountNumber: '',
        comment: '',
      };
      createUser(userObj, dispatch)

  }
}

  handleInputChange(e, data) {
    const { name, value, label } = e.target;
    let { formObject, validity } = this.state;
    const formValue = value.charAt(0);
    let str;
    if (formValue === ' ') {
      str = value.trim();
    }
    else {
      str = value;
      if(name === "confirmEmail"){
				validity.isValidConfirmEmail = _isEqual(formObject.email, str);
				validity = validateOnlineIdForm(name, str, validity);
			}else{
        validity = validateUserRegForm(name, str, validity);
			}
    }
    formObject[name] = str;
    this.setState({
      ...this.state,
      formObject: {
        ...this.state.formObject,
        ...formObject,
      },
      validity: {
        ...this.state.validity,
        ...validity,
      },
      userUpdated: true,
    });
  }

  handleSelectChange(selectedOption, data) {
    const { name } = data;
    let { formObject, shipToList, defaultShipToSelected, validity } = this.state;
    const { value, label } = selectedOption;
    formObject[name] = value;
    if (name === 'locationsSelected') {
      formObject['shipToIds'] = [value];
      defaultShipToSelected = value;
    }
    if (name === 'userType' && (shipToList.length > 1)) {
      formObject['shipToIds'] = [];
      formObject['locationsSelected'] = [];
      defaultShipToSelected = '';
    }
    validity = validateUserRegForm(name, value, validity);
    this.setState({
      ...this.state,
      formObject,
      defaultShipToSelected,
      validity: {
        ...this.state.validity,
        ...validity,
      },
      userUpdated: true,
    });
  }

  handleSelectBlur() {
    let { formObject, validity } = this.state;
    const { userType } = formObject;
    validity = validateUserRegForm('userType', userType, validity);
    this.setState({
      ...this.state,
      formObject,
      validity: {
        ...this.state.validity,
        ...validity,
      },
    });
  }

  handleMultiSelectBlur() {
    let { formObject, validity } = this.state;
    const { userType } = formObject;
    validity = validateUserRegForm('locationsSelected', userType, validity);
    this.setState({
      ...this.state,
      formObject,
      validity: {
        ...this.state.validity,
        ...validity,
      },
    });
  }

  handleMultiSelectChange(value, { action, removedValue, name }) {
    let {
      formObject: { locationsSelected },
      defaultShipToSelected,
      validity,
    } = this.state;
    const multiValue = [];
    if (!_isEmpty(value)) {
      value.map((selectedList) => {
        multiValue.push(selectedList.value)
      });
    }
    locationsSelected = value && value.length > 0 ? multiValue : [];
    defaultShipToSelected = (locationsSelected.length === 1) ? locationsSelected[0] : '';
    validity = validateUserRegForm(name, value, validity);
    this.setState({
      ...this.state,
      formObject: {
        ...this.state.formObject,
        locationsSelected: locationsSelected,
        shipToIds: locationsSelected,
      },
      validity: {
        ...this.state.validity,
        ...validity,
      },
      defaultShipToSelected,
      userUpdated: true,
    });
  }

  handleDefaultSelect(selectedOption, data) {
    this.setState({
      defaultShipToSelected: selectedOption.value,
    });
  }


  updateCountryCode = (key, e) => {
    this.setState({
      countryCode: e.target.name,
    })
  }

  handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (code !== 8 && code !== 9 && code !== 37 && code !== 38 && code !== 39 && code !== 40 && code !== 46);
  }

  handleKeyDown = (event, name) => {
    const { formObject: {
      firstName,
      lastName,
      mobileNo,
      email,
      xlpAccNo,
    }
    } = this.state;
    switch (name) {
      case 'firstName':
        if ((alphaCharRegExp(event.key) === false || (firstName && firstName.length >= 16)) && this.handleOtherKeys(event.keyCode)) {
          event.preventDefault();
        }
        break;
      case 'lastName':
        if ((alphaCharRegExp(event.key) === false || (lastName && lastName.length >= 16)) && this.handleOtherKeys(event.keyCode)) {
          event.preventDefault();
        }
        break;
      case 'mobileNo':
        if ((numbersOnlyRegExp(event.key) === false || (event.keyCode === 32) || (mobileNo && mobileNo.length >= 10)) && this.handleOtherKeys(event.keyCode)) {
          event.preventDefault();
        }
        break;
      case 'email':
        if (((event.keyCode === 32) || (email && email.length >= 50)) && this.handleOtherKeys(event.keyCode)) {
          event.preventDefault();
        }
        break;
      case 'xlpAccNo':
        if (
          (noSpecialCharsRegExp(event.key) ||
            event.keyCode === 32 ||
            (xlpAccNo && xlpAccNo.length >= 10)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  }

  onChangeValue(event) {
    this.setState({
      selectedOption: event.target.value
    });
  }
  render() {
    const {
      validity,
      userCreation,
      popUpMessage,
      formObject: {
        firstName,
        lastName,
        email,
        confirmEmail,
        mobileNo,
        xlpAccNo,
      },
      storeNumber,
      options,
      selectedOption,
      countryCode,
      userUpdated,
    } = this.state;
    const {
      userDetails: {
        userType,
      },
    } = this.props;
    return (
      <div className="manage-users-container">
        {userType === "IT_ADMIN" && (
          <Fragment >
         <div className="label-input-container">
         <label htmlFor="">Select a UserType</label>
           <div className="create-radio">
              <div className="radio-wrapper">
									<label class="customized-radio-button-createUser">
									<input
										type="radio"
										name="viewTypes"
								    value="Customer Admin"
										id='form-1'
                    checked={this.state.selectedOption === "Customer Admin"}
                    onChange={this.onChangeValue}
										/>
										<span>Customer Admin</span>
									</label>
									<label class="customized-radio-button-createUser">
									<input
										type="radio"
										name="viewTypes"
                    value="Store User"
                      onChange={this.onChangeValue}
										id='form-2'
										/>
										<span>Store User</span>
									</label>
								</div></div></div>
           { (selectedOption == 'Customer Admin' && selectedOption != 'Store User')   ?
            <div className="label-input-container">
              <label htmlFor="xlpAccNo">The Parts House Customer Number</label>
              <div>
                <Form.Control
                  type="text"
                  onChange={this.handleInputChange}
                  onKeyDown={(event) => this.handleKeyDown(event, 'xlpAccNo')}
                  name="xlpAccNo"
                  maxLength="16"
                  value={xlpAccNo}

                />
                <FormValidationErrorMessage
                  condition={!validity.isXlpAccNoNotNull}
                  errorMessage="Please enter your Customer number"
                />
                <FormValidationErrorMessage
                  condition={
                    !validity.isXlpAccNoMinLength &&
                    validity.isXlpAccNoNotNull
                  }
                  errorMessage="Customer number should be atleast 1 characters"
                />
                <FormValidationErrorMessage
                  condition={
                    !validity.isXlpAccNoAlphanumeric &&
                    validity.isXlpAccNoNotNull
                  }
                  errorMessage="Customer number must be alphanumeric characters only"
                />
                <FormValidationErrorMessage
                  condition={
                    !validity.isXlpAccNoLengthBelowLimit &&
                    validity.isXlpAccNoNotNull
                  }
                  errorMessage="Customer number should not exceed 10 characters"
                />
              </div>
            </div>:<span></span>}
            <div className="label-input-container">
              <label htmlFor="firstName">First Name</label>
              <div>
                <Form.Control
                  type="text"
                  onChange={this.handleInputChange}
                  onKeyDown={(event) => this.handleKeyDown(event, 'firstName')}
                  name="firstName"
                  maxLength="16"
                  value={firstName}
                // placeholder="First Name"
                />
                <FormValidationErrorMessage
                  condition={!validity.isFirstNameNotNull}
                  errorMessage="Please enter your first name"
                />
                <FormValidationErrorMessage
                  condition={!validity.isFirstnameMinLength && validity.isFirstNameNotNull && validity.isFirstNameAlphabetic}
                  errorMessage="First Name should be atleast 2 characters"
                />
                <FormValidationErrorMessage
                  condition={!validity.isFirstnameLengthBelowLimit && validity.isFirstNameNotNull}
                  errorMessage="First Name should not exceed 16 characters"
                />
                <FormValidationErrorMessage
                  condition={!validity.isFirstNameAlphabetic && validity.isFirstNameNotNull}
                  errorMessage="First name must be alpha characters only"
                />
              </div>
            </div>
            <div className="label-input-container">
              <label htmlFor="lastName">Last Name</label>
              <div>
                <Form.Control
                  type="text"
                  onChange={this.handleInputChange}
                  onKeyDown={(event) => this.handleKeyDown(event, 'lastName')}
                  name="lastName"
                  maxLength="16"
                  value={lastName}
                // placeholder="Last Name"
                />
                <FormValidationErrorMessage
                  condition={!validity.isLastNameNotNull}
                  errorMessage="Please enter your last name"
                />
                <FormValidationErrorMessage
                  condition={!validity.isLastnameMinLength && validity.isLastNameAlphabetic}
                  errorMessage="Last Name should be atleast 2 characters"
                />
                <FormValidationErrorMessage
                  condition={!validity.isLastnameLengthBelowLimit && validity.isLastNameAlphabetic}
                  errorMessage="Last Name should not exceed 16 characters"
                />
                <FormValidationErrorMessage
                  condition={!validity.isLastNameAlphabetic && validity.isLastNameNotNull}
                  errorMessage="Last name must contain alpha characters only"
                />
              </div>
            </div>
            <div className="label-input-container">
              <label htmlFor="emailId">Email</label>
              <div>
                <Form.Control
                  type="email"
                  onChange={this.handleInputChange}
                  onKeyDown={(event) => this.handleKeyDown(event, 'email')}
                  name="email"
                  maxLength="50"
                  value={email}
                // placeholder="E-mail"
                />
                <FormValidationErrorMessage
                  condition={!validity.isEmailIdNotNull}
                  errorMessage="Please enter your Email"
                />
                <FormValidationErrorMessage
                  condition={!validity.isEmailIdLengthBelowLimit && validity.isEmailIdNotNull && validity.isEmailValidFormat}
                  errorMessage="Email should not exceed 50 characters"
                />
                <FormValidationErrorMessage
                  condition={!validity.isEmailIdMinLength && validity.isEmailIdNotNull && validity.isEmailValidFormat}
                  errorMessage="Email should be atleast 7 characters"
                />
                <FormValidationErrorMessage
                  condition={!validity.isEmailValidFormat && validity.isEmailIdNotNull}
                  errorMessage="Email must be in username@domainname.com format"
                />
              </div>
            </div>
            <div className="label-input-container">
              <label htmlFor="ConfirmEmailId">Confirm Email</label>
              <div>
                <Form.Control
                  type="email"
                  onChange={this.handleInputChange}
                  onKeyDown={(event) => this.handleKeyDown(event, 'email')}
                  name="confirmEmail"
                  maxLength="50"
                  autocomplete="off"
                  onPaste={(e)=>{e.preventDefault()}}
                  value={confirmEmail}
                // placeholder="E-mail"
                />
                <FormValidationErrorMessage
								condition={
									!validity.isValidConfirmEmail
								}
								errorMessage="Email and confirm email should be same"
							/>
							<FormValidationErrorMessage
								condition={
									!validity.isConfirmEmailIdNotNull && validity.isValidConfirmEmail
								}
								errorMessage="Please confirm Email"
							/>
              </div>
            </div>
            <div className="label-input-container">
              <label htmlFor="mobile"><span className="maroonColorTxt">Cell Phone </span>Number</label>
              <div>
                <InputGroup className="mobile-no-container">
                  <DropdownButton
                    as={InputGroup.Prepend}
                    variant="outline-secondary"
                    id="input-group-dropdown-1"
                    title={countryCode}
                    onSelect={(key, e) => {
                      this.updateCountryCode(key, e);
                    }}
                  >
                    <Dropdown.Item name="+91">+91</Dropdown.Item>
                    <Dropdown.Item name="+1">+1</Dropdown.Item>
                  </DropdownButton>
                  <Form.Control
                    type="text"
                    onChange={this.handleInputChange}
                    name="mobileNo"
                    onKeyDown={(event) => this.handleKeyDown(event, 'mobileNo')}
                    maxLength="11"
                    value={mobileNo}
                  />
                  <FormValidationErrorMessage
                    condition={!validity.isMobileNoNotNull}
                    errorMessage="Please enter your Cell Number"
                  />
                  <FormValidationErrorMessage
                    condition={!validity.isMobileNoNumeric && validity.isMobileNoNotNull}
                    errorMessage="Cell number must be numeric only"
                  />
                  <FormValidationErrorMessage
                    condition={!validity.isMobileNoLengthBelowLimit && validity.isMobileNoNotNull && validity.isMobileNoNumeric}
                    errorMessage="Cell number should not exceed 10 numbers"
                  />
                  <FormValidationErrorMessage
                    condition={!validity.isMobileNoMinLength && validity.isMobileNoLengthBelowLimit && validity.isMobileNoNumeric && validity.isMobileNoNotNull}
                    errorMessage="Cell number should be atleast 10 numbers"
                  />
                </InputGroup>
              </div>
            </div>
            <p>Please ensure this is a cell phone number; a code will be sent to this number to finish registration</p>

            { (selectedOption != 'Customer Admin' && selectedOption == 'Store User')   ?
            <div className="label-dropdown-container">
          <Form.Label>Select a Store Number</Form.Label>
          <div>
            <Select
             options={options}
              name="storeNumber"
              value={storeNumber}
              className="custom-dropdown"
              placeholder="Select a Store Number"
              onChange={this.onChangeSelectValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#84bae2",
                  primary50: "#0F5283",
                  primary: "#0F5283",
                },
              })}
            />
             <FormValidationErrorMessage
              condition={!validity.isUserTypeNotNull}
              errorMessage="Please select the store Number"
            />
          </div>
        </div>:<span></span>}


            <div className="label-input-container">
              <label htmlFor="userType">User Type</label>
              <div>
                <Form.Control
                  disabled={true}
                  className="custom-dropdown"
                  value={selectedOption}
                />
              </div>
            </div>

             { (selectedOption == 'Customer Admin' && selectedOption != 'Store User') ?
            ((!this.props.userCreationApiCall) ? (
              <Button
                type="submit"
                className="user-management-submit-btn"
                disabled={
                  this.props.userCreationApiCall ||
                  !userUpdated || !_every(validity) ||
                  _isEmpty(firstName) || _isEmpty(lastName) || _isEmpty(email) || _isEmpty(xlpAccNo) || _isEmpty(mobileNo) || _isEmpty(confirmEmail) || (confirmEmail !== email)
                }
                onClick={this.handleSubmit}>
                Ok
              </Button>
            ) : (
              <div className="custom_spinner_container">
                <Spinner className="custom_spinner" animation="border" />
              </div>
            )):
            (!this.props.userCreationApiCall  ? (
              <Button
                type="submit"
                className="user-management-submit-btn"
                disabled={
                  this.props.userCreationApiCall ||
                  !userUpdated || (mobileNo.length < 10) || (firstName.length < 2 ) ||
                  (lastName.length < 2 ) ||
                  _isEmpty(firstName) || _isEmpty(lastName) || _isEmpty(email)  || _isEmpty(mobileNo) || _isEmpty(confirmEmail) || (confirmEmail !== email) ||(_isEmpty(storeNumber))
                 }
                onClick={this.handleSubmit}>
                Ok
              </Button>
            ) : (
              <div className="custom_spinner_container">
                <Spinner className="custom_spinner" animation="border" />
              </div>
            ))}
          </Fragment>

        )}

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userCreationSuccess: state.user.userCreationSuccess,
    userCreationApiCall: state.user.userCreationApiCall,
    userDetails: state.user.userDetails,
    messageFromAPi: state.user.messageFromAPi,
    ShipToIds: state.ShipToIds.shipToIds,

  };
}
export default connect(mapStateToProps)(CustomerAdmin);
