/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, Link, MenuItem, Select, TextField } from "@mui/material";
import { oemBuilderPartsMenu } from "helpers/constants";
import { useQuery } from "helpers/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Button } from "ui-library";
import { getQuoteUniversal, getReviewCKSearchData } from "../c_and_k.thunk";
import { resetCandK, setCandK } from "../c_and_k.slice";
import { IMaskInput } from "react-imask";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToasts } from "react-toast-notifications";
import { getVehicleByVinNumber } from "../../../actions/search";
import { clearPartData, clearChemicalData, clearCnKParts } from '../Checkout/CarouselUtils';
const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const Loading = () => {
  return <div>Loading...</div>;
};

const schema = yup.object({
  partType: yup.string().required("Part Type is required"),
  currentMileage: yup.string().required("Current Mileage is required").max(11, "Current Mileage shouldn't be greater than 9 digits"),
  vin: yup
    .string()
    .required("VIN # is required")
    .min(16, "Vin number should be atleast 16 characters")
    .max(17, "Vin number should not exceed above 17 characters"),    
});

export const TextMaskCustom = React.forwardRef(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={Number}
      thousandsSeparator=","
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const OEMLayout = (props) => {
  const { children } = props;

  const location = useLocation();
  const query = useQuery();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const candk = useSelector((state) => state.candk);
  const { cksearch = {} } = candk;
  const qPage = query.get("page");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      partType: qPage || "",
      vin: cksearch.vin || "",
    },
    resolver: yupResolver(schema),
  });

  const { addToast } = useToasts();
  const userDetails = user.userDetails;
  const shipTo =
    (userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos.length > 0 &&
      userDetails.shipTos[0]) ||
    {};

  const [vin] = useState(cksearch?.vin?.toUpperCase() || "");
  const [currentMileage] = useState(
    cksearch?.currentMileage === 0
      ? ""
      : cksearch?.currentMileage
      ? cksearch?.currentMileage
      : ""
  );

  useEffect(() => {
    resetPage();

    return () => {
      resetPage();
    };
  }, []);

  useEffect(() => {
    setValue("partType", cksearch?.parts?.[0]?.part?.searchType || qPage);
    setValue("currentMileage", cksearch?.currentMileage || "");
    setValue("vin", cksearch?.vin?.toUpperCase() || "");
  }, [location, cksearch]);

  useEffect(() => {
    setValue("partType", qPage, { shouldValidate: true });
  }, [qPage]);

  const resetPage = () => {
    reset();
    dispatch(resetCandK());
    clearCnKParts(dispatch)
    clearPartData(dispatch);
    clearChemicalData(dispatch);
  };

  const handleReset = () => {
    resetPage();
  };

  const searchTypeMapping = {
    'engine': 'engine',
    'automaticTransmission' : 'autoTransmission',
    'manualTransmission': 'manualTransmission',
    'reardifferential': 'differential',
    'transfercase': 'TransferCases'
  };
   
  const handleSearch = (data) => {
    const payload = {
      vin: data.vin.toUpperCase().trim(),
      currentMileage: data.currentMileage.replace(/,/g, ""),
      partType: data.partType,
      address: {
        zipCode: shipTo.zip,
        stateCode: shipTo.state,
        countryCode: shipTo.country,
        address: shipTo.address,
        city: shipTo.city,
        state: shipTo.state,
      },
      parts: [
        {
          part: {
            searchType: data.partType.replace(/ /g, ""),
            value: searchTypeMapping[data.partType.replace(/ /g, "")] || null
          },
          quantity: 1,
        },
      ],
    };
    //dispatch(getQuoteUniversal(payload));
    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });

    dispatch(getQuoteUniversal(payload))
      .then((action) => {
        if (action.payload) {
          dispatch(setCandK(action.payload));
        }

        const preview = action.payload;

        if (preview &&
          (preview.errorMessage === null || preview.errorMessage === "") &&
          preview.partsFound &&
          preview.partsFound.length > 0
        ) {
          getVehicleByVinNumber(data.vin, userDetails, dispatch);
        } else if (preview && preview.errorMessage) {
          const err = preview.errorMessage;
          if (err.includes("Current Mileage")) {
            setError("currentMileage", { type: "validate", message: err });
          } else {
            setError("vin", {
              type: "validate",
              message: "VIN # is not valid",
            });
          }
        }

        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      })
      .catch((error) => {
        addToast(error || "Error during API call", {
          appearance: "error",
          autoDismiss: true,
        });
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      })
      .finally(() => {
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      });
    dispatch(getReviewCKSearchData(payload));
  };

  return (
    <React.Fragment>
      <div className="oem-layout">
        <div className="container oem-container">
          <div className="oem-top-section-wrapper">
            <form
              onSubmit={handleSubmit((data) => {
                handleSearch(data);
              })}
            >
              <div className="oem-top-section">
                <div className="oem-item oem-vin">
                  <div className="oem-item-inputs">
                    <div className="oem-top-sec-label">Part Types</div>
                    <div className="oem-top-sec-component">
                      <FormControl sx={{ m: 0, minWidth: 120 }}>
                        <Controller
                          name="partType"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              size="small"
                              displayEmpty
                              inputProps={{ "aria-label": "OEM Part Types" }}
                              error={errors.partType}
                              helperText={
                                errors.partType && errors.partType.message
                              }
                            >
                              <MenuItem value="">
                                <em>Select Part Type</em>
                              </MenuItem>
                              {oemBuilderPartsMenu.map((part, i) => {
                                return (
                                  <MenuItem value={part.type} key={part.label}>
                                    <Link
                                      to={`${part.link}?page=${
                                        part.type || part.label
                                      }`}
                                      underline="none"
                                    >
                                      {part.label}
                                    </Link>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </div>
                  </div>
                  {errors.partType && (
                    <div className="error-msg">{errors.partType.message}</div>
                  )}
                </div>
                <div className="oem-item oem-vin">
                  <div className="oem-item-inputs">
                    <div className="oem-top-sec-label">Current Mileage:</div>
                    <div className="oem-top-sec-component">
                      <Controller
                        name="currentMileage"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            placeholder="Enter Current Mileage"
                            defaultValue={currentMileage}
                            InputProps={{
                              inputComponent: TextMaskCustom,
                            }}
                            error={errors.currentMileage}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {errors.currentMileage && (
                    <div className="error-msg">
                      {errors.currentMileage.message}
                    </div>
                  )}
                </div>
                <div className="oem-item oem-vin">
                  <div className="oem-item-inputs">
                    <div className="oem-top-sec-label">VIN #:</div>
                    <div className="oem-top-sec-component">
                      <Controller
                        name="vin"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            placeholder="Enter VIN Number"
                            // value={vin}
                            defaultValue={vin}
                            input
                            error={errors.vin}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {errors.vin && (
                    <div className="error-msg">{errors.vin.message}</div>
                  )}
                </div>
                <div className="oem-part-search">
                  <Button type="submit" variant="contained">
                    Search
                  </Button>
                </div>
                <div>
                  <Link component="button" type="button" onClick={handleReset}>
                    Reset Search
                  </Link>
                  <div></div>
                </div>
              </div>
            </form>
          </div>
          {candk.loading && !candk.isLoadingComplete ? <Loading /> : children}
          {/* <Loader
            isLoading={candk.loading}
            isLoadingComplete={candk.isLoadingComplete}
            data={candk.parts}
            component={<Loading />}
          >
            {children}
          </Loader> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OEMLayout;
