import React, { useState, useEffect } from "react";
import { useDispatch ,useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _isEmpty from "lodash/isEmpty";
import {
  getUserShipInfo ,SupplierRegion
} from '../../../helpers/utils';
import {
  setShippingInfo
} from "./simplehomepage.slice";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Locations from "../../Locations";
import XModal from "../../shared/XModal";
import _cloneDeep from "lodash/cloneDeep";
import _uniqBy from "lodash/uniqBy";
import PickupDelivery from "components/shared/PickupDeliveryHeader";

const PartCatalogHeader = (props) => {
  const dispatch = useDispatch();
  let { storeInfo ,userDetails, getShipInfo, checkoutDetails } = props;
  const [pickupLocation, setPickupLocation] = useState(getUserShipInfo(userDetails).storeNumber.toString());
  const [pickupLocationName, setPickupLocationName] = useState("");
  let allStoreData =  useSelector((state) => state.userManagement.allStores); 
  const storeDetails = useSelector((state) => state.mygarage.storeDetails);
  const [selectedOption, setSelectedOption] = useState(getUserShipInfo(userDetails).shipMethod);
  const [showLocationsPopup, setShowLocationsPopup] = useState(false);
  const [showRegionLocationsPopup, setShowRegionLocationsPopup] = useState(false);
  const [filteredStores, setfilteredStores] = useState([]);

  useEffect(() => {
    let {isTrue, stores} = SupplierRegion(userDetails,storeDetails)
    if (isTrue) {allStoreData  = stores}
    if (!_isEmpty(allStoreData ) && !_isEmpty(selectedOption)) {
      let filteredStores = [];
      filteredStores = !_isEmpty(allStoreData ) && allStoreData.pickupStores && selectedOption == "0002" ?  _cloneDeep(allStoreData.pickupStores) : _cloneDeep(allStoreData.deliveryStores);
      if(!_isEmpty(allStoreData )&& !_isEmpty(allStoreData.defaultStore)) {
        filteredStores.push(_cloneDeep(allStoreData.defaultStore));
        filteredStores = _uniqBy(filteredStores,"storeNumber");
        setfilteredStores(filteredStores);
      }
      !_isEmpty(filteredStores) && filteredStores.map(value => {
        if (value.storeNumber === getUserShipInfo(userDetails).storeNumber.toString()) {
          setPickupLocationName(value.storeName);
        }
      })
    }   
  }, [allStoreData, selectedOption])

  useEffect(() => {
    if (checkoutDetails && checkoutDetails.length > 0) {
      setSelectedOption(checkoutDetails[0].shipMethod);
      setPickupLocation(checkoutDetails[0].store);
    } else if(!_isEmpty(userDetails)){
      setSelectedOption(getUserShipInfo(userDetails).shipMethod);
      setPickupLocation(getUserShipInfo(userDetails).storeNumber);
    }   
  }, [userDetails, checkoutDetails])

  const handlePickupLocationChange = (key, storeInfo) => {
    let sellerPartnerId = getUserShipInfo(userDetails).sellerPartnerId;
    let storeNumber = getUserShipInfo(userDetails).storeNumber;
    let shipMethod = getUserShipInfo(userDetails).shipMethod
    setPickupLocation(selectedOption);
    !_isEmpty(filteredStores) && filteredStores.map(value => {
      if (value.sellerPartID === key) {
        sellerPartnerId = value.sellerPartnerId ? value.sellerPartnerId : "";
        storeNumber = value.storeNumber;
        shipMethod = selectedOption;
        setPickupLocationName(value.storeName);
      }
    })
    dispatch(setShippingInfo({
      shippingInfo: {
        sellerPartnerId: sellerPartnerId,
        storeNumber: storeNumber,
        shipMethod: shipMethod
      }
    }));
    //getShipInfo(sellerPartnerId, storeNumber, shipMethod);
  };

  const handleShipMethod = (event) => {
    let sellerPartnerId = getUserShipInfo(userDetails).sellerPartnerId;
    let storeNumber = getUserShipInfo(userDetails).storeNumber;
    let shipMethod = event.target.value;
    setPickupLocation(getUserShipInfo(userDetails).storeNumber);
    setSelectedOption(event.target.value);
    dispatch(setShippingInfo({
      shippingInfo: {
        sellerPartnerId: sellerPartnerId,
        storeNumber: storeNumber,
        shipMethod: shipMethod
      }
    }));
    //getShipInfo(sellerPartnerId, storeNumber, shipMethod);
  };

  return (
    <React.Fragment>
      <Row className="align-items-sm-center ml-4">
        <PickupDelivery
          handleViewTypeChange={handleShipMethod}
          storeSellerId={handlePickupLocationChange}
        /> 
      </Row>
    </React.Fragment>
  );
};

PartCatalogHeader.propTypes = {};

export default PartCatalogHeader;
