import React, {useState} from 'react';
import { Container, Row, Form, InputGroup, DropdownButton, Dropdown, Button, Spinner} from 'react-bootstrap';
import FormValidationErrorMessage from '../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import {
	numbersOnlyRegExp,
	validateOnlineIdForm
} from '../../helpers/utils';
import { Fragment } from 'react';


function VerifyMobileMFALogin({currentOtpSubmitting,handleConfirmCurrentPhone, validity, handleInputBlur, currentMobileNumber,newPhoneSubmitting, newOtpSubmitting, handleUpdateNewMob, handleMfaSubmitNewMob}) {
	const [countryCode, setCountryCode] = useState("+1")
	const [mobileNo, setMobileNo] = useState('')
	const [newOtp, setNewOtp] = useState('');
	const [currentOtp, setCurrentOtp] = useState('')
	const [isrequiredNewPhone, setIsrequiredNewPhone ] = useState(false)
	const [currentOtpValidity, setCurrentOtpValidity] = useState({isConfirmationNotNull:true});
	const [newtOtpValidity, setNewOtpValidity] = useState({isNewConfirmationNotNull:true});
	const [newMobileValidity, setNewMobileOtpValidity] = useState({isMobileNoNotNull:true});

	const handleKeyDown = (event, name) => {
		switch (name) {
			case 'mobileNo':
				if (
					(numbersOnlyRegExp(event.key) === false ||
						event.keyCode === 32 ||
						(mobileNo && mobileNo.length >= 10)) &&
					handleOtherKeys(event.keyCode)
				) {
					event.preventDefault();
				}
				break;
			default:
				break;
		}
	};

	const changeCountryCode = (key, event)=>{
		setCountryCode(event.target.name)
	}

	const handleInputChangeNewOtp = (event) =>{
		let {target:{name, value} }= event
		if(name === "smsConfirmationCode"){
		let	validity = validateOnlineIdForm(name, value, currentOtpValidity);
			setCurrentOtpValidity(validity)
			setCurrentOtp(value);
		}else if(name === "smsConfirmationCodeNew"){
		let	validity = validateOnlineIdForm(name, value, newtOtpValidity);
			setNewOtpValidity(validity)
			setNewOtp(value)
		}

	}

	const handleInputChangeNewMob = (event)=>{
		let {target:{name, value} }= event
		let	validity = validateOnlineIdForm(name, value, newMobileValidity);
		setNewMobileOtpValidity(validity);
		setMobileNo(event.target.value)
	}

	const handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
		return (
			code !== 8 &&
			code !== 9 &&
			code !== 37 &&
			code !== 38 &&
			code !== 39 &&
			code !== 40 &&
			code !== 46
		);
	};


	return (
		<Container fluid className="login_container">
			<Row className="login_title">
				<span>Phone number verification</span>
			</Row>
			{!isrequiredNewPhone ?
			<Row className="login_box">
				<span className="your-current-phone">{`Your current phone number registered is ${currentMobileNumber}`}</span>
				<div>
					<Form className="login_form" >
					<p className="change-mobile-label-text">Do you want to change your mobile number? Please {''}
						<span
							onClick={() => {
								setIsrequiredNewPhone(true)
							}}
							style={{ cursor: "pointer", color: '#9e331f' }}
							title="Click here"
						>
							Click here
						</span>
						, else please click Continue
					</p>
						<div className="returning_customer_container">
							{/* <div className="input_fields">
								<label>Current Mobile number</label>
								<Form.Control
									size="sm"
									type="text"
									name="currentMobile"
									value={currentMobileNumber}
									disabled={true}
								/>
							</div> */}
							{/* <div className="input_fields">
								<Form.Control
									size="sm"
									type="text"
									name="smsConfirmationCode"
									onChange={handleInputChangeNewOtp}
									placeholder="Please Input One Time Password*"
								/>
								<FormValidationErrorMessage
									condition={!currentOtpValidity.isConfirmationNotNull}
									errorMessage="Please enter your Confirmation Code"
								/>
							</div> */}
							{currentOtpSubmitting ? (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									disabled
								>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									Loading..
								</Button>
							) : (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									type="submit"
									onClick={(e)=>{
										e.preventDefault();
										handleConfirmCurrentPhone();
									}}
								>
									Continue
								</Button>
							)}
						</div>
					</Form>
				</div>
			</Row>:
			<Fragment>
			<Row className="login_box">
			<span>Please enter your new number and click Get one time password button</span>
				<Form className="login_form new-otp-form" >
						<div className="returning_customer_container">
							<div className="input_fields">
								<label>New Mobile number</label>
								<InputGroup>
								<DropdownButton
									class="text-white-button"
									as={InputGroup.Prepend}
									variant="outline-secondary"
									title={countryCode}
									id="input-group-dropdown-1"
									onSelect={(key, e) => {
										changeCountryCode(key, e);
									}}
								>
									<Dropdown.Item name="+91">
										+91
									</Dropdown.Item>
									<Dropdown.Item name="+1">+1</Dropdown.Item>
								</DropdownButton>
								<Form.Control
									type="text"
									onChange={handleInputChangeNewMob}
									name="mobileNo"
									id="mobileNo"
									maxLength="11"
									onKeyDown={(event) =>
										handleKeyDown(event, 'mobileNo')
									}
								/>
								<FormValidationErrorMessage
									condition={!newMobileValidity.isMobileNoNotNull}
									errorMessage="Please enter your phone number"
								/>
							</InputGroup>
							</div>
							{newPhoneSubmitting ? (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									disabled
								>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									Loading..
								</Button>
							) : (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									type="submit"
									onClick={(e)=>{
										e.preventDefault();
										if(newMobileValidity.isMobileNoNotNull){
											handleUpdateNewMob(countryCode,mobileNo)}}
										}
								>
									Get one time password
								</Button>
							)}
							<div className="input_fields">
								<Form.Control
									size="sm"
									type="text"
									name="smsConfirmationCodeNew"
									onChange={handleInputChangeNewOtp}
									placeholder="Please Input One Time Password*"
								/>
								<FormValidationErrorMessage
									condition={!newtOtpValidity.isNewConfirmationNotNull}
									errorMessage="Please enter your Confirmation Code"
								/>
							</div>
							{newOtpSubmitting ? (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									disabled
								>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									Loading..
								</Button>
							) : (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									type="submit"
									onClick={(e)=>{
										e.preventDefault();
										if(newtOtpValidity.isNewConfirmationNotNull){
											handleMfaSubmitNewMob(newOtp)}}
										}
								>
									Submit
								</Button>
							)}
						</div>
					</Form>
			</Row>
			</Fragment>}
		</Container>
	);
}
export default VerifyMobileMFALogin;
