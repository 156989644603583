import React, { Fragment } from "react";
import {
  Image,
  Container,
  Row,
  Col,
  Button,
  Card,
	Modal,
  OverlayTrigger,
  Tooltip,
  Form,
  Spinner,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";
import _uniqBy from "lodash/uniqBy";
import {
  timeInUTC,
  getDefaultDataBasedOnUSerPref,
  getPreferenceDataFromStorage,
  getRequestPayloadForOrchestratorAPI,
  getExtendedTotalPrice,
  SupplierRegion,getPartsPreferredBrands,
  setCookie,
  getCookie,
} from "../../helpers/utils";
import _keys from "lodash/keys";
import {
  getChemPartByGrp,
  getPartsByMultiGroupOrchestrator,
  updateFilterSectionEnabledStatus,
} from "../../actions/parts";
import ymmeRefreshIcon from "../../static/images/icons/red/ymme-refresh-icon.png";
import { setJobEventStatus } from "../shared/MyGarage/mygarage.slice";
import { Redirect, Link } from "react-router-dom";
import BreadCrumbInput from "../shared/BreadCrumbInput";
import XModal from "../shared/XModal";
import SelectedVehicle from "../shared/SelectedVehicle";
import SingleProduct from "./SingleProduct";
import FilterSection from "./FilterSection";
import Placeholder from "../shared/Placeholder";
import "./styles.scss";
import PaginationComponent from "../shared/Pagination";
import TopBar from "./TopBar";
import ShipInfoHeader from '../shared/ShipInfoHeader/ShipInfoLocationHeader';
import SortByShow from './SortByShow';
import FavoriteIconHeader from '../shared/FavoriteIconHeader';
import SpecificconditionIcon from '../shared/SpecificconditionIcon';
import PromotionsTab from "../shared/Promotions";
import clsx from "clsx";
import TableViewWrapper from "./tableview";
import _find from "lodash/find";
import { container } from "@aws-amplify/ui";
import _ from "lodash";
import MultipleSelectCheckbox from "./customMultiCheckboxSelect";
import _filter from "lodash/filter";
import AddToCartWarningPopup from "../shared/AddToCartWarningPopup/AddToCartWarningPopup";
import { setVehicles } from '../shared/MyGarage/mygarage.thunks';
import {
	setVehicleSelected,
} from '../shared/MyGarage/mygarage.slice';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Locations from "../Locations";
import PickupDelivery from "components/shared/PickupDeliveryHeader";
import VehicleBreadCrumb from "components/shared/VehicleBreadCrumb";
import { prepareTablePartsDataFromUtil } from "./partsUtils";

const actionTypes = {
  PROMOTION_EVENT_STATUS: "PROMOTION_EVENT_STATUS",
};

class ListingWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCall: props.partsApiCall,
      showBuyersGuide: false,
      selectedParts: [],
      buyersGuideData: props.buyersGuideData ? props.buyersGuideData : {},
      buyersGuideBrandData: props.buyersGuideBrandData
        ? props.buyersGuideBrandData
        : [],
      partsAllDetails: props.partsAllDetails ? props.partsAllDetails : [],
      selectedJob: props.selectedJob ? props.selectedJob : {},
      partsQtySum: {},
      extraSpecifics: [],
      selectedSpecifics: [],
      redirectToHomePage: false,
			showLocationsPopup: false,
			showRegionLocationsPopup: false,
      redirectToChemicalPage: false,
      currentSort: "Default",
      viewPromotions: false,
      partTypes: props.partTypes,
      currentFilter: "ALL",
      userDetails: [],
      defaultSortChanged: false,
      defaultPageSizeChanged: false,
      showFullView: true,
      filterEnabled: false,
      userPrefView: "",
      partTopArrowClicked: false,
      redirectLink:
        window.location.pathname === "/supplies-list"
          ? `/supplies-catalog`
          : `/`,
		  enableDelivery:
		  props.checkoutDetails && !_isEmpty(props.checkoutDetails)
			? (props.checkoutDetails[0].shipMethod === "0002" || props.checkoutDetails[0].shipMethod === "0001" || props.checkoutDetails[0].shipMethod === "FIWS")
			  ? false
			  : true
			: this.props.userDetails &&
			  this.props.userDetails.shipTos &&
			  this.props.userDetails.shipTos.length > 0 &&
			  this.props.userDetails.shipTos[0].shipMethod &&
			  this.props.userDetails.shipTos[0].shipMethod &&
			  (this.props.userDetails.shipTos[0].shipMethod === "0002" || this.props.userDetails.shipTos[0].shipMethod === "0001" || this.props.userDetails.shipTos[0].shipMethod === "FIWS")
			? false
			: true,
      addToCartWarningPopup: false,
      selectedCartData: [],
	  allStores: this.props.allStoreData,
      selectedStore: this.getCartMoreInfo().cartStore,
      sellPartnerId: this.getCartMoreInfo().sellerPartnerId,
      selectedStoreName: this.getCartMoreInfo().selectedStoreName,
      shipMethod: this.getCartMoreInfo().shipMethod,
      customerShipMethod: this.getCartMoreInfo().defaultShipMethod,
      cartStore: this.getCartMoreInfo().cartStore
    };
		this.handleYmmeFromPopup = this.handleYmmeFromPopup.bind(this);
	}

	handleYmmeFromPopup = (event) => {
		if (event && event.data && typeof(event.data) === 'string' && event.data === "loaded") {
			let { selectedVehicle: sv } = this.props;
			window.ymmeWin.postMessage(JSON.stringify(sv),"*");
  }

		if (event && event.data && typeof(event.data) === 'string' && event.data.includes("engine")) {
			const sv = JSON.parse(event.data);
			const { x2userName, shipTos, billTo } =
				this.props.userDetails || {};
			// Dispatch My Garage Vehicles
			const {
				engine,
				make,
				model,
				year,
				makeId,
				modelId,
				engineId,
				internalMakeId
			} = sv;

			if (engine && make && model && year) {
				const { dispatch } = this.props;
				dispatch({
					payload: {
						selectedVehicle: sv,
					},
					type: 'VEHICLE_SELECTION_COMPLETED',
				});
				const payload = {
					createdBy: x2userName,
					createdDate: timeInUTC(),
					customerId: billTo.billToId,
					engine: engine.trim(),
					engineId,
					favoriteFlag: 'N',
					favoriteText: '',
					favoriteUpdatedDate: '',
					lastOrderedDate: '',
					make: make.trim(),
					makeId,
					internalMakeId,
					model: model.trim(),
					modelId,
					orderedFlag: 'N',
					searchId: '',
					shipToId: shipTos[0].shipToId,
					updatedBy: x2userName,
					updatedDate: timeInUTC(),
					year: year.trim(),
				};
				dispatch(setVehicles(payload));
				// On Vehicle Select clear the specific conditions for a vehicle
				dispatch({
				  payload: {},
				  type: "RESET_SPECIFIC_CONDITIONS",
				});
				dispatch(setVehicleSelected(payload));

				if (this.props.selectedGroupDetails && this.props.selectedGroupDetails.length > 0) {
					const __orchestratorPayload = getRequestPayloadForOrchestratorAPI(this.props.selectedGroupDetails);
					dispatch(
						getPartsByMultiGroupOrchestrator(
							sv.year,
							sv.makeId,
							sv.modelId,
							sv.engineId,
							{ requestItems: __orchestratorPayload },
							null,
							null,
							null,
							null,
							this.props.catalogFilter,
							this.props.userDetails.billTo.mclSupplier,
							this.props.selectedPartType,
							true

						)
					);
				}
			}
		}

	}

  componentDidMount() {
    const { dispatch,userDetails } = this.props;

		window.addEventListener('message', this.handleYmmeFromPopup);

    dispatch(setJobEventStatus({ status: false }));
    dispatch({
      payload: {
        status: false,
      },
      type: actionTypes.PROMOTION_EVENT_STATUS,
    });
    let currentStateSort = this.state.currentSort;
    const userPreferenceDetail = getPreferenceDataFromStorage();
    if (!_isEmpty(userPreferenceDetail)) {
      let { defaultSortChanged, defaultPageSizeChanged } = this.state;
      let currentSort = currentStateSort;

      let userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
      if (!defaultPageSizeChanged) {
        let currentPageSize = userPrefDetail.pageSize;
        this.setState({
          ...this.state,
          currentPageSize,
        });
      }
      if (!defaultSortChanged) {
        currentSort = userPrefDetail.currentSort;
      }
      let selectedViewType = this.setDefaultPreference(
        "Product Information",
        "Presentation Mode"
      );
      const userPreferenceView = selectedViewType;

      let selectedPartTopArrowView = this.setDefaultPreference(
        "Product Information",
        "Page Header"
      );
      const userPreferenceArrowView = selectedPartTopArrowView;

      this.setState({
        ...this.state,
        currentSort,
        showFullView: userPreferenceView === "full",
        userPrefView: userPreferenceView,
        partTopArrowClicked:
          userPreferenceArrowView === "collapse"
      });
    }
	let {isTrue, stores} = SupplierRegion(userDetails,this.props.storeDetails)
	  if (isTrue) {
		this.setState({ allStores : stores})
      }

	const viewPromotions = getCookie('viewPromotions') || "true";
    if(viewPromotions === "false") {
      dispatch({
        payload: {},
        type: "COLLAPSE_PROMOTIONS_TAB",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!_isEqual(this.props, prevProps)) {
      let {
        apiCall,
        buyersGuideData,
        buyersGuideBrandData,
        partsAllDetails,
        selectedSpecifics,
        addToCartWarningPopup,
        selectedCartData,
        userPrefView,
		shipMethod,
		selectedStore
		//allStores
      } = this.state;

      if (!_isEqual(this.props.buyersGuideData, prevProps.buyersGuideData)) {
        buyersGuideData = this.props.buyersGuideData;
      }
	//   if (!_isEqual(this.props.allStoreData, prevProps.allStoreData)) {
    //     allStores = this.props.allStoreData;
    //   }
	//   if (!_isEqual(this.props.storeDetails, prevProps.storeDetails) && true) {
    //     allStores = this.props.storeDetails;
    //   }
	if (!_isEqual(this.props.selectedStore, prevProps.selectedStore)) {
        selectedStore = this.props.selectedStore;
      }
	if (!_isEqual(this.props.shipMethod, prevProps.shipMethod)) {
        shipMethod = this.props.shipMethod;
      }
      if (
        !_isEqual(
          this.props.buyersGuideBrandData,
          prevProps.buyersGuideBrandData
        )
      ) {
        buyersGuideBrandData = this.props.buyersGuideBrandData;
      }

      if (!_isEqual(this.props.partsAllDetails, prevProps.partsAllDetails)) {
        partsAllDetails = [...this.props.partsAllDetails];
      }

	  if (!_isEqual(this.props.partsApiCall, prevProps.partsApiCall)) {
        apiCall = this.props.partsApiCall;
      }

      if (
				!_isEqual(this.props.addToCartWarning, prevProps.addToCartWarning)
			) {
				addToCartWarningPopup = this.props.addToCartWarning;
			}

      if (
				!_isEqual(this.props.selectedAddCartData, prevProps.selectedAddCartData)
			) {
				selectedCartData = _cloneDeep(this.props.selectedAddCartData);
			}

      let currentStateSort = this.state.currentSort;
      const userPreferenceDetail = getPreferenceDataFromStorage();
      if (!_isEmpty(userPreferenceDetail)) {
        let { defaultSortChanged, defaultPageSizeChanged } = this.state;
        let userPrefDetail =
          getDefaultDataBasedOnUSerPref(userPreferenceDetail);
        let currentSort = currentStateSort;
        if (!defaultPageSizeChanged) {
          let currentPageSize = userPrefDetail.pageSize;
          this.setState({
            ...this.state,
            currentPageSize,
          });
        }
        if (!defaultSortChanged) {
          currentSort = userPrefDetail.currentSort;
        }
        let selectedViewType = {};
        selectedViewType = this.setDefaultPreference(
          "Product Information",
          "Presentation Mode"
        );
        let selectedPartTopArrowView = this.setDefaultPreference(
          "Product Information",
          "Page Header"
        );
        const userPreferenceArrowView = selectedPartTopArrowView;
        const userPreferenceView = selectedViewType;

        this.setState({
          ...this.state,
          currentSort,
          showFullView: userPreferenceView === "full",
          userPrefView: userPreferenceView,
          partTopArrowClicked:
            userPreferenceArrowView === "collapse"
        });
      }

      let currentFilter = this.state.currentFilter;
      if (this.props.parts.selectedPartType) {
        if (this.props.parts.selectedPartType) {
          currentFilter = this.props.parts.selectedPartType.partDesc;
        }
      }

      this.setState({
        ...this.state,
        apiCall,
        buyersGuideData,
        buyersGuideBrandData,
        partsAllDetails,
        selectedSpecifics,
        currentFilter,
        addToCartWarningPopup,
        selectedCartData,
		currentFilter,
        shipMethod,
        selectedStore,
		//allStores
      });
    }
  }

  componentWillUnmount() {
    let { dispatch } = this.props;
    window.removeEventListener('message', this.handleYmmeFromPopup);
    dispatch({
      payload: {
        mixedPromo: false,
      },
      type: "MIXED_PROMOTION",
    });

    // clear the selected part type if any
    dispatch({
      payload: {
        selectedPartType: null,
      },
      type: "SELECTED_PART_TYPE",
    });
  }

  getCartMoreInfo = () => {
    let { userDetails, checkoutDetails, allStoreData} = this.props;
    let cartData = [];
    let userData = userDetails && userDetails.shipTos && userDetails.shipTos[0] && userDetails.shipTos[0].location && userDetails.shipTos[0].location;
	let defaultShipMethod = userDetails && userDetails.shipTos && userDetails.shipTos[0] && userDetails.shipTos[0].shipMethod;
	let shipMethod = defaultShipMethod;
	let defaultStore = userDetails && userDetails.shipTos && userDetails.shipTos[0] && userDetails.shipTos[0].storeNumber;
	let cartStore = defaultStore;
    if(checkoutDetails.length > 0) {
		cartStore =  checkoutDetails[0].store;
		shipMethod = checkoutDetails[0].shipMethod;
		if(allStoreData && allStoreData.pickupStores && shipMethod === '0002') {
			cartData = _find(allStoreData.pickupStores, { storeNumber:  cartStore})
		}
		if(allStoreData && allStoreData.deliveryStores && shipMethod != '0002') {
			cartData = _find(allStoreData.deliveryStores, { storeNumber: cartStore})
		}
	}
	let sellPartnerId =  !_isEmpty(cartData) ? cartData.sellerPartnerId : !_isEmpty(userData) && userData.sellerPartnerId;
    let selectedStoreName = !_isEmpty(cartData) ? cartData.storeNumber + " - " + cartData.storeName : !_isEmpty(userData) && userData.storeNumber + " - " + userData.storeName;
    return {
      sellerPartnerId : sellPartnerId,
      selectedStoreName :  selectedStoreName,
	  shipMethod: shipMethod,
	  defaultShipMethod: defaultShipMethod,
	  defaultStore: defaultStore,
	  cartStore : cartStore
    }
  }

  setDefaultPreference = (code, text) => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let selectedFilter = {};
    if (!_isEmpty(userPreferenceDetail)) {
      selectedFilter = _find(userPreferenceDetail, {
        code: code,
        text: text,
      });
    }
    return (
      selectedFilter && selectedFilter.answer && selectedFilter.answer.code
    );
  };

  handlePageChange = (clickedPage) => {
    let {
      catalogFilter,
      catalogOrder,
      catalogSortBy,
      catalogPageSize,
      catalogRequestGroups,
      dispatch,
      selectedVehicle: { year, makeId, modelId, engineId },
    } = this.props;
    window.scrollTo(0, 0);
    dispatch(
      getPartsByMultiGroupOrchestrator(
        year,
        makeId,
        modelId,
        engineId,
        catalogRequestGroups,
        clickedPage,
        catalogPageSize,
        catalogSortBy,
        catalogOrder,
        catalogFilter
      )
    );
  };

  renderNoDataState = () => {
    return (
      <Card className="text-center">
        <Card.Header className="xlp-primary-banner">No Parts Found</Card.Header>
        <Card.Body>
          <Card.Title>
					We couldn't find the parts that you were looking for
          </Card.Title>

          <Button
            className="xlp-submit-btn"
            onClick={() => {
              this.setState({ redirectToHomePage: true });
            }}
          >
            Back to HomePage
          </Button>
        </Card.Body>
      </Card>
    );
  };

  setDefalutProductQuantity = () => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let selectedProductQty = {};
    if (!_isEmpty(userPreferenceDetail)) {
      selectedProductQty = _find(userPreferenceDetail, {
        code: "Product Information",
        text: "Default Order Qty to Qty Per Vehicle",
      });
    }
    return selectedProductQty &&
      selectedProductQty.answer &&
      selectedProductQty.answer.code === "N"
      ? false
      : true;
  };
  separtionCodeForBrandAndParType = (data) => {
		let { currentSort, defaultSortChanged} = this.state;
		const userPreferenceDetail = getPreferenceDataFromStorage();
		let userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
		let rows = [];
		if (currentSort == "Brand (A to Z)" || currentSort == 'Brand (Z to A)') {
			rows = this.brandpart(data);
		}
		if (currentSort == "Part type (A to Z)" || currentSort == "Part type (Z to A)") {
			rows = this.partTypeSepartion(data);
		}
		if(currentSort == "Price (Low to High)"){
			rows = this.priceLowToHighTableView(data);
		}
		if(currentSort == "Price (High to Low)"){
			rows = this.priceHighToLowTableView(data);
		}
		if (currentSort == "Default") {
			rows = this.defaultSepartionView(data);
		}
		return rows;
	}

	priceLowToHighTableView=(rowsdata) =>{
		const {
			minPrice,
			 maxPrice
			 } = this.props;
		let minpriceCount = Math.floor(minPrice);
	    let totalCount  = (maxPrice + minPrice)/5;
		let roundUptotalCount = Math.ceil(totalCount);
		let rowArray =[];
		let rowArray1st =[];
		let rowArray2nd =[];
		let rowArray3rd =[];
		let rowArray4th =[];
		let rowArray5th =[];
		let outofstock1starray =[];
		let outofstock2ndarray =[];
		let outofstock3rdarray =[];
		let outofstock4tharray =[];
		let outofstock5tharray =[];
		rowsdata.map((part, index) => {
			if(part.modifiedcostPrice <= roundUptotalCount){
				if(part.sortAvailability.length <= 0  ){
					outofstock1starray.push(part);
				}else{

					rowArray1st.push(part);

				}
			}
			if(part.modifiedcostPrice > roundUptotalCount && part.modifiedcostPrice <= 2*roundUptotalCount ){

				if( part.sortAvailability.length <= 0  ){
					outofstock2ndarray.push(part);
				}else{

						rowArray2nd.push(part)

				}
			}
			if(part.modifiedcostPrice > 2*roundUptotalCount && part.modifiedcostPrice <= 3*roundUptotalCount ){
				if(part.sortAvailability.length <= 0 ){
					outofstock3rdarray.push(part);
				}else{

						rowArray3rd.push(part);

				}
			}
			if(part.modifiedcostPrice > 3*roundUptotalCount && part.modifiedcostPrice <= 4*roundUptotalCount){
				if(part.sortAvailability.length <= 0){
					outofstock4tharray.push(part);
				}else{

						rowArray4th.push(part);

				}
			}
			if(part.modifiedcostPrice> 4*roundUptotalCount && part.modifiedcostPrice <= 5*roundUptotalCount){
				if( part.sortAvailability.length <= 0){
					outofstock5tharray.push(part);
				}else{

				rowArray5th.push(part);

				}
			}
		})

		this.priceSeparationTableView( "$"+ minpriceCount +" - "+"$"+ roundUptotalCount,rowArray1st,rowArray);
		this.priceSeparationTableView("$"+ roundUptotalCount +" - "+ "$" + 2*roundUptotalCount,rowArray2nd,rowArray);
		this.priceSeparationTableView("$"+ 2*roundUptotalCount +" - "+ "$"+ 3*roundUptotalCount,rowArray3rd,rowArray);
		this.priceSeparationTableView("$"+ 3*roundUptotalCount +" - "+ "$"+ 4*roundUptotalCount,rowArray4th,rowArray);
		this.priceSeparationTableView("$"+ 4*roundUptotalCount +" - "+ "$"+ 5*roundUptotalCount,rowArray5th,rowArray);

		this.priceSeparationTableView( "$"+ minpriceCount +" - "+"$"+ roundUptotalCount,outofstock1starray,rowArray);
		this.priceSeparationTableView("$"+ roundUptotalCount +" - "+ "$" + 2*roundUptotalCount,outofstock2ndarray,rowArray);
		this.priceSeparationTableView("$"+ 2*roundUptotalCount +" - "+ "$"+ 3*roundUptotalCount,outofstock3rdarray,rowArray);
		this.priceSeparationTableView("$"+ 3*roundUptotalCount +" - "+ "$"+ 4*roundUptotalCount,outofstock4tharray,rowArray);
		this.priceSeparationTableView("$"+ 4*roundUptotalCount +" - "+ "$"+ 5*roundUptotalCount,outofstock5tharray,rowArray);
		return rowArray;

	}
	priceHighToLowTableView=(rowsdata) =>{
		const {
		  minPrice,
		   maxPrice
		   } = this.props;

		let minpriceCount = Math.floor(minPrice);
	    let totalCount  = (maxPrice + minPrice)/5;
		let roundUptotalCount = Math.ceil(totalCount);
		let rowArray =[];
		let rowArray1st =[];
		let rowArray2nd =[];
		let rowArray3rd =[];
		let rowArray4th =[];
		let rowArray5th =[];
		let outofstock1starray =[];
		let outofstock2ndarray =[];
		let outofstock3rdarray =[];
		let outofstock4tharray =[];
		let outofstock5tharray =[];
		let outofstockMainArry =[];
		rowsdata.map((part, index) => {
			if(part.modifiedcostPrice <= roundUptotalCount){

				if( part.sortAvailability.length <= 0){
					outofstock1starray.push(part);
				}else{

					rowArray1st.push(part);

				}
			}
			if(part.modifiedcostPrice > roundUptotalCount && part.modifiedcostPrice <= 2*roundUptotalCount ){

				if( part.sortAvailability.length <= 0){
					outofstock2ndarray.push(part);
				}else{

						rowArray2nd.push(part)

				}
			}
			if(part.modifiedcostPrice > 2*roundUptotalCount && part.modifiedcostPrice <= 3*roundUptotalCount ){
				if( part.sortAvailability.length <= 0){
					outofstock3rdarray.push(part);
				}else{

						rowArray3rd.push(part);

				}
			}
			if(part.modifiedcostPrice > 3*roundUptotalCount && part.modifiedcostPrice <= 4*roundUptotalCount){
				if( part.sortAvailability.length <= 0){
					outofstock4tharray.push(part);
				}else{

						rowArray4th.push(part);

				}
			}
			if(part.modifiedcostPrice> 4*roundUptotalCount && part.modifiedcostPrice <= 5*roundUptotalCount){
				if(  part.sortAvailability.length <= 0){
					outofstock5tharray.push(part);
				}else{

				rowArray5th.push(part);

				}
			}
		})

		this.priceSeparationTableView("$"+ 5*roundUptotalCount +" - "+ "$"+ 4*roundUptotalCount,rowArray5th,rowArray);
		this.priceSeparationTableView("$"+ 4*roundUptotalCount +" - "+ "$"+ 3*roundUptotalCount,rowArray4th,rowArray);
		this.priceSeparationTableView("$"+ 3*roundUptotalCount +" - "+ "$"+ 2*roundUptotalCount,rowArray3rd,rowArray);
		this.priceSeparationTableView("$"+ 2*roundUptotalCount +" - "+ "$" + roundUptotalCount,rowArray2nd,rowArray);
		this.priceSeparationTableView( "$"+ roundUptotalCount +" - "+"$"+  minpriceCount,rowArray1st,rowArray);

		this.priceSeparationTableView("$"+ 5*roundUptotalCount +" - "+ "$"+ 4*roundUptotalCount,outofstock5tharray,rowArray);
		this.priceSeparationTableView("$"+ 4*roundUptotalCount +" - "+ "$"+ 3*roundUptotalCount,outofstock4tharray,rowArray);
		this.priceSeparationTableView("$"+ 3*roundUptotalCount +" - "+ "$"+ 2*roundUptotalCount,outofstock3rdarray,rowArray);
		this.priceSeparationTableView("$"+ 2*roundUptotalCount +" - "+ "$" + roundUptotalCount,outofstock2ndarray,rowArray);
		this.priceSeparationTableView( "$"+ roundUptotalCount +" - "+"$"+  minpriceCount,outofstock1starray,rowArray);
		return rowArray;

	}
priceSeparationTableView =(br , array ,rowArray)=>{
	if(array.length > 0){
		let countItems= array.length;
		rowArray.push({
				part: {
					partNumber: br,
					alternate: [],
					coverageText: "",
					description: "",
					descriptionID: "",
					extendedDescription: "",
					genericDescription: "",
					genericNumber: "",
					gfxAvailable: true,
					groupNumber: "",
					lineCodes: "",
					mediaTag: "",
					mfgName: "",
					miscellaneousTexts: [],
					orderNumber: "",
					partTypeDescriptions: {
						id: "", text: ""
					},
					perCarQty: "",
					position: "",
					unansweredSpecifics: [],
					years: "",
					sku: "",
					substitute: [],
					szPerCarQty: "",
					bundleToolTip: null,

					pricingAvailability: {
						availability: {
							availableDuration: "",
							availableQty: "",
							availableTime: "",
							code: ""
						},
						familyToolTip: {},
						bundleToolTip: null,
						buyQtyWarning: "",
						costPrice: "",
						extendedCostPrice: "",
						extendedListPrice: "",
						familyToolTip: null,
						id: "",
						inStock: true,
						itemSpecific: "",
						listPrice: "",
						mfgCode: "",
						packCode: "",
						packQty: "",
						partNum: "",
						qtyBuyInc: "",
						qtyPerVeh: ""

					}
				},
				items:countItems,
				groupby: true,
				isExapand: true,
				brand: "",
				description: "",
				availabilities: "",
				availability: "",
				extendedPrice: "",
				hiddenAvail: false,
				links: "",
				listprice: "",
				qtypervehicle: "",
				qtytoorder: "",
				totalAvailable: "",
				validationRed: false,
				yourprice: "",
				rowMoreInfo: ""

			})

			array.map((v ,index)=>{
				rowArray.push(v);
			})
		}
}
	defaultSepartionView = (rowsdata) => {
		let rowArray =[];
		rowsdata.map((part, index) => {
		  rowArray.push(part)
		   })

		return rowArray;
	}
	partTypeSepartion = (rows) => {
		let sortedBrandArray = [];
		let rowArray = [];
        let outofStockRows = [];
		let outofstockmainarray =[];
		rows.map((row, index) => {
			let extendedDescription = (row.part.extendedDescription.toUpperCase())
			if (sortedBrandArray === null || sortedBrandArray === "" || sortedBrandArray === undefined) {

				sortedBrandArray.push(
					extendedDescription
				)
			}
			else if (sortedBrandArray !== null &&  row.sortAvailability.length > 0 && !sortedBrandArray.includes(extendedDescription)) {
               sortedBrandArray.push(extendedDescription)
			}
			else if(row.sortAvailability.length <= 0 && outofStockRows !== null && !outofStockRows.includes(extendedDescription)){
				outofStockRows.push(extendedDescription)
			}

		})

		sortedBrandArray.map((br, index) => {
			let countItems ;
			const countBrandItems = rows.filter(item => item.sortAvailability.length > 0 && item.part.extendedDescription.toUpperCase() === br).length
			countItems = countBrandItems;

			rowArray.push({
				part: {
					partNumber: br,
					alternate: [],
					coverageText: "",
					description: "",
					descriptionID: "",
					extendedDescription: "",
					genericDescription: "",
					genericNumber: "",
					gfxAvailable: true,
					groupNumber: "",
					lineCodes: "",
					mediaTag: "",
					mfgName: "",
					miscellaneousTexts: [],
					orderNumber: "",
					partTypeDescriptions: {
						id: "", text: ""
					},
					perCarQty: "",
					position: "",
					unansweredSpecifics: [],
					years: "",
					sku: "",
					substitute: [],
					szPerCarQty: "",
					bundleToolTip: null,

					pricingAvailability: {
						availability: {
							availableDuration: "",
							availableQty: "",
							availableTime: "",
							code: ""
						},
						familyToolTip: {},
						bundleToolTip: null,
						buyQtyWarning: "",
						costPrice: "",
						extendedCostPrice: "",
						extendedListPrice: "",
						familyToolTip: null,
						id: "",
						inStock: true,
						itemSpecific: "",
						listPrice: "",
						mfgCode: "",
						packCode: "",
						packQty: "",
						partNum: "",
						qtyBuyInc: "",
						qtyPerVeh: ""

					}
				},
				items:countItems,
				groupby: true,
				isExapand: true,
				brand: "",
				description: "",
				availabilities: countItems,
				availability: "",
				extendedPrice: "",
				hiddenAvail: false,
				links: "",
				listprice: "",
				qtypervehicle: "",
				qtytoorder: "",
				totalAvailable: "",
				validationRed: false,
				yourprice: "",
				rowMoreInfo: ""

			})
			rows.map((part, index) => {
				if (br == part.part.extendedDescription.toUpperCase()  &&  part.sortAvailability.length > 0 ) {
					rowArray.push(
						{
							autoFocus:part.autoFocus,
							part: part.part,
							groupby: false,
							brand: part.brand,
              corePrice:part.corePrice,
							coreListPrice :part.coreListPrice,
							description: part.description,
							availabilities: part.availabilities,
							availability: part.availability,
							extendedPrice: part.extendedPrice,
							hiddenAvail: part.hiddenAvail,
							links: part.links,
							listprice: part.listprice,
							qtypervehicle: part.qtypervehicle,
							qtyBuyInc: part.qtyBuyInc,
							qtytoorder: part.qtytoorder,
							totalAvailable: part.totalAvailable,
							validationRed: part.validationRed,
							yourprice: part.yourprice,
							rowMoreInfo: part.rowMoreInfo,
						}
					)
				}
			})


	})
	outofStockRows.map((br, index) => {
		let countItems ;
		const countBrandItems = rows.filter(item => item.sortAvailability.length <=0 && item.part.extendedDescription.toUpperCase() === br).length
		countItems = countBrandItems;
 outofstockmainarray.push({
			part: {
				partNumber: br,
				alternate: [],
				coverageText: "",
				description: "",
				descriptionID: "",
				extendedDescription: "",
				genericDescription: "",
				genericNumber: "",
				gfxAvailable: true,
				groupNumber: "",
				lineCodes: "",
				mediaTag: "",
				mfgName: "",
				miscellaneousTexts: [],
				orderNumber: "",
				partTypeDescriptions: {
					id: "", text: ""
				},
				perCarQty: "",
				position: "",
				unansweredSpecifics: [],
				years: "",
				sku: "",
				substitute: [],
				szPerCarQty: "",
				bundleToolTip: null,

				pricingAvailability: {
					availability: {
						availableDuration: "",
						availableQty: "",
						availableTime: "",
						code: ""
					},
					familyToolTip: {},
					bundleToolTip: null,
					buyQtyWarning: "",
					costPrice: "",
					extendedCostPrice: "",
					extendedListPrice: "",
					familyToolTip: null,
					id: "",
					inStock: true,
					itemSpecific: "",
					listPrice: "",
					mfgCode: "",
					packCode: "",
					packQty: "",
					partNum: "",
					qtyBuyInc: "",
					qtyPerVeh: ""

				}
			},
			items:countItems,
			groupby: true,
			isExapand: true,
			brand: "",
			description: "",
			availabilities: countItems,
			availability: "",
			extendedPrice: "",
			hiddenAvail: false,
			links: "",
			listprice: "",
			qtypervehicle: "",
			qtytoorder: "",
			totalAvailable: "",
			validationRed: false,
			yourprice: "",
			rowMoreInfo: ""

		})
		rows.map((part, index) => {
			if (br == part.part.extendedDescription.toUpperCase()  &&  part.sortAvailability.length <= 0 ) {
				outofstockmainarray.push(
					{
						autoFocus:part.autoFocus,
						part: part.part,
						groupby: false,
						brand: part.brand,
						description: part.description,
						availabilities: part.availabilities,
						availability: part.availability,
						extendedPrice: part.extendedPrice,
						hiddenAvail: part.hiddenAvail,
						links: part.links,
						listprice: part.listprice,
            corePrice:part.corePrice,
            coreListPrice :part.coreListPrice,
						qtypervehicle: part.qtypervehicle,
						qtyBuyInc: part.qtyBuyInc,
						qtytoorder: part.qtytoorder,
						totalAvailable: part.totalAvailable,
						validationRed: part.validationRed,
						yourprice: part.yourprice,
						rowMoreInfo: part.rowMoreInfo,
					}
				)
			}
		})


})

return rowArray.concat(outofstockmainarray);

	}
	brandpart = (rowsdata) => {
		//let groupPartsByBrand = _.mapValues(_.groupBy(rowsdata, "brand"));
		const rowData = [];
		const rowhead = [];
		let rowArray = [];

		let outofStockRows = [];
		let outofstockmainarray =[];
		rowsdata.map((row, index) => {
			let extendedDescription = row.brand;
			if (rowhead === null || rowhead === "" || rowhead === undefined) {

				rowhead.push(
					extendedDescription
				)
			}
			else if (rowhead !== null &&  row.sortAvailability.length >0 && !rowhead.includes(extendedDescription)) {
				rowhead.push(extendedDescription)
			}
			else if(row.sortAvailability.length <= 0 && outofStockRows !== null && !outofStockRows.includes(extendedDescription)){
				outofStockRows.push(extendedDescription)
			}

		})

		rowhead.map((br, index) => {
			let countItems ;
			const countBrandItems = rowsdata.filter(item => item.sortAvailability.length >0 && item.brand === br).length
			countItems = countBrandItems;

			rowArray.push({
				part: {
					partNumber: br,
					alternate: [],
					coverageText: "",
					description: "",
					descriptionID: "",
					extendedDescription: "",
					genericDescription: "",
					genericNumber: "",
					gfxAvailable: true,
					groupNumber: "",
					lineCodes: "",
					mediaTag: "",
					mfgName: "",
					miscellaneousTexts: [],
					orderNumber: "",
					partTypeDescriptions: {
						id: "", text: ""
					},
					perCarQty: "",
					position: "",
					unansweredSpecifics: [],
					years: "",
					sku: "",
					substitute: [],
					szPerCarQty: "",
					bundleToolTip: null,
					pricingAvailability: {
						availability: {
							availableDuration: "",
							availableQty: "",
							availableTime: "",
							code: ""
						},
						familyToolTip: {},
						bundleToolTip: null,
						buyQtyWarning: "",
						costPrice: "",
						extendedCostPrice: "",
						extendedListPrice: "",
						familyToolTip: null,
						id: "",
						inStock: true,
						itemSpecific: "",
						listPrice: "",
						mfgCode: "",
						packCode: "",
						packQty: "",
						partNum: "",
						qtyBuyInc: "",
						qtyPerVeh: ""

					}
				},
				groupby: true,
				isExapand: true,
				brand: "",
				description: "",
				availabilities:"",
				items:countItems,
				availability:"",
				extendedPrice: "",
				hiddenAvail: false,
				links: "",
				listprice: "",
				qtypervehicle: "",
				qtytoorder: "",
				totalAvailable: "",
				validationRed: false,
				yourprice: "",
				rowMoreInfo: ""

			})
			rowsdata.map((part, index) => {
				if (br == part.brand && part.sortAvailability.length >0) {
					rowArray.push(
						{  autoFocus:part.autoFocus,
							 rowId:part.part.partNumber,
							part: part.part,
							groupby: false,
							brand: part.brand,
              corePrice:part.corePrice,
							coreListPrice :part.coreListPrice,
							description: part.description,
							availabilities: part.availabilities,
							availability: part.availability,
							extendedPrice: part.extendedPrice,
							hiddenAvail: part.hiddenAvail,
							links: part.links,
							listprice: part.listprice,
							qtypervehicle: part.qtypervehicle,
							qtyBuyInc: part.qtyBuyInc,
							qtytoorder: part.qtytoorder,
							totalAvailable: part.totalAvailable,
							validationRed: part.validationRed,
							yourprice: part.yourprice,
							rowMoreInfo: part.rowMoreInfo,
						}
					)
				}
			})
		})

		outofStockRows.map((br, index) => {
			let countItems ;
			const countBrandItems = rowsdata.filter(item => item.sortAvailability.length <=0 && item.brand === br).length
			countItems = countBrandItems;

			outofstockmainarray.push({
				part: {
					partNumber: br,
					alternate: [],
					coverageText: "",
					description: "",
					descriptionID: "",
					extendedDescription: "",
					genericDescription: "",
					genericNumber: "",
					gfxAvailable: true,
					groupNumber: "",
					lineCodes: "",
					mediaTag: "",
					mfgName: "",
					miscellaneousTexts: [],
					orderNumber: "",
					partTypeDescriptions: {
						id: "", text: ""
					},
					perCarQty: "",
					position: "",
					unansweredSpecifics: [],
					years: "",
					sku: "",
					substitute: [],
					szPerCarQty: "",
					bundleToolTip: null,
					pricingAvailability: {
						availability: {
							availableDuration: "",
							availableQty: "",
							availableTime: "",
							code: ""
						},
						familyToolTip: {},
						bundleToolTip: null,
						buyQtyWarning: "",
						costPrice: "",
						extendedCostPrice: "",
						extendedListPrice: "",
						familyToolTip: null,
						id: "",
						inStock: true,
						itemSpecific: "",
						listPrice: "",
						mfgCode: "",
						packCode: "",
						packQty: "",
						partNum: "",
						qtyBuyInc: "",
						qtyPerVeh: ""

					}
				},
				groupby: true,
				isExapand: true,
				brand: "",
				description: "",
				availabilities:"",
				items:countItems,
				availability:"",
				extendedPrice: "",
				hiddenAvail: false,
				links: "",
				listprice: "",
				qtypervehicle: "",
				qtytoorder: "",
				totalAvailable: "",
				validationRed: false,
				yourprice: "",
				rowMoreInfo: ""

			})
			rowsdata.map((part, index) => {
				if (br == part.brand && part.sortAvailability.length <=0) {
					outofstockmainarray.push(
						{  autoFocus:part.autoFocus,
							 rowId:part.part.partNumber,
							part: part.part,
							groupby: false,
							brand: part.brand,
							description: part.description,
							availabilities: part.availabilities,
							availability: part.availability,
							extendedPrice: part.extendedPrice,
							hiddenAvail: part.hiddenAvail,
							links: part.links,
              corePrice:part.corePrice,
							coreListPrice :part.coreListPrice,
							listprice: part.listprice,
							qtypervehicle: part.qtypervehicle,
							qtyBuyInc: part.qtyBuyInc,
							qtytoorder: part.qtytoorder,
							totalAvailable: part.totalAvailable,
							validationRed: part.validationRed,
							yourprice: part.yourprice,
							rowMoreInfo: part.rowMoreInfo,
						}
					)
				}
			})
		})
		return rowArray.concat(outofstockmainarray);
	}

  prepareTablePartsData = () => {
    const {
      dispatch,
      laborGuideData,
      selectedGroupDetails,
      selectedVehicle,
      userDetails,
      laborApiCall,
      catalogPageNumber,
      catalogTotalPage,
    } = this.props;
    let {
      buyersGuideBrandData,
      buyersGuideData,
      partsAllDetails,
      partsQtySum,
      selectedStore,
      sellPartnerId,
      shipMethod,
    } = this.state;

    let defualtTotalQuantity = this.setDefalutProductQuantity();

    if (!_isEmpty(partsAllDetails)) {
      let productArray = [];
      let rows = [];
      let rowsMoreDetails = [];
      let autofocusIndexNumber = 0;
			let iterator = true;
      if (partsAllDetails.length > 0) {
        partsAllDetails.map((magicValue, index) => {
					if (iterator) {
						autofocusIndexNumber = index;
						iterator = false;
					}
				})
        partsAllDetails.map((part , index) => {
          let appendPart =
            (part.alternate && part.alternate.length > 0) ||
            (part.substitute && part.substitute.length > 0);
          let altPartNumsArr = [];
          let subPartNumsArr = [];
          const altPartNums =
            part.alternate && part.alternate.length > 0
              ? part.alternate.map((alt) => {
                  altPartNumsArr.push(alt.partNumber);
                })
              : "";
          const subPartNums =
            part.substitute && part.substitute.length > 0
              ? part.substitute.map((sub) => {
                  subPartNumsArr.push(sub.partNumber);
                })
              : "";
          rows.push({
            availability: 1,
            part: part,
            description: part.description,
            brand: part.mfgName,
            links: "link",
            sortAvailability : part.pricingAvailability && part.pricingAvailability.availability,
            modifiedcostPrice:part.pricingAvailability && part.pricingAvailability.costPrice,
            yourprice:
              part.pricingAvailability && part.pricingAvailability.costPrice,
            listprice:
              part.pricingAvailability && part.pricingAvailability.listPrice,
              corePrice: part.pricingAvailability && part.pricingAvailability.corePrice,
              coreListPrice : part.pricingAvailability && part.pricingAvailability.coreListPrice,
              extendedPrice :  defualtTotalQuantity ? getExtendedTotalPrice( part.pricingAvailability.corePrice , part.pricingAvailability.costPrice ,part.perCarQty) : 0,
            qtypervehicle: part.perCarQty,
			qtyBuyInc: part.pricingAvailability.qtyBuyInc,
            availabilities: [],
            hiddenAvail: false,
            validationRed: false,
            totalAvailable: 0,
            autoFocus:  index == autofocusIndexNumber ? true : false,
            qtytoorder: defualtTotalQuantity ? part.pricingAvailability.qtyBuyInc > 1 ? part.pricingAvailability.qtyBuyInc : part.perCarQty : '',
            rowMoreInfo: {
							buyersGuideData: buyersGuideData,
							buyersGuideBrandData: buyersGuideBrandData,
							selectedVehicle: selectedVehicle,
							laborGuideData: laborGuideData,
							partDetails: part,
							partType: 'part',
							dispatch: dispatch,
							selectedGroupDetails: selectedGroupDetails,
							partsQtySum: partsQtySum[part.partNum],
							userDetails: userDetails,
							laborApiCall: laborApiCall,
							appendPart: appendPart,
							altPartNums: altPartNumsArr.join(','),
							subPartNums: subPartNumsArr.join(',')
						}

          });

          rowsMoreDetails.push({
            buyersGuideData: buyersGuideData,
            buyersGuideBrandData: buyersGuideBrandData,
            selectedVehicle: selectedVehicle,
            laborGuideData: laborGuideData,
            partDetails: part,
            partType: "part",
            dispatch: dispatch,
            selectedGroupDetails: selectedGroupDetails,
            partsQtySum: partsQtySum[part.partNum],
            userDetails: userDetails,
            laborApiCall: laborApiCall,
            appendPart: appendPart,
            altPartNums: altPartNumsArr.join(","),
            subPartNums: subPartNumsArr.join(","),
          });
          //alternate parts
          if (part.alternate && part.alternate.length > 0) {
            part.alternate.map((altPart, index) => {
              let customAltpart = _cloneDeep(altPart);
							// customAltpartextendedDescription

						  customAltpart['extendedDescription']=part['extendedDescription']
							customAltpart['mfgName'] = customAltpart.mfgName ? customAltpart.mfgName : part['mfgName']
							customAltpart.modifiedcostPrice = part.pricingAvailability.costPrice;
							customAltpart.sortAvailability = part.pricingAvailability.availability;
              let appendAltPart =
                index !== part.alternate.length - 1 ||
                (part.substitute && part.substitute.length > 0);
              rows.push({
                availability: 1,
                part: customAltpart,
                description: altPart.description,
                brand: customAltpart.mfgName,
                links: "",
                sortAvailability :customAltpart.sortAvailability ,
                modifiedcostPrice:customAltpart.modifiedcostPrice,
                yourprice:
                  altPart.pricingAvailability &&
                  altPart.pricingAvailability.costPrice,
                listprice:
                  altPart.pricingAvailability &&
                  altPart.pricingAvailability.listPrice,
                  corePrice: altPart.pricingAvailability && altPart.pricingAvailability.corePrice,
                  coreListPrice : altPart.pricingAvailability && altPart.pricingAvailability.coreListPrice,
                  extendedPrice : defualtTotalQuantity ? getExtendedTotalPrice( altPart.pricingAvailability.corePrice , altPart.pricingAvailability.costPrice ,altPart.perCarQty): 0,
                qtypervehicle: altPart.perCarQty,
				qtyBuyInc: altPart.pricingAvailability.qtyBuyInc,
                buyersGuideData: buyersGuideData,
                availabilities: [],
                hiddenAvail: false,
                validationRed: false,
                totalAvailable: 0,
                qtytoorder: defualtTotalQuantity ? altPart.pricingAvailability.qtyBuyInc > 1 ? altPart.pricingAvailability.qtyBuyInc : altPart.perCarQty : '',
                rowMoreInfo: {
                  buyersGuideData: buyersGuideData,
                  buyersGuideBrandData: buyersGuideBrandData,
                  selectedVehicle: selectedVehicle,
                  laborGuideData: laborGuideData,
                  partDetails: altPart,
                  isAltType: true,
                  partType: 'part',
                  dispatch: dispatch,
                  selectedGroupDetails: selectedGroupDetails,
                  partsQtySum: partsQtySum[altPart.partNum],
                  userDetails: userDetails,
                  laborApiCall: laborApiCall,
                  altPartNums : part.partNumber,
                  appendPart: appendPart
                  }
              });
              rowsMoreDetails.push({
                buyersGuideData: buyersGuideData,
                buyersGuideBrandData: buyersGuideBrandData,
                selectedVehicle: selectedVehicle,
                laborGuideData: laborGuideData,
                partDetails: altPart,
                isAltType: true,
                partType: "part",
                dispatch: dispatch,
                selectedGroupDetails: selectedGroupDetails,
                partsQtySum: partsQtySum[altPart.partNum],
                userDetails: userDetails,
                laborApiCall: laborApiCall,
                altPartNums: part.partNumber,
                appendPart: appendPart,
              });
            });
          }
          //substitute parts
          if (part.substitute && part.substitute.length > 0) {
            part.substitute.map((subPart, index) => {
        			let customAltpart = _cloneDeep(subPart);
							// customAltpartextendedDescription

						  customAltpart['extendedDescription']=part['extendedDescription']
              customAltpart['mfgName'] = customAltpart.mfgName ? customAltpart.mfgName : part['mfgName']
							customAltpart.modifiedcostPrice = part.pricingAvailability.costPrice;
							customAltpart.sortAvailability = part.pricingAvailability.availability
              let appendSubPart = index !== part.substitute.length - 1;
              rows.push({
                availability: 1,
                part: customAltpart,
                description: subPart.description,
                extendedDescription:customAltpart.extendedDescription,
                sortAvailability :customAltpart.sortAvailability,
                brand: customAltpart.mfgName,
                links: "",
                modifiedcostPrice:customAltpart.modifiedcostPrice,
                yourprice:
                  subPart.pricingAvailability &&
                  subPart.pricingAvailability.costPrice,
                listprice:
                  subPart.pricingAvailability &&
                  subPart.pricingAvailability.listPrice,
                  corePrice: subPart.pricingAvailability && subPart.pricingAvailability.corePrice,
                  coreListPrice : subPart.pricingAvailability && subPart.pricingAvailability.coreListPrice,
                  extendedPrice :  defualtTotalQuantity ? getExtendedTotalPrice( subPart.pricingAvailability.corePrice , subPart.pricingAvailability.costPrice ,subPart.perCarQty) : 0,
                qtypervehicle: subPart.perCarQty,
				qtyBuyInc: subPart.pricingAvailability.qtyBuyInc,
                buyersGuideData: buyersGuideData,
                availabilities: [],
                showMoreAvailability: false,
                hiddenAvail: false,
                validationRed: false,
                totalAvailable: 0,
                qtytoorder: defualtTotalQuantity ? subPart.pricingAvailability.qtyBuyInc > 1 ? subPart.pricingAvailability.qtyBuyInc : subPart.perCarQty : '',
                rowMoreInfo: {
                  buyersGuideData: buyersGuideData,
                  buyersGuideBrandData: buyersGuideBrandData,
                  selectedVehicle: selectedVehicle,
                  laborGuideData: laborGuideData,
                  partDetails: subPart,
                  isSubType: true,
                  partType: 'part',
                  dispatch: dispatch,
                  selectedGroupDetails: selectedGroupDetails,
                  partsQtySum: partsQtySum[subPart.partNum],
                  userDetails: userDetails,
                  laborApiCall: laborApiCall,
                  subPartNums: part.partNumber,
                  appendPart: appendPart
                    }
              });
              rowsMoreDetails.push({
                buyersGuideData: buyersGuideData,
                buyersGuideBrandData: buyersGuideBrandData,
                selectedVehicle: selectedVehicle,
                laborGuideData: laborGuideData,
                partDetails: subPart,
                isSubType: true,
                partType: "part",
                dispatch: dispatch,
                selectedGroupDetails: selectedGroupDetails,
                partsQtySum: partsQtySum[subPart.partNum],
                userDetails: userDetails,
                laborApiCall: laborApiCall,
                subPartNums: part.partNumber,
                appendPart: appendPart,
              });
            });
          }
        });
        let partTypeAndBrandSepartion = this.separtionCodeForBrandAndParType(rows);
        productArray.push(
          <TableViewWrapper
            userDetails={userDetails}
            rows={partTypeAndBrandSepartion}
            rowsMoreDetails={rowsMoreDetails}
            selectedStore={selectedStore}
            sellPartnerId={sellPartnerId}
            shipMethod={shipMethod}
          />
        );
        productArray.push(
          <PaginationComponent
            handlePageChange={this.handlePageChange}
            currentPage={catalogPageNumber}
            totalPages={catalogTotalPage}
          />
        );
      } else {
        productArray.push(this.renderNoDataState());
      }
      return productArray;
    } else {
      return this.renderNoDataState();
    }
  };
  separtionCodeForBrandAndParTypeSingleView = (data) => {
		let { currentSort, defaultSortChanged} = this.state;
		const userPreferenceDetail = getPreferenceDataFromStorage();
		let userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
		let rows = [];
		if (currentSort == "Brand (A to Z)" || currentSort == 'Brand (Z to A)') {
			rows = this.brandpartsingleview(data);
		}
		if (currentSort == "Part type (A to Z)" || currentSort == "Part type (Z to A)") {
			rows = this.partTypeSepartionsingleview(data);
		}
		if(currentSort == "Price (Low to High)"){
			rows = this.priceLowToHigh(data);
		}
		if(currentSort == "Price (High to Low)"){
			rows = this.PriceHighToLow(data);
		}
		if (currentSort == "Default") {
			rows = this.defaultSepartionSingleView(data);
		}
		return rows;
	}


	priceLowToHigh=(rowsdata) =>{
		const {
			minPrice,
			 maxPrice
			 } = this.props;
		// const totals = rowsdata.map(x => x.pricingAvailability.listPrice);
		// let maxPrice = Math.max(...totals);
		// let minPrice = Math.min(...totals);
		let minpriceCount = Math.floor(minPrice);
	    let totalCount  = (maxPrice + minPrice)/5;
		let roundUptotalCount = Math.ceil(totalCount);
		let rowArray =[];
		let rowArray1st =[];
		let rowArray2nd =[];
		let rowArray3rd =[];
		let rowArray4th =[];
		let rowArray5th =[];
		let outofstock1starray =[];
		let outofstock2ndarray =[];
		let outofstock3rdarray =[];
		let outofstock4tharray =[];
		let outofstock5tharray =[];
		rowsdata.map((part, index) => {
			if(part.pricingAvailability.costPrice <= roundUptotalCount){
				if(part.pricingAvailability.availability.length <= 0  ){
					outofstock1starray.push(part);
				}else{

					rowArray1st.push(part);

				}
			}
			if(part.pricingAvailability.costPrice > roundUptotalCount && part.pricingAvailability.costPrice <= 2*roundUptotalCount ){

				if(part.pricingAvailability.availability.length <= 0  ){
					outofstock2ndarray.push(part);
				}else{

					rowArray2nd.push(part)

				}
			}
			if(part.pricingAvailability.costPrice > 2*roundUptotalCount && part.pricingAvailability.costPrice <= 3*roundUptotalCount ){
				if(part.pricingAvailability.availability.length <= 0 ){
					outofstock3rdarray.push(part);
				}else{

						rowArray3rd.push(part);

				}
			}
			if(part.pricingAvailability.costPrice > 3*roundUptotalCount && part.pricingAvailability.costPrice <= 4*roundUptotalCount){
				if( part.pricingAvailability.availability.length <= 0){
					outofstock4tharray.push(part);
				}else{

						rowArray4th.push(part);

				}
			}
			if(part.pricingAvailability.costPrice > 4*roundUptotalCount && part.pricingAvailability.costPrice <= 5*roundUptotalCount){
				if( part.pricingAvailability.availability.length <=0){
					outofstock5tharray.push(part);
				}else{

				rowArray5th.push(part);

				}
			}
		})
		this.priceSeparationSingleView("(" + "$"+ minpriceCount +" - "+"$"+ roundUptotalCount + ")",rowArray1st,rowArray);
		this.priceSeparationSingleView("(" + "$"+ roundUptotalCount +" - "+ "$" + 2*roundUptotalCount + ")",rowArray2nd,rowArray);
		this.priceSeparationSingleView("(" + "$"+ 2*roundUptotalCount +" - "+ "$"+ 3*roundUptotalCount + ")",rowArray3rd,rowArray);
		this.priceSeparationSingleView("(" + "$"+ 3*roundUptotalCount +" - "+ "$"+ 4*roundUptotalCount + ")",rowArray4th,rowArray);
		this.priceSeparationSingleView("(" +"$"+ 4*roundUptotalCount +" - "+ "$"+ 5*roundUptotalCount + ")",rowArray5th,rowArray);

		this.priceSeparationSingleView("(" + "$"+ minpriceCount +" - "+"$"+ roundUptotalCount + ")",outofstock1starray,rowArray);
		this.priceSeparationSingleView("(" + "$"+ roundUptotalCount +" - "+ "$" + 2*roundUptotalCount + ")",outofstock2ndarray,rowArray);
		this.priceSeparationSingleView("(" + "$"+ 2*roundUptotalCount +" - "+ "$"+ 3*roundUptotalCount + ")",outofstock3rdarray,rowArray);
		this.priceSeparationSingleView("(" + "$"+ 3*roundUptotalCount +" - "+ "$"+ 4*roundUptotalCount + ")",outofstock4tharray,rowArray);
		this.priceSeparationSingleView("(" +"$"+ 4*roundUptotalCount +" - "+ "$"+ 5*roundUptotalCount + ")",outofstock5tharray,rowArray);

		return rowArray;

	}
	PriceHighToLow=(rowsdata) =>{
		const {
			minPrice,
			 maxPrice
			 } = this.props;
		// const totals = rowsdata.map(x => x.pricingAvailability.listPrice);
		// let maxPrice =Math.max(...totals);
		// let minPrice = Math.min(...totals);
		let minpriceCount =Math.floor(minPrice);
	    let totalCount  = (maxPrice + minPrice)/5;
		let roundUptotalCount = Math.ceil(totalCount);
		let rowArray =[];
		let rowArray1st =[];
		let rowArray2nd =[];
		let rowArray3rd =[];
		let rowArray4th =[];
		let rowArray5th =[];
		let outofstock1starray =[];
		let outofstock2ndarray =[];
		let outofstock3rdarray =[];
		let outofstock4tharray =[];
		let outofstock5tharray =[];
		rowsdata.map((part, index) => {
			if(part.pricingAvailability.costPrice <= roundUptotalCount){
				if(part.pricingAvailability.availability.length <= 0  ){
					outofstock1starray.push(part);
				}else{

					rowArray1st.push(part);

				}
			}
			if(part.pricingAvailability.costPrice > roundUptotalCount && part.pricingAvailability.costPrice <= 2*roundUptotalCount ){

				if(part.pricingAvailability.availability.length <= 0  ){
					outofstock2ndarray.push(part);
				}else{

					rowArray2nd.push(part)

				}
			}
			if(part.pricingAvailability.costPrice > 2*roundUptotalCount && part.pricingAvailability.costPrice <= 3*roundUptotalCount ){
				if(part.pricingAvailability.availability.length <= 0 ){
					outofstock3rdarray.push(part);
				}else{

						rowArray3rd.push(part);

				}
			}
			if(part.pricingAvailability.costPrice > 3*roundUptotalCount && part.pricingAvailability.costPrice <= 4*roundUptotalCount){
				if( part.pricingAvailability.availability.length <= 0){
					outofstock4tharray.push(part);
				}else{

						rowArray4th.push(part);

				}
			}
			if(part.pricingAvailability.costPrice > 4*roundUptotalCount && part.pricingAvailability.costPrice <= 5*roundUptotalCount){
				if( part.pricingAvailability.availability.length <=0){
					outofstock5tharray.push(part);
				}else{

				rowArray5th.push(part);

				}
			}
		})
		this.priceSeparationSingleView("(" + "$"+ 5*roundUptotalCount +" - "+ "$"+ 4*roundUptotalCount + ")",rowArray5th,rowArray);
		this.priceSeparationSingleView("(" + "$"+ 4*roundUptotalCount +" - "+ "$"+ 3*roundUptotalCount + ")",rowArray4th,rowArray);
		this.priceSeparationSingleView("(" + "$"+ 3*roundUptotalCount +" - "+ "$"+ 2*roundUptotalCount + ")",rowArray3rd,rowArray);
		this.priceSeparationSingleView("(" + "$"+ 2*roundUptotalCount +" - "+ "$" + roundUptotalCount + ")",rowArray2nd,rowArray);
		this.priceSeparationSingleView("(" + "$"+ roundUptotalCount +" - "+"$"+  minpriceCount + ")",rowArray1st,rowArray);

		this.priceSeparationSingleView("(" + "$"+ 5*roundUptotalCount +" - "+ "$"+ 4*roundUptotalCount + ")",outofstock5tharray,rowArray);
		this.priceSeparationSingleView("(" + "$"+ 4*roundUptotalCount +" - "+ "$"+ 3*roundUptotalCount + ")",outofstock4tharray,rowArray);
		this.priceSeparationSingleView("(" + "$"+ 3*roundUptotalCount +" - "+ "$"+ 2*roundUptotalCount + ")",outofstock3rdarray,rowArray);
		this.priceSeparationSingleView("(" + "$"+ 2*roundUptotalCount +" - "+ "$" + roundUptotalCount + ")",outofstock2ndarray,rowArray);
		this.priceSeparationSingleView("(" + "$"+ roundUptotalCount +" - "+"$"+  minpriceCount + ")",outofstock1starray,rowArray);

		return rowArray;

	}
priceSeparationSingleView =(br , array ,rowArray)=>{
	if(array.length > 0){
		let countItems= array.length;
		rowArray.push({
			alternate: [],
			coverageText: "",
			description: br,
			descriptionID: "",
			extendedDescription: "",
			genericDescription: "",
			genericNumber: "",
			gfxAvailable: false,
			groupNumber: "",
			image: "",
			images: [],
			lineCodes: [],
			mediaTag: "",
			mfgName: "",
			miscellaneousTexts: [],
			orderNumber: "",
			partNumber: "",
			partTypeDescriptions: [
				{ id: "", text: "" }
			],
			perCarQty: "",
			position: "",
			pricingAvailability: {

				availability: [],
				bundleToolTip: "",
				buyQtyWarning: "",
				costPrice: "",
				extendedCostPrice: "",
				extendedListPrice: "",
				familyToolTip: "",
				id: "",
				inStock: "",
				itemSpecific: "",
				listPrice: countItems,
				mfgCode: "",
				packCode: "",
				packQty: "",
				partNum: "",
				qtyBuyInc: "",
				qtyPerVeh: "",
				qtyPriceBreakUp: [],
				status: "",
				toolTips: []

			},
			sku: "",
			substitute: [],
			szPerCarQty: "",
			unansweredSpecifics: [],
			years: "",

		})

			array.map((v ,index)=>{
				rowArray.push(v);
			})
		}
}

	defaultSepartionSingleView = (rowsdata) => {
		let rowArray =[];
		rowsdata.map((part, index) => {
		  rowArray.push(part)
		   })

		return rowArray;
	}
	brandpartsingleview = (rowsdata) => {
		//let groupPartsByBrand = _.mapValues(_.groupBy(rowsdata, "mfgName"));
		const rowData = [];
		const rowhead = [];
		let rowArray = [];
		let outofStockRows = [];
		let outofstockmainarray =[];
		rowsdata.map((row, index) => {
			let extendedDescription = row.mfgName;
			if (rowhead === null || rowhead === "" || rowhead === undefined) {

				rowhead.push(
					extendedDescription
				)
			}
			else if (rowhead !== null &&  row.pricingAvailability.availability.length >0 && !rowhead.includes(extendedDescription)) {
				rowhead.push(extendedDescription)
			}
			else if(row.pricingAvailability.availability.length <= 0 && outofStockRows !== null && !outofStockRows.includes(extendedDescription)){
				outofStockRows.push(extendedDescription)
			}

		})

		rowhead.map((br, index) => {
			let countItems ;
			const countBrandItems = rowsdata.filter(item => item.pricingAvailability.availability.length >0 && item.mfgName === br).length
			countItems = countBrandItems;
			rowArray.push({
				alternate: [],
				coverageText: "",
				description: br,
				descriptionID: "",
				extendedDescription: "",
				genericDescription: "",
				genericNumber: "",
				gfxAvailable: false,
				groupNumber: "",
				image: "",
				images: [],
				lineCodes: [],
				mediaTag: "",
				mfgName: "",
				miscellaneousTexts: [],
				orderNumber: "",
				partNumber: "",
				partTypeDescriptions: [
					{ id: "", text: "" }
				],
				perCarQty: "",
				position: "",
				pricingAvailability: {

					availability: [],
					bundleToolTip: "",
					buyQtyWarning: "",
					costPrice: "",
					extendedCostPrice: "",
					extendedListPrice: "",
					familyToolTip: "",
					id: "",
					inStock: "",
					itemSpecific: "",
					listPrice: countItems,
					mfgCode: "",
					packCode: "",
					packQty: "",
					partNum: "",
					qtyBuyInc: "",
					qtyPerVeh: "",
					qtyPriceBreakUp: [],
					status: "",
					toolTips: []

				},
				sku: "",
				substitute: [],
				szPerCarQty: "",
				unansweredSpecifics: [],
				years: "",

			})
			rowsdata.map((part, index) => {
				if (br == part.mfgName && part.pricingAvailability.availability.length >0) {
					rowArray.push(part);
				}
			})
		})

		outofStockRows.map((br, index) => {
			let countItems ;
			const countBrandItems = rowsdata.filter(item =>  item.pricingAvailability.availability.length <=0 && item.mfgName === br).length
			countItems = countBrandItems;
			outofstockmainarray.push({
				alternate: [],
				coverageText: "",
				description: br,
				descriptionID: "",
				extendedDescription: "",
				genericDescription: "",
				genericNumber: "",
				gfxAvailable: false,
				groupNumber: "",
				image: "",
				images: [],
				lineCodes: [],
				mediaTag: "",
				mfgName: "",
				miscellaneousTexts: [],
				orderNumber: "",
				partNumber: "",
				partTypeDescriptions: [
					{ id: "", text: "" }
				],
				perCarQty: "",
				position: "",
				pricingAvailability: {

					availability: [],
					bundleToolTip: "",
					buyQtyWarning: "",
					costPrice: "",
					extendedCostPrice: "",
					extendedListPrice: "",
					familyToolTip: "",
					id: "",
					inStock: "",
					itemSpecific: "",
					listPrice: countItems,
					mfgCode: "",
					packCode: "",
					packQty: "",
					partNum: "",
					qtyBuyInc: "",
					qtyPerVeh: "",
					qtyPriceBreakUp: [],
					status: "",
					toolTips: []

				},
				sku: "",
				substitute: [],
				szPerCarQty: "",
				unansweredSpecifics: [],
				years: "",

			})
			rowsdata.map((part, index) => {
				if (br == part.mfgName && part.pricingAvailability.availability.length <=0) {
					outofstockmainarray.push(part);
				}
			})
		})
		return rowArray.concat(outofstockmainarray);
	}

	partTypeSepartionsingleview = (rows) => {
		let sortedBrandArray = [];
		let rowArray = [];
		let outofStockRows = [];
		let outofstockmainarray =[];

		rows.map((row, index) => {
			let extendedDescription = (row.extendedDescription.toUpperCase())
			if (sortedBrandArray === null || sortedBrandArray === "" || sortedBrandArray === undefined) {

				sortedBrandArray.push(
					extendedDescription
				)
			} else if (sortedBrandArray !== null && row.pricingAvailability.availability.length >0 && !sortedBrandArray.includes(extendedDescription)) {

				sortedBrandArray.push(
					extendedDescription
				)
			}else if (outofStockRows !== null && row.pricingAvailability.availability.length <=0 && !outofStockRows.includes(extendedDescription)) {

				outofStockRows.push(
					extendedDescription
				)
			}

		})

		sortedBrandArray.map((br, index) => {
			let countItems ;
			const countBrandItems = rows.filter(item => item.pricingAvailability.availability.length >0 && item.extendedDescription.toUpperCase() === br).length
			countItems = countBrandItems;
			rowArray.push({
				alternate: [],
				coverageText: "",
				description: br,
				descriptionID: "",
				extendedDescription: "",
				genericDescription: "",
				genericNumber: "",
				gfxAvailable: false,
				groupNumber: "",
				image: "",
				images: [],
				lineCodes: [],
				mediaTag: "",
				mfgName: "",
				miscellaneousTexts: [],
				orderNumber: "",
				partNumber: "",
				partTypeDescriptions: [
					{ id: "", text: "" }
				],
				perCarQty: "",
				position: "",
				pricingAvailability: {

					availability: [],
					bundleToolTip: "",
					buyQtyWarning: "",
					costPrice: "",
					extendedCostPrice: "",
					extendedListPrice: "",
					familyToolTip: "",
					id: "",
					inStock: "",
					itemSpecific: "",
					listPrice: countItems,
					mfgCode: "",
					packCode: "",
					packQty: "",
					partNum: "",
					qtyBuyInc: "",
					qtyPerVeh: "",
					qtyPriceBreakUp: [],
					status: "",
					toolTips: []

				},
				sku: "",
				substitute: [],
				szPerCarQty: "",
				unansweredSpecifics: [],
				years: "",

			})
			rows.map((part, index) => {
				if (br == part.extendedDescription.toUpperCase() && part.pricingAvailability.availability.length >0) {
					rowArray.push(
						part
					)
				}
			})
		})

		outofStockRows.map((br, index) => {
			let countItems ;
			const countBrandItems = rows.filter(item => item.pricingAvailability.availability.length <=0 && item.extendedDescription.toUpperCase() === br).length
			countItems = countBrandItems;
			outofstockmainarray.push({
				alternate: [],
				coverageText: "",
				description: br,
				descriptionID: "",
				extendedDescription: "",
				genericDescription: "",
				genericNumber: "",
				gfxAvailable: false,
				groupNumber: "",
				image: "",
				images: [],
				lineCodes: [],
				mediaTag: "",
				mfgName: "",
				miscellaneousTexts: [],
				orderNumber: "",
				partNumber: "",
				partTypeDescriptions: [
					{ id: "", text: "" }
				],
				perCarQty: "",
				position: "",
				pricingAvailability: {

					availability: [],
					bundleToolTip: "",
					buyQtyWarning: "",
					costPrice: "",
					extendedCostPrice: "",
					extendedListPrice: "",
					familyToolTip: "",
					id: "",
					inStock: "",
					itemSpecific: "",
					listPrice: countItems,
					mfgCode: "",
					packCode: "",
					packQty: "",
					partNum: "",
					qtyBuyInc: "",
					qtyPerVeh: "",
					qtyPriceBreakUp: [],
					status: "",
					toolTips: []

				},
				sku: "",
				substitute: [],
				szPerCarQty: "",
				unansweredSpecifics: [],
				years: "",

			})
			rows.map((part, index) => {
				if (br == part.extendedDescription.toUpperCase() && part.pricingAvailability.availability.length <= 0) {
					outofstockmainarray.push(
						part
					)
				}
			})
		})
		return rowArray.concat(outofstockmainarray);


	}


  renderPartsData = () => {
    const {
      dispatch,
      laborGuideData,
      selectedGroupDetails,
      selectedVehicle,
      userDetails,
      laborApiCall,
      catalogPageNumber,
      catalogTotalPage,
    } = this.props;
    let {
      buyersGuideBrandData,
      buyersGuideData,
      partsAllDetails,
      partsQtySum,
      selectedStore,
      sellPartnerId,
      shipMethod,
    } = this.state;
    if (!_isEmpty(partsAllDetails)) {
      let productArray = [];
      let partTypeAndBrandSepartion = this.separtionCodeForBrandAndParTypeSingleView(partsAllDetails);
      if (partTypeAndBrandSepartion.length > 0) {
				partTypeAndBrandSepartion.map((part) => {
          let appendPart =
            (part.alternate && part.alternate.length > 0) ||
            (part.substitute && part.substitute.length > 0);
          let altPartNumsArr = [];
          let subPartNumsArr = [];
          const altPartNums =
            part.alternate && part.alternate.length > 0
              ? part.alternate.map((alt) => {
                  altPartNumsArr.push(alt.partNumber);
                })
              : "";
          const subPartNums =
            part.substitute && part.substitute.length > 0
              ? part.substitute.map((sub) => {
                  subPartNumsArr.push(sub.partNumber);
                })
              : "";
          productArray.push(
            <SingleProduct
              buyersGuideData={buyersGuideData}
              buyersGuideBrandData={buyersGuideBrandData}
              selectedVehicle={selectedVehicle || {}}
              laborGuideData={laborGuideData}
              partDetails={part}
              partType={"part"}
              dispatch={dispatch}
              selectedGroupDetails={selectedGroupDetails}
              partsQtySum={partsQtySum[part.partNum]}
              userDetails={userDetails}
              laborApiCall={laborApiCall}
              appendPart={appendPart}
              altPartNums={`${altPartNumsArr.join(",")}`}
              subPartNums={`${subPartNumsArr.join(",")}`}
              selectedStore={selectedStore}
              sellPartnerId={sellPartnerId}
              shipMethod={shipMethod}
            />
          );
          //alternate parts
          if (part.alternate && part.alternate.length > 0) {
            part.alternate.map((altPart, index) => {
              let appendAltPart =
                index !== part.alternate.length - 1 ||
                (part.substitute && part.substitute.length > 0);
              productArray.push(
                <SingleProduct
                  buyersGuideData={buyersGuideData}
                  buyersGuideBrandData={buyersGuideBrandData}
                  selectedVehicle={selectedVehicle || {}}
                  laborGuideData={laborGuideData}
                  partDetails={altPart}
                  isAltType={true}
                  dispatch={dispatch}
                  selectedGroupDetails={selectedGroupDetails}
                  partsQtySum={partsQtySum[part.partNum]}
                  userDetails={userDetails}
                  laborApiCall={laborApiCall}
                  appendPart={appendAltPart}
                  selectedStore={selectedStore}
                  sellPartnerId={sellPartnerId}
                  shipMethod={shipMethod}
                />
              );
            });
          }
          //substitute parts
          if (part.substitute && part.substitute.length > 0) {
            part.substitute.map((subPart, index) => {
              let appendSubPart = index !== part.substitute.length - 1;
              productArray.push(
                <SingleProduct
                  buyersGuideData={buyersGuideData}
                  buyersGuideBrandData={buyersGuideBrandData}
                  selectedVehicle={selectedVehicle || {}}
                  laborGuideData={laborGuideData}
                  partDetails={subPart}
                  isSubType={true}
                  dispatch={dispatch}
                  selectedGroupDetails={selectedGroupDetails}
                  partsQtySum={partsQtySum[part.partNum]}
                  userDetails={userDetails}
                  laborApiCall={laborApiCall}
                  appendPart={appendSubPart}
                  selectedStore={selectedStore}
                  sellPartnerId={sellPartnerId}
                  shipMethod={shipMethod}
                />
              );
            });
          }
        });
        productArray.push(
          <PaginationComponent
            handlePageChange={this.handlePageChange}
            currentPage={catalogPageNumber}
            totalPages={catalogTotalPage}
          />
        );
      } else {
        productArray.push(this.renderNoDataState());
      }
      return productArray;
    } else {
      return this.renderNoDataState();
    }
  };

  handleJob = (key, e) => {
    let { selectedJob, dispatch, selectedVehicle: sv } = this.props;
    if (!_isEmpty(selectedJob)) {
      let groupIds = [];
      selectedJob.jobsDetails.map((job) => {
        if (job.partType === "chemicals") {
          groupIds.push(job.groupId);
        }
      });
      dispatch(getChemPartByGrp(groupIds));
      this.setState({ redirectToChemicalPage: true });
    }
  };

  handleMixPromo = (key, e) => {
    let { dispatch } = this.props;
    this.setState({ redirectToChemicalPage: true });
    dispatch({
      payload: {
        mixedPromo: false,
      },
      type: "MIXED_PROMOTION",
    });
  };

  handlePageSizeDropDown = (key, e) => {
    let {
      catalogFilter,
      catalogOrder,
      catalogRequestGroups,
      catalogSortBy,
      dispatch,
      selectedVehicle: sv,
    } = this.props;
    let { defaultPageSizeChanged } = this.state;
    defaultPageSizeChanged = true;
    dispatch(
      getPartsByMultiGroupOrchestrator(
        sv.year,
        sv.makeId,
        sv.modelId,
        sv.engineId,
        catalogRequestGroups,
        1,
        key,
        catalogSortBy,
        catalogOrder,
        catalogFilter
      )
    );
    this.setState({
      ...this.state,
      defaultPageSizeChanged: defaultPageSizeChanged,
    });
  };

  handleSortBy = (key, e) => {
    let {
      catalogPageNumber,
      catalogPageSize,
      catalogRequestGroups,
      dispatch,
      selectedVehicle: sv,
      catalogFilter,
    } = this.props;
    let { currentSort, defaultSortChanged } = this.state;
    defaultSortChanged = true;
    let sortBy = "partType";
    let order = null;
    currentSort = e.target.innerText;
    switch (key) {
      case "MclDefault":
        sortBy = "mcl-default";
        order = "ASC";
        break;
      case "PartTypeASC":
        sortBy = "partType";
        order = "ASC";
        break;
      case "PartTypeDESC":
        sortBy = "partType";
        order = "DESC";
        break;
      case "BrandASC":
        sortBy = "brand";
        order = "ASC";
        break;
      case "BrandDESC":
        sortBy = "brand";
        order = "DESC";
        break;
      case "PriceASC":
        sortBy = "price";
        order = "ASC";
        break;
      case "PriceDESC":
        sortBy = "price";
        order = "DESC";
        break;
      default:
        break;
    }
    dispatch(
      getPartsByMultiGroupOrchestrator(
        sv.year,
        sv.makeId,
        sv.modelId,
        sv.engineId,
        catalogRequestGroups,
        catalogPageNumber,
        catalogPageSize,
        sortBy,
        order,
        catalogFilter
      )
    );
    this.setState({
      ...this.state,
      currentSort: currentSort,
      defaultSortChanged: defaultSortChanged,
    });
  };

  handleByStore = (key, storeInfo, selectedShipMethod) => {
    let {
      catalogPageNumber,
      catalogPageSize,
      catalogRequestGroups,
      dispatch,
      selectedVehicle: sv,
      catalogFilter,
      catalogSortBy,
      catalogOrder,
      userDetails,
    } = this.props;
    let { enableDelivery, shipMethod, allStores } = this.state;
    let filteredStores = allStores && enableDelivery ? _cloneDeep(allStores.deliveryStores) : _cloneDeep(allStores.pickupStores);
    if(!_isEmpty(allStores) && !_isEmpty(allStores.defaultStore)) {
      filteredStores.push(_cloneDeep(allStores.defaultStore));
    }
    let catgroup = _cloneDeep(catalogRequestGroups);
    catgroup.shipMethod =  selectedShipMethod ? selectedShipMethod : shipMethod;
    catgroup.store =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeNumber
        ? userDetails.shipTos[0].location.storeNumber
        : "";
    catgroup.sellPartnerId =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.sellerPartnerId
        ? userDetails.shipTos[0].location.sellerPartnerId
        : "";
    if (key !== "Delivery") {
      catgroup.sellPartnerId = key ? key : "6036530010347581";
      let filteredStore = _find(
        filteredStores,
        (storeData) => storeData.sellerPartnerId === key
      );
      catgroup.store = filteredStore.storeNumber;
      this.setState({
        ...this.state,
        selectedStore: filteredStore.storeNumber,
        sellPartnerId: filteredStore.sellerPartnerId,
        selectedStoreName: filteredStore.storeNumber + " - " + filteredStore.storeName,
        catgroup,
      });
    }
    dispatch(
      getPartsByMultiGroupOrchestrator(
        sv.year,
        sv.makeId,
        sv.modelId,
        sv.engineId,
        catgroup,
        catalogPageNumber,
        catalogPageSize,
        catalogSortBy,
        catalogOrder,
        catalogFilter
      )
    );
  };

  handleViewTypeChange = (enableDlvery) => {
    let { selectedStore, sellPartnerId, selectedStoreName, shipMethod, customerShipMethod } =
      this.state;
    let { userDetails } = this.props;
    let defaultSelectedStore =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeNumber
        ? userDetails.shipTos[0].location.storeNumber
        : "";
    let defaultSellPartnerId =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.sellerPartnerId
        ? userDetails.shipTos[0].location.sellerPartnerId
        : "";
    let defaultSelectedStoreName =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeName
        ? userDetails.shipTos[0].location.storeNumber + " - " + userDetails.shipTos[0].location.storeName
        : "Select Store";
	shipMethod = enableDlvery !== "isPickup"
    ? userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos.length > 0 &&
      userDetails.shipTos[0].shipMethod &&
      userDetails.shipTos[0].shipMethod
    : "0002";
    this.setState({
      ...this.state,
      enableDelivery: enableDlvery !== "isPickup" ? true : false,
      selectedStore: defaultSelectedStore,
    //   selectedStoreName:
    //     defaultSelectedStoreName,
      sellPartnerId: defaultSellPartnerId,
      shipMethod: shipMethod,
    });
    // if (!enableDlvery) {
    //   this.handleByStore("Delivery", e);
    // }
    this.handleByStore('Delivery',[], shipMethod);
  };

  getFilterTitle = () => {
    let defaultTitle = "ALL";
    let { partTypes } = this.props;
    let selectedPartType = this.props.parts.selectedPartType;
    if (partTypes && selectedPartType) {
      const isFound = _.filter(partTypes, (pt) => {
        return pt.text === selectedPartType.partTypeDesc;
      });

      if (isFound && isFound.length > 0) {
        defaultTitle = selectedPartType.partTypeDesc;
      }
      if (
        isFound &&
        isFound.length == 0 &&
        selectedPartType.partTypeId === "Multiple Items"
      ) {
        defaultTitle = selectedPartType.partTypeDesc;
      }
    }
    return defaultTitle;
  };

  getMultiSelectArray = () => {
    let multiSelectArray = [];
    let { partTypes } = this.props;
    let selectedPartType = this.props.parts.selectedPartType;
    if (partTypes && selectedPartType) {
      const isFound = _.filter(partTypes, (pt) => {
        return pt.text === selectedPartType.partTypeDesc;
      });
      if (
        isFound &&
        isFound.length == 0 &&
        selectedPartType.partTypeId === "Multiple Items"
      ) {
        multiSelectArray = selectedPartType.partTypeDesc;
      }
    }
    return multiSelectArray;
  };

  handlePartTypeFilter = (options, selectedOptions) => {
    let {
      catalogPageNumber,
      catalogPageSize,
      dispatch,
      catalogSortBy,
      catalogRequestGroups,
      catalogOrder,
      selectedVehicle: sv,
      catalogFilter,
    } = this.props;
    let { currentFilter } = this.state;
    let key = 0;
    options = options.filter((o) => o.value !== "apply");
    let allOptions = options.filter((o) => o.value === "*");
    if (allOptions.length > 0) {
      currentFilter = "ALL";
      key = "ALL";
    } else if (options.length > 1) {
      currentFilter = options;
      key = "Multiple Items";
    } else {
      currentFilter = options[0].label;
      key = options[0].value;
    }
    const partTypeData = {
      partTypeId: key,
      partTypeDesc: currentFilter,
    };
    dispatch({
      payload: {
        selectedPartType: partTypeData,
      },
      type: "SELECTED_PART_TYPE",
    });
    let catalogRequestGroupClone = _cloneDeep(catalogRequestGroups);
    let catalogFilterClone = _cloneDeep(catalogFilter);
    let partTypes = [];
    options.map((o) => {
      partTypes.push({ id: o.value });
    });
    catalogFilterClone = {
      ...catalogFilterClone,
      partTypes: partTypes,
    };

    dispatch(
      getPartsByMultiGroupOrchestrator(
        sv.year,
        sv.makeId,
        sv.modelId,
        sv.engineId,
        catalogRequestGroupClone,
        catalogPageNumber,
        catalogPageSize,
        catalogSortBy,
        catalogOrder,
        catalogFilterClone
      )
    );
    this.setState({
      ...this.state,
      currentFilter,
      catalogFilter,
      catalogRequestGroupClone,
    });
  };

  toggleView = (event) => {
    let { showFullView } = this.state;
    this.setState({
      ...this.state,
      showFullView: !showFullView,
    });
  };

	showYmmePopup = () => {
		const height = window.screen.availHeight - 320;
		const width = window.screen.availWidth - 500;
		const left = 250;
		const top = 160;
    window.ymmeWin = window.open(`/YMMEOnly`, '', `left=${left},top=${top},width=${width},height=${height},resizable=1`);
	};

	isBrandsSelected = () => {
		const supplier = this.props.userDetails.billTo.mclSupplier;
		const prefBrands = getPartsPreferredBrands(supplier);

		if (
		  prefBrands &&
		  prefBrands.multiAnswer &&
		  prefBrands.possibleMultiAnswer &&
		  (prefBrands.multiAnswer.length === prefBrands.possibleMultiAnswer.length || prefBrands.multiAnswer.length === 0)
		) {
		  return false;
		} else {
		  return true;
		}
	  };


  render() {
    const {
      selectedVehicle,
      currentPageSize,
      totalItems,
      catalogPageNumber,
      catalogTotalPage,
      partTypes,
      catalogFilter,
      dispatch,
      userDetails,
    } = this.props;
    let {
      apiCall,
      currentSort,
      redirectToHomePage,
			showLocationsPopup,
			showRegionLocationsPopup,
      redirectToChemicalPage,
      currentFilter,
      showFullView,
      filterEnabled,
      userPrefView,
      partTopArrowClicked,
      redirectLink,
      enableDelivery,
      selectedCartData,
      selectedStore,
      selectedStoreName,
      customerShipMethod,
      addToCartWarningPopup,
      shipMethod,
	  allStores,
    } = this.state;
    let userStoreMainLoc =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.mainLocation;

    let userStoreMainLocType =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.locationType;
    let filteredStore = [];

    if (!_isEmpty(allStores) && allStores.deliveryStores && allStores.pickupStores) {
        filteredStore = enableDelivery ? allStores && _cloneDeep(allStores.deliveryStores) : allStores && _cloneDeep(allStores.pickupStores);
        if(!_isEmpty(allStores) && !_isEmpty(allStores.defaultStore)) {
          filteredStore.push(_cloneDeep(allStores.defaultStore));
        }
		filteredStore = _uniqBy(
			filteredStore,
			"storeNumber"
		  );
        // filteredStore = _filter(allStores, (store) => (
      // 	(store.mainLoc === userStoreMainLoc && ((store.locationClass !== 'DC') || (store.locationClass !== 'DSW')))
      // ));
    }

    if (redirectToHomePage) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { vehicleSelectionDone: false },
          }}
        />
      );
    }
    if (redirectToChemicalPage) {
      return (
        <Redirect
          to={{
            pathname: "/supplies-list",
          }}
        />
      );
    }
    if (_isEmpty(selectedVehicle)) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    return (
      <React.Fragment>
		 <Container className="breadcrumb__chemicals">
			<div className="bread_crum_section">
			  <ul>
				<li>
				  <Link to="/">Home</Link>
				</li>
				<li>
				  <span>PartCatalog</span>
				</li>
			  </ul>
			</div>
		  </Container>
        <div
          className={clsx("productsContainer", {
            "promotions-active": this.props.viewPromotions,
          })}
        >
          {apiCall && (
            <div className="custom_spinner_container catalog_spinner">
              <Spinner className="custom_spinner" animation="border" />
            </div>
          )}
        <div className="product-list-container">
				<Container>
				  <div className="btn-filter-catalog d-flex">
					<ShipInfoHeader
					  shipMethod={shipMethod}
					  storeName={selectedStore}
					/>
					<div className="store-filter store-space">
                    <PickupDelivery
                      handleViewTypeChange={this.handleViewTypeChange}
                      storeSellerId={this.handleByStore}
                    />
                    </div>
					{/* <div className="store-filter store-space">
          {customerShipMethod && customerShipMethod != '0002' && (
								<div className="radio-wrapper">
									<label class="customized-radio-button">
									<input
										type="radio"
										name="viewTypes"
								    checked={enableDelivery}
										id='form-1'
										onChange={(e) => {this.handleViewTypeChange(e,enableDelivery)}}
										/>
										<span>Delivery</span>
									</label>
									<label class="customized-radio-button">
									<input
										type="radio"
										name="viewTypes"
								        checked={!enableDelivery}
								        onChange={(e) => {this.handleViewTypeChange(e, enableDelivery)}}
										id='form-2'
										/>
										<span>Pick Up</span>
									</label>
								</div>)}
								{filteredStore && filteredStore.length > 1 && (
								<div className="store-dropdown">
								{customerShipMethod == '0002' ? (<span>Pick Up Locations: </span>) : <span>Locations:</span>}
								<OverlayTrigger
								   placement="right"
								  delay={{ show: 100, hide: 400 }}
								  overlay={
									<Tooltip id="pickup-location">
											You can {enableDelivery ? "deliver" : "pick up"} your parts from a different store than your primary store. Click the drop down to select a different {enableDelivery ? "stores" : "pick up location"}
									</Tooltip>
								 }
							    >
							     <DropdownButton className="xlp-btn" id="dropdown-basic-button"
								    title={selectedStoreName}
										  onSelect={(key, e) => {
											this.handleByStore(key, e);
								    }}
									>
									{filteredStore.map((store) => (
                                        <Dropdown.Item eventKey={store.sellerPartnerId}>
                                              {store.storeNumber} - {store.storeName}
                                        </Dropdown.Item>
                                    ))}{" "}
								  </DropdownButton>
        						</OverlayTrigger>
								{selectedStore && !_isEmpty(selectedStore) && (
									<>
									<OverlayTrigger
									placement="right"
									delay={{ show: 100, hide: 400 }}
									overlay={
										<Tooltip id="selected-location">
										{`Click this to locate the selected Store in Map`}
										</Tooltip>
									}
									>
										<LocationOnIcon className="cursor-pointer" onClick={() => {
											this.setState({ showLocationsPopup: true });
										}} />
									</OverlayTrigger>
									<OverlayTrigger
									placement="right"
									delay={{ show: 100, hide: 400 }}
									overlay={
										<Tooltip id="selected-location">
										{`Click this to locate all the stores in the region of selected store in Map`}
										</Tooltip>
									}
									>
										<LocationCityIcon className="cursor-pointer" onClick={() => {
											this.setState({ showRegionLocationsPopup: true });
										}} />
									</OverlayTrigger>
									</>
								)}
							</div>
							)}
					</div> */}
				  </div>
				  <div className="btn-filter-catalog d-flex new_customer_container">
				  <OverlayTrigger
					  placement="right"
					  delay={{ show: 100, hide: 400 }}
					  overlay={
						<Tooltip id="toggle-view-part-compact">
						  {`Search Vehicle By YMME`}
						</Tooltip>
					  }
					>
					  <button
						class="part-arrow-collapse"
						onClick={this.showYmmePopup}
					  >
						<Image height="30px" src={ymmeRefreshIcon} />
					  </button>
					</OverlayTrigger>
					<FavoriteIconHeader/>
					<SpecificconditionIcon/>
					<div class="listing-toggleview d-flex ml-4 mr-4">
					  <OverlayTrigger
						placement="right"
						delay={{ show: 100, hide: 400 }}
						overlay={
						  <Tooltip id="toggle-view-part-compact">
							{`${
							  showFullView
								? 'You are in "Full View", clicking on the button will switch to "Compact View"'
								: 'You are in "Compact View", clicking on the button will switch to "Full View"'
							}`}
						  </Tooltip>
						}
					  >
						<Form.Group controlId="toggle-view-id">
						  <Form.Check
							type="switch"
							id="toggle-view"
							label={`${
							  userPrefView === "full" ? "Compact View" : "Full View"
							}`}
							onChange={(e) => this.toggleView(e)}
						  />
						</Form.Group>
					  </OverlayTrigger>
					</div>
					{/* <BreadCrumbInput /> */}
          <VehicleBreadCrumb/>
					<OverlayTrigger
					  placement="left"
					  delay={{ show: 100, hide: 400 }}
					  overlay={
						<Tooltip id="toggle-view-part-compact">
						  {`Click here to clear this view and start a new search`}
						</Tooltip>
					  }
					>
					  <div className="topbar-buttons new-search-button">
						<Link to={redirectLink}>
						  <Button className="new-search">New Search </Button>
						</Link>{" "}
					  </div>
					</OverlayTrigger>
					</div>
					<Row
					className={
					  this.state.filterEnabled
						? "btn-filter-catalog-top"
						: "btn-filter-catalog-top"
					}
				  >
				  <Col lg="2" md="3" sm="3" xs="12" className="left-section">
					<div
					  class="listing-toggleview d-flex "
					  id="filter-part-catalog"
					>
					  <OverlayTrigger
						placement="right"
						delay={{ show: 100, hide: 400 }}
						overlay={
						  <Tooltip id="toggle-view-part-compact">
							{`${
							  filterEnabled
								? "Click here to show filters"
								: "Click here to hide filters"
							}`}
						  </Tooltip>
						}
					  >
						<Button
						  className={
							filterEnabled
							  ? "btn-filter btn-filter-grey"
							  : "btn-filter"
						  }
						  onClick={(e) => {
							dispatch(
							  updateFilterSectionEnabledStatus(filterEnabled)
							);
							this.setState({ filterEnabled: !filterEnabled });
						  }}
						>
						  <i class="fa fa-filter" aria-hidden="true"></i>
						  <i class="fa fa-bars" aria-hidden="true"></i>
						</Button>
					  </OverlayTrigger>
					  </div>
					 </Col>
					<Col lg="10" md="9" sm="9" xs="12" className="right_section">
					{!apiCall && (
					  <SortByShow
					  handlePartTypeFilter={(key, e) =>
						this.handlePartTypeFilter(key, e)
					  }
					  multiSelectArray={this.getMultiSelectArray()}
					  //currentFilter={this.getFilterTitle()}
					  partTypes={this.props.partTypes}
					  currentPageSize={currentPageSize}
					  currentSort={currentSort}
					  totalItems={totalItems}
						  isChemicalCatalog={false}
						currentPage={catalogPageNumber}
						totalPages={catalogTotalPage}
						handlePageChange={this.handlePageChange}
						handleDropDown={(key, e) => {
							this.handlePageSizeDropDown(key, e);
						}}
						handleSortBy={(key, e) =>
							this.handleSortBy(key, e)
						}
						currentFilter={(catalogFilter && catalogFilter.partTypes && catalogFilter.partTypes.length == 0) ? "ALL" : this.getFilterTitle()}
						handleJob={(key, e) => {
							this.handleJob(key, e);
						}}
						handleMixPromo={(key, e) => {
							this.handleMixPromo(key, e);
						}}
						userDetails={this.props.userDetails}
							/>
							)}
				</Col>
				</Row>
				  <Row
					className={
					  this.state.filterEnabled
						? "product_details_main_container active"
						: "product_details_main_container"
					}
				  >
					<FilterSection
					/>
					<Col lg="10" md="9" sm="9" xs="12" className="right_section">
					  {showFullView ? (
						!apiCall ? (
						  this.renderPartsData()
						) : (
						  <Fragment>
							<Placeholder rows={4} />
						  </Fragment>
						)
					  ) : !apiCall ? (
						prepareTablePartsDataFromUtil(this.props, this.state, this.separtionCodeForBrandAndParType, this.renderNoDataState, this.handlePageChange)
					  ) : (
						<Fragment>
						  <Placeholder rows={4} />
						</Fragment>
					  )}
					</Col>
				  </Row>
				</Container>
			  </div>
          <PromotionsTab
            showSidebarPopUp={this.props.viewPromotions}
            promotions={this.props.userPromotionDetails}
            handlePromotions={() => {
              const { viewPromotions } = this.props;

			  setCookie('viewPromotions', !this.props.viewPromotions, 365)
              this.props.dispatch({
                payload: {},
                type: "VIEWPROMOTIONS_TOGGLE",
              });

              if (!viewPromotions) {
                if (document.querySelector(".section-wrapper")) {
                  document.querySelector(".section-wrapper").style.marginLeft =
                    "250px";
                }

                if (document.querySelector(".section__banner")) {
                  document.querySelector(".section__banner").style.marginLeft =
                    "250px";
                }
              } else {
                if (document.querySelector(".section-wrapper")) {
                  document.querySelector(".section-wrapper").style.marginLeft =
                    "0";
                }

                if (document.querySelector(".section__banner")) {
                  document.querySelector(".section__banner").style.marginLeft =
                    "0";
                }
              }
            }}
          />
          {addToCartWarningPopup ? (<AddToCartWarningPopup
		        openPopup={addToCartWarningPopup}
            selectedAvailabilityHours={selectedCartData}
            source={"partCategory"}
			orderType="REGULAR"
					  DefaultAddItemToCart={false}
				    closePopup={() => {
						this.setState({
							addToCartWarningPopup: false
						})
				    }}
			fromPage={"catalogwarningpopup"}
            />) : null}

            {(showLocationsPopup || showRegionLocationsPopup) && (
					<XModal
						show={showLocationsPopup || showRegionLocationsPopup}
						showButtons={false}
						title={`Store Location - Store # ${selectedStoreName}`}
						customHeader={
							<React.Fragment>
								<Modal.Title id="warning-modal-title" className="pull-left">
								Store Location - Store # {selectedStoreName}
								</Modal.Title>
								<div
									className="close-btn"
									onClick={() => { this.setState({ showLocationsPopup: false }); this.setState({ showRegionLocationsPopup: false }); }}
								>
									<span className="material-icons">close</span>
								</div>
							</React.Fragment>
						}
						handleClose={() => { this.setState({ showLocationsPopup: false }); this.setState({ showRegionLocationsPopup: false }); }}
					>
						<Locations allCityStores={showRegionLocationsPopup ? true : false} selectedStore={selectedStore}/>
					</XModal>
			  )}
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    parts: state.parts,
    buyersGuideBrandData: state.parts.buyersGuideBrandDetails,
    buyersGuideData: state.parts.buyersGuideDetails,
    catalogFilter: state.parts.catalogFilter,
    catalogOrder: state.parts.catalogOrder,
    catalogPageNumber: state.parts.catalogPageNumber,
    catalogPageSize: state.parts.catalogPageSize,
    currentPageSize: state.parts.catalogPageSize,
    catalogSortBy: state.parts.catalogSortBy,
    catalogRequestGroups: state.parts.catalogRequestGroups,
    catalogTotalPage: state.parts.catalogTotalPage,
    extraSpecificsForParts: state.parts.extraSpecificsForParts,
    partsAllDetails: state.parts.partsAllDetails,
    laborApiCall: state.parts.laborApiCall,
    laborGuideData: state.parts.laborGuideDetails,
    partsApiCall: state.parts.partsApiCall,
    selectedGroupDetails: state.parts.selectedGroupDetails,
    selectedJob: state.mygarage.selectedJob,
    selectedVehicle: state.search.selectedVehicle,
    totalItems: state.parts.catalogTotalItems,
    userDetails: state.user.userDetails,
    userPromotionDetails: state.user.userPromotionDetails,
    viewPromotions: state.app.viewPromotions,
    partTypes: state.parts.partTypes,
    allStoreData: state.userManagement.allStores,
    addToCartWarning: state.parts.addToCartWarning,
	selectedAddCartData: state.parts.selectedAddCartData,
	cartStore: state.parts.cartStore,
	cartShipMethod: state.parts.cartShipMethod,
    minPrice:state.parts.minPrice,
	maxPrice :state.parts.maxPrice,
	storeDetails: state.mygarage.storeDetails,
	checkoutDetails: state.cart.checkoutDetails,
	selectedPartType:state.parts.selectedPartType,
	globalLoader: state.app.globalLoader
  };
}
export default connect(mapStateToProps)(ListingWrapper);
