import React, {useState} from "react";
import { useDispatch } from "react-redux";
import {
    Form,
    Button,
} from 'react-bootstrap';
import {
    changePassword,
} from '../../../actions/auth';
import _every from 'lodash/every';
import _isEmpty from 'lodash/isEmpty';
import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';

const UpdatePassword = (props) => {
    const {
        handleInputChange,
        validity,
        fields,
    } = props;
    const dispatch = useDispatch();
    const handleSubmit = () => {
        if (_every(validity)) {
            changePassword(fields, dispatch);
        }
    }
	const [showCurrentPassword, setShowCurrentPassword ] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword ] = useState(false)
	const [showNewPassword, setShowNewPassword ] = useState(false)

	const handleShowOrHide = (field)=>{
        if(field === "currentPassword"){
            setShowCurrentPassword(!showCurrentPassword)
        }else if(field === "newPassword"){  
			setShowNewPassword(!showNewPassword)
		}else if(field === "confirmPassword"){
			setShowConfirmPassword(!showConfirmPassword)
		}
	}
    return (
        <Form autocomplete="off">
            <div>
                <span>Please follow the below rules for setting the password.</span>
                <ul>
                    <li>Minimum password length – 8</li>
                    <li>Password should contain atleast 1 uppercase, 1 lowercase, 1 number and a special character.</li>
                    <li>{`'Special character should include - = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ \`'`}</li>
                </ul>
            </div>
            <div className="label-input-container">
                <label className="password-label" htmlFor="currentPassword">Current Password</label>
                <div className="d-flex position-relative">
                    <Form.Control
                        type={showCurrentPassword ? "text" : "password"}
                        onChange={handleInputChange}
                        name="currentPassword"
                        id="currentPassword"
                        value={fields.currentPassword}
                    />
                    {showCurrentPassword 
                    ? <i class="fa fa-eye-slash eyeIcon_usermanagement" onClick={()=>{handleShowOrHide("currentPassword")}} aria-hidden="true"></i>
                    : <i class="fa fa-eye eyeIcon_usermanagement" onClick={()=>{handleShowOrHide("currentPassword")}} aria-hidden="true"></i>
                    }
                </div>
            </div>
            <div className="label-input-container">
                <label className="password-label" htmlFor="newPassword">New Password</label>
                <div>
                    <div className="d-flex position-relative">
                        <Form.Control
                            type={showNewPassword ? "text" : "password"}
                            onChange={handleInputChange}
                            onPaste={(e)=>{e.preventDefault()}}
                            name="newPassword"
                            id="newPassword"
                            value={fields.newPassword}
                        />
                        {showNewPassword 
                        ? <i class="fa fa-eye-slash eyeIcon_usermanagement" onClick={()=>{handleShowOrHide("newPassword")}} aria-hidden="true"></i>
                        : <i class="fa fa-eye eyeIcon_usermanagement" onClick={()=>{handleShowOrHide("newPassword")}} aria-hidden="true"></i>
                        }
                    </div>
                    <FormValidationErrorMessage
                        condition={!validity.hasValidLength && !_isEmpty(fields.newPassword)}
                        errorMessage="Password should contain minimum 8 characters"
                    />
                    <FormValidationErrorMessage
                        condition={!validity.isValidPassword && validity.hasValidLength} 
                        errorMessage="Your password does not follow the standards"
                    />
                </div>
            </div>
            <div className="label-input-container">
                <label className="password-label" htmlFor="confirmPassword">Confirm Password</label>
                <div>
                    <div className="d-flex position-relative">
                        <Form.Control
                            type={showConfirmPassword ? "text" : "password"}
                            onChange={handleInputChange}
                            onPaste={(e)=>{e.preventDefault()}}
                            name="confirmPassword"
                            id="confirmPassword"
                            value={fields.confirmPassword}
                        />
                        {showConfirmPassword 
                        ? <i class="fa fa-eye-slash eyeIcon_usermanagement" onClick={()=>{handleShowOrHide("confirmPassword")}} aria-hidden="true"></i>
                        : <i class="fa fa-eye eyeIcon_usermanagement" onClick={()=>{handleShowOrHide("confirmPassword")}} aria-hidden="true"></i>
                        }
                    </div>
                    <FormValidationErrorMessage
                        condition={validity.isValidPassword && !validity.isPasswordMatched}
                        errorMessage="The New Password and Confirm Password are not matching"
                    />
                </div>
            </div>
            <Button
                onClick={handleSubmit}
                disabled={!_every(validity)}
                className="user-management-submit-btn"
            >
                ok
            </Button>
        </Form>
    );
}

export default UpdatePassword;
