import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import _isEmpty from "lodash/isEmpty";
import {
    formatTimeAvb,
    getNonDetailedBucket
} from "../../helpers/utils";
const RefreshDataPopup = ({ checkoutDetails }) => {

    const headerStyleConfirm = {
        backgroundColor: "#0F5283",
        color: "#FFFFFF",
        fontWeight: "500",
        "border-color": "#0F5283",
        "font-size": "17px",
        width: "110px",
    };

    const prepareCheckoutData = (data) => {
        let cartConsolidateArr = [];
        if (!_isEmpty(checkoutDetails)) {
            const cartList = checkoutDetails.map((obj) => ({ ...obj }));
            cartList.map((one) => {
                if (one.isCurrentLineItemChanged) {
                    one.availability = (one.availabilityHour + "-" + one.qty);
                    let matched = false;
                    if (_isEmpty(cartConsolidateArr)) {
                        cartConsolidateArr.push(one);
                    } else {
                        matched = cartConsolidateArr.findIndex((two, index) => {
                            return (
                                two.partNum === one.partNum &&
                                two.mfgCode === one.mfgCode &&
                                two.desc === one.desc &&
                                two.vehicleInfo === one.vehicleInfo
                            );
                        });
                        if (matched >= 0) {
                            cartConsolidateArr[matched].qty =
                                cartConsolidateArr[matched].qty + one.qty;
                            cartConsolidateArr[matched].availability = cartConsolidateArr[matched].availability + "," + one.availability;
                        } else {
                            cartConsolidateArr.push(one);
                        }
                    }
                }
            });
        }
        if (cartConsolidateArr && cartConsolidateArr.length > 0) {
            cartConsolidateArr.map((item) => {
                item.availability = !_isEmpty(item.availability)
                    ? item.availability.split(",")
                    : [item.availabilityHour + "-" + item.qty];
            });
        }
        return cartConsolidateArr;
    };

    const columns = [
        {
            dataField: "mfgCode",
            text: "P/L",
            align: "center",
            headerAlign: "center",
            headerStyle: headerStyleConfirm,
        },
        {
            dataField: "partNum",
            text: "Part#",
            align: "center",
            headerAlign: "center",
            headerStyle: headerStyleConfirm,
        },
        {
            dataField: "availabilityHour",
            text: "Availability",
            headerStyle: headerStyleConfirm,
            align: "center",
            headerAlign: "center",
            width: "450px",
            classes: "bootstrap-table-bold-cell",
            formatter: function (cell, row) {
                let availablility = [];
                if (!_isEmpty(row.partNum) && !_isEmpty(row.availability) && row.distStatus !== "Out Of Stock") {
                    {
                        row.availability.map((value, index) => {
                            {
                                let array = value.split('-');
                                let val = array[0];
                                let qty = array[1];
                                getNonDetailedBucket(val)
                                    ? availablility.push(
                                        <li>
                                            <span className="text-black ml-2">
                                                <span className="maroonColorTxt">
                                                    <b>{qty}</b>{" "}
                                                </span>
                                                {val} <br />
                                            </span>
                                        </li>
                                    )
                                    : availablility.push(
                                        <li>
                                            <span className="text-black ml-2">
                                                <span className="maroonColorTxt">
                                                    <b>{qty}</b>{" "}
                                                </span>{" "}
                                                <u>Within</u> {formatTimeAvb(val)}{" "}
                                                <br />
                                            </span>
                                        </li>
                                    );
                            }
                        });
                    }
                } else {
                    availablility.push(
                        <li>
                            <span>
                                <p
                                    className="checkout-header out-of-stock"
                                    title={
                                        row.distStatus == "Out Of Stock"
                                            ? "currently out of stock"
                                            : row.mfgCode
                                    }
                                >
                                    {row.distStatus == "Out Of Stock" ? (
                                        <b className="add_to_cart_out">Currently out of stock</b>
                                    ) : (
                                        ""
                                    )}
                                </p>
                            </span>
                        </li>
                    );
                }
                let fullDiv = (
                    <div className="product-image-container table-view-margin vehicle-name-padding">
                        {" "}
                        <div
                            className={
                                _isEmpty(row.partNum)
                                    ? "checkout-availability-subdiv gry-back-ground"
                                    : "checkout-availability-subdiv avabilability-padding"
                            }
                        >
                            <ul>{availablility}</ul>
                        </div>
                    </div>
                );
                return fullDiv;
            },
        }
    ]

    return (
        checkoutDetails.some((p) => (p.isCurrentLineItemChanged)) &&
        (<div className="place-order-table">
            <BootstrapTable
                keyField="id"
                data={prepareCheckoutData("")}
                columns={columns}
            />
        </div>)
    );
}

export default RefreshDataPopup;
