/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Image, Button } from "react-bootstrap";
import "./styles.scss";
import { currencyFormatter, getImageURLCookieDetails } from "helpers/utils";
import ImagesSlider from "../../../components/Listing/ImagesSlider/index";
import Moment from "react-moment";
import { getCKReviewSelectedOrder } from "../c_and_k.thunk";
import { useDispatch } from "react-redux";
import ItemsRender from "./items";
import RenderIIcon from "../components/RenderIIcon";

function OEMSingleView(props) {
  const [showImagesSlider, setShowImagesSlider] = useState(false);
  const [readmore, setReadmore] = useState(false);
  const { partDetails } = props;
  const imageURLCookie = getImageURLCookieDetails();
  const dispatch = useDispatch();

  const readFunction = () => {
    setReadmore(!readmore);
  };

  let imageLbl =
    partDetails.resourceUrls && partDetails.resourceUrls.length === 1
      ? "image"
      : "images";

  let image =
    partDetails.resourceUrls && partDetails.resourceUrls.length > 0
      ? `${partDetails.resourceUrls[0].url}?${imageURLCookie}`
      : "/noImage.jpg";

  const togglePopup = (popupName) => {
    setShowImagesSlider(false);
  };

  const modifyImageUrls = (details) => {
    return { ...details, images: details.resourceUrls };
  };
  const redirectToReviewPage = (row) => {
    if (row.stockStatus !== "Yes") {
      props.setIsCallType(true);
    } else {
      props.setIsCallType(false);
    }
    dispatch(getCKReviewSelectedOrder({ ...row, show: true }));
    props.setShowPopup(true);
  };

  return (
    <>
      <div className="singleBox">
        <div className="content-wrapper">
          <div className="single_product_img">
            <div className="product-image-container">
              <div className="image-thumbnail-container-c-and-k">
                <Image
                  className={"image-thumbnail cursor-pointer"}
                  src={image}
                  alt="no image"
                  onClick={() => {
                    if (true) {
                      setShowImagesSlider(true);
                    }
                  }}
                  fluid
                />
              </div>
              {true && (
                <a
                  onClick={() => {
                    if (partDetails.images.length) {
                      this.setState({
                        showImagesSlider: false,
                      });
                    }
                  }}
                >
                  {partDetails.resourceUrls.length} {imageLbl}
                </a>
              )}
            </div>
          </div>
          <div className="product-details">
            <div className="top-row">
              <div className="top-left">
                <div>
                  <div className="part-no">
                    Part# {partDetails.quotedPartNumber}
                  </div>
                </div>
                <div className="part-desc">{partDetails.description}</div>
                <div className="part-match-details">
                  Notes: {partDetails.matchDetails}
                </div>
              </div>
              <div className="top-right">
                <div className="addtocart-section">
                  {partDetails.stockStatus !== "Yes" &&
                    partDetails.stockStatus !== "No" && (
                      <RenderIIcon
                        label=""
                        hoverText="This item is subject to an availability check. A team member will contact you within 2 business days to confirm availability and delivery date"
                      />
                    )}
                  <Button
                    className="buynow"
                    disabled={partDetails.stockStatus !== "Yes"}
                    onClick={() => redirectToReviewPage(partDetails)}
                  >
                    {partDetails.stockStatus !== "Yes"
                      ? "Order Now"
                      : "Buy Now"}
                  </Button>
                </div>
                {/* {partDetails.stockStatus === "Call" && (
                  <div className="helper-text">
                    Contact your sales rep for availability
                  </div>
                )} */}
                {partDetails.stockStatus === "No" && (
                  <div className="helper-text">Currently Out of Stock</div>
                )}
              </div>
            </div>
            <div className="bottom-row">
              <ItemsRender
                items={[
                  {
                    label: (
                      <RenderIIcon
                        label="Your Price"
                        hoverText="Warranty is included in purchase"
                        iconColor="xlp"
                        className="custom-i-icon"
                      />
                    ),
                    value:
                      partDetails.customerPrice !== null
                        ? `${currencyFormatter(partDetails.customerPrice)}`
                        : "Not Available",
                  },
                  {
                    label: (
                      <RenderIIcon
                        label="Core Value"
                        hoverText="Not charged with the return of the core"
                        iconColor="xlp"
                        className="custom-i-icon"
                      />
                    ),
                    value:
                      partDetails.coreValue !== null
                        ? `${currencyFormatter(partDetails.coreValue)}`
                        : "Not Available",
                  },
                  {
                    label: "List Price",
                    value:
                      partDetails.listPrice !== null
                        ? `${currencyFormatter(partDetails.listPrice)}`
                        : "Not Available",
                  },
                  {
                    label: "Estimated Delivery Date",
                    value: partDetails.deliveryDate ? (
                      <Moment
                        date={partDetails.deliveryDate}
                        format="MM/DD/YYYY"
                      />
                    ) : (
                      "Not Available"
                    ),
                  },
                  {
                    label: "Cut Off Order Time:",
                    value: partDetails.deliveryDateCutoffUTC ? (
                      <Moment
                        date={`${partDetails.deliveryDateCutoffUTC}Z`}
                        format="MM/DD/YYYY hh:mm A z"
                        tz="America/Chicago"
                      />
                    ) : (
                      "Not Available"
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        {partDetails &&
          partDetails.messages &&
          partDetails.messages.length > 0 && (
            <div className="bottom_decription">
              <div className="add-info">Additional Info:</div>
              {readmore ? (
                <>
                  {partDetails.messages.map((message, i) => {
                    return <p>{message.content}</p>;
                  })}
                  <a onClick={readFunction}>Read Less</a>{" "}
                </>
              ) : (
                <>
                  {partDetails.messages[0].content.substring(0, 75)}...
                  <a onClick={readFunction}>Read more</a>
                </>
              )}
            </div>
          )}
        {showImagesSlider && (
          <ImagesSlider
            openPopup={showImagesSlider}
            closePopup={togglePopup}
            partDetails={modifyImageUrls(partDetails)}
            setShowImagesSlider={setShowImagesSlider}
          />
        )}
      </div>
      {/* {ckorder?.show && (
        <CKReviewPopUp show={ckorder?.show} handleClose={handleClose} />
      )} */}
    </>
  );
}

OEMSingleView.propTypes = {};

export default OEMSingleView;
