import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import _head from "lodash/head";
import _find from "lodash/find";
import VehicleMaintenancePopup from "../../components/shared/VehicleMaintenance";
import FluidsAndFiltersPopup from "../../components/shared/FluidsAndFilters";
import { setExpressHomepage } from "../../components/Homepage/SimpleHomepage/simplehomepage.slice";
import {
  addItemToCart,
  addItemToCart2,
  getItemsFromCart,
  getCheckoutItems,
  clearCart,
  getCart,
  placeCartOrder,
  deleteItemFromCart2,
  saveQuote,
  saveQuoteForStockOrder,
  submitLostSales,
  placeCartOrder2
} from "../../actions/cart";
import _isEqual from "lodash/isEqual";
import _findIndex from "lodash/findIndex";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import _every from "lodash/every";
import _pick from "lodash/pick";
import _uniqBy from "lodash/uniqBy";

import CartPartDetails from "./CartPartDetails";
import CartPricingDetails from "./CartPricingDetails";

import ThankyouPopup from "./ThankyouPopup";
import LostSales from "./LostSales";
import Estimate from "./Estimate";
import {
  triggerUxCritialErrors,
  triggerCustomUxCriticalError,
} from "../../actions/error";
import { setQuoteSelected } from '../shared/MyGarage/mygarage.slice';
import ShipInfoHeader from "components/shared/ShipInfoHeader";
import {
  addJob,
  setPartHistory,
  setVehicles,
} from "../shared/MyGarage/mygarage.thunks";

import estimateViewIcon from "../../static/images/estimateViewIcon.png";
import XModal from "../shared/XModal";
import { Redirect } from "react-router-dom";
import RefreshDataPopup from "./RefreshDataPopup";

import {
  isInputBlank,
  hasQuoteAlphaSpecialIntChar,
  isJobNameAlphanumeric,
  hasMinLength,
  isLengthLessthan,
  isNumeric,
  isMaxLengthLessthan,
  isMinLength,
  isValidAvailableQty,
  timeInUTC,
  noSpecialCharsRegExp,
  getPreferenceDataFromStorage,
  getPartPackQty,
  displayQtyChangeMsg,
  isStockOrder
} from "../../helpers/utils";
import FormValidationErrorMessage from "../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import PromotionsTab from "../shared/Promotions";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import Catalog from "../../static/js/Content/scripts/catalog-v3.0.0";
const actionTypes = {
  CART_PLACE_ORDER_WARNING: "CART_PLACE_ORDER_WARNING",
  CART_CHECKOUT_FETCHED: 'CART_CHECKOUT_FETCHED',
	CART_DETAILS_FETCHED: 'CART_DETAILS_FETCHED'
};

const quoteStyle = {
  display: "flex",
  alignItems: "center",
};

const apiKey = 'apikey-12345-key';
const catalogSdk = new Catalog(apiKey, false);

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    let totalBillingInfo = {
      totalPartsCostPrice: 0,
      totalPartsListPrice: 0,
    };
    let checkoutDetails = [];
    if (props.checkoutDetails && !_isEmpty(props.checkoutDetails)) {
      props.checkoutDetails &&
        props.checkoutDetails.map((cartItem) => {
          let single = {
            ...cartItem,
            qtyValidity: this.intializeQtyValidations(),
          };
          checkoutDetails.push(single);
          totalBillingInfo.totalPartsCostPrice +=
            cartItem.totalCostPrice + cartItem.qty * cartItem.corePrice;
          totalBillingInfo.totalPartsListPrice += cartItem.totalListPrice;
        });
    }
    this.state = {
      placeOrderData: null,
      altEmail: null,
      cartFetched: true,
      checkoutDetails,
      totalBillingInfo,
      successPopUp: false,
      cartDistributionWaringPopup: false,
      showQuoteModal: false,
      showJobModal: false,
      showLostSales: false,
      lostSalesData: [],
      sourceOfLostSales: "",
      showEstimateView: false,
      vehicleData: null,
      showVehicleMaintenance: false,
      showFluidsAndFilter: false,
      quoteName: null,
      quotePhoneNumber: "",
      orderComment: "",
      jobName: null,
      selectedQuote: props.selectedQuote ? props.selectedQuote : "",
      quoteNameValidity: this.intializeQuoteValidations(),
      jobNameValidity: this.intializeJobValidations(),
      validCart: true,
      jobSaved: false,
      poNum: null,
      comments: null,
      showON: false,
      userPrefViewForLostSale: true,
      showLoader: false,
      userPrefViewForEmail: null,
      openWarning: false,
      warningMessage: "",
      cartStore: this.props.cartStore ? this.props.cartStore : "",
      cartShipMethod: this.props.cartShipMethod
        ? this.props.cartShipMethod
        : null,
      isQuickCart: props.quickCart || false,
      poDetails: {},
    };
    this.clearUserCart = this.clearUserCart.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
    this.handleInputOnBlur = this.handleInputOnBlur.bind(this);
    this.handleInputOnMouseLeave = this.handleInputOnMouseLeave.bind(this);
    this.handleEstimateViewBtn = this.handleEstimateViewBtn.bind(this);
    this.handleQuoteNameChange = this.handleQuoteNameChange.bind(this);
    this.handleJobNameChange = this.handleJobNameChange.bind(this);
    this.handleQuoteNameBlur = this.handleQuoteNameBlur.bind(this);
    this.handleJobNameBlur = this.handleJobNameBlur.bind(this);
    this.handleVehicleMaintenan = this.handleVehicleMaintenan.bind(this);
    this.handleFluidSpec = this.handleFluidSpec.bind(this);
  }
  intializeQuoteValidations() {
    this.quoteNameValidity = {
      isQuoteAlphaSpecialChar: true,
      isQuoteNameMinLimit: true,
      isQuoteNameMaxLimit: true,
    };
    return this.quoteNameValidity;
  }
  intializeJobValidations() {
    this.jobNameValidity = {
      isJobAlphanumeric: true,
      isJobNameMinLimit: true,
      isJobNameMaxLimit: true,
    };
    return this.jobNameValidity;
  }
  intializeQtyValidations() {
    this.qtyValidity = {
      isOrderQtyNotNull: true,
      isOrderQtynumeric: true,
      isOrderQtyLessThanMaxLimit: true,
      isOrderQtyMinLimit: true,
      isOrderQtyValid: true,
      isValidAvailableQty: true,
    };
    return this.qtyValidity;
  }
  validateQuoteName(name, quoteNameValidity) {
    quoteNameValidity.isQuoteAlphaSpecialChar =
      hasQuoteAlphaSpecialIntChar(name);
    quoteNameValidity.isQuoteNameMinLimit = isMinLength(name, 1);
    quoteNameValidity.isQuoteNameMaxLimit = isMaxLengthLessthan(name, 50);
    return quoteNameValidity;
  }
  validateJobName(name, jobNameValidity) {
    jobNameValidity.isJobAlphanumeric = isJobNameAlphanumeric(name);
    jobNameValidity.isJobNameMinLimit = isMinLength(name, 1);
    jobNameValidity.isJobNameMaxLimit = isMaxLengthLessthan(name, 50);
    return jobNameValidity;
  }
  handleQuoteNameChange(e) {
    let quoteNameValidity = this.state;
    const { value } = e.target;
    const quoteValue = value.charAt(0);
    let quoteString = value;
    if (quoteValue === " ") {
      quoteString = value.trim();
    }
    quoteNameValidity = this.validateQuoteName(quoteString, quoteNameValidity);
    this.setState({
      quoteName: quoteString.toUpperCase(),
      quoteNameValidity: {
        ...quoteNameValidity,
      },
    });
  }
  handleQuoteNameBlur() {
    let quoteNameValidity = this.state;
    quoteNameValidity = this.intializeQuoteValidations();
    this.setState({
      quoteNameValidity: {
        ...quoteNameValidity,
      },
    });
  }
  handleJobNameChange(e) {
    let jobNameValidity = this.state;
    const { value } = e.target;
    const jobValue = value.charAt(0);
    let jobString = value;
    if (jobValue === " ") {
      jobString = value.trim();
    }
    jobNameValidity = this.validateJobName(jobString, jobNameValidity);
    this.setState({
      jobName: jobString.toUpperCase(),
      jobNameValidity: {
        ...jobNameValidity,
      },
    });
  }
  handleJobNameBlur(e) {
    let jobNameValidity = this.state;
    jobNameValidity = this.intializeJobValidations();
    this.setState({
      jobNameValidity: {
        ...jobNameValidity,
      },
    });
  }
  componentDidMount() {
    let { selectedQuote, dispatch, userDetails } = this.props;
    dispatch({
			payload: { show: false },
			type: 'VIEWPROMOTIONS_TOGGLE'
		});
    if (!_isEmpty(userDetails)) {
      // getCart(dispatch);
      getCheckoutItems(dispatch, true);
    }
    if (!_isEmpty(selectedQuote)) {
      this.setState({ quoteName: selectedQuote });
    }
    const userPreferenceDetail = getPreferenceDataFromStorage();
    if (!_isEmpty(userPreferenceDetail)) {
      let selectedLostSaleView = {};
      selectedLostSaleView = _find(userPreferenceDetail, {
        code: "Cart",
        text: "Lost Sales",
      });
      let selectedEmailPref = {};
      selectedEmailPref = _find(userPreferenceDetail, {
        code: "Cart",
        text: "Order Notifications",
      });

      const userPreferenceEmail =
        selectedEmailPref &&
        selectedEmailPref.answer &&
        selectedEmailPref.answer.text &&
        selectedEmailPref.answer.text;

      if (!_isEmpty(userPreferenceEmail)) {
        this.setState({
          userPrefViewForEmail: userPreferenceEmail,
        });
      }

      const userPreferenceView =
        selectedLostSaleView &&
        selectedLostSaleView.answer &&
        selectedLostSaleView.answer.code &&
        selectedLostSaleView.answer.code;
      this.setState({
        userPrefViewForLostSale:
          userPreferenceView == "implicit" ? false : true,
      });
    }
  }
  componentDidUpdate(prevProps) {
    let {
      totalBillingInfo,
      successPopUp,
      checkoutDetails,
      cartStore,
      cartShipMethod,
      cartDistributionWaringPopup,
      poNum,
      poDetails
    } = this.state;
    let { userDetails, dispatch } = this.props;
    dispatch({
			payload: { show: false },
			type: 'VIEWPROMOTIONS_TOGGLE'
		});
    let stateChanged = false;
    if (!_isEqual(this.props, prevProps)) {
      totalBillingInfo.totalPartsCostPrice = 0;
      totalBillingInfo.totalPartsListPrice = 0;
      checkoutDetails = [];
      this.props.checkoutDetails &&
        this.props.checkoutDetails.map((cD, key) => {
          let single = {
            ...cD,
            qtyValidity: this.intializeQtyValidations(),
            cartFlag: "Yes",
          };
          checkoutDetails.push(single);
          totalBillingInfo.totalPartsCostPrice +=
            cD.totalCostPrice + cD.qty * cD.corePrice;
          totalBillingInfo.totalPartsListPrice += cD.totalListPrice;
        });
      stateChanged = true;
      poNum = !this.props.cartPlaceOrderWarning && this.props.checkoutDetails && !_isEmpty(this.props.checkoutDetails) && this.props.checkoutDetails[0].purchaseOrder ? this.props.checkoutDetails[0].purchaseOrder : "";
      if(this.props.cartPlaceOrderWarning) {
        poNum = poDetails.poNumber;
      }
    }
    if (!_isEqual(this.props, prevProps)) {
      if (
        this.props.cartPlaceOrderWarning !== prevProps.cartPlaceOrderWarning &&
        this.props.cartPlaceOrderWarning === true
      ) {
        cartDistributionWaringPopup = true;
        stateChanged = true;
      }
      if (
        this.props.cartOrderPlaced !== prevProps.cartOrderPlaced &&
        this.props.cartOrderPlaced === true
      ) {
        successPopUp = true;
        stateChanged = true;
        if (userDetails && userDetails.userType && userDetails.userType === "SMS_MITCHELL1") {
          this.transferOrderParts();
        }
      }
      if (!_isEqual(this.props.cartStore, prevProps.cartStore)) {
        stateChanged = true;
        cartStore = this.props.cartStore;
      }
      if (!_isEqual(this.props.cartShipMethod, prevProps.cartShipMethod)) {
        stateChanged = true;
        cartShipMethod = this.props.cartShipMethod;
      }
      if (!_isEqual(this.props.userDetails, prevProps.userDetails)) {
        let { dispatch } = this.props;
        // getCart(dispatch);
        getCheckoutItems(dispatch, true);
        const userPreferenceDetail = getPreferenceDataFromStorage();
        if (userPreferenceDetail) {
          let selectedLostSaleView = {};
          selectedLostSaleView = _find(userPreferenceDetail, {
            code: "Cart",
            text: "Lost Sales",
          });
          let selectedEmailViewType = {};
          selectedEmailViewType = _find(userPreferenceDetail, {
            code: "Cart",
            text: "Oder Notifications",
          });
          const userPreferenceEmail =
            selectedEmailViewType &&
            selectedEmailViewType.answer &&
            selectedEmailViewType.answer.text &&
            selectedEmailViewType.answer.text;

          if (!_isEmpty(userPreferenceEmail)) {
            this.setState({
              userPrefViewForEmail: userPreferenceEmail,
            });
          }

          let userPrefView =
            selectedLostSaleView &&
            selectedLostSaleView.answer &&
            selectedLostSaleView.answer.code &&
            selectedLostSaleView.answer.code;
          let userPrefViewForLostSale = userPrefView === "implicit";
          this.setState({
            ...this.state,
            userPrefViewForLostSale,
          });
        }
      }
      if (stateChanged) {
        let { selectedQuote } = this.props;
        if (!_isEmpty(selectedQuote)) {
          this.setState({ quoteName: selectedQuote });
        }
        this.setState({
          checkoutDetails,
          totalBillingInfo,
          successPopUp,
          cartDistributionWaringPopup,
          cartStore,
          cartShipMethod,
          poNum
        });
      }
    }
  }

  handleImplicitLostSaleSubmit(lostSaleData, sourcePath) {
    let { dispatch } = this.props;
    const { history } = this.props;
    const modified = lostSaleData.map((item) => {
      return {
        availabilityDuration: item.availabilityHour,
        availableQty: item.availableQty,
        brandName: item.mfgCode,
        costPrice: item.costPrice,
        partDesc: item.desc,
        partNum: item.partNum,
        selectedQty: item.qty,
        userId: this.props.userDetails.x2userName,
        userResponse: {
          availability: item.propAvailability,
          brand: item.propBrand,
          otherReason: ["Unknown"],
          customer: item.notInterested ? ["Not interested"] : undefined,
          betterPrice: item.betterPrice,
          fromWD: item.fromWD,
        },
        vehicle: item.vehicleInfo,
      };
    });
    submitLostSales(modified, dispatch);
    if (modified.length === 1) {
      const deletePart = {
        availabilityHour: _head(lostSaleData).availabilityHour,
        partNum: _head(lostSaleData).partNum,
        qty: _head(lostSaleData).qty,
        vehicleInfo: _head(lostSaleData).vehicleInfo,
        mfgCode: _head(lostSaleData).mfgCode,
        pricingType: _head(lostSaleData).pricingType,
      };
      this.setState({
        ...this.state,
        openWarning: false,
      });
      deleteItemFromCart2(deletePart, dispatch, false);
    } else {
      clearCart(dispatch);
    }
    if (sourcePath === "clearCartBtn") {
      history.push("/");
    }
  }
  handleDeleteItem(e, part) {
    const obj = [part];
    let { userPrefViewForLostSale, warningMessage } = this.state;
    this.setState({
      showLostSales: userPrefViewForLostSale,
      lostSalesData: obj,
      sourceOfLostSales: "deleteIcon",
      openWarning: !userPrefViewForLostSale ? true : false,
      warningMessage: "You want to delete this cart item",
    });
  }
  handleInputOnMouseLeave(e, part, partNum, availabilityHour) {
    let {
      target: { value },
    } = e;
    let { cartAllDetails, preveousOrderQty } = this.state;

    let partIndex = _findIndex(cartAllDetails, {
      availabilityHour: availabilityHour,
      partNum: partNum,
      itemId: part.itemId && part.itemId,
    });
    if (!_isEmpty(value) && Number(value) !== 0) {
      value = getPartPackQty(
        part.qtyBuyInc,
        Number(value),
        cartAllDetails[partIndex].availableQty
      );

      if (
        preveousOrderQty != value &&
        part.qtyBuyInc > 1 &&
        value <= cartAllDetails[partIndex].availableQty
      ) {
        this.qtyByINCUpdateSuccessMessage();
        preveousOrderQty = value;
      }
    } else {
      value = Number(value);
      preveousOrderQty = Number(value);
    }
    cartAllDetails[partIndex].qty = Number(value);
    this.setState({
      ...this.state,
      cartAllDetails,
      preveousOrderQty,
    });
  }
  handleInputOnBlur(e, part, partNum, availabilityHour) {
    let {
      target: { value },
    } = e;
    let { cartAllDetails, showLostSales, lostSalesData } = this.state;
    let { dispatch, userDetails } = this.props;
    let partIndex = _findIndex(cartAllDetails, {
      availabilityHour: availabilityHour,
      partNum: partNum,
      itemId: part.itemId && part.itemId,
    });
    if (!_isEmpty(value) && Number(value) !== 0) {
      value = getPartPackQty(
        part.qtyBuyInc,
        Number(value),
        cartAllDetails[partIndex].availableQty
      );
      value = Number(value);
    }

    cartAllDetails[partIndex].qty = Number(value);
    if (Number(value) === 0) {
      cartAllDetails[partIndex].qty = this.props.cartAllDetails[partIndex].qty;
      showLostSales = true;
      lostSalesData = [part];
    } else {
      if (
        cartAllDetails[partIndex].qty <= cartAllDetails[partIndex].availableQty
      ) {
        addItemToCart2(cartAllDetails, dispatch, true, userDetails, "cartpreview");
      }
    }
    this.setState({
      ...this.state,
      cartAllDetails,
      testState: true,
      showLostSales,
      lostSalesData,
      sourceOfLostSales: "qtyToZero",
    });
  }
  qtyByINCUpdateSuccessMessage = () => {
    let { dispatch } = this.props;
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = displayQtyChangeMsg();
    statusMessageProps.type = "success";
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  };

  validateQty(value, qtyValidity, availableQty) {
    let orderQty = value;
    qtyValidity.isOrderQtyNotNull = !isInputBlank(orderQty);
    qtyValidity.isOrderQtynumeric = isNumeric(orderQty);
    qtyValidity.isOrderQtyLessThanMaxLimit = isLengthLessthan(orderQty, 4);
    qtyValidity.isOrderQtyMinLimit = hasMinLength(orderQty, 1);
    qtyValidity.isValidAvailableQty = isValidAvailableQty(
      orderQty,
      availableQty
    );
    qtyValidity.isOrderQtyValid = _every(
      _pick(qtyValidity, [
        "isOrderQtyNotNull",
        "isOrderQtynumeric",
        "isOrderQtyLessThanMaxLimit",
        "isOrderQtyMinLimit",
        "isValidAvailableQty",
      ])
    );
    // qtyValidity.partIndex  = partIndex;
    return qtyValidity;
  }
  handleInputOnChange(e, partNum, availabilityHour, vehicleInfo) {
    let {
      target: { value, name },
    } = e;
    let { cartAllDetails, validCart, preveousOrderQty } = this.state;
    let partIndex = _findIndex(cartAllDetails, {
      availabilityHour: availabilityHour,
      partNum: partNum,
      vehicleInfo: vehicleInfo,
    });
    preveousOrderQty = Number(value);
    cartAllDetails[partIndex].qty = Number(value);
    if (value !== "") {
      cartAllDetails[partIndex].qtyValidity = this.validateQty(
        value,
        cartAllDetails[partIndex].qtyValidity,
        cartAllDetails[partIndex].availableQty
      );
      validCart = _every(
        cartAllDetails,
        (cD) => cD.qtyValidity.isOrderQtyValid === true
      );
    }

    this.setState({
      ...this.state,
      cartAllDetails,
      validCart,
      preveousOrderQty,
    });
  }

  clearUserCart(implicitClear) {
    let { dispatch } = this.props;
    dispatch(setQuoteSelected(''));
    const { checkoutDetails, userPrefViewForLostSale } = this.state;
    for (var key in checkoutDetails) {
      checkoutDetails[key].propAvailability = [];
      checkoutDetails[key].propBrand = [];
      checkoutDetails[key].propOther = [];
      checkoutDetails[key].betterPrice = [];
      checkoutDetails[key].fromWD = [];
    }
    let cartDetails = [...checkoutDetails];

    if (implicitClear) {
      this.handleImplicitLostSaleSubmit(
        cartDetails,
        "clearCartBtn"
      );
    } else {
    this.setState({
      showLostSales: this.state.userPrefViewForLostSale,
      lostSalesData: cartDetails,
      sourceOfLostSales: "clearCartBtn",
      openWarning: !userPrefViewForLostSale ? true : false,
      warningMessage: "You want to clear the cart",
    });
  }
  }

  handleSaveAsQuote = () => {
		let { dispatch, userDetails: { x2userName, mobileNumber } } = this.props;
		let { quoteName, quoteNameValidity, poNum, comments } = this.state;
		quoteNameValidity = this.validateQuoteName(quoteName, quoteNameValidity);
		if (_every(quoteNameValidity)) {
			let quotePrefix = 'Default';
			let toAddress = 'self';
			// saveQuote(x2userName, quoteName, mobileNumber, dispatch).then((savedQuote) => {
        saveQuoteForStockOrder(x2userName, quoteName, mobileNumber, dispatch, false, toAddress, poNum, comments, quotePrefix).then((savedQuote) => {
          const quoteSaved = {
					heading: `Quote: ${quoteName} is saved`,
					message: '',
					type: 'success'
				};
				triggerCustomUxCriticalError(quoteSaved, dispatch);
			});
			dispatch({
				payload: {
					cartDetails: []
				},
				type: actionTypes.CART_DETAILS_FETCHED
			});
			dispatch({
				payload: {
					checkoutdetails: []
				},
				type: actionTypes.CART_CHECKOUT_FETCHED
			});
			this.setState({ showQuoteModal: false, redirectToHomePage: true, quoteName: '' });
			dispatch(setQuoteSelected(''));
		} else {
			this.setState({
				quoteNameValidity: {
					...quoteNameValidity
				}
			});
		}
	};

  handleSaveAsJob = () => {
    let {
      dispatch,
      userDetails: { x2userName, billTo, shipTos },
    } = this.props;
    let { checkoutDetails, jobName, jobNameValidity } = this.state;
    let jobDetailsList = [];
    jobNameValidity = this.validateJobName(jobName, jobNameValidity);
    if (_every(jobNameValidity)) {
      checkoutDetails.map((item) => {
        if (item.categoryId !== null && item.groupId !== null) {
          const partType =
            item.partType === "Regular" ? "catalog" : "chemicals";
          jobDetailsList.push({
            categoryId: parseInt(item.categoryId),
            groupId: parseInt(item.groupId),
            jobSearchId: "",
            jobsDetailsId: "",
            partTypeDesc: item.desc,
            partTypeId: item.descId !== null ? item.descId : "0",
            partType: partType,
          });
        }
      });
      const payload = {
        createdBy: x2userName,
        createdDate: timeInUTC(),
        customerId: billTo.billToId,
        jobName: jobName,
        recommendedJob: "N" ,
        jobSearchId: "",
        jobsDetails: jobDetailsList,
        shipToId: shipTos[0].shipToId,
        userTimeZone: "IST", //TODO
        dispatch: dispatch,
      };
      try {
        const addJobAction = dispatch(addJob(payload));
        addJobAction.then((action) => {
          if (action.type && action.type === "addJob/fulfilled") {
            this.setState({ showJobModal: false });
            const statusMessageProps = {
              heading: `Job: ${jobName} is created`,
              message: "",
              type: "success",
            };
            this.setState({ jobSaved: true });
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
          }
        });
      } catch (error) {
        triggerUxCritialErrors(error, dispatch);
      }
    } else {
      this.setState({
        jobNameValidity: {
          ...jobNameValidity,
        },
      });
    }
  };

  placeOrder(mN, accPartialShip, orderComments, isPlaceOrderDistributed) {
    let {
      dispatch,
      userDetails: { x2userName, shipTos, billTo, userType },selectedAddressId, cartTempDeliveryAddress
    } = this.props;
    let { cartAllDetails, poNum, altEmail, userPrefViewForEmail, placeOrderData, poDetails, cartDistributionWaringPopup } = this.state;
    this.setState({ orderComment: orderComments });
    placeOrderData = { mN: mN, accPartialShip: accPartialShip, orderComments: orderComments };
    altEmail = !isEmpty(altEmail)
      ? altEmail
      : !isEmpty(userPrefViewForEmail)
        ? userPrefViewForEmail
        : "";
    let uniqPartDetails = _uniqBy(cartAllDetails, "partNum");
    let uniqVehicleDetails = _uniqBy(cartAllDetails, "vehicleInfo");
    this.setState({
      showLoader: true,
      placeOrderData
    });
    uniqPartDetails.map((item, key) => {
      if (item.categoryId && !_isEmpty(item.descId)) {
        const payload = {
          dispatch: dispatch,
          categoryId: item.categoryId,
          createdBy: x2userName,
          createdDate: timeInUTC(),
          customerId: billTo.billToId,
          engine: item.engine ? item.engine.trim() : "",
          engineId: item.engineId ? item.engineId : "",
          groupId: item.groupId,
          lastOrderedDate: timeInUTC(),
          make: item.make ? item.make.trim() : "",
          makeId: item.makeId ? item.makeId : "",
          model: item.model ? item.model.trim() : "",
          modelId: item.modelId ? item.modelId : "",
          orderedFlag: "Y",
          partTypeDesc: item.desc,
          partTypeDescId: item.descId,
          searchPartId: "",
          shipToId: shipTos[0].shipToId,
          source: item.partType === "Regular" ? "partCategory" : "chemicals",
          updatedBy: x2userName,
          updatedDate: timeInUTC(),
          userFilter: "ordered",
          year: item.yearId ? item.yearId.trim() : "",
        };
        dispatch(setPartHistory(payload));
      }
    });
    uniqVehicleDetails.map((item, key) => {
      if (item.yearId && item.makeId && item.modelId && item.engineId) {
        const payload = {
          dispatch: dispatch,
          createdBy: x2userName,
          createdDate: timeInUTC(),
          customerId: billTo.billToId,
          engine: item.engine ? item.engine.trim() : "",
          engineId: item.engineId ? item.engineId : "",
          favoriteFlag: "N",
          favoriteText: "",
          favoriteUpdatedDate: "",
          lastOrderedDate: timeInUTC(),
          make: item.make ? item.make.trim() : "",
          makeId: item.makeId ? item.makeId : "",
          internalMakeId: item.makeId ? item.makeId : "",
          model: item.model ? item.model.trim() : "",
          modelId: item.modelId ? item.modelId : "",
          orderedFlag: "Y",
          searchId: "",
          shipToId: shipTos[0].shipToId,
          updatedBy: x2userName,
          updatedDate: timeInUTC(),
          year: item.yearId ? item.yearId.trim() : "",
        };
        dispatch(setVehicles(payload));
      }
    });
    const smsToken = (userType === "SMS_MITCHELL1") ? true : false;
    if(cartDistributionWaringPopup) {
      poNum = poDetails.poNumber;
      orderComments = poDetails.comments;
    }
    let sellerNetwork =
      shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.sellerNetwork
        ? shipTos[0].location.sellerNetwork
        : "";

    placeCartOrder2(
      poNum.trim(),
      mN,
      altEmail,
      accPartialShip,
      orderComments,
      isPlaceOrderDistributed,
      dispatch,
      smsToken,
      selectedAddressId,
      cartTempDeliveryAddress,
      sellerNetwork

    ).then(() => {
      this.setState({
        showLoader: false,
      });
    });
  }

  handleEstimateViewBtn = () => {
    this.props.dispatch({
      payload: {
        hideCostPrice: true,
      },
      type: "HIDE_COST_PRICE",
    });
    this.setState({ showEstimateView: true });
  };

  handleVehicleMaintenan = (openOrClose, part) => {
    let showVehMaintanace = [];
    if (openOrClose == 1) {
      showVehMaintanace = true;
    } else {
      showVehMaintanace = false;
    }
    this.setState({ vehicleData: part });
    this.setState({ showVehicleMaintenance: showVehMaintanace });
  };

  handleFluidSpec = (openOrClose, part) => {
    let showFluidSpec = [];
    if (openOrClose == 1) {
      showFluidSpec = true;
    } else {
      showFluidSpec = false;
    }
    this.setState({ vehicleData: part });
    this.setState({ showFluidsAndFilter: showFluidSpec });
  };

  handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  handleKeyDown = (event, name) => {
    const { quoteName, jobName } = this.state;
    switch (name) {
      case "quotename":
        if (
          ((quoteName && quoteName.indexOf() === 0) ||
            quoteName === null ||
            quoteName === "") &&
          event.key === " "
        ) {
          event.preventDefault();
        }
        if (
          quoteName &&
          quoteName.length >= 50 &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "jobname":
        if (
          ((jobName && jobName.indexOf() === 0) ||
            jobName === null ||
            jobName === "") &&
          event.key === " "
        ) {
          event.preventDefault();
        }
        if (
          noSpecialCharsRegExp(event.key) ||
          (jobName &&
            jobName.length >= 50 &&
            this.handleOtherKeys(event.keyCode))
        ) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  handleWarningClose = () => {
    this.setState({
      ...this.state,
      openWarning: false,
    });
  };

  handlePlaceOrderWarningClose = () => {
    let { placeOrderData } = this.state;
    this.placeOrder(placeOrderData.mN, placeOrderData.accPartialShip, placeOrderData.orderComments, true);
    this.setState({
      ...this.state,
      openWarning: false,
      cartDistributionWaringPopup: false
    });
  };
  handlePlaceOrder = () => {
    let { dispatch } = this.props;
    dispatch({
      payload: {
        cartplaceorderwarning: false,
      },
      type: actionTypes.CART_PLACE_ORDER_WARNING,
    });
    this.setState({
      ...this.state,
      cartDistributionWaringPopup: false,
    });
  };

  redirectToExpressCheckout = () => {
    let { dispatch } = this.props;
    dispatch(setExpressHomepage({expressHome: true }));
  }

 transferOrderParts = () => {
    let { checkoutDetails, poNum, orderComment, cartShipMethod } = this.state;
    let {
      orderConfirmationNumber,
      userDetails,selectedAddressId
    } = this.props;
		let cartToTransfer = [];
		const cartList = checkoutDetails.map(obj => ({...obj}));
		const uniqueParts = cartList.reduce((cart, curr) => {
			const index = cart.findIndex(item => item.partNum === curr.partNum)
			index > -1 ? cart[index].qty += curr.qty : cart.push(curr)
			return cart
		}, []);

    const deliveryType = !_isEmpty(checkoutDetails) ? ((checkoutDetails[0].shipMethod === "0002" || checkoutDetails[0].shipMethod === "0001") ? "Pickup" : "Delivery")
     : (userDetails.shipTos[0].shipMethod === "0001" || userDetails.shipTos[0].shipMethod === "0002")
       ? "Pickup"
       : "Delivery";

  //  const deliveryType = (cartShipMethod && cartShipMethod != null) ? (cartShipMethod == "0001" || cartShipMethod == "0002") ? "Pickup" : "Delivery"
  //    : (userDetails.shipTos && !_isEmpty(userDetails.shipTos) && (userDetails.shipTos[0].shipMethod === "0001" || userDetails.shipTos[0].shipMethod === "0002"))
  //      ? "Pickup"
  //      : "Delivery";
   const orderItem = catalogSdk.createOrderItem(orderComment || "", deliveryType || "", poNum || "", orderConfirmationNumber || "");
		uniqueParts.map((part) => {
			// getInterChangeBrandOptions(part.partNum, "ALL", dispatch);
			catalogSdk.addOrderPart2(
				orderItem, // orderItem - Order created via createOrderItem
				part.store, // locationId - Identifier for location part is from - TODO
				"", // locationName - Name for location part is from - TODO
				"", // status - Order status - TODO
				part.partNum, // partNumber - Part Number
				part.mfgCode, // manufacturerLineCode - Manufacturer line code - required
				"-", // manufacturerName - Manufacturer Name - TODO
				part.desc, // description - Description of part
				part.listPrice, // unitList - List
				part.costPrice, // unitCost - Cost
				part.corePrice, // unitCore - Any Core Amount
				part.qty, // quantityRequested - Typically, qty requested = qty ordered = qty available. However, you can return different values. 
				part.qty, // quantityOrdered - Actual qty of parts successfully ordered
				part.availableQty, // quantityAvailable - Available qty - TODO
				"", // size - Part Size (e.g. Tire Size) (optional)
				catalogSdk.PartCategory.UNSPECIFIED, // partCategory - Type of part (optional)
				"", // Name of the Supplier (optional) - TODO (Chemicals) - TODO
				"", // Custom metadata for use by the catalog (optional) - TODO
				"", // shippingDescription - shipping description (optional) - TODO
        0, // shippingCost - shipping cost (optional) - TODO
			);    
			cartToTransfer.push(orderItem);
		});
		catalogSdk.transfer(JSON.stringify(cartToTransfer, undefined, 4));
	}

  render() {
    let {
      qtyInCart,
      cartApiFetching,
      orderConfirmationNumber,
      dispatch,
      userDetails,
      hideCostPrice,
      cartStore,
      cartShipMethod,
      cartPlaceOrderWarning,
      isExpressCheckoutTab,
      cartOrderPlaced,
      orderType,
      selectedAddressId
    } = this.props;
    let {
      altEmail,
      cartAllDetails,
      totalBillingInfo,
      successPopUp,
      showQuoteModal,
      showJobModal,
      showLostSales,
      showEstimateView,
      vehicleData,
      showVehicleMaintenance,
      showFluidsAndFilter,
      redirectToHomePage,
      lostSalesData,
      sourceOfLostSales,
      quoteName,
      jobName,
      quoteNameValidity,
      jobNameValidity,
      validCart,
      jobSaved,
      poNum,
      showON,
      showLoader,
      userPrefViewForEmail,
      openWarning,
      warningMessage,
      checkoutDetails,
      cartDistributionWaringPopup,
      isQuickCart
    } = this.state;
    const deliveryType = !_isEmpty(checkoutDetails) ? ((checkoutDetails[0].shipMethod === "0002" || checkoutDetails[0].shipMethod === "0001") ? "Pickup" : "Delivery")
     : (userDetails.shipTos[0].shipMethod === "0001" || userDetails.shipTos[0].shipMethod === "0002")
       ? "Pickup"
       : "Delivery";
    if (redirectToHomePage) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    return (
      <div className={clsx({ "promotions-active": this.props.viewPromotions && !isQuickCart })}>
      {!isQuickCart && (
        <div className="shopping_cart_titles">
          <Container>
            <Row>
              <Col lg="3"></Col>
              <Col lg="5">
                <div className="cart_breadcrumb">
                  {this.props.userDetails &&
                    this.props.userDetails.shipTos &&
                    this.props.userDetails.shipTos.length > 0 &&
                    this.props.userDetails.shipTos[0].shipMethod &&
                    this.props.userDetails.shipTos[0].shipMethod ? (
                    <Fragment>
                      <div className="wc-ship-info">
                        <div className="text-black">
                          <span>
                            You are in Check Out Page, please review items and
                            place order
                          </span>
                          <br />
                        </div>
                        <ShipInfoHeader/>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              <Col lg="4">
                <div className="cart_breadcrumb">
                  <div className="estimate_hide_container table-view-estimate">
                    <div>
                      <img src={estimateViewIcon} alt="Estimate View" onClick={this.handleEstimateViewBtn} />
                      <span onClick={this.handleEstimateViewBtn}>
                        Estimate View
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
        <Container fluid
          className={isQuickCart? "quickCart_container" : "checkout_container pl-5 pr-5"}
          ref={(el) => (this.componentRef = el)}
        >
          <Row className="shopping_details">
          {!isQuickCart && (
            <CartPartDetails
              clearUserCart={this.clearUserCart}
              saveAsQuote={() => {
                this.setState({ showQuoteModal: true });
              }}
              saveAsJob={() => {
                this.setState({ showJobModal: true });
              }}
              handleVehicleMaintenan={this.handleVehicleMaintenan}
              handleFluidSpec={this.handleFluidSpec}
              jobSaved={jobSaved}
              checkoutDetails={checkoutDetails}
              cartApiFetching={cartApiFetching}
              handleDeleteItem={this.handleDeleteItem}
              handleInputOnBlur={this.handleInputOnBlur}
              handleInputOnMouseLeave={this.handleInputOnMouseLeave}
              handleInputOnChange={this.handleInputOnChange}
              hideCostPrice={hideCostPrice}
              userDetails={userDetails}
            />
          )}
            <CartPricingDetails
              cartOrderType={orderType}
              isQuickCart={isQuickCart}
              clearUserCart={this.clearUserCart}
              placeOrder={this.placeOrder}
              totalBillingInfo={totalBillingInfo}
              saveAsQuote={() => {
                this.setState({ showQuoteModal: true });
              }}
              saveAsJob={() => {
                this.setState({ showJobModal: true });
              }}
              jobSaved={jobSaved}
              hideCostPrice={hideCostPrice}
              validCart={validCart}
              poNum={poNum}
              setPoDetails={(poDetails) => this.setState({ poDetails: poDetails })}
              cartPlaceOrderWarning={cartPlaceOrderWarning}
              handleComment={(e) => this.setState({ comments: e.target.value })}
              handlePoNum={(e) => this.setState({ poNum: e })}
              userDetails={userDetails}
              handleSettings={() =>
                this.setState({ showON: !this.state.showON })
              }
              checkoutDetails={checkoutDetails}
            />
          </Row>
        </Container>
        {cartOrderPlaced && (<ThankyouPopup
          openPopup={successPopUp}
          orderConfirmationNumber={orderConfirmationNumber}
          closePopup={() => {
            this.setState({
              successPopUp: false,
              redirectToHomePage: (!isExpressCheckoutTab ? true : this.redirectToExpressCheckout()) ,
            });
            dispatch({
              payload: {
                orderPlaced: false,
                orderConfirmationNumber: "",
              },
              type: "CART_ORDER_PLACED",
            });
          }}
        />)}
        {showQuoteModal && (
          <XModal
            title="Quote Name"
            show={showQuoteModal}
            className="custom_xmodal quote-modal"
            handleClose={() => {
              this.setState({ showQuoteModal: false });
            }}
            handleAction={this.handleSaveAsQuote}
            yesButtonText="Save"
            noButtonText="Cancel"
            isValidValidity={!_isEmpty(quoteName) ? true : false}
          >
            <div className="my-1 col-sm-12" style={quoteStyle}>
              <Form.Control
                placeholder={
                  !_isEmpty(quoteName) ? `${quoteName}` : "Quote Name"
                }
                className="input__default"
                name="quotename"
                maxLength="51"
                value={quoteName}
                onChange={this.handleQuoteNameChange}
                onBlur={this.handleQuoteNameBlur}
                onKeyDown={(event) => {
                  this.handleKeyDown(event, "quotename");
                }}
              />
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip`}>
                    Enter Customer Name or Phone Number or Both or Any Text to
                    identify this quote.
                  </Tooltip>
                }
              >
                <span class="material-icons">info</span>
              </OverlayTrigger>
            </div>
            <FormValidationErrorMessage
              condition={
                !quoteNameValidity.isQuoteAlphaSpecialChar &&
                quoteNameValidity.isQuoteNameMinLimit
              }
              errorMessage={
                "Quote name must contain alphanumeric or special characters only"
              }
            />
            <FormValidationErrorMessage
              condition={
                !quoteNameValidity.isQuoteNameMinLimit &&
                quoteNameValidity.isQuoteAlphaSpecialChar
              }
              errorMessage={"Quote name should have atleast 1 character"}
            />
            <FormValidationErrorMessage
              condition={
                !quoteNameValidity.isQuoteNameMaxLimit &&
                quoteNameValidity.isQuoteNameMinLimit
              }
              errorMessage={"Quote name should not exceed 50 characters"}
            />
          </XModal>
        )}
        <XModal
          title="Order Notifications"
          show={showON}
          className="custom_xmodal"
          handleClose={() => {
            this.setState({ showON: false, altEmail: null });
          }}
          isValidValidity={true}
          handleAction={() => this.setState({ showON: false })}
          yesButtonText="Ok"
          noButtonText="Cancel"
        >
          <div className="my-1 col-sm-12">
            <span>
              Your order confirmation message will be sent to this address{" "}
              <b>
                {" "}
                {!isEmpty(userPrefViewForEmail)
                  ? userPrefViewForEmail
                  : userDetails
                    ? userDetails.x2userName
                    : ""}
              </b>
              . Alternatively, please enter email address below for order
              confirmation message.
            </span>
            <Form.Control
              placeholder="Alternate Email"
              class="input__default"
              name="altEmail"
              maxLength="51"
              value={altEmail}
              onChange={(e) => {
                this.setState({ altEmail: e.target.value });
              }}
            />
          </div>
        </XModal>
        {showJobModal && (
          <XModal
            title="Job Name"
            show={showJobModal}
            className="custom_xmodal"
            handleClose={() => {
              this.setState({ showJobModal: false, jobName: "" });
            }}
            handleAction={this.handleSaveAsJob}
            yesButtonText="Save"
            noButtonText="Cancel"
            isValidValidity={!_isEmpty(jobName) ? true : false}
          >
            <div className="my-1 col-sm-12">
              <Form.Control
                placeholder="Job Name"
                class="input__default"
                name="jobname"
                maxLength="51"
                value={jobName}
                onChange={this.handleJobNameChange}
                onBlur={this.handleJobNameBlur}
                onKeyDown={(event) => {
                  this.handleKeyDown(event, "jobname");
                }}
              />
            </div>
            <FormValidationErrorMessage
              condition={
                !jobNameValidity.isJobAlphanumeric &&
                jobNameValidity.isJobNameMinLimit
              }
              errorMessage={"Job name must be alphanumeric characters only"}
            />
            <FormValidationErrorMessage
              condition={
                !jobNameValidity.isJobNameMinLimit &&
                jobNameValidity.isJobAlphanumeric
              }
              errorMessage={"Job name should have atleast 1 character"}
            />
            <FormValidationErrorMessage
              condition={
                !jobNameValidity.isJobNameMaxLimit &&
                jobNameValidity.isJobNameMinLimit
              }
              errorMessage={"Job name should not exceed 50 characters"}
            />
          </XModal>
        )}
        {showLostSales ? (
          <LostSales
            openPopup={showLostSales}
            closePopup={() => {
              this.setState({ showLostSales: false });
            }}
            selectedPartDetails={lostSalesData}
            dispatch={dispatch}
            userId={userDetails.x2userName}
            source={sourceOfLostSales}
          />
        ) : null}
        {showEstimateView ? (
          <Estimate
            openPopup={showEstimateView}
            closePopup={() => {
              this.setState({ showEstimateView: false });
            }}
            data={checkoutDetails}
            totalBillingInfo={totalBillingInfo}
            cartApiFetching={cartApiFetching}
            userDetails={userDetails}
          />
        ) : null}
        {showVehicleMaintenance && !_isEmpty(vehicleData) &&  !_isEmpty(vehicleData[0]) ? (
          <VehicleMaintenancePopup
            openPopup={showVehicleMaintenance}
            selectedVehicle={{
              ...vehicleData[0],
              year: vehicleData[0].yearId
            }}
            closePopup={() => this.handleVehicleMaintenan(0, [])}
          />
        ) : null}
        {showFluidsAndFilter && !_isEmpty(vehicleData) &&  !_isEmpty(vehicleData[0]) ? (
          <FluidsAndFiltersPopup
            openPopup={showFluidsAndFilter}
            selectedVehicle={{
              ...vehicleData[0],
              year: vehicleData[0].yearId,
            }}
            closePopup={() => this.handleFluidSpec(0, [])}
          />
        ) : null}
        {openWarning && (
          <XModal
            show={openWarning}
            handleClose={this.handleWarningClose}
            handleAction={() => {
              this.handleImplicitLostSaleSubmit(
                lostSalesData,
                sourceOfLostSales
              );
            }}
            className="custom_xmodal"
            isValidValidity={true}
          >
            <div className="delete__text text__center">
              {warningMessage}
              <br />
            </div>
          </XModal>
        )}

        {/* <PromotionsTab
          showSidebarPopUp={this.props.viewPromotions}
          promotions={this.props.userPromotionDetails}
          handlePromotions={() => {
            const { viewPromotions } = this.props;

            this.props.dispatch({
              payload: {},
              type: "VIEWPROMOTIONS_TOGGLE",
            });

            if (!viewPromotions) {
              if (document.querySelector(".section-wrapper")) {
                document.querySelector(".section-wrapper").style.marginLeft =
                  "250px";
              }

              if (document.querySelector(".section__banner")) {
                document.querySelector(".section__banner").style.marginLeft =
                  "250px";
              }
            } else {
              if (document.querySelector(".section-wrapper")) {
                document.querySelector(".section-wrapper").style.marginLeft =
                  "0";
              }

              if (document.querySelector(".section__banner")) {
                document.querySelector(".section__banner").style.marginLeft =
                  "0";
              }
            }
          }}
        /> */}
        {showLoader && (
          <div className="custom_spinner_container">
            <Spinner className="custom_spinner" animation="border" />
          </div>
        )}
        {cartDistributionWaringPopup && (
          <XModal
            show={cartDistributionWaringPopup}
            handleAction={this.handlePlaceOrderWarningClose}
            handleClose={this.handlePlaceOrder}
            className="custom_xmodal"
            isValidValidity={true}
            yesButtonText="Yes"
            noButtonText="No"
          >
            <div className="delete__text text__center">
            There are changes in the Cart items due to inventory changes since you add items to cart. Do you want to proceed?<br />
            </div>
            <RefreshDataPopup
              checkoutDetails={checkoutDetails}
            />
          </XModal>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    checkoutDetails: state.cart.checkoutDetails,
    cartOrderPlaced: state.cart.cartOrderPlaced,
    cartPlaceOrderWarning: state.cart.cartPlaceOrderWarning,
    qtyInCart: state.cart.qtyInCart,
    cartApiFetching: state.cart.cartApiFetching,
    orderConfirmationNumber: state.cart.orderConfirmationNumber,
    userDetails: state.user.userDetails,
    hideCostPrice: state.cart.hideCostPrice,
    selectedQuote: state.mygarage.selectedQuote,
    userPromotionDetails: state.user.userPromotionDetails,
    viewPromotions: state.app.viewPromotions,
    cartStore: state.parts.cartStore,
    cartShipMethod: state.parts.cartShipMethod,
    orderType: state.cart.orderType,
    selectedAddressId:state.cart.selectedAddressId,
    cartTempDeliveryAddress: state.cart.cartTempDeliveryAddress

  };
}
export default connect(mapStateToProps)(Checkout);
