import { createSlice } from "@reduxjs/toolkit";

import { search,chemicalsearch } from "./chemicalsSearch.thunk";

let initialState = {
  loading: false,
  error: null,
  options: [],
  brand: null,
};

const chemicalsSearch = createSlice({
  name: "chemicalsSearch",
  initialState,
  reducers: {
    getGroupsByCategory(state, action) {
      const { payload } = action;
      const options = {
        chemicalParts: [],
        chemicalCategories: [],
        chemicalGroups: payload.groups,
      };

      state.options = [options];
    },
    showBrandGroups(state, action) {
      const __options = state.options[0];
      __options.chemicalBrands = [];
      __options.chemicalCategories = [];
      state.options = [__options];
    },
    setBrand(state, action) {
      const { payload } = action;
      const __options = state.options[0];
      __options.chemicalBrands = [];
      state.brand = payload.brand;
      state.options = [__options];
    },
  },
  extraReducers: {
    [search.pending]: (state) => {
      state.loading = true;
    },
    [search.fulfilled]: (state, action) => {
      const { payload } = action;
      state.options = [payload];
      state.loading = false;
    },
    [search.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [chemicalsearch.pending]: (state) => {
      state.loading = true;
    },
    [chemicalsearch.fulfilled]: (state, action) => {
      const { payload } = action;
      state.options = [payload];
      state.loading = false;
    },
    [chemicalsearch.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

const { actions, reducer } = chemicalsSearch;

export const { getGroupsByCategory, setBrand, showBrandGroups } = actions;

export default reducer;
