
import React from 'react';


/**
 * A custom error message for form validation
 *
 * @param  {bool}  props.condition    Is true if a component state is in an error condition
 * @param  {string} props.error-message    Error message
 * @return {JSX}    The paragraph for error message
 */
const FormValidationErrorMessage = ({
    children,
    condition,
    errorMessage,
}) => (
    condition ?
        (<p className="error-message">{errorMessage}{children}</p>)
        : null
);

export default FormValidationErrorMessage;
