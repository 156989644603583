import React from "react";
import T from "prop-types";
import { Link } from "react-router-dom";

import "./styles.scss";

const Remove = (props) => {
  const { handleRemove } = props;

  return (
    <React.Fragment>
      <div className="remove__component">
        <Link onClick={handleRemove} className="remove__link">
          Remove
        </Link>
      </div>
    </React.Fragment>
  );
};

Remove.propTypes = {
  handleRemove: T.func,
};

export default Remove;
