import React from "react";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  formatAmount,
  getExtendedTotalPrice
} from "../../../helpers/utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#9D1D20',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PartTable = (props) => {
  return (
    <React.Fragment>
      {!_isEmpty(props.parts) ? (
        <React.Fragment>
          <TableContainer component={Paper}>
            <Table sx={{ marginTop: '10px', width: '90%' }} size="large" aria-label="a dense table">
              <TableHead>
                <StyledTableRow>
                  {/* <StyledTableCell>P/L</StyledTableCell> */}
                  <StyledTableCell align="right">Part#</StyledTableCell>
                  <StyledTableCell align="right">Description</StyledTableCell>
                  <StyledTableCell align="right">Brand</StyledTableCell>
                  <StyledTableCell align="right">Your Price</StyledTableCell>
                  <StyledTableCell align="right">Core Price</StyledTableCell>
                  <StyledTableCell align="right">List Price</StyledTableCell>
                  <StyledTableCell align="right">Qty</StyledTableCell>
                  <StyledTableCell align="right">Availability</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {props.parts.map((part) => (
                  <StyledTableRow
                    key={part.partNumber}
                  // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell align="right">{part.partNumber}</StyledTableCell>
                    <StyledTableCell align="right">{part.description}</StyledTableCell>
                    <StyledTableCell align="right">{part.mfgName}</StyledTableCell>
                    <StyledTableCell align="right">${formatAmount(part.pricingAvailability.costPrice)}</StyledTableCell>
                    <StyledTableCell align="right">${formatAmount(part.pricingAvailability.corePrice)}</StyledTableCell>
                    <StyledTableCell align="right">${formatAmount(part.pricingAvailability.listPrice)}</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      ) : null
      }

    </React.Fragment>
  );
};

PartTable.propTypes = {};

export default PartTable;
