import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _, { isEmpty } from "lodash";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import _find from 'lodash/find';
import XModal from '../../components/shared/XModal';

function MultipleSelectCheckbox({
	handlePartTypeFilter,
	partTypes,
	currentFilter,
	multiSelectArray
}) {
	const parts = useSelector((state) => state.parts);
    let catalogFilter  = parts && parts.catalogFilter && parts.catalogFilter.partTypes;
	const [partTypeFilterSelected, setpartTypeFilterSelected] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [partTypeFilterOptions, setpartTypeFilterOptions] = useState([]);
	const [customStyle, setCustomStyle] = useState({
		option: (provided, state) => ({
			...provided,
			justifyContent: 'center',
			backgroundColor: state.label == 'APPLY' ? '#aa0d10' : 'white',
			color: state.label == 'APPLY' ? 'white' : 'black',
			padding: state.label == 'APPLY' ? '8px 85px' : '8px 12px'
		})
	});
	const filterByPartTypes = (partArr, selectedArr) => {
		let res = [];
		if(!_isEmpty(partArr) && !_isEmpty(selectedArr)){
		res = partArr.filter(el => {
		   return selectedArr.find(element => {
			  return element.id === el.id;
		   });
		});
		return res;
	   }
	 }
	useEffect(() => {
		let options = [
			{ label: "APPLY", value: "apply" },
			{ label: "ALL", value: "*" }
		];
		let selectedOptions =[{ label: "ALL", value: "*" }]
		if ((!_isEmpty(partTypes) && partTypes.length > 0) || (!_isEmpty(partTypes) &&  !_isEmpty(catalogFilter)&& catalogFilter.length > 0)) {

			partTypes.map((value) => {
				options.push({ label: value.text, value: value.id });
			});

			let data = filterByPartTypes(partTypes ,catalogFilter);

			!_isEmpty(data) && data.map((value) => {
				selectedOptions.push({ label: value.text, value: value.id });
			});

			if (currentFilter === "ALL" && _isEmpty(data)) {
				setpartTypeFilterOptions(options);
				setSelectedOptions(options.filter((o) => o.value !== "apply"));

			} else if(currentFilter === "ALL" && !_isEmpty(data)){
				setpartTypeFilterOptions(options);
				if(!_isEmpty(options) && !_isEmpty(selectedOptions) && options.length !== selectedOptions.length + 1){
				 setSelectedOptions(selectedOptions.filter((o) => o.label !== "ALL"));
				}else{
				setSelectedOptions(options.filter((o) => o.value !== "apply"));
				}
			}
			else
			if (currentFilter === "Multiple Items" ) {
				setSelectedOptions(multiSelectArray);
			} else {
				setpartTypeFilterOptions(options);
				if (typeof currentFilter === 'string') {
					let currentFiterArry = _find(options, {
						label: currentFilter
					});
					setSelectedOptions([currentFiterArry]);
				} else {
					setSelectedOptions(currentFilter.filter((o) => o.value !== "apply"));
				}
			}
		}
	}, [partTypes]);

	const onPartTypeFilterChange = (value, event) => {
		let selectedFilter = [];
		setCustomStyle(
			{
				option: (provided, state) => ({
					...provided,
					justifyContent: 'center',
					backgroundColor: state.label == 'APPLY' ? '#aa0d10' : 'white',
					color: state.label == 'APPLY' ? 'white' : 'black',
					padding: state.label == 'APPLY' ? '8px 85px' : '8px 12px'
				})
			}
		)
		if (event.action === "select-option" && event.option.value === "*") {
			setSelectedOptions(partTypeFilterOptions.filter((o) => o.value !== "apply"))
			currentFilter = event.option;
			selectedFilter = partTypeFilterOptions.filter((o) => o.value !== "*" || o.value !== "apply");
		} else if (
			event.action === "deselect-option" &&
			event.option.value === "*"
		) {
			setSelectedOptions([]);
			selectedFilter = [];
			currentFilter = "0 selected";
		} else if (event.action === "deselect-option") {
			setSelectedOptions(value.filter((o) => o.value !== "*"));
			selectedFilter = value.filter((o) => o.value !== "*" || o.value !== event.option.value)
		} else if (value.length === partTypeFilterOptions.length - 1) {
			setSelectedOptions(partTypeFilterOptions);
			selectedFilter = partTypeFilterOptions.filter((o) => o.value !== "*" || o.value !== "apply");
		} else {
			selectedFilter = value.filter((o) => o.value !== "*" || o.value !== "apply");
			setSelectedOptions(value);
		}

		if (event.option.value === "*" || value.length == 0) {
			if (((selectedFilter.length == 1 && selectedFilter[0].value == '*') || selectedFilter.length == 0) || value.length == 0) {
				setCustomStyle({
					option: (provided, state) => ({
						...provided,
						justifyContent: 'center',
						pointerEvents: state.label == 'APPLY' ? 'none' : '',
						backgroundColor: state.label == 'APPLY' ? '#808080' : 'white',
						color: state.label == 'APPLY' ? 'white' : 'black',
						padding: state.label == 'APPLY' ? '8px 85px' : '8px 12px'
					})
				})
			}
		}

		if (event.option.value === "apply") {
			handlePartTypeFilter(selectedFilter, selectedFilter);
		}
	}

	const getPartTypeFilterDdwnBtnLbl = ({ placeholderButtonLabel, value }) => {
		if (value && value.some((o) => o.value === "*")) {
			return `All`;
		} else if ((value.length == 1 && value[0].value == 'apply') || value.length == 0) {
			return `None selected`;
		} else if (value.length == 1) {
			return `${value[0].label}`;
		} else {
			return `Multiple Items`;
		}
	}

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			justifyContent: 'center',
			backgroundColor: state.label == 'APPLY' ? '#aa0d10' : 'white',
			color: state.label == 'APPLY' ? 'white' : 'black',
			padding: state.label == 'APPLY' ? '8px 85px' : '8px 12px'
		})
	}

	const handleWarningClose = () => {
		setpartTypeFilterSelected(false);
	};

	return (<div className="">
		{partTypes && partTypes.length > 0 && (
			<div class="part-type-filter">
				<span> Part Type: </span>{' '}
				<ReactMultiSelectCheckboxes
					className="dropdown-scroll"
					options={partTypeFilterOptions}
					value={selectedOptions}
					getDropdownButtonLabel={getPartTypeFilterDdwnBtnLbl}
					onChange={onPartTypeFilterChange}
					styles={customStyle}
					groupHeading={true}
					hideSearch={true}
				/>
			</div>
		)}
	</div>
	);
}
export default MultipleSelectCheckbox;
