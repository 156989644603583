import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { Form, Button, Spinner } from 'react-bootstrap';
import { getAllCustomers, impersonateUserBillTo } from './usermanagement.thunk';
import Select from 'react-select';
import { resetData } from './usermanagement.slice';
import { updateToken } from '../../../actions/auth';
import XModal from '../../shared/XModal';
import { triggerCustomUxCriticalError } from '../../../actions/error';
import { clearCart } from '../../../actions/cart';
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { setVehicleSelected } from "../../shared/MyGarage/mygarage.slice";
import { getPreferenceDataFromStorage } from '../../../helpers/utils';
import UserAccountInformation from "./UserAccountInformation";
const actionTypes = { GLOBAL_LOADER: 'GLOBAL_LOADER' };

const BillTo = () => {
    const dispatch = useDispatch();
    const cartStatus = useSelector(state => state.cart);
    const [billTo, setbillTo] = useState('');
    const [showBillTOModal, setshowBillTOModal] = useState(false);
    const [showChangeBillTO, SetShowChangeBillTO] = useState(true);
    const userDetailsArr = useSelector((state) => state.user);
    let billToStatus = useSelector((state) => state.userManagement.billToStatus);
    const [buttonState, setbuttonState] = useState(false);
    const [errorCodeState, seterrorCodeState] = useState(false);
    const [customerlistoptions, setCustomerlistoptions] = useState([]);
    const [showBillTOclearModal, setshowBillTOclearModal] = useState(false);
    const [showChangeCustomerBillTo, SetShowChangeCustomerBillTo] = useState(false);
    const refreshBillToUser = useSelector((state) => state.userManagement.refreshBillToUser);
    const customerList = useSelector((state) => state.userManagement.customerList);
    let userType = '';
    let userDetails = "";
    if (!_isEmpty(userDetailsArr)) {
        userType = userDetailsArr.userDetails.userType;
        userDetails = userDetailsArr.userDetails;
    }
    const [showLoader, setshowLoader] = useState(true);
    const [superAdminUserList, setSuperAdminUserList] = useState([]);


    useEffect(() => {
        return () => {
            dispatch(resetData());
        };
    }, [refreshBillToUser]);

    useEffect(() => {
        dispatch({
            payload: {
                globalLoader: !showLoader
            },
            type: actionTypes.GLOBAL_LOADER
        });
    }, [showLoader]);

    useEffect(() => {
        if (!_isEmpty(userDetailsArr) && !_isEmpty(superAdminUserList)) {
            createCustomerBillToOptions(superAdminUserList);
        }
    }, [userDetailsArr]);

    useEffect(() => {
        let statusMessageProps = {
            heading: "",
            message: "",
            type: "",
        };
        if (billToStatus === 'failed') {
            statusMessageProps.heading = 'BillTo modification Failed';
            statusMessageProps.type = 'error';
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
        }
    }, [billToStatus])

    useEffect(() => {
        if(userDetailsArr.userDetails.userType === "SUPER_ADMIN" || showChangeCustomerBillTo){
        dispatch(getAllCustomers());
        }
    }, [showChangeCustomerBillTo]);

    useEffect(() => {
        if (!_isEmpty(customerList)) {
            let billToOptions = createCustomerBillToOptions(customerList);
            setCustomerlistoptions(billToOptions);
        }
    }, [customerList]);

    const createCustomerBillToOptions = (billTo) => {
        let optionArr = [];
        if (!_isEmpty(billTo)) {
            billTo.map((billToName) => {
                if (billToName.billtoId !== userDetailsArr.userDetails.xlPartAccountNumber) {
                    optionArr.push({
                        value: billToName.billtoId,
                        label: billToName.billtoId + " - " + billToName.name,
                    });
                }
            });
        }
        setCustomerlistoptions(optionArr);
        return optionArr;
    };

    const handleUpdate = () => {
        setshowLoader(false);
        if (cartStatus.qtyInCart > 0 && (userDetailsArr.userDetails.userType == "SUPER_ADMIN" || showChangeCustomerBillTo)) {
            setshowLoader(true);
            setshowBillTOclearModal(true);

        } else if (cartStatus.qtyInCart == 0 && (userDetailsArr.userDetails.userType == "SUPER_ADMIN" || showChangeCustomerBillTo)) {
            setshowLoader(false);
            changeBillTo();
        }
        else
            if (userDetailsArr.userDetails.userType === "IT_ADMIN" ||
                userDetailsArr.userDetails.userType === "OSR" ||
                userDetailsArr.userDetails.userType === 'IT_INTERNAL' || 
                 userDetailsArr.userDetails.userType === "DSM") {
                const payload = {
                    billTo,
                    x2UserName: userDetailsArr.userDetails.x2userName,
                    userType: userDetailsArr.userDetails.userType,
                    buyPartnerCreationFlag: false,
                    dispatch,
                };
                dispatch(impersonateUserBillTo(payload)).then((action) => {

                    if (action.type === "impersonateUserBillTo/fulfilled") {
                        setshowLoader(true);
                        if (cartStatus.qtyInCart > 0) {
                            setshowBillTOclearModal(true);
                        }
                        else {
                            deleleteMethod();
                        }
                    } else if (action.payload.errorCode === 'E1112') {
                        setshowLoader(true);
                        setshowBillTOModal(true);
                    }
                    setshowLoader(true);
                })
            }
    }
    const changeBillTo = () => {
        setshowBillTOclearModal(false);
        const payload = {
            billTo: billTo.value,
            x2UserName: userDetailsArr.userDetails.x2userName,
            userType: userDetailsArr.userDetails.userType,
            buyPartnerCreationFlag: true,
            dispatch,
        };
        dispatch(impersonateUserBillTo(payload)).then((action) => {
            setbillTo('');
            updateToken(dispatch).then(() => {
                let statusMessageProps = {
                    heading: "",
                    message: "",
                    type: "",
                };
                if (action.payload == "Change BillTo successful") {
                    dispatch(getAllCustomers()).then((result) => {
                        setSuperAdminUserList(result.payload);
                    });
                    statusMessageProps.heading = 'BillTo modified successfully';
                    statusMessageProps.type = 'success';
                    triggerCustomUxCriticalError(statusMessageProps, dispatch);
                    setbillTo('');
                    setshowBillTOModal(false);
                    setshowBillTOclearModal(false);
                    setshowLoader(true);
                    dispatch(setVehicleSelected(null));
                    dispatch({
                        payload: {
                            selectedVehicle: null,
                        },
                        type: "VEHICLE_SELECTION_COMPLETED",
                    });
                }

            })
            setshowLoader(true);
        })
    }

    const deleleteMethod = async () => {
        if (userDetailsArr.userDetails.userType == "SUPER_ADMIN" || showChangeCustomerBillTo) {
            setshowLoader(true);
            changeBillTo();
        }
        if (cartStatus.qtyInCart > 0) {
            await clearCart(dispatch);
        }
        if (userDetailsArr.userDetails.userType === "IT_ADMIN" ||
          userDetailsArr.userDetails.userType === "OSR" || 
          userDetailsArr.userDetails.userType === 'IT_INTERNAL' || 
          userDetailsArr.userDetails.userType === "DSM" ) {
            setTimeout(() => {
                updateToken(dispatch).then(() => {
                    let statusMessageProps = {
                        heading: "",
                        message: "",
                        type: "",
                    };
                    if (billToStatus === 'success' || billToStatus.payload?.errorCode === 'E1112') {
                        statusMessageProps.heading = 'BillTo modified successfully';
                        statusMessageProps.type = 'success';
                        triggerCustomUxCriticalError(statusMessageProps, dispatch);
                        setbillTo('');
                        setshowBillTOModal(false);
                        setshowBillTOclearModal(false);
                        setshowLoader(true);
                        dispatch(setVehicleSelected(null));
                        dispatch({
                            payload: {
                                selectedVehicle: null,
                            },
                            type: "VEHICLE_SELECTION_COMPLETED",
                        });
                    } else if (billToStatus === 'failed') {
                        statusMessageProps.heading = 'BillTo modification Failed';
                        statusMessageProps.type = 'error';
                        triggerCustomUxCriticalError(statusMessageProps, dispatch);
                    }

                })
            }, 2000)
        }
    }

    const handleUpdatecreate = () => {
        const payload = {
            billTo,
            x2UserName: userDetailsArr.userDetails.x2userName,
            userType: userDetailsArr.userDetails.userType,
            buyPartnerCreationFlag: true,
            dispatch,
        };
        setshowLoader(false);
        dispatch(impersonateUserBillTo(payload)).then((action) => {
            if (action.type === "impersonateUserBillTo/fulfilled") {
                setshowLoader(true);
                if (cartStatus.qtyInCart > 0) {
                    setshowBillTOclearModal(true);
                }
                else {
                    setshowBillTOModal(false)
                    deleleteMethod();
                }
                //deleleteMethod();
            } setshowLoader(true);
        })
        setshowBillTOModal(false);
    }

    const handleMagicWordSelection = (billTo) => {
        setbillTo(billTo);
        console.log(`Option selected:`, billTo.value);
    }

    useEffect(() => {
        if (userDetailsArr.userDetails.userType !== null) {
            let userPreferenceDetail = getPreferenceDataFromStorage();
            if (!_isEmpty(userPreferenceDetail)) {
                let grandAccessItemSet = userPreferenceDetail.find((item) => {
                    return (
                        item.text === "Personalize Linked Accounts" && item.code === "User Management")
                })

                if (grandAccessItemSet && grandAccessItemSet.multiAnswer) {
                    grandAccessItemSet.multiAnswer.map((singleAnswer) => {
                        if (singleAnswer.code === "enableUserEmulation") {
                            if(userDetailsArr.userDetails.userType === 'CUSTOMER_BUYER' ||
                               userDetailsArr.userDetails.userType === 'LOCATION_BUYER' ||
                               userDetailsArr.userDetails.userType === 'CUSTOMER_ADMIN' ){
                               SetShowChangeCustomerBillTo(true);
                            }
                        }
                    })
                }
            }
        }


    }, [userDetailsArr]);

    return (
        <Fragment>
        <Fragment>

            {((userDetailsArr.userDetails && (userDetailsArr.userDetails.userType === "SUPER_ADMIN" )) || showChangeCustomerBillTo)
                && (
                    <div className="label-dropdown-container">
                        <Form.Label>XL Parts Customer Number</Form.Label>
                        <Select
                            className="custom-dropdown"
                            placeholder="Select a Customer"
                            value={billTo}
                            onChange={handleMagicWordSelection}
                            options={!_isEmpty(customerlistoptions) && customerlistoptions}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: "#84bae2",
                                    primary50: "#0F5283",
                                    primary: "#0F5283",
                                }
                            })}
                        />
                    </div>
                )}
            {(userDetailsArr.userDetails &&  (userDetailsArr.userDetails.userType === "IT_ADMIN" ||
            userDetailsArr.userDetails.userType === "OSR"  ||  userDetailsArr.userDetails.userType === 'IT_INTERNAL' || userDetailsArr.userDetails.userType === "DSM"))
                && (
                    <div className="label-input-container">
                <label htmlFor="currentPassword">Change BillTo</label>
                <Form.Control
                    type="text"
                    onChange={(event) => setbillTo(event.target.value)}
                    name="currentPassword"
                    id="currentPassword"
                    value={billTo}
                />
            </div>)}
            {showLoader ? (
                <Button
                    onClick={handleUpdate}
                    disabled={_isEmpty(billTo)}
                    className="changeBillto_button ok-button"
                >
                    ok
                </Button>) : (

                <Button
                    className="user-management-submit-btn ok-button"
                    variant="primary"
                    disabled
                >
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    Loading...
                </Button>
            )}


            {showBillTOModal
                && (
                    <XModal
                        show={showBillTOModal}
                        showButtons={false}
                        title="Create Impersonate User"
                        className="change_shipTo_save_quote_modal"
                        handleClose={() => setshowBillTOModal(false)}
                    >
                        <div className="change_shipTo_modal_heading">
                            <span className="shipTo_modal_text">
                                This Customer is not yet registered in RAPID. Do you want to really proceed?
                            </span>
                        </div>
                        <div className="shipTo_modal_button_padding">
                            <span className="shipTo_clear_cart_button">
                                {showLoader ? (
                                    <Button
                                        className="add_to_cart_button"
                                        disable={buttonState && !_isEmpty(billTo)}
                                        onClick={handleUpdatecreate}
                                    >
                                        YES
                                    </Button>) : (

                                    <Button
                                        className="user-management-submit-btn"
                                        variant="primary"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </Button>
                                )}


                            </span>
                            <span className="shipTo_save_as_quote_button">
                                <Button
                                    className="add_to_cart_button"
                                    onClick={() => setshowBillTOModal(false)}
                                >
                                    NO
                                </Button>
                            </span>
                        </div>
                    </XModal>
                )}
            {showBillTOclearModal
                && (
                    <XModal
                        show={showBillTOclearModal}
                        showButtons={false}
                        title="Create Impersonate User"
                        className="change_shipTo_save_quote_modal"
                        handleClose={() => setshowBillTOclearModal(false)}
                    >
                        <div className="change_shipTo_modal_heading">
                            <span className="shipTo_modal_text">
                                Changing BillTo will empty your Cart. Do you really want to proceed?
                            </span>
                        </div>
                        <div className="shipTo_modal_button_padding">
                            <span className="shipTo_clear_cart_button">
                                {showLoader ? (
                                    <Button
                                        className="add_to_cart_button"
                                        disable={buttonState && !_isEmpty(billTo)}
                                        onClick={deleleteMethod}
                                    >
                                        YES
                                    </Button>) : (
                                    <Button
                                        className="user-management-submit-btn"
                                        variant="primary"
                                        disabled
                                    >
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </Button>
                                )}
                            </span>
                            <span className="shipTo_save_as_quote_button">
                                <Button
                                    className="add_to_cart_button"
                                    onClick={() => setshowBillTOclearModal(false)}
                                >
                                    NO
                                </Button>
                            </span>
                        </div>
                    </XModal>
                )}
        </Fragment><br></br> <br></br><br></br>
                  <UserAccountInformation /></Fragment>

    );

}

export default BillTo;
