/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import XModal from "../../shared/XModal";
import { useDispatch, useSelector } from "react-redux";
import { Form, Modal } from "react-bootstrap";
import { Fragment } from "react";
import _ from "lodash";
import _cloneDeep from "lodash/cloneDeep";
import {
  getDefaultDataBasedOnUSerPref,
  getPreferenceDataFromStorage,
  getCartShipDetails,
} from "../../../helpers/utils";
import { getPartsByMultiGroupOrchestrator } from "../../../actions/parts";

const getUniq = (sps) => {
  let u = sps.map((sps) => sps.partSpecificConditions);
  u = _.flatten(u);
  u = _.uniqBy(u, "text");
  return u;
};

function SpecificConditionsModal(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [requestItems, setRequestItems] = useState([]);
  const [conditions, setConditions] = useState([]);

  const parts = useSelector((state) => state.parts);
  const specificsForParts = parts.extraSpecificsForParts;
  const catalogRequestGroups = parts.catalogRequestGroups;
  const specificConditions = parts.specificConditions;

  const myGarage = useSelector((state) => state.mygarage);
  const selectedVehicle = myGarage.selectedVehicle;

  const userDetails = useSelector((state) => state.user.userDetails);
  const checkoutDetails = useSelector((state) => state.user.checkoutDetails);
  const shipInfo = getCartShipDetails(userDetails, checkoutDetails);
  const isExpress = useSelector(
    (state) => state.expressCart.expressToggleEnabled
  );
  const shippingInformation = useSelector(
    (state) => state.expressCart.shippingInfo
  );
  useEffect(() => {
    let clonespecificsForParts = _cloneDeep(specificsForParts);
    if (clonespecificsForParts && clonespecificsForParts.length > 0) {
      let uniqSpecificConditions = getUniq(clonespecificsForParts);

      // build request items payload
      const __items = clonespecificsForParts.map((sp) => {
        const specificCondition = sp.partSpecificConditions.map((psc) => {
          return {
            code: psc.code,
            answer: null,
            partPossibleAnswers: psc.partPossibleAnswers,
            text: psc.text,
          };
        });

        return { groupCode: sp.groupCode, specificCondition };
      });
      setRequestItems(__items);
      // END payload

      // hide already answered specific conditions
      const __specificConditions = _.cloneDeep(specificConditions);
      clonespecificsForParts.forEach((e) => {
        e.partSpecificConditions.forEach((psc) => {
          __specificConditions.forEach((scs) => {
            scs.specificCondition.forEach((sc) => {
              if (psc.code === sc.code) {
                psc.answer = sc.answer;
              }
            });
          });
        });
      });
      // If catalog request group doesn't matches with API response specific conditions, send the request payload with subsequent conditions
      if (clonespecificsForParts.length !== catalogRequestGroups.requestItems) {
        const clonedItems = _.cloneDeep(catalogRequestGroups.requestItems);
        const __items__ = clonedItems.map((r) => {
          __items.map((s) => {
            if (+r.groupCode === +s.groupCode) {
              r.specificCondition = s.specificCondition;
            }

            return s;
          });
          return r;
        });
        setRequestItems(__items__);
      }

      if (specificConditions.length > 0) {
        let showConditions = false;
        const payload = clonespecificsForParts.map((group) => {
          const specificCondition = group.partSpecificConditions.map((psc) => {
            if (psc.answer) {
              return { code: psc.code, answer: psc.answer };
            } else {
              showConditions = true;
              return [];
            }
          });

          return { groupCode: group.groupCode, specificCondition };
        });

        if (showConditions) {
          setConditions(uniqSpecificConditions);
          setShow(true);
        } else {
          setRequestItems(payload);
          uniqSpecificConditions = [];

          handleSubmit(payload);

          setConditions(uniqSpecificConditions);
          setShow(false);
        }
      } else {
        setConditions(uniqSpecificConditions);
        setShow(true);
      }
    }
  }, [specificsForParts]);

  const handleSpecifics = (itemCode, answer) => {
    const payload = requestItems.map((r) => {
      r.specificCondition.map((sc) => {
        if (sc.code === itemCode && !sc.answer) {
          sc.answer = answer;
        }

        return sc;
      });

      return r;
    });

    setRequestItems(payload);
  };

  const handleSubmit = (payload) => {
    const { year, makeId, modelId, engineId } = selectedVehicle;
    let sellerPartnerID = shipInfo.sellerPartnerId
      ? shipInfo.sellerPartnerId
      : "";
    let shipMethod = shipInfo.shipMethod ? shipInfo.shipMethod : "";
    let store = shipInfo.storeNumber ? shipInfo.storeNumber : "";
    if (isExpress) {
      sellerPartnerID = shippingInformation.sellerPartnerId;
      store = shippingInformation.storeNumber;
      shipMethod = shippingInformation.shipMethod;
    }
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
    let __payload = payload ? { requestItems: payload } : { requestItems };

    let catalogRequestGroup = _.cloneDeep(catalogRequestGroups);
    let groupAll = catalogRequestGroup && catalogRequestGroup.requestItems;
    if (payload && catalogRequestGroups && catalogRequestGroups.requestItems) {
      catalogRequestGroups.requestItems.map((item, index) => {
        payload.forEach(function (value) {
          if (Number(value.groupCode) === item.groupCode) {
            groupAll[index].specificCondition = value.specificCondition;
          }
        });
      });
      __payload.requestItems = groupAll;
    }

    // check if any unanswered specific conditions and assign them ? (don't know) option default
    __payload = __payload.requestItems.map((r) => {
      r.specificCondition.map((s) => {
        if (!s.answer) {
          s.answer = "?";
        }

        return s;
      });

      return r;
    });

    // TO Store the extras for the group
    // set timeout is to supress mutation error between action dispatches;
    setTimeout(() => {
      dispatch({
        payload: {
          specificConditions: __payload,
          concat: true,
        },
        type: "SPECIFIC_CONDITIONS",
      });
    }, 1000);

    dispatch(
      getPartsByMultiGroupOrchestrator(
        year,
        makeId,
        modelId,
        engineId,
        {
          requestItems: __payload,
          sellPartnerId: sellerPartnerID,
          store: store,
          shipMethod: shipMethod,
        },
        userPrefDetail.pageNumber,
        userPrefDetail.pageSize,
        userPrefDetail.catalogSortBy,
        userPrefDetail.catalogOrder,
        catalogRequestGroups.filter ? catalogRequestGroups.filter : {},
        userDetails.billTo.mclSupplier,
        parts.selectedGroupDetails?parts.selectedGroupDetails: parts.selectedPartType,
        true,
        selectedVehicle


      )
    );
    dispatch({
      payload: {
        specificConditions: [],
      },
      type: "EXTRA_SPECIFICS_FOR_PARTS",
    });

    // Clear the conditions which are set before
    setConditions([]);
    setRequestItems([]);

    // close the modal
    setShow(false);
  };

  const isChecked = (pscCode, itemCode) => {
    let bool = false;
    if (pscCode === "?") {
      if (itemCode === "N") {
        bool = true;
      }
    } else {
      if (itemCode === "?") {
        bool = true;
      }
    }
    return bool;
  };

  return (
    <React.Fragment>
      <XModal
        show={show}
        className="specific__condition_modal"
        customHeader={
          <Modal.Title id="warning-modal-title">
            Please select an Item specific to continue
          </Modal.Title>
        }
        submitType
        favoriteTextValidity={true}
        handleAction={() => handleSubmit()}
      >
        <React.Fragment>
          <div className="vehicle-specific-wrapper">
            {conditions &&
              conditions.map((condition, cIndex) => {
                return (
                  <div className="dynamic-col" key={`group__${cIndex}`}>
                    <Fragment>
                      <Fragment>
                        {!condition.answer && (
                          <div
                            className="specific__condition"
                            key={`${condition.text}`}
                          >
                            <div className="bold">{condition.text}</div>
                            {condition.partPossibleAnswers.map(
                              (item, index) => {
                                return (
                                  <Form.Check
                                    type="radio"
                                    name={`${cIndex}_option_${condition.text.replace(
                                      / /g,
                                      "_"
                                    )}`}
                                    label={`${item.text}`}
                                    id={`${item.code}_${cIndex}`}
                                    defaultChecked={isChecked(
                                      condition.code,
                                      item.code
                                    )}
                                    onClick={(e) => {
                                      handleSpecifics(
                                        condition.code,
                                        item.code
                                      );
                                    }}
                                  />
                                );
                              }
                            )}
                          </div>
                        )}
                      </Fragment>
                    </Fragment>
                  </div>
                );
              })}
          </div>
        </React.Fragment>
      </XModal>
    </React.Fragment>
  );
}

function areEqual(prevProps, nextProps) {
  return true;
}

SpecificConditionsModal.propTypes = {};

export default React.memo(SpecificConditionsModal, areEqual);
