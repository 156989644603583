import React, { useEffect, useState } from "react";
import Interchange from "../shared/Interchange";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.scss";
import { Link } from "react-router-dom";

import PromotionsTab from "../shared/Promotions";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { getCookie, setCookie } from "helpers/utils";

const InterchangeWrapper = () => {
  const dispatch = useDispatch();

  const userPromotionDetails = useSelector(
    (state) => state.user.userPromotionDetails
  );
  const viewPromotions = useSelector((state) => state.app.viewPromotions);

  useEffect(() => {
    const viewPromotions = getCookie('viewPromotions') || "true";
    if(viewPromotions === "false") {
      dispatch({
        payload: {},
        type: "COLLAPSE_PROMOTIONS_TAB",
      });
    }      
  }, [])

  return (
    <div
      className={clsx("interchange-wrapper", {
        "promotions-active": viewPromotions,
      })}
    >
      <Container fluid className="pl-5 interchange-container">
        <Row>
          <Col md="12">
            <div className="bread_crum_section">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>Interchange</span>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={12}>
            {" "}
            <div className="inerchange-box">
              <div className="interchange-heading">
                <h3>Interchange</h3>
              </div>
              <Interchange catalog={false} 
               dualBoxView={true}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <PromotionsTab
        showSidebarPopUp={viewPromotions}
        promotions={userPromotionDetails}
        handlePromotions={() => {
          setCookie('viewPromotions', !viewPromotions, 365)
          dispatch({
            payload: {},
            type: "VIEWPROMOTIONS_TOGGLE",
          });

          if (!viewPromotions) {
            if (document.querySelector(".section-wrapper")) {
              document.querySelector(".section-wrapper").style.marginLeft =
                "250px";
            }

            if (document.querySelector(".section__banner")) {
              document.querySelector(".section__banner").style.marginLeft =
                "250px";
            }
          } else {
            if (document.querySelector(".section-wrapper")) {
              document.querySelector(".section-wrapper").style.marginLeft = "0";
            }

            if (document.querySelector(".section__banner")) {
              document.querySelector(".section__banner").style.marginLeft = "0";
            }
          }
        }}
      />
    </div>
  );
};
export default InterchangeWrapper;
