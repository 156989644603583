import React, { Fragment } from "react";
import {
    Alert,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    Button,
    InputGroup,
    Row,
    Spinner,
    Modal,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import {
    validateOnlineIdForm,
    numbersOnlyRegExp,
    alphaCharRegExp,
    noSpecialCharsRegExp,
    decimalNumbersOnlyRegExp,
} from "../../../helpers/utils";
import {
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
//import { registerUser } from "../../../actions/user";
import { registerMitchellUser } from "../../../actions/testsms";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import _every from "lodash/every";
import { Redirect } from "react-router-dom";
import storage from "../../../helpers/storage";
import { getShipToIds } from "../../../actions/testsms";
import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import Select from "react-select";
import { Visibility, VisibilityOff, Login } from "@mui/icons-material";
class OnlineIdRequestMitchell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formObject: this.initializeForm(),
            redirectToLogin: false,
            validity: this.intializeValidations(),
            errorPopUp: false,
            showPassword: false,
            showConfirmPassword: false,
            options: [],
            shipTo: [],
        };
        this.handleShowOrHideConfirmPassword =
            this.handleShowOrHideConfirmPassword.bind(this);
        this.handleShowOrHide = this.handleShowOrHide.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        storage.set("authFailed", false, "local");
    }

    initializeForm() {
        this.formObject = {
            xlpAccNo: "",
            xlpLastInvoiceNo: "",
            xlpLastInvoiceAmount: "",
            email: "",
            confirmEmail: "",
            password: "",
        };
        return this.formObject;
    }

    intializeValidations() {
        this.validity = {
            isFirstNameNotNull: true,
            isFirstnameLengthBelowLimit: true,
            isFirstnameMinLength: true,
            isFirstNameAlphabetic: true,
            isFirstNameValid: true,
            isLastNameNotNull: true,
            isLastnameLengthBelowLimit: true,
            isLastnameMinLength: true,
            isLastNameAlphabetic: true,
            isLastNameValid: true,
            isXlpAccNoNotNull: true,
            isXlpAccNoAlphanumeric: true,
            isXlpAccNoLengthBelowLimit: true,
            isXlpAccNoMinLength: true,
            isValidXlpAccNo: true,
            isXlpLastInvoiceNoAlphanumeric: true,
            isInvoiceAmountNotNull: true,
            isInvoiceNoNotNull: true,
            isXlpLastInvoiceNoLengthBelowLimit: true,
            isXlpLastInvoiceNoMinLength: true,
            isInvoiceAmtLengthBelowLimit: true,
            isInvoiceAmountNumeric: true,
            isValidInvoiceAmount: true,
            isEmailIdNotNull: true,
            isEmailIdLengthBelowLimit: true,
            isEmailValidFormat: true,
            isEmailIdMinLength: true,
            isValidEmail: true,
            isValidConfirmEmail: true,
            isConfirmEmailIdNotNull: true,
            isMobileNoNotNull: true,
            isMobileNoLengthBelowLimit: true,
            isMobileNoMinLength: true,
            isMobileNoNumeric: true,
            isValidMobileNo: true,
            isPasswordNotNull: true,
            isValidConfirmPassword: true,
            isShipToNotNull: true,
        };
        return this.validity;
    }

    componentDidUpdate(prevProps) {
        let { formObject, redirectToLogin, formErrorMessage, errorPopUp } =
            this.state;
        let { dispatch } = this.props;
        let stateChanged = false;
        if (!_isEqual(this.props, prevProps)) {
            if (
                !_isEqual(this.props.userCreationApiCall, prevProps.userCreationApiCall)
            ) {
                stateChanged = true;
            }
            if (!_isEqual(this.props.userCreation, prevProps.userCreation)) {
                if (this.props.userCreation === "success") {
                    redirectToLogin = true;
                    stateChanged = true;
                }
            }
            if (!_isEqual(this.props.messageFromApi, prevProps.messageFromApi)) {
                formErrorMessage = this.props.messageFromApi.replace(
                    /The Parts House/g,
                    '<span style="color:#0F5283;"><b>The Parts House</b></span>'
                );
                errorPopUp = true;
                dispatch({
                    payload: {
                        userCreationSuccess: this.props.userCreation,
                        message: "",
                        userCreationApiCall: this.props.userCreationApiCall,
                    },
                    type: "USER_CREATION_COMPLETE",
                });
            }
            if (stateChanged) {
                this.setState({
                    ...this.state,
                    formObject: {
                        ...this.state.formObject,
                        ...formObject,
                    },
                    redirectToLogin,
                    formErrorMessage,
                    errorPopUp,
                });
            }
        }
    }

    validateForm() {
        let {
            formObject: {
                xlpAccNo,
                xlpLastInvoiceNo,
                xlpLastInvoiceAmount,
                email,
                confirmEmail,
                password,
                confirmPassword
            },
            shipTo,
            validity,
        } = this.state;
        validity = validateOnlineIdForm("xlpAccNo", xlpAccNo, validity);
        validity = validateOnlineIdForm(
            "xlpLastInvoiceNo",
            xlpLastInvoiceNo,
            validity
        );
        validity = validateOnlineIdForm(
            "xlpLastInvoiceAmount",
            xlpLastInvoiceAmount,
            validity
        );
        validity.isShipToNotNull = !_isEmpty(shipTo);
        validity = validateOnlineIdForm("email", email, validity);
        validity = validateOnlineIdForm("confirmEmail", confirmEmail, validity);
        validity = validateOnlineIdForm("password", password, validity);
        validity.isValidConfirmEmail = _isEqual(email, confirmEmail);
        validity.isValidConfirmPassword = _isEqual(password, confirmPassword);
        this.setState({ ...this.state, validity });
        return _every(validity);
    }

    handleSubmit(e) {
        e.preventDefault();
        let {
            formObject: {
                xlpAccNo,
                xlpLastInvoiceNo,
                xlpLastInvoiceAmount,
                email,
                password,
            },
            shipTo,
        } = this.state;
        let { dispatch } = this.props;
        if (this.validateForm()) {
            let userObj = {
                user_email: email,
                password: password,
                invoice_amount: xlpLastInvoiceAmount,
                invoice_number: xlpLastInvoiceNo,
                userType: "CUSTOMER_ADMIN",
                sms_source: "SMS-MITCHELL1",
                customer_id: xlpAccNo,
                shipTo_id: shipTo.value,
            };
            //this.props.dispatch(registerMitchellUser(userObj));
            registerMitchellUser(userObj, dispatch);
        }
    }

    handleInputChange(e) {
        const { name, value } = e.target;
        let { formObject, validity } = this.state;
        const formValue = value.charAt(0);
        let str;
        if (formValue === " ") {
            str = value.trim();
        } else {
            str = value;
            if (name === "confirmEmail") {
                validity.isValidConfirmEmail = _isEqual(formObject.email, str);
                validity = validateOnlineIdForm(name, str, validity);
            } else if (name === "confirmPassword") {
                validity.isValidConfirmPassword = _isEqual(formObject.password, str);
            } else {
                validity = validateOnlineIdForm(name, str, validity);
            }
        }
        formObject[name] =
            name === "xlpAccNo" || name === "xlpLastInvoiceNo"
                ? str.toUpperCase()
                : str;

        let { dispatch } = this.props;

        if (name === "xlpAccNo") {
            this.changeShipTo(e);
        }

        this.setState({
            formObject: {
                ...this.state.formObject,
                ...formObject,
            },
            validity: {
                ...this.state.validity,
                ...validity,
            },
            ...this.state,
        });
    }

    handleInputBlur() {
        let { validity } = this.state;
        validity = this.intializeValidations();
        this.setState({
            validity: {
                ...validity,
            },
        });
    }
    changeShipTo = (e) => {
        const { name, value } = e.target;
        const formValue = value.charAt(0);
        let str;
        if (formValue === " ") {
            str = value.trim();
        } else {
            str = value;
        }
        if (str.length >= 2 && name === "xlpAccNo") {
            let payload = {
                billtoid: str,
            };
            this.props.dispatch(getShipToIds(payload)).then((action) => {
                let shipToData = action;
                this.setState({ options: [] });
                this.setState({ shipTo: [] });
                shipToData.map((data) => {
                    this.state.options.push({
                        value: data.shipToId,
                        label: data.address,
                    });
                });
            });
            this.setState(this.state.options);
        }
    };

    handleOtherKeys = (code) => {
        // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
        return (
            code !== 8 &&
            code !== 9 &&
            code !== 37 &&
            code !== 38 &&
            code !== 39 &&
            code !== 40 &&
            code !== 46
        );
    };

    handleKeyDown = (event, name) => {
        const {
            formObject: {
                xlpAccNo,
                xlpLastInvoiceNo,
                xlpLastInvoiceAmount,
                email,
                password,
            },
        } = this.state;
        switch (name) {
            case "xlpAccNo":
                if (
                    (noSpecialCharsRegExp(event.key) ||
                        event.keyCode === 32 ||
                        (xlpAccNo && xlpAccNo.length >= 10)) &&
                    this.handleOtherKeys(event.keyCode)
                ) {
                    event.preventDefault();
                }
                break;
            case "xlpLastInvoiceNo":
                if (
                    (noSpecialCharsRegExp(event.key) ||
                        event.keyCode === 32 ||
                        (xlpLastInvoiceNo && xlpLastInvoiceNo.length >= 10)) &&
                    this.handleOtherKeys(event.keyCode)
                ) {
                    event.preventDefault();
                }
                break;
            case "xlpLastInvoiceAmount":
                if (
                    (decimalNumbersOnlyRegExp(event.key) === false ||
                        event.keyCode === 32 ||
                        (xlpLastInvoiceAmount && xlpLastInvoiceAmount.length >= 9)) &&
                    this.handleOtherKeys(event.keyCode)
                ) {
                    event.preventDefault();
                }
                break;
            case "email":
                if (
                    (event.keyCode === 32 || (email && email.length >= 50)) &&
                    this.handleOtherKeys(event.keyCode)
                ) {
                    event.preventDefault();
                }
                break;
            default:
                break;
        }
    };

    handleShowOrHideConfirmPassword() {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }

    handleShowOrHide() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    onChangeSelectValue = (shipTo) => {
        this.setState({ shipTo });
        console.log(`Option selected:`, shipTo.value);
    };

    render() {
        const {
            formObject: {
                xlpAccNo,
                xlpLastInvoiceNo,
                xlpLastInvoiceAmount,
                email,
                password,
                confirmEmail,
            },
            showPassword,
            showConfirmPassword,
            validity,
            redirectToLogin,
            formErrorMessage,
            errorPopUp,
            options,
            shipTo,
        } = this.state;

        // if (redirectToLogin) {
        //     setTimeout(() => {
        //         let url = `${process.env.REACT_APP_DOMAIN}/configuration.html`
        //         window.location.replace(url);
        //     }, 2000)
        // }
        return (
            <Fragment>
                <div className="onlineReqFormContaierMitchell">
                    <Modal size="lg" show={errorPopUp} className="thank_you_modal">
                        <Modal.Body>
                            {formErrorMessage && (
                                <p className="error-message">
                                    {ReactHtmlParser(formErrorMessage)}
                                </p>
                            )}
                            <Button
                                onClick={() => {
                                    this.setState({ errorPopUp: false });
                                }}
                            >
                                ok
                            </Button>
                        </Modal.Body>
                    </Modal>
                    <div className="onlineReqFormContaierWrapperMitchell">
                        <div className="title">
                            <h2>MITCHELL1 Registration form</h2>
                            <p>Please enter your information in the form below.</p>
                        </div>
                        <Form className="onlineReqForm" onSubmit={this.handleSubmit}>
                            <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="xlpAccNo">
                                    Your XL Parts Customer Number
                                </InputLabel>
                                <OutlinedInput
                                    id="xlpAccNo"
                                    name="xlpAccNo"
                                    label="Your XL Parts Customer Number"
                                    maxLength="16"
                                    onChange={this.handleInputChange}
                                />
                            </FormControl>

                            <FormValidationErrorMessage
                                condition={!validity.isXlpAccNoNotNull}
                                errorMessage="Please enter your Customer number"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isXlpAccNoMinLength && validity.isXlpAccNoNotNull
                                }
                                errorMessage="Customer number should be atleast 1 characters"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isXlpAccNoAlphanumeric && validity.isXlpAccNoNotNull
                                }
                                errorMessage="Customer number must be alphanumeric characters only"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isXlpAccNoLengthBelowLimit &&
                                    validity.isXlpAccNoNotNull
                                }
                                errorMessage="Customer number should not exceed 10 characters"
                            />

                            <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                                {/* <InputLabel htmlFor="xlpLastInvoiceNo">Last XL Parts Invoice Number</InputLabel> */}
                                <TextField
                                    id="xlpLastInvoiceNo"
                                    name="xlpLastInvoiceNo"
                                    label="Last XL Parts Invoice Number"
                                    maxLength="16"
                                    onChange={this.handleInputChange}
                                //helperText="Enter last Sales Invoice Number received from XL Parts. This cannot be a credit invoice."
                                />
                            </FormControl>
                            <FormValidationErrorMessage
                                condition={!validity.isInvoiceNoNotNull}
                                errorMessage="Please enter your Invoice Number"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isXlpLastInvoiceNoMinLength &&
                                    validity.isInvoiceNoNotNull &&
                                    validity.isXlpLastInvoiceNoLengthBelowLimit
                                }
                                errorMessage="Invoice number should be atleast 10 characters"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isXlpLastInvoiceNoLengthBelowLimit &&
                                    validity.isXlpLastInvoiceNoMinLength
                                }
                                errorMessage="Invoice number should not exceed 10 characters"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isXlpLastInvoiceNoAlphanumeric &&
                                    validity.isXlpLastInvoiceNoMinLength
                                }
                                errorMessage="Invoice number must contain alphanumeric characters only"
                            />
                            <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                                {/* <InputLabel htmlFor="xlpLastInvoiceNo">Last XL Parts Invoice Number</InputLabel> */}
                                <TextField
                                    name="xlpLastInvoiceAmount"
                                    id="xlpLastInvoiceAmount"
                                    label="Last XL Parts Invoice Amount"
                                    maxLength="16"
                                    value={xlpLastInvoiceAmount}
                                    onChange={this.handleInputChange}
                                    onKeyDown={(event) => {
                                        this.handleKeyDown(event, "xlpLastInvoiceAmount");
                                    }}
                                />
                            </FormControl>
                            <FormValidationErrorMessage
                                condition={!validity.isInvoiceAmountNotNull}
                                errorMessage="Please enter your Invoice Amount"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isInvoiceAmtLengthBelowLimit &&
                                    validity.isInvoiceAmountNotNull &&
                                    validity.isInvoiceAmountNumeric
                                }
                                errorMessage="Invoice Amount should not exceed 9 numbers"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isInvoiceAmountNumeric &&
                                    validity.isInvoiceAmountNotNull
                                }
                                errorMessage="Invoice Amount must be numeric only"
                            />
                            <label htmlFor="ShipTo">ShipTo</label>
                            <Select
                                options={options}
                                name="ShipTo"
                                value={shipTo}
                                //isDisabled={options.length == 0}
                                className="custom-dropdown1"
                                placeholder="Select a ShipTo address"
                                onChange={this.onChangeSelectValue}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#84bae2",
                                        primary50: "#0F5283",
                                        primary: "#0F5283",
                                    },
                                })}
                            />
                            <FormValidationErrorMessage
                                condition={!validity.isShipToNotNull}
                                errorMessage={"Please select a shipto address"}
                            />
                            <br></br>
                            <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="email">Email</InputLabel>
                                <OutlinedInput
                                    id="email"
                                    name="email"
                                    label="Email"
                                    maxLength="16"
                                    onChange={this.handleInputChange}
                                />
                            </FormControl>
                            <FormValidationErrorMessage
                                condition={!validity.isEmailIdNotNull}
                                errorMessage="Please enter your Email"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isEmailIdLengthBelowLimit &&
                                    validity.isEmailIdNotNull &&
                                    validity.isEmailValidFormat
                                }
                                errorMessage="Email should not exceed 50 characters"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isEmailIdMinLength &&
                                    validity.isEmailIdNotNull &&
                                    validity.isEmailValidFormat
                                }
                                errorMessage="Email should be atleast 7 characters"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isEmailValidFormat && validity.isEmailIdNotNull
                                }
                                errorMessage="Email must be in username@domainname.com format"
                            />
                            <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="confirmEmail">Confirm Email</InputLabel>
                                <OutlinedInput
                                    onChange={this.handleInputChange}
                                    onKeyDown={(event) => {
                                        this.handleKeyDown(event, "email");
                                    }}
                                    name="confirmEmail"
                                    id="confirmEmail"
                                    label="Confirm Email"
                                    value={confirmEmail}
                                    maxLength="50"
                                    //autocomplete="off"
                                    onPaste={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </FormControl>
                            <FormValidationErrorMessage
                                condition={!validity.isValidConfirmEmail}
                                errorMessage="Email and confirm email should be same"
                            />
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isConfirmEmailIdNotNull &&
                                    validity.isValidConfirmEmail
                                }
                                errorMessage="Please confirm Email"
                            />
                            <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    error={!validity.isPasswordNotNull}
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleShowOrHide}
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff color="primary" />
                                                ) : (
                                                    <Visibility color="primary" />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    onChange={this.handleInputChange}
                                />
                            </FormControl>
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isPasswordNotNull
                                }
                                errorMessage="Password is required"
                            />
                            <FormControl sx={{ my: 1 }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="password">Confirm Password</InputLabel>
                                <OutlinedInput
                                    error={!validity.isPasswordNotNull}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type={showConfirmPassword ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleShowOrHideConfirmPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? (
                                                    <VisibilityOff color="primary" />
                                                ) : (
                                                    <Visibility color="primary" />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm Password"
                                    onChange={this.handleInputChange}
                                    onBlur={this.handleInputBlur}
                                    autoComplete="off"
                                    onPaste={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </FormControl>
                            <FormValidationErrorMessage
                                condition={
                                    !validity.isValidConfirmPassword
                                }
                                errorMessage="New Password and confirm password should be same"
                            />
                            {!this.props.userCreationApiCall ? (
                                <Button
                                    type="submit"
                                    className="xlp-submit-btn"
                                    disabled={!_every(validity)}
                                >
                                    SUBMIT
                                </Button>
                            ) : (
                                <Button className="xlp-submit-btn" variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </Button>
                            )}
                        </Form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        userCreation: state.user.userCreationSuccess,
        userCreationApiCall: state.user.userCreationApiCall,
        messageFromApi: state.user.messageFromAPi,
    };
}
export default connect(mapStateToProps)(OnlineIdRequestMitchell);
