import React, { Fragment } from "react";
import { Nav, Image, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import hideIcon from "../../../static/images/hideIcon.png";
import showIcon from "../../../static/images/show.png";
import cart from "../../../static/images/icons/red/cart.png";
import pickupIcon from "../../../static/images/pickupIcon.png";
import deliveryIcon from "../../../static/images/cargo_truck.png";
import XModal from "../XModal";
import CartPreview from "../../shared/CartPreview";
import { setExpressCheckoutClicked } from "../../../components/Homepage/SimpleHomepage/simplehomepage.slice";
import { Badge, IconButton } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  getConsolidatedCartData
} from "../../../helpers/utils";

const CartHeader = (props) => {
  let {
    printCartValueInPasswordFormat,
    cartState,
    handleMouseLeave,
    handleMouseEnter,
    handleHideButton,
    hideAllPrice,
    cartAllDetails,
    enableCartRefreshTime,
    timeRun,
    isExpressCheckout,
    setShowOrderPreview,
    showOrderPreview,
    isStockOrder,
    checkoutDetails
  } = props;
  const userDetails = useSelector((state) => state.user.userDetails);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const disableBasedOnUrl = location.pathname.includes('/ck_checkout');


  return (
    <Nav.Item className="my-cart-btn" href="">
      <span>
        <b>
          {props.checkoutDetails &&
          props.checkoutDetails.length > 0 &&
          (props.checkoutDetails[0]?.shipMethod === "0001" ||
            props.checkoutDetails[0]?.shipMethod === "0002"|| props.checkoutDetails[0]?.shipMethod === "FIWS") ? (
            <Fragment>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">
                  {props.checkoutDetails[0]?.shipMethod === "0002"||props.checkoutDetails[0]?.shipMethod === "0001" ? 'PICKUP' : 'DROP SHIP'}
                </Tooltip>}
              >
                <img
                  className="show-dp-icon"
                  src={pickupIcon}
                  alt="Show Icon"
                />
              </OverlayTrigger>
            </Fragment>
          ) : userDetails &&
            userDetails.shipTos &&
            userDetails.shipTos.length > 0 &&
            userDetails.shipTos[0].shipMethod &&
            (userDetails.shipTos[0]?.shipMethod == "0001" ||
              userDetails.shipTos[0]?.shipMethod == "0002"|| userDetails.shipTos[0]?.shipMethod === "FIWS") ? (
            <Fragment>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">
                  {userDetails.shipTos[0]?.shipMethod === "0002" || props.checkoutDetails[0]?.shipMethod === "0001" ? 'PICKUP' : 'DROP SHIP'}
                </Tooltip>}
              >
                <img
                  className="show-dp-icon"
                  src={pickupIcon}
                  alt="Show Icon"
                />
              </OverlayTrigger>
            </Fragment>
          ) : (
            <Fragment>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">DELIVERY</Tooltip>}
              >
                <img
                  className="show-dp-icon"
                  src={deliveryIcon}
                  alt="Show Icon"
                />
              </OverlayTrigger>
            </Fragment>
          )}
        </b>
      </span>
      <Link
        to={disableBasedOnUrl ? null :
          (window.location.href.indexOf("/home") > -1 &&
          cartState &&
          cartState.qtyInCart > 0
            ? "/home/shopping-cart"
            : "/checkout")
        }
      >
        <div className="my-cart-btn-wrapper">
          <div
            className={`my-cart-btn-img-wrapper ${disableBasedOnUrl ? 'isdisabled' : ''}`} 
            onMouseLeave={handleMouseLeave}
            onMouseEnter={disableBasedOnUrl ? null : handleMouseEnter}
          >
            <Badge
              badgeContent={props.qtyInCart ? props.qtyInCart : 0}
              color="primary"
              max={99}
            >
              <ShoppingCartIcon fontSize="large" color="'black'" />
            </Badge>
          </div>
        </div>
      </Link>
      {props.hideCostPrice ? (
        <div>
          {cartState && cartState.qtyInCart > 0 && (
            <Link to={disableBasedOnUrl ? null :"/checkout"} className="buynow-link">
              Buy Now
            </Link>
          )}
          <div className="d-flex">
            <div className="my-cart-btn-text">
              <span>
                {"$" + printCartValueInPasswordFormat(props.cartCost)}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {cartState && cartState.qtyInCart > 0 && (
             <Link to={disableBasedOnUrl ? null :"/checkout"} className="buynow-link">
              Buy Now
            </Link>
          )}
          <div className="my-cart-btn-text">
            <span>
              {!hideAllPrice && props.cartCost && props.cartCost > 0
                ? `$${props.cartCost.toFixed(2)}`
                : "$0.00"}
            </span>
          </div>
        </div>
      )}
      <div className="price-hide-icons">
        {!props.hideCostPrice ? (
          <IconButton onClick={handleHideButton}>
            <VisibilityOff color="'black'" />
          </IconButton>
        ) : (
          <IconButton onClick={handleHideButton}>
            <Visibility color="primary" />
          </IconButton>
        )}
      </div>
      {cartState && cartState?.qtyInCart > 0 && (
        <XModal
          show={showOrderPreview}
          showButtons={false}
          title=""
          className="order-preview-modal dynamic-width"
          customHeader={
            <div className="order-preview-custom-header">
              <span>Cart</span>
              {cartState &&
              cartState.qtyInCart > 0 &&
              enableCartRefreshTime &&
              timeRun > 0 &&
              !isStockOrder ? (
                <span className="cart-refresh-timer">
                  <b>Your Cart will be refreshed in next {timeRun} seconds</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              ) : null}
              <div>
                <div
                  className="close-btn"
                  onClick={() => setShowOrderPreview(false)}
                >
                  <span className="material-icons">close</span>
                </div>
              </div>
            </div>
          }
          handleAction={() => setShowOrderPreview(false)}
        >
          {" "}
          <div></div>
          <CartPreview
            checkoutDetails={getConsolidatedCartData(cartAllDetails)}
            userDetails={userDetails}
            //cartRefreshTime={cartRefreshTime}
          />
          {cartState.qtyInCart > 0 && (
            <Button
              className="add_to_cart_button cart-checkout-button"
              onClick={() => {
                if (isExpressCheckout) {
                  dispatch(
                    setExpressCheckoutClicked({ isExpressCheckout: false })
                  );
                }
                setShowOrderPreview(false);
              }}
            >
              Close
            </Button>
          )}
          {cartState && cartState.qtyInCart > 0 && (
            <Button
              className="add_to_cart_button cart-checkout-button"
              onClick={() => {
                if (!isExpressCheckout) {
                  history.push("/checkout");
                } else {
                  dispatch(
                    setExpressCheckoutClicked({ isExpressCheckout: false })
                  );
                }
                setShowOrderPreview(false);
              }}
            >
              Check Out
            </Button>
          )}
        </XModal>
      )}
    </Nav.Item>
  );
};

export default CartHeader;
