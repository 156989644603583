import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Button, Spinner, Form, Row, Col } from "react-bootstrap";
import AvailabilityStoreView from "../shared/AvailabilityStoreView"
import BootstrapTable from "react-bootstrap-table-next";
import { themeColor } from "../../helpers/utils";
import XModal from "../shared/XModal";
import Select from "react-select";
import _ from "lodash";
import NoDataState from "components/StoreDashboard/popUp/NoDataState";


const MagicWordTable = (props) => {
    const {
        sourceName,
        magicOptions,
        handleMagicWordSelection,
        partNumerEntered,
        showMagicModal,
        magicTitle,
        closeAll,
        magicRef,
        magicWordsColumns,
        partMagicRows,
        rowDataMagic,
        showLoadermagic,
        handleSubmit,
        enableOk,
        setrowData,
        showWithinPopup,
        setShowWithinPopup,
        storeData
    } = props;

    const addItemcartFetching = useSelector((state) => state.cart.addItemcartFetching);


    const selectRow = {
        mode: "checkbox",
        classes: "selection-row",
        headerColumnStyle: {
            backgroundColor: "#0F5283",
            "z-index": "999",
        },

        onSelect: (row, isSelect) => {
            try {
                if (row.availabilityAll.length === 0) {
                    return {
                        disabled: true,
                    };
                } else if (isSelect === true && row.availabilityAll.length > 0) {
                    setrowData((rowDataMagic) => [...rowDataMagic, row]);
                } else if (isSelect === false) {
                    setrowData((rowDataMagic) =>
                        rowDataMagic.filter((item) => item.uniquedata !== row.uniquedata)
                    );
                }
            } catch (error) { }
        },

        onSelectAll: (isSelect, rows) => {
            let rowsarray = [];
            rows.map((item) => {
                if (item.availabilityAll.length == 0) {
                    return false;
                } else if (item.availabilityAll.length > 0) {
                    rowsarray.push(item);
                }
            });
            if (isSelect) {
                setrowData(rowsarray);
                return rows.filter((r) => r.availabilityAll.length >= 1).map((r) => r.uniquedata);
            } else {
                setrowData([]);
            }
        },

        selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
            <Form.Check
                custom
                type="checkbox"
                label={<></>}
                ref={(input) => {
                    if (input)
                        input.indeterminate = indeterminate;
                }}
                {...rest}
            />
        ),
    };

    return (
        <Fragment>
            <div className="label-dropdown-container-stock" style={{ "column-gap": "10px" }}>
                <Form.Label className={sourceName == "chemical" ? "magicword_label" : ""}>Magic Word </Form.Label>
                <Select
                    autoFocus={true}
                    options={!_.isEmpty(magicOptions) && magicOptions}
                    isDisabled={_.isEmpty(magicOptions)}
                    name="Magicword"
                    placeholder="Select a Magic Word"
                    className="custom-dropdown_stock"
                    onChange={handleMagicWordSelection}
                    value={partNumerEntered}
                    theme={(theme) => themeColor(theme)}
                    ref={magicRef}
                />
            </div>
            {showMagicModal && (
                <XModal
                    show={showMagicModal}
                    isValidValidity={true}
                    showButtons={false}
                    title={!_.isEmpty(magicTitle) ? `List of parts for ${magicTitle}` : ""}
                    handleClose={() => { closeAll() }}
                    className="vehicle-maintance-stockcheck-modal" >
                    <Row className="table-row popup-vehicle-maintance-addtocart">
                       {!_.isEmpty(partMagicRows)  ? (<Col>
                            <div className="">
                                <BootstrapTable
                                    bootstrap4
                                    keyField="uniquedata"
                                    data={partMagicRows}
                                    columns={magicWordsColumns}
                                    bordered={true}
                                    selectRow={selectRow}
                                    wrapperClasses="table-responsive-stock"
                                />
                            </div>
                        </Col>) :
                        (!showLoadermagic && _.isEmpty(partMagicRows))  ? 
                        <div className="magictable-noData">
                               <NoDataState />
                        </div>:""
                        }
                    </Row>
                    <div className="magic-word-submitbutton">
                        
                            <Button
                                className={"add_to_cart_button"}
                                onClick={() => handleSubmit()}
                                disabled={_.isEmpty(rowDataMagic) || enableOk}
                            >
                                {sourceName == "chemical" ? "ADD TO CART" : "OK"}
                            </Button>
                            <Button
                                className="add_to_cart_button"
                                onClick={() => { closeAll() }}
                            >
                                Cancel
                            </Button>
                    </div>
                    {showWithinPopup ?
                        (<AvailabilityStoreView
                            openPopup={showWithinPopup}
                            closePopup={() => setShowWithinPopup(false)}
                            storeData={storeData}
                        />
                        )
                        : null}
                    {(showLoadermagic || addItemcartFetching) && (<div className="custom_spinner_container">
                        <Spinner className="custom_spinner" animation="border" />
                    </div>
                    )}
                </XModal>
            )}
        </Fragment>
    );
}
export default MagicWordTable;
