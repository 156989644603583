import React, { useRef, useEffect, useState } from 'react';
import { Form, Accordion } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

function ClassicChemicalCategoryWrapper(props) {
  let {
		handleClick,
		selectedGroupDetails,
    brandsView
	} = props;
  const parts = useSelector((state) => state.parts);
  const [categoryGroupMap, setCategoryGroupMap]  = useState([]);
  const [brandsCategoryMap, setBrandsCategoryMap]  = useState([]);
  const [groupsHeading, setGroupsHeading] = useState('Groups');
  const [categoriesHeading, setCategoriesHeading] = useState('Categories');
  const chemicalCategoriesRef = useRef([]);
  const brandsCategoriesRef = useRef([]);

  useEffect(() => {
    let categoryGroups = parts.categorySuppliesMap.map(category => ({...category, selected: false, active: false, enableSelectAll:false}));
    setCategoryGroupMap(_cloneDeep(categoryGroups));
  }, [parts.categorySuppliesMap]);

  useEffect(() => {
    if(parts.brandsCategoryMap) {
      let brandCategories = parts.brandsCategoryMap.map(category => ({...category, selected: false, active: false, enableSelectAll:false}));
      setBrandsCategoryMap(_cloneDeep(brandCategories));
    }
  }, [parts.brandsCategoryMap]);

  const handleCategorySelect = (event, category) => {
    const isChecked = event.target.checked;
    let matchingCategory = categoryGroupMap.find((data) => {
			return data.code === category.code;
		});
		matchingCategory.selected = isChecked;
    matchingCategory.active = isChecked;
		setCategoryGroupMap([...categoryGroupMap]);
    if(!isChecked) {
      const selectedGroups = [...selectedGroupDetails];
      selectedGroups.map((group, index) => {
        if(group.categoryId === matchingCategory.code) {
          handleClick(null, group, matchingCategory);
        }
      });
    }
    _findIndex([...categoryGroupMap],{selected: true}) >= 0 ? setGroupsHeading('Groups - Select one or more') : setGroupsHeading('Groups') ;
  }

  useEffect(() => {
    if(brandsCategoryMap.length > 0) {
      brandsCategoryMap.map((b, index) => {
        if(b.selected) {
          b.selected = false;
        }
        if(b.active) {
          b.active = false;
        }
      })
      setBrandsCategoryMap([...brandsCategoryMap]);
      brandsCategoriesRef.current.map((brandRef) => {
        if(brandRef !== null && brandRef.checked) {
          brandRef.checked = false;
        }
      });
    }

    if(categoryGroupMap.length > 0) {
      categoryGroupMap.map((c, index) => {
        if(c.selected) {
          c.selected = false;
        }
        if(c.active) {
          c.active = false;
        }
        chemicalCategoriesRef.current.map((chemicalRef) => {
          if(chemicalRef !== null && chemicalRef.checked) {
            chemicalRef.checked = false;
          }
        });
      })
      setCategoryGroupMap([...categoryGroupMap]);
    }

	}, [brandsView])

  const handleBrandSelect = (event, brand) => {
    const isChecked = event.target.checked;
    let matchingCategory = brandsCategoryMap.find((data) => {
			return data.supplier.code === brand.supplier.code;
		});
		matchingCategory.selected = isChecked;
    matchingCategory.active = isChecked;
		setBrandsCategoryMap([...brandsCategoryMap]);
    if(!isChecked) {
      const selectedGroups = [...selectedGroupDetails];
      selectedGroups.map((group, index) => {
        if(group.brandCode === matchingCategory.supplier.code) {
          handleClick(null, group, matchingCategory);
        }
      });
    }
    _findIndex([...brandsCategoryMap],{selected: true}) >= 0 ? setCategoriesHeading('Categories - Select one or more') : setCategoriesHeading('Categories') ;
  }

  const toggleCategoryGroup = (event, category) => {
    let matchingCategory = categoryGroupMap.find((data) => {
			return data.code === category.code;
		});
    matchingCategory.active = !matchingCategory.active;
		setCategoryGroupMap([...categoryGroupMap]);
  }

  const toggleBrandCategoryGroup = (event, category) => {
    let matchingCategory = brandsCategoryMap.find((data) => {
			return data.supplier.code === category.supplier.code;
		});
    matchingCategory.active = !matchingCategory.active;
		setBrandsCategoryMap([...brandsCategoryMap]);
  }

  const handleCustomClick = (e, data, category ,brandOrCategory) => {
    let matchingCategory = [];
    let singleCategoryGroups;
    matchingCategory = selectedGroupDetails.filter((item) => {
      return item.categoryId == category.code;
    })
    if(data.groupSelect){
      matchingCategory = selectedGroupDetails.filter((item) => {
        return item.brandCode == category.supplier.code;
      })
    }
    singleCategoryGroups = categoryGroupMap;
    let categoryLength = data.groupSelect ? category.chemicalCategories.length : category.groups.length;
    if ((matchingCategory.length == categoryLength) || (matchingCategory.length >= (categoryLength - 1))) {
      let selectedIndex;
      if (category.canSelectChemical) {
        selectedIndex = _findIndex(matchingCategory, {
          id: data.id,
        });
      }
      if(data.groupSelect){
        selectedIndex = _findIndex(matchingCategory, {
          pimId: data.pimID,
        });
      }
      if(selectedIndex === -1){
        category.enableSelectAll = true;
        data.selected = true;
      }
      else {
        category.enableSelectAll = false;
        data.selected = false;
      }
    }
    else if(_isEmpty(data.selected)) {
           data.selected = data.selected ? false : true;
    }

    brandOrCategory == "brand" ? setBrandsCategoryMap([...brandsCategoryMap]) : setCategoryGroupMap(singleCategoryGroups);
    handleClick(e, data, category);
  }


  const selectAllGroups = (category, e ,brandOrCategory) => {
    let categoryGroups = [...categoryGroupMap];
    const isChecked = e.target.checked;
    category.enableSelectAll = isChecked;
    let specificGroup = brandOrCategory == "brand"? "chemicalCategories":"groups"
    category[specificGroup].map((group, index) => {
      let groupIndex;
      if (category.canSelectChemical) {
				groupIndex = _findIndex(selectedGroupDetails, {
					id: Number(group.id),
				});
			}
			if (group && group.groupSelect) {
				groupIndex = _findIndex(selectedGroupDetails, {
					id: Number(group.id),
				});
			}
      if (isChecked) {
        group.selected= true;
				if (groupIndex === -1) {
					handleClick(e, group, category);
				}
			} else {
        group.selected= false;
				if (groupIndex !== -1) {
					handleClick(e, group, category);
				}
			}
  });

  if(brandOrCategory == "brand"){
    setBrandsCategoryMap([...brandsCategoryMap]);
  }else{
    setCategoryGroupMap([...categoryGroups]);
  }

};

  const renderCategories = () => {
  	return categoryGroupMap.map((category, index) => {
      return (
        category.groups && category.groups.length > 0 && (
          <Form.Group controlId={`CategoriesCheckbox${index+1}`}>
            <Form.Check
              type="checkbox"
              label={category.name}
              ref={el => chemicalCategoriesRef.current[index] = el}
              onChange={(e) => handleCategorySelect(e, category)}
            />
          </Form.Group>
	      )
      );
		});
	};

  const renderBrandCategories = () => {
  	return brandsCategoryMap.map((category, index) => {
      return (
        category.chemicalCategories && category.chemicalCategories.length > 0 && (
          <Form.Group controlId={`CategoriesCheckbox${index+1}`}>
            <Form.Check
              type="checkbox"
              label={category.supplier.name}
              ref={el => brandsCategoriesRef.current[index] = el}
              onChange={(e) => handleBrandSelect(e, category)}
            />
          </Form.Group>
	      )
      );
		});
	};

  const renderCategoryGroups = () => {
  	return categoryGroupMap.map((category, cIndex) => {
      return (
        category.selected && (
          <Accordion defaultActiveKey="0">
            <div className="classicListWrapper">
              <Accordion.Toggle
                as="div"
                className="accordionHead"
                variant="link"
                eventKey="0"
                onClick={(e) => toggleCategoryGroup(e, category)}
              >
                <div class={`classicListWrapperHead ${category.active ? 'expanded' : 'collapsed'}`}>
                  {category.name}
                </div>
              </Accordion.Toggle>
    					<Accordion.Collapse eventKey="0">
    						<div className="classicListWrapperBody">
                  <Form.Group >
                  <strong>
                    <Form.Check type="checkbox"
                    checked= {
                        category && category.enableSelectAll
                    }
                    onChange={(e) =>{
                        selectAllGroups(category, e ,"category");
                    }}
                     label="Select All Groups" />
                   </strong>
                  </Form.Group >
                  {category.groups.map((group, gIndex) => {
                    return (
                      <Form.Group controlId={`GroupsCheckbox${cIndex+1}${gIndex+1}`}>
                        <Form.Check
                          type="checkbox"
                          label={group.name}
                          onClick={(e) => handleCustomClick(e, group, category, "category")}
                          checked= {
                            group && group.selected
                          }
                       />
                      </Form.Group>
                    );
                  })}
    						</div>
    					</Accordion.Collapse>
            </div>
  				</Accordion>
	      )
      );
		});
	};

  const renderBrandCategoryGroups = () => {
    return brandsCategoryMap.map((category, cIndex) => {
      return (
        category.selected && (
          <Accordion defaultActiveKey="0">
            <div className="classicListWrapper">
              <Accordion.Toggle
                as="div"
                className="accordionHead"
                variant="link"
                eventKey="0"
                onClick={(e) => toggleBrandCategoryGroup(e, category)}
              >
                <div class={`classicListWrapperHead ${category.active ? 'expanded' : 'collapsed'}`}>
                  {category.supplier.name}
                </div>
              </Accordion.Toggle>
    					<Accordion.Collapse eventKey="0">
    						<div className="classicListWrapperBody">
                  <Form.Group >
                    <strong>
                      <Form.Check type="checkbox"
                        checked={
                          category && category.enableSelectAll
                        }
                        onChange={(e) => {
                          selectAllGroups(category, e, "brand");
                        }}
                        label="Select All Groups" />
                    </strong>
                  </Form.Group >
                  {category.chemicalCategories.map((group, gIndex) => {
                    return (
                      <Form.Group controlId={`GroupsCheckbox${cIndex+1}${gIndex+1}`}>
                        <Form.Check
                          type="checkbox"
                          label={group.name}
                          onClick={(e) => handleCustomClick(e, group, category, "brand")}
                          checked= {
                            group && group.selected
                          }
                       />
                      </Form.Group>
                    );
                  })}
    						</div>
    					</Accordion.Collapse>
            </div>
  				</Accordion>
	      )
      );
		});
	};

  return (
    <section className="classicSection">
      <Container>
       <div class="containerHead">All Chemicals Catalog</div>
        <div className="classicWrapper">
          <div className="classicCategories">
            <div className="classicMainHead">{brandsView ? 'Brands - Select one or more' : 'Categories - Select one or more' }</div>
            <div className="classicListWrapper">
              <div className="classicListWrapperBody">
                {brandsView ? renderBrandCategories() : renderCategories() }
              </div>
            </div>
          </div>
          <div className="classicGroups">
            <div className="classicMainHead">{brandsView ? categoriesHeading : groupsHeading }</div>
            <Accordion defaultActiveKey="0">
              <div className="classicListWrapper">
                {brandsView ? renderBrandCategoryGroups() : renderCategoryGroups()}
              </div>
            </Accordion>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ClassicChemicalCategoryWrapper;
