/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { AsyncTypeahead, MenuItem, Menu, withItem } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import favoriteIcon from "../../../static/images/icons/red/Select_Deselect_Favorite_1.png";
import { ReactComponent as FavoriteIcon } from "../../../static/images/icons/Select_Deselect_Favorite_1.svg";
import { setFavoriteVehicle } from "../MyGarage/mygarage.thunks";
import { getProductListFromSkuNumber, search } from "../../../components/Homepage/Search/globalSearch.thunk";
import { getGroupsForCategory, reset } from "../../../components/Homepage/Search/globalSearch.slice";
import { getPartsByMultiGroupOrchestrator } from "../../../actions/parts";
import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import { isLengthLessthan, timeInUTC } from '../../../helpers/utils';
import { useRef } from 'react';
import Fuse from 'fuse.js';
import SpecificconditionIcon from '../../shared/SpecificconditionIcon';
import _isEmpty from "lodash/isEmpty";
import { logUserStockActivity } from "../../../actions/user"; 

function VechicleBread(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const parts = useSelector(state => state.parts);
    const globalSearch = useSelector((state) => state.globalSearch);
    const searchInput = useRef(null);

    const userDetails = useSelector((state) => state.user.userDetails);
    const [searchFailureValue,setSearchFailure]=useState("");
    const [optionsWithDataGlobalSearch, setOptionsWithDataGlobalSearch] = useState(false);
    const [gloabalSearchStarted,setGlobalSearchStarted]=useState(false);
    const [updatedSearchValue,setUpdatedSearchValue]=useState("");
    const [sourceAlreadyCalled,setSourceAlreadyCalled]=useState({source:"",searchValue:""});
  
    useEffect(() => {
        const handleSearchError = (query) => {        
        const errorData = {
          source: "Part Catalog - Default",
          searchvalue: query,
          resultFound: false,
          selectedVehicle  : sv ? `${sv?.year} ${sv?.make} ${sv?.model} ${sv?.engine}`: null,			
          filters: {
            groupCode: parts?.specificCondition && parts?.specificCondition.length>0 ? (parts?.specificCondition[0]?.groupCode):null,
            brand: parts?.catalogFilter?.brand,
            position: parts?.catalogFilter?.position,
            availablity: parts?.catalogFilter?.availablity,
          },
        };
        logUserSearchFailureActivity(errorData)
    }
    
      // Update the local state whenever globalSearch.options changes
      setTimeout(() => {
        setOptionsWithDataGlobalSearch(hasDataInOptions(globalSearch.options));
        const globalSearchResult=hasDataInOptions(globalSearch.options);
        if(!globalSearchResult  && gloabalSearchStarted && updatedSearchValue ===searchFailureValue ){
          handleSearchError(searchFailureValue)
          setGlobalSearchStarted(false);
          setUpdatedSearchValue("");
        }
      },2000);
    }, [globalSearch.options, gloabalSearchStarted, searchFailureValue, updatedSearchValue]);

    const hasDataInOptions = (options) => {
        if (options.length > 0 && options[0] && typeof options[0] === "object") {
          const objectAtIndex0 = options[0];
          for (const key in objectAtIndex0) {
            if (objectAtIndex0[key] && objectAtIndex0[key].length > 0) {
              return true; // At least one key-value array has data
            }
          }
        }
        return false; // No key-value array has data
      };
    // Custom Menu Item
    const Item = withItem(MenuItem);

    const initializeValidity = () => {
        const validity = {
            globalSearchMaxLimit: true
        };
        return validity;
    }

    const [validity, setValidity] = useState(initializeValidity);

    let {
        clearLists,
    } = props;
    const mygarage = useSelector((state) => state.mygarage);
    const sv = mygarage.selectedVehicle || search.selectedVehicle;

    const handleToggleFavorite = (v, flag) => {
        const updated = {
            ...v,
            favoriteText: "",
            favoriteFlag: flag,
            favoriteUpdatedDate: timeInUTC(),
        };

        dispatch(setFavoriteVehicle(updated));
    };

    const onOptionEmpty = (q) => {
        const stockCheck = {
            requestItems: [{
                mfgCode: "*",
                partNum: q,
                qty: 1
            }]
        };
        dispatch(getProductListFromSkuNumber(stockCheck))
    }

    const handleSearch = (query) => {
        setUpdatedSearchValue(query);

        let multipleQuery = query.trim().split(' ');
        multipleQuery = [].concat(query.trim());

        multipleQuery = _.uniq(multipleQuery);

        let validity = initializeValidity();
        validity.globalSearchMaxLimit = isLengthLessthan(query, 100);
        setValidity(validity);
        multipleQuery.forEach(q => {
            const payload = { searchKey: q };
            dispatch(search(payload)).then(results => {

                const { payload: { data } } = results

                setTimeout(() => {
                    if (results.payload && results.payload.data) {
                        let oValues = Object.values(data);
                        oValues = _.flatten(oValues.filter(v => v));
                        if (_.isEmpty(oValues)) {
                            // handleSearchError(q);
                            onOptionEmpty(q);
                            setTimeout(()=>{
                                // handleSearchError(q);
                                setGlobalSearchStarted(true);

                            },2000)
                            setSearchFailure(query);
                        }
                    }
                }, 1000 * 1);
            });
        });
    };

    const getGroupFromCategory = (categoryName, groupName) => {
        const category = _.find(parts.categoryGroupMap, c => c.name.toLowerCase() === categoryName.toLowerCase());
        const g = _.find(category.groups, g => g.name.toLowerCase() === groupName.toLowerCase());
        return Object.assign({}, g);
    }
    // const handleSearchError = (query) => {
    //     const errorData = {
    //       source: "Chemicals and Parts",
    //       searchvalue: query,
    //       resultFound: false,
    //       selectedVehicle : sv,
    //       filters:{
    //         groudCode:parts?.specificCondition[0].groudCode,
    //         brand:parts?.catalogFilter?.brand,
    //         position:parts?.catalogFilter?.position,
    //         availablity:parts?.catalogFilter?.availablity,
    
    //       }
    //     };
    
    //     const storedErrors = JSON.parse(localStorage.getItem('searchErrors')) || [];
	// 	const existingErrorIndex = storedErrors.findIndex(error => error.source === errorData.source);

	// 	if (existingErrorIndex !== -1) {
	// 		// If an entry with the same source exists, update it
	// 		storedErrors[existingErrorIndex] = errorData;
	// 	} else {
	// 		// If no entry with the same source exists, add a new entry
	// 		storedErrors.push(errorData);
	// 	}
    //     // localStorage.setItem('searchErrors', JSON.stringify(storedErrors));
    //   };
      const logUserSearchFailureActivity = (storedErrors) => {    
        const { x2userName, shipTos, billTo } = userDetails;
        if (!_isEmpty(userDetails) && !_isEmpty(billTo)) {
          let loginId = "";
          let shipToId = shipTos[0]?.shipToId;
          let userData = JSON.stringify(storedErrors);
          let type="Search - No Result"
          const {searchValue}= sourceAlreadyCalled;
          if(searchValue !== storedErrors.searchvalue ){
            setSourceAlreadyCalled({source:storedErrors.source,searchValue:storedErrors.searchvalue})
     
          dispatch(
            logUserStockActivity(
              timeInUTC(),
              billTo.billToId,
              loginId,
              shipToId,
              type,
              userData,
              x2userName
            )
          );
            }
        }
    }
    
    const handleChange = (selected) => {
        const option = _.head(selected);
        if (!option) return;

        switch (option.type) {
            case 'partSkus':
                history.push(`/stock-check?partSku=${option.partSku.partNum}&description=${option.option}&mfgCode=${option.partSku.mfgCode}`)
                break;
            case 'parts':
                history.push("/product-list");
                // Dispatch Selected Group
                let group = getGroupFromCategory(option.groupAndCategories.categoryDesc, option.groupAndCategories.groupDesc);
                group.categoryId = option.groupAndCategories.categoryId;

                dispatch({
                    payload: {
                        selectedGroupDetails: [group],
                    },
                    type: "GROUP_SELECTED",
                });
                const payload = {
                    requestItems: [
                        {
                            groupCode: option.groupAndCategories.groupId,
                            specificCondition: [],
                        },
                    ],
                };

                // store the selected part type for the filter to show in the part type dropdown filter
                dispatch({
                    payload: {
                        selectedPartType: option,
                    },
                    type: 'SELECTED_PART_TYPE'
                });

                dispatch(getPartsByMultiGroupOrchestrator(sv.year, sv.makeId, sv.modelId, sv.engineId, payload, null, null, null, null, { partTypes: [{ id: option.partTypeId }] },null,
                    option ||parts.selectedPartType,
                    false,
                    sv));
                break;
            case 'category':
                const categoryGroups = parts.categoryGroupMap;

                let category = _.filter(categoryGroups, c => c.name === option.categoryDesc);
                category = _.head(category);
                dispatch(getGroupsForCategory(category));

                searchInput.current.toggleMenu();

                break;
            case 'group':
                history.push("/product-list");
                // Dispatch Selected Group
                let _group = getGroupFromCategory(option.categoryDesc, option.groupDesc);
                const __group = Object.assign(_group, { categoryId: +option.categoryId });

                dispatch({
                    payload: {
                        selectedGroupDetails: [__group],
                    },
                    type: "GROUP_SELECTED",
                });
                let reqObj = {
                    requestItems: [
                        {
                            groupCode: option.groupId,
                            specificCondition: [],
                        },
                    ],
                };
                dispatch(getPartsByMultiGroupOrchestrator(sv.year, sv.makeId, sv.modelId, sv.engineId, reqObj));
                break;
            default:
                break;
        }


    }

    const filterBy = (option, { text }) => {
        // if (option.option) {
        //     return option.option.toLowerCase().indexOf(text.toLowerCase()) !== -1
        // } else {
        return ['option']
        // }
    }

    const fuzySearch = (options) => {
        let intermOptions = {};

        if (options.length > 0 && searchInput && searchInput.current) {
            const input = searchInput.current.getInput();

            intermOptions = Object.assign({}, _.head(options));
            const fuzzyParts = new Fuse(options[0].parts, { keys: ['partTypeDesc'], threshold: 0.2, includeMatches: true, includeScore: true, findAllMatches: false });
            let searchedParts = fuzzyParts.search(input.value.trim()).map(i => i.item);
            const _parts = options[0].parts || [];
            searchedParts = _.uniqBy(_parts.concat(searchedParts), p => p.partTypeId);

            intermOptions.parts = searchedParts;
        }

        return [intermOptions];
    }

    const getGroupLabels = (label) => {
        switch (label) {
            case 'partSkus':
                return 'Part SKUs - Go To Stock Check';
            case 'group':
                return 'Groups'
            case 'category':
                return 'Categories';
            case 'parts':
                return 'Part Descriptions';
            default:
                break;
        }
    }

    const getValue = (option, type) => {
        switch (type) {
            case 'partSkus':
                return option.option;
            case 'category':
                return option.categoryDesc;
            case 'group':
                return option.groupDesc;
            case 'parts':
                return `${option.partTypeDesc}`
            default:
                break;
        }
    }

    const getLabel = (option) => {
        return getValue(option, option.type)
    }

    const setOption = (option, type) => {
        let _option = Object.assign({}, option);
        _option.type = type;
        return _option;
    }

    const discard = (obj) => {
        let _obj = Object.assign({}, obj);
        if (obj) {
            delete _obj.states;
            delete _obj.make;
            delete _obj.model;
            delete _obj.vehicles;
        }
        return _obj;
    }

    const onKeyDown = (event) => {
        if (event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.target.value.length >= 100) {
            event.preventDefault();
        }
    }

    const renderMenu = (results, menuProps, state) => {
        if (!results.length) return <Menu {...menuProps}><MenuItem>Searching...</MenuItem></Menu>;

        if (!globalSearch.loading) {
            const top = _.head(results);

            let flag = false;
            const values = Object.values(discard(top));
            for (let i = 0; i < values.length; i++) {
                const t = values[i];
                if (t.length > 0) {
                    flag = true;

                    break;
                }
            }

            if (!flag) {
                return <Menu {...menuProps}><MenuItem>No Matching results</MenuItem></Menu>
            }
        }

        return <Menu {...menuProps}>
            {results.map((result, index) => {
                return Object.keys(discard(result)).sort().map((label, i) => {
                    return result[label].length > 0 && <React.Fragment>
                        {i !== 0 && <Menu.Divider />}
                        <Menu.Header>
                            <h6>{getGroupLabels(label)}</h6>
                        </Menu.Header>
                        {result[label].map(value => {
                            return <MenuItem key={`${index}_${i}`} option={setOption(value, label)}>
                                {getValue(value, label)}
                            </MenuItem>
                        })}
                    </React.Fragment>
                })
            })}
        </Menu>
    }

    return (
        <section className="sticky-breadcrumb">
            <div className="breadcrumb_section">
                <Container>
                    <div className="breadcrumb-wrapper">
                        <div className="custom-breadcrumb" >
                            <div className="b-info">Selected vehicle: </div>
                            <ul>
                                <li className="selected_vehicle_line">
                                    <a href="#" onClick={() => { clearLists([], true) }}>{sv && sv.year} {sv && sv.make} {sv && sv.model} {sv && sv.engine}</a>
                                    {sv && sv.favoriteFlag === "N" && (
                                        <FavoriteIcon
                                            className="non__favorite favoriteIcon"
                                            onClick={() => handleToggleFavorite(sv, "Y")}
                                        />
                                    )}

                                    {sv && sv.favoriteFlag === "Y" && (
                                        <React.Fragment>
                                            <FavoriteIcon
                                                className="favorite favoriteIcon"
                                                onClick={() => handleToggleFavorite(sv, "N")}
                                            />
                                        </React.Fragment>
                                    )}

                                </li>
                            </ul>
                        </div>
                        <div className="propack_icon">
                            <SpecificconditionIcon />
                        </div>
                        <div className="breadcrumb-input">
                            <AsyncTypeahead id="category__search"
                                useCache={false}
                                ref={searchInput}
                                filterBy={filterBy}
                                labelKey={option => getLabel(option)}
                                minLength="2"
                                inputProps={{ className: "input__default input__search" }}
                                onSearch={handleSearch}
                                onChange={handleChange}
                                options={!globalSearch.loading ? fuzySearch(globalSearch.options || []) : []}
                                placeholder="Search by Category, Group, Part Description..."
                                renderMenu={renderMenu}
                                onKeyDown={onKeyDown}
                            />
                            <FormValidationErrorMessage
                                condition={!validity.globalSearchMaxLimit}
                                errorMessage="Global search key should not exceed more than 100 characters"
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}
export default VechicleBread;
