import React, { Fragment } from "react";
import { Container, Navbar, Image, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import logo from "../../../static/images/TPH Rapid.png";

function NonAuthHeader(props) {
  const { pathname } = useLocation();

  const registerLink = "/online-id-req-form";
  const loginLink = "/login";
  const mitchel1Link = "/online-sms-req-form";
  const url = `${process.env.REACT_APP_DOMAIN}/configuration.html`

  if (window.location.pathname.includes("/tracking-order")) {
    return null;
  }
  
  return (
    <Fragment>
      <section className="section__navigation">
        <Container className="p-0">
          <Navbar expand="lg" className="p-0">
            <Navbar.Brand>
              <Link to="/login">
                <Image src={logo} />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="xl-main-navigation">
              <Nav className="mr-auto"></Nav>
              <Nav>
                {pathname !== registerLink && pathname !== mitchel1Link && (
                  <Nav.Link>
                    <Link to="/online-id-req-form">Sign up</Link>
                  </Nav.Link>
                )}
                {pathname !== loginLink && pathname !== mitchel1Link && (
                  <Nav.Link>
                    <Link to="/login">Login</Link>
                  </Nav.Link>
                )}
              </Nav>
              {pathname === mitchel1Link && (
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Login
                </a>
              )}
              {/* <Nav className="ml-auto pseudo-nav">
                                        <NavDropdown.Item href="">
                                        <MyGarage />
                                    </NavDropdown.Item>                                    
                                        <NavDropdown.Item href="">
                                            <Image src={cart}/>
                                    </NavDropdown.Item>        
                                </Nav>         */}
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </section>
    </Fragment>
  );
}
export default NonAuthHeader;
