import axios from 'axios';
import httpClient from "../../../services/http";

const buildQueryUrl = (payload) => {
  const params = [];

  for (const k in payload) {
    if (payload.hasOwnProperty(k)) {
      const element = payload[k];
      params.push(encodeURIComponent(k) + "=" + encodeURIComponent(element));
    }
  }
  return params.join("&");
};

let cancelToken;
export const fetchSearchOptions = (data) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation is canceled due to new search request");
  }

  cancelToken = axios.CancelToken.source();

  return httpClient.get(`/catalog/search/global-text?${buildQueryUrl(data)}`, {
    cancelToken: cancelToken.token,
  });
};

export const fetchVehiclesByVin = ({ vin }) =>
  httpClient.get(`/catalog/vehicle/vin/${vin}`);

export const fetchVehiclesByPlate = (data) =>
  httpClient.get(`/catalog/vehicle/p2v?${buildQueryUrl(data)}`);

export const getMakes = (data) =>
  httpClient.get(`/catalog/vehicle/make/internal?${buildQueryUrl(data)}`);

export const getModels = (data) =>
  httpClient.get(`/catalog/vehicle/make/internal/model?${buildQueryUrl(data)}`);

export const getEngines = (data) =>
  httpClient.get(`/catalog/vehicle/engine?${buildQueryUrl(data)}`);

let cancelTokenAlternate;
export const getPartList = (data) => {
  if (typeof cancelTokenAlternate != typeof undefined) {
    cancelTokenAlternate.cancel(
      "Operation is canceled due to new search request"
    );
  }

  cancelTokenAlternate = axios.CancelToken.source();
  return httpClient.post(`/order-service/pricing-availability`, data, {
    cancelToken: cancelTokenAlternate.token,
  });
};

export const getPartsDescService = ({payload, source}) => {
  return httpClient.post(`/catalog/part/partTypesByVehicles?${buildQueryUrl(payload)}`, null, {cancelToken: source.token});
}
