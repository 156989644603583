import httpClient from "../../../services/http";
import { buildQueryUrl } from "../../../helpers/utils";

export const getMakes = (data) =>
  httpClient.get(`/catalog/vehicle/make/internal?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const getModels = (data) =>
  httpClient.get(`/catalog/vehicle/make/internal/model?${buildQueryUrl(data)}`);

export const getEngines = (data) =>
  httpClient.get(`/catalog/vehicle/engine?${buildQueryUrl(data)}`);

export const getAllChemicalCategories = (data) =>
  httpClient.get(`catalog/chemical/category?${buildQueryUrl(data)}`);

export const getAllPartCategories = (data) =>
  httpClient.get(`catalog/part/category?${buildQueryUrl(data)}`);

export const getAllPartCategoryGroups = (data) =>
  httpClient.get(`catalog/part/category/${data.category}/group?labor=true`);

export const getAllPropackGroups = (data) =>
  httpClient.get(`/promotion-service/job?${buildQueryUrl(data)}`);

export const getAllCheckoutItems = (data) =>
  httpClient.get(`order-service/carts/checkout/items`);

export default httpClient;
