import { getPartsByMultiGroupOrchestrator, getChemPartByGrp } from "../../../actions/parts";
import {
  getDefaultDataBasedOnChemicalUSerPref,
  getPreferenceDataFromStorage,
  getDefaultDataBasedOnUSerPref,
  getRequestPayloadForOrchestratorAPI,
  getChemicalsPreferredBrands,

} from "../../../helpers/utils";
import _ from "lodash";


export const chemicalApiCall = (dispatch, selectedGroupDetails, userPreferenceDetail, sellerId, store, shipMethod) => {
  let userPrefDetail = getDefaultDataBasedOnChemicalUSerPref(userPreferenceDetail);
  const prefBrands = getChemicalsPreferredBrands();
  const brands = prefBrands.multiAnswer.map((b) => (typeof b === "string" ? b : b.text));
  // let groupIds = [909]; 
  let groupIds = selectedGroupDetails.map(detail => detail.groupId);
  setTimeout(async () => {
    try {
      const response = await dispatch(getChemPartByGrp(
        groupIds,
        userPrefDetail.pageNumber,
        userPrefDetail.pageSize,
        userPrefDetail.catalogSortBy,
        userPrefDetail.catalogOrder,
        brands,
        null,
        null,
        null,
        null,
        null,
        [],
        sellerId,
        store,
        shipMethod,
        {}
      ));
      const totalItems = response?.data?.totalItems || 0;
    } catch (error) {
      console.error("Error fetching chemical parts:", error);
    }
  }, 0);
};

export const partApiCall = (dispatch, selectedGroupDetails, selectedVehicle, sellerId, store, shipMethod) => {
  const { year, makeId, modelId, engineId } = selectedVehicle;
  const userPreferenceDetail = getPreferenceDataFromStorage();
  const userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
  const selectedGroupDeta = [];
  let requestItemsArray = selectedGroupDetails.map(detail => ({
    groupCode: detail.groupId,
    specificCondition: detail.specificCondition || []
  }));

  let requetPartsItemArray = selectedGroupDetails.map(detail => ({
    id: detail.partTypeId
  }));

  const filter = {
    availability: [],
    brand: [],
    exactMatch: true,
    misc: [],
    partTypes: requetPartsItemArray,
  };

  const prepareGroup = _.uniqWith(
    selectedGroupDetails.map(item => ({
      code: Number(item.groupId),
      name: item.name,
      categoryId: item.categoryId
    })),
    _.isEqual
  );

  
  const __orchestratorPayload = getRequestPayloadForOrchestratorAPI(
    prepareGroup
  );

  setTimeout(() => {
    dispatch(
      getPartsByMultiGroupOrchestrator(
        year,
        makeId,
        modelId,
        engineId,
        {
          requestItems: __orchestratorPayload,
          sellPartnerId: sellerId,
          store: store,
          shipMethod: shipMethod,
        },
        userPrefDetail.pageNumber,
        userPrefDetail.pageSize,
        userPrefDetail.catalogSortBy,
        userPrefDetail.catalogOrder,
        filter,
        selectedGroupDeta,
        true,
        selectedVehicle,
        true,
        true
      )
    );
  }, 500);
};

