import React from "react";
import { Row, Col } from "react-bootstrap";
import Placeholder from "../../shared/Placeholder";
import XModal from "../../shared/XModal";
import { useSelector } from "react-redux";
import {
  formatAmount,
  timeInCST,
  timeInUTC,
  time,
} from "../../../helpers/utils";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print-nonce";

function Estimate({
  openPopup,
  closePopup,
  data,
  totalBillingInfo,
  cartApiFetching,
  userDetails,
}) {
  const printComponent = useRef();
  const parts = useSelector((state) => state.parts);
  const hideAllPrice =  parts  && parts.hidePrice;

  const handlePrint = useReactToPrint({
    content: () => printComponent.current,
  });
  console.log("user-----", userDetails.X2userName);
  return (
    <XModal
      show={openPopup}
      title="Estimate View"
      submitType={true}
      submitButtonText="Print"
      showButtons={true}
      handleAction={handlePrint}
      handleClose={closePopup}
      favoriteTextValidity={true}
    >
      {!cartApiFetching ? (
        <>
          <div className="estimate_popup_container" ref={printComponent}>
            <div className="">
              <div className="print__logo">
                <img
                  alt=""
                  src="/public_images/Print_Logo.png"
                  align="center"
                />
              </div>
            </div>
            <div className="print__header">Estimate View</div>
            <table className="estimate_table">
              <thead>
                <tr className="estimate_table_column">
                  <th>
                    <span>Order QTY</span>
                  </th>
                  {/* <th>
                            <span>Availability</span>
                        </th> */}
                  <th>
                    <span>P/L</span>
                  </th>
                  <th>
                    <span>Item#</span>
                  </th>
                  <th>
                    <span>Description</span>
                  </th>
                  {!hideAllPrice && (
                  <th>
                    <span>List Price</span>
                  </th>)}
                  {!hideAllPrice && (
                  <th>
                    <span>Total List Price</span>
                  </th>)}
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item) => {
                    return (
                      <tr className="estimate_table_data">
                        <td>
                          {/* <span className="mobile_col_data">Order QTY:</span> */}
                          <span>{item.qty}</span>
                        </td>
                        {/* <td>
                                                      
                                                      <span>{item.availabilityHour}</span>
                                                  </td> */}
                        <td>
                          {/* <span className="mobile_col_data">P/L:</span> */}
                          <span>{item.mfgCode}</span>
                        </td>
                        <td>
                          {/* <span className="mobile_col_data">Item#:</span> */}
                          <span>{item.partNum}</span>
                        </td>
                        <td>
                          {/* <span className="mobile_col_data">Description:</span> */}
                          <span>{item.desc}</span>
                        </td>
                        {!hideAllPrice && (
                        <td>
                          {/* <span className="mobile_col_data">List Price:</span> */}
                          <span className="maroonColorTxt">
                            ${formatAmount(item.listPrice)}
                          </span>
                        </td>)}
                        {!hideAllPrice && (
                        <td>
                          {/* <span className="mobile_col_data">Total List Price:</span> */}
                          <span className="maroonColorTxt">
                            ${formatAmount(item.qty * item.listPrice)}
                          </span>
                        </td>)}
                      </tr>
                    );
                  })
                ) : (
                  <span>NO data</span>
                )}
                  {!hideAllPrice && (
                <tr className="estimate_table_total">
                  <td colspan="5">
                    <span>Total</span>
                  </td>
                  <td className="maroonColorTxt">
                    <span className="maroonColorTxt">
                      ${formatAmount(totalBillingInfo.totalPartsListPrice)}
                    </span>
                  </td>
                </tr>)}
              </tbody>
            </table>
            {/* <div className="estimate_table_total">
                      <span>Total</span>
                      <span className="maroonColorTxt">${formatAmount(totalBillingInfo.totalPartsListPrice)}</span>
                  </div> */}
            <p>Estimate includes only Parts!</p>
            <div className="page-footer" id="footer">
              {userDetails.x2userName}
              <div className="page-footer_time" id="footer">
                {timeInCST()}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Placeholder rows={2} />
      )}
    </XModal>
  );
}

export default Estimate;
