import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import _ from "lodash";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import {
  Accordion,
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import PaginationComponent from "../../Pagination";

import DateRangePicker from "react-bootstrap-daterangepicker";

import "./styles.scss";
import { Link, Redirect, useHistory } from "react-router-dom";

import Placeholder from "../../Placeholder";
import XSelect from "../../XSelect";
import XModal from "../../XModal";

import {
  getPartsByMultiGroupOrchestrator,
  getChemPartByGrp,
} from "../../../../actions/parts";
import {
  fetchPartsHistory,
  searchPartsHistory,
  deletePartHistory,
  setVehicles,
} from "../mygarage.thunks";
import Remove from "../Components/Remove";
import FormValidationErrorMessage from "../../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import {
  hasAlphaSpecialIntChar,
  hasMinLength,
  timeInUTC,
  getPreferenceDataFromStorage,
  getDefaultDataBasedOnChemicalUSerPref,
  getDefaultDataBasedOnUSerPref,
} from "../../../../helpers/utils";
// import orderedIcon from "../../../../static/images/icons/order.svg";
import orderedIcon from "../../../../static/images/icons/red/order.png";
import {
  setPartSelected,
  setVehicleSelected,
  setJobSelected,
} from "../mygarage.slice";
 import _cloneDeep from "lodash/cloneDeep"
import { ReactSVG } from "react-svg";
import { triggerCustomUxCriticalError } from "../../../../actions/error";

const options = [
  { label: "Previously Selected", value: "selected" },
  { label: "Previously Ordered", value: "ordered" },
];

const PartsHistory = (props) => {
  const { closeOverlay } = props;
  const isVehicleSelectionToastDisplayed = useSelector((state) => state.app.isToastVisible);
  const searchInput = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const initializeValidity = () => {
    const validity = {
      isSearchKeyNotNull: true,
      isSearchAlphanumericSpecial: true,
      isSearchMinLimit: true,
    };
    return validity;
  };

  const user = useSelector((state) => state.user);
  const userAdditionInfo = useSelector((state) => state.userManagement);
  const parts = useSelector((state) => state.parts);
  const mygarage = useSelector((state) => state.mygarage);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);
  const [dateFilter, setDateFilter] = useState(null);
  const [validity, setvalidity] = useState(initializeValidity);
  const [vehicleSearchArray, setVehicleSearchArray] = useState([]);
  const [initialDSWVehicleData, setInitialDSWVehicleData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState(options[0]);
  const [defaultSelectChanged, setDefaultSelectChanged] = useState(false);
  const all = [{ label: "ALL", value: 'ALL' }];
  const [customerIdFilter, setCustomerIdFilter] = useState(all);
  const partsHistories = mygarage.partsHistories;
  let totalPages = mygarage.partsTotalPages;
  let totalItems = mygarage.partsTotalItems;

  const preparePayLoad = () => {
    let customerNumber = [];
    if (user && user.isDSW === true && !_isEmpty(userAdditionInfo) && !_isEmpty(userAdditionInfo.accounts)) {
      let obj =
        !_isEmpty(user.userDetails) &&
        !_isEmpty(userAdditionInfo.accounts) &&
        userAdditionInfo.accounts.map((d) => ({ label: d.customerId }));
      customerNumber =
        user &&
        user.userDetails &&
        user.userDetails.billTo &&
        user.userDetails.billTo.billToId;

      let storeArr = [];
      obj?.map((s, i) => {
        storeArr.push({
          customerID: s.label,
        });
      });
      storeArr.map((data, i) => {
        if (!_isEmpty(data) && data !== customerNumber) {
          storeArr.push({
            customerID: customerNumber,
          });
        }
      });
      const uniqueCustomerPayload = Object.values(
        storeArr.reduce((a, c) => {
          a[c.customerID + "|" + c.customerID] = c;
          return a;
        }, {})
      );
      return uniqueCustomerPayload;
    }
  };
  const getSelectOptions = (data) => {
    let { xlPartAccountNumber, x2userName } = user.userDetails;
    let { name } = user.userDetails.billTo;
    let obj =
      !_isEmpty(user.userDetails) && !_isEmpty(userAdditionInfo.accounts) &&
      userAdditionInfo.accounts.map((d) => ({
        label: d.customerId + " - " + d.customerName,
        value: d.customerId,
      }));

    obj.unshift({
      label: xlPartAccountNumber + "-" + name,
      value: xlPartAccountNumber,
    });
    obj.unshift({ label: "ALL", value: "ALL" });

    return obj;
  };
  const constructRequestItems = () => {
    let requestItems = [{}];
    let payloadData = preparePayLoad();
    if (!_isEmpty(payloadData) && !_isEmpty(customerIdFilter[0]) && customerIdFilter[0].value === 'ALL' && !_isEmpty(user) && user.isDSW === true) {
      requestItems =
        !_isEmpty(payloadData) &&
        payloadData.map((d) => ({
          customerID: d.customerID,
          shipTo:
            !_isEmpty(user.userDetails) && user.userDetails.shipTos[0].shipToId,
        }));
    }
    else if (!_isEmpty(customerIdFilter[0]) && customerIdFilter[0].value !== 'ALL' && !_isEmpty(user) && user.isDSW === true) {
      requestItems = [{
        customerID:
          customerIdFilter[0].value,
        shipTo: user.userDetails.shipTos[0].shipToId,
      }]
    }
    else if (!_isEmpty(user) && user.isDSW === false) {
      requestItems = [
        {
          customerID:
            user &&
            user.userDetails &&
            user.userDetails.billTo &&
            user.userDetails.billTo.billToId,
          shipTo: user.userDetails.shipTos[0].shipToId,
        },
      ];
    }
    return requestItems;
  }


  const initialFetchPartsHistory = (clickedPage) => {
    let requestItems = [{}];
    let pageNum = clickedPage ? clickedPage : currentPage;
    let preferenceFilter = handleFilterBasedOnUserPref();
    const byCustomerList = {
      pageNo: pageNum,
      pageSize: pageSize,
      userFilter: preferenceFilter.value,
    };
    requestItems = constructRequestItems();

    const payload = { requestItems, params: byCustomerList };

    try {
      const partsHistoryAction = dispatch(fetchPartsHistory(payload));
      partsHistoryAction.then((action) => {
        if (action.payload) {
          let arrData = _cloneDeep(action.payload);
          Object.values(arrData).map((item) => {
            setCurrentPage(item.pageNumber);
            setVehicleSearchArray(arrData);
            setInitialDSWVehicleData(arrData);
          })
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    initialFetchPartsHistory();
  }, []);

  const handleSearch = (searchText) => {
    let requestItems = [{}];
    requestItems = constructRequestItems();
    const payload = { requestItems, searchKey: searchText }

    if (searchText && searchText.length > 1) {
      const vehiclesAction = dispatch(searchPartsHistory(payload));
      vehiclesAction.then((action) => {
        if (action.payload) {
          setVehicleSearchArray(action.payload);
        }
      });

    } else if (_.isEmpty(searchText)) {
      initialFetchPartsHistory();
    }
  };

  const handleSearchChange = (event) => {
    const searchData = event.target.value;
    let validity = initializeValidity();
    validity.isSearchAlphanumericSpecial = hasAlphaSpecialIntChar(searchData);
    validity.isSearchMinLimit = hasMinLength(searchData, 1);
    setvalidity(validity);
  };

  const handleSearchBlur = () => {
    let validity = initializeValidity();
    setvalidity(validity);
  };

  const deleteVehicleHandler = () => {
    const { searchPartId } = selectedVehicle;
    const removeAction = dispatch(
      deletePartHistory({ searchPartId: searchPartId })
    );

    setOpenWarning(false);

    removeAction.then(() => {
      initialFetchPartsHistory();
    });
  };

  const handleFilterBy = (filter) => {
    let requestItems = [{}];
    searchInput.current.value = "";
    let vehicleSelectedValue = options.find(
      (option) => option.value === filter.value
    );
    setFilter(vehicleSelectedValue);
    setDefaultSelectChanged(true);
    const byCustomerList = {
      pageNo: currentPage,
      pageSize: pageSize,
      userFilter: filter.value,
    };
    requestItems = constructRequestItems();

    const payload = { requestItems, params: byCustomerList };
    
    try {
      const partsHistoryAction = dispatch(fetchPartsHistory(payload));
      partsHistoryAction.then((action) =>  {
        let arrData = _cloneDeep(action.payload);
        Object.values(arrData).map((item) => {
          setCurrentPage(item.pageNumber);
          setVehicleSearchArray(arrData);
          setInitialDSWVehicleData(arrData);
        })

      });
    } catch (error) {}
  };

  const handlePartsSelect = (selectedPart) => {
    if (isVehicleSelectionToastDisplayed) {
      let errorMessageProps = {
        heading: '',
        message: "manualDismiss",
        type: "info"
      };
      triggerCustomUxCriticalError(errorMessageProps, dispatch);
    }
    const userPreferenceDetail = getPreferenceDataFromStorage();
    if (selectedPart.source === "chemicals") {
      const userPrefChemPartByCatDtl =
        getDefaultDataBasedOnChemicalUSerPref(userPreferenceDetail);
      history.push("/supplies-list");
      const categorys = parts.categorySuppliesMap;
      const { groupId, categoryId } = selectedPart;

      let selectedGroup = _.head(
        _.filter(
          _.head(_.filter(categorys, (c) => c.code === categoryId)).groups,
          (g) => g.id === groupId
        )
      );
      const { categoryID, name, id } = selectedGroup;
      selectedGroup = [{ categoryId: categoryID, name, id }];
      dispatch({
        payload: {
          selectedGroupDetails: selectedGroup,
        },
        type: "SUPPLIES_GROUP_SELECTED",
      });
      let groupIds = selectedGroup.map((single) => single.id);
      dispatch(
        getChemPartByGrp(
          groupIds,
          userPrefChemPartByCatDtl.pageNumber,
          userPrefChemPartByCatDtl.pageSize,
          userPrefChemPartByCatDtl.catalogSortBy,
          userPrefChemPartByCatDtl.catalogOrder
        )
      );
      dispatch({
        payload: {
          brandsView: false,
        },
        type: "SUPPLIES_BRAND_VIEW",
      });
    } else {
      const userPrefPartDtl =
        getDefaultDataBasedOnUSerPref(userPreferenceDetail);
      history.push("/product-list");
      // TODO need to handle if already vehicle is selected
      const categorys = parts.categoryGroupMap;
      dispatch(setPartSelected(selectedPart));
      dispatch(setJobSelected([]));
      const { year, makeId, modelId, engineId, groupId, categoryId } =
        selectedPart;

      let selectedGroup = _.head(
        _.filter(
          _.head(_.filter(categorys, (c) => c.code === categoryId)).groups,
          (g) => g.code === groupId
        )
      );

      selectedGroup = { ...selectedGroup, categoryId: categoryId };

      // Not setting the selected vehicle, if already vehicle is selected
      if (!mygarage.selectedVehicle) {
        dispatch(setVehicleSelected(selectedPart));
        dispatch({
          payload: {
            selectedVehicle: selectedPart,
          },
          type: "VEHICLE_SELECTION_COMPLETED",
        });

        // search the vehicle for and update the updatedDate for the vehicle
        const vehicles = mygarage.vehicles;
        const foundVehicle = vehicles.filter(
          (v) =>
            v.year === year &&
            v.makeId === makeId &&
            v.modelId === modelId &&
            v.engineId === engineId
        );
        if (foundVehicle.length > 0) {
          const payload = {
            ...foundVehicle[0],
            updatedDate: timeInUTC(),
          };
          dispatch(setVehicles(payload));
        }
      }

      // Dispatch Selected Group
      dispatch({
        payload: {
          selectedGroupDetails: [selectedGroup],
        },
        type: "GROUP_SELECTED",
      });
      let foundSPCondition;
      if (parts.catalogRequestGroups && parts.catalogRequestGroups.length > 0) {
        foundSPCondition = parts.catalogRequestGroups.requestItems.filter(
          (group) => +group.groupCode === +groupId
        );
      }

      let reqObj = {
        requestItems: [
          {
            groupCode: groupId,
            specificCondition:
              foundSPCondition &&
              foundSPCondition[0] &&
              foundSPCondition[0].specificCondition
                ? foundSPCondition[0].specificCondition
                : [],
          },
        ],
      };

      dispatch(
        getPartsByMultiGroupOrchestrator(
          year,
          makeId,
          modelId,
          engineId,
          reqObj,
          userPrefPartDtl.pageNumber,
          userPrefPartDtl.pageSize,
          userPrefPartDtl.catalogSortBy,
          userPrefPartDtl.catalogOrder,
          parts.catalogFilter
        )
      );
    }

    closeOverlay();
  };

  const handleWarningOpen = (v) => {
    setSelectedVehicle(v);
    // closeOverlay();
    setOpenWarning(true);
  };

  const handleWarningClose = () => {
    setOpenWarning(false);
  };

  const handleFilterBasedOnUserPref = () => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let filterData = filter;
    if (!_isEmpty(userPreferenceDetail)) {
      let selectedViewType = {};
      selectedViewType = _find(userPreferenceDetail, {
        code: "My Garage",
        text: "Parts History",
      });
      let currentlySelectedValue, currentlySelectedText;
      if (!defaultSelectChanged) {
        currentlySelectedValue =
          selectedViewType &&
          selectedViewType.answer &&
          selectedViewType.answer.code &&
          selectedViewType.answer.code;
        currentlySelectedText =
          selectedViewType &&
          selectedViewType.answer &&
          selectedViewType.answer.text &&
          selectedViewType.answer.text;

        if (currentlySelectedText && currentlySelectedValue) {
          setFilter({
            label: currentlySelectedText,
            value: currentlySelectedValue,
          });
          filterData = {
            label: currentlySelectedText,
            value: currentlySelectedValue,
          };
        }
      }
    }
    return filterData;
  };

  const handleDateRange = (e, picker) => {
    let requestItems = [{}];
    searchInput.current.value = "";
    const { startDate, endDate } = picker;

    const _startDate = startDate.toISOString();
    const _endDate = endDate.toISOString();

    setDateFilter(
      `${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`
    );
    const byCustomerList = {
      pageNo: currentPage,
      pageSize: pageSize,
      startDate: _startDate,
      endDate: _endDate,
      userFilter: filter.value,
    };
    requestItems = constructRequestItems();

    const payload = { requestItems, params: byCustomerList };
    try {
      const partsHistoryAction = dispatch(fetchPartsHistory(payload));
      partsHistoryAction.then((action) => {
        if (action.payload) {
          let arrData = _cloneDeep(action.payload);
          Object.values(arrData).map((item) => {
            setCurrentPage(item.pageNumber);
            setVehicleSearchArray(arrData);
            setInitialDSWVehicleData(arrData);
          })
        }
      });
    } catch (error) {}
  };

  const clearDateFilter = () => {
    setDateFilter("");
    initialFetchPartsHistory();
  };

  const handlePageChange = (clickedPage) => {
    setCurrentPage(clickedPage);
    initialFetchPartsHistory(clickedPage);
  };
  useEffect(() => {
    initialFetchPartsHistory();
  }, [customerIdFilter]);

  const handleCustomerFilter = (e) => {
    let billTo = e.value;
    setCustomerIdFilter([{ label: e.value, value: e.value }]);
  };


  const sortEntriesByActiveBillTo = () => {
    let entries = Object.entries(vehicleSearchArray);
    if (customerIdFilter[0].value === 'ALL') {
      const currentBillToId = user.userDetails.billTo.billToId;
      let foundIndex = null;
      let foundItem = null;
      entries.forEach((vehicle, i) => {
        const bId = vehicle[0];
        if (bId === currentBillToId) {
          foundIndex = i;
          foundItem = vehicle;
        }
      });

      entries.splice(foundIndex, 1);
      entries.unshift(foundItem);
      entries = entries.filter((v) => v); // filter out undefined or null values if any;
    }
    return entries;
  };
  return (
    <React.Fragment>
      <div className="partshistory-wrapper">
        {!_isEmpty(vehicleSearchArray) && (
          <Row>
            <Col md="4" className="mygarage_pagination_wrapper">
              <PaginationComponent
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </Col>
          </Row>
        )}
        <Row>
          {user.isDSW === true && (
              <Col className="select-container-mygarage">
                <XSelect
                  options={getSelectOptions(user.userDetails.accounts)}
                  isSearchable={false}
                  // isClearable
                  value={!_isEmpty(customerIdFilter) && customerIdFilter}
                  defaultValue={customerIdFilter}
                  onChange={(e) => handleCustomerFilter(e)}
                />
              </Col>
            )}

            {user.isDSW === true && (
              <Col md="5" sm="6">
                <Form.Control
                  ref={searchInput}
                  placeholder="Search Parts History"
                  className="input__search input__control"
                  onKeyUp={(e) => handleSearch(e.target.value)}
                  onKeyDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                  onMouseOver={(e) => e.stopPropagation()}
                  onChange={handleSearchChange}
                  onBlur={handleSearchBlur}
                />
                <FormValidationErrorMessage
                  condition={
                    !validity.isSearchAlphanumericSpecial &&
                    validity.isSearchMinLimit
                  }
                  errorMessage="Search key must be alphanumeric or special characters"
                />
                <FormValidationErrorMessage
                  condition={!validity.isSearchMinLimit}
                  errorMessage="Search key should have atleast 1 character"
                />
              </Col>
            )}
          {user.isDSW === false && (  
          <Col md="7" sm="6">
            <Form.Control
              ref={searchInput}
              placeholder="Search Parts History"
              className="input__search input__control"
              onKeyUp={(e) => handleSearch(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
              onMouseOver={(e) => e.stopPropagation()}
              onChange={handleSearchChange}
              onBlur={handleSearchBlur}
            />
            <FormValidationErrorMessage
              condition={
                !validity.isSearchAlphanumericSpecial &&
                validity.isSearchMinLimit
              }
              errorMessage="Search key must be alphanumeric or special characters"
            />
            <FormValidationErrorMessage
              condition={!validity.isSearchMinLimit}
              errorMessage="Search key should have atleast 1 character"
            />
          </Col>
          )}
          <Col md="4" sm="4" className="padding-left-0">
            <XSelect
              isSearchable={false}
              options={options}
              // isClearable
              defaultValue={{ label: "Previously Selected", value: "selected" }}
              value={filter}
              placeholder="Filter By"
              onChange={handleFilterBy}
            />
          </Col>
          <Col md="1" sm="2">
            <DateRangePicker onApply={handleDateRange}>
              <span className="material-icons date__filter">date_range</span>
            </DateRangePicker>
          </Col>
        </Row>
        {dateFilter && (
          <Row>
            <Col md="12">
              <div className="filter__wrapper">
                <span>{dateFilter}</span>

                {dateFilter && (
                  <span
                    className="material-icons clear__filter_btn"
                    onClick={clearDateFilter}
                  >
                    highlight_off
                  </span>
                )}
              </div>
            </Col>
          </Row>
        )}

        {mygarage.loading ? (
          <Placeholder rows={1} />
        ) : user.isDSW === true && !_isEmpty(vehicleSearchArray) && (
          <React.Fragment>
            <div className="mygarage__content inerchange-box-myGarage interchange_card_my">
              <Accordion allowZeroExpanded={true} preExpanded={[0]}>
                  {sortEntriesByActiveBillTo().map((data, i) => {
                    return (
                      <AccordionItem uuid={i}>
                        {!_isEmpty(data) && (
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {`${data[0]}`}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                        )}
                        <AccordionItemPanel>
                          {!_isEmpty(data[1].pagedItems) &&
                            data[1].pagedItems.map((ph, i) => {
                              return (
                                ph.customerId && (
                                  <div
                                    className="mygarage__vehicle"
                                    key={`${ph.engine}__${i}`}
                                  >
                                    <div className="bold v__title">
                                      {ph.partTypeDesc}
                                    </div>
                                    <div className="v__title">
                                      <div className="v__title_w_title">
                                        {ph.year} {ph.make} {ph.model} {ph.engine}
                                        <div className="v__icons">
                                          {ph.orderedFlag === "Y" && (
                                            <img
                                              src={orderedIcon}
                                              alt="favorite"
                                              className="v__icon"
                                            />
                                          )}
                                        </div>
                                      </div>

                                      {/* Select */}
                                      <div className="operation__links">
                                        <a className="b__link tph-cursor"
                                          onClick={() => handlePartsSelect(ph)}
                                        >
                                          Select
                                        </a>

                                        {/* Remove */}
                                        <Remove
                                          handleRemove={(e) =>
                                            handleWarningOpen(ph)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="v__date">
                                      <label
                                        style={{
                                          color: "#aa0d10",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Added:{" "}
                                      </label>{" "}
                                      <label style={{ fontWeight: "normal" }}>
                                        <Moment
                                          date={ph.updatedDate}
                                          format="MM-DD-YYYY"
                                        />
                                      </label>
                                    </div>
                                    <div className="v__mechanic">
                                      <label
                                        style={{
                                          color: "#aa0d10",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Added By:{" "}
                                      </label>{" "}
                                      <label style={{ fontWeight: "normal" }}>
                                        {" "}
                                        {ph.createdBy}
                                      </label>
                                    </div>
                                  </div>
                                )
                              );
                            })}
                          {dateFilter &&
                            !_isEmpty(data) &&
                            _isEmpty(data[1].pagedItems) && <div className="no-quotes">
                              "No Parts History found on this date, please select a different date."
                            </div>}
                          {dateFilter === null &&
                            !_isEmpty(data) &&
                            _isEmpty(data[1].pagedItems) && <div className="no-quotes">
                              "You have logged in for the first time, please start with Product Category/Group search"
                            </div>}
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
            </div>
          </React.Fragment>
        // ) : (
          // <div className="no-quotes">
          //   {dateFilter
          //     ? "No Parts History found on this date, please select a different date."
          //     : "You have logged in for the first time, please start with Product Category/Group search"}
          // </div>
        )}
        {mygarage.loading ? (
          <Placeholder rows={1} />
        ) : user && user.isDSW === false && !_isEmpty(vehicleSearchArray) && (
          <React.Fragment>
            {sortEntriesByActiveBillTo().map((data, i) => {
              return (
                <div className="mygarage__content">
                  {!_isEmpty(data[1].pagedItems) &&
                    data[1].pagedItems.map((ph, i) => {
                      return (
                        ph.customerId && (
                          <div
                            className="mygarage__vehicle"
                            key={`${ph.engine}__${i}`}
                          >
                            <div className="bold v__title">
                              {ph.partTypeDesc}
                            </div>
                            <div className="v__title">
                              <div className="v__title_w_title">
                                {ph.year} {ph.make} {ph.model} {ph.engine}
                                <div className="v__icons">
                                  {ph.orderedFlag === "Y" && (
                                    <img
                                      src={orderedIcon}
                                      alt="favorite"
                                      className="v__icon"
                                    />
                                  )}
                                </div>
                              </div>

                              {/* Select */}
                              <div className="operation__links">
                                <a
                                  className="b__link tph-cursor"
                                  onClick={() => handlePartsSelect(ph)}
                                >
                                  Select
                                </a>

                                {/* Remove */}
                                <Remove
                                  handleRemove={(e) =>
                                    handleWarningOpen(ph)
                                  }
                                />
                              </div>
                            </div>

                            <div className="v__date">
                              <label
                                style={{
                                  color: "#aa0d10",
                                  fontWeight: "normal",
                                }}
                              >
                                Added:{" "}
                              </label>{" "}
                              <label style={{ fontWeight: "normal" }}>
                                <Moment
                                  date={ph.updatedDate}
                                  format="MM-DD-YYYY"
                                />
                              </label>
                            </div>
                            <div className="v__mechanic">
                              <label
                                style={{
                                  color: "#aa0d10",
                                  fontWeight: "normal",
                                }}
                              >
                                Added By:{" "}
                              </label>{" "}
                              <label style={{ fontWeight: "normal" }}>
                                {" "}
                                {ph.createdBy}
                              </label>
                            </div>
                          </div>
                        )
                      );
                    })}
                  {dateFilter &&
                    !_isEmpty(data) &&
                    _isEmpty(data[1].pagedItems) && <div className="no-quotes">
                      "No Parts History found on this date, please select a different date."
                    </div>}
                  {dateFilter === null &&
                    !_isEmpty(data) &&
                    _isEmpty(data[1].pagedItems) && <div className="no-quotes">
                      "You have logged in for the first time, please start with Product Category/Group search"
                    </div>}
                </div>
              );
            })}
          </React.Fragment>
          // ) : (
          //   <div className="no-quotes">
          //     {dateFilter
          //       ? "No Parts History found on this date, please select a different date."
          //       : "You have logged in for the first time, please start with Product Category/Group search"}
          //   </div>
        )}
      </div>
      {selectedVehicle && (
        <XModal
          show={openWarning}
          handleClose={handleWarningClose}
          handleAction={deleteVehicleHandler}
          onHide={handleWarningClose}
          className="custom_xmodal"
          isValidValidity={true}
        >
          <div className="delete__text text__center">
            You want to delete the part <br />
            <span className="bold">
              {`${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`}
            </span>
            <br />
            <span className="bold">{selectedVehicle.partTypeDesc}</span>
          </div>
        </XModal>
      )}
    </React.Fragment>
  );
};

export default PartsHistory;
