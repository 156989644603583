import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { unwrapResult } from "@reduxjs/toolkit";

import "./styles.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import PaginationComponent from "../../Pagination";

import { isAlphanumeric, hasMinLength } from "../../../../helpers/utils";
import {
  clearCart,
  getCart,
  getItemsFromCart,
  editQuoteName,
  quoteDetails,
  clearQuoteDetails,
  getCheckoutItems
} from "../../../../actions/cart";
import { fetchQuotes, deleteQuote, viewQuote } from "../mygarage.thunks";
import { clearQuoteError, setQuoteSelected } from "../mygarage.slice";
import Placeholder from "../../Placeholder";
import FormValidationErrorMessage from "../../../shared/FormValidationErrorMessage/FormValidationErrorMessage";

import { isLengthLessthan } from "../../../../helpers/utils";
import { isEmpty } from "lodash";
import _every from "lodash/every";
import _pick from "lodash/pick";

// Custom Components
import XModal from "../../XModal";
import Remove from "../Components/Remove";
import LostSales from "../../../ShoppingCart/LostSales";
import editIcon from "../../../../static/images/icons/red/edit.png";
import { useToasts } from "react-toast-notifications";
import _ from "lodash";

const SUCCESS_QUOTE_EDITED = "Quote name changed successfully.";
const ERROR_QUOTE_EDITED = "Quote name is failed to save.";

const Quotes = (props) => {
  const { closeOverlay } = props;

  const actionTypes = { QUOTES_DATA: "QUOTES_DATA" };

  const initializeValidity = () => {
    const validity = {
      isSearchAlphanumeric: true,
      isSearchMinLimit: true,
    };
    return validity;
  };

  const quoteEditInput = useRef();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [openWarning, setOpenWarning] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [vId, setVId] = useState(null);
  const [openViewQuote, setOpenViewQuote] = useState(false);
  const [validity, setvalidity] = useState(initializeValidity);
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeQuoteName, setActiveQuoteName] = useState(null);
  const [showLostSales, setShowLostSales] = useState(false);
  const [quoteData, setShowQuoteData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [quoteId, setQuoteId] = useState(null)

  const history = useHistory();
  const location = useLocation();

  const mygarage = useSelector((state) => state.mygarage);
  const cart = useSelector((state) => state.cart);
  let quoteDetailData = _.cloneDeep(
    useSelector((state) => state.cart.quoteDetailData)
  );
  const [selectedPart, setSelectedPart] = useState([]);
  // quoteDetailData = _.cloneDeep(quoteDetailData);

  const userDetails = useSelector((state) => state.user.userDetails);

  const handleSearchChange = (event) => {
    const searchData = event.target.value;
    let validity = initializeValidity();
    validity.isSearchAlphanumeric = isAlphanumeric(searchData);
    validity.isSearchMinLimit = hasMinLength(searchData, 1);
    setvalidity(validity);
  };

  const handleSearchBlur = () => {
    let validity = initializeValidity();
    setvalidity(validity);
  };

  const initialFetchQuotes = (clickedPage) => {
    let pageNum = clickedPage ? clickedPage : currentPage;
    const payload = {
      shipToId:
        (user.userDetails && user.userDetails.shipTos[0].shipToId) || "001",
      pageNum: pageNum,
      pageSize: pageSize,
    };
    try {
      const quotesAction = dispatch(fetchQuotes(payload));
      quotesAction.then((action) => {
        if (action.payload) {
          setQuotes(action.payload.pagedItems);
          setCurrentPage(action.payload.pageNumber);
          setTotalPages(action.payload.totalPages);
          setTotalItems(action.payload.totalItems);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    initialFetchQuotes();
  }, []);

  useEffect(() => {
    if (quoteDetailData && !showLostSales) {
      setSelectedPart(quoteDetailData);
      setShowQuoteData(quoteDetailData);
      setShowLostSales(true);
    }
  }, [quoteDetailData]);

  const deleteQuoteHandler = () => {
    const { id } = selectedQuote;
    const removeAction = dispatch(deleteQuote({ id }));
    setOpenWarning(false);

    removeAction.then((action) => {
      clearQuoteDetails(dispatch);
      initialFetchQuotes();
    });
  };

  const searchQuotes = (searchTerm) => {
    const payload = {
      shipToId:
        (user.userDetails && user.userDetails.shipTos[0].shipToId) || "001",
      pageNum: currentPage,
      pageSize: pageSize,
      searchParam: searchTerm,
    };
    try {
      const quotesAction = dispatch(fetchQuotes(payload));
      quotesAction.then((action) => {
        setQuotes(action.payload.pagedItems);
        setTotalPages(action.payload.totalPages);
        setTotalItems(action.payload.totalItems);
      });
    } catch (error) {}
  };

  // To handle Warning Pop-up to show if cart has already items.
  useEffect(() => {
    if (mygarage.quoteError) {
      setOpenViewQuote(true);
    }
  }, [mygarage.quoteError]);

  const saveQuoteAction = (id) => {
    const viewQuoteAction = dispatch(viewQuote({ id: vId || id }));
    viewQuoteAction.then((action) => {
      dispatch(clearQuoteError());

      // TODO Dispatch an action to get the cart items, if already in shopping-cart page.
      const { pathname } = location;

      if (pathname === "/checkout") {
        //getCart(dispatch);
        getCheckoutItems(dispatch);
      } else {
        history.push("/checkout");
      }
    });
  };

  const viewQuoteHandler = (id) => {
    setOpenViewQuote(false);
    if (cart.qtyInCart > 0) {
      clearCart(dispatch, () => {
        saveQuoteAction(id);
      });
    } else {
      saveQuoteAction(id);
    }

    closeOverlay();
  };

  const handleWarningOpen = (v) => {
    setQuoteId(v.id);
    quoteDetails(v.id, dispatch);
    setSelectedQuote(v);
  };

  const handleWarningClose = () => {
    setOpenWarning(false);
    setOpenViewQuote(false);
    setShowEditModal(false);
    dispatch(clearQuoteError());
  };

  const handleViewQuoteModal = (v, id) => {
    setVId(id);
    setActiveQuoteName(v);
    setSelectedQuote(v);
    dispatch(setQuoteSelected(v));
    if (cart.qtyInCart > 0) {
      dispatch(viewQuote({ id: vId || id }));
    } else {
      viewQuoteHandler(id);
    }
  };

  const handleEditQuote = (v, id) => {
    setActiveQuoteName(v);
    setVId(id);
    setShowEditModal(true);
    setTimeout(() => {
      quoteEditInput.current.focus();
    }, 500);
  };

  const handleSaveQuote = () => {
    if (vId) {
      editQuoteName(vId, activeQuoteName, dispatch)
        .then((success) => {
          initialFetchQuotes();
          addToast(SUCCESS_QUOTE_EDITED, {
            appearance: "success",
            autoDismiss: true,
          });
        })
        .catch((err) => {
          addToast(ERROR_QUOTE_EDITED, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    }
    setShowEditModal(false);
  };

  const handleQuoteNameChange = (event) => {
    const favText = event.target.value;
    let validity = initializeValidity();
    const isValid = /^[a-zA-Z0-9 ]+$/.test(favText);
    validity.isFavTextAlphanumeric = isValid;
    validity.isFavTextMinLimit = hasMinLength(favText, 1);
    validity.isFavTextMaxLimit = isLengthLessthan(favText, 50);
    validity.isFavTextValid = _every(
      _pick(validity, [
        "isFavTextAlphanumeric",
        "isFavTextMinLimit",
        "isFavTextValid",
        "isFavTextMaxLimit",
      ])
    );
    setvalidity(validity);
    setActiveQuoteName(event.target.value);
  };

  const handlePageChange = (clickedPage) => {
    setCurrentPage(clickedPage);
    initialFetchQuotes(clickedPage);
  };

  return (
    <React.Fragment>
      <div className="quotes-wrapper">
        {quotes && (
          <Row>
            <Col md="4" className="mygarage_pagination_wrapper">
              <PaginationComponent
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md="7">
            <div className="search-container">
              <Form.Control
                placeholder="Search Quote"
                className="input__search input__control"
                onKeyUp={(e) => searchQuotes(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
                onMouseOver={(e) => e.stopPropagation()}
                onChange={handleSearchChange}
                onBlur={handleSearchBlur}
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isSearchAlphanumeric && validity.isSearchMinLimit
                }
                errorMessage="Search key must be alphanumeric only"
              />
              <FormValidationErrorMessage
                condition={!validity.isSearchMinLimit}
                errorMessage="Search key should have atleast 1 character"
              />
            </div>
          </Col>
          <Col></Col>
        </Row>

        {mygarage.loading ? (
          <Placeholder rows={1} />
        ) : quotes && quotes.length ? (
          <React.Fragment>
            <div className="mygarage__content">
              {quotes.map((v, i) => {
                return (
                  <div className="mygarage__vehicle" key={`${v.name}__${i}`}>
                    <Row>
                      <Col md="5">
                        <div className="quote_name">
                          Quote Name: {v.name.substring(0, 20)}{" "}
                          <span
                            class="material-icons v__icon action edit_icon"
                            onClick={() => handleEditQuote(v.name, v.id)}
                          >
                            edit
                          </span>
                        </div>
                        <div>
                          Date:{" "}
                          <Moment format="MM-DD-YYYY" date={v.createDate} />
                          &nbsp;&nbsp;(Expiry Date:&nbsp;
                          <Moment format="MM-DD-YYYY" date={v.expirationDate} />
                          )
                        </div>
                        <div>User: {v.userName}</div>
                        <div></div>
                      </Col>
                      <Col md="4">
                        <div>
                          Total Qty: <span className="">{v.totalQty}</span>
                        </div>
                        <div className="bold">Amount: ${v.totalCost}</div>
                      </Col>
                      <Col md="3" className="d-flex">
                        <a
                          onClick={() => handleViewQuoteModal(v.name, v.id)}
                          className="view_quotes tph-cursor"
                        >
                          View
                        </a>
                        <Remove handleRemove={(e) => handleWarningOpen(v)} />
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        ) : (
          <div className="no-quotes">
            You have not saved any cart as Quote, please add parts to cart and
            save it as Quote
          </div>
        )}
      </div>

      {openViewQuote && (
        <XModal
          show={openViewQuote}
          handleClose={handleWarningClose}
          handleAction={viewQuoteHandler}
          isValidValidity={true}
          className="custom_xmodal"
        >
          <div className="text__center">
            You want to clear the cart
            {location.pathname === "/checkout"
              ? null
              : " and move to shopping cart page"}
          </div>
        </XModal>
      )}

      {showEditModal && (
        <XModal
          show={showEditModal}
          title="Edit the Quote Name"
          handleClose={handleWarningClose}
          handleAction={handleSaveQuote}
          isValidValidity={true}
          className="custom_xmodal"
          submitButtonText="Save"
          submitType
          favoriteTextValidity={_every(validity) && !isEmpty(activeQuoteName)}
        >
          <div className="text__center input__row">
            <Form.Control
              ref={quoteEditInput}
              defaultValue={activeQuoteName}
              onChange={handleQuoteNameChange}
              onBlur={handleQuoteNameChange}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              onFocus={(e) => {
                handleQuoteNameChange(e);
                e.stopPropagation();
              }}
              onMouseOver={(e) => e.stopPropagation()}
              autoFocus={true}
            />{" "}
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip`}>
Enter customer name, phone number, both, any text to identify this quote                </Tooltip>
              }
            >
              <span class="material-icons">info</span>
            </OverlayTrigger>
          </div>
          <FormValidationErrorMessage
            condition={!validity.isFavTextAlphanumeric}
            errorMessage={"Favorite text must be alphanumeric only"}
          />
          <FormValidationErrorMessage
            condition={!validity.isFavTextMinLimit}
            errorMessage={"Favorite text should have atleast 1 character"}
          />
          <FormValidationErrorMessage
            condition={
              !validity.isFavTextMaxLimit && validity.isFavTextMinLimit
            }
            errorMessage={"Favorite text should not exceed 50 character"}
          />
        </XModal>
      )}

      {/* Requirement changes, need to call lost sales when you remove the quote */}
      {showLostSales ? (
        <LostSales
          openPopup={showLostSales}
          closePopup={() => {
            setShowLostSales(false);
            clearQuoteDetails(dispatch);
          }}
          selectedPartDetails={selectedPart}
          dispatch={dispatch}
          userId={userDetails.x2userName}
          source={"removeQuotes"}
          handleQuoteRemove={deleteQuoteHandler}
          quoteId = {quoteId}
        />
      ) : null}
    </React.Fragment>
  );
};

export default Quotes;
