import React, { Fragment, useState } from 'react';
import { Tab, Row, Col, Form } from 'react-bootstrap';
import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import _pick from 'lodash/pick';
import _every from 'lodash/every';
import { isAlphanumeric, isLengthLessthan, hasMinLength } from '../../../helpers/utils';

function VinSearch(props) {
	let { handleInputOnBlur, handleInputOnChange } = props;

	const intializeVinValidations = () => {
		const vinValidity = {
			isVinAlphanumeric: true,
			isVinMinLength: true,
			isVinLengthBelowLimit: true,
			isVinValid: true,
		}
		return vinValidity;
	}

	const [vinValidity, setVinValidity] = useState(intializeVinValidations);
	const [vinnumber, setVinNumber] = useState('');

	const handleVinChange = (event) => {
		const vinValue = event.target.value;
		let vinValidity = intializeVinValidations();
		vinValidity.isVinAlphanumeric = isAlphanumeric(vinValue);
		vinValidity.isVinLengthBelowLimit = isLengthLessthan(vinValue, 17);
		vinValidity.isVinMinLength = hasMinLength(vinValue, 16);
		vinValidity.isVinValid = _every(
			_pick(vinValidity, [
				'isVinAlphanumeric',
				'isVinLengthBelowLimit',
				'isVinMinLength',
			]),
		);
		setVinValidity(vinValidity);
		setVinNumber(vinValue);
	}

	const isInputBlank = (input) => {
		return input === "" || input === null || input === undefined;
	};

	return (
		<Tab.Pane eventKey="VIN">
			<Row className="justify-content-center vin-wrapper">
				<Col className="select-container">
					<Form.Control
						className="input__default"
						type="text"
						onBlur={handleInputOnBlur}
						onChange={handleVinChange}
						onKeyDown={handleInputOnChange}
						name="vinSearch"
						placeholder="Search By your vehicle VIN"
						maxLength={18}
					/>
				</Col>
				<Fragment>
					<FormValidationErrorMessage
						condition={!vinValidity.isVinLengthBelowLimit && vinValidity.isVinMinLength}
						errorMessage={"Vin number should not exceed above 17 characters"}
					/>
					<FormValidationErrorMessage
						condition={!vinValidity.isVinMinLength && vinValidity.isVinLengthBelowLimit && vinValidity.isVinAlphanumeric}
						errorMessage={"Vin number should be atleast 16 characters"}
					/>
					<FormValidationErrorMessage
						condition={!vinValidity.isVinAlphanumeric && vinValidity.isVinLengthBelowLimit && !isInputBlank(vinnumber)}
						errorMessage={"Vin number should be alphanumeric only"}
					/>
				</Fragment>
			</Row>
		</Tab.Pane>
	);
}
export default VinSearch;
