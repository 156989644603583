import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  getMakes,
  getModels,
  getEngines,
  getAllChemicalCategories,
  getAllPartCategories,
  getAllPartCategoryGroups,
  getAllPropackGroups,
  getAllCheckoutItems
} from "./simplehomepage.service";

export const getMakesForYear = createAsyncThunk(
  "getMakesForYear",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getMakes(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getModelsForMake = createAsyncThunk(
  "getModelsForMake",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getModels(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getEnginesForModel = createAsyncThunk(
  "getEnginesForModel",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getEngines(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getChemicalCategories = createAsyncThunk(
  "getChemicalCategories",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getAllChemicalCategories(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getPropackGroups = createAsyncThunk(
  "getPropackGroups",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getAllPropackGroups(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getPartCategories = createAsyncThunk(
  "getPartCategories",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getAllPartCategories(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getPartCategoryGroups = createAsyncThunk(
  "getPartCategoryGroups",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getAllPartCategoryGroups(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getCheckoutItems = createAsyncThunk(
  "getCheckoutItems",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getAllCheckoutItems(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

