import httpClient from "../../../services/http";
import { buildQueryUrl } from "../../../helpers/utils";

export const getAllFailedOrders = (data) =>
  httpClient.get(`order-service/orders/failed?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const retryFailedOrders = (data) =>
  httpClient.get(`order-service/orders/retry?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const updateFailedOrder = (data) =>
  httpClient.put(`order-service/orders/failed`, data);

export default httpClient;
