
import React, { useEffect, useState } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Locations from "../../Locations";
import {OverlayTrigger,Tooltip,Dropdown, Modal,DropdownButton} from 'react-bootstrap';
import XModal from "../../shared/XModal";
import  DropShipHeader  from '../../shared/DropShipHeader';
import {getUserShipInfo} from '../../../helpers/utils';
import { useSelector } from "react-redux";
import { FormControl, Link, MenuItem} from "@mui/material";
import Select from '@mui/material/Select';
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

function PickupDelivery({ handleViewTypeChange, storeSellerId }) {
  const storeDetails = useSelector((state) => state.mygarage.storeDetails);
  const user = useSelector((state) => state.user);
  const userDetails = useSelector((state) => state.user.userDetails);
  const customerShipMethod = user.userDetails.shipTos && user.userDetails.shipTos.length > 0 && user.userDetails.shipTos[0].shipMethod && user.userDetails.shipTos[0].shipMethod ? user.userDetails.shipTos[0].shipMethod : ""
  const checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  let enableDelivery = (checkoutDetails && checkoutDetails.length > 0) ? ((checkoutDetails[0].shipMethod == "0002") ? false : true) : (userDetails && userDetails.shipTos && userDetails.shipTos.length > 0 && userDetails.shipTos[0].shipMethod && userDetails.shipTos[0].shipMethod && (userDetails.shipTos[0].shipMethod === "0002") ? false : true);
  const [selectedView, setSelectedView] = useState(enableDelivery);
  const [pickupLocation, setPickupLocation] = useState(null);
  const [showRegionLocationsPopup, setShowRegionLocationsPopup] = useState(false);
  const [selectedStoreName, setSelectedStoreName] = useState(null);
  const [showLocationsPopup, setShowLocationsPopup] = useState(false);
  const [storeSelection, setStoreSelection] = useState('');
  const [store, setStore] = useState(userDetails && userDetails.shipTos && userDetails.shipTos[0] &&
    userDetails.shipTos[0].location && userDetails.shipTos[0].location.storeNumber ? userDetails.shipTos[0].location.storeNumber : "");

  let filteredStore = [];
  if ((!_isEmpty(storeDetails))) {
    filteredStore = selectedView ? [] : _cloneDeep(storeDetails);
  }
  useEffect(() => {
    if (pickupLocation) {
      !_isEmpty(storeDetails) && storeDetails.map(value => {
        if (value.storeNum === pickupLocation.toString()) {
          setSelectedStoreName(value.storeName);
          setStoreSelection(value.sellerPartID);
        }
      })
    }
  }, [])
  useEffect(() => {
    if (checkoutDetails && checkoutDetails.length > 0) {
      setPickupLocation(checkoutDetails[0]?.store);
    } else if (!_isEmpty(userDetails)) {
      setPickupLocation(getUserShipInfo(userDetails).storeNumber);
    }
  }, [userDetails, checkoutDetails])

  let defaultSelectedStoreName =
    userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeName
      ? userDetails.shipTos[0].location.storeName
      : "Select Store";
  let defaultSelectedSeller =
    userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.sellerPartnerId
      ? userDetails.shipTos[0].location.sellerPartnerId
      : "Select Store";
  let sellerNetwork =
      userDetails &&
        userDetails.shipTos &&
        userDetails.shipTos[0] &&
        userDetails.shipTos[0].location &&
        userDetails.shipTos[0].location.sellerNetwork
        ? userDetails.shipTos[0].location.sellerNetwork
        : "";

  useEffect(() => {
    if (!_isEmpty(storeDetails) && !_isEmpty(userDetails) && !_isEmpty(userDetails.shipTos) && !_isEmpty(userDetails.shipTos[0])) {
      let defaultStoreValue = getUserShipInfo(userDetails).storeNumber;
      let storeNum = checkoutDetails.length > 0 ? checkoutDetails[0].store : defaultStoreValue;
      storeDetails.map((v) => {
        if (v.storeNum == storeNum) {
         setSelectedStoreName(v.storeName)
         setStoreSelection(v.sellerPartID)
        }
      });
    }
    let enableDelivery = (checkoutDetails && checkoutDetails.length > 0) ? ((checkoutDetails[0].shipMethod == "0002") ? false : true) : (userDetails && userDetails.shipTos && userDetails.shipTos.length > 0 && userDetails.shipTos[0].shipMethod && userDetails.shipTos[0].shipMethod && (userDetails.shipTos[0].shipMethod === "0002") ? false : true);
    setSelectedView(enableDelivery);
  }, [checkoutDetails, storeDetails]);


  const handleByStore = (event, data, filteredStore) => {
    let {value, children} = data.props;
    let storeInfo = _find(filteredStore, (storeData) => ((storeData.sellerPartID === value) ));
    setStore(storeInfo.storeNum);
    setPickupLocation(storeInfo.storeNum);
    setSelectedStoreName(children);
    setStoreSelection(value)
  };

  const handleButtonChange = (e) => {
    setSelectedStoreName(defaultSelectedStoreName)
    setStoreSelection(defaultSelectedSeller)
  };


  return (
      <div className='shipping_option_dropdown'>{sellerNetwork !== 'ECOM' ? (<div className="ship-info-header store-filter store-margin maroonColorTxt ">
      {customerShipMethod && customerShipMethod !== '0002' && (
        <div className="radio-wrapper">
          <FormControl>
            <RadioGroup
              onChange={(e) => {
                setSelectedView(!selectedView);
                handleButtonChange(e)
                handleViewTypeChange(e.currentTarget.defaultValue);
              }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="delivery"
                checked={selectedView}
                control={<Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}/>}
                label={<span style={{ fontSize: '14px', color: '#9d1d20' }}>Delivery</span>}
              />
              <FormControlLabel
                value="isPickup"
                checked={!selectedView}
                control={<Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}/>}
                label={<span style={{ fontSize: '14px', color: '#9d1d20' }}>Pickup</span>}
              />
            </RadioGroup>
          </FormControl>
        </div>
      )}
      <div></div>
      {filteredStore && filteredStore.length > 1 && (
        <div className="">
          {customerShipMethod == '0002' ? (<div className='pickup-locations'>Pick Up Locations: </div>) : <div className='pickup-locations'>Locations:</div>}
          <OverlayTrigger
            placement="right"
            delay={{ show: 100, hide: 400 }}
            overlay={
              <Tooltip id="pickup-location">
                You can {selectedView ? "deliver" : "pick up"} your parts from a different store than your primary store. Click the drop down to select a different {enableDelivery ? "store" : "pick up location"}
              </Tooltip>
            }
          >
            <FormControl sx={{ m: 2, minWidth: 120 , fontSize: 14}} size="small">
              <Select
                value={storeSelection}
                onChange={(e, data) => {
                  handleByStore(e, data, filteredStore);
                  storeSellerId(data.props.value, filteredStore);
                }}
                sx={{fontSize:'14px', color: '#9d1d20'}}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {filteredStore.map((store, i) => {
                  return (
                    <MenuItem sx={{fontSize:'14px', color: '#9d1d20'}} value={store.sellerPartID} key={store.label}>
                      {store.storeName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </OverlayTrigger>
        </div>)}
      {(!selectedView && !_isEmpty(storeDetails) && storeDetails.length > 1 ) && (
        <>
        <OverlayTrigger
          placement="right"
          delay={{ show: 100, hide: 400 }}
          overlay={
            <Tooltip id="selected-location">
              {`Click this to locate the selected Store in Map`}
            </Tooltip>
          }
        >
          <LocationOnIcon className="cursor-pointer stockcheck-location" onClick={() => {
            setShowLocationsPopup(true);
          }} />
        </OverlayTrigger>
              <OverlayTrigger
              placement="right"
              delay={{ show: 100, hide: 400 }}
              overlay={
                <Tooltip id="selected-location">
                  {`Click this to locate all the stores in the region of selected store in Map`}
                </Tooltip>
              }
            >
              <LocationCityIcon className="cursor-pointer stockcheck-location" onClick={() => {
                setShowRegionLocationsPopup(true);
              }} />
            </OverlayTrigger>
            </>
        )}

      {(showLocationsPopup || showRegionLocationsPopup) && (
        <XModal
          show={showLocationsPopup || showRegionLocationsPopup}
          showButtons={false}
          title={`Store Location - Store # ${selectedStoreName}`}
          customHeader={
            <React.Fragment>
              <Modal.Title id="warning-modal-title" className="pull-left">
                Store Location - Store # {selectedStoreName}
              </Modal.Title>
              <div
                className="close-btn"
                onClick={() => { setShowLocationsPopup(false); setShowRegionLocationsPopup(false); }}
              >
                <span className="material-icons">close</span>
              </div>
            </React.Fragment>
          }
          handleClose={() => { setShowLocationsPopup(false); setShowRegionLocationsPopup(false); }}
        >
          <Locations allCityStores={showRegionLocationsPopup ? true : false}  selectedStore={pickupLocation.toString()} />
        </XModal>
      )}

    </div>) :
    (<DropShipHeader
      dropShipInputChange={handleViewTypeChange}
      dropShipStoreChange={storeSellerId}
    />)}</div>

  );
}
export default PickupDelivery;
