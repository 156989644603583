const user = (
  state = { userDetails: { userSecurityGroup: {}, shipTos: [{}], billTo: {} },
  companyData: [],
  customerTerms: null,
  showPaymentLink: false,
  isDSW: false,
},
  action
) => {
  let newState = {
    ...state,
  };
  switch (action.type) {
    case "USER_CREATION_COMPLETE":
      newState = {
        ...state,
        userCreationApiCall: action.payload.userCreationApiCall,
        messageFromAPi: action.payload.message,
        userCreationSuccess: action.payload.userCreationSuccess,
      };
      break;
      case "PASSWORD_RESET_COMPLETE":
        newState = {
          ...state,
          passwordCreationApiCall: action.payload.passwordCreationApiCall,
          messageFromAPi: action.payload.message,
          passwordCreationSuccess: action.payload.passwordCreationSuccess,
        };
        break;
    case "USER_CREATION_API_CALL":
      newState = {
        ...state,
        userCreationApiCall: action.payload.userCreationApiCall,
      };
      break;
      case "CUSTOMER_CREATION_API_CALL":
        newState = {
          ...state,
          customerCreationApiCall: action.payload.customerCreationApiCall,
        };
        break;
      case "CUSTOMER_CREATION_COMPLETE":
        newState = {
          ...state,
          customerCreationApiCall: action.payload.customerCreationApiCall,
          messageFromAPi: action.payload.message,
          userCreationSuccess: action.payload.userCreationSuccess,
        };
        break;
    case "SHIPTO_REFRESH_COMPLETE":
      newState = {
        ...state,
        shipToRefreshApiCall: action.payload.shipToRefreshApiCall,
        messageFromAPi: action.payload.message,
        shipToRefreshSuccess: action.payload.shipToRefreshSuccess,
      };
      break;
    case "SHIPTO_LOCATION_COMPLETE":
      newState = {
        ...state,
        shipToLocationApiCall: action.payload.shipToLocationApiCall,
        messageFromAPi: action.payload.message,
        shipToLocationSuccess: action.payload.shipToLocationSuccess,
      };
      break;
    case "PROMOTIONS_FOR_USER":
      newState = {
        ...state,
        userPromotionDetails: action.payload.promotionsData,
      };
      break;
    case "USER_DETAILS_FETCHED":
      newState = {
        ...state,
        userDetails: action.payload.userDetails,
      };
      break;
    case "PRO_PACKS_FETCHED":
      newState = {
        ...state,
        proPacksData: Object.assign(
          [],
          state.proPacksData,
          action.payload.proPacksData
        ),
      };
      break;
      case "SET_USER_DSW":
        newState = {
          ...state,
          isDSW: action.payload,
        };
        break;
    default:
      break;
  }
  return newState;
};
export default user;
