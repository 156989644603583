import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useSelector } from 'react-redux';

const WarrantyDatePicker = (props) => {
	let {
		part,
		onchangeDate,
		DRIVER_DELIVERED,
		userType,
		STORE_USER,
		CC_MANAGER,
		AREA_MANAGER,
		setLineItemRows,
		LineItemsRows,
		warrantyUpdatedData,
		SetWarrantyUpdatedData,
		SetLineItemEditedData
	} = props;

	const parts = useSelector((state) => state.parts);

	const [ value, setValue ] = useState(part.purchaseDate);
	const calendarRef = React.useRef();

	useEffect(() => {
		let date;
		let updatedDetails =
			warrantyUpdatedData &&
			warrantyUpdatedData.map((item) => {
				if (item.rowId === part.rowId) {
					date = item.label === 'Unlinked' ? item.purchaseDate : item.invoiceDate;
					return { ...item, invoiceDate: item.label === 'Unlinked' ? item.purchaseDate : item.invoiceDate };
				} else {
					return { ...item };
				}
			});
		setValue(date);
		SetWarrantyUpdatedData(updatedDetails);
		SetLineItemEditedData(updatedDetails);
	}, []);

	const handleDateChange = (date) => {
		let updatedDetails =
			warrantyUpdatedData &&
			warrantyUpdatedData.map((item) => {
				if (item.rowId === part.rowId) {
					return { ...item, invoiceDate: item.label === 'Unlinked' ? item.purchaseDate : date };
				} else {
					return { ...item };
				}
			});
		setValue(date);
		SetWarrantyUpdatedData(updatedDetails);
		SetLineItemEditedData(updatedDetails);
		onchangeDate(date, part);
	};

	const isValidUser = (line) => {
			if (line.returnType === 'Warranty' && userType === STORE_USER || CC_MANAGER || AREA_MANAGER) {			if (line.lineStatus === DRIVER_DELIVERED || line.lineStatus === 'NEW_ITEM') {
				return true;
			}
		} else return false;
	};
	const handlePlaceHolder = (line) => {
		if (line.lineStatus === DRIVER_DELIVERED || line.lineStatus === 'NEW_ITEM') {
			if (line.returnType !== 'Warranty') {
				return true;
			} else if (line.returnType === 'Warranty') {
				return false;
			}
			return true;
		} else return true;
	};
	return (
		<span className="warrantyDatediv">
			<Row>
				<Col>
					<DatePicker
						ref={calendarRef}
						selected={
							part.returnType === 'Warranty' ? !_.isEmpty(part.purchaseDate) ? (
								new Date(part.purchaseDate)
							) : (
								''
							) : (
								''
							)
						}
						value={part.returnType === 'Warranty' ? value : ''}
						disabled={isValidUser(part) ? false : true}
						placeholderText={handlePlaceHolder(part) ? ' ' : 'Select Date'}
						dateFormat="MM/dd/yyyy"
						onChange={handleDateChange}
						className={
							isValidUser(part) ? 'form-control date-width' : ' form-control date-width disabled_inputBox'
						}
					/>
				</Col>
				<Col>
					<span onClick={() => calendarRef.current.setOpen(true)}>
						<label className="date1">
							<i className="fa fa-calendar-minus-o  date-picker" aria-hidden="true" />
						</label>
					</span>
				</Col>
			</Row>
		</span>
	);
};

export default WarrantyDatePicker;
