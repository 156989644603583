import React from "react";
import { Container, Row, Form } from "react-bootstrap";
import { Button, FormControl, InputLabel, OutlinedInput } from "@mui/material";

import FormValidationErrorMessage from "../shared/FormValidationErrorMessage/FormValidationErrorMessage";

function ForgotPassword(props) {
  const {
    handleInputChange,
    handleInputBlur,
    username,
    handleFormSubmit,
    goBackToLogin,
    validity,
  } = props;
  return (
    <Container fluid className="login_container_forget">
      <Row className="login_title">
        <span>Let’s Get Started!</span>
      </Row>
      <Row className="login_box">
        <span>Forgot Password?</span>
        <div>
          <Form className="login_form" onSubmit={handleFormSubmit}>
            <div className="returning_customer_container">
              <div className="input_fields">
                <FormControl sx={{ my: 3 }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="username">Your User ID*</InputLabel>
                  <OutlinedInput
                    autoFocus={true}
                    error={!validity.isConfirmationNotNull}
                    id="username"
                    name="username"
                    label="Your User ID*"
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    helperText={
                      !validity.isConfirmationNotNull &&
                      "Please enter your Confirmation Code"
                    }
                  />
                  <FormValidationErrorMessage
                    condition={!validity.isConfirmationNotNull}
                    errorMessage="Please enter your Confirmation Code"
                  />
                </FormControl>

                <FormValidationErrorMessage
                  condition={!validity.isUserNameNotNull}
                  errorMessage="Please enter your Username"
                />
                <FormValidationErrorMessage
                  condition={
                    !validity.isUserNameLengthBelowLimit &&
                    validity.isUserNameNotNull
                  }
                  errorMessage="Username should not exceed above 50 characters"
                />
                <FormValidationErrorMessage
                  condition={
                    !validity.isUserNameMinLength && validity.isUserNameNotNull
                  }
                  errorMessage="Username should have atleast 7 characters"
                />
                <FormValidationErrorMessage
                  condition={
                    !validity.isUserNameValidFormat &&
                    validity.isUserNameNotNull &&
                    validity.isUserNameMinLength
                  }
                  errorMessage="Username must be in abc@def.com format"
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
              >
                Send code
              </Button>
              <div className="go-back-link" onClick={goBackToLogin}>
                Go back?{" "}
                <span title="Click here" style={{ cursor: "pointer" }}>
                  Click here
                </span>
              </div>
            </div>
          </Form>
        </div>
      </Row>
    </Container>
  );
}
export default ForgotPassword;
