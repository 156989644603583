import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, Form, Col } from "react-bootstrap";
import XModal from '../../shared/XModal';
import _ from "lodash";
import _isEmpty from 'lodash/isEmpty';
import { triggerCustomUxCriticalError } from '../../../actions/error';
import { createOrUpdateStoreUser } from "../storeDashboard.thunks";

const StoreManualProcessPostData = (props) => {
  let {
    showManalModal,
    setShowManualModal,
    setManualProcessId,
    ManualProcessId,
    displayErrorMsg,
    handleCloseMethod,
    clearHeaderId,
    prepareData,
  } = props;

  const parts = useSelector((state) => state.parts);
  let manualProcessData = _.cloneDeep(parts.manualProcessData);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [comment, setComment] = useState('');
  const [refValue, setRefValue] = useState('');

  const handleCloseAction = () => {
    setShowManualModal(false);
    setLoader(false);
    setManualProcessId("");
    setRefValue("");
    setComment("");
    handleCloseMethod();
    clearPayloadData();
  }

  const handleRefChange = (e) => {
    setRefValue(e.target.value);
  }
  const clearPayloadData = () => {
    dispatch({
      payload: {
        manualProcessData: [],
      },
      type: 'STORE_MANUAL_PROCESS',
    });
  }
  const handleManulProcess = () => {
    setLoader(true);
    let returnPayload = [];
    returnPayload = {
      return_id: ManualProcessId,
      action: "manualProcess",
      payload: {
        lineItems: manualProcessData,
        refNumber: refValue ? refValue : "",
        comment: comment ? comment : ""
      }
    }
    dispatch(createOrUpdateStoreUser(returnPayload)).then((action) => {
      let statusMessageProps = {
        heading: "",
        message: "",
        type: "",
      };
      if (action.type === "createOrUpdateStoreUser/fulfilled") {
        statusMessageProps.heading = 'Credit Memo Processed successfully';
        statusMessageProps.type = 'success';
        triggerCustomUxCriticalError(statusMessageProps, dispatch);
        clearPayloadData();
        handleCloseMethod();
        handleCloseAction();
      } else {
        displayErrorMsg();
        handleCloseAction();
        prepareData();
        clearHeaderId();
        clearPayloadData();
      }
    })
  }
  return (
    <XModal
      show={showManalModal}
      showButtons={false}
      title="Credit Memo Processed Manually"
      className="change_store_manual_processingModal"
      favoriteTextValidity={true}
      submitType={true}
      handleAction={() => handleCloseAction()}
      handleClose={() => handleCloseAction()}
      submitButtonText="OK"
    >
      <div className="change_shipTo_modal_heading">
        <span className="escalate-textarea-msg">
          This Return transaction cannot be posted by Rapid because of <span className="marron_text">Vision default shipTo</span> configuration.
          Please process the Credit Memo manually in Vision and enter reference # or comments below so that
          Rapid can reflect a status of <span className="marron_text">POSTED</span> for this return transaction.
        </span>
        <Col >
          <Form.Group className="label-ref-container">
            <Form.Label
              style={{
                width: "177px",
                fontSize: "17px",
                fontWeight: "400",
              }}
            >
              Reference # :
            </Form.Label>
            <Form.Control
              maxLength="150"
              className="input_textboxwidth input__return"
              type="text"
              name="po"
              value={refValue}
              onChange={handleRefChange}
            />
          </Form.Group></Col>
        <span className="escalate-textarea-msg">
          Add your comments in below text box and click Yes
        </span>
        <div className="stock_check_textarea">
          <textarea
            id="textArea"
            rows="4"
            cols="45"
            maxlength="1000"
            style={{ overflowY: "scroll", height: "130px", width: "95%" }}
            onChange={(event) => setComment(event.target.value)}
            value={comment}
          ></textarea>
        </div>
      </div>
      <div className="shipTo_modal_button_padding">
        <span className="manual_process_button">
          {loader ?
            <Button
              className="store_button"
              variant="primary"
              disabled
            > <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
              Loading..
            </Button> :
            <Button
              className="store_savePost_button"
              disabled={_isEmpty(comment) && _isEmpty(refValue)}
              onClick={() => handleManulProcess()}
            >
              YES
            </Button>}
        </span>
      </div>
    </XModal>

  )
}
export default StoreManualProcessPostData;