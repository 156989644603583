import React from 'react';
import './styles.scss';


const ItemsRender = (props) => {

    const { items } = props;
    return (
        <div className='item'>
            {items &&
                items
                    .filter(
                        (item) =>
                            item.value !== "undefined" &&
                            item.value !== "null" &&
                            item.value !== "$NaN"
                    )
                    .map((item,index) => {
                        return (
                            <div className='containerCard'>
                                <div className='priceLabel'>
                                    {item.title}
                                </div>
                                <div className='price'>
                                    {item.value}
                                </div>
                            </div>
                        );
                    })}
        </div>
    );
};

export default ItemsRender;
