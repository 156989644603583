import React from 'react';
import { Container, Row } from 'react-bootstrap';

const PlaceHolderImage = () => <div className="gradient image_loader"></div>;

const PlaceholderHeading = () => (
	<div className="gradient heading_loader">
		<span></span>
	</div>
);
const PlaceholerTitle = () => (
	<div className="gradient title_loader">
		<span></span>
	</div>
);
const PlaceHolderCard = () => (
	<Container className="loading_container">
		<div className="loading_box">
			<Row>
				<PlaceHolderImage />
				<div>
					<PlaceholderHeading />
					<PlaceholerTitle />
				</div>
			</Row>
			<Row>
				<div className="description_loader">
					<PlaceholderLine />
					<PlaceholderLine />
					<PlaceholderLine />
					<PlaceholderLine />
				</div>
			</Row>
		</div>
	</Container>
);

const PlaceholderLine = () => <p className="gradient"></p>;
export {
    PlaceHolderCard,
	PlaceHolderImage,
	PlaceholderHeading,
	PlaceholerTitle,
	PlaceholderLine,
};
