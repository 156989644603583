import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPartList,
  getPartListFromFile,
  getEpartsExpert,
  getOrderNumberFromMfgCode,
  getC2CLink,
  addItemsToCart,
  getBuyersGuideListFromPart,
  getMagicalWords,
  getVideoArticleLinks,
  getStockInterchange
} from "./stockcheck.service";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";

export const getStockProductListFromSkuNumber = createAsyncThunk(
  "getStockProductListFromSkuNumber",
  async (payload) => {
    const response = await getPartList(payload.request);
    let modifiedResponse = {
      partNumber: payload.request.requestItems[0].partNum,
      responseData: response.data,
      skuIndex: payload.skuIndex,
      partsNotFoundData :response.data,
    };
    const responseArr = [];
    const partsNotFoundData =[];
    if (!_isEmpty(response) && !_isEmpty(response.data)) {
      response.data.map((product) => {
        if (
          product.status === "8" ||
          product.status === "1" ||
          product.status === "6" ||
          product.status === "3" 
        ) {
          responseArr.push(product);
        }else if(product.status === "2"){
          partsNotFoundData.push(product);
        }
      });
    }
    if (!_isEmpty(responseArr) && responseArr.length === 1) {
      const fetchProductData = await getSingleProductDetails(
        payload,
        responseArr[0]
      );
      modifiedResponse = {
        ...modifiedResponse,
        singleProductDetails: fetchProductData,
      };
    }
    modifiedResponse.responseData = responseArr;
    return modifiedResponse;
  }
);



export const getSingleProductDetails = async (requestPayload, responseData) => {
  let response = {
    ...responseData,
  };
  let bdData = [];
  let c2cData = [];
  let orderNumber = {};
  let hasC2Clink = false;
  let hasBuyersData = false;
  let hasOrderNumber = false;
  let updatedAvailibity = [];
  let updatedQty = "1";
  if (!_isEmpty(response.availability)) {
    response.availability.map((availibility) => {
      if (availibility.availableQty > 0) {
        updatedAvailibity.push({
          ...availibility,
          orderQty: !_isEmpty(requestPayload.request.requestItems[0].qty)
            ? requestPayload.request.requestItems[0].qty
            : "1",
        });
      }
    });
  }
  const orderPayload = {
    partNumber: requestPayload.request.requestItems[0].partNum,
    lineCode: response.mfgCode, //requestPayload.request.requestItems[0].mfgCode,
    dispatch: requestPayload.request.dispatch,
  };
  const c2cPayload = {
    partNum: requestPayload.request.requestItems[0].partNum,
    mfgCode: response.mfgCode, //requestPayload.request.requestItems[0].mfgCode,
    dispatch: requestPayload.request.dispatch,
  };
  c2cData = await getC2CLink(c2cPayload);
  orderNumber = await getOrderNumberFromMfgCode(orderPayload);
  if (!_isEmpty(c2cData.data)) {
    hasC2Clink = true;
  }
  if (!_isEmpty(orderNumber.data)) {
    hasOrderNumber = true;
    const buyersgpayload = {
      partNumber: requestPayload.request.requestItems[0].partNum,
      mfgrsName: orderNumber.data[0].manufacturer,
      dispatch: requestPayload.request.dispatch,
    };
    bdData = await getBuyersGuideListFromPart(buyersgpayload);
    if (!_isEmpty(bdData.data)) {
      hasBuyersData = true;
    }
  }
  if (_isEmpty(updatedAvailibity)) {
    updatedQty = "0";
  } else if (!_isEmpty(requestPayload.request.requestItems[0].qty)) {
    updatedQty = requestPayload.request.requestItems[0].qty;
  }
  response = {
    ...response,
    qty: updatedQty,
    smartLink: hasC2Clink ? c2cData.data.c2cURL : "",
    availability: updatedAvailibity,
    addToOrder: !_isEmpty(response.availability),
    isEpartExpert: hasBuyersData,
    manufacturer: hasOrderNumber ? orderNumber.data[0].manufacturer : "",
    orderId: hasOrderNumber ? orderNumber.data[0].orderNumber : "",
    partType: hasBuyersData ? bdData.data.partGroup[0].name : "",
    partDescription: hasBuyersData
      ? bdData.data.partGroup[0].partDescriptions[0].name
      : "",
    uniqId: requestPayload.uniqId,
  };
  return response;
};

export const uploadPartList = createAsyncThunk(
  "uploadPartList",
  async (payload) => {
    const response = await getPartListFromFile(payload);
    return response.data;
  }
);

export const videoArticleLinks = createAsyncThunk(
  "videoArticleLinks",
  async (payload) => {
    const response = await getVideoArticleLinks(payload);
    return response.data;
  }
);

// export const getEpartsExpertList = createAsyncThunk(
//     "getEpartsExpertList",
//     async (payload) => {
//         const response = await getEpartsExpert(payload);
//         return response.data;
//     }
// );

export const getOrderNumber = createAsyncThunk(
  "getOrderNumber",
  async (payload) => {
    const response = await getOrderNumberFromMfgCode(payload.orderPayload);
    let bdData = [];
    let c2cData = [];
    let returnObj = {
      [payload.selectedValue]: {},
    };
    if (!_isEmpty(response.data)) {
      const bgpayload = {
        partNumber: payload.orderPayload.partNumber,
        mfgrsName: response.data[0].manufacturer,
        dispatch: payload.dispatch,
      };
      bdData = await getBuyersGuideListFromPart(bgpayload);
    }
    const c2cPayload = {
      partNum: payload.orderPayload.partNumber,
      mfgCode: payload.orderPayload.lineCode,
      dispatch: payload.dispatch,
    };
    c2cData = await getC2CLink(c2cPayload);
    if (
      (!_isEmpty(c2cData) && !_isEmpty(c2cData.data)) ||
      (!_isEmpty(bdData) && !_isEmpty(bdData.data))
    ) {
      returnObj[payload.selectedValue] = {
        url:
          !_isEmpty(c2cData) && !_isEmpty(c2cData.data)
            ? c2cData.data.c2cURL.replace("http", "https")
            : "",
        orderId: !_isEmpty(response.data) ? response.data[0].orderNumber : "",
        manufacturer: !_isEmpty(response.data)
          ? response.data[0].manufacturer
          : "",
        partDescription: !_isEmpty(bdData) ? bdData.data.partGroup[0].name : "",
        partType: !_isEmpty(bdData)
          ? bdData.data.partGroup[0].partDescriptions[0].name
          : "",
      };
    }
    return returnObj;
  }
);

// export const addToCart = createAsyncThunk(
//     "addToCart",
//     async (payload) => {
//         const response = await addItemsToCart(payload);
//         return response.data;
//     }
// );

//magical word

export const getMagicalWordsList = createAsyncThunk(
  "getMagicalWordsList",
  async (payload) => {
    const response = await getMagicalWords();
    return response.data;
  }
);

export const stockCheckInterchange = createAsyncThunk(
  "stockCheckInterchange",
  async (payload) => {
    const response = await getStockInterchange(payload);
    return response.data;
  }
);
