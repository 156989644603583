import React, {useEffect, useState} from 'react';
import { Row, Col , OverlayTrigger,Tooltip } from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { formatAmount, timeInUTC, numbersOnlyRegExp, formatTimeAvb } from "../../helpers/utils";
import { getPartsByMultiGroupOrchestrator, getChemPartByGrp } from '../../actions/parts';
import { setVehicleSelected } from '../shared/MyGarage/mygarage.slice';
import { setVehicles } from '../shared/MyGarage/mygarage.thunks';

import deleteIcon from '../../static/images/deleteIcon.png';
import shopProduct from '../../static/images/shop_similar_products.png';
import FormValidationErrorMessage from '../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import PricingIcons from '../Listing/PricingIcons';

function CartSinglePart(props) {
	let {
		partDetails,
		handleDeleteItem,
		handleInputOnBlur,
		handleInputOnChange,
		hideCostPrice,
		previousPartDetails,
		qtyValidity,
		modifiedDate
	} = props;
	const history = useHistory();
	const dispatch = useDispatch();
	const userDetails = useSelector(state => state.user.userDetails);
	const [defaultStore, setDefaultStore] = useState("")

	const handleSKUClick = (e, part) => {
		const {
			yearId,
			makeId,
			make,
			model,
			engine,
			modelId,
			engineId,
			groupId,
			categoryId,
			partType,
		} = part;
		if (partType === 'Regular' && !!categoryId && !!groupId) {
			let selectedGroup = { categoryId, code: Number(groupId), name: '', engineCode: 'Y' };
			dispatch({
				payload: {
					selectedGroupDetails: [selectedGroup],
				},
				type: 'GROUP_SELECTED',
			});
			dispatch({
				payload: {
					selectedVehicle: {
						yearId,
						makeId,
						modelId,
						engineId,
						year: yearId,
						make,
						model,
						engine,
					},
				},
				type: 'VEHICLE_SELECTION_COMPLETED',
			});
			const { x2userName, shipTos, billTo } =
				userDetails || {};
			const payload = {
				createdBy: x2userName,
				createdDate: timeInUTC(),
				customerId: billTo.billToId,
				engine: engine ? engine.trim() : '',
				engineId,
				favoriteFlag: 'N',
				favoriteText: '',
				favoriteUpdatedDate: '',
				lastOrderedDate: '',
				make: make ? make.trim() : '',
				makeId,
				model: model ? model.trim() : '',
				modelId,
				orderedFlag: 'N',
				searchId: '',
				shipToId: shipTos[0].shipToId,
				updatedBy: x2userName,
				updatedDate: timeInUTC(),
				year: yearId ? yearId.trim() : '',
			};
			dispatch(setVehicles(payload));
			dispatch(setVehicleSelected(payload));
			let reqObj = {
				requestItems: [
					{
						groupCode: groupId,
						specificCondition: [],
					},
				],
			};
			dispatch(getPartsByMultiGroupOrchestrator(yearId, makeId, modelId, engineId, reqObj));
			history.push('/product-list');
		} else if (partType === 'Chemical' && !!groupId) {
			dispatch(getChemPartByGrp([groupId]));
			history.push('/supplies-list');

		}
	}

	useEffect(() => {
		let localStore = (userDetails && userDetails.shipTos && userDetails.shipTos[0] &&
			userDetails.shipTos[0].location && userDetails.shipTos[0].location.storeNumber ? "#" + userDetails.shipTos[0].location.storeNumber + " - " + userDetails.shipTos[0].location.storeName : "")
		setDefaultStore(localStore);
	}, [userDetails])

	const handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
		return (code !== 8 && code !== 9 && code !== 37 && code !== 38 && code !== 39 && code !== 40 && code !== 46);
	}

	const handleKeyDown = (event) => {
		if ((numbersOnlyRegExp(event.key) === false || (partDetails.qty && partDetails.qty.length >= 4)) && handleOtherKeys(event.keyCode)) {
			event.preventDefault();
		}
	}

	return (
		<div className="white_box">
			{!_isEmpty(previousPartDetails) && (previousPartDetails.vehicleInfo === partDetails.vehicleInfo) &&
				previousPartDetails.availabilityHour ===
				partDetails.availabilityHour ? null : (
				<div className="within_hour_container">
					{/* {partDetails.availabilityHour > 0 ? (
						<span>Within {partDetails.availabilityHour} Hour</span>
					)  */}
					{/* below added for tph stores changes above commented may need to added back later  */}
					{partDetails.availabilityHour === "Your Store" ? (
						<span>{partDetails.availabilityHour}&nbsp;{defaultStore}</span>
					) 
					: (
						<React.Fragment>
							{/* {partDetails.availabilityHour !== "Immediately" ?
								((formatTimeAvb(partDetails.availabilityHour) === 'Next Day') || (formatTimeAvb(partDetails.availabilityHour) === 'Same Day') ? <span>Within {formatTimeAvb(partDetails.availabilityHour)}</span> : (<span>Within {formatTimeAvb(partDetails.availabilityHour)}</span>))
								:
								<span>{formatTimeAvb(partDetails.availabilityHour)}</span>
							} */}
							{/* added for tph stores changes above commented need to added back later  */}
							{partDetails.availabilityHour ?
								((formatTimeAvb(partDetails.availabilityHour) === 'Next Business Day') || (formatTimeAvb(partDetails.availabilityHour) === 'Stores in Your Area') || (formatTimeAvb(partDetails.availabilityHour) === 'Next 2 + Business Days') || (formatTimeAvb(partDetails.availabilityHour) === 'Upto 5 Days') || (formatTimeAvb(partDetails.availabilityHour) === 'Next 2 Weeks') || (formatTimeAvb(partDetails.availabilityHour) === 'Direct Order Store') || (formatTimeAvb(partDetails.availabilityHour) === 'Pick Up Store') || (formatTimeAvb(partDetails.availabilityHour) === 'Distribution Centers') ? <span>{formatTimeAvb(partDetails.availabilityHour)}</span> : (<span>{partDetails.availabilityHour && partDetails.availabilityHour === 'Pick Up Store'? 'Store': partDetails.availabilityHour}&nbsp;&nbsp;{partDetails.availabilityHour === 'Store' || partDetails.availabilityHour === 'Pick Up Store' ? (partDetails.store ? partDetails.store : "") : null}</span>))
								:
								<span>{formatTimeAvb(partDetails.availabilityHour)}</span>
							}
						</React.Fragment>
					)}
				</div>
			)}

			<Row className="within_hour_details">
				<img
					src={deleteIcon}
					className="delete-icon"
					onClick={(event) => {
						handleDeleteItem(event, partDetails);
					}}
					alt="Delete"
				/>
				<Col lg="3" md="3" sm="3" className="qty_container">
					<div>
						<div className="flText">
							<span>P/L</span>
							<span>{partDetails.mfgCode}</span>
						</div>
						<div className="part_container">
							<span>Part# {partDetails.partNum}</span>
						</div>
					</div>
					<div className="order_qty_avail_qty_container">
						<div className="order_qty_container">
							<span>Order QTY:</span>
							<input
								type="text"
								name="orderQty"
								value={partDetails.qty}
								disabled={partDetails.qtyBuyInc > 1 ? true :false}
								onBlur={(e) => {
									handleInputOnBlur(
										e,
										partDetails,
										partDetails.partNum,
										partDetails.availabilityHour
									);
								}}
								onChange={(e) => {
									handleInputOnChange(
										e,
										partDetails.partNum,
										partDetails.availabilityHour,
										partDetails.vehicleInfo
									);
								}}
								onKeyDown={(event) => { handleKeyDown(event) }}
							/>
						</div>
						<div className="avail_qty_container">
							<span>{` `}Avail QTY: {partDetails.availableQty}</span>
						</div>
					</div>
					{" "}
					{(modifiedDate != " " && modifiedDate && modifiedDate != undefined) ?
					<div className="order_qty_container">
							<span className="cart_date_color">Item added to cart on : {modifiedDate}</span>
						</div>: <span></span>  }
					<FormValidationErrorMessage
						condition={!qtyValidity.isOrderQtynumeric && qtyValidity.isOrderQtyMinLimit}
						errorMessage={"Order quantity must be numeric only"}
					/>
					<FormValidationErrorMessage
						condition={!qtyValidity.isOrderQtyLessThanMaxLimit && qtyValidity.isOrderQtyMinLimit}
						errorMessage={"Order quantity should be less than 4 numbers"}
					/>
					<FormValidationErrorMessage
						condition={!qtyValidity.isOrderQtyMinLimit}
						//errorMessage={"Order quantity should have altleast 1 number"}
					/>
					<FormValidationErrorMessage
						condition={!qtyValidity.isValidAvailableQty && qtyValidity.isOrderQtyLessThanMaxLimit && qtyValidity.isOrderQtynumeric}
						errorMessage={"The Order Quantity should be less than or equal to Available Quantity"}
					/>
					<FormValidationErrorMessage
						condition={!_isEmpty(partDetails.warningMsg)}
						errorMessage={partDetails.warningMsg}
					/>
				</Col>
				<Col lg="3" md="3" sm="3" className="description_pack_qty_container">
					<div className="description_container">
						<span>Description</span>
						{partDetails.groupId && partDetails.vehicleInfo !== 'Non-Vehicle Specific' ?
							<div className="shop-product-wrapper">
								<img
									src={shopProduct}
									className="shop-product-icon"
									onClick={(e) => { handleSKUClick(e, partDetails) }}
									alt="Show"
								/>
								<p className="show-product-text">
									Show similar products
							</p>
							</div> :
							''
						}
						<p className="bold">{partDetails.desc}</p>
					</div>
					<div className="pack_qty_container">
						<span>Pack QTY: {partDetails.packQty}</span>
					</div>
					{Number(partDetails.qtyPerVeh) > 0 && (<div className="pack_qty_container">
						<span>QTY per vehicle: {partDetails.qtyPerVeh}</span>
					</div>)}
					<div className="cart-icons">
						<PricingIcons toolTips={
							JSON.parse(partDetails.toolTip)
							|| []
						} />
					</div>
				</Col>
				<Col lg={hideCostPrice ? "6" : "3"} md="3" sm="3" className="pricing_details_container">
					<div className="pricing_details_text">
						<span className="small_grey_font">Pricing Details</span>
					</div>
					<div className="pricing_details_table">
						<div>
							<span>List Price:</span>
							<span className="maroonColorTxt bold">
								${formatAmount(partDetails.listPrice)}
							</span>
						</div><br></br><br></br>
						<div className={partDetails.costPrice !== partDetails.oldCostPrice ? 'addSpace' : "total_your_price"}>
							<span>Total List Price:</span>
							<span className="maroonColorTxt bold">
								${formatAmount(partDetails.totalListPrice)}
							</span>
						</div>
					</div>
				</Col>
				{
					!hideCostPrice ?
						<Col lg="3" md="3" sm="3" className="total_cost_container">
							<div className="your_total_cost_table">
								<div className={partDetails.costPrice !== partDetails.oldCostPrice && partDetails.costPrice < partDetails.oldCostPrice ? 'strikeIt' : null}>
									<span>Your Price:</span>
									<span className="maroonColorTxt bold">${partDetails.costPrice > partDetails.oldCostPrice ? formatAmount(partDetails.costPrice) : formatAmount(partDetails.oldCostPrice)}</span>
								</div>
								{partDetails.costPrice !== partDetails.oldCostPrice && partDetails.costPrice < partDetails.oldCostPrice ?
									<div className="discount_price">
										<span className="maroonColorTxt bold">${formatAmount(partDetails.costPrice)}</span>
									</div>
									: null}
								<div className="core_price">
									<span> Core Price :</span>
									<span className="maroonColorTxt bold">${ formatAmount(partDetails.corePrice)}</span>
								    </div>
									<div className="total_price">
						<OverlayTrigger
						  placement="bottom"
						  delay={{ show: 100, hide: 400 }}
						  overlay={
						  <Tooltip id="extended-price-tooltip"
						  className="tooltip-description-custom-corePrice">
						 <span className="font-weight-bold">
						 Total Extended Price = Qty * (Your Price + Core Price)
                         </span>
						  </Tooltip>}>
						  <span>Total Extended Price: </span>
						  </OverlayTrigger>
									<span className="maroonColorTxt bold">${formatAmount((partDetails.costPrice * partDetails.qty) + (partDetails.corePrice * partDetails.qty))}</span>
								</div>
							</div>
						</Col>
						: ''
				}
			</Row>
		</div>
	);
}
export default CartSinglePart;
