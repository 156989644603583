import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import XModal from "../XModal";
import { triggerCustomUxCriticalError } from '../../../actions/error';
import { updateToken } from '../../../actions/auth';
import { addItemToCart2, clearCartForStore, saveQuote, saveQuoteForStockOrder } from "../../../actions/cart";
import _every from 'lodash/every';
import _isEmpty from "lodash/isEmpty";
import { setVehicleSelected, setQuoteSelected } from "../../shared/MyGarage/mygarage.slice";
import { impersonateUserBillTo } from '../../User/UserManagement/usermanagement.thunk';
import { getUserShipInfo } from '../../../helpers/utils';
import _cloneDeep from "lodash/cloneDeep";
import { useHistory } from "react-router-dom";

const actionTypes = {
    VEHICLE_SELECTION_COMPLETED: "VEHICLE_SELECTION_COMPLETED",
    PREVIOUS__BILLTO_CHECKOUT_DATA: "PREVIOUS__BILLTO_CHECKOUT_DATA",
    GLOBAL_LOADER: 'GLOBAL_LOADER',
    CART_DETAILS_FETCHED: 'CART_DETAILS_FETCHED',
    CART_CHECKOUT_FETCHED: 'CART_CHECKOUT_FETCHED'

};

const WarningPopup = (props) => {
    let {
        userDetails,
        isBillToEmulation,
        isEmulated,
        defaultBillTo
    } = props;
    const previousBillTocheckoutData = useSelector((state) => state.cart.previousBillTocheckoutData);
    const poDetails = useSelector((state) => state.cart.PODetails);
    const [openDSWWarning, setOpenDSWWarning] = useState(false);
    const [saveQuoteWarning, setSaveQuoteWarning] = useState(false);
    const [defaultRadioCheck, setdefaultRadioCheck] = useState("copytocart");
    const [poData, setpoData] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    let { xlPartAccountNumber } = userDetails;

    useEffect(() => {
        if (isBillToEmulation) {
            if (!_isEmpty(props.checkoutDetails)) {
                setOpenDSWWarning(true);
                // isEmulated(false);
                dispatch({
                    payload: {
                        prevBillToCheckoutData: props.checkoutDetails
                    },
                    type: actionTypes.PREVIOUS__BILLTO_CHECKOUT_DATA,
                });
            } else {
                changeBillTo();
            }
        }
    }, [isBillToEmulation]);

    useEffect(() => {
        if (!_isEmpty(poDetails)) {
            setpoData(poDetails)
        }
    }, [poDetails]);

    useEffect(() => {
        if (!_isEmpty(userDetails) && xlPartAccountNumber === defaultBillTo && isBillToEmulation) {
            isEmulated(false);
            let statusMessageProps = {
                heading: "",
                message: "",
                type: "",
            };
            statusMessageProps.heading = 'BillTo modified successfully';
            statusMessageProps.type = 'success';
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
            dispatch(setVehicleSelected(null));
            dispatch({
                payload: {
                    selectedVehicle: null,
                },
                type: "VEHICLE_SELECTION_COMPLETED",
            });
            if (!_isEmpty(previousBillTocheckoutData) && defaultRadioCheck === 'copytocart') {
                DSWWarningHandler();
                history.push('/');
            } else {
                dispatch({
                    payload: {
                        globalLoader: false
                    },
                    type: actionTypes.GLOBAL_LOADER
                });
                history.push('/');
            }
        }
    }, [userDetails]);

    const handleWarningClose = (e) => {
        setOpenDSWWarning(false)
        isEmulated(false);
    };

    const DSWWarningHandler = () => {
        let cartPayloadItems = [];
        if (!_isEmpty(previousBillTocheckoutData)) {
            let storeNumber = getUserShipInfo(userDetails).storeNumber;
            let shipMethod = getUserShipInfo(userDetails).shipMethod;
            let sellerId = getUserShipInfo(userDetails).sellerPartnerId;
            cartPayloadItems = _cloneDeep(previousBillTocheckoutData);
            cartPayloadItems.map((v) => {
                delete v.lineComment;
                delete v.itemId;
                v.store = storeNumber;
                v.shipMethod = shipMethod;
                v.sellPartnerId = sellerId;
            })
        }
        addItemToCart2(cartPayloadItems, dispatch);
        dispatch({
            payload: {
                prevBillToCheckoutData: []
            },
            type: actionTypes.PREVIOUS__BILLTO_CHECKOUT_DATA,
        });
        setOpenDSWWarning(false);;
    };

    const handleClearCart = () => {
        setOpenDSWWarning(false);;
        clearCartForStore(dispatch).then(res => {
            changeBillTo();
        });
    }

    const handleClickOk = () => {
        setOpenDSWWarning(false)
        if (defaultRadioCheck === 'savequote') {
            handleAutoQuote();
        } else if (defaultRadioCheck === 'deleteandchange' || defaultRadioCheck === 'copytocart') {
            if(defaultRadioCheck === 'deleteandchange') {
                dispatch({
                    payload: {
                        prevBillToCheckoutData: []
                    },
                    type: actionTypes.PREVIOUS__BILLTO_CHECKOUT_DATA,
                });
            }
            handleClearCart();
        }
    }


    const changeBillTo = () => {
        try {
            dispatch({
                payload: {
                    globalLoader: true
                },
                type: actionTypes.GLOBAL_LOADER
            });
            const payload = {
                billTo: defaultBillTo,
                x2UserName: userDetails.x2userName,
                userType: userDetails.userType,
                buyPartnerCreationFlag: true,
                dispatch,
            };
            dispatch(impersonateUserBillTo(payload)).then((action) => {
                if(action.payload && action.payload.errorMessage && !_isEmpty(action.payload.errorMessage)) {
                    dispatch({
                        payload: {
                            globalLoader: false
                        },
                        type: actionTypes.GLOBAL_LOADER
                    });
                    return;
                }
                updateToken(dispatch).then(() => {
                    // let statusMessageProps = {
                    //     heading: "",
                    //     message: "",
                    //     type: "",
                    // };
                    // if (action.payload == "Change BillTo successful") {
                    //     statusMessageProps.heading = 'BillTo modified successfully';
                    //     statusMessageProps.type = 'success';
                    //     triggerCustomUxCriticalError(statusMessageProps, dispatch);
                    //     dispatch(setVehicleSelected(null));
                    //     dispatch({
                    //         payload: {
                    //             selectedVehicle: null,
                    //         },
                    //         type: "VEHICLE_SELECTION_COMPLETED",
                    //     });
                    // }

                })
            })
        } catch (error) {
            dispatch({
                payload: {
                    globalLoader: false
                },
                type: actionTypes.GLOBAL_LOADER
            });
        }
    }

    const handleRadioChange = (event) => {
        setdefaultRadioCheck(event.target.id);
    }

    const handleAutoQuote = (toAddress = 'self') => {
        let { x2userName, mobileNumber } = userDetails;
        dispatch({
            payload: {
                globalLoader: true
            },
            type: actionTypes.GLOBAL_LOADER
        });
        let quoteName = '';
        let poNumber = '';
        let comment = '';
        if (!_isEmpty(poData)) {
            poNumber = poData.poNumber;
            comment = poData.comments;
        }
        let quotePrefix = 'DSW_QUOTE_';
        saveQuoteForStockOrder(
            x2userName,
            quoteName,
            mobileNumber,
            dispatch,
            false,
            toAddress,
            poNumber,
            comment,
            quotePrefix
        ).then((savedQuote) => {
            // setOpenDSWWarning(false);;
            dispatch({
                payload: {
                    globalLoader: false
                },
                type: actionTypes.GLOBAL_LOADER
            });
            dispatch({
                payload: {
                    cartDetails: [],
                },
                type: actionTypes.CART_DETAILS_FETCHED,
            });
            dispatch({
                payload: {
                    checkoutdetails: []
                },
                type: actionTypes.CART_CHECKOUT_FETCHED,
            });
            dispatch({
                payload: {
                    prevBillToCheckoutData: []
                },
                type: actionTypes.PREVIOUS__BILLTO_CHECKOUT_DATA,
            });
            dispatch(setQuoteSelected(''));
            const quoteSaved = {
                heading: `cart is saved as quote`,
                message: '',
                type: 'success'
            };
            triggerCustomUxCriticalError(quoteSaved, dispatch);
            setTimeout(() => {
                changeBillTo();
            }, 100)
        });
    };
    return (<div>
        {openDSWWarning && (
            <XModal
                show={openDSWWarning}
                handleClose={handleWarningClose}
                handleAction={handleClickOk}
                isValidValidity={true}
                yesButtonText="Ok"
                noButtonText="Cancel"
                className="custom_xmodal dsw_warning_modal"
            >
                {/* <div className="text__center">
                Do you want to copy the cart details from {xlPartAccountNumber} to {defaultBillTo} ?
                </div> */}
                <div className="Scrollable-shipTo">
                    <label class="customized-radio-button">
                        <input
                            type="radio"
                            id={'copytocart'}
                            onChange={handleRadioChange}
                            checked={
                                defaultRadioCheck === 'copytocart' ? true : false
                            }
                        />
                        <span>Copy current Cart details to {defaultBillTo} ?</span>
                    </label>
                    <label class="customized-radio-button">
                        <input
                            type="radio"
                            id={'savequote'}
                            onChange={handleRadioChange}
                            checked={
                                defaultRadioCheck === 'savequote' ? true : false
                            }
                        />
                        <span>Save existing Cart as Quote and switch to new Account ?</span>
                    </label>
                    <label class="customized-radio-button">
                        <input
                            type="radio"
                            id={'deleteandchange'}
                            onChange={handleRadioChange}
                            checked={
                                defaultRadioCheck === 'deleteandchange' ? true : false
                            }
                        />
                        <span>Delete existing Cart Details and switch to new Account ?</span>
                    </label>

                </div>
            </XModal>
        )}
    </div>
    );
};

export default WarningPopup;
