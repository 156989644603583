import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import Part from "./Part"
import _ from "lodash";
import { Row, Form, Card, Col, OverlayTrigger, Tooltip, } from "react-bootstrap";
import PartSearch from "./PartSearch";
import TotalParts from "./TotalParts";
import PartTypes from "./PartTypes";
import CircularProgress from "@material-ui/core/CircularProgress";
import PartTable from "./PartTable";
import VehicleMaintenancePopup from "../../../components/shared/VehicleMaintenance";
import FluidsAndFiltersPopup from "../../../components/shared/FluidsAndFilters";
import vehicleMaintIcon from "../../../static/images/vehicleMaintenance.png";
import fluidsAndFiltersIcon from "../../../static/images/fluidsAndFilters.png";

const PartDetails = (props) => {
  const dispatch = useDispatch();
  const parts = useSelector((state) => state.parts);
  const express = useSelector((state) => state.expressCart);
  const [filteredParts, setFilteredParts] = useState([]);
  const [searchingParts, setSearchingParts] = useState(false);
  const [showVehicleMaintenance, setShowVehicleMaintenance] = useState(false);
  const [showFluidsAndFilter, setShowFluidsAndFilter] = useState(false);
  const selectedVehicle = props.part.selectedVehicle;
  let partsAllDetails = parts.partsAllDetails;
  let partsApiCall = parts.partsApiCall;
  const { groupName, categoryName } = props.part;
  const { year, make, model, engine } = selectedVehicle;

  const userPrefView = 'full';
  const [showTableView, setShowTableView] = useState(false);

  useEffect(() => {
    if (!_isEmpty(props.part)) {
      const partData = props.part;
      if (!_isEmpty(partsAllDetails)) {
        const partItem = partsAllDetails.find((part) => {
          return (
            part.year === partData.yearCode
            && part.make === partData.makeCode
            && part.model === partData.modelCode
            && part.engine === partData.engineCode
            && Number(part.groupNumber) === partData.groupCode
          )
        });
        // && partItem.categoryId === partData.partCategoryCode
        if (partItem) {
          setFilteredParts(getPartsWithAlternateSuperseded(partsAllDetails));
        }
      } else {
        const requestVehicle = express.requestVehicle || {};
        const requestGroup = !_isEmpty(express.requestGroups) ? express.requestGroups[0] : [];
        if (partData.yearCode === requestVehicle.year
          && partData.makeCode === requestVehicle.makeId
          && partData.modelCode === requestVehicle.modelId
          && partData.engineCode === requestVehicle.engineId
          && partData.groupCode === requestGroup.groupCode) {
          setFilteredParts([]);
        }
      }
    }
  }, [partsAllDetails]);

  useEffect(() => {
  }, [partsApiCall]);

  const searchParts = (searchValue) => {
    if (!_isEmpty(searchValue)) {
      const searchedParts = partsAllDetails.filter((part) => part.description && part.description.toLowerCase().includes(searchValue.toLowerCase()))
      setFilteredParts([]);
      setSearchingParts(true);
      setTimeout(() => {
        setFilteredParts(getPartsWithAlternateSuperseded(searchedParts));
        setSearchingParts(false);
      }, 500);
    } else {
      setFilteredParts(getPartsWithAlternateSuperseded(partsAllDetails));
    }
  }

  const getPartsWithAlternateSuperseded = (parts) => {
    let partArray = [];
    !_isEmpty(parts) && parts.map((part, k) => {
      let altPartNumsArr = [];
      let subPartNumsArr = [];
      if (!_isEmpty(part.alternate)) {
        part.alternate.map((alt) => {
          altPartNumsArr.push(alt.partNumber)
        })
      };
      if (!_isEmpty(part.substitute)) {
        part.substitute.map((sub) => {
          subPartNumsArr.push(sub.partNumber)
        });
      }
      partArray.push({ ...part, partType: "Regular", altPartNums: altPartNumsArr.join(','), subPartNums: subPartNumsArr.join(',') });
      //alternate parts
      if (!_isEmpty(part.alternate)) {
        part.alternate.map((altPart) => {
          let description = altPart.description;
          if (_isEmpty(altPart.description)) {
            description = altPart.extendedDescription;
          }
          partArray.push({ ...altPart, partType: "Alternate", description });
        });
      }
      //superseded parts
      if (!_isEmpty(part.substitute)) {
        part.substitute.map((subPart) => {
          let description = subPart.description;
          if (_isEmpty(subPart.description)) {
            description = subPart.extendedDescription;
          }
          partArray.push({ ...subPart, partType: "Superseded", description });
        });
      }
    });
    return partArray;
  }

  const toggleView = (event) => {
    setShowTableView(!showTableView);
  };

  const handleVehicleMaintenan = () => {
    setShowVehicleMaintenance(!showVehicleMaintenance);
  };

  const handleFluidSpec = () => {
    setShowFluidsAndFilter(!showFluidsAndFilter);
  };

  const renderNoDataState = () => {
    if (!_isEmpty(filteredParts)) {
      return null;
    } else {
    return (
        <Card className="pt-1 text-center">
        <Card.Header className="xlp-primary-banner">
          No Parts Found
        </Card.Header>
        <Card.Body>
          <Card.Title>
          We couldn't find the parts that you were looking for
          </Card.Title>
        </Card.Body>
      </Card>
    );
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={1}></Col>
        <Col lg={11}>
          <div className="part-detail-bredcrum">
            {year}
            <span className="text-black">{" > "}</span>
            {make}
            <span className="text-black">{" > "}</span>
            {model}
            <span className="text-black">{" > "}</span>
            {engine}
            <span className="text-black">{" > "}</span>
            {categoryName}
            <span className="text-black">{" > "}</span>
            {groupName}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <PartSearch searchParts={searchParts} />
        </Col>
        <Col lg={4} className="pt-2">
          <PartTypes selectedVehicle={selectedVehicle} />
        </Col>
        <Col lg={3} className="pt-2">
          <TotalParts filteredParts={filteredParts} />
        </Col>
        <Col lg={2} className="pt-2">
          <div className="vehicle-spec-and-maintanance">
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="button-tooltip">Vehicle Maintenance</Tooltip>
              }
            >
              <input
                type="image"
                className="vehicle-maint-icon table-margin"
                onClick={() => handleVehicleMaintenan()}
                src={vehicleMaintIcon}
                alt="Vehicle Maintenance Icon"
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="button-tooltip">Vehicle Specs</Tooltip>}
            >
              <input
                type="image"
                className="fluids-filters-icon"
                onClick={() => handleFluidSpec()}
                src={fluidsAndFiltersIcon}
                alt="Fluids and Filters Icon"
              />
            </OverlayTrigger>
          </div>
        </Col>
        {/* <Col lg={4}>
          <div className="toggleView d-flex " style={{ marginTop: '20px' }}>
            <OverlayTrigger
              placement="right"
              delay={{ show: 100, hide: 400 }}
              overlay={
                <Tooltip id="toggle-view-table-full">
                  {`${showTableView
                    ? 'You are in "Table View", clicking on the button will switch to "Full View"'
                    : 'You are in "Full View", clicking on the button will switch to "Table View"'
                    }`}
                </Tooltip>
              }
            >
              <Form.Group controlId="toggle-e-view-id">
                <Form.Check
                  type="switch"
                  id="toggle-e-view"
                  label={`${userPrefView === 'full'
                    ? 'Table View'
                    : 'Full View'
                    }`}
                  onChange={(e) => toggleView(e)}
                />
              </Form.Group>
            </OverlayTrigger>
          </div>
        </Col> */}
      </Row>
      {!_isEmpty(filteredParts) ? (
        <React.Fragment>
          <Row>
            {showTableView ? (
              <React.Fragment>
                <PartTable parts={filteredParts} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {filteredParts &&
                  filteredParts.map((part) => {
                    return (
                      <React.Fragment>
                        <Part nodeData={props.part} part={part} />
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            )}
          </Row>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {partsApiCall || searchingParts ? (
            <React.Fragment>
              <CircularProgress color="gray" size="2rem" />
            </React.Fragment>
          ) : (
            <React.Fragment>{renderNoDataState()}</React.Fragment>
          )}
        </React.Fragment>
      )}
      {showVehicleMaintenance && !_isEmpty(selectedVehicle) ? (
        <VehicleMaintenancePopup
          openPopup={showVehicleMaintenance}
          selectedVehicle={selectedVehicle}
          closePopup={() => handleVehicleMaintenan()}
        />
      ) : null}
      {showFluidsAndFilter &&
        !_isEmpty(selectedVehicle) &&
        !_isEmpty(selectedVehicle) ? (
        <FluidsAndFiltersPopup
          openPopup={showFluidsAndFilter}
          selectedVehicle={selectedVehicle}
          closePopup={() => handleFluidSpec()}
        />
      ) : null}
    </React.Fragment>
  );
};

PartDetails.propTypes = {};

export default PartDetails;
