import React, { Fragment } from "react";
import { Form, Image, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import _sumBy from "lodash/sumBy";
import _uniqBy from "lodash/uniqBy";
import _isEqual from "lodash/isEqual";
import {
  isValidAvailableQty,
  isInputBlank,
  isValidPositiveNumber,
  distributeAvailability,
  distributeAvailabilityByStore,
  getExtendedTotalPrice,
  formatAmount,
  numbersOnlyRegExp,
  formatTimeAvb,
  getShipInfo,
  getPartPackQty,
  displayQtyChangeMsg,
  getDistributionForVehicle,
  getDistributionForNonVehicle,
  distributeAvailability2,
  getSellerInfo,
  enableBlackColorBasedOnStorePref
} from "../../../helpers/utils";
import { connect } from "react-redux";
import FormValidationErrorMessage from "../FormValidationErrorMessage/FormValidationErrorMessage";
import AddToCart from "../AddToCart/AddToCart";
import isEmpty from "lodash/isEmpty";
import { triggerCustomUxCriticalError } from "../../../actions/error";
import PricingIcons from "../../Listing/PricingIcons";
import _cloneDeep from 'lodash/cloneDeep';
import AvailabilityStoreView from "../AvailabilityStoreView";
class SingleInterchange extends React.Component {
  constructor(props) {
    super(props);
    let { singlePart } = props;
    let maxQty = !_isEmpty(singlePart.pricingAvailability.availability)
      ? singlePart.pricingAvailability.availability.reduce(
          (item1, item2) => item1 + item2["availableQty"],
          0
        )
      : 0;
    let isAltPart = !_isEmpty(props.partType) && props.partType === "altPart";
    let isSubPart = !_isEmpty(props.partType) && props.partType === "subPart";
    this.state = {
      extendedPrice: 0,
      qty: !_isEmpty(singlePart.pricingAvailability.availability) ? 1 : "",
      maxQty,
      isAltPart,
      isSubPart,
      appendPart: props.appendPart ? props.appendPart : false,
      selectedAvailabilityHours: [],
      validity: this.intializeValidations(),
      orderArray: [],
      totalOrderQty: 0,
      preveousOrderQty: 0,
      singlePartData: {},
      showWithinPopup:false,
      storeData:"",
      isMobileEnabled: this.props.userDetails?.shipTos[0]?.isMobileEnabled
    };
  }

  componentDidUpdate(prevProps) {
    const {
      addItemcartFetching,
    } = this.props;
    let {selectedAvailabilityHours, qty} = this.state;
    if (!_isEqual(this.props, prevProps)) {
      let {
        singlePartData
      } = this.state;
      if (!_isEqual(addItemcartFetching, prevProps.addItemcartFetching)) {
        if(!_isEmpty(singlePartData)) {
          singlePartData.isSelected = false;
          this.setState({
          ...this.state,
          singlePartData
          })
        }
      }
    //  if (!_isEqual(qty, prevProps.qty)) {
        if (
      qty == 1 &&
      selectedAvailabilityHours &&
      selectedAvailabilityHours.length == 0
     ) {
       this.handleInputChange({ target: { value: 1 } });
     }
     // }
    }
  }

  intializeValidations() {
    this.validity = {
      doesQtyExist: true,
      isQtyMoreThanOne: true,
      isQtyLessThanAvailable: true,
    };
    return this.validity;
  }

  validateQty(quantity, availableQuantity, validity) {
    validity.doesQtyExist = !isInputBlank(quantity);
    validity.isQtyMoreThanOne = isValidPositiveNumber(quantity);
    validity.isQtyLessThanAvailable = isValidAvailableQty(
      quantity,
      availableQuantity
    );
    return validity;
  }

  onclick = (tempData) => {
    this.setState({
      storeData: tempData,
    });
    this.setState({
      showWithinPopup: true,
    });
  }

   checkIfMobileEnabled = (tempData,text,isTooltip) =>{
    let {isMobileEnabled} = this.state
    return(
      isMobileEnabled ? 
      <u className={isTooltip ?"cursor-pointer": "text-color"} onClick={()=>{this.onclick(tempData)}}>{text}</u> :
    <u>{text}</u>
    )
  }

  handleInputChangeValue(event) {
    let { qty, maxQty, validity, preveousOrderQty } = this.state;
    if (!_isEmpty(event)) {
      qty = event.target.value;
    }
    qty = Number(qty);
    preveousOrderQty = Number(qty);
    validity = this.validateQty(qty, maxQty, validity);
    this.setState({
      ...this.state,
      qty,
      preveousOrderQty,
      validity,
    });
  }

  handlePartSelect = (e, selectedAvailabilityHours, singlepartdata = []) =>  {
    singlepartdata.isSelected = e.target.checked ? true : false;
    let {handleCheckBox} = this.props;
    this.setState({singlePartData : singlepartdata
    })
    handleCheckBox(e, selectedAvailabilityHours, singlepartdata);
  };

  handleInputChange = (e) => {
    let {
      qty,
      maxQty,
      validity,
      validitySum,
      extendedPrice,
      preveousOrderQty,
    } = this.state;
    let selectedAvailabilityHours = [];
    let singlePartData = {};
    let {
      singlePart,
      selectedVehicle,
      partLevel,
      isStockCheck,
      userDetails,
      parts,
      cart,
      dispatch,
    } = this.props;
    isStockCheck = isStockCheck ? isStockCheck : false;
    singlePart = _cloneDeep(singlePart);
    let orderArray = [];
    let orderData = [];
    qty = e.target.value;
    let value = e.target.value;
    let { shipTos } = userDetails;
    let shipMethod = getShipInfo(userDetails, parts).shipMethod
      ? getShipInfo(userDetails, parts).shipMethod
      : shipTos && shipTos[0] && shipTos[0].shipMethod && shipTos[0].shipMethod;
    let storeNumber = getShipInfo(userDetails, parts).storeNumber
      ? getShipInfo(userDetails, parts).storeNumber
      : shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.storeNumber &&
        shipTos[0].location.storeNumber;
    let sellerPartnerId = getShipInfo(userDetails, parts).sellerPartnerId
      ? getShipInfo(userDetails, parts).sellerPartnerId
      : shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.sellerPartnerId &&
        shipTos[0].location.sellerPartnerId;
    if (!_isEmpty(e)) {
      value = e.target.value;
    } else {
      value =
        singlePart.pricingAvailability.qtyBuyInc > 1
          ? singlePart.pricingAvailability.qtyBuyInc
          : 1;
      qty = Number(value);
    }
    if (!_isEmpty(e)) {
      if (!_isEmpty(value) && Number(value) !== 0) {
        let ca = getPartPackQty(
          singlePart.pricingAvailability.qtyBuyInc,
          Number(qty),
          maxQty
        );
        qty = ca;
        if (
          preveousOrderQty !== qty &&
          singlePart.pricingAvailability.qtyBuyInc > 1
        ) {
          this.qtyByINCUpdateSuccessMessage();
          preveousOrderQty = qty;
        }
      } else {
        qty = Number(value);
        preveousOrderQty = Number(value);
      }
    }
    singlePart.qty = qty;
    singlePartData = singlePart;
    validity = this.validateQty(qty, maxQty, validity);
    let distributionData = {};
    if (validity) {
      extendedPrice = formatAmount(
        qty * singlePart.pricingAvailability.costPrice
      );

      if(!isStockCheck && (partLevel !== undefined && partLevel) && !_isEmpty(selectedVehicle)) {
        distributionData = getDistributionForVehicle(
        singlePart.partNumber,
        singlePart,
        singlePart.pricingAvailability.availability,
        qty,
        selectedVehicle,
        cart
      );
      } else {
        distributionData = getDistributionForNonVehicle(
          singlePart.partNumber,
          singlePart,
          singlePart.pricingAvailability.availability,
          qty,
          cart
        );
      }

      if (singlePart.pricingAvailability.qtyBuyInc > 1) {
        orderArray = distributeAvailability(
          qty,
          singlePart.pricingAvailability.availability
        );
        orderData = distributeAvailability2(
          distributionData.totalQty,
          distributionData.filteredAvailability
        );
      } else {
        orderArray = distributeAvailability(
          e.target.value,
          singlePart.pricingAvailability.availability
        );
        orderData = distributeAvailability2(
          distributionData.totalQty,
         distributionData.filteredAvailability
        );
      }

      let mfgCode =
        singlePart.lineCodes && singlePart.lineCodes.length > 0
          ? singlePart.lineCodes[0]
          : singlePart.pricingAvailability &&
            singlePart.pricingAvailability.mfgCode
          ? singlePart.pricingAvailability.mfgCode
          : null;
      selectedAvailabilityHours.push(...distributionData.alreadyAddedItem);
      orderData.map((item) => {
        selectedAvailabilityHours.push({
          availabilityHour: item.availableDuration,
          availableQty: item.availableQty,
          cartFlag: "no",
          // categoryId,
          costPrice: singlePart.pricingAvailability.costPrice,
          corePrice: singlePart.pricingAvailability.corePrice,
          coreListPrice: singlePart.pricingAvailability.coreListPrice,
          qtyBuyInc: singlePart.pricingAvailability.qtyBuyInc,
          desc: singlePart.description,
          descId: singlePart.descriptionID,
          engineId: !_isEmpty(selectedVehicle)
            ? selectedVehicle.engineId
            : null,
          engine: !_isEmpty(selectedVehicle) ? selectedVehicle.engine : null,
          listPrice: singlePart.pricingAvailability.listPrice,
          makeId: !_isEmpty(selectedVehicle) ? selectedVehicle.makeId : null,
          make: !_isEmpty(selectedVehicle) ? selectedVehicle.make : null,
          modelId: !_isEmpty(selectedVehicle) ? selectedVehicle.modelId : null,
          model: !_isEmpty(selectedVehicle) ? selectedVehicle.model : null,
          mfgCode: mfgCode,
          packQty: singlePart.packQty,
          partNum: singlePart.partNumber,
          partType: "Regular",
          qty: item.orderQty,
          vehicleInfo:
            !isStockCheck && partLevel && !_isEmpty(selectedVehicle)
              ? `${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`
              : "Non-Vehicle Specific",
          yearId: !_isEmpty(selectedVehicle) ? selectedVehicle.year : null,
          shipMethod: shipMethod,
          store: storeNumber,
          sellPartnerId: sellerPartnerId,
          distStatus: "Completed",
          orderType: "REGULAR"
        });
      });
    }
    this.setState({
      ...this.state,
      extendedPrice,
      preveousOrderQty,
      orderArray,
      validitySum,
      maxQty,
      qty,
      selectedAvailabilityHours,
      singlePartData,
      validity,
    });
  };

  qtyByINCUpdateSuccessMessage = () => {
    let { dispatch } = this.props;
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = displayQtyChangeMsg();
    statusMessageProps.type = "success";
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  };

  prepareTooltipAvailability = (availabilityData) => {
    let availabilities = availabilityData;
    let returnArray = [];
    availabilities.map((singleAvailability, key) => {
      if (singleAvailability.availableQty > 0) {
        if (
          singleAvailability.availableDuration === "Next Business Day" ||
          singleAvailability.availableDuration === "Next 2 + Business Days" ||
          singleAvailability.availableDuration === "Next 2 Weeks"
        ) {
          returnArray.push(
            <ul>
              <li>
                <span className="text-white">
                  <span className="store-maroon-text">
                    {singleAvailability.availableQty}
                  </span>
                  &nbsp;
                  {this.checkIfMobileEnabled(singleAvailability?.storeAvailabilities, singleAvailability.availableDuration, true)}
                </span>
              </li>
            </ul>
          );
        } else {
          singleAvailability.storeAvailabilities.map((storeData, storeKey) => {
            if (key != 0) {
              returnArray.push(
                <ul>
                  <li>
                    <span className="text-white">
                      <b>{storeData.storeQty}</b>
                    </span>
                    <span className="text-white">
                      &nbsp;{` at Store #`}
                      <b>{storeData.store}</b>
                      <br />
                      {singleAvailability.availableDuration ===
                        "Direct Order Store" ||
                      singleAvailability.availableDuration ==
                        "Pick Up Store" ? (
                        <b>
                          ({singleAvailability.availableDuration},
                          {storeData.storeQualifier
                            ? storeData.storeQualifier
                            : ""}
                          )
                        </b>
                      ) : null}
                    </span>
                  </li>
                </ul>
              );
            }
          });
        }
      }
    });
    let fullDiv = [];
    fullDiv = <div>{returnArray}</div>;
    return fullDiv;
  };

  prepareAvailabilityDisplay = (availability, qtyEntered) => {
    let { singlePart, partLevel } = this.props;
    let { orderArray, selectedAvailabilityHours } = this.state;
    let uniqueData = _uniqBy(availability, "availableDuration");
    let enableOtherAvailabilities =
      uniqueData.length > 0 &&
      uniqueData[0].availableQty &&
      uniqueData[0].availableQty >= 1
        ? false
        : true;
    let otherStoreAvailability = 0;
    uniqueData.map((unique, index) => {
      if (index != 0) {
        otherStoreAvailability += unique.availableQty;
      }
    });
    let availableArray = [];
    uniqueData.map((unique, index) => {
      (unique.availableDuration === "Your Store" ||
        unique.availableDuration === "Store") &&
        availableArray.push(
          <Fragment>
            <ul>
              <li>
                <span className="d-flex">
                  <span className="text-black">
                    {unique.availableQty == 0 ? (
                      <span className="store-maroon-text compact-text-grey-Zero">
                        <b>{unique.availableQty}</b>
                      </span>
                    ) : (
                      <span className="store-maroon-text compact-text-grey">
                        <b>{unique.availableQty}</b>
                      </span>
                    )}
                    &nbsp; at &nbsp;
                    {unique.availableDuration}{" "}
                    {unique.storeAvailabilities.length > 0 &&
                    unique.storeAvailabilities[0].store ? (
                      <b>#{unique.storeAvailabilities[0].store}</b>
                    ) : null}
                  </span>
                  {true && !enableOtherAvailabilities ? (
                    qtyEntered > uniqueData[0].availableQty ? (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 100, hide: 4000 }}
                        show={qtyEntered > uniqueData[0].availableQty}
                        overlay={
                          <Tooltip
                            id="toggle-view-full-compact"
                            className="custom-tooltip-availability"
                          >
                            {this.prepareTooltipAvailability(availability)}
                          </Tooltip>
                        }
                      >
                        <a className="maroonColorTxt info-icon">
                          <i class="fa fa-info-circle"></i>
                        </a>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 100, hide: 4000 }}
                        overlay={
                          <Tooltip
                            id="toggle-view-full-compact"
                            className="custom-tooltip-availability"
                          >
                            {this.prepareTooltipAvailability(availability)}
                          </Tooltip>
                        }
                      >
                        <a className="maroonColorTxt info-icon">
                          <i class="fa fa-info-circle"></i>
                        </a>
                      </OverlayTrigger>
                    )
                  ) : null}
                </span>
              </li>
              {true && enableOtherAvailabilities ? (
                <li>
                  <span className="d-flex">
                    <span className="text-black">
                      <span className="store-maroon-text compact-text-green">
                        <b>{otherStoreAvailability}</b>
                      </span>
                      &nbsp; at Other Stores &nbsp;{" "}
                    </span>
                    {true && enableOtherAvailabilities ? (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 100, hide: 4000 }}
                        overlay={
                          <Tooltip
                            id="toggle-view-full-compact"
                            className="custom-tooltip-availability"
                          >
                            {this.prepareTooltipAvailability(availability)}
                          </Tooltip>
                        }
                      >
                        <a className="maroonColorTxt info-icon">
                          <i class="fa fa-info-circle"></i>
                        </a>
                      </OverlayTrigger>
                    ) : null}
                  </span>
                </li>
              ) : null}
            </ul>
          </Fragment>
        );
    });
    if (availableArray.length > 0 && partLevel) {
      availableArray.push(
        <AddToCart
          description={singlePart.description}
          selectedAvailabilityHours={selectedAvailabilityHours}
          fromPage={"interchangepartlevel"}
        />
      );
    }
    return availableArray;
  };

  renderAvailability = () => {
    let { singlePart, partLevel, selectedstore } = this.props;
    let { orderArray, selectedAvailabilityHours,qty } = this.state;
    let availabilitySection = [];
    singlePart.pricingAvailability.availability.map(
      (singleAvailability, key) => {
        // if (singleAvailability.availableQty > 0 && orderArray[key] && orderArray[key].orderQty) {
        if (singleAvailability.availableQty > 0) {
          availabilitySection.push(
            <Fragment>
              <div>
                <span>
                  {!_isEmpty(orderArray) && !_isEmpty(orderArray[key]) ? (
                     <span className={enableBlackColorBasedOnStorePref(selectedstore) ? "" : "maroonColorTxt"}>
                     <b>{ orderArray[key].orderQty }</b>
                     </span>                  
                  ) : (
                    ""
                  )}{" "}
                </span>

                {singleAvailability.availableTime === null ||
                singleAvailability.availableTime === "null" ||
                singleAvailability.availableDuration === "Same Day" ||
                (singleAvailability.availableDuration === "Immediately" &&
                  singleAvailability.code === 0)
                  ? !_isEmpty(orderArray[key]) &&
                    orderArray[key].orderQty && (
                      <span>
                        {this.checkIfMobileEnabled(orderArray[key]?.storeAvailability,formatTimeAvb(orderArray[key]?.availableDuration))}{" "}
                        <span className="maroonColorTxt"></span>
                      </span>
                    )
                  : !_isEmpty(orderArray[key]) &&
                    orderArray[key].orderQty && (
                      <span>
                        {" "}
                        {this.checkIfMobileEnabled(orderArray[key]?.storeAvailability,"Within")}{" "}
                        {formatTimeAvb(orderArray[key]?.availableDuration)}
                        <span className="maroonColorTxt"></span>
                      </span>
                    )}
              </div>
            </Fragment>
          );
        }
      }
    );
    availabilitySection = availabilitySection .length > 0 ? <div>{availabilitySection}</div> : 0;
    if (availabilitySection.length > 0 && partLevel) {
      availabilitySection.push(
        <AddToCart
          description={singlePart.description}
          selectedAvailabilityHours={selectedAvailabilityHours}
          fromPage={"partlevelinterchange"}
        />
      );
    }
    return availabilitySection;
  };

  handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  handleKeyDown = (event) => {
    const qtyValue = event.target.value;
    if (
      (numbersOnlyRegExp(event.key) === false ||
        (qtyValue && qtyValue.length >= 4) ||
        event.keyCode === 32) &&
      this.handleOtherKeys(event.keyCode)
    ) {
      event.preventDefault();
    }
  };

  render() {
    let {
      singlePart,
      handleCoverToCover,
      partLevel,
      handleCheckBox,
      partType,
      altPartNums,
      subPartNums,
      userDetails,
      hideAllPrice
    } = this.props;
    let {
      qty,
      validity,
      extendedPrice,
      selectedAvailabilityHours,
      selectedVehicle,
      orderArray,
      showOrdeQty,
      isAltPart,
      isSubPart,
      maxQty,
      appendPart,
      singlePartData,
      showWithinPopup,
      storeData
    } = this.state;

    // if (
    //   qty == 1 &&
    //   selectedAvailabilityHours &&
    //   selectedAvailabilityHours.length == 0
    // ) {
    //   // this.handleInputChange({ target: { value: 1 } });
    // }
    return (
      <div
        className={
          appendPart ? "interchange_card append-part" : "interchange_card"
        }
      >
        {
          <Fragment>
            {isAltPart || isSubPart ? (
              <div className="product_img">
                <h6>{isAltPart ? `Alternative` : `Superseded`}</h6>
                <div className="image-thumbnail-container-interchange">
                  <Image
                    className="image-thumbnail"
                    src={
                      '/noImage.jpg'
                    }
                    fluid
                  />
                </div>
              </div>
            ) : (
              <div className="product_img">
                <div className="image-thumbnail-container-interchange">
                  <Image
                    className={"image-thumbnail"}
                    src={
                      singlePart.thumbnailData
                        ? `data:image/png;base64,${singlePart.thumbnailData}`
                        : '/noImage.jpg'
                    }
                    fluid
                  />
                </div>
                <span
                  className="c2c"
                  onClick={() =>
                    handleCoverToCover(
                      singlePart.partNumber,
                      singlePart.orderNumber
                    )
                  }
                >
                  Cover to Cover
                </span>
              </div>
            )}
            <div className="interchange-brand-details">
              <span className="product_name">{singlePart.description}</span>
              <div className="product_brand">
                <span>Brand: </span>
                <span>{singlePart.mfgName}</span>
              </div>
              <div className="partNumber">
                <span>Part# {singlePart.partNumber}</span>
              </div>
              <div>
                <PricingIcons
                  toolTips={singlePart.pricingAvailability.toolTips}
                />
              </div>
              {appendPart && partType === "Regular" && (
                <div className="superseded_alt_interchange">
                  <p className="maroonColorTxt">
                    {!_isEmpty(altPartNums)
                      ? `Alternate Part ${altPartNums}`
                      : ""}
                    {!_isEmpty(subPartNums)
                      ? `Superseded Part ${subPartNums}`
                      : ""}
                  </p>
                </div>
              )}
              <div className="perVeh_years_position_container">
                {/* {singlePart.perCarQty && ( */}
                {/* <div>
										<span>Per Veh: </span>
										<span>{singlePart.perCarQty}</span>
									</div> */}
                {/* )} */}
                {/* {singlePart.years && (
									<div>
										<span>Years: </span>
										<span>{singlePart.years}</span>
									</div>
								)} */}
                {/* <div>
									<span>Position: </span>
									<span></span>
							</div> */}
              </div>
            </div>
            <div className="price_container">
            <div className="mainpriceinfo">
              {!hideAllPrice && (<div className="price_data">
                <span className="price_title"> Your Price:{" "}</span>
                <span className="priceLabel">
                  ${singlePart.pricingAvailability.costPrice}
                </span>
              </div>)}
              {!hideAllPrice && (
              <div className="price_data">
              <span className="price_title">Core Price:{" "}</span>
              <span className="priceLabel">
                ${singlePart.pricingAvailability.corePrice}
              </span>
            </div>
             )}
              {!hideAllPrice && (
              <div className="price_data">
              <span className="price_title">List Price:{" "}</span>
              <span className="priceLabel">
                ${singlePart.pricingAvailability.listPrice}
              </span>
            </div>
            )}
            </div>
              {_sumBy(
                singlePart.pricingAvailability.availability,
                "availableQty"
              ) > 0 ? (
                <Fragment>
                  <div className="ord_qty_extended_price_container">
                    <div className="ord_qty_container">
                      <span>Order QTY</span>
                      <Form.Control
                        type="text"
                        value={qty}
                        disabled={_isEmpty(
                          singlePart.pricingAvailability.availability
                        )}
                        onChange={(e) => this.handleInputChangeValue(e)}
                        onBlur={(e) => this.handleInputChange(e)}
                        onMouseLeave={(e) => this.handleInputChange(e)}
                        onKeyDown={(event) => {
                          this.handleKeyDown(event);
                        }}
                      />
                    </div>
                    <div className="extended_price_container">
                    {!hideAllPrice && ( 
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 100, hide: 400 }}
                        overlay={
                          <Tooltip className="custom-tooltip-availability">
                            <span className="font-weight-bold">
                              Total Extended Price = Qty * (Your Price + Core
                              Price)
                            </span>
                          </Tooltip>
                        }
                      >
                       <p>
                          Total Extended Price:{" "}
                          <span className="marron_text">
                            $
                            {getExtendedTotalPrice(
                              singlePart.pricingAvailability.corePrice,
                              singlePart.pricingAvailability.costPrice,
                              qty
                            )}
                          </span>
                        </p>
                      </OverlayTrigger>
                    )}
                    </div>{" "}
                  </div>
                  {/* <FormValidationErrorMessage
										condition={
											!validity.doesQtyExist || !validity.isQtyMoreThanOne
										}
										errorMessage={'Please enter a quantity'}
									/> */}
                  <FormValidationErrorMessage
                    condition={
                      !validity.isQtyLessThanAvailable &&
                      validity.doesQtyExist &&
                      validity.isQtyMoreThanOne
                    }
                    errorMessage={`The Order Quantity should be less than or equal to  ${maxQty}`}
                  />
                </Fragment>
              ) : <div className="outofstock-text">Currently out of stock</div>}
            </div>
            <div className="hourAvailable_button_container interchange-add-cart">
            {/* {qty > 0
                ? this.prepareAvailabilityDisplay(
                    singlePart.pricingAvailability.availability,
                    qty
                  )
                : null} */}

              {qty > 0
                 ?  (getSellerInfo(userDetails) ?  this.renderAvailability() : this.prepareAvailabilityDisplay(
              singlePart.pricingAvailability.availability,qty)) : null }
              {!_sumBy(
                singlePart.pricingAvailability.availability,
                "availableQty"
              ) > 0 ? (
               null
              ) : (
                <Form.Group>
                  <Form.Check
                    className="select_part_checkbox"
                    type="checkbox"
                    label="Select part"
                    checked={singlePartData.isSelected}
                    onChange={(e) =>
                      this.handlePartSelect(e, selectedAvailabilityHours, singlePartData)
                    }
                  />
                </Form.Group>
              )}
              {showWithinPopup ?
              (<AvailabilityStoreView
              openPopup={showWithinPopup}
              closePopup={() => this.setState({showWithinPopup:false})}
              storeData={storeData}
              />
              )
              : null }
              {/* {qty > 0 && !partLevel ? (
                <Form.Group>
                  <Form.Check
                    className="select_part_checkbox"
                    type="checkbox"
                    label="Select part"
                    onChange={(e) =>
                      handleCheckBox(e, selectedAvailabilityHours)
                    }
                  />
                </Form.Group>
              ) : null} */}
            </div>
          </Fragment>
        }
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedVehicle: state.search.selectedVehicle,
    hideAllPrice : state.parts && state.parts.hidePrice,
    parts: state.parts,
    cart: state.cart,
    addItemcartFetching: state.cart.addItemcartFetching
  };
}
export default connect(mapStateToProps)(SingleInterchange);
