import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { addFeedBackComment } from '../Header/header.service';

export const addFeedBack = createAsyncThunk(
    "addFeedBack",
    async (payload, { signal }) => {
      const source = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await addFeedBackComment(payload, {
        cancelToken: source.token,
      });
      return response.data;
    }
  );