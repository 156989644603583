/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _each from "lodash/each";
import _head from "lodash/head";
import _uniqueId from "lodash/uniqueId";
import _uniqWith from "lodash/uniqWith";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";
import _uniqBy from "lodash/uniqBy";
import linkIcon from "../../static/images/linkIcon.png";
import { logUserStockActivity } from "../../actions/user";
import { getPartsPricingAvailability } from "../../components/Listing/VectorGfx/vectorgfx.thunks";
import {
  getBuyersGuideData,
  getBuyersGuideBrandData,
  getC2CUrl,
  // getInterchangeData,
} from "../../actions/parts";
import { updateProductList, updateStockList,resetPageContent, resetProductList} from "./stockcheck.slice";
import { getOrderNumber, videoArticleLinks, getSingleProductDetails, getStockProductListFromSkuNumber, stockCheckInterchange} from "./stockcheck.thunk";
import {
  distributeAvailability,
  getExtendedTotalPrice,
  timeInUTC,
  formatTimeAvb,
  handleKeyDown,
  handleKeyDownAlphabet,
  getShipInfo,
  getDistributionForNonVehicle,
  distributeAvailability2,
  getPartPackQty,
  displayQtyChangeMsg,
  isStockOrder,
  getUserDefaultShipInfo,
  enableBlackColorBasedOnStorePref
} from "../../helpers/utils";
import {
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  Spinner
} from "react-bootstrap";
import { triggerCustomUxCriticalError } from "../../actions/error";
import { addItemToCart2 } from "../../actions/cart";
import XModal from "../shared/XModal";
import CoverToCoverPopup from "../Listing/CoverToCoverPopup";
import Interchange from "../shared/Interchange";
import BuyingGuideComponent from "../shared/BuyingGuide";
import AvailabilityStoreView from "../shared/AvailabilityStoreView"
import LostSales from "../ShoppingCart/LostSales";
import { useLocation } from "react-router-dom";
import AddToCartWarningPopup from "../shared/AddToCartWarningPopup/AddToCartWarningPopup";
import  PickupDelivery  from '../shared/PickupDeliveryHeader';
import _find from 'lodash/find';
import isEmpty from "lodash/isEmpty";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const PartsTable = (props) => {
  let { getMagicWordShipData } = props;
  const dispatch = useDispatch();
  const x2userName = useSelector((state) => state.user.userDetails.x2userName);
  const cart = useSelector((state) => state.cart);
  const userSecurityGroup = useSelector(
    (state) => state.user.userDetails.userSecurityGroup
  );
  const showInterchange =
    !_isEmpty(userSecurityGroup) && userSecurityGroup.interchange === false
      ? false
      : true;
  const showBuyersGuide =
    !_isEmpty(userSecurityGroup) && userSecurityGroup.buyersGuide === false
      ? false
      : true;
  const initializeValidity = () => {
    const validity = {
      isPartsNoMinLimit: true,
      isPartsNoAlphaSpecial: true,
      isPartsNoValid: true,
      isPartsNoNotNull: false,
    };
    return validity;
  };
  // TODO: loader for stock check btn click, links dropdown align, addUser() call in old code,
  // qty change not updating availability, multiple parts addition, isImport flag, tooltips,
  // sorting, row productLineCopy/uniqId, same part added again
  const userDetails = useSelector((state) => state.user.userDetails);
  const checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  const parts = useSelector((state) => state.parts);
  const shipInfo = getShipInfo(userDetails, parts);
  const orderType = useSelector((state) => state.cart.orderType);
  const [inputAuto, setInputAuto] = useState(false);
  const [showLoadermagic, setShowLoadermagic] = useState(false);
  const [partSelect, setPartSelect] = useState(true);
  const [partNum, setPartNum] = useState("");
  const [interChangePartNum, setInterChangePartNum] = useState(null);
  const [mfgCode, setmfgCode] = useState("");
  const [partNumClear, setPartNumClear] = useState("");
  const [partsColumns, setPartsColumns] = useState([]);
  const [c2cModalStatus, setc2cModalStatus] = useState(false);
  const [interchangeModalStatus, setInterchangeModalStatus] = useState(false);
  const [buyersModalStatus, setbuyersModalStatus] = useState(false);
  const [selectedAvailabilityHours, setSelectedAvailabilityHours] = useState(
    []
  );
  const [addToCartPayload, setAddToCartPayload] = useState(null);
  const [addToCartWarningPopup, setaddToCartWarningPopup] = useState(false);
  const [showLostSales, setshowLostSales] = useState(false);
  const [showWithinPopup, setShowWithinPopup] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const hideAllPrice =  parts  && parts.hidePrice;
  const [selectedPartData, setSelectedPartData] = useState({
    smartLink: "",
  });
  let stockDetails = useSelector((state) => state.stockCheck);
  const [shipMethod, setShipMethod] = useState(null);
  const [sellerId, setSellerId] = useState(null);
  /* added this due to tab search issue, since tab event immediately not getting update with state values*/
  const [shipInfoObjt, setShipInfoObjt] = useState({shipMethod: null, sellerId: null, store: null});
  const [stockStore, setStockStore] = useState(null); 
  const customerStore = userDetails && userDetails.shipTos && userDetails.shipTos[0] &&
                         userDetails.shipTos[0].location && userDetails.shipTos[0].location.storeNumber ? userDetails.shipTos[0].location.storeNumber : "";
  const customerSellerId = userDetails.shipTos && userDetails.shipTos.length > 0 && userDetails.shipTos[0].location && userDetails.shipTos[0].location.sellerPartnerId && userDetails.shipTos[0].location.sellerPartnerId ? userDetails.shipTos[0].location.sellerPartnerId  : ""; 	 
  const [isPickup, setIsPickup] = useState(false);
  const [isViewChanged, setIsViewChanged] = useState(false);
  const params = useQuery();
  const emptyData = [
    {
      id: 1,
      emptySelection: true,
      selected: false,
    },
    {
      id: 2,
      emptySelection: true,
      selected: false,
    },
  ];
  const [partsData, setPartsData] = useState(emptyData);
  const DescriptionHeaderStyle = {
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "50px",
  };
  const headerStyle = {
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "110px",
  };
  const partStyle = {
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "110px",
  };
  const headerStyle1 = {
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "50px",
  };
  const headerStyle2 = {
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "55px",
  };
  const qtyStyle = {
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "65px",
  };
  const availabilityStyle = {
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "125px",
  };
  const headerStyle3 = {
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "70px"
  };

  useEffect(() => {
    const customerSellerId = userDetails.shipTos && userDetails.shipTos.length > 0 && userDetails.shipTos[0].location && userDetails.shipTos[0].location.sellerPartnerId && userDetails.shipTos[0].location.sellerPartnerId ? userDetails.shipTos[0].location.sellerPartnerId  : ""; 	 
    const cartSellerId = checkoutDetails && checkoutDetails.length > 0  ? checkoutDetails[0].sellPartnerId : customerSellerId;
    const customerShipMethod = userDetails.shipTos && userDetails.shipTos.length > 0 && userDetails.shipTos[0].shipMethod && userDetails.shipTos[0].shipMethod ? userDetails.shipTos[0].shipMethod  : ""; 	 
    const cartShipMethod =  checkoutDetails && checkoutDetails.length > 0  ? checkoutDetails[0].shipMethod : customerShipMethod;
    const customerStore = userDetails && userDetails.shipTos && userDetails.shipTos[0] &&
                         userDetails.shipTos[0].location && userDetails.shipTos[0].location.storeNumber ? userDetails.shipTos[0].location.storeNumber : "";
    const cartStore = checkoutDetails && checkoutDetails.length > 0 ?  checkoutDetails[0].store : customerStore;
    setShipMethod(cartShipMethod);
    setSellerId(cartSellerId);
    setStockStore(cartStore);
    let shipInfo = {};
    shipInfo.shipMethod = cartShipMethod;
    shipInfo.sellerId = cartSellerId;
    shipInfo.store = cartStore;
    Object.assign(shipInfoObjt, shipInfo);
    setMagicShipData(shipInfo);
  }, [checkoutDetails, userDetails])

  const setMagicShipData = (shippingData) => {
    let shipInfo = {};
    if (!_isEmpty(shippingData.sellerId)) {
      shipInfo.storeNumber = shippingData.store;
      shipInfo.sellerPartnerId = shippingData.sellerId;
      shipInfo.shipMethod = shippingData.shipMethod;
      getMagicWordShipData(shipInfo);
    }
  }

  useEffect(() => {
    partsTable();
  }, []);

  useEffect(_ => {
    const handleScroll = _ => { 
      let count = 0;
      if(!_isEmpty(partsData)){
        partsData.map((value,index) => {
          if((typeof value.isLinkApiCalled != 'undefined') && !value.isLinkApiCalled && count < 5) {
            count++;
            value.value = value.pl;
            value.isLinkApiCalled = true; 
            handleMfgCodeSelection(value, value, index);
          }
        })
        if(count > 0) {
          setPartsData(partsData);
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
    return _ => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!_isEmpty(stockDetails.selectedStockList)) {
      let productList = _cloneDeep(stockDetails.selectedStockList);
      dispatch(resetProductList());
      productList.map((product) => {
        const partRow = convertProductListToPartData(product);
        let data = !_isEmpty(partsData) && partsData.findIndex(function(item) {
          return item.partNum == product.partNum ;
        })
        if(data == -1){
          partsData.push(partRow);
        }else{
          partsData.splice(data, 1);
          partsData.push(partRow);
        }
      });
      partsData.map((d)=>{
        const itemToRemoveIndex = partsData.findIndex(function(item) {
          return item.partNum == ''|| item.partNum === undefined ;
        });
        if(itemToRemoveIndex !== -1){
          partsData.splice(itemToRemoveIndex, 1);
        }
      })
      pushEmptyRows();
      setPartsData(partsData);
      setTimeout(() => {
        partsTable();
      }, 100);
    }
  }, [stockDetails.selectedStockList]);

  useEffect(() => {
    const partNum = params.get('partSku');
    const description = params.get('description')
    const mfgCode = params.get('mfgCode');
    if(partNum !== undefined && partNum !== null){
    partsData.push({partNum,description,mfgCode})
    partsData.map((d)=>{
      const itemToRemoveIndex = partsData.findIndex(function(item) {
        return item.partNum == ''|| item.partNum === undefined ;
      });
      if(itemToRemoveIndex !== -1){
        partsData.splice(itemToRemoveIndex, 1);
      }
    })
    handlePricingAvailbility()}
}, []);

  const onChangePLField = (event, row, rowIndex) => {
    partsData[rowIndex].pl = event.target.value;
  };

  const setPartForRow = (partNum, row, rowIndex) => {
    let value = partNum.toUpperCase();
    setPartNumClear(value);
    partsData[rowIndex].partNum = value;
    if (!row.productLine && (row.partNew || row.partNew === "")) {
      setTimeout(() => {
        partsTable();
      }, 100);
    }
    handleFetchEnable();
  };

  const convertProductListToPartData = (product) => {
    const totalExtPrice = getExtendedTotalPrice(product.corePrice,product.costPrice, product.qty);
    let altSub = product.status === "3" ? "- <SUPERSEDED> - " : product.status === "6" ? "- <ALTERNATE> - " : "";    
    let isPartNotFound = product.status === "2" ? true : false;
    let shownodata = isPartNotFound ? 1 : "none";
    let nobprt = isPartNotFound ? 1 : true;
    return {
      partNum: product.partNum,
      qty: product.qty ? product.qty : 0,
      qtyBuyInc: product.qty,
      altSub: altSub,
      description:  product.itemSpecific,
      productLine: [product],
      productLineCopy: [product],
      descriptionValues: [product],
      selectedRowPart: product,
      pl: !isPartNotFound ?  product.mfgCode : "",
      mfgCode: product.mfgCode,
      noborder: true,
      noborderpart: nobprt,
      yourPrice: !isPartNotFound ? product.costPrice : "",
      listPrice: !isPartNotFound ? product.listPrice : "",
      corePrice: !isPartNotFound ? product.corePrice : "",
      availability: product.availability,
      availabilityAll: product.availabilityAll ? product.availabilityAll : product.availability,
      totalExtendedPrice: !isPartNotFound ? totalExtPrice : "",
      lostSales: true,
      //select: product.addToOrder,
      select: product.availability?.length > 0 ? true : false,
      unique: product.uniqId,
      isLinkApiCalled: product.isLinkApiCalled,
      showNoData: shownodata ,
      isPartNotFound: isPartNotFound
    };
  };

  const videoArticleLinksMethod = (desc , partNumber, rowIndex)=>{
    let payload ={
      mfgCode : desc,
      partNumber:partNumber
    }
  //  dispatch(videoArticleLinks(payload)).then((result) => {
  //   if(result.type == "videoArticleLinks/fulfilled" && !_isEmpty(result.payload)){
  //     partsData[rowIndex].resources =  result.payload;
  //   }}) 
  //   setPartsData(partsData);        
  }

  const showInterChangeByDefault = (partsData) => {
    let noDataCount = partsData.filter((item) => item.showNoData === 1);
    if (partsData.length === 3 && noDataCount.length == 1) {
      setInterChangePartNum(noDataCount[0].partNum);
      setInterchangeModalStatus(true);
    } else {
      setInterchangeModalStatus(false);
    }
  };

  const onChangePartsQty = (event, row, rowIndex,totalAvailqty) => {
    let {
      target: { value },
    } = event;
    partsData[rowIndex].qty = value;
    if (row.partNum) {
      if (value > totalAvailqty) {
        partsData[rowIndex].totalAvailable = totalAvailqty + 1;
      }
      if (value === "" || value == 0 || value > totalAvailqty) {
        partsData[rowIndex].select = false;
      } else {
        partsData[rowIndex].select = true;
      }
      handleAvailabilitySelectionOnQtyChange(event, row, rowIndex,totalAvailqty)
      partsTable(partsData);
    }
  };
 
  const handleAvailabilitySelectionOnQtyChange = (event, row, rowIndex,totalAvailqty) => {
    selectedAvailabilityHours.length = 0;
    let {
      target: { value },
    } = event;
    let defaultTotalValue = !_isEmpty(value.toString()) ? value : "";
    let orderArray = [];
    if (row.availabilityAll) {
      orderArray = distributeAvailability(
        defaultTotalValue,
        row.availabilityAll
      );
    }
    orderArray.map((item) => {
      selectedAvailabilityHours.push({
        availabilityHour: item.availableDuration,
        availableQty: item.availableQty,
        orderQty: item.orderQty,
      });
    });
    row.totalExtendedPrice = value * row.yourPrice;
    row.qty = value;
    partsData[rowIndex].qty = row.qty;
    partsData[rowIndex].totalExtendedPrice = getExtendedTotalPrice(
      row.corePrice,
      row.yourPrice,
      row.qty
    );
    partsData[rowIndex].totalAvailable = totalAvailqty;
    setPartsData(partsData);
    setSelectedAvailabilityHours(selectedAvailabilityHours);
  };

  const addedToCartOrQuote = () => {
    dispatch(resetProductList());
  };

  const handleMfgCodeSelection = (event, row, rowIndex) => {
    setmfgCode(event);
    partsData[rowIndex].noborder = 1;
    partsData[rowIndex].productLine = event;
    partsData[rowIndex].mfgCode = event;
    row.partNew = row.partNum;
    if (!_isEmpty(row.descriptionValues)) {
      row.descriptionValues.map((value, descIndex) => {
        if (value.mfgCode === event.value && value.partNum == event.partNum) {
          let altSub = value.status === "3"? "- <SUPERSEDED> - ": value.status === "6"? "- <ALTERNATE> - ": "";
          let modifiedId = altSub + value.mfgCode +" " + value.partNum +" - " + value.itemSpecific;
          const request = {
            orderPayload: {
              partNumber: row.partNum,
              lineCode: event.value,
              dispatch: dispatch,
            },
            selectedValue: event.label ? event.label : modifiedId,
            lineCode: event.value,
          };
         let payload ={
          mfgCode : event.desc,
          partNumber:event.partNum
        }
      //  dispatch(videoArticleLinks(payload)).then((result) => {
      //   if(result.type == "videoArticleLinks/fulfilled" && !_isEmpty(result.payload) && (!_isEmpty(result.payload.articles) || !_isEmpty(result.payload.videos))){
      //     partsData[rowIndex].resources =  result.payload;
      //     partsData[rowIndex].videoFlag  = true
      //     setPartsData(_cloneDeep(partsData));
      //     partsTable();
      //   }}) 
          if (!_isEmpty(value)) {
            dispatch(getOrderNumber(request)).then((result) => {
              if (!_isEmpty(result.payload) && result.meta && result.meta.arg && result.meta.arg.selectedValue && result.payload[result.meta.arg.selectedValue].url !== undefined) {
                event.label = event.label ? event.label : modifiedId;
                const descVal = partsData[rowIndex].descriptionValues[descIndex];
                descVal.partDescription = result.payload[event.label].partDescription;
                descVal.partType = result.payload[event.label].partType;
                descVal.manufacturer = result.payload[event.label].manufacturer;
                descVal.orderId = result.payload[event.label].orderId;
                descVal.smartLink = result.payload[event.label].url;
                descVal.isEpartExpert = true;
                partsData[rowIndex].isEpartExpert = true;
                partsData[rowIndex].isLinkApiCalled = true;
                partsData[rowIndex].links = result.payload[event.label].url;
                partsData[rowIndex].selectedRowPart = descVal;
                setPartsData(partsData)
              }
              partsData[rowIndex].partNum = value.partNum;
              partsData[rowIndex].description = value.itemSpecific.toUpperCase();
              partsData[rowIndex].pl = value.mfgCode;
              partsData[rowIndex].qtyBuyInc = value.qtyBuyInc;
              partsData[rowIndex].altSub = value.altSub;
              let updatedQty = value.qtyBuyInc > 1 ? value.qtyBuyInc : partsData[rowIndex].qty ? partsData[rowIndex].qty : value.qty;
              partsData[rowIndex].qty = updatedQty
              partsData[rowIndex].yourPrice = value.costPrice;
              partsData[rowIndex].corePrice = value.corePrice;
              partsData[rowIndex].listPrice = value.listPrice;
              partsData[rowIndex].availability = value.availability;
              partsData[rowIndex].availabilityAll = value.availability;
              partsData[rowIndex].selectedAvl = value.availability;
              partsData[rowIndex].lostSales = true;
              partsData[rowIndex].totalExtendedPrice = getExtendedTotalPrice(
                value.corePrice,
                value.costPrice,
                updatedQty
              );
              partsData[rowIndex].select = value.availability.length > 0 ? true : false;
              partsData[rowIndex].selectedRowPart = value;
              setPartsData(partsData)
              partsTable();
            });
          }
        }
      });
    }
  };

  const onClickSelect = (e, row, index) => {
    try {
      if (_isEmpty(row.pl)) {
        return {
          disabled: true,
        };
      }
      if (row.showNoData === 1) {
        return {
          disabled: true,
        };
      }
      if (e.target.checked) {
        partsData[index].select = true;
        setPartSelect(false);
      } else if (e.target.checked === false && !_isEmpty(partsData)) {
        partsData[index].select = false;
        setPartSelect(true);
        if (partsData.find((item) => item.select === true)) {
          setPartSelect(false);
        }
      }
      partsTable();
    } catch (error) {}
  };

  const selectColFormatter = (cell, row, index) => {
    if (!(row.pl && row.description)) {
      return null;
    }
    else if(row.qty === 0){return null}
    else {
      return (
        <div>
          <input
            type="checkbox"
            class="select-style"
            disabled={row.availabilityAll?.length === 0}
            checked={row.select}
            onChange={(e) => {
              onClickSelect(e, row, index);
            }}
          />
        </div>
      );
    }
  };

  const prepareMoreRowData = (data, key, partNum, pickedup, isInterchangable) => {
    partsData[key].showInterchangeLink = isInterchangable;
    if (data.length === 1 && data[0].status === "2") {
      partsData[key].showNoData = 1;       
      partsData[key].noborderpart = 1;
      partsData[key].unique = _uniqueId(partsData[key].partNum);
    } else {
      let partRows = [];
      let descriptionValues = [];
      let partArr = [];
      let description = "";
      data.map((value, index) => {
        if((value.partNum === partNum && pickedup) || !pickedup){
        let product = prepareProductData(value);
        descriptionValues.push(product);
        description = value.itemSpecific;
        let altSub = value.status === "3"? "- <SUPERSEDED> - ": value.status === "6"? "- <ALTERNATE> - ": "";
        partRows.push({
          value: value.mfgCode,
          label: altSub + value.mfgCode +" " + value.partNum +" - " + value.itemSpecific,
          partNum : value.partNum,
          desc :value.itemSpecific
        });}
      });
      partArr = _uniqWith(partRows, _isEqual);
      partsData[key].productLine = partArr;
      partsData[key].productLineCopy = partArr;
      partsData[key].descriptionValues = descriptionValues;
      if (descriptionValues.length === 1) {
        let mfgCode = descriptionValues[0].mfgCode;
        let modifiedId = descriptionValues[0].modifiedId
        const request = {
          orderPayload: {
            partNumber: partNum,
            lineCode: mfgCode,
            dispatch: dispatch,
          },
          selectedValue: modifiedId,
          lineCode: mfgCode,
        };
       // videoArticleLinksMethod(descriptionValues[0]?.itemSpecific , partNum , key)   
        let payload ={
          mfgCode : descriptionValues[0]?.itemSpecific ,
          partNumber:partNum
        }
        // dispatch(videoArticleLinks(payload)).then((result) => {
        //   if(result.type == "videoArticleLinks/fulfilled" && !_isEmpty(result.payload) && (!_isEmpty(result.payload.articles) || !_isEmpty(result.payload.videos))){
        //   partsData[key].resources =  result.payload;
        //   partsData[key].videoFlag  = true ;
        //   setPartsData(_cloneDeep(partsData));
        //     partsTable();
        // }}) 
        partsTable(); 
          dispatch(getOrderNumber(request)).then((result) => {
            if (result.payload && result.meta && result.meta.arg && result.meta.arg.selectedValue && result.payload[result.meta.arg.selectedValue].url !== undefined) {
              const descVal = partsData[key].descriptionValues[0];
              descVal.partDescription = result.payload[modifiedId].partDescription;
              descVal.partType = result.payload[modifiedId].partType;
              descVal.manufacturer = result.payload[modifiedId].manufacturer;
              descVal.orderId = result.payload[modifiedId].orderId;
              descVal.smartLink = result.payload[modifiedId].url;
              descVal.isEpartExpert = true;
              partsData[key].links = result.payload[modifiedId].url;
              //partsData[key].resources = result.payload[modifiedId].resources;
              partsData[key].selectedRowPart = descVal;
              partsData[key].isEpartExpert = true;
              setPartsData(_cloneDeep(partsData));
              partsTable();
          }})
          partsData[key].partNum = descriptionValues[0].partNum;
          partsData[key].description = description.toUpperCase();
          partsData[key].pl = mfgCode;
          partsData[key].noborder = 1;
          partsData[key].select = descriptionValues[0].availability.length > 0 ? true :false;
          partsData[key].qty = partsData[key].qty ? partsData[key].qty : descriptionValues[0].qty;
          partsData[key].qtyBuyInc = descriptionValues[0].qtyBuyInc;
          partsData[key].yourPrice = descriptionValues[0].costPrice;
          partsData[key].corePrice = descriptionValues[0].corePrice;
          partsData[key].listPrice = descriptionValues[0].listPrice;
          partsData[key].availability = descriptionValues[0].availability;
          partsData[key].availabilityAll = descriptionValues[0].availability;
          partsData[key].lostSales = true;
          partsData[key].totalExtendedPrice = getExtendedTotalPrice(descriptionValues[0].corePrice,descriptionValues[0].costPrice,descriptionValues[0].qty
          );
          partsData[key].selectedRowPart = descriptionValues[0];
      }
      partsData[key].noborderpart = 1;
      partsData[key].unique = _uniqueId(partsData[key].partNum);
    }
    pushEmptyRows();
    setPartsData(_cloneDeep(partsData));
    partsTable();
  };

  const openVideosOrArticles = (url) => {
    window.open(url);
  };

  const handleBuyersGuideModal = (cell, row, index) => {
    setRowSelectedData(row);
    if (!buyersModalStatus) {
      getBuyersGuideData(
        selectedPartData.partNum,
        dispatch,
        selectedPartData.manufacturer
      );
      getBuyersGuideBrandData(selectedPartData.partNum, dispatch);
    }
    getC2CUrl(selectedPartData.partNum, selectedPartData.orderId, dispatch);
    setbuyersModalStatus(true);
  };

  const handleLostSales = (cell, row, index) => {
    setRowSelectedData(row);
    setshowLostSales(true);
  };

  const handleC2CModal = (cell, row, index) => {
    setRowSelectedData(row);
    setc2cModalStatus(true);
  };

  const handleInterchangeModal = (cell, row, index) => {
    setRowSelectedData(row);
    setInterChangePartNum(row.partNum);
    setInterchangeModalStatus(true);
  };

  const setRowSelectedData = (row) => {
    setSelectedPartData(row.selectedRowPart);
  };

  const handlePricingAvailbility2 = async (shipMtd = shipMethod, sellerID = sellerId, storeNum = stockStore, viewChanged = false , pickedup = false ) => {
    setIsViewChanged(false);
    let partSkus = [];
    partsData.forEach((v) => delete v.new);
    await _each(partsData, (val, key) => {
      if (val.partNum === "") {
        delete partsData[key].partNum;
      }
      if ((!_isEmpty(val.partNum) && _isEmpty(val.productLine) && !val.showNoData) || (!_isEmpty(val.partNum) && viewChanged)) {
        let reqItems = [];
        reqItems.push({
          mfgCode: val.pl ? val.pl : "*",
          partNum: val.partNum,
          qty: val.qty ? val.qty : 1,
        });
        const payload = {
          requestItems: reqItems,
          sellPartnerId: sellerID,
          shipMethod: shipMtd,
          store: storeNum,
          dispatch: dispatch,
        };
        setShowLoadermagic(true);
        let isInterchangable = false;
        let priceData = [];
       dispatch(getPartsPricingAvailability(payload))
          .then((action) => {
            priceData = action.payload;
            if (!_isEmpty(priceData) && priceData.length < 2) {
              let { status } = priceData[0];
              const payload = {
                partNumber: val.partNum,
                priority: "P",
                mfg: "ALL",
                partType: "ALL",
                sellPartnerId: sellerID,
                shipMethod: shipMtd,
                store: storeNum,
              };
              if (status === "2") {
                dispatch(stockCheckInterchange(payload)).then((action) => {
                  setShowLoadermagic(false);
                  if (!_isEmpty(action.payload)) {
                    isInterchangable = true;
                    prepareMoreRowData(
                      priceData,
                      key,
                      val.partNum,
                      pickedup,
                      isInterchangable
                    );
                  } else {
                    prepareMoreRowData(priceData, key, val.partNum ,pickedup, isInterchangable);
                  }
                })
              } else {
                setShowLoadermagic(false);
                prepareMoreRowData(priceData, key, val.partNum ,pickedup, isInterchangable);
              }
            } else {
              setShowLoadermagic(false);
               prepareMoreRowData(priceData, key, val.partNum ,pickedup, isInterchangable);
            }
          })
          .catch((error) => {
            console.log("error" + error);
            setShowLoadermagic(false);
          });
          partSkus.push(val.partNum);
      }
    });
    setPartNum("");
    var allPartSkus = partSkus.join();
    let {
      billTo: { billToId: customerId },
      shipTos,
      x2userName: userName,
    } = userDetails;
    let loginId = "";
    dispatch(
      logUserStockActivity(
        timeInUTC(),
        customerId,
        loginId,
        shipTos[0].shipToId,
        "STOCK_CHECK",
        allPartSkus,
        userName
      )
    );
  };

  const handlePricingAvailbility = async (shipMtd = shipMethod, sellerID = sellerId, storeNum = stockStore, viewChanged = false , pickedup = false ) => {
    setIsViewChanged(false);
    let partSkus = [];
    partsData.forEach((v) => delete v.new);
	  // await Promise.all(_each(partsData, async (val, key) => {
    // const contents = await fs.readFile(file, 'utf8')
    // console.log(contents)
    // }));
    let  key = 0;
    for (const val of partsData) {
    //await _each(partsData, (val) => {
      if (val.partNum === "") {
        delete partsData[key].partNum;
      }
      if ((!_isEmpty(val.partNum) && _isEmpty(val.productLine) && !val.showNoData) || (!_isEmpty(val.partNum) && viewChanged)) {
        let reqItems = [];
        reqItems.push({
          mfgCode: val.pl ? val.pl : "*",
          partNum: val.partNum,
          qty: val.qty ? val.qty : 1,
        });
        const payload = {
          requestItems: reqItems,
          sellPartnerId: sellerID,
          shipMethod: shipMtd,
          store: storeNum,
          dispatch: dispatch,
        };
        setShowLoadermagic(true);
        let isInterchangable = false;
        let priceData = [];
        await dispatch(getPartsPricingAvailability(payload))
          .then((action) => {
            priceData = action.payload;
            if (!_isEmpty(priceData) && priceData.length < 2) {
              let { status } = priceData[0];
              const payload = {
                partNumber: val.partNum,
                priority: "P",
                mfg: "ALL",
                partType: "ALL",
                sellPartnerId: sellerID,
                shipMethod: shipMtd,
                store: storeNum,
              };
              if (status === "2") {
                dispatch(stockCheckInterchange(payload)).then((action) => {
                  setShowLoadermagic(false);
                  if (!_isEmpty(action.payload)) {
                    isInterchangable = true;
                    prepareMoreRowData(
                      priceData,
                      key,
                      val.partNum,
                      pickedup,
                      isInterchangable
                    );
                  } else {
                    prepareMoreRowData(priceData, key, val.partNum ,pickedup, isInterchangable);
                  }
                })
              } else {
                setShowLoadermagic(false);
                prepareMoreRowData(priceData, key, val.partNum ,pickedup, isInterchangable);
              }
            } else {
              setShowLoadermagic(false);
               prepareMoreRowData(priceData, key, val.partNum ,pickedup, isInterchangable);
            }
          })
          .catch((error) => {
            console.log("error" + error);
            setShowLoadermagic(false);
          });
          partSkus.push(val.partNum);
      }
      key++;
    }
    //});
    setPartNum("");
    var allPartSkus = partSkus.join();
    let {
      billTo: { billToId: customerId },
      shipTos,
      x2userName: userName,
    } = userDetails;
    let loginId = "";
    dispatch(
      logUserStockActivity(
        timeInUTC(),
        customerId,
        loginId,
        shipTos[0].shipToId,
        "STOCK_CHECK",
        allPartSkus,
        userName
      )
    );
  };


  const pushEmptyRows = ()=> {
    if (partsData.length - partsData.filter((p) => p.partNum).length === 1) {
      partsData.push({
        id: 3,
        emptySelection: true,
        selected: false,
      });
    } else if (
      partsData.length - partsData.filter((p) => p.partNum).length ===
      0
    ) {
      partsData.push({
        id: 3,
        emptySelection: true,
        selected: false,
      });
      partsData.push({
        id: 4,
        emptySelection: true,
        selected: false,
      });
    }
  }

  const prepareProductData = (data) => {
    return {
      inValidPartData: data.status === "2" ? true : false,
      altSub: data.status === "3"? "- <SUPERSEDED> - ": data.status === "6"? "- <ALTERNATE> - ": "",
      addToOrder: data.availability.length > 0 ? true : false,
      availability: data.availability,
      availabilityAll: data.availability,
      partNum: data.partNum,
      extendedCostPrice: data.extendedCostPrice,
      coreListPrice: data.coreListPrice,
      qtyPerVeh: data.qtyPerVeh,
      corePrice: data.corePrice,
      qtyBuyInc: data.qtyBuyInc,
      mfgCode: data.mfgCode,
      itemSpecific: data.itemSpecific,
      costPrice: data.costPrice,
      listPrice: data.listPrice,
      isImport: false,
      isEpartExpert: false,
      smartLink: "",
      orderId: "",
      manufacturer: "",
      partType: "",
      partDescription: "",
      qty: data.qtyBuyInc > 1 ? data.qtyBuyInc : 1,
      uniqId: Math.random(),
      modifiedId: `${data.mfgCode} ${data.partNum} - ${data.itemSpecific}`,
      reqPartNum: data.partNum,
    };
  };

  const qtyByINCUpdateSuccessMessage = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = displayQtyChangeMsg();
    statusMessageProps.type = "success";
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  };

  const handleAvailabilitySelection = (event, row, rowIndex,totalAvailqty) => {
    if(userDetails?.billTo?.mclSupplier !== 'CD' || userDetails?.billTo?.mclSupplier === 'CD'){
    let { target: { value }} = event;
    if (!_isEmpty(value)) {
      if (Number(value) !== 0) {
        let totalValue = getPartPackQty(row.qtyBuyInc, value, totalAvailqty);
        if (totalValue != value && row.qtyBuyInc > 1) {
          qtyByINCUpdateSuccessMessage();
          handleAvailabilitySelectionOnQtyChange({ target: { value: totalValue } }, row, rowIndex,totalAvailqty)
          partsTable(partsData);
        }
      } 
    }
    }
  };

  const handleKeyDownCall = (event) => {
    if((event.key == 'Enter' && event.keyCode == 13) || (event.key == 'Tab' && event.keyCode == 9 )) {
        setTimeout(() => {
          handlePricingAvailbility(shipInfoObjt.shipMethod, shipInfoObjt.sellerId, shipInfoObjt.store, isViewChanged, isPickup);
          }, 200);
    }
  }

  const onclick = (tempData) => {
    setStoreData(tempData)
    setShowWithinPopup(true)
  }

  const checkIfMobileEnabled = (tempData, text) =>{
    return(
      userDetails?.shipTos[0]?.isMobileEnabled ? 
    <u className="text-color" onClick={()=>{onclick(tempData)}}>{text}</u> :
    <u>{text}</u>
    )
  }

const setProductLineOnMouse = (partNum, row, rowIndex) => {
  if (!row.productLine && row.partNum) {
    let value = partNum.toUpperCase();
    let seletedProductArr = partsData.find(
      (product) => product.partNew === value
    );
    if (
      seletedProductArr?.productLineCopy &&
      seletedProductArr.descriptionValues?.length > 1
    ) {
      partsData[rowIndex].productLine = seletedProductArr.productLineCopy;
      partsData[rowIndex].descriptionValues =
        seletedProductArr.descriptionValues;
      partsData[rowIndex].unique = _uniqueId(partsData[rowIndex].partNum);
      partsData[rowIndex].noborderpart = 1;
      partsTable();
    }
  }
};

  const availabilityColFormatter = (cell, row) => {
    let { store } = shipInfoObjt;
    if(row.productLine){  
    const availabilityOptns = [];

    if (row.availabilityAll?.length === 0 && !row.isPartNotFound) {
      return <div className="out-of-stock">
      <span>Currently out of stock</span>
    </div>;
    }

    if (row.availabilityAll !== undefined && row.availabilityAll.length !== 0 ) {
      row.availabilityAll?.map((item) => {
        let time = item.availableDuration.split("");
        const optionText = (
          <span className="availability_container -ml-2">
            <li>
              <span className={enableBlackColorBasedOnStorePref(store) ? "text-black" : "maroonColorTxt"}>
              <b>{item.availableQty}</b>
              </span>
            {" "}{_head(time) === "0" ? checkIfMobileEnabled(item.storeAvailabilities, "Within") : ""}{" "}
            { _head(time) === "0" ? formatTimeAvb(item.availableDuration) : checkIfMobileEnabled(item.storeAvailabilities,item.availableDuration) }
            </li>
          </span>
        );
        availabilityOptns.push(optionText);
      });
      return availabilityOptns;
    }
   
    return <span></span>;
  }
  };

  const checkIsPartInCart = (partNum, mfgCode) => {
    let str = "";
    let count = 0;
    let qty = 0;
    cart.cartAllDetails.map((item) => {
      if (item.partNum === partNum && item.mfgCode === mfgCode) {
        qty += item.qty;
        count = qty;
        str = qty + ` in Cart`;
      }
    });
    return { label: str, count: count };
  };

  const partsTable = (rows) => {
    if (partsData.find((item) => item.select === true)) {
      setPartSelect(false);
    }
    const columns = [
      {
        dataField: "pl",
        text: "P/L",
        headerStyle: headerStyle2,
        formatter: function (cell, row, index) {
          if (!row.noborder) {
          return (
            <Fragment>
              <Form.Control
                type="text"
                name="pl"
                onChange={(e) => {
                  onChangePLField(e, row, index);
                }}
                onKeyDown={(event) => {
                  handleKeyDownAlphabet(event);
                }}
                value={row.pl}
                className="qty_input_field1 stockcheck-font"
                maxLength="3"
              />
            </Fragment>
          );
        } else {
          return <div className="stockcheck-font pl">{row.pl}</div>;
        }
        },
      },
      {
        dataField: "partNum",
        text: "Item#",
        editable: true,
        headerStyle: partStyle,
        formatter: function (cell, row, index) {
          if(!row.partNew) { row.partNew = row.partNum}
          if (!row.noborderpart) {
          return (
            <Fragment>
              <Form.Control
                type="text"
                name="partNum"
                placeholder="Enter Part Number"
                onChange={(event) => {
                  setInputAuto(true);
                  setPartForRow(event.target.value, row, index);
                }}
                // TODO: qty change to default
                onMouseLeave={(event) =>
                  setProductLineOnMouse(event.target.value, row, index)
                }
                onKeyDown={handleKeyDownCall}
                maxLength="20"
                value={row.partNum}
                className="qty_input_field1 placeholder-font"
                autoFocus={
                  row.new || (row.id == 1 && row.id !== 2) || row.partNum
                }
              />
            </Fragment>
          );
        } else {
          return (
              <div className="stockcheck-font pl" >{row.partNum}</div>
          );
          // {!_isEmpty(row.partNum) && hasWhiteSpace(row.partNum) && <span className="error-message">"Part number should not contain space"</span>}
        }
        },
      },
      {
        dataField: "qty",
        text: "Qty",
        editable: true,
        headerStyle: qtyStyle,
        formatter: function (cell, row, index) {
          let totalAvailqty = 0;
          let uniqueData = _uniqBy(
            row.availability,
            "availableDuration"
          );
          uniqueData.map((singleAvailability, key) => {
            if (singleAvailability.availableQty > 0) {
              totalAvailqty += singleAvailability.availableQty;
            }
          });
          let errorMessage =
            "Please enter a quantity less than" + ` ${totalAvailqty + 1}`;
          if (
            checkIsPartInCart(row.partNum, row.pl).count &&
            checkIsPartInCart(row.partNum, row.pl).count > 0
          ) {
            let alreadyAvailableItemInCart = checkIsPartInCart(
              row.partNum, row.pl
            ).count;
            totalAvailqty = totalAvailqty - alreadyAvailableItemInCart;
            errorMessage =
              "Please enter a quantity less than" + ` ${totalAvailqty + 1} `;
          }
          return ( 
             <Fragment>
              <OverlayTrigger
                  placement="right"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    (row.qty > totalAvailqty) && totalAvailqty !== 0 ? (
                      <Tooltip
                        id="description-part-catalog-tooltip"
                        className="tooltip-description"
                      >
                        <span className="font-weight-bold">{errorMessage}</span>
                      </Tooltip>
                    ) : (
                      <span></span>
                    )
                  }
                >
              <Form.Control
                type="text"
                name="qty"
                className="qty_input_field1 stockcheck-font"
                maxLength="5"
                value={!row.isPartNotFound ? row.qty : ""}
                onChange={(e) => {
                  onChangePartsQty(e, row, index,totalAvailqty);
                  //handleAvailabilitySelection(e, row, index, totalAvailqty);
                }}
                onBlur={(e) => {
                  handleAvailabilitySelection(e, row, index, totalAvailqty);
                }}
                onMouseLeave={(e) => handleAvailabilitySelection(e, row, index, totalAvailqty)}
                defaultValue={row.showNoData ? "" : row.partNum ? 1 : ""}
                // onKeyDown={(event) => {
                //   handleKeyDown(event);
                // }}
                onKeyDown={handleKeyDownCall}
                autoFocus={(row.qty || (row.pl && row.qty === "")) && true}
              />
              </OverlayTrigger>
            </Fragment>
          );
        },
      },
      {
        dataField: "productLine",
        text: "Description",
        sort: true,
        editable: false,
        headerStyle: DescriptionHeaderStyle,
        formatter: function (cell, row, index) {
          if (!row.isPartNotFound && (!_isEmpty(row.productLine) || !_isEmpty(row.description))) {
            return (
              <div className="stockcheck-select">
                {row.productLine && row.productLine.length > 1 ? (
                  <Select
                    options={!_isEmpty(row.productLine) && row.productLine}
                    name="mfgCode"
                    placeholder="Select Manufacturer ..."
                    className="custom-dropdown-stockcheck stockcheck-font"
                    onChange={(e) => handleMfgCodeSelection(e, row, index)}
                    disabled={row.selected}
                    value={row.mfgCode}
                    autoFocus={true}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#84bae2",
                        primary50: "#0F5283",
                        primary: "#0F5283",
                      },
                    })}
                  />
                ) : (
                  <div>
                    <div className="stockcheck-description stockcheck-font">
                    <span className="maroonColorTxt pr-1">{row.altSub ? row.altSub  : ""}</span>
                      {row.description}
                    </div>
                    {/* {
                      <span style={{ visibility: "hidden" }}>
                        {(row.mfgCode = row.productLine?.value)}
                      </span>
                    } */}
                  </div>
                )}
              </div>
              //)
            );
          } else {
            return (
              <div className="part-not-found stockcheck-font">
                {" "}
                {row.showNoData === 1 && (
                  <span className="error-message">
                    Part number not found{" "}
                    {row.showInterchangeLink === true && (<a
                      className="text-black click-for-inter"
                      onClick={(e) => {
                        handleInterchangeModal(e, row, index);
                      }}
                    >
                      click here for Interchange
                    </a>)}
                  </span>
                )}
              </div>
            );
          }
        },
      },
      {
        dataField: "descriptionValues",
        text: "descriptionValues",
        hidden: true,
      },
      {
        dataField: "description",
        text: "Description",
        hidden: true,
        formatter: function (cell, row, index) {
          return (
            <div className="stockcheck-description stockcheck-font">
              {row.description}
            </div>
          );
        },
      },
      {
        dataField: "showNoData",
        text: "showNoData",
        hidden: true,
      },
      {
        dataField: "showInterchangeLink",
        text: "showOnterchangeLink",
        hidden: true,
      },
      {
        dataField: "isPartNotFound",
        text: "isPartNotFound",
        hidden: true,
      },
      {
        dataField: "price",
        text: "price",
        hidden: true,
      },
      {
        dataField: "emptySelection",
        text: "empty",
        hidden: true,
      },
      {
        dataField: "isLinkApiCalled",
        text: "empty",
        hidden: true,
      },
      {
        dataField: "links",
        text: "Links",
        headerStyle: headerStyle3,
        headerAlign: "center",
        formatter: function (cell, row, index) {
          if ((row.videoFlag) || (row.isEpartExpert)) {
          // if ((row.isEpartExpert)) {
            return (
              <Fragment>
                <div className="stock-check-links-container">
                  <img src={linkIcon} alt="Link" />
                  <div>
                    <ul>
                      {row.isEpartExpert &&(
                      <li>
                        <a
                          href="#"
                          onClick={(e) => {
                            handleC2CModal(e, row, index);
                          }}
                          title="Cover to Cover"
                        >
                          Cover to Cover
                        </a>
                      </li>)}
                      {showInterchange && !_isEmpty(row.pl) && row.isEpartExpert&& (
                        <li>
                          <a
                            href="#"
                            onClick={(e) => {
                              handleInterchangeModal(e, row, index);
                            }}
                          >
                            Interchange
                          </a>
                        </li>
                      )}
                      {showBuyersGuide && !_isEmpty(row.pl) && row.isEpartExpert && (
                        <li>
                          <a
                            href="#"
                            onClick={(e) => {
                              handleBuyersGuideModal(e, row, index);
                            }}
                          >
                            Buyers Guide
                          </a>
                        </li>
                      )}
                  {row  && row.videoFlag &&
                      !_isEmpty(row.resources) &&
                      ((row.resources.articles &&
                        row.resources.articles.length > 0) ||
                        (row.resources.videos &&
                          row.resources.videos.length > 0)) && (
                        <li className="fullview_sublinks_Container">
                          <a href="#/">Resources</a>
                          <div>
                            <ul>
                              {row.resources.videos &&
                                row.resources.videos.length > 0 && (
                                  <li className="video_links_Container">
                                    <a href="#/">Videos</a>
                                    <div className="video_articles">
                                      <ul>
                                        {row.resources.videos.map(
                                          (v) => {
                                            return (
                                              <li
                                                onClick={() => {
                                                  openVideosOrArticles(
                                                    v.link
                                                  );
                                                }}
                                              >
                                                <a href="#/">{v.description}</a>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </li>
                                )}
                              {row.resources.articles &&
                                row.resources.articles.length > 0 && (
                                  <li className="article_links_Container">
                                    <a href="#/">Articles</a>
                                    <div className="video_articles">
                                      <ul>
                                        {row.resources.articles.map(
                                          (a) => {
                                            return (
                                              <li
                                                onClick={() => {
                                                  openVideosOrArticles(
                                                    a.link
                                                  );
                                                }}
                                              >
                                                <a href="#/">{a.description}</a>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </li>
                                )}
                            </ul>
                          </div>
                        </li>
                    )}
                    </ul>
                  </div>
                </div>
              </Fragment>
            );
          } else {
            return <div className="stockcheck-font"></div>;
          }
        },
      },
      {
        dataField: "yourPrice",
        hidden: hideAllPrice,
        text: <span>Your<br />Price</span>,
        headerStyle: headerStyle2,
        formatter: function (cell, row, index) {
          return <div className="stockcheck-price-font">{row.yourPrice}</div>;
        },
      },
      {
        dataField: "corePrice",
        text: "Core Price",
        hidden: hideAllPrice,
        headerStyle: headerStyle2,
        formatter: function (cell, row, index) {
          return <div className="stockcheck-price-font">{row.corePrice}</div>;
        },
      },
      {
        dataField: "listPrice",
        hidden: hideAllPrice,
        text: <span>List<br />Price</span>,
        headerStyle: headerStyle2,
        formatter: function (cell, row, index) {
          return <div className="stockcheck-price-font">{row.listPrice}</div>;
        },
      },
      {
        dataField: "totalExtendedPrice",
        text: "Total Extended Price",
        hidden: hideAllPrice,
        headerStyle: headerStyle2,
        formatter: function (cell, row, index) {
          return (
            (row.totalExtendedPrice != "NaN") ? <div className="stockcheck-price-font">{row.totalExtendedPrice}</div>:
            <span></span>
          );
        },
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <span>Extended Price {""}</span>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 400 }}
                overlay={
                  <Tooltip
                    id="extended-price-tooltip"
                    className="tooltip-description-custom-corePrice"
                  >
                    <span className="font-weight-bold">
                      Total Extended Price = Qty * (Your Price + Core Price)
                    </span>
                  </Tooltip>
                }
              >
                <a className="whiteColorTxt info-icon">
                  <i class="fa fa-info-circle"></i>
                </a>
              </OverlayTrigger>
            </div>
          );
        },
      },
      {
        dataField: "selectedRowPart",
        text: "selectedRowPart",
        hidden: true,
      },
      {
        dataField: "availability",
        text: "Availability",
        sort: true,
        editable: true, 
        hidden: true,
        formatter:  userDetails?.billTo?.mclSupplier == 'CD' && false ?   availabilityColFormatterForCD : availabilityColFormatter,
      },
      {
        dataField: "availabilityAll",
        text: "Availability",
        hidden: true,
      },
      {
        dataField: "selectedAvailabilityHours",
        text: "Selected Availability",
        hidden: true,
      },
      {
        dataField: "selectedAvl",
        text: "Availability",
        //headerAlign: "center",
        headerStyle: availabilityStyle,
        formatter: userDetails?.billTo?.mclSupplier == 'CD' && false ? availabilityColFormatterForCD : availabilityColFormatter,
        //formatter: availabilityColFormatter,
      },
      {
        dataField: "select",
        text: "Select",
        headerStyle: headerStyle1,
        formatter: selectColFormatter,
      },
      {
        dataField: "new",
        text: "new",
        hidden: true,
      },
      {
        dataField: "lostSales",
        text: "",
        headerStyle: headerStyle3,
        formatter: function (cell, row, index) {
          if (row.lostSales && !row.isPartNotFound) {
            return (
              <Fragment>
                <button
                  className="lost_sales_button"
                  onClick={(e) => {
                    handleLostSales(e, row, index);
                  }}
                >
                  Lost Sales
                </button>
              </Fragment>
            );
          } else {
            return <div className="stockcheck-font"></div>;
          }
        },
      },
    ];
    setPartsColumns(columns);
    setPartsData(partsData);
    //showInterChangeByDefault(partsData);
  };

  const prepareTooltipAvailability = (availabilityData) => {
    let availabilities = availabilityData;
    let returnArray = [];
    availabilities?.map((singleAvailability, key) => {
      if (singleAvailability.availableQty > 0) {
        if (
          singleAvailability.availableDuration === "Next Business Day" ||
          singleAvailability.availableDuration === "Next 2 + Business Days" ||
          singleAvailability.availableDuration === "Next 2 Weeks"
        ) {
          returnArray.push(
            <ul>
              <li>
                <span className="text-white">
                  <span className="store-maroon-text">
                    {singleAvailability.availableQty}
                  </span>
                  &nbsp; &nbsp;
                  {singleAvailability.availableDuration}{" "}
                </span>
              </li>
            </ul>
          );
        } else {
          singleAvailability.storeAvailabilities.map((storeData, storeKey) => {
            if (key != 0) {
              returnArray.push(
                <ul>
                  <li>
                    <span className="text-white">
                      <b>{storeData.storeQty}</b>
                    </span>
                    <span className="text-white">
                      &nbsp;{` at Store #`}
                      <b>{storeData.store}</b>
                      <br />
                      {singleAvailability.availableDuration ===
                        "Direct Order Store" ||
                      singleAvailability.availableDuration ==
                        "Pick Up Store" ? (
                        <b>
                          ({singleAvailability.availableDuration},
                          {storeData.storeQualifier
                            ? storeData.storeQualifier
                            : ""}
                          )
                        </b>
                      ) : null}
                    </span>
                  </li>
                </ul>
              );
            }
          });
        }
      }
    });
    let fullDiv = [];
    fullDiv = <div>{returnArray}</div>;
    return fullDiv;
  };

  const availabilityColFormatterForCD = (cell, row) => {
    if(row.productLine){
    if (row.availabilityAll?.length === 0) {
      return <div className="out-of-stock">
      <span>Currently out of stock</span>
    </div>;
    }
    if (row.availabilityAll && row.availabilityAll.length !== 0) {
    let uniqueData = _uniqBy(row.availabilityAll, "availableDuration");
    let enableOtherAvailabilities =
      uniqueData.length > 0 &&
      uniqueData[0].availableQty &&
      uniqueData[0].availableQty >= 1
        ? false
        : true;
    let otherStoreAvailability = 0;
    uniqueData.map((unique, index) => {
      if (index != 0) {
        otherStoreAvailability += unique.availableQty;
      }
    });

    return (
      <div className="availability-container-sc">
        <div className="availability-subdiv">
          {uniqueData.map((unique, index) => {
            return (
              (unique.availableDuration === "Your Store" ||
                unique.availableDuration === "Store") && (
                <ul>
                  <li>
                    <span className="availability-text">
                      {unique.availableQty == 0 ? (
                        <span className="store-maroon-text compact-text-grey-Zero">
                          <b>{unique.availableQty}</b>
                        </span>
                      ) : (
                        <span className="store-maroon-text compact-text-grey">
                          <b>{unique.availableQty}</b>
                        </span>
                      )}
                      &nbsp;at &nbsp;
                      {unique.availableDuration}{" "}
                      {unique.storeAvailabilities.length > 0 &&
                      unique.storeAvailabilities[0].store ? (
                        <b>#{unique.storeAvailabilities[0].store}</b>
                      ) : null}
                    </span>
                    {true && !enableOtherAvailabilities ? (
                      row.selectedQuantity &&
                      row.selectedQuantity > uniqueData[0].availableQty ? (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 100, hide: 4000 }}
                          show={
                            row.selectedQuantity &&
                            row.selectedQuantity > uniqueData[0].availableQty
                          }
                          overlay={
                            <Tooltip
                              id="toggle-view-full-compact"
                              className="custom-tooltip-availability apply-max-height"
                            >
                              {prepareTooltipAvailability(row.availabilityAll)}
                            </Tooltip>
                          }
                        >
                          <a className="i_icon-tooltip info-icon">
                            <i class="fa fa-info-circle"></i>
                          </a>
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 100, hide: 4000 }}
                          overlay={
                            <Tooltip
                              id="toggle-view-full-compact"
                              className="custom-tooltip-availability apply-max-height"
                            >
                              {prepareTooltipAvailability(row.availabilityAll)}
                            </Tooltip>
                          }
                        >
                          <a className="i_icon-tooltip info-icon">
                            <i class="fa fa-info-circle"></i>
                          </a>
                        </OverlayTrigger>
                      )
                    ) : null}
                  </li>
                </ul>
              )
            );
          })}
          {true && enableOtherAvailabilities ? (
            <ul>
              <li>
                <span className="availability-text">
                  <span className="store-maroon-text compact-text-green ml-3">
                    <b>{otherStoreAvailability}</b>
                  </span>
                  &nbsp;at Other Stores &nbsp;{" "}
                </span>
                {true && enableOtherAvailabilities ? (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 100, hide: 4000 }}
                    overlay={
                      <Tooltip
                        id="toggle-view-full-compact"
                        className="custom-tooltip-availability apply-max-height"
                      >
                        {prepareTooltipAvailability(row.availabilityAll)}
                      </Tooltip>
                    }
                  >
                    <a className="i_icon-tooltip info-icon">
                      <i class="fa fa-info-circle"></i>
                    </a>
                  </OverlayTrigger>
                ) : null}
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    );}
    return <span></span>;
   }
  };

  const getProductQtyBasedonQtyByInc = (allAvailability, qty, qtyBuyInc) => {
    let totalAvailqty = 0;
    allAvailability.map((item) => {
			totalAvailqty += item.availableQty;
		});
    return getPartPackQty(qtyBuyInc, qty, totalAvailqty)
  }

  const handleAddToCart = () => {
    let requestPayload = [];
    let {shipMethod, sellerId, store} = shipInfoObjt;
    let cloneCart = _cloneDeep(cart);
    if(isStockOrder(orderType) || deliveryPickupWarning(shipMethod, stockStore)){
      cloneCart.checkoutDetails = [];
    }
    if (!_isEmpty(partsData)) {
      partsData.map((partRow) => {
        const product = partRow.selectedRowPart;
        const isRowSelected = partRow.select;
        const allAvailabilityData = !_isEmpty(product?.availability) ? product?.availability : [];
        let qty = getProductQtyBasedonQtyByInc(allAvailabilityData, partRow?.qty, product?.qtyBuyInc);
        if (product && isRowSelected) {
          let tempObj = {
            availabilityHour: "",
            availableQty: 0,
            cartFlag: "no",
            corePrice: product.corePrice,
            coreListPrice: product.coreListPrice,
            costPrice: product.costPrice,
            desc: product.itemSpecific,
            listPrice: product.listPrice,
            mfgCode: product.mfgCode,
            qtyBuyInc: product.qtyBuyInc,
            packQty: product.packQty,
            partNum: product.partNum,
            qty: qty,
            vehicleInfo: "Non-Vehicle Specific",
            shipMethod: shipMethod,
            store: store,
            sellPartnerId: sellerId,
            //toolTip: JSON.stringify(product.toolTips)
          };
          let orderArray = [];
          cloneCart.checkoutDetails = requestPayload.length > 0 ? requestPayload : cloneCart.checkoutDetails;
          let distributionData = getDistributionForNonVehicle(
            product.partNum,
            product,
            product.availability,
            qty,
            cloneCart
          );
          orderArray = distributeAvailability2(
            Number(distributionData.totalQty),
            distributionData.filteredAvailability
          );
          if (product.addToOrder) {
            requestPayload = [];
            requestPayload.push(...distributionData.alreadyAddedItem);
            orderArray.map((availabilityData) => {
              tempObj = {
                ...tempObj,
                availabilityHour: availabilityData.availableDuration,
                availableQty: availabilityData.availableQty,
                qty: !_isEmpty(availabilityData.orderQty.toString())
                  ? availabilityData.orderQty.toString()
                  : "1",
                distStatus: "Completed",
                orderType: "REGULAR"
              };
              requestPayload.push(tempObj);
            });
          }
        }
      });
      let uniqueData = requestPayload.filter((ele, ind) => ind === requestPayload.findIndex( elem => elem.partNum === ele.partNum && elem.mfgCode === ele.mfgCode && (elem.vehicleInfo === ele.vehicleInfo) && (elem.availabilityHour === ele.availabilityHour)))
      setAddToCartPayload(uniqueData);
      if (isStockOrder(orderType) || deliveryPickupWarning(shipMethod, store)) {
				setaddToCartWarningPopup(true);
   		} else {
        addItemToCart2(uniqueData, dispatch);
        clearAll(); // TODO: clear only after addItemToCart is successful
        setPartSelect(true);
       dispatch(resetPageContent());
      }
    }
  };

  const deliveryPickupWarning = (shipMethod , store) => {
    let warning = false;
    if(checkoutDetails.length > 0 && ((checkoutDetails[0].shipMethod != shipMethod) || (checkoutDetails[0].store != store))) {
       warning = true
    } 
    return warning;
  }

  const onClickExpand = () => {
    partsData.forEach((v) => delete v.new);
    partsData.push({
      emptySelection: true,
      selected: false,
    });
    partsData.push({
      emptySelection: true,
      selected: false,
    });
    partsData.map((v, i) => {
      if (
        v.partNum === undefined &&
        !partsData.some((p) => {
          return p.new;
        })
      ) {
        partsData[i].unique = _uniqueId();
        partsData[i].new = true;
      }
    });
    partsTable();
  };

  
  const clearAll = () => {
    setPartsData([]);
    partsData.length = 0;
    partsData.push(
      {
        id: 1,
        emptySelection: true,
        selected: false,
      },
      {
        id: 2,
        emptySelection: true,
        selected: false,
      }
    );
    dispatch(resetPageContent());
    setPartsData(partsData);
    partsTable();
    setPartNum("");
    setTimeout(() => {
      setPartsData([]);
      partsData.length = 0;
      partsData.push(
        {
          id: 1,
          emptySelection: true,
          selected: false,
        },
        {
          id: 2,
          emptySelection: true,
          selected: false,
        }
      );
      setPartsData(partsData);
      partsTable();
      setPartNum("");
      setPartSelect(true);
    }, 10);
  };

  const handleFetchEnable = () => {
    let bool = partsData.some((product) => {
      return (
        product.partNum &&
        !product.productLine &&
        !product.showNoData
      );
    });
    setPartNum(bool);
  };

  const handleViewTypeChange = (pickupDelivery) => {
    let shipInfo = {};
    let shipMethod =
    pickupDelivery !== "isPickup"
      ? userDetails &&
        userDetails.shipTos &&
        userDetails.shipTos.length > 0 &&
        userDetails.shipTos[0].shipMethod &&
        userDetails.shipTos[0].shipMethod
      : "0002";
  let defaultShipInfo = getUserDefaultShipInfo(userDetails);
    shipInfo.shipMethod = shipMethod;
    shipInfo.sellerId = defaultShipInfo.sellerPartnerId;
    shipInfo.store = defaultShipInfo.storeNumber;
    Object.assign(shipInfoObjt, shipInfo);
    setMagicShipData(shipInfo);
    setIsViewChanged(true);
    setIsPickup(shipMethod === '0002'|| shipMethod === 'ECOM' ? true : false);
    setShipMethod(shipMethod)
    handlePricingAvailbility(shipMethod , customerSellerId , customerStore, true ,true);
  };

  const storeInfo = (key, storeDetail) => {
    let filteredStore = _find(storeDetail, (storeData) => (
      (storeData.sellerPartID === key)
    ));
    let storeNo = filteredStore.storeNum;
    let shipInfo = {};
    shipInfo.shipMethod = shipMethod;
    shipInfo.sellerId = key;
    shipInfo.store = storeNo;
    Object.assign(shipInfoObjt, shipInfo);
    setMagicShipData(shipInfo);
     setSellerId(key)
     setStockStore(storeNo);
     setIsViewChanged(true);
     setIsPickup(true);
      handlePricingAvailbility(shipMethod, key, storeNo, true ,true); 
  }

  const resetStockCheck = () => {
    
      clearAll(); // TODO: clear only after addItemToCart is successful
      setPartSelect(true);
    
  }

  return (
    <Fragment>
      <div className="stockcheck_shippingdropdown">
      <PickupDelivery
            handleViewTypeChange = {handleViewTypeChange}
            storeSellerId = {storeInfo}
          />
      </div>
      <div className="stock_check_table">
        <div style={{ border: "1px solid #ced4da" }}>
        {showLoadermagic && (
              <div className="custom_spinner_container catalog_spinner">
                <Spinner className="custom_spinner" animation="border" />
              </div>
          )}
          {partsColumns.length > 0 && (
            <BootstrapTable
              keyField="unique"
              data={partsData}
              columns={partsColumns}
              wrapperClasses="stockcheck-table-responsive"
              tabIndexCell
              bordered={false}
            />
          )}
          {c2cModalStatus && selectedPartData.smartLink && (
            <CoverToCoverPopup
              openPopup={c2cModalStatus}
              url={selectedPartData.smartLink
                .replace("http:", "")
                .replace("https:", "")}
              closePopup={() => setc2cModalStatus(false)}
            />
          )}
          {interchangeModalStatus && (
            <XModal
              show={interchangeModalStatus}
              showButtons={false}
              title="Interchange"
              handleClose={() => setInterchangeModalStatus(false)}
            >
              <Interchange
                partNumber={!_isEmpty(selectedPartData) && !_isEmpty(selectedPartData.partNum) ? selectedPartData.partNum : interChangePartNum}
                partBrand={!_isEmpty(selectedPartData) && !_isEmpty(selectedPartData.manufacturer) ? selectedPartData.manufacturer : null}
                partType={!_isEmpty(selectedPartData) && !_isEmpty(selectedPartData.partType ) ? selectedPartData.partType : null}
                partLevel={true}
                c2cUrl={!_isEmpty(selectedPartData) && !_isEmpty(selectedPartData.smartLink) ? selectedPartData.smartLink : null}
                catalog={false}
                isStockCheck={true}
                dualBoxView={false}
              />
            </XModal>
          )}
          {buyersModalStatus && (
            <XModal
              show={buyersModalStatus}
              showButtons={false}
              title="Buyers Guide"
              handleClose={() => setbuyersModalStatus(false)}
            >
              <BuyingGuideComponent
                selectedPartNo={selectedPartData.partNum}
                selectedBrand={selectedPartData.manufacturer}
                partDetails={selectedPartData}
                selectedPartType={selectedPartData.partType}
                selectedGroup={selectedPartData.partDescription}
                c2cUrl={selectedPartData.smartLink}
              />
            </XModal>
          )}
          {showLostSales && (
            <LostSales
              openPopup={showLostSales}
              closePopup={() => {
                setshowLostSales(false);
              }}
              selectedPartDetails={[selectedPartData]}
              dispatch={dispatch}
              userId={x2userName}
              source="catalogPage"
            />
          )}
        </div>
        <div className="stockcheck_add_to_cart_button">
          <div className="stock_check_btn">
            <OverlayTrigger
              overlay={
                <Tooltip
                  id={`tooltip`}
                >{`Click here to stock check more parts`}</Tooltip>
              }
            >
              <span onClick={() => onClickExpand()}>+</span>
            </OverlayTrigger>
            <Button
              disabled={!partNum}
              onClick={() => {
                handlePricingAvailbility();
              }}
            >
              {"  "} {} STOCK CHECK
            </Button>
            <Button
              style={{ marginLeft: "9px" }}
              onClick={() => {
                clearAll();
              }}
            >
              {"  "} {} CLEAR ALL
            </Button>
          </div>
          <Button
            className="add_to_cart_button"
            disabled={partSelect}
            onClick={() => {
              handleAddToCart();
            }}
          >
            ADD TO CART
          </Button>
          {addToCartWarningPopup ? (
          <AddToCartWarningPopup
            openPopup={addToCartWarningPopup}
            addedToCartOrQuote={addedToCartOrQuote}
            selectedAvailabilityHours={addToCartPayload}
            source={"chemicals"}
            orderType="REGULAR"
            DefaultAddItemToCart={true}
            resetStockCheck={resetStockCheck}
            closePopup={() => {
              setaddToCartWarningPopup(false);
            }}
          />
        ) : null}
        {showWithinPopup ?
        (<AvailabilityStoreView
        openPopup={showWithinPopup}
        closePopup={() => setShowWithinPopup(false)}
        storeData={storeData}
        />
        )
        : null }
        </div>
      </div>
    </Fragment>
  );
};

export default PartsTable;
