/* eslint-disable eqeqeq */
import partsApi from "../services/partsApi";
import orderApi from "../services/orderApi";
import orchestratorApi from "../services/orchestratorApi";
import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _toString from "lodash/toString";
import _remove from "lodash/remove";
import { setPartsAPIRequest } from "../components/Homepage/SimpleHomepage/simplehomepage.slice";
import _cloneDeep from "lodash/cloneDeep";
import { triggerCustomUxCriticalError } from "../actions/error";
import {
  getChemicalsPreferredBrands,
  getPartsPreferredBrands,
  timeInUTC
} from "../helpers/utils";
import { logUserStockActivity } from "../actions/user";
import storage from "../helpers/storage";

export const actionTypes = {
  C2C_URL_FETCHED: "C2C_URL_FETCHED",
  CATEGORY_LIST_ALL: "CATEGORY_LIST_ALL",
  CATEGORY_GROUP_MAP: "CATEGORY_GROUP_MAP",
  EXTRA_SPECIFICS_FOR_PARTS: "EXTRA_SPECIFICS_FOR_PARTS",
  PARTS_BY_GROUP_API_CALL: "PARTS_BY_GROUP_API_CALL",
  PARTS_LIST_BY_GROUPS: "PARTS_LIST_BY_GROUPS",
  PART_LIST_DETAILS: "PART_LIST_DETAILS",
  BUYER_GUIDE_DATA_FETCHED: "BUYER_GUIDE_DATA_FETCHED",
  BUYER_GUIDE_BRAND_DATA_FETCHED: "BUYER_GUIDE_BRAND_DATA_FETCHED",
  PART_CATALOG_THUMBNAILS: "PART_CATALOG_THUMBNAILS",
  PART_CATALOG_IMAGES: "PART_CATALOG_IMAGES",
  LABOR_GUIDE_DATA_FETCHED: "LABOR_GUIDE_DATA_FETCHED",
  INTERCHANGE_DATA_FETCHED: "INTERCHANGE_DATA_FETCHED",
  INTERCHANGE_BULK_DATA_FETCHED: "INTERCHANGE_BULK_DATA_FETCHED",
  CATEGORY_SUPPLIES_MAP: "CATEGORY_SUPPLIES_MAP",
  SUPPLIES_LIST_DETAILS: "SUPPLIES_LIST_DETAILS",
  INTERCHANGE_API_CALL: "INTERCHANGE_API_CALL",
  INTERCHANGE_BRAND_API_CALL: "INTERCHANGE_BRAND_API_CALL",
  BRANDS_CATEGORY_MAP: "BRANDS_CATEGORY_MAP",
  BUYERS_API_CALL: "BUYERS_API_CALL",
  LABOR_API_CALL: "LABOR_API_CALL",
  SUPPLIES_LIST_API_LOADING: "SUPPLIES_LIST_API_LOADING",
  PARTS_BY_GROUP_REQUEST: "PARTS_BY_GROUP_REQUEST",
  SUPPLIES_BY_PART_REQUEST: "SUPPLIES_BY_PART_REQUEST",
  INTERCHANGE_BRANDS_DROPDOWN_OPTIONS_FETCH:
    "INTERCHANGE_BRANDS_DROPDOWN_OPTIONS_FETCH",
  INTERCHANGE_PART_TYPE_DROPDOWN_OPTIONS_FETCH:
    "INTERCHANGE_PART_TYPE_DROPDOWN_OPTIONS_FETCH",
  SUPPLIES_CATEGORIES_BRAND_MAP: "SUPPLIES_CATEGORIES_BRAND_MAP",
  GROUP_CHANGED: "GROUP_CHANGED",
  PART_CATALOG_TYPES: "PART_CATALOG_TYPES",
  CATEGORY_CHANGED: "CATEGORY_CHANGED",
  CHEMICAL_CATEGORY_CHANGED: "CHEMICAL_CATEGORY_CHANGED",
  UPDATE_PART_CATALOG_FILTER_SECTION: "UPDATE_PART_CATALOG_FILTER_SECTION",
  GLOBAL_LOADER: "GLOBAL_LOADER",
  SEARCH_FAILURE_ENABLED: "SEARCH_FAILURE_ENABLED",
  SEARCH_FAILURE_ENABLED_FOR_ADDED_TO_CART:"SEARCH_FAILURE_ENABLED_FOR_ADDED_TO_CART",
  PARTS_NOTFOUND: 'PARTS_NOTFOUND'

};

export const getAllPartCategories = (dispatch) => {
  partsApi
    .get(`/part/category`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      const { data: categories } = result;
      dispatch({
        payload: {
          categoryList: result.data,
        },
        type: actionTypes.CATEGORY_LIST_ALL,
      });
      categories.forEach((category) => {
        dispatch({
          payload: {
            categoryGroupsList: [],
            categoryId: category.code,
            categoryName: category.name,
            categoryimage: category.imageUrl,
          },
          type: actionTypes.CATEGORY_GROUP_MAP,
        });
        partsApi
          .get(`/part/category/${category.code}/group?labor=false`, {
            params: {
              dispatch,
            },
          })
          .then((result) => {
            if (category.code === 10) {
              const isNotValidData =
                !_isEmpty(result.data) &&
                result.data.some((item) => item.code === 7)
                  ? false
                  : true;
              if (isNotValidData) {
                let errorMessageProps = {
                  heading:
                    "We are experiencing intermittent System problems. Please try after 5 minutes.",
                  message: "",
                  type: "info",
                };
                triggerCustomUxCriticalError(errorMessageProps, dispatch);
              }
            }
            dispatch({
              payload: {
                categoryGroupsList: result.data,
                categoryId: category.code,
                categoryName: category.name,
                categoryimage: category.imageUrl,
                categoryCanSelect: category.canSelect,
              },
              type: actionTypes.CATEGORY_GROUP_MAP,
            });
          });
      });
    })
    .catch((error) => {
      console.log(error, "error in all parts api");
    });
};

export const getC2CUrl = (
  partNumber,
  orderNumber,
  dispatch,
  isInterchange = false
) => {
  return partsApi
    .get(`/part/media?partNum=${partNumber}&orderNum=${orderNumber}`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          c2cUrl: result.data.slice(5),
          partNumber: partNumber,
          isInterchange,
        },
        type: actionTypes.C2C_URL_FETCHED,
      });

      return result;
    })
    .catch((error) => {
      console.log("error in c2c api", error);
    });
};

export const getImagesThumbnailsForCatalog = (orderNum, partNum, dispatch) => {
  partsApi
    .get(`/part/thumbnail`, {
      params: {
        dispatch,
        orderNum,
        partNum,
      },
    })
    .then((result) => {
      if (result) {
        dispatch({
          payload: {
            thumbnailData: result.data.thumbnails,
          },
          type: actionTypes.PART_CATALOG_THUMBNAILS,
        });
      }
    });
};

export const getImagesForCatalog = (parts, bodyData, dispatch) => {
  partsApi
    .post(`/part/image`, bodyData, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      if (result && result.data) {
        dispatch({
          payload: {
            thumbnailData: result.data,
          },
          type: actionTypes.PART_CATALOG_IMAGES,
        });
      }
      getImagesThumbnailsForCatalog(
        _toString(_map(parts, "orderNumber")),
        _toString(_map(parts, "partNumber")),
        dispatch
      );
    });
};

export const getAllPartTypes = (bodyData, dispatch) => {
  let requestBody = Object.values(bodyData).map((value) => value.groupCode);
  partsApi
    .post(`/part/partTypes`, requestBody, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      if (result && result.data) {
        dispatch({
          payload: {
            partTypes: result.data,
          },
          type: actionTypes.PART_CATALOG_TYPES,
        });
      }
    });
};

export const getCatalogPartTypes = (
  bodyData,
  year,
  make,
  model,
  engine,
  dispatch
) => {
  let requestBody = Object.values(bodyData).map((value) => value.groupCode);
  partsApi
    .post(
      `/part/partTypes?year=${year}&make=${make}&model=${model}&engine=${engine}`,
      requestBody,
      {
        params: {
          dispatch,
        },
      }
    )
    .then((result) => {
      if (result && result.data) {
        dispatch({
          payload: {
            partTypes: result.data,
          },
          type: actionTypes.PART_CATALOG_TYPES,
        });
      }
    });
};

export const getBuyersGuideData = (partNumber, dispatch, brand = null) => {
  dispatch({
    payload: {
      buyersApiCallOnGoing: true,
    },
    type: actionTypes.BUYERS_API_CALL,
  });
  let URL = `/bg/assistData?partNumber=${partNumber}`;
  if (brand) {
    URL = `/bg/assistData?partNumber=${partNumber}&mfgrsName=${brand}`;
  }
  partsApi
    .get(URL, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          buyersApiCallOnGoing: false,
        },
        type: actionTypes.BUYERS_API_CALL,
      });
      dispatch({
        payload: {
          partNumber: partNumber,
          buyersGuideData: result.data,
        },
        type: actionTypes.BUYER_GUIDE_DATA_FETCHED,
      });
    })
    .catch((error) => {
      console.log("error in buyers guide api", error);
    });
};

export const getBuyersGuideBrandData = (partNumber, dispatch) => {
  partsApi
    .get(`/bg/assistData/mfg?partNumber=${partNumber}`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          partNumber: partNumber,
          buyersGuideBrandData: result.data,
        },
        type: actionTypes.BUYER_GUIDE_BRAND_DATA_FETCHED,
      });
    })
    .catch((error) => {
      console.log("error in buyers guide brand api", error);
    });
};

/*optimization change */
export const getLaborGroups = (code, dispatch) => {
  return partsApi
    .get(`/part/category/${code}/group?labor=true`, {
      // .get(`/part/category/11/group?labor=true`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error in labor guide api", error);
    });
};
export const getLaborGuideData = (
  engine,
  group,
  make,
  model,
  year,
  dispatch
) => {
  dispatch({
    payload: {
      laborApiCallOnGoing: true,
    },
    type: actionTypes.LABOR_API_CALL,
  });
  const fullParams = {
    params: {
      dispatch,
      year,
      make,
      model,
      engine,
    },
  };
  return partsApi
    .get(`/labor/category/group/${group}/labor`, {
      ...fullParams,
    })
    .then((result) => {
      dispatch({
        payload: {
          laborGuideData: result.data,
        },
        type: actionTypes.LABOR_GUIDE_DATA_FETCHED,
      });
      dispatch({
        payload: {
          laborApiCallOnGoing: false,
        },
        type: actionTypes.LABOR_API_CALL,
      });
      return result;
    })
    .catch((error) => {
      dispatch({
        payload: {
          laborApiCallOnGoing: false,
        },
        type: actionTypes.LABOR_API_CALL,
      });
      console.log("error in labor guide api", error);
    });
};

export const getInterchangeData = (
  partNumber,
  priority = "P",
  mfg,
  partType,
  sellPartnerId,
  shipMethod,
  store,
  dispatch
) => {
  dispatch({
    payload: {
      interchangeApiCallOnGoing: true,
    },
    type: actionTypes.INTERCHANGE_API_CALL,
  });
  dispatch({
    payload: {
      globalLoader: true,
    },
    type: actionTypes.GLOBAL_LOADER,
  });

  // dispatch({
  //   payload: {
  //     interchangeData: [],
  //   },
  //   type: actionTypes.INTERCHANGE_DATA_FETCHED,
  // });
  orchestratorApi
    .get(`interchange`, {
      params: {
        dispatch,
        partNumber,
        priority,
        mfg,
        partType,
        sellPartnerId,
        shipMethod,
        store,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          interchangeData: result,
          interchanges: result?.data
            ? [{ interchangeData: result.data, partNumber }]
            : [],
        },
        type: actionTypes.INTERCHANGE_DATA_FETCHED,
      });
      dispatch({
        payload: {
          interchangeApiCallOnGoing: false,
        },
        type: actionTypes.INTERCHANGE_API_CALL,
      });
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
    })
    .catch((error) => {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
      dispatch({
        payload: {
          interchangeData: [],
          interchanges: true ? [{ interchangeData: [], partNumber }] : [],
        },
        type: actionTypes.INTERCHANGE_DATA_FETCHED,
      });
      dispatch({
        payload: {
          interchangeApiCallOnGoing: false,
        },
        type: actionTypes.INTERCHANGE_API_CALL,
      });
      console.log("error in interchange api", error);
    });
};

export const getInterChangeBrandOptions = (partNumber, partType, dispatch) => {
  dispatch({
    payload: {
      interchangeBrandApiCallOnGoing: true,
    },
    type: actionTypes.INTERCHANGE_BRAND_API_CALL,
  });
  partsApi
    .get(`/part/inter/mfg`, {
      params: {
        partNumber,
        partType,
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          partNumber: partNumber,
          brandOptions: result.data,
        },
        type: actionTypes.INTERCHANGE_BRANDS_DROPDOWN_OPTIONS_FETCH,
      });
      dispatch({
        payload: {
          interchangeBrandApiCallOnGoing: false,
        },
        type: actionTypes.INTERCHANGE_BRAND_API_CALL,
      });
    })
    .catch((error) => {
      console.log("error in interchange brands dropdown api");
    });
};

export const getInterChangePartTypeOptions = (partNumber, mfg, dispatch) => {
  partsApi
    .get(`part/inter/partType`, {
      params: {
        dispatch,
        partNumber,
        mfg,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          partNumber: partNumber,
          partTypeOptions: result.data,
        },
        type: actionTypes.INTERCHANGE_PART_TYPE_DROPDOWN_OPTIONS_FETCH,
      });
    })
    .catch((error) => {
      console.log("error in interchange part type dropdown api");
    });
};

export const getChemicalCategories = (dispatch) => {
  partsApi
    .get(`/chemical/category`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      const { data: categories } = result;
      categories.forEach((category) => {
        dispatch({
          payload: {
            categorySuppliesList: [],
            categoryId: category.id,
            categoryName: category.name,
            categoryImage: category.imageUrl,
            categoryGroupSelect: category.groupSelect,
          },
          type: actionTypes.CATEGORY_SUPPLIES_MAP,
        });
        partsApi
          .get(`/chemical/category/${category.id}/group`, {
            params: {
              dispatch,
            },
          })
          .then((result) => {
            if (result) {
              dispatch({
                payload: {
                  categorySuppliesList: result.data,
                  categoryId: category.id,
                  categoryName: category.name,
                  categoryImage: category.imageUrl,
                  categoryGroupSelect: category.groupSelect,
                },
                type: actionTypes.CATEGORY_SUPPLIES_MAP,
              });
            }
          });
      });
    })
    .catch((error) => {
      console.log("error in supplies category", error);
    });
};

export const getSuppliesCategoryByBrand = (dispatch) => {
  partsApi
    .get(`/chemical/category/groupByBrand`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          brandsCategoryMap: result.data,
        },
        type: actionTypes.BRANDS_CATEGORY_MAP,
      });
    })
    .catch((error) => {
      console.log(error, "error in category by brand api");
    });
};

export const getPricingAvailability = (bodyData, dispatch) => {
  return orderApi.post(`pricing-availability`, bodyData, {
    params: {
      dispatch,
    },
  });
};

export const getAvailableGFXFilter = (
  group,
  year,
  make,
  model,
  engine,
  dispatch
) => {
  // const fullParams = {
  //     dispatch,
  //     year,
  //     make,
  //     model,
  //     engine,
  // }
  partsApi
    .get(
      `/part/category/group/${group}/illustration/filter?engine=${engine}&make=${make}&model=${model}&year=${year}`
    )
    .then((result) => {
      if (result) {
        dispatch({
          payload: {
            globalFiltersForGfx: result.data.globalfilterHeaderValuesMap,
            illustrationDataItemList: result.data.illustrationDataItemList,
          },
          type: actionTypes.GFX_GLOBAL_FILTERS,
        });
      }
    })
    .catch((error) => {
      console.log(error, "error in gfx filter API ");
    });
};

export const getBrandByCategory = () => {
  return (dispatch) => {
    return partsApi
      .get(`/chemical/category/brand`)
      .then((result) => {
        if (result) {
          dispatch({
            payload: {
              suppCBMap: result.data,
            },
            type: actionTypes.SUPPLIES_CATEGORIES_BRAND_MAP,
          });
        }
      })
      .catch((error) => {
        console.log("error in brands by  category API", error);
      });
  };
};

export const getPartsByMultiGroupOrchestrator = (
  year,
  make,
  model,
  engine,
  groupdata,
  pageNumber = 1,
  pageSize = 25,
  sortBy = "partType",
  order = "ASC",
  filter = {},
  supplier,
  selectedData,
  alreadyCalled,
  sv,
  isCandk = false
) => {
  pageSize = pageSize >= 25 ? pageSize : 25;
  let groups = _cloneDeep(groupdata);
  // pageSize = 300;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        payload: {
          getAllPartsByGroupApiCall: true,
        },
        type: actionTypes.PARTS_BY_GROUP_API_CALL,
      });
    groups.requestItems.forEach((g) => (g.groupCode = Number(g.groupCode)));
    groups.filter = filter;
    dispatch({
      payload: {
        requestGroups: groups,
        requestVehicle: {
          year,
          makeId: make,
          modelId: model,
          engineId: engine,
        },
        catalogPageNumber: pageNumber,
        //catalogPageSize: 300,
        catalogPageSize: pageSize,
        catalogSortBy: sortBy,
        catalogOrder: order,
        catalogFilter: filter,
      },
      type: actionTypes.PARTS_BY_GROUP_REQUEST,
    });
    dispatch(
      setPartsAPIRequest({
        reqGroups: [...groups.requestItems],
        reqVehicle: {
          year,
          makeId: make,
          modelId: model,
          engineId: engine,
        },
      })
    );
    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
    dispatch({
      payload: {
        isPartsNotFound: false,
      },
      type: actionTypes.PARTS_NOTFOUND,
    });
    return orchestratorApi
      .post(`part`, groups, {
        params: {
          dispatch,
          year,
          make,
          model,
          engine,
          pageNumber,
          pageSize,
          sortBy,
          order,
        },
      })
      .then((result) => {
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });

        dispatch({
          payload: {
            searchFailure: false,
          },
          type: actionTypes.SEARCH_FAILURE_ENABLED,
        });
        dispatch({
          payload: {
            searchFailureForAddedToCart: false,
          },
          type: actionTypes.SEARCH_FAILURE_ENABLED_FOR_ADDED_TO_CART,
        });
        if (result.data.totalItems === 0 && _isEmpty(result.data.extras)) {
          dispatch({
            payload: {
              searchFailure: true,
            },
            type: actionTypes.SEARCH_FAILURE_ENABLED,
          });
        }
        if (!_isEmpty(result.data.pagedItems)) {
          dispatch({
            payload: {
              searchFailureForAddedToCart: true,
            },
            type: actionTypes.SEARCH_FAILURE_ENABLED_FOR_ADDED_TO_CART,
          });
        } else {
          dispatch({
            payload: {
              isPartsNotFound: true,
            },
            type: actionTypes.PARTS_NOTFOUND,
          });
        }

        // request again parts with already selected preferred brands
        const partsPrefBrands = getPartsPreferredBrands(supplier || "TS");
        let pref = !_isEmpty(partsPrefBrands) && _cloneDeep(partsPrefBrands);
        const prefBrands = !_isEmpty(pref) && pref.multiAnswer;

        if (
          !_isEmpty(prefBrands) &&
          prefBrands &&
          prefBrands.length > 0 &&
          false
        ) {
          const _brands = prefBrands.map((p) => p);
          // const brands = _brands.join(", ");

          // const brands = prefBrands;

          groups.filter = { brand: _brands };

          return orchestratorApi
            .post(`part`, groups, {
              params: {
                dispatch,
                year,
                make,
                model,
                engine,
                pageNumber,
                pageSize,
                sortBy,
                order,
              },
            })
            .then((_result) => {
              let {
                data: {
                  extras,
                  filter: {
                    availability,
                    brand,
                    position,
                    partTypes,
                    maxPrice,
                    minPrice,
                  },
                  pagedItems: partsDetails,
                  pageNumber,
                  pageSize,
                  totalItems,
                  totalPages,
                },
              } = _result;

              if (!_isEmpty(extras)) {
                dispatch({
                  payload: {
                    specificConditions: extras,
                  },
                  type: actionTypes.EXTRA_SPECIFICS_FOR_PARTS,
                });
              } else {
                const partsAllDetails = partsDetails.map((part) => {
                  return {
                    ...part,
                    year,
                    make,
                    model,
                    engine,
                  };
                });

                // to handle invariant redux, state mutation
                setTimeout(() => {
                  console.log("dispatch ==> ", brand, result.data.filter);
                  dispatch({
                    payload: {
                      allBrands: _.uniq([
                        ...brand,
                        ...result.data.filter.brand,
                      ]),
                      availabilities: availability,
                      partTypes: partTypes,
                      catalogPageNumber: pageNumber,
                      catalogPageSize: pageSize,
                      catalogTotalPage: totalPages,
                      catalogTotalItems: totalItems,
                      partsAllDetails,
                      positions: position,
                      getAllPartsByGroupApiCall: false,
                      maxPrice: maxPrice,
                      minPrice: minPrice,
                    },
                    type: actionTypes.PART_LIST_DETAILS,
                  });

                  dispatch({
                    payload: {
                      getAllPartsByGroupApiCall: false,
                    },
                    type: actionTypes.PARTS_BY_GROUP_API_CALL,
                  });
                }, 2000);

                let imgReqItems = [];
                partsDetails.forEach((part) => {
                  let item = {
                    partNumber: part.partNumber,
                    supplier: part.mfgName,
                    // lineCode: !_isEmpty(part.lineCodes) ? part.lineCodes[0] : ""
                  };
                  imgReqItems.push(item);
                  if (part.alternate.length > 0) {
                    part.alternate.forEach((alternatePart) => {
                      let alternateItem = {
                        partNumber: alternatePart.partNumber,
                        supplier: alternatePart.mfgName,
                      };
                      imgReqItems.push(alternateItem);
                    });
                  }
                  if (part.substitute.length > 0) {
                    part.substitute.forEach((substitutePart) => {
                      let substituteItem = {
                        partNumber: substitutePart.partNumber,
                        supplier: substitutePart.mfgName,
                      };
                      imgReqItems.push(substituteItem);
                    });
                  }
                });
                getImagesForCatalog(partsDetails, imgReqItems, dispatch);
                getCatalogPartTypes(
                  groups.requestItems,
                  year,
                  make,
                  model,
                  engine,
                  dispatch
                );
              }
            })
            .catch();
        } else {
          let {
            data: {
              extras,
              filter: {
                availability,
                brand,
                position,
                partTypes,
                maxPrice,
                minPrice,
              },
              pagedItems: partsDetails,
              pageNumber,
              pageSize,
              totalItems,
              totalPages,
            },
          } = result;

          if (!_isEmpty(extras)) {
            dispatch({
              payload: {
                specificConditions: extras,
              },
              type: actionTypes.EXTRA_SPECIFICS_FOR_PARTS,
            });
          } else {
            const partsAllDetails = partsDetails.map((part) => {
              return {
                ...part,
                year,
                make,
                model,
                engine,
              };
            });

            dispatch({
              payload: {
                allBrands: brand,
                availabilities: availability,
                partTypes: partTypes,
                catalogPageNumber: pageNumber,
                catalogPageSize: pageSize,
                catalogTotalPage: totalPages,
                catalogTotalItems: totalItems,
                partsAllDetails,
                positions: position,
                getAllPartsByGroupApiCall: false,
                maxPrice: maxPrice,
                minPrice: minPrice,
              },
              type: actionTypes.PART_LIST_DETAILS,
            });
            let imgReqItems = [];
            partsDetails.forEach((part) => {
              let item = {
                partNumber: part.partNumber,
                supplier: part.mfgName,
                // lineCode: !_isEmpty(part.lineCodes) ? part.lineCodes[0] : ""
              };
              imgReqItems.push(item);
              if (part.alternate.length > 0) {
                part.alternate.forEach((alternatePart) => {
                  let alternateItem = {
                    partNumber: alternatePart.partNumber,
                    supplier: alternatePart.mfgName,
                  };
                  imgReqItems.push(alternateItem);
                });
              }
              if (part.substitute.length > 0) {
                part.substitute.forEach((substitutePart) => {
                  let substituteItem = {
                    partNumber: substitutePart.partNumber,
                    supplier: substitutePart.mfgName,
                  };
                  imgReqItems.push(substituteItem);
                });
              }
            });
            getImagesForCatalog(partsDetails, imgReqItems, dispatch);
            if(!isCandk) {
            getCatalogPartTypes(
              groups.requestItems,
              year,
              make,
              model,
              engine,
              dispatch
            );
            }
            if (partsDetails.length <= 0 && alreadyCalled) {
              let loginId = storage.get("loginId", "local");
              let customerId = storage.get("customerId", "local");
              let shipToId = storage.get("shipToId", "local");
              let userName = storage.get("userName", "local");

              const errorData = {
                source: "Part Catalog - Default",
                searchvalue: selectedData,
                resultFound: false,
                // year:year || null,
                // makeId: make || null,
                // modelId: model || null,
                // engineId: engine || null,
                selectedVehicle: sv
                  ? `${sv?.year} ${sv?.make} ${sv?.model} ${sv?.engine}`
                  : null,
                filters: {
                  allBrands: brand,
                  availabilities: availability,
                  // partTypes: partTypes,
                  // partsAllDetails,
                  positions: position,
                  misc: filter?.misc,
                },
              };
              let userData = JSON.stringify(errorData);
              let type = "Search - No Result";
              dispatch(
                logUserStockActivity(
                  timeInUTC(),
                  customerId,
                  loginId,
                  shipToId,
                  type,
                  userData,
                  userName
                )
              );
            }
          }
        }
        resolve(result);
      })
      .catch((error) => {
        dispatch({
          payload: {
            isPartsNotFound: true,
          },
          type: actionTypes.PARTS_NOTFOUND,
        });
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });

        dispatch({
          payload: {
            allBrands: [],
            availabilities: [],
            catalogPageNumber: 0,
            catalogPageSize: 0,
            catalogTotalPage: 0,
            catalogTotalItems: 0,
            partsAllDetails: [],
            positions: [],
            partTypes: [],
            getAllPartsByGroupApiCall: false,
          },
          type: actionTypes.PART_LIST_DETAILS,
        });
        reject(error);
      });
      });
  };
};

export const getChemPartByGrp = (
  groupCodes,
  pageNumber = 1,
  pageSize = 25,
  sortBy = "partType",
  order = "ASC",
  brand,
  availability,
  misc,
  specification,
  sizeQty,
  caseQty,
  partTypeIds,
  sellPartnerId,
  store,
  shipMethd,
  filter = {},
  makeNextCall = false
) => {
  pageSize = pageSize >= 25 ? pageSize : 25;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
    setTimeout(() => {
      dispatch({
        payload: {
          supplyListApiCall: true,
        },
        type: actionTypes.SUPPLIES_LIST_API_LOADING,
      });
    }, 100);
    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
    dispatch({
      payload: {
        isPartsNotFound: false,
      },
      type: actionTypes.PARTS_NOTFOUND,
    });
    dispatch({
      payload: {
        suppliesRequestGroups: groupCodes,
        pageNumber,
        pageSize,
        sortBy,
        order,
        brand,
        availability,
        misc,
        specification,
        sizeQty,
        caseQty,
        partTypeIds,
        catalogFilter: filter,
      },
      type: actionTypes.SUPPLIES_BY_PART_REQUEST,
    });
    return orchestratorApi
      .get(`chemical`, {
        params: {
          dispatch,
          groupCode: groupCodes ? groupCodes.toString() : null,
          pageNumber,
          pageSize,
          sortBy,
          order,
          brand: brand ? brand.toString() : null,
          availability: availability ? availability.toString() : null,
          misc: misc ? misc.toString() : null,
          specification: specification ? specification.toString() : null,
          sizeQty: sizeQty ? sizeQty.toString() : null,
          caseQty: caseQty ? caseQty.toString() : null,
          partTypeIds: partTypeIds ? partTypeIds.toString() : null,
          sellPartnerId: sellPartnerId ? sellPartnerId : null,
          store: store ? store : null,
          shipMethod: shipMethd ? shipMethd : null,
        },
      })
      .then((result) => {
        // request again chemicals with already selected preferred brands;
        const userChemicalsPrefBrands = getChemicalsPreferredBrands();
        const prefBrands =
          !_isEmpty(userChemicalsPrefBrands) &&
          userChemicalsPrefBrands.multiAnswer;

        if (
          !_isEmpty(prefBrands) &&
          prefBrands &&
          prefBrands.length > 0 &&
          makeNextCall
        ) {
          const _brands = prefBrands.map((p) => {
            if (typeof p === "string") {
              return p;
            } else {
              return p.text;
            }
          });
          const brands = _brands.join(",");

          return orchestratorApi
            .get(`chemical`, {
              params: {
                dispatch,
                groupCode: groupCodes ? groupCodes.toString() : null,
                pageNumber,
                pageSize,
                sortBy,
                order,
                brand: brands,
              },
            })
            .then((_result) => {
              let {
                data: {
                  filter: {
                    availability,
                    brand,
                    caseQty,
                    specification,
                    sizeQty,
                    partTypes,
                    minPrice,
                    maxPrice,
                  },
                  pagedItems: partsDetails,
                  pageNumber,
                  pageSize,
                  totalItems,
                  totalPages,
                },
              } = _result;

              if(_isEmpty(partsDetails)) {
                dispatch({
                  payload: {
                    isPartsNotFound: true,
                  },
                  type: actionTypes.PARTS_NOTFOUND,
                });
              }

              dispatch({
                payload: {
                  globalLoader: false,
                },
                type: actionTypes.GLOBAL_LOADER,
              });

              dispatch({
                payload: {
                  supplyListApiCall: false,
                },
                type: actionTypes.SUPPLIES_LIST_API_LOADING,
              });

              dispatch({
                payload: {
                  allCaseQty: _remove(caseQty, (cq) => cq),
                  allSizeQty: _remove(sizeQty, (sq) => sq),
                  suppliesAllDetails: partsDetails,
                  suppliesAllBrands: _.uniq([
                    ...brand,
                    ...result.data.filter.brand,
                  ]),
                  suppliesAvailability: availability,
                  partTypes: partTypes,
                  specs: _remove(specification, (sp) => sp),
                  pageNumber: pageNumber,
                  totalPages: totalPages,
                  totalItems: totalItems,
                  pageSize: pageSize,
                  minPrice: minPrice,
                  maxPrice: maxPrice,
                },
                type: actionTypes.SUPPLIES_LIST_DETAILS,
              });

              const _groupCodes = groupCodes.map((g) => {
                return { groupCode: g };
              });

              _groupCodes.filter = filter;

              // getAllPartTypes(_groupCodes, dispatch);
            })
            .catch();
        } else {
          let {
            data: {
              filter: {
                availability,
                brand,
                caseQty,
                specification,
                sizeQty,
                partTypes,
                minPrice,
                maxPrice,
              },
              pagedItems: partsDetails,
              pageNumber,
              pageSize,
              totalItems,
              totalPages,
            },
          } = result;

          dispatch({
            payload: {
              supplyListApiCall: false,
            },
            type: actionTypes.SUPPLIES_LIST_API_LOADING,
          });
          
          dispatch({
            payload: {
              globalLoader: false,
            },
            type: actionTypes.GLOBAL_LOADER,
          });

          dispatch({
            payload: {
              allCaseQty: _remove(caseQty, (cq) => cq),
              allSizeQty: _remove(sizeQty, (sq) => sq),
              suppliesAllDetails: partsDetails,
              suppliesAllBrands: brand,
              suppliesAvailability: availability,
              partTypes: partTypes,
              specs: _remove(specification, (sp) => sp),
              pageNumber: pageNumber,
              totalPages: totalPages,
              totalItems: totalItems,
              pageSize: pageSize,
              minPrice: minPrice,
              maxPrice: maxPrice,
            },
            type: actionTypes.SUPPLIES_LIST_DETAILS,
          });

          const _groupCodes = groupCodes.map((g) => {
            return { groupCode: g };
          });

          _groupCodes.filter = filter;
          // getAllPartTypes(_groupCodes, dispatch);
        }
        // END

        // dispatch({
        //   payload: {
        //     supplyListApiCall: false,
        //   },
        //   type: actionTypes.SUPPLIES_LIST_API_LOADING,
        // });

        // dispatch({
        //   payload: {
        //     allCaseQty: _remove(caseQty, (cq) => cq),
        //     allSizeQty: _remove(sizeQty, (sq) => sq),
        //     suppliesAllDetails: partsDetails,
        //     suppliesAllBrands: brand,
        //     suppliesAvailability: availability,
        //     partTypes: partTypes,
        //     specs: _remove(specification, (sp) => sp),
        //     pageNumber: pageNumber,
        //     totalPages: totalPages,
        //     totalItems: totalItems,
        //     pageSize: pageSize,
        //     minPrice: minPrice,
        //     maxPrice: maxPrice,
        //   },
        //   type: actionTypes.SUPPLIES_LIST_DETAILS,
        // });

        // const _groupCodes = groupCodes.map((g) => {
        //   return { groupCode: g };
        // });

        // _groupCodes.filter = filter;
        // getAllPartTypes(_groupCodes, dispatch);
        resolve(result);
      })
      .catch((err) => {
        console.log("err in chemicals group call", err);
        reject(err);
        dispatch({
          payload: {
            suppliesAllDetails: [],
            suppliesAllBrands: [],
            suppliesAvailability: [],
            pageNumber: 1,
            totalPages: 0,
            totalItems: 0,
            pageSize: 1,
          },
          type: actionTypes.SUPPLIES_LIST_DETAILS,
        });
        dispatch({
          payload: {
            supplyListApiCall: false,
          },
          type: actionTypes.SUPPLIES_LIST_API_LOADING,
        });
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
        dispatch({
          payload: {
            isPartsNotFound: true,
          },
          type: actionTypes.PARTS_NOTFOUND,
        });

      });
    });

  };
};
export const updateFilterSectionEnabledStatus = (filterStatus) => {
  return (dispatch) => {
    dispatch({
      payload: {
        filterEnabled: filterStatus,
      },
      type: actionTypes.UPDATE_PART_CATALOG_FILTER_SECTION,
    });
  };
};

export const getChemPartByCat = (
  categoryCodes,
  brandCodes,
  groupCodes = [],
  pageNumber = 1,
  pageSiz = 25,
  sortBy = "partType",
  order = "ASC",
  brand,
  availability = [],
  misc = [],
  specification,
  sizeQty,
  caseQty,
  sellPartnerId,
  store,
  shipMethd,
  filter = {},
  makeNextCall = false
) => {
  let pageSize = pageSiz >= 25 ? pageSiz : 25;
  return (dispatch) => {
    dispatch({
      payload: {
        suppliesAllDetails: [],
        suppliesAllBrands: [],
        suppliesAvailability: [],
        pageNumber: 1,
        totalPages: 0,
        totalItems: 0,
        pageSize: 1,
      },
      type: actionTypes.SUPPLIES_LIST_DETAILS,
    });
    dispatch({
      payload: {
        supplyListApiCall: true,
      },
      type: actionTypes.SUPPLIES_LIST_API_LOADING,
    });
    dispatch({
      payload: {
        reqBrands: brandCodes,
        reqCategories: categoryCodes,
        suppliesRequestGroups: groupCodes,
        // brands:brandCodes,
        pageNumber,
        pageSize,
        sortBy,
        order,
        brand,
        availability,
        misc,
        specification,
        sizeQty,
        caseQty,
        catalogFilter: filter,
      },
      type: actionTypes.SUPPLIES_BY_PART_REQUEST,
    });
    return orchestratorApi
      .get(`chemical/category/part`, {
        params: {
          dispatch,
          categoryCode: categoryCodes ? categoryCodes.toString() : null,
          brandCode: brandCodes ? brandCodes.toString() : null,
          pageNumber,
          pageSize,
          sortBy,
          order,
          brand: brand ? brand.toString() : null,
          availability: availability ? availability.toString() : null,
          misc: misc ? misc.toString() : null,
          specification: specification ? specification.toString() : null,
          sizeQty: sizeQty ? sizeQty.toString() : null,
          caseQty: caseQty ? caseQty.toString() : null,
          sellPartnerId: sellPartnerId ? sellPartnerId : null,
          store: store ? store : null,
          shipMethod: shipMethd ? shipMethd : null,
        },
      })
      .then((result) => {
        // request again chemicals with already selected preferred brands;
        const userChemicalsPrefBrands = getChemicalsPreferredBrands();
        const prefBrands =
          userChemicalsPrefBrands && userChemicalsPrefBrands.multiAnswer;

        if (prefBrands && prefBrands.length > 0 && makeNextCall) {
          const _brands = prefBrands.map((p) => p.text);
          const brands = _brands.join(", ");

          return orchestratorApi
            .get(`chemical/category/part`, {
              params: {
                dispatch,
                categoryCode: categoryCodes ? categoryCodes.toString() : null,
                brandCode: brandCodes ? brandCodes.toString() : null,
                pageNumber,
                pageSize,
                sortBy,
                order,
                brand: brands,
                availability: availability ? availability.toString() : null,
                misc: misc ? misc.toString() : null,
                specification: specification ? specification.toString() : null,
                sizeQty: sizeQty ? sizeQty.toString() : null,
                caseQty: caseQty ? caseQty.toString() : null,
                sellPartnerId: sellPartnerId ? sellPartnerId : null,
                store: store ? store : null,
                shipMethod: shipMethd ? shipMethd : null,
              },
            })
            .then((_result) => {
              let {
                data: {
                  filter: {
                    availability,
                    brand,
                    caseQty,
                    specification,
                    sizeQty,
                    partTypes,
                    minPrice,
                    maxPrice,
                  },
                  pagedItems: partsDetails,
                  pageNumber,
                  pageSize,
                  totalItems,
                  totalPages,
                },
              } = _result;
              dispatch({
                payload: {
                  supplyListApiCall: false,
                },
                type: actionTypes.SUPPLIES_LIST_API_LOADING,
              });
              dispatch({
                payload: {
                  allCaseQty: _remove(caseQty, (cq) => cq),
                  allSizeQty: _remove(sizeQty, (sq) => sq),
                  suppliesAllDetails: partsDetails,
                  suppliesAllBrands: _.uniq([
                    ...brand,
                    ...result.data.filter.brand,
                  ]),
                  suppliesAvailability: availability,
                  specs: _remove(specification, (sp) => sp),
                  pageNumber: pageNumber,
                  totalPages: totalPages,
                  totalItems: totalItems,
                  partTypes: partTypes,
                  pageSize: pageSize,
                  minPrice: minPrice,
                  maxPrice: maxPrice,
                },
                type: actionTypes.SUPPLIES_LIST_DETAILS,
              });
              const _groupCodes = groupCodes.map((g) => {
                return { groupCode: g };
              });
              _groupCodes.filter = filter;

              // getAllPartTypes(_groupCodes, dispatch);
            })
            .catch();
        } else {
          let {
            data: {
              filter: {
                availability,
                brand,
                caseQty,
                specification,
                sizeQty,
                partTypes,
                minPrice,
                maxPrice,
              },
              pagedItems: partsDetails,
              pageNumber,
              pageSize,
              totalItems,
              totalPages,
            },
          } = result;
          dispatch({
            payload: {
              supplyListApiCall: false,
            },
            type: actionTypes.SUPPLIES_LIST_API_LOADING,
          });
          dispatch({
            payload: {
              allCaseQty: _remove(caseQty, (cq) => cq),
              allSizeQty: _remove(sizeQty, (sq) => sq),
              suppliesAllDetails: partsDetails,
              suppliesAllBrands: brand,
              suppliesAvailability: availability,
              specs: _remove(specification, (sp) => sp),
              pageNumber: pageNumber,
              totalPages: totalPages,
              totalItems: totalItems,
              partTypes: partTypes,
              pageSize: pageSize,
              minPrice: minPrice,
              maxPrice: maxPrice,
            },
            type: actionTypes.SUPPLIES_LIST_DETAILS,
          });
          const _groupCodes = groupCodes.map((g) => {
            return { groupCode: g };
          });
          _groupCodes.filter = filter;

          // getAllPartTypes(_groupCodes, dispatch);
        }
      })
      .catch((err) => {
        dispatch({
          payload: {
            suppliesAllDetails: [],
            suppliesAllBrands: [],
            suppliesAvailability: [],
            pageNumber: 1,
            totalPages: 0,
            totalItems: 0,
            pageSize: 1,
          },
          type: actionTypes.SUPPLIES_LIST_DETAILS,
        });
        dispatch({
          payload: {
            supplyListApiCall: false,
          },
          type: actionTypes.SUPPLIES_LIST_API_LOADING,
        });
      });
  };
};
export const cartPricing = (req) => {
  return (dispatch) => {
    return orderApi
      .post(`/cart-pricing`, req)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {});
  };
};

export const getStoreDetails = (bodyData, dispatch) => {
  let requestBody = Object.values(bodyData).map((value) => value.groupCode);
  partsApi
    .post(`/part/partTypes`, requestBody, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      if (result && result.data) {
        dispatch({
          payload: {
            partTypes: result.data,
          },
          type: actionTypes.PART_CATALOG_TYPES,
        });
      }
    });
};
export const postInterchangeBulkUpload = (payload, dispatch) => {
  dispatch({
    payload: {
      interchangeApiCallOnGoing: true,
    },
    type: actionTypes.INTERCHANGE_API_CALL,
  });
  orchestratorApi
    .post(`/interchange/import`, payload, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: result?.data ? result.data : [],
        type: actionTypes.INTERCHANGE_BULK_DATA_FETCHED,
      });
      dispatch({
        payload: {
          interchangeApiCallOnGoing: false,
        },
        type: actionTypes.INTERCHANGE_API_CALL,
      });
    })
    .catch((error) => {
      dispatch({
        payload: {
          interchangeApiCallOnGoing: false,
        },
        type: actionTypes.INTERCHANGE_API_CALL,
      });
      console.log("error in interchange api", error);
    });
};
