import React, { Fragment } from 'react';
import {
	Alert,
	Tab,
	Nav,
	Form,
	Button,
	Container,
	Row,
	Col,
	InputGroup,
	DropdownButton,
	Dropdown,
} from 'react-bootstrap';
import {
	validateUserRegForm,
	populateUserDropDownOptions,
	numbersOnlyRegExp,
	alphaCharRegExp,
    timeInUTC,
} from '../../../helpers/utils';
import {
	getDriverList,
} from '../UserManagement/usermanagement.thunk';
import {
	fetchShipToIds
} from "../../shared/ShipTo/shipTo.thunk";
import { createDriver } from '../../../actions/user';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';

import _every from 'lodash/every';
import Select from 'react-select';

import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import DeleteDriver from './DeleteDriver';
import ActivateOrInactivateDriver from './ActivateOrInactivateDriver';
import { first } from 'lodash';



class DriverManager extends React.Component {
	constructor(props) {
		super(props);
		let shipToList = !_isEmpty(props.ShipToIds)
			? populateUserDropDownOptions(props.ShipToIds)
			: [];
		let shipToIds = [];
		let locationsSelected = [];
		if (shipToList.length === 1) {
			locationsSelected = shipToList[0].value;
			shipToIds = [shipToList[0].id];
		}
		this.state = {
			formObject: {
				customerNumber: !_isEmpty(props.userDetails)
					? props.userDetails.customerCode
					: '',
				firstName: '',
				lastName: '',
				title: '',
				xlpAccNo: !_isEmpty(props.userDetails)
					? props.userDetails.billTo.billToId
					: '',
				companyName: '',
				companyId: !_isEmpty(props.companyData) ? props.companyData[0].companyId : '',
				xlpLastInvoiceNo: '',
				xlpLastInvoiceAmount: '',
				address: '',
				pin: '',
				phoneNo: '',
				mobileNo: '',
				comments: '',
				locationsSelected,
				userType: '',
				store: '',
				shipToIds,
			},
			companyCode: !_isEmpty(props.companyData) ? props.companyData[0].companyCode : '',
			shipToList,
			validity: this.intializeValidations(),
			userCreation: '',
			popUpMessage: '',
			countryCode: '+1',
			countrypincode:'',
			defaultShipToSelected: '',
			userUpdated: false,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleSelectBlur = this.handleSelectBlur.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.validateForm = this.validateForm.bind(this);
		this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
		this.handleDefaultSelect = this.handleDefaultSelect.bind(this);
		this.handleMultiSelectBlur = this.handleMultiSelectBlur.bind(this);
	 
	}



	intializeValidations() {
		this.validity = {
			isFirstNameNotNull: true,
			isFirstnameLengthBelowLimit: true,
			isFirstnameMinLength: true,
			isFirstNameAlphabetic: true,
			isFirstNameValid: true,

			isLastNameNotNull: true,
			isLastnameLengthBelowLimit: true,
			isLastnameMinLength: true,
			isLastNameAlphabetic: true,
			isLastNameValid: true,

			isPinNotNull: true,
			ispinLengthBelowLimit: true,
			isPinNoNumeric: true,
			isPinMinLength: true,
			isValidpin: true,

			isStoreNotNull: true,
			isStoreLengthBelowLimit: true,
			isStoreNoNumeric: true,
			isStoreMinLength: true,
			isValidstore: true,

			isMobileNoNotNull: true,
			isMobileNoLengthBelowLimit: true,
			isMobileNoMinLength: true,
			isMobileNoNumeric: true,
			isValidMobileNo: true,

			isLocationNotNull: true,
			isUserTypeNotNull: true,
		};
		return this.validity;
	}

	componentDidMount() {
		const {
			userDetails: {
				billTo: {
					billToId,
				},
			},
		} = this.props;
		const payload = {
			billtoid: billToId,
		};
		fetchShipToIds(payload);
	}

	componentDidUpdate(prevProps) {
		let { shipToList, formObject, userCreation, popUpMessage, userUpdated, companyCode } = this.state;
		let stateChanged = false;
		if (!_isEqual(this.props, prevProps)) {
			if (!_isEqual(this.props.ShipToIds, prevProps.ShipToIds)) {
				shipToList = populateUserDropDownOptions(
					this.props.ShipToIds
				);
				if (shipToList.length === 1) {
					formObject.locationsSelected = shipToList[0].value;
					formObject.shipToIds = [shipToList[0].id];
				}
				stateChanged = true;
			}
			if (!_isEqual(this.props.userDetails, prevProps.userDetails)) {
				formObject.companyName = this.props.userDetails.companyName;
				formObject.xlpAccNo = this.props.userDetails.billTo.billToId;
				formObject.customerNumber = this.props.userDetails.customerCode;
				stateChanged = true;
			}
			if (!_isEqual(this.props.companyData, prevProps.companyData)) {
				companyCode = this.props.companyData[0].companyCode;
				stateChanged = true;
			}
			if (
				!_isEqual(this.props.messageFromAPi, prevProps.messageFromAPi)
			) {
				userCreation = this.props.userCreationSuccess;
				popUpMessage = this.props.messageFromAPi;
				window.scrollTo(0, 0);
				stateChanged = true;
				formObject.firstName = '';
				formObject.lastName = '';
				formObject.store = '';
				formObject.pin = '';
				formObject.mobileNo = '';
				userUpdated = false;
			}

			if (stateChanged) {
				this.setState({
					...this.state,
					formObject,
					shipToList,
					userCreation,
					popUpMessage,
					userUpdated,
					companyCode
				});
			}
		}
	}

	validateForm() {
		let {
			formObject: {
				firstName,
				lastName,
				store,
				title,
				pin,
				phoneNo,
				mobileNo,
				userType,
				shipToIds,
				locationsSelected,
			},
			validity,
		} = this.state;
		validity = validateUserRegForm('firstName', firstName, validity);
		validity = validateUserRegForm('lastName', lastName, validity);
		validity = validateUserRegForm('store', store, validity);
		validity = validateUserRegForm('pin', pin, validity);
		validity = validateUserRegForm('phoneNo', phoneNo, validity);
		validity = validateUserRegForm('mobileNo', mobileNo, validity);
		validity = validateUserRegForm('locationsSelected', locationsSelected, validity);
		validity = validateUserRegForm('userType', userType, validity);
		validity = validateUserRegForm('locationsSelected', locationsSelected, validity);
		// validity.isValidShipTo = _isEmpty(shipToIds);
		// validity = validateOnlineIdForm('comments', comments, validity);
		this.setState({ ...this.state, validity });
		return _every(validity);
	}

	handleSubmit(e) {
		e.preventDefault();
		let {
			formObject: {
				customerNumber,
				firstName,
				lastName,
				title,
				xlpAccNo,
				companyName,
				xlpLastInvoiceNo,
				xlpLastInvoiceAmount,
				address,
				email,
				phoneNo,
				mobileNo,
				userType,
				shipToIds,
				pin,
				store		
	
			},
			countryCode,
			countrypincode,
			defaultShipToSelected,
			companyCode
		} = this.state;
		const {
			userDetails,

		} = this.props;
		const UserPayload = {
			customerId: userDetails.billTo.billToId,
		};
		let { dispatch } = this.props;
		let updatedShipToObj = {};
		if (!_isEmpty(shipToIds)) {
			if (shipToIds.length > 1) {
				shipToIds.map((id) => {
					updatedShipToObj[id.toString()] = (id === defaultShipToSelected);
				});
			} else {
				updatedShipToObj[shipToIds[0]] = true;
			}
		}
		if (true) {
			let userObj = {
				companyId: this.props.companyData && this.props.companyData[0].companyId,
                storeNum: store,
                pin: companyCode + firstName.toUpperCase(firstName).charAt(0) + lastName.toUpperCase(lastName).charAt(0) + pin,
                mobileNumber: mobileNo,
                fname: firstName,
                lname: lastName,
				createdBy: this.props.userDetails && this.props.userDetails.x2userName,
				createdDate: timeInUTC(),
				pinValidDate:timeInUTC().substring(0,10)
			};
			createDriver(userObj, dispatch).then((result) => {
				//dispatch(getDriverList(UserPayload));
			});
		}
	}


	handleInputChange(e, data) {
		const { name, value, label } = e.target;
		let { formObject, validity } = this.state;
		const formValue = value.charAt(0);
		//const formValue = value.charAt(0);
		console.log("Value :", value);
		console.log("Value from event:", formValue);
	
		let str;
		if (formValue === ' ') {
			str = value.trim();
		}
		else {
			str = value;
			validity = validateUserRegForm(name, str, validity);
		}
		formObject[name] = str;
		
		this.setState({
			...this.state,
			formObject: {
				...this.state.formObject,
				...formObject,
			},
			validity: {
				...this.state.validity,
				...validity,
			},
			userUpdated: true,
		});
	}

	handleSelectChange(selectedOption, data) {
		const { name } = data;
		let { formObject, shipToList, defaultShipToSelected, validity } = this.state;
		const { value, label } = selectedOption;
		formObject[name] = value;
		if (name === 'locationsSelected') {
			formObject['shipToIds'] = [value];
			defaultShipToSelected = value;
		}
		if (name === 'userType' && (shipToList.length > 1)) {
			formObject['shipToIds'] = [];
			formObject['locationsSelected'] = [];
			defaultShipToSelected = '';
		}
		validity = validateUserRegForm(name, value, validity);
		this.setState({
			...this.state,
			formObject,
			defaultShipToSelected,
			validity: {
				...this.state.validity,
				...validity,
			},
			userUpdated: true,
		});
	}

	handleSelectBlur() {
		// const { name } = event.target;
		let { formObject, validity } = this.state;
		const { userType } = formObject;
		validity = validateUserRegForm('userType', userType, validity);
		this.setState({
			...this.state,
			formObject,
			validity: {
				...this.state.validity,
				...validity,
			},
		});
	}

	handleMultiSelectBlur() {
		let { formObject, validity } = this.state;
		const { userType } = formObject;
		validity = validateUserRegForm('locationsSelected', userType, validity);
		this.setState({
			...this.state,
			formObject,
			validity: {
				...this.state.validity,
				...validity,
			},
		});
	}

	handleMultiSelectChange(value, { action, removedValue, name }) {
		let {
			formObject: { locationsSelected },
			defaultShipToSelected,
			validity,
		} = this.state;
		const multiValue = [];
		if (!_isEmpty(value)) {
			value.map((selectedList) => {
				multiValue.push(selectedList.value)
			});
		}
		locationsSelected = value && value.length > 0 ? multiValue : [];
		defaultShipToSelected = (locationsSelected.length === 1) ? locationsSelected[0] : '';
		validity = validateUserRegForm(name, value, validity);
		this.setState({
			...this.state,
			formObject: {
				...this.state.formObject,
				locationsSelected: locationsSelected,
				shipToIds: locationsSelected,
			},
			validity: {
				...this.state.validity,
				...validity,
			},
			defaultShipToSelected,
			userUpdated: true,
		});
	}

	handleDefaultSelect(selectedOption, data) {
		this.setState({
			defaultShipToSelected: selectedOption.value,
		});
	}

	renderUserTypeSelection() {
		let {
			shipToList,
			formObject: { locationsSelected, userType },
			validity,
		} = this.state;

	}

	
	updateCountryCode = (key, e) => {
		this.setState({
			countryCode: e.target.name,
		})
	}

	updatecountrypincode = (key, e) => {
		this.setState({
			countrypincode: e.target.name,
		})

	}
	handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
		return (code !== 8 && code !== 9 && code !== 37 && code !== 38 && code !== 39 && code !== 40 && code !== 46);
	}

	handleKeyDown = (event, name) => {
		const { formObject: {
			firstName,
			lastName,
			mobileNo,
			email,
			pin,
			store,		
		}
		} = this.state;
		switch (name) {
			case 'firstName':
				if ((alphaCharRegExp(event.key) === false || (firstName && firstName.length >= 16)) && this.handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
				break;
			case 'lastName':
				if ((alphaCharRegExp(event.key) === false || (lastName && lastName.length >= 16)) && this.handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
				break;
			case 'mobileNo':
				if ((numbersOnlyRegExp(event.key) === false || (event.keyCode === 32) || (mobileNo && mobileNo.length >= 10)) && this.handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
				break;
			case 'email':
				if (((event.keyCode === 32) || (email && email.length >= 50)) && this.handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
				break;
			case 'pin':
				if ((numbersOnlyRegExp(event.key) === false || (event.keyCode === 32) || (pin && pin.length >= 5)) && this.handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
				break;
			case 'store':
				if ((numbersOnlyRegExp(event.key) === false || (event.keyCode === 32) || (store && store.length >= 5)) && this.handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
				break;
			default:
				break;
		}
	}


	render() {
		const {
			validity,
			formObject: {
				firstName,
				lastName,
				pin,
				mobileNo,
				store,
			},
			countryCode,
			countrypincode,
			userUpdated,
			companyCode
		} = this.state;
		const {
			userDetails: {
				userType,
			},
		} = this.props;
		return (
			<div className="manage-users-container">
				<Tab.Container id="left-tabs-example" defaultActiveKey="first">
					<Row className="row">
						<Col lg={3} md={3} sm={3} xs={3} className="horizontal-tabs">
							<Nav variant="pills" className="flex-column">
								{(userType === 'COMPANY_ADMIN')
									&& (
										<Fragment>
											<Nav.Item>
												<Nav.Link eventKey="first">Create Driver</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="second">Activate/Modify Driver</Nav.Link>
											</Nav.Item>
										</Fragment>
									)}
								<Nav.Item>
									<Nav.Link eventKey={`${userType === 'COMPANY_ADMIN' ? "third" : "first"}`}>Delete Driver</Nav.Link>
								</Nav.Item>
							</Nav>
						</Col>
						<Col lg={9} md={9} sm={9} xs={9} className="horizontal-tab-details-container">
							<Tab.Content className="horizontal-tab-details">
								{(userType === 'COMPANY_ADMIN' )
									&& (
										<Fragment>
											<Tab.Pane eventKey="first">		
			         							<div className="label-input-container">
													<label htmlFor="firstName">First Name</label>
													<div>
														<Form.Control
															type="text"
															onChange={this.handleInputChange}
															onKeyDown={(event) => this.handleKeyDown(event, 'firstName')}
															name="firstName"
															maxLength="16"
															value={firstName}
														
														// placeholder="First Name"
														/>
														<FormValidationErrorMessage
															condition={!validity.isFirstNameNotNull}
															errorMessage="Please enter your first name"
														/>
														<FormValidationErrorMessage
															condition={!validity.isFirstnameMinLength && validity.isFirstNameNotNull && validity.isFirstNameAlphabetic}
															errorMessage="First Name should be atleast 2 characters"
														/>
														<FormValidationErrorMessage
															condition={!validity.isFirstnameLengthBelowLimit && validity.isFirstNameNotNull}
															errorMessage="First Name should not exceed 16 characters"
														/>
														<FormValidationErrorMessage
															condition={!validity.isFirstNameAlphabetic && validity.isFirstNameNotNull}
															errorMessage="First name must be alpha characters only"
														/>
													</div>
												</div>
												<div className="label-input-container">
													<label htmlFor="lastName">Last Name</label>
													<div>
														<Form.Control
															type="text"
															onChange={this.handleInputChange}
															onKeyDown={(event) => this.handleKeyDown(event, 'lastName')}
															name="lastName"
															maxLength="16"
															value={lastName}
														// placeholder="Last Name"
														/>
														<FormValidationErrorMessage
															condition={!validity.isLastNameNotNull}
															errorMessage="Please enter your last name"
														/>
														<FormValidationErrorMessage
															condition={!validity.isLastnameMinLength && validity.isLastNameAlphabetic}
															errorMessage="Last Name should be atleast 2 characters"
														/>
														<FormValidationErrorMessage
															condition={!validity.isLastnameLengthBelowLimit && validity.isLastNameAlphabetic}
															errorMessage="Last Name should not exceed 16 characters"
														/>
														<FormValidationErrorMessage
															condition={!validity.isLastNameAlphabetic && validity.isLastNameNotNull}
															errorMessage="Last name must contain alpha characters only"
														/>
													</div>
												</div>

												<div className="label-input-container">
													<label htmlFor="mobile"><span className="maroonColorTxt">Cell Phone </span>Number</label>
													<div>
														<InputGroup className="mobile-no-driver-container">
															<DropdownButton
																as={InputGroup.Prepend}
																variant="outline-secondary"
																id="input-group-dropdown-1"
																title={countryCode}
																onSelect={(key, e) => {
																	this.updateCountryCode(key, e);
																}}
															>
																<Dropdown.Item name="+91">+91</Dropdown.Item>
																<Dropdown.Item name="+1">+1</Dropdown.Item>
															</DropdownButton>
															<Form.Control
																type="text"
																onChange={this.handleInputChange}
																name="mobileNo"
																onKeyDown={(event) => this.handleKeyDown(event, 'mobileNo')}
																maxLength="11"
																value={mobileNo}
															/>
															<FormValidationErrorMessage
																condition={!validity.isMobileNoNotNull}
																errorMessage="Please enter your Cell Number"
															/>
															<FormValidationErrorMessage
																condition={!validity.isMobileNoNumeric && validity.isMobileNoNotNull}
																errorMessage="Cell number must be numeric only"
															/>
															<FormValidationErrorMessage
																condition={!validity.isMobileNoLengthBelowLimit && validity.isMobileNoNotNull && validity.isMobileNoNumeric}
																errorMessage="Cell number should not exceed 10 numbers"
															/>
															<FormValidationErrorMessage
																condition={!validity.isMobileNoMinLength && validity.isMobileNoLengthBelowLimit && validity.isMobileNoNumeric && validity.isMobileNoNotNull}
																errorMessage="Cell number should be atleast 10 numbers"
															/>
														</InputGroup>
													</div>
												</div>


												<div className="label-input-container">
													<label htmlFor="store">Store #</label>
													<div>
													<InputGroup className="mobile-no-driver-container">
														<Form.Control

															type="text"
															onChange={this.handleInputChange}
															onKeyDown={(event) => this.handleKeyDown(event, 'store')}
															name="store"
															maxLength="5"
															value={store}
														// placeholder="E-mail"
														/>
														<FormValidationErrorMessage
															condition={!validity.isStoreNotNull}
															errorMessage="Please enter your Store Number"
														/>
														<FormValidationErrorMessage
															condition={!validity.isStoreMinLength && validity.isStoreLengthBelowLimit && validity.isStoreNoNumeric && validity.isStoreNotNull}
															errorMessage="Store number should containes 4 digits only"
														/>
														<FormValidationErrorMessage
															condition={!validity.isStoreNoNumeric && validity.isStoreNotNull}
															errorMessage="Store number must be numeric only"
														/>
														<FormValidationErrorMessage
															condition={!validity.isStoreLengthBelowLimit && validity.isStoreNotNull && validity.isStoreNoNumeric}
															errorMessage="Store number should not exceed 4 digits"
															/>
                                                        </InputGroup>
													</div>
												</div>

												<div className="label-input-container">
													<label htmlFor="pin">PIN </label>
													<div>						
														<InputGroup className="mobile-no-driver-container">
															<DropdownButton
							                                    variant="outline-secondary"
																id="input-group-dropdown-1"
																title={companyCode + firstName.toUpperCase(firstName).charAt(0) + lastName.toUpperCase(lastName).charAt(0)}
																/>
															<Form.Control
																type="text"
																onChange={this.handleInputChange}
																name="pin"
																onKeyDown={(event) => this.handleKeyDown(event, 'pin')}
																maxLength="5"
																value={pin}
															>
										
															</Form.Control>
													


															<FormValidationErrorMessage
																condition={!validity.isPinNotNull}
																errorMessage="Please enter your 4 digit PIN"
															/>
															<FormValidationErrorMessage
																condition={!validity.isPinMinLength && validity.ispinLengthBelowLimit && validity.isPinNoNumeric && validity.isPinNotNull}
																errorMessage="PIN number should containes 4 digits only"
															/>
															<FormValidationErrorMessage
																condition={!validity.isPinNoNumeric && validity.isPinNotNull}
																errorMessage="PIN number must be numeric only"
															/>
															<FormValidationErrorMessage
															condition={!validity.ispinLengthBelowLimit && validity.isPinNotNull && validity.isPinNoNumeric }
															errorMessage="PIN number should not exceed 4 digits"
															/>
														</InputGroup>
													</div>
												</div>

												{this.renderUserTypeSelection()}
												<Button
													type="submit"
													className="user-management-submit-btn"
													//disabled={!_every(validity) | this.props.driverCreationApiCall }
													onClick={this.handleSubmit}
												>
													Create
										</Button>
											</Tab.Pane>
											<Tab.Pane eventKey="second">
												<ActivateOrInactivateDriver 
												  companyData={this.props.companyData}
												/>
											</Tab.Pane>
										</Fragment>
									)}
								<Tab.Pane  eventKey={`${userType === 'COMPANY_ADMIN' ? "third" : "first"}`}>
									<DeleteDriver
									companyData={this.props.companyData}
									/>
								</Tab.Pane>
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</div>
		);
	}
}

DriverManager.defaultProps = {
	userDetails: {
		userType: '',
		billTo: {
			billToId: ''
		}
	},
}

function mapStateToProps(state) {
	return {
		userCreationSuccess: state.user.userCreationSuccess,
		driverCreationApiCall: state.user.driverCreationApiCall,
		userDetails: state.user.userDetails,
		messageFromAPi: state.user.messageFromAPi,
		ShipToIds: state.ShipToIds.shipToIds,
	};
}
export default connect(mapStateToProps)(DriverManager);
