/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
  AsyncTypeahead,
  MenuItem,
  Menu,
  withItem,
} from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  getProductListFromSkuNumber,
  search,
  addSearchedValue,
} from "../../../components/Homepage/Search/globalSearch.thunk";
import {
  getGroupsForCategory,
  reset,
} from "../../../components/Homepage/Search/globalSearch.slice";
import { getPartsByMultiGroupOrchestrator } from "../../../actions/parts";
import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import {
  buildQueryUrl,
  getDefaultDataBasedOnUSerPref,
  getPreferenceDataFromStorage,
  isLengthLessthan,
  timeInUTC,
} from "../../../helpers/utils";
import "./styles.scss";
import { useRef } from "react";
import Fuse from "fuse.js";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import XModal from "../XModal";
import SpecificConditionsReselect from "../../Homepage/SpecificConditionsReselect";
import { setGroupSelectedFromSearch } from "../../../components/Homepage/SimpleHomepage/simplehomepage.slice";
import _isEmpty from "lodash/isEmpty";
import { logUserStockActivity } from "../../../actions/user";
import axios from "axios";
import instance from "../../../services/http.js";
import short from "short-uuid";
import { SEARCH_API_WORD } from "components/Homepage/Search/helpers";

const actionTypes = { SEARCHED_ADDTOCART_DATA: "SEARCHED_ADDTOCART_DATA" };
const ITEM_HEIGHT = 32;

function BreadCrumbInput(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const parts = useSelector((state) => state.parts);
  const searchInput = useRef(null);
  const [showSCReselect, setShowSCReselect] = useState(false);
  const [requestItems, setRequestItems] = useState(null);
  const user = useSelector((state) => state.user);
  const userDetails = useSelector((state) => state.user.userDetails);
  // Custom Menu Item
  const Item = withItem(MenuItem);
  const [searchFailureValue, setSearchFailure] = useState("");
  const [optionsWithDataGlobalSearch, setOptionsWithDataGlobalSearch] =
    useState(false);
  const [gloabalSearchStarted, setGlobalSearchStarted] = useState(false);
  const [updatedSearchValue, setUpdatedSearchValue] = useState("");
  const [sourceAlreadyCalled, setSourceAlreadyCalled] = useState({
    source: "",
    searchValue: "",
  });
  const [SelectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = React.useState([]);
  let [matches, setMatches] = React.useState({});
  let [matchesLength, setMatchesLength] = React.useState(0);
  const [queues, setQueues] = useState({});
  const [defaultInputValue, setDefaultInputValue] = useState(null);

  // API Call for Part Types by Vehicles
  const apiCall = (term, source, searchTermOrder, terms) => {
    instance
      .post(`${SEARCH_API_WORD}${buildQueryUrl(term)}`, null, {
        cancelToken: source.token,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        const results = resp;

        for (var j = 0; j < results.length; j++) {
          var resultTerm = results[j].name;
          if (matches[resultTerm]) {
            matches[resultTerm].matchCount += searchTermOrder; //earlier words receive the higher order
            if (matches[resultTerm].typeIdList.indexOf(results[j].id) < 0) {
              matches[resultTerm].typeIdList.push(results[j].id);
            }
            if (
              matches[resultTerm].groupNumberList.indexOf(results[j].groupNum) <
              0
            ) {
              matches[resultTerm].groupNumberList.push(results[j].groupNum);
            }
          } else {
            matchesLength++;
            matches[resultTerm] = {
              typeIdList: [results[j].id],
              groupNumberList: [results[j].groupNum],
              term: resultTerm,
              matchCount: searchTermOrder, //earlier words receive the higher order
              score: null,
            };
          }
        }

        for (var matchTerm in matches) {
          var index = 0;
          matches[matchTerm].score =
            matches[matchTerm].matchCount +
            matchTerm
              .toUpperCase()
              .split(/\s/)
              .reverse()
              // eslint-disable-next-line no-loop-func
              .map(function (e) {
                var termIndex = terms.indexOf(e),
                  score;

                if (terms.indexOf(e) < 0) {
                  score = 0;
                } else {
                  score = (termIndex + 1) * ++index;
                }
                return score;
              })
              .reduce((total, e) => total + e, 0);
        }

        let _finalResult = Object.entries(matches).sort(
          (a, b) => b[1].score - a[1].score
        );

        const finalResult = _finalResult.map((f) => f[1]);

        let searchResults = {};
        if (terms.length === 1) {
          searchResults = finalResult;
        } else {
          searchResults = {
            likelyMatches: finalResult.splice(0, 5),
            allMatches: finalResult.splice(0, 20),
          };
        }

        setOptions([{ parts: searchResults }]);
        // setIsPartsLoading(false);
      })
      .catch((err) => {
        // setIsPartsLoading(false);
      });
  };

  useEffect(() => {
    // globalSearch.options
    let seelctedData =
      parts.selectedGroupDetails ||
      parts.selectedPartType ||
      SelectedOption ||
      requestItems;
    let updatedSearchData =
      parts.selectedGroupDetails &&
      parts.selectedGroupDetails.map((item, i) => {
        const result =
          parts.categoryList &&
          parts.categoryList.find(
            (category) => Number(category.code) === Number(item.categoryId)
          );
        return {
          category: result?.name,
          group: item?.name,
        };
      });
    if (parts.selectedPartType !== null && globalSearch?.group?.length === 0) {
      updatedSearchData = {
        category: parts.selectedPartType?.groupAndCategories?.categoryDesc,
        group: parts.selectedPartType?.groupAndCategories?.groupDesc,
        partTypeDesc: parts.selectedPartType?.partTypeDesc,
      };
    } else if (
      Number(parts.selectedGroupDetails[0]?.code) ===
        Number(parts.selectedPartType?.groupAndCategories?.groupId) &&
      Number(parts.selectedGroupDetails[0]?.code) ===
        Number(globalSearch?.group[0].groupId)
    ) {
      updatedSearchData = {
        category: parts.selectedPartType?.groupAndCategories?.categoryDesc,
        group: parts.selectedPartType?.groupAndCategories?.groupDesc,
        partTypeDesc: parts.selectedPartType?.partTypeDesc,
      };
    }
    if (!Array.isArray(seelctedData) && typeof query === "string") {
      updatedSearchData = seelctedData;
    }
    if (parts.searchFailure) {
      handleSearchError(updatedSearchData, "resultNotFound");
      dispatch({
        payload: {
          searchFailure: false,
        },
        type: "SEARCH_FAILURE_ENABLED",
      });
    }
    if (parts.searchFailureForAddedToCart) {
      dispatch(addSearchedValue(updatedSearchData));
      isSearchValueAddedToCart(updatedSearchData);
      dispatch({
        payload: {
          searchFailureForAddedToCart: false,
        },
        type: "SEARCH_FAILURE_ENABLED_FOR_ADDED_TO_CART",
      });
    }
    // else if ( !_isEmpty(parts.partsAllDetails) && parts.searchFailure){
    // 	dispatch(addSearchedValue(SelectedOption))
    // 	isSearchValueAddedToCart(SelectedOption);
    // }
  }, [
    parts.searchFailure,
    parts.searchFailureForAddedToCart,
    parts.selectedPartType,
    SelectedOption,
    handleSearchError,
    dispatch,
    isSearchValueAddedToCart,
  ]);

  useEffect(() => {
    // Update the local state whenever globalSearch.options changes
    setTimeout(() => {
      setOptionsWithDataGlobalSearch(hasDataInOptions(globalSearch.options));
      const globalSearchResult = hasDataInOptions(globalSearch.options);
      if (
        !globalSearchResult &&
        gloabalSearchStarted &&
        updatedSearchValue === searchFailureValue
      ) {
        handleSearchError(searchFailureValue, "resultNotFound");
        setGlobalSearchStarted(false);
        setUpdatedSearchValue("");
      }
    }, 2000);
  }, [
    globalSearch?.options,
    gloabalSearchStarted,
    searchFailureValue,
    updatedSearchValue,
  ]);

  const hasDataInOptions = (options) => {
    if (options.length > 0 && options[0] && typeof options[0] === "object") {
      const objectAtIndex0 = options[0];
      for (const key in objectAtIndex0) {
        if (objectAtIndex0[key] && objectAtIndex0[key].length > 0) {
          return true; // At least one key-value array has data
        }
      }
    }
    return false; // No key-value array has data
  };
  const initializeValidity = () => {
    const validity = {
      globalSearchMaxLimit: true,
    };
    return validity;
  };

  const [validity, setValidity] = useState(initializeValidity);
  const mygarage = useSelector((state) => state.mygarage);
  const globalSearch = useSelector((state) => state.globalSearch);
  const sv =
    props.selectedVehicle || mygarage.selectedVehicle || search.selectedVehicle;
  const searchedValues = globalSearch.searchedValues;

  const onOptionEmpty = (q) => {
    const stockCheck = {
      requestItems: [
        {
          mfgCode: "*",
          partNum: q,
          qty: 1,
        },
      ],
    };
    dispatch(getProductListFromSkuNumber(stockCheck));
  };
  const handleSearchError = (query, resultfound) => {
    const updatedAvailabilityValue =
      parts?.catalogFilter?.availability &&
      parts?.catalogFilter?.availability.map((item) => {
        return item.availabilityText;
      });
    const errorData = {
      source: "Part Catalog - Default",
      searchvalue: JSON.stringify(query),
      resultFound: resultfound === "resultFound" ? true : false,
      selectedVehicle: sv
        ? `${sv?.year} ${sv?.make} ${sv?.model} ${sv?.engine}`
        : null,
      filters: {
        brand: parts?.catalogFilter?.brand,
        position: parts?.catalogFilter?.position,
        availability: updatedAvailabilityValue,
        misc: parts?.catalogFilter?.misc,
        partTypes: parts?.catalogFilter?.partTypes,
      },
    };
    logUserSearchFailureActivity(errorData, resultfound);
  };
  const logUserSearchFailureActivity = (storedErrors, resultfound) => {
    const { x2userName, shipTos, billTo } = userDetails;
    if (!_isEmpty(userDetails) && !_isEmpty(billTo)) {
      let loginId = "";
      let shipToId = shipTos[0]?.shipToId;
      let userData = JSON.stringify(storedErrors);
      let type =
        resultfound === "resultFound"
          ? "Search Result Found - Not Added To Cart"
          : "Search - No Result";
      const { searchValue } = sourceAlreadyCalled;
      //   if(searchValue !== storedErrors.searchvalue ){
      setSourceAlreadyCalled({
        source: storedErrors.source,
        searchValue: storedErrors.searchvalue,
      });

      dispatch(
        logUserStockActivity(
          timeInUTC(),
          billTo.billToId,
          loginId,
          shipToId,
          type,
          userData,
          x2userName
        )
      );
      // }
    }
  };

  const reset_matches = () => {
    setMatches({});
    setMatchesLength(0);
  };

  const handleSearchPromises = {};
  let cancelToken, source;
  const handleSearch = (query) => {
    setUpdatedSearchValue(query);
    let multipleQuery = query.trim().split(" ");
    multipleQuery = [].concat(query.trim());

    multipleQuery = _.uniq(multipleQuery);

    let validity = initializeValidity();
    validity.globalSearchMaxLimit = isLengthLessthan(query, 100);
    setValidity(validity);

    let payload = {};

    if (sv) {
      reset_matches();
      payload = {
        year: sv.year,
        make: sv.makeId,
        model: sv.modelId,
        engine: sv.engineId,
        searchString: query,
      };

      const terms = _.uniq(query.trim().toUpperCase().split(/\s+/).reverse());

      for (var q in queues) {
        queues[q]?.cancel();

        delete queues[q];
        setQueues(queues);
      }

      for (var i = 0; i < terms.length; i++) {
        cancelToken = axios.CancelToken;
        source = cancelToken.source();

        const term = terms[i];
        const searchTermOrder = i + 1;
        payload.searchString = term;

        handleSearchPromises[short.generate()] = source;

        setQueues(handleSearchPromises);
        apiCall(payload, source, searchTermOrder, terms);
      }
    } else {
      multipleQuery.forEach((q) => {
        let payload = { searchKey: q };

        dispatch(search(payload)).then((results) => {
          const {
            payload: { data },
          } = results;

          setTimeout(() => {
            if (results.payload && results.payload.data) {
              let oValues = Object.values(data);
              oValues = _.flatten(oValues.filter((v) => v));

              if (_.isEmpty(oValues)) {
                onOptionEmpty(query);
                setTimeout(() => {
                  setGlobalSearchStarted(true);
                }, 2000);
                setSearchFailure(query);
              }
            }
          }, 1000 * 1);
        });
      }); // multiplequery foreach end
    }
  };

  const isSearchValueAddedToCart = (option) => {
    if (searchedValues.length === 0) {
      // Handle empty arrays as needed
      return false;
    }

    if (searchedValues.length >= 1) {
      if (parts.isSearchedValuesAddedToCart) {
        dispatch({
          payload: {
            selectedAddCartData: parts.selectedAddCartData,

            isSearchedValuesAddedToCart: false,
          },
          type: actionTypes.SEARCHED_ADDTOCART_DATA,
        });
      } else {
        handleSearchError(
          searchedValues[searchedValues.length - 1],
          "resultFound"
        );
      }
    }
  };

  const getCategoryFromGroupId = (groupId) => {
    const _category = _.find(parts.categoryGroupMap, (c) => {
      return _.find(c.groups, (g) => g.code === Number(groupId));
    });

    const _group = _.find(_category.groups, (g) => g.code === Number(groupId));

    const category = Object.assign({}, _category);
    const group = Object.assign({}, _group);

    return { category, group };
  };

  const getGroupFromCategory = (categoryName, groupName) => {
    const category = _.find(
      parts.categoryGroupMap,
      (c) => c.name.toLowerCase() === categoryName.toLowerCase()
    );
    const g = _.find(
      category.groups,
      (g) => g.name.toLowerCase() === groupName.toLowerCase()
    );
    return Object.assign({}, g);
  };

  const handleChange = (selected) => {
    const option = _.head(selected);
    setSelectedOption(option);
    if (!option) return;

    switch (option.type) {
      case "partSkus":
        history.push(
          `/stock-check?partSku=${option.partSku.partNum}&description=${option.option}&mfgCode=${option.partSku.mfgCode}`
        );
        break;
      case "parts":
        if (props.isExpress) {
          searchInput.current.toggleMenu();
          searchInput.current.toggleMenu();
          const groupId = option.groupNumberList?.[0];

          const { category: _category, group: _group } = getCategoryFromGroupId(
            groupId,
            parts.categoryGroupMap
          );

          dispatch(
            setGroupSelectedFromSearch({
              option: option.groupAndCategories || {
                categoryId: _category.code,
                groupId: _group.code,
              },
              sv,
            })
          );
        } else {
          history.push("/product-list");

          const { group, category: _category } = getCategoryFromGroupId(
            option.groupAndCategories.groupId
          );
          // Dispatch Selected Group
          group.categoryId = _category.code;

          dispatch({
            payload: {
              selectedGroupDetails: [group],
            },
            type: "GROUP_SELECTED",
          });
          const payload = {
            requestItems: [
              {
                groupCode: option.groupAndCategories.groupId,
                specificCondition: [],
              },
            ],
          };

          // store the selected part type for the filter to show in the part type dropdown filter
          dispatch({
            payload: {
              selectedPartType: option,
            },
            type: "SELECTED_PART_TYPE",
          });

          let filter = {
            ...parts.catalogFilter,
            exactMatch: true,
            partTypes: [{ id: option.partTypeId }],
          };

          dispatch(
            getPartsByMultiGroupOrchestrator(
              sv.year,
              sv.makeId,
              sv.modelId,
              sv.engineId,
              payload,
              null,
              null,
              null,
              null,
              filter,
              user.userDetails.billTo.mclSupplier,
              option || parts.selectedPartType,
              false,
              sv
            )
          ).then((response) => {});
        }
        break;
      case "category":
        const categoryGroups = parts.categoryGroupMap;

        let category = _.filter(
          categoryGroups,
          (c) => c.name === option.categoryDesc
        );
        category = _.head(category);

        dispatch(getGroupsForCategory(category));

        searchInput.current.toggleMenu();

        break;
      case "group":
        if (props.isExpress) {
          searchInput.current.toggleMenu();
          searchInput.current.toggleMenu();
          dispatch(setGroupSelectedFromSearch({ option, sv }));
        } else {
          history.push("/product-list");
          // Dispatch Selected Group
          let _group = getGroupFromCategory(
            option.categoryDesc,
            option.groupDesc
          );
          const __group = Object.assign(_group, {
            categoryId: +option.categoryId,
          });

          dispatch({
            payload: {
              selectedGroupDetails: [__group],
            },
            type: "GROUP_SELECTED",
          });
          let reqObj = {
            requestItems: [
              {
                groupCode: option.groupId,
                specificCondition: [],
              },
            ],
          };

          const { exactMatch, ...groupFilters } = parts.catalogFilter;

          dispatch(
            getPartsByMultiGroupOrchestrator(
              sv.year,
              sv.makeId,
              sv.modelId,
              sv.engineId,
              reqObj,
              null,
              null,
              null,
              null,
              groupFilters,
              user.userDetails.billTo.mclSupplier,
              option || parts.selectedPartType,
              false,
              sv
            )
          );
        }
        break;
      default:
        break;
    }
  };

  const filterBy = (option, { text }) => {
    return ["option"];
  };

  const fuzySearch = (options) => {
    let intermOptions = {};

    if (options.length > 0 && searchInput && searchInput.current) {
      const input = searchInput.current.getInput();

      intermOptions = Object.assign({}, _.head(options));
      const fuzzyParts = new Fuse(options[0].parts, {
        keys: ["partTypeDesc"],
        threshold: 0.2,
        includeMatches: true,
        includeScore: true,
        findAllMatches: false,
      });
      let searchedParts = fuzzyParts
        .search(input.value.trim())
        .map((i) => i.item);
      const _parts = options[0].parts || [];
      searchedParts = _.uniqBy(_parts.concat(searchedParts), (p) => p.term);

      intermOptions.parts = searchedParts;
    }

    return [intermOptions];
  };

  const getGroupLabels = (label) => {
    switch (label) {
      case "partSkus":
        return "Part SKUs - Go To Stock Check";
      case "group":
        return "Groups";
      case "category":
        return "Categories";
      case "parts":
        return "Part Descriptions";
      default:
        break;
    }
  };

  const getValue = (option, type) => {
    switch (type) {
      case "partSkus":
        return option.option;
      case "category":
        return option.categoryDesc;
      case "group":
        return option.groupDesc;
      case "parts":
        return `${option.term || option.partTypeDesc}`;
      default:
        break;
    }
  };

  const getLabel = (option) => {
    return getValue(option, option.type);
  };

  const setOption = (option, type) => {
    let _option = Object.assign({}, option);
    _option.type = type;
    return _option;
  };

  const discard = (obj) => {
    let _obj = Object.assign({}, obj);
    if (obj) {
      delete _obj.states;
      delete _obj.make;
      delete _obj.model;
      delete _obj.vehicles;
    }
    return _obj;
  };

  const onKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      const list = document.querySelectorAll(".dropdown-item");
      const firstItem = list[0];

      firstItem?.focus();
    }
  };

  const handleKeyDown = (e) => {
    const activeElement = e.target;
    const currTarget = e.target;
    const dataset = currTarget.dataset;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      activeElement.nextSibling.focus();
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      activeElement.previousSibling.focus();
    } else if (e.key === "ArrowRight") {
      const currId = Number(dataset.id);
      const parentElement = currTarget.parentElement;

      const height = parentElement.scrollHeight;

      const ITEM_PER_ROW = parseInt(height / ITEM_HEIGHT);
      const nextId = currId + ITEM_PER_ROW;
      const list = parentElement.querySelector(`[data-id="${nextId}"]`);
      if (list) {
        list.focus();
      }
    } else if (e.key === "ArrowLeft") {
      const currId = Number(dataset.id);
      const parentElement = currTarget.parentElement;

      const height = parentElement.scrollHeight;

      const ITEM_PER_ROW = parseInt(height / ITEM_HEIGHT);
      const nextId = currId - ITEM_PER_ROW;
      const list = parentElement.querySelector(`[data-id="${nextId}"]`);
      if (list) {
        list.focus();
      }
    } else if (e.key === "Enter") {
      const option = JSON.parse(dataset?.option);

      handleChange([option]);
    } else if (e.key === "Tab") {
      const parentNode = currTarget.parentNode;
      const grandParent = parentNode.parentNode;
      const grandParentSibling = grandParent.nextSibling;

      if (!grandParentSibling) {
        const greatGrandParent = grandParent.parentNode;
        const firstChild = greatGrandParent.firstChild;

        const item = firstChild?.querySelector(".dropdown-item");
        if (item) {
          item.focus();
        }
      }

      const listItem = grandParentSibling?.querySelector(".dropdown-item");
      if (listItem) {
        listItem.focus();
      }
    }
  };

  const renderMenu = (results, menuProps, state) => {
    if (!results.length) {
      return (
        <Menu {...menuProps}>
          <MenuItem>Searching...</MenuItem>
        </Menu>
      );
    }
    if (!globalSearch.loading && false) {
      const top = _.head(results);

      let flag = false;
      const values = Object.values(discard(top));
      for (let i = 0; i < values.length; i++) {
        const t = values[i];
        if (t.length > 0) {
          flag = true;

          break;
        }
      }

      if (!flag) {
        return (
          <Menu {...menuProps}>
            <MenuItem>No Matching results</MenuItem>
          </Menu>
        );
      }
    }

    return (
      <Menu {...menuProps} onKeyDown={handleKeyDown}>
        {results?.map((result, index) => {
          return Object.keys(discard(result))
            .sort()
            .map((label, i) => {
              return (
                (result[label].length > 0 || label === "parts"
                  ? true
                  : false) && (
                  <React.Fragment key={`${i}-main-${label}`}>
                    {i !== 0 && <Menu.Divider />}
                    <Menu.Header>
                      <h6>{getGroupLabels(label)}</h6>
                    </Menu.Header>
                    <React.Fragment>
                      {label === "parts" ? (
                        Array.isArray(result[label]) ? (
                          result[label].map((value, oIndex) => {
                            return (
                              <MenuItem
                                key={`${index}_${i}_${label}_${oIndex}`}
                                option={setOption(value, label)}
                                data-option={JSON.stringify(
                                  setOption(value, label)
                                )}
                                data-id={oIndex}
                              >
                                {getValue(value, label)}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <div className="parts-desc">
                            {result[label]["likelyMatches"] &&
                              result[label]["likelyMatches"].length > 0 && (
                                <React.Fragment>
                                  {" "}
                                  <div className="parts-title">
                                    Likely Matches
                                  </div>
                                  {result[label]["likelyMatches"]?.map(
                                    (value, oIndex) => {
                                      return (
                                        <MenuItem
                                          key={`${index}_${i}_${label}_${oIndex}`}
                                          option={setOption(value, label)}
                                          data-option={JSON.stringify(
                                            setOption(value, label)
                                          )}
                                          data-id={oIndex}
                                        >
                                          {getValue(value, label)}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </React.Fragment>
                              )}

                            {result[label]["allMatches"] &&
                              result[label]["allMatches"].length > 0 && (
                                <React.Fragment>
                                  <div className="parts-title">All Matches</div>
                                  {result[label]["allMatches"]?.map(
                                    (value, oIndex) => {
                                      return (
                                        <MenuItem
                                          key={`${index}_${i}_${label}_${oIndex}`}
                                          option={setOption(value, label)}
                                          data-option={JSON.stringify(
                                            setOption(value, label)
                                          )}
                                          data-id={oIndex}
                                        >
                                          {getValue(value, label)}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </React.Fragment>
                              )}
                          </div>
                        )
                      ) : (
                        result[label]?.map((value, j) => {
                          return (
                            <MenuItem
                              key={`${label}-${i}-${j}`}
                              option={setOption(value, label)}
                              data-option={JSON.stringify(
                                setOption(value, label)
                              )}
                              data-id={j}
                            >
                              {getValue(value, label)}
                            </MenuItem>
                          );
                        })
                      )}
                    </React.Fragment>
                  </React.Fragment>
                )
              );
            });
        })}
      </Menu>
    );
  };

  return (
    <div
      className={
        props.isExpress
          ? "express-breadcrumb-input-box"
          : "breadcrumb-input-box"
      }
    >
      <AsyncTypeahead
        id="category__search"
        useCache={false}
        ref={searchInput}
        filterBy={filterBy}
        labelKey={(option) => getLabel(option)}
        minLength="2"
        inputProps={{ className: "input__default input__search" }}
        onSearch={handleSearch}
        onChange={handleChange}
        options={
          !globalSearch.loading
            ? fuzySearch(sv ? options : globalSearch.options || [])
            : []
        }
        placeholder="Search by Category, Group, Part Description, Part No..."
        renderMenu={renderMenu}
        onKeyDown={onKeyDown}
      />
      <FormValidationErrorMessage
        condition={!validity.globalSearchMaxLimit}
        errorMessage="Global search key should not exceed more than 100 characters"
      />
    </div>
  );
}
export default BreadCrumbInput;
