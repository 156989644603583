/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container } from 'react-bootstrap';
import React, { useState } from 'react';
import { MenuItem, withItem } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import favoriteIcon from "../../../static/images/icons/red/Select_Deselect_Favorite_1.png";
import { ReactComponent as FavoriteIcon } from "../../../static/images/icons/Select_Deselect_Favorite_1.svg";
import { setFavoriteVehicle } from "../MyGarage/mygarage.thunks";
import { search } from "../../Homepage/Search/globalSearch.thunk";
import { timeInUTC } from '../../../helpers/utils';

function FavoriteIconHeader(props) {
    const dispatch = useDispatch();
    const mygarage = useSelector((state) => state.mygarage);
    const sv = mygarage.selectedVehicle || search.selectedVehicle;

    const handleToggleFavorite = (v, flag) => {
        const updated = {
            ...v,
            favoriteText: "",
            favoriteFlag: flag,
            favoriteUpdatedDate: timeInUTC(),
        };

        dispatch(setFavoriteVehicle(updated));
    };


    return (
        <div style={{padding: "7px 17px 0px 17px",fontSize: "17px"}} >
                        {sv && sv.favoriteFlag === "N" && (
                                        <FavoriteIcon
                                            className="non__favorite favoriteIcon"
                                            onClick={() => handleToggleFavorite(sv, "Y")}
                                        />
                                    )}

                                    {sv && sv.favoriteFlag === "Y" && (
                                        <React.Fragment>
                                        <FavoriteIcon
                                            className="favorite favoriteIcon"
                                            onClick={() => handleToggleFavorite(sv, "N")}
                                        />
                                        </React.Fragment>
                                    )}
        </div>
    );
}
export default FavoriteIconHeader;
