/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { formatAmount, formatMonth } from "../../../helpers/utils";
import "./styles.scss";

const headerStyle = {
  backgroundColor: "#0F5283",
  color: "#FFFFFF",
};

const BatteryPurchaseTable = (props) => {
  const [MonthlySalesColumns, setMonthlySalesColumns] = useState([]);
  const [MonthlySalesTableData, setMonthlySalesTableData] = useState([]);
  const [IndicatorColumns, setIndicatorColumns] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [TableData, setTableData] = useState([]);

  const data = useSelector((state) => state.ProgramAndBenefits);

  const benefitData = data && data.benefitData;

  const batteryPurchased = benefitData && benefitData.batteryPurchased;

  useEffect(() => {
    if (!_isEmpty(batteryPurchased)) {
      constructBatteryPurchase(batteryPurchased);
    }
  }, [batteryPurchased]);

  const constructBatteryPurchase = (data) => {
    setMonthlySalesTableData([]);
    setMonthlySalesColumns([]);
    setIndicatorColumns([]);
    setTableColumns([]);
    setTableData([]);

    const rowSalesData = [];
    if (!_isEmpty(data)) {
      data.forEach((item, index) => {
        rowSalesData.push({
          month: formatMonth(item.monthYear),
          purchaseQty: item.batteriesPurchased ? item.batteriesPurchased : 0,
          goal: formatAmount(item.towardsGoal),
          credit: formatAmount(
            item.creditTowardsTester ? item.creditTowardsTester : 0
          ),
          invoiceNumber: item.invoiceNumber ? item.invoiceNumber : "",
          invoiceUrl: item.invoiceUrl,
          index: index,
        });
      });
    }

    const MonthlySalesColumns = [
      {
        dataField: "month",
        text: "Month/Year",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
      {
        dataField: "purchaseQty",
        text: "Batteries Purchased",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
        classes: function callback(cell, row, rowIndex, colIndex) {
          return row.purchaseQty <= 0
            ? "bold"
            : row.purchaseQty <= 6
            ? "health-bg-danger"
            : row.purchaseQty > 6 && row.purchaseQty <= 11
            ? "bg-warning"
            : row.purchaseQty >= 12
            ? "bg-success"
            : "";
        },
      },
    ];
    const indicatorColumns = [
      {
        dataField: "batterySalesIndicator",
        text: "Battery Sales Indicator",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
    ];
    const loanColumns = [
      {
        dataField: "partno",
        text: "Batteries on Consignment",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
    ];
    setTableColumns(loanColumns);
    setMonthlySalesColumns(MonthlySalesColumns);
    setMonthlySalesTableData(rowSalesData);
    setIndicatorColumns(indicatorColumns);
  };

  const renderNoDataTb = () => {
    let headers = Object.values(MonthlySalesColumns);
    return (
      <Row>
        <Col md={4}>
          <table className="table table-bordered">
            <thead>
              <tr>
                {headers.map((header) => {
                  return <th>{header.text}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {MonthlySalesTableData && MonthlySalesTableData.length === 0 && (
                <tr>
                  <td align="center" colspan="7">
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const indicators = [
    { class: "health-bg-danger", label: "Low (below 6)" },
    { class: "bg-warning", label: "Medium" },
    { class: "bg-success", label: "High (above 11)" },
  ];

  return (
    <div className="consignment-container">
      <div className="battery-sales-indicator-wrapper">
        <div className="title">Battery Sales Indicator</div>
        <div className="battery-indicators-wrapper">
          {indicators.map((indi, i) => {
            return (
              <div key={i} className="battery-indicator">
                <div className={`box ${indi.class}`}></div>
                <div className="box-label">{indi.label}</div>
              </div>
            );
          })}
        </div>
      </div>
      <Row className="consignment-view-container">
        <Col md={4}>
          {MonthlySalesTableData &&
          MonthlySalesTableData.length > 0 &&
          MonthlySalesColumns.length > 0 ? (
            <BootstrapTable
              bootstrap4
              keyField="specDesc"
              data={MonthlySalesTableData}
              columns={MonthlySalesColumns}
              wrapperClasses="monthly-sales-responsive"
              noDataIndication
              // rowClasses="custom-row-class"
            />
          ) : (
            renderNoDataTb()
          )}
        </Col>
      </Row>
    </div>
  );
};
BatteryPurchaseTable.propTypes = {};
export default BatteryPurchaseTable;
