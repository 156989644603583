import { createSlice } from "@reduxjs/toolkit";
import _isEmpty from "lodash/isEmpty";
import _ from "lodash";
import {
  getStockProductListFromSkuNumber,
  uploadPartList,
  // getEpartsExpertList,
  getOrderNumber,
  getMagicalWordsList,
  getSingleProductDetails,
} from "./stockcheck.thunk";

let initialState = {
  productList: [],
  magicLists: [],
  fetchingData: false,
  uploadingFile: false,
  isEmptyEpartsData: null,
  epartsData: [],
  chemicalLoading: false,
  chemicalData: [],
  selectedProductList: [],
  selectedStockList: [],
  magicWordOptions:[],
  c2cLink: {},
  stockList: {},
  showLoader: false,
  uploadFileError: false,
  noPartError: false,
  resetSearch: false,
};

const stockCheck = createSlice({
  name: "stockCheck",
  initialState,
  reducers: {
    updateProductList(state, action) {
      state.selectedProductList = action.payload;
    },
    updateSelectedStockList(state, action) {
      state.selectedStockList = action.payload;
    },
    resetProductList(state) {
      state.productList = [];
      // state.selectedProductList = [];
      // state.selectedStockList = [];
      state.stockList = {};
    },
    updateStockList(state, action) {
      state.stockList = action.payload;
    },
    resetStockList(state) {
      state.stockList = {};
    },
    resetSearch(state) {
      state.resetSearch = true
    },
    resetPageContent(state) {
      state.productList = [];
      state.selectedStockList = [];
      state.selectedProductList = [];
      state.stockList = {};
    },
    updateLoaderStatus(state, action) {
      state.showLoader = action.payload;
    },
 

    // updateProductListForC2C(state, action) {
    //     state.selectedProductList = action.payload;
    // }
  },
  extraReducers: {
    [getMagicalWordsList.fulfilled]: (state, action) => {
      state.magicLists = action.payload;
      let optionArr =[];
      if(!_isEmpty(action.payload)){
        action.payload.map((magicname) => {
          optionArr.push({
            value: magicname.magicPartNumber,
            label: magicname.magicPartNumber,
          });
        });
      }
      let magic = _.uniqBy(optionArr ,"value");
      state.magicWordOptions = magic;
    },
    [getStockProductListFromSkuNumber.fulfilled]: (state, action) => {
      const prevState = state.productList;
      const prevselectedProductList = state.selectedProductList;
      let singleProductDetails = {};
      let updatedProductList = [];
      let updatedselectedProductList = [];
      let resetStatus = false;
      const responseArr = [];
      if (!_isEmpty(action.payload.responseData)) {
        action.payload.responseData.map((response) => {
          if (
            response.status === "8" ||
            response.status === "1" ||
            response.status === "6" ||
            response.status === "3"
          ) {
            responseArr.push(response);
          }
        });
      }
      const isSingleProduct = responseArr.length === 1;
      if (!_isEmpty(action.payload)) {
        action.payload.responseData.map((product) => {
          if (
            (product.status === "8" ||
              product.status === "3" ||
              product.status === "6") &&
            !isSingleProduct
          ) {
            const productObj = {
              ...product,
              reqPartNum: action.payload.partNumber,
              isEpartExpert: true,
              isImport: false,
              modifiedId: `${product.mfgCode} ${product.partNum} - ${product.itemSpecific}`,
              //modifiedIdForAltSup: `${product.mfgCode} ${product.partNum} - ${product.itemSpecific}`,
              isSub: product.status === "3" ? true : false,
              isAlt: product.status === "6" ? true : false,
            };
            updatedProductList.push(productObj);
          }

          if (
            product.status === "1" ||
            (product.status === "8" && isSingleProduct)
          ) {
            singleProductDetails = action.payload.singleProductDetails;
            const selectedProductObj = {
              ...product,
              qty: singleProductDetails.qty,
              smartLink: singleProductDetails.smartLink,
              availability: singleProductDetails.availability,
              allAvailabilityData: singleProductDetails.allAvailabilityData,
              addToOrder: singleProductDetails.addToOrder,
              isEpartExpert: singleProductDetails.isEpartExpert,
              manufacturer: singleProductDetails.manufacturer,
              orderId: singleProductDetails.orderId,
              partType: singleProductDetails.partType,
              partDescription: singleProductDetails.partDescription,
              uniqId: singleProductDetails.uniqId,
            };
            resetStatus = true;
            updatedselectedProductList.push(selectedProductObj);
          }
        });

        action.payload.partsNotFoundData.map((product) => {
          if (product.status === "2" ){
            const productObj = {
              ...product,
              reqPartNum: action.payload.partNumber,
            };
            updatedProductList.push(productObj);
          }
        })
    
        updatedProductList = [...prevState, ...updatedProductList];
        updatedselectedProductList = [
          ...prevselectedProductList,
          ...updatedselectedProductList,
        ];
        return {
          ...state,
          productList: updatedProductList,
          noPartError: _isEmpty(updatedProductList),
          selectedProductList: updatedselectedProductList,
          resetSearch: resetStatus,
        };
      }
    },
    [uploadPartList.pending]: (state) => {
      state.uploadingFile = true;
    },
    [uploadPartList.fulfilled]: (state, action) => {
      state.uploadingFile = false;
      let updatedProductList = [];
      const prevState = state.selectedProductList;
      if (!_isEmpty(action.payload)) {
        action.payload.map((product, key) => {
          let updatedAvailibility = [];
          !_isEmpty(product.availability) &&
            product.availability.map((availData) => {
              if (availData.availableQty > 0) {
                updatedAvailibility.push(availData);
              }
            });
          if (product.status === "1" || product.status === "8" || product.status === "6" ||
          product.status === "3" || product.status === "2") {
            const productObj = {
              ...product,
              reqPartNum: product.partNum,
              addToOrder: true,
              isEpartExpert: true,
              isImport: true,
              isLinkApiCalled: false,
              availability: updatedAvailibility,
              modifiedId: `${product.mfgCode} ${product.partNum} - ${product.itemSpecific}`,
              uniqId: `${prevState.length}-${key}`,
            };
            updatedProductList.push(productObj);
          }
        });
        updatedProductList = [...prevState, ...updatedProductList];
        //state.selectedProductList = updatedProductList;
        state.selectedStockList = updatedProductList;
      }
    },
    [uploadPartList.rejected]: (state) => {
      state.uploadingFile = false;
      state.uploadFileError = true;
    },
    // [getEpartsExpertList.fulfilled]: (state, action) => {
    //     if (!_isEmpty(action.payload)) {
    //         state.epartsData = action.payload;
    //         state.isEmptyEpartsData = false;
    //     } else {
    //         state.isEmptyEpartsData = true;
    //     }
    // },
    [getOrderNumber.pending]: (state) => {
      state.showLoader = true;
    },
    [getOrderNumber.fulfilled]: (state, action) => {
      state.c2cLink = action.payload;
      state.showLoader = false;
    },
    [getOrderNumber.rejected]: (state) => {
      state.showLoader = false;
    },
  },
});

const { actions, reducer } = stockCheck;

export const {
  updateSelectedStockList,
  updateProductList,
  resetProductList,
  updateStockList,
  resetPageContent,
  resetStockList,
  updateLoaderStatus,
  resetSearch
} = actions;

export default reducer;
