import React from "react";
import { Typography as MuiTypography } from "@mui/material";

function Typography(props) {
  const { as } = props;

  return <MuiTypography variant={as} {...props} />;
}

export default Typography;
