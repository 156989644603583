import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  Image,
  Overlay,
  Tab,
  TabContainer,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import myGarage from "../../../static/images/icons/red/MY_GARAGE.png";
import { useRef } from "react";

import Vehicles from "./Vehicles";
import Jobs from "./Jobs";
import Quotes from "./Quotes";
import PartsHistory from "./PartsHistory";
import Orders from "./Orders";
import "./styles.scss";
import _isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import {
  getPreferenceDataFromStorage,
  getUserPreferenceItem,
} from "../../../helpers/utils";
import Returns from "./Returns";
import StockOrders from "./StockOrders";
import { useHistory } from "react-router-dom";
import { isMobile, isTablet, isSafari } from "react-device-detect";

const MyGarage = () => {
  const [showMyGaragePopUp, setShowMyGaragePopUp] = useState(false);
  const target = useRef(null);
  const mygarage = useSelector((state) => state.mygarage);
  const sv = mygarage.selectedVehicle;
  const [show, setShow] = useState(false);
  const [canAccessAccount, setCanAccessAccount] = useState(false);
  const [canAccessReturns, setCanAccessReturns] = useState(false);
  const [isRootClose, setIsRootClose] = useState(true);

  const history = useHistory();

  const user = useSelector((state) => state.user);
  const userType =
    user.userDetails && user.userDetails.userType
      ? user.userDetails.userType
      : null;

  const handleCloseOverlay = () => {
    setShow(false);
  };

  const getTextWidth = (text, font) => {
    var canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
  };

  const renderYMME = (text) => {
    const width = getTextWidth(text, "500 13px Roboto, sans-serif");

    return width > 190 ? true : false;
  };

  const handleRootClose = (e) => {
    const target = e.target;
    const isModalOpen = document.querySelectorAll(".modal.show");
    const isModalElement = target.parentElement.className.includes("modal");

    if (!_isEmpty(e.path)) {
      for (let i = 0; i < e.path.length; i++) {
        const path = e.path[i];
        const className = path.className;

        if (className && className.includes("daterangepicker")) {
          return false;
        }
      }
    }
    if (isModalOpen.length === 0 && !isModalElement) {
      setShow(false);
    }
    if (
      e.target.innerText === "Apply" ||
      e.target.innerText === "Cancel" ||
      e.target.className === "prev available" ||
      e.target.className === "next available" ||
      e.target.parentElement.className === "next available" ||
      e.target.parentElement.className === "prev available"
    ) {
      setShow(true);
      return false;
    }

    setShow(!show);
  };

  useEffect(() => {
    userType !== null &&
    (userType === "CUSTOMER_ADMIN" ||
      userType === "IT_ADMIN" ||
      userType === "OSR" ||
      userType === "DSM" ||
      userType === "SUPER_ADMIN" ||
      userType === "SMS_MITCHELL1")
      ? setCanAccessAccount(true)
      : setCanAccessAccount(false);
    if (userType !== null) {
      let userPreferenceDetail = getPreferenceDataFromStorage();
      if (!_isEmpty(userPreferenceDetail)) {
        let grandAccessItemSet = userPreferenceDetail.find((item) => {
          return (
            item.text === "Grant Access To" && item.code === "User Management"
          );
        });

        if (grandAccessItemSet && grandAccessItemSet.multiAnswer) {
          grandAccessItemSet.multiAnswer.map((singleAnswer) => {
            if (singleAnswer.code == "account") {
              setCanAccessAccount(true);
            }
          });
        }
      }

      if (!_isEmpty(userPreferenceDetail)) {
        let grandAccessItems = userPreferenceDetail.find((item) => {
          return item.text === "smsWebUser" && item.code === "User Management";
        });

        if (grandAccessItems && grandAccessItems.possibleAnswer) {
          grandAccessItems.possibleAnswer.map((singleAnswer) => {
            if (singleAnswer.default && userType === "SMS_WEB") {
              setCanAccessAccount(true);
            }
          });
        }
      }

      if (!_isEmpty(userPreferenceDetail)) {
        let grandAccessReturn = userPreferenceDetail.find((item) => {
          return (
            item.text === "return-default-stores" &&
            item.code === "User Management"
          );
        });

        if (grandAccessReturn && grandAccessReturn.possibleAnswer) {
          grandAccessReturn.possibleAnswer.map((singleAnswer) => {
            const arr = singleAnswer.text.split(";");
            let foundReturnId = arr.find((item) => {
              //return item === user.userDetails.xlPartAccountNumber;
              return item === user.userDetails.shipTos[0]?.storeNumber;
            });
            if (
              foundReturnId !== undefined &&
              singleAnswer.default &&
              foundReturnId === user.userDetails.shipTos[0]?.storeNumber
            ) {
              setCanAccessReturns(true);
            } else {
              setCanAccessReturns(false);
            }
          });
        }
      }
    }
  }, [user]);

  // Do not close the my garage modal on closing other modal, when my garage is present.
  const handleUserKeyPress = useCallback((event) => {
    const garageModalNode = document.querySelector(".mygarage.show");
    const garageModalWithAnotherModalNode =
      document.querySelector(".modal.show");

    if (
      document.body.contains(garageModalNode) &&
      document.body.contains(garageModalWithAnotherModalNode)
    ) {
      setIsRootClose(false);
    } else {
      setIsRootClose(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", handleUserKeyPress);
    return () => {
      window.removeEventListener("click", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
  // End

  const isStockOrderEnable = () => {
    let enableStockUser = false;
    let { x2userName, userType } = user.userDetails;
    enableStockUser = userType === "IT_ADMIN";
    const mailList = getUserPreferenceItem(
      "stockOrderEmailList",
      "customer-list"
    );
    if (mailList && mailList.length > 0) {
      enableStockUser = mailList.some(
        (item) =>
          (item === x2userName && userType === "OSR") ||
          (item === "all" && (userType === "IT_ADMIN" || userType === "OSR")) ||
          item === "OSR"
      )
        ? true
        : enableStockUser;
    }
    const loc = window.location;
    const hostname = loc.hostname;
    const isProd = hostname.includes("rapid");
    //if(userType === 'IT_ADMIN' && !isProd) {
    if (enableStockUser) {
      return true;
    } else {
      return false;
    }
  };

  const handleStockOrder = () => {
    setShow(false);
    history.push("/stock-order");
    const menu = document.querySelector(".navbar-toggler");
    const body = document.querySelector("body");
    if (menu && body) {
      setTimeout(() => {
        body.click();
      }, 100);
      menu.click();
    }
  };

  return (
    <Fragment>
      <div
        className="my-garage-btn-wrapper"
        onClick={() => setShow(!show)}
        ref={target}
      >
        <div className="my-garage-btn-img-wrapper">
          <Image src={myGarage} />
        </div>
        <div className="my-garage-btn-text">
          <div className="title__line">
            <span>MY GARAGE </span>
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
          {sv ? (
            renderYMME(`${sv.year} ${sv.make} ${sv.model}`) ? (
              <span>{`${sv.year} ${sv.make}`}</span>
            ) : (
              <span>{`${sv.year} ${sv.make} ${sv.model}`}</span>
            )
          ) : (
            <span>No Vehicle Selected</span>
          )}
        </div>
      </div>
      <div className={showMyGaragePopUp ? "active" : ""}>
        <Overlay
          target={target.current}
          show={show}
          placement="bottom-end"
          rootClose={true}
          onHide={handleRootClose}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              className="mygarage"
              {...props}
              style={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                color: "black",
                borderRadius: 0,
                minWidth: "45%",
                maxWidth: isMobile ? "calc(100% - 30px)" : "45%",
                marginLeft: isMobile || isTablet ? "32px" : "-10%",
                marginTop: "10px",
                boxShadow: "0 0 3px 0 #000",
                zIndex: 999,
                paddingRight: "20px",
                ...props.style,
              }}
            >
              <div className="v__tabs">
                <TabContainer defaultActiveKey="vehicles">
                  <Row>
                    <Col md={12}>
                      <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                          <Nav.Link eventKey="vehicles">Vehicles</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="jobs">Jobs</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="quotes">Quotes</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="partsHistory">
                            Parts History
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="orders">Orders</Nav.Link>
                        </Nav.Item>
                        {canAccessReturns ? (
                          <Nav.Item>
                            <Nav.Link eventKey="returns">Returns</Nav.Link>
                          </Nav.Item>
                        ) : null}
                        <Nav.Item>
                          {isStockOrderEnable() && (
                            <Nav.Link onClick={() => handleStockOrder()}>
                              Stock Orders
                            </Nav.Link>
                          )}
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="vehicles">
                          <Vehicles closeOverlay={handleCloseOverlay} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="jobs">
                          <Jobs closeOverlay={handleCloseOverlay} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="quotes">
                          <Quotes closeOverlay={handleCloseOverlay} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="partsHistory">
                          <PartsHistory closeOverlay={handleCloseOverlay} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="orders">
                          <Orders closeOverlay={handleCloseOverlay} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="returns">
                          <Returns closeOverlay={handleCloseOverlay} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="stockOrders">
                          <StockOrders closeOverlay={handleCloseOverlay} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </TabContainer>
              </div>
            </div>
          )}
        </Overlay>
      </div>
    </Fragment>
  );
};
export default MyGarage;
