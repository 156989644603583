import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";
import {
  getCheckoutItems
} from "./simplehomepage.thunks";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  formatAmount,
  getExtendedTotalPrice
} from "../../../helpers/utils";
import Checkout from "../../Checkout/index";

const QuickCart = ({redirectToCart}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkoutData = useSelector((state) => state.cart.checkoutDetails);
  const qtyInCart = useSelector((state) => state.cart.qtyInCart);
  const [checkoutItems, setCheckoutItems] = useState(checkoutData);
  const [cartTotal, setCartTotal] = useState([]);
  const parts = useSelector((state) => state.parts);
  const hideAllPrice =  parts  && parts.hidePrice;

  useEffect(() => {
    if (!_isEmpty(checkoutData)) {
      setCheckoutItems(checkoutData);
      const totalCartAmount = checkoutData.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.totalCostPrice;
      }, 0);
      setCartTotal(totalCartAmount);
    }
  }, [checkoutData]);

  // useEffect(() => {
  //   const payload = {};
  //   if (_isEmpty(checkoutItems)) {
  //     dispatch(getCheckoutItems(payload)).then((result) => {
  //       setCheckoutItems(result.payload.cartItems);
  //       const totalCartAmount = result.payload.cartItems.reduce(function (accumulator, currentValue) {
  //         return accumulator + currentValue.totalCostPrice;
  //       }, 0);
  //       setCartTotal(totalCartAmount);
  //     });
  //   }
  // }, []);

  return (
    <React.Fragment>
      {!_isEmpty(checkoutItems) && qtyInCart > 0 ? (
        <React.Fragment>
          <Checkout history={history} quickCart={true} />
          <TableContainer component={Paper}>
            <Table sx={{ maxWidth: 350 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>P/L</TableCell> */}
                  <TableCell align="right">Part#</TableCell>
                  <TableCell align="right">Desc</TableCell>
                  <TableCell align="right">Qty</TableCell>
                   {!hideAllPrice && (
                  <TableCell align="right">Price</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {checkoutItems.map((item) => (
                  <TableRow
                    key={item.partNum}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="right">{item.partNum}</TableCell>
                    <TableCell align="right">{item.desc}</TableCell>
                    <TableCell align="right">{item.qty}</TableCell>
                     {!hideAllPrice && (
                    <TableCell align="right">${formatAmount(item.totalCostPrice)}</TableCell>)}
                  </TableRow>
                ))}

                 {!hideAllPrice && (
                 <TableRow>
                  <TableCell colSpan={3}><span><strong>Total</strong></span></TableCell>
                  <TableCell align="right"><span><strong>${formatAmount(cartTotal)}</strong></span></TableCell>
                </TableRow>)}
              </TableBody>
            </Table>
          </TableContainer>
          <u className="text-color mt-4" onClick={(e)=>redirectToCart(true)}>See More Details...</u>
        </React.Fragment>
      ) :  (<React.Fragment><TableContainer component={Paper}><TableBody align="center"> <TableRow><TableCell align="center"><span className="text-black">There are no items in your cart.</span></TableCell></TableRow></TableBody></TableContainer></React.Fragment>)
      }
    </React.Fragment >
  );
};

QuickCart.propTypes = {};

export default QuickCart;
