import httpClient from "../../services/http";


const buildQueryUrl = (payload) => {
	const params = [];
	for (const k in payload) {
		if (payload.hasOwnProperty(k)) {
			const element = payload[k];
			params.push(encodeURIComponent(k) + '=' + encodeURIComponent(element));
		}
	}
	return params.join('&');
};

export const getQuoteUniversalService = (data) => {
  return httpClient.post(`/reman-order-service/quote/universal`, data);
};

export const getQuoteShippingService = (data) => {
  return httpClient.post(`/reman-order-service/shipping/cost`, data);
};
export const placeOrderService = (data) => {
  return httpClient.post(`/orchestrator-service/remanOrder/placeOrder`, data);
};

export const getPreviousLookupService = (data) => httpClient.get(`/reman-order-service/quote/quoteId/${data.billToId}?${buildQueryUrl(data.pagination)}`);

export const getcandkcategoriesService = (data) => httpClient.get(`/catalog/part/customizedVinPartTypes?${buildQueryUrl(data.query)}`);
