/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";
import { Row, Col, Form, Button, Accordion, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import _, { set } from "lodash";
import _uniqBy from "lodash/uniqBy";
import _isEmpty from "lodash/isEmpty";
import CoverToCoverPopup from "../../../components/Listing/CoverToCoverPopup";
import XSelect from "../XSelect";
import Placeholder from "../Placeholder";
import carIcon from "../../../static/images/car.png";
import _every from "lodash/every";
import _pick from "lodash/pick";
import {
  getBuyersGuideBrandData,
  getBuyersGuideData,
  getC2CUrl,
} from "../../../actions/parts";
import {
  isInputBlank,
  hasAlphaSpecialIntChar,
  hasMinLength,
} from "../../../helpers/utils";
import resetIcon from "../../../static/images/reset.png";
import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import "./styles.scss";

const BuyingGuideComponent = (props) => {
  const {
    selectedPartNo,
    selectedBrand,
    selectedGroup,
    selectedPartType,
    partDetails,
    c2cUrl,
  } = props;

  const initializeValidity = () => {
    const validity = {
      isPartsNoMinLimit: true,
      isPartsNoAlphaSpecial: true,
      isPartsNoValid: true,
      isPartsNoNotNull: false,
    };
    return validity;
  };

  const dispatch = useDispatch();
  const parts = useSelector((state) => state.parts);
  const searchInputRef = useRef(null);

  const [partNumber, setPartNumber] = useState(selectedPartNo);
  const [partTypes, setPartTypes] = useState([]);
  const [makes, setMakes] = useState([]);
  const [appCount, setAppCount] = useState(0);
  const [showCoverToCover, setShowCoverToCover] = useState(false);
  const [showBrandSelect, setShowBrandSelect] = useState(false);
  const [showGroupSelect, setShowGroupSelect] = useState(false);
  const [showPartTypeSelect, setShowPartTypeSelect] = useState(false);
  const [showC2CUrl, setShowC2CUrl] = useState(c2cUrl);
  const [showC2C, setShowC2C] = useState(false);

  const [validity, setvalidity] = useState(initializeValidity);
  const [brandValue, setBrandValue] = useState(selectedBrand);
  const [groupValue, setGroupValue] = useState(selectedGroup);
  const [partTypeValue, setPartTypeValue] = useState(selectedPartType);
  const [enableValidation, setEnableValidation] = useState(false);

  const brands = parts.buyersGuideBrandDetails || [];
  const groups = parts.buyersGuideDetails || {};

    const handlePartSearch = () => {
    let validity = initializeValidity();

    validity.isPartsNoNotNull = isInputBlank(partNumber);

    setvalidity(validity);
    
    getBuyersGuideBrandData(partNumber, dispatch);
    getBuyersGuideData(partNumber, dispatch);
    setShowGroupSelect(false);
    setShowPartTypeSelect(false);
    setMakes([]);
    setShowC2C(false);
    setBrandValue(null);
    setEnableValidation(false);

    
  };

  const handlePartNoChange = (event) => {
    const partsNo = event.target.value;
    if(_isEmpty(partsNo)) {
      setEnableValidation(false);
    }
   
    
    let validity = initializeValidity();
    validity.isPartsNoAlphaSpecial = hasAlphaSpecialIntChar(partsNo);
    validity.isPartsNoMinLimit = hasMinLength(partsNo, 1);
    validity.isPartsNoValid = _every(
      _pick(validity, ["isPartsNoAlphaSpecial", "isPartsNoMinLimit"])
    );
    setvalidity(validity);
    setPartNumber(event.target.value);
  };

  const handlePartNoBlur = () => {
    let validity = initializeValidity();
    setvalidity(validity);
  };

  const getSelectOptions = (data, prop) => {
    const obj = data.map((d) => ({ label: d[prop], value: d[prop] }));

    if (prop === "manufacturer") {
      let listData = _uniqBy(data, "manufacturer");
      return _.sortBy(
        listData.map((d) => ({ label: d[prop], value: d[prop] })),
        (o) => o.label
      );
    }
    return _.sortBy(obj, (o) => o.label);
  };

  const handleGroup = (group) => {
    const { label } = group;
    const _partTypes = _.head(_.filter(groups.partGroup, { name: label }));
    if (_partTypes) {
      setPartTypes(_partTypes.partDescriptions);
    }

    if (groupValue || group) {
      setShowPartTypeSelect(true);
    }

    setGroupValue(label);
  };

  const handlePartType = (partType) => {
    const { label } = partType;
    const _makes = _.head(_.filter(partTypes, { name: label }));
    if (_makes) {
      const sortedMakes = _.sortBy(_makes.makes, "makeName");
      setMakes(sortedMakes);
      setAppCount(_makes.application);
      setShowC2C(true);
    }

    setPartTypeValue(label);
  };

  const handleBrandChange = (brand) => {
    const { label } = brand;
    console.log("brand",brands)
   const _brand = brands.find(brand => brand.manufacturer === label);

     getC2CUrlAsync(partNumber,  _brand.orderNumber);
    getBuyersGuideData(partNumber, dispatch, label);
    setShowGroupSelect(true);
    setShowPartTypeSelect(false);
    setMakes([]);

    setBrandValue(label);
    // setPartTypeValue(undefined);
    setGroupValue(undefined);
  };

  const handleAccordionClick = () => {};

  const handleCoverToCover = () => {
    setShowCoverToCover(true);
    
  };

  const handleReset = () => {
    setPartNumber("");
    setShowBrandSelect(false);
    setShowGroupSelect(false);
    setShowPartTypeSelect(false);
    setMakes([]);
    setAppCount(0);
    setShowC2CUrl(null);
    setShowC2C(false);
    setBrandValue(null);
    setPartTypes([]);
    setGroupValue(null);
    setPartTypeValue(null);
    setEnableValidation(false);
   
    

    setTimeout(() => {
      searchInputRef.current.focus();
    }, 100);
  };
  const handleKeyDown = (event) => {
    const partNumber = event.target.value;
    if (
      event.key === " " ||
      (partNumber &&
        partNumber.length >= 20 &&
        event.keyCode !== 8 &&
        event.keyCode !== 9 &&
        event.keyCode !== 37 &&
        event.keyCode !== 38 &&
        event.keyCode !== 39 &&
        event.keyCode !== 40 &&
        event.keyCode !== 46 &&
        event.keyCode !== 17 &&
        event.keyCode !== 65)
    ) {
      event.preventDefault();
    }
  };


const getC2CUrlAsync = (partNumber,orderNumber) => {
  getC2CUrl(partNumber, orderNumber, dispatch).then(
    (__response) => {
      if (__response) {
        const __c2cUrl = __response.data.slice(5);
        setShowC2CUrl(__c2cUrl);
      
      }
    }
  );
}

useEffect(() => {

 if (_isEmpty(parts.buyersGuideBrandDetails)) {
   setEnableValidation(true);
  } 
  else {
    setEnableValidation(false);
  }
}, [parts.buyersApiCall]);

useEffect(() => {
  setEnableValidation(false);
}, []);


  useEffect(() => {
    if (parts.buyersGuideBrandDetails) {
     
      const __buyersGuide = parts.buyersGuideBrandDetails[0];

     getC2CUrlAsync(partNumber,  __buyersGuide.orderNumber);
     
    }
  
  }, [parts.buyersGuideBrandDetails]);

  useEffect(() => {
    if (brands && brands.length > 0) {
      setBrandValue(brands[0].manufacturer);

      const __brand = {
        label: brands[0].manufacturer,
        value: brands[0].manufacturer,
      };
      handleBrandChange(__brand);
    }
  }, [brands]);

  useEffect(() => {
    if (groups.partGroup && groups.partGroup.length > 0) {
      setGroupValue(groups.partGroup[0].name);
      const __group = {
        label: groups.partGroup[0].name,
        value: groups.partGroup[0].name,
      };
      handleGroup(__group);
    }
  }, [groups]);

  useEffect(() => {
    if (partTypes && partTypes.length > 0) {
      setPartTypeValue(partTypes[0].name);
      const __partType = { label: partTypes[0].name, value: partTypes[0].name };
      handlePartType(__partType);
    }
  }, [partTypes]);

  useEffect(() => {
    if (c2cUrl && parts.buyersGuideBrandDetails) {
      setShowC2CUrl(c2cUrl);
    }
  }, [c2cUrl]);

  useEffect(() => {
    if (selectedGroup) {
      setGroupValue(selectedGroup);
      const __group = { label: selectedGroup, value: selectedGroup };
      handleGroup(__group);
      setTimeout(() => {
        setShowGroupSelect(true);
      }, 500);
    }

    if (selectedPartType) {
      const __partType = { label: selectedPartType, value: selectedPartType };
      handlePartType(__partType);
      setTimeout(() => {
        setShowPartTypeSelect(true);
      }, 500);
    }

    if (selectedBrand) {
      setShowBrandSelect(true);
    }
  }, []);

  useEffect(() => {
    if (
      parts.buyersGuideBrandDetails &&
      !_.isEmpty(searchInputRef.current.value)
    ) {  
      setShowBrandSelect(true);
    } else {
      setShowBrandSelect(false);
      setShowGroupSelect(false);
      setShowPartTypeSelect(false);
      setShowC2C(false);
      setMakes([]);
     }
  }, [parts.buyersGuideBrandDetails]);

  return (
    <React.Fragment>
      <div className="buying__guide__component">
        <div className="caption">
          Enter a part number for which you would like to see vehicles.
        </div>
        <Row>
        <Col lg={3} md={4}>
            <div className="align">
              <img className="car__icon" src={carIcon} alt="Car" />
              <Form.Label>Part #</Form.Label>
              <div className="part__search">
                <Form.Control
                  className="part__search_input"
                  size="sm"
                  type="text"
                  maxLength="20"
                  onChange={handlePartNoChange}
                  onBlur={handlePartNoBlur}
                  placeholder="Enter Part #"
                  value={partNumber}
                  autoFocus
                  ref={searchInputRef}
                  onKeyDown={handleKeyDown}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handlePartSearch();
                    }
                  }}
                />
                <Button
                  onClick={handlePartSearch}
                  className="search__button"
                  //  disabled={ !partNumber ||_every(validity)}
                >
                  Search
                </Button>
              </div>
              
              {(enableValidation)
                && (
              <FormValidationErrorMessage
                condition={enableValidation && partNumber && parts.buyersApiCall===false}
                errorMessage={"Part number not found"}
              />
              )}

              <FormValidationErrorMessage
                condition={validity.isPartsNoNotNull}
                errorMessage={"Please enter Part Number"}
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isPartsNoAlphaSpecial && validity.isPartsNoMinLimit
                }
                errorMessage={
                  "Parts number should include alphanumeric or special characters"
                }
              />
              <FormValidationErrorMessage
                condition={!validity.isPartsNoMinLimit}
                errorMessage={"Please enter Part Number"}
              />
            </div>
          </Col>
          <Col>
            {brands.length > 0 && showBrandSelect && (
              <React.Fragment>
                <Form.Label>Brand:</Form.Label>
                <XSelect
                  options={getSelectOptions(brands, "manufacturer")}
                  onChange={handleBrandChange}
                  defaultValue={
                    brandValue ? { label: brandValue, value: brandValue } : null
                  }
                  value={
                    brandValue ? { label: brandValue, value: brandValue } : null
                  }
                  placeholder={"Select Brand"}
                />
              </React.Fragment>
            )}
          </Col>
          <Col>
            {groups.partGroup && showGroupSelect && (
              <React.Fragment>
                <Form.Label>Group:</Form.Label>
                <XSelect
                  options={getSelectOptions(groups.partGroup || [], "name")}
                  onChange={handleGroup}
                  placeholder={"Select Group"}
                  defaultValue={
                    groupValue
                      ? {
                          label: groupValue,
                          value: groupValue,
                        }
                      : null
                  }
                  value={
                    groupValue
                      ? {
                          label: groupValue,
                          value: groupValue,
                        }
                      : null
                  }
                />
              </React.Fragment>
            )}
          </Col>
          <Col>
            {partTypes.length > 0 && showGroupSelect && showPartTypeSelect && (
              <React.Fragment>
                <Form.Label>Part Type:</Form.Label>
                <XSelect
                  options={getSelectOptions(partTypes, "name")}
                  onChange={handlePartType}
                  placeholder={"Select Part Type"}
                  defaultValue={
                    partTypeValue
                      ? {
                          label: partTypeValue,
                          value: partTypeValue,
                        }
                      : null
                  }
                  value={
                    partTypeValue
                      ? {
                          label: partTypeValue,
                          value: partTypeValue,
                        }
                      : null
                  }
                />
              </React.Fragment>
            )}
          </Col>
        </Row>
        <div className="smartPage_totalNo_resetBtn_Container">
          {showC2C && showGroupSelect && showPartTypeSelect && (
            <React.Fragment>
              <div>
                <span onClick={handleCoverToCover}>Cover to Cover</span>
               
              </div>
              <div>
                <p>
                  Total Number of Applications:{" "}
                  <span
                    className="appNo"
                    style={{ color: "#000", fontSize: "1rem" }}
                  >
                    {appCount}
                  </span>
                </p>
              </div>
            </React.Fragment>
          )}
          <Button onClick={handleReset}>
            Reset <img src={resetIcon} alt="Reset" />
          </Button>
        </div>
        <div className="accordion_container">
          <Accordion className="main_accordion">
            {showGroupSelect && showPartTypeSelect && (
              <React.Fragment>
                {makes ? (
                  makes.map((make, index) => {
                    return (
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          className="accordion_header"
                          eventKey={index + 1}
                          onClick={handleAccordionClick}
                        >
                          <span>{make.makeName}</span>
                          <span>{make.application}</span>
                          <span>
                            {make.fromYear}-{make.toYear}
                          </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index + 1}>
                          <Card.Body className="accordion_body">
                            <Accordion className="child_accordion">
                              {_.sortBy(make.models, "modelName").map(
                                (model, index) => {
                                  return (
                                    <Card>
                                      <Accordion.Toggle
                                        as={Card.Header}
                                        class="accordion_header"
                                        eventKey={index + 1}
                                      >
                                        <span>{model.modelName}</span>
                                        <span>{model.application}</span>
                                        <span>
                                          {model.fromYear}-{model.toYear}
                                        </span>
                                      </Accordion.Toggle>
                                      <Accordion.Collapse eventKey={index + 1}>
                                        <Card.Body className="accordion_body">
                                          <ul>
                                            {model.engines.map((engine) => {
                                              return (
                                                <li>
                                                  <span>
                                                    {engine.engineName}{" "}
                                                    {engine.fromYear}-
                                                    {engine.toYear}
                                                  </span>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </Card.Body>
                                      </Accordion.Collapse>
                                    </Card>
                                  );
                                }
                              )}
                            </Accordion>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    );
                  })
                ) : (
                  <span>No Data Found</span>
                )}
              </React.Fragment>
            )}
          </Accordion>
        </div>
      </div>
      {showCoverToCover && (
        <CoverToCoverPopup
          openPopup={showCoverToCover}
          url={showC2CUrl || c2cUrl}
          closePopup={(e) => setShowCoverToCover(false)}
        />
      )}
    </React.Fragment>
  );
};
BuyingGuideComponent.propTypes = {
  selectedPartNo: T.string,
  selectedBrand: T.string,
  selectedGroup: T.string,
  selectedPartType: T.string,
 
};

export default BuyingGuideComponent;
