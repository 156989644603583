import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import _ from "lodash";
import { getPartsByMultiGroupOrchestrator } from "../../../actions/parts";
import {
  getDefaultDataBasedOnUSerPref,
  getPreferenceDataFromStorage,
} from "../../../helpers/utils";
import MultipleSelectCheckbox from "../../Listing/customMultiCheckboxSelect";

const PartTypes = (props) => {
  const dispatch = useDispatch();
  let partTypes = useSelector((state) => state.parts.partTypes);
  let catalogFilter = useSelector((state) => state.parts.catalogFilter);
  let catalogRequestGroups = useSelector(
    (state) => state.parts.catalogRequestGroups
  );
  const userDetails = useSelector((state) => state.user.userDetails);
  const parts = useSelector((state) => state.parts);
  const selectedPartType = parts.selectedPartType;
  let partsApiCall = parts.partsApiCall;
  const mygarage = useSelector((state) => state.mygarage);

  const getFilterTitle = () => {
    let defaultTitle = "ALL";
    if (partTypes && selectedPartType) {
      const isFound = _.filter(partTypes, (pt) => {
        return pt.text === selectedPartType.partTypeDesc;
      });

      if (isFound && isFound.length > 0) {
        defaultTitle = selectedPartType.partTypeDesc;
      }
    }
    return defaultTitle;
  };

  const getMultiSelectArray = () => {
    let multiSelectArray = [];
    if (partTypes && selectedPartType) {
      const isFound = _.filter(partTypes, (pt) => {
        return pt.text === selectedPartType.partTypeDesc;
      });
      if (
        isFound &&
        isFound.length == 0 &&
        selectedPartType.partTypeId === "Multiple Items"
      ) {
        multiSelectArray = selectedPartType.partTypeDesc;
      }
    }
    return multiSelectArray;
  };

  const handlePartTypeFilter = (options, selectedOptions) => {
    let currentFilter = "";
    let key = 0;
    options = options.filter((o) => o.value !== "apply");
    let allOptions = options.filter((o) => o.value === "*");
    if (allOptions.length > 0) {
      currentFilter = "ALL";
      key = "ALL";
    } else if (options.length > 1) {
      currentFilter = options;
      key = "Multiple Items";
    } else {
      currentFilter = options[0].label;
      key = options[0].value;
    }
    const partTypeData = {
      partTypeId: key,
      partTypeDesc: currentFilter,
    };
    dispatch({
      payload: {
        selectedPartType: partTypeData,
      },
      type: "SELECTED_PART_TYPE",
    });
    let catalogRequestGroupClone = _cloneDeep(catalogRequestGroups);
    let catalogFilterClone = _cloneDeep(catalogFilter);
    let partTypes = [];
    options.map((o) => {
      partTypes.push({ id: o.value });
    });
    catalogFilterClone = {
      ...catalogFilterClone,
      partTypes: partTypes,
    };
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
    dispatch(
      getPartsByMultiGroupOrchestrator(
        props.selectedVehicle.year, // TODO
        props.selectedVehicle.makeId,
        props.selectedVehicle.modelId,
        props.selectedVehicle.engineId,
        catalogRequestGroupClone,
        1,
        100,
        userPrefDetail.catalogSortBy,
        userPrefDetail.catalogOrder,
        catalogFilterClone,
        true,
        userDetails.billTo.mclSupplier,
        parts.selectedGroupDetails?parts.selectedGroupDetails: parts.selectedPartType,
        true,
        mygarage.selectedVehicle


      )
    );
  };

  return (
    <Fragment>
      {!partsApiCall ? (
        <MultipleSelectCheckbox
          handlePartTypeFilter={handlePartTypeFilter}
          multiSelectArray={getMultiSelectArray}
          currentFilter={
            catalogFilter &&
            catalogFilter.partTypes &&
            catalogFilter.partTypes.length == 0
              ? "ALL"
              : getFilterTitle()
          }
          partTypes={partTypes}
        />
      ) : null}
    </Fragment>
  );
};

PartTypes.propTypes = {};

export default PartTypes;
