import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import _remove from "lodash/remove";
import _isEmpty from "lodash/isEmpty";
import { addItemToCart, addItemToCart2 } from "../../../actions/cart";
import { setPartHistory } from "../../../components/shared/MyGarage/mygarage.thunks";
import { Button, Form,  OverlayTrigger, Tooltip } from "react-bootstrap";
import _find from "lodash/find";
import _uniqBy from "lodash/uniqBy";
import _map from "lodash/map";
import _includes from "lodash/includes";
import XModal from "../../../components/shared/XModal";
import { clearCartForStore, saveQuote } from "../../../actions/cart";

import { timeInUTC, noSpecialCharsRegExp, hasQuoteAlphaSpecialIntChar, isMinLength, isMaxLengthLessthan } from "../../../helpers/utils";
import _every from 'lodash/every';
import FormValidationErrorMessage from '../../../components/shared/FormValidationErrorMessage/FormValidationErrorMessage';
import { triggerCustomUxCriticalError } from '../../../actions/error';

const actionTypes = {
	CART_CHECKOUT_FETCHED: 'CART_CHECKOUT_FETCHED'
};


export const PowerTrainWarningPopup = (props) => {
  const dispatch = useDispatch();
  let { openPopup, closePopup } = props;
  const user = useSelector((state) => state.user);
  const [showQuoteMsg, setshowQuoteMsg] = useState(false);
  const [showWarningModal, setshowWarningModal] = useState(true);
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [quoteNameValidity, setQuoteNameValidity] = useState({
   isQuoteAlphaSpecialChar: true,
    isQuoteNameMinLimit: true,
    isQuoteNameMaxLimit: true,
  })
  
  const [quoteName, setQuoteName] = useState(null)
  const quoteStyle = {
    display: "flex",
    alignItems: "center"
  }
 

  const handleCloseModal = () => {
    setshowWarningModal(false);
    setshowQuoteMsg(false);
	setShowQuoteModal(false);
	closePopup(false);
  };


  const handleSaveAsQuote = () => {
		const {
      userDetails: {
          x2userName,
          mobileNumber,
      }
  } = user;
		
		let quoteData = validateQuoteName(quoteName, quoteNameValidity);
		if (_every(quoteData)) {
			saveQuote(x2userName, quoteName, mobileNumber, dispatch).then(savedQuote => {
			   if(!_isEmpty(savedQuote) &&  savedQuote.data){
				const quoteSaved = {
					heading: `Quote: ${quoteName} is saved`,
					message: "",
					type: "success",
				};
				dispatch({
					payload: {
					  checkoutdetails: [],
					},
					type: actionTypes.CART_CHECKOUT_FETCHED,
				  });
				handleCloseModal();
				//addToCart();
				triggerCustomUxCriticalError(quoteSaved, dispatch);
			   } else{
				   setShowQuoteModal(false);
			   }
			});
		}
		else {
			setQuoteNameValidity(quoteData);
		}
	};

  const handleQuoteNameChange = (e) => {
	//	let quoteNameValidity = this.state;
		const { value } = e.target;
		const quoteValue = value.charAt(0);
		let quoteString = value;
		if (quoteValue === ' ') {
			quoteString = value.trim();
		};
		let quoteName = validateQuoteName(quoteString, quoteNameValidity);
    setQuoteName(quoteString.toUpperCase())
    setQuoteNameValidity(quoteName);
	};

  const handleQuoteNameBlur = () => {
    let quoteNameValidData = quoteNameValidity
		setQuoteNameValidity(quoteNameValidData)
	}

  const validateQuoteName = (name, quoteNameValidity) => {
		quoteNameValidity.isQuoteAlphaSpecialChar = hasQuoteAlphaSpecialIntChar(name);
		quoteNameValidity.isQuoteNameMinLimit = isMinLength(name, 1);
		quoteNameValidity.isQuoteNameMaxLimit = isMaxLengthLessthan(name, 50);
		return quoteNameValidity;
	};

  const handleKeyDown = (event, name) => {
		switch (name) {
			case 'quotename':
				if (((quoteName && quoteName.indexOf() === 0) || quoteName === null || quoteName === "") && event.key === ' ') {
					event.preventDefault();
				}
				if ((quoteName && quoteName.length >= 50 && this.handleOtherKeys(event.keyCode))) {
					event.preventDefault();
				}
				break;
			default:
				break;
		}
	}

	const handleClearCart = (isClear) => {
	  clearCartForStore(dispatch).then(res => {
		handleCloseModal();	
	  });
  }    
    //} 
 

  const getMessage = () => {
	
		return  (
			<span className="modal_text">
             Your Cart already contains Regular items, To continue to add parts you need to clear the Cart or save the Cart as Quote
			</span>
			)
  }
  return (
    <div>
      {openPopup && (
        <XModal
          show={openPopup}
          showButtons={false}
          title="Are you sure ?"
          className="store_waring_popup_modal powertrain-warning-modal"
          handleClose={handleCloseModal}
        ><div className="store_waring_popup_modal_heading">
           {getMessage()}
		  </div>
          <div className="store_waring_popup_modal_button_padding">
            <span className="clear_cart_button">
              <Button className="add_to_cart_button" onClick={() => handleClearCart(true)}>
                Delete the cart
              </Button>
            </span>
            <span className="save_as_quote_button">
              <Button
                className="add_to_cart_button"
                onClick={() => setShowQuoteModal(true)}
              >
                Save the cart as Quote
              </Button>
            </span>
          </div>
        </XModal>
      )}
      {showQuoteModal && (
					<XModal
						title="Quote Name"
						show={showQuoteModal}
						className="store-custom_xmodal quote-modal"
						handleClose={() => {
							setShowQuoteModal(false)
						}}
						handleAction={handleSaveAsQuote}
						yesButtonText="Save"
						noButtonText="Cancel"
						isValidValidity={!_isEmpty(quoteName) ? true : false}
					>
						<div className="my-1 col-sm-12" style={quoteStyle}>
							<Form.Control
								placeholder={!_isEmpty(quoteName) ? `${quoteName}` : "Quote Name"}
								className="input__default"
								name="quotename"
								maxLength="51"
								value={quoteName}
								onChange={handleQuoteNameChange}
								onBlur={handleQuoteNameBlur}
								onKeyDown={(event) => {handleKeyDown(event, 'quotename') }}
							/>
							<OverlayTrigger
								overlay={
									<Tooltip id={`tooltip`}>
										Enter Customer Name or Phone Number or
										Both or Any Text to identify this quote.
									</Tooltip>
								}
							>
								<span class="material-icons">info</span>
							</OverlayTrigger>
						</div>
						<FormValidationErrorMessage
							condition={
								!quoteNameValidity.isQuoteAlphaSpecialChar && quoteNameValidity.isQuoteNameMinLimit
							}
							errorMessage={
								'Quote name must contain alphanumeric or special characters only'
							}
						/>
						<FormValidationErrorMessage
							condition={!quoteNameValidity.isQuoteNameMinLimit && quoteNameValidity.isQuoteAlphaSpecialChar}
							errorMessage={
								'Quote name should have atleast 1 character'
							}
						/>
						<FormValidationErrorMessage
							condition={
								!quoteNameValidity.isQuoteNameMaxLimit &&
								quoteNameValidity.isQuoteNameMinLimit
							}
							errorMessage={
								'Quote name should not exceed 50 characters'
							}
						/>
					</XModal>
				)}
    </div>
  );
};
