import { createSlice, current } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  setFavoriteVehicle,
  setVehicles,
  fetchVehicles,
  fetchQuotes,
  fetchRecommendedJobs,
  searchVehicles,
  setPartHistory,
  fetchPartsHistory,
  searchPartsHistory,
  deletePartHistory,
  fetchOrders,
  addJob,
  fetchJobs,
  searchJobs,
  deleteJob,
  viewQuote,
  getStatementsUrl,
  getStatementsChangedUrl,
  getAllPartDescription,
  getAllChemicalDescription,
  getAllReports,
  getAllStores,
  getBalance,
  getPayments,
  getCustomerPaymentsByDate,
  verifyAppVersion,
  getAllStoreNumber,
  fetchReturn,
  fetchPartTypes,
  getCreditUrl
} from "./mygarage.thunks";

let initialState = {
  loading: false,
  ordersLoading: false,
  ordersError: null,
  error: false,
  vehicles: null,
  returnList:[],
  quotes: [],
  recommendedJobs:[],
  partsHistories: [],
  homepagePartTypes:[],
  jobs: [],
  orders: [],
  storeList:[],
  currentRequestId: null,
  selectedVehicle: null,
  selectedPart: null,
  selectedJob: null,
  selectedQuote: null,
  msg: null,
  totalPages: null,
  totalItems: null,
  partsTotalPages: null,
  partsTotalItems: null,
  quoteError: null,
  statementsError: null,
  getCreditUrlError:null,
  JobEventStatus: null,
  appVerCheckError: null,
  storeOptions: [],
  storeNumberOptions:[],
  allStoresRegion: {
    defaultStore:  null,
		pickupStores :  [],
		deliveryStores : []}
};

const myGarageSlice = createSlice({
  name: "mygarage",
  initialState,
  reducers: {
    setVehicleSelected(state, action) {
      state.selectedVehicle = action.payload;
    },
    setPartSelected(state, action) {
      state.selectedPart = action.payload;
    },
    setJobSelected(state, action) {
      state.selectedJob = action.payload;
    },
    setQuoteSelected(state, action) {
      state.selectedQuote = action.payload;
    },
    clearQuoteError(state) {
      state.quoteError = null;
    },
    setJobEventStatus(state, action) {
      state.JobEventStatus = action.payload.status;
    },
  },
  extraReducers: {
    [setVehicles.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [setVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.msg = action.payload;
    },
    [setVehicles.rejected]: (state, action) => {
      state.loading = false;
    },

    [fetchVehicles.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.vehicles = action.payload.pagedItems;
      state.totalPages = action.payload.totalPages;
      state.totalItems = action.payload.totalItems;
    },
    [fetchVehicles.rejected]: (state, action) => {
      state.loading = false;
    },
      // View Credits
      [ getCreditUrl.pending]: (state, action) => {
        state.loading = false;
      },
      [ getCreditUrl.fulfilled]: (state, action) => {
        state.getCreditUrlError = null;
        state.loading = false;
      },
      [ getCreditUrl.rejected]: (state, action) => {
        state.statementsError = action.payload;
        state.loading = false;
      },

    // Search Vehicles
    [searchVehicles.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [searchVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.vehicles = action.payload;
    },
    [searchVehicles.rejected]: (state, action) => {
      state.loading = false;
    },

    // favorite Vehicles
    [setFavoriteVehicle.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = false;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [setFavoriteVehicle.fulfilled]: (state, action) => {
      const {
        payload,
        meta: { arg },
      } = action;
      if (payload.statusCode.toLowerCase() === "ok") {
        const __currentState = current(state);
        if (
          __currentState.selectedVehicle &&
          __currentState.selectedVehicle.searchId === arg.searchId
        ) {
          state.selectedVehicle = arg;
        }
      }
      state.loading = false;
    },
    [setFavoriteVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Quotes
    [fetchQuotes.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchQuotes.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.quotes.push(action.payload);
        state.currentRequestId = null;
      }
    },
    [fetchQuotes.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = null;
      }
    },
    [fetchReturn.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchReturn.fulfilled]: (state, action) => {
      state.loading = false;
      state.returnList = action.payload;
    },

    [fetchReturn.rejected]: (state, action) => {
      state.loading = false;
    },
    // Parts History
    [setPartHistory.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [setPartHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.msg = action.payload;
    },
    [setPartHistory.rejected]: (state, action) => {
      state.loading = false;
    },

    [fetchPartsHistory.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPartsHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.partsHistories = action.payload.pagedItems;
      state.partsTotalPages = action.payload.totalPages;
      state.partsTotalItems = action.payload.totalItems;
    },
    [fetchPartsHistory.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = null;
      }
    },

    [deletePartHistory.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePartHistory.fulfilled]: (state, action) => {
      state.loading = false;
      // state.partsHistories = action.payload;
    },
    [deletePartHistory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [fetchRecommendedJobs.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchRecommendedJobs.fulfilled]: (state, action) => {
      state.loading = false;
      state.recommendedJobs = action.payload;
    },
    [fetchRecommendedJobs.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = null;
      }
    },
    // storesNumbr
    [getAllStoreNumber.pending]: (state, action) => {
      if (!state.loading) {
       state.loading = true;
      }
      },
     [getAllStoreNumber.fulfilled]: (state, action) => {
        state.loading = false;
          state.storeList = action.payload;
          let options = []
          let array =[];
          if(!_.isEmpty(action.payload) && action.payload.length > 0){
            array.push({
            value:"*",
            label:"ALL"
          })}
          action.payload?.map((store) => {
            let arr = store.storeName?.split('-')
            const eachOption = {
                storeNum: store.storeNum,
                storeName: arr[1],
            };
            array.push({
              value: store.fullStoreNumber,
              label: store.fullStoreNumber + " - " + store.storeName
            })
            options.push(eachOption);
        });
      state.storeNumberOptions = array;
      state.storeOptions = options;
      },
     [getAllStoreNumber.rejected]: (state, action) => {
       state.loading = false;
      },
    // Search Parts History
    [searchPartsHistory.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [searchPartsHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.partsHistories = action.payload;
    },
    [searchPartsHistory.rejected]: (state, action) => {
      state.loading = false;
    },

    // Jobs
    [addJob.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [addJob.fulfilled]: (state, action) => {
      state.loading = false;
      state.msg = action.payload;
    },
    [addJob.rejected]: (state, action) => {
      state.loading = false;
    },

    [fetchJobs.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchJobs.fulfilled]: (state, action) => {
      state.loading = false;
      state.jobs = action.payload;
    },
    [fetchJobs.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = null;
      }
    },

    [deleteJob.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteJob.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteJob.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllPartDescription.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllPartDescription.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getAllPartDescription.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllChemicalDescription.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllChemicalDescription.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getAllChemicalDescription.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // Search Jobs
    [searchJobs.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [searchJobs.fulfilled]: (state, action) => {
      state.loading = false;
      state.jobs = action.payload;
    },
    [searchJobs.rejected]: (state, action) => {
      state.loading = false;
    },

    // Fetch Orders
    [fetchOrders.pending]: (state, action) => {
      if (!state.ordersLoading) {
        state.ordersLoading = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [fetchOrders.fulfilled]: (state, action) => {
      state.ordersLoading = false;
      if (action.payload.data) {
        action.payload.data.forEach((order) => {
          if (order.orderStatus === "PROCESSED") {
            order.sort = -2;
          } else if (order.orderStatus === "RECEIVED") {
            order.sort = -1;
          } else {
            order.sort = 0;
          }
        });

        _.sortBy(action.payload.data, (p) => p.sort);
        state.orders = action.payload.data;
      }

      state.ordersError = null;
    },
    [fetchOrders.rejected]: (state, action) => {
      state.ordersLoading = false;
      const { payload } = action;

      state.ordersError = { message: payload.errorMessage };
    },

    // View Quote
    [viewQuote.pending]: (state, action) => {
      state.loading = false;
    },
    [viewQuote.fulfilled]: (state, action) => {
      state.quoteError = null;
      state.loading = false;
    },
    [viewQuote.rejected]: (state, action) => {
      state.quoteError = action.payload;
      state.loading = false;
    },

    // View Statements
    [getStatementsUrl.pending]: (state, action) => {
      state.loading = false;
    },
    [getStatementsUrl.fulfilled]: (state, action) => {
      state.statementsError = null;
      state.loading = false;
    },
    [getStatementsUrl.rejected]: (state, action) => {
      state.statementsError = action.payload;
      state.loading = false;
    },
    [getStatementsChangedUrl.pending]: (state, action) => {
      state.loading = false;
    },
    [getStatementsChangedUrl.fulfilled]: (state, action) => {
      state.statementsChangedError = null;
      state.loading = false;
    },
    [getStatementsChangedUrl.rejected]: (state, action) => {
      state.statementsChangedError = action.payload;
      state.loading = false;
    },

    // Verify App Version
    [verifyAppVersion.pending]: (state, action) => {
      state.loading = false;
    },
    [verifyAppVersion.fulfilled]: (state, action) => {
      state.appVerCheckError = null;
      state.loading = false;
    },
    [verifyAppVersion.rejected]: (state, action) => {
      state.appVerCheckError = action.payload;
      state.loading = false;
    },

    //Reports

    [getAllReports.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
      }
    },
    [getAllReports.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getAllReports.rejected]: (state, action) => {
      state.loading = false;
    },
    // stores
    [getAllStores.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
      }
    },
    [getAllStores.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeDetails = action.payload;
    },
    [getAllStores.rejected]: (state, action) => {
      state.loading = false;
    },
    [getBalance.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
      }
    },
    [getBalance.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getBalance.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchPartTypes.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPartTypes.fulfilled]: (state, action) => {
     state.loading = false;
     state.homepagePartTypes  = action.payload;
    },
    [fetchPartTypes.rejected]: (state, action) => {
     state.loading = false;
    },
    [getPayments.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
      }
    },
    [getPayments.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getPayments.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

const { actions, reducer } = myGarageSlice;

export const {
  getMyGarageVehicles,
  setVehicleSelected,
  setPartSelected,
  setJobSelected,
  setQuoteSelected,
  clearQuoteError,
  setJobEventStatus,
} = actions;

export default reducer;
