import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import CarouselChildElement from './CarouselChildElement';
import _isEmpty from "lodash/isEmpty";
import {
	getImageURLCookieDetails
} from '../../../helpers/utils';

function SingleProPack(props) {
	let {
        proPack,
		handleClick,
		index,
		selectedBundle,
    } = props;
	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
	const imageURLCookie = getImageURLCookieDetails();
	const CustomLeftArrow = ({ onClick, ...rest }) => {
		const {
			onMove,
			carouselState: { currentSlide, deviceType },
		} = rest;
		return <a onClick={() => onClick()} className="arrow-left"></a>;
	};
	const CustomRightArrow = ({ onClick, ...rest }) => {
		const {
			onMove,
			carouselState: { currentSlide, deviceType },
		} = rest;
		return <a onClick={() => onClick()} className="arrow-right"></a>;
	};
	const renderCarouselelements = () => {
        let carouselElements = [];
		if ( !_isEmpty(proPack.jobs)) {
			proPack.jobs.forEach((singleItem, key) => {
				carouselElements.push(
					<CarouselChildElement
						bundle={singleItem}
						handleClick={handleClick}
                        selectedBundle={selectedBundle}
						key={key}
					/>
				);
			});
		}
		return carouselElements;
	};
	return (
		<Row className="py-4 product-row">
			<Col sm={3} className="product-category p-0">
				{index === 0 && (
					<h3 className="text-uppercase propacks_title mb-4">Pro Packs</h3>
				)}
				<div className="product-category__wrapper">
					<div className="product-category__wrapper--inner">
						<div className="product-category__img">

                            <img
                                src={proPack.image ? `${proPack.image}?${imageURLCookie}` : '/noImage.jpg'}
                                alt="Pro-Pack-Name"
                            ></img>

						</div>
						<div className="product-category__title">
							{proPack.name}
						</div>
					</div>
				</div>
			</Col>
			<Col sm={9}>
				{index === 0 && (
					<h3 className="text-center text-uppercase mb-4">
						Bundles
					</h3>
				)}
				{!_isEmpty(proPack.jobs) && (
					<Carousel
						swipeable={false}
						draggable={false}
						showDots={false}
						responsive={responsive}
						infinite={true}
						autoPlaySpeed={1000}
						keyBoardControl={true}
						customLeftArrow={<CustomLeftArrow />}
						customRightArrow={<CustomRightArrow />}
						customTransition="all .5"
						shouldResetAutoplay={false}
						transitionDuration={500}
						containerClass="carousel-container"
						dotListClass="custom-dot-list-style"
						itemClass="carousel-item-padding-40-px"
					>
						{renderCarouselelements()}
					</Carousel>
				)}
			</Col>
		</Row>
	);
}
export default SingleProPack;
