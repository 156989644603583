import React, { useState, Fragment, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Modal,
    Card,
  } from "react-bootstrap";
  import closeBlackThin from "../../static/images/closeBlackThin.png";
import { useDispatch,useSelector } from "react-redux";
import {getAllWarranty} from "../Warranty/warranty.thunk";
import _isEmpty from "lodash/isEmpty";
import Placeholder from "../shared/Placeholder";
import _filter from 'lodash/filter';
import _, { isNull } from "lodash";


export default
  function Warranty (props) {
    let {closePopup,openPopup,apiCallStatus,partData} = props;
    const dispatch = useDispatch();
    const [tableData , settableData] = useState([]);
    const [loader , setLoader] = useState(true);

    let refs = [];
    if (tableData) {
      refs = tableData.reduce((acc, item) => {
        acc[item.id] = React.createRef();
        return acc;
      }, {});
    }
    useEffect(() => {
      let arr =[];
        const warrantyAction = dispatch(getAllWarranty());
          warrantyAction.then((action) => {
         if(action.type === "getAllWarrantyDetails/fulfilled" && !_isEmpty(action.payload)){
          setLoader(false);
          const lineSupplies = action.payload;
          arr = lineSupplies.filter((v) => partData.lineCodes == v.line);
          settableData(arr);
          console.log("arrr===>",arr);
    }else{
      setLoader(false);
    }})
   }, []);

   const renderNoDataState = () => {
    return (
      <Card className="text-center">
        <Card.Header className="xlp-primary-banner">
          No Data Found
        </Card.Header>
        <Card.Body>
          <Card.Title>
          We couldn't find the data that you were looking for
          </Card.Title>
        </Card.Body>
      </Card>
    );
  };
    return (
        <Modal show={openPopup} className="labor_guide_popup_warranty">
        <Modal.Header>
          <Modal.Title className="title_container">
            <span>warranty details</span>
            <img
              src={closeBlackThin}
              alt="Close"
              onClick={() => closePopup("warranty")}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {(!loader) ? (
          <Fragment>
            {!_isEmpty(tableData) ?
              (<Container className="table__container">
                <Row className="tableContainer">
                  <div className="table">
                    <div className="tabelColumn_warranty">
                      <Col md="2">
                        <span>Category</span>
                      </Col>
                      <Col md="1" className="center_align">
                        <span>Line</span>
                      </Col>
                      <Col md="3" className="center_align">
                        <span>Part Type Description</span>
                      </Col>
                      <Col md="3" className="center_align">
                        <span>Supplier Stated Warranty</span>
                      </Col>
                      <Col md="3" className="center_align">
                        <span>Tech support information</span>
                      </Col>
                    </div>
                    <p className="count_of_labor_item_text">
                      {tableData.length} warranty details found
                    </p>

                    <div className="table__body_warranty">

                      { !_isEmpty(tableData) && tableData.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="tableRow animate__animated warranty-pop"
                              ref={refs[item.id]}
                            >
                             <Col md="2">
                                <Fragment>
                                  <span>
                                    {item.category}

                                  </span>
                                </Fragment>
                                </Col>
                                <Col md="1">
                                <Fragment>
                                  <div className="center_align">
                                  <span>
                                    {item.line}

                                  </span>
                                  </div>
                                </Fragment>
                                </Col>
                                <Col md="3">
                                <Fragment>
                                <div className="center_align">
                                  <span>
                                    {item.partTypeDescription}

                                  </span>
                                  </div>
                                </Fragment>
                                </Col>
                                <Col md="3">
                                <Fragment>
                                <div className="center_align">
                                  <span >
                                    {item.supplierStatedWarranty}

                                  </span>
                                  </div>
                                </Fragment>
                                </Col>
                                <Col md="3">
                                <Fragment>
                                <div className="center_align">
                                  <span >
                                    {item.techSupportInformation}

                                  </span>
                                  </div>
                                </Fragment>
                                </Col>

                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Row>
              </Container>)
             : (
              <div className="NoData_store_container_warranty">
              {renderNoDataState()}
            </div>
            )}
          </Fragment>
        ) : ( <Placeholder rows={3} />)}
        </Modal.Body>
      </Modal>
	);

}