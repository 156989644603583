import React from "react";
import T from "prop-types";
import { Button, Modal } from "react-bootstrap";
import clsx from "clsx";
import "./styles.scss";

const XModal = (props) => {
  const {
    title,
    children,
    handleClose,
    handleAction,
    submitType,
    showButtons,
    yesButtonText,
    noButtonText,
    submitButtonText,
    className,
    customHeader,
    isValidValidity,
    favoriteTextValidity,
    onCloseHandle
  } = props;
  return (
    <React.Fragment>
      <Modal
        {...props}
        aria-labelledby="warning-modal"
        className={clsx("xmodal", className ? className : null)}
        centered
        onHide={onCloseHandle}
        onExited={onCloseHandle}
      >
        <Modal.Header>
          {customHeader ? (
            customHeader
          ) : (
            <React.Fragment>
              <Modal.Title id="warning-modal-title">{title}</Modal.Title>
              <div className="close-btn" onClick={handleClose}>
                <span className="material-icons">close</span>
              </div>
            </React.Fragment>
          )}
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {showButtons && (
          <Modal.Footer className="xmodal-footer">
            {!submitType ? (
              <React.Fragment>
                <Button
                  disabled={isValidValidity === true ? false : true}
                  className="action-btn"
                  onClick={handleAction}
                >
                   {yesButtonText}
                </Button>
                <Button variant="light" onClick={handleClose}>
                  {noButtonText}
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
               <Button
                  className="action-btn"
                  disabled={favoriteTextValidity === true ? false : true}
                  onClick={handleAction}
                >
                  {submitButtonText}
                </Button>
              </React.Fragment>
            )}
          </Modal.Footer>
        )}
      </Modal>
    </React.Fragment>
  );
};

XModal.propTypes = {
  title: T.string,
  handleClose: T.func,
  handleAction: T.func,
  show: T.bool,
  showButtons: T.bool,
  yesButtonText: T.string,
  noButtonText: T.string,
  submitButtonText: T.string,
  submitType: T.bool,
  onCloseHandle: T.func,

};

XModal.defaultProps = {
  title: "Are you sure?",
  showButtons: true,
  yesButtonText: "Yes",
  noButtonText: "No",
  submitButtonText: "Submit",
};

export default XModal;
