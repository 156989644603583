/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  AsyncTypeahead,
  Menu,
  MenuItem,
} from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import Fuse from "fuse.js";
import FuzzySearch from "fuzzy-search";
import { getChemPartByGrp } from "../../../actions/parts";
import DropDownSearch from "../Search/DropDownSearch";
import {
  search,
  vinSearch,
  licensePlateSearch,
  getModelsSearch,
  enginesSearch,
  getVehiclesByModel,
  getVehiclesByMake,
  getMakesByYear,
  getProductListFromSkuNumber,
} from "./globalSearch.thunk";
import { getGroupsForCategory, reset } from "./globalSearch.slice";
import { setVehicleSelected } from "../../shared/MyGarage/mygarage.slice";
import { setVehicles } from "../../shared/MyGarage/mygarage.thunks";
// import { resetStockList } from "../../StockCheck/stockcheck.slice";
import {
  getGroupsByCategory,
  setBrand,
  showBrandGroups,
} from "../../ChemicalsAndSupplies/chemicalsSearch.slice";

import { getPartsByMultiGroupOrchestrator } from "../../../actions/parts";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import { buildQueryUrl, getCategoryFromGroupId, isLengthLessthan, timeInUTC } from "../../../helpers/utils";
import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import { useEffect } from "react";
import _isEmpty from "lodash/isEmpty";
import _head from "lodash/head";
import OptionCard from "./OptionCard";
import { chemicalsearch } from "../../ChemicalsAndSupplies/chemicalsSearch.thunk";
import "./styles.scss";
import T from "prop-types";
import { logUserStockActivity } from "../../../actions/user";
import axios from "axios";
import short from "short-uuid";
import instance from "services/http";
import { forIn } from "lodash";
import { DEBOUNCE_RATE, SEARCH_API_WORD } from "./helpers";

const NO_VEHICLE_MSG =
  "Please select a vehicle from YMME search or select a vehicle from My Garage, or select a vehicle from search";

const GAP = 16;

function GlobalSearch(props) {
  const { dropdownValue } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();

  const initializeValidity = () => {
    const validity = {
      globalSearchMaxLimit: true,
    };
    return validity;
  };
  const smsPartDescription = useSelector(
    (state) => state.app.smsPartDescription
  );
  const smsVinNumber = useSelector((state) => state.app.smsVinNumber);
  const smsUser = useSelector((state) => state.app.smsUser);
  const smsChemicalPart = useSelector((state) => state.app.smsChemicalPart);
  const globalSearch = useSelector((state) => state.globalSearch);
  const chemicalsGlobalSearch = useSelector(
    (state) => state.chemicalsGlobalSearch
  );
  const userDetails = useSelector((state) => state.user.userDetails);

  const [isState, setIsState] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [validity, setValidity] = useState(initializeValidity);
  const [searchFailureValue, setSearchFailure] = useState(false);
  const [gloabalSearchStarted, setGlobalSearchStarted] = useState(false);
  const [updatedSearchValue, setUpdatedSearchValue] = useState("");
  const [options, setOptions] = React.useState([]);
  let [matches, setMatches] = React.useState({});
  let [matchesLength, setMatchesLength] = React.useState(0);
  const [isPartsLoading, setIsPartsLoading] = React.useState(false);
  const [queues, setQueues] = useState({});
  const [searchInputValue, setSearchInputValue] = useState(null);

  const user = useSelector((state) => state.user);
  const parts = useSelector((state) => state.parts);
  const searchInput = useRef(null);
  const mygarage = useSelector((state) => state.mygarage);
  const sv = mygarage.selectedVehicle;
  const selectedDropDown = parts.selectedDropDown;
  const isLoading = globalSearch.loading;

  useEffect(() => {
    if (searchInputValue) {
      handleSearch(searchInputValue);
    }
  }, [searchInputValue]);

  useEffect(() => {
    // Update the local state whenever globalSearch.options changes
    setTimeout(() => {
      const globalSearchResult = hasDataInOptions(globalSearch.options);
      const chemicalSearchResult = hasDataInOptions(
        chemicalsGlobalSearch.options
      );
      if (
        selectedDropDown === "Part Catalog" &&
        !globalSearchResult &&
        gloabalSearchStarted &&
        updatedSearchValue === searchFailureValue
      ) {
        handleSearchError(searchFailureValue);
        setGlobalSearchStarted(false);
        setUpdatedSearchValue("");
      } else if (
        selectedDropDown === "Chemicals" &&
        !chemicalSearchResult &&
        gloabalSearchStarted &&
        updatedSearchValue === searchFailureValue
      ) {
        handleSearchError(searchFailureValue);
        setGlobalSearchStarted(false);
        setUpdatedSearchValue("");
      } else if (
        !globalSearchResult &&
        !chemicalSearchResult &&
        gloabalSearchStarted &&
        updatedSearchValue === searchFailureValue &&
        selectedDropDown === "All"
      ) {
        handleSearchError(searchFailureValue);
        setGlobalSearchStarted(false);
        setUpdatedSearchValue("");
      }
    }, 500);
  }, [
    globalSearch.options,
    chemicalsGlobalSearch.options,
    gloabalSearchStarted,
    searchFailureValue,
    handleSearchError,
    selectedDropDown,
    updatedSearchValue,
  ]);

  useEffect(() => {
    if (
      smsUser &&
      _isEmpty(sv) &&
      !_isEmpty(smsPartDescription) &&
      smsPartDescription.length > 1
    ) {
      handleSearch(smsPartDescription);
      searchInput.current.focus();
    } else {
      dispatch({
        payload: {
          smsPageLoader: false,
        },
        type: "SMS_PAGE_LOADER",
      });
    }
  }, [sv, smsPartDescription]);

  useEffect(() => {
    const states =
      globalSearch.options.length > 0 ? globalSearch.options[0].states : [];
    if (states && states.length === 1) {
      const state = _.head(states);

      const plate = state.plate;
      const query = searchInput.current.getInput();
      const multipleQuery = query.value.trim().split(" ");
      const plateNumber = _.last(multipleQuery);
      if (plateNumber.length > 5) {
        dispatch(licensePlateSearch({ plateNumber, state: plate.stateCode }));
      }
    }
  }, [globalSearch.options]);

  const onOptionEmpty = (q) => {
    const stockCheck = {
      requestItems: [
        {
          mfgCode: "*",
          partNum: q,
          qty: 1,
        },
      ],
    };
    dispatch(getProductListFromSkuNumber(stockCheck));
  };

  const handleSearchError = (query) => {
    const errorData = {
      source: `Home Page - ${selectedDropDown}`,
      searchvalue: query,
      resultFound: false,
      selectedVehicle: sv
        ? `${sv?.year} ${sv?.make} ${sv?.model} ${sv?.engine}`
        : null,

      filters: {
        groupCode: parts?.specificCondition?.[0]?.groupCode || null,
        brand: parts?.catalogFilter?.brand || null,
        position: parts?.catalogFilter?.position || null,
        availablity: parts?.catalogFilter?.availablity || null,
      },
    };

    logUserSearchFailureActivity(errorData);
  };

  const logUserSearchFailureActivity = (storedErrors) => {
    const { x2userName, shipTos, billTo } = userDetails;
    if (!_isEmpty(userDetails) && !_isEmpty(billTo)) {
      let loginId = "";
      let shipToId = shipTos[0]?.shipToId;
      let userData = JSON.stringify(storedErrors);
      let type = "Search - No Result";

      dispatch(
        logUserStockActivity(
          timeInUTC(),
          billTo.billToId,
          loginId,
          shipToId,
          type,
          userData,
          x2userName
        )
      );
    }
  };
  // Add this function to check if any array in the object has data
  const hasDataInOptions = (options) => {
    if (options.length > 0 && options[0] && typeof options[0] === "object") {
      const objectAtIndex0 = options[0];
      for (const key in objectAtIndex0) {
        if (objectAtIndex0[key] && objectAtIndex0[key].length > 0) {
          return true; // At least one key-value array has data
        }
      }
    }
    return false; // No key-value array has data
  };

  const reset_matches = () => {
    setMatches({});
    setMatchesLength(0);
  };

  // API Call for Part Types by Vehicles
  const apiCall = (term, source, searchTermOrder, terms) => {
    instance
      .post(`${SEARCH_API_WORD}${buildQueryUrl(term)}`, null, {
        cancelToken: source.token,
      })
      .then((resp) => resp.data)
      .then((resp) => {
        const results = resp;

        for (var j = 0; j < results.length; j++) {
          var resultTerm = results[j].name;
          if (matches[resultTerm]) {
            matches[resultTerm].matchCount += searchTermOrder; //earlier words receive the higher order
            if (matches[resultTerm].typeIdList.indexOf(results[j].id) < 0) {
              matches[resultTerm].typeIdList.push(results[j].id);
            }
            if (
              matches[resultTerm].groupNumberList.indexOf(results[j].groupNum) <
              0
            ) {
              matches[resultTerm].groupNumberList.push(results[j].groupNum);
            }
          } else {
            matchesLength++;
            matches[resultTerm] = {
              typeIdList: [results[j].id],
              groupNumberList: [results[j].groupNum],
              term: resultTerm,
              matchCount: searchTermOrder, //earlier words receive the higher order
              score: null,
            };
          }
        }

        for (var matchTerm in matches) {
          var index = 0;
          matches[matchTerm].score =
            matches[matchTerm].matchCount +
            matchTerm
              .toUpperCase()
              .split(/\s/)
              .reverse()
              // eslint-disable-next-line no-loop-func
              .map(function (e) {
                var termIndex = terms.indexOf(e),
                  score;

                if (terms.indexOf(e) < 0) {
                  score = 0;
                } else {
                  score = (termIndex + 1) * ++index;
                }
                return score;
              })
              .reduce((total, e) => total + e, 0);
        }

        let _finalResult = Object.entries(matches).sort(
          (a, b) => b[1].score - a[1].score
        );

        const finalResult = _finalResult.map((f) => f[1]);

        let searchResults = {};
        if (terms.length === 1) {
          searchResults = finalResult;
        } else {
          searchResults = {
            likelyMatches: finalResult.splice(0, 5),
            allMatches: finalResult.splice(0, 20),
          };
        }

        setOptions([{ parts: searchResults }]);

        setIsPartsLoading(false);
      })
      .catch((err) => {
        setIsPartsLoading(false);
      });
  };

  const handleSearchPromises = {};
  const handleSearch = (query) => {
    setIsPartsLoading(true);

    let cancelToken, source;

    setUpdatedSearchValue(query);

    let validity = initializeValidity();
    validity.globalSearchMaxLimit = isLengthLessthan(query, 100);
    setValidity(validity);
    const multipleQuery = _.uniq(query.trim().split(" ").concat(query));

    let payload = { searchKey: query };

    if (sv) {
      reset_matches();

      payload = {
        year: sv.year,
        make: sv.makeId,
        model: sv.modelId,
        engine: sv.engineId,
        searchString: query,
      };

      const terms = _.uniq(
        query.trim().toUpperCase().replace(/,/g, "").split(/\s+/).reverse()
      );

      for (var q in queues) {
        queues[q]?.cancel();

        delete queues[q];
        setQueues(queues);
      }

      // chemical Search even if vehicle is selected
      dispatch(chemicalsearch({ s: query }));

      for (var i = 0; i < terms.length; i++) {
        cancelToken = axios.CancelToken;
        source = cancelToken.source();

        const term = terms[i];
        const searchTermOrder = i + 1;
        payload.searchString = term;

        handleSearchPromises[short.generate()] = source;

        setQueues(handleSearchPromises);
        apiCall(payload, source, searchTermOrder, terms);
      }
    } else {
      const payload1 = { s: query };

      if (
        isState ||
        (multipleQuery.length === 2 && _.head(multipleQuery).length === 2)
      ) {
        const stateCode = selectedState
          ? selectedState.stateCode
          : _.head(multipleQuery);
        const plateNumber = _.last(multipleQuery);
        if (plateNumber.length > 5 && (selectedState || stateCode)) {
          dispatch(licensePlateSearch({ plateNumber, state: stateCode }));
        }
      } else {
        if (query.trim().length === 17 && multipleQuery.length === 1) {
          dispatch(vinSearch({ vin: query }));
        } else {
          dispatch(chemicalsearch({ s: query }));
          dispatch(search({ payload }));
          onOptionEmpty(query);

          // dispatch(chemicalsearch(payload1))
          //   .then((results) => {
          //     dispatch(search({ payload }))
          //       .then((results) => {
          //         setTimeout(() => {
          //           if (results.payload && results.payload.data) {
          //             onOptionEmpty(query);
          //             setTimeout(() => {
          //               setGlobalSearchStarted(true);
          //               setSearchFailure(query);
          //             }, 500);

          //             // -------------------------------change for iframe integration----------------------
          //             if (!_isEmpty(smsPartDescription) && smsUser) {
          //               let dropDownOptions = results.payload.data.partType;
          //               if (_isEmpty(dropDownOptions) && smsChemicalPart) {
          //                 history.push("/supplies-catalog");
          //               } else if (
          //                 dropDownOptions &&
          //                 dropDownOptions.length === 1
          //               ) {
          //                 let findedPartType = _head(dropDownOptions);
          //                 let tempFindedPart = { ...findedPartType };
          //                 tempFindedPart["type"] = "parts";
          //                 if (_isEmpty(smsVinNumber)) {
          //                   handleChange([tempFindedPart]);
          //                 }
          //                 dispatch({
          //                   payload: {
          //                     smsPartDesc: null,
          //                   },
          //                   type: "SMS_PART_DESCRIPTION",
          //                 });
          //                 dispatch({
          //                   payload: {
          //                     smsPageLoader: false,
          //                   },
          //                   type: "SMS_PAGE_LOADER",
          //                 });
          //               } else {
          //                 dispatch({
          //                   payload: {
          //                     smsPartDesc: null,
          //                   },
          //                   type: "SMS_PART_DESCRIPTION",
          //                 });
          //                 dispatch({
          //                   payload: {
          //                     smsPageLoader: false,
          //                   },
          //                   type: "SMS_PAGE_LOADER",
          //                 });
          //               }
          //             }
          //             // ----------------------------------------------------------------------------------
          //           } else {
          //             handleSearchError(query);
          //             setUpdatedSearchValue("");
          //           }
          //         }, 500 * 1);
          //       })
          //       .catch((error) => {
          //         handleSearchError(query);
          //         setUpdatedSearchValue("");
          //       });
          //     // }
          //   })
          //   .catch((err) => {
          //     handleSearchError(query);
          //     setUpdatedSearchValue("");
          //     if (!_isEmpty(smsPartDescription) && smsUser) {
          //       dispatch({
          //         payload: {
          //           smsPartDesc: null,
          //         },
          //         type: "SMS_PART_DESCRIPTION",
          //       });
          //       dispatch({
          //         payload: {
          //           smsPageLoader: false,
          //         },
          //         type: "SMS_PAGE_LOADER",
          //       });
          //     }
          //   });
        }
      }
    }
  };

  const getGroupFromCategory = (categoryName, groupName) => {
    const category = _.find(
      parts.categoryGroupMap,
      (c) => c.name.toLowerCase() === categoryName.toLowerCase()
    );
    const g = _.find(
      category.groups,
      (g) => g.name.toLowerCase() === groupName.toLowerCase()
    );
    return Object.assign({}, g);
  };

  const handleChange = (selected) => {
    const option = _.head(selected);
    if (!option) return;

    switch (option.type) {
      case "chemicalBrands":
        dispatch(setBrand({ chemicalBrands: option }));
        searchInput.current.toggleMenu();
        break;

      case "chemicalCategories":
        const partSupplies = parts.categorySuppliesMap;
        let chemicalCategories = _.filter(
          partSupplies,
          (p) => option.name === p.name
        );
        chemicalCategories = _.head(chemicalCategories);
        if (chemicalsGlobalSearch.chemicalBrands) {
          dispatch(showBrandGroups());
        } else {
          dispatch(getGroupsByCategory(chemicalCategories));
        }
        searchInput.current.toggleMenu();
        break;

      case "chemicalGroups":
        const groupIds = selected.map((single) => single.id);

        let _brand = "";

        if (chemicalsGlobalSearch.brand) {
          _brand = chemicalsGlobalSearch.brand.name;
        }

        dispatch(
          getChemPartByGrp(
            groupIds,
            parts.suppliesPageNumber,
            parts.suppliesPageSize,
            parts.suppliesSortBy,
            parts.suppliesOrder,
            _brand
          )
        );
        history.push("/supplies-list");
        break;

      case "chemicalParts":
        const ids = selected.map((single) => single.groupID);
        const partDescids = selected.map((single) => single.partDescriptionID);
        // dispatch(getChemPartByGrp(ids));
        dispatch(
          getChemPartByGrp(
            ids,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            partDescids
          )
        );

        history.push("/supplies-list");
        break;

      case "partSkus":
        history.push(
          `/stock-check?partSku=${option.partSku.partNum}&description=${option.option}&mfgCode=${option.partSku.mfgCode}`
        );
        break;

      case "parts":
        // store the selected part type for the filter to show in the part type dropdown filter
        dispatch({
          payload: {
            selectedPartType: option,
          },
          type: "SELECTED_PART_TYPE",
        });

        if (!sv) {
          addToast(NO_VEHICLE_MSG, { appearance: "info", autoDismiss: true });
          return;
        }

        const groupId = option.groupNumberList?.[0];

        const { category: _category, group: _group } = getCategoryFromGroupId(
          groupId,
          parts.categoryGroupMap
        );

        // Dispatch Selected Group
        dispatch({
          payload: {
            selectedGroupDetails: [{ categoryId: _category.code }],
          },
          type: "GROUP_SELECTED",
        });

        const reqObj = {
          requestItems: [
            {
              groupCode: _group.code,
              specificCondition: [],
            },
          ],
        };

        const filter = {
          ...parts.catalogFilter,
          exactMatch: true,
          partTypes: [{ id: option.partTypeId || option.typeIdList[0] }],
        };

        history.push("/product-list");
        dispatch(
          getPartsByMultiGroupOrchestrator(
            sv.year,
            sv.makeId,
            sv.modelId,
            sv.engineId,
            reqObj,
            null,
            null,
            null,
            null,
            filter,
            userDetails.billTo.mclSupplier,
            selected[0] || parts.selectedPartType,
            true
          )
        );
        break;

      case "category":
        const categoryGroups = parts.categoryGroupMap;
        let category = _.filter(
          categoryGroups,
          (c) => c.code.toString() === option.categoryId.toString()
        );
        category = _.head(category);
        dispatch(getGroupsForCategory(category));

        searchInput.current.toggleMenu();
        break;
      case "group":
        let group = getGroupFromCategory(option.categoryDesc, option.groupDesc);
        group.categoryId = +option.categoryId;

        dispatch({
          payload: {
            selectedGroupDetails: [group],
          },
          type: "GROUP_SELECTED",
        });

        if (!sv) {
          addToast(NO_VEHICLE_MSG, { appearance: "info", autoDismiss: true });
          return;
        }
        history.push("/product-list");
        // Dispatch Selected Group
        const _reqObj = {
          requestItems: [
            {
              groupCode: option.groupId,
              specificCondition: [],
            },
          ],
        };
        dispatch(
          getPartsByMultiGroupOrchestrator(
            sv.year,
            sv.makeId,
            sv.modelId,
            sv.engineId,
            _reqObj,
            null,
            null,
            null,
            null,
            parts.catalogFilter,
            userDetails.billTo.mclSupplier,
            selected[0] || parts.selectedPartType,
            true
          )
        );
        break;
      case "vehicles":
        const selectedVehicle = option.vehicleParams;
        if (
          (selectedVehicle.yearDesc || selectedVehicle.year) &&
          !(selectedVehicle.modelDesc || selectedVehicle.model) &&
          !(selectedVehicle.makeDesc || selectedVehicle.make) &&
          !(selectedVehicle.engine || selectedVehicle.engineDesc)
        ) {
          dispatch(getMakesByYear(selectedVehicle));
        }

        if (
          !(selectedVehicle.modelDesc || selectedVehicle.model) &&
          (selectedVehicle.yearDesc || selectedVehicle.year) &&
          (selectedVehicle.makeDesc || selectedVehicle.make)
        ) {
          dispatch(getModelsSearch(selectedVehicle));
        }

        if (
          !selectedVehicle.engine &&
          (selectedVehicle.yearDesc || selectedVehicle.year) &&
          (selectedVehicle.makeDesc || selectedVehicle.make) &&
          (selectedVehicle.modelDesc || selectedVehicle.model)
        ) {
          dispatch(enginesSearch(selectedVehicle));
        }

        if (selectedVehicle.engine) {
          const { x2userName, shipTos, billTo } = user.userDetails || {};
          const payload = {
            createdBy: x2userName,
            createdDate: timeInUTC(),
            customerId: billTo.billToId,
            engine: selectedVehicle.engine.trim(),
            engineId: selectedVehicle.engineId,
            favoriteFlag: "N",
            favoriteText: "",
            favoriteUpdatedDate: "",
            lastOrderedDate: "",
            make: selectedVehicle.make.trim(),
            makeId: selectedVehicle.makeId,
            internalMakeId: selectedVehicle.internalMakeId,
            model: selectedVehicle.model.trim(),
            modelId: selectedVehicle.modelId,
            orderedFlag: "N",
            searchId: "",
            shipToId: shipTos[0].shipToId,
            updatedBy: x2userName,
            updatedDate: timeInUTC(),
            year: selectedVehicle.year.trim(),
          };
          dispatch(setVehicles(payload));
          dispatch(setVehicleSelected(payload));
          dispatch({
            payload: {
              selectedVehicle,
            },
            type: "VEHICLE_SELECTION_COMPLETED",
          });

          if (parts.selectedGroupDetails.length > 0) {
            let requestItems = parts.selectedGroupDetails.map((singleItem) => {
              return { groupCode: singleItem.code, specificCondition: [] };
            });
            let groupsSelected = { requestItems };
            history.push("/product-list");

            dispatch(
              getPartsByMultiGroupOrchestrator(
                selectedVehicle.yearDesc || selectedVehicle.year,
                selectedVehicle.makeId,
                selectedVehicle.modelId,
                selectedVehicle.engineId,
                groupsSelected,
                null,
                null,
                null,
                null,
                parts.catalogFilter,
                userDetails.billTo.mclSupplier,
                selected[0] || parts.selectedPartType,
                true
              )
            );
          }
        }

        // searchInput.current.toggleMenu();
        break;
      case "make":
        const _payload = { searchKey: option.mainMakeName, searchType: "make" };

        dispatch(getVehiclesByMake(_payload));

        searchInput.current.toggleMenu();
        break;
      case "model":
        const payload = { searchKey: option.modelDesc, searchType: "model" };

        dispatch(getVehiclesByModel(payload));

        searchInput.current.toggleMenu();
        break;
      case "states":
        setIsState(true);
        setSelectedState(option.plate);
        break;
      default:
        break;
    }
  };

  const handleVehiclesChange = (selected) => {
    const option = _.head(selected);

    if (!option) return;

    switch (option.type) {
      case "vehicles":
        const selectedVehicle = option.vehicleParams;
        if (
          (selectedVehicle.yearDesc || selectedVehicle.year) &&
          !(selectedVehicle.modelDesc || selectedVehicle.model) &&
          !(selectedVehicle.makeDesc || selectedVehicle.make) &&
          !(selectedVehicle.engine || selectedVehicle.engineDesc)
        ) {
          // dispatch(getMakesByYear(selectedVehicle))
        }

        if (
          !(selectedVehicle.modelDesc || selectedVehicle.model) &&
          (selectedVehicle.yearDesc || selectedVehicle.year) &&
          (selectedVehicle.makeDesc || selectedVehicle.make)
        ) {
          // dispatch(getModelsSearch(selectedVehicle));
        }

        if (
          !selectedVehicle.engine &&
          (selectedVehicle.yearDesc || selectedVehicle.year) &&
          (selectedVehicle.makeDesc || selectedVehicle.make) &&
          (selectedVehicle.modelDesc || selectedVehicle.model)
        ) {
          dispatch(enginesSearch(selectedVehicle));
        }

        if (searchInput && !searchInput.current.isMenuShown) {
          searchInput.current.toggleMenu();
        }

        break;
      default:
        break;
    }
  };

  const getGroupLabels = (label) => {
    switch (label) {
      case "chemicalBrands":
        return "Chemical Brands";
      case "chemicalParts":
        return "Chemical Part Descriptions";
      case "chemicalGroups":
        return "Chemical Groups";
      case "chemicalCategories":
        return "Chemical Categories";
      case "partSkus":
        return "Part SKUs - Go To Stock Check";
      case "group":
        return "Parts Groups";
      case "category":
        return "Parts Categories";
      case "parts":
        return "Part Descriptions";
      case "make":
        return "Parts Makes";
      case "model":
        return "Parts Model";
      case "vehicles":
        return "Parts Vehicles";
      case "states":
        return "Parts States";
      default:
        break;
    }
  };

  const getValue = (option, type) => {
    switch (type) {
      case "chemicalBrands":
        return option.name;
      case "chemicalParts":
        return option.partDescription;
      case "chemicalGroups":
        return option.name;
      case "chemicalCategories":
        return option.name;
      case "partSkus":
        return option.option;
      case "vehicles":
        return option.option;
      case "category":
        return option.categoryDesc;
      case "group":
        return option.groupDesc;
      case "make":
        return option.mainMakeName;
      case "model":
        return option.modelDesc;
      case "parts":
        return `${option.term || option.partTypeDesc}`;
      case "states":
        return option.state;
      default:
        break;
    }
  };

  const getLabel = (option) => {
    return getValue(option, option.type);
  };

  const setOption = (option, type) => {
    let _option = Object.assign({}, option);
    _option.type = type;

    return _option;
  };

  const cw =
    document.querySelector(".category__wrapper") ||
    document.querySelector(".chemicalsAndSuppliesContainer");

  const renderMenu = (results, menuProps, state) => {
    const bounding = document
      .querySelector(".input__default.input__search")
      .getBoundingClientRect();
    let gs = document.querySelector("#global__search");
    const __cw = cw && cw.getBoundingClientRect();

    if (gs && bounding && __cw) {
      const _gs = gs.getBoundingClientRect();

      const menuHeight = window.innerHeight - _gs.top - GAP;
      menuProps.style = {
        ...menuProps.style,
        display: "flex",
        left: `${__cw.left}px`,
        width: `${__cw.width - GAP}px`,
        transform: `translate3d(-${bounding.left - GAP / 2}px, 52px, 0)`,
        height: `${menuHeight}px)`,
      };
    }

    if (!results.length)
      return (
        <Menu {...menuProps}>
          <MenuItem>Searching...</MenuItem>
        </Menu>
      );

    if ((!globalSearch.loading || !isPartsLoading) && false) {
      const top = _.head(results);

      let flag = false;
      const values = top && Object.values(top);

      if (values && Array.isArray(values)) {
        for (let i = 0; i < values.length; i++) {
          const t = values[i];
          if (t.length > 0) {
            flag = true;

            break;
          }
        }
      } else {
        for (const key in values) {
          if (values[key].length > 0) {
            flag = true;

            break;
          }
        }
      }

      if (!flag) {
        return (
          <Menu {...menuProps}>
            <MenuItem>No Matching results</MenuItem>
          </Menu>
        );
      }
    }

    setIsPartsLoading(false);

    return (
      <OptionCard
        results={results}
        getGroupLabels={getGroupLabels}
        setOption={setOption}
        getValue={getValue}
        isVehicleSelected={!_.isEmpty(sv)}
        handleChange={handleChange}
        {...menuProps}
      />
    );
  };

  const handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  const onKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      const list = document.querySelectorAll(".dropdown-item");
      const firstItem = list[0];

      firstItem?.focus();
    }
  };

  const handleFocus = (e) => {
    const searchText = e.target.value;

    if (searchText === "") {
      dispatch(reset());
    }
  };

  const fuzySearch = (options, chemicalOptions) => {
    let intermOptions = {};
    if (options.length > 0 && searchInput && searchInput.current) {
      const input = searchInput.current.getInput();

      intermOptions = Object.assign({}, _.head(options));

      let filteredParts;
      if (sv) {
        filteredParts = options[0]?.parts || {};
      } else {
        const fuzzyParts = new FuzzySearch(
          options[0].parts,
          ["partTypeDesc"],
          {}
        );
        filteredParts = fuzzyParts.search(input.value);
        const _parts = options[0].parts || [];
        filteredParts = _.uniqBy(
          _parts.concat(filteredParts),
          (p) => p.partTypeId
        );
      }

      const fuzzyStates = new FuzzySearch(
        options[0].states,
        ["state", "stateDesc"],
        {}
      );
      const filteredStates = fuzzyStates.search(input.value);

      const fuzzyMakes = new FuzzySearch(
        options[0].make,
        ["make", "makeDesc"],
        {}
      );
      const filteredMakes = fuzzyMakes.search(input.value);

      const fuzzyModel = new FuzzySearch(
        options[0].model,
        ["model", "modelDesc"],
        {}
      );
      const filteredModels = fuzzyModel.search(input.value);

      intermOptions.parts = filteredParts;
      intermOptions.states = filteredStates;
      intermOptions.make = filteredMakes;
      intermOptions.model = filteredModels;

      let vehicles = [];
      if (!sv && options[0].vehicles.length === 1) {
        vehicles = options[0].vehicles;
        const selectedVehicle = vehicles[0].vehicleParams;

        if (
          (selectedVehicle.yearDesc || selectedVehicle.year) &&
          !(selectedVehicle.modelDesc || selectedVehicle.model) &&
          !(selectedVehicle.makeDesc || selectedVehicle.make) &&
          !(selectedVehicle.engine || selectedVehicle.engineDesc)
        ) {
          dispatch(getMakesByYear(selectedVehicle));
        }

        if (input.value.split(" ").length > 2) {
          handleVehiclesChange(vehicles);
        }
      } else if (!sv) {
        const __fuse = new Fuse(options[0].vehicles, { keys: ["option"] });
        vehicles = __fuse.search(input.value).map((i) => i.item);

        if (input.value.split(" ").length > 2) {
          handleVehiclesChange(vehicles);
        }
      }

      intermOptions.vehicles = _.sortBy(vehicles, (v) => v.option);
    }

    switch (selectedDropDown) {
      case "Part Catalog":
        intermOptions = intermOptions;
        break;
      case "Chemicals":
        intermOptions = chemicalOptions[0];
        break;
      default:
        intermOptions = Object.assign(intermOptions, chemicalOptions[0]);
        break;
    }

    // setIntermFuzyOptions([intermOptions])
    return [intermOptions];
  };

  const getMaxHeight = () => {
    let gs = document.querySelector("#global__search");

    if (gs) {
      const _gs = gs.getBoundingClientRect();
      const menuHeight = window.innerHeight - _gs.top - GAP;

      return `${menuHeight}px`;
    } else {
      return "auto";
    }
  };

  return (
    <>
      <Row className="justify-content-center search_box ">
        <DropDownSearch
          onSearch={handleSearch}
          chemicalsearch={chemicalsearch}
          search={search}
          globalSearch={globalSearch.option}
          chemicalSearch={chemicalsGlobalSearch.options}
          value={dropdownValue}
        />
        <Col sm={8}>
          <AsyncTypeahead
            useCache={false}
            onFocus={handleFocus}
            clearButton={true}
            ref={searchInput}
            filterBy={() => true}
            inputProps={{ className: "input__default input__search" }}
            id="global__search"
            isLoading={isLoading || isPartsLoading}
            labelKey={(option) => getLabel(option)}
            options={
              !isLoading
                ? fuzySearch(
                    sv && !isPartsLoading ? options : globalSearch.options,
                    chemicalsGlobalSearch.options
                  )
                : []
            }
            delay={0}
            onSearch={(query) => setSearchInputValue(query)}
            placeholder="Search by Year, Make, Model, Vin, License Plate, Part No"
            onChange={handleChange}
            minLength={2}
            defaultInputValue={
              !_isEmpty(smsPartDescription) && smsPartDescription.length > 1
                ? smsPartDescription
                : ""
            }
            onKeyDown={onKeyDown}
            renderMenu={renderMenu}
            maxHeight={getMaxHeight()}
          />
          <FormValidationErrorMessage
            condition={!validity.globalSearchMaxLimit}
            errorMessage="Global search key should not exceed more than 100 characters"
          />
        </Col>
      </Row>
    </>
  );
}

GlobalSearch.propTypes = {
  dropdownValue: T.string,
};

GlobalSearch.defaultProps = {
  dropdownValue: "All",
};

export default GlobalSearch;
