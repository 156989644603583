import React, { Fragment, useState } from 'react';
import { Tab, Row, Col, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import { statesList } from '../../../helpers/constants';
import { isAlphanumeric, isLengthLessthan, hasMinLength } from '../../../helpers/utils';
import _every from 'lodash/every';
import _pick from 'lodash/pick';
import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';

function LicensePlateSearch(props) {
	let { handleInputOnBlur, onSelectChange, stateValidity, selectedState, handleInputOnChange } = props;

	const intializelicenseValidation = () => {
		const licenseValidity = {
			isLicenceAlphanumeric: true,
			isLicenceMinLength: true,
			isLicenceLengthBelowLimit: true,
			isLicenceValid: true,
		}
		return licenseValidity;
	}

	const [licenseValidity, setlicenseValidity] = useState(intializelicenseValidation);
	const [licensenumber, setlicenseNumber] = useState('');

	const handleLicensePlateChange = (event) => {
		const licenseValue = event.target.value;
		let licenseValidity = intializelicenseValidation();
		licenseValidity.isLicenceAlphanumeric = isAlphanumeric(licenseValue);
		licenseValidity.isLicenceLengthBelowLimit = isLengthLessthan(licenseValue, 11);
		licenseValidity.isLicenceMinLength = hasMinLength(licenseValue, 4);
		licenseValidity.isLicenceValid = _every(
			_pick(licenseValidity, [
				'isLicenceAlphanumeric',
				'isLicenceLengthBelowLimit',
				'isLicenceMinLength',
			]),
		);
		setlicenseValidity(licenseValidity);
		setlicenseNumber(licenseValue);
	}

	const handleValidationMessage = () => {
		const licenseValidity = intializelicenseValidation();
		setlicenseValidity(licenseValidity);
	}

	const isInputBlank = (input) => {
		return input === "" || input === null || input === undefined;
	};

	return (
		<Tab.Pane eventKey="LicensePlate">
			<Row className="licenseplate-wrapper justify-content-center">
				<Col className="select-container">
					<Select
						options={statesList}
						name="state"
						placeholder="State"
						onChange={onSelectChange}
						value={selectedState}
					/>
				</Col>
				<Fragment>
					<FormValidationErrorMessage
						condition={!stateValidity.isStateMinLength}
						errorMessage={"State should have atleast 2 characters"}
					/>
					<FormValidationErrorMessage
						condition={!stateValidity.isStateAlphanumeric && stateValidity.isStateMinLength}
						errorMessage={"State should be alphanumeric only"}
					/>
				</Fragment>
				<Col className="select-container">
					<Form.Control
						className="input__default d-inline-block"
						type="text"
						onBlur={handleInputOnBlur}
						onKeyDown={handleInputOnChange}
						name="plate"
						placeholder="License Plate"
						onChange={handleLicensePlateChange}
						onFocus={handleValidationMessage}
						maxLength={12}
					/>
				</Col>
				<Fragment>
					<FormValidationErrorMessage
						condition={!licenseValidity.isLicenceLengthBelowLimit && licenseValidity.isLicenceMinLength && licenseValidity.isLicenceAlphanumeric}
						errorMessage={"License plate number should not exceed above 11 characters"}
					/>
					<FormValidationErrorMessage
						condition={!licenseValidity.isLicenceMinLength && licenseValidity.isLicenceAlphanumeric && licenseValidity.isLicenceLengthBelowLimit}
						errorMessage={"License plate number should have atleast 4 character"}
					/>
					<FormValidationErrorMessage
						condition={!licenseValidity.isLicenceAlphanumeric && !isInputBlank(licensenumber)}
						errorMessage={"License plate number should be alphanumeric only"}
					/>
				</Fragment>
			</Row>
		</Tab.Pane>
	);
}
export default LicensePlateSearch;
