import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import XModal from '../XModal';

import './styles.scss';

const headerStyle = {
	backgroundColor: '#0F5283',
	color: '#FFFFFF',
	'z-index': '999'
};

const OrderAvailabilityPopUp = (props) => {
	let { openPopup, closePopup, data, activeOrder, fromMarket, market, store } = props;

	useEffect(
		() => {
			constructGridData(data ? data : []);
		},
		[ data ]
	);
	const [ partInvoiceTableColumns, setPartInvoiceTableColumns ] = useState([]);
	const [ partInvoiceTableData, setPartInvoiceTableData ] = useState([]);
	const user = useSelector((state) => state.user);
  const mygarage = useSelector(state => state.mygarage);
	const userDetails = user.userDetails;
  const mainLocationStores = mygarage.storeDetails;

	const constructGridData = (availData) => {
		setPartInvoiceTableColumns([]);
		setPartInvoiceTableData([]);

		const rowData = [];
		availData.forEach((store, index) => {
      mainLocationStores.forEach(mls => {
        if ((Number(store.store) === Number(mls.storeNum))) {
          rowData.push({
            storeDetails: { store: store.store, name: store.storeName, qty: store.storeQty },
            rop: store.rop ? store.rop : 0,
            yearlyDemand: store.yearlyDemand ? store.yearlyDemand : 0,
            availqty: store.storeQty,
            index: index
          });
        }
      })
		});

		const index = fromMarket
			? _.findIndex(rowData, (r) => Number(r.storeDetails.store) === Number(market))
			: _.findIndex(rowData, (r) => Number(r.storeDetails.store) === Number(store));
		if (index > -1) {
			const hub = rowData[index];
			rowData.splice(index, 1);
			rowData.unshift(hub);
		}

		const columns = [
			{
				dataField: 'store',
				text: 'STORE',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				width: '80',
				'z-index': '999',
				formatter: function(cell, row, index) {
					return (
						<div>{`${row.storeDetails.store} ${row.storeDetails.name
							? `- ${row.storeDetails.name}`
							: ''}`}</div>
					);
				}
			},
			{
				dataField: 'rop',
				text: 'ROP',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				width: '80',
				'z-index': '999'
			},

			{
				dataField: 'yearlyDemand',
				text: 'YEAR DEMAND',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				'z-index': '999'
			},
			{
				dataField: 'availqty',
				text: 'AVAILABLE QTY',
				headerStyle: headerStyle,
				align: 'center',
				headerAlign: 'center',
				'z-index': '999'
			}
		];
		setPartInvoiceTableColumns(columns);
		setPartInvoiceTableData(rowData);
	};

	return (
		<React.Fragment>
			<XModal
				title={
					<Row>
						<Col md="4">{`PART: ${activeOrder.lineCode} ${activeOrder.partSKU}`}</Col>
						<Col md="8">{`PART AVAILABILITY (${userDetails.billTo.billToId})`}</Col>
					</Row>
				}
				show={openPopup}
				handleClose={closePopup}
				showButtons={false}
				dialogClassName="order-availability-modal"
			>
				<Row className="view-container">
					<React.Fragment>
						{partInvoiceTableData.length > 0 && partInvoiceTableColumns.length > 0 ? (
							<Col sm={12}>
								<Row className="table-row">
									<Col>
										<BootstrapTable
											keyField="specDesc"
											data={partInvoiceTableData}
											columns={partInvoiceTableColumns}
											wrapperClasses="order-table-responsive"
											// rowClasses="custom-row-class"
                      rowClasses={(row, rowIndex) => {
                        if(!fromMarket && rowIndex === 0) {
                          return ['table-primary']
                        }
                      }}
										/>
									</Col>
								</Row>
							</Col>
						) : (
							<div className="no-data">
								<div>No Data</div>
							</div>
						)}
					</React.Fragment>
				</Row>
			</XModal>
		</React.Fragment>
	);
};

export default OrderAvailabilityPopUp;
