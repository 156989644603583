import React from "react";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import TableViewWrapper from "./tableview";
import PaginationComponent from "../shared/Pagination";
import { setDefalutProductQuantity, getExtendedTotalPrice } from "../../helpers/utils"; // Adjust the path to your utility functions
import {
  Image,
  Container,
  Row,
  Col,
  Button,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
  Form,
  Spinner,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";

const defaultSepartionSingleView = (rowsdata) => {
  let rowArray = [];
  rowsdata.map((part, index) => {
    rowArray.push(part);
  });

  return rowArray;
};

const externalrenderNoDataState = (isPartsNotFound) => {
  if(isPartsNotFound) {
    return (
      <div className="partnotfound-table">
      <Card className="text-center">
        <Card.Header className="xlp-primary-banner">No Parts Found</Card.Header>
        <Card.Body>
          <Card.Title>
            We couldn't find the parts that you were looking for
          </Card.Title>
        </Card.Body>
      </Card>
      </div>
    );
  } 
};


export const prepareTablePartsDataFromUtil = (props, state, separtionCodeForBrandAndParType = () => {}, renderNoDataState = () => {}, handlePageChange = () => {}, isCatalog=true, isForCandK=false) => { 
  const {
    dispatch,
    laborGuideData,
    selectedGroupDetails,
    selectedVehicle,
    userDetails,
    laborApiCall,
    catalogPageNumber,
    catalogTotalPage,
    isPartsNotFound
  } = props;

  const {
    buyersGuideBrandData,
    buyersGuideData,
    partsAllDetails,
    partsQtySum = {},
    selectedStore,
    sellPartnerId,
    shipMethod,
  } = state;

  let defualtTotalQuantity = setDefalutProductQuantity();

  if (!_isEmpty(partsAllDetails)) {
    let productArray = [];
    let rows = [];
    let rowsMoreDetails = [];
    let autofocusIndexNumber = 0;
    let iterator = true;

    if (partsAllDetails.length > 0) {
      partsAllDetails.map((magicValue, index) => {
        if (iterator) {
          autofocusIndexNumber = index;
          iterator = false;
        }
      });
      partsAllDetails.map((part, index) => {
        let appendPart =
          (part.alternate && part.alternate.length > 0) ||
          (part.substitute && part.substitute.length > 0);
        let altPartNumsArr = [];
        let subPartNumsArr = [];
        const altPartNums =
          part.alternate && part.alternate.length > 0
            ? part.alternate.map((alt) => {
                altPartNumsArr.push(alt.partNumber);
              })
            : "";
        const subPartNums =
          part.substitute && part.substitute.length > 0
            ? part.substitute.map((sub) => {
                subPartNumsArr.push(sub.partNumber);
              })
            : "";
        rows.push({
          availability: 1,
          part: part,
          description: part.description,
          sortAvailability:
            part.pricingAvailability && part.pricingAvailability.availability,
          brand: part.mfgName,
          links: "link",
          modifiedcostPrice:
            part.pricingAvailability && part.pricingAvailability.costPrice,
          modifiedBrandName: part.mfgName,
          yourprice:
            part.pricingAvailability && part.pricingAvailability.costPrice,
          listprice:
            part.pricingAvailability && part.pricingAvailability.listPrice,
          corePrice:
            part.pricingAvailability && part.pricingAvailability.corePrice,
          coreListPrice:
            part.pricingAvailability && part.pricingAvailability.coreListPrice,
          extendedPrice: defualtTotalQuantity
            ? getExtendedTotalPrice(
                part.pricingAvailability.corePrice,
                part.pricingAvailability.costPrice,
                part.perCarQty
              )
            : 0,
          qtypervehicle: part.perCarQty,
          qtyBuyInc: part.pricingAvailability.qtyBuyInc,
          availabilities: [],
          hiddenAvail: false,
          validationRed: false,
          totalAvailable: 0,
          autoFocus:
            index === autofocusIndexNumber &&
            part.pricingAvailability.qtyBuyInc > 1
              ? true
              : false,
          qtytoorder: defualtTotalQuantity
            ? part.pricingAvailability.qtyBuyInc > 1
              ? part.pricingAvailability.qtyBuyInc
              : part.perCarQty
            : "",
          rowMoreInfo: {
            buyersGuideData: buyersGuideData,
            buyersGuideBrandData: buyersGuideBrandData,
            selectedVehicle: selectedVehicle,
            laborGuideData: laborGuideData,
            partDetails: part,
            partType: "part",
            dispatch: dispatch,
            selectedGroupDetails: selectedGroupDetails,
            partsQtySum: partsQtySum[part.partNum],
            userDetails: userDetails,
            laborApiCall: laborApiCall,
            appendPart: appendPart,
            altPartNums: altPartNumsArr.join(","),
            subPartNums: subPartNumsArr.join(","),
          },
        });

        rowsMoreDetails.push({
          buyersGuideData: buyersGuideData,
          buyersGuideBrandData: buyersGuideBrandData,
          selectedVehicle: selectedVehicle,
          laborGuideData: laborGuideData,
          partDetails: part,
          partType: "part",
          dispatch: dispatch,
          selectedGroupDetails: selectedGroupDetails,
          partsQtySum: partsQtySum[part.partNum],
          userDetails: userDetails,
          laborApiCall: laborApiCall,
          appendPart: appendPart,
          altPartNums: altPartNumsArr.join(","),
          subPartNums: subPartNumsArr.join(","),
        });

        // alternate parts
        if (part.alternate && part.alternate.length > 0) {
          part.alternate.map((altPart, index) => {
            let customAltpart = _cloneDeep(altPart);
            customAltpart["extendedDescription"] = part["extendedDescription"];
            customAltpart.modifiedcostPrice = part.pricingAvailability.costPrice;
            customAltpart.modifiedBrandName = part.mfgName;
            customAltpart.sortAvailability = part.pricingAvailability.availability;
            let appendAltPart =
              index !== part.alternate.length - 1 ||
              (part.substitute && part.substitute.length > 0);
            rows.push({
              availability: 1,
              part: customAltpart,
              sortAvailability: customAltpart.sortAvailability,
              extendedDescription: customAltpart.extendedDescription,
              description: altPart.description,
              brand: customAltpart.mfgName,
              links: "",
              modifiedcostPrice: customAltpart.modifiedcostPrice,
              modifiedBrandName: customAltpart.modifiedBrandName,
              yourprice:
                altPart.pricingAvailability &&
                altPart.pricingAvailability.costPrice,
              listprice:
                altPart.pricingAvailability &&
                altPart.pricingAvailability.listPrice,
              corePrice:
                altPart.pricingAvailability &&
                altPart.pricingAvailability.corePrice,
              coreListPrice:
                altPart.pricingAvailability &&
                altPart.pricingAvailability.coreListPrice,
              extendedPrice: defualtTotalQuantity
                ? getExtendedTotalPrice(
                    altPart.pricingAvailability.corePrice,
                    altPart.pricingAvailability.costPrice,
                    altPart.perCarQty
                  )
                : 0,
              qtypervehicle: altPart.perCarQty,
              qtyBuyInc: altPart.pricingAvailability.qtyBuyInc,
              buyersGuideData: buyersGuideData,
              availabilities: [],
              hiddenAvail: false,
              validationRed: false,
              totalAvailable: 0,
              qtytoorder: defualtTotalQuantity
                ? altPart.pricingAvailability.qtyBuyInc > 1
                  ? altPart.pricingAvailability.qtyBuyInc
                  : altPart.perCarQty
                : "",
              rowMoreInfo: {
                buyersGuideData: buyersGuideData,
                buyersGuideBrandData: buyersGuideBrandData,
                selectedVehicle: selectedVehicle,
                laborGuideData: laborGuideData,
                partDetails: altPart,
                isAltType: true,
                partType: "part",
                dispatch: dispatch,
                selectedGroupDetails: selectedGroupDetails,
                partsQtySum: partsQtySum[altPart.partNum],
                userDetails: userDetails,
                laborApiCall: laborApiCall,
                altPartNums: part.partNumber,
                appendPart: appendPart,
              },
            });
            rowsMoreDetails.push({
              buyersGuideData: buyersGuideData,
              buyersGuideBrandData: buyersGuideBrandData,
              selectedVehicle: selectedVehicle,
              laborGuideData: laborGuideData,
              partDetails: customAltpart,
              isAltType: true,
              partType: "part",
              dispatch: dispatch,
              selectedGroupDetails: selectedGroupDetails,
              partsQtySum: partsQtySum[altPart.partNum],
              userDetails: userDetails,
              laborApiCall: laborApiCall,
              altPartNums: part.partNumber,
              appendPart: appendPart,
            });
          });
        }

        // substitute parts
        if (part.substitute && part.substitute.length > 0) {
          part.substitute.map((subPart, index) => {
            let customAltpart = _cloneDeep(subPart);
            customAltpart["extendedDescription"] = part["extendedDescription"];
            customAltpart.modifiedcostPrice = part.pricingAvailability.costPrice;
            customAltpart.modifiedBrandName = part.mfgName;
            customAltpart.sortAvailability = part.pricingAvailability.availability;
            let appendSubPart = index !== part.substitute.length - 1;
            rows.push({
              availability: 1,
              sortAvailability: customAltpart.sortAvailability,
              extendedDescription: customAltpart.extendedDescription,
              part: customAltpart,
              description: subPart.description,
              brand: customAltpart.mfgName,
              links: "",
              modifiedcostPrice: customAltpart.modifiedcostPrice,
              modifiedBrandName: customAltpart.modifiedBrandName,
              coreListPrice:
                subPart.pricingAvailability &&
                subPart.pricingAvailability.coreListPrice,
              corePrice:
                subPart.pricingAvailability &&
                subPart.pricingAvailability.corePrice,
              yourprice:
                subPart.pricingAvailability &&
                subPart.pricingAvailability.costPrice,
              listprice:
                subPart.pricingAvailability &&
                subPart.pricingAvailability.listPrice,
              extendedPrice: defualtTotalQuantity
                ? getExtendedTotalPrice(
                    subPart.pricingAvailability.corePrice,
                    subPart.pricingAvailability.costPrice,
                    subPart.perCarQty
                  )
                : 0,
              qtypervehicle: subPart.perCarQty,
              qtyBuyInc: subPart.pricingAvailability.qtyBuyInc,
              buyersGuideData: buyersGuideData,
              availabilities: [],
              showMoreAvailability: false,
              hiddenAvail: false,
              validationRed: false,
              totalAvailable: 0,
              qtytoorder: defualtTotalQuantity
                ? subPart.pricingAvailability.qtyBuyInc > 1
                  ? subPart.pricingAvailability.qtyBuyInc
                  : subPart.perCarQty
                : "",
              rowMoreInfo: {
                buyersGuideData: buyersGuideData,
                buyersGuideBrandData: buyersGuideBrandData,
                selectedVehicle: selectedVehicle,
                laborGuideData: laborGuideData,
                partDetails: subPart,
                isSubType: true,
                partType: "part",
                dispatch: dispatch,
                selectedGroupDetails: selectedGroupDetails,
                partsQtySum: partsQtySum[subPart.partNum],
                userDetails: userDetails,
                laborApiCall: laborApiCall,
                subPartNums: part.partNumber,
                appendPart: appendPart,
              },
            });
            rowsMoreDetails.push({
              buyersGuideData: buyersGuideData,
              buyersGuideBrandData: buyersGuideBrandData,
              selectedVehicle: selectedVehicle,
              laborGuideData: laborGuideData,
              partDetails: customAltpart,
              isSubType: true,
              partType: "part",
              dispatch: dispatch,
              selectedGroupDetails: selectedGroupDetails,
              partsQtySum: partsQtySum[subPart.partNum],
              userDetails: userDetails,
              laborApiCall: laborApiCall,
              subPartNums: part.partNumber,
              appendPart: appendPart,
            });
          });
        }
      });

      let partTypeAndBrandSepartion = isCatalog ? separtionCodeForBrandAndParType(rows) : defaultSepartionSingleView(rows);

      productArray.push(
        <TableViewWrapper
          userDetails={userDetails}
          rows={partTypeAndBrandSepartion}
          rowsMoreDetails={rowsMoreDetails}
          selectedStore={selectedStore}
          sellPartnerId={sellPartnerId}
          shipMethod={shipMethod}
          isForCandK={isForCandK}
        />
      );

      productArray.push(
        <PaginationComponent
          handlePageChange={handlePageChange}
          currentPage={catalogPageNumber}
          totalPages={catalogTotalPage}
        />
      );
    } else {
      productArray.push(isCatalog ? renderNoDataState() : externalrenderNoDataState(isPartsNotFound));
    }
    return productArray;
  } else {
    return isCatalog ? renderNoDataState() : externalrenderNoDataState(isPartsNotFound);
  }
};
