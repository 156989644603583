import React from "react";
import { Card } from 'react-bootstrap';

const NoDataState = (props) => {


	return (
		<Card className="text-center">
			<Card.Header className="xlp-primary-banner">
				No Data Found
			</Card.Header>
			<Card.Body>
				<Card.Title>
				We couldn't find the parts that you were looking for
				</Card.Title>
			</Card.Body>
		</Card>
	);
}
export default NoDataState;
