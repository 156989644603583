import React from "react";
import ReactLoading from "react-loading";

import "./styles.scss";

const ReportCard = (props) => {
  const { isLoading, head, subHead, children } = props;

  return (
    <div className="report-card">
      <div className="rcard-title">
        <div>
          {head} {subHead && <span className="sub-text">{subHead}</span>}
        </div>
        {isLoading && <ReactLoading type="spin" height={32} width={32} />}
      </div>
      <div className="rcard-content">{children}</div>
    </div>
  );
};

export default ReportCard;
