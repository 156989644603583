import { Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "ui-library";

const AccountToggle = (props) => {
  let { handleDSW } = props;
  const user = useSelector((state) => state.user);
  const userAdditionInfo = useSelector((state) => state.userManagement);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accounts, setAccounts] = useState(null);
  const open = Boolean(anchorEl);

  const userDetails = user.userDetails;

  useEffect(() => {
    if (
      userAdditionInfo &&
      userAdditionInfo.accounts &&
      userAdditionInfo.accounts.length > 0
    ) {
      const currentBillToId = userDetails.billTo.billToId;
      let _accounts = userAdditionInfo.accounts.map((account) => {
        if (account.customerId !== currentBillToId) {
          return account;
        } else {
          return undefined;
        }
      });
      _accounts = _accounts.filter((a) => a);
      setAccounts(_accounts);
    } else {
      setAccounts([]);
    }
  }, [userAdditionInfo.accounts]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    handleDSW(event);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{whiteSpace:"nowrap"}}
      >
        Switch Account
        <span class="material-icons">keyboard_arrow_down</span>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {accounts &&
          accounts.map((account, i) => {
            return (
              <MenuItem onClick={handleClose}>
                <div key={`${account.customerId}-${i}`}>
                  {account.customerId} - {account.customerName}
                </div>
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};

export default AccountToggle;
