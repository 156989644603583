import React, { useEffect, useState } from "react";
import { Form, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import _ from "lodash";
import _isEmpty from "lodash/isEmpty";

function PartTypes(props) {
  const { partTypeSelectionData } = props;
  const dispatch = useDispatch();
  const parts = useSelector((state) => state.parts);
  const selectedPartTypes = parts && parts.homePagePartTypes;
  const selectedPartData = parts && parts.selectedCatalogPartTypes;
  const [partTypes, SetPartType] = useState([]);
  const [toggle, SetToggle] = useState(false);
  const contentWrapper = React.useRef([]);

  useEffect(() => {
    if(typeof selectedPartTypes !== "undefined") {
      let data = _.cloneDeep(selectedPartTypes);
      SetPartType([...data]);
    }
  }, [selectedPartTypes]);

  const handlePartTypeCheck = (e, group, partType) => {
    let data = _.cloneDeep(selectedPartTypes);
    let selectedPart = _.cloneDeep(selectedPartData);
    let checked = e.target.checked;
    let matchingPartType = data.find((item) => {
      return item.code === group.code;
    });

    matchingPartType &&
      matchingPartType?.partTypes.map((part) => {
        if (part.id === partType.id) {
          if (checked) {
            part.ptSelected = true;
          } else {
            matchingPartType.selectAll = false;
            part.ptSelected = false;
            _.remove(selectedPart, (sp) => sp.id === part.id);
          }
        }
      });

    let findcheckBox =
      matchingPartType &&
      matchingPartType.partTypes.filter((v) => v.ptSelected);
    findcheckBox &&
      findcheckBox.map((part) => {
        selectedPart.push(part);
      });
    if (
      !_.isEmpty(findcheckBox) &&
      checked &&
      findcheckBox.length === matchingPartType?.partTypes.length
    ) {
      matchingPartType.selectAll = true;
    }
    SetPartType([...data]);
    props.PayloadData(selectedPart);
    props.PartTypePayloadData(data);
  };

  const togglePartType = (e, group) => {
    let partData = _.cloneDeep(selectedPartTypes);
    let matchingPartType = partData.find((data) => {
      return data.code === group.code;
    });
    matchingPartType.active = !matchingPartType.active;
    handleSetPayloadData(partData);
    SetPartType([...partData]);
  };

  const handleSetPayloadData =(data)=>{
    dispatch({
		  payload: {
		  homePagePartTypes: data,
		  },
		  type: 'HOME_PAGE_PART_TYPES',
		});

  }

  const SelectAllPartTypes = (e, group) => {
    let data = _.cloneDeep(selectedPartTypes);
    let selectedPart = _.cloneDeep(selectedPartData);
    let checked = e.target.checked;
    let matchingPartType = data.find((data) => {
      return data.code === group.code;
    });
    if (checked) {
      matchingPartType.isSelected = true;
      matchingPartType.selectAll = true;
      matchingPartType.partTypes.map((v) => {
        v.ptSelected = true;
        selectedPart.push(v);
      });
    } else if (!checked) {
      matchingPartType.selectAll = false;
      matchingPartType.partTypes.map((v) => {
        v.ptSelected = false;
        _.remove(selectedPart, (sp) => sp.id == v.id);
      });
    }
    SetPartType([...data]);
    props.PayloadData(selectedPart);
    props.PartTypePayloadData(data);
  };

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const renderPartTypes = () => {
    return partTypes.map((group, cIndex) => {
      return (
        ((props.categoryId !== undefined &&
          props.categoryId === group.categoryId) ||
          props.ClassicView === "ClassicView") &&
          partTypeSelectionData.selectedGroups && !_.isEmpty(partTypeSelectionData.selectedGroups) && partTypeSelectionData.selectedGroups.some(item => item.code === group.code && item.selected) &&
          group.isSelected && 
          group.selected && (  
          <Accordion defaultActiveKey="0" className="parttypeaccordian">
            <div className="classicListWrapper">
              <Accordion.Toggle
                as="div"
                className="accordionHead"
                variant="link"
                eventKey="0"
                onClick={(e) => {
                  SetToggle(!toggle);
                  togglePartType(e, group);
                }}
              >
                <div
                  class={`classicListWrapperHead ${
                    group.active ? "expanded" : "collapsed"
                  }`}
                >
                  {group.name}
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <div className="parttype-content">
                  <div
                    className={
                      "classicListWrapperBody" +
                      " " +
                      group.name.replace(/ +/g, "")
                    }
                    ref={(el) =>
                      (contentWrapper.current[group.name.replace(/ +/g, "")] =
                        el)
                    }
                  >
                    {!_.isEmpty(group.partTypes) && group.partTypes.length > 5 && (
                      <div className="partypearrowwrapper">
                        <a
                          onClick={() =>
                            sideScroll(
                              contentWrapper.current[
                                group.name.replace(/ +/g, "")
                              ],
                              21,
                              100,
                              -10
                            )
                          }
                          className="arrow-left"
                        ></a>
                      </div>
                    )}
                    {!_.isEmpty(group.partTypes) && (
                      <Form.Group>
                        <strong>
                          <Form.Check
                            type="checkbox"
                            className="select_all_check"
                            checked={group && group.selectAll}
                            onChange={(e) => {
                              SelectAllPartTypes(e, group);
                            }}
                            label="Select All Part Types"
                          />
                        </strong>
                      </Form.Group>
                    )}
                    {!_.isEmpty(group.partTypes) ? (
                      !_.isEmpty(group.partTypes) &&
                      group.partTypes.map((gr, gIndex) => {
                        return (
                          <Form.Group controlId={`GroupsCheckbox${gr.id}`}>
                            <Form.Check
                              className="other_checks"
                              type="checkbox"
                              label={gr.text}
                              checked={gr && gr.ptSelected}
                              onClick={(e) => handlePartTypeCheck(e, group, gr)}
                            />
                          </Form.Group>
                        );
                      })
                    ) : (
                      <div class={`classicListWrapperHeadForNoData`}>
                        Not Available
                      </div>
                    )}
                    {!_.isEmpty(group.partTypes) && group.partTypes.length > 5 && (
                      <div className="partypearrowwrapper">
                        <a
                          onClick={() =>
                            sideScroll(
                              contentWrapper.current[
                                group.name.replace(/ +/g, "")
                              ],
                              21,
                              100,
                              10
                            )
                          }
                          className="arrow-right"
                        ></a>
                      </div>
                    )}
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        )
      );
    });
  };

  return (
    <div className="classicPartTypes">
    <div className="classicMainHead scrolltopartypesheader">Part Types</div>
     {!_.isEmpty(partTypes) &&
    partTypes !== undefined &&
    partTypes.some((group) => group.isSelected && (props.categoryId === group.categoryId  ||
    props.ClassicView === "ClassicView"))
    && (
        <Accordion defaultActiveKey="0">
          <div className="classicListWrapper">{renderPartTypes()}</div>
        </Accordion>
    )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    categoryMap: state.parts.categoryGroupMap,
    categoryList: state.parts.categoryList,
  };
}

export default connect(mapStateToProps)(PartTypes);
