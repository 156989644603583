/* eslint-disable react-hooks/exhaustive-deps */
import T from "prop-types";
import React, { useEffect, useState } from "react";
import OEMLayout from "./layout";

// import styles
import "./styles.scss";
import OEMFullView from "./fullView";
import OEMCompactView from "./compactView";
import { Checkbox, FormControlLabel, FormGroup, Switch } from "@mui/material";
import _ from "lodash";

import { getPreferenceDataFromStorage } from "helpers/utils";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";

const USED = "UsedPowertrain";
const REMAN = "RemanPowertrain";

export const CK_PartSearch = (props) => {
  const [view, setView] = useState(false);
  const [filter, setFilter] = useState("both");
  const [showRemanParts, setShowRemanParts] = useState(true);
  const [showUsedParts, setShowUsedParts] = useState(true);
  const [usedParts, setUsedParts] = useState([]);
  const [remanParts, setRemanParts] = useState([]);
  const [groups, setGroups] = useState({});

  const candk = useSelector((state) => state.candk);

  const handleViewChange = () => {
    setView(!view);
  };

  const handleRemanParts = () => {
    setShowRemanParts(!showRemanParts);
  };

  const handleUsedParts = () => {
    setShowUsedParts(!showUsedParts);
  };

  const setDefaultPreference = (code, text) => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let selectedFilter = {};
    if (!_isEmpty(userPreferenceDetail)) {
      selectedFilter = _find(userPreferenceDetail, {
        code: code,
        text: text,
      });
    }
    return (
      selectedFilter && selectedFilter.answer && selectedFilter.answer.code
    );
  };

  useEffect(() => {
    let selectedViewType = setDefaultPreference(
      "Product Information",
      "Presentation Mode"
    );
    if (selectedViewType === "full") {
      setView(true);
    } else {
      setView(false);
    }
  }, []);

  useEffect(() => {
    if (showRemanParts && showUsedParts) {
      setFilter("both");
      const usedParts = _.groupBy(candk.parts, (d) => d.partCatalog === USED);
      const remanParts = _.groupBy(candk.parts, (d) => d.partCatalog === REMAN);

      setGroups({ [USED]: usedParts, [REMAN]: remanParts });
    } else if (showRemanParts && !showUsedParts) {
      const gData = _.groupBy(candk.parts, (d) => d.partCatalog);
      const filteredData = { ...gData.RemanPowerTrain, UsedPowerTrain: [] };
      setGroups(filteredData);
      setFilter("reman");
    } else if (!showRemanParts && showUsedParts) {
      const gData = _.groupBy(candk.parts, (d) => d.partCatalog);
      const filteredData = { ...gData.RemanPowerTrain, RemanPowerTrain: [] };
      setGroups(filteredData);
      setFilter("used");
    } else {
      setFilter(null);
    }
  }, [showRemanParts, showUsedParts]);

  useEffect(() => {
    const gData = _.groupBy(candk.parts, (d) => d.partCatalog);
    setUsedParts(gData.UsedPowertrain || []);
    setRemanParts(gData.RemanPowertrain || []);
  }, [candk.parts]);

  console.log("type ==> ", showRemanParts, showUsedParts);

  return (
    <OEMLayout>
      {candk.isLoadingComplete && (
        <div className="oem-action-row">
          <div className="oem-switch-view">
            <OverlayTrigger
              placement="right"
              delay={{ show: 100, hide: 400 }}
              overlay={
                <Tooltip id="toggle-view-ck-compact">
                  {`${
                    view
                      ? 'You are in "Full View", clicking on the button will switch to "Compact View"'
                      : 'You are in "Compact View", clicking on the button will switch to "Full View"'
                  }`}
                </Tooltip>
              }
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={view}
                      onChange={handleViewChange}
                      name="full-view"
                    />
                  }
                  label="Full View"
                />
              </FormGroup>
            </OverlayTrigger>
          </div>
          <div className="oem-filter">
            <div className="oem-filter-title bold">Filter: </div>
            <FormGroup className="oem-filter-group">
              <FormControlLabel
                className="oem-filter-group-label"
                control={
                  <Checkbox
                    checked={showRemanParts}
                    onChange={handleRemanParts}
                  />
                }
                label={`Reman Parts (${remanParts.length})`}
              />
              <FormControlLabel
                className="oem-filter-group-label"
                control={
                  <Checkbox
                    checked={showUsedParts}
                    onChange={handleUsedParts}
                  />
                }
                label={`Used Parts (${usedParts.length})`}
              />
            </FormGroup>
          </div>
        </div>
      )}
      {view ? (
        <OEMFullView filter={filter} items={candk.parts} />
      ) : (
        <OEMCompactView filter={filter} items={candk.parts} />
      )}
    </OEMLayout>
  );
};

CK_PartSearch.propTypes = {};

export default CK_PartSearch;
