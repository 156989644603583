import React from 'react';
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {
	Form,
	Container,
	Button,
	OverlayTrigger,
	Tooltip,
	Spinner,
	Row,
	Col
} from 'react-bootstrap';
import { connect } from 'react-redux';
import {
	getChemicalCategories,
	getSuppliesCategoryByBrand,
	getChemPartByCat,
	getBrandByCategory,
	getChemPartByGrp,
} from '../../actions/parts';
import { Link, Redirect } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import clsx from 'clsx';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _flatten from 'lodash/flatten';

import ClassicChemicalCategoryWrapper from './Classic';
import CategoryCarousel from '../Homepage/Categories/CategoryCarousel';
import BannerSection from './BannerSection';

import BrandToggleIcon from '../../static/images/BrandToggle.png';
import CategoryToggleIcon from '../../static/images/CategoryToggle.png';
import PromotionsTab from '../shared/Promotions';
import {getChemicalsPreferredBrands, getDefaultDataBasedOnChemicalUSerPref, getPreferenceDataFromStorage, getDefaultPreference,timeInUTC,getCartShipDetails,setCookie,getCookie} from '../../helpers/utils';
import _cloneDeep from 'lodash/cloneDeep';
import { getMagicalWordsList } from "components/StockCheck/stockcheck.thunk";
import MagicWord from "components/StockCheck/MagicWord";
import { addSearchedValue } from '../../components/Homepage/Search/globalSearch.thunk';
import { logUserStockActivity } from "../../actions/user";

const actionTypes = { SEARCHED_ADDTOCART_DATA: 'SEARCHED_ADDTOCART_DATA' };

class ChemicalsAndSupplies extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			brandsView: false,
			categoryList: [],
			redirectToProductList: false,
			selectedCategoryId: '',
			selectedGroupDetails: [],
			viewPromotions: false,
			showClassicView: false,
			userPrefView: '',
		};
	}

	componentDidMount() {
		const { dispatch, userDetails } = this.props;
		const { brandsView } = this.state;
		if (_isEmpty(this.props.categoryMap)) {
			getChemicalCategories(dispatch);
		}
		if (_isEmpty(this.props.brandsCategoryMap)) {
			getSuppliesCategoryByBrand(dispatch);
		}
		dispatch(getMagicalWordsList());
		dispatch(getBrandByCategory());
		const userPreferenceDetail = getPreferenceDataFromStorage();
		if (!_isEmpty(userPreferenceDetail)) {
			let selectedViewType = {};
			selectedViewType = _find(userPreferenceDetail, {
				code: 'Chemicals Catalog',
				text: 'Presentation Mode'
			});
			let presentionMode = getDefaultPreference('Chemicals Catalog', 'Presentation Mode');
			let selectedBrandorCategoryViewType = {};
			selectedBrandorCategoryViewType = _find(
				userPreferenceDetail,
				{
					code: 'Chemicals Catalog',
					text: 'Shopping Method'
				}
			);
			const userPreferenceBrandorCategoryView =
        selectedBrandorCategoryViewType &&
        selectedBrandorCategoryViewType.answer &&
        selectedBrandorCategoryViewType.answer.code &&
        selectedBrandorCategoryViewType.answer.code;
      let userPreferenceView =
        (selectedViewType &&
          selectedViewType.answer &&
          selectedViewType.answer.code &&
          selectedViewType.answer.code) ||
        null;
      let possibleAnswerData =
        selectedViewType.possibleAnswer && selectedViewType.possibleAnswer;
      userPreferenceView =
        userPreferenceView == null
          ? possibleAnswerData && possibleAnswerData.find((v) => v.default).code
          : userPreferenceView;
      this.setState({
        showClassicView: userPreferenceView === "classic",
        showIllustratedView: userPreferenceView === "illustrated",
        userPrefView: userPreferenceView,
        brandsView:
          userPreferenceBrandorCategoryView == "brand" ? true : brandsView,
      });
    }

	const viewPromotions = getCookie('viewPromotions') || "true";
    if(viewPromotions === "false") {
      dispatch({
        payload: {},
        type: "COLLAPSE_PROMOTIONS_TAB",
      });
    }
  }

	componentDidUpdate(prevProps) {
		if (!_isEqual(this.props, prevProps)) {
			const userPreferenceDetail = getPreferenceDataFromStorage();
			if (!_isEqual(userPreferenceDetail)) {
				if (
					!_isEmpty(userPreferenceDetail)) {
					let selectedViewType = {};
					selectedViewType = _find(
						userPreferenceDetail,
						{
							code: 'Chemicals Catalog',
							text: 'Presentation Mode'
						}
					);
					let presentionMode = getDefaultPreference('Chemicals Catalog', 'Presentation Mode');
					let selectedBrandorCategoryViewType = {};
					selectedBrandorCategoryViewType = _find(
						userPreferenceDetail,
						{
							code: 'Chemicals Catalog',
							text: 'Shopping Method'
						}
					);
					const userPreferenceBrandorCategoryView =
					selectedBrandorCategoryViewType &&
					selectedBrandorCategoryViewType.answer &&
					selectedBrandorCategoryViewType.answer.code &&
					selectedBrandorCategoryViewType.answer.code;
				  let userPreferenceView =
					(selectedViewType &&
					  selectedViewType.answer &&
					  selectedViewType.answer.code &&
					  selectedViewType.answer.code) ||
					null;
				  let possibleAnswerData =
					selectedViewType.possibleAnswer && selectedViewType.possibleAnswer;
				  userPreferenceView =
					userPreferenceView == null
					  ? possibleAnswerData &&
						possibleAnswerData.find((v) => v.default).code
					  : userPreferenceView;
				  this.setState({
					showClassicView: userPreferenceView === "classic",
					showIllustratedView: userPreferenceView === "illustrated",
					userPrefView: userPreferenceView,
					brandsView:
					  userPreferenceBrandorCategoryView == "brand"
						? true
						: this.props.brandsView,
				  });
				}
			  }
			}
		
		}
	toggleView = (event) => {
		let value = event.target.value;
		let { showClassicView, selectedGroupDetails } = this.state;
		_remove(selectedGroupDetails, (p) => p);
		this.setState({
			...this.state,
			showClassicView: value === "classic",
			showIllustratedView: value === "illustrated",
			selectedGroupDetails,
		});
	};

	handleGroupClick = (e, data, category) => {
		let { selectedGroupDetails, brandsView } = this.state;
		let { dispatch } = this.props;
		if (brandsView) {
			let selectedIndex = _findIndex(selectedGroupDetails, {
				id: Number(data.id),
				brandCode: category.supplier.code,
			});
			if (selectedIndex !== -1) {
				_remove(selectedGroupDetails, {
					id: Number(data.id),
					brandCode: category.supplier.code,
				});
			} else {
				let singleItem = {
					brandCode: category.supplier.code,
					categoryId: data.id,
					name: data.name,
					id: data.id,
					pimId: data.pimID,
				};
				selectedGroupDetails.push(singleItem);
			}
		} else {
			let selectedIndex = _findIndex(selectedGroupDetails, {
				id: Number(data.id),
				categoryId: category.code
			});
			if (selectedIndex !== -1 && category.code == selectedGroupDetails[selectedIndex].categoryId) {
				_remove(selectedGroupDetails, { id: Number(data.id), categoryId: category.code });
			} else {
				let singleItem = {
					categoryId: category.code,
					name: data.name,
					id: data.id,
				};
				selectedGroupDetails.push(singleItem);
			}
		}
		this.setState({
			selectedGroupDetails,
		});
	};

	renderCategories = (categoryMap, brands = false) => {
		let { selectedGroupDetails } = this.state;
		let categoryRows = [];
		if (categoryMap && categoryMap.length) {
			categoryMap.forEach((category, index) => {
				categoryRows.push(
					<CategoryCarousel
						category={category}
						subCategories={
							brands
								? category.chemicalCategories
								: category.groups
						}
						key={category.id}
						index={index}
						handleClick={this.handleGroupClick}
						selectedGroupDetails={selectedGroupDetails}
						// selectedGroupCode={selectedGroupDetails.id}
						// selectedCategoryId={selectedGroupDetails.brandId}
						brandsView={brands}
					/>
				);
			});
		}
		return categoryRows;
	};

	getCartMoreInfo = () => {
		let {userDetails} = this.props;
		let cartData = [];
		let storesData = [];
		let defaultStore = this.props.allStores && this.props.allStores.defaultStore ? this.props.allStores.defaultStore : "";
		let userData = this.props.userDetails && this.props.userDetails.shipTos && this.props.userDetails.shipTos[0] && this.props.userDetails.shipTos[0].location && this.props.userDetails.shipTos[0].location;
		if(this.props.cartStore && this.props.cartStore != null && this.props.allStores && this.props.allStores.pickupStores && this.props.cartShipMethod === '0002') {
			storesData = _cloneDeep(this.props.allStores.pickupStores);
			storesData.push(defaultStore);
			cartData = _find(storesData, { storeNumber: this.props.cartStore})
		}
		if(this.props.cartStore && this.props.cartStore != null && this.props.allStores && this.props.allStores.deliveryStores && this.props.cartShipMethod != '0002') {
		    storesData = _cloneDeep(this.props.allStores.deliveryStores);
			storesData.push(defaultStore);
			cartData = _find(storesData, { storeNumber: this.props.cartStore})
		}
		let sellPartnerId =  this.props.sellerPartnerId && this.props.sellerPartnerId != null ?  this.props.sellerPartnerId : (!_isEmpty(cartData) ? cartData.sellerPartnerId : !_isEmpty(userData) && userData.sellerPartnerId);
		let selectedStoreName = !_isEmpty(cartData) ? cartData.storeName : !_isEmpty(userData) && userData.storeName;    
		let cartStore = this.props.cartStore && this.props.cartStore != null ? this.props.cartStore : (!_isEmpty(cartData) ? cartData.storeNumber : !_isEmpty(userData) && userData.storeNumber);
		let cartShipMethod = this.props.cartShipMethod ? this.props.cartShipMethod  : (this.props.userDetails.shipTos && this.props.userDetails.shipTos[0] && this.props.userDetails.shipTos[0].shipMethod && this.props.userDetails.shipTos[0].shipMethod ? this.props.userDetails.shipTos[0].shipMethod : "");   
		return {
		  sellPartnerId : sellPartnerId,
		  selectedStoreName :  selectedStoreName,
		  store: cartStore,
		  cartShipMethod : cartShipMethod
		}
	  }

	handleDisplayProduct = () => {
		let { selectedGroupDetails, brandsView } = this.state;
		let { categoryMap, userDetails, dispatch,checkoutDetails } = this.props;
		const selectedData =this.props.selectedGroupDetails;
		dispatch({
			payload: {
				selectedGroupDetails,
			},
			type: 'SUPPLIES_GROUP_SELECTED',
		});
		let sellPartnerId = getCartShipDetails(userDetails, checkoutDetails).sellerPartnerId;
		let store = getCartShipDetails(userDetails, checkoutDetails).storeNumber;
		let shipMethd =getCartShipDetails(userDetails, checkoutDetails).shipMethod;
	 //for brand filter default all selection
	 dispatch({
		payload: {
		  bool: true,
		},
		type: "CHEMICAL_CATEGORY_CHANGED",
	  });
	  const userPreferenceDetail = getPreferenceDataFromStorage();
	  let userPrefDetail =
		getDefaultDataBasedOnChemicalUSerPref(userPreferenceDetail);
	  const prefBrands = getChemicalsPreferredBrands();
	  console.log("user pref ==> ", userPreferenceDetail, prefBrands);
	  console.log("prefBrands ==> ", prefBrands);

	  const brands = prefBrands.multiAnswer.map((b) => {
		if (typeof b === "string") {
		  return b;
		} else {
		  return b.text;
		}
	  });
	  
		if (brandsView) {
			let chemCats = selectedGroupDetails.map((s) =>
				_find(categoryMap, { code: Number(s.categoryId) })
			);
			let selgrps = _flatten(_map(chemCats, 'groups'));
			dispatch(
				getChemPartByCat(
					selectedGroupDetails.map((s) => s.pimId),
					selectedGroupDetails.map((s) => s.brandCode),
					_map(selgrps, 'id'),
					userPrefDetail.pageNumber,
					userPrefDetail.pageSize,
					userPrefDetail.catalogSortBy,
					userPrefDetail.catalogOrder,
					null,
					null,
					null,
					null,
					null,
					null,
					sellPartnerId,
                    store,
                    shipMethd,
					{},
					false
				)
			);
			dispatch({
				payload: {
					selectedGroupDetails: selgrps,
				},
				type: 'SUPPLIES_GROUP_SELECTED',
			});
			dispatch({
				payload: {
					brandsView: true,
				},
				type: 'SUPPLIES_BRAND_VIEW',
			});
		} else {
			let groupIds = selectedGroupDetails.map((single) => single.id);
			setTimeout(async()=>{
				try{
				  const response = await dispatch(getChemPartByGrp(
				groupIds,
				userPrefDetail.pageNumber,
				userPrefDetail.pageSize,
				userPrefDetail.catalogSortBy,
				userPrefDetail.catalogOrder,
				brands,
			    null,
				null,
				null,
				null,
				null,
				[],
				sellPartnerId,
                store,
                shipMethd,
				{}
				))
				const totalItems = response?.data?.totalItems || 0;
				setTimeout(()=>{
				  if (totalItems <= 0) {
					this.handleSearchError(JSON.stringify(selectedData),"resultNotFound");
				}else{
					this.props.dispatch(addSearchedValue(selectedData))
					// this.isSearchValueAddedToCart(this.state.selectedGroupDetails);
				  }
				},[1000])
		
				  }
				  catch(error){
					this.handleSearchError(selectedData,"resultNotFound");
				}
				},1500);
					  
			dispatch({
				payload: {
					brandsView: false,
			},
				type: 'SUPPLIES_BRAND_VIEW',
			});
		}
		this.setState({ redirectToProductList: true });
	};
	isSearchValueAddedToCart=(option)=>{
		const searchedValues = this.props?.searchedValues ;
			if (searchedValues.length === 0 ) {
				// Handle empty arrays as needed
				return false;
			  }
		if(searchedValues.length >= 1){
			if(this.props.parts.isSearchedValuesAddedToCart){
				this.props.dispatch({
					payload: {selectedAddCartData:this.props.parts.selectedAddCartData,
	
						isSearchedValuesAddedToCart: false,
					},
					type: actionTypes.SEARCHED_ADDTOCART_DATA,})
			}else{
				this.handleSearchError(JSON.stringify(searchedValues[searchedValues.length-1]),"resultFound");
			}
		 }
	
		}
  handleSearchError = (query,resultfound) => {
    const sv= this.props.selectedVehicle;
    const parts = this.props.parts;
   const errorData = {
         source: "Chemicals - Default",
         searchvalue: query,
         resultFound: resultfound === "resultFound"? true : false,
         selectedVehicle  : sv ? `${sv?.year} ${sv?.make} ${sv?.model} ${sv?.engine}`: null,	
       filters:{
     groupCode: this.props.suppliesReqGrpIds,
     brand:this.props.brandsSelected,
     // position:parts?.catalogFilter?.position,
     availablity:this.props.suppliesReqAvailability,
 
     },

   };
   this.logUserSearchFailureActivity(errorData,resultfound)
 };
   logUserSearchFailureActivity = (storedErrors,resultfound) => {
          const { x2userName, shipTos, billTo } = this.props.userDetails;
          if (!_isEmpty(this.props.userDetails) && !_isEmpty(billTo)) {
            let loginId = "";
            let shipToId = shipTos[0]?.shipToId;
            let userData = JSON.stringify(storedErrors);
            let type=resultfound === "resultFound"? "Search Result Found - Not Added To Cart"  :"Search - No Result";
          // const {searchValue}= sourceAlreadyCalled;
            // if(searchValue !== storedErrors.searchvalue ){
            //   setSourceAlreadyCalled({source:storedErrors.source,searchValue:storedErrors.searchvalue})
            this.setState({
              sourceAlreadyCalled: {
                source: storedErrors.source,
                searchValue: storedErrors.searchvalue,
              },
            });
            
              this.setState({})
            this.props.dispatch(
            logUserStockActivity(
              timeInUTC(),
              billTo.billToId,
              loginId,
              shipToId,
              type,
              userData,
              x2userName
            )
            );
            // }
          }
   };

	handleViewToggle = () => {
		this.setState({
			brandsView: !this.state.brandsView,
			selectedGroupDetails: [],
		});
	};

	render() {
		let { categoryMap, brandsCategoryMap, smsPageLoader, userDetails, checkoutDetails } = this.props;
		// console.log(brandsCategoryMap);
		let {
			selectedGroupDetails,
			redirectToProductList,
			brandsView,
			showClassicView,
			showIllustratedView,
			userPrefView,
		  } = this.state;
		  if (redirectToProductList) {
			return (
				<Redirect
					to={{
						pathname: '/supplies-list',
					}}
				/>
			);
		}
		return (
      <div
        className={clsx("chemicalsAndSuppliesContainer", {
          "promotions-active": this.props.viewPromotions,
        })}
      >
        {smsPageLoader && (
          <div className="custom_spinner_container sms-user-spinner">
            <Spinner className="custom_spinner" animation="border" />
          </div>
        )}
        <Container className="breadcrumb__chemicals">
          <div className="bread_crum_section">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <span>Chemicals</span>
              </li>
            </ul>
          </div>
        </Container>
		<BannerSection />
        {/* <ChemicalsSuppliesListingWrapper /> */}
        <section className="section-wrapper">
          <Button
            className="display-prod-btn go-btn"
            disabled={_isEmpty(selectedGroupDetails)}
            onClick={() => this.handleDisplayProduct()}
          >
            GO
          </Button>

          <section className="section__products">
            <Container fluid className="pl-5 pr-5">
              <div className="brand_category_toggle_icon">
                <img
                  src={brandsView ? CategoryToggleIcon : BrandToggleIcon}
                  onClick={() => this.handleViewToggle()}
                  alt={brandsView ? "Brand View" : "Category View"}
                />
                <p
                  onClick={() => this.handleViewToggle()}
                  className="brand_title"
                >
                  {brandsView ? "Shop by Categories " : "Shop by Brands"}
                </p>
              </div>
			  <div class="chemical d-flex">
                <Row>
                  <Col md={6}>
                    <div>
                      <FormControl>
                        <RadioGroup
                          onChange={(e) => this.toggleView(e)}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="illustrated"
                            checked={showIllustratedView}
                            control={<Radio />}
                            label={
                              <span>
                                <b>Illustrated View</b>
                              </span>
                            }
                          />
                          <FormControlLabel
                            value="classic"
                            checked={showClassicView}
                            control={<Radio />}
                            label={
                              <span>
                                <b>Classic View</b>
                              </span>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Col>
                  <Col md={6} className="magicWord_homePage">
				  <MagicWord sourceName="chemical" 
                    shipInfoForMagic={getCartShipDetails(userDetails, checkoutDetails)}
                    checkoutDetails={checkoutDetails}                   
                    />
                  </Col>
                </Row>
              </div>


              {!showClassicView ? (
                <React.Fragment>
                  {brandsView
                    ? this.renderCategories(brandsCategoryMap, true)
                    : this.renderCategories(categoryMap)}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ClassicChemicalCategoryWrapper
                    handleClick={this.handleGroupClick}
                    selectedGroupDetails={selectedGroupDetails}
                    brandsView={brandsView}
                  />
                </React.Fragment>
              )}
            </Container>
          </section>
        </section>

        <PromotionsTab
          showSidebarPopUp={this.props.viewPromotions}
          promotions={this.props.userPromotionDetails}
          handlePromotions={() => {
			setCookie('viewPromotions', !this.props.viewPromotions, 365)
            this.props.dispatch({
              payload: {},
              type: "VIEWPROMOTIONS_TOGGLE",
            });
          }}
        />
      </div>
    );
	}
}
function mapStateToProps(state) {
	return {
		categoryMap: state.parts.categorySuppliesMap,
		brandsCategoryMap: state.parts.brandsCategoryMap,
		userPromotionDetails: state.user.userPromotionDetails,
		viewPromotions: state.app.viewPromotions,
		userDetails: state.user.userDetails,
		cartStore: state.parts.cartStore,
		cartShipMethod: state.parts.cartShipMethod,
		allStores: state.mygarage.storeDetails,
		smsPageLoader : state.app.smsPageLoader,
		sellerPartnerId: state.parts.sellerPartnerId,
		parts: state.parts,
		searchedValues: state.globalSearch.searchedValues,
    	selectedPartType: state.parts.selectedPartType,
		selectedVehicle:state.mygarage.selectedVehicle,
		suppliesSelectedGroups:state.parts.suppliesSelectedGroups,
		selectedGroupDetails: state.parts.selectedGroupDetails,
		checkoutDetails: state.cart.checkoutDetails

	

	};
}
export default connect(mapStateToProps)(ChemicalsAndSupplies);
