import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import _isEmpty from "lodash/isEmpty";
import PaginationComponent from "../../shared/Pagination";
import XModal from "../../shared/XModal";
import OrderPreview from "../../shared/OrderPreview";

import "./styles.scss";

import {
  getAllFailedOrderTxns,
  retryFailedOrderTxns,
  updateFailedOrderTxns,
} from "./timedouttxns.thunks";
import {
  triggerUxCritialErrors,
  triggerCustomUxCriticalError,
} from "../../../actions/error";
import emailUtil from "../../../helpers/email/emailUtil";

const headerStyle = {
  backgroundColor: "#9D1D20",
  color: "#FFFFFF",
  "border-color": "#9D1D20",
};

const TimedoutTransactions = () => {
  const dispatch = useDispatch();
  const cartStatus = useSelector((state) => state.cart);
  const [failedTxns, setFailedTxns] = useState([]);
  const [timedoutTxnsTableRows, setTimedoutTxnsTableRows] = useState([]);
  const [timedoutTxnsTableColumns, setTimedoutTxnsTableColumns] = useState([]);
  const [noTableData, setNoTableData] = useState(false);
  const [updateStatusModal, setUpdateStatusModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(null);
  const [totalItems, setTotalItems] = useState(null);

  const [orderStatusOptions, setOrderStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [closeTypeOptions, setCloseTypeOptions] = useState([]);
  const [selectedCloseType, setSelectedCloseType] = useState(null);

  const [showOrderPreview, setShowOrderPreview] = useState(false);

  const [selectedCartId, setSelectedCartId] = useState(null);
  const [updatedCartDetails, setUpdatedCartDetails] = useState({
    acxNo: "",
    cartId: "",
    closeType: "",
    orderNo: "",
    reason: "",
    status: "",
  });

  useEffect(() => {
    setOrderStatusOptions([
      {
        value: "CLOSED",
        label: "Closed",
      },
      {
        value: "OPEN",
        label: "Open",
      },
      {
        value: "ORDERED",
        label: "Ordered",
      },
      {
        value: "SHIPPED",
        label: "Shipped",
      },
      {
        value: "INVOICED",
        label: "Invoiced",
      },
    ]);
  }, []);

  useEffect(() => {
    setCloseTypeOptions([
      {
        value: "CANCELLED",
        label: "Cancelled",
      },
      {
        value: "PLACED_MANUALLY",
        label: "Placed Manually",
      },
      {
        value: "RETRY",
        label: "Retry",
      },
      {
        value: "OTHER",
        label: "Other",
      },
    ]);
  }, []);

  const initialGetFailedTxns = (clickedPage) => {
    let pageNum = clickedPage ? clickedPage : currentPage;
    const payload = {
      pageNo: pageNum,
      pageSize: pageSize,
      dispatch: dispatch,
    };
    try {
      const allFailedOrderTxnsAction = dispatch(getAllFailedOrderTxns(payload));
      allFailedOrderTxnsAction.then((action) => {
        setFailedTxns(action.payload.pagedItems);
        setTotalPages(action.payload.totalPages);
        setTotalItems(action.payload.totalItems);
        constructGridData(action.payload.pagedItems);
      });
    } catch (error) {}
  };

  useEffect(() => {
    initialGetFailedTxns();
  }, []);

  const resetSelections = () => {
    setTimedoutTxnsTableColumns([]);
    setTimedoutTxnsTableRows([]);
    setNoTableData(false);
    setSelectedCartId(null);
    setUpdatedCartDetails({
      acxNo: "",
      cartId: "",
      closeType: "",
      orderNo: "",
      reason: "",
      status: "",
    });
    setSelectedStatus(null);
    setSelectedCloseType(null);
  };

  const constructGridData = (allFailedOrderTxns) => {
    setNoTableData(false);
    setTimedoutTxnsTableColumns([]);
    setTimedoutTxnsTableRows([]);

    const rowData = [];
    const columns = [
      {
        dataField: "custId",
        text: "Customer Id",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "userEmail",
        text: "User Email",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "userMob",
        text: "User Mobile",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "shipTo",
        text: "Ship To",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "location",
        text: "Location",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "noOfParts",
        text: "No Of Parts",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "totalAmount",
        text: "Total Amount",
        headerStyle: headerStyle,
        align: "right",
        headerAlign: "center",
      },
      {
        dataField: "timeStamp",
        text: "Timestamp",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "cartNo",
        text: "Action",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        formatter: cartActionColFormatter,
      },
      {
        dataField: "status",
        text: "Status",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "retry",
        text: "Retry",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        formatter: retryColFormatter,
      },
    ];

    allFailedOrderTxns.map((data, index) => {
      rowData.push({
        custId: data.custId,
        userEmail: data.userId,
        userMob: data.userMobile ? data.userMobile : "",
        shipTo: data.shipToId,
        location: data.location,
        noOfParts: data.totalQty,
        totalAmount: data.totalAmount,
        timeStamp: data.timeStamp,
        cartNo: data.cartId,
        acxNo: "", // TODO
        orderNo: "", // TODO
        retry: "",
        status: data.status,
        closeType: data.closeType ? data.closeType : "",
        reason: data.reason ? data.reason : "",
      });
    });
    setTimedoutTxnsTableColumns(columns);
    setTimedoutTxnsTableRows(rowData);
    if (rowData.length == 0) {
      setNoTableData(true);
    }
  };

  const handleOpenCart = (event, row) => {
    setShowOrderPreview(true);
  };

  const handleUpdateCart = (event, row) => {
    setSelectedCartId(row.cartNo);
    setUpdateStatusModal(true);
  };

  const cartActionColFormatter = (cell, row) => {
    return (
      <span>
        <a href="#" onClick={(e) => handleOpenCart(e, row)}>
          Open Cart
        </a>
        <span> / </span>
        <a href="#" onClick={(e) => handleUpdateCart(e, row)}>
          Update Status
        </a>
      </span>
    );
  };

  const handleRetryTxn = (event, row) => {
    const payload = {
      cartId: row.cartNo,
      dispatch: dispatch,
    };
    try {
      const retryFailedOrderTxnsAction = dispatch(
        retryFailedOrderTxns(payload)
      );
      retryFailedOrderTxnsAction.then((action) => {
        if (action.payload) {
          const statusMessageProps = {
            heading: `Retry successful`,
            message: "",
            type: "success",
          };
          triggerCustomUxCriticalError(statusMessageProps, dispatch);
        }
      });
    } catch (error) {}
  };

  const retryColFormatter = (cell, row) => {
    return (
      <span>
        <Button className="retry-btn" onClick={(e) => handleRetryTxn(e, row)}>
          Retry
        </Button>
      </span>
    );
  };

  const handlePageChange = (clickedPage) => {
    setCurrentPage(clickedPage);
    initialGetFailedTxns(clickedPage);
  };

  const handleClosePopup = () => {
    resetSelections();
    setUpdateStatusModal(false);
  };

  const handleSelectChange = (selectedOption, data) => {
    let { name } = data;
    const { value, label } = selectedOption;
    updatedCartDetails[name] = value;
    if (name === "status") {
      const status = orderStatusOptions.find((item) => {
        return item.value.trim() === value.trim();
      });
      setSelectedStatus(status);
    }
    if (name === "closeType") {
      const type = closeTypeOptions.find((item) => {
        return item.value.trim() === value.trim();
      });
      setSelectedCloseType(type);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    updatedCartDetails[name] = value; // TODO: validations, enable submit button only if valid
  };

  const submitStatus = () => {
    updatedCartDetails["cartId"] = selectedCartId;
    const payload = updatedCartDetails;
    payload["dispatch"] = dispatch;
    try {
      const updateFailedOrderTxnsAction = dispatch(
        updateFailedOrderTxns(updatedCartDetails)
      );
      updateFailedOrderTxnsAction.then((action) => {
        resetSelections();
        initialGetFailedTxns();
        setUpdateStatusModal(false);
      });
    } catch (error) {}
  };

  return (
    <div className="table-row">
      <Container>
        {timedoutTxnsTableColumns.length > 0 &&
        timedoutTxnsTableRows.length > 0 ? (
          <React.Fragment>
            <Row>
              <Col md="4" className="mygarage_pagination_wrapper">
                <PaginationComponent
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  totalPages={totalPages}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="my-3">
                <span class="bold"> Failed Trnsactions </span>
              </Col>
              <Col md={12}>
                <BootstrapTable
                  keyField="custId"
                  data={timedoutTxnsTableRows}
                  columns={timedoutTxnsTableColumns}
                  // wrapperClasses="table-responsive"
                  // rowClasses="custom-row-class"
                />
              </Col>
            </Row>
          </React.Fragment>
        ) : null}
        {noTableData === true ? (
          <div className="noResultWrapper">
            <div className="noResult">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              Sorry, we couldn't find any results
            </div>
          </div>
        ) : null}
        <XModal
          show={updateStatusModal}
          title={"UPDATE ORDER STATUS"}
          handleClose={handleClosePopup}
          handleAction={() => {
            submitStatus();
          }}
          submitType
          favoriteTextValidity={true}
        >
          <section>
            <Container className="py-4">
              <Row className="justify-content-center">
                <Col className="select-container">
                  <Form.Control
                    placeholder="Enter ACX No"
                    className="input__control"
                    type="text"
                    name="acxNo"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col className="select-container">
                  <Form.Control
                    placeholder="Enter Order No"
                    className="input__control"
                    type="text"
                    name="orderNo"
                    onChange={handleInputChange}
                  />
                </Col>
                <Col className="select-container">
                  <Select
                    options={orderStatusOptions}
                    name="status"
                    placeholder="Order Status"
                    onChange={handleSelectChange}
                    value={selectedStatus}
                  />
                </Col>
                <Col className="select-container">
                  <Select
                    options={closeTypeOptions}
                    name="closeType"
                    placeholder="Close Type"
                    onChange={handleSelectChange}
                    value={selectedCloseType}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Reason"
                    name="reason"
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
            </Container>
          </section>
        </XModal>
        <XModal
          show={showOrderPreview}
          showButtons={false}
          title="order preview"
          className="order-preview-modal"
          customHeader={
            <div className="order-preview-custom-header">
              <span>order preview</span>
              <div>
                <Link
                  to="/checkout"
                  onClick={() => setShowOrderPreview(false)}
                >
                  Go To Cart
                </Link>
                <div
                  className="close-btn"
                  onClick={() => setShowOrderPreview(false)}
                >
                  <span className="material-icons">close</span>
                </div>
              </div>
            </div>
          }
          handleAction={() => setShowOrderPreview(false)}
        >
          <OrderPreview cartAllDetails={cartStatus.cartAllDetails} />
        </XModal>
      </Container>
    </div>
  );
};
export default TimedoutTransactions;
