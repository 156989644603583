import React, { useState, useEffect } from "react";
import { Form, Image, Row } from "react-bootstrap";
import {
  AsyncTypeahead,
  Highlighter,
  Menu,
  MenuItem,
  TypeaheadMenu,
} from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { catalogsearch } from "../Homepage/Search/globalSearch.thunk"
import banner from "../../static/images/banner.png";
import { search } from "./chemicalsSearch.thunk";
import {
  getGroupsByCategory,
  setBrand,
  showBrandGroups,
} from "./chemicalsSearch.slice";
import { getChemPartByGrp } from "../../actions/parts";
import { Link, useHistory } from "react-router-dom";
import { useRef } from "react";
import {
  isLengthLessthan,
  getImageURLCookieDetails,
} from "../../helpers/utils";
import FormValidationErrorMessage from "../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import _isEmpty from "lodash/isEmpty";
import _head from "lodash/head";

import { useToasts } from "react-toast-notifications";
import GlobalSearch from '../Homepage/Search/GlobalSearch';

const NO_VEHICLE_MSG = 'Please select a vehicle from YMME search or select a vehicle from My Garage, or select a vehicle from search';

function BannerSection(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchInput = useRef();
  const chemicalsGS = useSelector((state) => state.chemicalsGlobalSearch);
  const parts = useSelector((state) => state.parts);
  const banner_image_url = `${process.env.REACT_APP_IMAGE_S3_BUCKET_URL
    }/${"Banner/chemicalbanner.png"}?${getImageURLCookieDetails()}`;

  const initializeValidity = () => {
    const validity = {
      globalSearchMaxLimit: true,
    };
    return validity;
  };

  const [validity, setValidity] = useState(initializeValidity);
  const [image_url, setimage_url] = useState(banner_image_url);
  const options = chemicalsGS.options;
  const smsPartDescription = useSelector(
    (state) => state.app.smsPartDescription
  );
  const smsVinNumber = useSelector((state) => state.app.smsVinNumber);
  const smsUser = useSelector((state) => state.app.smsUser);
  const smsChemicalPart = useSelector((state) => state.app.smsChemicalPart);
  const mygarage = useSelector(state => state.mygarage);
  const sv = mygarage.selectedVehicle;
  let selectedDropDown = parts.selectedDropDown;
  const { addToast } = useToasts();
  const user = useSelector(state => state.user);
  const [isState, setIsState] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const userDetails = useSelector((state) => state.user.userDetails);


  useEffect(() => {
    if (
      smsUser &&
      !_isEmpty(smsPartDescription) &&
      smsPartDescription.length > 1
    ) {
      handleSearch(smsPartDescription);
      searchInput.current.focus();
    } else {
      dispatch({
        payload: {
          smsPageLoader: false,
        },
        type: "SMS_PAGE_LOADER",
      });
    }
  }, [smsPartDescription]);

  const handleSearch = (query) => {
    let validity = initializeValidity();
    validity.globalSearchMaxLimit = isLengthLessthan(query, 100);
    setValidity(validity);
    const payload = { s: query };
    const partpayload = { searchKey: query };
    dispatch(search(payload)).then((results) => {
      dispatch(catalogsearch(partpayload)).then((results) => {

        // -------------------------------change for iframe integration----------------------
        if (!_isEmpty(smsPartDescription) && smsUser) {
          let dropDownOptions = results.payload.chemicalParts;
          if (dropDownOptions && dropDownOptions.length == 1) {
            let findedPartType = _head(dropDownOptions);
            let tempFindedPart = { ...findedPartType };
            tempFindedPart["type"] = "partDescription";
            handleChange([tempFindedPart]);
          }
          dispatch({
            payload: {
              smsPartDesc: null,
            },
            type: "SMS_PART_DESCRIPTION",
          });
          dispatch({
            payload: {
              smsPageLoader: false,
            },
            type: "SMS_PAGE_LOADER",
          });
        }
      })
      // ----------------------------------------------------------------------------------
    })
      .catch((err) => {
        if (!_isEmpty(smsPartDescription) && smsUser) {
          dispatch({
            payload: {
              smsPartDesc: null,
            },
            type: "SMS_PART_DESCRIPTION",
          });
          dispatch({
            payload: {
              smsPageLoader: false,
            },
            type: "SMS_PAGE_LOADER",
          });
        }

      });
  };
  const getGroupFromCategory = (categoryName, groupName) => {
    const category = _.find(parts.categoryGroupMap, c => c.name.toLowerCase() === categoryName.toLowerCase());
    const g = _.find(category.groups, g => g.name.toLowerCase() === groupName.toLowerCase());
    return Object.assign({}, g);
  }
  const handleChange = (selected) => {
    const option = _.head(selected);

    if (!option) return;

    switch (option.type) {
      case "brand":
        dispatch(setBrand({ brand: option }));
        searchInput.current.toggleMenu();
        break;
      case "category":
        const partSupplies = parts.categorySuppliesMap;
        let category = _.filter(partSupplies, (p) => option.name === p.name);
        category = _.head(category);
        if (chemicalsGS.brand) {
          dispatch(showBrandGroups());
        } else {
          dispatch(getGroupsByCategory(category));
        }

        searchInput.current.toggleMenu();
        break;
      case "group":
        const groupIds = selected.map((single) => single.id);

        let _brand = "";

        if (chemicalsGS.brand) {
          _brand = chemicalsGS.brand.name;
        }

        dispatch(
          getChemPartByGrp(
            groupIds,
            parts.suppliesPageNumber,
            parts.suppliesPageSize,
            parts.suppliesSortBy,
            parts.suppliesOrder,
            _brand
          )
        );
        history.push("/supplies-list");
        break;

      case "partDescription":
        const ids = selected.map((single) => single.groupID);
        const partDescids = selected.map((single) => single.partDescriptionID);
        // dispatch(getChemPartByGrp(ids));
        dispatch(
          getChemPartByGrp(
            ids,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            partDescids
          )
        );

        history.push("/supplies-list");
        break;
   
      default:
        break;
    }
  };

  const getTypes = (type) => {
    switch (type) {
      case "chemicalBrands":
        return "brand";
      case "chemicalParts":
        return "partDescription";
      case "chemicalGroups":
        return "group";
      case "chemicalCategories":
        return "category";
      case "parts":
        return "parts";
      case "partcategory":
        return "partcategory";
      case "partgroup":
        return "partgroup";
      case 'vehicles':
        return 'vehicles';
      case 'group':
        return 'group';
      case 'make':
        return 'make';
      case 'model':
        return 'model';
      case 'states':
        return 'states';
      default:
        break;
    }
  };

  const setOption = (option, type) => {
    let _option = Object.assign({}, option);
    _option.type = getTypes(type);
    return _option;
  };

  const getGroupLabels = (label) => {
    switch (label) {
      case "chemicalBrands":
        return "Brands";
      case "chemicalParts":
        return "Part Descriptions";
      case "chemicalGroups":
        return "Groups";
      case "chemicalCategories":
        return "Categories";
      case "parts":
        return "Parts";
      case "partcategory":
        return "Part Category";
      case "partgroup":
        return "Part Group";
      case 'vehicles':
        return 'Vehicles';
      case 'group':
        return 'Group';
      case 'make':
        return 'Make';
      case 'model':
        return 'Model';
      case 'states':
        return 'States';
      default:
        break;
    }
  };

  const onKeyDown = (event) => {
    if (
      event.key !== "Backspace" &&
      event.key !== "Tab" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight" &&
      event.target.value.length >= 100
    ) {
      event.preventDefault();
    }
  };

  const renderMenu = (results, menuProps) => {
    if (!results.length)
      return (
        <Menu {...menuProps}>
          <MenuItem>Searching...</MenuItem>
        </Menu>
      );

    if (!chemicalsGS.loading) {
      const top = _.head(results);

      let flag = false;
      const values = Object.values(top);
      for (let i = 0; i < values.length; i++) {
        const t = values[i];
        if (t.length > 0) {
          flag = true;

          break;
        }
      }

      if (!flag) {
        return (
          <Menu {...menuProps}>
            <MenuItem>No Matching results</MenuItem>
          </Menu>
        );
      }
    }

    return (
      <Menu {...menuProps}>
        {results.map((result, index) => {
          return Object.keys(result)
            .sort()
            .map((label, i) => {
              return (
                result[label].length > 0 && (
                  <React.Fragment>
                    {i !== 0 && <Menu.Divider />}
                    <Menu.Header>
                      <h6>{getGroupLabels(label)}</h6>
                    </Menu.Header>
                    {result[label].map((value) => {
                      return (
                        <MenuItem option={setOption(value, label)}>
                          {value.name ||
                            `${value.partDescription} (${value.partSKU})`}
                        </MenuItem>
                      );
                    })}
                  </React.Fragment>
                )
              );
            });
        })}
      </Menu>
    );
  };

  const filterBy = (option, { text }) => {
    let p = option.chemicalCategories;
    let filteredChemical = _.filter(option.chemicalCategories, (c) => c.name.toLowerCase().includes(text));
    console.log("filteredChemical ==>", filteredChemical);
    //  option.chemicalCategories =filteredChemical;
    Object.assign(p, { filteredChemical });
    // const filteredChemicals = option.chemicalCategories.map((n) => {
    //   if(n.name.toLowerCase().includes(text)){
    //    return n.name;
    //   }
    // })

    if (option.name) {
      return option.name.toLowerCase().indexOf(text.toLowerCase()) !== -1;
    } else {
      return ["name"];
    }
  };

  const labelKey = (option) => {
    if (chemicalsGS.brand && option.type === "category") {
      return `${chemicalsGS.brand.name} - ${option.name || option.partDescription
        }`;
    }
    return `${option.name || option.partDescription}`;
  };

  const setDefaultImage = () => {
    setimage_url(
      `${process.env.REACT_APP_IMAGE_S3_BUCKET_URL
      }/${"Banner/default.png"}?${getImageURLCookieDetails()}`
    );
  };

  return (
    <div
      className="chemicalsAndSuppliesBanner"
      style={{ backgroundImage: "url(" + image_url + ")" }}
    >

      <Image src={image_url} onError={setDefaultImage} />
      <div className="chemicalsAndSuppliesBannerContent">
        <h2>Chemicals and Supplies</h2>
        <GlobalSearch dropdownValue="All" />
        {/* {userDetails && userDetails?.billTo?.mclSupplier !== "CD" && (

          <DropDownSearch />
        )}
        <Form >
          <AsyncTypeahead
            ref={searchInput}
            renderMenu={renderMenu}
            labelKey={labelKey}
            filterBy={filterBy}
            options={options}
            onChange={handleChange}
            onSearch={handleSearch}
            defaultInputValue={
              !_isEmpty(smsPartDescription) && smsPartDescription.length > 1
                ? smsPartDescription
                : ""
            }
            placeholder="Search by Brand, Category, Group, Part Description, Part SKU..."
            id="chemicals__search"
            inputProps={{ className: "searchInput input__search" }}
            onKeyDown={onKeyDown}
          />
        </Form> */}
        <FormValidationErrorMessage
          condition={!validity.globalSearchMaxLimit}
          errorMessage="Global search key should not exceed more than 100 characters"
        />
      </div>
    </div>
  );
}
export default BannerSection;
