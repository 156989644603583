import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Form,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import {
    updateToken,
} from '../../../actions/auth';
import {
    clearCart,
    saveQuote,
} from '../../../actions/cart';
import {
    hasQuoteAlphaSpecialIntChar,
    isMaxLengthLessthan,
    isMinLength
} from '../../../helpers/utils';
import XModal from '../../shared/XModal';
import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import {
    fetchShipToIds,fetchUserShipToIds
} from "./shipTo.thunk";
import {
    updateUserDetails,
} from '../../User/UserManagement/usermanagement.thunk';
import {
    setVehicleSelected,
} from '../MyGarage/mygarage.slice';
import { useToasts } from "react-toast-notifications";

const quoteStyle = {
    display: "flex",
    alignItems: "center"
}
const SUCCESS_SHIPTO_EDITED = "Default ShipTo# changed successfully.";
const CUSTOMER_BUYER = "CUSTOMER_BUYER";
const LOCATION_BUYER = "LOCATION_BUYER";

const ShipTo = () => {
    const userDetailsArr = useSelector((state) => state.user);
    const shipToData = useSelector((state) => state.ShipToIds);
    const cartQty = useSelector((state) => state.cart.qtyInCart);
    const [showWarningModal, setshowWarningModal] = useState(false);
    const [showQuoteMsg, setshowQuoteMsg] = useState(false);
    const [shipTo, setShipTo] = useState(null);
    const [tempShipToId, settempShipToId] = useState('');
    const [quoteName, setquoteName] = useState('');
    const [userUpdate, setuserUpdate] = useState(false);
    const [shipToUserData, setShipToUserData] = useState([]);
    const [quoteModified, setquoteModified] = useState(false);
    const [selectInActiveShipTo, setselectInActiveShipTo] = useState(null);
    const { addToast } = useToasts();
   
    const intializeQuoteValidations = () => {
        const quoteNameValidity = {
            isQuoteAlphaSpecialChar: true,
            isQuoteNameMinLimit: true,
            isQuoteNameMaxLimit: true,
        }
        return quoteNameValidity;
    }
    const [quoteNameValidity, setquoteNameValidity] = useState(intializeQuoteValidations);
    const dispatch = useDispatch();
    const history = useHistory();
    let billToId = "";
  if (!_isEmpty(userDetailsArr) && userDetailsArr.userDetails && userDetailsArr.userDetails.billTo) {
    billToId = userDetailsArr.userDetails.billTo.billToId;
  }

  useEffect(() => {
    let checkIsValidUser  =  !_isEmpty(userDetailsArr)  && (userDetailsArr.userDetails.userType === "LOCATION_BUYER" || userDetailsArr.userDetails.userType === "CUSTOMER_BUYER") && !_isEmpty(shipToData.userShipTos) && shipToData.userShipTos.length > 1 ? true : (userDetailsArr.userDetails.userType !== "LOCATION_BUYER" || userDetailsArr.userDetails.userType !== "CUSTOMER_BUYER") ? true : false;
    if (userDetailsArr?.userDetails && 
      userDetailsArr?.userDetails?.shipTos?.[0]?.isActive === "N" && checkIsValidUser) {
      updateActiveShipTo();}
  }, [userDetailsArr.userDetails]);
  useEffect(() => {
    const payload = {
      billtoid: billToId,
    };
    if (!_isEmpty(billToId)) {
      dispatch(fetchShipToIds(payload));

      if (!_isEmpty(userDetailsArr) && userDetailsArr.userDetails &&  
      (userDetailsArr.userDetails.userType === LOCATION_BUYER || 
       userDetailsArr.userDetails.userType === CUSTOMER_BUYER)){
      dispatch(fetchUserShipToIds());
    }
    }
  }, [billToId]);

  useEffect(() => {
    if (quoteModified) {
      updateShipTo();
    }
  }, [quoteModified]);

  const handleShipToChange = (event) => {
    if (cartQty > 0) {
      setshowWarningModal(true);
      settempShipToId(event.target.id);
    } else {
      setShipTo(event.target.id);
      setuserUpdate(true);
    }
  };

  useEffect(() => {
    if (!_isEmpty(userDetailsArr) && 
        userDetailsArr.userDetails && userDetailsArr.userDetails.userType !== null) {
            isDefaultShipToData();
    }
}, [userDetailsArr]);
    
      const isDefaultShipToData = () => {
        if (!_isEmpty(userDetailsArr) &&
        (userDetailsArr.userDetails.userType === LOCATION_BUYER || userDetailsArr.userDetails.userType === CUSTOMER_BUYER ) &&
        userDetailsArr.userDetails.shipTos  &&  userDetailsArr.userDetails.shipTos?.[0].isActive === "Y") {
        setShipToUserData(shipToData.userShipTos);
      }else 
      if(!_isEmpty(userDetailsArr)  && !_isEmpty(shipToData) && userDetailsArr.userDetails &&  
       userDetailsArr.userDetails &&  userDetailsArr.userDetails.shipTos){
       setShipToUserData(shipToData.shipToIds);
      }
      }
    const getDropdownOptions = () => {
        const {
            userDetails: {
                shipTos,
            }
        } = userDetailsArr;
        const SelectedshipToId = shipTos[0]?.shipToId;
        return (
            <div className="Scrollable-shipTo">
            {!_isEmpty(shipToUserData) && shipToUserData.map((data) => {  
            return (
            <label class="customized-radio-button">
              <input
                type="radio"
                id={data.shipToId}
                onChange={handleShipToChange}
                checked={
                  !_isEmpty(shipTo)
                    ? data.shipToId === shipTo
                    : data.shipToId === SelectedshipToId
                }
                disabled={data.isActive === "N"}
              />
              <span>{`${data.shipToId}, ${data.name} ; ${
                data.isActive === "N" ? "InActive" : "Active"
              }`}</span>
            </label>
                    );
                })}
            </div>
        );
    }
    const updateActiveShipTo = () => {
        let userShipTo =
        userDetailsArr.userDetails &&
        userDetailsArr.userDetails.shipTos &&
        userDetailsArr.userDetails.shipTos[0] &&
        userDetailsArr.userDetails.shipTos[0]?.shipToId;
  
        !_isEmpty(shipToUserData) && shipToUserData.map((ship) => {
        if (ship.shipToId === userShipTo) {
          if (ship.isActive === "N") {
            if (shipToUserData.length > 1) {
              updateDefaultInActiveShipTo();
            }
          }
        }
      });
    };

      const updateDefaultInActiveShipTo = () => {
    let userData = userDetailsArr && userDetailsArr?.userDetails;
    !_isEmpty(userData) && !_isEmpty(shipToUserData) && userData.shipTos?.find((line) => {
      if (line.isActive === "N") {
        shipToUserData?.find((line1) => {
          if (line1.isActive === "Y") {
            updateShipTo(line1.shipToId);
          }
        });
      }
    });
  };
    
      const updateShipTo = (id) => {
        const { shipToIds } = shipToData;
        let shipToIdList = {};
        let filterShipTo = shipTo !== null ? shipTo : id;
    
        !_isEmpty(shipToUserData) && shipToUserData.map((shipToData) => {
            shipToIdList[shipToData.shipToId.toString()] =
              shipToData.shipToId === filterShipTo;
          });
    
        const {
          userDetails: {
            isActive,
            firstName,
            lastName,
            userType,
            x2userName,
            xlPartAccountNumber,
            mobileNumber,
          },
        } = userDetailsArr;
        const payload = {
          isActive,
          email: x2userName,
          firstName,
          lastName,
          shipToIds: shipToIdList,
          userType,
          xlPartAccountNumber,
          mobileNumber,
        };
        dispatch(updateUserDetails(payload));
        updateToken(dispatch);
        dispatch(setVehicleSelected(null));
        dispatch({
          payload: {
            selectedVehicle: null,
          },
          type: "VEHICLE_SELECTION_COMPLETED",
        });
        if (updateShipTo)
          addToast(SUCCESS_SHIPTO_EDITED, {
            appearance: "success",
            autoDismiss: false,
          });
        history.push("/");
      };

    const handleCloseModal = () => {
        setshowWarningModal(false);
        setshowQuoteMsg(false)
    }

    const handleClearCart = async () => {
        await clearCart(dispatch);
        setshowWarningModal(false);
        setshowQuoteMsg(false);
        setShipTo(tempShipToId);
        setuserUpdate(true);
        setquoteModified(true)
    }

    const handleQuoteCancel = () => {
        setshowWarningModal(false);
        setshowQuoteMsg(false)
    }

    const validateQuoteName = (name, quoteNameValidity) => {
        quoteNameValidity.isQuoteAlphaSpecialChar = hasQuoteAlphaSpecialIntChar(name);
        quoteNameValidity.isQuoteNameMinLimit = isMinLength(name, 1);
        quoteNameValidity.isQuoteNameMaxLimit = isMaxLengthLessthan(name, 50);
        return quoteNameValidity;
    };

    const handleQuoteName = (event) => {
        let quoteValue = event.target.value;
        if (!_isEmpty(quoteValue)) {
            quoteValue = quoteValue.toUpperCase();
            const quoteValidity = validateQuoteName(quoteValue, quoteNameValidity);
            setquoteNameValidity(quoteValidity);
        }
        setquoteName(quoteValue);
    }

    const handleSaveASQuote = async () => {
        const {
            userDetails: {
                x2userName,
                mobileNumber,
            }
        } = userDetailsArr;
        dispatch({
            payload: {
                quoteError: false,
            },
            type: 'SAVE_QUOTE_ERROR',
        });
        await saveQuote(x2userName, quoteName, mobileNumber, dispatch).then((result) => {
            setshowWarningModal(false);
            setshowQuoteMsg(false);
            setShipTo(tempShipToId);
            setuserUpdate(true);
            setquoteModified(true);
        });
    }

    return (
        <div className="ship-to-container">
            {!_isEmpty(shipToUserData)
                && (
                    <Fragment>
                        <div className="shipTo_heading">Your user id is currently assigned to the selected shipto below.</div>
                        {(shipToUserData.length > 1)
                            && (
                                <span>Select default Ship To</span>
                            )}
                        {getDropdownOptions()}
                        {showWarningModal
                            && (
                                <XModal
                                    show={showWarningModal}
                                    showButtons={false}
                                    title="Change ShipTo..."
                                    className="change_shipTo_save_quote_modal"
                                    handleClose={handleCloseModal}
                                >
                                    {!showQuoteMsg
                                        && (
                                            <Fragment>
                                                <div className="change_shipTo_modal_heading">
                                                    <span className="shipTo_modal_text">
                                                        Your current cart is not empty. Before proceeding either clear the cart or save the cart as Quote.
                            </span>
                                                </div>
                                                <div className="shipTo_modal_button_padding">
                                                    <span className="shipTo_clear_cart_button">
                                                        <Button
                                                            className="add_to_cart_button"
                                                            onClick={handleClearCart}
                                                        >
                                                            Clear cart
                                </Button>
                                                    </span>
                                                    <span className="shipTo_save_as_quote_button">
                                                        <Button
                                                            className="add_to_cart_button"
                                                            onClick={() => setshowQuoteMsg(true)}
                                                        >
                                                            Save as quote
                                </Button>
                                                    </span>
                                                </div>
                                            </Fragment>
                                        )}
                                    {showQuoteMsg
                                        && (
                                            <Fragment>
                                                <div className="my-1 col-sm-12 shipTo_quote_input" style={quoteStyle}>
                                                    <Form.Control
                                                        placeholder={"Quote Name"}
                                                        className="input__default"
                                                        name="quotename"
                                                        maxLength="51"
                                                        value={quoteName}
                                                        onChange={handleQuoteName}
                                                    // onBlur={handleQuoteNameBlur}
                                                    />
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip id={`tooltip`}>
Enter customer name, phone number, both, any text to identify this quote								</Tooltip>
                                                        }
                                                    >
                                                        <span class="material-icons">info</span>
                                                    </OverlayTrigger>
                                                </div>
                                                <FormValidationErrorMessage
                                                    condition={
                                                        !quoteNameValidity.isQuoteAlphaSpecialChar && quoteNameValidity.isQuoteNameMinLimit
                                                    }
                                                    errorMessage={
                                                        'Quote name must contain alphanumeric or special characters only'
                                                    }
                                                />
                                                <FormValidationErrorMessage
                                                    condition={!quoteNameValidity.isQuoteNameMinLimit && quoteNameValidity.isQuoteAlphaSpecialChar}
                                                    errorMessage={
                                                        'Quote name should have atleast 1 character'
                                                    }
                                                />
                                                <FormValidationErrorMessage
                                                    condition={
                                                        !quoteNameValidity.isQuoteNameMaxLimit &&
                                                        quoteNameValidity.isQuoteNameMinLimit
                                                    }
                                                    errorMessage={
                                                        'Quote name should not exceed 50 characters'
                                                    }
                                                />
                                                <div className="shipTo_quote_input_buttons">
                                                    <span className="shipTo_save_quote_button">
                                                        <Button
                                                            onClick={handleSaveASQuote}
                                                            className="add_to_cart_button"
                                                            disabled={_isEmpty(quoteName)}
                                                        >
                                                            Save
                                </Button>
                                                    </span>
                                                    <span className="shipTo_cancel_quote_button">
                                                        <Button
                                                            onClick={handleQuoteCancel}
                                                            className="add_to_cart_button"
                                                        >
                                                            Cancel
                                </Button>
                                                    </span>
                                                </div>
                                            </Fragment>
                                        )}
                                </XModal>
                            )}
                        {(shipToUserData.length > 1)
                            && (
                                <Button
                                    className="user-management-submit-btn"
                                    onClick={updateShipTo}
                                    disabled={!userUpdate}
                                >
                                    ok
                                </Button>
                            )}
                    </Fragment>
                )}
        </div>
    );
};

export default ShipTo;
