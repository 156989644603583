import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  Spinner,
  Container,
} from "react-bootstrap";
import _isEqual from "lodash/isEqual";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _uniqBy from "lodash/uniqBy";
import _cloneDeep from "lodash/cloneDeep";
import _every from "lodash/every";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";

import AddToCart from "../AddToCart/AddToCart";
import ResetIcon from "../../../static/images/reset.png";
import XSelect from "../XSelect";
import CoverToCoverPopup from "../../Listing/CoverToCoverPopup";
import Placeholder from "../Placeholder";
import {
  getInterchangeData,
  getInterChangeBrandOptions,
  getInterChangePartTypeOptions,
  getC2CUrl,
  postInterchangeBulkUpload,
} from "../../../actions/parts";
import SingleInterchange from "./SingleIntetrchange";
import {
  hasAlphaSpecialIntChar,
  hasMinLength,
  deliveryPickupWarning,
  getImageURLCookieDetails,
  getDistributionForVehicle,
  getDistributionForNonVehicle,
  distributeAvailability2,
  formatAmount,
  isStockOrder,
  getCartShipDetails
} from "../../../helpers/utils";
import FormValidationErrorMessage from "../FormValidationErrorMessage/FormValidationErrorMessage";
import _find from "lodash/find";
import AddToCartWarningPopup from "../AddToCartWarningPopup/AddToCartWarningPopup";
import  PickupDelivery  from '../PickupDeliveryHeader';

import {
  Accordion,
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// import "react-accessible-accordion/dist/fancy-example.css";
import InterchangeAccordian from "./interchange.accordion";
import ShipInfoHeader from "../ShipInfoHeader";

import "./styles.scss";
import { triggerCustomUxCriticalError } from "../../../actions/error";

const actionTypes = {
  INTERCHANGE_BRANDS_DROPDOWN_OPTIONS_FETCH:
    "INTERCHANGE_BRANDS_DROPDOWN_OPTIONS_FETCH",
  INTERCHANGE_PART_TYPE_DROPDOWN_OPTIONS_FETCH:
    "INTERCHANGE_PART_TYPE_DROPDOWN_OPTIONS_FETCH",
  INTERCHANGE_DATA_FETCHED: "INTERCHANGE_DATA_FETCHED",
  CNK_CART_CHECKOUT_FETCHED: 'CNK_CART_CHECKOUT_FETCHED'
};

class Interchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interchangeData:
        props.interchangeData !== undefined ? props.interchangeData : [],
      brandOptions: props.brandOptions !== undefined ? props.brandOptions : [],
      partTypeOptions:
        props.partTypeOptions !== undefined ? props.partTypeOptions : [],
      showCoverToCover: false,
      partNumber: this.props.partNumber || "",
      brandList: [],
      partTypeList: [],
      interchangeBrandsList: [],
      validity: this.intializeValidations(),
      selectedBrand: "",
      selectedPartType: "",
      showBrands: false,
      showInterchangeBrandList: false,
      hide: false,
      isChecked: false,
      selectedAvailabilityHours: [],
      c2cUrl: "",
      userDetails: [],
      enableSinglePartDetails: true,
      dualBoxView: this.props.dualBoxView ? this.props.dualBoxView : true,
      partDefaultBrand: this.props.partBrand ? this.props.partBrand : null,
      defaultPartType: this.props.partType ? this.props.partType : null,
      activeBrand: 0,
      activePart: 0,
      addToCartWarningPopup: false,
      selectedCartData: [],
      addToCartWarning: false,
      cartStore: this.props.cartStore ? this.props.cartStore : "",
      shipInfo: "",
      showpopup: false,
      enableValidation: false,
      shipMethod: null,
      sellPartnerId:  null,
      store: null,
      isAccordionUpdated:false
    };
    this.handleCoverToCover = this.handleCoverToCover.bind(this);
    this.getDropDownList = this.getDropDownList.bind(this);
    this.handleDropDownSelection = this.handleDropDownSelection.bind(this);
    this.handlePartNoBlur = this.handlePartNoBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  intializeValidations() {
    this.validity = {
      isPartsNoAlphaSpecial: true,
      isPartsNoMinLimit: true,
    };
    return this.validity;
  }

  validatePartsNo(value, validity) {
    validity.isPartsNoAlphaSpecial = hasAlphaSpecialIntChar(value);
    validity.isPartsNoMinLimit = hasMinLength(value, 1);

    return validity;
  }

  componentDidMount = () => {
    console.log("component mounted...");
    const { dispatch, partNumber, parts, userDetails, checkoutDetails  } = this.props;
    let { shipMethod,
      sellPartnerId,
      store } = this.state;
    if (_isEmpty(sellPartnerId)) {
      let userDetails = _cloneDeep(this.props.userDetails);
      let shipInfo = getCartShipDetails(this.props.userDetails, checkoutDetails);
      sellPartnerId = shipInfo.sellerPartnerId
        ? shipInfo.sellerPartnerId
        : "";
      shipMethod = shipInfo.shipMethod ? shipInfo.shipMethod : "";
      store = shipInfo.storeNumber ? shipInfo.storeNumber : "";
      
        this.setState({
          ...this.state,
          sellPartnerId,
          shipMethod,
          store
        })
    }
    if (partNumber) {
      getInterChangeBrandOptions(partNumber, "ALL", dispatch);
    }
  };

  handleToggle = (bool) => {
    this.setState({
      showpopup: bool ? bool : !this.state.showpopup,
    });
  };

  addToCartForWarning = () => {
    this.setState({
      addToCartWarningPopup: true,
    });
  };

  componentDidUpdate(prevProps) {
    const {
      partLevel,
      partNumber,
      partType,
      partBrand,
      dualBoxView,
      userDetails,
      parts,
      dispatch,
      addItemcartFetching,
      globalLoader,
      checkoutDetails
    } = this.props;
    let stateChanged = false;
    if (!_isEqual(this.props, prevProps)) {
      let {
        interchangeData,
        brandOptions,
        partTypeOptions,
        brandList,
        partTypeList,
        interchangeBrandsList,
        userDetails,
        activeBrand,
        activePart,
        selectedBrand,
        selectedPartType,
        addToCartWarningPopup,
        selectedCartData,
        cartStore,
        shipInfo,
        enableValidation,
        selectedAvailabilityHours,
        shipMethod,
        sellPartnerId,
        store,
        isAccordionUpdated
      } = this.state;

      if (!_isEqual(this.props.interchanges, prevProps.interchanges)) {
        stateChanged = true;
        isAccordionUpdated = true;
      }

      if (!_isEqual(this.props.cartStore, prevProps.cartStore)) {
        stateChanged = true;
        cartStore = this.props.cartStore;
      }
      if (!_isEqual(this.props.cartStore, prevProps.cartStore)) {
        stateChanged = true;
        cartStore = this.props.cartStore;
      }
      if (
        !_isEqual(addItemcartFetching, prevProps.addItemcartFetching)
      ) {
        if(selectedAvailabilityHours.length > 0){
          stateChanged = true;
          selectedAvailabilityHours = [];
        }
      }
      if (
        !_isEqual(globalLoader, prevProps.globalLoader)
      ) {
        if(selectedAvailabilityHours.length > 0){
          stateChanged = true;
          selectedAvailabilityHours = [];
        }
      }
      if (!_isEqual(this.props.userDetails, prevProps.userDetails) || _isEmpty(sellPartnerId)) {
        userDetails = _cloneDeep(this.props.userDetails);
        shipInfo =  getCartShipDetails(this.props.userDetails, checkoutDetails);
        sellPartnerId = shipInfo.sellerPartnerId
          ? shipInfo.sellerPartnerId
          : "";
        shipMethod = shipInfo.shipMethod ? shipInfo.shipMethod : "";
        store = shipInfo.storeNumber ? shipInfo.storeNumber : "";
        stateChanged = true;
      }
      if (
        !_isEqual(this.props.selectedAddCartData, prevProps.selectedAddCartData)
      ) {
        stateChanged = true;
        selectedCartData = _cloneDeep(this.props.selectedAddCartData);
      }
    
      if (!_isEqual(this.props.brandOptions, prevProps.brandOptions)) {
        if (!_isEmpty(this.props.brandOptions)) {
          brandOptions = _cloneDeep(this.props.brandOptions);
          brandList = this.getDropDownList(brandOptions, "brand");
          if (activePart !== 0 && activeBrand == 0 && dualBoxView) {
            let activeData = this.getActiveItemInListBox(brandOptions, "brand");
            activeBrand = activeData.value;
            selectedBrand = activeData.name;
            this.getAllBrands(brandOptions, "brand");
          }
          if (dualBoxView) {
            const { partNumber } = this.state;
            const filterBrandName = _find(brandOptions, [
              "name",
              selectedBrand,
            ]);
            if (!_isEmpty(filterBrandName) && activePart != 0) {
              let mfgCode = filterBrandName.code;
              getInterchangeData(
                partNumber,
                "P",
                mfgCode.toString(),
                activePart.toString(),
                sellPartnerId,
                shipMethod,
                store,
                dispatch
              );
            }
          }
        }
        stateChanged = true;
        if (partLevel) {
          const filterBrand = _filter(brandList, (brand) =>
           !_isEmpty(partBrand) && partBrand.includes(brand.label)
          );
          const mfgCode = filterBrand.map((item) => {
            return item.value;
          });
          if (!dualBoxView) {
            getInterchangeData(
              partNumber,
              "P",
              mfgCode.toString(),
              0,
              sellPartnerId,
              shipMethod,
              store,
              dispatch
            );
          }
        }
      }
      if (!_isEqual(this.props.partTypeOptions, prevProps.partTypeOptions)) {
        if (!_isEmpty(this.props.partTypeOptions)) {
          partTypeOptions = _cloneDeep(this.props.partTypeOptions);
          partTypeList = this.getDropDownList(partTypeOptions, "partType");
          let activeData = this.getActiveItemInListBox(
            partTypeOptions,
            "partType"
          );
          activePart = activeData.value;
          selectedPartType = activeData.name;
          if (dualBoxView) {
            const { partNumber } = this.state;
            const filterPartName = _find(partTypeOptions, [
              "name",
              selectedPartType,
            ]);
            if (!_isEmpty(filterPartName) && activeBrand != 0) {
              let partTypeCode = filterPartName.code;
              getInterchangeData(
                partNumber,
                "P",
                activeBrand.toString(),
                partTypeCode.toString(),
                sellPartnerId,
                shipMethod,
                store,
                dispatch
              );
            }
          }
        }
        stateChanged = true;
      }

      if (!_isEqual(this.props.interchangeData, prevProps.interchangeData)) {
        interchangeData = _cloneDeep(this.props.interchangeData);
        stateChanged = true;
      }
      if (!_isEqual(this.props.dualBoxView, prevProps.dualBoxView)) {
        dualBoxView = this.props.dualBoxView;
        stateChanged = true;
      }
      if (!_isEmpty(this.props.userDetails)) {
        userDetails = _cloneDeep(this.props.userDetails);
        stateChanged = true;
      }

      if (stateChanged) {
        this.setState({
          ...this.state,
          interchangeData,
          brandOptions,
          partTypeOptions,
          brandList,
          partTypeList,
          interchangeBrandsList,
          userDetails,
          activeBrand,
          activePart,
          selectedBrand,
          selectedPartType,
          dualBoxView,
          enableValidation,
          //addToCartWarningPopup,
          selectedCartData,
          cartStore,
          shipInfo,
          selectedAvailabilityHours,
          shipMethod,
          store,
          sellPartnerId,
          isAccordionUpdated
        });
      }
    }
  }

  componentWillUnmount = () => {
    this.handleReset();
  };

  deliveryPickupWarning = () => {
    let {shipMethod, store} = this.state;
    let { checkoutDetails, orderType } = this.props;
    let warning = false;
    if(isStockOrder(orderType) || (checkoutDetails.length > 0 && ((checkoutDetails[0].shipMethod !== shipMethod) || (checkoutDetails[0].store !== store)))) {
       warning = true
    } 
    return warning;
  }

  addToCartForCandk = () => {
    const { dispatch } = this.props;
    let availability = this.selectedOrders();
    dispatch({
      payload: {
        candkCheckoutDetails: availability
      },
      type: actionTypes.CNK_CART_CHECKOUT_FETCHED,
    });
    const statusMessageProps = {
      heading: "Item added to cart",
      message: "",
      type: "success",
    };
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  }

  handleCoverToCover = (partNumber, orderNumber) => {
    const { dispatch } = this.props;
    getC2CUrl(partNumber, orderNumber, dispatch, true);
    this.setState({ showCoverToCover: !this.state.showCoverToCover });
  };

  getActiveItemInListBox = (data, dropdownType) => {
    let { activeBrand, activePart } = this.state;
    let isAvaible = [];
    data = data.filter(function (value, index, arr) {
      return value.name !== "ALL";
    });
    let activeValue = 0;
    let activeValueName = null;
    if (activeBrand == 0 && activePart == 0) {
      activeValue = 0;
      activeValueName = null;
    } else {
      if (dropdownType == "brand") {
        isAvaible = _find(data, { code: activeBrand });
        if (isAvaible && !_isEmpty(isAvaible)) {
          activeValue = isAvaible && isAvaible.code;
          activeValueName = isAvaible && isAvaible.name;
        }
        data.map((item, index) => {
          if (index === 0 && isAvaible == undefined && activeBrand != 0) {
            activeValue = item.code;
            activeValueName = item.name;
          }
        });
      }
      if (dropdownType == "partType") {
        isAvaible = _find(data, { code: activePart });
        if (isAvaible && !_isEmpty(isAvaible)) {
          activeValue = isAvaible && isAvaible.code;
          activeValueName = isAvaible && isAvaible.name;
        }
        data.map((item, index) => {
          if (index === 0 && isAvaible == undefined) {
            activeValue = item.code;
            activeValueName = item.name;
          }
        });
      }
    }
    return { name: activeValueName, value: activeValue };
  };

  getDropDownList = (data, dropdownType) => {
    const { selectedBrand } = this.state;
    const { partBrand } = this.props;
    let options = [];
    if (!_isEmpty(data)) {
      let filterData = [];

      if (dropdownType === "interchangeBrand") {
        filterData = data.filter(function (value, index, arr) {
          return value.name !== (selectedBrand || partBrand);
        });
      } else {
        filterData = data.filter(function (value, index, arr) {
          return value.name !== "ALL";
        });
      }

      filterData.map((item) => {
        const eachOption = {
          id: item.code,
          value: item.code,
          label: item.name,
        };
        options.push(eachOption);
      });
      return _uniqBy(options, "label");
    }
    return null;
  };

  // renderNoDataState = () => {
  //   return (
  //     <Card className="text-center">
  //       <Card.Header className="xlp-primary-banner">No Parts Found</Card.Header>
  //       <Card.Body>
  //         <Card.Title>
  //           We couldn't find the parts that you were looking for
  //         </Card.Title>
  //       </Card.Body>
  //     </Card>
  //   );
  // };

  handleApplyFilter = () => {
    let {
      partNumber,
      selectedBrand,
      selectedPartType,
      brandList,
      partTypeList,
      shipInfo,
    } = this.state;
    let sellPartnerId = shipInfo.sellerPartnerId
      ? shipInfo.sellerPartnerId
      : "";
    let shipMethod = shipInfo.shipMethod ? shipInfo.shipMethod : "";
    let store = shipInfo.storeNumber ? shipInfo.storeNumber : "";
    const { dispatch } = this.props;
    const filterBrand = _filter(brandList, ["label", selectedBrand]);
    const mfgCode = filterBrand.map((item) => {
      return item.value;
    });
    const filterParttype = _filter(partTypeList, ["label", selectedPartType]);
    const partTypeCode = filterParttype.map((item) => {
      return item.value;
    });
    getInterchangeData(
      partNumber,
      "P",
      mfgCode.toString(),
      partTypeCode.toString(),
      sellPartnerId,
      shipMethod,
      store,
      dispatch
    );
  };

  getAllBrands = (data, type) => {
    let { partNumber, partTypeList, selectedPartType, activeBrand, shipInfo } =
      this.state;
    let sellPartnerId = shipInfo.sellerPartnerId
      ? shipInfo.sellerPartnerId
      : "";
    let shipMethod = shipInfo.shipMethod ? shipInfo.shipMethod : "";
    let store = shipInfo.storeNumber ? shipInfo.storeNumber : "";
    const { dispatch } = this.props;

    const filterParttype = _find(partTypeList, ["label", selectedPartType]);
    let mfgCode = [];
    let filterData = [];
    filterData = data.filter(function (value, index, arr) {
      return value.name !== "ALL";
    });

    let partTypeCode = filterParttype.value;
    if (type === "partType") {
      filterData.forEach((item) => {
        mfgCode.push(item.code);
      });
      this.setState({
        activeBrand: 0,
      });
      getInterchangeData(
        partNumber,
        "P",
        mfgCode.toString(),
        partTypeCode.toString(),
        sellPartnerId,
        shipMethod,
        store,
        dispatch
      );
    }
    if (type === "brand") {
      filterData.forEach((item) => {
        mfgCode.push(item.code);
      });
      this.setState({
        activeBrand: 0,
      });
      getInterchangeData(
        partNumber,
        "P",
        mfgCode.toString(),
        partTypeCode.toString(),
        sellPartnerId,
        shipMethod,
        store,
        dispatch
      );
    }
  };

  handleDropDownSelection = (
    _partNumber,
    option,
    type,
    _interchangeData,
    _brandList,
    _partTypeList
  ) => {
    const { label } = option;
    const { dispatch, brandOptions } = this.props;
    let { selectedBrand, selectedPartType, activeBrand, shipInfo } = this.state;
    // this is to update the partNumber in the state, where getInterchangeData is dependent on state partNumber;
    this.setState({
      partNumber: _partNumber,
    });

    let filterData = [];
    let mfgCode = [];
    let partTypeCode = null;
    if (type === "interchangeBrand") {
      if (label !== "All") {
        filterData = _filter(_interchangeData, ["name", selectedBrand]);
      } else {
        filterData = _interchangeData;
      }
    }

    if (type === "brand") {
      dispatch({
        payload: {
          partTypeOptions: [],
        },
        type: actionTypes.INTERCHANGE_PART_TYPE_DROPDOWN_OPTIONS_FETCH,
      });

      console.log("handle dropdowns ==>", this.state, option);

      this.setState({
        selectedBrand: label,
        activeBrand: option.id,
      });
      const filterBrand = _filter(_brandList, ["label", label]);
      const mfgCode = filterBrand.map((item) => {
        return item.value;
      });
      if (selectedPartType == null) {
        partTypeCode = 0;
      } else {
        const filterParttype = _find(_partTypeList, [
          "label",
          selectedPartType,
        ]);
        partTypeCode = filterParttype?.value;
      }

      getInterChangePartTypeOptions(_partNumber, mfgCode.toString(), dispatch);
    }

    if (type === "partType") {
      dispatch({
        payload: {
          brandOptions: [],
        },
        type: actionTypes.INTERCHANGE_DATA_FETCHED,
      });
      this.setState({
        selectedPartType: label,
        activePart: option.id,
        //interchangeBrandsList: this.getDropDownList(brandOptions, 'interchangeBrand')
      });
      if (selectedBrand == "" || selectedBrand == null) {
        _brandList.forEach((item) => {
          mfgCode.push(item.value);
        });
      } else {
        const filteredBrand = _filter(_brandList, ["label", selectedBrand]);
        mfgCode = filteredBrand.map((item) => {
          return item.value;
        });
      }
      const partTypeData = _find(_partTypeList, ["label", label]);
      let partTypeValue = partTypeData.value;
      partTypeCode = partTypeValue;
      getInterChangeBrandOptions(
        _partNumber,
        partTypeCode.toString(),
        dispatch
      );
      //getInterchangeData(partNumber, 'P', mfgCode.toString(), partTypeCode.toString(), dispatch);
    }

    let sellPartnerId = shipInfo.sellerPartnerId
      ? shipInfo.sellerPartnerId
      : "";
    let shipMethod = shipInfo.shipMethod ? shipInfo.shipMethod : "";
    let store = shipInfo.storeNumber ? shipInfo.storeNumber : "";
    if(!_isEmpty(mfgCode) && partTypeCode != 0) {
      getInterchangeData(
        _partNumber,
        "P",
        mfgCode.toString(),
        partTypeCode.toString(),
        sellPartnerId,
        shipMethod,
        store,
        dispatch
      );
    }

  };

  handleInputChange(event) {
    let { validity } = this.state;
    let {
      target: { value },
    } = event;

    if (value.length === 0) {
      this.setState({
        brandList: [],
        partTypeList: [],
        showBrands: false,
        selectedBrand: "",
        interchangeData: [],
      });
    }

    validity = this.validatePartsNo(value, validity);

    this.setState({
      partNumber: event.target.value,
      validity,
      enableValidation: false,
    });
  }

  handlePartNoBlur() {
    let { validity } = this.state;
    validity = this.intializeValidations();
    this.setState({
      validity,
      // enableValidation: false,
    });
  }

  handleKeyDown(event) {
    const partNumber = event.target.value;
    if (
      event.key === " " ||
      (partNumber &&
        partNumber.length >= 20 &&
        event.keyCode !== 8 &&
        event.keyCode !== 9 &&
        event.keyCode !== 37 &&
        event.keyCode !== 38 &&
        event.keyCode !== 39 &&
        event.keyCode !== 40 &&
        event.keyCode !== 46 &&
        event.keyCode !== 17 &&
        event.keyCode !== 65)
    ) {
      event.preventDefault();
    }
  }

  handlePartSearch = (isShipInfoChanged = false, shipData = {}) => {
    let { partNumber, brandList, validity, shipInfo, partTypeList, shipMethod, store, sellPartnerId } =
      this.state;
    let { dispatch } = this.props;
    if(isShipInfoChanged) {
      shipMethod  = shipData.shipMethod;
      sellPartnerId = shipData.sellerId;
      store =  shipData.store;
    }
    validity = this.validatePartsNo(partNumber, validity);
    this.setState({
      interchangeData: [],
      brandOptions: [],
      partTypeOptions: [],
      brandList: [],
      partTypeList: [],
      selectedBrand: "",
      selectedPartType: "",
      activeBrand: 0,
      activePart: 0,
      enableValidation: false,
      isAccordionUpdated: false
    });

    if (
      _isEmpty(
        this.props.interchangeData &&
          this.props.activeBrand === 0 &&
          this.props.interchangeApiCall === false
      )
    ) {
      this.setState({ enableValidation: true });
    } else {
      this.setState({ enableValidation: false });
    }

    if (_every(validity)) {
      getInterChangeBrandOptions(partNumber, "ALL", dispatch);
      getInterChangePartTypeOptions(partNumber, "ALL", dispatch);
      getInterchangeData(
        partNumber,
        "P",
        "ALL",
        "ALL",
        sellPartnerId,
        shipMethod,
        store,
        dispatch
      );
      if (brandList.length === 0) {
        this.setState({
          showBrands: false,
          selectedBrand: "",
        });
      } else {
        this.setState({
          partDefaultBrand: "ALL",
          defaultPartType: "ALL",
          selectedBrand: "ALL",
          selectedPartType: "ALL",
          showBrands: true,
          activeBrand: 0,
          activePart: 0,
        });
      }
    } else {
      this.setState({
        validity,
      });
    }
  };

  resetBrandsAndParts = () => {
    let { dispatch } = this.props;
    dispatch({
      payload: {
        brandOptions: [],
      },
      type: actionTypes.INTERCHANGE_BRANDS_DROPDOWN_OPTIONS_FETCH,
    });
    dispatch({
      payload: {
        partTypeOptions: [],
      },
      type: actionTypes.INTERCHANGE_PART_TYPE_DROPDOWN_OPTIONS_FETCH,
    });
    let { partNumber, brandList, validity, shipInfo } = this.state;
    let sellPartnerId = shipInfo.sellerPartnerId
      ? shipInfo.sellerPartnerId
      : "";
    let shipMethod = shipInfo.shipMethod ? shipInfo.shipMethod : "";
    let store = shipInfo.storeNumber ? shipInfo.storeNumber : "";
    this.setState({
      brandOptions: [],
      partTypeOptions: [],
      brandList: [],
      partTypeList: [],
      selectedBrand: "",
      selectedPartType: "",
      activeBrand: 0,
      activePart: 0,
    });
    if (_every(validity)) {
      getInterChangePartTypeOptions(partNumber, "ALL", dispatch);
      getInterChangeBrandOptions(partNumber, "ALL", dispatch);
      getInterchangeData(
        partNumber,
        "P",
        "ALL",
        "ALL",
        sellPartnerId,
        shipMethod,
        store,
        dispatch
      );
    }
  };

  handleReset = () => {
    const { dispatch } = this.props;
    dispatch({
      payload: {
        interchangeData: [],
      },
      type: actionTypes.INTERCHANGE_DATA_FETCHED,
    });
    dispatch({
      type: "INTERCHANGES_RESET",
    });
    dispatch({
      payload: {
        brandOptions: [],
      },
      type: actionTypes.INTERCHANGE_BRANDS_DROPDOWN_OPTIONS_FETCH,
    });
    dispatch({
      payload: {
        partTypeOptions: [],
      },
      type: actionTypes.INTERCHANGE_PART_TYPE_DROPDOWN_OPTIONS_FETCH,
    });

    this.setState({
      partNumber: "",
      interchangeData: [],
      brandOptions: [],
      partTypeOptions: [],
      brandList: [],
      partTypeList: [],
      interchangeBrandsList: [],
      selectedBrand: "",
      selectedPartType: "",
      enableValidation: false,
    });
  };

  getWhichCart = () => {
    const { isForCandK, candkCheckoutDetails, cart } = this.props;
    let orginalCart = {};
    if(isForCandK) {
      orginalCart.checkoutDetails = _cloneDeep(candkCheckoutDetails);
    } else {
      orginalCart = _cloneDeep(cart);
    }  
    return orginalCart
  }

  selectedOrders = () => {
    const { dispatch, isForCandK } = this.props;
    let requestPayload = [];
    let { selectedAvailabilityHours, sellPartnerId, shipMethod, store } = this.state
    let {partLevel , isStockCheck, selectedVehicle, cart, userDetails, parts } = this.props;
    let {
			shipTos
		} = userDetails;
    let vehicleInfo =
          !isStockCheck && partLevel && !_isEmpty(selectedVehicle)
            ? `${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`
            : "Non-Vehicle Specific";
    let distributionData = {};
    let orderArray = [];
    selectedAvailabilityHours.map((singlePart) => {
      let extendedPrice = formatAmount(
        singlePart.qty * singlePart.pricingAvailability.costPrice
      );
      let mfgCode = singlePart.lineCodes && singlePart.lineCodes.length > 0 ? singlePart.lineCodes[0] : (singlePart.pricingAvailability &&
        singlePart.pricingAvailability.mfgCode ? singlePart.pricingAvailability.mfgCode : null);
      let tempObj = {
        availabilityHour: "",
        availableQty: "",
        cartFlag: "no",
        // categoryId,
        costPrice: singlePart.pricingAvailability.costPrice,
        corePrice: singlePart.pricingAvailability.corePrice,
        coreListPrice: singlePart.pricingAvailability.coreListPrice,
        qtyBuyInc :singlePart.pricingAvailability.qtyBuyInc,
        desc: singlePart.description,
        descId: singlePart.descriptionID,
        engineId: !_isEmpty(selectedVehicle)
          ? selectedVehicle.engineId
          : null,
        engine: !_isEmpty(selectedVehicle) ? selectedVehicle.engine : null,
        listPrice: singlePart.pricingAvailability.listPrice,
        makeId: !_isEmpty(selectedVehicle) ? selectedVehicle.makeId : null,
        make: !_isEmpty(selectedVehicle) ? selectedVehicle.make : null,
        modelId: !_isEmpty(selectedVehicle) ? selectedVehicle.modelId : null,
        model: !_isEmpty(selectedVehicle) ? selectedVehicle.model : null,
        mfgCode: mfgCode,
        packQty: singlePart.packQty,
        partNum: singlePart.partNumber,
        partType: "Regular",
        qty: singlePart.qty,
        vehicleInfo:
          !isStockCheck && partLevel && !_isEmpty(selectedVehicle)
            ? `${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`
            : "Non-Vehicle Specific",
        yearId: !_isEmpty(selectedVehicle) ? selectedVehicle.year : null,
        shipMethod: shipMethod,
        store: store,
        sellPartnerId: sellPartnerId,
        distStatus: "Completed",
        orderType: "REGULAR"
      };
      let orderArray = [];
      let cloneCart = this.getWhichCart();
      if (!isForCandK && this.deliveryPickupWarning()) {
          cloneCart.checkoutDetails = [];
      }
      if(!isStockCheck && (partLevel !== undefined && partLevel) && !_isEmpty(selectedVehicle)) {
        distributionData = getDistributionForVehicle(
        singlePart.partNumber,
        singlePart,
        singlePart.pricingAvailability.availability,
        singlePart.pricingAvailability.qtyBuyInc > 1 ? Number(singlePart.qty) : singlePart.qty,
        selectedVehicle,
        cloneCart
      );
      } else {
        distributionData = getDistributionForNonVehicle(
          singlePart.partNumber,
          singlePart,
          singlePart.pricingAvailability.availability,
          singlePart.pricingAvailability.qtyBuyInc > 1 ? Number(singlePart.qty) : singlePart.qty,
          cloneCart
        );
      }
      distributionData.alreadyAddedItem =
        this.deliveryPickupWarning()
          ? cart.checkoutDetails
          : distributionData.alreadyAddedItem;
      orderArray = distributeAvailability2(
        distributionData.totalQty,
        distributionData.filteredAvailability
      );
       let alreadyAddedToCart = [];
       requestPayload =  _filter(requestPayload, (item) => (item.partNum !== singlePart.partNumber) && (item.mfgCode !== mfgCode) && (item.vehicleInfo !== vehicleInfo || item.vehicleInfo == vehicleInfo))
       distributionData.alreadyAddedItem.map((dist)=> {
          requestPayload.map((pyld)=> {
            if((dist.partNum === pyld.partNum) && (dist.mfgCode === pyld.mfgCode) && (dist.vehicleInfo === pyld.vehicleInfo)) {
              alreadyAddedToCart.push(pyld);
            } else {
              alreadyAddedToCart.push(dist);
            }
          })
        })
        if(requestPayload.length === 0) {
          alreadyAddedToCart = distributionData.alreadyAddedItem
      }
      alreadyAddedToCart = alreadyAddedToCart.filter((ele, ind) => ind === alreadyAddedToCart.findIndex( elem => elem.partNum === ele.partNum && elem.mfgCode === ele.mfgCode && (elem.vehicleInfo === ele.vehicleInfo) && (elem.availabilityHour === ele.availabilityHour)))
      requestPayload.push(... alreadyAddedToCart);
      if (singlePart.addToOrder) {
        
        orderArray.map((availabilityData) => {
          tempObj = {
            ...tempObj,
            availabilityHour: availabilityData.availableDuration,
            availableQty: availabilityData.availableQty,
            qty: !_isEmpty(availabilityData.orderQty.toString())
              ? Number(availabilityData.orderQty.toString())
              : Number("1"),
            distStatus: "Completed",
            orderType: "REGULAR",
            toolTip: JSON.stringify(singlePart.pricingAvailability.toolTips)
          };
          requestPayload.push(tempObj);
        });
      }
    });
  let uniqueData = {};
  if(this.deliveryPickupWarning()) {
    uniqueData = requestPayload;
    return uniqueData 
  } else {
    uniqueData = requestPayload.filter((ele, ind) => ind === requestPayload.findIndex( elem => elem.partNum === ele.partNum && elem.mfgCode === ele.mfgCode && (elem.vehicleInfo === ele.vehicleInfo) && (elem.availabilityHour === ele.availabilityHour)))
    return uniqueData;
  }
  
  }
  // handleCheckBox = (e, orderArray) => {
  //   let { selectedAvailabilityHours } = this.state;

  //   let selectedIndex = _findIndex(selectedAvailabilityHours, {
  //     partNum: orderArray[0].partNum,
  //     desc: orderArray[0].desc,
  //   });

  //   if (selectedIndex !== -1) {
  //     _remove(selectedAvailabilityHours, {
  //       partNum: orderArray[0].partNum,
  //       desc: orderArray[0].desc,
  //     });
  //   } else {
  //     selectedAvailabilityHours = [
  //       ...this.state.selectedAvailabilityHours,
  //       ...orderArray,
  //     ];
  //   }

  //   this.setState({ selectedAvailabilityHours });
  // };

  handleCheckBox = (e, orderArray, allPartData = {}) => {

    const { dispatch } = this.props;
    let partClone = _cloneDeep(allPartData);
    if(e.target.checked) {
      partClone.addToOrder = true;
    } 
    else {
      partClone.addToOrder = false;
    }
     let { selectedAvailabilityHours } = this.state;
     selectedAvailabilityHours = _filter(selectedAvailabilityHours, (b) => b.description !== partClone.description);
    selectedAvailabilityHours.push(partClone);
    const filteredData = _filter(selectedAvailabilityHours, (b) => b.addToOrder !== false);
    this.setState({ selectedAvailabilityHours: filteredData });
  };

  displayPartDetails = () => {
    const { dispatch } = this.props;
    const {
      partTypeList,
      brandList,
      selectedBrand,
      partNumber,
      dualBoxView,
      interchangeData,
      shipInfo,
    } = this.state;
    let strPartLabel;
    let sellPartnerId = shipInfo.sellerPartnerId
      ? shipInfo.sellerPartnerId
      : "";
    let shipMethod = shipInfo.shipMethod ? shipInfo.shipMethod : "";
    let store = shipInfo.storeNumber ? shipInfo.storeNumber : "";
    if (partTypeList && partTypeList.length === 1) {
      let partLabel = partTypeList.map((data) => {
        return data.label;
      });
      strPartLabel = partLabel.toString();
      const filterBrand = _filter(brandList, ["label", selectedBrand]);
      const mfgCode = filterBrand.map((item) => {
        return item.value;
      });

      const filterParttype = _filter(partTypeList, ["label", strPartLabel]);
      const partTypeCode = filterParttype.map((item) => {
        return item.value;
      });

      getInterchangeData(
        partNumber,
        "P",
        mfgCode.toString(),
        partTypeCode.toString(),
        sellPartnerId,
        shipMethod,
        store,
        dispatch
      );
    }
    this.setState({
      interchangeData: dualBoxView ? interchangeData : [],
      enableSinglePartDetails: false,
      selectedPartType: strPartLabel,
    });
  };

  getPartTypeLabel = (partTypeList) => {
    if (partTypeList && partTypeList.length === 1) {
      const partValue = partTypeList.map((data) => {
        return { label: data.label, value: data.value };
      });
      if (this.state.enableSinglePartDetails === true) {
        this.displayPartDetails();
      }
      return partValue;
    }
  };

  handleUploadFile = (event) => {
    let { dualBoxView } = this.state;
    let importParts = "";
    let { dispatch } = this.props;
    const formData = new FormData();
    formData.append("file", event.target.files[0], event.target.files[0].name);
    let fileExtension = event.target.files[0].name.split(".").pop();
    if (fileExtension === "xlsx" || fileExtension === "xls") {
      postInterchangeBulkUpload(formData, dispatch);
      setTimeout(() => {
        this.handleToggle(false);
      }, 500);
      this.setState({ ...this.state, dualBoxView });
    }
  };

  storeSellerId = (id, stores) => {
    let { shipMethod, partNumber} = this.state;
    let storeNo = stores.split("-")[0].trim();
    this.setState({
      ...this.state,
      shipMethod: shipMethod,
      sellPartnerId: id,
      store:storeNo 
    });
    let shipInfo = {
      sellerId : id,
      store : storeNo,
      shipMethod: shipMethod
    }
    if(!_isEmpty(partNumber)) {
      this.handlePartSearch(true, shipInfo)
     }
  };

  handleViewTypeChange = (pickupDelivery) => {
    let {sellPartnerId, store, interchangeData, shipMethod, partNumber} = this.state;
    let { userDetails } = this.props;
    shipMethod =
      pickupDelivery == "delivery"
        ? userDetails &&
          userDetails.shipTos &&
          userDetails.shipTos.length > 0 &&
          userDetails.shipTos[0].shipMethod &&
          userDetails.shipTos[0].shipMethod
        : "0002";
        const customerSellerId = userDetails.shipTos && userDetails.shipTos.length > 0 && userDetails.shipTos[0].location && userDetails.shipTos[0].location.sellerPartnerId && userDetails.shipTos[0].location.sellerPartnerId ? userDetails.shipTos[0].location.sellerPartnerId  : ""; 	 
        const customerStore = userDetails && userDetails.shipTos && userDetails.shipTos[0] &&
                             userDetails.shipTos[0].location && userDetails.shipTos[0].location.storeNumber ? userDetails.shipTos[0].location.storeNumber : "";
    this.setState({
      ...this.state,
      shipMethod,
      interchangeData
    });
    let shipInfo = {
      sellerId : customerSellerId,
      store : customerStore,
      shipMethod: shipMethod
    }
    if(!_isEmpty(partNumber)) {
     this.handlePartSearch(true, shipInfo)
    }
  };

  storeInfo = (key, storeInfo) => {
    let {shipMethod, partNumber} = this.state;
    let filteredStore = _find(storeInfo, (storeData) => (
      (storeData.sellerPartID === key)
    ));
    this.setState({
      sellPartnerId: key,
      store: filteredStore.storeNum
    });
    let shipInfo = {
      sellerId : key,
      store : filteredStore.storeNum,
      shipMethod: shipMethod
    }
    if(!_isEmpty(partNumber)) {
      this.handlePartSearch(true, shipInfo)
    }
  }

  render() {
    const {
      brandOptions,
      partTypeOptions,
      showCoverToCover,
      partNumber,
      brandList,
      partTypeList,
      selectedAvailabilityHours,
      validity,
      selectedBrand,
      selectedPartType,
      showBrands,
      isChecked,
      partDefaultBrand,
      defaultPartType,
      activeBrand,
      activePart,
      selectedCartData,
      addToCartWarningPopup,
      addToCartWarning,
      cartStore,
      shipMethod,
      store,
      isAccordionUpdated
    } = this.state;
    const {
      parts,
      interchangeData,

      interchangeBrandApiCall,
      interchangeApiCall,
      partBrand,
      partType,
      brands,
      partLevel,
      interChangeC2CUrl,
      c2cUrl,
      dualBoxView,
      cartShipMethod,
      checkoutDetails,
      userDetails,
      isForCandK,
      globalLoader,
      interchanges
    } = this.props;
    return (
      <div className="interchange_modal">      
        <div className={partLevel ? "interchange_dropdowns_container model-popup" : "interchange_dropdowns_container"}>
          <div> 
            <p className="interchange_description">
              This is a relational interchange which take a part number for a given
              manufacture and return the most probable matching part number from
              other manufacturers where the applications of parts within the catalog
              are the same.
            </p>
          </div>
          <div className="interchangeWrapper">
            <div className="interchangeLeft">
              {!partBrand ? (
                <img
                  src={ResetIcon}
                  alt="reset"
                  onClick={(e) => this.handleReset()}
                />
              ) : null}
              <Form.Label>Part#</Form.Label>
              <Form.Control
                size="sm"
                type="text"
                maxLength="20"
                disabled={this.props.partNumber}
                value={partNumber.toUpperCase()}
                onChange={(e) => this.handleInputChange(e)}
                onBlur={this.handlePartNoBlur}
                autoFocus={true}
                onKeyDown={this.handleKeyDown}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.handlePartSearch(false);
                  }
                }}
              />
              {!partBrand && !partType ? (
                <div className="interchange_search__button">
                <Button
                  onClick={() => {
                    this.handlePartSearch(false);
                  }}
                   // disabled={!_isEmpty(partNumber)}
                >
                  {interchangeApiCall ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    "Search"
                  )}
                </Button>
                </div>
              ) : null}
            </div>
            <div className="interchangeRight">
              {dualBoxView && (
                <div className="import_part_list_conatiner_interchange_import_btn">
                  <Button onClick={() => this.handleToggle()}>
                    Import Part List
                  </Button>

                  {this.state.showpopup && (
                    <div className="import_part_list_popup_i">
                      <div className="import_part_list_header_i">
                        <span>Import Part Interchange List</span>
                        <div
                          className="close-btn"
                          onClick={() => this.handleToggle()}
                        >
                          <span className="material-icons">close</span>
                        </div>
                      </div>
                      <div className="choose_action_container_i">
                        <span>Import file containing parts list</span>
                        <div className="import_file_section_i">
                          <input
                            type="file"
                            onChange={this.handleUploadFile}
                            accept=".xlsx, .xls"
                          />
                        </div>
                        <a
                          href={`${process.env.REACT_APP_INTERCHANGE_IMPORT_TEMPLATE
                            }?${getImageURLCookieDetails()}`}
                          target="_blank"
                        >
                          Download template
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.deliveryPickupWarning() ? (
                <div className="prod_quantity_section">
                  <Fragment>
                    <Button
                      className="add-to-cart-btn"
                      onClick={() => this.addToCartForWarning()}
                      disabled={_isEmpty(selectedAvailabilityHours)}
                    >
                      Add to cart
                    </Button>
                  </Fragment>
                </div>
              ) : (
                <div className="add-to-catalog-btn-container">
                   {!isForCandK ? <AddToCart
                    disabled={_isEmpty(selectedAvailabilityHours)}
                    buttonStyle="interchange-addtocartbutton add-to-cart-btn"
                    // description={singlePart.description}
                    //selectedAvailabilityHours={selectedAvailabilityHours}
                    selectedAvailabilityHours={this.selectedOrders()}
                  />: 
                  <Button
                      className="add-to-cart-btn"
                      onClick={() => this.addToCartForCandk()}
                      disabled={_isEmpty(selectedAvailabilityHours)}
                    >
                      Add to cart
                  </Button>}
                </div>
              )}
            </div>
            </div>   
          <div className="disclaimerWrapper">
            <FormValidationErrorMessage
              condition={
                !validity.isPartsNoAlphaSpecial && validity.isPartsNoMinLimit
              }
              errorMessage={
                "Parts number should include alphanumeric or special characters"
              }
            />
            {this.state.enableValidation && (
              <FormValidationErrorMessage
                condition={
                  this.state.activeBrand === 0 &&
                  _isEmpty(interchangeData) &&
                  this.state.enableValidation &&
                  partNumber &&
                  interchangeApiCall === false
                }
                errorMessage={
                  <div className="error-message-interchange">
                    Part Number not found
                  </div>
                }
              />
            )}
            <FormValidationErrorMessage
              condition={!validity.isPartsNoMinLimit}
              errorMessage={
                <div className="error-message-interchange">
                  Please enter Part Number
                </div>
              }
            />
            {true && (
              <div
                className={
                  dualBoxView
                    ? "disclaimer_msg "
                    : "disclaimer_msg"
                }
              >
                "Disclaimer: To be used as a guide only. Please reference
                Catalog to verify application"
                {/* {this.props.userDetails &&
                this.props.userDetails.shipTos &&
                this.props.userDetails.shipTos.length > 0 &&
                this.props.userDetails.shipTos[0].shipMethod &&
                this.props.userDetails.shipTos[0].shipMethod ? (
                  <div className="wc-ship-info-interchange">
                    <span>
                      Ship Method:
                      <span className="text-black">
                        {cartShipMethod && cartShipMethod != null
                          ? cartShipMethod == "WC"
                            ? " Pickup "
                            : " Delivery "
                          : this.props.userDetails.shipTos[0].shipMethod ===
                            "WC"
                          ? " Pickup "
                          : " Delivery "}{" "}
                      </span>
                      From XL Parts Location :{" "}
                      <span className="text-black">
                        {cartStore
                          ? cartStore
                          : this.props.userDetails.shipTos[0].storeNumber}
                      </span>
                    </span>
                  </div>
                ) : (
                  ""
                )}  */}
           {/* <div className="wc-ship-info-interchange">
                  <ShipInfoHeader
                    shipMethod={shipMethod}
                    storeName={store}
                  />
                </div> */}
              </div>
            )}
            <div className="wc-ship-info-interchange">
                  <ShipInfoHeader
                    shipMethod={shipMethod}
                    storeName={store}
                  />
            </div> 
            <div className="interchange_shippingdropdown">
              {!isForCandK && <PickupDelivery
                handleViewTypeChange={this.handleViewTypeChange}
                storeSellerId={this.storeInfo}
              />}
            </div>
            {false &&
              partNumber &&
              brandList &&
              partTypeList &&
              brandList.length > 0 ? (
              <Fragment>
                <div className="interchange-dual-list">
                  <div className="container">
                    <div>
                      <span>
                        <b>Reset Brands and Part Types: </b>
                      </span>
                      &nbsp;&nbsp;
                      <img
                        className="reset-part-brand"
                        src={ResetIcon}
                        alt="reset"
                        onClick={(e) => this.resetBrandsAndParts()}
                      />
                    </div>
                    <br />
                    <div className="row">
                      <div className="dual-list list-left col-md-5">
                        <div className="well text-right">
                          <div className="row">
                            <div className="col-md-7">
                              <span>Brands</span>
                            </div>
                          </div>
                          <ul className="list-group brand-list">
                            {brandList.length > 0 &&
                              brandList.map((value, index) => {
                                return (
                                  <li
                                    className={
                                      value.id == activeBrand
                                        ? "list-group-item brand-active-item"
                                        : "list-group-item"
                                    }
                                    onClick={(option) =>
                                      this.handleDropDownSelection(
                                        value,
                                        "brand"
                                      )
                                    }
                                  >
                                    {value.label}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                      <div className="dual-list list-right col-md-5">
                        <div className="well text-right">
                          <div className="row">
                            <div className="col-md-7">
                              <span>Part Types</span>
                            </div>
                          </div>
                          <ul className="list-group part-list">
                            {partTypeList &&
                              partTypeList.length > 0 &&
                              partTypeList.map((value, index) => {
                                return (
                                  <li
                                    className={
                                      value.id == activePart
                                        ? "list-group-item brand-active-item"
                                        : "list-group-item"
                                    }
                                    onClick={(option) =>
                                      this.handleDropDownSelection(
                                        value,
                                        "partType"
                                      )
                                    }
                                  >
                                    {value.label}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container apply-filter">
                    <Button
                      onClick={() => {
                        this.handleApplyFilter();
                      }}
                      className="search__button"
                    >
                      {interchangeApiCall ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        "Apply Filter"
                      )}
                    </Button>
                  </div>
                </div>
              </Fragment>
            ) : null}
          </div>   
          {/* <Row style={{ width: "100%" }}>
            <Col
              md={partBrand ? 5 : 7}
              className={dualBoxView ? "interchange-partno-align-left" : ""}
            >
              <div className="interchange_part_container">
                {!partBrand ? (
                  <img
                    src={ResetIcon}
                    alt="reset"
                    onClick={(e) => this.handleReset()}
                  />
                ) : null}
                <Form.Label>Part#</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  maxLength="20"
                  disabled={this.props.partNumber}
                  value={partNumber.toUpperCase()}
                  onChange={(e) => this.handleInputChange(e)}
                  onBlur={this.handlePartNoBlur}
                  autoFocus={true}
                  onKeyDown={this.handleKeyDown}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.handlePartSearch(false);
                    }
                  }}
                />
                {!partBrand && !partType ? (
                  <Button
                    onClick={() => {
                      this.handlePartSearch(false);
                    }}
                    className="search__button" // disabled={!_isEmpty(partNumber)}
                  >
                    {interchangeApiCall ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Search"
                    )}
                  </Button>
                ) : null}
              </div>
            </Col>
            {dualBoxView && (
              <div className="import_part_list_conatiner_interchange_import_btn">
                <Button onClick={() => this.handleToggle()}>
                  Import Part List
                </Button>

                {this.state.showpopup && (
                  <div className="import_part_list_popup_i">
                    <div className="import_part_list_header_i">
                      <span>Import Part Interchange List</span>
                      <div
                        className="close-btn"
                        onClick={() => this.handleToggle()}
                      >
                        <span className="material-icons">close</span>
                      </div>
                    </div>
                    <div className="choose_action_container_i">
                      <span>Import file containing parts list</span>
                      <div className="import_file_section_i">
                        <input
                          type="file"
                          onChange={this.handleUploadFile}
                          accept=".xlsx, .xls"
                        />
                      </div>
                      <a
                        href={`${
                          process.env.REACT_APP_INTERCHANGE_IMPORT_TEMPLATE
                        }?${getImageURLCookieDetails()}`}
                        target="_blank"
                      >
                        Download template
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}

            {(!dualBoxView && showBrands && brandList.length > 0) ||
            partBrand ? (
              <Col md={4}>
                <div className="brand_container">
                  <Form.Label>Brand</Form.Label>
                  <XSelect
                    isDisabled={partLevel}
                    defaultValue={
                      partDefaultBrand
                        ? { label: partDefaultBrand, value: partDefaultBrand }
                        : null
                    }
                    placeholder="Select Brand"
                    onChange={(option) =>
                      this.handleDropDownSelection(option, "brand")
                    }
                    options={brandList}
                  />
                </div>
              </Col>
            ) : null}
            {!dualBoxView &&
            selectedBrand &&
            partTypeList.length > 0 &&
            !partLevel ? (
              <Col>
                <div className="part_type_container">
                  <Form.Label>Part Type</Form.Label>
                  <XSelect
                    isDisabled={partLevel}
                    defaultValue={
                      defaultPartType
                        ? { label: defaultPartType, value: defaultPartType }
                        : null
                    }
                    onChange={(option) =>
                      this.handleDropDownSelection(option, "partType")
                    }
                    options={partTypeList}
                    placeholder="Select Part Type"
                    value={this.getPartTypeLabel(partTypeList)}
                  />
                </div>
              </Col>
            ) : null}
            <Col md={3}>
              {this.deliveryPickupWarning() ? (
                <div className="prod_quantity_section">
                  <Fragment>
                    <Button
                      className="add-to-cart-btn"
                      onClick={() => this.addToCartForWarning()}
                      disabled={_isEmpty(selectedAvailabilityHours)}
                    >
                      Add to cart
                    </Button>
                  </Fragment>
                </div>
              ) : (
                <div className="add-to-catalog-btn-container">
                  <AddToCart
                    disabled={_isEmpty(selectedAvailabilityHours)}
                    buttonStyle="interchange-addtocartbutton add-to-cart-btn"
                    // description={singlePart.description}
                    //selectedAvailabilityHours={selectedAvailabilityHours}
                    selectedAvailabilityHours={this.selectedOrders()}
                  />
                </div>
              )}
            </Col>
            <FormValidationErrorMessage
              condition={
                !validity.isPartsNoAlphaSpecial && validity.isPartsNoMinLimit
              }
              errorMessage={
                "Parts number should include alphanumeric or special characters"
              }
            />
            {this.state.enableValidation && (
              <FormValidationErrorMessage
                condition={
                  this.state.activeBrand === 0 &&
                  _isEmpty(interchangeData) &&
                  this.state.enableValidation &&
                  partNumber &&
                  interchangeApiCall === false
                }
                errorMessage={
                  <div className="error-message-interchange">
                    Part Number not found
                  </div>
                }
              />
            )}
            <FormValidationErrorMessage
              condition={!validity.isPartsNoMinLimit}
              errorMessage={
                <div className="error-message-interchange">
                  Please enter Part Number
                </div>
              }
            />
            {true && (
              <p
                className={
                  dualBoxView
                    ? "disclaimer_msg dual-box-view"
                    : "disclaimer_msg"
                }
              >
                "Disclaimer: To be used as a guide only. Please reference
                Catalog to verify application"

                <div className="wc-ship-info-interchange">
                  <ShipInfoHeader
                    shipMethod={shipMethod}
                    storeName={store}
                  />
                </div>
              </p>
            )}
            <div className="interchange_shippingdropdown">
            <PickupDelivery
              handleViewTypeChange={this.handleViewTypeChange}
              storeSellerId={this.storeInfo}
            />
            </div>
            {false &&
            partNumber &&
            brandList &&
            partTypeList &&
            brandList.length > 0 ? (
              <Fragment>
                <div className="interchange-dual-list">
                  <div className="container">
                    <div>
                      <span>
                        <b>Reset Brands and Part Types: </b>
                      </span>
                      &nbsp;&nbsp;
                      <img
                        className="reset-part-brand"
                        src={ResetIcon}
                        alt="reset"
                        onClick={(e) => this.resetBrandsAndParts()}
                      />
                    </div>
                    <br />
                    <div className="row">
                      <div className="dual-list list-left col-md-5">
                        <div className="well text-right">
                          <div className="row">
                            <div className="col-md-7">
                              <span>Brands</span>
                            </div>
                          </div>
                          <ul className="list-group brand-list">
                            {brandList.length > 0 &&
                              brandList.map((value, index) => {
                                return (
                                  <li
                                    className={
                                      value.id == activeBrand
                                        ? "list-group-item brand-active-item"
                                        : "list-group-item"
                                    }
                                    onClick={(option) =>
                                      this.handleDropDownSelection(
                                        value,
                                        "brand"
                                      )
                                    }
                                  >
                                    {value.label}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                      <div className="dual-list list-right col-md-5">
                        <div className="well text-right">
                          <div className="row">
                            <div className="col-md-7">
                              <span>Part Types</span>
                            </div>
                          </div>
                          <ul className="list-group part-list">
                            {partTypeList &&
                              partTypeList.length > 0 &&
                              partTypeList.map((value, index) => {
                                return (
                                  <li
                                    className={
                                      value.id == activePart
                                        ? "list-group-item brand-active-item"
                                        : "list-group-item"
                                    }
                                    onClick={(option) =>
                                      this.handleDropDownSelection(
                                        value,
                                        "partType"
                                      )
                                    }
                                  >
                                    {value.label}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container apply-filter">
                    <Button
                      onClick={() => {
                        this.handleApplyFilter();
                      }}
                      className="search__button"
                    >
                      {interchangeApiCall ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        "Apply Filter"
                      )}
                    </Button>
                  </div>
                </div>
              </Fragment>
            ) : null}
            {/* <Col></Col> */}
          {/* </Row> */} 
        </div>

        {/* {!partLevel && !dualBoxView ? (
          <div className="add-to-catalog-btn-container">
            {!_isEmpty(selectedAvailabilityHours) ? (
              <AddToCart
                // description={singlePart.description}
                selectedAvailabilityHours={selectedAvailabilityHours}
              />
            ) : (
              <Button disabled>Add To Cart</Button>
            )}
          </div>
        ) : null}
        {dualBoxView &&
        _isEmpty(interchangeData) &&
        !interchangeApiCall &&
        brandList &&
        brandList.length > 0 ? (
          <Container>
            <Row>
              {this.renderBrandAndPartFilter()}
              {!partLevel &&
                _isEmpty(interchangeData) &&
                !interchangeApiCall && (
                  <Col md={{ span: 8 }}>{this.renderNoDataState()}</Col>
                )}
            </Row>
          </Container>
        ) : null}
        {!dualBoxView &&
          !partLevel &&
          _isEmpty(interchangeData) &&
          selectedPartType &&
          selectedBrand &&
          !interchangeApiCall && (
            <Col md={{ span: 8, offset: 2 }}>{this.renderNoDataState()}</Col>
          )} */}
        <div className="inerchange-box ">
          {isAccordionUpdated && <Accordion
            allowZeroExpanded={true}
            preExpanded={
              interchanges && interchanges.length > 0
                ? [interchanges.length - 1]
                : [0]
            }
          >
            {this.props.interchanges?.map((interchange, index) => {
              return (
                interchange.partNum && (
                  <AccordionItem uuid={index}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {/* <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? "expanded" : "collapsed"
                        }
                      </AccordionItemState> */}
                        {`${interchange.partNum}`}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <InterchangeAccordian
                        dualBoxView={this.state.dualBoxView}
                        activeBrand={this.state.activeBrand}
                        activePart={this.state.activePart}
                        handleCoverToCover={this.handleCoverToCover}
                        handleDropDownSelection={this.handleDropDownSelection}
                        resetBrandsAndParts={this.resetBrandsAndParts}
                        brandList={this.getDropDownList(
                          interchange.brands,
                          "brand"
                        )}
                        partTypeList={this.getDropDownList(
                          interchange.partTypes,
                          "partType"
                        )}
                        brandOptions={brandOptions}
                        partTypeOptions={partTypeOptions}
                        partNumber={interchange.partNum}
                        validity={this.state.validity}
                        shipInfo={this.state.shipInfo}
                        selectedstore={store}
                        handleCheckBox={this.handleCheckBox}
                        userDetails={this.props.userDetails}
                        interchangeData={interchange.interchangeData}
                        interchangeApiCallOnGoing={
                          this.props.interchangeApiCallOnGoing
                        }
                      />                    
                    </AccordionItemPanel>
                  </AccordionItem>
                )
              );
            })}
           </Accordion>}
        </div>
        <div>{!isAccordionUpdated && globalLoader &&  <Placeholder rows={2} />}</div>
        {showCoverToCover ? (
          <CoverToCoverPopup
            openPopup={showCoverToCover}
            closePopup={this.handleCoverToCover}
            url={c2cUrl || interChangeC2CUrl}
          />
        ) : null}
        {addToCartWarningPopup ? (
          <AddToCartWarningPopup
            openPopup={addToCartWarningPopup}
            selectedAvailabilityHours={this.selectedOrders()}
            source={"partCategory"}
            DefaultAddItemToCart={true}
            orderType="REGULAR"
            closePopup={() => {
              this.setState({
                addToCartWarningPopup: false,
              });
            }}
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    interchanges: state.parts.interchanges,
    interchangeData: state.parts.interchangeData,
    interchangeApiCall: state.parts.interchangeApiCall,
    interchangeBrandApiCall: state.parts.interchangeBrandApiCall,
    brandOptions: state.parts.brandOptions,
    partTypeOptions: state.parts.partTypeOptions,
    interChangeC2CUrl: state.parts.interChangeC2CUrl,
    userDetails: state.user.userDetails,
    addToCartWarning: state.parts.addToCartWarning,
    selectedAddCartData: state.parts.selectedAddCartData,
    cartStore: state.parts.cartStore,
    parts: state.parts,
    cartShipMethod: state.parts.cartShipMethod,
    selectedVehicle: state.search.selectedVehicle,
    cart: state.cart,
    checkoutDetails: state.cart.checkoutDetails,
    globalLoader: state.app.globalLoader,
    addItemcartFetching: state.cart.addItemcartFetching,
    orderType: state.cart.orderType,
    candkCheckoutDetails: state.cart.candkCheckoutDetails
  };
}
export default connect(mapStateToProps)(Interchange);
