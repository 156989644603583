import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import _isEmpty from "lodash/isEmpty";
import "./styles.scss";
import { useSelector } from "react-redux";
import Moment from "react-moment";

const headerStyle = {
  backgroundColor: "#0F5283",
  color: "#FFFFFF",
};

const TesterTable = (props) => {
  const data = useSelector((state) => state.ProgramAndBenefits);
  const benefitData = data && data.benefitData;
  const batterytester = benefitData && benefitData.batteryTester;

  const [testerColoumn, setTesterColoumn] = useState([]);
  const [testerData, setTesterData] = useState([]);

  useEffect(() => {
    if (!_isEmpty(batterytester)) {
      constructTesterData(batterytester);
    }
  }, [batterytester]);

  const constructTesterData = (data) => {
    setTesterColoumn([]);
    setTesterData([]);
    const testerTableData = [];

    data.forEach((item, index) => {
      testerTableData.push({
        part: item.testerPart,
        date: item.dateIssued,
        value: item.price,
        invoice: item.invNumber,
      });
    });

    const testerColoumn = [
      {
        dataField: "part",
        text: "Part",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
      {
        dataField: "date",
        text: "Date",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
        formatter: (cell, row) => {
          return <Moment date={cell} format="MM-DD-YYYY" />;
        },
      },
      {
        dataField: "invoice",
        text: "Invoice",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
      {
        dataField: "value",
        text: "Value",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
      },
    ];
    setTesterColoumn(testerColoumn);
    setTesterData(testerTableData);
  };

  const renderTesterTb = () => {
    let headers = Object.values(testerColoumn);
    return (
      <Row>
        <Col>
          <table className="table table-bordered">
            <thead>
              <tr>
                {headers.map((header) => {
                  return <th>{header.text}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {testerData && testerData.length === 0 && (
                <tr>
                  <td>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };
  return (
    <div className="stocking-batteries">
      <Col>
        {testerData && testerData.length > 0 && testerColoumn.length > 0 ? (
          <BootstrapTable
            bootstrap4
            keyField="specDesc"
            data={testerData}
            columns={testerColoumn}
            wrapperClasses="earnBackDisplay"
            noDataIndication
          />
        ) : (
          renderTesterTb()
        )}
      </Col>
    </div>
  );
};
TesterTable.propTypes = {};
export default TesterTable;
