import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import { triggerCustomUxCriticalError } from '../../../actions/error';

const SessionExpiry = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let errorMessageProps = {
      heading: "Error",
      message: "Statements View session expired",
      type: "error",
    };
    triggerCustomUxCriticalError(errorMessageProps, dispatch);
  }, []);
  return (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  );
};

export default SessionExpiry;
