import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _uniqWith from "lodash/uniqWith";
import _without from "lodash/without";
import _concat from "lodash/concat";

const app = (state = {smsUser:false, smsPageLoader:false, smsChemicalPart:false, smsPartDescription:null, smsVinNumber:null, viewPromotions: true, globalLoader: false,
  isToastVisible: false }, action) => {
  let newState = {
    ...state
  };
  switch (action.type) {
    case "TRIGGER_UX_CRITICAL_ERROR":
      newState = {
        ...state,
        errors: !_isEmpty(state.errors)
          ? _uniqWith(_concat(state.errors, action.payload.errors), _isEqual)
          : action.payload.errors,
        // errors: (!_isEmpty(state.errors)) ?
        // [...state.errors, ...action.payload.errors]
        // : action.payload.errors,
        // errors: Object.assign([],  action.payload.errors),
      };
      break;
    case "DISMISS_UX_CRITICAL_ERROR":
      newState = {
        ...state,
        errors: _without(state.errors, action.payload.error),
      };
      break;
    case "DISMISS_ALL_UX_CRITICAL_ERROR":
      newState = {
        ...state,
        errors: [],
      };
      break;
    case "AUTHORIZATION_DONE":
      newState = {
        ...state,
        userAuthenticated: action.payload.authorizationDone,
      };
      break;
      case "SMS_PAGE_LOADER":
      newState = {
        ...state,
        smsPageLoader: action.payload.smsPageLoader,
      };
      break;
      case "SMS_USER":
      newState = {
        ...state,
        smsUser: action.payload.smsUser,
      };
      break;
      case "SMS_VIN":
      newState = {
        ...state,
        smsVinNumber: action.payload.smsVin,
      };
      break;
      case "SMS_PART_DESCRIPTION":
      newState = {
        ...state,
        smsPartDescription: action.payload.smsPartDesc,
      };
      break;
      case "SMS_CHEMICAL_PART":
      newState = {
        ...state,
        smsChemicalPart: action.payload.smsChemicalPart,
      };
      break;
      case "RESET_SMS_DETAILS":
      newState = {
        ...state,
        smsUser: false,
        smsChemicalPart: false,
        smsVinNumber: null,
        smsPartDescription: null,
      };
      break;
    case "VIEWPROMOTIONS_TOGGLE":
      const {
        payload,
        payload: { show },
      } = action;
      newState = {
        ...state,
        viewPromotions: _isEmpty(payload) ? !state.viewPromotions : show,
      };
      break;
    case "COLLAPSE_PROMOTIONS_TAB":
      newState = {
        ...state,
        viewPromotions: false,
      };
      break;
    case "REFRESH_TOKEN_STATUS":
      newState = {
        ...state,
        refreshTokenStatus: action.payload.status,
      };
      break;
    case "GLOBAL_LOADER":
      newState = {
        ...state,
        globalLoader: action.payload.globalLoader,
      };
      break;
    default:
      break;
    case 'SET_TOAST_DISPLAYED':
      return {
          ...state,
          isToastVisible: action.payload.isToastDisplayed,
      };
  }

  return newState;
};

export default app;
