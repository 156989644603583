export const statesList = [
  {
    label: "Alabama",
    id: "AL",
    value: "AL",
  },
  {
    label: "Alaska",
    id: "AK",
    value: "AK",
  },
  {
    label: "American Samoa",
    id: "AS",
    value: "AS",
  },
  {
    label: "Arizona",
    id: "AZ",
    value: "AZ",
  },
  {
    label: "Arkansas",
    id: "AR",
    value: "AR",
  },
  {
    label: "California",
    id: "CA",
    value: "CA",
  },
  {
    label: "Colorado",
    id: "CO",
    value: "CO",
  },
  {
    label: "Connecticut",
    id: "CT",
    value: "CT",
  },
  {
    label: "Delaware",
    id: "DE",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    id: "DC",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    id: "FM",
    value: "FM",
  },
  {
    label: "Florida",
    id: "FL",
    value: "FL",
  },
  {
    label: "Georgia",
    id: "GA",
    value: "GA",
  },
  {
    label: "Guam",
    id: "GU",
    value: "GU",
  },
  {
    label: "Hawaii",
    id: "HI",
    value: "HI",
  },
  {
    label: "Idaho",
    id: "ID",
    value: "ID",
  },
  {
    label: "Illinois",
    id: "IL",
    value: "IL",
  },
  {
    label: "Indiana",
    id: "IN",
    value: "IN",
  },
  {
    label: "Iowa",
    id: "IA",
    value: "IA",
  },
  {
    label: "Kansas",
    id: "KS",
    value: "KS",
  },
  {
    label: "Kentucky",
    id: "KY",
    value: "KY",
  },
  {
    label: "Louisiana",
    id: "LA",
    value: "LA",
  },
  {
    label: "Maine",
    id: "ME",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    id: "MH",
    value: "MH",
  },
  {
    label: "Maryland",
    id: "MD",
    value: "MD",
  },
  {
    label: "Massachusetts",
    id: "MA",
    value: "MA",
  },
  {
    label: "Michigan",
    id: "MI",
    value: "MI",
  },
  {
    label: "Minnesota",
    id: "MN",
    value: "MN",
  },
  {
    label: "Mississippi",
    id: "MS",
    value: "MS",
  },
  {
    label: "Missouri",
    id: "MO",
    value: "MO",
  },
  {
    label: "Montana",
    id: "MT",
    value: "MT",
  },
  {
    label: "Nebraska",
    id: "NE",
    value: "NE",
  },
  {
    label: "Nevada",
    id: "NV",
    value: "NV",
  },
  {
    label: "New Hampshire",
    id: "NH",
    value: "NH",
  },
  {
    label: "New Jersey",
    id: "NJ",
    value: "NJ",
  },
  {
    label: "New Mexico",
    id: "NM",
    value: "NM",
  },
  {
    label: "New York",
    id: "NY",
    value: "NY",
  },
  {
    label: "North Carolina",
    id: "NC",
    value: "NC",
  },
  {
    label: "North Dakota",
    id: "ND",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    id: "MP",
    value: "MP",
  },
  {
    label: "Ohio",
    id: "OH",
    value: "OH",
  },
  {
    label: "Oklahoma",
    id: "OK",
    value: "OK",
  },
  {
    label: "Oregon",
    id: "OR",
    value: "OR",
  },
  {
    label: "Palau",
    id: "PW",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    id: "PA",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    id: "PR",
    value: "PR",
  },
  {
    label: "Rhode Island",
    id: "RI",
    value: "RI",
  },
  {
    label: "South Carolina",
    id: "SC",
    value: "SC",
  },
  {
    label: "South Dakota",
    id: "SD",
    value: "SD",
  },
  {
    label: "Tennessee",
    id: "TN",
    value: "TN",
  },
  {
    label: "Texas",
    id: "TX",
    value: "TX",
  },
  {
    label: "Utah",
    id: "UT",
    value: "UT",
  },
  {
    label: "Vermont",
    id: "VT",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    id: "VI",
    value: "VI",
  },
  {
    label: "Virginia",
    id: "VA",
    value: "VA",
  },
  {
    label: "Washington",
    id: "WA",
    value: "WA",
  },
  {
    label: "West Virginia",
    id: "WV",
    value: "WV",
  },
  {
    label: "Wisconsin",
    id: "WI",
    value: "WI",
  },
  {
    label: "Wyoming",
    id: "WY",
    value: "WY",
  },
];

export const lostSalesQuestions = {
  HighPrice: "What's the right price?",
  BetterPrice: "from which WD?",
  NotAvailable: "When did you need it?",
  WrongBrand: "What brand do you need?",
  OtherReason: "Please tell us why?",
};

export const laborSkillHoverValues = {
  A: {
    heading: "Highly Skilled:",
    description:
      "Requires the use of precision measuring tools and specialized test equipment. requires through knowledge of complicated systems and strong diagnostic capabilities",
  },
  B: {
    heading: "Skilled:",
    description:
      "Requires the use of basic tools and simple measuring devices. Accurate diagnosis is required using special test equipment. Must have basic knowledge of complex systems.",
  },
  C: {
    heading: "Semi Skilled:",
    description:
      "Requires the use of basic tools. Diagnosis is limited to a single possible cause of problem. Must have basic knowledge of component or system operation",
  },
  D: {
    heading: "Low Skilled:",
    description:
      "Repair consists of part replacement only, Must be able to follow wriiten and/or verbal instructions",
  },
};
export const mobileInstructions = [
  {
    platform: "iOS",
    version: "1.0.18",
    instruction: "scan below QR code from your mobile camera",
    envs: [
      {
        env: ["dev-rapid", "localhost"],
        link: "https://apps.apple.com/in/app/tph-rapid/id1603236298",
        caption: "DEV Build",
        lastUpdated: "",
      },
      {
        env: ["uat-rapid"],
        link: "https://apps.apple.com/in/app/tph-rapid/id1603236298",
        caption: "UAT Build",
        lastUpdated: "",
      },
      {
        env: ["rapid"],
        link: "https://apps.apple.com/in/app/tph-rapid/id1603236298",
        caption: "PROD Build",
        lastUpdated: "",
      },
    ],
  },
  {
    platform: "Android",
    version: "1.0.18",
    instruction: "scan below QR code from your mobile camera",
    envs: [
      {
        env: ["dev-rapid", "localhost"],
        caption: "DEV Build",
        link: "https://exp-shell-app-assets.s3.us-west-1.amazonaws.com/android/%40ynirman/xlparts-rapid-9f1ce92257244a4d8f281785b310977a-signed.apk",
        lastUpdated: "2021-08-10T11:30:00.000Z",
      },
      {
        env: ["uat-rapid"],
        caption: "UAT Build",
        link: "https://play.google.com/store/apps/details?id=com.thepartshouse.rapid",
        lastUpdated: "2021-08-31T16:00:00.000Z",
      },
      {
        env: ["rapid"],
        link: "https://play.google.com/store/apps/details?id=com.thepartshouse.rapid",
        caption: "PROD Build",
        lastUpdated: "2022-02-17T08:37:39.429Z",
      },
    ],
  },
];
export const searchWords = ["PROGRAMMABLE"];

export const isProgrammable = (desc) => {
  let bool = false;
  searchWords.forEach((w) => {
    if (desc.includes(w)) {
      bool = true;
    }
  });

  return bool;
};
export const apks = [
  {
    env: ["dev-cep", "localhost"],
    caption: "DEV Build",
    link: "https://exp-shell-app-assets.s3.us-west-1.amazonaws.com/android/%40yeshwanthdornala/cep-mobile-app-fde4a5fd79574e9e99c48939425ff9d4-signed.apk",
    lastUpdated: "2021-07-28T12:00:00.000Z",
  },
  {
    env: ["uat-cep"],
    caption: "UAT Build",
    link: "https://exp-shell-app-assets.s3.us-west-1.amazonaws.com/android/%40yeshwanthdornala/cep-mobile-app-9a92bbd382d843e68665228661602bc9-signed.apk",
    lastUpdated: "2021-07-29T04:00:00.000Z",
  },
  {
    env: ["uat-rapid"],
    caption: "UAT Build",
    link: "https://exp-shell-app-assets.s3.us-west-1.amazonaws.com/android/%40ynirman/tph-rapid-a88528635c7f4a30bb467b40cb454915-signed.apk",
    lastUpdated: "2021-12-07T04:30:00.000Z",
  },
];
export const oemBuilderPartsMenu = [
  {
    label: "Engine",
    link: "/ck_partsearch",
    type: "engine",
  },
  {
    label: "Automatic Transmission",
    link: "/ck_partsearch",
    type: "automaticTransmission",
  },
  {
    label: "Manual Transmission",
    link: "/ck_partsearch",
    type: "manualTransmission",
  },
  {
    label: "Differential",
    link: "/ck_partsearch",
    type: "reardifferential",
  },
  {
    label: "Transfer Cases",
    link: "/ck_partsearch",
    type: "transfercase",
  },
  {
    label: "Previous Lookups",
    link: "/ck_previous-look-ups",
  },
];