
const { createSlice } = require("@reduxjs/toolkit");

let initialState = {
  partSearchText: null,
  showLoader: false,
  shippingInfo: {
    sellerPartnerId: null,
    storeNumber: null,
    shipMethod: null
  },
  groupSelectedFromSearch: null,
  expressToggleEnabled: false,
  redirectToExpressHome: null,
  isExpressCheckoutClicked : false,
  requestGroups: null,
  requestVehicle: {
    year: null,
    makeId: null,
    modelId: null,
    engineId: null
  }
};

const expressCart = createSlice({
  name: "expressCart",
  initialState,
  reducers: {
    setPartSearchText: (state, action) => {
      const { payload } = action;
      state.partSearchText = payload.searchText;
    },
    setShowLoader: (state, action) => {
      const { payload } = action;
      state.showLoader = payload.showLoader;
    },
    setShippingInfo: (state, action) => {
      const { shippingInfo } = action.payload;
      state.shippingInfo = {
        sellerPartnerId: shippingInfo.sellerPartnerId,
        storeNumber: shippingInfo.storeNumber,
        shipMethod: shippingInfo.shipMethod
      }
    },
    setPartsAPIRequest: (state, action) => {
      const { reqVehicle, reqGroups } = action.payload;
      state.requestGroups = reqGroups;
      state.requestVehicle = reqVehicle;
    },
    setGroupSelectedFromSearch: (state, action) => {
      const { payload } = action;
      state.groupSelectedFromSearch = payload;
    },
    setExpressToggle: (state, action) => {
      const { payload } = action;
      state.expressToggleEnabled = payload.isExpress;
    },
    setExpressHomepage: (state, action) => {
      const { payload } = action;
      state.redirectToExpressHome = payload.expressHome;
    },
    setExpressCheckoutClicked: (state, action) => {
      const { payload } = action;
      state.isExpressCheckoutClicked = payload.isExpressCheckoutClicked;
    }
  },
  extraReducers: {
  },
});

const { actions, reducer } = expressCart;

export const { setPartSearchText, setShowLoader, setShippingInfo, setPartsAPIRequest, setExpressToggle, setExpressHomepage, setGroupSelectedFromSearch, setExpressCheckoutClicked } = actions;

export default reducer;
