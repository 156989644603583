import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from 'lodash/isEmpty';
import _ from "lodash";
import Placeholder from '../shared/Placeholder';
import moment from "moment";
import _cloneDeep from 'lodash/cloneDeep';
import ImagesSlider from "../Listing/ImagesSlider/index";
import NoDataState from './popUp/NoDataState';
import ReturnDetailsIicon from './popUp/ReturnDetailsIicon';
import CreditMemoTable from './popUp/CreditMemoTable';
import LineItems from './LineItems';
import StoreManualProcessPostData from './popUp/StoreManualProcessPostData';
import HeaderLineItem from './HeaderLineItem';
import storage from "../../helpers/storage";

function HomePage(props) {
  let {
    returnsLoading,
    OPERATIONS_MANAGER,
    partLineItemRows,
    handlePageChange,
    ExpandRows,
    USERROLE,
    displayErrorMsg,
    themeColor,
    STORE_USER,
    AREA_MANAGER,
    clearHeaderId,
    userType,
    CC_MANAGER
  } = props;

  const parts = useSelector((state) => state.parts);
  let headerId = parts.headerId;
  const dispatch = useDispatch();
  const [showModal, setshowModal] = useState(false);
  const [showModalID, setshowModalID] = useState("");
  const [confirmNumber, setConfirmNumber] = useState("");
  const [rowdata, setRowsdata] = useState([...partLineItemRows]);
  const [confirmData, setConfirmData] = useState([]);
  const [creditLoader, setCreditLoader] = useState(false);
  const [headerSaveAndPostId, setHeadderSaveAndPostId] = useState("");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [returnDetailsId, setReturnDetailsId] = useState("");
  const [showImagesSlider, setShowImagesSlider] = useState(false);
  const [imgSlide, setImageSlide] = useState([]);
  const [showManalModal, setShowManualModal] = useState(false);
  const [ManualProcessId, setManualProcessId] = useState("");
  const [lineItemEditedData, SetLineItemEditedData] = useState([]);


  const DRIVER_DELIVERED = "DRIVER_DELIVERED";
  const DRIVER_INITIATED = "DRIVER_INITIATED";
  const CUSTOMER_INITIATED = "CUSTOMER_INITIATED";
  const STORE_DECLINED = "STORE_DECLINED";
  const STORE_APPROVED = "STORE_APPROVED";
  const DRIVER_COLLECTED = "DRIVER_COLLECTED";
  const INITIATED = "INITIATED";
  const STORE_REVIEWED = "STORE_REVIEWED";
  const ESCALATED = "STORE_ESCALATED";
  const AM_APPROVED = "AM_APPROVED";
  const AM_DECLINED = "AM_DECLINED";
  const MANUALLY_PROCESSED = "MANUALLY_PROCESSED";
  const STORE_INITIATED = "STORE_INITIATED";
  const INVOICE_ERROR_MSG = "Return cannot be processed as this part has already been returned in the past  for the selected invoice."

  const options = [
    { label: "New", value: "New" },
    { label: "Core", value: "Core" },
    { label: "Warranty", value: "Warranty" },
  ];

  useEffect(() => {
    prepareData();
  }, [partLineItemRows])


  const prepareData = () => {
    let data = _cloneDeep(partLineItemRows);
    console.log("🚀 ~ file: HomePage.jsx:82 ~ !_isEmpty ~ data", data)
    !_isEmpty(data) && data.map((v, key) => {
      v["saveButtonEnabled"] = "";
      v['refreshAllLineItem'] = false;
      v['isExpand']=v.hasOwnProperty("isExpand")?v.isExpand:true;
      !_isEmpty(v.lineItems) &&
        v.lineItems.map((line, index) => {
          line["inVoiceOptions"] = prepareInvoiceOptions(line.invoices, line.returnType);
          line['rowId'] = line.id;
          line['lineSpaceError'] = true;
          line['lineItemLoader'] = false;
          line['dateFlag'] = _isEmpty(line.purchaseDate) ? true : false;
          line['isPLDropDownSelected'] = true;
          line['newItemAdded'] = false;
          line['hideNewItemAdded'] = false;
          line['lineStatus'] = line.status ? line.status : "";
          line['pl'] = (line.status === STORE_APPROVED || line.status === STORE_DECLINED || line.status === ESCALATED || line.status === AM_APPROVED || line.status === AM_DECLINED) ? line.storeMfgCode : (line.status === DRIVER_DELIVERED || line.status === DRIVER_COLLECTED) ? line.pickupMfgCode : line.mfgCode;
          line['partNumb'] = (line.status === STORE_APPROVED || line.status === STORE_DECLINED || line.status === ESCALATED || line.status === AM_APPROVED || line.status === AM_DECLINED) ? line.storePartNumber : (line.status === DRIVER_DELIVERED || line.status === DRIVER_COLLECTED) ? line.pickupPartNumber : line.partNumber;
          line['isEditableField'] = ((v.returnType === DRIVER_INITIATED || v.returnType === CUSTOMER_INITIATED || v.returnType === STORE_INITIATED) && line.status === DRIVER_DELIVERED ? true : false); // only customer initiated , driver initiated and store initiated returns can have editable fields.
          line['customerInitiated'] = (line.status === DRIVER_DELIVERED && (v.returnType === CUSTOMER_INITIATED || v.returnType === STORE_INITIATED)) ? true : false;
          line['disableDriverInitiatedline'] = ((v.returnType === DRIVER_INITIATED && line.status === DRIVER_DELIVERED) ? true : false); // This flag is used for driver-initiated returns. From the API we are not getting description, price etc data  in the case of driver initiated returns , so from the UI we are making pricing API calls mandatory (validate icon used for pricing calls ) .
          line["storeQty"] = (line.status === STORE_APPROVED || line.status === STORE_DECLINED || line.status === DRIVER_DELIVERED || line.status === ESCALATED || line.status === AM_APPROVED || line.status === DRIVER_COLLECTED || line.status === AM_DECLINED) ? (line.storeQty > 0 ? line.storeQty : line.pickUpQty) : " ";

        })
    })
    setRowsdata(data);
  }

  // used to preparing the MFG code  or PL
  const prepareMFGCodeDropDown = (pricingAvailabilities, line, flag) => {
    let optionArr = [];
    if (pricingAvailabilities !== undefined && pricingAvailabilities.length > 0) {
      pricingAvailabilities.map((p) => {
        if (p.mfgCode !== "*" && !_isEmpty(p.mfgCode) && !_isEmpty(p.itemSpecific)) {
          optionArr.push({
            value: p.mfgCode + "-" + p.partNum + "-" + p.itemSpecific,
            label: p.mfgCode + "-" + p.partNum + "-" + p.itemSpecific,
            mfgCode: p.mfgCode,
            desc: p.itemSpecific,
            corePrice: p.corePrice,
            costPrice: p.costPrice,
            partNum: p.partNum,
            itemSpecific: p.itemSpecific,
            responseData: pricingAvailabilities,
            flag: flag
          });
        }
      })
    }
    let uniqueDataMFG = _.uniqBy(optionArr, "value");

    return uniqueDataMFG;
  }
  const prepareInvoiceOptions = (invoices, returnType,) => {
    let optionArr = [];
    if (!_isEmpty(invoices) && invoices !== undefined && invoices.length > 0) {
      optionArr.push({
        value: "",
        label: "Unlinked",
      });
      invoices.map((invoice) => {
        if (!_isEmpty(invoice.invoiceNumber)) {
          optionArr.push({
            ...invoice,
            value: invoice.invoiceNumber,
            label: invoice.invoiceNumber,
            availToCredit: returnType === "Core" ? invoice.coreQtyAvailToCredit : invoice.qtyAvailToCredit,
          })
        }
      });
    }

    let uniqueArr = !_isEmpty(optionArr) && _.uniqBy(optionArr, "value");
    const filteredDates = !_isEmpty(uniqueArr) && uniqueArr.sort((a, b) => new moment(b.invoiceDate).format('YYYYMMDD') - new moment(a.invoiceDate).format('YYYYMMDD'));
    return filteredDates;
  }

  //This method is used for enabling and disabling the save and post buttons. save&post button only enabled if one of the lineitem should approved by user
  const isSaveAndPostEnabled = (lineItems) => {

    let isValidArray = !_isEmpty(lineItems) && lineItems.filter(line => line.status).map((name) => name.status);
    let isStoreApproved = isValidArray.includes(STORE_APPROVED);
    let isAMApproved = isValidArray.includes(AM_APPROVED);
    if (isValidArray.includes(DRIVER_DELIVERED) || isValidArray.includes(INITIATED) || isValidArray.includes(DRIVER_COLLECTED) || isValidArray.includes(ESCALATED)) {
      return true;
    } else
      if (!isStoreApproved && !isAMApproved && (isValidArray.includes(STORE_DECLINED) || isValidArray.includes(AM_DECLINED))) {
        return true;
      } else
        if (isStoreApproved || isAMApproved) {
          return false;
        } else
          return true;
  }


  const onAddBtnClick = (returnId) => {
    if (!_isEmpty(rowdata)) {
      rowdata.map((partReturn) => {
        if (partReturn.id === returnId) {
          partReturn.lineItems.unshift({
            lineItemLoader: false,
            id: "",
            rowId: Math.random(),
            customerId: partReturn.customerId,
            returnId: partReturn.id,
            storePartNumber: "",
            lineStatus: "NEW_ITEM",
            newlineItem: true,
            storeQty: 1,
            returnType: "New",
            storeMfgCode: "",
            dateFlag: true,
            lineSpaceError: true,
            hideNewItemAdded: true,
            newItemAdded: true,
          })
        }
      })
    }
    setRowsdata([...rowdata])
  };


  const clearPayloadData = () => {
    dispatch({
      payload: {
        lineItemData: [],
      },
      type: 'STORE_DATA',
    });
    dispatch({
      payload: {
          selectedCoreQtyAvailToCredit:0,
          selectedQtyAvailToCredit:0,
          selectedInvoices:[],

      },
      type: 'SELECTED_QTY_AVAIL_TO_CREDIT',
  });
  dispatch({
    payload: {
      updatedInvoicesWarrantyDates: {},
    },
    type: "UPDATE_INVOICES_WARRANTY_DATES",
  });
  storage.set("IsUnlinked",false,"local");

  }

  const handleCloseMethod = () => {
    handlePageChange("", false);
    setHeadderSaveAndPostId("");
    setshowModal(false);
    clearPayloadData();
  }

  const togglePopup = () => {
    setShowImagesSlider(!showImagesSlider);
  }

  const showPopUpInfo = (id) => {
    setShowInfoModal(true);
    setReturnDetailsId(id);
  }

  //With this method, we are validating  user entered partNumber and pricing call partNumber matching or not
  const checkPartNumberIsValid = (filteredData, partNumber) => {
    let filteredPartNum = !_isEmpty(partNumber) ? (partNumber).replace(/\s/g, '').replace(/[&/\\#,+()$~%.'":*?_^<>{}-]/g, "") : "";
    let data = !_isEmpty(filteredData) && filteredData.filter(item => !_isEmpty(item.partNum) && ((item.partNum).replace(/\s/g, '').replace(/[&/\\#,+()$~%.'":*?_^<>{}-]/g, "") !== filteredPartNum));
    return data;
  }

  // this method is  used to remove the ALT and SUB parts (status code 6 and 3 we are not  considering )
  const removedSupAndAltPart = (payloadData) => {
    let data = !_isEmpty(payloadData) && payloadData.filter(item => (!(item.status === '6' || item.status === '3')));
    return data;
  }

  // This method is used to check the invoice available qty and the user entered verified qty should not exceed
  const checkIsValidAvailableQty = (line) => {
    let specificValuesFromLineItems = lineItemEditedData && lineItemEditedData.filter(obj => obj.rowId ===line.rowId)
    let lineItemObject =specificValuesFromLineItems[0] || line;
    let storeQt=lineItemObject && lineItemObject.storeQty !== undefined ?Number(lineItemObject.storeQty):Number(lineItemObject.qty)

    let storeQty = storeQt !== undefined ? storeQt : Number(line.qty);

    if (!_isEmpty(lineItemObject.invoices) && lineItemObject.invoices.length > 0 && lineItemObject.invoiceAvlQty !== undefined && storeQty !== "" && storeQty !== 0) {
      let isValidQty ="";
      if(lineItemObject.returnType ==="Core"){
        isValidQty=lineItemObject.selectedCoreQtyAvailToCredit;
      }if(lineItemObject.returnType ==="New"){
        isValidQty=lineItemObject.selectedQtyAvailToCredit;
      }
    if(lineItemObject.returnType ==="Warranty"){
      isValidQty=lineItemObject.qtyAvailToCredit;
    }
      if (isValidQty >= Number(storeQty) && Number(storeQty) !== 0) {
        return false;
      } else
        if (lineItemObject.returnType === "New" && storeQty > lineItemObject.totalQtyAvailToCredit && lineItemObject.label !== "Unlinked") {
          return "Return cannot be processed for this invoice as the Qty Available to Credit is less than the Verified Qty";
        } else
          if (lineItemObject.returnType === "Core" && storeQty > lineItemObject.totalCoreQtyAvailToCredit && lineItemObject.label !== "Unlinked") {
            return "Return cannot be processed for this invoice as the Core Qty Available to Credit is less than the Verified Qty"
          } else
            if ((lineItemObject.returnType === "Core" && isValidQty <= 0 && lineItemObject.label !== "Unlinked") || (lineItemObject.returnType === "New" && isValidQty <= 0 &&  lineItemObject.label !== "Unlinked")) {
              // return INVOICE_ERROR_MSG;
                  return "";

            } else
              if (Number(storeQty) === 0 && line.label !== "Unlinked") {
                return "";
              } else
              if(lineItemObject.returnType === "Warranty"  && (((lineItemObject.label !=="Unlinked") && (( Number(storeQty) > lineItemObject.qtyAvailToCredit) || Number(storeQty)>isValidQty)))){
                return "Return cannot be processed for this invoice as the Core Qty Available to Credit is less than the Verified Qty"
              }else if ((lineItemObject.returnType === "Core" && lineItemObject.label === "Unlinked") || (lineItemObject.returnType === "New"  && lineItemObject.label === "Unlinked")) {
                return false
              }


    }
    return false;
  }
  const handleExpandButtonInSpecificHeader=(line)=>{
    console.log("outside check,",line)
    !_isEmpty(rowdata) && rowdata.map((v, key) => {
      if(line.id === v.id){
        v['isExpand']=!v['isExpand'];

      }
    })
    setRowsdata([...rowdata])
  }

  return (
    <div>
      {
        (returnsLoading) ?
          (<div className='loading_content'>
            <Placeholder rows={2} /></div>)
          :
          !_isEmpty(rowdata) ?
            (rowdata.map((v, key) => (
              <div className="white_box_container" key={v.id}>
                <HeaderLineItem
                  STORE_REVIEWED={STORE_REVIEWED}
                  OPERATIONS_MANAGER={OPERATIONS_MANAGER}
                  rowValues={v}
                  DRIVER_DELIVERED={DRIVER_DELIVERED}
                  STORE_USER={STORE_USER}
                  userType={userType}
                  onAddBtnClick={onAddBtnClick}
                  rowdata={rowdata}
                  isSaveAndPostEnabled={isSaveAndPostEnabled}
                  setManualProcessId={setManualProcessId}
                  setHeadderSaveAndPostId={setHeadderSaveAndPostId}
                  setCreditLoader={setCreditLoader}
                  handlePageChange={handlePageChange}
                  setshowModal={setshowModal}
                  setConfirmNumber={setConfirmNumber}
                  clearPayloadData={clearPayloadData}
                  setshowModalID={setshowModalID}
                  prepareData={prepareData}
                  setShowManualModal={setShowManualModal}
                  clearHeaderId={clearHeaderId}
                  displayErrorMsg={displayErrorMsg}
                  headerSaveAndPostId={headerSaveAndPostId}
                  creditLoader={creditLoader}
                  STORE_DECLINED={STORE_DECLINED}
                  AM_DECLINED={AM_DECLINED}
                  setConfirmData={setConfirmData}
                  showPopUpInfo={showPopUpInfo}
                  ExpandRows={ExpandRows}
                  lineItemEditedData={lineItemEditedData}
                  handleExpandButtonInSpecificHeader={handleExpandButtonInSpecificHeader}
                />

                {showInfoModal && (returnDetailsId === v.id) && !_isEmpty(v) && (
                  <ReturnDetailsIicon
                    ReturnData={v}
                    DRIVER_DELIVERED={DRIVER_DELIVERED}
                    STORE_REVIEWED={STORE_REVIEWED}
                    imgSlide={imgSlide}
                    setImageSlide={setImageSlide}
                    setShowImagesSlider={setShowImagesSlider}
                    setShowInfoModal={setShowInfoModal}
                    setReturnDetailsId={setReturnDetailsId}
                  />
                )}

                {v.refreshAllLineItem || (headerId === v.id) ?
                  (<div className='single-loading_content'>
                    <Placeholder rows={1} /></div>) :
                  (<>
                   {!v.isExpand? <span className='NoOfPartsInStoreDashboard'>No of Parts - {v.lineItems.length}</span>:""}

                  <LineItems
                    STORE_REVIEWED={STORE_REVIEWED}
                    STORE_INITIATED={STORE_INITIATED}
                    LineItemRowsData={v.lineItems}
                    OPERATIONS_MANAGER={OPERATIONS_MANAGER}
                    headerLineItems={v}
                    rowdata={rowdata}
                    setRowsdata={setRowsdata}
                    ExpandRows={ExpandRows}
                    DRIVER_DELIVERED={DRIVER_DELIVERED}
                    DRIVER_INITIATED={DRIVER_INITIATED}
                    DECLINED={userType === STORE_USER ? STORE_DECLINED : AM_DECLINED}
                    APPROVED={userType === STORE_USER ? STORE_APPROVED : AM_APPROVED}
                    MANUALLY_PROCESSED={MANUALLY_PROCESSED}
                    ESCALATED={ESCALATED}
                    prepareInvoiceOptions={prepareInvoiceOptions}
                    prepareMFGCodeDropDown={prepareMFGCodeDropDown}
                    checkPartNumberIsValid={checkPartNumberIsValid}
                    removedSupAndAltPart={removedSupAndAltPart}
                    displayErrorMsg={displayErrorMsg}
                    handlePageChange={handlePageChange}
                    isSaveAndPostEnabled={isSaveAndPostEnabled}
                    themeColor={themeColor}
                    options={options}
                    USERROLE={USERROLE}
                    userType={userType}
                    STORE_USER={STORE_USER}
                    CC_MANAGER={CC_MANAGER}
                    AREA_MANAGER={AREA_MANAGER}
                    CUSTOMER_INITIATED={CUSTOMER_INITIATED}
                    clearHeaderId={clearHeaderId}
                    checkIsValidAvailableQty={checkIsValidAvailableQty}
                    INVOICE_ERROR_MSG={INVOICE_ERROR_MSG}
                    SetLineItemEditedData={SetLineItemEditedData}
                  />
                  </>)}
                {(showModal && showModalID === v.id) &&
                  (
                    <CreditMemoTable
                      showModal={showModal}
                      handleCloseMethod={handleCloseMethod}
                      confirmNumber={confirmNumber}
                      confirmData={confirmData}
                    />
                  )}
                {(showImagesSlider && (returnDetailsId === v.id)) && (
                  <ImagesSlider
                    closePopup={togglePopup}
                    openPopup={showImagesSlider}
                    partDetails={imgSlide}
                  />
                )}
                {(showManalModal && ManualProcessId === v.id) && (
                  <StoreManualProcessPostData
                    showManalModal={showManalModal}
                    setShowManualModal={setShowManualModal}
                    ManualProcessId={ManualProcessId}
                    setManualProcessId={setManualProcessId}
                    displayErrorMsg={displayErrorMsg}
                    handleCloseMethod={handleCloseMethod}
                    prepareData={prepareData}
                    clearHeaderId={clearHeaderId}
                  />)}
              </div>
            ))) : (
              <div className="NoData_store_container">
                <NoDataState />
              </div>)
      }
    </div>
  )
}

export default HomePage;
