// getShipToIds
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getShipToIds,getUserShipToIds
} from './shipTo.service';

export const fetchShipToIds = createAsyncThunk(
    "fetchShipToIds",
    async (payload, { signal }) => {
        const response = await getShipToIds(payload);
        return response.data;
    }
)

export const fetchUserShipToIds = createAsyncThunk(
    "fetchUserShipToIds",
    async (payload)  => {
        const response = await getUserShipToIds();
        return response.data;
    }
)