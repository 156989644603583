import React, { useState, useRef, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";
import { Col, Form, Row } from "react-bootstrap";
import CartSinglePart from "./CartSinglePart";
import Placeholder from "../shared/Placeholder";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import XLLogo from "../../static/images/TPH Rapid.png";
import { formatAmount } from "../../helpers/utils";
import Moment from "react-moment";
import { fetchOrders } from "../shared/MyGarage/mygarage.thunks";
import { getCartItemsByAcxNum } from "../../actions/cart";

function CartOrdersDetails() {
  let params = useParams();
  const acxNumCartApiCall = useSelector(
    (state) => state.cart.acxNumCartApiCall
  );
  const acxNumDetails = useSelector((state) => state.cart.acxNumCartData);
  const cartApiFetching = useSelector((state) => state.cart.cartApiFetching);
  const hideCostPrice = useSelector((state) => state.cart.hideCostPrice);
  const dispatch = useDispatch();
  const mygarage = useSelector((state) => state.mygarage);
  const mygarageOrder = mygarage.orders;
  const userDetails = useSelector((state) => state.user.userDetails);

  useEffect(() => {
    initialFetchOrders();
  }, []);

  useEffect(() => {
    getCartItemsByAcxNum(params.acxNum, dispatch);
  }, []);

  const initialFetchOrders = () => {
    try {
      dispatch(fetchOrders());
    } catch (error) {}
  };

  const handleTrackOrderClick = (orderStatus, orderNumber) => {
    window.open(
      orderStatus !== "DELIVERED"
        ? `/order-delivery-status/${orderNumber}`
        : "",
      "_blank"
    );
  };
  const renderPartDetails = () => {
    let partSection = [];

    if (!_isEmpty(acxNumDetails)) {
      let cartStore =
        acxNumDetails[0] && acxNumDetails[0].store
          ? acxNumDetails[0].store
          : "";
      let defultDeliveryStore =
        userDetails &&
        userDetails.shipTos &&
        userDetails.shipTos[0] &&
        userDetails.shipTos[0].location &&
        userDetails.shipTos[0].location.storeNumber
          ? userDetails.shipTos[0].location.storeNumber
          : "";
      let pickupDetails =
        defultDeliveryStore == cartStore ? "Delivery" : "Pickup";

      acxNumDetails.items.map((part, key) => {
        partSection.push(
          <React.Fragment>
            {acxNumDetails.items[key - 1] &&
            acxNumDetails.items[key - 1].vehicleInfo ===
              part.vehicleInfo ? null : (
              <React.Fragment>
                <p>{part.vehicleInfo}</p>
              </React.Fragment>
            )}
            <CartSinglePart
              qtyValidity={part.qtyValidity || {}}
              partDetails={part}
              previousPartDetails={acxNumDetails.items[key - 1] || {}}
              handleDeleteItem={""}
              handleInputOnBlur={""}
              handleInputOnChange={""}
              hideCostPrice={hideCostPrice}
              modifiedDate={part.modifiedDate}
            />
          </React.Fragment>
        );
      });
      return partSection;
    } else {
      return <span className="noitems_text">No order found</span>;
    }
  };
  return (
    <div>
      {!acxNumCartApiCall && !mygarage.ordersLoading ? (
        <div className="order_delivery_status_container">
          <div className="wrapper">
            <div className="order_delivery_status_header">
              <img src={XLLogo} alt="XL Parts" />
              {mygarageOrder.map((v, i) =>
                v.acxNum === params.acxNum ? (
                  <div className="product_delivery_detail">
                    <span>welcome to the parts house</span>
                    <div>
                      <div>
                        <span className="marron_text"># Of Parts:</span>
                        <span>{v.noOfPartSKUs}</span>
                      </div>

                      <div>
                        <span className="marron_text">Total QTY:</span>
                        <span>{v.orderQty}</span>
                      </div>
                      <div>
                        <span className="marron_text">Amount:</span>
                        <span className="order__amount">
                          ${formatAmount(v.orderAmount)}
                        </span>
                      </div>
                      {v.orderDate && (
                        <div>
                          <span className="marron_text">Order Date:</span>
                          <span>
                            <Moment format="MM-DD-YYYY" date={v.orderDate} />
                          </span>
                        </div>
                      )}
                      {v.acxNum && (
                        <div>
                          <span className="marron_text">ACX #:</span>
                          <span>{v.acxNum}</span>
                        </div>
                      )}
                      {v.poNumber && (
                        <div>
                          <span className="marron_text">PO #:</span>
                          <span>{v.poNumber}</span>
                        </div>
                      )}

                      {!_isEmpty(acxNumDetails) &&
                      acxNumDetails.userName != undefined ? (
                        <div>
                          <span className="marron_text">User Email:</span>
                          <span>{acxNumDetails.userName}</span>
                        </div>
                      ) : (
                        <span> </span>
                      )}
                    </div>

                    <div>
                      {v.orderNumber && (
                        <div>
                          <span className="marron_text">Order #:</span>
                          <span>{v.orderNumber}</span>
                        </div>
                      )}
                      <span className="marron_text">
                        {!_isEmpty(v.invoiceNos) && v.invoiceNos.length > 1
                          ? `Invoices: `
                          : !_isEmpty(v.invoiceNos) && v.invoiceNos.length == 1
                          ? `Invoice #: `
                          : " "}
                      </span>
                      {v.invoiceNos &&
                        v.invoiceNos.length > 0 &&
                        v.invoiceNos.map((inv, key) =>
                          !_isEmpty(v.invoiceNos) &&
                          _isEmpty(inv.invoiceUrl) ? (
                            <div>
                              <span>
                                {v.invoiceNos.length > 1 &&
                                key !== v.invoiceNos.length - 1
                                  ? inv.invoiceNumber.concat(", ")
                                  : inv.invoiceNumber}
                              </span>
                            </div>
                          ) : (
                            !_isEmpty(v.invoiceNos) &&
                            !_isEmpty(inv.invoiceUrl) && (
                              <div className="track__link mr-2">
                                <Link
                                  onClick={() => {
                                    window.open(inv.invoiceUrl, "_blank");
                                  }}
                                >
                                  <span
                                    className={
                                      !_isEmpty(inv.invoiceUrl)
                                        ? "cart_track_invoice_link"
                                        : " "
                                    }
                                  >
                                    {v.invoiceNos.length > 1 &&
                                    key !== v.invoiceNos.length - 1
                                      ? inv.invoiceNumber.concat(", ")
                                      : inv.invoiceNumber}
                                  </span>
                                </Link>
                              </div>
                            )
                          )
                        )}
                      <div
                        className={
                          !_isEmpty(v.invoiceNos)
                            ? "text__right d-flex"
                            : "text__right"
                        }
                      >
                        {!_isEmpty(v.invoiceNos) && (
                          <div className="track__link mr-2">
                            <Link
                              onClick={() => {
                                handleTrackOrderClick(
                                  v.orderStatus,
                                  v.orderNumber
                                );
                              }}
                            >
                              <span className="cart_track_marron_text">
                                {" "}
                                {v.orderStatus === "DELIVERED" ||
                                v.orderStatus === "COMPLETED" ||
                                v.orderStatus === "PARTIALLY_COMPLETED" ||
                                v.orderStatus === "CANCELED"
                                  ? "Order Details"
                                  : "TRACK ORDER"}
                              </span>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <span></span>
                )
              )}
            </div>
            <div className="gray_box_container cart_scroll_cartOrder">
              <div className="cart-order-heading">Cart History Details</div>
              {!acxNumCartApiCall || !cartApiFetching ? (
                renderPartDetails()
              ) : (
                <Placeholder rows={2} />
              )}
            </div>
          </div>{" "}
        </div>
      ) : (
        <Placeholder rows={2} />
      )}
    </div>
  );
}

export default CartOrdersDetails;
