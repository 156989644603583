/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Form,
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { withRouter } from "react-router";
import "react-multi-carousel/lib/styles.css";
import _ from "lodash";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import _findIndex from "lodash/findIndex";
import _cloneDeep from "lodash/cloneDeep";
import _remove from "lodash/remove";
import _find from "lodash/find";
import _flatten from "lodash/flatten";
import _map from "lodash/map";
import _uniq from "lodash/uniq";

import { statesList } from "../../helpers/constants";
import {
  timeInUTC,
  getDefaultDataBasedOnUSerPref,
  getPreferenceDataFromStorage,
  getRequestPayloadForOrchestratorAPI,
  setImageURLCookie,
  isImageURLCookieValid,
  showReleaseNotes,
  hideReleaseNotes,
  getDefaultPreference,
  getPartsPreferredBrands,
  getCookie,
  setCookie,
} from "../../helpers/utils";

import { connect } from "react-redux";
import {
  getPartsByMultiGroupOrchestrator,
  getChemPartByGrp,
} from "../../actions/parts";
import { Redirect } from "react-router-dom";
import { getPromotionForParts } from "../../actions/user";

import { getPartImageURLCookie } from "../User/UserManagement/usermanagement.thunk";

import {
  setVehicles,
  fetchPartTypes,
} from "../shared/MyGarage/mygarage.thunks";
import {
  setVehicleSelected,
  setJobSelected,
} from "../shared/MyGarage/mygarage.slice";

import {
  getAllYears,
  getMakesForYear,
  getModelsForMake,
  getEngineForYearMakeModel,
  getVehicleByVinNumber,
  getVehicleByLicensePlate,
} from "../../actions/search";

import {
  populateSearchDropdownOptions,
  validateSearchWrapper,
  validateState,
} from "../../helpers/utils";

import ProPackContainer from "./ProPacks";
import ClassicProPackContainer from "./ProPacks/Classic";
import SearchWrapper from "./Search/index";
import CategoryWrapper from "./Categories";
import ClassicCategoryWrapper from "./Categories/Classic";
import PromotionsTab from "../shared/Promotions";
import clsx from "clsx";
import ReleaseNotePopup from "./ReleaseNotePopup";
import storage from "../../helpers/storage";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SimpleHomePage from "./SimpleHomepage/index";
import { setExpressToggle } from "./SimpleHomepage/simplehomepage.slice";
import { logUserStockActivity } from "../../actions/user";
import { vehicleSelectPrompt } from "../../helpers/utils";
import { triggerCustomUxCriticalError } from "../../actions/error";

const actionTypes = {
  CLEAR_MODEL_AND_ENGINE: "CLEAR_MODEL_AND_ENGINE",
  CLEAR_ENGINE: "CLEAR_ENGINE",
  CLEAR_YMME_LISTS: "CLEAR_YMME_LISTS",
  GROUP_SELECTED: "GROUP_SELECTED",
  ENGINE_FOR_YEAR_MAKE_MODEL: "ENGINE_FOR_YEAR_MAKE_MODEL",
  MAKES_FOR_YEAR: "MAKES_FOR_YEAR",
  MODEL_FOR_MAKE_AND_YEAR: "MODEL_FOR_MAKE_AND_YEAR",
  PROPACK_SELECTED: "PROPACK_SELECTED",
  VEHICLE_SELECTION_COMPLETED: "VEHICLE_SELECTION_COMPLETED",
  SELECTED_PART_CATALOG_TYPES: "SELECTED_PART_CATALOG_TYPES",
  HOME_PAGE_PART_TYPES: "HOME_PAGE_PART_TYPES",
};
class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.yearRef = React.createRef();
    this.makeRef = React.createRef();
    this.modelRef = React.createRef();
    this.engineRef = React.createRef();
    let selectedVehicle = props.selectedVehicle
      ? props.selectedVehicle
      : {
          year: "",
          make: "",
          makeId: "",
          model: "",
          modelId: "",
          engine: "",
          engineId: "",
          trim: "",
        };
    let selectedState = {};
    if (props.userDetails) {
      selectedState = _find(statesList, {
        value:
          (this.props.userDetails.shipTos &&
            this.props.userDetails.shipTos[0] &&
            this.props.userDetails.shipTos[0].state) ||
          "TX",
      });
    }
    this.state = {
      partTypeSelectionData: {selectedGroups : []},
      redirectToProductList: false,
      searchData: {
        yearList: !_isEmpty(props.yearList)
          ? populateSearchDropdownOptions(this.props.yearList)
          : [],
        makeList: !_isEmpty(props.makeList)
          ? populateSearchDropdownOptions(this.props.makeList)
          : [],
        modelList: !_isEmpty(props.modelList)
          ? populateSearchDropdownOptions(this.props.modelList)
          : [],
        engineList: !_isEmpty(props.engineList)
          ? populateSearchDropdownOptions(this.props.engineList)
          : [],
        trimList: [],
        vinNumber: "",
        selectedState,
        showFluidsAndFilters: false,
        showVehicleMaintenance: false,
      },
      selectedVehicle,
      selectedGroupDetails: [],
      selectedProPack: {},
      showMessage: false,
      userPromotionDetails: !_isEmpty(props.userPromotionDetails)
        ? props.userPromotionDetails
        : [],
      vehicleSelectionDone: false,
      viewPromotions: true,
      validity: this.intializeValidations(),
      stateValidity: this.intializeStateValidations(),
      showClassicView: false,
      showReleaseNotesPopup: false,
      showYmmeOnly:
        this.props.location &&
        this.props.location.pathname &&
        this.props.location.pathname === "/YMMEOnly"
          ? true
          : false,
      userPrefView: "",
      showExpressButton: true,
      showExpressCatalog: false,
      partTypeDataArr: [],
      groupPartsHomepage: [],
    };
    let { dispatch } = props;
    // fix for selection while coming back to page
    dispatch({
      payload: {
        selectedGroupDetails: [],
      },
      type: actionTypes.GROUP_SELECTED,
    });
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.clearLists = this.clearLists.bind(this);
    this.handleInputOnBlur = this.handleInputOnBlur.bind(this);
    this.handleGroupClick = this.handleGroupClick.bind(this);
    this.closeReleaseNotes = this.closeReleaseNotes.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.partTypeAPICall = this.partTypeAPICall.bind(this);
    this.PayloadData = this.PayloadData.bind(this);
    this.PartTypePayloadData = this.PartTypePayloadData.bind(this);
    this.clearPayload = this.clearPayload.bind(this);
  }

  handleLoad = (event) => {
    if (window.opener) {
      window.opener.postMessage("loaded", "*");
    }
  };

  clearPayload = () => {
    let { dispatch } = this.props;
    dispatch({
      payload: {
        selectedCatalogPartTypes: [],
      },
      type: "SELECTED_PART_CATALOG_TYPES",
    });
    dispatch({
      payload: {
        homePagePartTypes: [],
      },
      type: "HOME_PAGE_PART_TYPES",
    });
    this.setState({ partTypeSelectionData : { selectedGroups:  []}});
  };

  partTypeAPICall = (checked, data, category, flag) => {
    let { groupPartsHomepage, partTypeDataArr, partTypeSelectionData } = this.state;
    let categoryCode = category.code ? category.code : category;
    let { partTypeData, selectedPartTypes, myGarageSelectedVehicle, dispatch } =
      this.props;
    let selectedPartType = _.cloneDeep(selectedPartTypes);
    let findPartType =
      !_.isEmpty(partTypeData) &&
      partTypeData.find((item) => item.code == data.code && category.code === item.categoryId);
    if (checked && _isEmpty(findPartType)) {
      let requestItems = [data.code];
      if (requestItems !== undefined && !_isEmpty(myGarageSelectedVehicle)) {
        let res = [];
        let payload = {
          year: myGarageSelectedVehicle ? myGarageSelectedVehicle.year : "",
          make: myGarageSelectedVehicle ? myGarageSelectedVehicle.makeId : "",
          model: myGarageSelectedVehicle ? myGarageSelectedVehicle.modelId : "",
          engine: myGarageSelectedVehicle
            ? myGarageSelectedVehicle.engineId
            : "",
          requestItems,
        };
        this.setState({ partTypeSelectionData : {selectedGroups:  [...category.groups, ...partTypeSelectionData.selectedGroups]}});
        dispatch(fetchPartTypes(payload)).then((action) => {
          this.setState({ partTypeSelectionData : {selectedGroups:  [...category.groups, ...partTypeSelectionData.selectedGroups]}});
           if (!_isEmpty(action.payload)) {
            res = [...action.payload].map((item) => ({
              ...item,
              ptSelected: true,
            }));
            [...action.payload].map((item) => {
              partTypeDataArr.push({
                ...item,
                ptSelected: true,
              });
            });
          }
          let newGroup = {
            code: data.code,
            uniqueId: data.code + "-" + categoryCode,
            name: data.name,
            categoryId: category.code ? category.code : category,
            selectAll: true,
            selected: category.selected
            ? category.selected
            : flag
            ? true
            : category.canSelect,
            active: true,
            isSelected: true,
            partTypes: _.uniqBy(res, "id"),
          };

          if(category.enableSelectAll) {
            groupPartsHomepage.push(newGroup);
          } else {
            groupPartsHomepage.unshift(newGroup);
          }
          if (category.enableSelectAll) {
            const sortedGroupPartsHomepage = [];
            category.groups.forEach((categoryGroup) => {
              const foundGroup = groupPartsHomepage.find((group) => ((group.code === categoryGroup.code) && (group.categoryId === category.code)));
              if (foundGroup) {
                sortedGroupPartsHomepage.push(foundGroup);
              } else {
                sortedGroupPartsHomepage.push(categoryGroup);
              }
            });
            // Add the remaining items from groupPartsHomepage that are not already in sortedGroupPartsHomepage
            groupPartsHomepage.forEach((group) => {
              const foundGroup = sortedGroupPartsHomepage.find((sortedGroup) => (sortedGroup.code === group.code) && (group.categoryId === category.code));
              if (!foundGroup) {
                sortedGroupPartsHomepage.push(group);
              }
            });
            groupPartsHomepage = sortedGroupPartsHomepage;
          }

          if (!_isEmpty(groupPartsHomepage)) {
            partTypeDataArr.map((v) => selectedPartType.push(v));
            this.PartTypePayloadData(groupPartsHomepage);
            this.PayloadData(selectedPartType);
          }
        });
      }
    } else if (
      checked &&
      !_isEmpty(findPartType) &&
      !_isEmpty(groupPartsHomepage)
    ) {
    const matchingItem = groupPartsHomepage.find(
        (item) => item.code === data.code && categoryCode === item.categoryId
      );
      if (matchingItem) {
        matchingItem.selectAll = true;
        matchingItem.selected = category.selected
          ? category.selected
          : flag
          ? true
          : category.canSelect;
        matchingItem.isSelected = true;
        matchingItem.partTypes.forEach((v) => {
          v.ptSelected = true;
          selectedPartType.push(v);
        });
        // Remove the matching item from its current position
        groupPartsHomepage = groupPartsHomepage.filter(
          (item) => item.code !== data.code || categoryCode !== item.categoryId
        );
       // Add the matching item to the first index
        groupPartsHomepage.unshift(matchingItem);
      }
      this.PayloadData(selectedPartType);
      this.PartTypePayloadData(groupPartsHomepage);
    } else if (!checked && !_isEmpty(groupPartsHomepage)) {
      [...groupPartsHomepage].map((item) => {
        if (item.code === data.code && categoryCode === item.categoryId) {
          item.isSelected = false;
          item.selectAll = false;
          item.partTypes.map((v) => {
            v.ptSelected = false;
            _.remove(selectedPartType, (sp) => sp.id === v.id);
          });
        }
      });
      this.PartTypePayloadData(groupPartsHomepage);
      this.PayloadData(selectedPartType);
    }
  };

  PayloadData = (partType) => {
    let { dispatch } = this.props;
    let data = _.uniqBy(partType, "id");
    dispatch({
      payload: {
        selectedCatalogPartTypes: _.cloneDeep(data),
      },
      type: "SELECTED_PART_CATALOG_TYPES",
    });
    this.setState({ partTypeDataArr: [] });
  };

  PartTypePayloadData = (partType, type) => {
    let { dispatch } = this.props;
    let data = _.uniqBy(partType, "uniqueId");
    dispatch({
      payload: {
        homePagePartTypes: _.cloneDeep(data),
      },
      type: "HOME_PAGE_PART_TYPES",
    });
    this.setState({ groupPartsHomepage: data });
  };

  handleYmmeFromCatalog = (event) => {
    if (
      event &&
      event.data &&
      typeof event.data === "string" &&
      event.data.includes("engine")
    ) {
      const sv = JSON.parse(event.data);
      console.log("selected vehicle from parent: ", event.data);
      const { engine, make, model, year } = sv;

      if (engine && make && model && year) {
        const { dispatch } = this.props;
        dispatch({
          payload: {
            selectedVehicle: sv,
          },
          type: "VEHICLE_SELECTION_COMPLETED",
        });
        const payload = { ...sv, updatedDate: timeInUTC() };
        // dispatch(setVehicles(payload));
        dispatch(setVehicleSelected(payload));
        this.clearLists([], true);
      }
    }
  };

  logUserActivity = (isActivityEnabled, activityName) => {
    let { userDetails, dispatch } = this.props;
    const { x2userName, shipTos, billTo } = userDetails;
    if (isActivityEnabled && !_isEmpty(userDetails) && !_isEmpty(billTo)) {
      let loginId = "";
      let shipToId = shipTos[0]?.shipToId;
      let userData = "";
      dispatch(
        logUserStockActivity(
          timeInUTC(),
          billTo.billToId,
          loginId,
          shipToId,
          activityName,
          userData,
          x2userName
        )
      );
    }
  };

  expressPreference = () => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    if (!_isEmpty(userPreferenceDetail) && !_isEmpty(this.props.userDetails)) {
      let grandAccessExpress = userPreferenceDetail.find((item) => {
        return (
          item.text === "express-customer" && item.code === "User Management"
        );
      });
      if (grandAccessExpress && grandAccessExpress.possibleAnswer) {
        grandAccessExpress.possibleAnswer.map((singleAnswer) => {
          const arr = singleAnswer.text.split(";");
          let foundExpressId = arr.find((item) => {
            return item === this.props.userDetails?.xlPartAccountNumber;
          });
          if (
            foundExpressId !== undefined &&
            singleAnswer.default &&
            foundExpressId === this.props.userDetails?.xlPartAccountNumber
          ) {
            this.setState({ showExpressButton: true });
          }
        });
      }
    }
  };

  setDefaultView = () => {
    let { dispatch } = this.props;
    const userPreferenceDetail = getPreferenceDataFromStorage();
    if (!_isEmpty(userPreferenceDetail)) {
      let { showExpressButton } = this.state;
      let selectedViewType = {};
      //let presentionMode = getDefaultPreference('Parts Catalog', 'Presentation Mode');
      selectedViewType = _find(userPreferenceDetail, {
        code: "Parts Catalog",
        text: "Presentation Mode",
      });
      let defaultAnswer =
        (selectedViewType &&
          selectedViewType.answer &&
          selectedViewType.answer.code &&
          selectedViewType.answer.code) ||
        null;
      let possibleAnswerData =
        selectedViewType.possibleAnswer && selectedViewType.possibleAnswer;
      defaultAnswer =
        defaultAnswer == null
          ? possibleAnswerData && possibleAnswerData.find((v) => v.default).code
          : defaultAnswer;
      defaultAnswer =
        !showExpressButton && defaultAnswer === "express"
          ? possibleAnswerData && possibleAnswerData.find((v) => v.default).code
          : defaultAnswer;
      dispatch(setExpressToggle({ isExpress: defaultAnswer === "express" }));
      this.logUserActivity(
        defaultAnswer === "express" &&
          process.env.REACT_APP_EXPRESS_USER_ACTIVITY_TRACKER,
        "EXPRESS-TOGGLE"
      );
      this.setState({
        showClassicView: defaultAnswer === "classic",
        showIllustratedView: defaultAnswer === "illustrated",
        showExpressView: defaultAnswer === "express",
        userPrefView: defaultAnswer,
      });
    }
  };
  componentDidMount() {
    const { dispatch, userDetails, smsVinNumber, catalogFilter } = this.props;
    if (_isEmpty(this.props.yearList)) {
      getAllYears(dispatch);
    }
    window.addEventListener("message", this.handleYmmeFromCatalog);
    window.addEventListener("load", this.handleLoad);
    if (this.props.selectedVehicle) {
      if (
        this.props.location &&
        this.props.location.state &&
        !this.props.location.state.vehicleSelectionDone
      ) {
        this.getYMME();
        this.setState({ vehicleSelectionDone: false });
      } else {
        this.setState({ vehicleSelectionDone: true });
      }
    }

    if (showReleaseNotes()) {
      this.setState({ showReleaseNotesPopup: true });
    }
    //this.expressPreference()
    this.setDefaultView();
    if (!_isEmpty(userDetails && userDetails.billTo)) {
      dispatch(
        getPromotionForParts(
          userDetails.billTo.billToId,
          userDetails.shipTos[0].shipToId
        )
      );
    }
    if (!_isEmpty(smsVinNumber) && smsVinNumber.length >= 16) {
      getVehicleByVinNumber(smsVinNumber, userDetails, dispatch);
    }

    const partsPrefBrands = getPartsPreferredBrands(
      (userDetails && userDetails.billTo.mclSupplier) || "TS"
    );

    if (partsPrefBrands && partsPrefBrands.multiAnswer) {
      let brands = partsPrefBrands.multiAnswer.map((b) => {
        if (typeof b === "string") {
          return b;
        } else {
          return b.text;
        }
      });

      if (brands.indexOf("All") >= 0) {
        brands = [];
      }

      const filter = { ...catalogFilter, brand: brands };

      dispatch({
        type: "UPDATE_CATALOG_FILTER",
        payload: filter,
      });
    }

    const viewPromotions = getCookie('viewPromotions') || "true";
    if(viewPromotions === "false") {
      dispatch({
        payload: {},
        type: "COLLAPSE_PROMOTIONS_TAB",
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleYmmeFromCatalog);
    window.removeEventListener("load", this.handleLoad);
    this.clearPayload();
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      searchData: { yearList, makeList, modelList, engineList, selectedState },
      selectedGroupDetails,
      selectedVehicle,
      showMessage,
      userPromotionDetails,
      vehicleSelectionDone,
      showClassicView,
      userPrefView,
      showExpressButton,
      showExpressView,
    } = this.state;
    let stateChanged = false;

    if (!_isEqual(this.props, prevProps)) {
      if (!_isEqual(this.props.yearList, prevProps.yearList)) {
        yearList = populateSearchDropdownOptions(this.props.yearList);
        stateChanged = true;
        if (this.yearRef && this.yearRef.current) {
          setTimeout(() => {
            if (this.yearRef && this.yearRef.current) {
              this.yearRef.current.select.focus();
            }
          }, 500);
        }
      }
      if (!_isEqual(this.props.makesListForYear, prevProps.makesListForYear)) {
        makeList = populateSearchDropdownOptions(this.props.makesListForYear);
        stateChanged = true;
        if (this.makeRef) {
          setTimeout(() => {
            if (this.makeRef.current && this.makeRef.current.select) {
              this.makeRef.current.select.focus();
            }
          }, 500);
        }
      }
      if (
        !_isEqual(
          this.props.modelListForYearAndMake,
          prevProps.modelListForYearAndMake
        )
      ) {
        modelList = populateSearchDropdownOptions(
          this.props.modelListForYearAndMake
        );
        stateChanged = true;
        if (this.modelRef) {
          setTimeout(() => {
            if (this.modelRef.current && this.modelRef.current.select) {
              this.modelRef.current.select.focus();
            }
          }, 500);
        }
      }
      if (
        !_isEqual(
          this.props.enginesForYearMakeModel,
          prevProps.enginesForYearMakeModel
        )
      ) {
        engineList = populateSearchDropdownOptions(
          this.props.enginesForYearMakeModel
        );
        stateChanged = true;
        if (this.engineRef) {
          setTimeout(() => {
            if (this.props.enginesForYearMakeModel.length !== 1) {
              if (this.engineRef.current && this.engineRef.current.select) {
                this.engineRef.current.select.focus();
              }
            }
          }, 500);
        }
      }
      if (!_isEqual(this.props.selectedVehicle, prevProps.selectedVehicle)) {
        selectedVehicle = { ...this.props.selectedVehicle };
        vehicleSelectionDone = !!selectedVehicle.engine; // Double check
        stateChanged = true;
        if (window.opener && prevProps.selectedVehicle != null) {
          window.opener.postMessage(JSON.stringify(selectedVehicle), "*");
          window.close();
        }
      }
      if (
        !_isEqual(
          this.props.selectedGroupDetails,
          prevProps.selectedGroupDetails
        )
      ) {
        selectedGroupDetails = _cloneDeep(this.props.selectedGroupDetails);
        stateChanged = true;
      }
      if (
        !_isEqual(
          this.props.userPromotionDetails,
          prevProps.userPromotionDetails
        )
      ) {
        userPromotionDetails = this.props.userPromotionDetails;
        stateChanged = true;
      }
      if (!_isEqual(prevProps.userDetails, this.props.userDetails)) {
        this.logUserActivity(
          showExpressView &&
            process.env.REACT_APP_EXPRESS_USER_ACTIVITY_TRACKER,
          "EXPRESS-TOGGLE"
        );
        selectedState = _find(statesList, {
          value:
            (this.props.userDetails &&
              this.props.userDetails.shipTos &&
              this.props.userDetails.shipTos[0] &&
              this.props.userDetails.shipTos[0].state) ||
            "TX",
        });
        let { dispatch, userDetails } = this.props;
        if (
          userDetails &&
          userDetails.billTo &&
          userDetails.billTo.billToId &&
          userDetails.shipTos &&
          userDetails.shipTos[0]
        ) {
          dispatch(
            getPromotionForParts(
              userDetails.billTo.billToId,
              userDetails.shipTos[0].shipToId
            )
          );
        }
        stateChanged = true;
      }
      //this.expressPreference();
      //this.setDefaultView();
      if (stateChanged) {
        this.setState({
          ...this.state,
          searchData: {
            ...this.state.searchData,
            yearList,
            makeList,
            modelList,
            engineList,
            selectedState,
          },
          selectedGroupDetails,
          selectedVehicle,
          showMessage,
          userPromotionDetails,
          vehicleSelectionDone,
          showClassicView,
          userPrefView,
          showExpressButton,
        });
      }
    }
  }

  intializeValidations() {
    this.validity = {
      isYearLengthBelowLimit: true,
      isYearMinLength: true,
      isYearNumeric: true,
      isYearValid: true,
      isMakeLengthBelowLimit: true,
      isMakeMinLength: true,
      isMakeAlphabetic: true,
      isMakeValid: true,
      isModelAlphaSpecialChar: true,
      isModelLengthBelowLimit: true,
      isModelMinLength: true,
      isModelValid: true,
      isEngineAlphaSpecialChar: true,
      isEngineLengthBelowLimit: true,
      isEngineMinLength: true,
      isEngineValid: true,
    };
    return this.validity;
  }

  intializeStateValidations() {
    this.stateValidity = {
      isStateAlphanumeric: true,
      isStateMinLength: true,
    };
    return this.stateValidity;
  }

  getYMME() {
    const { selectedVehicle, dispatch } = this.props;
    if (selectedVehicle) {
      if (selectedVehicle.year) getMakesForYear(selectedVehicle.year, dispatch);
      if (selectedVehicle.year && selectedVehicle.internalMakeId)
        getModelsForMake(
          selectedVehicle.year,
          selectedVehicle.internalMakeId,
          dispatch
        );
      if (
        selectedVehicle.year &&
        selectedVehicle.makeId &&
        selectedVehicle.modelId
      ) {
        getEngineForYearMakeModel(
          selectedVehicle.year,
          selectedVehicle.makeId,
          selectedVehicle.modelId,
          dispatch
        );
      }
    }
  }

  clearLists(lists, callAPi = false) {
    let { searchData } = this.state;
    lists.map((list) => {
      searchData[list] = "";
    });
    this.setState({
      searchData,
      vehicleSelectionDone: false,
    });
    if (callAPi) {
      this.getYMME();
    }
  }

  handleSelectChange(selectedOption, data) {
    const { name } = data;
    let {
      selectedVehicle,
      vehicleSelectionDone,
      validity,
      stateValidity,
      searchData: { selectedState },
    } = this.state;
    const { dispatch, catalogFilter } = this.props;
    const { value, label } = selectedOption;
    let sv = { ...selectedVehicle };
    switch (name) {
      case "year":
        sv = {
          year: label,
          make: "",
          makeId: "",
          model: "",
          modelId: "",
          engine: "",
          engineId: "",
        };
        validity = validateSearchWrapper(name, value, validity);
        this.clearLists(["makeList", "modelList", "engineList"]);
        dispatch({
          payload: {
            yearList: this.props.yearList,
            makeList: [],
            modelList: [],
            engineList: [],
          },
          type: actionTypes.CLEAR_YMME_LISTS,
        });
        getMakesForYear(sv.year, dispatch);
        break;
      case "make":
        sv = {
          ...sv,
          make: label,
          makeId: value,
          model: "",
          modelId: "",
          engine: "",
          engineId: "",
        };
        validity = validateSearchWrapper(name, value, validity);
        this.clearLists(["modelList", "engineList"]);
        dispatch({
          payload: {
            yearList: this.props.yearList,
            makeList: this.props.makesListForYear,
            modelList: [],
            engineList: [],
          },
          type: actionTypes.CLEAR_YMME_LISTS,
        });
        getModelsForMake(sv.year, value, dispatch);
        break;
      case "model":
        let selectedMake = this.props.modelListForYearAndMake.find((model) => {
          return model.code === value && model.name === label;
        });
        sv = {
          ...sv,
          model: label,
          modelId: value,
          internalMakeId: +selectedMake.internalMakeCode,
          makeId: selectedMake.makeCode,
          engine: "",
          engineId: "",
        };
        validity = validateSearchWrapper(name, value, validity);
        this.clearLists(["engineList"]);
        dispatch({
          payload: {
            yearList: this.props.yearList,
            makeList: this.props.makesListForYear,
            modelList: this.props.modelListForYearAndMake,
            engineList: [],
          },
          type: actionTypes.CLEAR_YMME_LISTS,
        });
        getEngineForYearMakeModel(sv.year, sv.makeId, value, dispatch);
        break;
      case "engine":
        sv[name] = label;
        sv.engineId = value;
        validity = validateSearchWrapper(name, value, validity);

        const { x2userName, shipTos, billTo } = this.props.userDetails || {};
        // Dispatch My Garage Vehicles
        const {
          engine,
          make,
          model,
          year,
          makeId,
          modelId,
          engineId,
          internalMakeId,
        } = sv;

        if (engine && make && model && year) {
          vehicleSelectionDone = true;
          dispatch({
            payload: {
              selectedVehicle: sv,
            },
            type: actionTypes.VEHICLE_SELECTION_COMPLETED,
          });

          const payload = {
            createdBy: x2userName,
            createdDate: timeInUTC(),
            customerId: billTo.billToId,
            engine: engine.trim(),
            engineId,
            favoriteFlag: "N",
            favoriteText: "",
            favoriteUpdatedDate: "",
            lastOrderedDate: "",
            make: make.trim(),
            makeId,
            internalMakeId,
            model: model.trim(),
            modelId,
            orderedFlag: "N",
            searchId: "",
            shipToId: shipTos[0].shipToId,
            updatedBy: x2userName,
            updatedDate: timeInUTC(),
            year: year.trim(),
          };
          dispatch(setVehicles(payload));
          dispatch(setVehicleSelected(payload));
        }

        let filter = { ...catalogFilter };

        if (this.props.selectedGroupDetails.length > 0) {
          const __orchestratorPayload = getRequestPayloadForOrchestratorAPI(
            this.props.selectedGroupDetails
          );
          setTimeout(()=>{
          dispatch(
            getPartsByMultiGroupOrchestrator(
              sv.year,
              sv.makeId,
              sv.modelId,
              sv.engineId,
              { requestItems: __orchestratorPayload },
              null,
              null,
              null,
              null,
              filter,
              this.props.userDetails.billTo.mclSupplier,
              this.props.selectedGroupDetails || this.props.selectedPartType,
              true,
              this.props.myGarageSelectedVehicle
            )
            )},500);
          this.setState({ redirectToProductList: true });
        }
        if (!_isEmpty(this.state.selectedProPack)) {
          this.processProPackRedirect(this.state.selectedProPack, sv);
        }
        break;
      case "state":
        selectedState = _find(statesList, { value: value });
        stateValidity = validateState(name, value, stateValidity);
        break;
      default:
        break;
    }
    this.setState({
      selectedVehicle: sv,
      vehicleSelectionDone,
      searchData: {
        ...this.state.searchData,
        selectedState,
      },
      validity: {
        ...this.state.validity,
        ...validity,
      },
      stateValidity: {
        ...this.state.stateValidity,
        ...stateValidity,
      },
    });
  }
  handleSearchError = (query, source) => {
    const { parts } = this.props;
    const errorData = {
      source: source,
      searchvalue: query,
      resultFound: false,
      selectedVehicle  : this.props.selectedVehicle ? `${this.props.selectedVehicle?.year} ${this.props.selectedVehicle?.make} ${this.props.selectedVehicle?.model} ${this.props.selectedVehicle?.engine}`: null,
      // selectedVehicle : this.state.selectedVehicle ? {
      //   year: this.state.selectedVehicle?.year || null,
      //   make: this.state.selectedVehicle?.make || null,
      //   model: this.state.selectedVehicle?.model || null,
      //   engine: this.state.selectedVehicle?.engine || null,
      // } : null,
      filters: {
        groupCode: parts?.specificCondition[0]?.groupCode || null,
        brand: parts?.catalogFilter?.brand || null,
        position: parts?.catalogFilter?.position || null,
        availablity: parts?.catalogFilter?.availablity || null,
      },
    };
    if (source === "License Plate") {
      errorData["state"] = this.state?.searchData?.selectedState;
      errorData["selectedVehicle"] = null;
    }
    if (source === "VIN") {
      errorData["selectedVehicle"] = null;
    }
    this.logUserSearchFailureActivity(errorData);
  };
  logUserSearchFailureActivity = (storedErrors) => {
    let { userDetails, dispatch } = this.props;
    const { x2userName, shipTos, billTo } = userDetails;
    if (!_isEmpty(userDetails) && !_isEmpty(billTo)) {
      let loginId = "";
      let shipToId = shipTos[0]?.shipToId;
      let userData = JSON.stringify(storedErrors);
      let type = "Search - No Result";
      dispatch(
        logUserStockActivity(
          timeInUTC(),
          billTo.billToId,
          loginId,
          shipToId,
          type,
          userData,
          x2userName
        )
      );
    }
  };

  async handleInputOnBlur(event) {
    let {
      target: { value, name },
    } = event;
    let { dispatch } = this.props;
    let {
      searchData: { selectedState },
    } = this.state;
    if (name === "vinSearch") {
      if (value.length >= 16) {
        try {
          const res = await getVehicleByVinNumber(
            value,
            this.props.userDetails,
            dispatch
          );
        } catch (error) {
          this.handleSearchError(value, "VIN");
      }
    }
   } else if (name === "plate") {
      if (selectedState && value.length > 4) {
        try {
          const res = await getVehicleByLicensePlate(
            selectedState.id,
            value,
            this.props.userDetails,
            dispatch
          );
        } catch (error) {
          this.handleSearchError(value, "License Plate");
        }

      }
    }
  }

  toggleView = (event) => {
    let value = event.target.value;
    let { dispatch } = this.props;
    dispatch(setExpressToggle({ isExpress: value === "express" }));
    this.logUserActivity(
      value === "express" &&
        process.env.REACT_APP_EXPRESS_USER_ACTIVITY_TRACKER,
      "EXPRESS-TOGGLE"
    );
    let { showClassicView, selectedProPack, selectedGroupDetails } = this.state;
    _remove(selectedGroupDetails, (p) => p);
    this.clearPayload();
    this.setState({
      ...this.state,
      showClassicView: value === "classic",
      showIllustratedView: value === "illustrated",
      showExpressView: value === "express",
      //showClassicView: !showClassicView,
      selectedProPack: {},
      selectedGroupDetails,
      partTypeDataArr: [],
      groupPartsHomepage: [],
    });
  };

  handleInputOnChange = (event) => {
    let {
      target: { value, name },
    } = event;
    let { dispatch } = this.props;
    let { searchData } = this.state;
    searchData[name] = value;
    if (event.key === " ") {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      if (name === "vinSearch") {
        if (value.length >= 16) {
          getVehicleByVinNumber(value, this.props.userDetails, dispatch);
        }
      } else if (name === "plate") {
        if (searchData.selectedState && value.length > 4) {
          getVehicleByLicensePlate(
            searchData.selectedState.id,
            value,
            this.props.userDetails,
            dispatch
          );
        }
      }
    }
  };

  handleProPackSelect = (e, { id, name }) => {
    let { selectedProPack, selectedGroupDetails } = this.state;
    let { dispatch, proPacksData, myGarageSelectedVehicle } = this.props;
        selectedGroupDetails = [];
    if (id === selectedProPack.id) {
      selectedProPack = {};
    } else {
      selectedProPack = _find(proPacksData[0].jobs, { id: id });
    }
    vehicleSelectPrompt(!_isEmpty(selectedProPack) ? true : false,
    dispatch, myGarageSelectedVehicle, triggerCustomUxCriticalError);
    dispatch({
      payload: {
        selectedProPack,
      },
      type: actionTypes.PROPACK_SELECTED,
    });
    if (!_isEmpty(selectedProPack) && this.state.selectedVehicle.engineId) {
      this.processProPackRedirect(selectedProPack, this.state.selectedVehicle);
    }
    this.setState({
      ...this.state,
      selectedProPack,
      selectedGroupDetails,
    });
  };

  handleGroupClick = (e, data, category) => {
    let { selectedGroupDetails, selectedProPack } = this.state;
    let { dispatch } = this.props;
    selectedProPack = {};
    let selectedIndex = _findIndex(selectedGroupDetails, {
      code: data.code,
      categoryId: category.code,
    });
    if (
      selectedIndex !== -1 &&
      category.code == selectedGroupDetails[selectedIndex].categoryId
    ) {
      _remove(selectedGroupDetails, {
        code: data.code,
        categoryId: category.code,
      });
    } else {
      let singleItem = [];
      singleItem = {
        categoryId: category.code,
        code: data.code,
        name: data.name,
        engineCode: data.engineCode,
        laborGroups: data.laborGroups,
      };
      selectedGroupDetails.push(singleItem);
    }
    dispatch({
      payload: {
        selectedGroupDetails,
      },
      type: actionTypes.GROUP_SELECTED,
    });
    this.setState({
      selectedGroupDetails,
      selectedProPack,
    });
  };

  closeReleaseNotes = () => {
    this.setState({ showReleaseNotesPopup: false });
    hideReleaseNotes();
  };

  processProPackRedirect = (selectedProPack = {}, selectedVehicle = {}) => {
    let { year, makeId, modelId, engineId } = selectedVehicle;
    let { dispatch } = this.props;
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
    if (!isImageURLCookieValid()) {
      dispatch(getPartImageURLCookie()).then((resp) => {
        setImageURLCookie(resp.payload);
      });
    }
    dispatch(setJobSelected([]));

    let requestItems = [];
    let brand = [];
    let chemReqItems = [];
    !_isEmpty(selectedProPack) &&
      selectedProPack.partJobItems.map((singleItem, key) => {
        if (!_isEmpty(singleItem.partGroup)) {
          requestItems = [
            ...requestItems,
            ..._map(_map(singleItem.partGroup, "code"), (c) => {
              return {
                groupCode: Number(c),
                specificCondition: [],
              };
            }),
          ];
        }
      });
    requestItems = _.uniqBy(requestItems, "groupCode");

    if (this.props.catalogRequestGroups) {
      requestItems = requestItems.map((r) => {
        this.props.catalogRequestGroups.requestItems.forEach((cr) => {
          if (r.groupCode === cr.groupCode) {
            r.specificCondition = cr.specificCondition;
          }
        });
        return r;
      });
    }
    if (!_isEmpty(selectedProPack.brands)) {
      brand = _map(selectedProPack.brands, "code");
    }

    let bundleCodeArr = _map(selectedProPack.bundlePriceCodes, "name");

    if (!_isEmpty(selectedProPack.chemicalJobItems)) {
      selectedProPack.chemicalJobItems.map((c) => {
        if (!_isEmpty(c.chemicalGroup)) {
          chemReqItems = c.chemicalGroup.map((single) => single.id);
        }
      });

      const filterChem =
        _isEmpty(bundleCodeArr) && selectedProPack.enableBundleLookup === false
          ? []
          : ["Bundle"];
      dispatch(
        getChemPartByGrp(
          _uniq(chemReqItems),
          userPrefDetail.pageNumber,
          userPrefDetail.pageSize,
          userPrefDetail.catalogSortBy,
          userPrefDetail.catalogOrder,
          null,
          null,
          filterChem
        )
      );
      dispatch({
        payload: {
          brandsView: false,
        },
        type: "SUPPLIES_BRAND_VIEW",
      });
    }
    dispatch({
      payload: {
        selectedGroupDetails: _map(
          _flatten(_map(selectedProPack.partJobItems, "partGroup")),
          (g) => {
            return { ...g, code: Number(g.code) };
          }
        ),
      },
      type: "GROUP_SELECTED",
    });
    const filterPart =
      _isEmpty(bundleCodeArr) && selectedProPack.enableBundleLookup === false
        ? []
        : ["Bundle"];
        setTimeout(()=>{dispatch(
          getPartsByMultiGroupOrchestrator(
        year,
        makeId,
        modelId,
        engineId,
        { requestItems },
        userPrefDetail.pageNumber,
        userPrefDetail.pageSize,
        userPrefDetail.catalogSortBy,
        userPrefDetail.catalogOrder,
        { exactMatch: true, brand, misc: filterPart },
        this.props.userDetails.billTo.mclSupplier,
        this.props.selectedGroupDetails || this.props.selectedPartType,
        true,
        this.props.myGarageSelectedVehicle
        )
        )},500)
        this.setState({ redirectToPropacksCatalog: true });
  };

  getCartMoreInfo = () => {
    let { userDetails } = this.props;
    let cartData = [];
    let storesData = [];
    let defaultStore =
      this.props.allStores && this.props.allStores.defaultStore
        ? this.props.allStores.defaultStore
        : "";
    let userData =
      this.props.userDetails &&
      this.props.userDetails.shipTos &&
      this.props.userDetails.shipTos[0] &&
      this.props.userDetails.shipTos[0].location &&
      this.props.userDetails.shipTos[0].location;
    if (
      this.props.cartStore &&
      this.props.cartStore != null &&
      this.props.allStores &&
      this.props.allStores.pickupStores &&
      this.props.cartShipMethod === "0002"
    ) {
      storesData = _cloneDeep(this.props.allStores.pickupStores);
      storesData.push(defaultStore);
      cartData = _find(storesData, { storeNumber: this.props.cartStore });
    }
    if (
      this.props.cartStore &&
      this.props.cartStore != null &&
      this.props.allStores &&
      this.props.allStores.deliveryStores &&
      this.props.cartShipMethod != "0002"
    ) {
      storesData = _cloneDeep(this.props.allStores.deliveryStores);
      storesData.push(defaultStore);
      cartData = _find(storesData, { storeNumber: this.props.cartStore });
    }
    let sellPartnerId =
      this.props.sellerPartnerId && this.props.sellerPartnerId != null
        ? this.props.sellerPartnerId
        : !_isEmpty(cartData)
        ? cartData.sellerPartnerId
        : !_isEmpty(userData) && userData.sellerPartnerId;
    let selectedStoreName = !_isEmpty(cartData)
      ? cartData.storeName
      : !_isEmpty(userData) && userData.storeName;
    let cartStore =
      this.props.cartStore && this.props.cartStore != null
        ? this.props.cartStore
        : !_isEmpty(cartData)
        ? cartData.storeNumber
        : !_isEmpty(userData) && userData.storeNumber;
    let cartShipMethod = this.props.cartShipMethod
      ? this.props.cartShipMethod
      : this.props.userDetails.shipTos &&
        this.props.userDetails.shipTos[0] &&
        this.props.userDetails.shipTos[0].shipMethod &&
        this.props.userDetails.shipTos[0].shipMethod
      ? this.props.userDetails.shipTos[0].shipMethod
      : "";
    return {
      sellPartnerId: sellPartnerId,
      selectedStoreName: selectedStoreName,
      store: cartStore,
      cartShipMethod: cartShipMethod,
    };
  };

  handleDisplayProduct = () => {
    let {
      selectedVehicle: { year, makeId, modelId, engineId },
      selectedGroupDetails,
      selectedProPack,
      showIllustratedView,
    } = this.state;
    let { dispatch, userDetails, catalogFilter } = this.props;
    let partArray = [];
    const userPreferenceDetail = getPreferenceDataFromStorage();
    if (!_isEmpty(this.props.selectedPartTypes)) {
      this.props.selectedPartTypes.map((v) => {
        partArray.push({ id: v.id });
      });
    }
    let userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
    let cartDetails = this.getCartMoreInfo();
    let sellPartnerId = cartDetails.sellPartnerId
      ? cartDetails.sellPartnerId
      : "";
    let store = cartDetails.store ? cartDetails.store : "";
    let shipMethod = cartDetails.cartShipMethod
      ? cartDetails.cartShipMethod
      : "";
    if (!isImageURLCookieValid()) {
      dispatch(getPartImageURLCookie()).then((resp) => {
        setImageURLCookie(resp.payload);
      });
    }
    //for brand filter default all selection
    dispatch({
      payload: {
        bool: true,
      },
      type: "CATEGORY_CHANGED",
    });
    dispatch(setJobSelected([]));
    let filter = { ...catalogFilter };
    if (partArray) {
      filter = { ...catalogFilter, exactMatch: true, partTypes: partArray, availability: [], misc: [] };
    }
    if (
      !_isEmpty(selectedGroupDetails) &&
      (showIllustratedView || !_isEmpty(this.props.selectedPartTypes))
    ) {
      const __orchestratorPayload = getRequestPayloadForOrchestratorAPI(
        this.props.selectedGroupDetails,
        this.props.catalogRequestGroups,
        this.props.specificConditions
      );
      console.log("go click ==> ", filter);
      const updatedSearchData=this.props.selectedGroupDetails && this.props.selectedGroupDetails.map((item,i)=>{
        const result = this.props.categoryList && this.props.categoryList.find((category) => category.code === item.categoryId);
        return {
          category:result.name,
          group:item.name
        }

      })
      setTimeout(()=>{
      dispatch(
        getPartsByMultiGroupOrchestrator(
          year,
          makeId,
          modelId,
          engineId,
          {
            requestItems: __orchestratorPayload,
            sellPartnerId: sellPartnerId,
            store: store,
            shipMethod: shipMethod,
          },
          userPrefDetail.pageNumber,
          userPrefDetail.pageSize,
          userPrefDetail.catalogSortBy,
          userPrefDetail.catalogOrder,
          filter,
          this.props.userDetails.billTo.mclSupplier,
          updatedSearchData || this.props.selectedGroupDetails || this.props.selectedPartType,
          true,
          this.props.myGarageSelectedVehicle
        )
        )},500);
        this.setState({ redirectToProductList: true });
    } else {
      this.processProPackRedirect(selectedProPack, this.state.selectedVehicle);
    }
  };
  render() {
    const {
      redirectToProductList,
      selectedVehicle,
      searchData: { yearList, makeList, modelList, engineList, selectedState },
      vehicleSelectionDone,
      selectedGroupDetails,
      userPromotionDetails,
      selectedProPack,
      stateValidity,
      redirectToPropacksCatalog,
      showClassicView,
      userPrefView,
      showReleaseNotesPopup,
      showYmmeOnly,
      showExpressButton,
      showIllustratedView,
      showExpressView,
      partTypeSelectionData
    } = this.state;
    const { refreshTokenStatus, smsPageLoader, proPacksData } = this.props;

    if (
      this.props.userDetails &&
      this.props.userDetails.userType === "STORE_USER"
    ) {
      return (
        <Redirect
          to={{
            pathname: "/store-user-dashboard",
          }}
        />
      );
    }
    if (
      this.props.userDetails &&
      (this.props.userDetails.userType === "CC_MANAGER" ||
        this.props.userDetails.userType === "AREA_MANAGER")
    ) {
      return (
        <Redirect
          to={{
            pathname: "/manager-dashboard",
          }}
        />
      );
    }
    if (redirectToProductList) {
      return (
        <Redirect
          to={{
            pathname: "/product-list",
          }}
        />
      );
    }
    if (redirectToPropacksCatalog) {
      return (
        <Redirect
          to={{
            pathname: "/propacks-list",
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <div
          className={clsx({
            "promotions-active": this.props.viewPromotions && !showYmmeOnly,
          })}
        >
          <div className="category__wrapper">
            {!showExpressView && (
              <SearchWrapper
                yearList={yearList}
                onSelectChange={this.handleSelectChange}
                selectedVehicle={selectedVehicle}
                makeList={makeList}
                modelList={modelList}
                engineList={engineList}
                vehicleSelectionDone={vehicleSelectionDone}
                clearLists={this.clearLists}
                handleInputOnBlur={this.handleInputOnBlur}
                validity={this.validity}
                stateValidity={stateValidity}
                handleInputOnChange={this.handleInputOnChange}
                selectedState={selectedState}
                makeListRef={this.makeRef}
                yearListRef={this.yearRef}
                modelListRef={this.modelRef}
                engineListRef={this.engineRef}
                selectedPromotion={this.props.selectedPromotion}
                categoryGroupMap={this.props.categoryGroupMap}
                myGarageSelectedVehicle={this.props.myGarageSelectedVehicle}
                showYmmeOnly={showYmmeOnly}
                isExpress={false}
                defaultActiveKey={"YMMET"}
              />
            )}
            {!showYmmeOnly ? (
              <section
                className={
                  showExpressView
                    ? "section-wrapper-express"
                    : "section-wrapper"
                }
              >
                {!showExpressView &&
                !(
                  this.props.selectedVehicle &&
                  (!_isEmpty(selectedGroupDetails) ||
                    !_isEmpty(selectedProPack))
                ) ? (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 100, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        Please select a vehicle and a group or bundle.
                      </Tooltip>
                    }
                  >
                    <Button className="display-prod-btn go-btn disabled">
                      GO
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <React.Fragment>
                    {!showExpressView && (
                      <Button
                        className="display-prod-btn go-btn"
                        onClick={() => this.handleDisplayProduct()}
                      >
                        GO
                      </Button>
                    )}
                  </React.Fragment>
                )}

                <PromotionsTab
                  showSidebarPopUp={this.props.viewPromotions}
                  promotions={this.props.userPromotionDetails}
                  handlePromotions={() => {
                    setCookie('viewPromotions', !this.props.viewPromotions, 365)
                    this.props.dispatch({
                      payload: {},
                      type: "VIEWPROMOTIONS_TOGGLE",
                    });
                  }}
                />

                {/* <div className="toggleView d-flex " style={{ marginTop: '-40px' }}>
								<OverlayTrigger
									placement="right"
									delay={{ show: 100, hide: 400 }}
									overlay={
										<Tooltip id="toggle-view-classic-illusttrated">
											{`${showClassicView
												? 'You are in "Classic View", clicking on the button will switch to "Illustrated View"'
												: 'You are in "Illustrated View", clicking on the button will switch to "Classic View"'
												}`}
										</Tooltip>
									}
								>
									<Form.Group controlId="toggle-view-id">
										<Form.Check
											type="switch"
											id="toggle-view"
											label={`${userPrefView === 'classic'
												? 'Illustrated View'
												: 'Classic View'
												}`}
											onChange={(e) => this.toggleView(e)}
										/>
									</Form.Group>
								</OverlayTrigger>
							</div> */}
                <Container
                  fluidclassName={
                    !_isEmpty(selectedVehicle) &&
                    !_isEmpty(selectedVehicle.year)
                      ? "express-header-container vehicle-selected pl-5 pr-5"
                      : "express-header-container pl-5 pr-5"
                  }
                >
                  <Row>
                    <Col md={showExpressView ? 5 : 9}>
                      <div className={showExpressView ? "d-flex" : "d-flex"}>
                        <FormControl>
                          <RadioGroup
                            onChange={(e) => this.toggleView(e)}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="illustrated"
                              checked={showIllustratedView}
                              control={<Radio />}
                              label={
                                <span>
                                  <b>Illustrated View</b>
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="classic"
                              checked={showClassicView}
                              control={<Radio />}
                              label={
                                <span>
                                  <b>Classic View</b>
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="express"
                              checked={showExpressView}
                              control={<Radio />}
                              label={
                                <span>
                                  <b>Express View</b>
                                </span>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </Col>
                    <Col md={7}>
                      {showExpressView && (
                        <div
                          className={
                            !_isEmpty(selectedVehicle) &&
                            !_isEmpty(selectedVehicle.year)
                              ? "express-search selected-vehicle"
                              : "express-search"
                          }
                        >
                          <SearchWrapper
                            handleInputOnBlur={this.handleInputOnBlur}
                            handleInputOnChange={this.handleInputOnChange}
                            onSelectChange={this.handleSelectChange}
                            selectedState={selectedState}
                            stateValidity={stateValidity}
                            isExpress={true}
                            defaultActiveKey={"VIN"}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
                <React.Fragment>
                  {showExpressView && showExpressButton ? (
                    <SimpleHomePage />
                  ) : showClassicView ? (
                    <React.Fragment>
                      {proPacksData &&
                      proPacksData[0] &&
                      proPacksData[0].jobs &&
                      proPacksData[0].jobs.length >= 1 ? (
                        <ClassicProPackContainer
                          handleClick={this.handleProPackSelect}
                          selectedProPack={selectedProPack}
                        />
                      ) : null}
                      <ClassicCategoryWrapper
                        partTypeAPICall={this.partTypeAPICall}
                        partTypeSelectionData={partTypeSelectionData}
                        PayloadData={this.PayloadData}
                        PartTypePayloadData={this.PartTypePayloadData}
                        handleClick={this.handleGroupClick}
                        selectedGroupDetails={selectedGroupDetails}
                        myGarageSelectedVehicle={this.props.myGarageSelectedVehicle}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {proPacksData &&
                      proPacksData[0] &&
                      proPacksData[0].jobs &&
                      proPacksData[0].jobs.length >= 1 ? (
                        <ProPackContainer
                          handleClick={this.handleProPackSelect}
                          selectedProPack={selectedProPack}
                        />
                      ) : null}
                      <CategoryWrapper
                        partTypeAPICall={this.partTypeAPICall}
                        PayloadData={this.PayloadData}
                        PartTypePayloadData={this.PartTypePayloadData}
                        handleClick={this.handleGroupClick}
                        selectedGroupDetails={selectedGroupDetails}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>

                {showReleaseNotesPopup ? (
                  <React.Fragment>
                    <ReleaseNotePopup
                      openPopup={true}
                      closePopup={this.closeReleaseNotes}
                    />
                  </React.Fragment>
                ) : null}

                <section className="section__end">
                  <Container></Container>
                </section>
              </section>
            ) : null}
          </div>
          {refreshTokenStatus && (
            <div className="custom_spinner_container">
              <Spinner className="custom_spinner" animation="border" />
            </div>
          )}
          {smsPageLoader && (
            <div className="custom_spinner_container sms-user-spinner">
              <Spinner className="custom_spinner" animation="border" />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    yearList: state.search.yearListAll,
    makesListForYear: state.search.makesListForYear,
    modelListForYearAndMake: state.search.modelListForYearAndMake,
    vinNumberSearchResult: state.search.vinNumberSearchResult,
    enginesForYearMakeModel: state.search.engineForYearMakeModel,
    appErrors: state.app.errors,
    categoryGroupMap: state.parts.categoryGroupMap,
    categorySuppliesMap: state.parts.categorySuppliesMap,
    categoryList: state.parts.categoryList,
    selectedPartTypes: state.parts.selectedCatalogPartTypes,
    selectedVehicle: state.mygarage.selectedVehicle,
    selectedGroupDetails: state.parts.selectedGroupDetails,
    userPromotionDetails: state.user.userPromotionDetails,
    userDetails: state.user.userDetails,
    proPacksData: state.user.proPacksData,
    viewPromotions: state.app.viewPromotions,
    selectedPromotion: state.parts.selectedPromotion,
    myGarageSelectedVehicle: state.mygarage.selectedVehicle,
    refreshTokenStatus: state.app.refreshTokenStatus,
    catalogRequestGroups: state.parts.catalogRequestGroups,
    extraSpecificsForParts: state.parts.extraSpecificsForParts,
    specificConditions: state.parts.specificConditions,
    cartStore: state.parts.cartStore,
    cartShipMethod: state.parts.cartShipMethod,
    allStores: state.userManagement.allStores,
    smsVinNumber: state.app.smsVinNumber,
    smsPageLoader: state.app.smsPageLoader,
    sellerPartnerId: state.parts.sellerPartnerId,
    partTypeData: state.parts.homePagePartTypes,
    catalogFilter: state.parts.catalogFilter,
    selectedPartType: state.parts.selectedPartType
  };
}
export default connect(mapStateToProps)(withRouter(HomePage));
