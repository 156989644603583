import _isEmpty from "lodash/isEmpty";
import React, { useEffect, useState, Fragment } from "react";
import {useSelector } from "react-redux";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import {formatTimeAvb, getSellerInfo, distributeAvailability, enableBlackColorBasedOnStorePref} from "./utils";
import _uniqBy from "lodash/uniqBy";
import _head from "lodash/head";
const getAvailability = (row = {}, singleAvailability = {}, availability = {}, orderArray = {}, userDetails = {}, viewType = '', onclick, qty, selectedStore) => {
  let ismobileEnabled = userDetails?.shipTos[0]?.isMobileEnabled;
  let isSellerDiff = getSellerInfo(userDetails) ? "isSellerDiff" : "normal";
  let filteredData = _uniqBy(
    availability,
    "availableDuration"
  );
  let isBlackColorCode = enableBlackColorBasedOnStorePref(selectedStore);
  switch (isSellerDiff) {
    case "isSellerDiff":
      if(viewType == 'Table') {
        return getTableAvailabilityForState(row, singleAvailability, onclick, ismobileEnabled, isBlackColorCode);
      } else if(viewType == 'FullView') {
        return getFullViewAvailabilityForState(filteredData, orderArray, row, onclick, ismobileEnabled, isBlackColorCode)
      } else {
        return getNormalAvailabilityForState(row, qty, ismobileEnabled, onclick, isBlackColorCode)
      }
    default:
    if(viewType == 'TablePop') {
      return getTableAvailabilityForTPH(row, qty, onclick, ismobileEnabled);
    } else if(viewType == 'FullView') {
      return getFullViewAvailabilityForTPH(filteredData, orderArray, row, onclick, ismobileEnabled)
    } else {
      return getNormalAvailabilityForTPH()
    }
  }
}

const storeBreakup =(storeData, onclick) => {
  let storeArr = []
  storeData?.map((s,i)=>{
    storeArr.push({
      store:s.store,
      storeQty:s.storeQty,
    })
  })
  return onclick(storeArr);
}

const checkIfMobileEnabled = (storeData,onclick,ismobileEnabled,text) =>{
  return(
    ismobileEnabled ? 
  <u className="text-color" onClick={()=>{onclick(storeData)}}>{text}&nbsp;</u> :
  <u>{text}&nbsp;</u>
  )
}

const checkIfMobileEnabledTooltip = (storeData,onclick,ismobileEnabled,text) =>{
  return(
    ismobileEnabled ? 
  <u className="cursor-pointer" onClick={()=>{onclick(storeData)}}>{text}&nbsp;</u> :
  <u>{text}&nbsp;</u>
  )
}

// const checkIfMobileEnabledForPopUp = (storeData,onclick,ismobileEnabled,text) =>{
//   return(
//   ismobileEnabled ? 
//   <u className="text-color" onClick={()=>{onclick(storeData)}}>{text}</u> :
//   <u>{text}</u>
//   )
// }

const getTableAvailabilityForState = (row, singleAvailability, onclick, ismobileEnabled, isBlackColorCode) => {
  let totalAvailqty = 0;
  totalAvailqty += singleAvailability.availableQty;
  return (<div className="availability-container">
  <div className="availability-subdiv">
    <ul>
      <li>
        {singleAvailability.availableTime === null ||
        singleAvailability.availableTime === "null" ||
        singleAvailability.availableDuration === "Your Store" ||
        (singleAvailability.availableDuration === "Immediately" &&
          singleAvailability.code === 0) ? (
          <span className="text-black">
            <span className={isBlackColorCode ? "" : "maroonColorTxt"}>
              <b>{singleAvailability.availableQty}</b>{" "}
            </span>
            {checkIfMobileEnabled(singleAvailability?.storeAvailabilities,onclick,ismobileEnabled,singleAvailability.availableDuration)}
          </span>
        ) : (
          <span className="text-black">
            <span className={isBlackColorCode ? "" : "maroonColorTxt"}>
              <b>{singleAvailability.availableQty}</b>{" "}
            </span>{" "}
            {checkIfMobileEnabled(singleAvailability?.storeAvailabilities,onclick,ismobileEnabled,"Within")}
            {formatTimeAvb(singleAvailability.availableDuration)}{" "}
          </span>
        )}
      </li>
    </ul>
  </div>
  {row.availabilities.length > 1 ? (
    row.hiddenAvail ? (
      <Fragment>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 3000 }}
          show={row.hiddenAvail}
          overlay={
            <Tooltip
              id="toggle-view-full-compact"
              className="custom-tooltip-availability apply-max-height"
            >
              {row.availabilities.map((singleAvailability, key) => {
                if (singleAvailability.availableQty > 0) {
                  //row.availability =
                  // singleAvailability.availableDuration = (singleAvailability.availableDuration === '00:00:00' && singleAvailability.code === 0) ? 'Immediately' : singleAvailability.availableDuration;
                  totalAvailqty += singleAvailability.availableQty;
                  return (
                    <ul>
                      {key !== 0 ? (
                        <li>
                          {singleAvailability.availableTime === null ||
                          singleAvailability.availableTime === "null" ||
                          singleAvailability.availableDuration ===
                            "Same Day" ||
                          (singleAvailability.availableDuration ===
                            "Immediately" &&
                            singleAvailability.code === 0) ? (
                            <span className="text-white">
                              <span className="font-weight-bold">
                                {singleAvailability.availableQty}{" "}
                              </span>
                            {checkIfMobileEnabledTooltip(singleAvailability?.storeAvailabilities,onclick,ismobileEnabled,singleAvailability.availableDuration)}
                            </span>
                          ) : (
                            <span className="text-white">
                              <span className="font-weight-bold">
                                {singleAvailability.availableQty}{" "}
                              </span>{" "}
                              {checkIfMobileEnabledTooltip(singleAvailability?.storeAvailabilities,onclick,ismobileEnabled,"Within")}           
                              {formatTimeAvb(
                                singleAvailability.availableDuration
                              )}{" "}
                            </span>
                          )}
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  );
                }
              })}
            </Tooltip>
          }
        >
          <a className="maroonColorTxt info-icon">
            <i class="fa fa-info-circle"></i>
          </a>
        </OverlayTrigger>
      </Fragment>
    ) : (
      <Fragment>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 3000 }}
          overlay={
            <Tooltip
              id="toggle-view-full-compact"
              className="custom-tooltip-availability apply-max-height"
            >
              {row.availabilities.map((singleAvailability, key) => {
                if (singleAvailability.availableQty > 0) {
                  //row.availability =
                  // singleAvailability.availableDuration = (singleAvailability.availableDuration === '00:00:00' && singleAvailability.code === 0) ? 'Immediately' : singleAvailability.availableDuration;
                  totalAvailqty += singleAvailability.availableQty;
                  return (
                    <ul>
                      {key !== 0 ? (
                        <li>
                          {singleAvailability.availableTime === null ||
                          singleAvailability.availableTime === "null" ||
                          singleAvailability.availableDuration ===
                            "Same Day" ||
                          (singleAvailability.availableDuration ===
                            "Immediately" &&
                            singleAvailability.code === 0) ? (
                            <span className="text-white">
                              <span className="font-weight-bold">
                                {singleAvailability.availableQty}{" "}
                              </span>
                              {checkIfMobileEnabledTooltip(singleAvailability?.storeAvailabilities,onclick,ismobileEnabled,singleAvailability.availableDuration)}
                            </span>
                          ) : (
                            <span className="text-white">
                              <span className="font-weight-bold">
                                {singleAvailability.availableQty}{" "}
                              </span>{" "}
                              {checkIfMobileEnabledTooltip(singleAvailability?.storeAvailabilities,onclick,ismobileEnabled,"Within")}           
                              {formatTimeAvb(
                                singleAvailability.availableDuration
                              )}{" "}
                            </span>
                          )}
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  );
                }
              })}
            </Tooltip>
          }
        >
          <a className="maroonColorTxt info-icon">
            <i class="fa fa-info-circle"></i>
          </a>
        </OverlayTrigger>
      </Fragment>
    )
  ) : (
    //its a dummy icon for setting some alignment issue
    <a className="disabledIcon info-icon">
      <i class="fa fa-info-circle"></i>
    </a>
  )}
</div>);
}
const getFullViewAvailabilityForState = (uniqueData, orderArray, partDetails, onclick, ismobileEnabled, isBlackColorCode) => {
  let availabilitySection = [];
  let totalAvailqty = 0;
  uniqueData.map((singleAvailability, key) => {
    if (singleAvailability.availableQty > 0) {
      // singleAvailability.availableDuration = (singleAvailability.availableDuration === '00:00:00' && singleAvailability.code === 0) ? 'Immediately' : singleAvailability.availableDuration;
      totalAvailqty += singleAvailability.availableQty;
      availabilitySection.push(
        <div className="catalog-availability-section">
          <span>QTY:</span>
          <div>
            <input
              type="text"
              className="mr-2"
              name={partDetails.partNumber}
              disabled
              value={
                !_isEmpty(orderArray) && !_isEmpty(orderArray[key])
                  ? orderArray[key].orderQty
                  : 0
              }
            />
            {singleAvailability.availableTime === null ||
            singleAvailability.availableTime === "null" ||
            singleAvailability.availableDuration === "Same Day" ||
            (singleAvailability.availableDuration === "Immediately" &&
              singleAvailability.code === 0) ? (
              <span className="text-black">
                {ismobileEnabled ? <u className="text-color" onClick={()=>{storeBreakup(singleAvailability?.storeAvailabilities, onclick)}}>{singleAvailability.availableDuration}</u> : singleAvailability.availableDuration} -&nbsp;
                <span className={isBlackColorCode ? "text-black" : "maroonColorTxt"}>
                  <b>{singleAvailability.availableQty}</b>
                </span>
                &nbsp;Available
              </span>
            ) : (
              <span className="text-black">
                {" "}
                {ismobileEnabled ? <u className="text-color" onClick={()=>{storeBreakup(singleAvailability?.storeAvailabilities, onclick)}}>Within</u> : "Within"}
                {" "}
                 {formatTimeAvb(
                  singleAvailability.availableDuration
                )}{" "}
                <span className={isBlackColorCode ? "text-black" : "maroonColorTxt"}>
                  <b>{singleAvailability.availableQty}{" "}</b>
                </span>
                &nbsp;Available
              </span>
            )}
          </div>
        </div>
      );
    }
  });
  return availabilitySection;
}

const getTableAvailabilityForTPH = (row, qty, onclick, ismobileEnabled) => {
  let uniqueData = _uniqBy(row.availabilityAll, "availableDuration");
  let enableOtherAvailabilities =
  uniqueData.length > 0 &&
  uniqueData[0].availableQty &&
  uniqueData[0].availableQty >= 1
    ? false
    : true;
let otherStoreAvailability = 0;
uniqueData.map((unique, index) => {
  if (index != 0) {
    otherStoreAvailability += unique.availableQty;
  }
});
  return (
    <div className="availability-container">
      <div className="availability-subdiv">
        {uniqueData.map((unique, index) => {
          return (
            (unique.availableDuration === "Your Store" ||
              unique.availableDuration === "Store") && (
              <ul>
                <li>
                  <span className="text-black">
                    {unique.availableQty == 0 ? (
                      <span className="store-maroon-text compact-text-grey-Zero">
                        <b>{unique.availableQty}</b>
                      </span>
                    ) : (
                      <span className="store-maroon-text compact-text-grey">
                        <b>{unique.availableQty}</b>
                      </span>
                    )}
                    &nbsp;at &nbsp;
                    {unique.availableDuration}{" "}
                    {unique.storeAvailabilities.length > 0 &&
                    unique.storeAvailabilities[0].store ? (
                      <b>#{unique.storeAvailabilities[0].store}</b>
                    ) : null}
                  </span>
                  {true && !enableOtherAvailabilities ? (
                    qty &&
                    qty > uniqueData[0].availableQty ? (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 100, hide: 4000 }}
                        show={
                          qty &&
                          qty > uniqueData[0].availableQty
                        }
                        overlay={
                          <Tooltip
                            id="toggle-view-full-compact"
                            className="custom-tooltip-availability"
                          >
                            {prepareTooltipAvailability(row.availabilityAll, onclick, ismobileEnabled)}
                          </Tooltip>
                        }
                      >
                        <a className="maroonColorTxt info-icon">
                          <i class="fa fa-info-circle"></i>
                        </a>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 100, hide: 4000 }}
                        overlay={
                          <Tooltip
                            id="toggle-view-full-compact"
                            className="custom-tooltip-availability"
                          >
                            {prepareTooltipAvailability(row.availabilityAll, onclick, ismobileEnabled)}
                          </Tooltip>
                        }
                      >
                        <a className="maroonColorTxt info-icon">
                          <i class="fa fa-info-circle"></i>
                        </a>
                      </OverlayTrigger>
                    )
                  ) : null}
                </li>
              </ul>
            )
          );
        })}
        {true && enableOtherAvailabilities ? (
          <ul>
            <li>
              <span className="text-black">
                <span className="store-maroon-text compact-text-green">
                  <b>{otherStoreAvailability}</b>
                </span>
                &nbsp;at Other Stores &nbsp;{" "}
              </span>
              {true && enableOtherAvailabilities ? (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 100, hide: 4000 }}
                  overlay={
                    <Tooltip
                      id="toggle-view-full-compact"
                      className="custom-tooltip-availability"
                    >
                      {prepareTooltipAvailability(row.availabilityAll,onclick, ismobileEnabled)}
                    </Tooltip>
                  }
                >
                  <a className="maroonColorTxt info-icon">
                    <i class="fa fa-info-circle"></i>
                  </a>
                </OverlayTrigger>
              ) : null}
            </li>
          </ul>
        ) : null}
      </div>
    </div>
  );
}

const prepareTooltipAvailability = (availabilityData, onclick, ismobileEnabled) => {
  let availabilities = availabilityData;
  let returnArray = [];
  availabilities.map((singleAvailability, key) => {
    if (singleAvailability.availableQty > 0) {
      if (
        singleAvailability.availableDuration === "Next Business Day" ||
        singleAvailability.availableDuration === "Next 2 + Business Days" ||
        singleAvailability.availableDuration === "Next 2 Weeks"
      ) {
        returnArray.push(
          <ul>
            <li>
              <span className="text-white">
                <span className="store-maroon-text">
                  {singleAvailability.availableQty}
                </span>
                &nbsp; &nbsp;
                {checkIfMobileEnabledTooltip(singleAvailability?.storeAvailabilities, onclick, ismobileEnabled, singleAvailability.availableDuration)}
              </span>
            </li>
          </ul>
        );
      } else {
        singleAvailability.storeAvailabilities.map((storeData, storeKey) => {
          if (key != 0) {
            returnArray.push(
              <ul>
                <li>
                  <span className="text-white">
                    <b>{storeData.storeQty}</b>
                  </span>
                  <span className="text-white">
                    &nbsp;{` at Store #`}
                    <b>{storeData.store}</b>
                    <br />
                    {singleAvailability.availableDuration ===
                      "Direct Order Store" ||
                    singleAvailability.availableDuration ==
                      "Pick Up Store" ? (
                      <b>
                        ({singleAvailability.availableDuration},
                        {storeData.storeQualifier
                          ? storeData.storeQualifier
                          : ""}
                        )
                      </b>
                    ) : null}
                  </span>
                </li>
              </ul>
            );
          }
        });
      }
    }
  });
  let fullDiv = [];
  fullDiv = <div>{returnArray}</div>;
  return fullDiv;
};

const getFullViewAvailabilityForTPH = (availableData, oArray, row, onClick, ismobileEnabled) => {
  let availabilitySection = [];
  !_isEmpty(availableData) && availableData.map((singleAvailability, availableKey) => {
    if (singleAvailability.availableQty > 0 || singleAvailability.availableDuration === 'Your Store' || singleAvailability.availableDuration === 'Store') {
      if (singleAvailability.availableDuration === "Next Business Day" || singleAvailability.availableDuration === "Next 2 + Business Days" || singleAvailability.availableDuration === 'Next 2 Weeks') {
        availabilitySection.push(
          <div className="catalog-availability-section">

            <span className="store-maroon-text avail-qty-width bold">
              {singleAvailability.availableQty}
            </span>
            <span className="text-black tph-store-avail-align">
              &nbsp;
              &nbsp;
              {checkIfMobileEnabled(singleAvailability?.storeAvailabilities, onClick, ismobileEnabled, singleAvailability.availableDuration)};
            </span>
          </div>
        );
      }
      else {
        singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities.map((storeData, key) => {
          availabilitySection.push(
            <div className="catalog-availability-section">
              <div>{storeData.storeQty == 0 ?
                <span className={availableKey == 0 ? "store-maroon-text avail-qty-width full-view-text-grey-Zero" : "store-maroon-text avail-qty-width bold"}>{storeData.storeQty}
                </span> :
                <span className={availableKey == 0 ? "store-maroon-text avail-qty-width full-view-text-grey" : "store-maroon-text avail-qty-width bold"}>{storeData.storeQty}
                </span>
              }

                <span className="text-black tph-store-avail-align">{singleAvailability.availableDuration === "Your Store" ? ` at Your Store #` : ` at Store #`}<b>{storeData.store}</b>
                  {singleAvailability.availableDuration == 'Direct Order Store' || singleAvailability.availableDuration == 'Pick Up Store' ?
                    (<OverlayTrigger
                      placement="bottom"
                      delay={{ show: 100, hide: 400 }}
                      overlay={
                        <Tooltip id="toggle-view-part-compact" className="custom-home-icon-tooltip">
                          {singleAvailability.storeAvailabilities.length > 0 ?
                            (<ul>
                              <li>{singleAvailability.availableDuration}, {storeData.storeQualifier ? storeData.storeQualifier : ""}</li>
                            </ul>)
                            : null}

                        </Tooltip>
                      }
                    >
                      <b>{singleAvailability.availableDuration == 'Direct Order Store' || singleAvailability.availableDuration == 'Pick Up Store' ? (<a class="maroonColorTxt info-icon full-view-store"><i class="fa fa-info-circle"></i></a>) : null}</b>
                    </OverlayTrigger>) : null}
                </span>
              </div>
            </div>
          );
        });
      }
    }
  });
  return availabilitySection
}

export const getNormalAvailabilityForState = (row, qty, ismobileEnabled, onclick, isBlackColorCode) =>{
  if(_isEmpty(row.availabilityForOrderQty)) {
    row.availabilityForOrderQty = distributeAvailability(
      1,
      row.availabilityAll
    );
  } 
  const availabilityOptns = [];
    if (row.availabilityForOrderQty !== null && row.availabilityForOrderQty[0]?.orderQty !== null) {
      if (row.availabilityForOrderQty.length > 0) {
        row.availabilityForOrderQty.map((item) => {
          let time = item.availabilityHour?.split("") || item.availableDuration?.split("");
          let availabilityHour = item.availabilityHour || item.availableDuration;
          availabilityOptns.push(
            <li>
              <span className={isBlackColorCode ? "" : "maroonColorTxt"}>
                <b>{item.availableQty}</b>
              </span> 
            {" "}{_head(time) === "0" ? checkIfMobileEnabled(item?.storeAvailability, onclick, ismobileEnabled, "Within") : ""}{" "}
            { _head(time) === "0" ? formatTimeAvb(availabilityHour) : checkIfMobileEnabled(item?.storeAvailability, onclick, ismobileEnabled, availabilityHour)}
            </li>
          );
        });
        return availabilityOptns;
      }
      if (
        qty !== null &&
        qty > 0 &&
        row.availabilityForOrderQty.length === 0
      ) {
        return <span>Not Available</span>;
      }
    }
    return <span></span>;
}

const getNormalAvailabilityForTPH = () =>{
}

export {
  getAvailability  
};
