import httpClient from "../../services/http";
import { buildQueryUrl } from "../../helpers/utils";

// const buildQueryUrl = (payload) => {
//     const params = [];
//     for (const k in payload) {
//       if (payload.hasOwnProperty(k)) {
//         const element = payload[k];
//         params.push(encodeURIComponent(k) + "=" + encodeURIComponent(element));
//       }
//     }
//     return params.join("&");
//   };

export const getPartList = (data) =>
  httpClient.post(`/order-service/pricing-availability`, data);


export const getPartListFromFile = (data) =>
  httpClient.post(`/order-service/stock-pricing/import`, data);

export const getBuyersGuideListFromPart = (data) =>
  httpClient.get(`/catalog/bg/assistData?${buildQueryUrl(data)}`, {
    dispatch: data.dispatch,
  });

// export const getChemicalPartList = (data) =>
//     httpClient.get(`/catalog/chemical/search?${buildQueryUrl(data)}`);

export const getOrderNumberFromMfgCode = (data) =>
  httpClient.get(`/catalog/bg/assistData/mfg?${buildQueryUrl(data)}`, {
    dispatch: data.dispatch,
  });

export const getC2CLink = (data) =>
  httpClient.get(`catalog/part/localC2C?${buildQueryUrl(data)}`, {
    dispatch: data.dispatch,
  });

export const getVideoArticleLinks = (data) =>
  httpClient.get(`/catalog/bg/assistData/videoAndArticleLinks?${buildQueryUrl(data)}`);

// export const addItemsToCart = (data) =>
//     httpClient.post(`/order-service/carts/items`, data);

//Magicalword
export const getMagicalWords = () => httpClient.get(`/catalog/magicwords`);

export const getStockInterchange = (data) =>
	httpClient.get(`/orchestrator-service/interchange?${buildQueryUrl(data)}`);
