import React, { useState, useRef } from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import { timeInCST, timeInUTC } from "../../helpers/utils";

import CartSinglePart from "./CartSinglePart";
import Placeholder from "../shared/Placeholder";
import vehicleMaintIcon from "../../static/images/vehicleMaintenance.png";
import fluidsAndFiltersIcon from "../../static/images/fluidsAndFilters.png";

import VehicleMaintenancePopup from "../../components/shared/VehicleMaintenance";
import FluidsAndFiltersPopup from "../../components/shared/FluidsAndFilters";
import { findKey } from "lodash";
import ReactToPrint from "react-to-print";

function CartPartDetails(props) {
  let {
    cartAllDetails,
    cartApiFetching,
    handleDeleteItem,
    handleInputOnBlur,
    handleInputOnChange,
    hideCostPrice,
    userDetails,
  } = props;
  const [showVehicleMaintenance, setShowVehicleMaintenance] = useState([]);
  const [showFluidsAndFilters, setShowFluidsAndFilters] = useState([]);
  let componentRef = useRef([]);

  const handleVehicleMaintenance = (key, openOrClose) => {
    let showVehMaintanace = [];
    if (openOrClose == 1) {
      showVehMaintanace[key] = true;
    } else {
      showVehMaintanace[key] = false;
    }
    setShowVehicleMaintenance(showVehMaintanace);
  };

  const handleFluidSpecs = (key, openOrClose) => {
    let showFluidSpec = [];
    if (openOrClose == 1) {
      showFluidSpec[key] = true;
    } else {
      showFluidSpec[key] = false;
    }
    setShowFluidsAndFilters(showFluidSpec);
  };

  const renderPartDetails = () => {
    let partSection = [];
    if (!_isEmpty(cartAllDetails)) {
      let cartStore =
        cartAllDetails[0] && cartAllDetails[0].store
          ? cartAllDetails[0].store
          : "";
      let defultDeliveryStore =
        userDetails &&
        userDetails.shipTos &&
        userDetails.shipTos[0] &&
        userDetails.shipTos[0].location &&
        userDetails.shipTos[0].location.storeNumber
          ? userDetails.shipTos[0].location.storeNumber
          : "";
      let pickupDetails =
        defultDeliveryStore == cartStore ? "Delivery" : "Pickup";
      // partSection.push(
      // <React.Fragment>
      //     <span className="pickup-details">{`${pickupDetails} From Store ${defultDeliveryStore}`}</span><br/>
      // </React.Fragment>);
      cartAllDetails.map((part, key) => {
        partSection.push(
          <React.Fragment>
            {cartAllDetails[key - 1] &&
            cartAllDetails[key - 1].vehicleInfo === part.vehicleInfo ? null : (
              <React.Fragment>
                <p>{part.vehicleInfo}</p>
                {part.vehicleInfo !== "Non-Vehicle Specific" ? (
                  <React.Fragment key={key}>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="button-tooltip">
                          Vehicle Maintenance
                        </Tooltip>
                      }
                    >
                      <input
                        type="image"
                        className="vehicle-maint-icon"
                        onClick={() => handleVehicleMaintenance(key, 1)}
                        src={vehicleMaintIcon}
                        alt="Vehicle Maintenance Icon"
                      />
                    </OverlayTrigger>
                    {showVehicleMaintenance[key] ? (
                      <VehicleMaintenancePopup
                        openPopup={showVehicleMaintenance[key]}
                        selectedVehicle={{ ...part, year: part.yearId }}
                        closePopup={() => handleVehicleMaintenance(key, 0)}
                      />
                    ) : null}
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id="button-tooltip">Vehicle Specs</Tooltip>
                      }
                    >
                      <input
                        type="image"
                        className="fluids-filters-icon"
                        onClick={() => handleFluidSpecs(key, 1)}
                        src={fluidsAndFiltersIcon}
                        alt="Fluids and Filters Icon"
                      />
                    </OverlayTrigger>
                    {showFluidsAndFilters[key] ? (
                      <FluidsAndFiltersPopup
                        openPopup={showFluidsAndFilters[key]}
                        selectedVehicle={{ ...part, year: part.yearId }}
                        closePopup={() => handleFluidSpecs(key, 0)}
                      />
                    ) : null}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </React.Fragment>
            )}
            <CartSinglePart
              qtyValidity={part.qtyValidity || {}}
              partDetails={part}
              previousPartDetails={cartAllDetails[key - 1] || {}}
              handleDeleteItem={handleDeleteItem}
              handleInputOnBlur={handleInputOnBlur}
              handleInputOnChange={handleInputOnChange}
              hideCostPrice={hideCostPrice}
            />
          </React.Fragment>
        );
      });
      return partSection;
    } else {
      return <span>No items in cart</span>;
    }
  };
  return (
    <Col lg="9" ref={(el) => (componentRef = el)}>
      <div className="">
        <div className="print__logo">
          <img alt="" src="/public_images/Print_Logo.png" align="center" />
        </div>
      </div>
      <div className="cart-print-heading">CART</div>
      <div className="gray_box_container cart_scroll">
        {/* {cartAllDetails && cartAllDetails.length > 0 && (
					// <p>
					// 	2005 Dodge Ram 2500 Laramie 5.9L L6 Turbo Diesel VIN:C
					// </p>
				)} */}
        <ReactToPrint
          copyStyles={true}
          documentTitle="XLParts: RAPID"
          trigger={() => {
            return (
              <a className="cart-print-this-out" href="#">
                <div class="print_icon d-flex">
                  <span class="material-icons">print</span>
                  <span>Print</span>
                </div>
              </a>
            );
          }}
          content={() => componentRef}
        />
        {!cartApiFetching ? renderPartDetails() : <Placeholder rows={2} />}
      </div>
      <div className="page-footer" id="footer">
        {userDetails.x2userName}
        <div className="page-footer_time" id="footer">
          {timeInCST()}
        </div>
      </div>
    </Col>
  );
}
export default CartPartDetails;
