import React, { useEffect, useState } from 'react';
import { Form, Accordion } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from "lodash/isEmpty";
import PartTypes from "../PartTypes";
import { vehicleSelectPrompt } from "../../../../helpers/utils";
import { triggerCustomUxCriticalError } from "../../../../actions/error";

function ClassicCategoryWrapper(props) {
  let {
		handleClick,
		selectedGroupDetails,
    partTypeAPICall ,
    PayloadData  ,
    PartTypePayloadData ,
    partTypeSelectionData,
    myGarageSelectedVehicle
	} = props;
  const parts = useSelector((state) => state.parts);
  const dispatch = useDispatch();
  const selectedPartData = parts && parts.selectedCatalogPartTypes;
  const partTypesData  = parts && parts.homePagePartTypes ;
  const [categoryGroupMap, setCategoryGroupMap]  = useState([]);
  const [productGroupsHeading, setProductGroupsHeading] = useState('Product Groups');
  const [ShowPartTypes , SetShowPartType] = useState("");
  const sv = useSelector(state => state.mygarage.selectedVehicle);
  let partTypeData = _.cloneDeep(partTypesData);
  let selectedPart = _.cloneDeep(selectedPartData);

  useEffect(() => {
    let categoryGroups = parts.categoryGroupMap.map(category => ({...category, selected: false, active: false, enableSelectAll:false}));
    let checkCatagorySelected = categoryGroups.find((v)=> v.selected);
    if(_isEmpty(checkCatagorySelected)){
      clearPayload()
    }
    setCategoryGroupMap(_cloneDeep(categoryGroups));
  }, [parts.categoryGroupMap]);

  useEffect(() => {
    let checkCatagorySelected = categoryGroupMap.find((v)=> v.selected);
    if(!_isEmpty(sv) &&
      selectedGroupDetails && selectedGroupDetails.length > 0 && !_isEmpty(checkCatagorySelected)){
      selectedGroupDetails?.map((v)=>{
      partTypeAPICall(true ,v ,v.categoryId , true);
      SetShowPartType(v.categoryId);
    })}
  }, [sv]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

   const  clearPayload =()=>{
    dispatch({
      payload: {
      selectedCatalogPartTypes: [],
      },
      type: 'SELECTED_PART_CATALOG_TYPES',
    });
    dispatch({
		  payload: {
		  homePagePartTypes: [],
		  },
		  type: 'HOME_PAGE_PART_TYPES',
		});
		}
  const handleCategorySelect = (event, category) => {
    const isChecked = event.target.checked;
    let selectedPartType = _.cloneDeep(parts.selectedCatalogPartTypes);
    let matchingCategory = categoryGroupMap.find((data) => {
			return data.code === category.code;
		});
    let matchingPartType = !_isEmpty(partTypeData) &&  partTypeData.filter((group) => {
			return group.categoryId === category.code});

		matchingCategory.selected = isChecked;
    matchingCategory.active = isChecked;
		setCategoryGroupMap([...categoryGroupMap]);
    if(!isChecked) {
      const selectedGroups = [...selectedGroupDetails];
      selectedGroups.map((group, index) => {
        if(group.categoryId === matchingCategory.code) {
          matchingCategory.enableSelectAll = isChecked;
          matchingCategory.groups.map((gp)=>{
           gp.selected = isChecked;
         })
          if(!_isEmpty(matchingPartType) && !_isEmpty(selectedPartType)){
           let partArr =[];
           matchingPartType.map((v)=>{
            v.selected = isChecked;
            v.partTypes.map((s)=>{
              partArr.push(s)})})

          let uniqueResultOne = selectedPartType.filter(function(obj) {
            return !_isEmpty(partArr) && !partArr.some(function(obj2) {
                return obj2.ptSelected && ( obj.id == obj2.id );
            }); });

          PayloadData(uniqueResultOne);
          }else if(!_isEmpty(matchingPartType)){
            matchingPartType.map((v)=>{
              v.selected = isChecked;
            })
          }
          handleClick(null, group, matchingCategory);
        }
      });
    }
      PartTypePayloadData(partTypeData);
    _findIndex([...categoryGroupMap],{selected: true}) >= 0 ? setProductGroupsHeading('Product Groups - Select one or more') : setProductGroupsHeading('Product Groups') ;
  }

  const toggleCategoryGroup = (event, category) => {
    let matchingCategory = categoryGroupMap.find((data) => {
			return data.code === category.code;
		});
    matchingCategory.active = !matchingCategory.active;
		setCategoryGroupMap([...categoryGroupMap]);
  }

  const renderCategories = () => {
  	return categoryGroupMap.map((category, index) => {
      return (
        category.groups && category.groups.length > 0 && (
          <Form.Group controlId={`CategoriesCheckbox${index+1}`}>
            <Form.Check
              type="checkbox"
              label={category.name}
              onChange={(e) => handleCategorySelect(e, category)}
            />
          </Form.Group>
	      )
      );
		});
  };

  const selectAllGroups = (category, e) => {
    vehicleSelectPrompt(e.target.checked, dispatch, myGarageSelectedVehicle, triggerCustomUxCriticalError);
    let categoryGroups = [...categoryGroupMap];
    const isChecked = e.target.checked;
    category.enableSelectAll = isChecked;

    category.groups.map((group, index) => {
      let groupIndex;
      if (category.canSelect) {
				groupIndex = _findIndex(selectedGroupDetails, {
					code: Number(group.code),
          categoryId: Number(category.code)
				});
			}
			if (category.canSelectChemical) {
				groupIndex = _findIndex(selectedGroupDetails, {
					id: Number(group.id),
				});
			}
      if (isChecked) {
        group.selected= true;
				if (groupIndex === -1) {
					handleClick(e, group, category);
				}
			} else {
        group.selected= false;
				if (groupIndex !== -1) {
					handleClick(e, group, category);
				}
			}
  });

  if (isChecked) {
    const apiCalls = selectedGroupDetails?.map((v) => partTypeAPICall(isChecked, v, category));
    Promise.all(apiCalls).then(() => {
      SetShowPartType(category.code);
      if(!_isEmpty(myGarageSelectedVehicle)){
        ScrollToMiddle();
      }
    }).catch((error) => {
      console.error("Error in API calls:", error);
    });
  } else {
    SetShowPartType(category.code);
  }
  setCategoryGroupMap(categoryGroups);
};
const ScrollToMiddle = () => {
  const element = document.querySelector('.scrolltopartypesheader');

  if (element) {
    const offsetTop = element.offsetTop;
    const scrollOffset = -200; // Adjust this value as needed
    const scrollTo = offsetTop - (window.innerHeight / 2 - element.offsetHeight / 2) - scrollOffset;
    window.scrollTo({
      top: scrollTo,
      behavior: 'smooth',
    });
  }
}

const handleSelectAllPartType =(category)=>{
  let matchingPartType = !_isEmpty(partTypeData) &&  partTypeData.filter((group) => {
    return group.categoryId === category.code});
    let partArr =[];
    !_isEmpty(matchingPartType) && matchingPartType.map((v)=>{
      if(!_isEmpty(v.partTypes)){ v.partTypes.map((v1)=>{
          partArr.push(v1);
        })}})
  let uniqueResultOne = !_isEmpty(selectedPart) && selectedPart.filter(function(obj) {
    return !_isEmpty(partArr) && !partArr.some(function(obj2) {
        return ( obj.id == obj2.id );
    });
  });
  _.remove(partTypeData ,(sp) => sp.categoryId == category.code);
  if(!_isEmpty(partTypeData)){

   partTypeData.map((v)=>{
    if(!_isEmpty(v.partTypes)){
  v.partTypes.map((v1)=>
  {uniqueResultOne.push(v1)})}})
  }
  else{
    uniqueResultOne=[]
    selectedPart =[]
  }

  PartTypePayloadData(partTypeData);
  PayloadData(uniqueResultOne);
}
const handleCustomClick = (e, data, category) => {
  let matchingCategory = [];
  let singleCategoryGroups;
  vehicleSelectPrompt(e.target.checked, dispatch, myGarageSelectedVehicle, triggerCustomUxCriticalError);
  matchingCategory = selectedGroupDetails.filter((item) => {
    return item.categoryId == category.code;
  })
  singleCategoryGroups = categoryGroupMap;
  if ((matchingCategory.length == category.groups.length) || (matchingCategory.length >= (category.groups.length - 1))) {
    let selectedIndex;
    if (category.canSelect) {
      selectedIndex = _findIndex(matchingCategory, {
        code: data.code,
      });
    }
    category.enableSelectAll = selectedIndex === -1 ? true : false;
    data.selected =  selectedIndex === -1 ? true : false;
  }
  else if(_isEmpty(data.selected)) {
         data.selected = data.selected ? false : true;
  }

  let checked = e && e.target && e.target.checked ? true : false;
  partTypeAPICall(checked ,data,category)
  if(checked && !_isEmpty(myGarageSelectedVehicle)) {
    ScrollToMiddle();
  }
  SetShowPartType(category.code);
  setCategoryGroupMap(singleCategoryGroups);
  handleClick(e, data, category);
}

  const renderCategoryGroups = () => {
  	return categoryGroupMap.map((category, cIndex) => {
      return (
        category.selected && (
          <Accordion defaultActiveKey="0">
            <div className="classicListWrapper">
              <Accordion.Toggle
                as="div"
                className="accordionHead"
                variant="link"
                eventKey="0"
                onClick={(e) => toggleCategoryGroup(e, category)}
              >
                <div class={`classicListWrapperHead ${category.active ? 'expanded' : 'collapsed'}`}>
                  {category.name}
                </div>
              </Accordion.Toggle>
    					<Accordion.Collapse eventKey="0">
    						<div className="classicListWrapperBody">
                <Form.Group >
                  <strong>
                  <Form.Check type="checkbox"
                    checked= {
                        category && category.enableSelectAll
                    }
                    onChange={(e) => {
                        selectAllGroups(category, e);
                    }}
                     label="Select All Groups" />
                  </strong>

                  </Form.Group>
                  {category.groups.map((group, gIndex) => {
                    return (
                      <Form.Group controlId={`GroupsCheckbox${cIndex+1}${gIndex+1}`}>
                        <Form.Check
                          type="checkbox"
                          label={group.name}
                          checked= {
                            group && group.selected
                          }
                          onClick={(e) => handleCustomClick(e, group, category)}
                       />
                      </Form.Group>
                    );
                  })}
    						</div>
    					</Accordion.Collapse>
            </div>
  				</Accordion>
	      )
      );
		});
	};

  return (
    <section className="classicSection classicSection_parts">
      <Container>
       <div class="containerHead">All Products Catalog</div>
        <div className="classicWrapper">
          <div className="classicCategories_partTypes">
            <div className="classicMainHead">Product Categories - Select one or more</div>
            <div className="classicListWrapper">
              <div className="classicListWrapperBody">
                {renderCategories()}
              </div>
            </div>
          </div>
          <div className="classicGroups_partTypes">
            <div className="classicMainHead">{productGroupsHeading}</div>
            <Accordion defaultActiveKey="0">
              <div className="classicListWrapper">
                {renderCategoryGroups()}
              </div>
            </Accordion>
          </div>
          <PartTypes
          categoryGroupMap={categoryGroupMap}
          PayloadData ={PayloadData}
          PartTypePayloadData ={PartTypePayloadData}
          ClassicView ={"ClassicView"}
          partTypeSelectionData={partTypeSelectionData}
        />
        </div>
      </Container>
    </section>
  );
}

export default ClassicCategoryWrapper;
