const { createSlice } = require("@reduxjs/toolkit");
const {
  getPaymentsPrintPDF,
  getPaymentsInvoiceByEmail,
} = require("./payments-print.thunk");

let initialState = {
  loading: false,
  emailSent: false,
};

const paymentsPrint = createSlice({
  name: "paymentsPrint",
  initialState,
  reducers: {
    resetEmailSent: (state) => {
      state.emailSent = false;
    },
  },
  extraReducers: {
    [getPaymentsPrintPDF.pending]: (state, action) => {
      state.loading = true;
    },
    [getPaymentsPrintPDF.fulfilled]: (state, action) => {
      state.pdf = action.payload;
      state.loading = false;
    },
    [getPaymentsPrintPDF.rejected]: (state, action) => {
      state.loading = false;
    },

    [getPaymentsInvoiceByEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [getPaymentsInvoiceByEmail.fulfilled]: (state, action) => {
      state.emailSent = true;
      state.loading = false;
    },
    [getPaymentsInvoiceByEmail.rejected]: (state, action) => {
      state.emailSent = false;
      state.loading = false;
    },
  },
});

const { actions, reducer } = paymentsPrint;

export const { resetEmailSent } = actions;

export default reducer;
