import React, {useEffect,useRef} from 'react';
import { Container, Row, Form, Button, Spinner } from 'react-bootstrap';
import FormValidationErrorMessage from '../shared/FormValidationErrorMessage/FormValidationErrorMessage';

function MFALogin({ handleSubmit, handleInputChange, userApiCall, validity, handleInputBlur }) {
	const inputRef = useRef(null);
	useEffect(() => {
		inputRef.current.focus();
	}, [])
	return (
		<Container fluid className="login_container">
			<Row className="login_title">
				<span>Two Step Authentication Enabled</span>
			</Row>
			<Row className="login_box">
				<span>Please input One Time Password received through SMS</span>
				<div>
					<Form className="login_form" onSubmit={handleSubmit}>
						<div className="returning_customer_container">
							<div className="input_fields">
								<Form.Control
									ref={inputRef}
									size="sm"
									type="text"
									name="smsConfirmationCode"
									onChange={handleInputChange}
									onBlur={handleInputBlur}
									placeholder="Please Input One Time Password*"
								/>
								<FormValidationErrorMessage
									condition={!validity.isConfirmationNotNull}
									errorMessage="Please enter your Confirmation Code"
								/>
							</div>
							{userApiCall ? (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									disabled
								>
									<Spinner
										as="span"
										animation="grow"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									Loading..
								</Button>
							) : (
								<Button
									className="xlp-submit-btn"
									variant="primary"
									type="submit"
								>
									Submit
								</Button>
							)}
						</div>
					</Form>
				</div>
			</Row>
		</Container>
	);
}
export default MFALogin;
