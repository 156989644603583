/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import "./styles.scss";
import {
  USERDEF,
  convertValue,
  currencyFormatter,
  getMonth,
} from "helpers/utils";
import XCharts from "components/Charts";
import ItemsRender from "pages/c_and_k/SingleView/items";
import { useDispatch, useSelector } from "react-redux";
import { benefitDetails, getDashboard } from "../programbenefits.thunk";
import _ from "lodash";
import moment from "moment";
import _isEmpty from "lodash/isEmpty";
import BootstrapTable from "react-bootstrap-table-next";
import ReactLoading from "react-loading";
import ReportCard from "../components/reportCard";

const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const ReportOverview = (props) => {
  const { handleProgramTab, programListAcess } = props;

  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const headerStyle = {
    backgroundColor: "#474747",
    color: "#FFFFFF",
  };
  const pnb = useSelector((state) => state.ProgramAndBenefits);
  const gmPartnerPerks = pnb.gmPartnerPerks || {};
  const mtapsn = pnb.mtapsn;
  const batteryProgram = pnb.batteryProgram;
  const laborClaim = pnb.laborClaim;
  // const xlprewards = pnb.xlpRewards;
  const volumeRebate = pnb.volumeRebate;
  const dashboard = pnb.dashboard;
  const {
    customerDashBoardYTDSalesAndReturns,
    fillRates,
    deliveryStats,
    customerDashBoardCategories,
    customerDashBoardItems,
  } = dashboard ?? [];
  let defautStore =
    userDetails &&
    userDetails.shipTos &&
    userDetails.shipTos[0] &&
    userDetails.shipTos[0].location &&
    userDetails.shipTos[0].location.storeNumber;

  const batteryPurchased = batteryProgram.batteryPurchased;
  const programList = pnb.programList;
  const [userDefines, setUserDefines] = useState(null);
  const [mtaItems, setMtaItems] = useState({});
  const [dashboardData, setDashBoardData] = useState([]);
  const [lineCodeData, setLineCodeData] = useState([]);
  const [batteryPurchasedGraphData, setBatteryPurchasedGraphData] = useState(
    []
  );
  const currentYear = moment().year().toString();
  const previousYear = (moment().year() - 1).toString();

  const showHide = (programData) => {
    return !_.find(programListAcess, function (o) {
      return o.ptName === programData;
    }).enable;
  };

  const getDashboardDetails = () => {
    const { billTo } = userDetails || {};
    let customerNumber = billTo.customerCode;
    const payload = {
      customerId: customerNumber,
      noOfMonths: 12,
      location: defautStore,
    };

    dispatch(getDashboard(payload));
  };

  const getVolmeRebateDetails = () => {
    const { xlPartAccountNumber } = userDetails || {};
    let customerNumber = xlPartAccountNumber;
    const payload = {
      name: "pvpVolumeRebate",
      customerId: customerNumber,
      noOfMonths: 12,
    };

    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });

    dispatch(benefitDetails(payload))
      .then((action) => {
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      })
      .catch((error) => {
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      });
  };

  // const getXlpRewardDetails = () => {
  //   const { xlPartAccountNumber } = userDetails || {};
  //   let customerNumber = xlPartAccountNumber;
  //   const payload = {
  //     name: "xlpRewards",
  //     customerId: customerNumber,
  //     noOfMonths: 2,
  //   };
  //   dispatch(benefitDetails(payload));
  // };

  const getLaborClaimDetails = () => {
    const { xlPartAccountNumber } = userDetails || {};
    let customerNumber = xlPartAccountNumber;
    const payload = {
      name: "laborClaim",
      customerId: customerNumber,
      noOfMonths: 12,
    };
    dispatch(benefitDetails(payload));
  };

  const getAllBatteryDetails = () => {
    const { xlPartAccountNumber } = userDetails || {};
    let customerNumber = xlPartAccountNumber;
    const payload = {
      name: "batteryConsignment",
      customerId: customerNumber,
      noOfMonths: 11,
    };
    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
    dispatch(benefitDetails(payload)).then((action) => {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
    });
  };

  const getMTAPSN = () => {
    const { billTo } = userDetails || {};
    let customerNumber = billTo.customerCode;
    const payload = {
      name: "mtapsn",
      customerId: customerNumber, //customerNumber,
      noOfMonths: 2,
    };
    dispatch(benefitDetails(payload));
  };

  const getAllDetails = () => {
    const { billTo } = userDetails || {};
    let customerNumber = billTo.billToId;
    const payload = {
      name: "acdGmPartner",
      customerId: customerNumber, //'103735',
      noOfMonths: 11,
    };
    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });

    dispatch(benefitDetails(payload)).then((_action) => {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
    });
  };

  useEffect(() => {
    // Customer Dashboard Action Dispatch
    getDashboardDetails();

    getAllDetails();

    getMTAPSN();

    getAllBatteryDetails();

    getLaborClaimDetails();

    getVolmeRebateDetails();
  }, [userDetails.billTo.billToId]);

  useEffect(() => {
    if (programList && programList.length > 0) {
      const _programList = _.head(programList);
      setUserDefines(_programList);
    }
  }, [programList]);

  useEffect(() => {
    if (mtapsn && mtapsn.mtaItems && mtapsn.mtaItems.length > 0) {
      const _mtaItems = _.head(mtapsn.mtaItems);
      setMtaItems(_mtaItems);
    }
  }, [mtapsn]);

  useEffect(() => {
    if (batteryProgram && batteryProgram.batteryPurchased) {
      const _graphData = batteryPurchased.map((bp) => {
        return {
          name: getMonth(bp.monthYear),
          "Battery Purchased": Number(bp.batteriesPurchased) || 0,
          key: "Battery Purchased",
        };
      });

      setBatteryPurchasedGraphData(_graphData);
    }
  }, [batteryPurchased]);

  useEffect(() => {
    if (!_isEmpty(customerDashBoardCategories)) {
      const _graphData = customerDashBoardCategories.map((bp) => {
        return {
          categories: bp.categoryDesc.trim(),
          [previousYear]: Number(bp.previousYear) || 0,
          [currentYear]: Number(bp.currentYear) || 0,
          // key: "Categories Purchased",
        };
      });
      setDashBoardData(_graphData);
    }
  }, [customerDashBoardCategories]);

  useEffect(() => {
    if (!_isEmpty(customerDashBoardItems)) {
      const _graphData = customerDashBoardItems.map((bp) => {
        return {
          id: bp.topLineCodes,
          label: bp.topLineCodes,
          value: Number(Number(bp.sales).toFixed(2)) || 0,
          // key: "Categories Purchased",
        };
      });

      setLineCodeData(_graphData);
    }
  }, [customerDashBoardItems]);

  // const getXLPRewardsGraphData = () => {
  //   let _graphData = [];
  //   if (!_isEmpty(xlprewards && xlprewards.monthlyAchievementCustomer)) {
  //     const monthlySales = xlprewards.monthlyAchievementCustomer;

  //     _graphData = monthlySales.map((ms) => {
  //       return {
  //         month: getMonth(ms.month),
  //         Sales: Number(ms.totalSalesXLPRewards),
  //       };
  //     });
  //   }
  //   return _graphData;
  // };

  const getGMPartnerPerksGaugeChartData = () => {
    const acdGmLySales = gmPartnerPerks.acdGMLYYearlySales;
    const lastYear = moment().subtract(1, "years").format("YYYY");
    const currYear = moment().year().toString();

    let lySales = acdGmLySales?.find((m) => m.year === lastYear);
    lySales = lySales ? lySales.yearlyTotalSales : 0;

    let currLySales = acdGmLySales?.find((m) => m.year === currYear);
    currLySales = currLySales ? currLySales.yearlyTotalSales : 0;

    const gmSalesTrend = [
      {
        id: lastYear,
        data: [
          {
            x: lastYear,
            y: Number(lySales),
          },
        ],
      },
      {
        id: currYear,
        data: [
          {
            x: currYear,
            y: Number(currLySales),
          },
        ],
      },
    ];

    return gmSalesTrend;
  };

  const getMTAPSNgaugeChartData = () => {
    const mtaLySales = mtapsn?.mtalyYearlySales;
    const lastYear = moment().subtract(1, "years").format("YYYY");
    const currYear = moment().year().toString();

    let lySales = mtaLySales?.find((m) => m.year === lastYear);
    lySales = lySales ? lySales.yearlyMTASales : 0;

    let currLySales = mtaLySales?.find((m) => m.year === currYear);
    currLySales = currLySales ? currLySales.yearlyMTASales : 0;

    const mtaSalesTrend = [
      {
        id: lastYear,
        data: [
          {
            x: lastYear,
            y: Number(lySales),
          },
        ],
      },
      {
        id: currYear,
        data: [
          {
            x: currYear,
            y: Number(currLySales),
          },
        ],
      },
    ];

    return mtaSalesTrend;
  };

  useEffect(() => {
    if (!_isEmpty(deliveryStats)) {
      let deliveryRowData = [];
      deliveryRowData = deliveryStats.map((item, index) => {
        return {
          period: item.period,
          invoices: item.invoices,
          avgDeliveyTime: item.adtMinutes,
          index: index,
        };
      });
      setTableData(deliveryRowData);
    }
  }, [deliveryStats]);

  const deliveryStatsColumn = [
    {
      dataField: "period",
      text: "Period",
      headerStyle: headerStyle,
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "invoices",
      text: "# Invoices",
      headerStyle: headerStyle,
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "avgDeliveyTime",
      text: "Avg Delivery Time(Minutes)",
      headerStyle: headerStyle,
      align: "center",
      headerAlign: "center",
    },
  ];

  const noDataState = () => {
    return (
      <p align="center" style={{ padding: "0", margin: "0" }}>
        No Data Found
      </p>
    );
  };

  return (
    <div className="report-overview">
      <div className="first-row">
        <div
          className="r-left tile"
          onClick={() => handleProgramTab("ACDGMPartnerPerks")}
        >
          <div className="section-title">GM Partner Perks</div>
          <div className="rc-containers">
            <div className="report-card-wrapper">
              <ReportCard
                head="Customer No."
                subHead="(GM Partner Perks)"
                isLoading={pnb.loading}
              >
                <div className="cd-currency">
                  {!_isEmpty(userDefines) &&
                  !_isEmpty(userDefines[USERDEF?.ACD_GM_PARTNER_PERKS])
                    ? userDefines[USERDEF?.ACD_GM_PARTNER_PERKS]
                    : "NOT ENROLLED"}
                </div>
              </ReportCard>
            </div>
            <div className="report-card-wrapper">
              <div className="report-card">
                <div className="rcard-title">
                  <div>
                    Total Sales YTD{" "}
                    <span className="sub-text">(GM Partner Perks)</span>{" "}
                  </div>
                  {!pnb.isDashboardLoaded && (
                    <ReactLoading type="spin" height={32} width={32} />
                  )}
                </div>
                <div className="rcard-content">
                  <div className="cd-currency">
                    {(getGMPartnerPerksGaugeChartData() &&
                      getGMPartnerPerksGaugeChartData()[1].data[0].y &&
                      currencyFormatter(
                        getGMPartnerPerksGaugeChartData()[1].data[0].y
                      )) ||
                      currencyFormatter(0)}
                    {/* {(gmPartnerPerks &&
                      gmPartnerPerks.totalSales &&
                      currencyFormatter(gmPartnerPerks.totalSales)) ||
                      currencyFormatter(0)} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="report-card-wrapper">
              <ReportCard
                head="YTD Trend to LY"
                subHead="(GM Partner Perks)"
                isLoading={!pnb.isDashboardLoaded}
              >
                <XCharts
                  chartType="nivoRadialChart"
                  data={getGMPartnerPerksGaugeChartData()}
                  gaugeLabel={convertValue(
                    getGMPartnerPerksGaugeChartData()[0].value
                  )}
                />
              </ReportCard>
            </div>
          </div>
        </div>
        <div className="r-middle tile">
          <div className="rm-first-col">
            <div className="report-card-wrapper">
              <ReportCard
                head="Returns vs Purchases"
                isLoading={!pnb.isDashboardLoaded}
              >
                <ItemsRender
                  direction="col"
                  items={[
                    {
                      label: "YTD New Returns",
                      value:
                        (customerDashBoardYTDSalesAndReturns &&
                          customerDashBoardYTDSalesAndReturns.rebateCredited &&
                          currencyFormatter(
                            customerDashBoardYTDSalesAndReturns.rebateCredited
                          )) ||
                        currencyFormatter(0),
                    },
                    {
                      label: "YTD Sales",
                      value:
                        (customerDashBoardYTDSalesAndReturns &&
                          customerDashBoardYTDSalesAndReturns.totalSalesXLPRewards &&
                          currencyFormatter(
                            customerDashBoardYTDSalesAndReturns.totalSalesXLPRewards
                          )) ||
                        currencyFormatter(0),
                    },
                  ]}
                />
              </ReportCard>
            </div>
            <div className="report-card-wrapper">
              <ReportCard
                head="In Stock Fill Rates YTD"
                isLoading={!pnb.isDashboardLoaded}
              >
                <ItemsRender
                  items={[
                    {
                      label: "",
                      value:
                        (fillRates && `${Number(fillRates).toFixed(2)}%`) || 0,
                    },
                  ]}
                />
              </ReportCard>
            </div>
            <div className="report-card-wrapper">
              <ReportCard
                head="Labor vs Purchases"
                isLoading={!pnb.isDashboardLoaded}
              >
                {/* <ItemsRender
                  direction="col"
                  items={[
                    {
                      label: "",
                      value: (laborClaim && laborClaim.laborVsPurchases) || 0,
                    },
                  ]}
                /> */}
                <ItemsRender
                  direction="col"
                  items={[
                    {
                      label: "YTD Labor",
                      // value: (laborClaim && laborClaim.laborVsPurchases) || 0,
                      value: currencyFormatter(
                        (laborClaim && laborClaim.totalSales) || 0
                      ),
                    },
                    {
                      label: "YTD Sales",
                      value:
                        (customerDashBoardYTDSalesAndReturns &&
                          customerDashBoardYTDSalesAndReturns.totalSalesXLPRewards &&
                          currencyFormatter(
                            customerDashBoardYTDSalesAndReturns.totalSalesXLPRewards
                          )) ||
                        currencyFormatter(0),
                    },
                  ]}
                />
              </ReportCard>
            </div>
          </div>
          <div className="rm-second-col">
            <div className="report-card-wrapper">
              <ReportCard
                head="Top Product Lines YTD Sales"
                isLoading={!pnb.isDashboardLoaded}
              >
                {!_isEmpty(lineCodeData) ? (
                  <XCharts
                    chartType="nivoPieChart"
                    data={lineCodeData}
                    isCurrency={true}
                  />
                ) : (
                  <div className="cd-currency">No Data</div>
                )}
              </ReportCard>
            </div>
            <div className="report-card-wrapper">
              <ReportCard
                head="Top Categories YOY Sales"
                isLoading={!pnb.isDashboardLoaded}
              >
                {!_isEmpty(dashboardData) ? (
                  <XCharts
                    chartType="nivoBarChart"
                    data={dashboardData}
                    dataKeys={[previousYear, currentYear]}
                    indexBy="categories"
                    axisBottom="Categories"
                  />
                ) : (
                  <div className="cd-currency">No Data</div>
                )}
              </ReportCard>
            </div>
          </div>
        </div>

        <div
          className="r-right tile"
          onClick={() => handleProgramTab("MTAPSN")}
        >
          <div className="section-title">MTA PSN</div>
          <div className="rc-containers">
            <div className="report-card">
              <div className="rcard-title">
                Loyalty ID <span className="sub-text"> (MTA PSN)</span>{" "}
              </div>
              <div className="rcard-content">
                <div className="cd-currency">
                  {!_isEmpty(userDefines) &&
                  !_isEmpty(userDefines[USERDEF?.MTA_PSN])
                    ? userDefines[USERDEF?.MTA_PSN]
                    : "NOT ENROLLED"}
                </div>
              </div>
            </div>

            <ReportCard head="Total Sales YTD" subHead="(MTA PSN)">
              <div className="cd-currency">
                {(getMTAPSNgaugeChartData() &&
                  getMTAPSNgaugeChartData()[1].data[0].y &&
                  currencyFormatter(
                    getMTAPSNgaugeChartData()[1].data[0].y
                  )) ||
                  currencyFormatter(0)}
                {/* {(mtapsn &&
                  mtapsn.mtaTotalSales &&
                  currencyFormatter(mtapsn.mtaTotalSales)) ||
                  currencyFormatter(0)} */}
              </div>
            </ReportCard>

            <ReportCard
              head="YTD Trend to LY"
              subHead="(MTA PSN)"
              isLoading={!pnb.isDashboardLoaded}
            >
              <XCharts
                chartType="nivoRadialChart"
                data={getMTAPSNgaugeChartData()}
                gaugeLabel={convertValue(getMTAPSNgaugeChartData()[0].value)}
              />
            </ReportCard>
          </div>
        </div>
      </div>

      <div className="second-row tile ">
        <div className="report-card-wrapper">
          <ReportCard head="In-Stock Delivery Stats">
            <div className="my-1 col-sm-12">
              <BootstrapTable
                keyField="period"
                data={tableData}
                columns={deliveryStatsColumn}
                noDataIndication={noDataState}
              />
            </div>
          </ReportCard>
        </div>
      </div>

      <div className="second-row tile xlprewardsgroup">
        {/* <div className="report-card-wrapper xlp-reward-card-wrapper">
          <div
            className="report-card"
            onClick={() => handleProgramTab("XLPREWARDS")}
          >
            <div className="rcard-title">XLP Rewards YTD</div>
            <div className="rcard-content">
              {!_isEmpty(getXLPRewardsGraphData()) ? (
                <XCharts
                  chartType="nivoBarChart"
                  data={getXLPRewardsGraphData()}
                  dataKeys={["Sales"]}
                  indexBy="month"
                />
              ) : (
                <div className="cd-currency">No Data</div>
              )}
            </div>
          </div> */}
        {/* </div> */}
        <div className="vertical-cards">
          <div className="report-card-wrapper">
            <div
              className="report-card"
              onClick={() => handleProgramTab("PVPVolumeRebate")}
            >
              <div className="rcard-title">Volume Rebate YTD</div>
              <div className="rcard-content">
                <div className="cd-currency">
                  {!showHide("PVPVolumeRebate") ? (
                    currencyFormatter(
                      volumeRebate &&
                        volumeRebate.totalVolumeRebate &&
                        currencyFormatter(volumeRebate.totalVolumeRebate)
                      // currencyFormatter(0)
                    )
                  ) : (
                    <div className="cd-currency">NOT ENROLLED </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="report-card-wrapper">
            <div
              className="report-card"
              onClick={() => handleProgramTab("LaborClaim")}
            >
              <div className="rcard-title">Labor Reimbursement YTD</div>
              <div className="rcard-content">
                <div className="cd-currency">
                  <ItemsRender
                    items={[
                      {
                        label: "",
                        value: currencyFormatter(
                          (laborClaim && laborClaim.totalSales) || 0
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="report-card-wrapper">
            <div
              className="report-card"
              onClick={() => handleProgramTab("ProntoSmartChoice")}
            >
              <div className="rcard-title">PartsPlus Car Care Center</div>
              <div className="rcard-content">
                <div className="cd-currency">
                  {!_isEmpty(userDefines) &&
                  !_isEmpty(userDefines[USERDEF?.PRONTO])
                    ? userDefines[USERDEF?.PRONTO]
                    : "NOT ENROLLED"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="report-card-wrapper battery-program-card-wrapper">
          <div
            className="report-card"
            onClick={() => handleProgramTab("BatteryConsignment")}
          >
            <div className="rcard-title">Battery Program YTD</div>
            <div className="rcard-content">
              {!_isEmpty(batteryPurchasedGraphData) ? (
                <XCharts
                  chartType="barChart"
                  data={batteryPurchasedGraphData}
                  dataKeys={[{ value: "Battery Purchased", color: "green" }]}
                />
              ) : (
                <div className="cd-currency">No Data</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportOverview;
