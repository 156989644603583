import { Auth } from "aws-amplify";
import { triggerCustomUxCriticalError } from "./error";
import storage from "../helpers/storage";
import { logUserActivity } from "./user";
import { timeInUTC } from "../helpers/utils";
import { setVehicleSelected } from "../components/shared/MyGarage/mygarage.slice";
import jwt_decode from "jwt-decode";

const logOut = async (dispatch) => {
  let loginId = storage.get("loginId", "local");
  let customerId = storage.get("customerId", "local");
  let shipToId = storage.get("shipToId", "local");
  let userName = storage.get("userName", "local");
  let loginDate = storage.get("loginDate", "local");

  //   On Logout Clear the Vehicle Selected in My Garage
  dispatch(setVehicleSelected(null));
  dispatch({
    payload: {
      selectedVehicle: null,
    },
    type: "VEHICLE_SELECTION_COMPLETED",
  });
  dispatch({
    payload: {},
    type: "RESET_SMS_DETAILS",
  });

  // check points are added to if logout action dispatched from test(debug) page, loginDate & customerId will be null and
  // also no authorization bearer token, if no token forbidden error will be fired to the UI.
  if (loginDate && customerId) {
    let type = "logout"
    dispatch(
      logUserActivity(
        type,
        customerId,
        loginDate,
        shipToId,
        userName,
        loginId,
        timeInUTC()
      )
    );
  }

  dispatch({ type: "LOGOUT" });

  try {
    await Auth.signOut();
    storage.unset("loginId", "local");
    storage.unset("authToken", "local");
    storage.unset('isSmsUser', 'local');
    storage.unset('deliveryaddressLists', 'local');
    dispatch({
      payload: {
        authorizationDone: false,
      },
      type: "AUTHORIZATION_DONE",
    });
    // dispatch
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

const fetchUserDetails = (dispatch) => {
  const smsUser = storage.get("isSmsUser", "local");
  if (smsUser) {
      const jwt = storage.get("authToken", "local");
      let decodedJwtToken = jwt_decode(jwt);
      let userDetails = decodedJwtToken.user_details;
      dispatch({
        payload: {
            smsUser: true,
        },
        type: 'SMS_USER',
      });
      dispatch({
        payload: {
          authorizationDone: true,
        },
        type: "AUTHORIZATION_DONE",
      });
      if (userDetails) {
        dispatch({
          payload: {
            userDetails,
          },
          type: "USER_DETAILS_FETCHED",
        });
      }      
      // let jwtData = {payload:{jwtToken:jwt}}
      // processLogin(jwtData, 'sms', dispatch);
  } else {
    return Auth.currentSession()
    .then((res) => {
      let idToken = res.getIdToken().getJwtToken();
      let userDetails = JSON.parse(res.getIdToken().payload.user_details);
      userDetails.mobileNumber = res.getIdToken().payload.phone_number;
      //You can print them to see the full objects
      // console.log(
      // 	`myAccessToken: ${JSON.stringify(accessToken)}`
      // );
      storage.set("authToken", idToken, "local");

      dispatch({
        payload: {
          authorizationDone: true,
        },
        type: "AUTHORIZATION_DONE",
      });
      dispatch({
        payload: {
          userDetails,
        },
        type: "USER_DETAILS_FETCHED",
      });
    })
    .catch((error) => {
      console.log("user not fetched", error);
      softLogout(dispatch);
    });

  }
  
  
};

const updateToken = (dispatch) => {
  dispatch({
    payload: {
      status: true,
    },
    type: "REFRESH_TOKEN_STATUS",
  });
  return Auth.currentAuthenticatedUser().then((user) => {
    Auth.currentSession().then((curent) => {
      user.refreshSession(curent.refreshToken, (err, session) => {
        fetchUserDetails(dispatch).then(() => {
          dispatch({
            payload: {
              status: false,
            },
            type: "REFRESH_TOKEN_STATUS",
          });
        });
      });
    });
  });
};

const changePassword = async (fields, dispatch) => {
  const statusMessageProps = {
    heading: "Success",
    message: "Your password has been changed successfully. Please login again.",
    type: "success",
  };
  let errorMessageProps = {
    heading: "Error",
    message: "The New Password and Confirm Password are not matching",
    type: "error",
  };
  const currentUser = await Auth.currentAuthenticatedUser();
  Auth.changePassword(currentUser, fields.currentPassword, fields.newPassword)
    .then(() => {
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      softLogout(dispatch);
    })
    .catch((error) => {
      if (error.code === "NotAuthorizedException") {
        errorMessageProps.message = "Please Enter Correct Current Password";
      }
      triggerCustomUxCriticalError(errorMessageProps, dispatch);
    });
};

const softLogout = (dispatch) => {
  storage.unset("loginId", "local");
  storage.unset("authToken", "local");
  storage.unset("loginDate", "local");
  storage.unset('isSmsUser', 'local');
  dispatch({
    payload: {
      authorizationDone: false,
    },
    type: "AUTHORIZATION_DONE",
  });
  dispatch({
    payload: {},
    type: "RESET_SMS_DETAILS",
  });
};

export const getReduxState = () => {
  return (dispatch, getState) => {
    return getState();
  };
};

export { fetchUserDetails, logOut, softLogout, updateToken, changePassword };
