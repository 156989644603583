import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchSearchOptions,
  fetchVehiclesByVin,
  fetchVehiclesByPlate,
  getEngines,
  getModels,
  getMakes,
  getPartList,
  getPartsDescService,
} from "./globalSearch.service";

export const addSearchedValue = createAsyncThunk(
  "addSearchedValue",
  async (payload) => {
    return payload;
  }
);

export const search = createAsyncThunk("globalSearch", async ({payload, isVehicleSelected, source}) => {
  let response;

  if(isVehicleSelected) {
    response = await getPartsDescService({payload, source});
  } else {
    response = await fetchSearchOptions(payload);
  }

  return response;
});

export const catalogsearch = createAsyncThunk(
  "globalSearch",
  async (payload) => {
    const response = await fetchSearchOptions(payload);
    return response;
  }
);

export const vinSearch = createAsyncThunk(
  "globalSearchVin",
  async (payload) => {
    const response = await fetchVehiclesByVin(payload);
    return response.data;
  }
);

export const licensePlateSearch = createAsyncThunk(
  "globalSearchLicensePlate",
  async (payload) => {
    const response = await fetchVehiclesByPlate(payload);
    return response.data;
  }
);

export const enginesSearch = createAsyncThunk(
  "enginesSearch",
  async (payload) => {
    const response = await getEngines({
      make: payload.makeId,
      year: payload.year,
      model: payload.modelId,
    });
    return response.data || [];
  }
);

export const getMakesByYear = createAsyncThunk("getMakes", async (payload) => {
  const response = await getMakes({
    year: payload.yearDesc || payload.year,
  });
  return response.data;
});

export const getModelsSearch = createAsyncThunk(
  "getModels",
  async (payload) => {
    const response = await getModels({
      make: payload.makeId,
      year: payload.yearDesc || payload.year,
    });
    return response.data;
  }
);

export const getVehiclesByModel = createAsyncThunk(
  "getVehiclesByModel",
  async (payload) => {
    const response = await fetchSearchOptions(payload);
    return response.data;
  }
);

export const getVehiclesByMake = createAsyncThunk(
  "getVehiclesByMake",
  async (payload) => {
    const response = await fetchSearchOptions(payload);
    return response.data;
  }
);

export const getProductListFromSkuNumber = createAsyncThunk(
  "getProductListFromSkuNumber",
  async (payload) => {
    const response = await getPartList(payload);
    let actualPricingAvailabiity = [];
    if (response && response.data) {
      actualPricingAvailabiity = response.data.filter(
        (pa) => pa.status === "1" || pa.status === "8"
      );
    }
    const modifiedResponse = {
      partNumber: payload.requestItems[0].partNum,
      responseData: actualPricingAvailabiity,
    };
    return modifiedResponse;
  }
);
