/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCustomerPaymentsByDate } from '../mygarage.thunks';
import PaginationComponent from '../../Pagination';
import _isEmpty from 'lodash/isEmpty';
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from 'react-moment';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import './styles.scss';
import isEmpty from 'lodash/isEmpty';
import { Textbox, Button } from '../../../../ui-library';
import { fetchTransaction } from '../../../../components/Payments/payments.thunk';
import XModal from '../../../../components/shared/XModal';
import _ from 'lodash';

const PaymentHistory = (props) => {
	const dispatch = useDispatch();
	const userDetailsArr = useSelector((state) => state.user);
	const payments = useSelector((state) => state.payments);

	const last3MonthsDate = new Date();
	last3MonthsDate.setMonth(last3MonthsDate.getMonth() - 3);
	const todaysDate = new Date();

	const [ openSuccessDialog, setOpenSuccessDialog ] = useState(false);
	const [ allPaymentData, setAllPaymentData ] = useState([]);
	const [ dateFilter, setDateFilter ] = useState(null);
	const [ isPaymentTypeOpen, setisPaymentTypeOpen ] = useState(false);
	const [ applyButtonClicked, setApplyClicked ] = useState(true);

	const [ apiOptions, setApiOptions ] = useState(null);
	const [ selectedOptions, setSelectedOptions ] = useState([
		{ label: 'APPLY', value: 'apply' },
		{ label: 'ALL', value: '*' },
		{ label: 'CC - Credit Card', value: 'CC' },
		{ label: 'CK - Check', value: 'CK' },
		{ label: 'C - Cash', value: 'C' },
		{ label: 'EFT - Electronic Fund Transfer', value: 'EFT' }
	]);
	const [ accountNo, setAccountNo ] = useState(null);

	const [ dates, setDates ] = useState(null);
	const [ customStyle, setCustomStyle ] = useState({
		option: (provided, state) => ({
			...provided,
			justifyContent: 'center',
			backgroundColor: state.label === 'APPLY' ? '#aa0d10' : 'white',
			color: state.label === 'APPLY' ? 'white' : 'black',
			padding: state.label === 'APPLY' ? '8px 85px' : '8px 12px'
		})
	});
	const [ currentPage, setCurrentPage ] = useState(1);
	const [ pageSize, setPageSize ] = useState(20);
	const [ transactionId, setTransactionId ] = useState(null);
	const [ transactionNullCheck, setTransactionNullCheck ] = useState(false);

	//let totalPages = mygarage.totalPages;

	const initialFetchAccount = (clickedPage) => {
		setSelectedOptions(options);
		let pageNum = clickedPage ? clickedPage : currentPage;

		const payload = {
			id: accountNo,
			data: {
				type: apiOptions ? apiOptions : null,
				start_date: last3MonthsDate.toISOString(),
				end_date: todaysDate.toISOString(),
				page: pageNum - 1,
				size: pageSize
			}
		};

		Object.keys(payload.data).forEach((k) => payload.data[k] == null && delete payload.data[k]);

		try {
			const allPaymentAction = dispatch(getCustomerPaymentsByDate(payload));
			allPaymentAction.then((action) => {
				if (action.payload) {
					setAllPaymentData(action.payload.data);
				}
			});
		} catch (error) {}
	};

	useEffect(
		() => {
			if (accountNo !== null) {
				initialFetchAccount();
			}
		},
		[ accountNo ]
	);

	useEffect(
		() => {
			if (!_isEmpty(userDetailsArr) && userDetailsArr.userDetails.xlPartAccountNumber) {
				setAccountNo(userDetailsArr.userDetails.xlPartAccountNumber);
			}
		},
		[ userDetailsArr ]
	);

	const headerStyle = {
		backgroundColor: '#0F5283',
		color: 'white'
	};

	const formatAmount = (amt) => {
		return Number(amt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	};
	const options = [
		{ label: 'APPLY', value: 'apply' },
		{ label: 'ALL', value: '*' },
		{ label: 'CC - Credit Card', value: 'CC' },
		{ label: 'CK - Check', value: 'CK' },
		{ label: 'C - Cash', value: 'C' },
		{ label: 'EFT - Electronic Fund Transfer', value: 'EFT' }
	];

	const columns = [
		{
			dataField: 'paymentDate',
			text: 'Payment Date',
			sort: true,
			editable: false,
			headerStyle: headerStyle,
			formatter: (cell, row, rowIndex, formatExtraData) => {
				return <Moment format="MM-DD-YYYY" date={row.paymentDate} />;
			}
		},
		{
			dataField: 'transactionType',
			text: 'Payment Type',
			sort: true,
			editable: false,
			headerStyle: headerStyle
		},
		{
			dataField: 'checkNumber',
			text: 'Check Number',
			sort: true,
			editable: false,
			headerStyle: headerStyle
		},
		{
			dataField: 'amount',
			text: 'Payment Amount',
			sort: true,
			editable: true,
			headerStyle: headerStyle,
			formatter: (cell, row) => '$' + formatAmount(row.amount)
		}
	];

	const handleDateRange = (e, picker) => {
		const { startDate, endDate } = picker;

		const _startDate = startDate.toISOString();
		const _endDate = endDate.toISOString();

		setDates({ start_date: _startDate, end_date: _endDate });

		setDateFilter(`${startDate.format('MM/DD/YYYY')} - ${endDate.format('MM/DD/YYYY')}`);
		setCurrentPage(1);
		const payload = {
			id: accountNo,
			data: {
				type: apiOptions ? apiOptions : null,
				start_date: _startDate,
				end_date: _endDate,
				page: 0,
				size: pageSize
			}
		};

		Object.keys(payload.data).forEach((k) => payload.data[k] == null && delete payload.data[k]);

		try {
			const allPaymentAction = dispatch(getCustomerPaymentsByDate(payload));
			allPaymentAction.then((action) => {
				if (action.payload) {
					setAllPaymentData(action.payload.data);
				}
			});
		} catch (error) {}
	};

	const clearDateFilter = () => {
		setDates(null);
		setDateFilter('');
		setCurrentPage(1);
		initialFetchAccount(1);
	};

	const handlePageChange = (clickedPage) => {
		if (clickedPage <= Math.ceil(allPaymentData.totalRecords / 20) && clickedPage >= 0) {
			setCurrentPage(clickedPage);
			if (dateFilter) {
				const payload = {
					id: accountNo,
					data: {
						type: apiOptions ? apiOptions : null,
						start_date: dates.start_date,
						end_date: dates.end_date,
						page: clickedPage - 1,
						size: pageSize
					}
				};

				Object.keys(payload.data).forEach((k) => payload.data[k] == null && delete payload.data[k]);

				try {
					const allPaymentAction = dispatch(getCustomerPaymentsByDate(payload));
					allPaymentAction.then((action) => {
						if (action.payload) {
							setAllPaymentData(action.payload.data);
						}
					});
				} catch (error) {}
			} else {
				initialFetchAccount(clickedPage);
			}
		}
	};

	const onPartTypeFilterChange = (value, event) => {
		let selectedFilter = [];
		setCustomStyle({
			option: (provided, state) => ({
				...provided,
				justifyContent: 'center',
				backgroundColor: state.label === 'APPLY' ? '#aa0d10' : 'white',
				color: state.label === 'APPLY' ? 'white' : 'black',
				padding: state.label === 'APPLY' ? '8px 85px' : '8px 12px'
			})
		});
		if (event.action === 'select-option' && event.option.value === '*') {
			setSelectedOptions(options.filter((o) => o.value !== 'apply'));
			//currentFilter = event.option;
			selectedFilter = options.filter((o) => o.value !== '*' || o.value !== 'apply');
		} else if (event.action === 'deselect-option' && event.option.value === '*') {
			setSelectedOptions([]);
			selectedFilter = [];
			//currentFilter = "0 selected";
		} else if (event.action === 'deselect-option') {
			setSelectedOptions(value.filter((o) => o.value !== '*'));
			selectedFilter = value.filter((o) => o.value !== '*' || o.value !== event.option.value);
		} else {
			selectedFilter = value.filter((o) => o.value !== '*' || o.value !== 'apply');
			// setSelectedOptions(value);
			const excludeApply = value.filter((p) => p.value !== 'apply');
			options.length - 2 === excludeApply.length ? setSelectedOptions(options) : setSelectedOptions(value);
		}
		const excludeApply = value.filter((p) => p.value !== 'apply');
		if (event.option.value === '*' && event.action === 'deselect-option') {
			setCustomStyle({
				option: (provided, state) => ({
					...provided,
					justifyContent: 'center',
					pointerEvents: state.label === 'APPLY' ? 'none' : '',
					backgroundColor: state.label === 'APPLY' ? '#808080' : 'white',
					color: state.label === 'APPLY' ? 'white' : 'black',
					padding: state.label === 'APPLY' ? '8px 85px' : '8px 12px'
				})
			});
		}

		if (event.option.value === 'apply') {
			setisPaymentTypeOpen(false);
			let apiOptions = [];
			const selectedOptionsCopy = [ ...selectedOptions ];
			selectedOptionsCopy.map((type, index) => {
				if (!(type.value === '*' || type.value === 'apply')) {
					apiOptions.push(type.value);
				}
			});
			apiOptions.forEach((str) => str.replace(/^"(.*)"$/, '$1'));
			setApplyClicked(false);
			handleFilterChange(apiOptions);
			setApiOptions(apiOptions);
		}
	};

	const handleFilterChange = (paymentTypes) => {
		setCurrentPage(1);
		const payload = {
			id: accountNo,
			data: {
				type: paymentTypes,
				start_date: dates ? dates.start_date : last3MonthsDate.toISOString(),
				end_date: dates ? dates.end_date : todaysDate.toISOString(),
				page: 0,
				size: pageSize
			}
		};

		Object.keys(payload).forEach((k) => payload[k] == null && delete payload[k]);

		try {
			const allPaymentAction = dispatch(getCustomerPaymentsByDate(payload));
			allPaymentAction.then((action) => {
				if (action.payload) {
					setAllPaymentData(action.payload.data);
					setApplyClicked(true);
				}
			});
		} catch (error) {}
	};

	const getPartTypeFilterDdwnBtnLbl = ({ placeholderButtonLabel, value }) => {
		const excludeApply = value.filter((p) => p.value !== 'apply' && p.value !== '*');
		if (excludeApply.length === options.length - 2) {
			return `All`;
		} else if (excludeApply.length === 0) {
			return `None selected`;
		} else if (excludeApply.length === 1) {
			return `${excludeApply[0].label}`;
		} else {
			return `Multiple Items`;
		}
	};

	const handleTransactionIdChange = (e) => {
		const value = e.target.value;
		setTransactionId(value);
		if (!value) {
			setTransactionNullCheck(true);
		} else {
			setTransactionNullCheck(false);
		}
	};

	const handleTransactionGo = () => {
		if (transactionId) {
			setTransactionNullCheck(false);
			dispatch(fetchTransaction(transactionId));
		} else {
			setTransactionNullCheck(true);
		}
	};

	useEffect(
		() => {
			if (payments.fetchTransactionSuccess) {
				setOpenSuccessDialog(!openSuccessDialog);
			}
		},
		[ payments.fetchTransactionSuccess ]
	);

	const renderMessage = () => {
		const transaction = payments.fetchTransactionSuccess;
		let msg = '';
		if (
			payments.fetchTransactionSuccess &&
			!_.isEmpty(transaction.responseCode) &&
			!_.isEmpty(transaction.retryMessage) &&
			!_.isEmpty(transaction.retryDecision)
		) {
			msg = transaction.retryMessage;
		} else {
			msg = transaction.message;
		}

		return msg;
	};

	const handleTransactionClose = () => {
		setOpenSuccessDialog(!openSuccessDialog);
		setTransactionId(null);
	};

	return (
		<div class="container">
			{!applyButtonClicked && (
				<div className="custom_spinner_container catalog_spinner">
					<Spinner className="custom_spinner" animation="border" />
				</div>
			)}
			<div className="bread_crum_section_payment mt-1">
				<ul>
					<li>
						<Link to="/">Home</Link>
					</li>
					<li>
						<span>Payment History</span>
					</li>
				</ul>
			</div>
			<div className="custom-table">
				<div className="search-transaction-wrapper">
					<div className="title">Search Payment Transaction</div>
					<div className="t-wrapper">
						<Textbox
							size="small"
							label="Transaction Id"
							className="t-search-box"
							onChange={handleTransactionIdChange}
							value={transactionId}
						/>
						<Button variant="contained" className="t-search-go" onClick={handleTransactionGo}>
							Go
						</Button>
					</div>
					{transactionNullCheck && <div className="error">Transaction Id cannot be empty</div>}
				</div>
				<hr />
				<Row>
					<div className="label-dropdown-container-payment" style={{ 'column-gap': '2px' }}>
						{applyButtonClicked && (
							<Fragment>
								<Form.Label>Payment Type : </Form.Label>
								<div class="part-type-filter">
									<ReactMultiSelectCheckboxes
										className="dropdown-scroll"
										options={options}
										value={selectedOptions}
										getDropdownButtonLabel={getPartTypeFilterDdwnBtnLbl}
										onChange={onPartTypeFilterChange}
										styles={customStyle}
										groupHeading={true}
										hideSearch={true}
										isOpen={isPaymentTypeOpen}
									/>
								</div>
							</Fragment>
						)}
					</div>
					<Col md="1" sm="2">
						<DateRangePicker onApply={handleDateRange}>
							<span className="material-icons date__filter__payment">date_range</span>
						</DateRangePicker>
					</Col>

					{dateFilter && (
						<div className="payment_filter__wrapper">
							<span>{dateFilter}</span>
							{dateFilter && (
								<span className="material-icons clear__filter_btn" onClick={clearDateFilter}>
									highlight_off
								</span>
							)}
						</div>
					)}
					{!dateFilter && (
						<div className="payment_filter__wrapper">
							(
							<span>
								{last3MonthsDate.toLocaleDateString()} - {todaysDate.toLocaleDateString()}
							</span>
							{/* <span
                className="material-icons clear__filter_btn"
                onClick={clearDateFilter}
              >
                highlight_off
              </span> */}
							)
						</div>
					)}
				</Row>
				{allPaymentData && allPaymentData.payments && !isEmpty(allPaymentData.payments) ? (
					<div className="payment_table">
						<BootstrapTable
							keyField="id"
							data={allPaymentData.payments}
							columns={columns}
							bordered={false}
							striped
							tabIndexCell
						/>
					</div>
				) : (
					<div className="no-quotes">"No payments found on this date, please select a different date."</div>
				)}
				{allPaymentData &&
				allPaymentData.totalRecords !== 0 && (
					<Row>
						<Col md="12" className="mygarage_pagination_wrapper_payment">
							<PaginationComponent
								handlePageChange={handlePageChange}
								currentPage={currentPage}
								totalPages={Math.ceil(allPaymentData.totalRecords / 20)}
							/>
						</Col>
					</Row>
				)}
			</div>
			{payments.fetchTransactionSuccess && (
				<XModal
					className="payments-fetch-transaction"
					title="Transaction Details"
					show={openSuccessDialog}
					showButtons={false}
					handleClose={handleTransactionClose}
				>
					<div className="transaction-status">
						<div>
							<span className="prop">Transaction Id: </span>
							<span className="value">{payments.fetchTransactionSuccess.transactionId}</span>
						</div>
						<div>
							<span className="prop">Status: </span>
							<span className="value">{renderMessage()}</span>
						</div>

						<Button className="ok-btn" variant="contained" onClick={handleTransactionClose}>
							OK
						</Button>
					</div>
				</XModal>
			)}
		</div>
	);
};

export default PaymentHistory;
