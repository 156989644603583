/* eslint-disable no-unreachable */
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import { formatAmount } from "../../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../helpers/storage";
import DatePicker from "react-datepicker";

import moment from "moment";

const InvoiceDetails = (props) => {
  const dispatch = useDispatch();
  const parts = useSelector((state) => state.parts);
  let { part, handleInvoice, INVOICE_ERROR_MSG, handleInvoiceForStore,setLineItemRows,checkIsValidAvailableQty,LineItemsRows ,warrantyUpdatedData,SetWarrantyUpdatedData,SetLineItemEditedData} = props;

  const [updatedPart, SetUpdatedPart] = useState(part);
  const [updatedWarrantyData, SetUpdatedWarrantyData] = useState(parts.updatedInvoicesWarrantyDates);
  // const [selectedWarrantyInvoice, SetSelectedWarrantyInvoice] = useState(parts.selectedInvoicesLineItems);

  useEffect(() => {
    SetUpdatedPart(part);
  }, [part]);


  useEffect(() => {
    dispatch({
      payload: {
        updatedInvoicesWarrantyDates: updatedWarrantyData,
        // selectedInvoicesLineItems:[...selectedWarrantyInvoice]
      },
      type: "UPDATE_INVOICES_WARRANTY_DATES",
    });

  }, [updatedWarrantyData]);

  // useEffect(() => {
  //   dispatch({
  //     payload: {
  //       selectedInvoicesLineItems:selectedWarrantyInvoice
  //     },
  //     type: 'SELECTED_INVOICES_DETAILS',
  //   });

  // }, [dispatch, selectedWarrantyInvoice]);



  const isValidInvoice = (line, invoice) => {
    let totalCoreQtyAvailToCredit = storage.get("coreQty", "local");
    let totalQtyAvailToCredit = storage.get("Qty", "local");

    let storeQty =
      line.storeQty !== undefined ? Number(line.storeQty) : Number(line.qty);
    if (invoice.label === "Unlinked") {
      return false;
    } else if (
      invoice.label !== "Unlinked" &&
      (storeQty === 0 || storeQty === "")
    ) {
      return "";
    }
    if (
      invoice.label !== "Unlinked" &&
      line.returnType === "Core" &&
      storeQty > totalCoreQtyAvailToCredit
    ) {
      return "Return cannot be processed for this invoice as the Core Qty Available to Credit is less than the Verified Qty";
    } else if (
      invoice.label !== "Unlinked" &&
      line.returnType !== "Core" &&
      storeQty > totalQtyAvailToCredit
    ) {
      return "Return cannot be processed for this invoice as the Qty Available to Credit is less than the Verified Qty";
    } else if (
      invoice.label !== "Unlinked" &&
      ((line.returnType === "Core" && invoice.coreQtyAvailToCredit === 0) ||
        (line.returnType !== "Core" && invoice.qtyAvailToCredit === 0))
    ) {
      return INVOICE_ERROR_MSG;
    }
    return false;
  };
  const checkForDefaultValue = (invoice) => {
    let returnType = part && part.returnType;
    let label = invoice && invoice.label;
    let totalCoreQtyAvailToCredit = storage.get("coreQty", "local");
    let totalQtyAvailToCredit = storage.get("Qty", "local");
    if (label === "Unlinked") {
      return invoice.isCheckedUnlinked;
    }

    if (returnType === "Core" && label !== "Unlinked") {
      return invoice.isCheckedCoreQtyAvailToCredit;
    } else if (returnType !== "Core" && label !== "Unlinked") {
      return invoice.isCheckedQtyAvailToCredit;
    }
    if (
      (returnType !== "Core" && part.storeQty > totalQtyAvailToCredit) ||
      (returnType === "Core" && part.storeQty > totalCoreQtyAvailToCredit)
    ) {
      if (label === "Unlinked") {
        return invoice.isCheckedUnlinked;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const HandleDisable = (invoice, i) => {
    let returnType = part.returnType;
    let storeQty = part.storeQty;
    let IsUnlinked = storage.get("IsUnlinked", "local");
    let label = invoice && invoice.label;
    if (part.storeQty === "" || part.storeQty === 0) {
      return true;
    }
    if (
      (returnType === "New" && storeQty > parts.totalQtyAvailToCredit) ||
      (returnType === "Core" && storeQty > parts.totalCoreQtyAvailToCredit)
    ) {
      return true;
    } else if (
      label === "Unlinked" &&
      ((returnType === "New" && parts.selectedQtyAvailToCredit >= 1) ||
        (returnType === "Core" && parts.selectedCoreQtyAvailToCredit >= 1))
    ) {
      return true;
    } else if (
      IsUnlinked == "false" &&
      label === "Unlinked" &&
      ((returnType === "New" && parts.selectedQtyAvailToCredit <= 1) ||
        (returnType === "Core" && parts.selectedCoreQtyAvailToCredit <= 1))
    ) {
      return false;
    } else if (
      IsUnlinked == "false" &&
      // label !== "Unlinked" &&
      ((returnType === "New" && parts.selectedQtyAvailToCredit < 1) ||
        (returnType === "Core" && parts.selectedCoreQtyAvailToCredit < 1))
    ) {
      return false;
    } else if (IsUnlinked == "true" && label !== "Unlinked") {
      return true;
    } else {
      return false;
    }
  };

  const onValueChange =(invoice,event)=> {
    if(invoice.label=== "Unlinked"){
      part.isUnlinkedSelected=false;
        storage.set("IsUnlinked",true,"local");
    }else{
      part.isOtherReturnTypeSelected=true;
        storage.set("IsUnlinked",false,"local");

    }
    part.isInvoiceSelected = invoice.label;
    part.selectedInvoiceNumber = invoice.value;
  if (!_isEmpty(invoice.invoiceDate)) {
        part.purchaseDate = moment(invoice.invoiceDate).format('MM/DD/YYYY');
    }

    part.invoiceAvlQty = Number(invoice.qtyAvailToCredit);
    part.checkAvailToCredit = checkIsValidAvailableQty(part) !== false ? true : false;
    const updatedInvoicesWithWarranty =
    updatedPart &&
    updatedPart.inVoiceOptions.map((item) => {
      if ((item.value || item.label) === (invoice.value || invoice.label)) {
        return { ...item, enableInvoiceRadio: true };
      } else {
        return { ...item,enableInvoiceRadio:false };
      }
    });
    const updatedLineItemRows=LineItemsRows.map((lineItem)=>{
      if(lineItem.rowId === part.rowId){
          return {...part,inVoiceOptions:updatedInvoicesWithWarranty}
      }
      return {...lineItem}
  })
    let updatedDetails = warrantyUpdatedData && warrantyUpdatedData.map((item)=>{
      if (item.rowId === part.rowId) {
        return {...item,invoiceNumber:invoice.label === "Unlinked"?"":invoice.value,label:invoice.label=== "Unlinked"?"Unlinked":invoice.value,invoiceDate:invoice.invoiceDate?moment(invoice.invoiceDate).format('MM/DD/YYYY'):part.purchaseDate,totalQtyAvailToCredit:invoice.totalQtyAvailToCredit,returnType:part.returnType,...invoice}
      }
      else{
        return {...item}
      }

    });


  SetWarrantyUpdatedData(updatedDetails);
  SetLineItemEditedData(updatedDetails);


  SetUpdatedPart({
    ...part,
    inVoiceOptions: [...updatedInvoicesWithWarranty],
  });
  // SetSelectedWarrantyInvoice(warrantyData)
  SetUpdatedWarrantyData(invoice);
  setLineItemRows(updatedLineItemRows)

  dispatch({
    payload: {
        updatedInvoices:{...part,inVoiceOptions:[...updatedInvoicesWithWarranty]},

    },
    type: 'SELECTED_QTY_AVAIL_TO_CREDIT',
});
  }
  return (
    !_isEmpty(updatedPart) && (
      <div className="invoiceDropDown megamenu">
        <div className="dropdown-menu show  invoiceDropdownItem store_scroll">
          {updatedPart && updatedPart.inVoiceOptions && (
            <ul className="invoice-menu-single-row">
              <div className="invoiceDetailsHeader">
                <Row className="marron_text">
                  <Col md={1}>Select</Col>
                  <Col md={2} style={{ marginLeft: "-10px" }}>
                    Invoice #
                  </Col>
                  <Col md={1} style={{ marginLeft: "-40px" }}>
                    Invoice Date
                  </Col>
                  <Col md={1} style={{ marginLeft: "30px" }}>
                    P/L
                  </Col>
                  <Col md={1} style={{ marginLeft: "-20px" }}>
                    Part #
                  </Col>
                  <Col md={1} style={{ marginLeft: "-10px" }}>
                    Qty
                  </Col>
                  <Col md={1} style={{ marginLeft: "-20px" }}>
                    Sale Price
                  </Col>
                  <Col md={1} style={{ marginLeft: "25px" }}>
                    Core Price
                  </Col>
                  <Col md={1} style={{ marginLeft: "21px", fontSize: "14px" }}>
                    Qty Available <br></br>To Credit
                  </Col>
                  <Col
                    md={ 2}
                    style={{ marginLeft: "27px", fontSize: "14px" }}
                  >
                    Core Qty Available <br></br>To Credit
                  </Col>
                  {/* {part.returnType === "Warranty" ? (
                    <Col
                      md={1}
                      style={{
                        marginLeft: "50px",
                        fontSize: "14px",
                        marginRight: "5px",
                      }}
                    >
                      Warranty <br></br>Date
                    </Col>
                  ) : null} */}
                </Row>
              </div>
              {!_isEmpty(updatedPart) &&
                !_isEmpty(updatedPart.inVoiceOptions) &&
                updatedPart.inVoiceOptions.map((invoice, index) => {
                  return (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 100, hide: 400 }}
                      overlay={
                        invoice.label === "Unlinked" ? (
                          <span></span>
                        ) : isValidInvoice(updatedPart, invoice) !== false &&
                          isValidInvoice(updatedPart, invoice) !== "" ? (
                          <Tooltip>
                            {isValidInvoice(updatedPart, invoice)}
                          </Tooltip>
                        ) : (
                          <span></span>
                        )
                      }
                    >
                      <li>
                        <Row
                          // className={
                          //   isValidInvoice(updatedPart, invoice) !== false
                          //     ? "invoiceToolTip"
                          //     : ""
                          // }
                        >
                          <Col md={1} style={{ marginLeft: invoice.label !== "Unlinked" ? "-13px":"-3px" }}>
                            {part.returnType === "Warranty" ? (
                              <input
                                type="radio"
                                value={invoice.value}
                                checked={invoice.enableInvoiceRadio?invoice.enableInvoiceRadio:false}
                                onChange={(e)=>onValueChange(invoice,e)}
                                className={invoice.value?"warrantyDateForInvoice":"withoutvalue"}
                                // onChange={(e) =>
                                //   handleInvoiceForStore(e, invoice, updatedPart)
                                // }
                              />
                            ) : (
                              <input
                                type="checkbox"
                                onClick={(e) =>
                                  handleInvoiceForStore(e, invoice, updatedPart)
                                }
                                disabled={HandleDisable(invoice, index)}
                                defaultChecked={checkForDefaultValue(invoice)}
                              />
                            )}
                          </Col>

                          <Col
                            md={2}
                            className={
                              invoice.label !== "Unlinked"
                                ? "colInvoice"
                                : "colUnlinkedInvoice"
                            }
                          >
                            {(invoice.value || invoice.label )?(invoice.value || invoice.label ):""}
                          </Col>
                          {!_isEmpty(invoice.value) && (
                            <>
                              <Col md={1} style={{ marginLeft: "-2px" }}>
                                {" "}
                                {moment(invoice.invoiceDate).format(
                                  "MM/DD/YYYY"
                                )}{" "}
                              </Col>
                              <Col md={1} style={{ marginLeft: "45px" }}>
                                {invoice.mfgCode}
                              </Col>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={
                                  _isEmpty(invoice.partNumber) ||
                                  (!_isEmpty(invoice.partNumber) &&
                                    invoice.partNumber.length < 10) ? (
                                    <span></span>
                                  ) : (
                                    <Tooltip>{invoice.partNumber}</Tooltip>
                                  )
                                }
                              >
                                <Col
                                  md={1}
                                  className={
                                    !_isEmpty(invoice.partNumber) &&
                                    invoice.partNumber.length < 10
                                      ? ""
                                      : "text_overFlow"
                                  }
                                  style={{ marginLeft: "5px" }}
                                >
                                  {invoice.partNumber}
                                </Col>
                              </OverlayTrigger>
                              <Col md={1} style={{ marginLeft: "-6px" }}>
                                {invoice.purchaseQty}
                              </Col>
                              {invoice.price ? (
                                <Col md={1} style={{ marginLeft: "1px" }}>
                                  ${formatAmount(invoice.price) + "-" + "each"}
                                </Col>
                              ) : (
                                ""
                              )}
                              {invoice.corePrice > 0 ? (
                                <Col md={1} style={{ marginLeft: "60px" }}>
                                  $
                                  {formatAmount(invoice.corePrice) +
                                    "-" +
                                    "each"}
                                </Col>
                              ) : (
                                <Col md={1} style={{ marginLeft: "60px" }}>{invoice.corePrice}</Col>
                              )}
                              <Col md={1} style={{ marginLeft: "31px" }}>
                                {invoice.qtyAvailToCredit}
                              </Col>
                              <Col
                                md={2}
                                style={{ marginLeft: "12px" }}
                              >
                                {invoice.coreQtyAvailToCredit}
                              </Col>
                              {/* {part.returnType === "Warranty" ? (
                                <Col md={1} style={{ marginLeft: "35px" }}>
                                  <DatePicker
                                    onChange={(date) =>
                                      handleWarrantyDates(date, invoice)
                                    }
                                    className="Invoice_date_picker"
                                    selected={
                                      part.returnType === "Warranty"
                                        ? !_isEmpty(invoice.invoiceDate)
                                          ? new Date(invoice.invoiceDate)
                                          : null
                                        : ""
                                    }
                                    value={
                                      invoice.invoiceDate
                                        ? moment(invoice.invoiceDate)
                                        : null
                                    }
                                    format="MM/DD/YYYY"
                                  />
                                </Col>
                              ) : null} */}
                            </>
                          )}
                        </Row>
                      </li>
                    </OverlayTrigger>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
    )
  );
};
export default InvoiceDetails;
