import React, { useState, useEffect } from 'react';
import { Col, Form, Button, Tooltip, OverlayTrigger, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import settingIcon from '../../static/images/settingIcon.png';
import showIcon from "../../static/images/show.png";
import hideIcon from "../../static/images/hideIcon.png";
import {
	hasAlphaSpecialIntChar,
	isMaxLengthLessthan,
	formatAmount,
	getPreferenceDataFromStorage,
	isStockOrder
} from '../../helpers/utils';
import { saveQuoteForStockOrder } from '../../actions/cart';
import _isEmpty from 'lodash/isEmpty';
import FormValidationErrorMessage from '../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import { useDispatch, useSelector } from 'react-redux';
import _find from 'lodash/find';
import _every from 'lodash/every';
import Catalog from '../../static/js/Content/scripts/catalog-v3.0.0';
import { triggerCustomUxCriticalError } from '../../actions/error';
import { Redirect } from 'react-router-dom';
import { setQuoteSelected } from "../shared/MyGarage/mygarage.slice";
import NewShipAddress from './NewShipAddresss';

const actionTypes = {
	GLOBAL_LOADER: 'GLOBAL_LOADER',
	CART_CHECKOUT_FETCHED: "CART_CHECKOUT_FETCHED",
    CART_DETAILS_FETCHED: "CART_DETAILS_FETCHED",
	PO_DETAILS: "PO_DETAILS"

};

function CartPricingDetails(props) {
	const initializeValidity = () => {
		const validity = {
			// isPurchaseOrdAlphaSpclChar: true,
			isPurchaseOrdMaxLength: true,
			isOrdCmntAlphaIntSpcl: true,
			isOrdCmntLessThanLimit: true,
			isPurchaseOrdPatternValid: true,
			isPOPatternConfigFound: true
		};
		return validity;
	};

	let {
		clearUserCart,
		placeOrder,
		saveAsQuote,
		saveAsJob,
		totalBillingInfo,
		hideCostPrice,
		validCart,
		jobSaved,
		handlePoNum,
		handleSettings,
		poNum,
		handleComment,
		userDetails,
		isQuickCart,
		cartOrderType,
		checkoutDetails,
		setPoDetails,
		cartPlaceOrderWarning
	} = props;

	const dispatch = useDispatch();
	const history = useHistory();
	const cart = useSelector(state => state.cart);
	const [validity, setvalidity] = useState(initializeValidity);
	const [mailNotifications, setmailNotifications] = useState(true);
	const [comment, setOrderComment] = useState('');
	const [accPartStatus, setaccPartStatus] = useState(true);
	const loading = useSelector((state) => state.cart.apiLoading);
	const userType = useSelector((state) => state.user.userDetails.userType)
	const [enablePlaceOrder, setEnablePlaceOrder] = useState(false);
	const [ isShipHasKit, setIsShipHasKit ] = useState(false);
	const [redirectToHomepage, setredirectHomePage ] = useState(false);
	const [enablePlaceOrderForSW, setEnablePlaceOrderForSW] = useState( (userType === "SERVICE_WRITER" || userType === "VISITOR") ? true : false);
	const refreshTokenStatus = useSelector(state => state.app.refreshTokenStatus);
	const placeOrderBtnStr = (userType === "SMS_MITCHELL1") ? "Place Order & Transfer" : "Place Order";
	const apiKey = 'apikey-12345-key';
	const catalogSdk = new Catalog(apiKey, false);
	const parts = useSelector((state) => state.parts);
	const hideAllPrice =  parts  && parts.hidePrice;
	// useEffect(() => {
	// 	const script = document.createElement('script');

	// 	script.src = process.env.PUBLIC_URL + "/Content/scripts/catalog-v3.0.0.js" ;
	// 	script.async = true;

	// 	document.body.appendChild(script);
	// 	return () => {
	// 	  document.body.removeChild(script);
	// 	}
	//   }, []);

	useEffect(() => {
		if(!cartPlaceOrderWarning && !_isEmpty(checkoutDetails)){
			let commentValue = checkoutDetails && !_isEmpty(checkoutDetails) && checkoutDetails[0].purchaseOrderDesc ? checkoutDetails[0].purchaseOrderDesc : "";
		  setOrderComment(commentValue);
		  dispatch({
			payload: {
				poNumber: poNum, comments: commentValue
			},
			type: actionTypes.PO_DETAILS,
		});
		}
		if (!_isEmpty(checkoutDetails)) {
			const isShipAll = setDefaultPreference('Cart', 'Ship All Together', 'Y')
			const hasKitItem = checkoutDetails && checkoutDetails.some(item => item.isKitItem);
			setIsShipHasKit(hasKitItem);
			if (hasKitItem) {
				setaccPartStatus(false);
			} else {
				setaccPartStatus(isShipAll);
			}
		}

	  }, [checkoutDetails]);
	  
	useEffect(
		() => {
			let status = true;
			if (!_isEmpty(userDetails) && userDetails.billTo.accPart === "Y") {
				status = false;
			}
			status = isShipHasKit ? false : setDefaultPreference('Cart', 'Ship All Together', 'Y');
			setaccPartStatus(status);
			let mailNofification = setDefaultPreference('Cart', 'Order Notification', 'N');
			setmailNotifications(mailNofification);

			if (userType !== null) {
				let userPreferenceDetail = getPreferenceDataFromStorage();
				if (!_isEmpty(userPreferenceDetail)) {
					let grandAccessItemSet = userPreferenceDetail.find((item) => {
						return (
							item.text === "Grant Access To IT Admin" &&
							item.code === "User Management"
						)
					})
					let grandAccess = userPreferenceDetail.find((item)=>{
						return(
						 item.text == "Grant Access To" && item.code == "User Management") 
					})
					if (grandAccessItemSet && grandAccessItemSet.multiAnswer) {
						grandAccessItemSet.multiAnswer.map((singleAnswer) => {
							if (singleAnswer.code == "enableOrderPlace") {
								setEnablePlaceOrder(true);
							}
						})
					}
					if(grandAccess && grandAccess.multiAnswer){
						grandAccess.multiAnswer.map((singleAnswer)=>{
							if(singleAnswer.code == "orderPlacement" ){
								if(userType === "SERVICE_WRITER"){
								  setEnablePlaceOrderForSW(false);
								}
							}
						})
						}
				}
			}
		}, [userDetails])

	const getExtendedPrice = () => {
		let extendedPrice = 0;
		!_isEmpty(checkoutDetails) && checkoutDetails.map((cartItem) => {
			extendedPrice += cartItem.totalCostPrice; 
		});
		return extendedPrice;
	}

	const getListPrice = () => {
		let totalPartsListPrice = 0;
		!_isEmpty(checkoutDetails) && checkoutDetails.map((cartItem) => {
			totalPartsListPrice += cartItem.totalListPrice;//+ cartItem.qty * cartItem.corePrice;
		});
		return totalPartsListPrice;
	}

	const setDefaultPreference = (prefCode, prefText, answerValue) => {
		let prefValue = true;
		const userPreferenceDetail = getPreferenceDataFromStorage();
		if (!_isEmpty(userPreferenceDetail)) {
			let matchedPref = {};
			matchedPref = _find(userPreferenceDetail, {
				code: prefCode,
				text: prefText
			});
			if (answerValue == 'Y') { prefValue = matchedPref && ((matchedPref.answer && matchedPref.answer.code && matchedPref.answer.code == "Y") || (matchedPref.answer === null)) ? true : false }

			if (answerValue == 'N') { prefValue = matchedPref && ((matchedPref.answer && matchedPref.answer.code && matchedPref.answer.code == "N") || (matchedPref.answer === null)) ? false : true }
		}
		return prefValue;
	}

	const handleOrderChange = (event) => {
		const { value } = event.target;
		let validity = initializeValidity();
		// validity.isPurchaseOrdAlphaSpclChar = hasAlphaSpecialIntChar(value);
		validity.isPurchaseOrdMaxLength = isMaxLengthLessthan(value, 15);
		validity.isPurchaseOrdPatternValid = isPOPatternValid(value);
		validity.isPOPatternConfigFound = isPurchOrdOPatternConfigFound(value);
		setvalidity(validity);
		handlePoNum(value);
		setPoDetails({
			poNumber: value, comments: comment
		})
		dispatch({
			payload: {
				poNumber: value, comments: comment
			},
			type: actionTypes.PO_DETAILS,
		});
		
	};

	const handleOrderCmntChange = (event) => {
		const { value } = event.target;
		let validity = initializeValidity();
		validity.isOrdCmntAlphaIntSpcl = hasAlphaSpecialIntChar(value);
		validity.isOrdCmntLessThanLimit = isMaxLengthLessthan(value, 500);
		setvalidity(validity);
		setOrderComment(value);
		handleComment(event);
		setPoDetails( {
			poNumber: poNum, comments: value
		})
		dispatch({
			payload: {
				poNumber: poNum, comments: value
			},
			type: actionTypes.PO_DETAILS,
		});
	};

	const isPOPatternValid = (str) => {
		const poRegex =
			userDetails &&
			userDetails.billTo &&
			userDetails.billTo.poRegex;
		if (poRegex && !_isEmpty(poRegex)) {
			var expression = new RegExp(poRegex);
			return expression.test(str);
		}
		return true;
	};

	const isPurchOrdOPatternConfigFound = (str) => {
		const poPattern =
			userDetails &&
			userDetails.billTo &&
			userDetails.billTo.poPattern;
		const poRegex =
			userDetails &&
			userDetails.billTo &&
			userDetails.billTo.poRegex;
		if (poPattern && (!poRegex || _isEmpty(poRegex))) {
			return false;
		}
		return true;
	};

	const handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right, Control A
		return (code !== 8 && code !== 9 && code !== 37 && code !== 38 && code !== 39 && code !== 40 && code !== 46 && code !== 17 && code !== 65);
	}

	const handleKeyDown = (event, name) => {
		const orderComment = event.target.value;
		switch (name) {
			case 'purchaseOrder':
				if (orderComment && orderComment.length >= 15 && handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
				break;
			case 'orderComment':
				if (orderComment && orderComment.length >= 500 && handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
				break;
			default:
				break;
		}
	};

	const isStockOrderCanPlace = () => {
		let noStockOrderPlace = false;
		if (!_isEmpty(cartOrderType) && isStockOrder(cartOrderType) && !_isEmpty(checkoutDetails)) {
			/* stock order specific */
			const lineItemGtrThanTen =
				[ ...new Set(checkoutDetails.map((item) => item.partNum)) ].length > 10 ? true : false;
			const isQtyGrtrThanSix = checkoutDetails.some((item) => item.qty > 6) ? true : false;
			noStockOrderPlace = lineItemGtrThanTen || isQtyGrtrThanSix;
		}
		return noStockOrderPlace;
	};

	const disablePlaceorder = () => {
		let isAccessible = false; /* customer specific*/
		if (!_isEmpty(userDetails)) {
			const { billTo } = userDetails;
			if (!_isEmpty(billTo) && billTo.salesId && !_isEmpty(billTo.customerTypes)) {
				let CtypehasAccess = billTo.customerTypes.some((item) => item.type === '09');
				isAccessible = CtypehasAccess && billTo.salesId === '700';
			}
		}
		return isStockOrderCanPlace() || isAccessible;
	};

	const transferParts = (comment) => {
		let cartToTransfer = [];
		const cartList = cart.checkoutDetails.map(obj => ({...obj}));
		const uniqueParts = cartList.reduce((cart, curr) => {
			const index = cart.findIndex(item => (item.partNum === curr.partNum && item.mfgCode === curr.mfgCode))
			index > -1 ? cart[index].qty += curr.qty : cart.push(curr)
			return cart
		}, []);
		uniqueParts.map((part) => {
			// getInterChangeBrandOptions(part.partNum, "ALL", dispatch);
			
			const partItem1 = catalogSdk.createPartItem2(part.partNum, // Part Number
				part.mfgCode, // Line Code - must not be blank
				"-", // Manufacturer name - TODO
				part.desc, // Description of part
				part.listPrice, // List
				part.costPrice, // Cost
				part.corePrice, // Core
				part.qty, // Quantity transferred
				"", // Tire Size, or other size information (optional)
				"", // UpcCode - Not currently persisted in Shop Management System (optional)
				catalogSdk.PartCategory.UNSPECIFIED, // Type of part (optional)
				"", // Name of the Supplier (optional) - TODO (Chemicals)
				"", // Custom metadata for use by the catalog (optional)
				);	
			cartToTransfer.push(partItem1);
		});
		catalogSdk.transfer(JSON.stringify(cartToTransfer, undefined, 4));
	}

	const handleAutoQuote = (toAddress) => {
		let { userDetails: { x2userName, mobileNumber } } = props;
		dispatch({
			payload: {
				globalLoader: true
			},
			type: actionTypes.GLOBAL_LOADER
		});
		let quoteName = '';
		let poNumber = _isEmpty(poNum) ? "" : poNum;
		saveQuoteForStockOrder(
			x2userName,
			quoteName,
			mobileNumber,
			dispatch,
			true,
			toAddress,
			poNumber,
			comment
		).then((savedQuote) => {
			dispatch({
				payload: {
					globalLoader: false
				},
				type: actionTypes.GLOBAL_LOADER
			});
			dispatch({
				payload: {
					cartDetails: [],
				},
				type: actionTypes.CART_DETAILS_FETCHED,
			});
			dispatch({
				payload: {
					checkoutdetails: []
				},
				type: actionTypes.CART_CHECKOUT_FETCHED,
			});
			dispatch(setQuoteSelected(''));
			const quoteSaved = {
				heading: `cart is saved as quote`,
				message: '',
				type: 'success'
			};
			triggerCustomUxCriticalError(quoteSaved, dispatch);
		});
		setredirectHomePage(true);
	};

	return redirectToHomepage ? (
		<Redirect
			to={{
				pathname: '/'
			}}
		/>
	) : (
		<Col
			lg={isQuickCart ? '12' : '3'}
			className={isQuickCart ? 'quick_cart_po_cntnr purchase_order_container' : 'purchase_order_container'}
		>
			<Form>
				{/* <div className="mb-2">
					<Button
						className="mt-0 maroonBtn xl_parts_btn"
						size="sm"
						block
						onClick={() => {
							history.push('/')
						}}
					>
						Shop More Products
					</Button>
				</div> */}
				<Form.Group className="purchase_order_input_container">
				<NewShipAddress/>
				<Form.Label className="maroonColorTxt bold">
						Purchase Order
					</Form.Label>
					<Form.Control
						maxLength="15"
						className="input_upperCase"
						type="text"
						value={poNum}
						onChange={handleOrderChange}
						onKeyDown={(event) => {
							handleKeyDown(event, 'purchaseOrder');
						}}
					/>
					{/* <FormValidationErrorMessage
						condition={
							!validity.isPurchaseOrdAlphaSpclChar &&
							validity.isPurchaseOrdMaxLength
						}
						errorMessage={
							'Purchase order should include alphanumeric or special characters'
						}
					/> */}
					<FormValidationErrorMessage
						condition={!validity.isPurchaseOrdMaxLength}
						errorMessage={
							'Purchase order should not exceed 15 characters'
						}
					/>
					<FormValidationErrorMessage
						condition={!validity.isPurchaseOrdPatternValid}
						errorMessage={
							'The PO Number you entered does not match the PO pattern you have designated with The Parts House. Please enter Upper Case letters only. For security reasons, we can not allow the order to be placed nor can we tell you the correct PO pattern. Please call your The Parts House store or The Parts House Accounts Receivable at 713-983-1159 for assistance.'
						}
					/>
					<FormValidationErrorMessage
						condition={!validity.isPOPatternConfigFound}
						errorMessage={
							'PO Validation configuration is missing. Please contact support.'
						}
					/>
				</Form.Group>


				<div className="notification_container">
					{/* <Form.Check
						// custom
                        inline
                        type='checkbox'
                        checked={mailNotifications}
						label="Send me an email/text notification for this order"
					/> */}
					<input
						type="checkbox"
						id="mailNotifications"
						name="mailNotifications"
						checked={mailNotifications}
						onClick={() => {
							setmailNotifications(!mailNotifications);
						}}
					/>
					<label for="mailNotifications">
						Email / text me notification of this order
					</label>
					<img
						src={settingIcon}
						alt="Settings"
						onClick={() => handleSettings()}
					/>
				</div>

				<div className="notification_container">
					<input
						disabled={isShipHasKit || cartOrderType === 'STOCK_ORDER'}
						type="checkbox"
						id="shipAllTogether"
						name="shipAllTogether"
						checked={accPartStatus}
						onClick={() => {
							setaccPartStatus(!accPartStatus);
						}}
					/>
				<label for="shipAllTogether">
						Ship All Together
					</label>
				</div>

				{/* <div className="mb-2">
					{userDetails &&
						userDetails.billTo.poRequired === 'Y' &&
						!poNum ? (
						<OverlayTrigger
							placement="bottom"
							delay={{ show: 50, hide: 400 }}
							overlay={
								<Tooltip id="button-tooltip">
									Please enter a purchase order number.
								</Tooltip>
							}
						>
							<Button
								className="maroonBtn xl_parts_btn display-prod-btn disabled"
								size="sm"
								block
							>
								{placeOrderBtnStr}
							</Button>
						</OverlayTrigger>
					) : (
						<Button
							className="maroonBtn xl_parts_btn"
							size="sm"
							block
							onClick={() => {
								if (userType === "SMS_MITCHELL1") {
									clearUserCart(true);
									transferParts(comment);
								} else {
								placeOrder(mailNotifications, accPartStatus, comment);
								}
							}}
							disabled={(!validCart || loading) || (userType === "IT_INTERNAL" && !enablePlaceOrder) || !_every(validity)}
						>
							{placeOrderBtnStr}
						</Button>
					)}
				</div> */}
				<Form.Control
					as="textarea"
					rows="2"
					placeholder="Order comment - Max of 500 characters"
					value={comment}
					onChange={handleOrderCmntChange}
					maxLength="500"
					onKeyDown={(event) => {
						handleKeyDown(event, 'orderComment');
					}}
				/>
				<FormValidationErrorMessage
					condition={!validity.isOrdCmntAlphaIntSpcl}
					errorMessage={
						'Order comment should include only alpha, special characters or integers'
					}
				/>
				<FormValidationErrorMessage
					condition={
						!validity.isOrdCmntLessThanLimit &&
						validity.isOrdCmntAlphaIntSpcl
					}
					errorMessage={
						'Order comment should not exceed 500 characters'
					}
				/>
			</Form>
			{!isQuickCart && (
				<React.Fragment>
					<div>
						<div className="pricing_details_table">
							{/* <div>
								<span>List Price:&nbsp;</span>
								<span>$66.84</span>
							</div> */}
							{!hideAllPrice && (
								<div>
									<span>Total List Price:&nbsp;</span>
									<span className="maroonColorTxt bold">
										${formatAmount(getListPrice())}
									</span>
								</div>)}
							    {!hideCostPrice && !hideAllPrice  ?
								<div className="total_price">
									<span>Savings:&nbsp;</span>
									<span className="maroonColorTxt bold ml-3">
										${formatAmount(
											getListPrice() - getExtendedPrice()
										)}
									</span>
								</div>
							 : ''}
							{!hideCostPrice && !hideAllPrice ? (
								<div className="total_price">
									{/* <div>
										<span>Your Cost:&nbsp;</span>
										<span>$33.42</span>
									</div> */}

									<span>Total Extended Price:&nbsp;</span>
									<span className="maroonColorTxt bold">
										$
										{formatAmount(getExtendedPrice())}
									</span>
								</div>
							) : null}
						</div>
					</div>
					<p className="disclaimer_text">
						***All Bundle Discounts will be Reflected on finalized
						invoice***
					</p>
					{cartOrderType === 'STOCK_ORDER' && (
						<div className="cartis_stockorder">
							<div className="email-btn">
								<Button
									className="xl_parts_btn email_tome"
									onClick={() => {
										handleAutoQuote('self');
									}}
								>
									EMAIL ME
								</Button>
							</div>
							<div className="email-op">
								<Button
									className="xl_parts_btn emailto_operation"
									onClick={() => {
										handleAutoQuote('odc');
									}}
									disabled={!isStockOrderCanPlace()}
								>
									EMAIL OPERATIONS
								</Button>
							</div>
							<Row hidden>
								<Col lg={5} md={12} sm={12} xs={12}>
									<Button
										className="xl_parts_btn email_tome"
										onClick={() => {
											handleAutoQuote('self');
										}}
									>
										EMAIL ME
									</Button>
								</Col>
								<Col lg={7} md={12} sm={12} xs={12}>
									<Button
										className="xl_parts_btn emailto_operation"
										onClick={() => {
											handleAutoQuote('odc');
										}}
										disabled={!isStockOrderCanPlace()}
									>
										EMAIL OPERATIONS
									</Button>
								</Col>
							</Row>
						</div>
					)}
				</React.Fragment>
			)}
			<div className="mb-2">

				{userType === "SMS_MITCHELL1" ? (
					<React.Fragment>
						<OverlayTrigger
							placement="top"
							delay={{ show: 50, hide: 400 }}
							overlay={
								<Tooltip id="button-tooltip">
								    Transfer the parts back to Manager SE. Please enter PO & place the order in Manager SE.
							    </Tooltip>
							}
						>
							<Button
								className="maroonBtn xl_parts_btn"
								size="sm"
								block
								onClick={() => {
									clearUserCart(true);
									transferParts(comment);
								}}
							>
								Transfer Parts
							</Button>
						</OverlayTrigger>
					</React.Fragment>
				) : (
					null
				)}		
				{userDetails &&
				userDetails.billTo.poRequired === 'Y' &&
				!poNum ? (
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 400 }}
					overlay={
						<Tooltip id="button-tooltip">
							Please enter a purchase order number.
						</Tooltip>
					}
				>
					<Button
						className="maroonBtn xl_parts_btn display-prod-btn disabled"
						size="sm"
						block
					>
						{placeOrderBtnStr}
					</Button>
				</OverlayTrigger>
			) : (
				<React.Fragment>
				{userType === "SMS_MITCHELL1" ? (
					<OverlayTrigger
						placement="bottom"
						delay={{ show: 50, hide: 400 }}
						overlay={
							<Tooltip id="button-tooltip">
								Place the Order now and transfer Order information back to Manager SE.
							</Tooltip>
						}
					>
						<Button
							className="maroonBtn xl_parts_btn"
							size="sm"
							block
							onClick={() => {
								placeOrder(mailNotifications, accPartStatus, comment, false);
							}}
							disabled={(!validCart || loading) || (enablePlaceOrderForSW) || !_every(validity) || disablePlaceorder()}
						>
							{placeOrderBtnStr}
						</Button>
					</OverlayTrigger>
				) : (	
					<Button
						className="maroonBtn xl_parts_btn"
						size="sm"
						block
						onClick={() => {
							placeOrder(mailNotifications, accPartStatus, comment, false);
						}}
						disabled={(!validCart || loading) || (enablePlaceOrderForSW) || !_every(validity) || disablePlaceorder()}
					>
						{placeOrderBtnStr}
					</Button>
				)}
				</React.Fragment>				
			)}			
			</div>
			{/* <div className="mb-1">
				<Button
					disabled={!(cart.qtyInCart > 0)}
					className="blkBtn xl_parts_btn"
					onClick={() => {
						clearUserCart();
					}}
				>
					Clear cart
				</Button>
			</div>
			<div className="mb-1">
				<Button
					disabled={!(cart.qtyInCart > 0)}
					className="maroonBtn xl_parts_btn"
					onClick={() => saveAsQuote()}
				>
					Save as quote
				</Button>
			</div>
			<div className="mb-1">
				<Button

					className="maroonBtn xl_parts_btn mb-5"
					onClick={() => saveAsJob()}
					disabled={jobSaved || !(cart.qtyInCart > 0)}
				>
					Save as Job
				</Button>
			</div> */}
		</Col>
	);
}
export default CartPricingDetails;
