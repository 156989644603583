/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import BootstrapTable from "react-bootstrap-table-next";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { formatAmount,formatAmountPrograms,currencyFormatter, getUserDefaultShipInfo } from "../../../helpers/utils";
import _filter from "lodash/filter";
import LostSales from '../../../components/Checkout/LostSales';
import {
  getcandkcategories
} from "../c_and_k.thunk";
import { prepareTablePartsDataFromUtil } from "../../../components/Listing/partsUtils";
import { prepareTableChemicalDataFromUtil } from "../../../components/ChemicalsAndSupplies/Listing/chemicalUtils";
import AddedPartsView from "./AddedPartsView";
import CarouselPartList from './CarouselPartList';
import Placeholder from "../../../components/shared/Placeholder";
import { getPartsByMultiGroupOrchestrator, getChemPartByGrp } from "../../../actions/parts";
import { useCandKSelectors } from "./Selectors";


const actionTypes = {
  PART_LIST_DETAILS: 'PART_LIST_DETAILS',
  SUPPLIES_LIST_DETAILS: 'SUPPLIES_LIST_DETAILS', 
  CNK_CART_CHECKOUT_FETCHED: 'CNK_CART_CHECKOUT_FETCHED',
  GLOBAL_LOADER: 'GLOBAL_LOADER'
};

function CartPartDetails(props) {
  const {warrantyId} = props;
  const dispatch = useDispatch();
  const {
    candk,
    catalogFilter,
    userDetails,
    selectedVehicle,
    laborGuideData,
    laborApiCall,
    catalogPageNumber,
    catalogTotalPage,
    buyersGuideBrandData,
    buyersGuideData,
    partsAllDetails,
    candkCheckoutDetails,
    catalogOrder,
    catalogSortBy,
    catalogPageSize,
    catalogRequestGroups,
    isPartsNotFound,
    suppliesAllDetails,
    currentPage,
    totalPages,
    suppliesReqAvailability,
    brandsSelected,
    pageSize,
    order,
    sortBy,
    suppliesOffers,
    suppliesReqGrpIds,
    reqSpecs,
    reqSizeQty,
    reqCaseQty,
    hidePrice,
    globalLoader    
  } = useCandKSelectors();
  
  const {
    year,
    makeId,
    modelId,
    engineId,
    vin
  } = selectedVehicle || {};
  const sellPartnerId = getUserDefaultShipInfo(userDetails)?.sellerPartnerId;
  const selectedStore = getUserDefaultShipInfo(userDetails)?.storeNumber;
  const shipMethod = getUserDefaultShipInfo(userDetails)?.shipMethod;

  const candkWarranty = candk?.ckorder?.warranties;
   let warnt = _filter(candkWarranty, (p) => warrantyId === p.warrantyId);

  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedGroupData, setSelectedGroupData] = useState([]);  
  const [candkcategories, setcandkcategories] = useState([]);

  const [showPartTable, setShowPartTable] = useState(false);
  const [showChemicalTable, setShowChemicalTable] = useState(false);
  const [showLostSales, setshowLostSales] = useState(false);
  const [lostSalesData, setLostSalesData] = useState([]);
  const [sourceOfLostSales, setSourceOfLostSales] = useState("");
  const [openWarning, setOpenWarning] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const wdata = warnt;

  useEffect(() => {
    const payload ={ query:  {
      year: year,
      make: makeId,
      model: modelId,
      engine: engineId,
      partTypeOrder: candk.cksearch && candk.cksearch.parts && candk.cksearch.parts[0] && candk.cksearch.parts[0].part.value || 0
      }
      }
      setLoading(true);
      dispatch(getcandkcategories(payload)).then((res)=> {
        setLoading(false);
            if(!_isEmpty(res.payload)) {
              setcandkcategories(res.payload)
            }
      });
  }, []);

  useEffect(() => {
    if (!_isEmpty(selectedGroupData)) {
      setShowPartTable(true);
      setShowChemicalTable(false);
    }
  }, [partsAllDetails, buyersGuideData]);

  useEffect(() => {
    if (!_isEmpty(selectedGroupData)) {
      setShowChemicalTable(true);
      setShowPartTable(false);
    }
  }, [suppliesAllDetails]);


  const handleChemicalPageChange = (clickedPage) => {
      dispatch(
        getChemPartByGrp(
          suppliesReqGrpIds,
          clickedPage,
          pageSize,
          sortBy,
          order,
          brandsSelected,
          suppliesReqAvailability,
          suppliesOffers,
          reqSpecs,
          reqSizeQty,
          reqCaseQty
        )
      );
    
  };



  const handlePageChange = (clickedPage) => {
    let {
      year, makeId, modelId, engineId 
    } = selectedVehicle;
    window.scrollTo(0, 0);
    dispatch(
      getPartsByMultiGroupOrchestrator(
        year,
        makeId,
        modelId,
        engineId,
        catalogRequestGroups,
        clickedPage,
        catalogPageSize,
        catalogSortBy,
        catalogOrder,
        catalogFilter
      )
    );
  };

  const renderPartTable = () => {
    let partsQtySum = {};
    console.log("table datasss", selectedGroupData)
    let selectedGroupDetails = selectedGroupData
    const props = {
      dispatch,
      laborGuideData,
      selectedGroupDetails,
      selectedVehicle,
      userDetails,
      laborApiCall,
      catalogPageNumber,
      catalogTotalPage,
      isPartsNotFound
    }
    const state = {
      buyersGuideBrandData,
      buyersGuideData,
      partsAllDetails,
      partsQtySum,
      selectedStore,
      sellPartnerId,
      shipMethod,
    };
    return prepareTablePartsDataFromUtil(props, state, () => {}, () => {}, handlePageChange, false, true)
  }

const renderChemicalTable = () => {
    const props = {
      dispatch,
      userDetails,
      currentPage, totalPages, suppliesAllDetails, isPartsNotFound
    }
    const state = {
      selectedStore,
      sellPartnerId,
      shipMethod,
    };
    return prepareTableChemicalDataFromUtil(props, state, () => {}, () => {}, handleChemicalPageChange, false, true)
  }

  useEffect(() => {
    if (!_isEmpty(candk.ckorder)) {
      setSelectedOrder({ ...candk.ckorder, ...wdata[0] });
    }
  }, [candk]);

  let products = [];

  const renderCheckout = () => {
    products.push(selectedOrder);
    return (
      <Fragment>
        <br />
        <div className="ck_checkout-table">
          <BootstrapTable keyField="id" data={products} columns={columns} />
        </div>
      </Fragment>
    );
  };

  const descriptionHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    aligin: "center",
    headerAlign: "center",
    "border-color": "#0f5283",
    width: "90px",
    "font-size": "14px",
  };

  const orderQtyHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    aligin: "center",
    headerAlign: "center",
    "border-color": "#0f5283",
    width: "140px",
    "font-size": "14px",
  };

  const availabilityHeaderStyle = {
    backgroundColor: "#0f5283",
    // aligin: "center",
    headerAlign: "center",
    color: "#FFFFFF",
    "border-color": "#0f5283",
    align: "center",
    width: "80px",
    "font-size": "14px",
  };

  const yourPriceHeaderStyle = {
    backgroundColor: "#0f5283",
    align: "center",
    headerAlign: "center",
    color: "#FFFFFF",
    "border-color": "#0f5283",
    "font-size": "14px",
    width: "80px",
  };

  const listPriceHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    align: "center",
    headerAlign: "center",
    "border-color": "#0f5283",
    "font-size": "14px",
    width: "100px",
  };

  const priceFormatter = (cell, row) => {
    return `${currencyFormatter(cell)}`;
  };
  const columns = [
    {
      dataField: "quotedPartNumber",
      text: <span>Part#</span>,
      headerStyle: descriptionHeaderStyle,
      headerAlign: "center",
      align: "center",
      classes: "bootstrap-table-bold-cell",
    },
    {
      dataField: "warrantyDisplayName",
      text: <span>Warranty</span>,
      align: "center",
      headerAlign: "center",
      headerStyle: orderQtyHeaderStyle,
      classes: "bootstrap-table-bold-cell",
    },
    {
      dataField: "customerPrice",
      text: <span>Your Price</span>,
      headerStyle: listPriceHeaderStyle,
      headerAlign: "center",
      align: "right",
      classes: "bootstrap-table-bold-cell marooncolor ck_checkout_rightalign",
      formatter: priceFormatter, // Add the formatter function for the "Price" column
      headerFormatter: (column, colIndex) => {
        return (
          <div>
            <span>Your Price</span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 100, hide: 400 }}
              overlay={
                <Tooltip
                  id="extended-price-tooltip"
                  className="tooltip-description-custom-corePrice"
                >
                  <span className="font-weight-bold">
                    Warranty is included in purchase
                  </span>
                </Tooltip>
              }
            >
              <a className="whiteColorTxt info-icon ml-1">
                <i class="fa fa-info-circle"></i>
              </a>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      dataField: "listPrice",
      text: <span>List Price</span>,
      // hidden : hideCostPrice || hideCostPricePref || hideAllPrice ,
      headerStyle: yourPriceHeaderStyle,
      headerAlign: "center",
      align: "right",
      classes: "bootstrap-table-bold-cell marooncolor ck_checkout_rightalign",
      formatter: priceFormatter, // Add the formatter function for the "Price" column
    },
    {
      dataField: "coreValue",
      text: <span>Core Value</span>,
      headerStyle: availabilityHeaderStyle,
      headerAlign: "center",
      align: "right",
      classes: "bootstrap-table-bold-cell marooncolor ck_checkout_rightalign",
      formatter: priceFormatter, // Add the formatter function for the "Price" column
      headerFormatter: (column, colIndex) => {
        return (
          <div>
            <span>Core Value</span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 100, hide: 400 }}
              overlay={
                <Tooltip
                  id="extended-price-tooltip"
                  className="tooltip-description-custom-corePrice"
                >
                  <span className="font-weight-bold">
                    Not charged with the return of the core
                  </span>
                </Tooltip>
              }
            >
              <a className="whiteColorTxt info-icon ml-1">
                <i class="fa fa-info-circle"></i>
              </a>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      dataField: "groupby",
      hidden: true,
    },
  ];
  

  const handleDeleteItem = (e, part) => {
		const obj = [ part ];
    setshowLostSales(true)
    setLostSalesData(obj);
    setSourceOfLostSales('deleteIcon');
    setOpenWarning(true ? true : false)
    setWarningMessage('You want to delete this cart item')
	}

  const setSelectedGroupForPartType = (selectedGroup) => {
    const prepareGroup = selectedGroup.map(item => ({
      code: Number(item.groupId),
      name: item.name,
      categoryId: item.categoryId
    }));
    setSelectedGroupData(prepareGroup);
  }

  return (
    <>
      <Col lg="9">
      {props.isCallType && <div style={{marginTop:"15px"}}> <p style={{fontSize:"16px",fontWeight:"700",mrgin:"0",padding:"0", backgroundColor: "#ff0"}}>This item is subject to an availability check. A team member will contact you within 2 business days to confirm availability and delivery date</p> </div> }
        <div className="gray_box_container cart_scroll ck_gray_box_container">
          <div className="candkfrequenttext" style={{ fontSize: "18px" }}>
            <b>Your Cart contains the following parts</b>
          </div>
          <div>
            <div className="checkout-print-cart checkout_table_width">
              <Col>{renderCheckout()}</Col>
              <Col>
              <AddedPartsView
								checkoutDetails={candkCheckoutDetails}
								cartApiFetching={false}
								handleDeleteItem={handleDeleteItem}
								hideCostPrice={false}
								userDetails={userDetails}
								isQuickCart={false}
                hidePrice={hidePrice}
							/></Col>
            </div>
          </div>
        </div>
        <div className="gray_box_container cart_scroll ck_gray_box_container shadow">
          <div className="checkout-print-cart checkout_table_width">
           {loading &&  <Placeholder rows={1} />}
            {!_isEmpty(candkcategories) && <CarouselPartList
              candkcategories={candkcategories}
              vinNumber={vin}
              setSelectedGroupForPartType={setSelectedGroupForPartType}
              sellerId={sellPartnerId}
              store={selectedStore}
              shipMethod={shipMethod}
              selectedVehicle={selectedVehicle}
            />}
          </div>
          <div>
            {showPartTable && renderPartTable()}
            {showChemicalTable && renderChemicalTable()}
          </div>
          {/* {globalLoader && <Placeholder rows={1} />} */}
        </div>
      </Col>
      {showLostSales ? (
					<LostSales
						openPopup={showLostSales}
						closePopup={() => {
							setshowLostSales(false);
						}}
						selectedPartDetails={lostSalesData}
						dispatch={dispatch}
						userId={userDetails.x2userName}
						source={sourceOfLostSales}
            isCandK={true}
            candkCheckoutDetails={candkCheckoutDetails}
					/>
				) : null}
    </>
  );
}
export default CartPartDetails;
