import _ from "lodash";
import {
  getQuoteUniversal,
  getReviewCKSearchData,
  getCKReviewSelectedOrder,
  getQuoteShipping,
  getReviewCKWarrantyData,
  getPreviousLookups,
  postCKPlaceOrder,
  getCKReviewEnteredOrderDetails,
  getcandkcategories
} from "./c_and_k.thunk";
import moment from "moment";

const { createSlice } = require("@reduxjs/toolkit");

let initialState = {
  loading: false,
  isLoadingComplete: false,
  parts: [],
  shippingData: [],
  placeOrderData: {},
  warrantyData: "",
  previousLookupsData: [],
  cksearch: {},
  ckorder: {},
  ckEnteredDetails: {},
  candkPartTypes: [],
  candkPartPrice: 0 
};

const candkSlice = createSlice({
  name: "candk",
  initialState,
  reducers: {
    resetCandK: (state) => {
      state.loading = false;
      state.isLoadingComplete = false;
      state.parts = [];
      state.cksearch = {};
      state.ckorder = {};
      state.ckEnteredDetails = {};
      state.placeOrderData = {};
      state.shippingData = [];
      state.warrantyData = "";
    },
    setCandK: (state, action) => {
      const parsed = action.payload || {};
      const parts = _.sortBy(parsed.partsFound || [], (p) => p.partCatalog);
      state.parts = parts || [];
      state.loading = false;
    },
    resetLoader: (state, action) => {
      state.loading = false;
    },
    setCandKPartsPrice: (state, action) => {
      state.candkPartPrice = action.payload || 0;
    },
  },
  extraReducers: {
    [getQuoteUniversal.pending]: (state, action) => {
      state.parts = [];
      state.loading = true;
      state.isLoadingComplete = false;
    },
    [getQuoteUniversal.fulfilled]: (state, action) => {
      const parsed = action.payload;

      if (!parsed.errorMessage) {
        let parts = _.sortBy(parsed.partsFound, (p) => p.partCatalog);

        parts = parts.map((p) => {
          if (
            p.stockStatus === "No" &&
            moment(p.deliveryDate).year() === 9999
          ) {
            p.coreValue = null;
            p.customerPrice = null;
            p.listPrice = null;
            p.deliveryDate = null;
            p.deliveryDateCutoffUTC = null;

            return p;
          } else {
            return p;
          }
        });
        state.parts = parts;
        state.isLoadingComplete = true;
        state.loading = false;
      } else {
        state.parts = [];
        state.loading = false;
        state.isLoadingComplete = false;
      }
    },
    [getQuoteUniversal.rejected]: (state, action) => {
      state.parts = [];
      state.loading = false;
      state.isLoadingComplete = false;
    },
    [getReviewCKSearchData.pending]: (state, action) => {
      state.loading = true;
    },
    [getReviewCKSearchData.fulfilled]: (state, action) => {
      const payload = action.payload;
      state.cksearch = payload; // Save the payload in the state field "cksearch"
      state.loading = false;
    },
    [getReviewCKSearchData.rejected]: (state, action) => {
      state.cksearch = null; // Handle the rejected state if needed
      state.loading = false;
    },
    [getCKReviewSelectedOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [getCKReviewSelectedOrder.fulfilled]: (state, action) => {
      const payload = action.payload;
      state.ckorder = payload; // Save the payload in the state field "cksearch"
      state.loading = false;
    },
    [getQuoteShipping.rejected]: (state, action) => {
      state.shippingData = null; // Handle the rejected state if needed
      state.loading = false;
    },
    [getQuoteShipping.pending]: (state, action) => {
      state.loading = true;
    },
    [getQuoteShipping.fulfilled]: (state, action) => {
      const payload = action.payload;
      state.shippingData = payload; // Save the payload in the state field "cksearch"
      state.loading = false;
    },
    [getCKReviewSelectedOrder.rejected]: (state, action) => {
      state.ckorder = {}; // Handle the rejected state if needed
      state.loading = false;
    },
    [postCKPlaceOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [postCKPlaceOrder.fulfilled]: (state, action) => {
      const payload = action.payload;
      state.placeOrderData = payload; // Save the payload in the state field "cksearch"
      state.loading = false;
    },
    [postCKPlaceOrder.rejected]: (state, action) => {
      state.placeOrderData = null; // Handle the rejected state if needed
      state.loading = false;
    },
    [getReviewCKWarrantyData.pending]: (state, action) => {
      state.loading = true;
    },
    [getReviewCKWarrantyData.fulfilled]: (state, action) => {
      const payload = action.payload;
      state.warrantyData = payload; // Save the payload in the state field "cksearch"
      state.loading = false;
    },
    [getReviewCKWarrantyData.rejected]: (state, action) => {
      state.warrantyData = null; // Handle the rejected state if needed
      state.loading = false;
    },
    [getPreviousLookups.fulfilled]: (state, action) => {
      const payload = action.payload;
      state.previousLookupsData = payload; // Save the payload in the state field "cksearch"
      state.loading = false;
    },
    [getPreviousLookups.rejected]: (state, action) => {
      // Handle the rejected state if needed
      state.loading = false;
    },
    [getCKReviewEnteredOrderDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getCKReviewEnteredOrderDetails.fulfilled]: (state, action) => {
      const payload = action.payload;
      state.ckEnteredDetails = payload; // Save the payload in the state field "cksearch"
      state.loading = false;
    },
    [getCKReviewEnteredOrderDetails.rejected]: (state, action) => {
      state.ckEnteredDetails = null; // Handle the rejected state if needed
      state.loading = false;
    },
    [getcandkcategories.pending]: (state, action) => {
      state.loading = true;
    },
    [getcandkcategories.fulfilled]: (state, action) => {
      const payload = action.payload;
      state.candkPartTypes = payload; // Save the payload in the state field "cksearch"
      state.loading = false;
    },
    [getcandkcategories.rejected]: (state, action) => {
      state.candkPartTypes = null; // Handle the rejected state if needed
      state.loading = false;
    },
  },
});

const { actions, reducer } = candkSlice;

export const { resetCandK, setCandK, resetLoader, setCandKPartsPrice } = actions;

export default reducer;
