const cart = (
  state = {
    hideCostPrice: false,
    cartAllDetails: [],
    checkoutDetails: [],
    previousBillTocheckoutData: [],
    cartPlaceOrderWarning: false,
    resetCartTimer: false,
    addItemcartFetching: false,
    orderType: "REGULAR",
    PODetails: {poNumber: null, comments: null},
    savedAddressDeatils:[],
    selectedAddressId:"",
    cartTempDeliveryAddress: {},
    candkCheckoutDetails: []
  },
  action
) => {
  let newState = {
    ...state,
  };
  switch (action.type) {
    case "CART_VALUES_FETCHED":
      newState = {
        ...state,
        qtyInCart: action.payload.qtyInCart,
        cartCost: action.payload.cartCost,
      };
      break;
    case "CART_DETAILS_FETCHED":
      newState = {
        ...state,
        // cartAllDetails: Object.assign([], state.cartAllDetails, action.payload.cartDetails),
        cartAllDetails: Object.assign([], action.payload.cartDetails),
      };
      break;
    case "CART_CHECKOUT_FETCHED":
        newState = {
          ...state,
          checkoutDetails: Object.assign([], action.payload.checkoutdetails),
          orderType: action.payload.checkoutdetails.length > 0 && action.payload.checkoutdetails[0].orderType ,
        };
      break;
    case "CART_PLACE_ORDER_WARNING":
        newState = {
          ...state,
          cartPlaceOrderWarning: action.payload.cartplaceorderwarning,
        };
      break;
    case "CART_RESET_TEMER":
        newState = {
          ...state,
          resetCartTimer: action.payload.resetcarttimer,
        };
      break;
    case "HIDE_COST_PRICE":
      newState = {
        ...state,
        hideCostPrice: action.payload.hideCostPrice,
      };
      break;
    case "CART_ORDER_PLACED":
      newState = {
        ...state,
        qtyInCart: 0,
        cartCost: 0,
        cartAllDetails: [],
        cartOrderPlaced: action.payload.orderPlaced,
        orderConfirmationNumber: action.payload.orderConfirmationNumber,
      };
      break;
    case "CART_API_CALL":
      newState = {
        ...state,
        cartApiFetching: action.payload.cartFetching,
      };
      break;
    case "SAVE_AS_QUOTE":
      newState = {
        ...state,
        savedAsQuote: action.payload.quoteSaved,
      };
      break;
    case "TRACK_ORDER_DATA_FETCHED":
      newState = {
        ...state,
        trackOrderDetails: action.payload.trackOrderData,
      };
      break;
    case "SET_TRACK_ORDER_DATA":
      newState = {
        ...state,
        trackOrderDetails: action.payload.trackOrderData,
      };
      break;
    case "TRACK_ORDER_API_CALL":
      newState = {
        ...state,
        trackOrderApiCall: action.payload.orderTrackApiOnGoing,
      };
      break;
    case "TRACK_ADD_TO_CART_STATUS":
      newState = {
        ...state,
        addToCartError: action.payload.addToCartError,
      };
      break;
    case "QUOTES_DATA":
      newState = {
        ...state,
        quoteDetailData: action.payload.quoteDetailData,
      };
      break;
    case "CLEAR_QUOTES_DATA":
      newState = {
        ...state,
        quoteDetailData: null,
      };
      break;
      case "ACXNUM_CART_DATA":
        newState = {
          ...state,
          acxNumCartData: action.payload.acxNumCartData,
        };
        break;
        case "ACXNUM_CART_DATA_API_CALL":
          newState = {
            ...state,
            acxNumCartApiCall: action.payload.acxNumCartApiCall,
          };
          break;
    case "CART_API_LOADING":
      newState = {
        ...state,
        apiLoading: action.payload.apiLoading,
      };
      break;
    case "ADD_API_CALL":
        newState = {
          ...state,
          addItemcartFetching: action.payload.addItemcartFetching,
    };
    break;
    case "PREVIOUS__BILLTO_CHECKOUT_DATA":
      newState = {
        ...state,
        previousBillTocheckoutData: Object.assign([], action.payload.prevBillToCheckoutData)
      };
      break;
    case "PO_DETAILS":
        newState = {
          ...state,
          PODetails: action.payload
        };
        break;
    case "SHIPPING_ADDDRESS_DATA":
      newState = {
        ...state,
        savedAddressDeatils: action.payload.shippingData
      };
      break;
    case "SAVE_AS_ADDRESS":
      newState = {
        ...state,
        savedAsNewAddress: action.payload.addressSaved
      };
      break;
    case "SELECTED_ADDRESS_ID":
      newState = {
        ...state,
        selectedAddressId: action.payload.selectedAddressId,
        cartTempDeliveryAddress: action.payload.cartTempDeliveryAddress
      };
      break;
    case "CNK_CART_CHECKOUT_FETCHED":
      newState = {
        ...state,
        candkCheckoutDetails: Object.assign([], action.payload.candkCheckoutDetails),
      };
      break;
    default:
      break;
  }
  return newState;
};
export default cart;
