import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ThankyouPopup(props) {
	let { openPopup, closePopup, orderConfirmationNumber } = props;
	return (
		<Modal size="lg" show={openPopup} className="thank_you_modal">
			<Modal.Body>
				<span className="bold">thank you for your order</span>
				<p>
					Confirmation number for this order is {orderConfirmationNumber}, please use this number in any
					communication with The Parts House regarding this order.
				</p>
				<Button onClick={closePopup}>ok</Button>
			</Modal.Body>
		</Modal>
	);
}

export default ThankyouPopup;
