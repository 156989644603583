export const data = [
  {
    package: "PERKS",
    pillar: "",
    annualFee: "$100",
    monthlyPurchase: "$0",
    firstReward: "1%",
    secondReward: "1%",
    thirdReward: "1%",
    fourthReward: "1%",
    collision: "0.25%",
    powertrain: "15 points/Assembly-5 Points/TransferCase",
    growthBonus: "Y",
    tradeOffer: "Y",
    customerOffer: "N",
  },
  {
    package: "PERKS+",
    pillar: "",
    annualFee: "$400",
    monthlyPurchase: "$3,000",
    firstReward: "1%",
    secondReward: "2%",
    thirdReward: "3%",
    fourthReward: "4%",
    collision: "0.5%",
    powertrain: "30 points/Assembly-15 Points/TransferCase",
    growthBonus: "Y",
    tradeOffer: "Y",
    customerOffer: "Y",
  },
  {
    package: "PRO PERKS++",
    pillar: "PROFESSIONAL SERVICE CENTER",
    annualFee: "$700",
    monthlyPurchase: "$6,000",
    firstReward: "1%",
    secondReward: "2%",
    thirdReward: "3%",
    fourthReward: "4%",
    collision: "1%",
    powertrain: "30 points/Assembly-15 Points/TransferCase",
    growthBonus: "Y",
    tradeOffer: "Y",
    customerOffer: "Y",
  },
];

export const dummyData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const nivo_data = [
  {
    id: "2023",
    color: "hsl(180, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 97,
      },
      {
        x: "helicopter",
        y: 28,
      },
      {
        x: "boat",
        y: 263,
      },
      {
        x: "train",
        y: 138,
      },
      {
        x: "subway",
        y: 77,
      },
      {
        x: "bus",
        y: 75,
      },
      {
        x: "car",
        y: 134,
      },
      {
        x: "moto",
        y: 28,
      },
      {
        x: "bicycle",
        y: 106,
      },
      {
        x: "horse",
        y: 38,
      },
      {
        x: "skateboard",
        y: 257,
      },
      {
        x: "others",
        y: 93,
      },
    ],
  },
  {
    id: "2022",
    color: "hsl(114, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 128,
      },
      {
        x: "helicopter",
        y: 211,
      },
      {
        x: "boat",
        y: 106,
      },
      {
        x: "train",
        y: 52,
      },
      {
        x: "subway",
        y: 211,
      },
      {
        x: "bus",
        y: 125,
      },
      {
        x: "car",
        y: 234,
      },
      {
        x: "moto",
        y: 104,
      },
      {
        x: "bicycle",
        y: 101,
      },
      {
        x: "horse",
        y: 72,
      },
      {
        x: "skateboard",
        y: 89,
      },
      {
        x: "others",
        y: 172,
      },
    ],
  },
];
