import React, { Fragment } from "react";
import {
  Alert,
  Tab,
  Nav,
  Form,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import {
  validateUserRegForm,
  populateUserDropDownOptions,
  numbersOnlyRegExp,
  alphaCharRegExp,
  timeInUTC,
} from "../../../helpers/utils";
import { getUsersList, getCompanyList } from "../UserManagement/usermanagement.thunk";
import { fetchShipToIds } from "../../shared/ShipTo/shipTo.thunk";
import { createCompanyAdminUser, createCompany } from "../../../actions/user";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import Select from "react-select";

import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import ModifyCompany from "./ModifyCompany";
import { statesList } from "../../../helpers/constants";

const userList = [
  {
    value: "COMPANY_ADMIN",
    label: "Company_Admin",
  },
];

class AddNewCompany extends React.Component {
  constructor(props) {
    super(props);

    let shipToList = !_isEmpty(props.ShipToIds)
      ? populateUserDropDownOptions(props.ShipToIds)
      : [];
    let shipToIds = [];
    let locationsSelected = [];

    if (shipToList.length === 1) {
      locationsSelected = shipToList[0].value;
      shipToIds = [shipToList[0].id];
    }

    this.state = {
      companySelected: "",
      companyList: !_isEmpty(props.companyList) ? props.companyList : "",
      selectedState: {value: 'TX', label: "Texas"},
      formObject: {
        customerNumber: !_isEmpty(props.userDetails)
          ? props.userDetails.customerCode
          : "",
        firstName: "",
        lastName: "",
        title: "",
        xlpAccNo: !_isEmpty(props.userDetails)
          ? props.userDetails.billTo.billToId
          : "",
        companyName: "",
        xlpLastInvoiceNo: "",
        xlpLastInvoiceAmount: "",
        address: "",
        address1: "",
        address2: "",
        email: "",
        phoneNo: "",
        mobileNo: "",
        comments: "",
        locationsSelected,
        userType: "",
        shipToIds,
        city: "",
        state: "",
        zipCode: "",
        selectedState: "TX",
        userId: "",
        isActive: "",
        updatedDate: "",
        createdDate: "",
        createdBy: "",
        updatedBy: "",
        companyId: ""
      },
      shipToList,
      validity: this.intializeValidations(),
      userCreation: "",
      popUpMessage: "",
      countryCode: "+1",
      defaultShipToSelected: "",
      userUpdated: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectBlur = this.handleSelectBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreateAdminSubmit = this.handleCreateAdminSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleDefaultSelect = this.handleDefaultSelect.bind(this);
    this.handleMultiSelectBlur = this.handleMultiSelectBlur.bind(this);
  }

  intializeValidations() {
    this.validity = {
      isFirstNameNotNull: true,
      isFirstnameLengthBelowLimit: true,
      isFirstnameMinLength: true,
      isFirstNameAlphabetic: true,
      isFirstNameValid: true,
      isLastNameNotNull: true,
      isLastnameLengthBelowLimit: true,
      isLastnameMinLength: true,
      isLastNameAlphabetic: true,
      isLastNameValid: true,
      isEmailIdNotNull: true,
      isEmailIdLengthBelowLimit: true,
      isEmailValidFormat: true,
      isEmailIdMinLength: true,
      isValidEmail: true,
      isMobileNoNotNull: true,
      isMobileNoLengthBelowLimit: true,
      isMobileNoMinLength: true,
      isMobileNoNumeric: true,
      isValidMobileNo: true,
      isLocationNotNull: true,
      isUserTypeNotNull: true,
      isStateMinLength: true,
      isStateAlphanumeric: true,
      isCompanyNameNotNull: true,
      isCompanynameLengthBelowLimit: true,
      isCompanynameMinLength: true,
      isCompanyNameAlphabetic: true,
      isCompanyNameValid: true,
      isAddress2NotNull: true,
      isAddress2LengthBelowLimit: true,
      isAddress2MinLength: true,
      isAddress2Alphabetic: true,
      isAddress1NotNull: true,
      isAddress1LengthBelowLimit: true,
      isAddress1MinLength: true,
      isAddress1Alphabetic: true,
      iscityNotNull: true,
      iscityLengthBelowLimit: true,
      iscityMinLength: true,
      iscityAlphabetic: true,
      iszipCodeNotNull: true,
      iszipCodeLengthBelowLimit: true,
      iszipCodeMinLength: true,
      iszipCodeisNumeric: true,
    };
    return this.validity;
  }

  componentDidMount() {
     getCompanyList();
  }

  componentDidUpdate(prevProps) {
    let { shipToList, formObject, userCreation, popUpMessage, userUpdated,companyList } =
      this.state;
    let stateChanged = false;
    if (!_isEqual(this.props, prevProps)) {
      if (!_isEqual(this.props.ShipToIds, prevProps.ShipToIds)) {
        shipToList = populateUserDropDownOptions(this.props.ShipToIds);
        if (shipToList.length === 1) {
          formObject.locationsSelected = shipToList[0].value;
          formObject.shipToIds = [shipToList[0].id];
        }
        stateChanged = true;
      }
      if (!_isEqual(this.props.companyList, prevProps.companyList)) {
        companyList = this.props.companyList; 
        stateChanged = true;
      }
      if (!_isEqual(this.props.userDetails, prevProps.userDetails)) {
        formObject.companyName = this.props.userDetails.companyName;
        formObject.xlpAccNo = this.props.userDetails.billTo.billToId;
        formObject.customerNumber = this.props.userDetails.customerCode;
        stateChanged = true;
      }
      if (!_isEqual(this.props.messageFromAPi, prevProps.messageFromAPi)) {
        userCreation = this.props.userCreationSuccess;
        popUpMessage = this.props.messageFromAPi;
        window.scrollTo(0, 0);
        stateChanged = true;
        formObject.firstName = "";
        formObject.lastName = "";
        formObject.email = "";
        formObject.mobileNo = "";
        formObject.companyName = "";
        formObject.address1 = "";
        formObject.address2 = "";
        formObject.city = "";
        formObject.zipCode = "";
        userUpdated = false;
      }

      if (stateChanged) {
        this.setState({
          ...this.state,
          formObject,
          shipToList,
          userCreation,
          popUpMessage,
          userUpdated,
          companyList
        });
      }
    }
  }

  validateForm() {
    let {
      formObject: {
        firstName,
        lastName,
        title,
        email,
        phoneNo,
        mobileNo,
        userType,
        shipToIds,
        locationsSelected,
        companyName,
        address1,
        address2,
        city,
        zipCode,
        state
      },
      validity,
    } = this.state;
    validity = validateUserRegForm("firstName", firstName, validity);
    validity = validateUserRegForm("lastName", lastName, validity);
    validity = validateUserRegForm("companyName", companyName, validity);
    validity = validateUserRegForm("address1", address1, validity);
    validity = validateUserRegForm("address2", address2, validity);
    validity = validateUserRegForm("city", city, validity);
    validity = validateUserRegForm("zipCode", zipCode, validity);
    validity = validateUserRegForm("state", state, validity);


    validity = validateUserRegForm(
      "locationsSelected",
      locationsSelected,
      validity
    );
    validity = validateUserRegForm(
      "locationsSelected",
      locationsSelected,
      validity
    );
    // validity.isValidShipTo = _isEmpty(shipToIds);
    // validity = validateOnlineIdForm('comments', comments, validity);
    this.setState({ ...this.state, validity });
    return _every(validity);
  }

  handleCreateAdminSubmit(e) {
    e.preventDefault();
    let {
      formObject: {
        firstName,
        lastName,
        email,
        mobileNo,
        shipToIds,
        companyId
      },
      companyList,
      countryCode,
      defaultShipToSelected,
    } = this.state;
    const { userDetails } = this.props;
    const UserPayload = {
      customerId: userDetails.billTo.billToId,
    };
    let { dispatch } = this.props;
    let updatedShipToObj = {};
    if (!_isEmpty(shipToIds)) {
      if (shipToIds.length > 1) {
        shipToIds.map((id) => {
          updatedShipToObj[id.toString()] = id === defaultShipToSelected;
        });
      } else {
        updatedShipToObj[shipToIds[0]] = true;
      }
    }
    if (this.validateForm) {
      let userObj = {
        firstName: firstName,
        isMFAEnabled: true,
        lastName: lastName,
        mobileNumber: `${countryCode}${mobileNo}`,
        shipToIds: {},
        userType: "COMPANY_ADMIN",
        email: email,
        updatedDate:timeInUTC(),
        updatedBy: userDetails.x2userName,
        companyId: companyId
       
      };
      createCompanyAdminUser(userObj, dispatch).then((result) => {
        //dispatch(getUsersList(UserPayload));
        this.clearCreateCompayAdminInputFields();
      });
    }
  }

  clearCreateCompayAdminInputFields() {
    let companySelected = "";
    let formObject =  {      
      firstName: "",
      lastName: "",
      mobileNo: "",
      email: ""
    };
    this.setState({
      ...this.state, 
      companySelected,
      formObject
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let {
      formObject: {
        customerNumber,
        firstName,
        lastName,
        title,
        xlpAccNo,
        companyName,
        xlpLastInvoiceNo,
        xlpLastInvoiceAmount,
        address,
        email,
        address1,
        address2,
        city,
        state,
        selectedState,
        zipCode,
        phoneNo,
        mobileNo,
        userType,
        shipToIds,
       
        
      },
      countryCode,
      defaultShipToSelected,
    } = this.state;
    const { userDetails } = this.props;
    const UserPayload = {
      customerId: userDetails.billTo.billToId,
    };
    let { dispatch } = this.props;
    let updatedShipToObj = {};
    if (!_isEmpty(shipToIds)) {
      if (shipToIds.length > 1) {
        shipToIds.map((id) => {
          updatedShipToObj[id.toString()] = id === defaultShipToSelected;
        });
      } else {
        updatedShipToObj[shipToIds[0]] = true;
      }
    }
    
    let updatedByname = this.props.userDetails.x2userName ? this.props.userDetails.x2userName : this.props.userDetails.x2userName 
    let createdByname = this.props.userDetails.x2userName ? this.props.userDetails.x2userName : this.props.userDetails.x2userName 
   
    let userId1 = this.props.userDetails.userId ? this.props.userDetails.userId : this.props.userDetails.userId 
    if (this.validateForm) {
      let userObj = {
        companyName: companyName,
        address1: address1,
        address2: address2,
        city: city,
        state: selectedState,
        zipCode: zipCode,
        createdDate:  timeInUTC(),
        createdBy: createdByname
      };
      createCompany(userObj, dispatch).then((result) => {
        //dispatch(getUsersList(UserPayload));         
        dispatch(getCompanyList());  
        this.clearCreateCompanyInputFileds();
      });
    }
  }
  
  clearCreateCompanyInputFileds() {
    let selectedState = {value: 'TX', label: "Texas"};
        let formObject =  {
          companyName: "",
          address1: "",
          address2: "",
          city: "",
          selectedState: "",
          zipCode: ""
        };
        this.setState({
          ...this.state, 
          selectedState,
          formObject
        });
  }
  handleInputChange(e, data) {
    const { name, value, label } = e.target;
    let { formObject, validity } = this.state;
    const formValue = value.charAt(0);
    let str;
    if (formValue === " ") {
      str = value.trim();
    } else {
      str = value;
      validity = validateUserRegForm(name, str, validity);
    }
    formObject[name] = str;
    this.setState({
      ...this.state,
      formObject: {
        ...this.state.formObject,
        ...formObject,
      },
      validity: {
        ...this.state.validity,
        ...validity,
      },
      userUpdated: true,
    });
  }

  handleSelectChange(selectedOption, data) {
    // const [companyId, setCompanyId] = useState(null);

    const { name } = data;
    let { formObject, shipToList, defaultShipToSelected, validity, selectedState , companySelected} =
      this.state;
    const { value, label } = selectedOption;

    formObject[name] = value;
    if (name === "locationsSelected") {
      formObject["shipToIds"] = [value];
      defaultShipToSelected = value;
    }
    if (name === "userType" && shipToList.length > 1) {
      formObject["shipToIds"] = [];
      formObject["locationsSelected"] = [];
      defaultShipToSelected = "";
    }
    if (name === "state") {
      formObject["selectedState"] = value;
      selectedState = selectedOption;
    }
    if (name === "companies") {
      formObject["companyId"] = value;
      companySelected = selectedOption;
    }

    validity = validateUserRegForm(name, value, validity);
    this.setState({
      ...this.state,
      companySelected,
      selectedState,
      formObject,
      defaultShipToSelected,
      validity: {
        ...this.state.validity,
        ...validity,
      },
      userUpdated: true,
    });
  }

  handleSelectBlur() {
    // const { name } = event.target;
    let { formObject, validity } = this.state;
    const { userType } = formObject;
    validity = validateUserRegForm("userType", userType, validity);
    this.setState({
      ...this.state,
      formObject,
      validity: {
        ...this.state.validity,
        ...validity,
      },
    });
  }

  handleMultiSelectBlur() {
    let { formObject, validity } = this.state;
    const { userType } = formObject;
    validity = validateUserRegForm("locationsSelected", userType, validity);
    this.setState({
      ...this.state,
      formObject,
      validity: {
        ...this.state.validity,
        ...validity,
      },
    });
  }

  handleMultiSelectChange(value, { action, removedValue, name }) {
    let {
      formObject: { locationsSelected },
      defaultShipToSelected,
      validity,
    } = this.state;
    const multiValue = [];
    if (!_isEmpty(value)) {
      value.map((selectedList) => {
        multiValue.push(selectedList.value);
      });
    }
    locationsSelected = value && value.length > 0 ? multiValue : [];
    defaultShipToSelected =
      locationsSelected.length === 1 ? locationsSelected[0] : "";
    validity = validateUserRegForm(name, value, validity);
    this.setState({
      ...this.state,
      formObject: {
        ...this.state.formObject,
        locationsSelected: locationsSelected,
        shipToIds: locationsSelected,
      },
      validity: {
        ...this.state.validity,
        ...validity,
      },
      defaultShipToSelected,
      userUpdated: true,
    });
  }

  handleDefaultSelect(selectedOption, data) {
    this.setState({
      defaultShipToSelected: selectedOption.value,
    });
  }

  renderUserTypeSelection() {
    let {
      shipToList,
      selectedState,
      formObject: {
       
        stateValidity,
        companyName,
        address1,
        address2,
        city,
        state,
        zipCode,
        companyValidity,
      },
      validity,
    } = this.state;

    return (
      <React.Fragment>
        <div className="label-input-container">
          <label htmlFor="companyName">Company Name</label>
          <div>
            <Form.Control
              type="text"
              onChange={this.handleInputChange}
              onKeyDown={(event) => this.handleKeyDown(event, "companyName")}
              name="companyName"
              maxLength="16"
              value={companyName}
              // placeholder="First Name"
            />
            <FormValidationErrorMessage
              condition={!validity.isCompanyNameNotNull}
              errorMessage="Please enter your company name"
            />
            <FormValidationErrorMessage
              condition={
                !validity.isCompanynameMinLength &&
                validity.isCompanyNameNotNull &&
                validity.isCompanyNameAlphabetic
              }
              errorMessage="Company Name should be atleast 5 characters"
            />
            <FormValidationErrorMessage
              condition={
                !validity.isCompanynameLengthBelowLimit &&
                validity.isCompanyNameNotNull
              }
              errorMessage="Company Name should not exceed 16 characters"
            />
            <FormValidationErrorMessage
              condition={
                !validity.isCompanyNameAlphabetic &&
                validity.isCompanyNameNotNull
              }
              errorMessage="Company Name must be alpha characters only"
            />
          </div>
        </div>

        <div className="label-input-container">
          <label htmlFor="adress1">Address 1</label>
          <div>
            <Form.Control
              type="text"
              onChange={this.handleInputChange}
              onKeyDown={(event) => this.handleKeyDown(event, "address1")}
              name="address1"
              maxLength="16"
              value={address1}
              // placeholder="First Name"
            />
            <FormValidationErrorMessage
              condition={!validity.isAddress1NotNull}
              errorMessage="Please enter your Address1"
            />
            <FormValidationErrorMessage
              condition={
                !validity.isAddress1MinLength &&
                validity.isAddress1NotNull &&
                validity.isAddress1Alphabetic
              }
              errorMessage="Address1 should be atleast 5 characters"
            />
          </div>
        </div>

        <div className="label-input-container">
          <label htmlFor="address2">Address 2</label>
          <div>
            <Form.Control
              type="text"
              onChange={this.handleInputChange}
              onKeyDown={(event) => this.handleKeyDown(event, "address2")}
              name="address2"
              maxLength="16"
              value={address2}
              // placeholder="First Name"
            />
            <FormValidationErrorMessage
              condition={!validity.isAddress2NotNull}
              errorMessage="Please enter your Address2"
            />
            <FormValidationErrorMessage
              condition={
                !validity.isAddress2MinLength &&
                validity.isAddress2NotNull &&
                validity.isAddress2Alphabetic
              }
              errorMessage="Address2 should be atleast 5 characters"
            />
          </div>
        </div>

        <div className="label-input-container">
          <label htmlFor="state">State</label>
          <Tab.Pane eventKey="State">
            <Row className="state-wrapper justify-content-center">
              <Col className="select-container-state">
                <Select
                  options={statesList}
                  name="state"
                  value={selectedState}
                  placeholder="Select"
                  onChange={this.handleSelectChange}
                />
              </Col>
              <Fragment>
                <FormValidationErrorMessage
                  condition={!validity.isStateMinLength}
                  errorMessage={"State should have atleast 2 characters"}
                />
                <FormValidationErrorMessage
                  condition={
                    !validity.isStateMinLength && stateValidity.isStateMinLength
                  }
                  errorMessage={"State should be alphanumeric only"}
                />
              </Fragment>
            </Row>
          </Tab.Pane>
        </div>

        <div className="label-input-container">
          <label htmlFor="city">City</label>
          <div>
            <Form.Control
              type="text"
              onChange={this.handleInputChange}
              onKeyDown={(event) => this.handleKeyDown(event, "city")}
              name="city"
              maxLength="16"
              value={city}
              // placeholder="First Name"
            />
            <FormValidationErrorMessage
              condition={!validity.iscityNotNull}
              errorMessage="Please enter your City"
            />
            <FormValidationErrorMessage
              condition={
                !validity.iscityMinLength &&
                validity.iscityNotNull &&
                validity.iscityAlphabetic
              }
              errorMessage="City should be atleast 5 characters"
            />
          </div>
        </div>
        <div className="label-input-container">
          <label htmlFor="zipcode">Zip Code</label>
          <div>
            <Form.Control
              type="text"
              onChange={this.handleInputChange}
              onKeyDown={(event) => this.handleKeyDown(event, "zipCode")}
              name="zipCode"
              maxLength="16"
              value={zipCode}
              // placeholder="First Name"
            />
            <FormValidationErrorMessage
              condition={!validity.iszipCodeNotNull}
              errorMessage="Please enter your Zip code"
            />
            <FormValidationErrorMessage
              condition={
                !validity.iszipCodeMinLength &&
                validity.iszipCodeNotNull &&
                validity.iszipCodeisNumeric
              }
              errorMessage="Zip code should be atleast 5 characters"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  updateCountryCode = (key, e) => {
    this.setState({
      countryCode: e.target.name,
    });
  };

  handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  handleKeyDown = (event, name) => {
    const {
      formObject: { firstName, lastName, mobileNo, email, companyName ,zipCode},
    } = this.state;
    switch (name) {
      case "firstName":
        if (
          (alphaCharRegExp(event.key) === false ||
            (firstName && firstName.length >= 16)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "lastName":
        if (
          (alphaCharRegExp(event.key) === false ||
            (lastName && lastName.length >= 16)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "mobileNo":
        if (
          (numbersOnlyRegExp(event.key) === false ||
            event.keyCode === 32 ||
            (mobileNo && mobileNo.length >= 10)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "email":
        if (
          (event.keyCode === 32 || (email && email.length >= 50)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
      case "companyName":
        if (
          (alphaCharRegExp(event.key) === false ||
            (companyName && companyName.length >= 16)) &&
          this.handleOtherKeys(event.keyCode)
        ) {
          event.preventDefault();
        }
        break;
        case "zipCode":
          if (
            (numbersOnlyRegExp(event.key) === false ||
              event.keyCode === 32 ||
              (zipCode && zipCode.length >= 10)) &&
            this.handleOtherKeys(event.keyCode)
          ) {
            event.preventDefault();
          }
          break;
      default:
        break;
    }
  };

  createCompanyOptions = (companyList) => {
    let optionArr = [];
    if (!_isEmpty(companyList)) {
      companyList.map((company) => {
        if (company.isActive) {
          optionArr.push({
            value: company.companyId,
            label: company.companyName,
          });
        }
      });
    }
    return optionArr;
  };

  render() {
    const {
      validity,
      companySelected,
      userCreation,
      popUpMessage,
      formObject: { firstName, lastName, email, mobileNo, selectedState },
      formObject: {
       
        stateValidity,
        companyName,
        address1,
        address2,
        city,
        state,
        zipCode,
        companyValidity,
      },
      countryCode,
      userUpdated,
      companyList
    } = this.state;
    const {
      userDetails: { userType },
    } = this.props;

    return (
      <div className="manage-users-container">
        <Tab.Container id="left-tabs-example" defaultActiveKey="">
          <Row className="row">
            <Col lg={3} md={4} sm={4} xs={6} className="horizontal-tabs">
              <Nav variant="pills" className="flex-column">
                {userType === "IT_ADMIN" && (
                  <Fragment>
                    <Nav.Item>
                      <Nav.Link eventKey="" onClick={() => { this.clearCreateCompanyInputFileds()}}>Create Company</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="first" onClick={() => { this.clearCreateCompayAdminInputFields()}}>Create Company Admin</Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                      <Nav.Link eventKey="second">Modify Company</Nav.Link>
                    </Nav.Item>
                  </Fragment>
                )}
              </Nav>
            </Col>
            <Col
              lg={9}
              md={8}
              sm={8}
              xs={6}
              className="horizontal-tab-details-container"
            >
              
              <Tab.Content className="horizontal-tab-details">
                {userType === "IT_ADMIN" && (
                  <Fragment>
                    <Tab.Pane eventKey="">
                      <div className="comapany-details-title">
                        <p>Company Details</p>
                      </div>
                      {/* <p>Please ensure this is a cell phone number; a code will be sent to this number to finish registration</p> */}
                      {this.renderUserTypeSelection()}

                      <Button
                        type="submit"
                        className="user-management-submit-btn"
                        disabled={
                          companyName === "" || address1 === "" ||address2 === "" || city === ""  ||zipCode==="" ? true : false
                         }

                      
                       onClick={this.handleSubmit}
                      >
                        Create
                      </Button>
                    </Tab.Pane>

                    <Tab.Pane eventKey="first">
                      <Tab.Pane eventKey="second">
                        <div className="label-dropdown-container">
                          <Form.Label>Companies</Form.Label>
                          <Select
                            name="companies"
                            options={
                            !_isEmpty(companyList) && this.createCompanyOptions(companyList)
                             }
                            value={companySelected}
                            placeholder="Select a Company"
                            className="custom-dropdown"
                            onChange={this.handleSelectChange}
                          />
                        </div>
                        <div className="label-input-container">
                          <label htmlFor="firstName">First Name</label>
                          <div>
                            <Form.Control
                              type="text"
                              onChange={this.handleInputChange}
                              onKeyDown={(event) =>
                                this.handleKeyDown(event, "firstName")
                              }
                              name="firstName"
                              maxLength="16"
                              value={firstName}
                              // placeholder="First Name"
                            />
                            <FormValidationErrorMessage
                              condition={!validity.isFirstNameNotNull}
                              errorMessage="Please enter your first name"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isFirstnameMinLength &&
                                validity.isFirstNameNotNull &&
                                validity.isFirstNameAlphabetic
                              }
                              errorMessage="First Name should be atleast 2 characters"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isFirstnameLengthBelowLimit &&
                                validity.isFirstNameNotNull
                              }
                              errorMessage="First Name should not exceed 16 characters"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isFirstNameAlphabetic &&
                                validity.isFirstNameNotNull
                              }
                              errorMessage="First name must be alpha characters only"
                            />
                          </div>
                        </div>
                        <div className="label-input-container">
                          <label htmlFor="lastName">Last Name</label>
                          <div>
                            <Form.Control
                              type="text"
                              onChange={this.handleInputChange}
                              onKeyDown={(event) =>
                                this.handleKeyDown(event, "lastName")
                              }
                              name="lastName"
                              maxLength="16"
                              value={lastName}
                              // placeholder="Last Name"
                            />
                            <FormValidationErrorMessage
                              condition={!validity.isLastNameNotNull}
                              errorMessage="Please enter your last name"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isLastnameMinLength &&
                                validity.isLastNameAlphabetic
                              }
                              errorMessage="Last Name should be atleast 2 characters"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isLastnameLengthBelowLimit &&
                                validity.isLastNameAlphabetic
                              }
                              errorMessage="Last Name should not exceed 16 characters"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isLastNameAlphabetic &&
                                validity.isLastNameNotNull
                              }
                              errorMessage="Last name must contain alpha characters only"
                            />
                          </div>
                        </div>
                        <div className="label-input-container">
                          <label htmlFor="emailId">Email</label>
                          <div>
                            <Form.Control
                              type="email"
                              onChange={this.handleInputChange}
                              onKeyDown={(event) =>
                                this.handleKeyDown(event, "email")
                              }
                              name="email"
                              maxLength="50"
                              value={email}
                              // placeholder="E-mail"
                            />
                            <FormValidationErrorMessage
                              condition={!validity.isEmailIdNotNull}
                              errorMessage="Please enter your Email"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isEmailIdLengthBelowLimit &&
                                validity.isEmailIdNotNull &&
                                validity.isEmailValidFormat
                              }
                              errorMessage="Email should not exceed 50 characters"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isEmailIdMinLength &&
                                validity.isEmailIdNotNull &&
                                validity.isEmailValidFormat
                              }
                              errorMessage="Email should be atleast 7 characters"
                            />
                            <FormValidationErrorMessage
                              condition={
                                !validity.isEmailValidFormat &&
                                validity.isEmailIdNotNull
                              }
                              errorMessage="Email must be in username@domainname.com format"
                            />
                          </div>
                        </div>
                        <div className="label-input-container">
                          <label htmlFor="mobile">
                            <span className="maroonColorTxt">Cell Phone </span>
                            Number
                          </label>
                          <div>
                            <InputGroup className="mobile-no-container">
                              <DropdownButton
                                as={InputGroup.Prepend}
                                variant="outline-secondary"
                                id="input-group-dropdown-1"
                                title={countryCode}
                                onSelect={(key, e) => {
                                  this.updateCountryCode(key, e);
                                }}
                              >
                                <Dropdown.Item name="+91">+91</Dropdown.Item>
                                <Dropdown.Item name="+1">+1</Dropdown.Item>
                              </DropdownButton>
                              <Form.Control
                                type="text"
                                onChange={this.handleInputChange}
                                name="mobileNo"
                                onKeyDown={(event) =>
                                  this.handleKeyDown(event, "mobileNo")
                                }
                                maxLength="11"
                                value={mobileNo}
                              />
                              <FormValidationErrorMessage
                                condition={!validity.isMobileNoNotNull}
                                errorMessage="Please enter your Cell Number"
                              />
                              <FormValidationErrorMessage
                                condition={
                                  !validity.isMobileNoNumeric &&
                                  validity.isMobileNoNotNull
                                }
                                errorMessage="Cell number must be numeric only"
                              />
                              <FormValidationErrorMessage
                                condition={
                                  !validity.isMobileNoLengthBelowLimit &&
                                  validity.isMobileNoNotNull &&
                                  validity.isMobileNoNumeric
                                }
                                errorMessage="Cell number should not exceed 10 numbers"
                              />
                              <FormValidationErrorMessage
                                condition={
                                  !validity.isMobileNoMinLength &&
                                  validity.isMobileNoLengthBelowLimit &&
                                  validity.isMobileNoNumeric &&
                                  validity.isMobileNoNotNull
                                }
                                errorMessage="Cell number should be atleast 10 numbers"
                              />
                            </InputGroup>
                          </div>
                        </div>
                        <p>
                          Please ensure this is a cell phone number; a code will
                          be sent to this number to finish registration
                        </p>

                        <div className="label-input-container">
                          <label htmlFor="userType">UserType</label>
                          <div>
                            <Form.Control
                              disabled={true}
                              className="custom-dropdown"
                              placeholder="Company Admin"
                            />
                          </div>
                        </div>

                        <Button
                          type="submit"
                          className="user-management-submit-btn"
                          disabled={
                            companySelected === "" || firstName === "" || lastName === "" || email === ""  ||mobileNo==="" ? true : false
                            }
                          onClick={this.handleCreateAdminSubmit}
                        >
                          Create
                        </Button>
                      </Tab.Pane>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <ModifyCompany 
                      userDetails={this.props.userDetails}
                      />
                    </Tab.Pane>
                  </Fragment>
                )}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}

AddNewCompany.defaultProps = {
  userDetails: {
    userType: "",
    billTo: {
      billToId: "",
    },
  },
};

function mapStateToProps(state) {
  return {
    userCreationSuccess: state.user.userCreationSuccess,
    userCreationApiCall: state.user.userCreationApiCall,
    userDetails: state.user.userDetails,
    messageFromAPi: state.user.messageFromAPi,
    ShipToIds: state.ShipToIds.shipToIds,
    companyList: state.userManagement.companyList
  };
}
export default connect(mapStateToProps)(AddNewCompany);
