import React from "react";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatAmount } from "../../../../helpers/utils";
import { useHistory } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0f5283",
    color: theme.palette.common.white,
    textTransform: "capitalize",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textTransform: "capitalize",
  },  
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "th,td": {
    padding: "8px",
  },

  // 'table, th, td': {
  //     border: "1px solid black",
  //  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ReturnTable = (props) => {
  const { closeOverlay } = props;
  const history = useHistory();

  const statements = (date) => {
    let fromdate = date[0].updatedAt;
    history.push({
      pathname: "/statements",
      search: "?credit=1",
      fromdate,
    });
    closeOverlay();
  };
  const checkForStatus=(part)=>{
    let status=part.status;
    if(status === "STORE_DECLINED" || status === "AM_DECLINED"){
      return "Return Declined"
    }
    else if(status === "STORE_APPROVED" || status === "AM_APPROVED"  ){
      if(part.visionStatus === "RETURN_ACCEPTED" ){
        return "Return Approved"
      }else if(part.visionStatus === "RETURN_DECLINED"){
        return "Return Declined"
      }

    }
    return status;
  }
  return (
    <React.Fragment>
      {!_isEmpty(props.lineItems) ? (
        <React.Fragment>
          <TableContainer component={Paper}>
            <Table sx={{ marginTop: "10px", width: "100%" }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left">Part#</StyledTableCell>
                  <StyledTableCell align="left">partDesc</StyledTableCell>
                  <StyledTableCell align="center">Qty</StyledTableCell>
                  <StyledTableCell align="center">Amount</StyledTableCell>
                  <StyledTableCell align="left">Return Type</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                  {!_isEmpty(props.status) &&
                  (props.status === props.STORE_REVIEWED ||
                    props.status === props.MANUALLY_PROCESSED) ? (
                    <StyledTableCell align="center" width="190">
                      <a
                        className="statement__link_return_credit_memo"
                        onClick={() => statements(props.lineItems)}
                      >
                        Credit Memo
                      </a>{" "}
                    </StyledTableCell>
                  ) : null}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {props.lineItems.map((part) => (
                  <StyledTableRow key={part.id}>
                    <StyledTableCell align="left">
                      {part.status === props.STORE_APPROVED ||
                      part.status === props.STORE_DECLINED ||
                      part.status === props.ESCALATED ||
                      part.status === props.AM_APPROVED ||
                      part.status === props.AM_DECLINED
                        ? part.storePartNumber
                        : part.status === props.DRIVER_DELIVERED ||
                          part.status === props.DRIVER_COLLECTED
                        ? part.pickupPartNumber
                        : part.partNumber}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {part.partDesc}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {part.status === props.STORE_APPROVED ||
                      part.status === props.STORE_DECLINED ||
                      part.status === props.ESCALATED ||
                      part.status === props.AM_APPROVED ||
                      part.status === props.AM_DECLINED
                        ? part.storeQty
                        : part.status === props.DRIVER_DELIVERED ||
                          part.status === props.DRIVER_COLLECTED
                        ? part.pickUpQty
                        : part.originalQty}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      $
                      {part.creditAmount !== undefined
                        ? formatAmount(part.creditAmount)
                        : formatAmount(0)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {part.returnType}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {!_isEmpty(props.status) &&
                      (props.status === props.STORE_REVIEWED ||
                        props.status === props.MANUALLY_PROCESSED)
                        ? checkForStatus(part)
                        : "In Process"}
                    </StyledTableCell>
                    {!_isEmpty(props.status) &&
                    (props.status === props.STORE_REVIEWED ||
                      props.status === props.MANUALLY_PROCESSED) ? (
                      <StyledTableCell align="center"></StyledTableCell>
                    ) : null}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default ReturnTable;
