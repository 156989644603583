import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Form, Row, OverlayTrigger, Tooltip,Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import _isEmpty from 'lodash/isEmpty';
import { getPreferenceDataFromStorage, getUserPreferenceItem } from '../../../../helpers/utils';
import './styles.scss';
import { getBalance, getCustomerPaymentsByDate } from '../mygarage.thunks';

const Account = (props) => {
	const dispatch = useDispatch();
	const { closeOverlay } = props;
	const user = useSelector((state) => state.user);
	const userType = user.userDetails && user.userDetails.userType ? user.userDetails.userType : null;
	const [ balanceData, setBalanceData ] = useState([]);
	const [ paymentData, setPaymentData ] = useState([]);
	const [ showPaymentLink, setShowPaymentLink ] = useState(false);
	const history = useHistory();

	const handleShowPaymentLink = () => {
		const terms = getUserPreferenceItem('payment-term', 'customer-list');
		const toEnable = getUserPreferenceItem('payment-customer', 'customer-list');

		if (terms && terms.length > 0) {
			const billTo = user.userDetails.billTo;
			const term = billTo.term;
			if (
				term &&
				terms.indexOf(term) !== -1 &&
				(toEnable.indexOf(billTo.billToId) !== -1 || toEnable.indexOf('all') !== -1)
			) {
				setShowPaymentLink(true);
			} else {
				setShowPaymentLink(false);
			}
		}
	};

	const initialFetchAccount = () => {
		try {
			const paymentAction = dispatch(getCustomerPaymentsByDate({ id: user.userDetails.xlPartAccountNumber }));
			paymentAction.then((action) => {
				if (action.payload && action.payload.data) {
					setPaymentData(action.payload.data.payments);
				}
			});

			const balanceAction = dispatch(getBalance({ code: user.userDetails.xlPartAccountNumber }));
			balanceAction.then((action) => {
				if (action.payload) {
					setBalanceData(action.payload.data);
				}
			});
		} catch (error) {}
	};

	useEffect(() => {
		initialFetchAccount();

		handleShowPaymentLink();
	}, []);

	const formatAmount = (amt) => {
		return Number(amt).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	};

	const statements = () => {
		history.push('/statements');
		closeOverlay();
	};

	const paymentHistory = () => {
		history.push('/payment-history');
		closeOverlay();
	};

	const handleMakePayment = () => {
		history.push('/payments');
		closeOverlay();
	};
	const handleBenefits = () => {
		history.push("program-benefits");
		closeOverlay();
	  };
	//console.log("---------------------ffff" + paymentData[0]);

	return (
		<React.Fragment>
			<div className="mygarage__content bold">
				<div className="mygarage__account__links1">
					<div className="account-detail-tab">
						<a className="statement__link_account1" onClick={(e) => statements()}>
							Statements & Invoices
						</a>{' '}
						<a className="statement__link_account" onClick={(e) => paymentHistory()}>
							PAYMENT HISTORY
						</a>
						{showPaymentLink && (
							<Button
								variant="contained"
								className="maroonBtn return_xl_parts_btn account_button"
								onClick={() => handleMakePayment()}
							>
								Open Invoices / Pay Online
							</Button>
						)}
						{!_isEmpty(user.userDetails) && user.userDetails.userType && (user.userDetails.userType === 'IT_ADMIN' || user.userDetails.userType === 'OSR') && (
						<Button
						    variant="contained"
							className="maroonBtn return_xl_parts_btn account_button"
							onClick={() => handleBenefits()}
						>
							Programs and Benefits
						</Button>)}
					</div>
				</div>
			</div>
			<div className="mygarage__content">
        <div className="mygarage__account">
          <Col md="7">
            <div>BILL TO DETAILS</div>
          </Col>
        </div>
      </div>
      <div className="mygarage__content">
        <div className="account__data">
          <Row>
            <Col md="4">
              <div>
                <span className="user_management_label_color_account">
                  TPH Account #{" "}
                </span>

                <span className="user_management_label_color_span">
                  {user.userDetails && user.userDetails.billTo
                    ? `${user.userDetails.billTo.billToId}`
                    : ``}
                </span>
              </div>
            </Col>
            <Col md="4">
              <div>
                <span className="user_management_label_color_account">
                  ACCOUNT NAME{" "}
                </span>

                <span className="account_billToDetails">
                  {user.userDetails && user.userDetails.billTo
                    ? `${user.userDetails.billTo.name}`
                    : ``}
                </span>
              </div>
            </Col>
            <Col md="4">
              <div>
                <span className="user_management_label_color_account">
                  ACCOUNT ADDRESS{" "}
                </span>

                <span className="account_billToDetails">
                  {user.userDetails && user.userDetails.billTo ? (
                    <div>
                      <span>{user.userDetails.billTo?.address}</span> <br></br>
                      <span>
                        {user.userDetails.billTo?.city +
                          " . " +
                          user.userDetails.billTo?.state +
                          " . " +
                          user.userDetails.billTo?.zip}
                      </span>{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </Col>{" "}
          </Row>
        </div>
      </div>

			{!_isEmpty(balanceData) ? (
				<React.Fragment>
					<div className="mygarage__content">
						<div className="mygarage__account">
							<Col md="4">
								<div>BALANCE DETAILS</div>
							</Col>
							<div className="a__date">
								AS OF <Moment format="MM-DD-YYYY" date={balanceData.accountStatusAsOfDate} />
							</div>
						</div>
					</div>

					<div className="mygarage__content">
						<div className="account__data">
							<Row>
								<Col md="4">
									<div>
										<span className="user_management_label_color_account">PAST DUE </span>

										<span className="user_management_label_color_span">
											${formatAmount(balanceData.futureBalance)}
										</span>
									</div>
								</Col>
								<Col md="4">
									<div>
										<span className="user_management_label_color_account">CURRENT </span>

										<span className="user_management_label_color_span">
											${formatAmount(balanceData.currentBalance)}
										</span>
									</div>
								</Col>
								<Col md="4">
									<div>
										<span className="user_management_label_color_account">NEW BALANCE </span>

										<span className="user_management_label_color_span">
											${formatAmount(balanceData.newBalance)}
										</span>
									</div>
								</Col>{' '}
								<Col md="4">
									<div>
										<span className="user_management_label_color_account">FUTURE </span>

										<span className="user_management_label_color_span">
											${formatAmount(balanceData.futureBalance)}
										</span>
									</div>
								</Col>
								<Col md="4">
									<div>
										<span className="user_management_label_color_account">DUE DATE </span>

										<span className="user_management_label_color_span">
											<Moment format="MM-DD-YYYY" date={balanceData.orderDate} />
										</span>
									</div>
								</Col>
							</Row>
						</div>
					</div>

					<div className="mygarage__content">
						<div className="mygarage__account">
							<Col md="7">
								<div>PURCHASE SUMMARY</div>
							</Col>
						</div>
					</div>

					<div className="mygarage__content">
						<div className="account__data">
							<Row>
								<Col md="4">
									<div>
										<span className="user_management_label_color_account">YTD </span>

										<span className="user_management_label_color_span">
											${formatAmount(balanceData.yearToDateBalance)}
										</span>
									</div>
								</Col>{' '}
								<Col md="4">
									<div>
										<span className="user_management_label_color_account">MTD </span>

										<span className="user_management_label_color_span">
											<span>${formatAmount(balanceData.monthToDateBalance)}</span>
										</span>
									</div>
								</Col>
								<Col md="4">
									<div>
										<span className="user_management_label_color_account">LAST YEAR </span>

										<span className="user_management_label_color_span">
											${formatAmount(balanceData.lastYearBalance)}
										</span>
									</div>
								</Col>{' '}
								<Col md="4">
									<div>
										<span className="user_management_label_color_account">LAST PAYMENT </span>

										<span className="user_management_label_color_span">
											${formatAmount(balanceData.lastPayment)}
										</span>
									</div>
								</Col>
								<Col md="4">
									<div>
										<span className="user_management_label_color_account">LAST PAYMENT DATE </span>

										<span span className="user_management_label_color_span">
											{!_isEmpty(paymentData) && !_isEmpty(paymentData[0]) ? (
												<Moment format="MM-DD-YYYY" date={paymentData[0].paymentDate} />
											) : null}
										</span>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</React.Fragment>
			) : (
				<div>
					<div className="mygarage__content">
						<div className="mygarage__account__nodata">
							<Col md="4">
								{' '}
								<div>BALANCE DETAILS</div>
							</Col>
							<span className="availability_section_account">- No data available</span>
						</div>
					</div>
					<div className="mygarage__content">
						<div className="mygarage__account__nodata">
							<Col md="4">
								<div>PURCHASE SUMMARY</div>
							</Col>
							<div className="availability_section_account">- No data available</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
	// (
	//   <Placeholder rows={1} />
	// );
};

export default Account;
