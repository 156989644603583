import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap"; // Import Modal from react-bootstrap
import { useHistory } from "react-router-dom";
import _filter from "lodash/filter";

import "./styles.scss";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import XModal from "../../../components/shared/XModal";
import { Accordion, Textbox } from "ui-library";
import { useSelector, useDispatch } from "react-redux";
import {
  getQuoteShipping,
  getCKReviewSelectedOrder,
  getCKReviewEnteredOrderDetails,
  getReviewCKWarrantyData,
} from "../c_and_k.thunk";
import { formatAmount, formatAmountPrograms, currencyFormatter, getExtendedTotalPrice, getCnKPartsSum } from "../../../helpers/utils";
import { IMaskInput } from "react-imask";
import _isEmpty from "lodash/isEmpty"
// import {ckorder} from "./data";
 
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const NumberMaskCustom = React.forwardRef(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={Number}
      thousandsSeparator=","
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CKReviewPopUp = (props) => {
  const { show, handleClose, setShow,isCallType ,warrantyId=""} = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetails);
  const candk = useSelector((state) => state.candk);
  const candkCheckoutDetails = useSelector((state) =>state.cart.candkCheckoutDetails);
  const {
    cksearch,
    ckorder,
    warrantyData = {},
    ckEnteredDetails = {},
    placeOrderData = {},
    loading = false,
    candkPartPrice
  } = candk;

  const schema = yup.object({
    customerContact: yup.string().required("Contact person is required"),
    vehicleOwnerName: yup.string().required("Vehicle owner name is required"),
    VIN: yup.string().required("VIN is required"),
    mileage: yup.string().required("required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.number().required("Phone is required"),
    selectedWarranty: yup.string().required("Warranty selection is required"),
  });

  const [customerInfo, setCustomerInfo] = useState({
    customername: userDetails?.billTo?.name || "",
    customerContact: userDetails
      ? `${userDetails.firstName} ${userDetails.lastName}`
      : "",
    email: userDetails?.x2userName || "",
    serviceAdvisorEmail: "",
    phone: "",
    serviceemail: "",
    paCode: "",
    servicecell: "",
  });

  const [vehicleInfo, setVehicleInfo] = useState({
    vehicleOwnerName: "",
    VIN: cksearch?.vin || "",
    mileage: cksearch?.currentMileage || "",
    roNumber: "",
  });

  const [selectedWarranty, setSelectedWarranty] = useState("Selected Warranty");
  const [isFormValid, setIsFormValid] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Update customerInfo object
    setCustomerInfo((prevCustomerInfo) => ({
      ...prevCustomerInfo,
      phone: ckEnteredDetails?.phone || "",
      serviceemail: ckEnteredDetails?.serviceemail || "",
      servicecell: ckEnteredDetails?.servicecell || "",
    }));

    // Update vehicleInfo object
    setVehicleInfo((prevVehicleInfo) => ({
      ...prevVehicleInfo,
      VIN: cksearch?.vin || ckEnteredDetails?.VIN || "",
      mileage: cksearch?.currentMileage || ckEnteredDetails?.mileage || "",
      vehicleOwnerName: ckEnteredDetails?.vehicleOwnerName || "",
    }));

    // setSelectedWarranty(warrantyData);
   
  }, [ckEnteredDetails]); // Run this effect whenever ckEnteredDetails changes
  
  useEffect(() => {
    if (typeof warrantyId === 'number' && typeof(warrantyId) != "undefined") {
      let warnty = _filter(ckorder?.warranties, (p) => warrantyId === p.warrantyId);
      setSelectedWarranty(warnty[0]);
    }
    else if (!_isEmpty(ckorder) && !_isEmpty(ckorder?.warranties) && !_isEmpty(ckorder?.warranties[0])) {
      let warntyPrice = _filter(ckorder?.warranties, (p) => p.warrantyPrice === 0);
      setSelectedWarranty(warntyPrice[0]);
    }
  }, [ckorder])


  const getTotalCorePrice = (price) => {
    let totalCorePrice = 0;
    if(!_isEmpty(candkCheckoutDetails)) {
      totalCorePrice = candkCheckoutDetails.reduce((total, item) => {
        return total + item.corePrice;
      }, 0);
    }
    let totalPrice = price + totalCorePrice;
    return currencyFormatter(totalPrice);
  }

  const {
    handleSubmit,
    control,
    register,
    formState: { isValid },
    trigger,
    reset,
    clearErrors,
    formState: { errors: fErrors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // Provide default values for the form fields
      customername: customerInfo?.customername || "",
      customerContact: userDetails
        ? `${userDetails.firstName} ${userDetails.lastName}`
        : "",
      email: userDetails?.x2userName || "",
      VIN: cksearch?.vin || ckEnteredDetails?.VIN || "",
      mileage: cksearch?.currentMileage || ckEnteredDetails?.mileage || "",
      phone: ckEnteredDetails?.phone || customerInfo?.phone || "",
      serviceemail:
        ckEnteredDetails?.serviceemail || customerInfo?.serviceemail || "",
      servicecell:
        ckEnteredDetails?.servicecell || customerInfo?.servicecell || "",
      vehicleOwnerName:
        ckEnteredDetails?.vehicleOwnerName ||
        vehicleInfo?.vehicleOwnerName ||
        "",
      selectedWarranty: warrantyData?.warrantyId || "Select Warranty",
    },
  });

  const handleCustomerInputChange = async (event) => {
    const { name, value } = event.target;
    setCustomerInfo((prevData) => ({ ...prevData, [name]: value }));
    await trigger(name); // Manually trigger validation for the field
    clearErrors(name); // Clear the error for this field
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null })); // Clear the error in state
  };

  const handleVehicleInputChange = async (event) => {
    const { name, value } = event.target;
    setVehicleInfo((prevData) => ({ ...prevData, [name]: value }));
    await trigger(name); // Manually trigger validation for the field
    clearErrors(name); // Clear the error for this field
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null })); // Clear the error in state
  };

  const handleWarrantyChange = async (event) => {
    // const data = findWarrantyById(event.target.value);
    const data = ckorder.warranties.find(
      (warranty) => warranty.warrantyId === event.target.value
    );

    setSelectedWarranty(data);
    await trigger(event.target.name); // Manually trigger validation for the field
    clearErrors(event.target.name); // Clear the error for this field
    setErrors((prevErrors) => ({ ...prevErrors, selectedWarranty: null })); // Clear the error in state
    // updateStateForWarranty(data);
  };
  const updateStateForWarranty = (data) => {
    dispatch(getCKReviewSelectedOrder({ ...ckorder, warrantyData: data }));
  };
  const findWarrantyById = (warrantyId) => {
    return ckorder.warranties.find(
      (warranty) => warranty.warrantyId === warrantyId
    );
  };

  const RedirectToCheckoutPage = async () => {
    try {
      const payload = {
        quotes: [
          {
            quoteID: ckorder.quoteId,
          },
        ],
      };
      dispatch(getQuoteShipping(payload));
      const res = findWarrantyById(selectedWarranty);
      dispatch(getReviewCKWarrantyData(selectedWarranty));
      dispatch(
        getCKReviewSelectedOrder({
          ...ckorder,
          show: false,
          fromCheckout: false,
        })
      );
      history.push({
        pathname: "/ck_partsearch/ck_checkout",
        state: {
          warrantyData: {
            "warrantyId": selectedWarranty?.warrantyId
          },
          selectedWarranty: selectedWarranty,
          isCallType: isCallType,
          warrantyId: selectedWarranty?.warrantyId
        } 
      });
    } catch (error) {
      console.error("Error navigating to checkout page:", error);
    }
  };
  const validateForm = () => {
    const newErrors = {};

    // Validate customerContact field
    if (!customerInfo.customerContact.trim()) {
      newErrors.customerContact = "Contact person required";
    }

    // Validate email field
    if (!customerInfo.email.trim()) {
      newErrors.email = "Email required";
    } else if (!isValidEmail(customerInfo.email)) {
      newErrors.email = "Invalid email address";
    }

    // Validate phone field
    if (!customerInfo.phone.trim()) {
      newErrors.phone = "Phone is required";
    } else if (customerInfo.phone.length !== 14) {
      newErrors.phone = "Phone must be exactly 10 digits";
    }

    // Validate vehicleOwnerName field
    if (!vehicleInfo.vehicleOwnerName.trim()) {
      newErrors.vehicleOwnerName = "Vehicle owner name required";
    }

    // Validate VIN field
    if (!vehicleInfo.VIN.trim()) {
      newErrors.VIN = "VIN required";
    }

    // Validate mileage field
    if (!vehicleInfo.mileage.trim()) {
      newErrors.mileage = "required";
    }

    // Validate selectedWarranty field
    if (
      selectedWarranty === "Select Warranty" ||
      selectedWarranty === undefined ||
      selectedWarranty === ""
    ) {
      newErrors.selectedWarranty = "Warranty required";
    }
    setIsFormValid(Object.keys(newErrors).length === 0);

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleClose = () => {
  //   history.push("/ck_partsearch");
  // };
  const handleSubmitForm = async () => {
    if (validateForm()) {
      onSubmit(); // Call your onSubmit function to handle the form submission
    } else {
      console.log("Form is not valid. Please fill in all required fields.");
    }
  };

  const onSubmit = async (data) => {
    dispatch(
      getCKReviewEnteredOrderDetails({ ...customerInfo, ...vehicleInfo })
    );
    RedirectToCheckoutPage();
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^\d+$/;
    return phoneRegex.test(phone);
  };
  return (
    <XModal
      title="Review Order"
      show={show}
      handleClose={handleClose}
      showButtons={false}
      className="ck_xmodal"
    >
      <div>
        {" "}
        {/* <form onSubmit={handleSubmit(handleSubmitForm)}> */}
        <form>
        {isCallType && <p style={{fontSize:"16px",fontWeight:"700",mrgin:"0",padding:"0 5px", backgroundColor: "#ff0"}}>This item is subject to an availability check. A team member will contact you within 2 business days to confirm availability and delivery date</p>}
          <Box display="flex" justifyContent="space-between">
            <Box width="67%">
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} container spacing={1}>
                  {/* <div className="ck_review_header"> */}

                  <span className="section-heading">Customer Information:</span>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex-container">
                    <Textbox
                      id="customername"
                      name="customername"
                      label="Customer Name"
                      fullWidth
                      value={
                        userDetails?.billTo?.name || customerInfo?.customername
                      }
                      disabled
                      inputProps={{ style: { height: "12px" } }} // Adjust the font size
                      className="ck_textfield textFieldStyle"
                      InputLabelProps={{
                        style: {
                          display: "flex",
                          alignItems: "center",
                          marginTop: "-5px",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex-container">
                    <Controller
                      name="customerContact"
                      control={control}
                      defaultValue={customerInfo.customerContact}
                      render={({ field }) => (
                        <Textbox
                          {...field}
                          fullWidth
                          name="customerContact"
                          value={customerInfo.customerContact} // Update this line
                          // value={`${userDetails.firstName + userDetails.lastName}` || customerInfo?.customerContact}
                          onChange={(e) => handleCustomerInputChange(e)}
                          className="ck_textfield"
                          label="Adjuster/ Customer Contact*"
                          inputProps={{ style: { height: "12px" } }} // Adjust the font size
                          InputLabelProps={{
                            style: {
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-5px",
                              fontSize: "14px",
                            },
                          }}
                          error={!!errors.customerContact}
                          helperText={errors.customerContact || ""}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex-container">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={
                        userDetails.billTo.email || customerInfo.email
                      }
                      render={({ field }) => (
                        <Textbox
                          {...field}
                          fullWidth
                          name="email"
                          value={userDetails.billTo.email || customerInfo.email}
                          onChange={(e) => handleCustomerInputChange(e)}
                          className="ck_textfield textFieldStyle"
                          label="Email*"
                          inputProps={{
                            style: { height: "12px" },
                            maxLength: 50,
                          }} // Adjust the font size
                          InputLabelProps={{
                            style: {
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-5px",
                              fontSize: "14px",
                            },
                          }}
                          error={!!errors.email}
                          helperText={errors.email || ""}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <span className="section-heading">Vehicle Information:</span>
                </Grid>
                <Grid item xs={5}>
                  <div className="flex-container">
                    <Controller
                      name="vehicleOwnerName"
                      control={control}
                      defaultValue={ckEnteredDetails?.vehicleOwnerName}
                      render={({ field }) => (
                        <Textbox
                          {...field}
                          fullWidth
                          name="vehicleOwnerName"
                          value={vehicleInfo?.vehicleOwnerName || ckEnteredDetails?.vehicleOwnerName}
                          onChange={handleVehicleInputChange}
                          label="Vehicle Owner Name*"
                          inputProps={{
                            style: { height: "12px" },
                            maxLength: 50,
                          }} // Adjust the font size
                          className="ck_textfield textFieldStyle"
                          InputLabelProps={{
                            style: {
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-5px",
                              fontSize: "14px",
                            },
                          }}
                          error={!!errors.vehicleOwnerName}
                          helperText={errors.vehicleOwnerName?<p style={{color:"red"}}>{errors.vehicleOwnerName}</p>:""}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className="flex-container">
                    <Controller
                      name="VIN"
                      control={control}
                      defaultValue={vehicleInfo?.VIN}
                      render={({ field }) => (
                        <Textbox
                          {...field}
                          fullWidth
                          name="VIN"
                          value={cksearch?.vin || ckEnteredDetails?.VIN || ""}
                          onChange={handleVehicleInputChange}
                          label="VIN*"
                          inputProps={{
                            style: { height: "12px" },
                            maxLength: 20,
                          }} // Adjust the font size
                          className="ck_textfield textFieldStyle"
                          InputLabelProps={{
                            style: {
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-5px",
                              fontSize: "14px",
                            },
                          }}
                          error={!!errors.VIN}
                          helperText={errors.VIN}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className="flex-container">
                    <Controller
                      name="mileage"
                      control={control}
                      defaultValue={vehicleInfo.mileage}
                      render={({ field }) => (
                        <Textbox
                          {...field}
                          fullWidth
                          name="mileage"
                          value={
                            cksearch?.currentMileage ||
                            ckEnteredDetails.mileage ||
                            ""
                          }
                          onChange={handleVehicleInputChange}
                          label="Mileage*"
                          inputProps={{
                            style: { height: "12px" },
                            maxLength: 7,
                          }} // Adjust the font size
                          className="ck_textfield textFieldStyle"
                          InputLabelProps={{
                            inputComponent: NumberMaskCustom,
                            style: {
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-5px",
                              fontSize: "14px",
                            },
                          }}
                          error={!!errors.mileage}
                          helperText={errors.mileage || ""}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} container spacing={1} fullwidth>
                  <span className="section-heading">
                    Repair Facility Information:
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex-container">
                    <Controller
                      name="serviceemail"
                      control={control}
                      defaultValue={
                        customerInfo.serviceemail ||
                        ckEnteredDetails.serviceemail
                      }
                      render={({ field }) => (
                        <Textbox
                          fullWidth
                          name="serviceemail"
                          value={
                            customerInfo.serviceemail ||
                            ckEnteredDetails.serviceemail
                          }
                          onChange={handleCustomerInputChange}
                          label="Service Advisor Email (Optional)"
                          inputProps={{
                            style: { height: "12px" },
                            maxLength: 50,
                          }} // Adjust the font size
                          className="ck_textfield textFieldStyle"
                          InputLabelProps={{
                            style: {
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-5px",
                              fontSize: "14px",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex-container">
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue={
                        customerInfo.phone || ckEnteredDetails?.phone
                      }
                      render={({ field }) => (
                        <Textbox
                          {...field}
                          fullWidth
                          name="phone"
                          value={customerInfo.phone || ckEnteredDetails?.phone}
                          onChange={handleCustomerInputChange}
                          label="Repair Facility Phone*"
                          className="ck_textfield textFieldStyle"
                          InputProps={{
                            style: { height: "45px" },
                            inputComponent: TextMaskCustom,
                          }}
                          InputLabelProps={{
                            style: {
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-5px",
                              fontSize: "14px",
                            },
                          }}
                          error={!!errors.phone}
                          helperText={
                            errors.phone
                            ? <p style={{color:"red"}}>{errors.phone}</p>
                              : customerInfo.phone.length !== 14
                                ? "Phone must be exactly 10 digits"
                                : ""
                          }
                        />
                      )}
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="flex-container">
                    <Controller
                      name="servicecell"
                      control={control}
                      defaultValue={
                        customerInfo.servicecell ||
                        ckEnteredDetails?.servicecell
                      }
                      render={({ field }) => (
                        <Textbox
                          fullWidth
                          name="servicecell"
                          value={
                            customerInfo.servicecell ||
                            ckEnteredDetails?.servicecell
                          }
                          onChange={handleCustomerInputChange}
                          label="Service Advisor Cell"
                          InputProps={{
                            style: { height: "45px" },
                            inputComponent: TextMaskCustom,
                          }}
                          className="ck_textfield textFieldStyle"
                          InputLabelProps={{
                            style: {
                              display: "flex",
                              alignItems: "center",
                              marginTop: "-5px",
                              fontSize: "14px",
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box width="30%">
              {/* Right Section */}
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} container spacing={1}>
                  <span className="section-heading">Order Summary:</span>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <table className="review-table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="tableDescriptionCK">
                          <Typography variant="body1">
                            Delivered Price
                          </Typography>
                        </td>
                        <td className="marooncolor">
                          {getCnKPartsSum(ckorder?.customerPrice, candkPartPrice)}
                        </td>
                      </tr>
                      <tr>
                        <td className="tableDescriptionCK">
                          <Typography variant="body1">
                            Core Value
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 100, hide: 400 }}
                              overlay={
                                <Tooltip
                                  id="extended-price-tooltip"
                                  className="tooltip-description-custom-corePrice"
                                >
                                  <span className="font-weight-bold">
                                    Not charged with the return of the core
                                  </span>
                                </Tooltip>
                              }
                            >
                              <a className="info-icon ml-1">
                                <i class="fa fa-info-circle"></i>
                              </a>
                            </OverlayTrigger>
                          </Typography>
                        </td>
                        <td className="marooncolor">
                          {getTotalCorePrice(ckorder?.coreValue)}
                        </td>
                      </tr>
                      <tr>
                        <td className="tableDescriptionCK">
                          {" "}
                          <Typography variant="body1">Pre Tax Price</Typography>
                        </td>
                        <td className="marooncolor">
                          {getCnKPartsSum(ckorder?.customerPrice, candkPartPrice)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  style={{ marginTop: "7px" }}
                >
                  {/* <Typography className="warrantyField">
                      Warranty
                    </Typography> */}
                  <Grid item xs={12} container spacing={1}>
                    <span className="section-heading">
                      Warranty
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 100, hide: 400 }}
                        overlay={
                          <Tooltip
                            id="extended-price-tooltip"
                            className="tooltip-description-custom-corePrice"
                          >
                            <span className="font-weight-bold">
                              Warranty is included in purchase
                            </span>
                          </Tooltip>
                        }
                      >
                        <a className="info-icon ml-1 icon-resize">
                          <i class="fa fa-info-circle"></i>
                        </a>
                      </OverlayTrigger>
                    </span>
                  </Grid>
                  {ckorder && ckorder.warranties &&
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={1}
                      style={{ marginTop: "1px" }}
                    >
                      {/* <Typography className="warrantyField">
                      Warranty
                    </Typography> */}
                      {/* <InputLabel className="warrantyField" shrink>Warranty*</InputLabel> */}

                      <FormControl
                        fullWidth
                        style={{
                          width: "240px",
                          marginLeft: "12px",
                          marginTop: "-7px !important",
                        }}                    >
                        <Controller
                          name="selectedWarranty"
                          control={control}
                          render={({ field }) => (

                            <Select
                              {...field}
                              value={selectedWarranty?.warrantyId || "Select Warranty"}
                              defaultValue={ckorder?.warranties[0].warrantyDisplayName}
                              onChange= {(e) => handleWarrantyChange(e)}
                              style={{ height: 40 }}
                              placeholder="Select Warranty"
                              error={!!errors.selectedWarranty}
                              helperText={errors.selectedWarranty || ""}
                              inputProps={{ style: { height: "12px" } }} // Adjust the font size
                              className="ck_textfield textFieldStyle"
                              InputLabelProps={{
                                style: {
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "-5px",
                                  fontSize: "14px",
                                },
                              }}
                            >
                              {ckorder && ckorder.warranties && (
                                <MenuItem value="Select Warranty" disabled>
                                  Select Warranty
                                </MenuItem>
                              )}
                              {ckorder && ckorder.warranties ? (
                                ckorder.warranties.map((item, index) => (
                                  <MenuItem
                                    value={item.warrantyId}
                                    key={`item + ${index}`}
                                  >
                                    {item.warrantyDisplayName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="">
                                  No warranties available
                                </MenuItem>

                              )}
                            </Select>
                          )}

                        />
                      </FormControl>
                      {errors.selectedWarranty && (
                        <p className="Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1rqod25-MuiFormHelperText-root" id='mui-17-helper-text'>
                          {errors.selectedWarranty}
                        </p>
                      )}
                    </Grid>}
                 
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </div>
      <Modal.Footer className="xmodal-footer">
        {/* Move the buttons to the right */}
        <div style={{ marginLeft: "auto" }}>
          <Button
            // onClick={handleSubmit(handleSubmitForm)}
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginRight: "10px" }}
            onClick={handleSubmitForm}
          // disabled={!formState.isValid} // Disable the button based on form validity
          >
            Checkout
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </Button>{" "}
        </div>
      </Modal.Footer>
    </XModal>
  );
};

export default CKReviewPopUp;
