import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "./styles.scss";
import { useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { currencyFormatter } from "helpers/utils";
import { formatMonth } from "helpers/utils";
import { Col, OverlayTrigger, Tooltip, Button, Row } from "react-bootstrap";

const FirstTable = () => {
  const [tableData, setTableData] = useState([]);

  const { benefitData } = useSelector(
    (state) => state.ProgramAndBenefits
  );

  useEffect(() => {
    if (!_isEmpty(benefitData && benefitData?.volumeItems)) {
      constrctTableData(benefitData?.volumeItems);
    }
  }, [benefitData]);

  const headerStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
  };

  const constrctTableData = (data = []) => {
    if (!_isEmpty(data)) {
      let pvpRowData = [];
      data.map((item, i) => {
        pvpRowData.push({
          month: item.month,
          sales: item.sales,
          rebate: item.rebates,
          tierEarned: item.tierEarned,
          creditMemoLink: item.invNumber,
          invUrl: item.invUrl,
          index: i,
        });
      });
      setTableData(pvpRowData);
    }
  };
  const dollarRoundOff = (dollar) => {
    //converts dollar to number and rounds it off
    return currencyFormatter(Math.round(Number(dollar))).split('.')[0];
  };

  const rebatesHeader = () => {
    return (
        <><span>Rebate</span>
        <OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 400 }}
        overlay={<Tooltip
          id="extended-price-tooltip"
          className="tooltip-description-custom-corePrice"
        >
          <span className="font-weight-bold">
          The Rebate Credit amount displayed is shown for the prior month's online sales.
          </span>
        </Tooltip>}
      >
        <a className="whiteColorTxt info-icon ml-1">
          <i class="fa fa-info-circle"></i>
        </a>
      </OverlayTrigger></>
    );
  }
  const salesHeader = () => {
    return (
        <><span>Sales</span>
        <OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 400 }}
        overlay={<Tooltip
          id="extended-price-tooltip"
          className="tooltip-description-custom-corePrice"
        >
          <span className="font-weight-bold">
          Sales values reflected are system generated actual rebate eligible sales may vary.
          </span>
        </Tooltip>}
      >
        <a className="whiteColorTxt info-icon ml-1">
          <i class="fa fa-info-circle"></i>
        </a>
      </OverlayTrigger></>
    );
  }
  const tierEarnedHeader = () => {
    return (
        <><span>Tier Earned</span>
        <OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 400 }}
        overlay={<Tooltip
          id="extended-price-tooltip"
          className="tooltip-description-custom-corePrice"
        >
          <span className="font-weight-bold">
          Tier Earned %’s are a reflection of system
           generated sales compared to the customer’s rebate table actual
            rebate value shown is a function of actual rebate eligible sales 
            at the time of the rebate issuance period .
          </span>
        </Tooltip>}
      >
        <a className="whiteColorTxt info-icon ml-1">
          <i class="fa fa-info-circle"></i>
        </a>
      </OverlayTrigger></>
    );
  }

  const noDataState = () => {
    return (
      <p align="center" style={{ padding: "0", margin: "0" }}>
        No Data Found
      </p>
    );
  };
  const columns = [
    {
      dataField: "month",
      text: "Month",
      headerStyle: headerStyle,
      align: "center",
      headerAlign: "center",
      formatter: (cell, row, index) => {
        return <span style={{whiteSpace:"nowrap"}}>{formatMonth(row.month)}</span>;
      },
    },
    {
      dataField: "sales",
      text: "Sales",
      headerStyle: headerStyle,
      classes: "pvp-rightalign",
      align: "center",
      headerAlign: "center",
      headerFormatter: (column, colIndex) => { return salesHeader() },
      formatter: (cell, row, index) => {
        return <span>{`${currencyFormatter(cell)}`}</span>;
      },
    },
    {
      dataField: "rebate",
      text: "Rebate",
      headerStyle: headerStyle,
      classes: "pvp-rightalign",
      align: "center",
      headerAlign: "center",
      headerFormatter: (column, colIndex) => { return rebatesHeader() },
      formatter: (cell, row, index) => {
        return <span>{`${currencyFormatter(cell)}`}</span>;
      },
    },
    {
      dataField: "tierEarned",
      text: "Tier Earned",
      headerStyle: headerStyle,
      align: "center",
      headerAlign: "center",
      headerFormatter: (column, colIndex) => { return tierEarnedHeader() },
      formatter: (cell, row, index) => {
        //return empty when there is no tier earned
        return row.tierEarned ? (
          <span>{dollarRoundOff(row.tierEarned)}.00%</span>
        ) : (
          <span style={{ color: "#0f5283" }}>-</span>
        );
      },
    },
    {
      dataField: "creditMemoLink",
      text: "Credit Memo Link",
      headerStyle: headerStyle,
      align: "center",
      headerAlign: "center",
      formatter: (cell, row, index) => {
       //return empty when no credit link to show or sales is zero
       let isSalesZero=+row.sales===0
        return row.creditMemoLink && ! isSalesZero ? (
          <a
            href={row.invUrl}
            style={{ textDecoration: "underline" }}
            target="_blank" rel="noreferrer"
          >
            {row.creditMemoLink}
          </a>
        ) : (
          <span style={{ color: "#0f5283" }}>-</span>
        );
      },
    },
  ];
  return (
    <BootstrapTable
      classes="pvp-table-styles"
      keyField="month"
      data={tableData}
      columns={columns}
      noDataIndication={noDataState}
    />
  );
};

export default FirstTable;
