import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _findIndex from "lodash/findIndex";
import { InputGroup, DropdownButton, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { triggerCustomUxCriticalError } from "../../../actions/error";
import {
  getUsersList,
  getCompanyList,
  modifyCompany
} from '../UserManagement/usermanagement.thunk';
import { populateUserDropDownOptions, timeInUTC } from "../../../helpers/utils";
import { setModifyUser } from "../UserManagement/usermanagement.slice";

const ModifyCompany = (props) => {
  let companyList = useSelector((state) => state.userManagement.companyList);
  let ShipToIds = useSelector((state) => state.ShipToIds.shipToIds);
  let selectedUser = useSelector(
    (state) => state.userManagement.selectedModifyUser
  );
  let [selectedModifyUser, setselectedModifyUser] = useState({});
  const [selectedLocation, setselectedLocation] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [isActive, setisActive] = useState(true);
  const [active, setActive] = useState(true);
  const [inActive, setInActive] = useState(false);
  const [userModified, setuserModified] = useState(false);
  let user = useSelector((state) => state.user.userDetails);
  const UserUpdate = useSelector((state) => state.userManagement.UserUpdate);
  const dispatch = useDispatch();
  let shipToList = !_isEmpty(ShipToIds)
    ? populateUserDropDownOptions(ShipToIds)
    : [];
  useEffect(() => {
    if (!_isEmpty(user)) {
      dispatch(getCompanyList());
    }
  }, []);

  useEffect(() => {
    let tempArr = [];
    setselectedModifyUser(selectedUser);
    if (!_isEmpty(selectedUser)) {
      selectedUser.shipTos.map((shipTo) => {
        tempArr.push(shipTo.shipToId);
      });
      setselectedLocation(tempArr);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!_isEmpty(user)) {
      let statusMessageProps = {
        heading: "",
        message: "",
        type: "",
      };
      if (UserUpdate === "success" && !_isEmpty(selectedUser)) {
        setuserModified(false);
        dispatch(setModifyUser(false));
        statusMessageProps.heading = "User Modified Successfully";
        statusMessageProps.type = "success";
        triggerCustomUxCriticalError(statusMessageProps, dispatch);
      } else if (UserUpdate === "failed" && !_isEmpty(selectedUser)) {
        statusMessageProps.heading = "User Modification Failed";
        statusMessageProps.type = "error";
        triggerCustomUxCriticalError(statusMessageProps, dispatch);
      }
      const payload = {
        customerId: user.billTo.billToId,
      };
      dispatch(getCompanyList());
    }
  }, [UserUpdate]);

  const handleSubmit = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
      };
    let payload = {
       id : companyId,
       isActive : active ? true : false,
       updatedBy: user.x2userName ? user.x2userName : 'uday',
       updatedDate: timeInUTC()
    };
    if(!_isEmpty(payload)) {
      dispatch(modifyCompany(payload)).then((result) => {
          if (result.payload) {
              statusMessageProps.heading = 'Company Modified Successfully';
              statusMessageProps.type = 'success';
              triggerCustomUxCriticalError(statusMessageProps, dispatch);
          } else {
              statusMessageProps.heading = 'Company Modification Failed';
              statusMessageProps.type = 'error';
              triggerCustomUxCriticalError(statusMessageProps, dispatch);
          }
          dispatch(getCompanyList());  
      });
  }
  };

  const createCompanyOptions = () => {
    let optionArr = [];
    if (!_isEmpty(companyList)) {
      companyList.map((company) => {
        optionArr.push({
          value: company.companyId,
          label: company.companyName,
        });
      });
    }
    return optionArr;
  };

  const handleSelectChange = (selectedOption, data) => {
    const { name } = data;
    const { value, label } = selectedOption;
    if (name === "companies") {
      setCompanyId(value)
      setuserModified(true);
    }
  }

  const handleActiveChange = (event) => {
    if (event.target.id === "inActive") {
      setActive(false)
    } else {
      setActive(true)
    }
    if (event.target.id === "Active") {
      setInActive(false)
    } else {
      setInActive(true)
    }
    setuserModified(true);
  }


  return (
    <Fragment>
      <div className="label-dropdown-container">
        <Form.Label>Companies</Form.Label>
        <Select
          name="companies"
          options={!_isEmpty(companyList) && createCompanyOptions()}
          placeholder="Select a Company"
          className="custom-dropdown"
          onChange={handleSelectChange}
        />
      </div>
      <div className="label-dropdown-container">
                        <label class="customized-radio-button company-active-radio">
                            <input
                                type="radio"
                                id="Active"
                                name="Active"
                                onChange={handleActiveChange}
                               checked={active}
                            />
                            <span>Active</span>
                        </label>
                        <label class="customized-radio-button">
                            <input
                                type="radio"
                                id="inActive"
                                name="inActive"
                                onChange={handleActiveChange}
                               checked={inActive}
                            />
                            
                            <span>InActive</span>
                        </label>
                        
       </div>

      <Button
        disabled={!userModified}
        onClick={handleSubmit}
        className="user-management-submit-btn"
      >
        OK
      </Button>
    </Fragment>
  );
};

export default ModifyCompany;
