import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { MenuItem, Grid, Box, TextField, Select } from "@mui/material";
import { setQuoteSelected } from "../shared/MyGarage/mygarage.slice";
import { triggerCustomUxCriticalError } from "../../actions/error";
import { saveShippingAddress } from "../../actions/cart";
import _isEmpty from "lodash/isEmpty";
import { statesList } from "../../helpers/constants";
import {
  getPreferenceDataFromStorage,
} from "../../helpers/utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Checkbox } from "ui-library";
import _ from 'lodash';

const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

export default function AdddressForm(props) {
  const { handleCancel, setNewAddressInfo, editableAddress, localAddress } = props;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetails);
  let defaultstate = userDetails.shipTos[0].state;
  const all = [{ label: defaultstate, value: defaultstate }];
  const [selectedFilter, setselectedFilter] = useState(all);

  const defaultValues = {
    companyName: editableAddress && localAddress && localAddress[0]?.companyName || "",
    cpFirstName: editableAddress && localAddress && localAddress[0]?.contactPersonFirstName || "",
    cpLastName: editableAddress && localAddress && localAddress[0]?.contactPersonLastName || "",
    streetAddress: editableAddress && localAddress && localAddress[0]?.streetAddress || "",
    state: editableAddress && localAddress && localAddress[0]?.state || userDetails.shipTos[0].state,
    city: editableAddress && localAddress && localAddress[0]?.city || "",
    zipCode: editableAddress && localAddress && localAddress[0]?.zipCode || "",
  };

  const [isSaveAddress, setIsSaveAddress] = useState(true);

  useEffect(() => {
    const isSave = determineSaveAddress();
    setIsSaveAddress(isSave === 'Y');
  }, []);

  const determineSaveAddress = () => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    if (!_isEmpty(userPreferenceDetail)) {
      const selectedFilter = _.find(userPreferenceDetail, {
        code: "Cart",
        text: "Save delivery address",
      });

      if (selectedFilter) {
        const { answer, possibleAnswer } = selectedFilter;
        if (answer && answer.code) {
          return answer.code;
        }

        const defaultAnswer = possibleAnswer?.find(v => v.default);
        return defaultAnswer?.code || null;
      }
    }
    return null;
  };

  const schema = yup.object({
    companyName: yup.string().required("Company Name is required"),
    cpFirstName: yup.string().required("First Name is required"),
    cpLastName: yup.string().required("Last Name is required"),
    streetAddress: yup.string().required("Street Address is required"),
    state: yup.string().required("State is required"),
    city: yup.string().required("City is required"),
    zipCode: yup
      .number()
      .typeError("Zip Code must be a number")
      .required("Zip Code is required"),
  }).required();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (data) => {
    handleSaveAddress(data);
  };

  const handleSaveAddress = (formData) => {
    const { xlPartAccountNumber, x2userName } = user.userDetails || {};
    let customerId = xlPartAccountNumber;   
    const addressSaved = {
      heading: `Address is saved`,
      message: "",
      type: "success",
    };
    let newRow = {
      customerId: customerId,
      companyName: formData.companyName,
      contactPersonFirstName: formData.cpFirstName,
      contactPersonLastName: formData.cpLastName,
      streetAddress: formData.streetAddress,
      city: formData.city,
      state: formData.state,
      country: "USA",
      zipCode: formData.zipCode,
    }
    let tempDeliveryAddressPayload = {
      companyName: formData.companyName,
      person: {
        firstName: formData.cpFirstName,
        middleName: "",
        lastName: formData.cpLastName,
        phone: "",
        fax: "",
        email: x2userName
      },
      address: {
        street: formData.streetAddress,
        city: formData.city,
        state: formData.state,
        country: "USA",
        postalID: formData.zipCode
      }
    };
    setNewAddressInfo({ isAddedNew: true, isSaveAddress: isSaveAddress, row: newRow });
    if (!isSaveAddress) {
      dispatch({
        payload: {
          selectedAddressId: "",
          cartTempDeliveryAddress: tempDeliveryAddressPayload
        },
        type: "SELECTED_ADDRESS_ID",
      });
      handleCancel();
      return;
    }
    
    dispatch({
      payload: { globalLoader: true },
      type: actionTypes.GLOBAL_LOADER,
    });
    saveShippingAddress(
      customerId,
      formData.companyName,
      formData.cpFirstName,
      formData.cpLastName,
      formData.streetAddress,
      formData.city,
      formData.state,
      "USA",
      formData.zipCode,
      dispatch
    ).then((savedAddress) => {
      dispatch({
        payload: {
          selectedAddressId: savedAddress.data?.id || null,
          cartTempDeliveryAddress: {}
        },
        type: "SELECTED_ADDRESS_ID",
      });

      dispatch({
        payload: { globalLoader: false },
        type: actionTypes.GLOBAL_LOADER,
      });
      dispatch(setQuoteSelected(""));
      triggerCustomUxCriticalError(addressSaved, dispatch);
    });

    handleCancel();
  };

  const handleSaveAddressChange = (event) => {
    setIsSaveAddress(!event.target.checked);
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" justifyContent="space-between">
            <Box width="100%">
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  <div className="flex-container">
                    <Controller
                      name="companyName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          error={!!errors.companyName}
                          fullWidth
                          label="Company Name*"
                          helperText={errors.companyName?.message}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex-container">
                    <Controller
                      name="cpFirstName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          error={!!errors.cpFirstName}
                          fullWidth
                          label="Contact Person First Name*"
                          helperText={errors.cpFirstName?.message}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="flex-container">
                    <Controller
                      name="cpLastName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          error={!!errors.cpLastName}
                          fullWidth
                          label="Contact Person Last Name*"
                          helperText={errors.cpLastName?.message}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <legend className="section-heading-address">Address:</legend>
                  <div className="flex-container">
                    <Controller
                      name="streetAddress"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          error={!!errors.streetAddress}
                          fullWidth
                          label="Street Address*"
                          helperText={errors.streetAddress?.message}
                        />
                      )}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="flex-container">
                    <Controller
                      name="city"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          error={!!errors.city}
                          fullWidth
                          label="City*"
                          helperText={errors.city?.message}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="flex-container">
                    <Controller
                      name="zipCode"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          error={!!errors.zipCode}
                          fullWidth
                          label="Zip / Postal code*"
                          helperText={errors.zipCode?.message}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="">
                    <Controller
                      name="state"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          error={!!errors.state}
                          fullWidth
                          label="State*"
                        >
                          {statesList.map((item, i) => (
                            <MenuItem value={item.id} key={item.label}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <div className="add-new-actions-ship">
            <div>
              <Checkbox
                label="Do not save this delivery address"
                checked={!isSaveAddress}
                onChange={handleSaveAddressChange}
              />
            </div>
            <div className="actions">
              <Button variant="contained" color="primary" type="submit">
                OK
              </Button>
              <Button variant="contained" color="info" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
