import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { Row, Tabs, Tab, Col } from "react-bootstrap";
import {
	isInputBlank,
	isNumberLessthan,
	hasNumberMinLength,
	isNumeric,
	numbersOnlyRegExp,
} from "../../../helpers/utils";
import Profile from "../storeProfile/Profile";
import Header from "./Header";


const url = "/store-user-dashboard";
const AMUrl = "/manager-dashboard";
const urlName = "Store Dashboard";

const StoreManagement = ({ props }) => {
	const [key, setKey] = useState("userProfile");
	const userDetailsArr = useSelector((state) => state.user);
	let userType = "";
	let userDetails = "";

	if (!_isEmpty(userDetailsArr)) {
		userType = userDetailsArr.userDetails.userType;
		userDetails = userDetailsArr.userDetails;
	}


	const initializeFields = () => {
		const fieldObj = {
			currentPassword: "",
			newPassword: "",
			confirmPassword: "",
		};
		return fieldObj;
	};
	const initializeValidity = () => {
		const validity = {
			hasValidLength: true,
			isPasswordMatched: true,
			isValidPassword: true,
		};
		return validity;
	};
	const initializeMobValidation = () => {
		const mobValidity = {
			isMobileNoNotNull: true,
			isMobileNoLengthBelowLimit: true,
			isMobileNoMinLength: true,
			isMobileNoNumeric: true,
		};
		return mobValidity;
	};

	const [fields, setFields] = useState(initializeFields);
	const [validity, setvalidity] = useState(initializeValidity);
	const [mobValidity, setMobValidity] = useState(initializeMobValidation);
	const [mobileNumber, setMobileNumber] = useState("");

	const handleSelect = (key) => {
		let validityData = initializeValidity();
		if (key === "manageUsers") {
			setvalidity(validityData);
			setFields(initializeFields);
			setMobValidity(initializeMobValidation);
			setMobileNumber("");
		}
		setKey(key);
	};

	const handleInputChange = (event) => {
		const passwordType = event.target.name;
		const { value } = event.target;
		const PasswordRegEx = RegExp(
			"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
		);
		let validityData = initializeValidity();
		switch (passwordType) {
			case "confirmPassword":
				validityData.isPasswordMatched = fields.newPassword === value;
				setvalidity(validityData);
				break;
			case "newPassword":
				validityData.hasValidLength = value.length >= 8;
				validityData.isValidPassword = PasswordRegEx.test(value);
				validityData.isPasswordMatched = fields.confirmPassword === value;
				setvalidity(validityData);
				break;
			default:
				break;
		}
		setFields({
			...fields,
			[event.target.id]: event.target.value,
		});
	};

	const handleMobNumberOnChange = (data) => {
		const number = data.currentTarget.value;
		const mobNumber = number.charAt(0);
		let value;
		if (mobNumber === " ") {
			value = number.trim();
		} else {
			value = number;
			validateMobileNumber(value);
		}
	};

	const validateMobileNumber = (value) => {
		let validity = initializeMobValidation();
		validity.isMobileNoNotNull = !isInputBlank(value);
		validity.isMobileNoLengthBelowLimit = isNumberLessthan(value, 10);
		validity.isMobileNoMinLength = hasNumberMinLength(value, 10);
		validity.isMobileNoNumeric = isNumeric(value);
		setMobValidity(validity);
		if (validity) {
			setMobileNumber(value);
		}
	};

	const handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
		return (
			code !== 8 &&
			code !== 9 &&
			code !== 37 &&
			code !== 38 &&
			code !== 39 &&
			code !== 40 &&
			code !== 46
		);
	};

	const handleKeyDown = (event) => {
		if (
			(numbersOnlyRegExp(event.key) === false ||
				event.keyCode === 32 ||
				(mobileNumber && mobileNumber.length >= 10)) &&
			handleOtherKeys(event.keyCode)
		) {
			event.preventDefault();
		}
	};


	return (
		<div className="driver_management_view_container">
			<Header
				storeURL={(!_isEmpty(userType) && userType === "STORE_USER") ? url : AMUrl}
				storeName={urlName}
			/>
			<div className="store-user-management-container driver-manager">
				<div className="container">

					<div className="driver-user_management_user_details">
						<Row>
							<Col md="5">
								<div>
									<span className="user_management_label_color">
										First Name:
									</span>
									<span className="store-management-text">{userDetails.firstName}
									</span>
								</div>

								<div>
									<span className="user_management_label_color">
										Last Name:
									</span>{" "}
									<span className="store-management-text">{userDetails.lastName}</span>
								</div>
								<div>
									<span className="user_management_label_color">
										User Type:
									</span>{" "}
									<span>{userDetails.userType}</span>
								</div>

							</Col>
							<Col md="7">

								<div>
									<span className="user_management_label_color">
										Mobile Number:
									</span>{" "}
									<span>{userDetails.mobileNumber}</span>
								</div>
								<div>
									<span className="user_management_label_color">Email:</span>{" "}
									<span>{userDetails.x2userName}</span>
								</div>
								{userDetails && userDetails.storeNumber && (	
								<div>
									<span className="user_management_label_color">Store Number:</span>{" "}
									<span>{userDetails.storeNumber}</span>
								</div>
								)}
							</Col>
						</Row>
					</div>

					<Tabs
						id="controlled-tab-example"
						activeKey={'driverUsers'}
						onSelect={handleSelect}
						className="vertical-tabs"
					>

						<Tab eventKey="driverUsers" title="Driver Manager">
							<Profile
								handleInputChange={handleInputChange}
								handleMobNumberOnChange={handleMobNumberOnChange}
								validity={validity}
								mobValidity={mobValidity}
								fields={fields}
								handleMobKeyDown={handleKeyDown}
								mobileNumber={mobileNumber}
							/>
						</Tab>


					</Tabs>
				</div>
			</div>
		</div>
	);

}

export default StoreManagement;
