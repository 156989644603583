import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector} from "react-redux";
import Select from 'react-select';
import _isEmpty from "lodash/isEmpty";
import { Form, Button } from 'react-bootstrap';
import {
    getDeleteDriverList,
    deleteDriverEmail,
} from '../UserManagement/usermanagement.thunk';
import XModal from '../../shared/XModal';
import { triggerCustomUxCriticalError } from "../../../actions/error";
import {timeInUTC} from '../../../helpers/utils';

const DeleteDriver = (props) => {
    let driverList = useSelector((state) => state.userManagement.driverList);
    let userStatus = useSelector((state) => state.userManagement.driverDeleted);
    let [selectedDriver, setselectedDriver] = useState(null);
    const [deleteUser, setdeleteUser] = useState('');
    let user = useSelector((state) => state.user.userDetails);
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [buttonState, setbuttonState] = useState(false);
    const dispatch = useDispatch();
    let [optionArr, setOptionArray] = useState([]);
    
    useEffect(() => {
        if(!_isEmpty(driverList)) {
            driverList.map((user) => {
                if (user.userType !== "CUSTOMER_ADMIN" && user.x2userName !== "deleted_user") {
                    optionArr.push({
                       // value: user.x2userName,
                       label:user.fname + " " + user.lname + " " + "-" + " " +user.mobileNumber
                    });
                }
            });
            setOptionArray(optionArr);
        }
    }, [driverList]);
 

    useEffect(() => {
        if(!_isEmpty(props.companyData)) {
        const payload = {
            companyId: props.companyData && props.companyData[0].companyId,
        };
        dispatch(getDeleteDriverList(payload));
        }
    }, [userStatus]);


const handleDeleteDriver = () => {
    const deletePayload = {};
    const payload = {
        companyId:1,
    };
    let statusMessageProps = {
        heading: "",
        message: "",
        type: "",
        };
        let driverId = 0;
        if(!_isEmpty(driverList)) {
            driverList.map((user) => {
                if (user.userType !== "CUSTOMER_ADMIN" && user.x2userName !== "deleted_user" && user.mobileNumber === selectedDriver) {
                    driverId = user.driverId;
                }
            });
        }
        if(!_isEmpty(selectedDriver)) {
            deletePayload.driverId = driverId;
            deletePayload.updatedBy = user.x2userName;
            deletePayload.updatedDate = timeInUTC();
        } else if (!_isEmpty(deleteUser)) {
            deletePayload.x2Username = deleteUser;
        }
    setbuttonState(true);
    
    if(!_isEmpty(deletePayload)) {
        dispatch(deleteDriverEmail(deletePayload)).then((result) => {
            if (result.payload && result.payload.data === 'Driver Details updated successfully') {
                statusMessageProps.heading = 'Driver Deleted Successfully';
                statusMessageProps.type = 'success';
                triggerCustomUxCriticalError(statusMessageProps, dispatch);
            } else {
                statusMessageProps.heading = 'Driver Deletion Failed';
                statusMessageProps.type = 'error';
                triggerCustomUxCriticalError(statusMessageProps, dispatch);
            }
            setshowDeleteModal(false);
            setselectedDriver('');
            setdeleteUser('');
            dispatch(getDeleteDriverList(payload));
        });
    }
}

const handleDriverSelection = (selectedOption, data) => {
   // let value = event.value;
    if(!_isEmpty(selectedOption)) {
        setselectedDriver(selectedOption.label.split('-')[1].trim(''));
    }
}

return (
    <Fragment>


        <div className="label-dropdown-container">
                            <Form.Label>Drivers</Form.Label>
                            <Select
                             name="deleteUser"
                            isDisabled={_isEmpty(driverList)}
                            data={driverList}
                            onChange={handleDriverSelection}
                            options={!_isEmpty(driverList) && optionArr}
                            placeholder="Select a Driver"
                            className="custom-dropdown"
                             /></div>


    <Button
          onClick={handleDeleteDriver}
          className="user-management-submit-btn"
     
    >
         Delete
    </Button>
    </Fragment>
);
}

export default DeleteDriver;
