import React, { useEffect } from "react";
import PropTypes from "prop-types";

import email from "../../helpers/email/emailUtil";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";

import { logOut } from "../../actions/auth";

function TestDebug(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = window.location;

  const { protocol, host } = location;

  const baseURL = `${protocol}//${host}`;

  useEffect(() => {
    var _navigator = {};
    for (var i in window.navigator) _navigator[i] = navigator[i];

    let userData = {
      navigator: _navigator,
      localStorage: window.localStorage,
    };
    console.log("user data ==> ", userData);
    userData = JSON.stringify(userData);

    email.sendEmail({ message: userData }, 500, {
      dispatch,
      URL,
      headers: {},
      baseURL,
      subject: "DEBUG Purpose - please ignore",
    });
  }, []);

  const clearLocalStorage = () => {
    window.localStorage.clear();
    logOut(dispatch);
    history.push("/login");
  };

  return (
    <React.Fragment>
      <div style={{ textAlign: "center", margin: "25px" }}>
        <h5>You entered into TEST page</h5>
        <p>Capturing the browser and localstorage data</p>
        <Button
          onClick={clearLocalStorage}
          style={{
            borderColor: "#a60b0f",
            textTransform: "uppercase",
            backgroundColor: "#a60b0f",
            color: "white",
          }}
        >
          Clear Local Storage{" "}
        </Button>
      </div>
    </React.Fragment>
  );
}

TestDebug.propTypes = {};

export default TestDebug;
