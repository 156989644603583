import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { logOut } from "../../../actions/auth";
import Button from "ui-library/Button";

const Logout = (props) => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      {props.userType !== "SMS_MITCHELL1" && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => logOut(dispatch)}
          className="homepage-logout_1"
          startIcon={
            <i class="fa fa-sign-out logout_button" aria-hidden="true" />
          }
        >
          Logout
        </Button>
      )}
    </Fragment>
  );
};

export default Logout;
