import React,{useState,useEffect} from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import "./styles.scss"
import { currencyFormatter } from 'helpers/utils';
import _isEmpty from "lodash/isEmpty";
import { useSelector } from 'react-redux';

const ThirdTable = () => {
  const [tableData,setTableData]=useState([])

  const headerStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
  };
  const { benefitData } = useSelector(
    (state) => state.ProgramAndBenefits
  );

  useEffect(() => {
    if (!_isEmpty(benefitData && benefitData?.yearlySalesList)) {
      constrctTableData(benefitData?.yearlySalesList);
    }
  }, [benefitData]);

  const constrctTableData = (data = []) => {
    if (!_isEmpty(data)) {
      let pvpRowData = [];
      data.map((item, i) => {
        pvpRowData.push({
          period: item.year,
          sales:item.yearlySales,
          rebate:item.rebateEarnedAmount,
          rebates:item.rebatesEarnedCount,
          weighted_earning:item.weightedEarning,
          index:i,
        });
      });
      setTableData(pvpRowData);
    }
  };

  const columns=[
    {
        dataField: 'period',
        text: 'Period',
        headerStyle: headerStyle,
        align: 'center',
        headerAlign: 'center',
    },
    {
        dataField: 'sales',
        text: 'Sales',
        headerStyle: headerStyle,
        classes:"pvp-rightalign",
        align: 'center',
        headerAlign: 'center',
        formatter:(cell,row,index)=>{
            return <span>{`${currencyFormatter(cell)}`}</span>
        }
    },
    {
        dataField: 'rebate',
        text: 'Rebate Earned',
        headerStyle: headerStyle,
        classes:"pvp-rightalign",
        align: 'center',
        headerAlign: 'center',
        formatter:(cell,row,index)=>{
          return <span>{`${currencyFormatter(cell)}`}</span>
      }
    },
    {
        dataField: 'rebates',
        text: 'Rebates Earned',
        headerStyle: headerStyle,
        align: 'center',
        headerAlign: 'center',
        formatter:(cell,row,index)=>{
          return <span>{cell}</span>
      }
    },
    {
        dataField: 'weighted_earning',
        text: 'Weighted Earning',
        headerStyle: headerStyle,
        align: 'center',
        headerAlign: 'center',
        formatter:(cell,row,index)=>{
          return <span>{`${currencyFormatter(cell).replace('$','')}%`}</span>
        }
    },
  ]

  const noDataState = () => {
    return (
      <p align="center" style={{ padding: "0", margin: "0" }}>
        No Data Found
      </p>
    );
  };

  return (
    <BootstrapTable
    classes='pvp-table-styles'
    keyField="month"
    data={tableData}
    columns={columns}
    noDataIndication={noDataState}
    />
  )
}

export default ThirdTable