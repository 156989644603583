
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _find from 'lodash/find';
import _isEmpty from "lodash/isEmpty";

function ShipInfoHeader({ shipMethod, storeName }) {
  const userDetails = useSelector((state) => state.user.userDetails);
  const checkoutDetails = useSelector((state) => state.cart.checkoutDetails);

 const shippingMethod = shipMethod ? shipMethod : (!_isEmpty(checkoutDetails) ? checkoutDetails[0]
    .shipMethod : userDetails?.shipTos[0]?.shipMethod);
 const location = storeName ? storeName : (checkoutDetails &&
    checkoutDetails.length > 0 &&
    checkoutDetails[0].store ? checkoutDetails[0].store : userDetails?.shipTos[0]?.storeNumber);

	return (	
            <div className="ship-info-header  maroonColorTxt">
              <span >
                Ship Method:<span className="text-black">{shipMethod && shipMethod != null && shipMethod == "0002" ? " Pickup " : (shippingMethod == "FIWS" ? " Drop Ship ":" Delivery ")}
                </span> 
                From TPH Parts Location : <span className="text-black">{parseInt(location, 10)}</span>
              </span>
            </div>
          		
	);
}

export default ShipInfoHeader;

