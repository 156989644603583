import { createAsyncThunk } from "@reduxjs/toolkit";
import _isEmpty from "lodash/isEmpty";
import axios from "axios";
import { 
  getReturnList ,
  createOrUpdateStore , 
  getInvoiceStoreData ,
  getPartListForStoreDashBoard,
  getAllManager,
  getUserRoles
} from "./storeDashboard.service";

export const fetchReturn  = createAsyncThunk(
    "fetchReturn",
    async (data) => {
      const response = await getReturnList(data);
      return response.data;
    }
  );

  export const createOrUpdateStoreUser = createAsyncThunk(
    "createOrUpdateStoreUser",
    async (payload, { signal, rejectWithValue }) => {
      const source = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      try {
        const response = await createOrUpdateStore(payload, {
          cancelToken: source.token,
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
  }

  );

  export const getInvoiceStoreDetails = createAsyncThunk(
    "getInvoiceStoreDetails",
    async (payload) => {
      const response = await getInvoiceStoreData(payload);
      return response.data;
    }
  );

  export const getStockProductList = createAsyncThunk(
    "getStockProductList",
    async (payload) => {
      const response = await getPartListForStoreDashBoard(payload);
      let modifiedResponse = {
        responseData: response.data,
        skuIndex: payload.skuIndex,
      };
      const responseArr = [];
      if (!_isEmpty(response) && !_isEmpty(response.data)) {
        response.data.map((product) => {
          if ( product.status === "8" || product.status === "1" || product.status === "6" || product.status === "3"  ) {
            responseArr.push(product);
          }
        });
      }
      modifiedResponse.responseData = responseArr;
      return modifiedResponse;
    }
  );

  export const getAllStoreNumberForManager  = createAsyncThunk(
    "getAllStoreNumberForManager",
    async (data) => {
      const response = await getAllManager(data);
      return response.data;
    }
  );

  export const  getAllUserRoles  = createAsyncThunk(
    "getAllUserRoles",
    async (data) => {
      const response = await  getUserRoles(data);
      return response.data;
    }
  );