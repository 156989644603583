import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getStatementsUrl , getCreditUrl,getStatementsChangedUrl} from "../mygarage.thunks";
import { sendUtilServiceEmail } from "../../../../helpers/email/email.thunks";
import { Form, Spinner, Col } from "react-bootstrap";
import { isInputBlank, timeInUTC } from "../../../../helpers/utils";
import XModal from "../../../shared/XModal";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import moment from "moment-timezone";
import FormValidationErrorMessage from "../../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import { triggerCustomUxCriticalError } from "../../../../actions/error";
import "./styles.scss";
import { programsStatementDetails } from '../../../../components/ProgramAndBenefits/programbenefits.thunk';
import QueryString from "query-string"
import { useLocation } from "react-router-dom";
import { getAllCustomers } from '../../../User/UserManagement/usermanagement.thunk';
import { getShipToIds } from '../../../../actions/testsms';
import Select from 'react-select';
import { Button } from 'ui-library';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}
const actionTypes = { GLOBAL_LOADER: 'GLOBAL_LOADER' };

const Statements = (props) => {
  const initializeValidity = () => {
    const validity = {
      isEmailValidFormat: true,
      isEmailNotNull: true,
      isAllDisclosuresAccepted: false,
    };
    return validity;
  };
  const params = useQuery();
	const customerList = useSelector((state) => state.userManagement.customerList)
  const dispatch = useDispatch();
  const userDetailsArr = useSelector((state) => state.user);
  let userType = "";
  if (!_isEmpty(userDetailsArr)) {
    userType = userDetailsArr.userDetails.userType;
  }
  let email =
    !_isEmpty(userDetailsArr) &&
    userDetailsArr.userDetails &&
    userDetailsArr.userDetails.x2userName
      ? userDetailsArr.userDetails.x2userName
      : "";
  let defaultVal = !_isEmpty(userDetailsArr) && userDetailsArr.userDetails && userDetailsArr.userDetails.billTo.billToId
    ? userDetailsArr.userDetails.billTo.billToId
    : '';
  const [iframeUrl, setIframeUrl] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [accountNumber, setAccountNumber] = useState('');
  const [consentEmailId, setConsentEmailId] = useState("");
  const [selectedBillTo, setSelectedBillTo] = useState("");
	const [changedBillTo, setChangedBillTo] = useState("");
	const [changedBillToName, setChangedBillToName] = useState([]);
	const [changedBillToNameText, setChangedBillToNameText] = useState([]);
  const [validity, setvalidity] = useState(initializeValidity());
  const [disclosuresAccepted, setDisclosuresAccepted] = useState({
    discontinue: false,
    eStatement: false,
    voluntary: false,
  });
  const [showGoPaperlessPopup, setShowGoPaperlessPopup] = useState(false);
  const stmtsRef = useRef(null);
  const location = useLocation();
  const parts = useSelector((state) => state.parts);
	const programUrl = parts && parts.programUrl;
  const emailRegex = new RegExp(
    /^[a-zA-Z0-9]+([\w\.\+\-\_])*([a-zA-Z0-9])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,}$/
  );
  const getStmtsUrlPrgBenefits = () => {
		if (programUrl) {
			const requestItems = {
				year: params.get('year'),
				month: params.get('month')
			};
			const payload = { requestItems, params: programUrl };
			try {
				dispatch(programsStatementDetails(payload)).then((action) => {
					if (action.payload && action.payload.url) {
						setShowLoader(false);
						setIframeUrl(action.payload.url);
						console.log('setIframeUrlPrgmsBenefits', iframeUrl);
					}
				});
			} catch (error) { }
		}
	};
  const getStmtsChangedUrl = (event) => {
		dispatch({
			payload: {
				globalLoader: true

			},

			type: actionTypes.GLOBAL_LOADER

		});
		let accountNum = [];
		if (userDetailsArr.userDetails.userType === "IT_ADMIN") {
			accountNum = accountNumber;
		}
		if (userDetailsArr.userDetails.userType === "SUPER_ADMIN") {
			accountNum = event.value;
			setChangedBillToName(event.label);

		}
		const payload = {
			dispatch: dispatch,
			accountNum: accountNum
		};
		try {
			const statementsChangedAction = dispatch(getStatementsChangedUrl(payload));
			statementsChangedAction.then((action) => {
				if (action.payload && action.payload.data && action.payload.data.url) {
					setShowLoader(false);
					setIframeUrl(action.payload.data.url);
					setAccountNumber(accountNum);
					setChangedBillTo(accountNum);

				}
			});
		} catch (error) { }
	};
  const getStmtsUrl = () => {
    const payload = {
      dispatch: dispatch,
    };
    try {
      const statementsAction = dispatch(getStatementsUrl(payload));
      statementsAction.then((action) => {
        if (action.payload && action.payload.data && action.payload.data.url) {
          setShowLoader(false);
          setIframeUrl(action.payload.data.url);
        }
      });
    } catch (error) {}
  };

  const getCreditsUrl = () => {
    const payload = {
      dispatch: dispatch
    };
    try {    
            const creditsActions = dispatch(getCreditUrl(payload));
            creditsActions.then((action) => {
              if (action.payload && action.payload.data && action.payload.data.url) {
                setShowLoader(false);
                setIframeUrl(action.payload.data.url);
              }
            });
        
    } catch (error) {}
  };
  
  useEffect(() => {
    dispatch({
        payload: {
            globalLoader: !showLoader
        },
        type: actionTypes.GLOBAL_LOADER
    });
  }, [showLoader]);  

  useEffect(() => {
    setShowLoader(true);
    let parsed = QueryString.parse(location.search);
    if (parsed.credit === 1 || parsed.credit === "1") {
      getCreditsUrl();
    } else if (!_isEmpty(accountNumber)) {
      getStmtsChangedUrl();
    } else if (programUrl) {
      getStmtsUrlPrgBenefits();
    } else {
      getStmtsUrl();
    }
  }, [location]);
  
  useEffect(() => {
    setConsentEmailId(email);
  }, []);

  const onStatementsLoad = () => {
		setShowLoader(false);
		dispatch({
			payload: {
				globalLoader: false
			},
        type: actionTypes.GLOBAL_LOADER
		});
		getBillToName();
	};

  useEffect(() => {
		if (userDetailsArr.userDetails.userType === "SUPER_ADMIN") {
			dispatch(getAllCustomers());
		}

	}, []);

	useEffect(() => {
		dispatch(getAllCustomers()).then((result) => {
			let billToOptions = createBillToOptions(result.payload);
			setSelectedBillTo(billToOptions);
		});
	}, []);

	const createBillToOptions = (customerList) => {
		let optionArr = [];
		if (!_isEmpty(customerList)) {
			customerList.map((billToList) => {
				optionArr.push({
					value: billToList.billtoId,
					label: billToList.billtoId + " - " + billToList.name,
				});
			});
		}
		return optionArr;
	};
	const getBillToName = () => {
		let name = [];
		const payload = {
			billtoid: accountNumber,
		};
		const statementsAction = dispatch(getShipToIds(payload));
		statementsAction.then((action) => {
			let shipToData = action;
			if (!_isEmpty(shipToData)) {
				shipToData.map((data) => {
					name = data.name
				});
				setChangedBillToNameText(name);
			}
			else {
				setChangedBillToNameText("Not Found");
			}

		});
	};
  const handlePartNoChange = (event) => {
		const accountNumber = event.target.value;
		setAccountNumber(accountNumber);
	}

	const handleKeyDown = (event) => {
		const accountNumber = event.target.value;
		if (
			event.key === " " ||
			(accountNumber &&
				accountNumber.length >= 20 &&
				event.keyCode !== 8 &&
				event.keyCode !== 9 &&
				event.keyCode !== 37 &&
				event.keyCode !== 38 &&
				event.keyCode !== 39 &&
				event.keyCode !== 40 &&
				event.keyCode !== 46 &&
				event.keyCode !== 17 &&
				event.keyCode !== 65)
		) {
			event.preventDefault();
		}
	};
  const handleEmailChange = (event) => {
    const value = !_isEmpty(event.target.value) ? event.target.value : email;
    setConsentEmailId(value);
    let validity = initializeValidity();
    validity.isEmailNotNull = !isInputBlank(value);
    validity.isEmailValidFormat = !_isEmpty(value)
      ? emailRegex.test(value)
      : true;
    validity.isAllDisclosuresAccepted = _every(disclosuresAccepted);
    setvalidity(validity);
  };

  const handleDisclosureCheck = (event) => {
    const isChecked = event.target.checked;
    const id = event.target.id;
    let disclosures = disclosuresAccepted;
    disclosures[id] = isChecked;
    setDisclosuresAccepted(disclosures);

    let validity = initializeValidity();
    validity.isEmailNotNull = !isInputBlank(consentEmailId);
    validity.isEmailValidFormat = !_isEmpty(consentEmailId)
      ? emailRegex.test(consentEmailId)
      : true;
    validity.isAllDisclosuresAccepted = _every(disclosures);
    setvalidity(validity);
  };

  const handleConsent = (event) => {
    const { shipTos, billTo } = userDetailsArr.userDetails || {};
    const customerName =
      userDetailsArr.userDetails && userDetailsArr.userDetails.billTo
        ? `${userDetailsArr.userDetails.billTo.name}`
        : "";
    const consentDate = moment
      .utc()
      .tz("America/Chicago")
      .format("MMMM Do YYYY, h:mm:ss");
    let emailContent = `Hi Team,<br><br>Customer <b>${billTo.billToId}</b>, <b>${customerName}</b> has submitted a request on RAPID to Go Paperless on ${consentDate} CST and have provided the emailID <a href = "mailto: ${consentEmailId}">${consentEmailId}</a> to receive the electronic Statements.<br>Please complete this request and follow up with the customer as needed.<br><br>Regards<br>RAPID IT Team`;
    const payload = {
      body: emailContent,
      fromName: "The Parts House ",
      subject: `Consent for Paperless Statements from Customer ${billTo.billToId}, ${customerName}`,
      toAddress: ["credit@xlparts.com", "AR@xlparts.com"],
    };
    if (_every(validity)) {
      const sendUtilServiceEmailAction = dispatch(
        sendUtilServiceEmail(payload)
      );
      sendUtilServiceEmailAction.then((action) => {
        console.log("email sent");
        setShowGoPaperlessPopup(false);
        setConsentEmailId(email);
        const statusMessageProps = {
          heading: "Success",
          message:
            "Your consent will be processed by the Accounts Receivable team.",
          type: "success",
        };
        triggerCustomUxCriticalError(statusMessageProps, dispatch);
      });
    }
  };
  //TODO update emailIds, invalid session should not refresh the url
  return (
    <div class="container">
      <div className="d-flex justify-content-between mt-1 mb-1">
        <div className="bread_crum_section_statements mt-1">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>Statements & Invoices</span>
            </li>
          </ul>
        </div>
        {!_isEmpty(userType) &&
          (userType === "CUSTOMER_ADMIN" || userType === "IT_ADMIN") && (
            <div class="mt-2">
              <button
                type="button"
                onClick={() => {
                  setShowGoPaperlessPopup(true);
                }}
                class="maroonBtn xl_parts_paperless_btn btn btn-primary btn-sm"
              >
                Go Paperless
              </button>
            </div>
          )}
      </div>
      <div className="label-input-container-inputBox">
        {!_isEmpty(userType) &&
          (userType === "IT_ADMIN") && (
            <div className="label-input-container-program">
              <label htmlFor="accountNumber">TPH Account# :</label>
              <Form.Control
                onChange={(e) => handlePartNoChange(e)}
                type="text"
                name="billToChange"
                id="billToChange"
                value={accountNumber}
                onKeyDown={handleKeyDown}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    getStmtsChangedUrl();
                  }
                }}
                maxLength={10} 
                />
              <div>
                {changedBillTo.length > 0 && iframeUrl.length > 0 && (
                  <div className="changed-billto">Customer : {`${changedBillTo} - ${changedBillToNameText}`}
                  </div>)}
              </div>
            </div>
          )}
      </div>
      <div className="label-input-container-inputBox custom_DropDown_billlTo">
        {!_isEmpty(userType) &&
          (userType === "SUPER_ADMIN") && (
            <>
              <label htmlFor="accountNumber">TPH Account# :</label>
              {selectedBillTo && (
                <Select
                  autoFocus={true}
                  name="changeBillTo"
                  options={!_isEmpty(selectedBillTo) && selectedBillTo}
                  placeholder="Select a BillTo"
                  onChange={getStmtsChangedUrl}
                  value={selectedBillTo.value}
                  defaultValue={selectedBillTo.filter(op => op.value === defaultVal)}
                  className="custom-dropdown_statement custom-dropdown_statementInvoice"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#84bae2",
                      primary50: "#0F5283",
                      primary: "#0F5283",
                    }
                  })} 
                />
              )}
              {/* {changedBillToName.length > 0 && iframeUrl.length > 0 && (
								<div className="changed-billto">Customer: {` ${changedBillToName}`}</div>)} */}
            </>

          )}
      </div>
      <div>
      {showLoader ?
						(
							<div className="custom_spinner_container">
								<Spinner className="custom_spinner" animation="border" />
							</div>
						) :    
            <iframe
          src={iframeUrl}
          // frameborder="0"
          width="100%"
          height="550"
          // ref={stmtsRef}
          onLoad={onStatementsLoad}
        ></iframe>
        }
       
        {showGoPaperlessPopup && (
          <XModal
            show={showGoPaperlessPopup}
            handleClose={() => {
              setShowGoPaperlessPopup(false);
              setConsentEmailId(email);
            }}
            handleAction={handleConsent}
            title="Go Paperless"
            className="consent_xmodal"
            isValidValidity={_every(validity)}
            yesButtonText="I Consent"
            noButtonText="Cancel"
          >
            <div class="container">
              <p className="paperless bold">Go Paperless</p>
              <p className="paperless_p">
                Save paper, stay organized. Get electronic Statements in your
                email instead of paper statements.
              </p>
              <p className="paperless_p">
                Please update the email ID to which the e-Statements are to be
                sent.
              </p>
              <Col md={4} className="mb-3 select-container">
                <Form.Control
                  placeholder={consentEmailId}
                  className="input__control"
                  type="text"
                  name="consentEmail"
                  onBlur={handleEmailChange}
                />
              </Col>
              <FormValidationErrorMessage
                condition={!validity.isEmailNotNull}
                errorMessage="Please enter the email"
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isEmailValidFormat && validity.isEmailNotNull
                }
                errorMessage="email must be in username@domainname.com format"
              />
              <p className="paperless_p">
                We'll start sending you statements via email once you choose "I
                Consent". Please allow our Accounts Receivable team 48 hours to
                complete your request.
              </p>

              <p className="paperless_p">
                <Form.Check
                  type="checkbox"
                  label='I understand that pursuant to my account payment terms with The Parts House LLC, I am entitled to receive periodic account statements in connection with my account, ("Account Statements"). By indicating my acceptance of these terms and conditions, I authorize The Parts House LLC to discontinue sending me statements via postal mail service for my account and to instead send them to me electronically (eStatements). I understand that once I accept the online agreement The Parts House LLC will send me an eStatement on an ongoing basis (BASED ON PAYMENT TERMS) to my email address above notifying me the eStatement(s) are ready to be viewed. I understand and agree that I will no longer receive paper copies of my account statements.'
                  id="discontinue"
                  name="disclosure1"
                  onChange={(e) => {
                    handleDisclosureCheck(e);
                  }}
                />
              </p>

              <p className="paperless_p">
                <Form.Check
                  type="checkbox"
                  label="I understand that once I accept the online agreement The Parts House LLC will send me an eStatement on an ongoing basis (BASED ON PAYMENT TERMS) to my email address above notifying me the eStatement(s) are ready to be viewed."
                  id="eStatement"
                  name="disclosure2"
                  onChange={(e) => {
                    handleDisclosureCheck(e);
                  }}
                />
              </p>

              <p className="paperless_p">
                <Form.Check
                  type="checkbox"
                  label={
                    <>
                      I understand My ongoing consent to receive subsequent
                      account statements electronically is voluntary and may be
                      withdrawn at any time. I may withdraw consent at any time
                      in writing by sending The Parts House LLC an email to{" "}
                      <a className="link" href="mailto:accounting@thepartshouse.com">
                         accounting@thepartshouse.com
                      </a>
                      , such withdrawal shall become effective no later than
                      fifteen (15) days after receipt by The Parts House LLC.
                    </>
                  }
                  id="voluntary"
                  name="disclosure3"
                  onChange={(e) => {
                    handleDisclosureCheck(e);
                  }}
                />
              </p>

              <FormValidationErrorMessage
                condition={!validity.isAllDisclosuresAccepted}
                errorMessage="Please select all above Disclosures to proceed."
              />
            </div>
          </XModal>
        )}
      </div>
    </div>
  );
};

export default Statements;
