import React, { useEffect, useState } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import T from "prop-types";
import { Link } from "react-router-dom";
import "./styles.scss";
import PaginationComponent from "../../../components/shared/Pagination";
import { useDispatch, useSelector } from "react-redux";
// import { data } from "./data";
import _isEmpty from "lodash/isEmpty";
import BootstrapTable from "react-bootstrap-table-next";
import { Button } from "ui-library";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { getPreviousLookups } from "../c_and_k.thunk";
import { getReviewCKSearchData, getQuoteUniversal } from "../c_and_k.thunk";
import { currencyFormatter } from "helpers/utils";
import __startCase from "lodash/startCase";
import { getVehicleByVinNumber } from "../../../actions/search";

const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const headerStyle = {
  backgroundColor: "#0F5283",
  color: "#FFFFFF",
};
function PreviousLookUps(props) {
  const initializeValidity = () => {
    const validity = {
      isSearchAlphanumeric: true,
      isSearchMinLimit: true,
    };
    return validity;
  };
  const dispatch = useDispatch();
  const [tableColumns, setTableColumns] = useState([]);
  const [TableData, setTableData] = useState([]);
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElemets] = useState(null);
  const [pageLength, setPageLength] = useState(null);
  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;
  const shipTo =
    (userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos.length > 0 &&
      userDetails.shipTos[0]) ||
    {};

  useEffect(() => {
    if (
      !_isEmpty(userDetails) &&
      !_isEmpty(userDetails.billTo) &&
      !_isEmpty(userDetails.billTo.billToId)
    ) {
      const payload = {
        pagination: {
          pageNumber: 0,
          pageSize: pageSize,
        },
        billToId: userDetails.billTo.billToId,
      };
      dispatch({
        payload: {
          globalLoader: true,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
      const response = dispatch(getPreviousLookups(payload));
      response.then((action) => {
        if (action.payload) {
          const myData = action.payload;
          setPageLength(myData?.ckQuoteDetails.length);
          constructGridData(myData);
          setData(myData);
          setTotalPages(myData?.totalPages);
          setTotalElemets(myData?.totalElements);
          dispatch({
            payload: {
              globalLoader: false,
            },
            type: actionTypes.GLOBAL_LOADER,
          });
        }
      });
    }
  }, [userDetails]);

  const history = useHistory();

  const handlePageChange = (clickedPage) => {
    setCurrentPage(
      clickedPage > totalPages ? 1 : clickedPage === 0 ? 1 : clickedPage
    );
    if (
      !_isEmpty(userDetails) &&
      !_isEmpty(userDetails.billTo) &&
      !_isEmpty(userDetails.billTo.billToId)
    ) {
      const payload = {
        pagination: {
          pageNumber:
            clickedPage > totalPages
              ? 0
              : clickedPage === 0
              ? 0
              : clickedPage - 1,
          pageSize: pageSize,
        },
        billToId: userDetails.billTo.billToId,
      };
      dispatch({
        payload: {
          globalLoader: true,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
      const response = dispatch(getPreviousLookups(payload));
      response.then((action) => {
        if (action.payload) {
          const myData = action.payload;
          setPageLength(myData?.ckQuoteDetails.length);
          constructGridData(myData);
          setData(myData);
          dispatch({
            payload: {
              globalLoader: false,
            },
            type: actionTypes.GLOBAL_LOADER,
          });
        }
      });
    }
  };

  const redirectToRemanCatalog = (row = "") => {
    if (!_isEmpty(row)) {
      const payload = {
        vin: row.vin,
        currentMileage: row.currentMileage.toString(),
        address: {
          zipCode: shipTo.zip,
          stateCode: shipTo.state,
          countryCode: shipTo.country,
        },
        parts: [
          {
            part: {
              searchType: row.searchType,
            },
            quantity: 1,
          },
        ],
      };
      dispatch(getReviewCKSearchData(payload));
      dispatch(getQuoteUniversal(payload));
      getVehicleByVinNumber(row.vin, userDetails, dispatch);

      history.push(`/ck_partsearch?page=${row.searchType}`);
    }
  };

  const constructGridData = (data) => {
    setTableColumns([]);
    setTableData([]);

    const rowData = [];
    if (!_isEmpty(data)) {
      data?.ckQuoteDetails?.map((availDetails, index) => {
        rowData.push({
          // quoteId: availDetails.quoteId,
          // partNumber: availDetails.partNumber,
          searchType: availDetails.searchType,
          creationDate: availDetails.creationDate,
          vin: availDetails.vinNumber.toUpperCase(),
          currentMileage: availDetails.currentMileage,
          partType: __startCase(availDetails.searchType),
          // expiryDate: availDetails.expirationDate,
          // yourPrice: availDetails.amount,
          // corePrice: availDetails.corePrice,
          // listPrice: availDetails.listPrice,
          index: index,
        });
      });
    }

    const columns = [
      {
        dataField: "quoteId",
        hidden: true,
      },
      {
        dataField: "partNumber",
        hidden: true,
      },
      {
        dataField: "searchType",
        hidden: true,
      },
      {
        dataField: "creationDate",
        text: "Creation Date",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        width: "80",
        formatter: (cell, row) => {
          return (
            <div>
              <Moment date={row.creationDate} format="MM/DD/YYYY" />
            </div>
          );
        },
      },
      {
        dataField: "expiryDate",
        hidden: true,
      },
      {
        dataField: "vin",
        text: "VIN",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "corePrice",
        hidden: true,
      },
      {
        dataField: "listPrice",
        hidden: true,
      },
      {
        dataField: "yourPrice",
        hidden: true,
      },
      {
        dataField: "partType",
        text: "Part Type",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "action",
        text: "Action",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <span>Action</span>
              &nbsp;&nbsp;
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 400 }}
                overlay={
                  <Tooltip
                    id="extended-price-tooltip"
                    className="tooltip-description-custom-corePrice"
                  >
                    <span className="font-weight-bold">
                      Information may have changed since quote. To see the
                      latest quote information, please click requote.
                    </span>
                  </Tooltip>
                }
              >
                <a className="whiteColorTxt info-icon">
                  <i class="fa fa-info-circle"></i>
                </a>
              </OverlayTrigger>
            </div>
          );
        },
        formatter: function (cell, row) {
          return (
            <>
              <div>
                <Button
                  variant="contained"
                  className="t-search-go"
                  size="small"
                  onClick={() => redirectToRemanCatalog(row)}
                >
                  Requote
                </Button>
              </div>
            </>
          );
        },
        sort: true,
      },
    ];
    setTableColumns(columns);
    setTableData(rowData);
  };

  return (
    <React.Fragment>
      <div className="container previous-lookups-container-padding">
        <div className="bread_crum_section_payment mt-1">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <a href="/ck_partsearch?page=Engine">
                <span>Reman Catalog</span>
              </a>
            </li>
          </ul>
        </div>

        <br></br>

        <div className="previous-lookup-header-style">
          <span>previous lookups</span>
        </div>
        <Row className="previous-lookups-pagination-align-style">
          <Col
            md="4"
            className="mygarage_pagination_wrapper previous-lookups-pagination-style-top"
          >
            <div
              style={{
                marginTop: "3px",
                marginRight: "26px",
                whiteSpace: "nowrap",
              }}
            >
              <p>
                <span>
                  {" "}
                  <span style={{ fontSize: "15px", marginRight: "5px" }}>
                    showing{" "}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {pageLength < pageSize ? pageLength : pageSize}{" "}
                  </span>
                </span>{" "}
                <span style={{ fontWeight: "bold" }}>
                  Parts / <span> {totalElements} </span>
                </span>
              </p>{" "}
            </div>
            <PaginationComponent
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          </Col>
        </Row>
        <React.Fragment>
          <div>
            {TableData.length > 0 && tableColumns.length > 0 ? (
              <Col sm={12}>
                <Row className="table-row">
                  <Col>
                    <BootstrapTable
                      keyField="specDesc"
                      classes="previous-lookups-table-data-padding"
                      data={TableData}
                      columns={tableColumns}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              <div></div>
            )}
          </div>
          <Row className="previous-lookups-pagination-align-style">
            <Col
              md="4"
              className="mygarage_pagination_wrapper previous-lookups-pagination-style-bottom"
            >
              <PaginationComponent
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </Col>
          </Row>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}

PreviousLookUps.propTypes = {};

export default PreviousLookUps;
