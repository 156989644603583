import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _each from "lodash/each";
import _find from "lodash/find";
import _every from "lodash/every";
import _uniqBy from "lodash/uniqBy";
import _cloneDeep from "lodash/cloneDeep";
import {Button,Container,Spinner,} from "react-bootstrap";
import { uploadPartList,getStockProductListFromSkuNumber,getMagicalWordsList,} from "./stockcheck.thunk";
import {resetProductList,resetPageContent,updateSelectedStockList,updateLoaderStatus,updateStockList,} from "./stockcheck.slice";
import {
  getImageURLCookieDetails,
  getShipInfo,
  timeInUTC,
  getCartShipDetails,
  setCookie,
  getCookie
} from "../../helpers/utils";
import { logUserStockActivity } from "../../actions/user";
import _head from "lodash/head";
import PromotionsTab from "../shared/Promotions";
import clsx from "clsx";
import XModal from "../shared/XModal";
import { useLocation } from "react-router-dom";
import PartsTable from "./PartsTable";
import "./styles.scss";
import MagicWord from "./MagicWord";
import ShipInfoHeader from "components/shared/ShipInfoHeader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function StockCheck(props) {
  // let stockDetails = useSelector( (state) => state.stockCheck.selectedProductList);
  let stockDetails = useSelector( (state) => state.stockCheck.selectedStockList);
  let stockList = useSelector((state) => state.stockCheck.stockList);
  let showLoader = useSelector((state) => state.stockCheck.showLoader);
  let productList = useSelector((state) => state.stockCheck.productList);
  let CartStatus = useSelector((state) => state.cart);
  let checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  const [resetError, setresetError] = useState(false);
  const [stockCheckStatus, setstockCheckStatus] = useState(true);
  const dispatch = useDispatch();
  const [Showpopup, setShowpopup] = useState(false);
  const userPromotionDetails = useSelector((state) => state.user.userPromotionDetails);
  const [warning, setWarning] = useState(false);
  const viewPromotions = useSelector((state) => state.app.viewPromotions);
  const userDetails = useSelector((state) => state.user.userDetails);
  const parts = useSelector((state) => state.parts);
  const cartShipMethod = useSelector((state) => state.parts.cartShipMethod);
  const cartStore = useSelector((state) => state.parts.cartStore);
  const [multiPartValue, setMultiPartValue] = useState("");
  const [stockCheckShowLoader, setStockCheckShowLoader] = useState(false);
  const shipInfo = getCartShipDetails(userDetails, checkoutDetails);
  const [shipInfoForMagic, setShipInfoForMagic] = useState(shipInfo);
  let singleItem = [];
  let { rows } = props;

  useEffect(() => {
    dispatch(getMagicalWordsList())
    const viewPromotions = getCookie('viewPromotions') || "true";
    if(viewPromotions === "false") {
      dispatch({
        payload: {},
        type: "COLLAPSE_PROMOTIONS_TAB",
      });
    }
  }, []);
  useEffect(() => {
    return () => {
      dispatch(resetPageContent());
    };
  }, []);

  useEffect(() => {
    if (CartStatus.addToCartError === false && !resetError) {
      dispatch(resetPageContent());
      setresetError(true);
    }
  }, [CartStatus]);

  useEffect(() => {
    if (!_isEmpty(stockList)) {
      let status = _every(stockList, {
        searchStatus: false,
        error: "",
        SkuNumber: "",
      });
      if (status) {
        setstockCheckStatus(true);
      } else {
        setstockCheckStatus(false);
      }
    }
  }, [stockList]);

  const handleUploadFile = (event) => {
    dispatch(resetProductList());
    const formData = new FormData();
    formData.append("file", event.target.files[0], event.target.files[0].name);
    let fileExtension = event.target.files[0].name.split(".").pop();
    if (fileExtension === "xlsx" || fileExtension === "xls") {
      dispatch(uploadPartList(formData)).then(() => {
        setShowpopup(false);
      });
    }
  };

  const warningMsg =(magicData)=>{
    magicData.map((p) => {
      !_isEmpty(stockDetails) && stockDetails.map((v) => {
        if (p.partNum === v.partNum) {
          setWarning(true);
        }
      });
    });
  }
  const handleSelectedPart = (magicData) => {
    if (!_isEmpty(magicData)) {
      let seletedProductArry = [];
      seletedProductArry = _cloneDeep(stockDetails);
      seletedProductArry.push(...magicData);
      singleItem = _uniqBy(seletedProductArry, "partNum");
      dispatch(updateSelectedStockList(singleItem));
    }
    warningMsg(magicData);
  };

  const handleStockCheckMultiPartButton = () => {
    setStockCheckShowLoader(true);
    let stockcheckdata = [];
    let multiArray = [];
    let lines = !_isEmpty(multiPartValue) && multiPartValue.split("\n");
    !_isEmpty(lines) &&
      lines.map((multiPartNumb) => {
        if (!_isEmpty(multiPartNumb)) {
          multiArray.push({
            mfgCode: "*",
            partNum: multiPartNumb,
            qty: 1,
          });
        }
      });
    const payload = {
      request: {
        requestItems: multiArray,
        sellPartnerId: shipInfo.sellerPartnerId ? shipInfo.sellerPartnerId : "",
        shipMethod: shipInfo.shipMethod ? shipInfo.shipMethod : "",
        store: shipInfo.storeNumber ? shipInfo.storeNumber : "",
        dispatch: dispatch,
      },
      uniqId: Math.random(),
    };

    dispatch(getStockProductListFromSkuNumber(payload)).then((result) => {
      if (result.type == "getStockProductListFromSkuNumber/fulfilled") {
        stockcheckdata.push(result.payload.partsNotFoundData);
        setStockCheckShowLoader(false);
        handlePrepareMultiParts(stockcheckdata);
      }
    });

    return stockcheckdata;
  };

  const handlePrepareMultiParts = (multiParts) => {
    const multipartArr = [];
    const multipartArr1 = [];
    let seletedProductArry = [];
    multipartArr.length = 0;
    !_isEmpty(multiParts) &&
      multiParts.map((part) => {
        part.map((p) => {
          if (p.status != "2") {
            multipartArr.push({
              status: p.status,
              isSub: p.status === "3" ? true : false,
              isAlt: p.status === "6" ? true : false,
              mfgCode: p.mfgCode,
              inValidPartData: p.status === "2" ? true : false,
              partNum: p.partNum,
              itemSpecific: p.itemSpecific,
              costPrice: p.costPrice,
              listPrice: p.listPrice,
              uniqId: Math.random(),
              availability: p.availability,
              availabilityAll: p.availability,
              qtyBuyInc: p.qtyBuyInc,
              corePrice: p.corePrice,
              coreListPrice: p.coreListPrice,
              selectedAvl: null,
              qty:
                (p.availability.length > 0) & (p.qtyBuyInc > 1)
                  ? p.qtyBuyInc
                  : p.availability.length > 0
                  ? 1
                  : "",
              addToOrder: p.availability.length > 0 ? true : false,
              totalAvailable: p.totalAvailable,
            });
          }
          if (p.status == "2") {
            multipartArr1.push({
              ...p,
              inValidPartData: p.status === "2" ? true : false,
            });
          }
        });
      });
    let multiPartData = multipartArr.concat(multipartArr1);
    seletedProductArry = _cloneDeep(stockDetails);
    seletedProductArry.push(...multiPartData);
    singleItem = _uniqBy(seletedProductArry, "partNum");
    dispatch(updateSelectedStockList(singleItem));
    setStockCheckShowLoader(false);
    setShowpopup(false);
    setMultiPartValue("");
  };
  const getMagicWordShipData = (shipDataFromtable) => {
    setShipInfoForMagic(shipDataFromtable);
  }

  const handleStockList = async () => {
    console.log("called stock list", stockList);
    const stockListData = stockList;
    let payload = {};
    let partSkus = [];
    setstockCheckStatus(true);
    await _each(stockListData, (val, key) => {
      if (!_isEmpty(val.SkuNumber)) {
        const tempArr = _find(productList, (part) => part.reqPartNum === key);
        payload = {
          request: {
            requestItems: [
              {
                mfgCode: !_isEmpty(val.mfgCode) ? val.mfgCode : "*",
                partNum: val.SkuNumber,
                qty: val.Qty ? val.Qty : 1,
              },
            ],
            sellPartnerId: shipInfo.sellerPartnerId
              ? shipInfo.sellerPartnerId
              : "",
            shipMethod: shipInfo.shipMethod ? shipInfo.shipMethod : "",
            store: shipInfo.storeNumber ? shipInfo.storeNumber : "",
            // dispatch: dispatch,
          },
          uniqId: stockDetails.length,
          skuIndex: key,
        };
        dispatch(updateLoaderStatus(true));
        dispatch(getStockProductListFromSkuNumber(payload)).then((result) => {
          dispatch(updateLoaderStatus(false));
          if (!_isEmpty(result)) {
            const tempObj = {
              Qty: "",
              mfgCode: "",
              index: key,
              SkuNumber: "",
              searchStatus: true,
              error: "",
            };
            if (_isEmpty(result.payload.responseData)) {
              tempObj.Qty = val.Qty;
              tempObj.mfgCode = val.mfgCode;
              tempObj.SkuNumber = val.SkuNumber;
              tempObj.error = "No data";
              tempObj.searchStatus = false;
            } else if (
              !_isEmpty(result.payload.responseData) &&
              result.payload.responseData.length > 1
            ) {
              tempObj.Qty = val.Qty;
              tempObj.mfgCode = val.mfgCode;
              tempObj.SkuNumber = val.SkuNumber;
              tempObj.searchStatus = false;
            }
            stockList = {
              ...stockList,
              [key]: tempObj,
            };
            dispatch(updateStockList(stockList));
          }
        });
        partSkus.push(val.SkuNumber);
      }
    });

    var allPartSkus = partSkus.join();
    let {
      billTo: { billToId: customerId },
      shipTos,
      x2userName: userName,
    } = userDetails;
    let loginId = "";
    dispatch(
      logUserStockActivity(
        timeInUTC(),
        customerId,
        loginId,
        shipTos[0].shipToId,
        "STOCK_CHECK",
        allPartSkus,
        userName
      )
    );
  };

  return (
    <div 
    className={clsx("stock_check_promotion_wrapper", {
      "promotions-active stock_checkWidth": viewPromotions,
    })}
    
    >
      <Container fluid className="stock_check_container">
        <div className="stock_check_wrapper">
          <div className="bread_crum_section">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <span>Stock Check</span>
              </li>
            </ul>
          </div>
          <div className="stoc_check_header">
            <span>stock check</span>
          </div>
          <div className="stock_check_btn_container">
            {/* {userDetails &&
            userDetails.shipTos &&
            userDetails.shipTos.length > 0 &&
            userDetails.shipTos[0].shipMethod &&
            userDetails.shipTos[0].shipMethod ? (
              <div className="wc-ship-info mx-auto">
                <span>
                  Ship Method:
                  <span className="text-black">
                    {cartShipMethod && cartShipMethod != null
                      ? cartShipMethod == "WC"
                        ? " Pickup "
                        : " Delivery "
                      : userDetails.shipTos[0] &&
                        userDetails.shipTos[0].shipMethod === "WC"
                      ? " Pickup "
                      : " Delivery "}{" "}
                  </span>
                  From XL Parts Location :{" "}
                  <span className="text-black">
                    {cartStore ? cartStore : userDetails.shipTos[0].storeNumber}
                  </span>
                </span>
              </div>
            ) : (
              ""
            )} */}
            <div className="wc-ship-info mx-auto">
            <ShipInfoHeader
                shipMethod={shipInfoForMagic?.shipMethod}
                storeName={shipInfoForMagic?.storeNumber}
              />
            </div>

          <MagicWord
          handleSelectedPart={handleSelectedPart}
          sourceName ="stockCheck"
          shipInfoForMagic={shipInfoForMagic}
          checkoutDetails={checkoutDetails}
          />

            {warning && (
              <XModal
                show={warning}
                title={"Parts in StockCheck"}
                className="warningpopup"
                favoriteTextValidity={true}
                submitType={true}
                handleAction={() => setWarning(false)}
                handleClose={() => setWarning(false)}
                submitButtonText="OK"
              >
                <div className="return-confirm">
                  Some parts already exit in stockcheck{singleItem.partNum}
                </div>
              </XModal>
            )}
            <div className="import_part_list_conatiner">
              <Button onClick={() => setShowpopup(true)}>
                Stock Check Multiple Parts
              </Button>
              {Showpopup && (
                <div className="import_part_list_popup">
                  <div className="import_part_list_header">
                    <span>Stock Check Multiple Parts</span>
                    <div
                      className="close-btn"
                      onClick={() => setShowpopup(false)}
                    >
                      <span className="material-icons">close</span>
                    </div>
                  </div>
                  <span className="stock-check-textarea-msg">
                    <br></br>Enter individual part numbers one per line{" "}
                    <br></br> in below text box and click OK{" "}
                  </span>
                  <div className="stock_check_textarea">
                    <textarea
                      id="textArea"
                      rows="4"
                      cols="45"
                      style={{ overflowY: "scroll", height: "150px" }}
                      onChange={(event) =>
                        setMultiPartValue(event.target.value)
                      }
                      value={multiPartValue}
                    ></textarea>
                  </div>
                  <div className="choose_action_container">
                    <span>Import file containing parts list</span>
                    <div className="import_file_section">
                      <input
                        type="file"
                        onChange={handleUploadFile}
                        accept=".xlsx, .xls"
                      />
                    </div>
                    <a
                      href={`${
                        process.env.REACT_APP_STOCK_IMPORT_TEMPLATE
                      }?${getImageURLCookieDetails()}`}                     
                    >
                      Download template
                    </a>
                  </div>

                  <div className="stock_check_button">
                    {stockCheckShowLoader ? (
                      <Button
                        className="user-management-submit-btn"
                        variant="primary"
                        disabled
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading..
                      </Button>
                    ) : (
                      <Button
                        style={{ width: "100px" }}
                        className="user-management-submit-btn"
                        onClick={handleStockCheckMultiPartButton}
                        disabled={_isEmpty(multiPartValue)}
                      >
                        ok
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Container fluid className="stock_check_table">
            <PartsTable
              handleStockList={handleStockList}
              getMagicWordShipData={getMagicWordShipData}
            />

          </Container>
          {showLoader && (
            <div className="custom_spinner_container">
              <Spinner className="custom_spinner" animation="border" />
            </div>
          )}
        </div>
        <PromotionsTab
          showSidebarPopUp={viewPromotions}
          promotions={userPromotionDetails}
          handlePromotions={() => {
            setCookie('viewPromotions', !viewPromotions, 365)
            dispatch({
              payload: {},
              type: "VIEWPROMOTIONS_TOGGLE",
            });

            if (!viewPromotions) {
              if (document.querySelector(".section-wrapper")) {
                document.querySelector(".section-wrapper").style.marginLeft =
                  "250px";
              }

              if (document.querySelector(".section__banner")) {
                document.querySelector(".section__banner").style.marginLeft =
                  "250px";
              }
            } else {
              if (document.querySelector(".section-wrapper")) {
                document.querySelector(".section-wrapper").style.marginLeft =
                  "0";
              }

              if (document.querySelector(".section__banner")) {
                document.querySelector(".section__banner").style.marginLeft =
                  "0";
              }
            }
          }}
        />
      </Container>
    </div>
  );
}

export default StockCheck;
