import { createSlice } from "@reduxjs/toolkit";
import _isEmpty from "lodash/isEmpty";

import {
  updateUserDetails,
  getUsersList,
  getCompanyList,
  deleteUserEmail,
  getModifyUserDetails,
  getUserPreferenceDetails,
  updateUserPreferenceDetails,
  updateBillTo,
  getAllCustomers,
  deleteUserEmailOSR,
  impersonateUserBillTo,
  getPartImageURLCookie,
  getDriverList,
  updateDriverDetails,
  getCompanyDataBasedOnCompanyAdmin,
  modifyCompany,
  getDelveryPickupData,
  getUserPgmAccess,
  getAllAccountConfigInfo,
  getDSWAccounts
} from "./usermanagement.thunk";

let initialState = {
  UserUpdate: "",
  usersList: [],
  customerList:[],
  OSRDeleted:'',
  userDeleted: "",
  billToStatus: "",
  selectedModifyUser: [],
  userPreferenceDetail: [],
  driverList: [],
  companyData: [],
  companyupdated: [],
  isUpdating: false,
  refreshModifyUser: 0,
  refreshDeleteUser: 0,
  allStores: [],
  accounts: [],
};

const userManagement = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    resetData(state) {
      state.billToStatus = "";
      state.OSRDeleted = '';
    },
    setModifyUser(state, action) {
      state.UserUpdate = action.payload;
    },
    setUserDefaultKey(state, action) {
      state.defaultEventKey = action.payload;
    },
    enableModifyUserLoader(state) {
      state.isUpdating = true;
    },
    refreshOtherTabs(state, action) {
      switch (action.payload) {
        case "create":
          state.refreshModifyUser = state.refreshModifyUser + 1;
          state.refreshDeleteUser = state.refreshDeleteUser + 1;
          state.refreshBillToUser =  state.refreshBillToUser +1
          break;
        case "modify":
          state.refreshDeleteUser = state.refreshDeleteUser + 1;
          break;
        case "delete":
          state.refreshModifyUser = state.refreshModifyUser + 1;
          break;
          case 'billTo':
          state.refreshModifyUser = state.refreshModifyUser +1
          state.refreshBillToUser =  state.refreshBillToUser +1
          break
        default:
          break;
      }
    },
  },
  extraReducers: {
    [updateUserDetails.fulfilled]: (state) => {
      state.UserUpdate = "success";
      state.isUpdating = false;
    },
    [updateUserDetails.rejected]: (state) => {
      state.UserUpdate = "failed";
      state.isUpdating = false;
    },
    [updateBillTo.fulfilled]: (state) => {
      state.billToStatus = "success";
    },
    [updateBillTo.rejected]: (state) => {
      state.billToStatus = "failed";
    },
    [impersonateUserBillTo.fulfilled]: (state) => {
      state.billToStatus = "success";
    },
    [impersonateUserBillTo.rejected]: (state, action) => {
      state.billToStatus = action;
    },
    [getUsersList.fulfilled]: (state, action) => {
      state.usersList = action.payload;
    },
    [getCompanyList.fulfilled]: (state, action) => {
      state.companyList = action.payload;
    },
    [deleteUserEmail.fulfilled]: (state) => {
      state.userDeleted = "success";
      state.selectedModifyUser = [];
    },
    [getAllCustomers.fulfilled]: (state, action) => {
      state.customerList = action.payload;
    },
    [deleteUserEmail.rejected]: (state) => {
      state.userDeleted = "failed";
      state.selectedModifyUser = [];
    },
    [ deleteUserEmailOSR.fulfilled]: (state) => {
      state.OSRDeleted = 'success';
      state.selectedModifyUser = [];
  },
  [ deleteUserEmailOSR.rejected]: (state, action) => {
      state.OSRDeleted = action;
      state.selectedModifyUser = [];
  },
    [getModifyUserDetails.fulfilled]: (state, action) => {
      if (!_isEmpty(action.payload) && !_isEmpty(action.payload.shipTos)) {
        let tempArr = [];
        action.payload.shipTos.map((shipTo) => {
          tempArr.push({
            ...shipTo,
            value: shipTo.shipToId,
            label: `${shipTo.shipToId} ${shipTo.address}`,
          });
        });
        action.payload.shipTos = tempArr;
      }
      state.selectedModifyUser = action.payload;
      state.isUpdating = false;
    },
    [getUserPreferenceDetails.fulfilled]: (state, action) => {
      if (!_isEmpty(action.payload) && !_isEmpty(action.payload.shipTos)) {
        let tempArr = [];
        action.payload.map((preferenceArray) => {
          tempArr.push({
            ...preferenceArray,
            prefName: preferenceArray.text,
          });
        });
        action.payload.preferenceData = tempArr;
      }
      state.userPreferenceDetail = action.payload;
    },
    [getUserPreferenceDetails.rejected]: (state, action) => {
      state.userPreferenceDetail = "failed";
    },
    [updateUserPreferenceDetails.fulfilled]: (state) => {
      state.UserPreferenceUpdate = "success";
    },
    [updateUserPreferenceDetails.rejected]: (state) => {
      state.UserPreferenceUpdate = "failed";
    },
    [getPartImageURLCookie.fulfilled]: (state) => {
      state.partImageURLCookie = "success";
    },
    [getPartImageURLCookie.rejected]: (state) => {
      state.partImageURLCookie = "failed";
    },
    [updateDriverDetails.fulfilled]: (state) => {
      state.UserUpdate = "success";
    },
    [updateDriverDetails.rejected]: (state) => {
      state.UserUpdate = "failed";
    },
    [getDriverList.fulfilled]: (state, action) => {
      state.driverList = action.payload;
    },
    [getCompanyDataBasedOnCompanyAdmin.fulfilled]: (state, action) => {
      state.companyData = action.payload;
    },
    [getCompanyDataBasedOnCompanyAdmin.rejected]: (state) => {
      state.companyData = "failed";
    },
    [modifyCompany.fulfilled]: (state, action) => {
      state.companyupdated = action.payload;
    },
    [modifyCompany.rejected]: (state) => {
      state.companyupdated = "failed";
    },
    [getDelveryPickupData.fulfilled]: (state, action) => {
      state.allStores = action.payload;
    },
    [getDelveryPickupData.rejected]: (state) => {
      state.allStores = "failed";
    },
    [getUserPgmAccess.fulfilled]: (state, action) => {
      state.userProgramAccesData = action.payload;
  },
    [getUserPgmAccess.rejected]: (state, action) => {
      state.userProgramAccesData  = 'failed';
  },
  [getAllAccountConfigInfo.fulfilled]: (state, action) => {
    state.accountConfigDetails = action.payload;
  },
  [getAllAccountConfigInfo.rejected]: (state, action) => {
    state.accountConfigDetails = [];
  },
  [getDSWAccounts.fulfilled]: (state, action) => {
    let { accounts } = action.payload;
    state.accounts = accounts;
  },
  [getDSWAccounts.rejected]: (state, action) => {
    state.accounts = [];
  },

  },
});

const { actions, reducer } = userManagement;

export const {
  resetData,
  setModifyUser,
  enableModifyUserLoader,
  setUserDefaultKey,
  refreshOtherTabs,
} = actions;

export default reducer;
