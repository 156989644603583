import React from 'react';
import { Pagination } from 'react-bootstrap';

function PaginationComponent(props) {
    let {
        totalPages,
        currentPage,
        handlePageChange,
    } = props;

    const renderPaginationNumbers = () => {
        let elements = [];
        let pageLowerLimit;
        let pageUpperLimit
        if (currentPage == 1) {
            pageLowerLimit = 1;
            pageUpperLimit = totalPages > 5 ? 5 : totalPages;
        } else if (currentPage == totalPages) {
            pageLowerLimit = totalPages <= 5 ? 1 : currentPage - 4
            pageUpperLimit = currentPage;
        } else {
            pageLowerLimit = (currentPage - 2) < 1 ? 1 : (currentPage - 2)
            pageUpperLimit = (currentPage + 2) > totalPages ? totalPages : (currentPage + 2);
            if ((currentPage - 2) < 1 && (totalPages >= 5)) {
                pageUpperLimit = pageUpperLimit + 1;
            }
            if ((currentPage + 2) > totalPages && (totalPages >= 5)) {
                pageLowerLimit = pageLowerLimit - 1
            }
        }
        for (let page = pageLowerLimit; page <= pageUpperLimit; page++) {
            elements.push(<Pagination.Item active={page === currentPage} onClick={() => { handlePageChange(page) }}>{page}</Pagination.Item>)
        }
        return elements;
    }
    return (
        totalPages > 1 && (<Pagination>
            <Pagination.First onClick={() => { handlePageChange(1) }} />
            <Pagination.Prev onClick={() => { handlePageChange(currentPage - 1) }} />
            {
                renderPaginationNumbers()
            }
            <Pagination.Next onClick={() => { handlePageChange(currentPage + 1) }} />
            <Pagination.Last onClick={() => { handlePageChange(totalPages) }} />
        </Pagination>)
    );
}
export default PaginationComponent;
