import httpClient from "../../services/http";
import { buildQueryUrl } from "../../helpers/utils";

  export const getReturnList = (data) =>
  httpClient.get(`/order-service/return/storeReturn?${buildQueryUrl(data)}`);

  export const createOrUpdateStore = (data) =>
  httpClient.post(`/order-service/return/${data.return_id}?action=${data.action}`, data.payload);

  export const getInvoiceStoreData = (data) =>
  httpClient.post(`/order-service/invoices/partSearch`, data);
  
  export const getPartListForStoreDashBoard = (data) =>
  httpClient.post(`/order-service/store/pricing-availability?billTo=${data.billTo}&shipTo=${data.shipTo}`, data.request);

  export const getAllManager = () =>
  httpClient.get(`/user-service/associatedStore`);

  export const getUserRoles = () =>
  httpClient.get(`/user-service/user/roles`);

  export default httpClient;