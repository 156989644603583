import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Dropdown,
  DropdownButton,
  InputGroup,
} from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import MultipleSelectCheckbox from "./customMultiCheckboxSelect";
import TopBarPaginationComponent from "../../components/shared/TopBarPagination";
import { useSelector } from "react-redux";
import _ from "lodash";

function SortByShow({
  brandsView,
  currentPageSize,
  totalItems,
  handleDropDown,
  handleJob,
  handleMixPromo,
  handleSortBy,
  currentSort,
  selectedBrands,
  suppliesAllBrands,
  handleBrandSelect,
  handlePageChange,
  currentPage,
  totalPages,
  isChemicalCatalog,
  handlePartTypeFilter,
  multiSelectArray,
  currentFilter,
  partTypes,
}) {
  const mixedPromo = useSelector((state) => state.parts.mixedPromo);

  return (
    <div className="show-sort-by-drop-downs">
      {
        <MultipleSelectCheckbox
          handlePartTypeFilter={handlePartTypeFilter}
          multiSelectArray={multiSelectArray}
          currentFilter={currentFilter}
          partTypes={partTypes}
        />
      }
      {handleSortBy && (
        <div className="ml-3">
          <span> Sort By: </span>{" "}
          <DropdownButton
            title={currentSort}
            onSelect={(key, e) => {
              handleSortBy(key, e);
            }}
          >
            {!isChemicalCatalog && (
              <Dropdown.Item eventKey="MclDefault">Default </Dropdown.Item>
            )}
            <Dropdown.Item eventKey="PartTypeASC">
              Part type (A to Z){" "}
            </Dropdown.Item>{" "}
            <Dropdown.Item eventKey="PartTypeDESC">
              Part type (Z to A){" "}
            </Dropdown.Item>{" "}
            <Dropdown.Item eventKey="BrandASC">Brand (A to Z) </Dropdown.Item>{" "}
            <Dropdown.Item eventKey="BrandDESC">Brand (Z to A) </Dropdown.Item>{" "}
            <Dropdown.Item eventKey="PriceASC">
              Price (Low to High){" "}
            </Dropdown.Item>{" "}
            <Dropdown.Item eventKey="PriceDESC">
              Price (High to Low){" "}
            </Dropdown.Item>{" "}
          </DropdownButton>{" "}
        </div>
      )}{" "}
      <div className="topbar-buttons">
        {mixedPromo && (
          <Button
            className="new-search"
            onClick={(key, e) => {
              handleMixPromo(key, e);
            }}
          >
            Chemicals
          </Button>
        )}
      </div>
      <div className="show-sort-by-drop-downs">
        {!_isEmpty(suppliesAllBrands) && !brandsView && (
          <div>
            <DropdownButton
              title={"Brands"}
              onSelect={(key, e) => {
                handleSortBy(key, e);
              }}
            >
              <ul className="brands-dropdown">
                {suppliesAllBrands.map((b) => (
                  <li>
                    <InputGroup>
                      <InputGroup.Checkbox
                        id={b}
                        name={b}
                        value={b}
                        onClick={() => {
                          handleBrandSelect(b);
                        }}
                        checked={selectedBrands.includes(b)}
                      />
                      <label for={b}>{b}</label>
                    </InputGroup>
                  </li>
                ))}{" "}
              </ul>
            </DropdownButton>
          </div>
        )}
        <div className="ml-2">
          <span> Show: </span>{" "}
          <DropdownButton
            title={currentPageSize}
            onSelect={(key, e) => {
              handleDropDown(key, e);
            }}
          >
            <Dropdown.Item eventKey="25"> 25 </Dropdown.Item>{" "}
            <Dropdown.Item eventKey="50"> 50 </Dropdown.Item>{" "}
            <Dropdown.Item eventKey="75"> 75 </Dropdown.Item>{" "}
            <Dropdown.Item eventKey="100"> 100 </Dropdown.Item>{" "}
          </DropdownButton>
        </div>{" "}
        <div className="">
          {mixedPromo && (
            <Button
              className="new-search"
              onClick={(key, e) => {
                handleMixPromo(key, e);
              }}
            >
              Chemicals
            </Button>
          )}
        </div>
      </div>
      <div className="per_page ml-3">
        <p>
          <span>
            {" "}
            {totalItems < currentPageSize ? totalItems : currentPageSize}{" "}
          </span>{" "}
          Parts / <span> {totalItems} </span>
        </p>{" "}
      </div>
      <div className="per_page ml-3">
        <TopBarPaginationComponent
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </div>{" "}
    </div>
  );
}
export default SortByShow;
