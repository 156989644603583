import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _each from "lodash/each";
import Select from "react-select";
import _uniqueId from "lodash/uniqueId";
import _every from "lodash/every";
import BootstrapTable from "react-bootstrap-table-next";
import _uniqWith from "lodash/uniqWith";
import _isEqual from "lodash/isEqual";
import _, { set } from "lodash";
import { triggerCustomUxCriticalError } from "../../actions/error";
import { useReactToPrint } from "react-to-print-nonce";
import { getInvoiceStoreDetails ,getStockProductList } from "../StoreDashboard/storeDashboard.thunks";
import { getPartsPricingAvailability } from "../../components/Listing/VectorGfx/vectorgfx.thunks";
import FormValidationErrorMessage from '../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import { createReturns, getInvoiceDetails } from "../../components/shared/MyGarage/mygarage.thunks";
import {
  Button,
  Spinner,
  Form,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  timeInCST,
  handleKeyDown,
  handleKeyDownReturns,
  handleKeyDownAlphabet,
  getShipInfo,
  formatAmount,
	isMaxLengthLessthan,
  printLogo,
  hasWhiteSpace,
  themeColor
} from "../../helpers/utils";
import clsx from "clsx";
import XModal from "../shared/XModal";
import StoreInitiatedReturn from "../StoreDashboard/popUp/StoreInitiatedReturn";


function CreateReturn(props) {
  const initializeValidity = () => {
		const validity = {
			// isPurchaseOrdAlphaSpclChar: true,
			isPurchaseOrdMaxLength: true,
			isOrdCmntAlphaIntSpcl: true,
			isOrdCmntLessThanLimit: true,
			isPurchaseOrdPatternValid: true,
			isPOPatternConfigFound: true
		};
		return validity;
	};
  const [validity, setvalidity] = useState(initializeValidity);
  const userDetails = useSelector((state) => state.user.userDetails);
  const parts = useSelector((state) => state.parts);
  const shipInfo = getShipInfo(userDetails, parts);
  const viewPromotions = useSelector((state) => state.app.viewPromotions);
  const [showLoadermagic, setShowLoadermagic] = useState(false);
  const [partNum, setPartNum] = useState("");
  const [partNumClear, setPartNumClear] = useState("");
  const [partSelect, setPartSelect] = useState(true);
  const dispatch = useDispatch();
  const [returnColumns, setReturnColumns] = useState([]);
  const [getPO, setPO] = useState("");
  const [confirmNumber, setConfirmNumber] = useState("");
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [confirmData, setConfirmData] = useState([]);
  const [showReturns, setShowReturns] = useState(userDetails && userDetails.userType !== undefined ? (userDetails.userType === "STORE_USER" ? false : true) : false);
  const [custNum, setCustNumber] = useState('');
  const [location, setLocation]  = useState("");
  const [storeShipTo, setStoreShipTo] = useState({storeship: null});
  const [showLoader, setShowLoader] = useState(false);
  const storeDashBoardShipTo = useSelector(
    (state) => state.storeDashBoard.storeShipTo
  );
  const [shipValue, setShipValue] = useState({value:null});

  useEffect(() => {
    let data =
      userDetails && userDetails.userType !== undefined
        ? userDetails.userType === "STORE_USER" ||
          userDetails.userType === "CC_MANAGER"
          ? false
          : true
        : false;
    setShowReturns(data);
  }, [userDetails, userDetails.userType]);

const isvalidpoNumberCheck =()=>{
  if(!_isEmpty(userDetails)){
    if (userDetails.billTo.poRequired === "Y") {
      if(!_isEmpty(getPO)){
        return false;
      }
    }else
    if( userDetails.billTo.poRequired !== "Y" ){
      return false;
    }
  }
  return true;
}
  const options = [
    { label: "New", value: "New" },
    { label: "Core", value: "Core" },
    { label: "Warranty", value: "Warranty" },
  ];
  const coreReturnType = [
    { label: "Core", value: "Core" }
  ];
  const coreReturnTypeObj = { label: "Core", value: "Core" }

  const emptyData = [
    {
      id: 1,
      emptySelection: true,
      selected: false,
      checkAvailToCredit:false,
      lineSpaceError:true
    }
  ];
  const [returnData, setreturnData] = useState(emptyData);
  const headerStyle = {
    padding: "17px",
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "110px",
  };
  const headerStyle2 = {
    padding: "17px",
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "85px",
  };
  const headerStyleConfirm = {
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    fontWeight: "500",
   "border-color": "#0f528",
    "font-size": "17px",
    width: "110px",
  };
  const headerStyle1 = {
    paddingLeft: "15px",
    paddingBottom: "17px",
    color: "#FFFFFF",
    fontWeight: "500",
    "font-size": "17px",
    width: "50px",
  };
  const headerStyleReason = {
    padding: "17px",
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    fontWeight: "500",
    "border-color": "#0F5283",
    "font-size": "17px",
    width: "150px",
  };

  useEffect(() => {
    returnTable();
  }, []);

  const printComponent = useRef();
  const handlePrint = useReactToPrint({
    content: () => printComponent.current,
  });

  const errorDispatch = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = "Internal system error. Please contact support.";
    statusMessageProps.type = 'error';
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  }

  const prepareRowData = (data, key, validReturnPart) => {
    let partRows = [];
    let descriptionValues = [];
    let partArr = [];
    let description = "";
    data.map((value, index) => {
      description = value.itemSpecific;
      descriptionValues.push({
        detailsDescription: value.mfgCode + " " + value.partNum + " - " + value.itemSpecific,
        mfgCode: value.mfgCode,
        description: value.itemSpecific,
        corePrice: value.corePrice,
        costPrice :value.costPrice,
        partNum : value.partNum
      });
      partRows.push({
        value: value.mfgCode,
        label: value.mfgCode + " " + value.partNum + " - " + value.itemSpecific,
      });
    });
    partArr = _uniqWith(partRows, _isEqual);
    returnData[key].productLine = partArr;
    returnData[key].productLineCopy = partArr;
    returnData[key].descriptionValues = descriptionValues;
    if (descriptionValues.length === 1) {
      if (descriptionValues[0].corePrice > 0 && (validReturnPart === false) || validReturnPart === true) {
        returnData[key].description = description.toUpperCase();
        returnData[key].pl = partArr[0].value;
        returnData[key].corePrice = descriptionValues[0].corePrice;
        returnData[key].costPrice = descriptionValues[0].costPrice;
        returnData[key].noborder = 1;
        returnData[key].select = true;
      }
      else {
        returnData[key].showNoData = 1;
        returnData[key].noborder = 1
      }
    }
    returnData[key].noborderpart = 1;
    returnData[key].unique = _uniqueId(returnData[key].partNum);

    if (validReturnPart === false) {
      returnData[key].invalidPurchasePart = 1;
      returnData[key].select = false;
      returnData[key].unique = _uniqueId();
    }
    setreturnData(returnData);
    returnTable();
  }

  const findMatchingObject = (row, targetDescription= "") => {
    let arr = [];
    if(!_isEmpty(row.descriptionValues)) {
      arr = row.descriptionValues;
      return arr.find(item => item.detailsDescription === targetDescription).partNum;
    }
    return row.partNum;
  };

  const prepareMoreRowData = (data, key, mfgCode, partNum) => {
    data = data.filter((o) => {
      return o.status !== '3' && o.status !== '6'
    });

    if (data.length === 1 && data[0].status === "2") {
      returnData[key].showNoData = 1;
      returnData[key].noborderpart = 1;
      returnData[key].unique = _uniqueId(returnData[key].partNum);
    }
    else {
      let validReturnPart = undefined
      if (data.length === 1) {
        mfgCode = data[0]?.mfgCode;
        returnData[key].partNum = data[0]?.partNum;
        partNum = data[0]?.partNum ?? partNum;
      }
      const payload = {
        partNum: partNum,
        mfgCode: mfgCode,
        billTo: userDetails && userDetails.xlPartAccountNumber,
      }
      if (!_isEmpty(mfgCode)) {
        dispatch(getInvoiceStoreDetails(payload)).then((action) => {
            if (action.type === 'getInvoiceStoreDetails/fulfilled') {
              let invoiceData = action.payload;
              returnData[key].invoiceData = invoiceData;
              let qty = returnData[key].rqty ? returnData[key].rqty : 1;
              returnData[key].qtyAvailToCredit = _.sumBy(invoiceData,"qtyAvailToCredit" );
              returnData[key].coreQtyAvailToCredit = _.sumBy(invoiceData,"coreQtyAvailToCredit" );
               let checkQty = data[0]?.corePrice > 0 ? returnData[key].coreQtyAvailToCredit : returnData[key].qtyAvailToCredit;
              if (invoiceData.length > 0  &&  checkQty >= Number(qty) && Number(qty) !== 0) {
                returnData[key].checkAvailToCredit = false;
                validReturnPart = true
              }  if(_isEmpty(invoiceData) && invoiceData.length === 0 ){
                validReturnPart = false;
              } else
              if(invoiceData.length > 0  &&  (checkQty < Number(qty) || Number(qty) === 0))
              {
                validReturnPart = false;
                returnData[key].checkAvailToCredit = true
               }
              prepareRowData(data, key, validReturnPart)
            } else {
              errorDispatch();
            }
          })
      }
      else if (_isEmpty(mfgCode)) {
        prepareRowData(data, key, validReturnPart)
      }
    }
  };

  const handleFetchEnable = () => {
    let bool = returnData.some((product) => {
      return (
        product.partNum &&
        !product.productLine &&
        !product.productLine &&
        !product.showNoData
      );
    });
    setPartNum(bool);
  };

  const handlePricingAvailbility = async () => {
    const shipValue = shipValue?.value;
    returnData.forEach((v) => delete v.new);
    //let prev = "";
    await _each(returnData, (val, key) => {
      if (val.partNum === "") {
        delete returnData[key].partNum;
      }

      if (
        !_isEmpty(val.partNum) &&
        _isEmpty(val.productLine) &&
        !val.showNoData
      ) {
        //prev = val.partNum;
        let reqItems = [];
        reqItems.push({
          mfgCode: val.pl ? val.pl : "*",
          partNum: val.partNum,
          qty: 1,
        });
        const payload = {
          requestItems: reqItems,
          store: shipInfo.storeNumber ? shipInfo.storeNumber : "",
          dispatch: dispatch,
        };

        const storeUserPayload = {
          billTo: !_isEmpty(custNum)
            ? custNum
            : userDetails && userDetails.xlPartAccountNumber,
          shipTo: shipValue
            ? shipValue
            : userDetails && userDetails.shipTos[0].shipToId,
          request: {
            requestItems: reqItems,
            store: shipInfo.storeNumber ? shipInfo.storeNumber : "",
            dispatch: dispatch,
          },
        };

        setShowLoadermagic(true);
        if (
          userDetails &&
          (userDetails.userType !== "STORE_USER" ||
            userDetails.userType !== "CC_MANAGER")
        ) {
          dispatch(getPartsPricingAvailability(payload))
            .then((action) => {
              prepareMoreRowData(action.payload, key, val.pl, val.partNum);
              setShowLoadermagic(false);
            })
            .catch((error) => {
              console.log("error" + error);
              setShowLoadermagic(false);
            });
        } else if (
          userDetails &&
          (userDetails.userType === "STORE_USER" ||
            userDetails.userType === "CC_MANAGER")
        ) {
          dispatch(getStockProductList(storeUserPayload))
            .then((action) => {
              prepareMoreRowData(
                action.payload.responseData,
                key,
                val.pl,
                val.partNum
              );
              setShowLoadermagic(false);
            })
            .catch((error) => {
              console.log("error" + error);
              setShowLoadermagic(false);
            });
        }
      }
    });
    setPartNum("");
  };


  const checkAvailableQty =(row ,rowIndex)=>{
    let returnType = !_isEmpty(row.returnType) && row.returnType.value ? row.returnType.value : "New" ;
    let checkQty = (returnType === "Core") ? returnData[rowIndex].coreQtyAvailToCredit : returnData[rowIndex].qtyAvailToCredit;
    if (row.invoiceData.length > 0  &&  checkQty >= Number(row.rqty) && Number(row.rqty) !== 0) {
      returnData[rowIndex].checkAvailToCredit = false;
      returnData[rowIndex].validReturnPart = true;
      returnData[rowIndex].select = true;
    }
    if(row.invoiceData.length > 0  &&  (checkQty < Number(row.rqty) || Number(row.rqty) === 0)) {
      returnData[rowIndex].checkAvailToCredit = true;
      returnData[rowIndex].validReturnPart = false;
      returnData[rowIndex].select = false;
    }
    returnData[rowIndex].unique = Math.random();
      returnTable();
  }
  const handleMfgCodeSelection = (event, row, rowIndex) => {
    returnData[rowIndex].select = true;
    returnData[rowIndex].noborder = 1;
    returnData[rowIndex].productLine = event;
    returnData[rowIndex].mfgCode = event;
    let selectedItemPartNum = findMatchingObject(row, event?.label);

    const payload = {
      partNum: selectedItemPartNum,
      mfgCode: event.value,
      billTo: userDetails && userDetails.xlPartAccountNumber,
    }
    dispatch(getInvoiceStoreDetails(payload)).then((action) => {
        if (action.type === 'getInvoiceStoreDetails/fulfilled') {
          let invoiceData = action.payload;
          returnData[rowIndex].invoiceData = invoiceData;
          returnData[rowIndex].qtyAvailToCredit = _.sumBy(invoiceData,"qtyAvailToCredit" );
          returnData[rowIndex].coreQtyAvailToCredit = _.sumBy(invoiceData,"coreQtyAvailToCredit" );
          checkAvailableQty(row,rowIndex);
          if (invoiceData.some((p) => p.invoiceNumber !== "" && p.invoiceNumber !== null)) {
          } else {
            returnData[rowIndex].invalidPurchasePart = 1;
            returnData[rowIndex].select = false;
            returnData[rowIndex].unique = _uniqueId(returnData[rowIndex].partNum);
            if (returnData[rowIndex].corePrice <= 0) {
              returnData[rowIndex].showNoData = 1;
              returnData[rowIndex].description = "";
              returnData[rowIndex].pl = "";
            }
          }
        }
        else {
          returnData[rowIndex].select = false;
          returnData[rowIndex].showNoData = 1;
          returnData[rowIndex].description = "";
          returnData[rowIndex].pl = "";
          returnData[rowIndex].unique = _uniqueId(returnData[rowIndex].partNum);
          errorDispatch();
        }
        returnTable();
      })
    if (!_isEmpty(row.descriptionValues)) {
      row.descriptionValues.map((value) => {
        if (value.mfgCode === event.value && value.detailsDescription ===  event.label) {
          returnData[rowIndex].description = value.description.toUpperCase();
          returnData[rowIndex].pl = value.mfgCode;
          returnData[rowIndex].partNum = value.partNum;
          returnData[rowIndex].corePrice = value.corePrice;
          returnData[rowIndex].costPrice = value.costPrice;
        }
      });
    }
    //returnTable();
  };

  const onChangePLField = (event, row, rowIndex) => {
    returnData[rowIndex].pl = event.target.value;
  };

  const prepareReturnConfirmData = (data) => {
    setConfirmNumber(data.acxConfNumber);
    let confirmRows = [];
    data.lineItems.map((value, index) => {
      confirmRows.push({
        partNum: value.partNumber,
        mfgCode: value.mfgCode,
        qty: value.originalQty,
        description: value.partDesc,
        returnType: value.returnType,
        creditAmt: value.creditAmount,
        msg: value.visionStatus === "RETURN_ACCEPTED" ?  "Under Review": "Declined" ,
      });
    });
    setConfirmData(confirmRows);
    setConfirmModal(true);
    return confirmRows;
  };

  const handleCreateReturn = () => {
    let items = [];
    returnData.map((item) => {
      if (item.partNum && item.select && item.description) {
        items.push({
          mfgCode: item.pl,
          partNum: item.partNum,
          desc: item.description,
          price: item.rqty ? (item.costPrice * Number(item.rqty)) : (item.costPrice * 1),
          qty: item.rqty ? item.rqty : 1,
          returnType: item.returnType?.value ? item.returnType.value : "New",
          reason: item.reason,
        });
      }
    });

    let payload = {
      refNum: getPO || "",
      items: items,
    };
    if(userDetails && (userDetails.userType === "STORE_USER" || userDetails.userType === "CC_MANAGER")){
      payload["billTo"] = custNum;
      payload["shipTo"] = shipValue?.value;
      payload["location"] = location;
    }

    setShowLoadermagic(true);
    dispatch(createReturns(payload))
      .then((invResult) => {
        if (invResult.payload !== undefined) {
          setShowLoadermagic(false);
          prepareReturnConfirmData(invResult.payload);
          clearAll();
          setPO("");
        } else {
          setShowLoadermagic(false);
          errorDispatch();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clearAll = () => {
    setPO("");
    setreturnData([]);
    returnData.length = 0;
    setreturnData(returnData);
    returnTable();
    setPartNum("");
    setTimeout(() => {
      setreturnData([]);
      returnData.length = 0;
      returnData.push(
        {
          id: 1,
          emptySelection: true,
          selected: false,
          checkAvailToCredit:false,
         lineSpaceError:true
        },

      );
      setreturnData(returnData);
      returnTable();
      setPartNum("");
      setPartSelect(true);
    }, 10);
  };

  const handleReturnType = (event, row, rowIndex) => {
    returnData[rowIndex].returnType = event;
    checkAvailableQty(row,rowIndex);
  };

  const setPartForRow = (partNum, row, rowIndex) => {
    let value = partNum.toUpperCase();
    if(!_isEmpty(value) && value.length > 0){
      returnData[rowIndex].lineSpaceError = hasWhiteSpace(value);
        returnTable();
    }
    setPartNumClear(value);
    returnData[rowIndex].partNum = value;
    if (row.lineSpaceError && !row.productLine && (row.partNew || row.partNew === "")) {
      setTimeout(() => {
        returnTable();
      }, 100);
    }
    handleFetchEnable();
  };

  const setProductLineOnMouse = (partNum, row, rowIndex) => {
    let value = partNum.toUpperCase();
    if (!row.productLine && row.partNum) {
      let seletedProductArr = returnData.find(
        (product) => product.partNum === value
      );
      if (
        seletedProductArr?.productLineCopy &&
        seletedProductArr.descriptionValues?.length > 1
      ) {
        returnData[rowIndex].productLine = seletedProductArr.productLineCopy;
        returnData[rowIndex].descriptionValues =
          seletedProductArr.descriptionValues;
        returnData[rowIndex].unique = _uniqueId(returnData[rowIndex].partNum);
        returnData[rowIndex].noborderpart = 1;
        returnTable();
      }
    }
  };

  const onChangeQtyField = (event, row, rowIndex) => {
    let {
      target: { value },
    } = event;
    returnData[rowIndex].rqty = value;
    if (row.description) {
      if (value === "") {
        returnData[rowIndex].select = false;
      } else if(!_isEmpty(value)) {
        returnData[rowIndex].select = true;
        checkAvailableQty(row,rowIndex);
      }
      setTimeout(() => {
        returnTable();
      }, 100);
    }
  };

  const onChangeReturnReasonFieldTable = (event, row, rowIndex) => {
    let {
      target: { value },
    } = event;
    returnData[rowIndex].reason = value;
    if (row.prevReason || row.prevReason === "") {
      setTimeout(() => {
        returnTable();
      }, 100);
    }
  };

  const handleEnterKey = (event) => {
    if (event.keyCode === 13) {
      handlePricingAvailbility();
    }
  };

  const returnTable = () => {
    returnData.forEach((v) => delete v.prevReason);
    returnData.map((v) => {
      if (v.description) {
        v.prevReason = v.reason;
      }
    });

    let IsValid = returnData.find((item) => item.select && item.checkAvailToCredit === false);
    if(IsValid){
      setPartSelect(false);
    }else{
      setPartSelect(true);
    }

    const columns = [
      {
        dataField: "pl",
        text: "P/L",
        headerStyle: headerStyle1,
        formatter: function (cell, row, index) {
          if (!row.noborder) {
            return (
              <Fragment>
                <Form.Control
                  type="text"
                  name="pl"
                  onChange={(e) => {
                    onChangePLField(e, row, index);
                  }}
                  onKeyDown={(event) => {
                    handleKeyDownAlphabet(event);
                  }}
                  value={row.pl}
                  className="qty_input_field return-font"
                  maxLength="3"
                />
              </Fragment>
            );
          } else {
            return <div className="return-font pl">{row.pl}</div>;
          }
        },
      },
      {
        dataField: "partNum",
        text: "Part SKU",
        editable: true,
        headerStyle: headerStyle2,
        formatter: function (cell, row, index) {
          row.partNew = row.partNum;
          if (!row.noborderpart) {
            return (
              <Fragment>
                <Form.Control
                  type="text"
                  name="partNum"
                  placeholder="Enter Part Number"
                  onBlur={(e) => {
                    if(row.lineSpaceError){
                      handlePricingAvailbility()
                    }
                     }}
                  onChange={(event) => {
                    setPartForRow(event.target.value, row, index);
                  }}
                  onMouseLeave={(event) =>{
                    if(row.lineSpaceError){
                    setProductLineOnMouse(event.target.value, row, index)
                  }}}
                  onKeyDown={(event) => {
                    //handleKeyDownReturns(event);
                    handleEnterKey(event);
                  }}
                  maxLength="20"
                  value={row.partNum}
                  className="qty_input_field return-font"
                  autoFocus={
                    row.new || (row.id == 1 && row.id !== 2) || row.partNum
                  }
                />

                {!row.lineSpaceError && <span className= "return_error">Part SKU should not contain space</span>}

                {row.invalidPurchasePart === 1 && <span className= {row.corePrice > 0 ? "error-message-partsNotpurchased" : "error-message"} >This part was not purchased</span>}
              </Fragment>
            );
          } else
          if(row.checkAvailToCredit && !row.validReturnPart){
            return (
              <div>
                <div className="return-font pl ml-1">{row.partNum}</div>
                {row.checkAvailToCredit && <span className=  "error-message return_errorMsg1" >Return cannot be processed as this part has already been returned in the past</span> }
              </div>
            );}
            else {
            return (
              <div>
                <div className="return-font pl ml-1">{row.partNum}</div>
                {row.invalidPurchasePart === 1 && <span className= {row.corePrice > 0 ? "error-message-partsNotpurchased" : "error-message"}>This part was not purchased</span>}
              </div>
            );
          }
        },
      },
      {
        dataField: "rqty",
        text: "Qty",
        editable: true,
        headerStyle: headerStyle1,
        formatter: function (cell, row, index) {
          return (
            <Fragment>
              <Form.Control
                type="text"
                name="rqty"
                onChange={(e) => {
                  onChangeQtyField(e, row, index);
                }}
                defaultValue={row.showNoData ? "" : row.partNum ? 1 : ""}
                onKeyDown={(event) => {
                  handleKeyDown(event);
                }}
                value={row.rqty}
                className="qty_input_field return-font"
                maxLength="4"
                autoFocus={
                 !_isEmpty(row.partNum) && row.partNum.length > 1 &&
                  (row.rqty || (row.pl && row.rqty === "")) && true}
              />
            </Fragment>
          );
        },
      },
      {
        dataField: "productLineCopy",
        text: "productLineCopy",
        hidden: true,
      }, {
        dataField: "newPartNum",
        text: "new1",
        hidden: true,
      },
      {
        dataField: "new",
        text: "new",
        hidden: true,
      },
      {
        dataField: "checkAvailToCredit",
        text: "checkAvailToCredit",
        hidden: true,
      },
      {
        dataField: "prevReason",
        text: "prevReason",
        hidden: true,
      },
      {
        dataField: "productLine",
        text: "Description",
        sort: true,
        editable: false,
        headerStyle: headerStyle,
        formatter: function (cell, row, index) {
          if (!_isEmpty(row.productLine)) {
            return (
              <div className="return-select">
                {row.productLine && row.productLine.length > 1 ? (
                  <Select
                    options={!_isEmpty(row.productLine) && row.productLine}
                    name="mfgCode"
                    placeholder="Select Manufacturer ...."
                    className="custom-dropdown-return return-font"
                    onChange={(e) => handleMfgCodeSelection(e, row, index)}
                    disabled={row.selected}
                    value={row.mfgCode}
                    //autoFocus={true}
                    theme={themeColor}
                  />
                ) : (
                  <div>
                    <div className="return-description return-font">
                      {row.description}
                    </div>
                    {
                      <span style={{ visibility: "hidden" }}>
                        {(row.mfgCode = row.productLine.value)}
                      </span>
                    }
                  </div>
                )}
              </div>
              //)
            );
          } else
          {
            return (
              <div className="return-select">
                <div className="return-description return-font">
                  {row.showNoData === 1 && !row.invalidPurchasePart && (
                    <span className="error-message">Part number not found</span>
                  )}
                </div>
              </div>
            );
          }
        },
      },
      {
        dataField: "unique",
        text: "uniqueData",
        hidden: true,
        formatter: function (cell, row, index) {
          return (row.unique = _uniqueId(row.partNum));
        },
      },
      {
        dataField: "descriptionValues",
        text: "descriptionValues",
        hidden: true,
      },
      {
        dataField: "description",
        text: "Description",
        headerStyle: headerStyle,
        hidden: true,
        formatter: function (cell, row, index) {
          return (
            <div className="return-description return-font">
              {row.description}
            </div>
          );
        },
      },
      {
        dataField: "showNoData",
        text: "showNoData",
        hidden: true,
      },
      {
        dataField: "invalidPurchasePart",
        text: "invalidPurchasePart",
        hidden: true,
      },
      {
        dataField: "corePrice",
        text: "corePrice",
        hidden: true,
      },
      {
        dataField: "costPrice",
        text: "costPrice",
        hidden: true,
      },
      {
        dataField: "price",
        text: "price",
        hidden: true,
      },
      {
        dataField: "lineSpaceError",
        text: "lineSpaceError",
        hidden: true,
      },
      {
        dataField: "emptySelection",
        text: "empty",
        hidden: true,
      },
      {
        dataField: "returnType",
        formatter: function (cell, row, index) {
          if (!_isEmpty(row.partNum) && !row.showNoData) {
            return (
              <Fragment>
                <div style={{ "column-gap": "1px", width: "0px" }}>
                  <Select
                    options={row.corePrice > 0 && row.invalidPurchasePart === 1 ? coreReturnType : options}
                    name="returnOptions"
                    placeholder="Select..."
                    className="custom-dropdown_return return-font"
                    defaultValue={options[0]}
                    //autoFocus={row.description ? true : false}
                    onChange={(e) => handleReturnType(e, row, index)}
                    value={row.corePrice > 0 && row.invalidPurchasePart === 1 ? coreReturnType && (returnData[index].returnType = coreReturnTypeObj) : row.returnType}
                    theme={themeColor}
                  />
                </div>
              </Fragment>
            );
          } else {
            return null;
          }
        },
        text: "Return Type",
        headerStyle: headerStyle1,
      },
      {
        dataField: "reason",
        text: "Return Reason",
        editable: true,
        headerStyle: headerStyleReason,
        formatter: function (cell, row, index) {
          if (!_isEmpty(row.partNum) && !row.showNoData) {
            return (
              <Fragment>
                <Form.Control
                  type="text"
                  name="reason"
                  onChange={(e) => {
                    onChangeReturnReasonFieldTable(e, row, index);
                  }}
                  value={row.reason}
                  className="qty_input_field return-font"
                  maxLength="50"
                  autoFocus={(row.reason || row.prevReason) && true}
                />
              </Fragment>
            );
          } else {
            return null;
          }
        },
      },
      {
        dataField: "select",
        text: "",
        headerStyle: tableHeaderStyle,
        formatter: selectColFormatter,
      },
    ];
    setReturnColumns(columns);
    setreturnData(returnData);
  };

  const selectColFormatter = (cell, row, index) => {
    if (!(row.pl && row.description)) {
      return null;
    } else if (row.rqty === "") {
      return null;
    }
    if (row.checkAvailToCredit) {
      return null;
    }
    else {
      return (
        <span>
          <input
            type="checkbox"
            checked={row.select}
            onChange={(e) => {
              onClickSelect(e, row, index);
            }}
          />
        </span>
      );
    }
  };

  const tableHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#0F5283",
    "border-color": "#a9d1d20",
  };

  const onClickSelect = (e, row, index) => {
    try {
      if (_isEmpty(row.pl)) {
        return {
          disabled: true,
        };
      }
      if (row.showNoData === 1) {
        return {
          disabled: true,
        };
      }
      if (e.target.checked) {
        returnData[index].select = true;
        row.selected = true;
        setPartSelect(false);
      } else if (e.target.checked === false && !_isEmpty(returnData)) {
        returnData[index].select = false;
        row.selected = false;
        setPartSelect(true);
        if (returnData.find((item) => item.select === true)) {
          setPartSelect(false);
        }
      }
      returnTable();
    } catch (error) { }
  };

  const onClickExpand = () => {
    returnData.forEach((v) => delete v.new);
    returnData.push({
      emptySelection: true,
      selected: false,
      checkAvailToCredit:false,
      lineSpaceError:true
    });
    returnData.map((v, i) => {
      if (
        v.partNum === undefined &&
        !returnData.some((p) => {
          return p.new;
        })
      ) {
        returnData[i].unique = _uniqueId();
        returnData[i].new = true;
      }
    });
    returnTable();
  };

  const returnConfirmcolumns = [
    {
      dataField: "mfgCode",
      text: "P/L",
      editable: false,
      headerStyle: headerStyleConfirm,
    },
    {
      dataField: "partNum",
      text: "Part SKU",
      headerStyle: headerStyleConfirm,
    },
    {
      dataField: "qty",
      text: "Qty",
      sort: true,
      editable: false,
      headerStyle: headerStyleConfirm,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      editable: false,
      headerStyle: headerStyleConfirm,
    },
    {
      dataField: "returnType",
      text: "Return Type",
      sort: true,
      editable: false,
      headerStyle: headerStyleConfirm,
    },

    {
      dataField: "creditAmt",
      text: "Credit Amount",
      sort: true,
      editable: false,
      headerStyle: headerStyleConfirm,
      formatter: function (cell, row) {
        return (
          <div className="">
            <span className="maroonColorTxt">
              ${formatAmount(row.creditAmt)}
            </span>
          </div>)
      }
    },
    {
      dataField: "msg",
      text: "Status",
      sort: true,
      editable: false,
      headerStyle: headerStyleConfirm,
    },
  ];
	const isPOPatternValid = (str) => {
    let regexValue = str && str.toUpperCase();
		const poRegex =
				userDetails &&
				userDetails.billTo &&
				userDetails.billTo.poRegex;
		if(poRegex && !_isEmpty(poRegex)) {
			var expression = new RegExp(poRegex);
			return expression.test(regexValue);
		}
		return true;
	};

  const isPurchOrdOPatternConfigFound = (str) => {
		const poPattern =
				userDetails &&
				userDetails.billTo &&
				userDetails.billTo.poPattern;
		const poRegex =
				userDetails &&
				userDetails.billTo &&
				userDetails.billTo.poRegex;
		if(poPattern && (!poRegex || _isEmpty(poRegex))) {
			return false;
		}
		return true;
	};

  const handleOrderChange = (event) => {
		const { value } = event.target;
		let validity = initializeValidity();
		// validity.isPurchaseOrdAlphaSpclChar = hasAlphaSpecialIntChar(value);
		validity.isPurchaseOrdMaxLength = isMaxLengthLessthan(value, 150);
		validity.isPurchaseOrdPatternValid = isPOPatternValid(value);
		validity.isPOPatternConfigFound = isPurchOrdOPatternConfigFound(value);
		setvalidity(validity);
    setPO(event.target.value)

	};

  const handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right, Control A
		return (code !== 8 && code !== 9 && code !== 37 && code !== 38 && code !== 39 && code !== 40 && code !== 46 && code !== 17 && code !== 65);
	}
	const handleKeyDownForPO = (event) => {
		const orderComment = event.target.value;
				if (orderComment && orderComment.length >= 150 && handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
	};

  return (
    <React.Fragment>
      <div className={clsx({ "promotions-active": viewPromotions })}>
        <div className="">
          <div className="stock_check_wrapper">
            {(showLoadermagic || showLoader) && (
              <div className="custom_spinner_container catalog_spinner">
                <Spinner className="custom_spinner" animation="border" />
              </div>
            )}
            <div className="bread_crum_section">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>Create Return</span>
                </li>
              </ul>
            </div>
            <br></br>
            {(userDetails && userDetails.userType === "STORE_USER") && (
             <StoreInitiatedReturn
             setShowReturns={setShowReturns}
             setLocation={setLocation}
             custNum={custNum}
             setCustNumber={setCustNumber}
             setStoreShipTo={setStoreShipTo}
             setShowLoader={setShowLoader}
             userDetails={userDetails}
             clearAll={clearAll}
             storeShipTo={storeShipTo}
             setShipValue={setShipValue}
             shipValue={shipValue}
             />)}
        <br></br>
        {(showReturns)&& (
        <>
          <div className="stoc_check_header">
              <span>Create Return</span>
            </div>
            <br></br>
            <Row>
              <Col md="5">
                <Form.Group className="label-ref-container">
                  <Form.Label
                    style={{
                      width: "177px",
                      fontSize: "17px",
                      fontWeight: "400",
                    }}
                  >
                    Return PO Number #
                  </Form.Label>
                  <OverlayTrigger
                      placement="top"
                      delay={{ show: 100, hide: 400 }}
                      overlay={
                     (userDetails && userDetails.billTo.poRequired === "Y") ?
                     (<Tooltip>
                          Enter a PO Number # that you can use later to inquire about this return.
                        </Tooltip>) :
                        (<Tooltip>
                          Enter a PO Number # of your choice that you can use later to inquire about this return. This is optional.
                        </Tooltip>)
                      }
                    ><div>
                        <span className="marron_text_i_Icon_return">
                          <i class="fa fa-info-circle" aria-hidden="true">
                          </i></span></div>
                    </OverlayTrigger>
					<Form.Control
          size="sm"
						maxLength="150"
            className="input__po"
						type="text"
            name="po"
            value={getPO}
						onChange={handleOrderChange}
						onKeyDown={(event) => {
							handleKeyDownForPO(event);
						}}
					/>
  </Form.Group>
  <Form.Group className="purchase_order_input_container">
					<FormValidationErrorMessage
						condition={!validity.isPurchaseOrdMaxLength}
						errorMessage={
							'Purchase order should not exceed 150 characters'
						}
					/>
					<FormValidationErrorMessage
					 condition={!validity.isPurchaseOrdPatternValid}
						errorMessage={
							'The PO Number you entered does not match the PO pattern you have designated with XL Parts. For security reasons, we can not allow the order to be placed nor can we tell you the correct PO pattern. Please call your XL Parts store or XL Parts Accounts Receivable at 713-983-1159 for assistance.'
						}
					/>
					<FormValidationErrorMessage
						 condition={!validity.isPOPatternConfigFound}
						errorMessage={
							'PO Validation configuration is missing. Please contact support.'
						}
					/>
          </Form.Group>

              </Col>
            </Row>
            <br></br>
            <div style={{ border: "1px solid #ced4da" }}>
              {returnColumns.length > 0 && (
                <BootstrapTable
                  keyField="unique"
                  data={returnData}
                  columns={returnColumns}
                  wrapperClasses="return-table-responsive"
                  tabIndexCell
                  bordered={false}
                />
              )}
            </div>
            <div className="return_add_to_cart_button">
              <div className="stock_check_btn">
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      id={`tooltip`}
                    >{`Click here to create return for more parts`}</Tooltip>
                  }
                >
                  <span onClick={() => onClickExpand()}>+</span>
                </OverlayTrigger>
                <Button
                  style={{ marginLeft: "9px" }}
                  onClick={() => {
                    clearAll();
                  }}
                >
                  {"  "} { } Clear All
                </Button>
              </div>
                <OverlayTrigger
                 tabindex="0"
                  overlay={
                    (isvalidpoNumberCheck())  ? (
                    <Tooltip>{`Please enter PO Number to submit the return`}</Tooltip>)
                    :<span></span>
                  }
                >
                  <span >
                  <Button
                className={(isvalidpoNumberCheck()) ? "pointer_event_submitBtn" : "add_to_cart_button" }
                disabled={ isvalidpoNumberCheck()  || partSelect  || !_every(validity)}
                onClick={() => {
                  handleCreateReturn();
                }}
              >
                Submit
              </Button>

                  </span>
                </OverlayTrigger>



              {showConfirmModal && (
                <XModal
                  show={showConfirmModal}
                  title={"THANK YOU FOR SUBMITTING CREDIT MEMO"}
                  className="vehicle-maintance-addcart-modal"
                  favoriteTextValidity={true}
                  submitType={true}
                  handleAction={() => setConfirmModal(false)}
                  handleClose={() => {
                    setConfirmModal(false);
                  }}
                  submitButtonText="OK"
                >
                  <div className="print_icon_return d-flex">
                    <span className="material-icons" onClick={handlePrint}>
                      print
                    </span>
                    <span onClick={handlePrint}>Print</span>
                  </div>
                  <div
                    className="estimate_popup_container"
                    ref={printComponent}
                  >
                    <div className="">
                      <div className="print__logo">
                        <img
                          alt=""
                          src={'/public_images/xlp_print_logo.png'}
                          align="center"
                        />
                      </div>
                    </div>
                    { (userDetails  && userDetails.shipTos[0] && userDetails.shipTos[0].shipMethod === 'WC') ? (
                         <div className="return-confirm">
                         Confirmation Number for this Credit Memo is{" "}
                         <b> {confirmNumber}</b>, The part must be returned at
                         the store location for the returns to be accepted
                       </div>
                    ):(
                      <div className="return-confirm">
                      Confirmation Number for this Credit Memo is{" "}
                      <b> {confirmNumber}</b>, please use this number in any
                      communication with The Parts House regarding this Credit Memo
                    </div>
                    )}
                    <div className="page-footer" id="footer">
                      {userDetails.x2userName}
                      <div className="page-footer_time" id="footer">
                        {timeInCST()}
                      </div>
                    </div>
                    <Row className="return-confirm-popup confirm-data">
                      <Col>
                        <div>
                          {confirmData.length > 0 && (
                            <BootstrapTable
                              bootstrap4
                              keyField="invoice"
                              data={confirmData}
                              columns={returnConfirmcolumns}
                              bordered={true}
                              wrapperClasses="confirm-data"
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </XModal>
              )}
            </div>
            </>)}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateReturn;
