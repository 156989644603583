import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip, Spinner, Col, Row , Form} from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import _isEmpty from "lodash/isEmpty";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { fetchReturn } from "../mygarage.thunks";
import Placeholder from "../../Placeholder";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { isAlphanumeric, hasMinLength } from "../../../../helpers/utils";
import FormValidationErrorMessage from "../../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import PaginationComponent from "../../Pagination";
import { getPreferenceDataFromStorage } from '../../../../helpers/utils';
import ReturnTable from "./ReturnTable";

const DRIVER_DELIVERED = "DRIVER_DELIVERED";
const DRIVER_INITIATED = "DRIVER_INITIATED";
const CUSTOMER_INITIATED = "CUSTOMER_INITIATED";
const STORE_DECLINED = "STORE_DECLINED";
const STORE_APPROVED = "STORE_APPROVED";
const DRIVER_COLLECTED = "DRIVER_COLLECTED";
const INITIATED = "INITIATED";
const STORE_REVIEWED = "STORE_REVIEWED";
const ESCALATED = "STORE_ESCALATED";
const AM_APPROVED = "AM_APPROVED";
const AM_DECLINED = "AM_DECLINED";
const MANUALLY_PROCESSED = "MANUALLY_PROCESSED";

const Returns = (props) => {
  const { closeOverlay } = props;
  let userPreferenceDetail = getPreferenceDataFromStorage();
  const dispatch = useDispatch();
  const initializeValidity = () => {
    const validity = {
      isSearchAlphanumeric: true,
      isSearchMinLimit: true,
    };
    return validity;
  };
  const history = useHistory();
  const [validity, setvalidity] = useState(initializeValidity);
  const mygarage = useSelector((state) => state.mygarage);
  const returnList = mygarage.returnList.partReturns;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  let totalPages = mygarage.returnList.totalPages;
  const user = useSelector((state) => state.user);
  const [dateFilter, setDateFilter] = useState(null);
  const [allStatus, setStatus] = useState("All");


  const handleSearch = (searchText) => {
    let pageNum = mygarage.returnList.page;
    let endTimeReturn = mygarage.returnList && mygarage.returnList.endTime ? mygarage.returnList.endTime : "";
    let startTime = mygarage.returnList.startTime && mygarage.returnList.startTime ? mygarage.returnList.startTime : "";

    if (searchText && searchText.length > 1) {
      const payload = {
        startTime: startTime,
        endTime: endTimeReturn,
        customerId: user.userDetails && user.userDetails.billTo.billToId,
        page: pageNum,
        pageSize: pageSize,
        searchKey: searchText,
        status: allStatus
      }
      dispatch(fetchReturn(payload));

    } else if (_.isEmpty(searchText)) {
      initialFetchReturns();
    }
  };

  const initialFetchReturns = () => {
    const payload = {
      customerId: user.userDetails && user.userDetails.billTo.billToId,
      page: currentPage,
      pageSize: pageSize,
      status: allStatus
    };
    try {
      const partsReturnsAction = dispatch(fetchReturn(payload));
      partsReturnsAction.then((action) => {
        if (action.payload) {
          setCurrentPage(action.payload.page);
        }
      });
    } catch (error) { }
  };

  useEffect(() => {
    if (!_isEmpty(userPreferenceDetail)) {
      let isValidReturnUser = userPreferenceDetail.find((item) => {
        return (item.text === "return-customer" && item.code === "User Management")
      })
      if (isValidReturnUser && isValidReturnUser.possibleAnswer) {
        isValidReturnUser.possibleAnswer.map((singleAnswer) => {
          const arr = singleAnswer.text.split(';')
          if (!_isEmpty(user.userDetails)) {
            let foundId = arr.find((item) => {
              return item === user.userDetails.xlPartAccountNumber;
            })
            if (user.userDetails.xlPartAccountNumber === foundId) {
              initialFetchReturns();
            }
          }
        })
      }
    }
  }, []);

  const handlePageChange = (clickedPage) => {
    let pageNum = clickedPage > totalPages ? "1" : clickedPage;
    let endTimeReturn = mygarage.returnList && mygarage.returnList.endTime ? mygarage.returnList.endTime : "";
    let startTime = mygarage.returnList && mygarage.returnList.startTime ? mygarage.returnList.startTime : "";

    const payload = {
      startTime: startTime,
      endTime: endTimeReturn,
      customerId: user.userDetails && user.userDetails.billTo.billToId,
      page: pageNum,
      pageSize: pageSize,
      status: allStatus
    };

    try {
      const partsReturnsAction = dispatch(fetchReturn(payload));
      partsReturnsAction.then((action) => {
        if (action.payload) {
          setCurrentPage(action.payload.page);
        }
      });
    } catch (error) { }

  };

  const handleDateRange = (e, picker) => {
    const { startDate, endDate } = picker;

    const _startDate = startDate.toISOString();
    const _endDate = endDate.toISOString();

    const startDate_return = startDate.format("YYYY-MM-DD");
    const endDate_return = endDate.format("YYYY-MM-DD");
    setDateFilter(
      `${startDate.format("YYYY-MM-DD")} - ${endDate.format("YYYY-MM-DD")}`
    );

    const payload = {
      customerId: user.userDetails && user.userDetails.billTo && user.userDetails.billTo.billToId,
      page: currentPage,
      pageSize: pageSize,
      startTime: startDate_return,
      endTime: endDate_return,
      status: allStatus
    };
    try {
      const partsReturnsAction = dispatch(fetchReturn(payload));
      partsReturnsAction.then((action) => {
        if (action.payload) {
          setCurrentPage(action.payload.page);
        }
      });
    } catch (error) { }
  };



  const handleSearchOrderChange = (event) => {
    const searchData = event.target.value;
    let validity = initializeValidity();
    validity.isSearchAlphanumeric = isAlphanumeric(searchData);
    validity.isSearchMinLimit = hasMinLength(searchData, 1);
    setvalidity(validity);
  };

  const statements = () => {
    history.push({
      pathname: '/statements',
      search: '?credit=1'
    });
    closeOverlay();
  };

  const handleCreateReturn = () => {
    closeOverlay();
    history.push("/create-return");
  };



  const handleSearchOrderBlur = () => {
    let validity = initializeValidity();
    setvalidity(validity);
  };

  return (
    <React.Fragment>
      <div className="orders-wrapper">
        {returnList && (
          <Row>
            <Col md="4" className="mygarage_pagination_wrapper">
              <PaginationComponent
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md="8">
            <div className="search-container">
              <Form.Control
                placeholder="Search REF# or ACX# "
                className="input__search input__control"
                onKeyUp={(e) => handleSearch(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
                onMouseOver={(e) => e.stopPropagation()}
                onChange={handleSearchOrderChange}
                onBlur={handleSearchOrderBlur}
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isSearchAlphanumeric && validity.isSearchMinLimit
                }
                errorMessage="Search key must be alphanumeric only"
              />
              <FormValidationErrorMessage
                condition={!validity.isSearchMinLimit}
                errorMessage="Search key should have atleast 1 character"
              />
            </div>
          </Col>
          <Col md="1" sm="2">
            <DateRangePicker onApply={handleDateRange}>
              <span className="material-icons date__filter">date_range</span>
            </DateRangePicker>
          </Col>
          <Col md="3">
            <div className="mt-3">

              <Button
                className="maroonBtn return_xl_parts_btn"
                onClick={() => handleCreateReturn()}
              >
                Create Return
              </Button>

            </div>
          </Col>
          <Col md="2">
            <div className="mygarage__returns_link">
              <div>
                <a
                  className="statement__link_return_credit"
                  onClick={(e) => statements()}
                >
                  Processed Returns & Credits
                </a>{" "}
              </div>
            </div>
          </Col>
        </Row> 
        {mygarage.loading ? (
          <Placeholder rows={1} />
        ) : !_isEmpty(returnList) && returnList.length ? (
          <React.Fragment>
            <div className="mygarage__content">
              {returnList.map((data, index) => {
                return (
                  <div className="mygarage__vehicle" key={data.id}>
                    <Row>
                      <div className="return_details">
                        <Col md="3">
                          <div style={{ marginLeft: "25px" }}>
                            <span className="return_summary_text"> ACX #: {"  "}</span>
                            {(data.acxConfNumber && (data.status !== STORE_REVIEWED || data.status === MANUALLY_PROCESSED) ) ?
                              (<span >{data.acxConfNumber}</span>) :
                              (data.oneStepConfNumber && data.status === STORE_REVIEWED) ?
                                (<span >{data.oneStepConfNumber}</span>) :
                                <span>N/A</span>
                            }
                          </div>
                        </Col>
                        <Col md="3">
                          <div style={{ marginLeft: "16px" }}>
                            <span className="return_summary_text"> Ref #: {"  "}</span>
                            <OverlayTrigger
                            placement="top"
                            delay={{ show: 100, hide: 400 }}
                            overlay={!_isEmpty(data.refNum) ? <Tooltip>{data.refNum}</Tooltip>:<span></span>}>
                            <span className="blacktext-referenceNum">{!_isEmpty(data.refNum) ? data.refNum : ""}</span>
                          </OverlayTrigger>
                          </div>
                        </Col>
                        <Col md="6">
                          <div >
                            <span className="return_summary_text">Creation Date: {"  "}</span>
                            <span>
                              {!_isEmpty(data.createdAt) &&(
                              <Moment format="MM-DD-YYYY" date={data.createdAt} />
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col >
                          <div className='received_text_align' style={{ marginLeft: "-10px" }}>
                            <span> {"  "}</span>
                            <span
                              className={`return_status ${"returnstore_status"}   `}
                            >
                              {(data.status === DRIVER_DELIVERED || data.status === DRIVER_COLLECTED) ? "In Process" :
                                (data.status === INITIATED || data.status === CUSTOMER_INITIATED || data.status === DRIVER_INITIATED) ? "In Process" :
                                  (data.status === STORE_REVIEWED ||  data.status === MANUALLY_PROCESSED) ? "POSTED" : ""}
                            </span>
                          </div>
                        </Col>

                      </div>
                    </Row>
                    <Row style={{ marginTop: '-5px', marginLeft: "40px", width: '592px' }}>
                      {!_isEmpty(data.lineItems) &&
                      (<ReturnTable
                        status={data.status}
                        lineItems={data.lineItems}
                        DRIVER_DELIVERED={DRIVER_DELIVERED}
                        STORE_DECLINED={STORE_DECLINED}
                        STORE_APPROVED={STORE_APPROVED}
                        DRIVER_COLLECTED={DRIVER_COLLECTED}
                        STORE_REVIEWED={STORE_REVIEWED}
                        ESCALATED={ESCALATED}
                        AM_APPROVED={AM_APPROVED}
                        AM_DECLINED={AM_DECLINED}
                        MANUALLY_PROCESSED ={MANUALLY_PROCESSED}
                      />)}
                    </Row>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        ) : (
          <div className="no-quotes">
            {(
              "You have not placed any returns, place an return."
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Returns;
