import React, { Fragment, useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  OverlayTrigger,
  Tooltip,
  Button,
  Col,
  Form,
  Container,
  Row,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import XModal from "../../../shared/XModal";
import _keys from "lodash/keys";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _ from "lodash";
import moment from "moment";
import _head from "lodash/head";
import _uniqBy from "lodash/uniqBy";
import resetIcon from "../../../../static/images/reset.png";
import AddToCart from "../../../shared/AddToCart/AddToCart";

import {
  getVectorGfxData,
  getAllPartByGroupData,
  getOEPartByGroupData,
  getPartsPricingAvailability,
} from "../vectorgfx.thunks";

import {
  getExtendedTotalPrice,
  numbersOnlyRegExp,
  isInputBlank,
  isValidPositiveNumber,
  isValidAvailableQty,
  distributeAvailability,
  formatTimeAvb,
  distributeAvailabilityByStore,
  deliveryPickupWarning,
  getShipInfo,
  getstoreName,
  getPartPackQty,
  displayQtyChangeMsg,
  getDistributionForVehicle,
  distributeAvailability2,
  getCartShipDetails
} from "../../../../helpers/utils";
import AddToCartWarningPopup from "../../../shared/AddToCartWarningPopup/AddToCartWarningPopup";
import { triggerCustomUxCriticalError } from "../../../../actions/error";
import "./styles.scss";
import { getAvailability } from "../../../../helpers/availabilityService"
import AvailabilityStoreView from "../../../shared/AvailabilityStoreView";
import ShipInfoHeader from "components/shared/ShipInfoHeader";

const actionTypes = {
  CNK_CART_CHECKOUT_FETCHED: 'CNK_CART_CHECKOUT_FETCHED'
};



const VectorGfxIllustrationPopup = (props) => {
  let { openPopup, closePopup, groupId, assetId, descriptionIds, isForCandK } = props;
  const dispatch = useDispatch();
  const parts = useSelector((state) => state.parts);
  const cart = useSelector((state) => state.cart);
  let checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  let candkCheckoutDetails = useSelector((state) => state.cart.candkCheckoutDetails);
  const hideAllPrice =  parts  && parts.hidePrice;
  const [selectedPartDiagramGroup, setSelectedPartDiagramGroup] = useState([]);
  let catalogRequestGroups = _cloneDeep(parts.catalogRequestGroups);
  const mygarage = useSelector((state) => state.mygarage);
  const search = useSelector((state) => state.search);
  const sv = props.sv || mygarage.selectedVehicle || search.selectedVehicle;
  const gfxIframe = useRef(null);
  const [svgData, setSvgData] = useState(null);
  const [svgDocument, setSvgDocument] = useState(null);
  const [registerEventDone, setRegisterEventDone] = useState(true);
  const [partList, setPartList] = useState([]);
  const [oePartList, setOEPartList] = useState([]);
  const [partListByManufacturer, setPartListByManufacturer] = useState([]);
  const [selectedPartGroupDetails, setSelectedPartGroupDetails] =
    useState(null);
  const [specificConditions, setSpecificConditions] = useState([]);
  const [selectedSpecificConditions, setSelectedSpecificConditions] = useState(
    []
  );
  const [showSpecificConditions, setShowSpecificConditions] = useState(false);
  const [partListTableColumns, setPartListTableColumns] = useState([]);
  const [partListTableRows, setPartListTableRows] = useState([]);
  const [selectedAvailabilityHours, setSelectedAvailabilityHours] = useState(
    []
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const userDetails = useSelector((state) => state.user.userDetails);
  const addToCartWarning = deliveryPickupWarning(userDetails, parts);
  const [addToCartWarningPopup, setaddToCartWarningPopup] = useState(false);
  const cartShipMethod = useSelector((state) => state.parts.cartShipMethod);
  const cartStore = useSelector((state) => state.parts.cartStore);
  const allStores = useSelector((state) => state.userManagement.allStores);
  const [legendLetter, setLegendLetter] = useState("");
  const [legendLetterFlag, setLegendLetterFlag]  = useState(false);
  const [MatchingPartNumsArray , setMatchingPartNumsArray]= useState([]);
  const [showAvailabilityStoreView, setShowAvailabilityStoreView] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [selectedGroupDetails, setSelectedGroupDetails] = useState(!_isEmpty(props.selectedGroupDetails) ? props.selectedGroupDetails : parts.selectedGroupDetails);
  const tableHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
  };

  const addToCartForWarning = () => {
    setaddToCartWarningPopup(true);
  };

  const renderSVGInIframe = (svgDoc, svgDataToIframe) => {
    if (gfxIframe.current && gfxIframe.current.contentDocument) {
      let doc = gfxIframe.current.contentDocument;
      doc.open();
      doc.write(svgDoc);
      doc.close();
      gfxIframe.current.style.width = "100%";
      // gfxIframe.current.style.height = `${gfxIframe.current.contentWindow.document.body.scrollHeight}px`
      gfxIframe.current.style.height = "700px";
      gfxIframe.current.contentWindow.postMessage(svgDataToIframe);
    }
  };

  const initialGetGfxSVGData = (partDetail) => {
    let partName = selectedGroupDetails[0].name; // TODO: for multiple group
    const payload = {
      engine: sv.engineId,
      make: sv.makeId,
      model: sv.modelId,
      year: sv.year,
      group: partDetail && partDetail.groupId ? partDetail.groupId : groupId,
      assetId: assetId,
      descriptionIds:
        partDetail && partDetail.descriptionIds
          ? partDetail.descriptionIds
          : descriptionIds,
      makeText: sv.make,
      modelText: sv.model,
      engineText: sv.engine,
      partText: partName,
      dispatch: dispatch,
    };
    try {
      const vectorGfxDataAction = dispatch(getVectorGfxData(payload));
      vectorGfxDataAction.then((action) => {
        if (action.payload.svgDocument) {
          const svgDoc = action.payload.svgDocument
            .replace(/window.external/g, "window.parent")
            // .replace("selectLayer(id){", "selectLayer(id){console.log('is Processing? : ', processing);console.log('is Locked out? : ', lockedOut);")
            .replace(
              /curZoom=2;/g,
              "curZoom=0;setTimeout(function(){ zoomInOut(); }, 500);"
            )
            .replace(
              /Matches Available in Alternate Data Set/g,
              "Part is available for the currently selected vehicle and group, but in an alternate dataset."
            )
            .replace(
              /Matches Available in Other Groups/g,
              "Part is available for the current vehicle, but in another part group. When part is clicked, new result set belonging to the part group will be displayed. The selected red part would turn solid green."
            )
            .replace(
              /Matches Available in All Lines/g,
              "Part is available for the current vehicle, group and in the current result set but under the coverage of different suppliers. If the part is solid orange, it is the currently selected part."
            )
            .replace(
              /Matches Available in Displayed Records/g,
              "Part is available in the current result set. If the part is solid green, it is the currently selected part."
            );
          setSvgDocument(svgDoc);
          setSvgData(action.payload.svgData);
          renderSVGInIframe(svgDoc, action.payload.svgData);
        }
      });
    } catch (error) {}
    getPartList(partDetail);
  };

  
  const handleReset =()=>{ 
    setLegendLetterFlag(false);
    setLegendLetter("");
    setMatchingPartNumsArray([]);
    setPartListByManufacturer([]);
    setPartListTableColumns([]);
    setPartListTableRows([]);
    setExpandedRows([]);
    renderSVGInIframe(svgDocument, svgData);
    initialGetGfxSVGData();
  }
  const getPartList = (partDetail) => {
    const partListPayload = {
      engine: sv.engineId,
      make: sv.makeId,
      model: sv.modelId,
      year: sv.year,
      dispatch: dispatch,
    };
    let matchingPartNo = catalogRequestGroups.requestItems.find((item) => {
      return item.groupCode === parseInt(groupId);
    });
    let partgroupId = 0;
    if (partDetail && partDetail.groupId) {
      partgroupId = parseInt(partDetail.groupId);
    } else {
      if (selectedPartGroupDetails) {
        partgroupId = selectedPartGroupDetails.partGroupId
          ? parseInt(selectedPartGroupDetails.partGroupId)
          : 0;
      }
    }
    try {
      const requestItems = {
        requestItems: [
          {
            groupCode: partgroupId > 0 ? partgroupId : matchingPartNo.groupCode,
            specificCondition:
              selectedSpecificConditions.length > 0
                ? selectedSpecificConditions
                : matchingPartNo.specificCondition,
          },
        ],
      };
      const payload = { requestItems, params: partListPayload };
      const partsDataAction = dispatch(getAllPartByGroupData(payload));
      partsDataAction.then((action) => {
        if (
          action &&
          action.payload &&
          action.payload.partItems &&
          action.payload.partItems.length > 0
        ) {
          setShowSpecificConditions(false);
          const parts = action.payload.partItems;
          addPricingAvailabilityData(parts);
          setSelectedSpecificConditions([]);
        }
        if (
          action &&
          action.payload &&
          action.payload.specificConditions &&
          action.payload.specificConditions.length > 0
        ) {
          const specificCondtns = action.payload.specificConditions.find(
            (data) => {
              return data.groupCode === partDetail.groupId;
            }
          );
          if (
            specificCondtns.partSpecificConditions &&
            specificCondtns.partSpecificConditions.length > 0
          ) {
            setSpecificConditions(specificCondtns.partSpecificConditions);
            setShowSpecificConditions(true);
            specificCondtns.partSpecificConditions.map((qstn, index) => {
              let defaultAnswer = qstn.partPossibleAnswers.find((answer) => {
                return answer.code === "N";
              });
              handleSelectedSpecificConditions(null, qstn, defaultAnswer);
            });
          }
        }
      });
    } catch (error) {}
  };

  const handleSelectedSpecificConditions = (event, question, answer) => {
    const selectedCondtn = {
      code: question.code,
      answer: answer.code,
    };

    let isSelectedConditnExists = selectedSpecificConditions.find((condn) => {
      return condn.code === question.code;
    });

    if (isSelectedConditnExists) {
      isSelectedConditnExists.answer = answer.code;
    } else {
      selectedSpecificConditions.push(selectedCondtn);
    }
  };

  const closeSpecificConditionsPopup = () => {
    setShowSpecificConditions(false);
  };

  useEffect(() => {
    setLegendLetterFlag(false);
    setLegendLetter("");
    initialGetGfxSVGData();
  }, []);

  useEffect(() => {
    setRegisterEventDone(true);
    window.addEventListener("message", gfxSendDataToLocalApp, {
      passive: true,
    });
    return () => window.removeEventListener("message", gfxSendDataToLocalApp);
  }, [registerEventDone, selectedPartDiagramGroup]);

  const groupPartsByManufacturer = (parts) => {
    setPartListByManufacturer([]);
    let groupPartsByMfr = _.mapValues(_.groupBy(parts, "mfgName"), (plist) =>
      plist.map((part) => _.omit(part, "mfgName"))
    );
    setPartListByManufacturer(groupPartsByMfr);
    console.log("groupPartsByMfr" + JSON.stringify(groupPartsByMfr));
    constructPartListTable(groupPartsByMfr);
  };

  const constructPartListTable = (groupPartsByMfr) => {
    const columns = [
      {
        dataField: "partByGroup",
        text: "Manufacturer Name",
        headerStyle: tableHeaderStyle,
      },
    ];
    setPartListTableColumns(columns);
    const rowData = [];
    const expandRows = [];
    _keys(groupPartsByMfr).map((mfrr) => {
      rowData.push({
        partByGroup: mfrr,
      });
      expandRows.push(mfrr);
    });
    setExpandedRows(expandRows);
    setPartListTableRows(rowData);
  };

  const addPricingAvailabilityData = (parts) => {
    let reqItems = [];
    parts.map((part, index) => {
      const lines = part.lineCodes.length > 0 ? part.lineCodes.join(",") : "*";
      reqItems.push({
        mfgCode: lines,
        partNum: part.partNumber,
        qty: part.perCarQty,
      });
    });
    const payload = {
      requestItems: reqItems,
      sellPartnerId: getCartShipDetails(userDetails, checkoutDetails).sellerPartnerId,
      shipMethod: getCartShipDetails(userDetails, checkoutDetails).shipMethod,
      store: getCartShipDetails(userDetails, checkoutDetails).storeNumber,
      dispatch: dispatch,
    };
    try {
      const pricingAvailabilityDataAction = dispatch(
        getPartsPricingAvailability(payload)
      );
      pricingAvailabilityDataAction.then((action) => {
        if (action.payload) {
          console.log(action.payload);
          const pricingAvailabilityData = action.payload;
          let partListTemp = [];
          parts.map((part, index) => {
            let matchingPartNum = pricingAvailabilityData.find((data) => {
              return data.partNum === part.partNumber;
            });
            let availabilityOptions = [];
            partListTemp.push({
              mfgName: part.mfgName,
              partNumber: part.partNumber,
              description: part.description,
              extDescription: part.extendedDescription,
              lineCodes: part.lineCodes.join(","),
              perCarQuantity: part.perCarQty,
              costPrice:
                matchingPartNum && matchingPartNum.costPrice
                  ? matchingPartNum.costPrice
                  : "",
              corePrice:
                matchingPartNum && matchingPartNum.corePrice
                  ? matchingPartNum.corePrice
                  : 0,
              qtyBuyInc:
                matchingPartNum && matchingPartNum.qtyBuyInc
                  ? matchingPartNum.qtyBuyInc
                  : 0,
              coreListPrice:
                matchingPartNum && matchingPartNum.coreListPrice
                  ? matchingPartNum.coreListPrice
                  : 0,
              listPrice:
                matchingPartNum && matchingPartNum.listPrice
                  ? matchingPartNum.listPrice
                  : "",
              availabilityAll:
                matchingPartNum && matchingPartNum.availability
                  ? matchingPartNum.availability
                  : "",
              availabilityForOrderQty: null,
              addToCart: false,
              selectedAvailabilityHours: null,
              selectedQuantity: null,
              groupNumber: part.groupNumber,
              descriptionId: part.descriptionID,
              partSelected: null,
            });
          });
          setPartList(partListTemp); // TODO: Remove duplicate records
          groupPartsByManufacturer(partListTemp);
          setSelectedPartDiagramGroup(partListTemp);
          // dispatch({
          //   payload: {
          //     selectedPartDiagramGroup: partListTemp,
          //   },
          //   type: "PART_DIAGRAM_GROUP_SELECTED",
          // });
        }
      });
    } catch (error) {}
  };

  const updateSelectedAvailableHours = () => {
    let selectedAvailabilityHrs = [];
    // TODO check for validParts Number(part.status) === 1?
    const selectedParts = selectedPartDiagramGroup.filter((part) => {
      return (
        part.addToCart === true &&
        part.selectedAvailabilityHours !== null &&
        part.selectedAvailabilityHours.length > 0 &&
        part.selectedQuantity > 0
      );
    });
    selectedParts.forEach((part) => {
      part.selectedAvailabilityHours.forEach((selectedAvailabilityHour) => {
        selectedAvailabilityHrs.push(selectedAvailabilityHour);
      });
    });
    setSelectedAvailabilityHours(selectedAvailabilityHrs);
  };

  const getAllOEParts = (partGroupId) => {
    const payload = {
      engine: sv.engineId,
      make: sv.makeId,
      model: sv.modelId,
      year: sv.year,
      group: partGroupId,
      pageNumber: -1,
      dispatch: dispatch,
    };
    try {
      const oePartsDataAction = dispatch(getOEPartByGroupData(payload));
      oePartsDataAction.then((action) => {
        const parts = action.payload.pagedItems;
        setOEPartList(parts);
        addPricingAvailabilityData(parts);
      });
    } catch (error) {}
  };

  window.gfxGetDataFromLocalApp = function (assetName) {
    return svgData;
  };

  const gfxSendDataToLocalApp = (event) => {
    const iframeEl = document.getElementById("iframeSVG");
    if (iframeEl && iframeEl.contentWindow === event.source) {
      console.log("message from iframe: " + JSON.stringify(event.data));
      const dataArr = event.data.split("\u001d");
      const partDetailsArr = dataArr[3].split(",");
      const lengendLetterName = dataArr[1];
      const groupColorCode = partDetailsArr[0];
      const partExpertLooupType = partDetailsArr[1];
      const partGroupId = partDetailsArr[2];
      let partDescriptionIds = [];
      setLegendLetter(lengendLetterName);
      for (let i = 3; i < partDetailsArr.length; i++) {
        partDescriptionIds.push(partDetailsArr[i]);
      }
      const partGroupDetails = {
        groupColorCode,
        partExpertLooupType,
        partGroupId,
        partDescriptionIds: partDescriptionIds,
      };
      console.log("partGroupDetails: " + JSON.stringify(partGroupDetails));
      setSelectedPartGroupDetails(partGroupDetails);
      // TODO: Uncomment for blue OE Parts
      // if(groupColorCode.toLowerCase().includes('blue')) {
      //   getAllOEParts(partGroupDetails.partGroupId);
      // }
      if (
        groupColorCode.toLowerCase().includes("green") ||
        groupColorCode.toLowerCase().includes("orange")
      ) {
        highlightSelectedPart(partGroupDetails);
      }
      if (groupColorCode.toLowerCase().includes("red")) {
        initialGetGfxSVGData({
          groupId: partGroupDetails.partGroupId,
          descriptionIds: partGroupDetails.partDescriptionIds,
        });
        setPartListTableColumns([]);
        setPartListTableRows([]);
        setExpandedRows([]);
      }
    }
  };

  const highlightSelectedPart = (partGroupDetails) => {
    let resetPartSelection = selectedPartDiagramGroup.filter((data) => {
      return data.partSelected !== null;
    });
    if (resetPartSelection && resetPartSelection.length > 0) {
      resetPartSelection.map((part, index) => {
        part.addToCart = false;
        part.partSelected = null;
        part.selectedAvailabilityHours = null;
        part.selectedQuantity = null;
        part.availabilityForOrderQty = null;
      });
    }
    const groupNumber = partGroupDetails.partGroupId;
    const descriptionIds = partGroupDetails.partDescriptionIds;
    descriptionIds.map((descriptionId, index) => {
      let matchingPartNums = selectedPartDiagramGroup.filter((data) => {
        return data.descriptionId === descriptionId;
      });
      if (matchingPartNums && matchingPartNums.length > 0) {
        matchingPartNums.map((matchingPartNum, index) => {
          matchingPartNum.addToCart =
            index > 0 ? matchingPartNum.addToCart : true;
          matchingPartNum.partSelected =
            index > 0
              ? partGroupDetails.groupColorCode.replace("dark", "light")
              : partGroupDetails.groupColorCode;
        });
        setMatchingPartNumsArray(matchingPartNums);
        groupPartsByManufacturer(matchingPartNums);
      }
    });
    updateSelectedAvailableHours();
  };

  //TODO: Loader until table data and diagram are shown, highlight the selected red part as filled gree, specific conditions
  const partListTableRowsByMfrr = (mfrrName) => {
    let rows = [];
    partListByManufacturer[mfrrName] &&
      partListByManufacturer[mfrrName].map((mfrr, index) => {
        rows.push({
          partNumber: mfrr.partNumber,
          description: mfrr.description,
          extDescription: mfrr.extDescription,
          lineCodes: mfrr.lineCodes,
          perCarQuantity: mfrr.perCarQuantity,
          costPrice: mfrr.costPrice,
          legendLetter:mfrr.partSelected ? legendLetter:"",
          corePrice: mfrr.corePrice,
          qtyBuyInc: mfrr.qtyBuyInc,
          coreListPrice: mfrr.coreListPrice,
          listPrice: mfrr.listPrice,
          availabilityAll: mfrr.availabilityAll,
          availabilityForOrderQty: mfrr.availabilityForOrderQty,
          addToCart: mfrr.addToCart,
          selectedAvailabilityHours: null,
          selectedQuantity: mfrr.selectedQuantity
            ? mfrr.selectedQuantity
            : null,
          groupNumber: mfrr.groupNumber,
          descriptionId: mfrr.descriptionId,
          partSelected: mfrr.partSelected,
        });
      });
    return rows;
  };

  const orderQtyColFormatter = (cell, row) => {
    let totalAvailqty = 0;
    let uniqueData = _uniqBy(row.availabilityAll, "availableDuration");
    uniqueData.map((singleAvailability, key) => {
      if (singleAvailability.availableQty > 0) {
        totalAvailqty += singleAvailability.availableQty;
      }
    });
    return (
      <span>
        <OverlayTrigger
          placement="right"
          delay={{ show: 100, hide: 400 }}
          overlay={
            row.selectedQuantity > totalAvailqty ? (
              <Tooltip
                id="description-part-catalog-tooltip"
                className="tooltip-description"
              >
                <span className="font-weight-bold">
                  Please enter a quantity less than {totalAvailqty}
                </span>
              </Tooltip>
            ) : (
              <span></span>
            )
          }
        >
          <input
            type="text"
            name="totalQty"
            size="3"
            //autoFocus
            value={row.selectedQuantity}
            onChange={(e) => handleAvailabilitySelection(e, row)}
            onBlur={(e) => {
              handleInputChange(e, totalAvailqty, row);
            }}
            onKeyDown={(event) => {
              handleKeyDown(event);
            }}
          />
        </OverlayTrigger>
      </span>
    );
  };

  const handleKeyDown = (event) => {
    const qtyValue = event.target.value;
    if (
      (numbersOnlyRegExp(event.key) === false ||
        (qtyValue && qtyValue.length >= 4) ||
        event.keyCode === 32) &&
      handleOtherKeys(event.keyCode)
    ) {
      event.preventDefault();
    }
  };

  const handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  const descColFormatter = (cell, row) => {
    return (
      <span>
        <b>{row.description}</b>
      </span>
    );
  };

  const partListTableColumnsByMfrr = () => {
    const columns = [
      {
        dataField: "partNumber",
        text: "Item #",
        headerStyle: tableHeaderStyle,
      },
      {
        dataField: "legendLetter",
        text: "Legend",
        hidden: (legendLetter && legendLetterFlag) ? false : true,
        headerStyle:  tableHeaderStyle ,
      },
      {
        dataField: "lineCodes",
        text: "P/L",
        headerStyle: tableHeaderStyle,
        hidden: true,
      },
      {
        dataField: "description",
        text: "Description",
        formatter: descColFormatter,
        headerStyle: tableHeaderStyle,
      },
      {
        dataField: "perCarQuantity",
        text: "Per\n Car\n Qty",
        headerStyle: tableHeaderStyle,
      },
      {
        dataField: "selectedQuantity",
        text: "Order Qty",
        headerStyle: tableHeaderStyle,
        formatter: orderQtyColFormatter,
      },
      {
        dataField: "extDescription",
        text: "Description",
        hidden: true,
      },
      {
        dataField: "qtyBuyInc",
        text: "qtyBuyInc",
        hidden: true,
      },
      {
        dataField: "costPrice",
        text: "Your Price",
        hidden: hideAllPrice,
        headerStyle: tableHeaderStyle,
      },
      {
        dataField: "corePrice",
        text: "Core Price",
        hidden: hideAllPrice,
        headerStyle: tableHeaderStyle,
      },
      {
        dataField: "listPrice",
        text: "List Price",
        hidden: hideAllPrice,
        headerStyle: tableHeaderStyle,
      },
      {
        dataField: "extendedPrice",
        text: "Extended Total Price",
        hidden: hideAllPrice,
        headerStyle: tableHeaderStyle,
        //classes: "bootstrap-table-bold-cell",
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <span>Total Extended Price</span>
              &nbsp;&nbsp;
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 400 }}
                overlay={
                  <Tooltip
                    id="extended-price-tooltip"
                    className="custom-tooltip-availability"
                  >
                    <span className="font-weight-bold">
                      Total Extended Price = Qty * (Your Price + Core Price)
                    </span>
                  </Tooltip>
                }
              >
                <i class="fa fa-info-circle" style={{ fontSize: "19px" }}></i>
              </OverlayTrigger>
            </div>
          );
        },
        formatter: function (cell, row, index) {
          if (row.selectedQuantity > 0) {
            return (
              <div>
                <span
                >
                  $
                  {getExtendedTotalPrice(
                    row.corePrice,
                    row.costPrice,
                    row.selectedQuantity
                  )}
                </span>
              </div>
            );
          }
          return <span></span>;
        },
      },
      {
        dataField: "availabilityAll",
        text: "Availability",
        hidden: true,
      },
      {
        dataField: "availabilityForOrderQty",
        text: "Availability",
        headerStyle: tableHeaderStyle,
        formatter: availabilityColFormatter,
      },
      {
        dataField: "selectedAvailabilityHours",
        text: "Selected Availability",
        hidden: true,
      },
      {
        dataField: "addToCart",
        text: "Add To Cart",
        headerStyle: tableHeaderStyle,
        formatter: addToCartColFormatter,
      },
      {
        dataField: "partSelected",
        text: "Part Selected",
        hidden: true,
      },
    ];
    return columns;
  };

  // TODO
  const validateQty = (quantity, availableQuantity, validity) => {
    validity.doesQtyExist = !isInputBlank(quantity);
    validity.isQtyMoreThanOne = isValidPositiveNumber(quantity);
    validity.isQtyLessThanAvailable = isValidAvailableQty(
      quantity,
      availableQuantity
    );
    return validity;
  };

  const handleInputChange = (event, totalAvailqty, row) => {
    if (row.qtyBuyInc > 1) {
      let matchingPartNum = selectedPartDiagramGroup.find((data) => {
        return data.partNumber === row.partNumber;
      });
      let value = getPartPackQty(
        row.qtyBuyInc,
        event.target.value,
        totalAvailqty
      );
      row.selectedQuantity = value;
      matchingPartNum.selectedQuantity = getPartPackQty(
        row.qtyBuyInc,
        event.target.value,
        totalAvailqty
      );
      if (value !== event.target.value) {
        qtyByINCUpdateSuccessMessage();
      }
    }
  };

  const qtyByINCUpdateSuccessMessage = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = displayQtyChangeMsg();
    statusMessageProps.type = "success";
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  };

  const getWhichCart = () => {
    let orginalCart = {};
    if(isForCandK) {
      orginalCart.checkoutDetails = _cloneDeep(candkCheckoutDetails);
    } else {
      orginalCart = _cloneDeep(cart);
    }  
    return orginalCart
  }


  const handleAvailabilitySelection = (event, row) => {
    let {
      target: { value },
    } = event;
    let selectedAvailabilityHours = [];
    let availabilityRow = [];
    let { shipTos } = userDetails;
   
    // validity = validateQty(value, partsQtySum, validity);
    let totalOrderQty = Number(value);
    let orderArrayForAvailabilityRow = distributeAvailability(value, row.availabilityAll);
    row.mfgCode = row.lineCodes;
    let cloneCart = getWhichCart();
    let distributionData = getDistributionForVehicle(
      row.partNumber,
      row,
      row.availabilityAll,
      totalOrderQty,
      sv,
      cloneCart
    );
    let orderArray = distributeAvailability2(
      Number(distributionData.totalQty),
      distributionData.filteredAvailability
    );
    selectedAvailabilityHours.push(...distributionData.alreadyAddedItem); 
    const { code, categoryId } = _find(selectedGroupDetails, {
      code: Number(groupId),
    });
    orderArrayForAvailabilityRow.map((item) => {
      availabilityRow.push(getAvailabilityObject(item, row, code, categoryId));
   });
    orderArray.map((item) => {
      selectedAvailabilityHours.push(getAvailabilityObject(item, row, code, categoryId));
    });
    // orderArray.map((item) => {
    //   selectedAvailabilityHours.push({
    //     availabilityHour: item.availableDuration,
    //     availableQty: item.availableQty,
    //     cartFlag: "no",
    //     categoryId,
    //     costPrice: row.costPrice,
    //     corePrice: row.corePrice,
    //     qtyBuyInc: row.qtyBuyInc,
    //     coreListPrice: row.coreListPrice,
    //     desc: row.description,
    //     groupId: code,
    //     listPrice: row.listPrice,
    //     mfgCode: row.lineCodes,
    //     packQty: row.perCarQuantity,
    //     partNum: row.partNumber,
    //     qty: item.orderQty,
    //     vehicleInfo: `${sv.year} ${sv.make} ${sv.model} ${sv.engine}`,
    //     shipMethod: shipMethod,
    //     store: storeNumber,
    //     sellPartnerId: sellerPartnerId,
    //   });
    // });

    const partNum = row.partNumber;
    let matchingPartNum = selectedPartDiagramGroup.find((data) => {
      return data.partNumber === partNum;
    });
    row.selectedAvailabilityHours = selectedAvailabilityHours;
    matchingPartNum.selectedAvailabilityHours = selectedAvailabilityHours;
    row.selectedQuantity = value;
    row.availabilityForOrderQty = availabilityRow;
    if (!_isEmpty(matchingPartNum)) {
      matchingPartNum.selectedAvailabilityHours = selectedAvailabilityHours;
      matchingPartNum.selectedQuantity = value;
      matchingPartNum.availabilityForOrderQty = availabilityRow;
      if(MatchingPartNumsArray.length > 0){
        groupPartsByManufacturer(MatchingPartNumsArray);
      }
    }
    updateSelectedAvailableHours();
  };

  const getAvailabilityObject = (item, row, code, categoryId) => {
    let shipMethod = getCartShipDetails(userDetails, checkoutDetails).shipMethod;     
    let storeNumber =  getCartShipDetails(userDetails, checkoutDetails).storeNumber;
    let sellerPartnerId = getCartShipDetails(userDetails, checkoutDetails).sellerPartnerId;

    return {
      availabilityHour: item.availableDuration,
      availableQty: item.availableQty,
      cartFlag: "no",
      categoryId,
      costPrice: row.costPrice,
      corePrice: row.corePrice,
      qtyBuyInc: row.qtyBuyInc,
      coreListPrice: row.coreListPrice,
      desc: row.description,
      groupId: code,
      listPrice: row.listPrice,
      qtyPerVeh: row.qtyPerVeh,
      mfgCode: row.lineCodes,
      packQty: row.perCarQuantity,
      partNum: row.partNumber,
      qty: item.orderQty,
      vehicleInfo: `${sv.year} ${sv.make} ${sv.model} ${sv.engine}`,
      shipMethod: shipMethod,
      store: storeNumber,
      sellPartnerId: sellerPartnerId,
      makeId: sv.makeId,
      make: sv.make,
      modelId: sv.modelId,
      model: sv.model,
      engineId: sv.engineId,
      engine: sv.engine,
      yearId: sv.year,
      partType: "Regular",
      distStatus: "Completed",
      orderType: "REGULAR",
      storeAvailability:item.storeAvailability
    }  
  }

  //we may need to change later
  const getStoreAvailibility = (val, availability) => {
    let dataArr = [];
    if (val == null) {
      return dataArr;
    }
    const defaultTotalValue = !_isEmpty(val.toString()) ? val : "1";
    let availabilityByStore = [];
    availability &&
      availability.map((singleAvailability) => {
        if (
          singleAvailability.availableDuration == "Your Store" ||
          singleAvailability.availableDuration == "Stores in Your Area"
        ) {
          singleAvailability.storeAvailabilities &&
            singleAvailability.storeAvailabilities.map(
              (singleStoreAvailability) => {
                availabilityByStore.push(singleStoreAvailability);
              }
            );
        } else {
          availabilityByStore.push(singleAvailability);
        }
      });
    let data = distributeAvailabilityByStore(
      defaultTotalValue,
      availabilityByStore
    );
    let defaultStore =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeNumber
        ? "20" + userDetails.shipTos[0].location.storeNumber
        : "";
    data.map((tempData) => {
      if (tempData.isStoreAvailability) {
        dataArr.push(
          <Fragment>
            <span>
              <span className="store-maroon-text avail-qty-width">
                {tempData.orderQty}
              </span>
              <span className="text-black tph-store-avail-align">
                {defaultStore == tempData.store
                  ? " Available at Your Store #"
                  : " Available at Store #"}
                <b>{tempData.store}</b>
              </span>
            </span>
          </Fragment>
        );
      } else {
        dataArr.push(
          <Fragment>
            <span>
              <span className="store-maroon-text avail-qty-width">
                {tempData.orderQty}
              </span>
              <span className="text-black tph-store-avail-align">
                {" "}
                Available <b>{tempData.availableDuration}</b>
              </span>
            </span>
          </Fragment>
        );
      }
    });
    return dataArr;
  };

  const prepareTooltipAvailability = (availabilityData) => {
    let availabilities = availabilityData;
    let returnArray = [];
    availabilities.map((singleAvailability, key) => {
      if (singleAvailability.availableQty > 0) {
        if (
          singleAvailability.availableDuration === "Next Business Day" ||
          singleAvailability.availableDuration === "Next 2 + Business Days" ||
          singleAvailability.availableDuration === "Next 2 Weeks"
        ) {
          returnArray.push(
            <ul>
              <li>
                <span className="text-white">
                  <span className="store-maroon-text">
                    {singleAvailability.availableQty}
                  </span>
                  &nbsp; &nbsp;
                  {singleAvailability.availableDuration}{" "}
                </span>
              </li>
            </ul>
          );
        } else {
          singleAvailability.storeAvailabilities.map((storeData, storeKey) => {
            if (key != 0) {
              returnArray.push(
                <ul>
                  <li>
                    <span className="text-white">
                      <b>{storeData.storeQty}</b>
                    </span>
                    <span className="text-white">
                      &nbsp;{` at Store #`}
                      <b>{storeData.store}</b>
                      <br />
                      {singleAvailability.availableDuration ===
                        "Direct Order Store" ||
                      singleAvailability.availableDuration ==
                        "Pick Up Store" ? (
                        <b>
                          ({singleAvailability.availableDuration},
                          {storeData.storeQualifier
                            ? storeData.storeQualifier
                            : ""}
                          )
                        </b>
                      ) : null}
                    </span>
                  </li>
                </ul>
              );
            }
          });
        }
      }
    });
    let fullDiv = [];
    fullDiv = <div>{returnArray}</div>;
    return fullDiv;
  };


  const onclick = (tempData) => {
    setStoreData(tempData)
    setShowAvailabilityStoreView(true)
  }

  const availabilityColFormatter = (cell, row) => {
    let storeNumber =  getCartShipDetails(userDetails, checkoutDetails).storeNumber;
    let availabilitySection = [];
    if (row.availabilityAll.length === 0) {
      return (
        <span className="availability_section">Currently out of stock</span>
      );
    }
    availabilitySection = getAvailability(row, row.availabilityAll,{}, {}, userDetails, "TablePop",onclick, row.selectedQuantity, storeNumber)
    return availabilitySection;
  };

  const addToCartColFormatter = (cell, row) => {
    return (
      <span>
        <input
          type="checkbox"
          // class="custom-control-input"
          checked={row.addToCart}
          onChange={(e) => {
            onAddToCartSelect(e, row);
          }}
        />
      </span>
    );
  };

  const onAddToCartSelect = (e, row) => {
    const partNum = row.partNumber;
    let matchingPartNum = selectedPartDiagramGroup.find((data) => {
      return data.partNumber === partNum;
    });
    row.addToCart = e.target.checked;
    matchingPartNum.addToCart = e.target.checked;
    if(MatchingPartNumsArray.length > 0){
      groupPartsByManufacturer(MatchingPartNumsArray);
    }
    updateSelectedAvailableHours();
  };

  const rowStyleWithColor = (row, rowIndex) => {
    const style = {};
    if (!_isEmpty(row.partSelected)) {
      style.backgroundColor =
        row.partSelected === "lightorange" ? "orange" : row.partSelected;
      style.fontWeight = "bold";
      style.color = "white";
      setLegendLetterFlag(true);
    } else {
      style.backgroundColor = "white";
      style.fontWeight = "normal";
      style.color = "black";
      setLegendLetterFlag(false);
    }
    return style;
  };

  const expandRow = {
    renderer: (row) => (
      <BootstrapTable
        keyField="partNumber"
        data={partListTableRowsByMfrr(row.partByGroup)}
        columns={partListTableColumnsByMfrr()}
        rowStyle={rowStyleWithColor}
      />
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expanded: expandedRows,
  };

  const addToCartForCandk = () => {
    dispatch({
      payload: {
        candkCheckoutDetails: selectedAvailabilityHours
      },
      type: actionTypes.CNK_CART_CHECKOUT_FETCHED,
    });
    const statusMessageProps = {
      heading: "Item added to cart",
      message: "",
      type: "success",
    };
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  }


  return (
    <React.Fragment>
      {true ? (
        <XModal
          title="PART DIAGRAM"
          show={openPopup}
          handleClose={closePopup}
          showButtons={false}
        >
          <section class="gfx-section">
            <div class="gfx-container">
              {!_isEmpty(svgDocument) ? (
                <React.Fragment>
                  {/* {userDetails &&
                  userDetails.shipTos &&
                  userDetails.shipTos.length > 0 &&
                  userDetails.shipTos[0].shipMethod ? (
                    <div className="wc-ship-info mx-auto maroonColorTxt"> */}
                      {/* <span>
                        {"All items are Pickup - please pick up at store # " +
                          userDetails.shipTos[0].storeNumber}
                      </span> */}
                      {/* <span>
                        Ship Method:
                        <span className="text-black">
                          {cartShipMethod && cartShipMethod != null
                            ? cartShipMethod == "0002"
                              ? " Pickup "
                              : " Delivery "
                            : userDetails.shipTos[0].shipMethod === "0002" ||
                              userDetails.shipTos[0].shipMethod === "0001"
                            ? " Pickup "
                            : " Delivery "}{" "}
                        </span>
                        From TPH Parts Location :{" "}
                        <span className="text-black">
                          {cartStore
                            ? cartStore +
                              " - " +
                              getstoreName(
                                allStores,
                                cartStore,
                                cartShipMethod,
                                userDetails
                              )
                            : userDetails.shipTos[0].storeNumber +
                              " - " +
                              userDetails.shipTos[0].location.storeName}
                        </span>
                      </span>
                    </div>
                  ) : (
                    ""
                  )} */}
                   <div className="wc-ship-info mx-auto">
                   <ShipInfoHeader/>
                  </div>
                  <h4 class="vector-title">
                    Please select green color parts (if found) in the diagram to
                    proceed
                  </h4>
                  <div className="partDiagram_resetButton">
              <Button
             onClick={handleReset}
              >
                Reset <i class="fa fa-refresh" aria-hidden="true" style={{marginLeft:"10px"}}></i>
              </Button>
              </div><br></br>
                  <div class="vectorWrapper">
                    <div class="vector-iframe-wrapper">
                      <iframe
                        id="iframeSVG"
                        name={assetId}
                        src="about:blank"
                        scrolling="no"
                        frameBorder="2"
                        ref={gfxIframe}
                        onLoad={() => setRegisterEventDone(false)}
                      />
                    </div>
                    <div class="checkbox-tree-wrapper">
                      <div class="checkbox-tree">
                        {partListTableColumns.length > 0 &&
                        partListTableRows.length > 0 ? (
                          <React.Fragment>
                            <BootstrapTable
                              keyField="partByGroup"
                              data={partListTableRows}
                              columns={partListTableColumns}
                              wrapperClasses="vector-table-responsive"
                              expandRow={expandRow}
                              // rowClasses="custom-row-class"
                            />
                            {addToCartWarning ? (
                              <div className="">
                                <Fragment>
                                  <Button
                                    className="add-to-cart-btn"
                                    onClick={() => addToCartForWarning()}
                                    disabled={
                                      selectedAvailabilityHours.length === 0
                                    }
                                  >
                                    Add to cart
                                  </Button>
                                </Fragment>
                              </div>
                            ) : (
                                !isForCandK ? <AddToCart
                                // description={partDetails.description}
                                disabled={
                                  selectedAvailabilityHours.length === 0
                                }
                                selectedAvailabilityHours={
                                  selectedAvailabilityHours
                                }
                                groupNumber={groupId}
                                fromPage={"partDiagram"}
                              />: <Button
                                className="add-to-cart-btn"
                                onClick={() => addToCartForCandk()}
                                disabled={_isEmpty(selectedAvailabilityHours)}
                              >
                                Add to cart
                              </Button>
                            )}
                            <Button
                              className="cancel-vector-illustration"
                              variant="light"
                              onClick={closePopup}
                            >
                              Cancel
                            </Button>
                          </React.Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </section>
        </XModal>
      ) : null}
      {showSpecificConditions &&
      specificConditions &&
      specificConditions.length > 0 ? (
        <XModal
          show={showSpecificConditions}
          className="specific__condition_modal"
          customHeader={
            <Modal.Title id="warning-modal-title">
              Please select an Item specific to continue
            </Modal.Title>
          }
          submitType
          favoriteTextValidity={true}
          handleAction={() => getPartList()}
        >
          <React.Fragment>
            <div className="">
              {specificConditions &&
                specificConditions.map((condition, cIndex) => {
                  return (
                    <div className="dynamic-col" key={`group__${cIndex}`}>
                      <Fragment>
                        <Fragment>
                          {!condition.answer && (
                            <div
                              className="specific__condition"
                              key={`${condition.text}`}
                            >
                              <div className="bold">{condition.text}</div>
                              {condition.partPossibleAnswers.map(
                                (item, index) => {
                                  return (
                                    <Form.Check
                                      type="radio"
                                      name={`${cIndex}_option_${condition.text.replace(
                                        / /g,
                                        "_"
                                      )}`}
                                      label={`${item.text}`}
                                      id={`${item.code}_${cIndex}`}
                                      defaultChecked={item.code === "N"}
                                      onChange={(e) => {
                                        handleSelectedSpecificConditions(
                                          e,
                                          condition,
                                          item
                                        );
                                      }}
                                    />
                                  );
                                }
                              )}
                            </div>
                          )}
                        </Fragment>
                      </Fragment>
                    </div>
                  );
                })}
            </div>
          </React.Fragment>
        </XModal>
      ) : null}
      {addToCartWarningPopup ? (
        <AddToCartWarningPopup
          openPopup={addToCartWarningPopup}
          selectedAvailabilityHours={selectedAvailabilityHours}
          source={"partCategory"}
          DefaultAddItemToCart={false}
          closePopup={() => {
            setaddToCartWarningPopup(false);
          }}
        />
      ) : null}
      {showAvailabilityStoreView ?
        (<AvailabilityStoreView
        openPopup={showAvailabilityStoreView}
        closePopup={() => setShowAvailabilityStoreView(false)}
        storeData={storeData}
        />
        )
        : null }
    </React.Fragment>
  );
};

export default VectorGfxIllustrationPopup;
