import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  Spinner,
  Container,
} from "react-bootstrap";

import AddToCartWarningPopup from "../AddToCartWarningPopup/AddToCartWarningPopup";
import CoverToCoverPopup from "../../Listing/CoverToCoverPopup";
import SingleInterchange from "./SingleIntetrchange";
import AddToCart from "../AddToCart/AddToCart";
import Placeholder from "../Placeholder";

import ResetIcon from "../../../static/images/reset.png";

import _isEmpty from "lodash/isEmpty";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import _every from "lodash/every";
import _filter from "lodash/filter";
import _find from "lodash/find";
import { useDispatch } from "react-redux";

import {
  getInterchangeData,
  getInterChangeBrandOptions,
  getInterChangePartTypeOptions,
  getC2CUrl,
} from "../../../actions/parts";

function InterchangeAccordian(props) {
  const dispatch = useDispatch();

  const {
    partBrand,
    partLevel,
    catalog,
    isStockCheck,
    interchangeData,
    userDetails,
    brandList,
    partTypeList,
    dualBoxView,
    interchangeApiCall,
    cartShipMethod,
    cartStore,
    handleCheckBox,
    partNumber,
    activeBrand,
    activePart,
    handleDropDownSelection,
    handleCoverToCover,
    shipInfo,
    validity,
    selectedBrand,
    selectedPartType,
    resetBrandsAndParts,
    interchangeApiCallOnGoing,
    selectedstore
    // renderNoDataState,
  } = props;

  const renderBrandAndPartFilter = (hasData) => {
    return (
      <div className="left-section-interchange" >
        {dualBoxView && brandList && brandList.length > 0 ? (
          <React.Fragment>
            <div className="interchange-dual-list">
              <div className="">
                <div>
                  <span>
                    <b>Reset Brands and Part {"          "}Types</b>
                  </span>
                  <img
                    className="reset-part-brand"
                    src={ResetIcon}
                    alt="reset"
                    onClick={(e) => resetBrandsAndParts()}
                  />
                </div>
                <br />
                <div className="dual-list list-left">
                  <div className="well text-right">
                    <div className="row">
                      <div className="col-md-8">
                        <span>
                          <b>Brands</b>
                        </span>
                      </div>
                    </div>
                    <ul className="list-group brand-list">
                      {brandList &&
                        brandList.length > 0 &&
                        brandList.map((value, index) => {
                          return (
                            <li
                              className={
                                value.id == activeBrand
                                  ? "list-group-item brand-active-item"
                                  : "list-group-item"
                              }
                              onClick={(option) =>
                                handleDropDownSelection(
                                  partNumber,
                                  value,
                                  "brand",
                                  interchangeData,
                                  brandList,
                                  partTypeList
                                )
                              }
                            >
                              {value.label}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>

                <br />
                <div className="dual-list list-right">
                  <div className="well text-right">
                    <div className="row">
                      <div className="col-md-8">
                        <span>
                          <b>Part Type</b>
                        </span>
                      </div>
                    </div>
                    <ul className="list-group part-list">
                      {partTypeList &&
                        partTypeList.length > 0 &&
                        partTypeList.map((value, index) => {
                          return (
                            <li
                              className={
                                value.id == activePart
                                  ? "list-group-item brand-active-item"
                                  : "list-group-item"
                              }
                              onClick={(option) =>
                                handleDropDownSelection(
                                  partNumber,
                                  value,
                                  "partType",
                                  interchangeData,
                                  brandList,
                                  partTypeList
                                )
                              }
                            >
                              {value.label}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  };

  const renderNoDataState = () => {
    return (
      <Card className="text-center no-data-card">
        <Card.Header className="xlp-primary-banner">No Parts Found</Card.Header>
        <Card.Body>
          <Card.Title>
          We couldn't find the parts that you were looking for
          </Card.Title>
        </Card.Body>
      </Card>
    );
  };

  const renderPartsData = () => {
    if (!_isEmpty(interchangeData) && !interchangeApiCall) {
      let productArray = [];

      if (interchangeData && interchangeData.length > 0) {
        interchangeData.map((part) => {
          let appendPart =
            !catalog &&
            ((part.alternate && part.alternate.length > 0) ||
              (part.substitute && part.substitute.length > 0));
          let altPartNumsArr = [];
          let subPartNumsArr = [];
          const altPartNums =
            part.alternate && part.alternate.length > 0
              ? part.alternate.map((alt) => {
                  altPartNumsArr.push(alt.partNumber);
                })
              : "";
          const subPartNums =
            part.substitute && part.substitute.length > 0
              ? part.substitute.map((sub) => {
                  subPartNumsArr.push(sub.partNumber);
                })
              : "";
          part.isSelected = false;
          productArray.push(
            <SingleInterchange
              partBrand={partBrand}
              singlePart={part}
              handleCoverToCover={handleCoverToCover}
              partType={"Regular"}
              partLevel={partLevel}
              handleCheckBox={handleCheckBox}
              appendPart={appendPart}
              altPartNums={`${altPartNumsArr.join(",")}`}
              subPartNums={`${subPartNumsArr.join(",")}`}
              isStockCheck={isStockCheck}
              userDetails={userDetails}
              selectedstore={selectedstore}
            />
          );
          //alternate parts
          if (!catalog && part.alternate && part.alternate.length > 0) {
            part.alternate.map((altPart, index) => {
              let appendAltPart =
                part.alternate.length - 1 ||
                (part.substitute && part.substitute.length > 0);
              productArray.push(
                <SingleInterchange
                  partBrand={partBrand}
                  singlePart={altPart}
                  handleCoverToCover={handleCoverToCover}
                  partType={"altPart"}
                  partLevel={partLevel}
                  handleCheckBox={handleCheckBox}
                  appendPart={appendAltPart}
                  isStockCheck={isStockCheck}
                  userDetails={userDetails}
                  selectedstore={selectedstore}
                />
              );
            });
          }
          //substitute parts
          if (!catalog && part.substitute && part.substitute.length > 0) {
            part.substitute.map((subPart, index) => {
              let appendSubPart = index !== part.substitute.length - 1;
              productArray.push(
                <SingleInterchange
                  partBrand={partBrand}
                  singlePart={subPart}
                  handleCoverToCover={handleCoverToCover}
                  partType={"subPart"}
                  partLevel={partLevel}
                  handleCheckBox={handleCheckBox}
                  appendPart={appendSubPart}
                  isStockCheck={isStockCheck}
                  userDetails={userDetails}
                  selectedstore={selectedstore}
                />
              );
            });
          }
        });
      }
      return productArray;
    } else {
      return renderNoDataState();
    }
  };

  return (
    <>
      {!interchangeApiCall ? (
        <React.Fragment>
          {_isEmpty(interchangeData) && interchangeApiCall === false ? (
            <Col md={{ span: 8, offset: 2 }}>{renderNoDataState()}</Col>
          ) : (
            <React.Fragment>
              {/* <p
                className={
                  dualBoxView
                    ? "disclaimer_msg dual-box-viewd"
                    : "disclaimer_msgd"
                }
              ></p> */}

              {dualBoxView ? (
                <div className="interchange-boxcontainer">
                  {/* <Row> */}
                    {renderBrandAndPartFilter()}
                    <div
                      // lg={!dualBoxView ? 12 : 9}
                      // md={!dualBoxView ? 12 : 7}
                      className="right-section-interchange"
                    >
                      {renderPartsData(true)}
                    </div>
                  {/* </Row> */}
                </div>
              ) : null}
              {!dualBoxView ? renderPartsData() : null}
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Placeholder rows={2} />
        </React.Fragment>
      )}
    </>
  );
}

InterchangeAccordian.propTypes = {};

export default InterchangeAccordian;
