import { createSlice } from "@reduxjs/toolkit";

import {
    fetchsiteNotification,
} from "./sitenotification.thunk";

let initialState = {
    notificationError: false,
    notification: null,
};

const sitenotification = createSlice({
    name: "sitenotification",
    initialState,
    extraReducers: {
        [fetchsiteNotification.pending]: (state) => {
            state.notificationError = false;
        },
        [fetchsiteNotification.fulfilled]: (state, action) => {
            state.notificationError = false;
            state.notification = action.payload;
        },
        [fetchsiteNotification.rejected]: (state) => {
            state.notificationError = true;
        },
    }
});

const { reducer } = sitenotification;

export default reducer;