/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Overlay, Modal } from 'react-bootstrap';
import _isEmpty from "lodash/isEmpty";
import moment from 'moment';
import _ from 'lodash';
import Xmodal from '../XModal';
import {
    fetchsiteNotification,
} from "./sitenotification.thunk";

import { fetchQuotes } from "../MyGarage/mygarage.thunks";
import { Fragment } from "react";
import bellIcon from '../../../static/images/Bell.png';

const QUOTES_EXPIRE_MESSAGE = "Some quotes will be expired shortly. Please check Quotes in \"My Garage\" section and take action.";

const SiteNotification = (props) => {
    const dispatch = useDispatch()
    const notificationArray = useSelector((state) => state.sitenotification.notification);
    const user = useSelector(state => state.user);
    const [notifications, setNotifications] = useState([]);
    const PAGE_SIZE = 100;
    const PAGE_NUM = 1;
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [tobeExpiredData, setExpiredData] = useState([]);

    useEffect(() => {
        if (!_isEmpty(notificationArray)) {
            notificationArray.siteNotificationMessages.forEach((data) => {
                notifications.push(data.message);
            });
            setNotifications(notifications);
        }

    }, [notificationArray, notifications])

    const initialFetchQuotes = (clickedPage) => {
        let pageNum = PAGE_NUM;
        const payload = {
            shipToId:
                (user.userDetails && user.userDetails.shipTos && user.userDetails.shipTos[0] && user.userDetails.shipTos[0].shipToId),
            pageNum: pageNum,
            pageSize: PAGE_SIZE,
        };
        try {
            const quotesAction = dispatch(fetchQuotes(payload));
            quotesAction.then((action) => {
                const quotes = action?.payload?.pagedItems || [];
                let tobeExpired = [];
                quotes.forEach(quote => {
                    const d = moment.duration(moment(quote.expirationDate).diff(moment()));
                    if (d.days() >= 0 && d.days() <= 7) {
                        tobeExpired.push(quote);
                    }
                });
                const res = tobeExpired.map((quote) => {
                    const d = moment.duration(moment(quote.expirationDate).diff(moment()));
                    return {
                        ...quote,
                        quoteNameUppercase: quote.name.toUpperCase(),
                        expiry: d.days(),
                    }
                })
                setExpiredData(res);
                // if (tobeExpired.length > 0) {
                //     const found = _.find(notifications, QUOTES_EXPIRE_MESSAGE)
                //     if (!found) {
                //         notifications.push(QUOTES_EXPIRE_MESSAGE);
                //         setNotifications(notifications);
                //     }
                // }
            });
        } catch (error) { }
    };

    useEffect(() => {
        dispatch(fetchsiteNotification());
    }, []);

    useEffect(() => {
        if(!_isEmpty(user.userDetails.userType) && user.userDetails.userType != undefined && user.userDetails.userType !== "STORE_USER"){
            initialFetchQuotes();
        }
    }, [user.userDetails]);

    const showNotification = () => {
        setShow(true);
    }



    const submitQuotes = () => {
        setShow(false);
    }

    return (
        <Fragment>
            <div className="width_100">
                {props.showNotification && (<marquee className="col-lg-10"
                    scrolldelay={notificationArray && (notificationArray.speedInSec || '')}
                    direction={notificationArray && (notificationArray.viewOrder === "RL" ? "left" : "right")}
                    bgcolor="black">
                    <span style={{ whiteSpace: "pre" }}>{notifications && notifications.join((notificationArray && notificationArray.separator) || ' ')}</span>
                </marquee>)}
                {!props.showNotification && tobeExpiredData && tobeExpiredData.length > 0 ?
                    <img
                        src={bellIcon}
                        className="bell-icon"
                        onClick={(event) => {
                            showNotification(event);
                        }}
                        alt="Show"
                        ref={target}
                    />
                    : ''}

                            
                            {show ? (
                                <Xmodal
                                    show={show}
                                    customHeader={
                                        <React.Fragment>
                                            <Modal.Title
                                                id="warning-modal-title"
                                                className="expiry-heading"
                                            >
                                                Quotes expiring within 7 days
								            </Modal.Title>
                                        </React.Fragment>}
                                    handleClose={() => {
                                        setShow(false);
                                    }}
                                    handleAction={() => {
                                        submitQuotes();
                                    }}
                                    submitType
                                    className="notification_center"
                                    submitButtonText='OK'
                                    favoriteTextValidity={true}
                                >
                                    <section>
                                        <Container>
                                            <div className="expiry-wrapper">{!_isEmpty(tobeExpiredData) && tobeExpiredData.map(quote =>
                                            (<Fragment>
                                                <p className="expiredData">{quote.expiry === 0 ? `${quote.quoteNameUppercase}` + ", expiring today" : `${quote.quoteNameUppercase}` + ", expires in " + `${quote.expiry}` + " days"}</p>
                                            </Fragment>)
                                            )}</div>
                                        </Container>
                                    </section>
                                </Xmodal>

                            ) : ''}
                        </div>
        </Fragment>
    );
};

export default SiteNotification;
