import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import _isEmpty from "lodash/isEmpty";

import "./styles.scss";
import { fetchOrders } from "../mygarage.thunks";
import Placeholder from "../../Placeholder";
import {
  isAlphanumeric,
  hasMinLength,
  formatAmount,
} from "../../../../helpers/utils";
import FormValidationErrorMessage from "../../../shared/FormValidationErrorMessage/FormValidationErrorMessage";

const Orders = (props) => {
  const { closeOverlay } = props;
  const dispatch = useDispatch();

  const initializeValidity = () => {
    const validity = {
      isSearchAlphanumeric: true,
      isSearchMinLimit: true,
    };
    return validity;
  };

  const [validity, setvalidity] = useState(initializeValidity);
  const userDetailsArr = useSelector((state) => state.user);
  const mygarage = useSelector((state) => state.mygarage);
  // const trackOrderDetails = useSelector((state) => state.cart.trackOrderDetails);
  const orders = mygarage.orders;

  const initialFetchOrders = () => {
    try {
      dispatch(fetchOrders());
    } catch (error) {}
  };

  const handleSearch = (searchText) => {
    dispatch(fetchOrders({ searchParam: searchText }));
  };

  const handleTrackOrderClick = (orderStatus, orderNumber) => {
    window.open(
      orderStatus
        ? `/order-delivery-status/${orderNumber}`
        : "",
      "_blank"
    );
  };

  useEffect(() => {
    initialFetchOrders();
  }, []);
  // console.log("orders ==> ", mygarage);
  const handleCartOrderDetailsClick = (acxNum) =>{
    window.open(
         `/cart-order-details/${acxNum}`
    );

  }
  const handleSearchOrderChange = (event) => {
    const searchData = event.target.value;
    let validity = initializeValidity();
    validity.isSearchAlphanumeric = isAlphanumeric(searchData);
    validity.isSearchMinLimit = hasMinLength(searchData, 1);
    setvalidity(validity);
  };

  const handleSearchOrderBlur = () => {
    let validity = initializeValidity();
    setvalidity(validity);
  };

  return (
    <React.Fragment>
      <div className="orders-wrapper">
        <Row>
          <Col md="7">
            <div className="search-container">
              <Form.Control
                placeholder="Search Order's Invoice #"
                className="input__search input__control"
                onKeyUp={(e) => handleSearch(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
                onMouseOver={(e) => e.stopPropagation()}
                onChange={handleSearchOrderChange}
                onBlur={handleSearchOrderBlur}
              />
              <FormValidationErrorMessage
                condition={
                  !validity.isSearchAlphanumeric && validity.isSearchMinLimit
                }
                errorMessage="Search key must be alphanumeric only"
              />
              <FormValidationErrorMessage
                condition={!validity.isSearchMinLimit}
                errorMessage="Search key should have atleast 1 character"
              />
            </div>
          </Col>
          <Col md="2">
            <div className="will_call_text">
              {!_isEmpty(userDetailsArr) &&
              (userDetailsArr.userDetails.shipTos[0].shipMethod === "0001"||userDetailsArr.userDetails.shipTos[0].shipMethod === "0002")
                ? "PICK UP"
                : ""}
            </div>
          </Col>
        </Row>
        {mygarage.ordersLoading ? (
          <Placeholder rows={1} />
        ) : orders && orders.length ? (
          <React.Fragment>
            <div className="mygarage__content">
              {orders.map((v, i) => {
                return (
                  <div className="mygarage__vehicle" key={`${v.name}__${i}`}>
                    <Row>
                      <Col md="4"> 
                           <div>                  
                          # of Parts: <span className="">{v.noOfPartSKUs}</span> 
                          </div> 
                        <div>
                          Total QTY: <span className="">{v.orderQty}</span>
                        </div>
                        <div>
                          Amount:
                          <span className="order__amount">
                            ${formatAmount(v.orderAmount)}
                          </span>
                        </div>
                        {v.orderDate && (
                        <div>
                          Order Date:{" "}
                          <Moment format="MM-DD-YYYY" date={v.orderDate} />
                        </div>)}
                        {/* <div>Location : {v.shipToLoc}</div>
                        <div>Ship To : {v.shipToNo}</div> */}
                        <div></div>
                      </Col>
                      <Col md="3">
                      { (!_isEmpty(v.acxNum) && v.acxNum) && (
                         (v.rapidOrder == true ) ?

                          <div className="track__link mr-2">
                              <Link
                                onClick={() => {
                                  handleCartOrderDetailsClick(
                                    v.acxNum
                                  );
                                }}
                              >
                              ACX #: <span className="">{v.acxNum}</span>
                              </Link>
                            </div> :
                          <div>
                            ACX #: <span className="">{v.acxNum}</span>
                          </div>
                        )}
                        {v.poNumber && (
                          <div>
                            PO #: <span className="">{v.poNumber}</span>
                          </div>
                        )}
                          {v.orderNumber && (
                        <div>
                          Order #: <span className="">{v.orderNumber}</span>
                        </div> )}
                        <div>
                          {/* username: <span className="">{v.userName}</span> */}
                        </div>
                      </Col>
                      <Col md="5">
                      <div className={!_isEmpty(v.invoiceNos) ? "text__right d-flex" : "text__right"}>
                          {!_isEmpty(v.invoiceNos) && (
                            <div className="track__link mr-2">
                              <Link
                                onClick={() => {
                                  handleTrackOrderClick(
                                    v.orderStatus,
                                    v.orderNumber
                                  );
                                }}
                              >
                               {v.orderStatus === "DELIVERED" ||
                                v.orderStatus === "COMPLETED" ||
                                v.orderStatus === "PARTIALLY_COMPLETED" ||
                                v.orderStatus === "CANCELED"
                                  ? "Order Details"
                                  : "Track Order"}
                              </Link>
                            </div>
                          )}
                          <div className={v.orderStatus === 'RECEIVED' ? 'received_text_align' : ''}>
                            <span
                              className={`order__status ${v.orderStatus === "DELIVERED"
                                  ? "delivered"
                                  : v.orderStatus === "COMPLETED" || v.orderStatus === "PARTIALLY_COMPLETED" || v.orderStatus === "CANCELED" ? "completed" : "received"
                                }  ${_isEmpty(v.invoiceNos) ? 'mr-5' : ''} `}
                            >
                              {v.orderStatus}
                            </span>
                          </div>
                        </div> 
                        {v.invoiceNos && v.invoiceNos.length > 0 && (
                          <div>
                            <span >{(!_isEmpty(v.invoiceNos) && v.invoiceNos.length > 1) ? `Invoices: ` : (!_isEmpty(v.invoiceNos) && v.invoiceNos.length == 1) ? `Invoice: ` :" "}</span> 
                            {v.invoiceNos.map((inv , key)=>(
                          !_isEmpty(v.invoiceNos) && (_isEmpty(inv.invoiceUrl)) ? (
                            <span>{(v.invoiceNos.length > 1 && (key !== v.invoiceNos.length - 1)) ? inv.invoiceNumber.concat(", ")   : inv.invoiceNumber}</span>)
                             :
                             (!_isEmpty(v.invoiceNos) && !_isEmpty(inv.invoiceUrl) && (  
                                <Link
                                  onClick={() => {
                                    window.open(
                                      inv.invoiceUrl,
                                      "_blank"
                                    );
                                  }}
                                >
                                  <span className={!_isEmpty(inv.invoiceUrl) ? "cart_track_invoice_order_link": " "}>{(v.invoiceNos.length > 1 && (key !== v.invoiceNos.length - 1)) ? inv.invoiceNumber.concat(", ") : inv.invoiceNumber}</span>
                                </Link>
                              ))
                            ))}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        ) : (
          <div className="no-quotes">
            {mygarage.ordersError ? (
              <span className="error">{mygarage.ordersError.message}</span>
            ) : (
              "You have not placed any orders, place an order from the cart."
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Orders;
