import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getBenefitDetailsService,
  getProgramsListDetails,
  getProgramsAndStatmentService,
  getBatteryStockDetailsService,
  getProgramBenefitsHelpService,
  getDashboardService,
} from "./programbenefits.service";

export const benefitDetails = createAsyncThunk(
  "benefitDetails",
  async (payload) => {
    const response = await getBenefitDetailsService(payload);

    return response.data;
  }
);

export const programsListDetails = createAsyncThunk(
  "programsListDetails",
  async (payload) => {
    const response = await getProgramsListDetails(payload);

    return response.data;
  }
);

export const programsStatementDetails = createAsyncThunk(
  "programsStatementDetails",
  async (payload) => {
    const response = await getProgramsAndStatmentService(payload);

    return response.data;
  }
);

export const getBatteryStockDetails = createAsyncThunk(
  "getBatteryStockDetails",
  async (payload) => {
    const response = await getBatteryStockDetailsService(payload);

    return response.data;
  }
);

export const programDocumentDetails = createAsyncThunk(
  "getProgramBenefitsHelpService",
  async (payload) => {
    const response = await getProgramBenefitsHelpService();

    return response.data;
  }
);

export const getDashboard = createAsyncThunk(
  "getDashboard",
  async (payload) => {
    const response = await getDashboardService(payload);

    return response.data;
  }
);
