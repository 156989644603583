import { createTheme } from "@mui/material";

export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#0f5283",
      dark: "#0f5283",
    },
    secondary: {
      main: "#0f5283",
    },
    error: {
      main: "#0f5283",
    },
    success: {
      main: "#0f5283",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: "bold",
      },
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;