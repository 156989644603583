import React, { useEffect, useState } from "react";
import { Col, Form, Button, Spinner } from "react-bootstrap";
import { Textbox } from "ui-library";
import "./styles.scss";
import _, { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  postCKPlaceOrder,
  getCKReviewSelectedOrder,
  getCKReviewEnteredOrderDetails,
  getReviewCKSearchData,
} from "../c_and_k.thunk";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";
import {
  formatAmount,
  formatAmountPrograms,
  currencyFormatter,
  getCnKPartsSum
} from "../../../helpers/utils";
import CKReviewPopUp from "../Checkout/CK_ReviewPopUp";
import ThankyouPopup from "./ThankyouPopup";
import { resetLoader } from "../c_and_k.slice";
import _filter from "lodash/filter";
import _isEmpty from 'lodash/isEmpty';
import XModal from '../../../components/shared/XModal';
import RefreshDataPopup from '../../../components/Checkout/RefreshDataPopup';

const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER", CNK_CART_CHECKOUT_FETCHED: 'CNK_CART_CHECKOUT_FETCHED', };

function CartPricingDetails(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const { warrantyData, warrantyId, isCallType, candkCheckoutDetails } = props;

  const candk = useSelector((state) => state.candk);
  const user = useSelector((state) => state.user);

  const userDetails = user.userDetails;
  const shipTo =
    (userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos.length > 0 &&
      userDetails.shipTos[0]) ||
    {};
  const {
    cksearch = {},
    ckorder = { show: false },
    ckEnteredDetails: {
      servicecell = "",
      customerContact = "",
      serviceemail = "",
      customername = "",
      phone = "",
      mileage = "",
      vehicleOwnerName = "",
      email = "",
      VIN = "",
    } = {},
    placeOrderData = {},
    loading = false,
    candkPartPrice
  } = candk;
  const [shippingInfo, setShippingInfo] = useState({});
  const [warrantyInfo, setWarrantyInfo] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [authorization, setAuthorization] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [orderID, setOrderID] = useState("");
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [redirectToHomePage, setRedirectToHomePage] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalState, setModalState] = useState({
    orderWarning: false,
    cartDistributionWarning: false,
  });

  const schema = yup.object({
    authorization: yup
      .number()
      .typeError("authorization must be a number")
      .required("authorization is required"),
  });
  const {
    handleSubmit,
    control,
    register,
    formState: { isValid },
    trigger,
    reset,
    clearErrors,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    dispatch({
      payload: {
        globalLoader: loading,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
  }, [dispatch, loading]);

  useEffect(() => {
    if (redirectToHomePage) {
      dispatch({
        payload: {
          globalLoader: false,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
      history.push("/");
    }
  }, [redirectToHomePage, history]);

  useEffect(() => {
    if (
      !isEmpty(candk.shippingData) &&
      !isEmpty(candk.shippingData.shippingCosts)
    ) {
      setShippingInfo(candk.shippingData.shippingCosts[0]);
      let subtotal =
        Number(candk?.ckorder?.customerPrice) -
        Number(candk.shippingData.shippingCosts[0].shippingCost);
      setSubTotal(subtotal);
    }
    if (!isEmpty(warrantyData)) {
      setWarrantyInfo(warrantyData);
    }
  }, [candk]);
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     history.push("/ck_partsearch");
  //   };

  //   // Attach the event listener for beforeunload
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [history]);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    // Use the 'name' attribute of the input field to distinguish between the two fields
    // if (name === "authorization") {
    setAuthorization(value);
    // }
    // else if (name === "contactNumber") {
    //   setContactNumber(value);
    // }
    await trigger(name); // Manually trigger validation for the field
    clearErrors(name); // Clear the error for this field
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  const handleCloseFromCheckout = () => {
    dispatch(
      getCKReviewSelectedOrder({ ...ckorder, show: false, fromCheckout: false })
    );
  };
  const candkWarranty = ckorder?.warranties;
  let warnt = _filter(candkWarranty, (p) => warrantyId === p.warrantyId);
  const handleReviewOrder = () => {
    dispatch(
      getCKReviewSelectedOrder({ ...ckorder, show: true, fromCheckout: true })
    );
  };
  function getFirstTwoCharacters(inputString) {
    if (typeof inputString === "string" && inputString.length >= 2) {
      return inputString.substring(0, 2);
    } else {
      // Handle the case when the input is not a valid string or shorter than 2 characters
      return "";
    }
  }
  const isPOPatternValid = (str) => {
		const poRegex = userDetails && userDetails.billTo && userDetails.billTo.poRegex;
		if (poRegex && !_isEmpty(poRegex)) {
			var expression = new RegExp(poRegex);
			return expression.test(str);
		}
		return true;
	};

	const isPurchOrdOPatternConfigFound = (str) => {
		const poPattern = userDetails && userDetails.billTo && userDetails.billTo.poPattern;
		const poRegex = userDetails && userDetails.billTo && userDetails.billTo.poRegex;
		if (poPattern && (!poRegex || _isEmpty(poRegex))) {
			return false;
		}
		return true;
	};
  const handlePlaceOrder = async () => {
    handleModalClose('orderWarning');
    handleModalClose('cartDistributionWarning');
    const { vin, currentMileage, address } = cksearch;
    const payload = {
      repairFacility: {
        addressDetails: {
          postalCode: shipTo.zip || "",
          stateCode: shipTo.state || "",
          // countryCode: address?.countryCode || "",
          address1: shipTo.address,
          address2: shipTo.address,
          city: shipTo.city,
          countryCode: getFirstTwoCharacters(shipTo.country),
        },
        contactCell: servicecell,
        contactName: customerContact,
        contactEmail: serviceemail,
        facilityName:`[${userDetails.billTo.billToId}] - ${customername}`,
        facilityPhone: phone,
        facilityFax: "",
      },

      customerInformation: {
        adjuster: customerContact,
        adjusterEmail: email,
        authorizationNumber: authorization.trim(),
        // contractNumber: contactNumber,
        currentMileage: mileage,
        // eocDate: "2023-07-24T07:50:29.605Z",
        // eocMileage: "",
        note: "",
        ownerName: vehicleOwnerName,
        repairOrderNumber: "",
        vin: VIN || "",
      },

      parts: [
        {
          quoteId: ckorder?.quoteId || "",
          quantity: 1,
          warrantyId: warrantyData?.warrantyId,
          partNumber: ckorder?.quotedPartNumber,
          partDescription: ckorder?.description,
        },
      ],

      // orderType: ckorder?.partCatalog
      orderType: ckorder?.partCatalog === "UsedPowertrain" ? "Used" : "Reman",
      orderNow: isCallType,
      cartItems: candkCheckoutDetails
    };

    if (validateForm()) {
      try {
        let response = await dispatch(postCKPlaceOrder(payload));
        if(response?.payload?.cartItems && !_isEmpty(response?.payload?.cartItems)) {
         dispatch({
          payload: {
            candkCheckoutDetails: response.cartItems
          },
          type: actionTypes.CNK_CART_CHECKOUT_FETCHED,
          });
          setModalState((prevState) => ({ ...prevState, ['cartDistributionWarning']: true }));
          return;
        }
        setOrderID(response?.payload?.orderId);
        if (response?.payload?.orderId) {
          setSuccessPopUp(true);
        } else {
          addToast(
            response?.payload?.errorMessage ||
              "Oops! Something went wrong. Please try again later.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
        // Further processing of the response or other actions
      } catch (error) {
        // Handle any errors that occurred during the API call or dispatch
        addToast(error?.errorMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      console.log("Form is not valid. Please fill in all required fields.");
    }
  };
  const validateForm = () => {
    const newErrors = {};

    // Validate mileage field
    if (!authorization.trim()) {
      newErrors.authorization = "PO Number required";
    } else if (!isPOPatternValid(authorization)) {
      newErrors.authorization = "The PO Number you entered does not match the PO pattern you have designated with XL Parts. Please enter Upper Case letters only. For security reasons, we can not allow the order to be placed nor can we tell you the correct PO pattern. Please call your XL Parts store or XL Parts Accounts Receivable at 713-983-1159 for assistance."
    } else if (!isPurchOrdOPatternConfigFound(authorization)) {
      newErrors.authorization = "PO Validation configuration is missing. Please contact support."
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleClose = () => {
    const payload = {
      vin: VIN || "",
      currentMileage: mileage.replace(/,/g, "") || "",
      partType: ckorder?.partRequested?.searchType,
      address: {
        zipCode: shipTo.zip,
        stateCode: shipTo.state,
        countryCode: shipTo.country,
        address: shipTo.address,
        city: shipTo.city,
        state: shipTo.state,
      },
      // parts: [
      //   {
      //     part: {
      //       searchType: page.replace(/ /g, ""),
      //     },
      //     quantity: 1,
      //   },
      // ],
    };
    setRedirectToHomePage(true);
    setSuccessPopUp(false);
    dispatch(getCKReviewEnteredOrderDetails({}));
    dispatch(getCKReviewSelectedOrder({}));
    dispatch(resetLoader({ loader: false }));
    dispatch(getReviewCKSearchData(payload));
    dispatch({
      payload: {
        globalLoader: false,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
  };

  const handleModalClose = (modalType) => {
    setModalState((prevState) => ({ ...prevState, [modalType]: false }));
  };
  
  const handleModalOpen = (modalType) => {
    if(validateForm()) {
      if(!_isEmpty(candkCheckoutDetails)) {
      setModalState((prevState) => ({ ...prevState, [modalType]: true }));
      } else {
        handlePlaceOrder();
      }
    }
  };
  
  const handlePlaceOrderSubmit = () => {
    handleModalClose('orderWarning');
    handlePlaceOrder();
  };
  
  const handleCartPlaceOrder = () => {
    handleModalClose('cartDistributionWarning');
    handlePlaceOrder();
  };

  return (
    <Col lg="3" className="ck_purchase_order_container">
      <Form>
        <Form.Group className="ck_purchase_order_input_container">
          <Form.Label className="maroonColorTxt bold ordersummaryheading">
            Order Summary
          </Form.Label>
        </Form.Group>
      </Form>
      <React.Fragment>
        <div>
          <div className="pricing_details_table">
            <div>
              <span>Item Subtotal&nbsp;</span>
              <span className="maroonColorTxt bold">
                {getCnKPartsSum(subTotal, candkPartPrice)}
              </span>
            </div>
            {/* <div className="ck_total_price">
              <span>Warranty&nbsp;</span>
              <span className="maroonColorTxt bold ml-3">
                $
                {warrantyInfo && warrantyInfo.warrantyPrice
                  ? formatAmount(warrantyInfo.warrantyPrice)
                  : formatAmount(0)}
              </span>
            </div> */}
            <div className="ck_total_price">
              <span>Shipping&nbsp;</span>
              <span className="maroonColorTxt bold">
                {shippingInfo && shippingInfo.shippingCost
                  ? currencyFormatter(shippingInfo.shippingCost)
                  : currencyFormatter(0)}{" "}
              </span>
            </div>
            <div className="ck_total_price_bordered">
              <span>Return Shipping&nbsp;</span>
              <span className="maroonColorTxt bold">
                {" "}
                {shippingInfo && shippingInfo.coreShippingCost
                  ? currencyFormatter(shippingInfo.coreShippingCost)
                  : currencyFormatter(0)}{" "}
              </span>
            </div>
            <div className="ck_price">
              <span>
                Grand Total <br></br>(Pre Tax price)&nbsp;
              </span>
              <span className="maroonColorTxt bold">
                {getCnKPartsSum(candk.ckorder && candk.ckorder.customerPrice ? candk.ckorder.customerPrice
                  : 0, candkPartPrice)}
              </span>
            </div>
            <br></br>
            <Form.Group className="ck_purchase_order_input_container">
              {/* <Form.Label className="maroonColorTxt bold ordersummaryheading">
                Contact Information
              </Form.Label> */}
            </Form.Group>
            <div className="ck_contact_information">
              <Grid item xs={6}>
                <div className="flex-container">
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Textbox
                        {...field}
                        fullWidth
                        name="authorization"
                        value={authorization}
                        onChange={handleChange}
                        label="PO Number*"
                        inputProps={{ style: { height: "12px" } }} // Adjust the font size
                        className="ck_textfield textFieldStyle"
                        InputLabelProps={{
                          style: {
                            display: "flex",
                            alignItems: "center",
                            // marginTop: "-5px",
                            fontSize: "14px",
                          },
                        }}
                        error={!!errors.authorization}
                        helperText={errors.authorization || ""}
                      />                      
                    )}
                  />
                </div>
              </Grid>
            </div>

            {/* <Form.Group className="ck_purchase_order_input_container ck_input_wrapper">
                <Form.Label className="maroonColorTxt bold ck_contactlabel">
                  PO#
                </Form.Label>
                <Form.Control
                  // maxLength="150"
                  // className="input_upperCase"
                  type="text"
                  className="ck_contactinput"
                  name="authorization"
                  value={authorization}
                  onChange={handleChange}
                />
              </Form.Group>
              {/* <Form.Group className="ck_purchase_order_input_container ck_input_wrapper">
                <Form.Label className="maroonColorTxt bold ck_contactlabel">
                  Contact# (opt)&nbsp;
                </Form.Label>
                <Form.Control
                  // className="input_upperCase"
                  type="text"
                  className="ck_contactinput"
                  name="contactNumber"
                  value={contactNumber}
                  onChange={handleChange}
                />
              </Form.Group> */}
            {/* </div> */}

            {/* <div className="ck_total_price">
              <span>Authorization# or PO# &nbsp;</span>
              <span className="maroonColorTxt bold">
                <input />
              </span>
            </div>
            <div className="ck_total_price">
              <span>Contact# (opt)&nbsp;</span>
              <span className="maroonColorTxt bold">
                <input />
              </span>
            </div> */}
          </div>
          {!loading ? (
           <div className="candk-zindex-buttons"> 
            <Button
              className="maroonBtn xl_parts_btn display-prod-btn ck_placeorder_button"
              size="sm"
              block
              onClick={()=> {handleModalOpen('orderWarning')}}
            >
              Place Order
            </Button>
            </div>
          ) : (
            <div className="candk-zindex-buttons">
            <Button
              className="maroonBtn xl_parts_btn display-prod-btn ck_placeorder_button "
              size="sm"
              block
              disabled
            >
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>
            </div>
          )}
          <div className="candk-zindex-buttons">
          <Button
            className="maroonBtn xl_parts_btn display-prod-btn ck_review_button"
            size="sm"
            block
            onClick={handleReviewOrder}
          >
            Review Order
          </Button>
          </div>
        </div>
      </React.Fragment>
      <ThankyouPopup
        openPopup={successPopUp}
        orderConfirmationNumber={orderID}
        closePopup={handleClose}
      />
      {ckorder?.show && ckorder?.fromCheckout && (
        <CKReviewPopUp
          show={ckorder?.show}
          handleClose={handleCloseFromCheckout}
          isCallType={props.isCallType}
          warrantyId={warrantyId}
        />
      )}
      {modalState.orderWarning && (
        <XModal
          show={modalState.orderWarning}
          handleClose={() => handleModalClose('orderWarning')}
          handleAction={handlePlaceOrderSubmit}
          className="custom_xmodal"
          favoriteTextValidity={true}
          submitType={true}
          submitButtonText="OK"
        >
          <div className="delete__text text__center candkwarning_text">
            {'Please note that install parts in your order will be shipped separately.'}
            <br />
          </div>
        </XModal>
      )}
      {modalState.cartDistributionWarning && !_isEmpty(candkCheckoutDetails) && (
        <XModal
          show={modalState.cartDistributionWarning}
          handleAction={handleCartPlaceOrder}
          handleClose={() => handleModalClose('cartDistributionWarning')}
          className="custom_xmodal"
          isValidValidity={true}
          yesButtonText="Yes"
          noButtonText="No"
        >
          <div className="delete__text text__center ">
            There are changes in the Cart items due to inventory changes since you add items to cart. Do you want to proceed?
            <br />
          </div>
          <RefreshDataPopup checkoutDetails={candkCheckoutDetails} />
        </XModal>
      )}
    </Col>
  );
}
export default CartPricingDetails;
