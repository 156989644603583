import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _findIndex from 'lodash/findIndex';
import { InputGroup, DropdownButton, Form, Spinner, Button } from 'react-bootstrap';
import Select from 'react-select';
import { triggerCustomUxCriticalError } from "../../../actions/error";
// import {
//     updateToken,
//     fetchUserDetails,
// } from '../../../actions/auth';
import {
    getUsersList,
    getModifyUserDetails,
    updateUserDetails,
    getUserPreferenceDetails,
    updateUserPreferenceDetails
} from '../UserManagement/usermanagement.thunk';
import {
    fetchShipToIds
} from "../../shared/ShipTo/shipTo.thunk";
import {
    populateUserDropDownOptions,
} from '../../../helpers/utils';
import {
    setModifyUser,
    enableModifyUserLoader
} from '../UserManagement/usermanagement.slice';
import _ from "lodash";

const userTypeList = [
    {
        value: 'LOCATION_BUYER',
        label: 'Location Buyer',
    },
    {
        value: 'CUSTOMER_BUYER',
        label: 'Customer Buyer',
    },
    {
        value: "VISITOR",
        label: "Visitor",
      }
];

const ModifyUser = () => {
    let usersList = useSelector((state) => state.userManagement.usersList);
    let ShipToIds = useSelector((state) => state.ShipToIds.shipToIds);
    let selectedUser = useSelector((state) => state.userManagement.selectedModifyUser);
    const [selectedModifyUser, setselectedModifyUser] = useState({});
    const [selectedLocation, setselectedLocation] = useState([]);
    const [userModified, setuserModified] = useState(false);
    const [modifyUserPref, setModifyUserPref] = useState([]);
    const [grandAccessAnswer, setGrandAccessAnswer] = useState([]);
    const [grandAccessValue, setGrandAccessValue] = useState([])
    const [grandAccessOptions, setGrandAccessOptions] = useState([]);
    const [modifyUserSelected, setModifyUserSelected] = useState(null);
    let user = useSelector((state) => state.user.userDetails);
    const UserUpdate = useSelector((state) => state.userManagement.UserUpdate)
    const isUpdating = useSelector((state) => state.userManagement.isUpdating)
    const refreshModifyUser = useSelector((state) => state.userManagement.refreshModifyUser)
    const [userValue, setUserValue] = useState(null)
    const dispatch = useDispatch();
    let shipToList = !_isEmpty(ShipToIds)
        ? populateUserDropDownOptions(ShipToIds)
        : [];
    useEffect(() => {
        if (!_isEmpty(user)) {
            const payload = {
                customerId: user.billTo.billToId,
            };
            const shipToPayload = {
                billtoid: user.billTo.billToId,
            }
            dispatch(getUsersList(payload));
            dispatch(fetchShipToIds(shipToPayload));
        }
    }, [user]);

    useEffect(() => {
        let findUserType =  userTypeList.findIndex(item => item.value === "SERVICE_WRITER");
        if( user && user.userType === "CUSTOMER_ADMIN" && findUserType === -1 && findUserType !== undefined){
          userTypeList.push({      
                  value: "SERVICE_WRITER",
                  label: "Service Writer",           
            })
        }else
        if(user.userType === "SUPER_ADMIN" && findUserType !== -1 && findUserType !== undefined ){
            userTypeList.splice(findUserType, 1);
        }
      }, [user.userType]);
      
    useEffect(() => {
        let tempArr = [];
        setselectedModifyUser(selectedUser);
        if (!_isEmpty(selectedUser)) {
            selectedUser.shipTos.map((shipTo) => {
                tempArr.push(shipTo.shipToId)
            });
            setselectedLocation(tempArr)
        } else {
            resetFields();
        }
    }, [selectedUser]);

    useEffect(() => {
        resetFields();
    }, [refreshModifyUser]);

    useEffect(() => {
        if (!_isEmpty(user)) {
            let statusMessageProps = {
                heading: "",
                message: "",
                type: "",
            };
            if (UserUpdate === 'success' && !_isEmpty(selectedUser)) {
                setuserModified(false);
                dispatch(setModifyUser(false))
                statusMessageProps.heading = 'User Modified Successfully';
                statusMessageProps.type = 'success';
                triggerCustomUxCriticalError(statusMessageProps, dispatch);
            } else if (UserUpdate === 'failed' && !_isEmpty(selectedUser)) {
                statusMessageProps.heading = 'User Modification Failed';
                statusMessageProps.type = 'error';
                triggerCustomUxCriticalError(statusMessageProps, dispatch);
            }
            const payload = {
                customerId: user.billTo.billToId,
            };
            dispatch(getUsersList(payload));
        }
    }, [UserUpdate]);

    const createDropDownOptions = () => {
        let optionArr = [];
        if(!_isEmpty(usersList)) {
            usersList.map((userL) => {
                let deletedUser =  userL.x2userName && userL.x2userName.split('|');
                let deletedUserName = deletedUser.length > 1 ? deletedUser [0] : '';
                if(user && user.userType === "CUSTOMER_ADMIN"){
                if ( (userL.userType !== "CUSTOMER_ADMIN" && userL.userType !== "SUPER_ADMIN")
                && (userL.userType == 'LOCATION_BUYER' || userL.userType == 'CUSTOMER_BUYER' || userL.userType == 'SERVICE_WRITER' || userL.userType === "VISITOR") && userL.x2userName !== "deleted_user") {
                    if((deletedUserName !== "deleted_user" )) {
                    optionArr.push({
                        value: userL.x2userName,
		                label: userL.x2userName,
                    });
                  }
                }
            } else
                if(user && user.userType === "SUPER_ADMIN"){
                    if ( (userL.userType !== "CUSTOMER_ADMIN" && userL.userType !== "SUPER_ADMIN")
                    && (userL.userType == 'LOCATION_BUYER' || userL.userType == 'CUSTOMER_BUYER') && userL.x2userName !== "deleted_user") {
                        if((deletedUserName !== "deleted_user" )) {
                        optionArr.push({
                            value: userL.x2userName,
                            label: userL.x2userName,
                        });
                      }
                    }
                }
            
            });
        }
        return optionArr;
    }

    const handleGrandAccessChange = (value)=>{
        let tempArr = [];
        if(!_isEmpty(value)){
            value.map((val) => {
                tempArr.push(val);
            });
            setGrandAccessValue(tempArr);
        }else{
            setGrandAccessValue([]);
        }
        setuserModified(true);
        let updatedUserPref = _.cloneDeep(modifyUserPref);
        
        let grandAccessIndex = updatedUserPref.findIndex((item)=>{
            return(
             item.text == "Grant Access To" && item.code == "User Management") 
        })
        let superAdminGrandAcessIndex =  updatedUserPref.findIndex((item)=>{
            return(
                item.text == "Personalize Linked Accounts" && item.code == "User Management" ) 
        })
        let grandAccessItemSet = updatedUserPref[grandAccessIndex];
        let superAdminGrandAcessSet = updatedUserPref[superAdminGrandAcessIndex];

        let multiAnswer = [];
        let superAdminmultiAnswer = [];
        tempArr.map((option)=>{
            if((user.userType !== "CUSTOMER_ADMIN" && user.userType === "SUPER_ADMIN" ) && superAdminGrandAcessSet && superAdminGrandAcessSet.id === option.id){
                superAdminmultiAnswer.push(option.value);
            }else if(grandAccessItemSet && grandAccessItemSet.id === option.id){
                multiAnswer.push(option.value);
            }
            
        })
        let data =[];
         data.push({
            id:grandAccessItemSet.id, 
            code:grandAccessItemSet.code, 
            answer:null, 
            multiAnswer:multiAnswer   
        })
        if((user.userType !== "CUSTOMER_ADMIN" && user.userType === "SUPER_ADMIN" ) && superAdminGrandAcessSet ){
        data.push({
            id:superAdminGrandAcessSet.id, 
            code:superAdminGrandAcessSet.code, 
            answer:null, 
            multiAnswer:superAdminmultiAnswer   
        })
    }
        setGrandAccessAnswer(data);
    }

    const handleUserSelection = (event) => {
        const payload = {
            x2Username: event.value,
        };
        setUserValue({ value: event.value, label: event.label });
        setselectedModifyUser({});
        dispatch(enableModifyUserLoader());
        dispatch(getModifyUserDetails(payload));

        const userDetails = {
            username: event.value
        }
        setModifyUserSelected(userDetails);
        dispatch({
            payload: {
                status: true,
            },
            type: "REFRESH_TOKEN_STATUS",
        });
        dispatch(getUserPreferenceDetails(userDetails)).then((res) => {
            dispatch({
                payload: {
                    status: false,
                },
                type: "REFRESH_TOKEN_STATUS",
            });
            setModifyUserPref(res.payload);
            createGrandAccessOptions(res.payload);
            setGrandAccessAnswer([])
        });
    }

    const createGrandAccessOptions =(itemArray)=>{
        let grandAccessItemSet =[];
         let grandAccess = !_isEmpty(itemArray) && itemArray.find((item)=>{
            return(
                item.text == "Grant Access To" &&
                item.code == "User Management"
            ) 
        })
    let superAdmingrandAccessItem = (user.userType !== "CUSTOMER_ADMIN" && user.userType === "SUPER_ADMIN" )&& !_isEmpty(itemArray) && itemArray.find((item)=>{
      return(item.text == "Personalize Linked Accounts" && item.code == "User Management" ) 
        
    })
    grandAccessItemSet.push(grandAccess)
    if(user.userType !== "CUSTOMER_ADMIN" && user.userType === "SUPER_ADMIN" ){
        grandAccessItemSet.push(superAdmingrandAccessItem);  
    }     
        if( !_isEmpty(grandAccessItemSet) ){
            let multiAnswer = [];
            grandAccessItemSet.map(( grandAccessItem)=>{
            grandAccessItem.multiAnswer.length>0 &&   grandAccessItem.multiAnswer.map((singleMultiAnswer)=>{
                let multiOptionValue= {
                    value: singleMultiAnswer.code,
                    label: singleMultiAnswer.text,
                    default : singleMultiAnswer.default,
                    id :grandAccessItem.id
                   }
                   multiAnswer.push(multiOptionValue);
                
            })
            setGrandAccessValue(multiAnswer);
        })}       
        else{
            setGrandAccessValue([]);  
        }
        let options =[];
        grandAccessItemSet &&  grandAccessItemSet.map(( grandAccessItem)=>{
        grandAccessItem.possibleMultiAnswer.map((item)=>{
            let singleOption ={
                value: item.code,
                label: item.text,
                default : item.default,
                id :grandAccessItem.id           
               }
               options.push(singleOption);
        })})
        setGrandAccessOptions(options);
    }
    

    const resetFields = () => {
        setUserValue(null);
        setGrandAccessValue([])
        setselectedModifyUser({});
        setselectedLocation([]);
        setuserModified(false);
    }

    const handleSubmit = () => {
        const {
            active,
            x2userName,
            firstName,
            lastName,
            mobileNumber,
            userType,
            xlPartAccountNumber,
            shipTos,
        } = selectedModifyUser;
        let payload = {
            active,
            email: x2userName,
            firstName,
            lastName,
            mobileNumber,
            shipToIds: shipTos,
            userType,
            xlPartAccountNumber,
        };
        let updatedShipToObj = {};
        if (!_isEmpty(shipTos) && shipTos.length > 1) {
            shipTos.map((obj) => {
                updatedShipToObj[obj.value.toString()] = (obj.isDefault === true);
            });
        } else if (!_isEmpty(shipTos)) {
            shipTos.map((obj) => {
                updatedShipToObj[obj.value.toString()] = true;
            });
        }
        payload.shipToIds = updatedShipToObj;
        dispatch(enableModifyUserLoader());
        dispatch(updateUserDetails(payload));
        resetFields();
        let data = { requestItems: grandAccessAnswer, params: modifyUserSelected }
        dispatch(updateUserPreferenceDetails(data))
    }

    return (
        <Fragment>
            {isUpdating && (
                <div className="custom_spinner_container catalog_spinner">
                    <Spinner className="custom_spinner" animation="border" />
                </div>
            )}
            <div className="label-dropdown-container">
                <Form.Label>Users</Form.Label>
                <Select
                    options={!_isEmpty(usersList) && createDropDownOptions()}
                    placeholder="Select a User"
                    className="custom-dropdown"
                    value={!_isEmpty(userValue) && userValue}
                    onChange={handleUserSelection}
                />
            </div>
            <div className="label-dropdown-container">
                <Form.Label>User Type</Form.Label>
                <Select
                    options={userTypeList}
                    className="custom-dropdown"
                    placeholder="Select user type"
                    isDisabled={_isEmpty(selectedModifyUser)}
                    value={!_isEmpty(selectedModifyUser) && userTypeList.filter(
                        (type) => selectedModifyUser.userType.includes(type.value)
                    )}
                    onChange={(event) => {
                        setselectedModifyUser({ ...selectedUser, userType: event.value });
                        setuserModified(true);
                    }}
                />
            </div>
            <div className="label-dropdown-container">
                <Form.Label>Locations</Form.Label>
                {(!_isEmpty(selectedModifyUser) && (selectedModifyUser.userType === 'CUSTOMER_BUYER'))
                    ? (
                        <Select
                            options={shipToList}
                            className="custom-dropdown"
                            placeholder="Select location"
                            isDisabled={_isEmpty(selectedModifyUser)}
                            isMulti
                            value={!_isEmpty(selectedModifyUser) && selectedModifyUser.shipTos}
                            onChange={(value) => {
                                let tempArr = [];
                                value.map((val) => {
                                    tempArr.push(val.value);
                                });
                                setselectedModifyUser({
                                    ...selectedModifyUser,
                                    shipTos: value,
                                });
                                tempArr.length === 0
                                    ? setuserModified(false)
                                    : setuserModified(true);
                                setselectedLocation(tempArr);
                            }}
                        />
                    ) : (
                        <Select
                            options={shipToList}
                            className="custom-dropdown"
                            placeholder="Select location"
                            isDisabled={_isEmpty(selectedModifyUser)}
                            value={!_isEmpty(selectedModifyUser) && shipToList.filter(
                                (shipTo) => selectedModifyUser.shipTos[0].shipToId.includes(shipTo.value)
                            )}
                            onChange={(event) => {
                                let tempArr = [];
                                tempArr.push(_find(ShipToIds, (shipTo) => (
                                    shipTo.shipToId === event.value
                                )))
                                setselectedModifyUser({ ...selectedModifyUser, shipTos: tempArr });
                                setuserModified(true);
                            }}
                        />
                    )}

            </div>
            {(!_isEmpty(selectedModifyUser) && (selectedModifyUser.userType === 'CUSTOMER_BUYER'))
                && (
                    <div className="label-dropdown-container">
                        <Form.Label>Default Location</Form.Label>
                        <Select
                            className="custom-dropdown"
                            options={shipToList.filter(
                                (shipTo) => selectedLocation.includes(shipTo.value)
                            )}
                            isDisabled={_isEmpty(selectedModifyUser)}
                            value={selectedModifyUser.shipTos.find(location => location.isDefault === true)}
                            onChange={(event) => {
                                let userIndex = null;
                                userIndex = _findIndex(selectedModifyUser.shipTos, {
                                    value: event.value,
                                });
                                let tempArr = selectedModifyUser.shipTos;
                                tempArr = tempArr.map((data, key) => ({
                                    ...data,
                                    isDefault: (key === userIndex),
                                }));
                                setselectedModifyUser({
                                    ...selectedModifyUser,
                                    shipTos: tempArr,
                                });
                                setuserModified(true);
                            }}
                        />
                    </div>
                )}
            <div className="label-dropdown-container">
                <Form.Label>Grant Access To</Form.Label>
                <Select
                    options={(!_isEmpty(grandAccessOptions) && selectedModifyUser && selectedModifyUser.userType === "SERVICE_WRITER") ? grandAccessOptions :
                    grandAccessOptions.filter((item)=>  item.label !== "Order Placement")}
                    value={(!_isEmpty(grandAccessValue) && selectedModifyUser.userType === "SERVICE_WRITER") ? grandAccessValue :
                    grandAccessValue.filter((item)=>  item.label !== "Order Placement")}
                    placeholder="Select Grant Access To"
                    className="custom-dropdown"
                    isMulti
                    onChange={(value) => { handleGrandAccessChange(value) }}
                    isDisabled={_isEmpty(selectedModifyUser)}
                />
            </div>
            <Button
                disabled={!userModified}
                onClick={handleSubmit}
                className="user-management-submit-btn ok-button"
            >
                ok</Button>
        </Fragment>
    );
}

export default ModifyUser;
