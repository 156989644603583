import React from 'react';
import { Row, Col, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import _find from 'lodash/find';
import _toString from 'lodash/toString';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _every from 'lodash/every';
import _uniqBy from 'lodash/uniqBy';
import _sumBy from 'lodash/sumBy';
import _remove from 'lodash/remove';
import _includes from 'lodash/includes';

import { connect } from 'react-redux';
import { addItemToCart, addItemToCart2 } from '../../../actions/cart';
import {
	isInputBlank,
	isValidAvailableQty,
	isValidPositiveNumber,
	distributeAvailability,
	formatAmount,
	formatTimeAvb,
	numbersOnlyRegExp,
	getExtendedTotalPrice,
	timeInUTC,
	getPartPackQty,
	displayQtyChangeMsg,
	getImageURLCookieDetails,
	hidePrice,
	distributeAvailability2,
	getDistributionForNonVehicle,
	getShipInfo,
	isStockOrder
} from '../../../helpers/utils';
import LostSales from '../../ShoppingCart/LostSales';
import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import PricingIcons from '../../Listing/PricingIcons';
import { setPartHistory } from '../../shared/MyGarage/mygarage.thunks';
import AddToCartWarningPopup from "../../shared/AddToCartWarningPopup/AddToCartWarningPopup";
import { triggerCustomUxCriticalError } from "../../../actions/error";
import _cloneDeep from 'lodash/cloneDeep';
import AvailabilityStoreView from "../../shared/AvailabilityStoreView";
import { getAvailability } from "../../../helpers/availabilityService";
const actionTypes = {
	SELECTED_ADDCART_DATA: 'SELECTED_ADDCART_DATA',
	ADDTO_CART_WARNING: "ADDTO_CART_WARNING"
};

class SingleSupplyItem extends React.Component {
	constructor(props) {
		super(props);
		let {
			partDetails: {
				pricingAvailability: { availability },
			},
		} = props;
		let maxQty = !_isEmpty(availability)
			? availability.reduce(
				(item1, item2) => item1 + item2['availableQty'],
				0
			)
			: 0;
		this.state = {
			extendedPrice: 0,
			selectedAvailabilityHours: [],
			maxQty,
			preveousOrderQty: 0,
			totalOrderQty: 0,
			showLostSales: false,
			extendedPriceHighlighter: false,
			validity: this.intializeValidations(),
			addToCartWarningPopup: false,
			cartShipMethod: props.cartShipMethod ? props.cartShipMethod : null,
			selectedStore: props.selectedStore ? props.selectedStore : getShipInfo(this.props.userDetails, this.props.parts).storeNumber,
			sellPartnerId: props.sellPartnerId ? props.sellPartnerId : getShipInfo(this.props.userDetails, this.props.parts).sellerPartnerId,
			shipMethod: props.shipMethod ? props.shipMethod : getShipInfo(this.props.userDetails, this.props.parts).shipMethod,
			customerShipMethod: this.props.userDetails && this.props.userDetails.shipTos && this.props.userDetails.shipTos.length > 0 && this.props.userDetails.shipTos[0].shipMethod && this.props.userDetails.shipTos[0].shipMethod ? this.props.userDetails.shipTos[0].shipMethod : "",
			showAvailabilityStoreView:false,
     		storeData:"",
			isMobileEnabled: this.props.userDetails?.shipTos[0]?.isMobileEnabled,
			hideAllPrice :  this.props.parts?.hidePrice
		};
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	intializeValidations() {
		this.validity = {
			doesQtyExist: true,
			isQtyMoreThanOne: true,
			isQtyLessThanAvailable: true,
		};
		return this.validity;
	}

	validateQty(quantity, availableQuantity, validity) {
		validity.doesQtyExist = !isInputBlank(quantity);
		validity.isQtyMoreThanOne = isValidPositiveNumber(quantity);
		validity.isQtyLessThanAvailable = isValidAvailableQty(
			quantity,
			availableQuantity
		);
		return validity;
	}

	handleInputChangeValue(event) {
		let { maxQty, preveousOrderQty, totalOrderQty, validity } = this.state;
		let value;
		if (!_isEmpty(event)) {
			value = event.target.value;
		}
		value = event.target.value;

		totalOrderQty = Number(value);
		preveousOrderQty = Number(value);
		validity = this.validateQty(value, maxQty, validity);
		this.setState({
			...this.state,
			totalOrderQty,
			preveousOrderQty,
			validity,
		});
	}

	handleInputChange(event) {
		let { extendedPrice, maxQty, totalOrderQty, validity, selectedStore, preveousOrderQty, sellPartnerId, shipMethod } = this.state;
		let {
			partDetails,
			selectedGroupDetails,
			categorySuppliesMap,
			userDetails,
			cart,
			parts,
			proPack
		} = this.props;
		let { pricingAvailability } = partDetails;

		let {
			target: { value },
		} = event;
		let orderArray = [];let selectedAvailabilityHours = [];
		let selectedAvailabilityHoursForPropack = [];
		let distributionData = {};
		maxQty = _sumBy(
			_uniqBy(pricingAvailability.availability, 'availableDuration'),
			'availableQty'
		);
		validity = this.validateQty(value, maxQty, validity);
		totalOrderQty = Number(value);

		if (!_isEmpty(event)) {
			if (!_isEmpty(value) && Number(value) !== 0) {
				let ca = getPartPackQty(
					pricingAvailability.qtyBuyInc,
					Number(totalOrderQty),
					maxQty
				);
				totalOrderQty = ca;
				if (preveousOrderQty !== totalOrderQty &&
					pricingAvailability.qtyBuyInc > 1) {
					this.qtyByINCUpdateSuccessMessage();
					preveousOrderQty = totalOrderQty;
				}
			} else {
				totalOrderQty = Number(value);
				preveousOrderQty = Number(value);
			}
		}
		if (validity) {
			extendedPrice = totalOrderQty * pricingAvailability.costPrice;
            let cloneCart = _cloneDeep(cart);
            if(shipMethod !== getShipInfo(userDetails, parts).shipMethod) {
                cloneCart.checkoutDetails = [];
            } 
			if (partDetails.pricingAvailability.qtyBuyInc > 1) {
				distributionData = getDistributionForNonVehicle(
					partDetails.partSKU, partDetails, pricingAvailability.availability, Number(totalOrderQty), cloneCart
				);
				orderArray = distributeAvailability2(
					Number(distributionData.totalQty),
					distributionData.filteredAvailability
				);
				// orderArray = distributeAvailability(
				// 	totalOrderQty,
				// 	pricingAvailability.availability
				// )

			} else {
				distributionData = getDistributionForNonVehicle(
					partDetails.partSKU, partDetails, pricingAvailability.availability, Number(value), cloneCart
				);        
				   orderArray = distributeAvailability2(
					Number(distributionData.totalQty),
					distributionData.filteredAvailability
				);
				// orderArray = distributeAvailability(
				// 	value,
				// 	pricingAvailability.availability
				// );
			}
			distributionData.alreadyAddedItem =  shipMethod !== getShipInfo(userDetails, parts).shipMethod ?  cart.checkoutDetails : distributionData.alreadyAddedItem;
			orderArray.map((item) => {
				selectedAvailabilityHours.push({
					availability: proPack ? pricingAvailability.availability : null, //  todo property should not add every time 
					availabilityHour: item.availableDuration,
					availableQty: item.availableQty,
					cartFlag: 'no',
					categoryId:
						Number(
							_find(categorySuppliesMap, (c) => {
								return _includes(
									_map(c.groups, 'id'),
									Number(partDetails.groupID)
								);
							}).code
						) || undefined,
					costPrice: pricingAvailability.costPrice,
					corePrice: pricingAvailability.corePrice,
					coreListPrice: pricingAvailability.coreListPrice,
					qtyBuyInc: pricingAvailability.qtyBuyInc,
					desc: partDetails.partDescription,
					descId: partDetails.partDescriptionID,
					groupId: partDetails.groupID,
					listPrice: pricingAvailability.listPrice,
					mfgCode: partDetails.brands
						? partDetails.brands[0].code
						: null,
					packQty: pricingAvailability.packQty,
					partNum: pricingAvailability.partNum,
					partType: 'Chemical',
					vehicleInfo: `Non-Vehicle Specific`,
					qty: item.orderQty,
					store: selectedStore,
					sellPartnerId: sellPartnerId,
					shipMethod: shipMethod,
					distStatus: "Completed",
                    isCartItem: false,
					orderType: "REGULAR"
				});
			});
			selectedAvailabilityHours.push(...distributionData.alreadyAddedItem);
			selectedAvailabilityHoursForPropack.push(...selectedAvailabilityHours);
			/* filtering only propack item */
			selectedAvailabilityHoursForPropack = selectedAvailabilityHoursForPropack.filter(
				(value) => value.isCartItem === false
		    ); 
		}

		this.setState({
			...this.state,
			extendedPrice,
			orderArray,
			selectedAvailabilityHours,
			selectedAvailabilityHoursForPropack,
			totalOrderQty,
			validity,
			preveousOrderQty,
			extendedPriceHighlighter: event ? true : false,
		});
		setTimeout(function (that) {
			that.setState({
				extendedPriceHighlighter: false
			})
		}, 2000, this);
	}

	qtyByINCUpdateSuccessMessage = () => {
		let { dispatch } = this.props;
		let statusMessageProps = {
			heading: "",
			message: "",
			type: "",
		};
		statusMessageProps.heading = displayQtyChangeMsg();
		statusMessageProps.type = "success";
		triggerCustomUxCriticalError(statusMessageProps, dispatch);
	};

	addToCart = () => {
		let { selectedAvailabilityHours } = this.state;
		const {
			dispatch,
			userDetails: { x2userName, userId, shipTos, billTo },
			orderType,
		} = this.props;
		dispatch({
			payload: {
				selectedAddCartData: _cloneDeep(selectedAvailabilityHours),
			},
			type: actionTypes.SELECTED_ADDCART_DATA,
		});
		if (isStockOrder(orderType)) {
			dispatch({
				payload: {
					addToCartWarning: true,
				},
				type: actionTypes.ADDTO_CART_WARNING,
			});
		} else {
			selectedAvailabilityHours = _remove(selectedAvailabilityHours, (a) => {
				return Number(a.qty) >= 0;
			});

			if (!_isEmpty(selectedAvailabilityHours)) {
				addItemToCart2(selectedAvailabilityHours, dispatch, true, this.props.userDetails, "checmicalFullview");
				let uniqSAH = _uniqBy(selectedAvailabilityHours, 'descId');
				uniqSAH.map((s, k) => {
					if (!!s.categoryId && !!s.groupId) {
						const payload = {
							categoryId: s.categoryId,
							createdBy: x2userName,
							createdDate: timeInUTC(),
							customerId: billTo.billToId,
							engine: '',
							engineId: '',
							groupId: s.groupId,
							lastOrderedDate: timeInUTC(),
							make: '',
							makeId: '',
							model: '',
							modelId: '',
							orderedFlag: 'N',
							partTypeDesc: s.desc,
							partTypeDescId: s.descId,
							searchPartId: '',
							shipToId: shipTos[0].shipToId,
							source: 'chemicals',
							updatedBy: x2userName,
							updatedDate: timeInUTC(),
							userFilter: 'selected',
							year: '',
						};
						dispatch(setPartHistory(payload));
					}
				});
			}
		}
	};

	handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
		return (
			code !== 8 &&
			code !== 9 &&
			code !== 37 &&
			code !== 38 &&
			code !== 39 &&
			code !== 40 &&
			code !== 46
		);
	};

	handleKeyDown = (event) => {
		const qtyValue = event.target.value;
		if (
			(numbersOnlyRegExp(event.key) === false ||
				(qtyValue && qtyValue.length >= 4) ||
				event.keyCode === 32) &&
			this.handleOtherKeys(event.keyCode)
		) {
			event.preventDefault();
		}
	};

	checkIsPartInCart = (partNum) => {
		const {
			cartStatus
		} = this.props;
		let str = ""
		let qty = 0;
		cartStatus.map((item) => {
			if (item.partNum === partNum) {
				qty += item.qty;
				str = qty + ` in Cart`
			}
		})
		return str;
	};

	onclick = (tempData) => {
		this.setState({
		  storeData: tempData,
		});
		this.setState({
		  showAvailabilityStoreView: true,
		});
	  }

	checkIfMobileEnabled = (tempData,text) =>{
		let { isMobileEnabled } = this.state
		return(
		  isMobileEnabled ? 
		<u className="text-color" onClick={()=>{this.onclick(tempData)}}>{text}</u> :
		<u>{text}</u>
		)
	  }

	renderAvailability = () => {
		let {
			partDetails,
			proPack,
			handlePartSelect,
			proPackKey,
			partCount,
			userDetails,
			selectedStore
		} = this.props;
		let { pricingAvailability } = partDetails;
		let {
			selectedAvailabilityHours,
			totalOrderQty,
			orderArray,
			extendedPrice,
			validity,
			maxQty,
			extendedPriceHighlighter,
			showAvailabilityStoreView,
			storeData,
			hideAllPrice,
		} = this.state;
		let availabilitySection = [];
		let hideCostPricePref = hidePrice("Catalog", "costPrice");
		let totalAvailqty = 0;
		let uniqueData = _uniqBy(
			partDetails.pricingAvailability.availability,
			"availableDuration"
		  );
		
		!_isEmpty(uniqueData) && uniqueData.map((singleAvailability) => {totalAvailqty += singleAvailability.availableQty;});
		availabilitySection = getAvailability(partDetails, {}, partDetails.pricingAvailability.availability, orderArray, userDetails, "FullView",this.onclick, 0, selectedStore);
		
		// else {
		// pricingAvailability.availability.map((singleAvailability, availableKey) => {
		// 	if (singleAvailability.availableQty > 0 || singleAvailability.availableDuration === 'Your Store' || singleAvailability.availableDuration === 'Store') {
		// 		if (singleAvailability.availableDuration === "Next Business Day" || singleAvailability.availableDuration === "Next 2 + Business Days" || singleAvailability.availableDuration === 'Next 2 Weeks') {
		// 			availabilitySection.push(
		// 				<div className="catalog-availability-section">

		// 					<span className="store-maroon-text avail-qty-width bold">
		// 						{singleAvailability.availableQty}
		// 					</span>
		// 					<span className="text-black tph-store-avail-align">
		// 						&nbsp;
		// 						&nbsp;
		// 						{this.checkIfMobileEnabled(singleAvailability?.storeAvailabilities,singleAvailability.availableDuration)};
		// 					</span>
		// 				</div>
		// 			);
		// 		}
		// 		else {
		// 			singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities.map((storeData, key) => {
		// 				availabilitySection.push(
		// 					<div className="catalog-availability-section">
		// 						<div>{storeData.storeQty == 0 ?
		// 							<span className={availableKey == 0 ? "store-maroon-text avail-qty-width full-view-text-grey-Zero" : "store-maroon-text avail-qty-width bold"}>{storeData.storeQty}
		// 							</span> :
		// 							<span className={availableKey == 0 ? "store-maroon-text avail-qty-width full-view-text-grey" : "store-maroon-text avail-qty-width bold"}>{storeData.storeQty}
		// 							</span>
		// 						}

		// 							<span className="text-black tph-store-avail-align">{singleAvailability.availableDuration === "Your Store" ? ` at Your Store #` : ` at Store #`}<b>{storeData.store}</b>
		// 								{singleAvailability.availableDuration == 'Direct Order Store' || singleAvailability.availableDuration == 'Pick Up Store' ?
		// 									(<OverlayTrigger
		// 										placement="bottom"
		// 										delay={{ show: 100, hide: 400 }}
		// 										overlay={
		// 											<Tooltip id="toggle-view-part-compact" className="custom-home-icon-tooltip">
		// 												{singleAvailability.storeAvailabilities.length > 0 ?
		// 													(<ul>
		// 														<li>{singleAvailability.availableDuration}, {storeData.storeQualifier ? storeData.storeQualifier : ""}</li>
		// 													</ul>)
		// 													: null}

		// 											</Tooltip>
		// 										}
		// 									>
		// 										<b>{singleAvailability.availableDuration == 'Direct Order Store' || singleAvailability.availableDuration == 'Pick Up Store' ? (<a class="maroonColorTxt info-icon full-view-store"><i class="fa fa-info-circle"></i></a>) : null}</b>
		// 									</OverlayTrigger>) : null}
		// 							</span>
		// 						</div>
		// 					</div>
		// 				);
		// 			});
		// 		}
		// 	}
		// });
	    // } 
		let fullDiv = [];
		fullDiv.push(<div className="availability-section tph-prod-quantity-section">{availabilitySection}</div>);
		availabilitySection = fullDiv;
		if (availabilitySection.length > 0) {
			availabilitySection.push(
				<React.Fragment>
					<p className="maroonColorTxt mb-0">
						Enter the quantity below in <b>Total Qty</b> field
					</p>
					<div className="add_to_cart_section">
						<span>
							<span>Total </span>
							<span>QTY:</span>
						</span>
						<input
							type="text"
							name="totalQty"
							value={totalOrderQty}
							onChange={(e) => this.handleInputChangeValue(e)}
							onBlur={(e) => this.handleInputChange(e)}
							onMouseLeave={(e) => this.handleInputChange(e)}
							onKeyDown={(event) => {
								this.handleKeyDown(event);
							}}
						/>
						{proPack ? (
							<button
							   disabled={
								_isEmpty(partDetails.pricingAvailability.availability) ||
								!_every(validity) 
								}
								onClick={() => {
									handlePartSelect(
										selectedAvailabilityHours,
										totalOrderQty,
										partDetails.partDescriptionID,
										partDetails.pricingAvailability
											.costPrice,
										proPackKey,
										partCount,
										false
									);
								}}
							>
								Select
							</button>
						) : (
							<button
								onClick={() => {
									this.addToCart();
								}}
								disabled={
									_isEmpty(
										pricingAvailability.availability
									) || !_every(validity)
								}
							>
								add to cart
							</button>
						)}
						{!this.props.cart.hideCostPrice && !hideCostPricePref  && !hideAllPrice && (
							<div>
								<OverlayTrigger
									placement="bottom"
									delay={{ show: 100, hide: 400 }}
									overlay={
										<Tooltip
											id="extended-price-tooltip"
											className="tooltip-description-custom-corePrice"
										>
											<span className="font-weight-bold">
												Total Extended Price = Qty * (Your Price + Core Price)
											</span>
										</Tooltip>
									}
								>
									<span>Total Extended Price : </span>
								</OverlayTrigger>

								<span
									className={extendedPriceHighlighter ? "bg-highlighter" : ""}
								>
									$
									{formatAmount(
										getExtendedTotalPrice(
											pricingAvailability.corePrice,
											pricingAvailability.costPrice,
											totalOrderQty != undefined ? totalOrderQty : 0
										)
									)}
								</span>
							</div>
						)}
						 {showAvailabilityStoreView ?
						(<AvailabilityStoreView
						openPopup={showAvailabilityStoreView}
						closePopup={() => this.setState({showAvailabilityStoreView:false})}
						storeData={storeData}
						/>
						)
						: null }
					</div>
					{/* <FormValidationErrorMessage
						condition={
							!validity.doesQtyExist || !validity.isQtyMoreThanOne
						}
						errorMessage={'Please enter a quantity'}
					/> */}
					<FormValidationErrorMessage
						condition={
							!validity.isQtyLessThanAvailable &&
							validity.doesQtyExist &&
							validity.isQtyMoreThanOne
						}
						errorMessage={`Please enter a quantity less than ${maxQty + 1
							}`}
					/>
					<span className="full-view-cartText maroonColorTxt">{this.checkIsPartInCart(partDetails.partSKU)}</span>
				</React.Fragment>
			);
		} else {
			availabilitySection.push(
				<div>
					<div className="add_to_cart_section supply-item-out-of-stock">
						<span>
							<b>Currently out of stock</b>
							<b></b>
						</span>
					</div>
				</div>
			);
		}

		return availabilitySection;
	};
	render() {
		let {
			partDetails,
			partType,
			altPartNums,
			subPartNums,
			appendPart,
			dispatch,
			userDetails: { x2userName },
			isAltType,
			isSubType,
		} = this.props;
		let { showLostSales, addToCartWarningPopup, selectedAvailabilityHours ,hideAllPrice } = this.state;
		let { pricingAvailability } = partDetails;
		let imgUrl = (partDetails.imageUrl) ? `${partDetails.imageUrl}?${getImageURLCookieDetails()}` : '/noImage.jpg';
		let hideListPrice = hidePrice("Catalog", "listPrice");
		let hideCostPrice = hidePrice("Catalog", "costPrice");
		let {
			orderArray
		  } = this.state;
		let availabilitySection = getAvailability(partDetails, {}, partDetails.pricingAvailability.availability, orderArray, this.props.userDetails, "FullView",this.onclick);
		const isOutOfStock = availabilitySection.length === 0;	  
		return (
			partDetails.partDescription && partDetails.partSKU !== "" ?
				<div className={appendPart ? 'box append-part' : 'box'}>
					<Row>
						{isAltType || isSubType ? (
							<Col lg={2} md={2} className="product-image-container">
								<p class="text-center alt-sub">
									{isAltType ? `Alternative` : `Superseded`}
								</p>
								<div className="image-thumbnail-container">
									<Image className={"image-thumbnail"} src={imgUrl} fluid />
									{/* <Image src={imgUrl} fluid /> */}
								</div>
							</Col>
						) : (
							<Col lg={2} md={2} className="product-image-container">
								<div className="image-thumbnail-container">
									<Image className={"image-thumbnail"} src={imgUrl} fluid />
									{/* <Image src={imgUrl} fluid /> */}
								</div>
							</Col>
						)}
						<Col lg={3} md={3} className="product_detail">
							<span className="product_name">
								{partDetails.partDescription}
							</span>
							{partDetails.brands.length > 0 &&
								partDetails.brands[0].name && (
									<div className="brand_costPrice_listPrice">
										<span>
											{partDetails.brands.length > 1
												? 'Brands:'
												: 'Brand:'}
										</span>
										<span>
											{_toString(
												_map(partDetails.brands, 'name')
											)}
										</span>
									</div>
								)}
							{!this.props.cart.hideCostPrice && !hideCostPrice && !hideAllPrice && (
								<div className="brand_costPrice_listPrice">
									<span>Your Price:</span>
									<span className="maroonColorTxt">
										${formatAmount(pricingAvailability.costPrice)}
									</span>
								</div>
							)}
							{!this.props.cart.hideCostPrice && !hideCostPrice &&  !hideAllPrice && (
								<div className="brand_costPrice_listPrice">
									<span>Core Price:</span>
									<span className="maroonColorTxt">
										${formatAmount(pricingAvailability.corePrice)}
									</span>
								</div>
							)}
							{hideListPrice || hideAllPrice ? (
								""
							) : (
								<div className="brand_costPrice_listPrice">
									<span>List Price:</span>
									<span className="maroonColorTxt">
										${formatAmount(pricingAvailability.listPrice)}
									</span>
								</div>
							)}
							<span className="part_no">
								Part# {pricingAvailability.partNum}
							</span>
							<PricingIcons toolTips={pricingAvailability.toolTips} />
							<div className="itemSpecificsContainer">
								<span>Specifications: </span>
								<span className="itemSpecific-value">
									{partDetails.specification}
								</span>
							</div>
							<div className="perVeh_years_position_Container">
								<div>
									<span>Size Qty: </span>
									<span className="perVeh_years_position_value">
										{partDetails.sizeQty}
									</span>
								</div>
							</div>
							{appendPart && partType === 'chemical' && (
								<div className={(!_isEmpty(altPartNums) || !_isEmpty(subPartNums)) ? "superseded_alt_chemicals" : ""}>
									<p className="maroonColorTxt">
										{!_isEmpty(altPartNums)
											? `Alternate Part ${altPartNums} `
											: ''}
										{!_isEmpty(subPartNums)
											? `Superseded Part ${subPartNums}`
											: ''}
									</p>
								</div>
							)}
						</Col>
						{isOutOfStock ? (
							<Col
							lg={3} md={3} 
							className="prod_quantity_section ml-auto"
							>
							{this.renderAvailability()}
							<button
								onClick={() => {
								this.setState({ showLostSales: true });
								}}
							>
								Lost Sales
							</button>
							</Col>
						) : (
							<Col
							lg={{ offset: 3 }}
							md={{ offset: 3 }}
							className="prod_quantity_section ml-auto"
							>
							{this.renderAvailability()}
							<button
								onClick={() => {
								this.setState({ showLostSales: true });
								}}
							>
								Lost Sales
							</button>
							</Col>

						)}
					</Row>
					{
						showLostSales ? (
							<LostSales
								openPopup={showLostSales}
								closePopup={() => {
									this.setState({ showLostSales: false });
								}}
								selectedPartDetails={[{
									availabilityHour: partDetails.pricingAvailability.availability && partDetails.pricingAvailability.availability.length > 0 ? partDetails.pricingAvailability.availability[0].availableDuration : null,
									availableQty: partDetails.pricingAvailability.availability && partDetails.pricingAvailability.availability.length > 0 ? partDetails.pricingAvailability.availability[0].availableQty : 0,
									mfgCode: partDetails.pricingAvailability.mfgCode,
									costPrice: partDetails.pricingAvailability.costPrice,
									desc: partDetails.partDescription,
									partNum: partDetails.partSKU,
									qty: 0,
									vehicleInfo: "Non-Vehicle Specific"
								}]}
								dispatch={dispatch}
								userId={x2userName}
								source="catalogPage"
							/>
						) : null
					}
					{addToCartWarningPopup ? (<AddToCartWarningPopup
						openPopup={addToCartWarningPopup}
						selectedAvailabilityHours={selectedAvailabilityHours}
						source={'parts'}
						orderType="REGULAR"
						addItemToCart={false}
						closePopup={() => {
							this.setState({
								addToCartWarningPopup: false
							})
						}}
					/>) : null}
				</div > : <div className='boxedBorder'>
					<span className="product_name">
						{partDetails.partDescription}{""}{" - "}{partDetails.items}{" "}Item(s)
					</span>
				</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		categorySuppliesMap: state.parts.categorySuppliesMap,
		userDetails: state.user.userDetails,
		selectedGroupDetails: state.parts.suppliesSelectedGroups,
		cart: state.cart,
		cartShipMethod: state.parts.cartShipMethod,
		cartStatus: state.cart.cartAllDetails,
		parts: state.parts,
		orderType: state.cart.orderType
	};
}

export default connect(mapStateToProps)(SingleSupplyItem);
