/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import Button from 'ui-library/Button';
import { payerDetails } from './payments.thunk';
import Savedcards from './savedCards';
import XModal from 'components/shared/XModal';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { resetDigest, resetFetchTransaction } from './payments.slice';
import Invoices from './Invoices';
import { Mail, Print } from '@mui/icons-material';
import { useToasts } from 'react-toast-notifications';
import { getPaymentsInvoiceByEmail } from 'components/PaymentsPrint/payments-print.thunk';
import { resetEmailSent } from 'components/PaymentsPrint/payments-print.slice';

function Payments(props) {
	const [ amount, setAmount ] = useState(null);
	const [ show, setShow ] = useState(false);
	const [ selectedPayerDetails, setSelectedPayerDetails ] = useState(null);
	const [ fetchStop, setFetchStop ] = useState(true);
	const [ openSuccessDialog, setOpenSuccessDialog ] = useState(false);
	const [ selected, setSelected ] = useState([]);

	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const { addToast } = useToasts();
	const params = queryString.parse(location.search);

	const payments = useSelector((state) => state.payments);
	const paymentsPrint = useSelector((state) => state.paymentsPrint);

	useEffect(() => {
		dispatch(resetDigest());
		dispatch(payerDetails());

		return () => {
			dispatch(resetDigest());
		};
	}, []);

	useEffect(
		() => {
			if (paymentsPrint.emailSent) {
				dispatch(resetEmailSent());
				addToast('Email sent', { appearance: 'success', autoDismiss: true });
			}
		},
		[ paymentsPrint.emailSent ]
	);

	useEffect(
		() => {
			if (!_.isEmpty(params) && fetchStop) {
				// dispatch(fetchTransaction({ id: params.ref }));
				setFetchStop(false);
				dispatch(resetDigest());
			}
		},
		[ params ]
	);

	useEffect(
		() => {
			if (payments.fetchTransactionSuccess) {
				setOpenSuccessDialog(!openSuccessDialog);
			}
		},
		[ payments.fetchTransactionSuccess ]
	);

	const handleConfirmPay = (paymentType) => {
		const _amount = parseFloat(amount).toFixed(2);

		// calculate invoice amount difference.
		let calcAmount = 0;
		if (selected.length > 0) {
			selected.forEach((s) => {
				calcAmount = calcAmount + s.uploadedAmount;
			});
			calcAmount = parseFloat(calcAmount).toFixed(2);
		} else {
			calcAmount = parseFloat(0).toFixed(2);
		}

		if (_amount !== calcAmount) {
			console.log('Payments: Error in invoice total amount diff');
		} else {
			console.log('Payments: proceed to digest...');
		}

		// dispatch(digest(payload));
	};

	useEffect(
		() => {
      console.log('payment digest ==> ', payments.digest);
			if (payments.digest) {
				const form = document.querySelector('#payment_confirmation');
        console.log('form ==> ', form);
				form?.submit();
			}
		},
		[ payments.digest ]
	);

	const selectPayerDetails = (details) => {
		setSelectedPayerDetails(details);
	};

	const handleSuccessClose = () => {
		dispatch(resetFetchTransaction());
		dispatch(payerDetails());
		history.push('/payments');
	};

	const handleSelected = (selected) => {
		const sum = _.sumBy(selected, (s) => Number(s.invoiceAmountDue));
		setAmount(sum);
		setSelected(selected);
	};

	const decision = (decision) => {
		let value = null;

		switch (decision) {
			case 'ACCEPT':
				value = params.setup === 'true' ? 'Setup Successful' : 'Successful';
				break;
			case 'CANCEL':
				value = params.setup === 'true' ? 'Setup Cancelled' : 'Cancelled';
				break;
			default:
				value = params.setup === 'true' ? 'Setup Failed' : 'Failed';
				break;
		}

		return value;
	};

	const getPDFURL = () => {
		const url = `/payments-print?ref=${params.ref}`;
		return url;
	};

	const sendInvoiceByMail = () => {
		dispatch(getPaymentsInvoiceByEmail(params.ref));
	};

	return (
		<div className="payments-wrapper">
			<div className="container">
				<div className="row">
					<div className="col">
						<Invoices handleSelected={handleSelected} />
					</div>
				</div>
			</div>
			<XModal
				className="saved-cards-modal"
				title="Setup Payments"
				show={show}
				showButtons={false}
				handleClose={() => setShow(!show)}
			>
				<Savedcards handleConfirmPay={handleConfirmPay} selectPayerDetails={selectPayerDetails} />
			</XModal>
			{!_.isEmpty(params) && (
				<XModal
					className="payment-success-wrapper"
					title={`Payment ${decision(params.decision)}`}
					show={true}
					showButtons={false}
					handleClose={handleSuccessClose}
				>
					<div className="payment-success">
						{params.decision === 'CANCEL' ? (
							<React.Fragment>
								<div>Your payment is cancelled.</div>
							</React.Fragment>
						) : params.decision === 'ACCEPT' ? (
							<React.Fragment>
								{params.setup === 'false' && (
									<div className="actions-line">
										<Tooltip title="Print">
											<Button
												color="primary"
												aria-label="print payment"
												onClick={() => window.open(getPDFURL())}
												startIcon={<Print />}
												variant="contained"
											>
												Print Receipt
											</Button>
										</Tooltip>

										<Tooltip title="Email">
											<Button
												color="primary"
												aria-label="email payment"
												component="label"
												onClick={() => sendInvoiceByMail()}
												startIcon={<Mail />}
												variant="contained"
											>
												Email Receipt
											</Button>
										</Tooltip>
									</div>
								)}
								{params.setup === 'false' && (
									<div>
										Your Payment Transaction ID: <span className="transactId">{params.id}</span>
									</div>
								)}
								{params.setup === 'false' ? (
									<div>
										Please use this number for any communication with XL Parts regarding the
										payment.{' '}
									</div>
								) : (
									<div>The payment setup has been made successfully</div>
								)}
								{params.setup === 'false' && <div>Thank you for your Payment.</div>}
							</React.Fragment>
						) : (
							<React.Fragment>
								<div>Your payment is failed.</div>
							</React.Fragment>
						)}
						<Button className="ok-btn" variant="contained" onClick={handleSuccessClose}>
							Ok
						</Button>
					</div>
				</XModal>
			)}
		</div>
	);
}

Payments.propTypes = {};

export default Payments;
