import React, { useState } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
	getGUID,
} from '../User/UserManagement/usermanagement.thunk'
import { getSMSOrders } from "../../actions/testsms";

const TestSMSIframe = () => {
  const dispatch = useDispatch();
  const [vinNumber, setVinNumber] = useState("");
  const [partDescription, setPartDescription] = useState("");
  // const [token, setToken] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [shiptoID, setShiptoID] = useState("");
  const [userID, setUserID] = useState("");
  const [orderJson, setOrderJson] = useState({});
  const [loader, setLoader] = useState(false);



  const onSubmit = () => {
    setLoader(true);
    const data = {
      customerID,shiptoID,userID
    }
    dispatch(getGUID(data)).then((res)=>{
      setLoader(false);
      if(res.payload){
        let token = res.payload.sessionToken
        
        if(token) {       
          const payload = {"guid": token} ; 
          dispatch(getSMSOrders(payload))
          .then((action) => {
            setOrderJson(action);
          })
          .catch((error) => {	
          })
        }
        
        // http://localhost:3000/smslogin?Token=2a4ce770-31e7-4b56-9d9f-f972d5bb6d6f&vin=19UUA66245A012181&partDesc=radiator
        const redirectUrl = `/smslogin?Token=${token}${vinNumber !==""?`&vin=${vinNumber}`:``}${partDescription !==""?`&partDesc=${partDescription}`:``}`;
        console.log("SMS Iframe Redirect URL : ", redirectUrl);
        window.open(redirectUrl, '_blank');
      }else{
        alert("Cannot generate Token !. Please verify customer ID, shipto ID & user ID")
      }
    }).catch((err)=>{
      setLoader(false);
    })
	};	

  return (
    <div className="user-management-container">
      {loader ?
        <div className="custom_spinner_container">
          <Spinner className="custom_spinner" animation="border" />
        </div> 
      : null}
      <div className="container">
        <div className="buying__guide__component">
        <Row>
            <Col>
              <div className="align">
                <Form.Label>Enter customer ID:</Form.Label>
                <div className="part__search">
                  <Form.Control
                    className="part__search_input"
                    size="sm"
                    type="text"
                    onChange={(event) => setCustomerID(event.target.value)}
                    // placeholder="Enter VIN"
                    value={customerID}
                    autoFocus
                  />                  
                </div>
              </div>
            </Col>
          {/* </Row>
          <Row> */}
            <Col>
              <div className="align">
                <Form.Label>Enter shipto ID:</Form.Label>
                <div className="part__search">
                  <Form.Control
                    className="part__search_input"
                    size="sm"
                    type="text"
                    onChange={(event) => setShiptoID(event.target.value)}
                    // placeholder="Enter VIN"
                    value={shiptoID}
                  />                  
                </div>
              </div>
            </Col>
          {/* </Row>
          <Row> */}
            <Col>
              <div className="align">
                <Form.Label>Enter user ID:</Form.Label>
                <div className="part__search">
                  <Form.Control
                    className="part__search_input"
                    size="sm"
                    type="text"
                    onChange={(event) => setUserID(event.target.value)}
                    // placeholder="Enter VIN"
                    value={userID}
                  />                  
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="align">
                <Form.Label>Enter VIN:</Form.Label>
                <div className="part__search">
                  <Form.Control
                    className="part__search_input"
                    size="sm"
                    type="text"
                    onChange={(event) => setVinNumber(event.target.value)}
                    // placeholder="Enter VIN"
                    value={vinNumber}
                  />                  
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="align">
                <Form.Label>Enter Part Description:</Form.Label>
                <div className="part__search">
                  <Form.Control
                    className="part__search_input"
                    size="sm"
                    type="text"
                    onChange={(event) => setPartDescription(event.target.value)}
                    value={partDescription}
                  />                  
                </div>
              </div>
            </Col>
          </Row>
          <Row> 
          <Col>
              <div className="align">
                <Form.Label>Orders JSON:</Form.Label>
                <div className="part__search">
                  <Form.Control
                    className="part__search_input"
                    size="sm"
                    type="text"
                    value={orderJson && orderJson.length ? JSON.stringify(orderJson) : ""}
                  />                  
                </div>
              </div>
            </Col>
          </Row> 
          <Row>
            <Col>
              <Button
                onClick={onSubmit}
                className="search__button mt-2"
              >
                SUBMIT
              </Button>
            </Col>
          </Row>	
        </div>
      </div>
    </div>
  );
}

export default TestSMSIframe;
