import React, { useState, useEffect } from 'react';
import sidebarItem from '../../../static/images/promotions_1.png';

import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _flatten from 'lodash/flatten';
import _head from 'lodash/head';
import _uniq from 'lodash/uniq';

import {
	constructReqItemsForGroups,
	constructReqItemsForCategories,
	getDefaultDataBasedOnUSerPref,
	getPreferenceDataFromStorage,
	getImageURLCookieDetails
} from '../../../helpers/utils';
import {
	getPartsByMultiGroupOrchestrator,
	getChemPartByGrp,
} from '../../../actions/parts';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { triggerCustomUxCriticalError } from '../../../actions/error';

const actionTypes = {
	SELECTED_PROMOTION: 'SELECTED_PROMOTION',
	PROMOTION_EVENT_STATUS: 'PROMOTION_EVENT_STATUS',
};

const handleDataAndCallApi = (
	singleItem,
	categoryGroupMap,
	chemCatMap,
	proPacksData,
	sv,
	history,
	dispatch
) => {
	let {
		chemicalCategories,
		chemicalGroups,
		chemicalParts,
		type,
		partCategories,
		partGroups,
		preDefinedJob,
		partTypes: partTypeArray,
	} = singleItem;
	let errorMessageProps = {
		heading: 'Please select a different promotion.',
		message: 'Selected promotion is currently unavailable.',
		type: 'info',
	};
	const userPreferenceDetail = getPreferenceDataFromStorage();
	let userPrefDetail = getDefaultDataBasedOnUSerPref(
		userPreferenceDetail
	);
	switch (type) {
		case 'ProPacks':
			{
				let selPP = {}
				let requestItems = [];
				if (preDefinedJob && preDefinedJob.length > 0) {
					selPP = _find(_head(proPacksData).jobs, {
						id: _head(preDefinedJob).id,
					});

					dispatch({
						payload: {
							selectedProPack: selPP,
						},
						type: 'PROPACK_SELECTED',
					});

					selPP.partJobItems.map((singleItem, key) => {
						if (!_isEmpty(singleItem.partGroup)) {
							requestItems = [
								...requestItems,
								..._map(_map(singleItem.partGroup, 'code'), (c) => {
									return {
										groupCode: Number(c),
										specificCondition: [],
									};
								}),
							];
						}
					});
					if (!_isEmpty(selPP.chemicalJobItems)) {
						let chemReqItems = [];
						selPP.chemicalJobItems.map((c) => {
							if (!_isEmpty(c.chemicalGroup)) {
								chemReqItems = c.chemicalGroup.map(single => single.id);
							}
						});
						dispatch(
							getChemPartByGrp(
								_uniq(chemReqItems),
								userPrefDetail.pageNumber,
								userPrefDetail.pageSize,
								userPrefDetail.catalogSortBy,
								userPrefDetail.catalogOrder,
								null,
								null,
								['Bundle']

							)
						);
						dispatch({
							payload: {
								brandsView: false,
							},
							type: 'SUPPLIES_BRAND_VIEW',
						});
					}
					dispatch({
						payload: {
							selectedGroupDetails: _flatten(
								_map(selPP.partJobItems, 'partGroup')
							),
						},
						type: 'GROUP_SELECTED',
					});
				}
				if (_isEmpty(requestItems)) {
					triggerCustomUxCriticalError(errorMessageProps, dispatch);
				} else {
					dispatch(
						getPartsByMultiGroupOrchestrator(
							sv.year,
							sv.makeId,
							sv.modelId,
							sv.engineId,
							{ requestItems },
							userPrefDetail.pageNumber,
							userPrefDetail.pageSize,
							userPrefDetail.catalogSortBy,
							userPrefDetail.catalogOrder,
							{ misc: ['Bundle'] },
						)
					);
					history.push('/propacks-list');
				}
			}
			break;
		case 'Categories':
			let requestItems = [];
			let categoryData = [];
			let selGrps = [];
			let filter = {};

			if (!_isEmpty(partGroups)) {
				requestItems = constructReqItemsForGroups(partGroups);
			}
			if (!_isEmpty(partCategories)) {
				categoryData = partCategories.map((singleCategory) =>
					_find(categoryGroupMap, {
						code: Number(singleCategory.code),
					})
				);
				requestItems = constructReqItemsForCategories(categoryData);
			}
			selGrps = [
				...partGroups,
				..._flatten(_map(categoryData, 'groups')),
			];
			if (!_isEmpty(partTypeArray)) {
				let partTypes = _map(partTypeArray, (p) => {
					return { id: p.code };
				});
				filter = { partTypes };
				let ptTypReqItms = _map(partTypeArray, (p) => {
					return {
						groupCode: p.groupCode,
						specificCondition: [],
					};
				});
				requestItems = [...requestItems, ...ptTypReqItms];
			}
			dispatch({
				payload: {
					selectedGroupDetails: selGrps,
				},
				type: 'GROUP_SELECTED',
			});
			if (_isEmpty(requestItems)) {
				triggerCustomUxCriticalError(errorMessageProps, dispatch);
			} else if (sv && sv.makeId && sv.modelId && sv.engineId) {
				dispatch(
					getPartsByMultiGroupOrchestrator(
						sv.year,
						sv.makeId,
						sv.modelId,
						sv.engineId,
						{ requestItems },
						userPrefDetail.pageNumber,
						userPrefDetail.pageSize,
						userPrefDetail.catalogSortBy,
						userPrefDetail.catalogOrder,
						filter
					)
				);
				history.push('/product-list');
			}
			break;
		case 'Chemicals':
			let groupIds = chemicalGroups.map((s) => Number(s.id));
			let chemCats = [];
			let partTypeIds = [];
			if (!_isEmpty(chemicalCategories)) {
				chemCats = chemicalCategories.map((s) =>
					_find(chemCatMap, { code: Number(s.id) })
				);
				groupIds = [
					...groupIds,
					..._map(_flatten(_map(chemCats, 'groups')), 'id'),
				];
			}
			if (!_isEmpty(chemicalParts)) {
				partTypeIds = _map(chemicalParts, 'partDescriptionID');
			}
			if (_isEmpty(groupIds)) {
				triggerCustomUxCriticalError(errorMessageProps, dispatch);
			} else {
				dispatch(
					getChemPartByGrp(
						groupIds,
						userPrefDetail.pageNumber,
						userPrefDetail.pageSize,
						userPrefDetail.catalogSortBy,
						userPrefDetail.catalogOrder,
						null,
						null,
						null,
						null,
						null,
						null,
						partTypeIds
					)
				);
				history.push('/supplies-list');
			}
			break;
		case 'Both':
			{
				let requestItems = [];
				let categoryData = [];
				let selGrps = [];
				let filter = {};

				if (!_isEmpty(partGroups)) {
					requestItems = constructReqItemsForGroups(partGroups);
				}
				if (!_isEmpty(partCategories)) {
					categoryData = partCategories.map((singleCategory) =>
						_find(categoryGroupMap, {
							code: Number(singleCategory.code),
						})
					);
					requestItems = [...requestItems, ...constructReqItemsForCategories(categoryData)];
				}
				selGrps = [
					...partGroups,
					..._flatten(_map(categoryData, 'groups')),
				];
				if (!_isEmpty(partTypeArray)) {
					let partTypes = _map(partTypeArray, (p) => {
						return { id: p.code };
					});
					filter = { partTypes };
					let ptTypReqItms = _map(partTypeArray, (p) => {
						return {
							groupCode: p.groupCode,
							specificCondition: [],
						};
					});
					requestItems = [...requestItems, ...ptTypReqItms];
				}
				dispatch({
					payload: {
						selectedGroupDetails: selGrps,
					},
					type: 'GROUP_SELECTED',
				});
				if (sv && sv.makeId && sv.modelId && sv.engineId) {
					dispatch(
						getPartsByMultiGroupOrchestrator(
							sv.year,
							sv.makeId,
							sv.modelId,
							sv.engineId,
							{ requestItems },
							userPrefDetail.pageNumber,
							userPrefDetail.pageSize,
							userPrefDetail.catalogSortBy,
							userPrefDetail.catalogOrder,
							filter
						)
					);
					history.push('/product-list');
				}
				let groupIds = chemicalGroups.map((s) => Number(s.id));
				let chemCats = [];
				let partTypeIds = [];
				if (!_isEmpty(chemicalCategories)) {
					chemCats = chemicalCategories.map((s) =>
						_find(chemCatMap, { code: Number(s.id) })
					);
					groupIds = [
						...groupIds,
						..._map(_flatten(_map(chemCats, 'groups')), 'id'),
					];
				}
				if (!_isEmpty(chemicalParts)) {
					partTypeIds = _map(chemicalParts, 'partDescriptionID');
				}
				if (_isEmpty(groupIds)) {
					triggerCustomUxCriticalError(errorMessageProps, dispatch);
				} else {
					dispatch(
						getChemPartByGrp(
							groupIds,
							userPrefDetail.pageNumber,
							userPrefDetail.pageSize,
							userPrefDetail.catalogSortBy,
							userPrefDetail.catalogOrder,
							null,
							null,
							null,
							null,
							null,
							null,
							partTypeIds
						)
					);
					dispatch({
						payload: {
							mixedPromo: true,
						},
						type: 'MIXED_PROMOTION',
					});
				}
			}
			break;
		default:
			break;
	}
};

const PromotionsTab = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const mygarage = useSelector((state) => state.mygarage);
	const sv = mygarage.selectedVehicle;
	const parts = useSelector((state) => state.parts);
	const categoryGroupMap = parts.categoryGroupMap;
	const chemCatMap = parts.categorySuppliesMap;
	const user = useSelector((state) => state.user);

	const proPacksData = user.proPacksData;

	const [selectedPromo, setSelectedPromo] = useState({});
	let { handlePromotions, showSidebarPopUp, promotions } = props;
	const handlePromotionsClick = (e, singleItem) => {
		const statusMessageProps = {
			message: 'Please select vehicle.',
			type: 'info',
		};
		if (
			singleItem.type === 'Chemicals' ||
			(sv && sv.makeId && sv.modelId && sv.engineId)
		) {
			handleDataAndCallApi(
				singleItem,
				categoryGroupMap,
				chemCatMap,
				proPacksData,
				sv,
				history,
				dispatch
			);
		} else {
			dispatch({
				payload: {
					singleItem,
				},
				type: actionTypes.SELECTED_PROMOTION,
			});
			dispatch({
				payload: {
					status: true,
				},
				type: actionTypes.PROMOTION_EVENT_STATUS,
			});
			setSelectedPromo(singleItem);
			triggerCustomUxCriticalError(statusMessageProps, dispatch);
			history.push('/');
		}
	};

	const renderPromotionBox = () => {
		let promoBox = [];
		if (!_isEmpty(promotions)) {
			promoBox = promotions.map((singleItem, index) => {
				let pdfUrl = !_isEmpty(
					_find(singleItem.actions, (atn) => {
						return atn.actionName.toUpperCase() === 'READ MORE';
					})
				)
					? _find(singleItem.actions, (atn) => {
						return atn.actionName.toUpperCase() === 'READ MORE';
					}).referenceAsset
					: '';
				let shopProducts = !_isEmpty(
					_find(singleItem.actions, (atn) => {
						return atn.actionName.toUpperCase() === 'SHOP PRODUCTS';
					})
				);
				return (
					<div
						key={index}
						className="siderbar-item"
						style={{ backgroundImage: `url(${sidebarItem})` }}
					>
						<div className="siderbar-item-content">
							<h4>{singleItem.name}</h4>
							{shopProducts && (
								<a
									href="#"
									className="btn btn-xlp"
									onClick={(e) => {
										handlePromotionsClick(e, singleItem);
									}}
								>
									Shop products
								</a>
							)}
							{!_isEmpty(pdfUrl) && (
								<div className="mt-1">
									<button
										className="rightArrowBtn"
										onClick={() => {
											const w = window.open(
												`${pdfUrl}?${getImageURLCookieDetails()}`,
												'PDF',
												'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1'
											);
										}}
									>
										read more &nbsp;
										<i className="fa fa-angle-double-right"></i>
									</button>
								</div>
							)}
						</div>
					</div>
				);
			});
		}
		return promoBox;
	};
	return (
		<React.Fragment>
			<div
				className={
					showSidebarPopUp
						? 'active xlp-sidebar-wrapper'
						: 'xlp-sidebar-wrapper'
				}
			>
				<span
					className="sidebar-btn text-uppercase"
					onClick={() => {
						handlePromotions();
					}}
				>
					{!showSidebarPopUp ? 'Promotions' : ''}
				</span>
				<div className="xlp-sidebar">{renderPromotionBox()}</div>
			</div>
		</React.Fragment>
	);
};

export { PromotionsTab as default, handleDataAndCallApi };
