import { applyMiddleware, createStore } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "../reducers/root";

// export default function configureStore(initialState = {}) {
//   return createStore(rootReducer, initialState, applyMiddleware(thunk));
// }
export default function createStoreApp(initialState = {}) {
  return configureStore({
    reducer: rootReducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      thunk,
    ],
  });
}
