import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import "../styles.scss";
import _isEmpty from 'lodash/isEmpty';
import ResetIcon from "../../../static/images/reset.png";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useHistory } from "react-router-dom";
import { isAlphanumeric, hasMinLength, isNumeric, handleOtherKeys, themeColor, returnHandleKeyDown, hasWhiteSpace, handleKeyDownReturns } from "../../../helpers/utils";
import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import _ from "lodash";
import StoreNumberMultiSelect from './storeNumberMultiSelect';

const options = [
  { label: "ALL", value: "All" },
  { label: "Open", value: "Open" },
  { label: "Picked Up", value: "Picked Up" },
  { label: "Escalated", value: "Escalated" },
  { label: "Store Processed", value: "Store Processed" },
  { label: "AM Processed", value: "AM Processed" },
  { label: "Posted", value: "Posted" }
]

const Filter = (props) => {
  let {
    storeNumberList,
    AREA_MANAGER,
    OPERATIONS_MANAGER,
    CC_MANAGER,
    storeNum,
    handleStoreNumber,
    setCustomerNum,
    setACX,
    handleStatusSelection,
    setREF,
    setPartNum,
    handleDateRange,
    clearDateFilter,
    handleOnApplyButn,
    customerNum,
    dateFilter,
    handleReset,
    partNum,
    status,
    ACX,
    REF,
    ExpandRows,
    handleExpandButton,
    rowData,
    STORE_USER,
    customerName,
    setCustomerName,
  } = props;

  const userDetails = useSelector((state) => state.user.userDetails);
  const storeBoard = useSelector((state) => state.storeDashBoard);
  const returnList = !_isEmpty(storeBoard) && storeBoard.returnList;
  const [disable, setDisable] = useState(false);
  const history = useHistory();
  const initializeValidity = () => {
    const validity = {
      isSearchAlphanumeric: true,
      isSearchMinLimit: true,
      isSearchNumeric: true,
      isSearchWhiteSpace: true,
      isSearchName: ""
    };
    return validity;
  };
  const [validity, setvalidity] = useState(initializeValidity);

  const handleCreateReturn = () => {
    history.push({
      pathname: '/create-return'
    });
  };
  const handleKeyDown1 = (event) => {
    const refValue = event.target.value;
    if (((refValue && refValue.length >= 30) || event.keyCode === 32)
      && handleOtherKeys(event.keyCode)) {
      event.preventDefault();
    }
  };
  const handleSearchPartChange = (event, name) => {
    const searchData = event.target.value;
    let validity = initializeValidity();
    validity.isSearchAlphanumeric = isAlphanumeric(searchData);
    validity.isSearchWhiteSpace = hasWhiteSpace(searchData);
    validity.isSearchMinLimit = hasMinLength(searchData, 1);
    validity.isSearchName = name;
    setvalidity(validity);
  };
  const handleSearchCustNumChange = (event, name) => {
    const searchData = event.target.value;
    let validity = initializeValidity();
    validity.isSearchNumeric = isNumeric(searchData);
    validity.isSearchMinLimit = hasMinLength(searchData, 1);
    validity.isSearchWhiteSpace = hasWhiteSpace(searchData);
    validity.isSearchName = name;
    setvalidity(validity);
  };
  const handleSearchCustNameChange = (event, name) => {
    const searchData = event.target.value;
    let validity = initializeValidity();
    validity.isSearchMinLimit = hasMinLength(searchData, 1);
    validity.isSearchWhiteSpace = hasWhiteSpace(searchData);
    validity.isSearchName = name;
    setvalidity(validity);
  };
  const handleSearchRefChange = (event, name) => {
    const searchData = event.target.value;
    let validity = initializeValidity();
    validity.isSearchMinLimit = hasMinLength(searchData, 1);
    validity.isSearchWhiteSpace = hasWhiteSpace(searchData);
    validity.isSearchName = name;
    setvalidity(validity);
  };
  const handleSearchOrderBlur = () => {
    let validity = initializeValidity();
    setvalidity(validity);
  };
  const getFilterTitle = () => {
    // let storeValue  = storeNum[0] && storeNum[0]?.value !== undefined  ? storeNum[0]?.value : storeNum.value; 
    // let defaultTitle = "ALL";
    // if (storeNumberList && storeNum) {
    //   const isFound = _.filter(storeNumberList, (pt) => {
    //     return pt.value === storeValue;
    //   });

    //   if (!_isEmpty(isFound)) {
    //     defaultTitle = storeValue;
    //   }
    // }
    return storeNum;
  };

  const getMultiSelectArray = () => {
    let multiSelectArray = [];
    let storeValue  = storeNum[0] && storeNum[0]?.value !== undefined  ? storeNum[0]?.value : storeNum.value; 
    if (storeNumberList && storeNum) {
      const isFound = _.filter(storeNumberList, (pt) => {
        return pt.value ===  storeValue;
      });
      if (isFound && isFound.length) {
        multiSelectArray =  storeValue;
      }
    }
    return multiSelectArray;
  };

  const handleStoreFilter = (value, selectedOptions) => {
    handleStoreNumber(value, selectedOptions);
    if (value.action === "deselect-option" && _isEmpty(selectedOptions)) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  return (
    <React.Fragment>
      <Row  className='media_query_width_fix_row'>
      {!_isEmpty(userDetails) && (userDetails.userType === STORE_USER || userDetails.userType === CC_MANAGER) && (        <Col md="3">
          <div className="mt-3">
            <Button
              className="maroonBtn return_xl_parts_btn"
              onClick={() => handleCreateReturn()}
            >
              Create Return
            </Button>
          </div>
        </Col>)}
      </Row>
      <Row>
        {((!_isEmpty(userDetails) && userDetails.userType && (userDetails.userType === CC_MANAGER || userDetails.userType === AREA_MANAGER || userDetails.userType === OPERATIONS_MANAGER)) ||
          (userDetails && !_isEmpty(userDetails.roles) && !_isEmpty(userDetails.roles[0]) && (userDetails.roles[0] === AREA_MANAGER || userDetails.roles[0] === CC_MANAGER))
        ) && (!_isEmpty(storeNumberList)) && (
            <Col md="auto">
              <StoreNumberMultiSelect
                defaultFilter={getFilterTitle() === 'ALL'? "" : storeNumberList[1]}
                storeNumberList={storeNumberList}
                handleStoreFilter={(key, e) => handleStoreFilter(key, e)}
                multiSelectArray={getMultiSelectArray()}
                currentFilter={getFilterTitle()}
              />
            </Col>)}
        <Col md="auto">
        <Form.Control
            placeholder="Search Name"
            type="text"
            className="input__control_store"
            value={customerName}
            onBlur={handleSearchOrderBlur}
            onChange={(event) => {
              handleSearchCustNameChange(event, "Cust Name ");
              setCustomerName(event.target.value);
            }}
            // onKeyDown={(event) => {
            //   returnHandleKeyDown(event);
            // }}
          />
        </Col>
        <Col md="auto">
            <Form.Control
            placeholder="Search Cust #"
            type="text"
            className={userDetails.userType !== STORE_USER ? "store-Num input__control_store" : "input__control_store"}
            maxLength="10"
            value={customerNum}
            onBlur={handleSearchOrderBlur}
            onChange={(event) => {
              handleSearchCustNumChange(event, "Cust # ");
              setCustomerNum(event.target.value);
            }}
            onKeyDown={(event) => {
              returnHandleKeyDown(event);
            }}
          />
        </Col>

        <Col md="auto" className="store-dashboard-filter">
          <Form.Control
            placeholder="Search Part #"
            type="text"
            value={partNum}
            maxLength="20"
            onBlur={handleSearchOrderBlur}
            onChange={(event) => {
              handleSearchRefChange(event, "Part # ");
              setPartNum(event.target.value.toUpperCase())
            }}
            className="input__control_store"
            onKeyDown={(event) => {
              handleKeyDownReturns(event);
            }}
          />
        </Col>
        <Col md="auto" className="store-dashboard-filter">
          <Form.Control
            placeholder="Search ACX #"
            type="text"
            maxLength="6"
            value={ACX}
            onBlur={handleSearchOrderBlur}
            onChange={(event) => {
              handleSearchPartChange(event, "ACX # ");
              setACX(event.target.value.toUpperCase())
            }}
            className="input__control_store"
            onKeyDown={(event) => {
              handleKeyDownReturns(event);
            }}
          />
        </Col>
        <Col md="auto" className="store-dashboard-filter">
          <Form.Control
            placeholder="Search REF #"
            type="text"
            value={REF}
            onBlur={handleSearchOrderBlur}
            onChange={(event) => {
              handleSearchRefChange(event, "REF # ");
              setREF(event.target.value.toUpperCase())
            }}
            className="input__control_store"
            onKeyDown={(event) => {
              handleKeyDown1(event);
            }}
          />
        </Col>

        <Col md="auto" className="store-dashboard-filter">
          <Select
            defaultValue={status}
            className="input__storedashboard_search input__control_store_search"
            onChange={handleStatusSelection}
            value={status}
            options={options}
            theme={(theme) => themeColor(theme)}
          />
        </Col>

        <Col md="auto" className="store-filter-date-range">
          <DateRangePicker onApply={handleDateRange}  >
            <span className="material-icons storedate__filter">date_range</span>
          </DateRangePicker>
          {dateFilter && !_isEmpty(userDetails) && (userDetails.userType !== STORE_USER) && (
            <div className="sotre_dashboard_filter__wrapper1">
              <span>{dateFilter}</span>
              {dateFilter && (
                <span
                  className="material-icons clear__filter_btn"
                  onClick={clearDateFilter}
                >
                  highlight_off
                </span>
              )}
            </div>
          )}
        </Col>
        {!_isEmpty(userDetails) && (userDetails.userType === STORE_USER) && (
          <Col md="auto" className="store-dashboard-filter">
            {dateFilter && (
              <div className="sotre_dashboard_filter__wrapper">
                <span>{dateFilter}</span>
                {dateFilter && (
                  <span
                    className="material-icons clear__filter_btn"
                    onClick={clearDateFilter}
                  >
                    highlight_off
                  </span>
                )}
              </div>
            )}
          </Col>)}
        <Col md="auto" className="store-filter-button-height">
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 400 }}
            overlay={
              <Tooltip>
                Apply your changes
              </Tooltip>
            }
          >
            <Button
              className={ 'store-filter-apply-button'}
              onClick={handleOnApplyButn}
              disabled={disable}
            >
              <span>Apply</span>
            </Button>
          </OverlayTrigger>
        </Col>
        <Col md="auto" className="store-filter-reset">
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 400 }}
            overlay={<Tooltip>Click here to refresh</Tooltip>}>
            <button class="part-arrow-collapse"
              onClick={() => { handleReset() }}>
              <img
                src={ResetIcon}
                alt="reset" />
            </button>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row className={validity.isSearchName === "Cust # " ? "filter_error_message_cust" : validity.isSearchName === "Part # " ? "filter_error_message_part" : validity.isSearchName === "ACX # " ? "filter_error_message_ACX" : "filter_error_message"}>
        <FormValidationErrorMessage
          condition={!validity.isSearchAlphanumeric && validity.isSearchWhiteSpace}
          errorMessage={validity.isSearchName + "must be alphanumeric only"}
        />
        <FormValidationErrorMessage
          condition={!validity.isSearchNumeric && validity.isSearchWhiteSpace && validity.isSearchMinLimit}
          errorMessage={validity.isSearchName + " must be numeric only"}
        />
        <FormValidationErrorMessage
          condition={!validity.isSearchWhiteSpace && validity.isSearchMinLimit}
          errorMessage={validity.isSearchName + " should not contain space"}
        />
      </Row>
      {(rowData && rowData.length > 0) &&
        (<Row>
          <div className="store_dashBoard_delivery_detail">
          <Col>
            <div style={{ marginLeft: "18px" }}>
              <span className="summary_text"> # of Customers: {"  "}</span>
              <span>{!_isEmpty(returnList) && returnList.noOfCustomer ? returnList.noOfCustomer : 0}</span>
            </div>
          </Col>
            <Col>
                <div>
                <span className="summary_text"> # of Parts: {"  "}</span>
                <span>{!_isEmpty(returnList) && returnList.noOfParts ? returnList.noOfParts : 0}</span>
              </div>
            </Col>
            <Col>
              <div>
                <span className="summary_text"> # of Returns: {"  "}</span>
                <span>{!_isEmpty(returnList) && returnList.totalReturns ? returnList.totalReturns : 0}</span>
              </div>
            </Col>
          </div>
        </Row>
        )}
      <Row>
        {(rowData && rowData.length > 0) && (
          <Col>
            <div className="store_dashBoard_delivery_detail">
              <div>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={ExpandRows ? <Tooltip>Click to hide details</Tooltip> : <Tooltip>Click to see more details</Tooltip>}>
                  <span className="collapse_text" onClick={() => handleExpandButton()}>
                    {ExpandRows ? "Collapse All " : "Expand All "} {ExpandRows ? <i class="fa fa-minus" aria-hidden="true"></i> : <i class="fa fa-plus" aria-hidden="true"></i>}
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          </Col>)}
      </Row>
    </React.Fragment>)
};
export default Filter;
