import React, { useEffect, useState, Fragment }  from 'react';
import { useDispatch } from "react-redux";
import Cookies from 'universal-cookie';
import XModal from "../shared/XModal";
import _isEmpty from "lodash/isEmpty";
import {getImageURLCookieDetails} from '../../helpers/utils';
import {
    fetchHelpMenuItems,
} from "../shared/HelpMenu/helpmenu.thunk";

function ReleaseNotePopup({ openPopup, closePopup }) {
	const dispatch = useDispatch()
	const [releaseNotesContent, setReleaseNotesContent] = useState('');
	const [releaseNotesLink, setReleaseNotesLink] = useState('');
  const [showPopup, setShowPopup] = useState(false);

	useEffect(() => {
		initialFetchHelpMenuItems();
	}, []);

	const initialFetchHelpMenuItems = () => {
		const payload = {
			dispatch: dispatch
		};
		try {
			const helpMenuItemsAction = dispatch(fetchHelpMenuItems(payload));
			helpMenuItemsAction.then((action) => {
				if(action.payload) {
					updateReleaseNote(action.payload);
				}
			});
		} catch (error) {}
	};

	const updateReleaseNote = (helpMenuItems) => {
		const releaseNoteItem = helpMenuItems.find((item) => {
	    return item.linkURL && item.linkURL.text && item.linkURL.text === 'PopupHelp';
	  });
		const content = (releaseNoteItem && releaseNoteItem.heading) ? releaseNoteItem.heading : '';
		if(!_isEmpty(content)) {
			setReleaseNotesContent(content);
		}
		const link = (releaseNoteItem && releaseNoteItem.linkURL && releaseNoteItem.linkURL.url) ? releaseNoteItem.linkURL.url : '';
		if(!_isEmpty(link)) {
			setReleaseNotesLink(link);
		}
    setShowPopup(false);
    const cookies = new Cookies();
    const cookiePublishDate = cookies.get('publishDate') ? cookies.get('publishDate') : '';
    if (releaseNoteItem.publishDate && releaseNoteItem.publishDate !== cookiePublishDate) {
      cookies.set('publishDate', releaseNoteItem.publishDate, { path: '/', maxAge: 31556926 });
      setShowPopup(true);
    }

	}

	return (
		<Fragment>
		  {!_isEmpty(releaseNotesContent) && showPopup && (
				<XModal
					show={openPopup}
					handleClose={closePopup}
					handleAction={closePopup}
					title="Release Note"
					className="custom_xmodal"
					favoriteTextValidity={true}
					submitButtonText="Ok"
					submitType
				>
					<div class="container my-4">
						<p className="release_notes bold">New RAPID Feature is released!</p>
						<p className="release_notes_content">{releaseNotesContent}</p>
					  {!_isEmpty(releaseNotesLink) && (
							<div className="release_notes_content text__center">
								<span><a href="#" onClick={() => {
									window.open(`${releaseNotesLink}?${getImageURLCookieDetails()}`, 'PDF', 'width=largeImage.stylewidth,height=largeImage.style.height,resizable=1')
								}} className="bold">Click here to learn more about this</a></span>
							</div>
						)}
					</div>
				</XModal>
			)}
		</Fragment>
	);
}

export default ReleaseNotePopup;
