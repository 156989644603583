import React, { Fragment, useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getPreferenceDataFromStorage } from "../../../helpers/utils";
import { useSelector, useDispatch } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import {
  CustomLeftArrow,
  CustomRightArrow,
  renderCarouselElements,
  getImageUrl,
  modifyArray
} from './CarouselUtils';
import { clearPartData, clearChemicalData } from './CarouselUtils';
import { chemicalApiCall, partApiCall } from './CarouselApiCalls';

const CarouselPartList = (props) => {
  const { candkcategories, vinNumber, setSelectedGroupForPartType, sellerId, store, shipMethod, selectedVehicle } = props;
  const [selectedGroupDetails, setSelectedGroupDetails] = useState([]);
  const [groups, setGroups] = useState([]);
  const [infinite, setInfinite] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const dispatch = useDispatch();
  const userPreferenceDetail = getPreferenceDataFromStorage();

  useEffect(() => {
    updateVisibleItems();
    window.addEventListener('resize', updateVisibleItems);
    return () => window.removeEventListener('resize', updateVisibleItems);
  }, [groups.length]);

  const updateVisibleItems = () => {
    const width = window.innerWidth;
    let newVisibleItems = groups;
    // if (width >= 1366 && width <= 2048 && groups.length >= 7) {
    //   setInfinite(true);
    // } else if (width > 1366 && width <= 2049) {
    //   setInfinite(groups.length > 7);
    // } else if (width < 1366 && width >= 1024) {
    //   setInfinite(groups.length >= 7);
    // } else {
    //   setInfinite(false);
    // }
    setInfinite(true);
    setVisibleItems(newVisibleItems);
  };

  useEffect(() => {
    if (!_isEmpty(candkcategories)) {
      let md = modifyArray(candkcategories);
      if (md && md.length > 0) {
        const lastItem = md[md.length - 1];
        const reorderedItems = [lastItem, ...md.slice(0, -1)];
        setGroups(reorderedItems);
        handleCustomClick("", reorderedItems[1], "");
      }
    }
  }, [candkcategories]);

  useEffect(() => {
    if (!_isEmpty(selectedGroupDetails)) {
      const hasNonPartItem = selectedGroupDetails.some(detail => detail.catalogItem !== "PartCatalog");
      if (hasNonPartItem) {
        chemicalApiCall(dispatch, selectedGroupDetails, userPreferenceDetail, sellerId, store, shipMethod);
      } else {
        partApiCall(dispatch, selectedGroupDetails, selectedVehicle, sellerId, store, shipMethod);
      }
    } else {
      clearPartData(dispatch);
      clearChemicalData(dispatch);
    }
  }, [selectedGroupDetails]);

  const handleCustomClick = (e, data, category) => {
    let selectedCloneDetails = [];
    data.groupIds.forEach(group => {
        selectedCloneDetails.push({
            id: data.id,
            name: data.name,
            groupId: group.groupId,
            partTypeId: group.partTypeId,
            categoryId: group.categoryId,
            catalogItem: data.catalogItem
        });
    });
    setSelectedGroupDetails(selectedCloneDetails);
    setSelectedGroupForPartType(selectedCloneDetails);
};


  return (
    <Fragment>
      <div className='carousel-section'>
        <div className="candkfrequenttext">
          <b>Frequently bought together based off of VIN {' '}<span>({vinNumber})</span></b>
        </div>
      </div>
      {!_isEmpty(visibleItems) && (
    <Carousel
    swipeable={false}
    draggable={false}
    showDots={false}
    responsive={{
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 6
      },
      desktop: {
        breakpoint: { max: 3000, min: 1224 },
        items: 6
      },
      largeTablet: {
        breakpoint: { max: 1224, min: 1024 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
      }
    }}
    infinite={infinite}
    autoPlaySpeed={1000}
    keyBoardControl={true}
    arrows={infinite}
    customRightArrow={infinite ? <CustomRightArrow /> : null}
    customLeftArrow={infinite ? <CustomLeftArrow /> : null}
    customTransition="all .5"
    shouldResetAutoplay={false}
    transitionDuration={500}
    containerClass="carousel-container"
    dotListClass="custom-dot-list-style"
    itemClass="carousel-item-padding-40-px"
  >
    {renderCarouselElements(visibleItems, handleCustomClick, selectedGroupDetails)}
  </Carousel>
      )}
    </Fragment>
  );
};

export default CarouselPartList;
