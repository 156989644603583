import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useState } from "react";
import _ from "lodash";

import "./styles.scss";

const SpecificConditionsReselect = (props) => {
  const { handleRequestItems } = props;
  const parts = useSelector((state) => state.parts);

  let specificConditions = _.cloneDeep(parts.specificConditions) || [];
  const [requestItems, setRequestItems] = useState(specificConditions);

  let merge = specificConditions.map((spc) => spc.specificCondition);
  merge = _.flatten(merge);
  merge = _.uniqBy(merge, (m) => m.code);

  specificConditions = merge;

  const handleSpecifics = (itemCode, answer) => {
    const payload = requestItems.map((r) => {
      r.specificCondition.map((sc) => {
        if (sc.code === itemCode) {
          sc.answer = answer;
        }

        return sc;
      });

      return r;
    });

    setRequestItems(payload);

    handleRequestItems(payload);
  };

  return (
    <React.Fragment>
      <div className="specific-conditions-reselect dynamic-col">
        {specificConditions &&
          specificConditions.map((condition, cIndex) => {
            return (
              <React.Fragment>
                {condition.answer && (
                  <div
                    className="specific__condition"
                    key={`${condition.text}`}
                  >
                    <div className="bold">{condition.text}</div>
                    {condition.partPossibleAnswers.map((item, index) => {
                      return (
                        <Form.Check
                          type="radio"
                          name={`${cIndex}_option_${condition.text.replace(
                            / /g,
                            "_"
                          )}`}
                          label={`${item.text}`}
                          id={`${item.code}_${cIndex}`}
                          defaultChecked={
                            condition.answer === item.code ? true : false
                          }
                          onClick={(e) => {
                            handleSpecifics(condition.code, item.code);
                          }}
                        />
                      );
                    })}
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </div>
    </React.Fragment>
  );
};

SpecificConditionsReselect.propTypes = {};

export default SpecificConditionsReselect;
