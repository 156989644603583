import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getAllLineCodesService,
	getCategoriesService,
	getLineCodeService,
	getPartImagesService,
	getPartInvoiceDetails,
	stockOrderPartDetailsService,
	getStockOrderROPDetails,
	getPartNumbersService,
	stockOrderUploadService,
	stockOrderPartAvailabilityService,
	stockOrderEmailROPToMeService,
	stockOrderHelpDocumentsService,
	stockOrderPreviewROPService,
	osrVisitFreequencyServiceGet,
	osrVisitFreequencyServicePost
} from './stockorder.service';

export const getAllLineCodes = createAsyncThunk('getAllLineCodes', async (payload) => {
	const response = await getAllLineCodesService(payload);
	return response.data;
});

export const getLineCode = createAsyncThunk('getLineCode', async (payload) => {
	const response = await getLineCodeService(payload);

	return response.data;
});

export const getInvoiceDetails = createAsyncThunk('getInvoiceDetails', async (payload) => {
	const response = await getPartInvoiceDetails(payload);
	return response.data;
});

export const getROPDetails = createAsyncThunk('getROPDetails', async (payload) => {
	let urlName = payload.rop === 0 ? 'delete' : 'save';
	const response = await getStockOrderROPDetails(payload, urlName);
	return response.data;
});

export const getStockOrderPartDetails = createAsyncThunk('getStockOrderPartDetails', async (payload) => {
	const response = await stockOrderPartDetailsService(payload);

	return response.data;
});

export const getCategories = createAsyncThunk('getCategories', async () => {
	const response = await getCategoriesService();

	return response.data;
});

export const getPartImages = createAsyncThunk('getPartImages', async (payload) => {
	const response = await getPartImagesService(payload);

	return response.data;
});

export const getPartNumbers = createAsyncThunk('getPartNumbers', async (payload) => {
	const response = await getPartNumbersService(payload);

	return response.data;
});

export const stockOrderUpload = createAsyncThunk('stockOrderUpload', async (payload) => {
	const response = await stockOrderUploadService(payload);

	return response.data;
});

export const stockOrderPartAvailability = createAsyncThunk('stockOrderAvailability', async (payload) => {
	const response = await stockOrderPartAvailabilityService(payload);

	return response.data;
});

export const stockOrderEmailROPToMe = createAsyncThunk('stockOrderEmailROPToMe', async (payload) => {
	const response = await stockOrderEmailROPToMeService(payload);

	return response.data;
});

export const stockOrderPreviewROP = createAsyncThunk('stockOrderPreviewROP', async (payload) => {
	const response = await stockOrderPreviewROPService(payload);

	return response;
});

export const stockOrderHelpDocuments = createAsyncThunk('stockOrderHelpDocuments', async (payload) => {
	const response = await stockOrderHelpDocumentsService();

	return response.data;
});

export const osrVisitFreequency = createAsyncThunk(
  "osrVisitFreequency",
  async (payload) => {
    if (payload?.frequency) {
      const response = await osrVisitFreequencyServicePost(payload);
      return response.data;
    } else {
      const response = await osrVisitFreequencyServiceGet(payload);
      return response.data;
    }
  }
);
