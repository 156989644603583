import React from "react";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import TableViewWrapper from "./tableview";
import PaginationComponent from "../../../components/shared/Pagination";
import {
  Card
} from "react-bootstrap";

const defaultSepartionSingleView = (rowsdata) => {
  let rowArray = [];
  rowsdata.map((part, index) => {
    rowArray.push(part);
  });

  return rowArray;
};

const externalrenderNoDataState = (isPartsNotFound) => {
  if(isPartsNotFound) {
    return (
      <div className="partnotfound-table">
      <Card className="text-center">
        <Card.Header className="xlp-primary-banner">No Parts Found</Card.Header>
        <Card.Body>
          <Card.Title>
            We couldn't find the parts that you were looking for
          </Card.Title>
        </Card.Body>
      </Card>
      </div>
    );
  } 
};


export const prepareTableChemicalDataFromUtil = (props, state, separtionCodeForBrand = () => {}, renderNoDataState = () => {}, handlePageChange = () => {}, isCatalog = true, isForCandK = false) => {
  let { currentPage, totalPages, suppliesAllDetails, isPartsNotFound } = props;
  let { selectedStore, sellPartnerId, shipMethod } = state;
  if (!_isEmpty(suppliesAllDetails)) {
    let productArray = [];
    let rows = [];
    let rowsMoreDetails = [];
    let autofocusIndexNumber = 0;
    let iterator = true;

    suppliesAllDetails.forEach((magicValue, index) => {
      if (iterator) {
        autofocusIndexNumber = index;
        iterator = false;
      }
    });

    suppliesAllDetails.forEach((part, index) => {
      let appendPart = (part.alternate && part.alternate.length > 0) || (part.substitute && part.substitute.length > 0);
      let altPartNumsArr = [];
      let subPartNumsArr = [];
      if (part.alternate && part.alternate.length > 0) {
        part.alternate.forEach((alt) => altPartNumsArr.push(alt.partSKU));
      }
      if (part.substitute && part.substitute.length > 0) {
        part.substitute.forEach((sub) => subPartNumsArr.push(sub.partSKU));
      }

      rows.push({
        availability: 1,
        part: part,
        description: part.partDescription,
        brand: part.brands,
        links: "link",
        modifiedcostPrice: part.pricingAvailability?.costPrice,
        sortAvailability: part.pricingAvailability?.availability,
        yourprice: part.pricingAvailability?.costPrice,
        listprice: part.pricingAvailability?.listPrice,
        corePrice: part.pricingAvailability?.corePrice,
        coreListPrice: part.pricingAvailability?.coreListPrice,
        extendedPrice: 0,
        qtypervehicle: part.sizeQty,
        qtyBuyInc: part.pricingAvailability?.qtyBuyInc,
        availabilities: [],
        hiddenAvail: false,
        validationRed: false,
        autoFocus: index == autofocusIndexNumber,
        totalAvailable: 0,
        rowMoreInfo: {
          partDetails: { part },
          partType: "chemical",
          appendPart: { appendPart },
          altPartNums: altPartNumsArr.join(","),
          subPartNums: subPartNumsArr.join(","),
        },
      });

      rowsMoreDetails.push({
        partDetails: { part },
        partType: "chemical",
        appendPart: { appendPart },
        altPartNums: altPartNumsArr.join(","),
        subPartNums: subPartNumsArr.join(","),
      });

      // Handling alternate parts
      if (part.alternate && part.alternate.length > 0) {
        part.alternate.forEach((altPart, index) => {
          let customAltpart = _cloneDeep(altPart);
          customAltpart.brands[0].name = part.brands[0].name;
          customAltpart.modifiedcostPrice = part.pricingAvailability.costPrice;
          customAltpart.sortAvailability = part.pricingAvailability.availability;
          let appendAltPart = index !== part.alternate.length - 1 || (part.substitute && part.substitute.length > 0);
          rows.push({
            availability: 1,
            part: altPart,
            description: altPart.partDescription,
            brand: customAltpart.brands,
            modifiedcostPrice: customAltpart.modifiedcostPrice,
            sortAvailability: customAltpart.sortAvailability,
            links: "link",
            yourprice: altPart.pricingAvailability?.costPrice,
            listprice: altPart.pricingAvailability?.listPrice,
            corePrice: altPart.pricingAvailability?.corePrice,
            coreListPrice: altPart.pricingAvailability?.coreListPrice,
            extendedPrice: 0,
            qtypervehicle: altPart.sizeQty,
            qtyBuyInc: altPart.pricingAvailability?.qtyBuyInc,
            availabilities: [],
            hiddenAvail: false,
            validationRed: false,
            totalAvailable: 0,
            rowMoreInfo: {
              partDetails: { altPart },
              isAltType: true,
              appendPart: appendAltPart,
            },
          });

          rowsMoreDetails.push({
            partDetails: { altPart },
            isAltType: true,
            appendPart: appendAltPart,
          });
        });
      }

      // Handling substitute parts
      if (part.substitute && part.substitute.length > 0) {
        part.substitute.forEach((subPart, index) => {
          let customAltpart = _cloneDeep(subPart);
          customAltpart.brands[0].name = part.brands[0].name;
          customAltpart.modifiedcostPrice = part.pricingAvailability.costPrice;
          customAltpart.sortAvailability = part.pricingAvailability.availability;
          let appendSubPart = subPart.substitute && index !== subPart.substitute.length - 1;
          rows.push({
            availability: 1,
            part: subPart,
            description: subPart.partDescription,
            brand: customAltpart.brands,
            sortAvailability: customAltpart.sortAvailability,
            modifiedcostPrice: customAltpart.modifiedcostPrice,
            links: "link",
            coreListPrice: subPart.pricingAvailability?.coreListPrice,
            corePrice: subPart.pricingAvailability?.corePrice,
            yourprice: subPart.pricingAvailability?.costPrice,
            listprice: subPart.pricingAvailability?.listPrice,
            extendedPrice: 0,
            qtypervehicle: subPart.sizeQty,
            qtyBuyInc: subPart.pricingAvailability?.qtyBuyInc,
            availabilities: [],
            hiddenAvail: false,
            validationRed: false,
            totalAvailable: 0,
            rowMoreInfo: {
              partDetails: subPart,
              isSubType: true,
              appendPart: appendSubPart,
            },
          });

          rowsMoreDetails.push({
            partDetails: subPart,
            isSubType: true,
            appendPart: appendSubPart,
          });
        });
      }
    });

    let partTypeAndBrandSepartion = isCatalog ? separtionCodeForBrand(rows) : defaultSepartionSingleView(rows);
    productArray.push(
      <TableViewWrapper
        userDetails={props.userDetails}
        rows={partTypeAndBrandSepartion}
        rowsMoreDetails={rowsMoreDetails}
        selectedStore={selectedStore}
        sellPartnerId={sellPartnerId}
        shipMethod={shipMethod}
        isForCandK={isForCandK}
      />
    );

    productArray.push(
      <PaginationComponent
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    );

    return productArray;
  } else {
    return isCatalog ? renderNoDataState() : externalrenderNoDataState(isPartsNotFound);
  }
};
