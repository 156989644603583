import React, { Fragment, useRef } from 'react';
import { Row, Col,  OverlayTrigger, Tooltip} from 'react-bootstrap';
import _isEmpty from "lodash/isEmpty";

import { formatAmount, getExtendedTotalPrice  } from "../../../helpers/utils";
import Placeholder from "../../shared/Placeholder";
import ReactToPrint from 'react-to-print';

const OrderPreview = ({ cartAllDetails }) => {
	let totalQty = 0;
	let totalCostPrice = 0;
	let totalListPrice = 0;
	let totalExtendedPrice = 0;
	let cartConsolidateArr = [];
	let componentRef = useRef([]);
	if (!_isEmpty(cartAllDetails)) {
		const cartList = cartAllDetails.map(obj => ({ ...obj }))
		cartList.map((one) => {
			let matched = false;
			if (_isEmpty(cartConsolidateArr)) {
				cartConsolidateArr.push(one)
			} else {
				matched = cartConsolidateArr.findIndex((two, index) => {
					return ((two.partNum === one.partNum) && (two.mfgCode === one.mfgCode) && (two.desc === one.desc))
				}
				)
				if (matched >= 0) {
					cartConsolidateArr[matched].qty = cartConsolidateArr[matched].qty + one.qty;
				} else {
					cartConsolidateArr.push(one);
				}
			}
		})
	}
	if (cartConsolidateArr && cartConsolidateArr.length > 0) {
		cartConsolidateArr.map(item => {
			totalQty = totalQty + item.qty
			totalCostPrice = totalCostPrice + item.costPrice
			totalListPrice = totalListPrice + item.listPrice
			totalExtendedPrice = totalExtendedPrice + (item.qty * item.costPrice) + (item.qty * item.corePrice)
		})
	}
	return (
		<div className="order-preview-table" ref={el => (componentRef = el)}>
			{/* <ReactToPrint
				copyStyles={true}
				documentTitle="Nishanth"
				trigger={() => {

					return <a href="#">Print this out!</a>;
				}}
				content={() => componentRef}
			   />   */}
			{
				cartConsolidateArr && cartConsolidateArr.length === 0 ?
					<span>There are no items in your cart.</span>
					:
					<Fragment>
						<Row className="order-preview-headers">
							<Col lg="1"><span>P/L</span></Col>
							<Col lg="2"><span>Part Number</span></Col>
							<Col lg="1"><span>Order QTY</span></Col>
							<Col lg="3"><span>Description</span></Col>
							<Col lg="1"><span>Your Price</span></Col>
						<Col lg="1"><span>Core Price</span></Col>				
						<Col lg="1"><span>List Price</span></Col>
						<Col lg="2"><span>Total Extended Price {" "}</span>
						<OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                 overlay={
                                <Tooltip id="description-part-catalog-tooltip" className="tooltip-description">
                                <span className="font-weight-bold">
                                Total Extended Price = Qty * (Your Price + Core Price)
                                  </span>
                                </Tooltip>
                        }>
                        <a className="maroonColorTxt info-icon">
                        <i class="fa fa-info-circle"></i>
                        </a>
                    </OverlayTrigger>
                        </Col>
						</Row>
						<div className="order-preview-data-container">
							{
								cartConsolidateArr && cartConsolidateArr.length ?
									cartConsolidateArr.map(item => (
										<Row className="order-preview-data">
									<Col lg="1 p-0">
										<span className="mobileTitle">P/L:</span>
										<span>{item.mfgCode}</span>
									</Col>
									<Col lg="2">
										<span className="mobileTitle">Part Number:</span>
										<span>{item.partNum}</span>
									</Col>
									<Col lg="1">
										<span className="mobileTitle">Order QTY:</span>
										<span>{item.qty}</span>
									</Col>
									<Col lg="3">
										<span className="mobileTitle">Description:</span>
										<span>{item.desc}</span>
									</Col>
									<Col lg="1">
										<span className="mobileTitle">Your Price:</span>
										<span className="maroonColorTxt">${formatAmount(item.costPrice)}</span>
									</Col>
									<Col lg="1 pl-4">
										<span className="mobileTitle">Core Price:</span>
										<span className="maroonColorTxt">${formatAmount(item.corePrice)}</span>
									</Col>
									<Col lg="1 pl-3">
										<span className="mobileTitle">List Price:</span>
										<span className="maroonColorTxt">${formatAmount(item.listPrice)}</span>
									</Col>
									<Col lg="2 pl-5">
										<span className="mobileTitle">Total Extended Price:</span>
										<span className="maroonColorTxt">${getExtendedTotalPrice(item.corePrice , item.costPrice ,item.qty)}</span>
									</Col>
								</Row>
									))
									:
									<Placeholder rows={1} />
							}
						</div>
						<Row className="total-count">
							<Col lg="3 p-0"><span>Total</span></Col>
							<Col lg="2">
								<span className="mobileTitle">Total QTY:</span>
								<span>{totalQty}</span>
							</Col>
							<Col lg={{ span: 2, offset: 3 }} md={{ offset: 0 }} justify-content-sm-left>
								{/* <span className="mobileTitle">Total List Price:</span>
					<span className="maroonColorTxt">${formatAmount(totalListPrice)}</span> */}
							</Col>
							{/* <Col lg="2">
					<span className="mobileTitle">Total Your Price:</span>
					<span className="maroonColorTxt">${formatAmount(totalCostPrice)}</span>
				</Col>				 */}
							<Col lg="2 pl-5">
								<span className="mobileTitle">Total Extended Price:</span>
								<span className="maroonColorTxt">${formatAmount(totalExtendedPrice)}</span>
							</Col>
						</Row>
					</Fragment>
			}
		</div>
	)
}

export default OrderPreview;
