/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Spinner, Row, Col } from "react-bootstrap";
import { Button } from "ui-library";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";

import { isEmpty } from "lodash";
import _isEmpty from "lodash/isEmpty";
import ProgramsData from "./programsData";

import { useLocation } from "react-router-dom";
import { programsListDetails } from "./programbenefits.thunk";
import { resetBenefitData } from "./programbenefits.slice";
import _cloneDeep from "lodash/cloneDeep";
import { formatMonth, getPreferenceDataFromStorage } from "../../helpers/utils";
import {
  getAllCustomers,
  impersonateUserBillTo,
} from "../User/UserManagement/usermanagement.thunk";
import XModal from "../shared/XModal";
import { clearCart } from "../../actions/cart";
import { updateToken } from "../../actions/auth";
import { triggerCustomUxCriticalError } from "../../actions/error";
import { setVehicleSelected } from "../shared/MyGarage/mygarage.slice";
import DocumentUpload from "./DocumentUpload";
import XCharts from "components/Charts";
import _find from "lodash/find";

import moment from "moment";
import ItemsRender from "pages/c_and_k/SingleView/items";
const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const GAP = 16;

const ProgramAndBenefits = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const usersprogramData = useSelector(
    (state) => state.userManagement.userProgramAccesData
  );
  const cartStatus = useSelector((state) => state.cart);
  const userDetails = user.userDetails;
  const location = useLocation();
  const tableSpecItemsRef = useRef([]);
  const [programName, setprogramName] = useState("");
  const [accessProgram, setAccessProgram] = useState(true);
  const [locationName, setLocationName] = useState(null);
  const [showLoader, setshowLoader] = useState(true);
  const [showBillTOclearModal, setshowBillTOclearModal] = useState(false);
  const [showChangeCustomerBillTo, SetShowChangeCustomerBillTo] =
    useState(false);
  const [showBillTOModal, setshowBillTOModal] = useState(false);
  const [billTo, setbillTo] = useState("");
  const [buttonState, setbuttonState] = useState(false);
  const [superAdminUserList, setSuperAdminUserList] = useState([]);
  const [isBillToUpdated, setIsBillToUpdated] = useState(false);
  const [userDef, setUserDef] = useState([]);
  const programNBenefits = useSelector((state) => state.ProgramAndBenefits);

  let billToStatus = useSelector((state) => state.userManagement.billToStatus);

  const defaultPrograms = [
    {
      ptDescription: "Customer Dashboard",
      ptName: "CustomerDashBoard",
      userDef: ["e"],
      enable: false,
      isVisible: true,
    },

    // {
    //   ptDescription: "XLP Rewards",
    //   ptName: "XLPREWARDS",
    //   userDef: ["XLPR"],
    //   enable: false,
    //   isVisible: true,
    // },
    {
      ptDescription: "Online Ordering Rebate",
      ptName: "OnlineOrderingRebate",
      userDef: ["e"],
      enable: false,
      isVisible: true,
    },
    {
      ptDescription: "Labor Claim",
      ptName: "LaborClaim",
      userDef: ["e"],
      enable: true,
      isVisible: true,
    },
    {
      ptDescription: "PartsPlus Car Care Center",
      ptName: "ProntoSmartChoice",
      userDef: [
        "CCC",
        "CCCP"
      ],
      enable: false,
      isVisible: true,
    },
    {
      ptDescription: "MTA PSN",
      ptName: "MTAPSN",
      userDef: ["PSN", "PSNE"],
      enable: false,
      isVisible: true,
    },
    {
      ptDescription: "ACD GM Partner Perks",
      ptName: "ACDGMPartnerPerks",
      userDef: ["GMPP-600181"],
      enable: false,
      isVisible: true,
    },
    {
      ptDescription: "Battery Program",
      ptName: "BatteryConsignment",
      userDef: ["BC9930293", "BC9012718"],
      enable: false,
      isVisible: true,
    },
    {
      ptDescription: "PFP Volume Rebate",
      ptName: "PVPVolumeRebate",
      userDef: ["PFPR"],
      enable: false,
      isVisible: true,
    },
  ];
  const [programList, setprogramList] = useState(defaultPrograms);

  const [defaultVal, setDefaultValue] = useState({
    label: "select Programs and Benefits",
    value: "selectProgramsandBenefits",
  });

  useEffect(() => {
    dispatch({
        payload: {
            globalLoader: !showLoader
        },
        type: actionTypes.GLOBAL_LOADER
    });
  }, [showLoader]);

  useEffect(() => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    if (billToStatus === "failed") {
      statusMessageProps.heading = "BillTo modification Failed";
      statusMessageProps.type = "error";
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
    }
  }, [billToStatus]);
  useEffect(() => {
    setprogramList(defaultPrograms);
    setIsBillToUpdated(!isBillToUpdated);
  }, [usersprogramData]);

  useEffect(() => {
    if (
      !isEmpty(usersprogramData) &&
      !isEmpty(userDetails) &&
      !isEmpty(userDetails.billTo)
    ) {
      getProgramData(userDetails);
    }
  }, [isBillToUpdated]);
  useEffect(() => {
    if (user.userDetails.userType !== null) {
      let userPreferenceDetail = getPreferenceDataFromStorage();
      if (!_isEmpty(userPreferenceDetail)) {
        let grandAccessItemSet = userPreferenceDetail.find((item) => {
          return (
            item.text === "Personalize Linked Accounts" &&
            item.code === "User Management"
          );
        });

        if (grandAccessItemSet && grandAccessItemSet.multiAnswer) {
          grandAccessItemSet.multiAnswer.map((singleAnswer) => {
            if (singleAnswer.code === "enableUserEmulation") {
              if (
                user.userDetails.userType === "CUSTOMER_BUYER" ||
                user.userDetails.userType === "LOCATION_BUYER" ||
                user.userDetails.userType === "CUSTOMER_ADMIN"
              ) {
                SetShowChangeCustomerBillTo(true);
              }
            }
          });
        }
      }
    }
  }, [user]);

  const getProgramData = (userData) => {
    let userPreferenceDetail = getPreferenceDataFromStorage();
    let preferenceDetail = {};
    let programPreference = {};
    if (!isEmpty(userPreferenceDetail)) {
      preferenceDetail = userPreferenceDetail.find((item) => {
        return item.text === "Programs" && item.code === "User Management";
      });
    }
    if (
      !isEmpty(preferenceDetail) &&
      !isEmpty(preferenceDetail.possibleAnswer) &&
      !isEmpty(preferenceDetail.possibleAnswer[0])
    ) {
      programPreference = JSON.parse(preferenceDetail.possibleAnswer[0].text);
    }
    if (!isEmpty(userData) && !isEmpty(userData.billTo)) {
      const { billTo } = userData || {};
      dispatch({
        payload: {
          globalLoader: true,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
      let customerNumber = billTo.customerCode;
      try {
        dispatch(programsListDetails(customerNumber)).then((action) => {
          dispatch({
            payload: {
              globalLoader: false,
            },
            type: actionTypes.GLOBAL_LOADER,
          });

          let preferedProgram = !isEmpty(action.payload?.[0])
            ? action.payload[0]
            : [];
          let programClone = _cloneDeep(programList);
          if (!isEmpty(usersprogramData)) {
            programClone.map((pgm, inde) => {
              if (pgm.ptName === "LaborClaim") {
                if (usersprogramData.lcFlag) {
                  pgm.isVisible = usersprogramData.lcFlag;
                  pgm.enable = usersprogramData.lcFlag;
                } else {
                  pgm.isVisible = usersprogramData.lcFlag;
                  pgm.enable = usersprogramData.lcFlag;
                }
              }
            });
          }
          //this is  for checking enrolled programs based on userDef values
          !isEmpty(preferedProgram) &&
            Object.keys(preferedProgram).map((key) => {
              setUserDef(preferedProgram);
              programClone.map((val, inde) => {
                if (
                  val.userDef.some(
                    (item) =>
                      (val.isVisible &&
                        ((val.ptName === "MTAPSN" &&
                          key === "userDef7" &&
                          !isEmpty(preferedProgram[key])) ||
                      
                          (val.ptName === "PVPVolumeRebate" &&
                            key === "userDef11" &&
                            !isEmpty(preferedProgram[key]) &&
                            preferedProgram[key].includes("PF")) ||
                            (val.ptName === "ProntoSmartChoice" &&
                              key === "userDef9" &&
                              !isEmpty(preferedProgram[key]) &&
                              preferedProgram[key].includes("CCC")) ||
                          (val.ptName === "OnlineOrderingRebate" &&
                            key === "userDef18" &&
                            isEmpty(preferedProgram[key])) ||
                          (val.ptName === "OnlineOrderingRebate" &&
                            key === "userDef18" &&
                            !isEmpty(preferedProgram[key]) &&
                            preferedProgram[key] !== "NEO"))) ||
                      item === preferedProgram[key] ||
                      (item.includes("GMPP") &&
                        preferedProgram[key].includes("GMPP")) ||
                      (item.includes("BC9") &&
                        preferedProgram[key].includes("BC"))
                  )
                ) {
                  val.enable = true;
                }
              });
            });

          //this is for hide/show the programs based on userDef values
          !isEmpty(preferedProgram) &&
            Object.keys(preferedProgram).map((key) => {
              programClone.map((val, inde) => {
                if (
                  val.userDef.some(
                    (item) =>
                      val.isVisible &&
                      val.ptName === "OnlineOrderingRebate" &&
                      key === "userDef18" &&
                      !isEmpty(preferedProgram[key]) &&
                      preferedProgram[key] === "NEO"
                  )
                ) {
                  val.isVisible = false;
                }
              });
            });

          !isEmpty(programPreference) &&
            programClone.map((val, inde) => {
              if (
                val.enable &&
                programPreference.some(
                  (p) => p.classification === 1 && val.ptName === p.name
                )
              ) {
                val.isVisible = true;
              }
              if (
                val.enable &&
                programPreference.some(
                  (p) => p.classification === 2 && val.ptName === p.name
                )
              ) {
                val.isVisible = true;
              }
              if (
                !val.enable &&
                programPreference.some(
                  (p) => p.classification === 2 && val.ptName === p.name
                )
              ) {
                val.isVisible = false;
              }
              if (
                val.enable &&
                programPreference.some(
                  (p) => p.classification === 3 && val.ptName === p.name
                )
              ) {
                val.isVisible = false;
              }
              if (
                !val.enable &&
                programPreference.some(
                  (p) => p.classification === 3 && val.ptName === p.name
                )
              ) {
                val.isVisible = false;
              }
            });
          setprogramList(programClone);
          console.log("programClone===>",programClone);
          let alreadyVisited = false;
          programClone.map((data, index) => {
            if (data.isVisible && !alreadyVisited) {
              alreadyVisited = true;
              setprogramName(programClone[index].ptName);
              setAccessProgram(programClone[index].enable);
            }
            return;
          });
        });
      } catch (error) {
        console.error("error", error);
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      }
    }
  };

  useEffect(() => {
    const locationURL = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    setprogramName(locationURL);
    let selectedValue = {
      label: "select Programs and Benefits",
      value: "selectProgramsandBenefits",
    };

    programList.forEach((val) => {
      if (val.value === locationURL) {
        selectedValue = { label: val.label, value: val.value };
      }
    });

    setDefaultValue(selectedValue);
  }, [location]);

  const programNameHandler = (locName, ptName, enabledForUser) => {
    //setDefaultValue(benefit);
    dispatch(resetBenefitData());
    setLocationName(locName);
    setprogramName(ptName);
    setAccessProgram(enabledForUser);
  };
  const handleUpdate = () => {
    setshowLoader(false);
    if (
      cartStatus.qtyInCart > 0 &&
      (user.userDetails.userType === "SUPER_ADMIN" || showChangeCustomerBillTo)
    ) {
      setshowLoader(true);
      setshowBillTOclearModal(true);
    } else if (
      cartStatus.qtyInCart === 0 &&
      (user.userDetails.userType === "SUPER_ADMIN" || showChangeCustomerBillTo)
    ) {
      setshowLoader(false);
      changeBillTo();
    } else if (
      user.userDetails.userType === "IT_ADMIN" ||
      user.userDetails.userType === "OSR" ||
      user.userDetails.userType === "IT_INTERNAL" ||
      user.userDetails.userType === "DSM" ||
      user.userDetails.userType === "OPERATIONS_MANAGER"
    ) {
      const payload = {
        billTo,
        x2UserName: user.userDetails.x2userName,
        userType: user.userDetails.userType,
        buyPartnerCreationFlag: false,
        dispatch,
      };
      dispatch(impersonateUserBillTo(payload)).then((action) => {
        if (action.type === "impersonateUserBillTo/fulfilled") {
          setshowLoader(true);
          if (cartStatus.qtyInCart > 0) {
            setshowBillTOclearModal(true);
          } else {
            deleleteMethod();
          }
        } else if (action.payload.errorCode === "E1112") {
          setshowLoader(true);
          setshowBillTOModal(true);
        }
        setshowLoader(true);
      });
    }
  };
  const changeBillTo = () => {
    setshowBillTOclearModal(false);
    const payload = {
      billTo: billTo.value,
      x2UserName: user.userDetails.x2userName,
      userType: user.userDetails.userType,
      buyPartnerCreationFlag: true,
      dispatch,
    };
    dispatch(impersonateUserBillTo(payload)).then((action) => {
      setbillTo("");
      updateToken(dispatch).then(() => {
        let statusMessageProps = {
          heading: "",
          message: "",
          type: "",
        };
        if (action.payload == "Change BillTo successful") {
          dispatch(getAllCustomers()).then((result) => {
            setSuperAdminUserList(result.payload);
          });
          statusMessageProps.heading = "BillTo modified successfully";
          statusMessageProps.type = "success";
          triggerCustomUxCriticalError(statusMessageProps, dispatch);
          setbillTo("");
          setshowBillTOModal(false);
          setshowBillTOclearModal(false);
          setshowLoader(true);
          dispatch(setVehicleSelected(null));
          dispatch({
            payload: {
              selectedVehicle: null,
            },
            type: "VEHICLE_SELECTION_COMPLETED",
          });
        }
      });
      setshowLoader(true);
    });
  };

  const deleleteMethod = async () => {
    if (
      user.userDetails.userType == "SUPER_ADMIN" ||
      showChangeCustomerBillTo
    ) {
      setshowLoader(true);
      changeBillTo();
    }
    if (cartStatus.qtyInCart > 0) {
      await clearCart(dispatch);
    }
    if (
      user.userDetails.userType === "IT_ADMIN" ||
      user.userDetails.userType === "OSR" ||
      user.userDetails.userType === "IT_INTERNAL" ||
      user.userDetails.userType === "DSM" ||
      user.userDetails.userType === "OPERATIONS_MANAGER"
    ) {
      setTimeout(() => {
        updateToken(dispatch).then(() => {
          let statusMessageProps = {
            heading: "",
            message: "",
            type: "",
          };
          if (
            billToStatus === "success" ||
            billToStatus.payload?.errorCode === "E1112"
          ) {
            statusMessageProps.heading = "BillTo modified successfully";
            statusMessageProps.type = "success";
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
            setbillTo("");
            setshowBillTOModal(false);
            setshowBillTOclearModal(false);
            setshowLoader(true);
            dispatch(setVehicleSelected(null));
            dispatch({
              payload: {
                selectedVehicle: null,
              },
              type: "VEHICLE_SELECTION_COMPLETED",
            });
          } else if (billToStatus === "failed") {
            statusMessageProps.heading = "BillTo modification Failed";
            statusMessageProps.type = "error";
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
          }
        });
      }, 2000);
    }
  };
  const handleUpdatecreate = () => {
    const payload = {
      billTo,
      x2UserName: user.userDetails.x2userName,
      userType: user.userDetails.userType,
      buyPartnerCreationFlag: true,
      dispatch,
    };
    setshowLoader(false);
    dispatch(impersonateUserBillTo(payload)).then((action) => {
      if (action.type === "impersonateUserBillTo/fulfilled") {
        setshowLoader(true);
        if (cartStatus.qtyInCart > 0) {
          setshowBillTOclearModal(true);
        } else {
          setshowBillTOModal(false);
          deleleteMethod();
        }
        //deleleteMethod();
      }
      setshowLoader(true);
    });
    setshowBillTOModal(false);
  };

  const modifyPartnerPacksData = () => {
    const partnerPacks =
      (programNBenefits.benefitData && programNBenefits.benefitData[0]) || [];
    const acdGMPartner = partnerPacks.acdGmPartner || [];
    const graphData = acdGMPartner?.map((acd) => {
      const m = moment();
      const thisYear = m.year();
      const lastyear = thisYear - 1;
      return {
        name: formatMonth(acd.monthYear),
        [thisYear]: acd.sales,
        [lastyear]: acd.salesLYMonth,
      };
    });

    return graphData;
  };

  const modifyPartnerPacksDataNivo = () => {
    const partnerPacks =
      (programNBenefits.benefitData && programNBenefits.benefitData[0]) || [];
    const acdGMPartner = partnerPacks.acdGmPartner || [];
    const m = moment();
    const thisYear = m.year();
    const lastyear = thisYear - 1;
    const years = [
      { year: thisYear, color: "#8884d8" },
      { year: lastyear, color: "#82ca9d" },
    ];
    const gData = years.map((y, i) => {
      const graphData = acdGMPartner?.map((acd) => {
        return {
          x: formatMonth(acd.monthYear),
          y: i === 0 ? acd.sales : acd.salesLYMonth,
        };
      });

      return { id: y.year, color: y.color, data: graphData };
    });

    return gData;
  };

  const handleProgramTab = (pName) => {
    const show = _find(programList, {
      ptName: pName,
    }).enable;
    const locName = _find(programList, {
      ptName: pName,
    }).ptDescription;
    setLocationName(locName);
    setprogramName(pName);
    setAccessProgram(show);
  };

  return (
    <React.Fragment>
      <div className="program-benifits-home">
        <div className="container-fluid">
          <div className="program-benifits_wrapper">
            <div className="bread_crum_section">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>Programs and Benefits</span>
                </li>
              </ul>
            </div>
            <div className="billto-wrapper">
              {user.userDetails &&
                (user.userDetails.userType === "IT_ADMIN" ||
                  user.userDetails.userType === "IT_INTERNAL" ||
                  user.userDetails.userType === "OPERATIONS_MANAGER" ||
                  user.userDetails.userType === "OSR" ||
                  user.userDetails.userType === "DSM") && (
                  <div className="label-input-container-program">
                    <label htmlFor="currentPassword">Change BillTo</label>
                    <Form.Control
                      type="text"
                      onChange={(event) => setbillTo(event.target.value)}
                      name="currentPassword"
                      id="currentPassword"
                      value={billTo}
                    />
                    {showLoader ? (
                      <Button
                        onClick={handleUpdate}
                        disabled={_isEmpty(billTo)}
                        className="changeBillto_button ok-button"
                        variant="contained"
                      >
                        ok
                      </Button>
                    ) : (
                      <Button
                        className="changeBillto_button ok-button"
                        variant="contained"
                        disabled
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </Button>
                    )}
                  </div>
                )}
              <div className="billto-details-wrapper">
                <ItemsRender
                  items={[
                    {
                      label: "XL Parts Account:",
                      value:
                        user.userDetails && user.userDetails.billTo
                          ? `${user.userDetails.billTo.billToId} - ${user.userDetails.billTo.name}`
                          : ``,
                    },
                    {
                      label: "ShipTo Address:",
                      value: (
                        <span>
                          <span>
                            {`${user.userDetails.shipTos[0].address}, ${user.userDetails.shipTos[0].city} ,`}
                          </span>
                          <span>
                            {`${user.userDetails.shipTos[0].state} , ${user.userDetails.shipTos[0].zip}`}
                          </span>
                        </span>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          {showBillTOModal && (
            <XModal
              show={showBillTOModal}
              showButtons={false}
              title="Create Impersonate User"
              className="change_shipTo_save_quote_modal"
              handleClose={() => setshowBillTOModal(false)}
            >
              <div className="change_shipTo_modal_heading">
                <span className="shipTo_modal_text">
                  This Customer is not yet registered in RAPID. Do you want to
                  really proceed?
                </span>
              </div>
              <div className="shipTo_modal_button_padding">
                <span className="shipTo_clear_cart_button">
                  {showLoader ? (
                    <Button
                      className="add_to_cart_button"
                      disable={buttonState && !_isEmpty(billTo)}
                      onClick={handleUpdatecreate}
                      variant="contained"
                    >
                      YES
                    </Button>
                  ) : (
                    <Button
                      className="user-management-submit-btn"
                      variant="contained"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </Button>
                  )}
                </span>
                <span className="shipTo_save_as_quote_button">
                  <Button
                    className="add_to_cart_button"
                    onClick={() => setshowBillTOModal(false)}
                    variant="contained"
                  >
                    NO
                  </Button>
                </span>
              </div>
            </XModal>
          )}
          {showBillTOclearModal && (
            <XModal
              show={showBillTOclearModal}
              showButtons={false}
              title="Create Impersonate User"
              className="change_shipTo_save_quote_modal"
              handleClose={() => setshowBillTOclearModal(false)}
            >
              <div className="change_shipTo_modal_heading">
                <span className="shipTo_modal_text">
                  Changing BillTo will empty your Cart. Do you really want to
                  proceed?
                </span>
              </div>
              <div className="shipTo_modal_button_padding">
                <span className="shipTo_clear_cart_button">
                  {showLoader ? (
                    <Button
                      className="add_to_cart_button"
                      disable={buttonState && !_isEmpty(billTo)}
                      onClick={deleleteMethod}
                      variant="contained"
                    >
                      YES
                    </Button>
                  ) : (
                    <Button
                      className="user-management-submit-btn"
                      variant="contained"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </Button>
                  )}
                </span>
                <span className="shipTo_save_as_quote_button">
                  <Button
                    className="add_to_cart_button"
                    onClick={() => setshowBillTOclearModal(false)}
                    variant="contained"
                  >
                    NO
                  </Button>
                </span>
              </div>
            </XModal>
          )}
          <DocumentUpload />

          <div className="select-programs">
            {true ? (
              <div className="view-container">
                <Row>
                  <Col lg={2} md={2} sm={2} className="spec-categories p-0">
                    <ul className="table-spec-categories">
                      {!isEmpty(programList) &&
                        programList.map((program, index) => {
                          return (
                            program.isVisible &&
                            program.isVisible === true && (
                              <li
                                className={
                                  program.ptName === programName
                                    ? "table_selected_spec"
                                    : "table_un_selected_spec"
                                }
                                ref={(el) =>
                                  (tableSpecItemsRef.current[index] = el)
                                }
                                onClick={(e) =>
                                  programNameHandler(
                                    program.ptDescription,
                                    program.ptName,
                                    program.enable
                                  )
                                }
                              >
                                <span>{program.ptDescription}</span>
                              </li>
                            )
                          );
                        })}
                    </ul>
                  </Col>
                  {!isEmpty(programName) ? (
                    <React.Fragment>
                      <Col lg={10} md={10} sm={10}>
                        <Row className="table-row">
                          <Col lg={12} md={12} sm={12}>
                            <ProgramsData
                              programName={programName}
                              userHasAccess={accessProgram}
                              userDef={userDef}
                              handleProgramTab={handleProgramTab}
                              programListAcess={programList}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </React.Fragment>
                  ) : null}
                </Row>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProgramAndBenefits;
