import React, { useState, useEffect } from 'react';
import { Col, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import settingIcon from '../../static/images/settingIcon.png';
import showIcon from "../../static/images/show.png";
import hideIcon from "../../static/images/hideIcon.png";
import {
	hasAlphaSpecialIntChar,
	isMaxLengthLessthan,
	formatAmount,
	getPreferenceDataFromStorage
} from '../../helpers/utils';
import _isEmpty from "lodash/isEmpty";
import FormValidationErrorMessage from '../shared/FormValidationErrorMessage/FormValidationErrorMessage';
import { useSelector } from 'react-redux';
import _find from "lodash/find";
import _every from "lodash/every";
import Catalog from "../../static/js/Content/scripts/catalog-v3.0.0";

function CartPricingDetails(props) {
	const initializeValidity = () => {
		const validity = {
			// isPurchaseOrdAlphaSpclChar: true,
			isPurchaseOrdMaxLength: true,
			isOrdCmntAlphaIntSpcl: true,
			isOrdCmntLessThanLimit: true,
			isPurchaseOrdPatternValid: true,
			isPOPatternConfigFound: true
		};
		return validity;
	};

	let {
		clearUserCart,
		placeOrder,
		saveAsQuote,
		saveAsJob,
		totalBillingInfo,
		hideCostPrice,
		validCart,
		jobSaved,
		handlePoNum,
		handleSettings,
		poNum,
		userDetails,
	} = props;

	const history = useHistory();
	const cart = useSelector(state => state.cart);
	const [validity, setvalidity] = useState(initializeValidity);
	const [mailNotifications, setmailNotifications] = useState(true);
	const [comment, setOrderComment] = useState('');
	const [accPartStatus, setaccPartStatus] = useState(true);
	const loading = useSelector((state) => state.cart.apiLoading);
	const userType = useSelector((state)=>state.user.userDetails.userType)
	const [enablePlaceOrder, setEnablePlaceOrder] = useState(false);
	const refreshTokenStatus = useSelector(state => state.app.refreshTokenStatus);
	const [enablePlaceOrderForSW, setEnablePlaceOrderForSW] = useState( (userType === "SERVICE_WRITER" || userType === "VISITOR" ) ? true : false);
	const placeOrderBtnStr = userType === "SMS_MITCHELL1" ? "Transfer Parts" : "Place Order";
	const apiKey = 'apikey-12345-key';
	const catalogSdk = new Catalog(apiKey, false);
	const parts = useSelector((state) => state.parts);
	const hideAllPrice =  parts  && parts.hidePrice;

	useEffect(() => {
		let status = true;
		if (!_isEmpty(userDetails) && userDetails.billTo.accPart === "Y") {
			status = false;
		}
		status = setDefaultPreference('Cart', 'Ship All Together', 'Y')
		setaccPartStatus(status);
		let mailNofification = setDefaultPreference('Cart', 'Order Notification', 'N');
		setmailNotifications(mailNofification);

		if(userType !== null) {
			let userPreferenceDetail = getPreferenceDataFromStorage();
			if(!_isEmpty(userPreferenceDetail)){
				let grandAccessItemSet = userPreferenceDetail.find((item)=>{
					return(
						item.text === "Grant Access To IT Admin" &&
						item.code === "User Management"
					) 
				})
				let grandAccess = userPreferenceDetail.find((item)=>{
					return(
					 item.text == "Grant Access To" && item.code == "User Management") 
				})

				if(grandAccessItemSet && grandAccessItemSet.multiAnswer){
				grandAccessItemSet.multiAnswer.map((singleAnswer)=>{
					if(singleAnswer.code == "enableOrderPlace"){
						setEnablePlaceOrder(true);
					}
				})
				}
				if(grandAccess && grandAccess.multiAnswer){
					grandAccess.multiAnswer.map((singleAnswer)=>{
						if(singleAnswer.code == "orderPlacement" ){
							if(userType === "SERVICE_WRITER"){
							  setEnablePlaceOrderForSW(false);
							}
						}
					})
				}
			}
		  }
	}, [userDetails])
	
	const setDefaultPreference = (prefCode, prefText, answerValue) => {
		let prefValue = true;
		const userPreferenceDetail = getPreferenceDataFromStorage();
		if (!_isEmpty(userPreferenceDetail)) {
			let matchedPref = {};
			matchedPref = _find(userPreferenceDetail, {
				code: prefCode,
				text: prefText
			});
			if (answerValue == 'Y') { prefValue = matchedPref && ((matchedPref.answer && matchedPref.answer.code && matchedPref.answer.code == "Y") || (matchedPref.answer === null)) ? true : false }

			if (answerValue == 'N') { prefValue = matchedPref && ((matchedPref.answer && matchedPref.answer.code && matchedPref.answer.code == "N") || (matchedPref.answer === null)) ? false : true }
		}
		return prefValue;
	}

	const handleOrderChange = (event) => {
		const { value } = event.target;
		let validity = initializeValidity();
		// validity.isPurchaseOrdAlphaSpclChar = hasAlphaSpecialIntChar(value);
		validity.isPurchaseOrdMaxLength = isMaxLengthLessthan(value, 150);
		validity.isPurchaseOrdPatternValid = isPOPatternValid(value);
		validity.isPOPatternConfigFound = isPurchOrdOPatternConfigFound(value);
		setvalidity(validity);
		handlePoNum(event);
	};
	const commentTextLimit = () => {
		let limit = 500;
		let commentLength = comment.length;
		const commentCharLeft = limit - commentLength;
		if (commentCharLeft >= 0) {
			const commentText = `${commentCharLeft}/${limit}`;
			return commentText;
		}
		return '';
	};
	const handleOrderCmntChange = (event) => {
		const { value } = event.target;
		let validity = initializeValidity();
		validity.isOrdCmntAlphaIntSpcl = hasAlphaSpecialIntChar(value);
		validity.isOrdCmntLessThanLimit = isMaxLengthLessthan(value, 500);
		setvalidity(validity);
		setOrderComment(value);
	};

	const isPOPatternValid = (str) => {
		const poRegex =
				userDetails &&
				userDetails.billTo &&
				userDetails.billTo.poRegex;
		if(poRegex && !_isEmpty(poRegex)) {
			var expression = new RegExp(poRegex);
			return expression.test(str);
		}
		return true;				
	};

	const isPurchOrdOPatternConfigFound = (str) => {
		const poPattern =
				userDetails &&
				userDetails.billTo &&
				userDetails.billTo.poPattern;
		const poRegex =
				userDetails &&
				userDetails.billTo &&
				userDetails.billTo.poRegex;
		if(poPattern && (!poRegex || _isEmpty(poRegex))) {
			return false;				
		}
		return true;				
	};

	const handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right, Control A
		return (code !== 8 && code !== 9 && code !== 37 && code !== 38 && code !== 39 && code !== 40 && code !== 46 && code !== 17 && code !== 65);
	}

	const handleKeyDown = (event, name) => {
		const orderComment = event.target.value;
		switch (name) {
			case 'purchaseOrder':
				if (orderComment && orderComment.length >= 150 && handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
				break;
			case 'orderComment':
				if (orderComment && orderComment.length >= 500 && handleOtherKeys(event.keyCode)) {
					event.preventDefault();
				}
				break;
			default:
				break;
		}
	};

	const transferParts = (comment) => {
		let cartToTransfer = [];
		const cartList = cart.cartAllDetails.map(obj => ({...obj}));
		const uniqueParts = cartList.reduce((cart, curr) => {
			const index = cart.findIndex(item => item.partNum === curr.partNum)
			index > -1 ? cart[index].qty += curr.qty : cart.push(curr)
			return cart
		}, []);
		uniqueParts.map((part) => {
			const partItem1 = catalogSdk.createPartItem2(part.partNum, part.mfgCode, "manufacturerName", part.desc, part.listPrice, part.costPrice, part.corePrice, part.qty, "", "", catalogSdk.PartCategory.UNSPECIFIED, "supplierName", "{ \"SupplierName\" : \"\"}", "Next Day", 6);	
			cartToTransfer.push(partItem1);
		});
		catalogSdk.transfer(JSON.stringify(cartToTransfer, undefined, 4));
	}

	return (
		<Col lg="3" className="purchase_order_container">
			<Form>
				<div className="mb-2">
					<Button
						className="mt-0 maroonBtn xl_parts_btn"
						size="sm"
						block
						onClick={() => {
							history.push('/')
						}}
					>
						Shop More Products
					</Button>
				</div>
				<Form.Group className="purchase_order_input_container">
					<Form.Label className="maroonColorTxt bold">
						Purchase Order
					</Form.Label>
					<Form.Control
						maxLength="150"
						className="input_upperCase"
						type="text"
						onChange={handleOrderChange}
						onKeyDown={(event) => {
							handleKeyDown(event, 'purchaseOrder');
						}}
					/>
					{/* <FormValidationErrorMessage
						condition={
							!validity.isPurchaseOrdAlphaSpclChar &&
							validity.isPurchaseOrdMaxLength
						}
						errorMessage={
							'Purchase order should include alphanumeric or special characters'
						}
					/> */}
					<FormValidationErrorMessage
						condition={!validity.isPurchaseOrdMaxLength}
						errorMessage={
							'Purchase order should not exceed 150 characters'
						}
					/>
					<FormValidationErrorMessage
						condition={!validity.isPurchaseOrdPatternValid}
						errorMessage={
							'The PO Number you entered does not match the PO pattern you have designated with The Parts House. For security reasons, we can not allow the order to be placed nor can we tell you the correct PO pattern. Please call your TPH store or The Parts House Accounts Receivable for assistance.'
						}
					/>
					<FormValidationErrorMessage
						condition={!validity.isPOPatternConfigFound}
						errorMessage={
							'PO Validation configuration is missing. Please contact support.'
						}
					/>
				</Form.Group>
				<div className="notification_container">
					{/* <Form.Check
						// custom
                        inline
                        type='checkbox'
                        checked={mailNotifications}
						label="Send me an email/text notification for this order"
					/> */}
					<input
						type="checkbox"
						id="mailNotifications"
						name="mailNotifications"
						checked={mailNotifications}
						onClick={() => {
							setmailNotifications(!mailNotifications);
						}}
					/>
					<label for="mailNotifications">
						Send me an email/text notification for this order
					</label>
					<img
						src={settingIcon}
						alt="Settings"
						onClick={() => handleSettings()}
					/>
				</div>

				<div className="notification_container">
					<input
						type="checkbox"
						id="shipAllTogether"
						name="shipAllTogether"
						checked={accPartStatus}
						onClick={() => {
							setaccPartStatus(!accPartStatus);
						}}
					/>
					<label for="shipAllTogether">
						Ship All Together
					</label>
				</div>

				<div className="mb-2">
					{userDetails &&
						userDetails.billTo.poRequired === 'Y' &&
						!poNum ? (
						<OverlayTrigger
							placement="bottom"
							delay={{ show: 50, hide: 400 }}
							overlay={
								<Tooltip id="button-tooltip">
									Please enter a purchase order number.
								</Tooltip>
							}
						>
							<Button
								className="maroonBtn xl_parts_btn display-prod-btn disabled"
								size="sm"
								block
							>
								{placeOrderBtnStr}
							</Button>
						</OverlayTrigger>
					) : (
						<Button
							className="maroonBtn xl_parts_btn"
							size="sm"
							block
							onClick={() => {
								if (userType === "SMS_MITCHELL1") {
									clearUserCart(true);
									transferParts(comment);
								} else {
									placeOrder(mailNotifications, accPartStatus, comment);
								}
							}}
							disabled={(!validCart || loading) || (enablePlaceOrderForSW) || !_every(validity)}
						>
							{placeOrderBtnStr}
						</Button>
					)}
				</div>
				<Form.Control
					as="textarea"
					rows="10"
					placeholder="Order Comment"
					onChange={handleOrderCmntChange}
					maxLength="500"
					onKeyDown={(event) => {
						handleKeyDown(event, 'orderComment');
					}}
				/>
				<p className="orderComment_text">{commentTextLimit()}</p>
				<FormValidationErrorMessage
					condition={!validity.isOrdCmntAlphaIntSpcl}
					errorMessage={
						'Order comment should include only alpha, special characters or integers'
					}
				/>
				<FormValidationErrorMessage
					condition={
						!validity.isOrdCmntLessThanLimit &&
						validity.isOrdCmntAlphaIntSpcl
					}
					errorMessage={
						'Order comment should not exceed 500 characters'
					}
				/>
			</Form>
			<div>
				<div className="pricing_details_table">
					{/* <div>
						<span>List Price:&nbsp;</span>
						<span>$66.84</span>
					</div> */}
					{!hideAllPrice && (<div>
						<span>Total List Price:&nbsp;</span>
						<span className="maroonColorTxt bold">
							$
							{formatAmount(totalBillingInfo.totalPartsListPrice)}
						</span>
					</div>)}
					{!hideCostPrice && !hideAllPrice ?
						<div className="total_price">
							<span>Savings:&nbsp;</span>
							<span className="maroonColorTxt bold ml-3">
								${formatAmount(totalBillingInfo.totalPartsListPrice - totalBillingInfo.totalPartsCostPrice)}
							</span>
						</div>
						: ''}
					{!hideCostPrice && !hideAllPrice ? (
						<div className="total_price">
							{/* <div>
								<span>Your Cost:&nbsp;</span>
								<span>$33.42</span>
							</div> */}

                            <span>Total Extended Price:&nbsp;</span>
							<span className="maroonColorTxt bold">
								$
								{formatAmount(
								totalBillingInfo.totalPartsCostPrice
							)}       
							</span>
						</div>
					) : null}
				</div>
			</div>
			<p className="disclaimer_text">
				***All Bundle Discounts will be Reflected on finalized
				invoice***
			</p>
			<div className="mb-1">
				<Button
					disabled={!(cart.qtyInCart > 0)}
					className="blkBtn xl_parts_btn"
					onClick={() => {
						clearUserCart();
					}}
				>
					Clear cart
				</Button>
			</div>
			<div className="mb-1">
				<Button
					disabled={!(cart.qtyInCart > 0)}
					className="maroonBtn xl_parts_btn"
					onClick={() => saveAsQuote()}
				>
					Save as quote
				</Button>
			</div>
			<div className="mb-1">
				<Button

					className="maroonBtn xl_parts_btn mb-5"
					onClick={() => saveAsJob()}
					disabled={jobSaved || !(cart.qtyInCart > 0)}
				>
					Save as Job
				</Button>
			</div>
		</Col>
	);
}
export default CartPricingDetails;
