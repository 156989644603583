import React from 'react';
import {
	getImageURLCookieDetails
} from '../../../helpers/utils';

function CarouselChildElement(props) {
    let {
        bundle,
        handleClick,
        selectedBundle,
        index,
    } = props;
    const imageURLCookie = getImageURLCookieDetails();
    const jobImage = (bundle.jobImage)?`${bundle.jobImage}?${imageURLCookie}`: '/noImage.jpg';
    let className = (selectedBundle.id === bundle.id)? "carousel-element__img item-selected" : "carousel-element__img" ;
    return(
        <div className="carousel-element" name={bundle.id} onClick={((e) => handleClick(e, {id:bundle.id, name:bundle.name}))} key={index}>
            <div className={className}>
                <img src={jobImage} alt="ProPack Bundle"></img>
            </div>
            <div className="carousel-element__caption">
                {bundle.jobName}
            </div>
        </div>
        );
}
export default CarouselChildElement;
