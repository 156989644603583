import React,{useState,useEffect} from 'react'
import { currencyFormatter } from '../../../helpers/utils';
import BootstrapTable from 'react-bootstrap-table-next'
import { useSelector } from 'react-redux';
import _isEmpty from "lodash/isEmpty"
import "./styles.scss"

const BatteryBonanza=()=>{
   const [tableData,setTableData]=useState([])
   const headerStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
   };
   const data=useSelector((state)=>state.ProgramAndBenefits)
   const benefitData=data && data.benefitData
   const batteryBonanzaData=(benefitData && benefitData.batteryBonanzas) || []
   useEffect(()=>{
    if (!_isEmpty(batteryBonanzaData)) {
        const batteryBonanzaRowData = []
        batteryBonanzaData.map((item, index) => {
            batteryBonanzaRowData.push({
                part_Bbonanza: item.partNumber,
                date_Bbonanza: item.invoiceDate,
                value_Bbonanza: item.value,
                cm_Bbonanza: item.cm,
                invUrl_Bbonanza: item.invUrl,
                index: index
            });
        });
        setTableData(batteryBonanzaRowData)
    }
  },[batteryBonanzaData])
  const batteryBonanzaColumn=[
	{
		dataField: 'part_Bbonanza',
		text: 'Part#',
		headerStyle: headerStyle,
		align: 'center',
		headerAlign: 'center',
	},
	{
		dataField: 'date_Bbonanza',
		text: 'Date',
		headerStyle: headerStyle,
		classes:"buy9pay8_and_bonaza_date_row_width",
		align: 'center',
		headerAlign: 'center',
		formatter:(cell, row, index)=>{
			let unformattedDate=row.date_Bbonanza.split("-")
			return unformattedDate[1] + '/' + unformattedDate[2] + '/' + unformattedDate[0];
		 }
	},
	{
		dataField: 'value_Bbonanza',
		text: 'Value',
		headerStyle: headerStyle,
		classes:"buy9pay8_and_bonanza_rightalign buy9pay8_and_bonaza_value_row_width",
		align: 'center',
		headerAlign: 'center',
		formatter: (cell) => {
			return `${currencyFormatter(cell)}`
		  },
	},
	{
		dataField: 'cm_Bbonanza',
		text: 'Credit Memo',
		headerStyle: headerStyle,
		align: 'center',
		headerAlign: 'center',
		formatter:(cell, row, index)=>{
			return <a href={row.invUrl_Bbonanza} style={{textDecoration:"underline"}} target='_blank'>{row.cm_Bbonanza}</a>
		 }
	},
  ]
  const noDataState = () => {
    return (
      <p align="center" style={{ padding: "0", margin: "0" }}>
        No Data Found
      </p>
    );
  };
  return(
    <>
        <BootstrapTable
            keyField="part_Bbonanza"
            classes="buy9pay8-and-bonanza-table-header"
            data={tableData}
            columns={batteryBonanzaColumn}
            noDataIndication={noDataState}
        />
    </>
  )
}

export default BatteryBonanza