/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { benefitDetails } from "./../programbenefits.thunk";
import _, { isEmpty } from "lodash";
import { data } from "./data";
import { formatAmountPrograms, formatMonth } from "../../../helpers/utils";
import { Row, Col } from "react-bootstrap";
import XCharts from "components/Charts";
import ItemsRender from "pages/c_and_k/SingleView/items";
import _isEmpty from "lodash/isEmpty";
import { USERDEF } from "../../../helpers/utils";
import { currencyFormatter } from "../../../helpers/utils";
const actionTypes = { GLOBAL_LOADER: "GLOBAL_LOADER" };

const useStyles = makeStyles({
  table: {
    textAlign: "center",
    borderCollapse: "collapse",
    display: "flex",
    flexDirection: "row",
  },
  head1: {
    borderBottom: "3px solid #dee2e6",
    padding: "10px",
    color: "white",
    background: "#0f5283",
  },
  head2: {
    padding: "10px",
    background: "#0f5283",
    color: "white",
    borderBottom: "3px solid #dee2e6",
  },
  // border: {
  //   border: "1px solid black",
  // },
});

function AcdGmPartnerPerks(props) {
  const { userDef } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;
  const [rebateData, setRebateData] = useState([]);
  const [loading] = useState(false);
  const acdPrograms = useSelector((state) => state.ProgramAndBenefits);

  const programList = acdPrograms.programList;
  const [userDefines, setUserDefines] = useState(null);
  const gmPartnerPerks = acdPrograms.gmPartnerPerks;
  const {
    acdGmPartner,
    acdGmPartnerYearlySales,
    programType,
    programReEnrollment,
    rewardsEndDate,
  } = gmPartnerPerks ?? [];

  useEffect(() => {
    if (programList && programList.length > 0) {
      const _programList = _.head(programList);
      setUserDefines(_programList);
    }
  }, [programList]);

  useEffect(() => {
    if (!isEmpty(acdGmPartner)) {
      setRebateData(acdGmPartner);
    }
    dispatch({
      payload: {
        globalLoader: loading,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
  }, [acdGmPartner, loading]);

  const getAllDetails = () => {
    const { billTo } = userDetails || {};
    let customerNumber = billTo.billToId;
    const payload = {
      name: "acdGmPartner",
      customerId: customerNumber, //'103735',
      noOfMonths: 11,
    };
    dispatch({
      payload: {
        globalLoader: true,
      },
      type: actionTypes.GLOBAL_LOADER,
    });
    dispatch(benefitDetails(payload))
      .then((action) => {
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      })
      .catch((error) => {
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
      });
  };

  useEffect(() => {
    getAllDetails();
  }, []);

  const dollarRoundOff = (dollar) => {
    //converts dollar to number and rounds it off
    return currencyFormatter(Math.round(Number(dollar))).split(".")[0];
  };

  const getDataKeys = () => {
    const years = _.uniq(_.map(acdGmPartnerYearlySales, (a) => a.year));

    const colors = ["#36AD3F", "#D71958", "#1604d9"];
    const keys = years.map((y, i) => {
      return { value: y, color: colors[i] };
    });

    return keys;
  };

  return (
    <div className="acg-gm-partner-perks">
      <div className="thresholdpoints acd-info-details">
        <span className="thresholdname">Rewards and Incentives</span>
        <div class="tooltip-custom">
          <a className="info-icon">
            <i class="fa fa-info-circle" />
          </a>
          <div
            class="tooltiptext acd-gm-tooltip"
            style={{ background: "grey" }}
          >
            <div className={classes.table} style={{ fontSize: "12px" }}>
              <div className="table-container">
                <div style={{ padding: "15px" }} />
                <table className={classes.border}>
                  <tr className="row-style2">
                    <th className={classes.head1}>Benefit Package</th>
                    <th className={classes.head2}>PROGRAM PILLARS</th>
                    <th>
                      <th
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          background: "#fff",
                        }}
                      >
                        <th
                          className="alignWithBorder"
                          style={{ width: "25%" }}
                        >
                          Rewards & Incentives
                        </th>

                        <th className="row-style" style={{ width: "75%" }}>
                          <th
                            className="header-unbold"
                            style={{
                              borderBottom: "3px solid #dee2e6",
                              padding: "0px",
                              background: "#fff",
                            }}
                          >
                            Annual Fee
                          </th>
                          <th
                            className="header-unbold"
                            style={{ padding: "12px", background: "#fff" }}
                          >
                            Average Monthly Purchases Required During Program
                            Period
                          </th>
                          <th
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              borderTop: "1px solid #dee2e6",
                            }}
                          >
                            <th
                              style={{ width: "100%", background: "#fff" }}
                              className="rewards header-unbold"
                            >
                              Rewards
                            </th>
                            <th
                              className="borderBottom"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <th style={{ borderBottom: "3px solid #dee2e6" }}>
                                <th
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <th className="repairs header-unbold">
                                    {" "}
                                    Maint.& Repair
                                  </th>
                                  <th
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <th className="borderWithPadding header-unbold">
                                      {`<$3k`}
                                    </th>
                                    <th className="borderWithPadding header-unbold">
                                      $3k-$4k
                                    </th>
                                    <th className="borderWithPadding header-unbold">
                                      $4k-$5k
                                    </th>
                                    <th
                                      className="header-unbold header-unbold"
                                      style={{ padding: "2px" }}
                                    >
                                      {`>$5k`}
                                    </th>
                                  </th>
                                </th>
                              </th>
                              <th className="borderWithPadding header-unbold">
                                Collision
                              </th>
                              <th
                                className="borderWithPadding header-unbold"
                                style={{ padding: "2px" }}
                              >
                                PowerTrain/ Propultion
                              </th>
                              <th
                                className="header-unbold"
                                style={{ padding: "2px" }}
                              >
                                Growth Bonus
                                <sup style={{ fontWeight: "bold" }}>*</sup>
                              </th>
                            </th>
                          </th>
                          <th
                            className="header-unbold"
                            style={{
                              borderBottom: "3px solid #dee2e6",
                              padding: "2px",
                              background: "#fff",
                            }}
                          >
                            Trade Offers With Streamlined Redemption Process
                          </th>
                          <th
                            className="header-unbold"
                            style={{ padding: "10px", background: "#fff" }}
                          >
                            Access to consumer offers
                          </th>
                        </th>
                      </th>
                      <tr />
                    </th>
                  </tr>
                </table>
              </div>
              <div>
                <div className="singleShopHeader">Single Shop</div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {data.map((item, i) => (
                    <div key={i} className="singleShop">
                      <div
                        style={{
                          background: "#0f5283",
                          color: "white",
                          padding: "10px",
                        }}
                      >
                        {item.package}
                      </div>
                      <div
                        className="pillar"
                        {...(item.pillar === "" && {
                          style: { padding: "20px" },
                        })}
                      >
                        {item.pillar}
                      </div>
                      <div className="borderBottom" style={{ padding: "12px" }}>
                        {item.annualFee}
                      </div>
                      <div className="padding-extra">
                        {item.monthlyPurchase}
                      </div>
                      <div className="borderBottom" style={{ padding: "15px" }}>
                        {item.firstReward}
                      </div>
                      <div className="borderBottom" style={{ padding: "10px" }}>
                        {item.secondReward}
                      </div>
                      <div className="borderBottom" style={{ padding: "10px" }}>
                        {item.thirdReward}
                      </div>
                      <div className="borderBottom" style={{ padding: "10px" }}>
                        {item.fourthReward}
                      </div>
                      <div className="borderBottom" style={{ padding: "10px" }}>
                        {item.collision}
                      </div>
                      <div className="powertrain-padding">
                        <span style={{ whiteSpace: "nowrap" }}>
                          {item.powertrain.split("-")[0]}
                        </span>
                        <br />
                        <span style={{ whiteSpace: "nowrap" }}>
                          {item.powertrain.split("-")[1]}
                        </span>
                      </div>
                      <div className="borderBottom" style={{ padding: "11px" }}>
                        {item.growthBonus}
                      </div>
                      <div className="tradeOffer" style={{ padding: "13px" }}>
                        {item.tradeOffer}
                      </div>
                      <div style={{ padding: "11px", background: "#fff" }}>
                        {item.customerOffer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="multiShopHeader">Multi-Shop</div>
                <div className="multiShopData">
                  <div className="package">PRO PERKS++</div>
                  <div className="pillar">
                    REGIONAL SERVICE <br /> CENTER
                  </div>
                  <div className="borderBottom" style={{ padding: "12px" }}>
                    $0
                  </div>
                  <div className="reginalMonthlySales">
                    $2,000 Avg-
                    <br />
                    per Location
                  </div>
                  <div className="borderBottom" style={{ padding: "15px" }}>
                    3%<sup style={{ fontWeight: "bold" }}>**</sup>
                  </div>
                  <div className="borderBottom" style={{ padding: "10px" }}>
                    3%<sup style={{ fontWeight: "bold" }}>**</sup>
                  </div>
                  <div className="borderBottom" style={{ padding: "10px" }}>
                    3%<sup style={{ fontWeight: "bold" }}>**</sup>
                  </div>
                  <div className="borderBottom" style={{ padding: "10px" }}>
                    3%<sup style={{ fontWeight: "bold" }}>**</sup>
                  </div>
                  <div className="borderBottom" style={{ padding: "10px" }}>
                    0.5%<sup style={{ fontWeight: "bold" }}>**</sup>
                  </div>
                  <div className="borderBottom" style={{ padding: "12px" }}>
                    <span style={{ whiteSpace: "nowrap" }}>
                      30 points/Assembly
                      <sup style={{ fontWeight: "bold" }}>**</sup>
                    </span>
                    <br />
                    <span style={{ whiteSpace: "nowrap" }}>
                      15 Points/TransferCase
                    </span>
                  </div>
                  <div className="borderBottom" style={{ padding: "11px" }}>
                    Y
                  </div>
                  <div className="tradeOffer" style={{ padding: "30px" }}>
                    Y
                  </div>
                  <div style={{ padding: "11px", background: "#fff" }}>Y</div>
                </div>
              </div>
            </div>
            <div>
              <p
                align="left"
                style={{
                  fontSize: "12px",
                  padding: "0",
                  margin: "0",
                  color: "#fff",
                }}
              >
                <sup style={{ fontWeight: "bold" }}>*</sup>20% additional
                rewards for 10% year-over-year growth in purchasing
              </p>
            </div>
            <div>
              <p
                align="left"
                style={{
                  fontSize: "12px",
                  padding: "0",
                  margin: "0",
                  color: "#fff",
                }}
              >
                <sup style={{ fontWeight: "bold" }}>**</sup>Reward in the form
                of a rebate
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="acd-info-details">
        {!_isEmpty(acdPrograms.benefitData) && (
          <div className="grid-header">
            <div className="card-wrapper" style={{ height: "fit-content" }}>
              <div className="acd-info-cards">
                <div className="report-card">
                  <div className="rcard-title">
                    Customer Account # with ACDelco
                  </div>
                  <div className="rcard-content">
                    <div className="cd-currency">
                      {userDefines &&
                        userDefines[USERDEF?.ACD_GM_PARTNER_PERKS]}
                    </div>
                  </div>
                </div>
                <div className="report-card">
                  <div className="rcard-title">Program Type</div>
                  <div className="rcard-content">
                    <div className="cd-currency">
                      {!_isEmpty(programType) ? programType : null}
                    </div>
                  </div>
                </div>
                <div className="report-card">
                  <div className="rcard-title">Program Re-Enrollment</div>
                  <div className="rcard-content">
                    <div className="cd-currency">
                      {!_isEmpty(programReEnrollment) ? (
                        <a
                          href="https://www.mygmpartnerperks.com/#!/login"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="report-card">
                  <div className="rcard-title">Rewards End Date</div>
                  <div className="rcard-content">
                    <div className="cd-currency">
                      {!_isEmpty(rewardsEndDate) ? rewardsEndDate : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="acd-line-report">
                <div className="report-card">
                  <div className="rcard-title">
                    Total Sales for last 3 years
                  </div>
                  <div className="rcard-content">
                    {!_isEmpty(acdPrograms.acdGmPartnerPerksGraphData) ? (
                      <XCharts
                        chartType="line"
                        data={acdPrograms.acdGmPartnerPerksGraphData}
                        dataKeys={getDataKeys()}
                      />
                    ) : (
                      <div className="cd-currency">-</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="xchart-wrapper">
              <div className="report-card">
                <div className="rcard-title">YTD Sales by Category</div>
                <div className="rcard-content">
                  {!_isEmpty(acdPrograms.acdCategorySalesGraphData) ? (
                    <XCharts
                      chartType="nivoBarChart"
                      data={acdPrograms.acdCategorySalesGraphData}
                      dataKeys={["sales"]}
                      indexBy="categories"
                      axisBottom="Categories"
                      nivoBarPadding={0.7}
                    />
                  ) : (
                    <div className="cd-currency">-</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <table className="table table-bordered" style={{ marginTop: "15px" }}>
          <thead>
            <tr>
              <th>Month</th>
              <th className="heading-right-acdm-table">ACD Sales This Year</th>
              <th className="heading-right-acdm-table">ACD Sales Last Year</th>
              <th className="heading-right-acdm-table">Growth</th>
            </tr>
          </thead>
          <tbody>
            {rebateData &&
              rebateData.length > 0 &&
              rebateData.map((data, i) => (
                <tr key={i}>
                  <td align="center" className="">
                    {formatMonth(data.monthYear).split("-")[0]}
                  </td>
                  <td align="right" className="maroonColorTxt">
                    {dollarRoundOff(data.sales)} (
                    {data && data?.monthYear?.split("-")[1]})
                  </td>
                  <td align="right" className="maroonColorTxt">
                    {dollarRoundOff(data.salesLYMonth)} (
                    {Number(data && data?.monthYear?.split("-")[1]) - 1})
                  </td>
                  <td
                    align="right"
                    className={
                      Number(data.growth) > 0
                        ? "td-background-light"
                        : "td-background-lightred"
                    }
                  >
                    {formatAmountPrograms(
                      data.growth === "Infinity" || null ? 0 : data.growth
                    )}
                    %
                  </td>
                </tr>
              ))}
            {
              !_isEmpty(acdPrograms.benefitData) && (
                // acdPrograms.benefitData.map((data, i) => (
                <tr>
                  <td
                    align="left"
                    className="font-weight-bold td-background-totalrow"
                  >
                    Total
                  </td>
                  <td
                    align="right"
                    className="maroonColorTxt bolder td-background-totalrow"
                  >
                    {dollarRoundOff(acdPrograms.benefitData.totalSales)}
                  </td>
                  <td
                    align="right"
                    className="maroonColorTxt bolder td-background-totalrow"
                  >
                    {dollarRoundOff(acdPrograms.benefitData.totalLYSales)}
                  </td>
                  <td className="td-background-totalrow" />
                </tr>
              )
              // ))}
            }
            {rebateData.acdGmPartner && rebateData.acdGmPartner.length === 0 && (
              <tr>
                <td align="center" colSpan="6">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AcdGmPartnerPerks;
