import React, {useEffect, useRef, useState } from 'react';
import {
    getMetabaseEmbedURL,
    isMetabaseTokenRefreshRequired
  } from "../../../../../helpers/utils";
import storage from "../../../../../helpers/storage";
import "../../../../../helpers/reportStyles.css";
import {useLocation } from "react-router-dom";
import { fetchMetabaseInfo } from "../../mygarage.thunks";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
const actionTypes = { GLOBAL_LOADER: 'GLOBAL_LOADER' };

const Report = (props) => {
    const location = useLocation();
    const userDetails = useSelector((state) => state.user.userDetails);
    //const logo = userDetails && userDetails?.billTo?.mclSupplier === "CD" ? cdXlpCombinedLogo : XLPLogo;
    const dispatch = useDispatch();
    const locationURL = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    const reportData = JSON.parse(
      storage.get(locationURL, "local")
    );
    const [iframeurl, setIFrameUrl] = useState(null);
    const rpt = useRef(null);

    useEffect(() => {
      dispatch({
				payload: {
					globalLoader: true
				},
				type: actionTypes.GLOBAL_LOADER
			});
        fetchMetabaseData();
      }, [location]);

    const fetchMetaData = () => {
        try {
            const metaBaseData = dispatch(fetchMetabaseInfo());
            metaBaseData.then((action) => {
                if (action.payload) {
                    isMetabaseTokenRefreshRequired(action.payload)
                    let iframeurl = getMetabaseEmbedURL(locationURL);
                    setIFrameUrl(iframeurl);
                }
            });
        } catch (error) { 
          dispatch({
            payload: {
              globalLoader: false
            },
            type: actionTypes.GLOBAL_LOADER
          });
        }
    }  

    const fetchMetabaseData = () => {
        const metabaseInfo = storage.get("metabaseInfo", "local");
        if (metabaseInfo === null) {
            fetchMetaData();
        } else if (metabaseInfo != null) {
            let metabaseTokenInfo = JSON.parse(storage.get('metabaseInfo', "local"));
            if ((metabaseTokenInfo.TokenexpTime && metabaseTokenInfo.TokenexpTime <= Math.round(Date.now() / 1000)) || (metabaseTokenInfo.secretKey === "")) {
                fetchMetaData();
            } else {
                let iframeurl = getMetabaseEmbedURL(locationURL);               
                  setIFrameUrl(iframeurl);               
            }
        }
        else {
            let iframeurl = getMetabaseEmbedURL(locationURL);
            setIFrameUrl(iframeurl);
        }
    };

    const onReportLoad = () => {
      dispatch({
				payload: {
					globalLoader: false
				},
				type: actionTypes.GLOBAL_LOADER
			});
    }

    // const setReportsStyle = () => {
    //         let iframeDoc = rpt.current;
    //         let y = (iframeDoc.contentWindow || iframeDoc.contentDocument);
    //         if (y.document)y = y.document;
    //         const styleCss = window.document.createElement('style');
    //         styleCss.type = 'text/css';
    //         styleCss.innerHTML = ".TableInteractive-headerCellData { color : green !important }";
    //         iframeDoc.head.appendChild(styleCss);
    //     };

    return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="bread_crum_section">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <span>Reports </span><span>&gt;&nbsp;</span> 
              </li>
              <li>
                <span>{reportData?.configData?.reportName}</span>
              </li>
            </ul>
        </div>
        <div className="report-iframe_content">
          {iframeurl != null ? (
            <iframe
              id="myframe"
              src={iframeurl}
              frameborder="0"
              width="100%"
              height="600"
              ref={rpt}
              onLoad={onReportLoad}
            ></iframe>
          ) : null}
        </div>
      </div>
     </React.Fragment>
    );
}

export default Report;
