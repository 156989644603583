
import React, { useEffect, useState } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Locations from "../../Locations";
import {OverlayTrigger,Tooltip,Dropdown, Modal,DropdownButton} from 'react-bootstrap';
import XModal from "../XModal";
import {getUserShipInfo} from '../../../helpers/utils';
import { useSelector } from "react-redux";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, MenuItem} from "@mui/material";
import Select from '@mui/material/Select';
import "./styles.scss";

function DropShipHeader({ dropShipInputChange, dropShipStoreChange }) {
  const storeDetails = useSelector((state) => state.mygarage.storeDetails);
  const user = useSelector((state) => state.user);
  const userDetails = useSelector((state) => state.user.userDetails);
  const customerShipMethod = user.userDetails.shipTos && user.userDetails.shipTos.length > 0 && user.userDetails.shipTos[0].shipMethod && user.userDetails.shipTos[0].shipMethod ? user.userDetails.shipTos[0].shipMethod : ""
  const checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  let enableDelivery = (checkoutDetails && checkoutDetails.length > 0) ? ((checkoutDetails[0].shipMethod == "FIWS") ? false : true) : (userDetails && userDetails.shipTos && userDetails.shipTos.length > 0 && userDetails.shipTos[0].shipMethod && userDetails.shipTos[0].shipMethod && (userDetails.shipTos[0].shipMethod === "FIWS") ? false : true);
  const [selectedView, setSelectedView] = useState(enableDelivery);
  const [pickupLocation, setPickupLocation] = useState(null);
  const [showRegionLocationsPopup, setShowRegionLocationsPopup] = useState(false);
  const [selectedStoreName, setSelectedStoreName] = useState(null);
  const [showLocationsPopup, setShowLocationsPopup] = useState(false);
  const [storeSelection, setStoreSelection] = useState('');
  const [store, setStore] = useState(userDetails && userDetails.shipTos && userDetails.shipTos[0] &&
  userDetails.shipTos[0].location && userDetails.shipTos[0].location.storeNumber ? userDetails.shipTos[0].location.storeNumber : "");

  let filteredStore = [];
  if ((!_isEmpty(storeDetails))) {
    filteredStore = selectedView ? [] : _cloneDeep(storeDetails);
  }
  useEffect(() => {
    if (pickupLocation) {
      !_isEmpty(storeDetails) && storeDetails.map(value => {
        if (value.storeNum === pickupLocation.toString()) {
          setSelectedStoreName(value.storeName);
          setStoreSelection(value.sellerPartID)
        }
      })
    }
  }, [])
  useEffect(() => {
    if (checkoutDetails && checkoutDetails.length > 0) {
      setPickupLocation(checkoutDetails[0]?.store);
    } else if (!_isEmpty(userDetails)) {
      setPickupLocation(getUserShipInfo(userDetails).storeNumber);
    }
  }, [userDetails, checkoutDetails])

  let defaultSelectedStoreName =
    userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeName
      ? userDetails.shipTos[0].location.storeName
      : "Select Store";
  let defaultSelectedSeller =
    userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.sellerPartnerId
      ? userDetails.shipTos[0].location.sellerPartnerId
      : "Select Store";

  useEffect(() => {
    if (!_isEmpty(storeDetails) && !_isEmpty(userDetails)) {
      let storeNum = checkoutDetails.length > 0 ? checkoutDetails[0].store : store;
      storeDetails.map((v) => {
        if (v.storeNum == storeNum) {
          setSelectedStoreName(v.storeName)
          setStoreSelection(v.sellerPartID)
        }
      });
    }
    if(!_isEmpty(checkoutDetails)) {
    let enableDelivery = (checkoutDetails && checkoutDetails.length > 0) ? ((checkoutDetails[0].shipMethod == "FIWS") ? false : true) : (userDetails && userDetails.shipTos && userDetails.shipTos.length > 0 && userDetails.shipTos[0].shipMethod && userDetails.shipTos[0].shipMethod && (userDetails.shipTos[0].shipMethod === "FIWS") ? false : true);
    let Seller = (checkoutDetails && checkoutDetails.length > 0 && checkoutDetails[0].sellPartnerId) ? checkoutDetails[0].sellPartnerId : (userDetails && userDetails.shipTos && userDetails.shipTos.length > 0 && userDetails.shipTos[0].sellerPartnerId  ? userDetails.shipTos[0].sellerPartnerId : "")
    setSelectedView(enableDelivery);
    setStoreSelection(Seller);
    }
  }, [checkoutDetails]);


  const handleByStore = (event, data, filteredStore) => {
    let {value, children} = data.props;
    let storeInfo = _find(filteredStore, (storeData) => ((storeData.sellerPartID === value) ));
    setStore(storeInfo.storeNum);
    setPickupLocation(storeInfo.storeNum);
    setSelectedStoreName(children);
    setStoreSelection(value)
  };

  const handleButtonChange = (e) => {
    setSelectedStoreName(defaultSelectedStoreName);
    setStoreSelection(defaultSelectedSeller);
  };


  return (
    <div className="ship-info-header store-filter store-margin maroonColorTxt ">
      {customerShipMethod && customerShipMethod == 'FIWS' && (
        <div className="radio-wrapper">
           <FormControl>
            <RadioGroup
              onChange={(e) => {
                setSelectedView(!selectedView);
                handleButtonChange(e)
                dropShipInputChange(e.currentTarget.defaultValue);
              }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              {/* <FormControlLabel
                value="delivery"
                checked={selectedView}
                control={<Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}/>}
                label={<span style={{ fontSize: '14px' }}>Delivery</span>}
              /> */}
              <FormControlLabel
                value="FIWS"
                checked={!selectedView}
                control={<Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22,
                  },
                }}/>}
                label={<span style={{ fontSize: '14px', color: '#9d1d20' }}>Drop Ship</span>}
              />
            </RadioGroup>
          </FormControl>
        </div>
      )}
      <div></div>

      {filteredStore && filteredStore.length > 1 && (
        <div className="">
           <div className='dropship-locations'>Locations:</div>
          <OverlayTrigger
            placement="right"
            delay={{ show: 100, hide: 400 }}
            overlay={
              <Tooltip id="pickup-location">
                You can {selectedView ? "deliver" : "drop ship"} your parts from a different store than your primary store. Click the drop down to select a different {enableDelivery ? "store" : "pick up location"}
              </Tooltip>
            }
          >
            <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
              <Select
                value={storeSelection}
                onChange={(e, data) => {
                  handleByStore(e, data, filteredStore);
                  dropShipStoreChange(data.props.value, filteredStore);
                }}
                sx={{fontSize:'14px',color: '#9d1d20'}}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {filteredStore.map((store, i) => {
                  return (
                    <MenuItem sx={{fontSize:'14px'}} value={store.sellerPartID} key={store.label}>
                      {store.storeName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </OverlayTrigger>
        </div>)}
      {(!selectedView && !_isEmpty(storeDetails) && storeDetails.length > 1 ) && (
        <>
        <OverlayTrigger
          placement="right"
          delay={{ show: 100, hide: 400 }}
          overlay={
            <Tooltip id="selected-location">
              {`Click this to locate the selected Store in Map`}
            </Tooltip>
          }
        >
          <LocationOnIcon className="cursor-pointer stockcheck-location" onClick={() => {
            setShowLocationsPopup(true);
          }} />
        </OverlayTrigger>
              <OverlayTrigger
              placement="right"
              delay={{ show: 100, hide: 400 }}
              overlay={
                <Tooltip id="selected-location">
                  {`Click this to locate all the stores in the region of selected store in Map`}
                </Tooltip>
              }
            >
              <LocationCityIcon className="cursor-pointer stockcheck-location" onClick={() => {
                setShowRegionLocationsPopup(true);
              }} />
            </OverlayTrigger>
            </>
        )}

      {(showLocationsPopup || showRegionLocationsPopup) && (
        <XModal
          show={showLocationsPopup || showRegionLocationsPopup}
          showButtons={false}
          title={`Store Location - Store # ${selectedStoreName}`}
          customHeader={
            <React.Fragment>
              <Modal.Title id="warning-modal-title" className="pull-left">
                Store Location - Store # {selectedStoreName}
              </Modal.Title>
              <div
                className="close-btn"
                onClick={() => { setShowLocationsPopup(false); setShowRegionLocationsPopup(false); }}
              >
                <span className="material-icons">close</span>
              </div>
            </React.Fragment>
          }
          handleClose={() => { setShowLocationsPopup(false); setShowRegionLocationsPopup(false); }}
        >
          <Locations allCityStores={showRegionLocationsPopup ? true : false}  selectedStore={pickupLocation.toString()} />
        </XModal>
      )}

    </div>
  );
}
export default DropShipHeader;
