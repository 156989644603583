import React, { useEffect, useState } from "react";
import { Image, Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import storage from "../../../helpers/storage";
import CardMedia from '@mui/material/CardMedia';
import { getImageURLCookieDetails } from "../../../helpers/utils";
import { getPartImageURLCookie } from "../../User/UserManagement/usermanagement.thunk";
import { fetchShipToIds } from "../../shared/ShipTo/shipTo.thunk";

const ReturnDetailsIicon = (props) => {
	let {
		ReturnData,
		setImageSlide,
		imgSlide,
		STORE_REVIEWED,
		DRIVER_DELIVERED,
		setShowImagesSlider,
		setShowInfoModal,
		setReturnDetailsId
	} = props;
	const dispatch = useDispatch();
	const[shipToName,SetShipToName]=useState("");

	useEffect(() => {
		dispatch(getPartImageURLCookie()).then((resp) => {
			storage.set("imageURLCookie", resp.payload || {}, "local");
		});
		let payload = {
			billtoid: ReturnData.customerId,
		  };

		dispatch(fetchShipToIds(payload)).then((action) => {
			let shipToData = action;
			!_isEmpty(shipToData.payload) && shipToData.payload.map((data) => {
				if(ReturnData.shipToId === data.shipToId)
				{
					SetShipToName(data.name)
				}
			  });
		 });
	}, [ReturnData])

	const handleImageClick = (image, images, sig) => {
		let img = [];
		!_isEmpty(images) && images.map((v) => {
			img.push({
				url: v,
				height: "1500",
				primary: false,
				width: "957"
			})
		})
		imgSlide['image'] = image;
		imgSlide['images'] = img;
		imgSlide['hidePrintSignature'] = sig;
		imgSlide['partNumber'] = Math.random();
		imgSlide['storeDashBoard'] = true;
		imgSlide['thumbnail'] = "";
		setImageSlide(imgSlide);
		setShowImagesSlider(true);
	}
	return (
    <div className="store_popUp store_line_item" key={ReturnData.id}>
      <div className="store_dashboard_part_list_header">
        <span> Return Details</span>
        <div
          className="close-btn"
          onClick={() => {
            setShowInfoModal(false);
            setReturnDetailsId("");
          }}
        >
          <span className="material-icons">close</span>
        </div>
      </div>
      <Container>
        <Row className="store_popup_shipTotext">
          <Col>
            <div className="store_popup_shipTotext">
              <span className="user_management_label_color">ShipTo :</span>{" "}
              <span className="">{ReturnData.shipToId}</span>
            </div>
          </Col>
          <Col>
            {shipToName && (
              <div className="store_popup_shipTotext">
                <span className="user_management_label_color">
                  ShipTo Name :
                </span>{" "}
                <span className="">{shipToName}</span>
              </div>
            )}
          </Col>
          <Col>
            {!_isEmpty(ReturnData.refNum) && (
              <div className="store_popup_shipTotext">
                <span className="user_management_label_color">REF # :</span>{" "}
                <span className="">{ReturnData.refNum}</span>
              </div>
            )}
          </Col>
        </Row>

        {!_isEmpty(ReturnData.driverDetails) &&
          ReturnData.driverDetails !== undefined &&
          (ReturnData.status === DRIVER_DELIVERED ||
            ReturnData.status === STORE_REVIEWED) && (
            <>
              <Row>
                <span className="store_popup_line_text">Driver Details</span>
              </Row>
              <Row style={{ display: "inline-flex" }}>
                <Col>
                  <div className="store_popup_text">
                    <span className="user_management_label_color">
                      First Name :
                    </span>{" "}
                    <span className="">
                      {ReturnData.driverDetails.firstName}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div className="store_popup_text">
                    <span className="user_management_label_color">
                      Last Name :
                    </span>{" "}
                    <span>{ReturnData.driverDetails.lastName}</span>
                  </div>
                </Col>
                <Col>
                  <div className="store_popup_text">
                    <span className="user_management_label_color">
                      Phone Number :
                    </span>{" "}
                    <span>{ReturnData.driverDetails.phoneNumber}</span>
                  </div>
                </Col>
                <Col>
                  <div className="store_popup_text">
                    <span className="user_management_label_color">
                      Picked Up Date :
                    </span>{" "}
                    <span>
                      {moment(ReturnData.pickupTime).format("MM/DD/YYYY")}
                    </span>
                  </div>
                </Col>
              </Row>
            </>
          )}
        <Row>
          {!_isEmpty(ReturnData.imageURLs) && (
            <span className="store_popup_line_text">Return Images </span>
          )}
        </Row>
        <Row>
          <Col>
            {!_isEmpty(ReturnData.imageURLs) &&
              ReturnData.imageURLs.map((image, i) => (
                <div className="track__link mr-2">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 400 }}
                    overlay={<Tooltip>Click on the image to view </Tooltip>}
                  >
                    <CardMedia
                      component="img"
                      onClick={() => {
                        handleImageClick(image, ReturnData.imageURLs, false);
                      }}
                      className={
                        image
                          ? "image-cursor cursor-pointer store_image-link"
                          : "cursor-pointer store_image-link"
                      }
                      src={`${image}?${getImageURLCookieDetails()}`}
                    />
                  </OverlayTrigger>
                </div>
              ))}
          </Col>
        </Row>
        {!_isEmpty(ReturnData.signatureURL) && (
          <>
            <Row>
              <span className="store_popup_line_text">Return Signature</span>
            </Row>
            <Row>
              <Col>
                <div className="track__link mr-2">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 400 }}
                    overlay={<Tooltip>Click on the image to view </Tooltip>}
                  >
                    <CardMedia
                      component="img"
                      onClick={() => {
                        handleImageClick(ReturnData.signatureURL, "", true);
                      }}
                      className={
                        ReturnData.signatureURL
                          ? "image-cursor cursor-pointer store_image-link"
                          : "cursor-pointer store_image-link"
                      }
                      src={`${
                        ReturnData.signatureURL
                      }?${getImageURLCookieDetails()}`}
                    />
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
          </>
        )}
        <br></br>
      </Container>
    </div>
  );
}
export default ReturnDetailsIicon;
