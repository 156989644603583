import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Typography } from "ui-library";

import "./styles.scss";

const RenderIIcon = (props) => {
  const {
    label = "not defined",
    hoverText = "not defined",
    iconColor = "xlp",
    className = "custom-root",
  } = props;

  return (
    <Typography variant="body1" className={`render-i-icon ${className}`}>
      {label}
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 400 }}
        overlay={
          <Tooltip id="extended-price-tooltip" className="tooltip-overlay">
            <span className="font-weight-bold">{hoverText}</span>
          </Tooltip>
        }
      >
        <div className={`info-icon ml-1 ${iconColor}`}>
          <i class="fa fa-info-circle"></i>
        </div>
      </OverlayTrigger>
    </Typography>
  );
};

export default RenderIIcon;
