import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Container, Modal, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import linkIcon from "../../../static/images/linkIcon.png";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import {
  getC2CUrl,
  getBuyersGuideData,
  getBuyersGuideBrandData,
  getLaborGuideData,
} from "../../../actions/parts";
import XModal from "../../shared/XModal";
import VectorGfxPopup from "../../Listing/VectorGfx/index";
import LaborGuide from "../../Listing/LaborGuide";
import CoverToCoverPopup from "../../Listing/CoverToCoverPopup";
import Interchange from "../../shared/Interchange";
import BuyingGuideComponent from "../../shared/BuyingGuide";
import LostSales from "../../ShoppingCart/LostSales";
import Warranty from "../../Warranty";

const Links = (props) => {

  const dispatch = useDispatch();
  const [showImagesSlider, setShowImagesSlider] = useState(false);
  const userDetails = useSelector((state) => state.user.userDetails);
  const userSecurityGroup = useSelector(
    (state) => state.user.userDetails.userSecurityGroup
  );
  const [showVectorGfx, setShowVectorGfx] = useState(false);
  const [showLaborGuide, setShowLaborGuide] = useState(false);
  const [laborGuideDetails, setLaborGuideDetails] = useState({});
  const [showBuyersGuide, setShowBuyersGuide] = useState(false);
  const [showInterchange, setShowInterchange] = useState(false);
  const [showCoverToCover, setShowCoverToCover] = useState(false);
  const [showWarrantyDetails, setShowWarrantyDetails] = useState(false);
  const [showLostSales, setShowLostSales] = useState(false);
  const selectedVehicle = props.nodeData.selectedVehicle;
  const selectedGroupDetails = props.nodeData.selectedGroupDetails;
  const x2userName = userDetails.x2userName;
  

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleLinksClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLinksClose = () => {
    setAnchorEl(null);
  };

  const togglePopup = (popupName) => {
    const partDetails = props.part;
    let laborpayload = {
      conditions: [],
    };
    handleLinksClose();
    switch (popupName) {
      case "buyersGuide":
        if (!showBuyersGuide) {
          getBuyersGuideData(
            partDetails.partNumber,
            dispatch,
            partDetails.mfgName
          );
          getBuyersGuideBrandData(partDetails.partNumber, dispatch);
        }
        getC2CUrl(partDetails.partNumber, partDetails.orderNumber, dispatch);
        setShowBuyersGuide(!showBuyersGuide);
        break;
      case "coverToCover":
        getC2CUrl(partDetails.partNumber, partDetails.orderNumber, dispatch);
        setShowCoverToCover(!showCoverToCover);
        break;
      case "laborGuide":
        if (!showLaborGuide) {
          let groupData = _find(selectedGroupDetails, {
            code: Number(partDetails.groupNumber),
          });

          if (
            groupData &&
            groupData.laborGroups &&
            groupData.laborGroups.length
          ) {
            let laborItems = [];
            let conditions = [];
            let laborGroupItemsRecd = 0;
            groupData.laborGroups.forEach((laborGroup, index) => {
              getLaborGuideData(
                selectedVehicle.engineId,
                groupData.laborGroups[index].textID,
                selectedVehicle.makeId,
                selectedVehicle.modelId,
                selectedVehicle.year,
                laborpayload.conditions,
                dispatch
              ).then((__response) => {
                if (__response && __response.data) {
                  laborGroupItemsRecd++;
                  laborItems.push(...__response.data.laborItems);
                  conditions.push(...__response.data.conditions);

                  console.log(__response.data);
                }
                if (__response && __response.data == null) {
                  laborGroupItemsRecd++;
                }
                if (laborGroupItemsRecd === groupData.laborGroups.length) {
                  setLaborGuideDetails({
                    laborItems: laborItems,
                    conditions: conditions,
                  });
                }
              });
            });
          }
        }

        setShowLaborGuide(!showLaborGuide);
        break;
      case "interchange":
        setShowInterchange(!showInterchange);
        break;
      case "vectorGfx":
        setShowVectorGfx(!showVectorGfx);
        break;
      case "imagesSlider":
        setShowImagesSlider(!showImagesSlider);
        break;
      case "warranty":
        setShowWarrantyDetails(!showWarrantyDetails);
        break;
      case "lostSales":
        setShowLostSales(!showLostSales);
        break;
      default:
        break;
    }
  };

  const setLaborGuideData = (laborGuideDetails) => {
    setLaborGuideDetails(laborGuideDetails);
  }

  return (
    <React.Fragment>
      {showVectorGfx ? (
        <VectorGfxPopup
          engine={selectedVehicle.engineId}
          group={props.part.groupNumber}
          descriptionID={props.part.descriptionID}
          make={selectedVehicle.makeId}
          model={selectedVehicle.modelId}
          showPopUp={showVectorGfx}
          togglePopup={() => togglePopup('vectorGfx')}
          year={selectedVehicle.year}
          selectedGroupDetails={selectedGroupDetails}
          selectedVehicle={selectedVehicle}
          shippingInfo={props.shippingInfo}
        // filterPopup={filterPopup}
        />
      ) : null}
      {showInterchange && (
        <XModal
          show={showInterchange}
          showButtons={false}
          title="Interchange"
          customHeader={
            <React.Fragment>
              <Modal.Title id="warning-modal-title" className="pull-left">
                Interchange
              </Modal.Title>
              <div
                className="close-btn"
                onClick={() => togglePopup("interchange")}
              >
                <span className="material-icons">close</span>
              </div>
            </React.Fragment>
          }
          handleClose={() => togglePopup("interchange")}
        >
          <Interchange
            partNumber={props.part.partNumber}
            partBrand={props.part.mfgName}
            partType={props.part.description}
            partLevel={true}
            catalog={true}
            dualBoxView={false}
          />
        </XModal>
      )}
      {showBuyersGuide ? (
        <XModal
          show={showBuyersGuide}
          showButtons={false}
          title="Buyers Guide"
          customHeader={
            <React.Fragment>
              <Modal.Title id="warning-modal-title" className="pull-left">
                Buyers Guide
              </Modal.Title>
              <div
                className="close-btn"
                onClick={() => togglePopup("buyersGuide")}
              >
                <span className="material-icons">close</span>
              </div>
            </React.Fragment>
          }
          handleClose={() => togglePopup("buyersGuide")}
        >
          <BuyingGuideComponent
            selectedPartNo={props.part.partNumber}
            selectedBrand={props.part.mfgName}
            partDetails={props.part}
            selectedPartType={props.part.extendedDescription}
            selectedGroup={selectedGroupDetails[0].name}
            c2cUrl={props.part.c2cUrl}
          />
        </XModal>
      ) : null}
      {showLaborGuide && laborGuideDetails.laborItems && (
        <LaborGuide
          partNumber={props.part.partNumber}
          openPopup={showLaborGuide}
          closePopup={() => togglePopup('laborGuide')}
          data={laborGuideDetails}
          apiCallStatus={""} // TODO
          selectedVehicle={selectedVehicle}
          setlaborGuideData={setLaborGuideData}
        />
      )}
      {showCoverToCover ? (
        <CoverToCoverPopup
          openPopup={showCoverToCover}
          url={props.part.c2cUrl}
          closePopup={() => togglePopup('coverToCover')}
        />
      ) : null}
      {showLostSales ? (
        <LostSales
          openPopup={showLostSales}
          closePopup={() => {
            setShowLostSales(false);
          }}
          selectedPartDetails={[
            {
              availabilityHour:
                props.part.pricingAvailability.availability &&
                  props.part.pricingAvailability.availability.length > 0
                  ? props.part.pricingAvailability.availability[0]
                    .availableDuration
                  : null,
              availableQty:
                props.part.pricingAvailability.availability &&
                  props.part.pricingAvailability.availability.length > 0
                  ? props.part.pricingAvailability.availability[0]
                    .availableQty
                  : 0,
              mfgCode: props.part.pricingAvailability.mfgCode,
              costPrice: props.part.pricingAvailability.costPrice,
              desc: props.part.description,
              partNum: props.part.partNumber,
              qty: 0,
              vehicleInfo: `${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`,
            },
          ]}
          dispatch={dispatch}
          userId={x2userName}
          source="catalogPage"
          selectedVehicle={selectedVehicle}
        />
      ) : null}
      {showWarrantyDetails && (
        <Warranty
          show={showWarrantyDetails}
          partNumber={props.part.partNumber}
          partData={props.part}
          apiCallStatus={""} // TODO
          partBrand={props.part.mfgName}
          openPopup={showWarrantyDetails}
          closePopup={() => togglePopup('warranty')}
        />
      )}
      <Row>
        <div class="links">
          <img 
            id="positioned-links-icon" 
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined} 
            src={linkIcon} 
            alt="Link" 
            onMouseEnter={handleLinksClick} 
            onClick={handleLinksClick} />
        </div>
        <Menu
          id="basic-menu"
          aria-labelledby="positioned-links-icon"
          anchorEl={anchorEl}
          open={open}
          onClose={handleLinksClose}
          disableScrollLock={true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            onMouseLeave: handleLinksClose
          }}
        >
          {(userSecurityGroup === null || userSecurityGroup.vectorIllustration) && (<MenuItem onClick={() => togglePopup('vectorGfx')}>Part Diagram</MenuItem>)}
          {(userSecurityGroup === null || userSecurityGroup.interchange) && (<MenuItem onClick={() => togglePopup('interchange')}>Interchange</MenuItem>)}
          {(userSecurityGroup === null || userSecurityGroup.buyersGuide) && (<MenuItem onClick={() => togglePopup('buyersGuide')}>Buyers Guide</MenuItem>)}
          {(userSecurityGroup === null || userSecurityGroup.laborGuide) && (<MenuItem onClick={() => togglePopup('laborGuide')}>Labor Guide</MenuItem>)}
          {(props.part.mediaTag && props.part.mediaTag === "Y") && (<MenuItem onClick={() => togglePopup('coverToCover')}>Cover to Cover</MenuItem>)}
          <MenuItem onClick={() => togglePopup('lostSales')}>Lost Sales</MenuItem>
          {(userSecurityGroup === null || userSecurityGroup.warranty) && (<MenuItem onClick={() => togglePopup('warranty')}>Warranty</MenuItem>)}
        </Menu>
      </Row>
    </React.Fragment>
  );
};

Links.propTypes = {};

export default Links;
