import React from 'react';
import _findIndex from 'lodash/findIndex';
import {
	getImageURLCookieDetails
} from '../../../helpers/utils';

function stringFormat(str) {
	return str.replace(/,/g, ', ').replace(/&/g, ' & ').replace(/ {2}/g, ' ')
}

function CarouselElement(props) {
	let {
		group,
		handleClick,
		selectedGroupDetails,
		category,
		index,
	} = props;
	let className;
	const imageURLCookie = getImageURLCookieDetails();
	let groupIndex = _findIndex(selectedGroupDetails, {
		id: Number(group.id),
	}); // for chemicals
	if (group.code) { // For homepage
		groupIndex = _findIndex(selectedGroupDetails, {
			code: Number(group.code),
			categoryId: Number(category.code)
		});
	}
	if (category && category.supplier && category.supplier.code) { // for chemicals brandView
		groupIndex = _findIndex(selectedGroupDetails, { id: Number(group.id), brandCode: category.supplier.code });
	}
	className =
		groupIndex !== -1
			? 'carousel-element__img item-selected'
			: 'carousel-element__img';
	const imgUrl = (group.imageUrl) ? `${group.imageUrl}?${imageURLCookie}` : '/noImage.jpg';
	return (
		<div
			className="carousel-element"
			name={group.code ? group.code : group.id}
			onClick={(e) => handleClick(e, group, category)}
			key={index}
		>
			<div className={className}>
				{/* <img src={group.imageUrl}></img> */}
				<img src={imgUrl} />
			</div>
			<div className="carousel-element__caption">{stringFormat(group.name)}</div>
		</div>
	);
}
export default CarouselElement;
