import React, { useState, useRef, Fragment, useEffect } from "react";
import { Col, OverlayTrigger, Tooltip, Button, Row } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import { useReactToPrint } from "react-to-print-nonce";
import Placeholder from "../../../components/shared/Placeholder";
import { useDispatch } from "react-redux";
import {
  timeInCST,
  timeInUTC,
  formatAmount,
  getExtendedTotalPrice,
  getDiscountTotalPrice,
  formatTimeAvb,
  printLogo,
  hidePrice,
  isStockOrder,
  getTotalPriceForCNK} from "../../../helpers/utils";
import { sendUtilServiceEmail } from "../../../helpers/email/email.thunks";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import deleteIcon from "../../../static/images/deleteIcon.png";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import shopProduct from "../../../static/images/shop_similar_products.png";
import { setVehicles } from "../../../components/shared/MyGarage/mygarage.thunks";
import {
  getPartsByMultiGroupOrchestrator,
  getChemPartByGrp,
  cartPricing
} from "../../../actions/parts";
import { setVehicleSelected } from "../../../components/shared/MyGarage/mygarage.slice";
import PricingIcons from '../../../components/Listing/PricingIcons';
import { setCandKPartsPrice } from "../c_and_k.slice";

const actionTypes = {
  GLOBAL_LOADER: "GLOBAL_LOADER"
};


function AddedPartsView(props) {
  const dispatch = useDispatch();
  console.log("isStockOrder check====>", isStockOrder());
  let {
    checkoutDetails,
    cartApiFetching,
    handleDeleteItem,
    hideCostPrice,
    userDetails
  } = props;

  const history = useHistory();
  let hideListPrice = hidePrice("Cart", "listPrice");
  let hideCostPricePref = hidePrice("Cart", "costPrice");
  const hideAllPrice = props.hidePrice;
  const [products, setProducts] = useState({});

  useEffect(() => {
    (async () => {
      if(!_isEmpty(checkoutDetails)) {
        await renderCheckout();
      }
    })();
  }, [checkoutDetails]);

  const getCartPricing = async (data) => {
    if (data.length > 0) {
      dispatch({
        payload: {
          globalLoader: true,
        },
        type: actionTypes.GLOBAL_LOADER,
      });
      let requestItems = data.map((p) => ({
        mfgCode: p.mfgCode,
        partNum: p.partNum,
        qty: p.qty,
        unitCost: p.costPrice,
      }));
      try {
        const res = await dispatch(cartPricing({ requestItems }));
        dispatch({
          payload: {
            globalLoader: false,
          },
          type: actionTypes.GLOBAL_LOADER,
        });
        !_isEmpty(res) && data.forEach((item1) => {
          const matchingItem = res.find(
            (item2) =>
              item2.partNum === item1.partNum &&
              item2.mfgCode === item1.mfgCode
          );
          if (matchingItem) {
            item1.costPrice = matchingItem.costPrice;
          }
        });
        updateCnKInstallPartsPrice(data)
        return data;
      } catch (error) {
        updateCnKInstallPartsPrice(data)
        return data;
      }
    }
  };

  const updateCnKInstallPartsPrice = (priceData) => {
    let candkPartPrice =  getTotalPriceForCNK(0, priceData)
    dispatch(setCandKPartsPrice(candkPartPrice));
  }

  
  const renderCheckout = async () => {
    let groupedCheckoutData = prepareCheckoutData();
    groupedCheckoutData = await getCartPricing(groupedCheckoutData);
    let prepareRows = [];
    let filtev = _.groupBy(groupedCheckoutData.map(item => ({
      ...item,
      normalizedVehicleInfo: item.vehicleInfo.trim().replace(/\s+/g, ' ').toUpperCase()
    })), 'normalizedVehicleInfo');
    Object.keys(filtev).forEach((k, index) => {
      let headerRow = {
        mfgCode: k,
        partNum: "",
        qty: "",
        desc: "",
        costPrice: "",
        corePrice: "",
        listPrice: "",
        extendedPrice: "",
        groupby: true,
        availabilityHour: k,
        part: filtev[k],
      };
      prepareRows.push(headerRow);
      prepareRows.push(...filtev[k]);
    });

    setProducts(prepareRows)
   
  };

  const renderTable = () => {
    return (
      <Fragment>
        {products && products.length === 0 ? (
          <span></span>
        ) : (
          <Fragment>
            {/* <span className="pickup-details">{`${pickupDetails} From Store ${defultDeliveryStore}`}</span> */}
            <br />
            <div className="checkout-table">
              <BootstrapTable
                keyField="id"
                data={products}
                columns={columns}
                rowClasses={rowClasses}
                rowStyle={rowStyle}
              // cellEdit={cellEditFactory({ mode: "click" })}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }

  const prepareCheckoutData = () => {
    let cartConsolidateArr = [];
    if (!_isEmpty(checkoutDetails)) {
      const cartList = checkoutDetails.map((obj) => ({ ...obj }));
      cartList.map((one) => {
        one.availability = (one.availabilityHour + "-" + one.qty);
        let matched = false;
        if (_isEmpty(cartConsolidateArr)) {
          cartConsolidateArr.push(one);
        } else {
          matched = cartConsolidateArr.findIndex((two, index) => {
            return (
              two.partNum === one.partNum &&
              two.mfgCode === one.mfgCode &&
              two.desc === one.desc &&
              two.vehicleInfo === one.vehicleInfo
            );
          });
          if (matched >= 0) {
            cartConsolidateArr[matched].qty =
              cartConsolidateArr[matched].qty + one.qty;
            cartConsolidateArr[matched].availability = cartConsolidateArr[matched].availability + "," + one.availability;
          } else {
            cartConsolidateArr.push(one);
          }
        }
      });
    }
    if (cartConsolidateArr && cartConsolidateArr.length > 0) {
      cartConsolidateArr.map((item) => {
        item.availability = !_isEmpty(item.availability)
          ? item.availability.split(",")
          : [item.availabilityHour + "-" + item.qty];
      });
    }
    return cartConsolidateArr;
  };

  const descriptionHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    aligin: "center",
    headerAlign: "center",
    "border-color": "#0f5283",
    width: "145px",
    "font-size": "14px",
  };

  const orderQtyHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    aligin: "center",
    headerAlign: "center",
    "border-color": "#0f5283",
    width: "130px",
    "font-size": "14px",
  };

  const partHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    headerAlign: "center",
    "border-color": "#0f5283",
    "font-size": "14px",
    width: "220px"
  };

  const availabilityHeaderStyle = {
    backgroundColor: "#0f5283",
    // aligin: "center",
    headerAlign: "center",
    color: "#FFFFFF",
    "border-color": "#0f5283",
    align: "center",
    width: "120px",
    "font-size": "14px",
  };

  const mfgHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    aligin: "center",
    headerAlign: "center",
    "border-color": "#0f5283",
    "font-size": "14px",
    width: "150px",
  };

  const extendedPriceHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    "border-color": "#0f5283",
    align: "center",
    width: "150px",
    headerAlign: "center",
    "font-size": "14px",
  };

  const yourPriceHeaderStyle = {
    backgroundColor: "#0f5283",
    align: "center",
    headerAlign: "center",
    color: "#FFFFFF",
    "border-color": "#0f5283",
    "font-size": "14px",
    width: "100px",
  };

  const packQtyHeaderStyle = {
    backgroundColor: "#0f5283",
    align: "center",
    headerAlign: "center",
    color: "#FFFFFF",
    "border-color": "#0f5283",
    "font-size": "14px",
    width: "60px",
  };

  const qtyPerVehicleHeaderStyle = {
    backgroundColor: "#0f5283",
    align: "center",
    headerAlign: "center",
    color: "#FFFFFF",
    "border-color": "#0f5283",
    "font-size": "14px",
    width: "140px",
  };

  const listPriceHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    align: "center",
    headerAlign: "center",
    "border-color": "#0f5283",
    "font-size": "14px",
    width: "100px",
  };
  const taxAmountHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    align: "center",
    headerAlign: "center",
    "border-color": "#0f5283",
    "font-size": "14px",
    width: "100px",
  };

  const printComponent = useRef();
  const handlePrint = useReactToPrint({
    content: () => printComponent.current,
  });

  const rowClasses = (row, rowIndex) => {
    let classes = null;

    if (row.groupby) {
      classes = "rowStyleCss";
    }
    return classes;
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (_isEmpty(row.partNum)) {
      style.backgroundColor = "#e3e3e3";
      style.border = "solid 1px #8c99a6";
    }

    return style;
  };

  const extendedPriceHeader = () => {
    return (
      <div>
        <span>Extended<br />Price</span>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 400 }}
          overlay={
            <Tooltip
              id="extended-price-tooltip"
              className="tooltip-description-custom-corePrice"
            >
              <span className="font-weight-bold">
                Extended Price = Qty * (Your Price + Core Price)
              </span>
            </Tooltip>
          }
        >
          <a className="whiteColorTxt info-icon ml-1">
            <i class="fa fa-info-circle"></i>
          </a>
        </OverlayTrigger>
      </div>
    );
  }
  const getDeleteIcon = (row) => {
    return (
      <OverlayTrigger
        placement="left"
        delay={{ show: 100, hide: 400 }}
        overlay={
          <Tooltip
            id="extended-price-tooltip"
            className="tooltip-description-custom-corePrice"
          >
            <span className="font-weight-bold">
              Remove this item from the cart.
            </span>
          </Tooltip>
        }
      >
        <img
          src={deleteIcon}
          className="checkout_delete-icon"
          onClick={(event) => {
            handleDeleteItem(event, row);
          }}
          alt="Delete"
        />
      </OverlayTrigger>)
  }

  // const discountPriceHeader = () => {
  //   return (
  //     <OverlayTrigger
  //       placement="bottom"
  //       delay={{ show: 100, hide: 400 }}
  //       overlay={
  //         <Tooltip
  //           id="extended-price-tooltip"
  //           className="tooltip-description-custom-corePrice"
  //         >
  //           <span className="font-weight-bold">
  //             Extended Price = Qty * (Discount Price + Core Price)
  //           </span>
  //         </Tooltip>
  //       }
  //     >
  //       <a className="maroonColorTxt info-icon ml-1">
  //         <i class="fa fa-info-circle"></i>
  //       </a>
  //     </OverlayTrigger>
  //   );
  // }


  const getShowSmilarProductIcon = (row) => {
    return (
      <OverlayTrigger
        placement="left"
        delay={{ show: 100, hide: 400 }}
        overlay={
          <Tooltip
            id="extended-price-tooltip"
            className="tooltip-description-custom-corePrice"
          >
            <span className="font-weight-bold">
              Show similar products
            </span>
          </Tooltip>
        }
      >
        <img
          src={shopProduct}
          className="shop-product-icon"
          onClick={(e) => {
            handleSKUClick(e, row);
          }}
          alt="Show"
        />
      </OverlayTrigger>)
  }
  const columns = [
    {
      dataField: "mfgCode",
      text: <span>Product<br />Line</span>,
      headerStyle: mfgHeaderStyle,
      headerAlign: "center",
      align: "center",
      classes: "bootstrap-table-bold-cell",
      formatter: function (cell, row) {
        console.log("row====>", row);
        return !_isEmpty(row.partNum) ? (
          <div className="table-view-margin checkout-part-width">
            <span>{row.mfgCode}</span>
            {row.toolTip && <div className="cart-icons ml-2">
						<PricingIcons toolTips={
							JSON.parse(row.toolTip)
							|| []
						} />
					</div>}
          </div>
        ) : (
          <div className="table-view-margin checkout-part-width">
            {" "}
            <div
              className={
                _isEmpty(row.partNum)
                  ? "checkout-availability-subdiv gry-back-ground header-font"
                  : "checkout-availability-subdiv avabilability-padding header-font"
              }
            >
              <ul><li className="text-black">{row.availabilityHour}
                <React.Fragment>
                  {row.availabilityHour !== "Non-Vehicle Specific" ? (
                    <React.Fragment>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id="button-tooltip">Vehicle Maintenance</Tooltip>
                        }
                      >
                        <span></span>
                        {/* <input
                          type="image"
                          className="vehicle-maint-icon table-margin"
                          onClick={() => handleVehicleMaintenan(1, row.part)}
                          src={vehicleMaintIcon}
                          alt="Vehicle Maintenance Icon"
                        /> */}
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id="button-tooltip">Vehicle Specs</Tooltip>
                        }
                      >
                        <span></span>
                        {/* <input
                          type="image"
                          className="fluids-filters-icon"
                          onClick={() => handleFluidSpec(1, row.part)}
                          src={fluidsAndFiltersIcon}
                          alt="Fluids and Filters Icon"
                        /> */}
                      </OverlayTrigger>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              </li></ul>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "partNum",
      text: <span>Part<br />Number</span>,
      headerStyle: partHeaderStyle,
      formatter: function (cell, row) {
        return !_isEmpty(row.partNum) ? (
          <div className="product-image-container table-view-margin vehicle-name-padding">
            <span>{row.partNum}</span>
          </div>
        ) : (
          ""
        );
      },
      headerAlign: "center",
      align: "center",
      classes: "bootstrap-table-bold-cell",
    },
    {
      dataField: "desc",
      text: <span>Item<br />Description</span>,
      headerAlign: "center",
      headerStyle: descriptionHeaderStyle,
      align: "center",
      classes: "bootstrap-table-bold-cell",
      formatter: function (cell, row, index) {
        console.log(row.qty);
        return !_isEmpty(row.desc) ? (
          <div className="product-image-container table-view-margin vehicle-name-padding checkout-description-width">
            <span>{row.desc}</span>
            {row?.discountAmount !== undefined && row.discountAmount > 0 && (
              <OverlayTrigger
                key={`discountPart`}
                placement={`top`}
                overlay={
                  <Tooltip id={`discountPart`}>
                    {row.qty >= row.discountQty ? (
                      <strong>Item is qualified for Bulk Discount.</strong>
                    ) : (
                      < strong > Please order {row.discountQty} quantity to qualify for Bulk Discount.</strong>
                    )}
                  </Tooltip>
                }
              >
                {/* <img className="icon-discount" src={DiscountImage} alt="DiscountPart" /> */}
              </OverlayTrigger>
            )}
          </div>
        ) : (
          ""
        );
      },
    },
    {
      dataField: "qty",
      text: <span>Order<br />QTY</span>,
      align: "center",
      headerAlign: "center",
      headerStyle: orderQtyHeaderStyle,
      classes: "bootstrap-table-bold-cell",
      formatter: function (cell, row) {
        return !_isEmpty(row.partNum) ? (
          <div className="product-image-container table-view-margin vehicle-name-padding">
            <span>{row.qty}</span>
          </div>
        ) : (
          ""
        );
      },
    },
    {
      dataField: "availabilityHour",
      text: <span>Item<br />Availability</span>,
      headerStyle: availabilityHeaderStyle,
      headerAlign: "center",
      width: "450px",
      classes: "bootstrap-table-bold-cell",
      formatter: function (cell, row) {
        let availablility = [];
          if (!_isEmpty(row.partNum) && !_isEmpty(row.availability) && row.distStatus !== "Out Of Stock") {
            {
              row.availability.map((value, index) => {
                {
                  let array = value.split('-');
                  let val = array[0];
                  let qty = array[1];
                  val === null ||
                    val === "null" ||
                    val === "Same Day" ||
                    val === "Immediately" ||
                    val === "Next Business Day"
                    ? availablility.push(
                      <li>
                        <span className="text-black">
                          <span className="maroonColorTxt">
                            <b>{qty}</b>{" "}
                          </span>
                          {val} <br />
                        </span>
                      </li>
                    )
                    : availablility.push(
                      <li>
                        <span className="text-black">
                          <span className="maroonColorTxt">
                            <b>{qty}</b>{" "}
                          </span>{" "}
                          <u>Within</u> {formatTimeAvb(val)}{" "}
                          <br />
                        </span>
                      </li>
                    );
                }
              });
            }
          } else {
            availablility.push(
              <li>
                <span>
                  <p
                    className="checkout-header out-of-stock"
                    title={
                      row.distStatus == "Out Of Stock"
                        ? "currently out of stock"
                        : row.mfgCode
                    }
                  >
                    {row.distStatus == "Out Of Stock" ? (
                      <b className="add_to_cart_out">Currently out of stock</b>
                    ) : (
                      ""
                    )}
                  </p>
                </span>
              </li>
            );
          }
          let fullDiv = (
            <div className="product-image-container table-view-margin vehicle-name-padding checkout-availability-width">
              {" "}
              <div
                className={
                  _isEmpty(row.partNum)
                    ? "checkout-availability-subdiv gry-back-ground"
                    : "checkout-availability-subdiv avabilability-padding"
                }
              >
                <ul>{availablility}</ul>
              </div>
            </div>
          );
          return fullDiv;
      }
    },
    {
      dataField: "packQty",
      text: <span>Pack<br />Qty</span>,
      headerAlign: "center",
      formatter: function (cell, row) {
        return !_isEmpty(row.partNum) ? (
          <div className="product-image-container table-view-margin vehicle-name-padding checkout-qty-width">
            <span className="text-black">
              {row.packQty}
            </span>
          </div>
        ) : (
          <span></span>
        );
      },
      headerStyle: packQtyHeaderStyle,
      align: "center",
      classes: "bootstrap-table-bold-cell",
    },
    {
      dataField: "qtyPerVeh",
      text: <span>Qty Per<br />Vehicle</span>,
      formatter: function (cell, row) {
        return !_isEmpty(row.partNum) ? (
          <div className="product-image-container table-view-margin vehicle-name-padding checkout-qtypervehicle-width">
            <span className="text-black">
              {row.qtyPerVeh ?? 0}
              {(hideCostPricePref && hideListPrice) ? getDeleteIcon(row) : ''}
            </span>
          </div>
         ) : (
          <span></span>
        );
      },
      headerAlign: "center",
      align: "center",
      headerStyle: qtyPerVehicleHeaderStyle,
      classes: "bootstrap-table-bold-cell",
    },
    {
      dataField: "costPrice",
      text: <span>Your<br />Price</span>,
      hidden: hideCostPrice || hideCostPricePref || hideAllPrice,
      formatter: function (cell, row) {
        return !_isEmpty(row.partNum) ? (
          <div className="product-image-container table-view-margin vehicle-name-padding checkout-availability-width">
            <span className="maroonColorTxt">
              ${formatAmount(row.costPrice)}
            </span>
          </div>
        ) : (
          <span></span>
        );
      },
      headerStyle: yourPriceHeaderStyle,
      headerAlign: "center",
      align: "right",
      classes: "bootstrap-table-bold-cell",
    },
    {
      dataField: "corePrice",
      text: <span>Core<br />Price</span>,
      hidden: hideCostPrice || hideCostPricePref || hideAllPrice,
      headerStyle: yourPriceHeaderStyle,
      headerAlign: "center",
      align: "right",
      classes: "bootstrap-table-bold-cell",
      formatter: function (cell, row) {
        return !_isEmpty(row.partNum) ? (
          <div className="product-image-container table-view-margin vehicle-name-padding checkout-availability-width">
            <span className="maroonColorTxt">
              ${formatAmount(row.corePrice)}
            </span>
          </div>
        ) : (
          <span></span>
        );
      },
    },
    {
      dataField: "listPrice",
      text: <span>List<br />Price</span>,
      hidden: hideListPrice || hideAllPrice,
      formatter: function (cell, row) {
        return !_isEmpty(row.partNum) ? (
          <div className="product-image-container table-view-margin vehicle-name-padding checkout-availability-width">
            <span className="maroonColorTxt">
              ${formatAmount(row.listPrice)}
            </span>
            {hideCostPricePref ? getDeleteIcon(row) : ''}
          </div>
        ) : (
          <span></span>
        );
      },
      headerStyle: listPriceHeaderStyle,
      headerAlign: "center",
      align: "right",
      classes: "bootstrap-table-bold-cell",
    },
    // {
    //   dataField: "taxAmount",
    //   text: <span>Tax<br />Amount</span>,
    //   formatter: function (cell, row) {
    //     return !_isEmpty(row.partNum) ? (
    //       <div className="product-image-container table-view-margin vehicle-name-padding checkout-availability-width">
    //         <span className="maroonColorTxt">
    //           ${formatAmount(row.taxAmount)}
    //         </span>
    //       </div>
    //     ) : (
    //       <span></span>
    //     );
    //   },
    //   headerStyle: taxAmountHeaderStyle,
    //   headerAlign: "center",
    //   align: "right",
    //   classes: "bootstrap-table-bold-cell",
    // },
    {
      dataField: "extendedPrice",
      text: <span>Extended<br />Price</span>,
      hidden : hideCostPrice || hideCostPricePref || hideAllPrice ,
      headerStyle: extendedPriceHeaderStyle,
      align: "right",
      headerAlign: "center",
      classes: "bootstrap-table-bold-cell",
      headerFormatter: (column, colIndex) => { return extendedPriceHeader() },
      formatter: function (cell, row, index) {
        // <span>{row.extendedPrice}</span>
        return !_isEmpty(row.partNum) ? (
          <div className="product-image-container table-view-margin checkout-exdprice-width extended_price_align">
            <span className="maroonColorTxt ml-n4">
              ${getExtendedTotalPrice(row.corePrice, row.costPrice, row.qty)}
            </span>
            {getDeleteIcon(row)}
          </div>
        ) : (
          <span></span>
        );
      }
    },
    {
      dataField: "groupby",
      hidden: true,
    },
  ];



  const handleSKUClick = (e, part) => {
    const {
      yearId,
      makeId,
      make,
      model,
      engine,
      modelId,
      engineId,
      groupId,
      categoryId,
      partType,
    } = part;
    if (partType === "Regular" && !!categoryId && !!groupId) {
      let selectedGroup = {
        categoryId,
        code: Number(groupId),
        name: "",
        engineCode: "Y",
      };
      dispatch({
        payload: {
          selectedGroupDetails: [selectedGroup],
        },
        type: "GROUP_SELECTED",
      });
      dispatch({
        payload: {
          selectedVehicle: {
            yearId,
            makeId,
            modelId,
            engineId,
            year: yearId,
            make,
            model,
            engine,
          },
        },
        type: "VEHICLE_SELECTION_COMPLETED",
      });
      const { x2userName, shipTos, billTo } = userDetails || {};
      const payload = {
        createdBy: x2userName,
        createdDate: timeInUTC(),
        customerId: billTo.billToId,
        engine: engine ? engine.trim() : "",
        engineId,
        favoriteFlag: "N",
        favoriteText: "",
        favoriteUpdatedDate: "",
        lastOrderedDate: "",
        make: make ? make.trim() : "",
        makeId,
        model: model ? model.trim() : "",
        modelId,
        orderedFlag: "N",
        searchId: "",
        shipToId: shipTos[0].shipToId,
        updatedBy: x2userName,
        updatedDate: timeInUTC(),
        year: yearId ? yearId.trim() : "",
      };
      dispatch(setVehicles(payload));
      dispatch(setVehicleSelected(payload));
      let reqObj = {
        requestItems: [
          {
            groupCode: groupId,
            specificCondition: [],
          },
        ],
      };
      dispatch(
        getPartsByMultiGroupOrchestrator(
          yearId,
          makeId,
          modelId,
          engineId,
          reqObj
        )
      );
      history.push("/product-list");
    } else if (partType === "Chemical" && !!groupId) {
      dispatch(getChemPartByGrp([groupId]));
      history.push("/supplies-list");
    }
  };

  return (
    <>
      <div className="cart_scroll">
        {!cartApiFetching ?

          <div className="checkout-print-cart checkout_table_width">
            {!_isEmpty(products) && renderTable()}
          </div>
          :
          <Placeholder rows={2} />}

      </div>
    </>

  );
}
export default AddedPartsView;
