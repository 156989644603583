import userApi from "../services/userApi";
import promotionsApi from "../services/promotionsApi";
import { triggerCustomUxCriticalError } from "./error";
import httpClient from "../services/http";
import storage from "../helpers/storage";

export const actionTypes = {
  CUSTOMER_CREATION_COMPLETE :"CUSTOMER_CREATION_COMPLETE",
  CUSTOMER_CREATION_API_CALL : "CUSTOMER_CREATION_API_CALL",
  USER_CREATION_API_CALL: "USER_CREATION_API_CALL",
  DRIVER_CREATION_API_CALL: "DRIVER_CREATION_API_CALL",
  DRIVER_CREATION_COMPLETE: "DRIVER_CREATION_COMPLETE",
  USER_CREATION_COMPLETE: "USER_CREATION_COMPLETE",
  PROMOTIONS_FOR_USER: "PROMOTIONS_FOR_USER",
  PRO_PACKS_FETCHED: "PRO_PACKS_FETCHED",
  SHIPTO_REFRESH_COMPLETE: "SHIPTO_REFRESH_COMPLETE",
  SHIPTO_LOCATION_COMPLETE: "SHIPTO_LOCATION_COMPLETE",
};

export const createUser = (userObj, dispatch) => {
  const statusMessageProps = {
    heading: "User Created Successfully",
    message: `Email verification has been sent to ${userObj.email}`,
    type: "success",
  };
  let requestParams = {
    dispatch,
  };
  if (userObj.userType && userObj.userType === "COMPANY_ADMIN") {
    requestParams = {
      dispatch,
      companyId: userObj.companyId,
      updatedBy: userObj.updatedBy,
      updatedDate: userObj.updatedDate,
    };
  }
  dispatch({
    payload: {
      userCreationApiCall: true,
    },
    type: actionTypes.USER_CREATION_API_CALL,
  });
  return userApi
    .post(`/user`, userObj, {
      params: requestParams,
    })
    .then((result) => {
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      dispatch({
        payload: {
          userCreationSuccess: "success",
          message: result.data,
          userCreationApiCall: false,
        },
        type: actionTypes.USER_CREATION_COMPLETE,
      });
    })
    .catch((error) => {
      dispatch({
        payload: {
          userCreationSuccess: "failed",
          message: error.errorMessage || "",
          userCreationApiCall: false,
        },
        type: actionTypes.USER_CREATION_COMPLETE,
      });
    });
};

export const registerUser = (userObj, dispatch) => {
  const statusMessageProps = {
    heading: "User Created Successfully",
    message: `Email verification has been sent to ${userObj.email}`,
    type: "success",
  };
  let requestParams = {
    dispatch,
  };
  if (userObj.userType && userObj.userType === "COMPANY_ADMIN") {
    requestParams = {
      dispatch,
      companyId: userObj.companyId,
      updatedBy: userObj.updatedBy,
      updatedDate: userObj.updatedDate,
    };
  }
  dispatch({
    payload: {
      userCreationApiCall: true,
    },
    type: actionTypes.USER_CREATION_API_CALL,
  });
  return userApi
    .post(`/user/register`, userObj, {
      params: requestParams,
    })
    .then((result) => {
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      dispatch({
        payload: {
          userCreationSuccess: "success",
          message: result.data,
          userCreationApiCall: false,
        },
        type: actionTypes.USER_CREATION_COMPLETE,
      });
    })
    .catch((error) => {
      dispatch({
        payload: {
          userCreationSuccess: "failed",
          message: error.errorMessage || "",
          userCreationApiCall: false,
        },
        type: actionTypes.USER_CREATION_COMPLETE,
      });
    });
};

export const createCompanyAdminUser = (userObj, dispatch) => {
  const statusMessageProps = {
    heading: "User Company Admin Created Successfully",
    message: `Email verification has been sent to ${userObj.email}`,
    type: "success",
  };
  let requestParams = {
    dispatch,
  };
  if (userObj.userType && userObj.userType === "COMPANY_ADMIN") {
    requestParams = {
      dispatch,
      companyId: userObj.companyId,
      updatedBy: userObj.updatedBy,
      updatedDate: userObj.updatedDate,
    };
  }
  dispatch({
    payload: {
      userCreationApiCall: true,
    },
    type: actionTypes.USER_CREATION_API_CALL,
  });
  return userApi
    .put(`/company/user`, userObj, {
      params: requestParams,
    })
    .then((result) => {
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      dispatch({
        payload: {
          userCreationSuccess: "success",
          message: result.data,
          userCreationApiCall: false,
        },
        type: actionTypes.USER_CREATION_COMPLETE,
      });
    })
    .catch((error) => {
      dispatch({
        payload: {
          userCreationSuccess: "failed",
          message: error.errorMessage || "",
          userCreationApiCall: false,
        },
        type: actionTypes.USER_CREATION_COMPLETE,
      });
    });
};

export const createCompany = (userObj, dispatch) => {
  const statusMessageProps = {
    heading: "Company Created Successfully",
    type: "success",
  };
  dispatch({
    payload: {
      userCreationApiCall: true,
    },
    type: actionTypes.USER_CREATION_API_CALL,
  });
  return userApi
    .post(`/company`, userObj, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      dispatch({
        payload: {
          userCreationSuccess: "success",
          message: result.data,
          userCreationApiCall: false,
        },
        type: actionTypes.USER_CREATION_COMPLETE,
      });
    })
    .catch((error) => {
      dispatch({
        payload: {
          userCreationSuccess: "failed",
          message: error.errorMessage || "",
          userCreationApiCall: false,
        },
        type: actionTypes.USER_CREATION_COMPLETE,
      });
    });
};

export const createDriver = (userObj, dispatch) => {
  const statusMessageProps = {
    heading: "User Created Successfully",
    message: `Driver Creation success`,
    type: "success",
  };
  dispatch({
    payload: {
      driverCreationApiCall: true,
    },
    type: actionTypes.DRIVER_CREATION_API_CALL,
  });
  return userApi
    .post(`/driverdetails`, userObj, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      dispatch({
        payload: {
          userCreationSuccess: "success",
          message: result.data,
          driverCreationApiCall: false,
        },
        type: actionTypes.DRIVER_CREATION_COMPLETE,
      });
    })
    .catch((error) => {
      dispatch({
        payload: {
          userCreationSuccess: "failed",
          message: error.errorMessage || "",
          driverCreationApiCall: false,
        },
        type: actionTypes.DRIVER_CREATION_COMPLETE,
      });
    });
};

export const getPromotionForUser = (dispatch) => {
  promotionsApi
    .get(`promotion`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      // console.log(result, 'result from Promotions');
      dispatch({
        payload: {
          promotionsData: result.data,
        },
        type: actionTypes.PROMOTIONS_FOR_USER,
      });
    })
    .catch((error) => {
      console.log("error in promotions", error);
    });
};
export const getPromotionForParts = (custNum, shipToID) => {
  return (dispatch) => {
    let reqObj = {
      params: {
        custNum,
        shipToID,
      },
    };
    promotionsApi
      .get(`job`, reqObj)
      .then((res) => {
        if (res) {
          dispatch({
            payload: {
              proPacksData: res.data,
            },
            type: actionTypes.PRO_PACKS_FETCHED,
          });
        }
      })
      .catch((err) => {
        console.log(err, "error in pro packs api");
      });
  };
};

export const logUserActivity = (
  type = "login",
  customerId,
  loginDate = "",
  shipToId,
  userName,
  loginId = "",
  logOutDate = ""
) => {
  return (dispatch) => {
    httpClient
      .post(
        `user-service/login/add-user`,
        {
          customerId,
          createDate: loginDate,
          loginId,
          // logOutDate,
          shipToId,
          type,
          userData: "",
          userName,
        },
        {
          params: {
            dispatch,
          },
        }
      )
      .then((result) => {
        if (!!loginDate) {
          storage.set("loginId", result.data.loginId, "local");
          storage.set("customerId", customerId, "local");
          storage.set("shipToId", shipToId, "local");
          storage.set("userName", userName, "local");
          storage.set("loginDate", loginDate, "local");
        }
      })
      .catch((error) => console.log("logging failed"));
  };
};

export const logUserStockActivity = (
  createDate,
  customerId,
  loginId,
  shipToId,
  type,
  userData,
  userName
) => {
  return (dispatch) => {
    httpClient
      .post(
        `user-service/login/add-user`,
        {
          customerId,
          createDate: createDate,
          loginId,
          // logOutDate,
          shipToId,
          type,
          userData: userData,
          userName,
        },
        {
          params: {
            dispatch,
          },
        }
      )
      .then((result) => {
        // if (!!loginDate) {
        //   storage.set("loginId", result.data.loginId, "local");
        //   storage.set("customerId", customerId, "local");
        //   storage.set("shipToId", shipToId, "local");
        //   storage.set("userName", userName, "local");
        //   storage.set("loginDate", loginDate, "local");
        // }
      })
      .catch((error) => console.log("logging for stock failed"));
  };
};

export const refreshShipTo = (userName, dispatch) => {
  return (dispatch) => {
    let reqObj = {
      params: {
        x2Username: userName,
        dispatch,
      },
    };
    userApi
      .get(`/shipto/refresh`, reqObj)
      .then((result) => {
        dispatch({
          payload: {
            shipToRefreshSuccess: "success",
            message: result.data,
            shipToRefreshApiCall: false,
          },
          type: actionTypes.SHIPTO_REFRESH_COMPLETE,
        });
      })
      .catch((error) => {
        dispatch({
          payload: {
            shipToRefreshSuccess: "failed",
            message: error.errorMessage || "",
            shipToRefreshApiCall: false,
          },
          type: actionTypes.SHIPTO_REFRESH_COMPLETE,
        });
      });
  };
};

export const refreshLocation = (reqObj) => {
  return (dispatch) => {
    userApi
      .post(`/user/shipTo/location`, reqObj, {
        params: {
          dispatch,
        },
      })
      .then((result) => {
        dispatch({
          payload: {
            shipToLocationSuccess: "success",
            message: result.data,
            shipToLocationApiCall: false,
          },
          type: actionTypes.SHIPTO_LOCATION_COMPLETE,
        });
      })
      .catch((error) => {
        dispatch({
          payload: {
            shipToLocationSuccess: "failed",
            message: error.errorMessage || "",
            shipToLocationApiCall: false,
          },
          type: actionTypes.SHIPTO_LOCATION_COMPLETE,
        });
      });
  };
};

export const createSuperAdmin = (userObj, dispatch) => {
  const statusMessageProps = {
    heading: "Customer Added Successfully",
   // message: `Email verification has been sent to ${userObj.email}`,
    type: "success",
  };
  let requestParams = {
    dispatch,
  };
    requestParams = {
      dispatch,
      invoiceAmount: userObj.invoiceAmount,
      invoiceNumber: userObj.invoiceNumber,
      xlPartAccountNumber: userObj.xlPartAccountNumber,
    };
  dispatch({
    payload: {
      customerCreationApiCall: true,
    },
    type: actionTypes.CUSTOMER_CREATION_API_CALL,
  });
  return userApi
    .post(`/user/createSuperAdmin`, userObj, {
      params: requestParams,
    })
    .then((result) => {
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
      dispatch({
        payload: {
          userCreationSuccess: "success",
          message: result.data,
          customerCreationApiCall: false,
        },
        type: actionTypes.CUSTOMER_CREATION_COMPLETE,
      });
    })
    .catch((error) => {
      dispatch({
        payload: {
          userCreationSuccess: "failed",
          message: error.errorMessage || "",
          customerCreationApiCall: false,
        },
        type: actionTypes.CUSTOMER_CREATION_COMPLETE,
      });
    });
};