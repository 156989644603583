import React, { useEffect, useRef, useState } from "react";
import { Carousel, Modal } from 'react-bootstrap';
import {
	getImageURLCookieDetails
} from '../../../helpers/utils';
import './imageSlider.scss';
import _isEmpty from 'lodash/isEmpty';

function ImagesSlider({ closePopup, openPopup, partDetails, setShowImagesSlider }) {
  const [index, setIndex] = useState(0);
  const [curZoom, setCurZoom] = useState(0);
  const [imgStyle, setImgStyle] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const [thumbnailImgStyle, setThumbnailImgStyle] = useState({
    transform : "scale(1)",
    maxWidth: "250px",
    maxHeight: "250px",
    width: "100%",
    marginTop: "250px",
    marginLeft: "250px"
  });

  const imagePrintRef = useRef([]);

  useEffect(()=> {
    if (openPopup) {
      let show = false;
      if(_isEmpty(partDetails.image) && partDetails.thumbnail) {
        show = true;
      }
      if(partDetails.storeDashBoard && !_isEmpty(partDetails.image)) {
        show = true;
      }
      if(partDetails.images && partDetails.images.length) {
        show = true;
      }
      if(!show && setShowImagesSlider) {
        setShowImagesSlider(false);
      }
      setShowPopup(show);
    }
  }, [])

  const handleSelect = (selectedIndex, e) => {
    setCurZoom(0);
    // setImgStyle({
    //   transform : "scale(1)",
    //   maxWidth: "1000px",
    //   width: "100%"
    // });
    setThumbnailImgStyle({
      transform : "scale(1)",
      maxWidth: "250px",
      maxHeight: "250px",
      width: "100%",
      marginTop: "250px",
      marginLeft: "250px"
    });
    setIndex(selectedIndex);
  };

  const handlePrint = () => {
    const w = window.open();
    if (imagePrintRef.current[index].innerHTML) {
      w.document.write(imagePrintRef.current[index].innerHTML);
      w.print();
    }
    w.close();
  }
  const zoomInOut = (e, zoomingIn) => {
    let currentZoom = curZoom;
  	if (zoomingIn) {
  		currentZoom++;
  	} else {
  		currentZoom--;
  	}
    setCurZoom(currentZoom);
    setZoomLevel(currentZoom);
  }

  const setZoomLevel = (currentZoom) => {
    let curScale=1+(currentZoom*0.100);
  	setImgStyle({
      transform : "scale("+curScale+")",
      maxWidth: "1000px",
      width: "100%"
    });
    setThumbnailImgStyle({
      transform : "scale("+curScale+")",
      maxWidth: "250px",
      maxHeight: "250px",
      width: "100%",
      marginTop: "250px",
      marginLeft: "250px"
    });
  }

  const setImageMarginTop = (e, imageId) => {
    let loadedImage = document.getElementById(imageId);
    let imageWrapperDiv = document.getElementsByClassName('imageScaleWrapper');
    if (loadedImage.height <= 300) {
      loadedImage.style.marginTop = "15%";
      loadedImage.style.height = "auto";
    } else if (loadedImage.height <= 500) {
      loadedImage.style.marginTop = "24%";
      loadedImage.style.height = "auto";
    } else {
      loadedImage.height = (imageWrapperDiv.length && imageWrapperDiv[0].clientHeight > 0)? imageWrapperDiv[0].clientHeight : '600px';
    }
    // loadedImage.style.left = "30%";
    // if (loadedImage.height > 500 && loadedImage.height < 800) {
    //   loadedImage.style.marginTop = "150px";
    // }
  };

  return(
      <Modal show={showPopup} className="sliderModal">
        <Modal.Body>
          <div className="modalTopBar"></div>
          <div className="modalSliderWrapper">
            <div className="image-btns row">
              <a className="btn" onClick={handlePrint}>
                <span className="material-icons">print</span>
              </a>
              <a className="btn" onClick={(e) => zoomInOut(e, true)}>
                <span className="material-icons">zoom_in</span>
              </a>
              <a className="btn" onClick={(e) => zoomInOut(e, false)}>
                <span className="material-icons">zoom_out</span>
              </a>
              <a
                className="btn close-btn"
                onClick={() => closePopup("imagesSlider")}
              >
                <span className="material-icons">close</span>
              </a>
            </div>
            <Carousel activeIndex={index} interval={null} onSelect={handleSelect}>
            {_isEmpty(partDetails.image) && partDetails.thumbnail
						&& (
							<Carousel.Item>
                    <div className="imageScaleWrapper">
											<div className="imageContainer row" ref={el => imagePrintRef.current[0] = el}>
	                      <img
	                        style={thumbnailImgStyle}
	                        src={`data:image/png;base64,${partDetails.thumbnail}`}
													// src={image.url}
													alt="Image"
                          id={`loadedImg-${partDetails.partNumber}-${0}`}
	                        // onLoad={(e) => setImageMarginTop(e, `loadedImg-${partDetails.partNumber}-${key}`)}
	                      />
											</div>
                    </div>
                    <Carousel.Caption>
                      <p>{partDetails.mfgName}  {partDetails.partNumber}   {partDetails.description}</p>
                    </Carousel.Caption>
                  </Carousel.Item>
						)}
            {partDetails.images && partDetails.images.map(
              (image, key) => {
                return (
                  <Carousel.Item>
                    <div className="imageScaleWrapper">
											<div className="imageContainer row" ref={el => imagePrintRef.current[key] = el}>
	                      <img
	                        style={imgStyle}
	                        src={`${image.url}?${getImageURLCookieDetails()}`}
													// src={image.url}
													alt="Image"
                          id={`loadedImg-${partDetails.partNumber}-${key}`}
	                        // onLoad={(e) => setImageMarginTop(e, `loadedImg-${partDetails.partNumber}-${key}`)}
	                      />
											</div>
                    </div>
                    <Carousel.Caption>
                      <p>{partDetails.mfgName}  {partDetails.partNumber}   {partDetails.description}</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              }
            )}
            </Carousel>
          </div>
        </Modal.Body>
      </Modal>
  );
}
export default ImagesSlider;
