/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button } from "react-bootstrap";
import "./styles.scss";
import { Image } from "react-bootstrap";
import T from "prop-types";
import Moment from "react-moment";
import { currencyFormatter, getImageURLCookieDetails } from "helpers/utils";

import ImagesSlider from "components/Listing/ImagesSlider/index";
import { useDispatch, useSelector } from "react-redux";
import { getCKReviewSelectedOrder } from "../c_and_k.thunk";
import CKReviewPopUp from "../Checkout/CK_ReviewPopUp";
import _ from "lodash";
import RenderIIcon from "../components/RenderIIcon";

const NA = () => {
  return <span style={{ textAlign: "center", display: "block" }}>NA</span>;
};

function OEMCompactView(props) {
  const { items, filter } = props;
  const [groups, setGroups] = useState(null);
  const dispatch = useDispatch();

  const [showImagesSlider, setShowImagesSlider] = useState(false);
  const [partDetails, setPartDetails] = useState(null);
  const [isCallType, setIsCallType] = useState(false);
  const candk = useSelector((state) => state.candk);
  const { ckorder } = candk;

  const handleClose = () => {
    dispatch(
      getCKReviewSelectedOrder({ ...ckorder, show: false, warrantyData: {} })
    );
  };

  const handlePartDetailsChange = (part) => {
    setPartDetails({
      ...part,
      images: part.resourceUrls,
      image:
        part.resourceUrls &&
        part.resourceUrls.length > 0 &&
        part.resourceUrls[0].url,
    });
  };

  const togglePopup = () => {
    setShowImagesSlider(!showImagesSlider);
  };

  const handleImageClick = (part) => {
    handlePartDetailsChange(part);
    setShowImagesSlider(true);
  };

  const redirectToReviewPage = (row) => {
    if (row.stockStatus !== "Yes") {
      setIsCallType(true);
    } else {
      setIsCallType(false);
    }
    dispatch(getCKReviewSelectedOrder({ ...row, show: true }));
    // history.push("/ck_partsearch/ck_review");
  };

  const headerStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    //'width': '90px'
    width: "60px",
  };
  const descriptionHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "160px",
  };

  const yourPriceHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "15px",
  };

  const listPriceHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "15px",
  };

  const QtyOrderheaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "50px",
  };

  const estimatedHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "60px",
  };

  const brandHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "70px",
  };

  const rowClasses = (row, rowIndex) => {
    let classes = null;

    if (row.partheader) {
      classes = "rowStyleCss";
    }
    return classes;
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.partheader) {
      style.backgroundColor = "#e3e3e3";
      style.border = "solid 2px #8c99a6";
    }

    return style;
  };

  const partNumberFormatter = (cell, row, index) => {
    const imageURLCookie = getImageURLCookieDetails();
    const { resourceUrls } = row;

    let image =
      resourceUrls && resourceUrls.length > 0
        ? `${resourceUrls[0].url}?${imageURLCookie}`
        : "/noImage.jpg";

    let imagesLength = 0;

    return (
      <>
        <div className="product-image-container float-left">
          <div
            title={imagesLength > 0 ? `${imagesLength} images` : ""}
            onClick={() => handleImageClick(row)}
          >
            {!row.partheader ? (
              <div className="image-thumbnail-container-table mr-1">
                <Image className="image-thumbnail cursor-pointer" src={image} />
              </div>
            ) : (
              <span></span>
            )}
          </div>
        </div>

        <div className="product_img">
          {row.partheader ? (
            <div className="product-image-container table-view-margin">
              <span>
                <p className="part-num-part-brand">{row.partheader}</p>
              </span>
            </div>
          ) : (
            <div className="product-image-container table-view-margin">
              <p className="part-num-catalog">{cell.trim()}</p>
            </div>
          )}
        </div>
      </>
    );
  };

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <div className="oem-read-more-paragraph">
        {isReadMore && text ? text?.slice(0, 40) : text}
        <span onClick={toggleReadMore} className="oem-read-more-style">
          {isReadMore ? "...Read More" : " Show Less"}
        </span>
      </div>
    );
  };

  const descriptionFormatter = (cell, row, index) => {
    return (
      <>
        {!row.partheader ? (
          <>
            <p style={{ margin: "0", padding: "0" }}>{row.description}</p>
            <ReadMore>
              {row.messages.map((msg) => msg.content).join("\r\n")}
            </ReadMore>
          </>
        ) : (
          <span></span>
        )}
      </>
    );
  };

  const readMoreFormater = (cell, row, index) => {
    return (
      <>
        {row.matchDetails && row.matchDetails.length > 0 ? (
          <ReadMore>{`Notes: ${row.matchDetails}`}</ReadMore>
        ) : (
          "-"
        )}
      </>
    );
  };

  const columns = [
    {
      dataField: "quotedPartNumber",
      text: "Part#",
      headerStyle: headerStyle,
      align: "center",
      headerAlign: "center",
      classes: "bootstrap-table-bold-cell",
      formatter: partNumberFormatter,
    },
    {
      dataField: "description",
      text: "Description",
      headerStyle: descriptionHeaderStyle,
      align: "center",
      classes: "bootstrap-table-bold-cell",
      headerAlign: "center",
      formatter: descriptionFormatter,
    },
    {
      dataField: "matchDetails",
      text: "Notes",
      headerStyle: descriptionHeaderStyle,
      align: "center",
      classes: "bootstrap-table-bold-cell",
      headerAlign: "center",
      formatter: readMoreFormater,
    },
    {
      dataField: "customerPrice",
      text: (
        <RenderIIcon
          label="Your Price"
          hoverText="Warranty is included in purchase"
          iconColor="light"
          className="custom-i-icon"
        />
      ),
      headerStyle: yourPriceHeaderStyle,
      align: "center",
      classes: "bootstrap-table-bold-cell ck_checkout_rightalign vAlign",
      headerAlign: "center",
      formatter: (cell) => {
        return cell !== null ? `${currencyFormatter(cell)}` : <NA />;
      },
    },
    {
      dataField: "coreValue",
      text: (
        <RenderIIcon
          label="Core Value"
          hoverText="Not charged with the return of the core"
          iconColor="light"
          className="custom-i-icon"
        />
      ),
      headerStyle: yourPriceHeaderStyle,
      align: "center",
      classes: "bootstrap-table-bold-cell ck_checkout_rightalign vAlign",
      headerAlign: "center",
      formatter: (cell) => {
        return cell !== null ? `${currencyFormatter(cell)}` : <NA />;
      },
    },
    {
      dataField: "listPrice",
      text: "List Price",
      headerStyle: listPriceHeaderStyle,
      align: "center",
      classes: "bootstrap-table-bold-cell ck_checkout_rightalign vAlign",
      headerAlign: "center",
      formatter: (cell) => {
        return cell !== null ? `${currencyFormatter(cell)}` : <NA />;
      },
    },
    {
      dataField: "deliveryDate",
      text: "Estimated Delivery Date",
      headerStyle: estimatedHeaderStyle,
      align: "center",
      headerAlign: "center",
      classes: "vAlign",
      formatter: (cell, row) => {
        return (
          <div>
            {cell ? (
              <Moment date={row.deliveryDate} format="MM/DD/YYYY" />
            ) : (
              "NA"
            )}
          </div>
        );
      },
    },
    {
      dataField: "deliveryDateCutoffUTC",
      text: "Cut-Off Order Time",
      headerStyle: estimatedHeaderStyle,
      align: "center",
      classes: "vAlign cut-off-time",
      headerAlign: "center",
      formatter: (cell, row) => {
        return (
          <div>
            {cell ? (
              <Moment
                date={`${row.deliveryDateCutoffUTC}Z`}
                format="MM/DD/YYYY hh:mm A z"
                tz="America/Chicago"
              />
            ) : (
              "NA"
            )}
          </div>
        );
      },
    },
    {
      dataField: "partheader",
      hidden: true,
    },
    {
      dataField: "qtytoorder",
      // text: "Qty to Order",
      text: "Buy/Order Now",
      headerStyle: QtyOrderheaderStyle,
      align: "center",
      headerAlign: "center",
      classes: "vAlign",
      formatter: function (cell, row, index) {
        return (
          <>
            <div className="oem-qty-order-container">
              <span
                class="table-add-to-cart-button"
                onClick={() => redirectToReviewPage(row)}
              >
                <Button
                  hidden={row.stockStatus !== "Yes"}
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-shopping-bag" aria-hidden="true"></i>
                </Button>
              </span>
              {row.stockStatus === "Call" && (
                <RenderIIcon
                  label=""
                  hoverText="This item is subject to an availability check. A team member will contact you within 2 business days to confirm availability and delivery date"
                />
              )}
              {row.stockStatus === "No" && (
                <span className="bolder">Currently Out of Stock</span>
              )}
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    switch (filter) {
      case "reman":
        const reman = items.filter(
          (item) => item.partCatalog === "RemanPowertrain"
        );
        const _groupsReman = _.groupBy(reman, (item) => item.partCatalog);
        setGroups(_groupsReman.RemanPowertrain || []);
        break;
      case "used":
        const used = items.filter(
          (item) => item.partCatalog === "UsedPowertrain"
        );
        const _groupsUsed = _.groupBy(used, (item) => item.partCatalog);
        setGroups(_groupsUsed.UsedPowertrain || []);
        break;
      case null:
        setGroups([]);
        break;
      default:
        // skip the grouping filter for now.
        // const groups = _.groupBy(items, (item) => item.partCatalog);
        setGroups(items);
        break;
    }
  }, [items, filter]);

  return (
    <>
      <div className="part-catalog-custom-table oem-cmp-view-container">
        {!filter ? (
          <div className="msg">
            Please select filter(Reman or Used Parts) from the above.
          </div>
        ) : _.isEmpty(groups || []) && candk.isLoadingComplete ? (
          <div className="msg">Parts not found</div>
        ) : !_.isEmpty(groups || []) ? (
          <BootstrapTable
            keyField="rowId"
            columns={columns}
            data={groups || []}
            rowClasses={rowClasses}
            rowStyle={rowStyle}
          />
        ) : (
          <></>
        )}
        {showImagesSlider ? (
          <ImagesSlider
            openPopup={showImagesSlider}
            closePopup={togglePopup}
            partDetails={partDetails}
            setShowImagesSlider={setShowImagesSlider}
          />
        ) : null}
      </div>
      {ckorder?.show && (
        // eslint-disable-next-line react/jsx-pascal-case
        <CKReviewPopUp
          show={ckorder?.show}
          handleClose={handleClose}
          isCallType={isCallType}
        />
      )}
    </>
  );
}

OEMCompactView.propTypes = {
  filter: T.oneOf(["both", "reman", "used"]),
};

export default OEMCompactView;
