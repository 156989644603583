import React, { Fragment } from "react";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { ToastProvider } from "react-toast-notifications";

import "../static/css/App.css";
import "../static/scss/App.scss";
import { fetchUserDetails } from "../actions/auth";
import { getPromotionForUser } from "../actions/user";

import AuthHeader from "./shared/Header/AuthHeader";
import NonAuthHeader from "./shared/Header";
import Router from "./Router";
import ToastMessage from "./shared/ToastMessage";
import ErrorBoundary from "./ErrorBoundary";
import storage from "../helpers/storage";
import { isSmsUser } from "../helpers/utils";
import {processLogin} from '../components/shared/LoginHelper'
import jwt_decode from "jwt-decode";

class App extends React.Component {

  componentWillMount() {
    let { dispatch } = this.props;

    //  ------------------sms refresh----------

    // let smsUser = isSmsUser();
    // if(smsUser){
    //   const jwt = storage.get("authToken", "local");
    //   let jwtData = {payload:{jwtToken:jwt}}
    //   dispatch({
    //     payload: {
    //         smsUser: true,
    //     },
    //     type: 'SMS_USER',
    //   });
    //   processLogin(jwtData, 'sms', dispatch);
    // }else{
    fetchUserDetails(dispatch);
    // }

    //TODO Keep this for now, later we will delete this
    const userPreference = storage.get("userPrefereces", "local");
    if (userPreference === "undefined") {
      storage.set("userPrefereces", {}, "local");
    }
  }

  componentDidUpdate() {
    let { dispatch, userAuthenticated } = this.props;
    if(this.props.smsUser){
    // Promotions to be fetched APP Level, as it is present in most of the pages.
    if (this.props.userPromotionDetails === undefined && userAuthenticated) {
      getPromotionForUser(dispatch);
    }
    }else{
      let userToken = storage.get("authToken", "local");
      let userDetails = "";
      let isCompanyAdmin = false;
      if(userToken){
          let decodedJwtToken = jwt_decode(userToken);
          userDetails = JSON.parse(decodedJwtToken.user_details)
          if(userDetails && userDetails.userType && userDetails.userType == 'STORE_USER') {
            isCompanyAdmin = true;
          }
      }
      // Promotions to be fetched APP Level, as it is present in most of the pages.
      if (this.props.userPromotionDetails === undefined && userAuthenticated && !isCompanyAdmin) {
        getPromotionForUser(dispatch);
      }
    }
  }

  render() {
    let { userAuthenticated } = this.props;
    return (
      <ToastProvider>
        <Fragment>
          {" "}
          <ToastMessage />
          <ErrorBoundary>
            {userAuthenticated ? <AuthHeader /> : <NonAuthHeader />} <Router />
          </ErrorBoundary>
        </Fragment>
      </ToastProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    userAuthenticated: state.app.userAuthenticated,
    userPromotionDetails: state.user.userPromotionDetails,
    userDetails : state.user.userDetails,
    smsUser : state.app.smsUser
  };
}
export default connect(mapStateToProps)(App);
