import React from "react";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import _some from "lodash/some";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import { ReactComponent as BundlePricing } from "../../static/images/icons/Bundle_Pricing.svg";
import { ReactComponent as FamilyPricing } from "../../static/images/icons/quotes.svg";

const PricingIcons = ({ toolTips, isProgrammable }) => {
  const user = useSelector((state) => state.user);
  const bundleCodes = user.userDetails.shipTos[0].bundleCodes || [];
  const familyCode = user.userDetails.billTo.familyPricePlan;
  const familyPricePlans = useSelector(
    (state) => state.userManagement.familyPricePlans
  );
  // const partDetails = useSelector((state) => state.partDetails.partDescription);
  // let searchWords = ["OIL", "LIFE", "BULK"];

  let bundleArr = bundleCodes.map((bundle) =>
    _some(toolTips, { code: bundle })
  );
  let validBundle = _find(toolTips, {
    code: bundleCodes[bundleArr.indexOf(true)],
  });
  let validFamilyPromos = toolTips.filter((single) => {
    let code = single.code.substring(0, 3);
    return code === `${familyCode}*` && single.type === "Family";
  });

  let qtyPriceBreakUp = _find(toolTips, { code: "QPB" });
  let prodPlan = !_isEmpty(validFamilyPromos)
    ? validFamilyPromos[0]?.code.substring(3)
    : "";
  let validProdPlan = !_isEmpty(prodPlan)
    ? _filter(familyPricePlans, { prodPlan: prodPlan })
    : "";

  const priceBreakUp = (qtyPriceBreakUp) => {
    return (
      <div className="bold">
        <span className="qty-price-break">Qty Price Breakup -</span>
        <Row>
          <Col md="6">Qty</Col>
          <Col md="6">Your Price</Col>
        </Row>
        {qtyPriceBreakUp.map((qty, index) => {
          return (
            <div style={{ alignItems: "left" }}>
              <Row>
                <Col>
                  {qty.minQty}
                  {qty.maxQty ? ` - ` + qty.maxQty : " or more"}
                </Col>
                <Col>${qty.unitCost}</Col>
              </Row>
            </div>
          );
        })}
      </div>
    );
  };

  const familyPriceBreakUp = (familyPriceBreakUp) => {
    return (
      <div className="bold">
        <span className="family-price-break">Family Price Breakup -</span>
        <Row>
          <Col md="6">Qty</Col>
          {familyPriceBreakUp[0].discountType === "D" ? (
            <Col md="6">Discount %</Col>
          ) : (
            <Col md="6">Your Price</Col>
          )}
        </Row>
        {familyPriceBreakUp.map((qty, index) => {
          return (
            <div>
              <Row>
                <Col>
                  {qty.fromValue}
                  {` - ` + qty.toValue}
                </Col>
                {qty.discountType === "D" ? (
                  <Col>{qty.discountPct} %</Col>
                ) : (
                  <Col>${qty.netPrice}</Col>
                )}
              </Row>
            </div>
          );
        })}
      </div>
    );
  };

  // displaying tooltip for only single promotions. need to fix later
  return (
    <div className="bundle_pricing_and_specials_container">
      {!!validBundle && (
        <OverlayTrigger
          key={`bundle`}
          placement={`bottom`}
          overlay={
            <Tooltip id={`bundle`}>
              <strong>{validBundle.text || null}</strong>.
            </Tooltip>
          }
        >
          <div>
            <BundlePricing className="icon" />
            {window.location.pathname !== "/checkout" && (
              <span className="icon-text"> Bundle Pricing </span>
            )}{" "}
          </div>
        </OverlayTrigger>
      )}{" "}
      {!_isEmpty(validFamilyPromos) && (
        <OverlayTrigger
          key={`family`}
          placement={`bottom`}
          overlay={
            <Tooltip id={`family`}>
              <strong>{validFamilyPromos[0].text || null}</strong>.
              {!_isEmpty(validProdPlan) && familyPriceBreakUp(validProdPlan)}
              {!_isEmpty(qtyPriceBreakUp) &&
                priceBreakUp(JSON.parse(qtyPriceBreakUp.text))}
            </Tooltip>
          }
        >
          <div>
            <FamilyPricing className="icon" />
            {(window.location.pathname !== "/checkout" && window.location.pathname !== "/ck_partsearch/ck_checkout") && (
              <span className="icon-text"> Family Pricing </span>
            )}{" "}        
          </div>
        </OverlayTrigger>
      )}{" "}
      {isProgrammable && (
        <OverlayTrigger
          key={`programmable`}
          placement={`bottom`}
          overlay={
            <Tooltip id={`programmable`}>
              <strong>Requires Programming</strong>
            </Tooltip>
          }
        >
          <div>
            <img src="/public_images/programmable.png" alt="Programmable" />
            {window.location.pathname !== "/checkout" && (
              <span> Programmable </span>
            )}{" "}
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
};
export default PricingIcons;
