import _isEmpty from "lodash/isEmpty";
import { sendUtilServiceEmail } from "./email.thunks";
import jwt_decode from "jwt-decode";
import storage from "../storage";

function sendEmail(error, status, config) {
  try {
    let errorMsg = "";
    if (!_isEmpty(error)) {
      if (error.message || error.error || error.errorMessage) {
        errorMsg += `<b>Error:</b> ${
          error.message || error.error || error.errorMessage || error.errorMsg
        }<br>`;
      }
    }
    if (config.url && config.url) {
      errorMsg += `<b>URL:</b> ${config.baseURL}/${config.url}<br>`;
    }
    if (!_isEmpty(config.method)) {
      errorMsg += `<b>Method:</b> ${config.method}<br>`;
    }
    if (status) {
      errorMsg += `<b>Status:</b> ${status}<br>`;
    }

    let token = "";
    if (!_isEmpty(config.headers.Authorization)) {
      token = config.headers.Authorization.replace("Bearer ", "");
    } else {
      token = storage.get("authToken", "local");
    }

    if (!_isEmpty(config.data)) {
      let reqPayload = JSON.stringify(config.data);
      errorMsg += `<b>Payload:</b> ${reqPayload}<br>`;
    }

    if (!_isEmpty(config.params)) {
      let reqParams = JSON.stringify(config.params);
      errorMsg += `<b>Params:</b> ${reqParams}<br>`;
    }

    if (!_isEmpty(token)) {
      let decodedJwtToken = jwt_decode(token);
      errorMsg += `<b>User:</b> ${decodedJwtToken.email}<br>`;
    }

    var _navigator = {};
    for (var i in window.navigator) _navigator[i] = navigator[i];
    errorMsg += `<b>Browser Details:</b> ${JSON.stringify(_navigator)}<br>`;

    if(!config.baseURL.includes('dev-cep-api') && !config.baseURL.includes('cep-dev')) {
      const payload = {
        body: errorMsg,
        fromName: "The Parts House",
        subject: config.subject
          ? `${config.subject} - ${config.baseURL.replace("http://", "")}`
          : `Error Notification - ${config.baseURL.replace("http://", "")}`,
      };

      const sendUtilServiceEmailAction = config.dispatch(
        sendUtilServiceEmail(payload)
      );
      sendUtilServiceEmailAction.then((action) => {
        console.log("email sent");
      });
    }

  } catch (error) {}
}

const sendCustomEmail = (payload, config) => {
  config.dispatch(
    sendUtilServiceEmail(payload)
  );
}


const emailUtil = {
  sendEmail,
  sendCustomEmail,
};

export default emailUtil;
