import _ from "lodash";
import moment from "moment";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import _head from "lodash/head";
import storage from "../../../helpers/storage";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getPartImageURLCookie, getUserPreferenceDetails, impersonateUserBillTo ,updateUserDetails} from '../../User/UserManagement/usermanagement.thunk';
import { dismissAllUxCritialErrors } from '../../../actions/error';
import { refreshShipTo, refreshLocation, logUserActivity, getPromotionForUser } from '../../../actions/user';
import { timeInUTC } from '../../../helpers/utils';
import {updateToken} from '../../../actions/auth';
import { triggerCustomUxCriticalError } from "../../../actions/error";
import { fetchShipToIds,fetchUserShipToIds } from "../../shared/ShipTo/shipTo.thunk";


const jwt = require("jsonwebtoken");
const OSRDefaultBillTO = process.env.REACT_APP_OSR_DEFAULT_BILLTO;
const OSRDefaultShipTo = process.env.REACT_APP_OSR_DEFAULT_SHIPTO;

const getUserPreference = (dispatch)=> {
    dispatch({
        payload: {
            status: true,
        },
        type: "REFRESH_TOKEN_STATUS",
    });
    dispatch(getUserPreferenceDetails()).then((res) => {
        storage.set('userPrefereces', res.payload || {}, 'local');
        dispatch({
            payload: {
                status: false,
            },
            type: "REFRESH_TOKEN_STATUS",
        });
    });
}

const updateShipTo = (userDetails, dispatch) => {
  if (!_isEmpty(userDetails.xlPartAccountNumber)) {
    const shipToPayload = {
      billtoid: userDetails.xlPartAccountNumber,
    };
    dispatch(fetchShipToIds(shipToPayload)).then((action) => {
      if (!_isEmpty(action.payload)) {
        if (userDetails.userType === "OSR" || userDetails.userType === "DSM"  && !_isEmpty(OSRDefaultShipTo)) {
           let shipToIdList = {};
          action.payload.map((shipToData) => {
            shipToIdList[shipToData.shipToId.toString()] =
              shipToData.shipToId === OSRDefaultShipTo;
          });
        
          const payload = {
            isActive: userDetails.isActive,
            email: userDetails.x2userName,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            shipToIds: shipToIdList,
            userType: userDetails.userType,
            xlPartAccountNumber: OSRDefaultBillTO,
            mobileNumber: userDetails.mobileNumber,
          };
        //  setTimeout(() => {
           dispatch(updateUserDetails(payload));
          updateToken(dispatch).then(() => {});
         // }, 100);
          
        }
        console.log("action.payload", action.payload);
      }
    });
  }
}

const processLogin = (res, loginType="cognito",dispatch, props) => {
    // TODO : sms iframe change- props code not available for sms -revisit 
    getUserPreference(dispatch);
    let idToken = ''
    let userDetails = {}
    if(loginType == 'cognito'){
        idToken = res.getIdToken().getJwtToken();
        userDetails = JSON.parse(
            res.getIdToken().payload.user_details
    );
    }else{
        idToken =  res.payload.jwtToken;
        let decodedJwtToken = jwt_decode(idToken);
        userDetails = decodedJwtToken.user_details;
    }
    storage.set('authToken', idToken, 'local');
    storage.set("showReleaseNotes", true, 'local');
    storage.set('authFailed', false, 'local');
    if(props){
        const { toastManager } = props;
        // To Remove the toast messages after login if any.
        dismissAllUxCritialErrors(dispatch);
        toastManager.removeAll();
        // --- END ---  
    }
    dispatch({
        payload: {
            authorizationDone: true,
        },
        type: 'AUTHORIZATION_DONE',
    });
    dispatch({
        payload: {
            userDetails,
        },
        type: 'USER_DETAILS_FETCHED',
    });
    let {
        shipTos,
        x2userName: userName,
    } = userDetails;
    let type = "login"
    let isCompanyAdmin =  userDetails.userType && userDetails.userType != "STORE_USER" ? false : true;
    if(props){
        // Promotions to be fetched APP Level, as it is present in most of the pages.
        if (_.isEmpty(props.userPromotionDetails) && !isCompanyAdmin) {
            getPromotionForUser(dispatch);
        }
    }
    if(!isCompanyAdmin) {
        let { billTo: {
              billToId: customerId
             }
          } = userDetails;
        dispatch(logUserActivity(type, customerId, timeInUTC(), shipTos[0].shipToId, userName))
     }
     if (userDetails.userType === "CUSTOMER_ADMIN" || userDetails.userType === "SUPER_ADMIN" ) {
        dispatch(refreshShipTo(userName, dispatch));
    }
    dispatch(refreshLocation({ dispatch }));
    dispatch(getPartImageURLCookie()).then((resp) => {
        storage.set('imageURLCookie', resp.payload || {}, 'local');
    });
    
    if(!_isEmpty(userDetails) && ( userDetails.userType === "LOCATION_BUYER" || userDetails.userType === "CUSTOMER_BUYER")){
      dispatch(fetchUserShipToIds());
      }
    let shipToIdList = {};

    const shipToPayload = {
      billtoid: userDetails.xlPartAccountNumber,
    };
    if (!_isEmpty(userDetails.xlPartAccountNumber)) {
      dispatch(fetchShipToIds(shipToPayload)).then((action) => {
        if (!_isEmpty(action.payload)) {
          userDetails?.shipTos?.find((line) => {
            if (line.isActive === "N") {
              action.payload?.find((shipToLine) => {
                action.payload.map((shipToData) => {
                  shipToIdList[shipToData.shipToId.toString()] =
                    shipToData.shipToId === shipToLine.shipToId;
                });
                if (shipToLine.isActive === "Y") {
                  const payload = {
                    isActive: userDetails.isActive,
                    email: userDetails.x2userName,
                    firstName: userDetails.firstName,
                    lastName: userDetails.lastName,
                    shipToIds: shipToIdList,
                    userType: userDetails.userType,
                    xlPartAccountNumber: userDetails.xlPartAccountNumber,
                    mobileNumber: userDetails.mobileNumber,
                  };
                  dispatch(updateUserDetails(payload));
                  updateToken(dispatch).then(() => {});
                }
              });
            }
          });
          console.log("action.payload", action.payload);
        }
      });
    }
  
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
      autoDismiss: false,
      autoClose: false,
    };
    let userShipTo = userDetails && userDetails.shipTos[0].shipToId;
    //    shipToData.shipToIds.map((ship) => {
    // if (ship.shipToId === userShipTo) {
    if (userDetails.shipTos[0].isActive === "N") {
      statusMessageProps.heading = "Default ShipTo# is InActive.";
      statusMessageProps.type = "error";
      statusMessageProps.autoDismiss = "false";
  
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
    }
    //   }
    //    });


    if (userDetails.userType === "OSR" || userDetails.userType === "DSM") {
        const payload = {
            billTo : OSRDefaultBillTO,
            x2UserName:userDetails.x2userName,
            userType: userDetails.userType,
            buyPartnerCreationFlag: true,
            dispatch,
        };
        dispatch(impersonateUserBillTo(payload)).then((action) => {
          updateToken(dispatch).then(() => {
            updateShipTo(userDetails, dispatch);
          })
        })
    }
};


export {
    processLogin
};
