import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import _remove from "lodash/remove";
import _isEmpty from "lodash/isEmpty";
import { addItemToCart, addItemToCart2 } from "../../../actions/cart";
import { setPartHistory } from "../MyGarage/mygarage.thunks";
import { Button, Form,  OverlayTrigger, Tooltip } from "react-bootstrap";
import _find from "lodash/find";
import _uniqBy from "lodash/uniqBy";
import _map from "lodash/map";
import _includes from "lodash/includes";
import XModal from "../XModal";
import { clearCartForStore, saveQuote } from "../../../actions/cart";

import { timeInUTC, noSpecialCharsRegExp, hasQuoteAlphaSpecialIntChar, isMinLength, isMaxLengthLessthan } from "../../../helpers/utils";
import _every from 'lodash/every';
import FormValidationErrorMessage from '../FormValidationErrorMessage/FormValidationErrorMessage';
import { triggerUxCritialErrors, triggerCustomUxCriticalError } from '../../../actions/error';
const actionTypes = {
	ADDTO_CART_WARNING: 'ADDTO_CART_WARNING',
	ADD_API_CALL: 'ADD_API_CALL'
};

const AddToCartWarningPopup = (props) => {
  const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const mygarage = useSelector((state) => state.mygarage);
	const categoryGroupMap = useSelector((state) => state.parts.categoryGroupMap);
	const checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  const [showQuoteMsg, setshowQuoteMsg] = useState(false);
  const [showWarningModal, setshowWarningModal] = useState(true);
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const cartShipMethod = useSelector((state) => state.parts.cartShipMethod);
	const cartStore = useSelector((state) => state.parts.cartStore);
	const cartOrderType = useSelector((state) => state.cart.orderType);
  const customerStore = user.userDetails && user.userDetails.shipTos && user.userDetails.shipTos[0] &&
                       user.userDetails.shipTos[0].location && user.userDetails.shipTos[0].location.storeNumber ? user.userDetails.shipTos[0].location.storeNumber : "";
  const customerShipMethod = user.userDetails.shipTos && user.userDetails.shipTos.length > 0 && user.userDetails.shipTos[0].shipMethod && user.userDetails.shipTos[0].shipMethod ? user.userDetails.shipTos[0].shipMethod  : ""; 	 
  const displayShipMethod =  checkoutDetails && checkoutDetails.length > 0  ? checkoutDetails[0].shipMethod : customerShipMethod;
  const displayStore = checkoutDetails && checkoutDetails.length > 0 ?  checkoutDetails[0].store : customerStore;
  const [quoteNameValidity, setQuoteNameValidity] = useState({
   isQuoteAlphaSpecialChar: true,
    isQuoteNameMinLimit: true,
    isQuoteNameMaxLimit: true,
  })
  let { openPopup, selectedAvailabilityHours, source, DefaultAddItemToCart, closePopup, addedToCartOrQuote, resetStockCheck, isStockOrder, orderType, fromPage } = props;
	if (!_isEmpty(orderType) && (!_isEmpty(cartOrderType) && cartOrderType !== orderType)) {
		selectedAvailabilityHours = selectedAvailabilityHours.filter((item) => {
			return (
				(item.orderType !== cartOrderType)
			);
		})
		if (orderType === 'STOCK_ORDER') {
			selectedAvailabilityHours = selectedAvailabilityHours.filter((item) => {
				return (
					(item.orderType !== null) 
				);
			})
		}
	} else {
		selectedAvailabilityHours = selectedAvailabilityHours.filter((item) => {
			return (
				(item.shipMethod !== displayShipMethod) || (item.store !== displayStore)
			);
			})
	}	
  const newStore = selectedAvailabilityHours && selectedAvailabilityHours.length > 0 ?  selectedAvailabilityHours[0].store : null;
  const newShipMethod = selectedAvailabilityHours && selectedAvailabilityHours.length > 0 ?  selectedAvailabilityHours[0].shipMethod : null;
  const [quoteName, setQuoteName] = useState(null)
  const quoteStyle = {
    display: "flex",
    alignItems: "center"
  }
  

  // const handleClearCart = async () => {
  //   await clearCart(dispatch);
  // };

  const handleCloseModal = () => {
    setshowWarningModal(false);
    setshowQuoteMsg(false);
	setShowQuoteModal(false);
	closePopup(false);
	dispatch({
		payload: {
		 addToCartWarning: false,
	   },
	   type: actionTypes.ADDTO_CART_WARNING,
	   });
	dispatch({
		payload: {
		  addItemcartFetching: false,
		},
		type: actionTypes.ADD_API_CALL,
	  });
  };


  const handleSaveAsQuote = () => {
		const {
      userDetails: {
          x2userName,
          mobileNumber,
      }
  } = user;
		
		let quoteData = validateQuoteName(quoteName, quoteNameValidity);
		if (_every(quoteData)) {
			saveQuote(x2userName, quoteName, mobileNumber, dispatch).then(savedQuote => {
			   if(!_isEmpty(savedQuote) &&  savedQuote.data){
				const quoteSaved = {
					heading: `Quote: ${quoteName} is saved`,
					message: "",
					type: "success",
				};
				if(resetStockCheck){
					resetStockCheck();
				}
				handleCloseModal();
				addToCart();
				triggerCustomUxCriticalError(quoteSaved, dispatch);
			   } else{
				   setShowQuoteModal(false);
			   }
			});
		}
		else {
			setQuoteNameValidity(quoteData);
		}
	};

  const handleQuoteNameChange = (e) => {
	//	let quoteNameValidity = this.state;
		const { value } = e.target;
		const quoteValue = value.charAt(0);
		let quoteString = value;
		if (quoteValue === ' ') {
			quoteString = value.trim();
		};
		let quoteName = validateQuoteName(quoteString, quoteNameValidity);
    setQuoteName(quoteString.toUpperCase())
    setQuoteNameValidity(quoteName);
	};

  const handleQuoteNameBlur = () => {
    let quoteNameValidData = quoteNameValidity
		setQuoteNameValidity(quoteNameValidData)
	}

  const validateQuoteName = (name, quoteNameValidity) => {
		quoteNameValidity.isQuoteAlphaSpecialChar = hasQuoteAlphaSpecialIntChar(name);
		quoteNameValidity.isQuoteNameMinLimit = isMinLength(name, 1);
		quoteNameValidity.isQuoteNameMaxLimit = isMaxLengthLessthan(name, 50);
		return quoteNameValidity;
	};

  const handleKeyDown = (event, name) => {
		switch (name) {
			case 'quotename':
				if (((quoteName && quoteName.indexOf() === 0) || quoteName === null || quoteName === "") && event.key === ' ') {
					event.preventDefault();
				}
				if ((quoteName && quoteName.length >= 50 && this.handleOtherKeys(event.keyCode))) {
					event.preventDefault();
				}
				break;
			default:
				break;
		}
	}

	const handleClearCart = (isClear) => {
    //if(isClear){
      //dispatch(clearCart)
	  clearCartForStore(dispatch).then(res => {
		if(resetStockCheck){
			resetStockCheck();
		}
		addToCart();	
		handleCloseModal();	
	  });
     //addToCart();
  }    
    //} 
  const addToCart = () => { 
	if(DefaultAddItemToCart) {
		addItemToCart2(selectedAvailabilityHours, dispatch, true, user, fromPage);
		if(addedToCartOrQuote){
			addedToCartOrQuote();
		}
		//addedToCartOrQuote ? addedToCartOrQuote() : "";
	} else {
		if(addedToCartOrQuote){
			addedToCartOrQuote();
		}
    const { model, modelId, make, makeId, engine, engineId, year, internalMakeId } = mygarage.selectedVehicle || {};
		selectedAvailabilityHours = _remove(
			selectedAvailabilityHours,
			(a) => Number(a.qty) > 0
		);
		if (!_isEmpty(selectedAvailabilityHours) && user && user.userDetails) {
			const {
				userDetails: { x2userName, shipTos, billTo },
			} = user;

			addItemToCart2(selectedAvailabilityHours, dispatch, true, user, "warningpopup");
			let uniqsah = _uniqBy(selectedAvailabilityHours, 'descId');
			uniqsah.map((s, k) => {
				const payload = {
					categoryId: Number(
						_find(categoryGroupMap, (c) => {
							return _includes(
								_map(c.groups, 'code'),
								Number(s.groupId)
							);
						}).code
					),
					createdBy: x2userName,
					createdDate: timeInUTC(),
					customerId: billTo.billToId,
					engine: engine.trim(),
					engineId,
					groupId: s.groupId,
					lastOrderedDate: timeInUTC(),
					make: make.trim(),
					makeId,
					internalMakeId,
					model: model.trim(),
					modelId,
					orderedFlag: 'N',
					partTypeDesc: s.desc,
					partTypeDescId: s.descId,
					searchPartId: '',
					shipToId: shipTos[0].shipToId,
					source,
					updatedBy: x2userName,
					updatedDate: timeInUTC(),
					userFilter: 'selected',
					year: year.trim(),
				};
				dispatch(setPartHistory(payload));
			});
		}
	 }
	};


  const getMessage = () => {
	if(!_isEmpty(orderType) && (!_isEmpty(cartOrderType) && cartOrderType !== orderType)) {
		return  (
			<span className="modal_text">
				Your Cart already contains {cartOrderType === "REGULAR"?  "Regular" : "Stock Order" } items, To continue to add parts you need to clear the Cart or save the Cart as Quote
			</span>
			)
	} else {
		return  (
			<span className="modal_text">
			   Your Cart already contains parts to be <span className="maroonColorTxt bold">{displayShipMethod === '0002' ? 'Picked Up' : (displayShipMethod === 'FIWS' ? 'Drop Shipped' : 'Delivered')}</span> from <span className="maroonColorTxt bold">Store {displayStore}</span>. To continue to add parts from <span className="maroonColorTxt bold ">Store {newStore}</span> to cart for <span className="maroonColorTxt bold ">{displayShipMethod == '0002' && newShipMethod !== '0002' ? 'Delivery' : (newShipMethod === 'ECOM' ? 'Drop Ship' : 'Pick Up')}</span>, either you need to clear the Cart or save the Cart as Quote.
			</span>
			)
	}
  }
  return (
    <div>
      {openPopup && (
        <XModal
          show={openPopup}
          showButtons={false}
          title="Are you sure ?"
          className="store_waring_popup_modal"
          handleClose={handleCloseModal}
        ><div className="store_waring_popup_modal_heading">
           {getMessage()}
		  </div>
          <div className="store_waring_popup_modal_button_padding">
            <span className="clear_cart_button">
              <Button className="add_to_cart_button" onClick={() => handleClearCart(true)}>
                Delete the cart
              </Button>
            </span>
            <span className="save_as_quote_button">
              <Button
                className="add_to_cart_button"
                onClick={() => setShowQuoteModal(true)}
              >
                Save the cart as Quote
              </Button>
            </span>
          </div>
        </XModal>
      )}
      {showQuoteModal && (
					<XModal
						title="Quote Name"
						show={showQuoteModal}
						className="store-custom_xmodal quote-modal"
						handleClose={() => {
							setShowQuoteModal(false)
						}}
						handleAction={handleSaveAsQuote}
						yesButtonText="Save"
						noButtonText="Cancel"
						isValidValidity={!_isEmpty(quoteName) ? true : false}
					>
						<div className="my-1 col-sm-12" style={quoteStyle}>
							<Form.Control
								placeholder={!_isEmpty(quoteName) ? `${quoteName}` : "Quote Name"}
								className="input__default"
								name="quotename"
								maxLength="51"
								value={quoteName}
								onChange={handleQuoteNameChange}
								onBlur={handleQuoteNameBlur}
								onKeyDown={(event) => {handleKeyDown(event, 'quotename') }}
							/>
							<OverlayTrigger
								overlay={
									<Tooltip id={`tooltip`}>
										Enter Customer Name or Phone Number or
										Both or Any Text to identify this quote.
									</Tooltip>
								}
							>
								<span class="material-icons">info</span>
							</OverlayTrigger>
						</div>
						<FormValidationErrorMessage
							condition={
								!quoteNameValidity.isQuoteAlphaSpecialChar && quoteNameValidity.isQuoteNameMinLimit
							}
							errorMessage={
								'Quote name must contain alphanumeric or special characters only'
							}
						/>
						<FormValidationErrorMessage
							condition={!quoteNameValidity.isQuoteNameMinLimit && quoteNameValidity.isQuoteAlphaSpecialChar}
							errorMessage={
								'Quote name should have atleast 1 character'
							}
						/>
						<FormValidationErrorMessage
							condition={
								!quoteNameValidity.isQuoteNameMaxLimit &&
								quoteNameValidity.isQuoteNameMinLimit
							}
							errorMessage={
								'Quote name should not exceed 50 characters'
							}
						/>
					</XModal>
				)}
    </div>
  );
};
export default AddToCartWarningPopup;
