/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";
import EnhancedTableHead from "./head";
import _ from "lodash";

import "./styles.scss";
import { currencyFormatter, getYearfromDate } from "../../helpers/utils";

const ERROR_MSG = "The amount entered is above the invoice amount due.";
const ERROR_MSG_NEGATIVE = "The mount entered should be greater than 0.";

const ResultsLoader = () => {
  return <React.Fragment>
    <div>
      <CircularProgress/>
      <div>Loading Results</div>
    </div>
  </React.Fragment>
}

const EnhancedTable = ({
  rows,
  handleSelected,
  selected,
  isUploaded,
  handleOverrideChange,
  customerNumber,
  isLoading,
  handleChange,
}) => {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("isMatched");
  // const [selected, setSelected] = React.useState([]);
  const [page] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage] = React.useState(-1);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event, checked) => {
    if (event.target.checked || checked) {
      handleSelected(rows);
      return;
    }
    handleSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = _.map(selected, "invoiceNumber").indexOf(
      name.invoiceNumber
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    handleSelected(newSelected);
  };

  // const isSelected = (name) => selected.indexOf(name) !== -1;
  const isSelected = (row) => {
    let bool = false;
    selected.forEach((s) => {
      if (s.invoiceNumber === row.invoiceNumber) {
        bool = true;
      }
    });

    return bool;
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const headCells = [
    {
      label: "Invoice Date",
      id: "invoiceDate",
      numeric: false,
      disablePadding: true,
      hidden: false,
    },
    {
      label: "Invoice #",
      id: "invoiceNumber",
      numeric: false,
      disablePadding: true,
      hidden: false,
    },
    {
      label: "Order #",
      id: "orderNumber",
      numeric: false,
      disablePadding: true,
      hidden: false,
    },
    {
      label: "Invoice Amount",
      id: "invoiceAmount",
      numeric: true,
      disablePadding: true,
      hidden: false,
    },
    {
      label: "Amount Due",
      id: "invoiceAmountDue",
      numeric: true,
      disablePadding: true,
      hidden: false,
    },
    {
      label: "Amount",
      caption: "I want to pay",
      id: "invoiceAmountDue",
      numeric: false,
      disablePadding: true,
      hidden: false,
    },
    {
      label: "Uploaded Amount",
      id: "uploadedAmount",
      numeric: false,
      disablePadding: true,
      hidden: !isUploaded,
    },
    {
      label: "Override",
      id: "amountType",
      numeric: false,
      disablePadding: true,
      hidden: !isUploaded,
    },
  ];

  const renderInvoiceLink = (row) => {
    const year = getYearfromDate(row.invoiceDate);
    const oneFlowLink = `https://oneflow.thepartshouse.com/XLP-SE/WebDoc.php?ACTION=get_trans_doc&CAB_NAME=AR_VISION_${year}&DOC=INV&CUSTNUM=${customerNumber}&INV=${row.invoiceNumber}`;

    return oneFlowLink;
  };

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            isUploaded={isUploaded}
          />
          <TableBody>
            {visibleRows.length > 0 && !isLoading ? (
              visibleRows
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = row.isMatched ? true : isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const useUploadedAmount = isUploaded
                    ? Number(row.uploadedAmount) < Number(row.invoiceAmountDue)
                    : false;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.invoiceNumber}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          disabled={row.error}
                        />
                      </TableCell>
                      {}
                      <TableCell
                        align="center"
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.invoiceDate}
                      </TableCell>
                      <TableCell align="center">
                        <a
                          href={renderInvoiceLink(row)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {row.masterInvoiceNumber || row.invoiceNumber}
                        </a>
                      </TableCell>
                      <TableCell align="center">{row.orderNumber}</TableCell>
                      <TableCell
                        align="right"
                        className="bold"
                      >{`${currencyFormatter(row.invoiceAmount)}`}</TableCell>
                      <TableCell
                        align="right"
                        className="bold"
                      >{`${currencyFormatter(row.invoiceAmountDue)}`}</TableCell>
                      <TableCell align="center" className="bold">
                        <FormControl
                          error={row.error || false}
                          sx={{ m: 1, width: "15ch" }}
                          variant="outlined"
                        >
                          <TextField
                            size="small"
                            id="amount-to-pay"
                            label="Amount"
                            defaultValue={`${
                              row.considerUploadedAmount
                                ? `${row.uploadedAmount}`
                                : `${row.invoiceAmountDue}`
                            }`}
                            onChange={(e) => handleChange(e, row)}
                            startAdornment={
                              <InputAdornment position="end">$</InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "amount-to-pay",
                              "className": "text-align-right"
                            }}
                            disabled={Number(row.invoiceAmountDue) < 0}
                          />
                          <FormHelperText
                            hidden={!row.error}
                            id="component-error-text"
                          >
                            {Number(row.uploadedAmount) < 0
                              ? ERROR_MSG_NEGATIVE
                              : ERROR_MSG}
                          </FormHelperText>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        align="center"
                        className="bold"
                        hidden={!isUploaded}
                      >{`${
                        isUploaded && useUploadedAmount
                          ? `$${row.uploadedAmount}`
                          : `$${row.invoiceAmountDue}`
                      }`}</TableCell>
                      <TableCell
                        align="center"
                        className="bold"
                        hidden={!isUploaded}
                      >
                        <FormControl fullWidth>
                          <NativeSelect
                            defaultValue={`${
                              isUploaded && row.considerUploadedAmount
                                ? "uploadedAmount"
                                : "amountDue"
                            }`}
                            inputProps={{
                              name: "override",
                              id: "uncontrolled-native",
                            }}
                            value={`${
                              isUploaded && row.considerUploadedAmount
                                ? "uploadedAmount"
                                : "amountDue"
                            }`}
                            onChange={(e) => handleOverrideChange(e, row)}
                            disabled={!row.isMatched}
                          >
                            <option value={"amountDue"}>Use Amount Due</option>
                            <option value={"uploadedAmount"}>
                              Use Uploaded Amount
                            </option>
                          </NativeSelect>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} style={{ textAlign: "center" }}>
                {isLoading && visibleRows.length === 0 ? <ResultsLoader/> : !isLoading && visibleRows.length === 0 ? "No Results Found" : <ResultsLoader/>}
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: "All" }]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
};

export default EnhancedTable;
