import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import clsx from "clsx";
import React from "react";
import { isFirefox } from "react-device-detect";

const PopperMenuList = (props) => {
  const {
    list,
    open,
    anchorRef,
    handleListKeyDown,
    handleMenuItemClicked,
    handleClose,
    id,
    paperStyles,
    menuStyles,
    menuListRef,
    selectedValue,
    popperStyles,
  } = props;

  console.log("is firefox ==> ", isFirefox);

  return (
    <React.Fragment>
      <Popper
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-start"
        className={clsx("paper-wrapper", { firefoxGrid: isFirefox })}
        transition
        disablePortal
        style={popperStyles}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper style={paperStyles}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={false}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  role="menu"
                  style={menuStyles}
                  ref={menuListRef}
                  variant="menu"
                >
                  {list &&
                    list.map((option, i) => {
                      return (
                        <MenuItem
                          onClick={(e) => handleMenuItemClicked(e, option, id)}
                          id={option.label}
                          data-id={i}
                          selected={
                            selectedValue === option.label ? true : false
                          }
                        >
                          {option.label}
                        </MenuItem>
                      );
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default PopperMenuList;
