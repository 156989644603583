import React, { Fragment } from "react";
import {
  Button,
  Row,
  Container,
  Col,
  Nav,
  Navbar,
  Image,
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import logo from "../static/images/TPH Rapid.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    let { redirectToHomepage, hasError } = this.state;
    if (redirectToHomepage) {
      return (
        <Redirect
          to={{
            pathname: "/product-list",
          }}
        />
      );
    }
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Fragment>
          <section className="section__navigation">
            <Container className="p-0">
              <Navbar expand="lg" className="p-0">
                <Navbar.Brand>
                  <a haref="/">
                    <Image src={logo} />
                  </a>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="xl-main-navigation">
                  <Nav className="mr-auto"></Nav>
                </Navbar.Collapse>
              </Navbar>
            </Container>
          </section>
          <Container>
            <Row>
              <Col md={{ span: 4, offset: 4 }}>
                <span
                  className="maroonColorTxt bold "
                  style={{ fontSize: "2.0em" }}
                >
                  Oops, something went wrong
                </span>
                <Button
                  className="add-to-cart-btn"
                  onClick={() => {
                    document.location.href = "/";
                  }}
                >
                  Go back to Homepage
                </Button>
              </Col>
            </Row>
          </Container>
        </Fragment>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
