import React, { Fragment } from 'react';
import {
	Form,
	Button,	
	Spinner,
} from 'react-bootstrap';
import {
	validateOnlineIdForm,
	noSpecialCharsRegExp,
	decimalNumbersOnlyRegExp,
} from '../../../helpers/utils';
import { getAllCustomers} from '../UserManagement/usermanagement.thunk';
import { createSuperAdmin } from '../../../actions/user';
import { connect } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _every from 'lodash/every';
import {
    updateToken,
   
} from '../../../actions/auth';


import FormValidationErrorMessage from '../../shared/FormValidationErrorMessage/FormValidationErrorMessage';

class CreateSuperAdmin extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			formObject: this.initializeForm(),
			validity: this.intializeValidations(),
			errorPopUp: false,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	
	}

	initializeForm() {
		this.formObject = {
			
			xlpAccNo: '',
			xlpLastInvoiceNo: '',
			xlpLastInvoiceAmount: '',
		
		};
		return this.formObject;
	}

	intializeValidations() {
		this.validity = {
			isXlpAccNoNotNull: true,
			isXlpAccNoAlphanumeric: true,
			isXlpAccNoLengthBelowLimit: true,
			isXlpAccNoMinLength: true,
			isValidXlpAccNo: true,
			isXlpLastInvoiceNoAlphanumeric: true,
			isInvoiceAmountNotNull: true,
			isInvoiceNoNotNull: true,
			isXlpLastInvoiceNoLengthBelowLimit: true,
			isXlpLastInvoiceNoMinLength: true,
			isInvoiceAmtLengthBelowLimit: true,
			isInvoiceAmountNumeric: true,
			isValidInvoiceAmount: true,
		
		};
		return this.validity;
	}

	componentDidUpdate(prevProps) {
		let {
			formObject,
			formErrorMessage,
			errorPopUp,
		} = this.state;
		let {
			dispatch
		} = this.props;
		let stateChanged = false;
		if (!_isEqual(this.props, prevProps)) {
			if (!_isEqual(this.props.customerCreationApiCall,prevProps.customerCreationApiCall)) 
			 { 
				 stateChanged = true;
			 }
			
				if (this.props.userCreation === 'success' && (!this.props.customerCreationApiCall)) {               
                    formObject.xlpLastInvoiceNo ='';
                    formObject.xlpLastInvoiceAmount='';
                    formObject.xlpAccNo = '';
                    this.setState({
                      ...this.state,
                      formObject,
          
                    });
					stateChanged = true;
					updateToken(dispatch);
					dispatch(getAllCustomers());				
				}
			
         
			if (stateChanged) {
				this.setState({
					...this.state,
					formObject: {
						...this.state.formObject,
						...formObject,
					},
					formErrorMessage,
					errorPopUp,
				});
			}
		}
	}

	validateForm() {
		let {
			formObject: {
			
				xlpAccNo,
				xlpLastInvoiceNo,
				xlpLastInvoiceAmount,
			
			},
			validity,
		} = this.state;
	
		validity = validateOnlineIdForm('xlpAccNo', xlpAccNo, validity);
		validity = validateOnlineIdForm(
			'xlpLastInvoiceNo',
			xlpLastInvoiceNo,
			validity
		);
		validity = validateOnlineIdForm(
			'xlpLastInvoiceAmount',
			xlpLastInvoiceAmount,
			validity
		);
	
		this.setState({ ...this.state, validity });
		return _every(validity);
	}

	handleSubmit(e) {
		e.preventDefault();
		let {
			formObject: {
			
				xlpAccNo,
				xlpLastInvoiceNo,
				xlpLastInvoiceAmount,
			
			},
		} = this.state;
		let { dispatch } = this.props;
		if (this.validateForm()) {
			let userObj = {
				invoiceAmount: xlpLastInvoiceAmount,
				invoiceNumber: xlpLastInvoiceNo,
				xlPartAccountNumber: xlpAccNo,
			};
			createSuperAdmin(userObj, dispatch);
		}
	}

	handleInputChange(e) {
		const { name, value } = e.target;
		let { formObject, validity } = this.state;
		const formValue = value.charAt(0);
		let str;
		if (formValue === ' ') {
			str = value.trim();
		} else {
			str = value;
			if(name === "confirmEmail"){
				validity.isValidConfirmEmail = _isEqual(formObject.email, str);
				validity = validateOnlineIdForm(name, str, validity);
			}else{
				validity = validateOnlineIdForm(name, str, validity);
			}
		}
		formObject[name] = (name === 'xlpAccNo' || name === 'xlpLastInvoiceNo') ? str.toUpperCase() : str;
		this.setState({
			formObject: {
				...this.state.formObject,
				...formObject,
			},
			validity: {
				...this.state.validity,
				...validity,
			},
			...this.state,
		});
	}


	handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
		return (
			code !== 8 &&
			code !== 9 &&
			code !== 37 &&
			code !== 38 &&
			code !== 39 &&
			code !== 40 &&
			code !== 46
		);
	};

	handleKeyDown = (event, name) => {
		const {
			formObject: {
			
				xlpAccNo,
				xlpLastInvoiceNo,
				xlpLastInvoiceAmount,
				
			},
		} = this.state;
		switch (name) {
			
			
			case 'xlpAccNo':
				if (
					(noSpecialCharsRegExp(event.key) ||
						event.keyCode === 32 ||
						(xlpAccNo && xlpAccNo.length >= 10)) &&
					this.handleOtherKeys(event.keyCode)
				) {
					event.preventDefault();
				}
				break;
			case 'xlpLastInvoiceNo':
				if (
					(noSpecialCharsRegExp(event.key) ||
						event.keyCode === 32 ||
						(xlpLastInvoiceNo && xlpLastInvoiceNo.length >= 10)) &&
					this.handleOtherKeys(event.keyCode)
				) {
					event.preventDefault();
				}
				break;
			case 'xlpLastInvoiceAmount':
				if (
					(decimalNumbersOnlyRegExp(event.key) === false ||
						event.keyCode === 32 ||
						(xlpLastInvoiceAmount &&
							xlpLastInvoiceAmount.length >= 9)) &&
					this.handleOtherKeys(event.keyCode)
				) {
					event.preventDefault();
				}
				break;
			
			default:
				break;
		}
	};

	render() {
		const {
            validity,
			formObject: {
			
				xlpAccNo,
				xlpLastInvoiceNo,
				xlpLastInvoiceAmount,
			
			},
			
		} = this.state;
        const {
            userDetails: {
              userType,
            },
          } = this.props;
		
		return (
            <div className="manage-users-container">
            { ( userType === "CUSTOMER_ADMIN" || userType === 'SUPER_ADMIN') && (
					<Fragment>
						<p className="label-input-msg-container">You can associate other 
						 <span className="user_management_label_color">
						  The Parts House
                        </span> Accounts with your Login. Please fill the form below.<br></br>
							You acknowledge that the Customer Account you are about to associate with your Login is a Business Entity <br></br>
							that you are legally associated with and that the information you are providing below is true and correct.
						</p>
						<div className="label-input-container">
							<label htmlFor="xlpAccNo">
							The Parts House Customer Number
							</label>
							<div>
								<Form.Control
									type="text"
									onChange={this.handleInputChange}
									onKeyDown={(event) => {
										this.handleKeyDown(event, 'xlpAccNo');
									}}
									name="xlpAccNo"
									id="xlpAccNo"
									value={xlpAccNo}
									maxLength="10"
								//  placeholder="Enter your XL Parts Accounts No"
								/>
								<FormValidationErrorMessage
									condition={!validity.isXlpAccNoNotNull}
									errorMessage="Please enter your Customer number"
								/>
								<FormValidationErrorMessage
									condition={
										!validity.isXlpAccNoMinLength &&
										validity.isXlpAccNoNotNull
									}
									errorMessage="Customer number should be atleast 1 characters"
								/>
								<FormValidationErrorMessage
									condition={
										!validity.isXlpAccNoAlphanumeric &&
										validity.isXlpAccNoNotNull
									}
									errorMessage="Customer number must be alphanumeric characters only"
								/>
								<FormValidationErrorMessage
									condition={
										!validity.isXlpAccNoLengthBelowLimit &&
										validity.isXlpAccNoNotNull
									}
									errorMessage="Customer number should not exceed 10 characters"
								/>
							</div></div>
						<div className="label-input-container">
							<label htmlFor="xlpLastInvoiceNo">
							Last The Parts House Invoice Number
							</label>
							<div>
								<Form.Control
									type="text"
									onChange={this.handleInputChange}
									onKeyDown={(event) => {
										this.handleKeyDown(
											event,
											'xlpLastInvoiceNo'
										);
									}}
									name="xlpLastInvoiceNo"
									id="xlpLastInvoiceNo"
									value={xlpLastInvoiceNo}
									maxLength="10"
								//  placeholder="Enter Your Last XL Parts Invoice Number"
								/>
								<FormValidationErrorMessage
									condition={!validity.isInvoiceNoNotNull}
									errorMessage="Please enter your Invoice Number"
								/>
								<FormValidationErrorMessage
									condition={
										!validity.isXlpLastInvoiceNoMinLength &&
										validity.isInvoiceNoNotNull &&
										validity.isXlpLastInvoiceNoLengthBelowLimit
									}
									errorMessage="Invoice number should be atleast 10 characters"
								/>
								<FormValidationErrorMessage
									condition={
										!validity.isXlpLastInvoiceNoLengthBelowLimit &&
										validity.isXlpLastInvoiceNoMinLength
									}
									errorMessage="Invoice number should not exceed 10 characters"
								/>
								<FormValidationErrorMessage
									condition={
										!validity.isXlpLastInvoiceNoAlphanumeric &&
										validity.isXlpLastInvoiceNoMinLength
									}
									errorMessage="Invoice number must contain alphanumeric characters only"
								/></div></div>
						<div className="label-input-container">
							<label htmlFor="xlpLastInvoiceAmount">
							Last The Parts House Invoice Amount
							</label>
							<div>
								<Form.Control
									type="text"
									onChange={this.handleInputChange}
									onKeyDown={(event) => {
										this.handleKeyDown(
											event,
											'xlpLastInvoiceAmount'
										);
									}}
									name="xlpLastInvoiceAmount"
									id="xlpLastInvoiceAmount"
									value={xlpLastInvoiceAmount}
									maxLength="9"
								//  placeholder="Enter Your Last XL Parts Invoice Amount"
								/>
								<FormValidationErrorMessage
									condition={!validity.isInvoiceAmountNotNull}
									errorMessage="Please enter your Invoice Amount"
								/>
								<FormValidationErrorMessage
									condition={
										!validity.isInvoiceAmtLengthBelowLimit &&
										validity.isInvoiceAmountNotNull &&
										validity.isInvoiceAmountNumeric
									}
									errorMessage="Invoice Amount should not exceed 9 numbers"
								/>
								<FormValidationErrorMessage
									condition={
										!validity.isInvoiceAmountNumeric &&
										validity.isInvoiceAmountNotNull
									}
									errorMessage="Invoice Amount must be numeric only"
								/></div></div>

						{(!this.props.customerCreationApiCall) ? (

							<Button
								style={{ marginLeft: "200px" }}
								type="submit"
								className="xlp-submit-btn"
								disabled={this.props.customerCreationApiCall || !_every(validity)}
								onClick={this.handleSubmit}
							>
								OK
							</Button>
						) : (
							<Button
								style={{ marginLeft: "200px" }}
								className="xlp-submit-btn"
								variant="primary"
								disabled
							>
								<Spinner
									as="span"
									animation="grow"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
								Loading...
							</Button>
						)}


					</Fragment>

				)}

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
        userDetails: state.user.userDetails,
		userCreation: state.user.userCreationSuccess,
		customerCreationApiCall: state.user.customerCreationApiCall,
		messageFromApi: state.user.messageFromAPi,
	};
}
export default connect(mapStateToProps)(CreateSuperAdmin);
