import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Modal, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _uniqBy from "lodash/uniqBy";
import _find from "lodash/find";
import _map from "lodash/map";
import _includes from "lodash/includes";
import _sumBy from "lodash/sumBy";
import _isEqual from "lodash/isEqual";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  formatAmount,
  getExtendedTotalPrice,
  getDistributionForVehicle,
  distributeAvailability2,
  getPartPackQty,
  getImageURLCookieDetails,
  formatTimeAvb,
  getShipInfo,
  displayQtyChangeMsg,
  setDefalutProductQuantity,
  isInputBlank,
  isValidPositiveNumber,
  isValidAvailableQty,
  getSellerInfo
} from "../../../helpers/utils";
import _cloneDeep from "lodash/cloneDeep";
import AddToCart from "../../shared/AddToCart/AddToCart";
import ImagesSlider from "../../Listing/ImagesSlider/index";
import { triggerCustomUxCriticalError } from "../../../actions/error";
import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import AvailabilityStoreView from "../../shared/AvailabilityStoreView";
import Links from "./Links"
import './styles.scss';
const actionTypes = {
  SELECTED_ADDCART_DATA: "SELECTED_ADDCART_DATA",
};

const Part = (props) => {
  const dispatch = useDispatch();
  const [orderArray, setOrderArray] = useState([]);
  const [singlePartData, setSinglePartData] = useState([]);
  const [totalOrderQty, setTotalOrderQty] = useState(null);
  const [showImagesSlider, setShowImagesSlider] = useState(false);
  const userDetails = useSelector((state) => state.user.userDetails);
  const shippingInformation = useSelector((state) => state.expressCart.shippingInfo);
  const imageURLCookie = getImageURLCookieDetails();
  const cart = useSelector((state) => state.cart);
  const selectedVehicle = props.nodeData.selectedVehicle;
  const parts = useSelector((state) => state.parts);
  const categoryGroupMap = useSelector((state) => state.parts.categoryGroupMap);
  const [showAvailabilityStoreView, setShowAvailabilityStoreView] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const hideAllPrice =  parts  && parts.hidePrice;
  const [validity, setValidity] = useState({
    doesQtyExist: true,
    isQtyMoreThanOne: true,
    isQtyLessThanAvailable: true,
  });

  const styles = theme => ({
    card: {
      width: 300,
      margin: 'auto'
    },
    media: {
      height: 100,
      width: '100%',
      objectFit: 'cover'
    }
  });

  let ismobileEnabled = userDetails?.shipTos[0]?.isMobileEnabled;
  const isAltType = props.part.partType && props.part.partType === "Alternate";
  const isSubType = props.part.partType && props.part.partType === "Superseded";
  let partsQtySum = _sumBy(
    _uniqBy(props.part.pricingAvailability.availability, "availableDuration"),
    "availableQty"
  );
  let preveousOrderQty;
  // let catalogFilter = _cloneDeep(parts.catalogFilter);
  useEffect(() => {
    let { part } = props;
    let defualtTotalQuantity = setDefalutProductQuantity();
    if (!_isEmpty(part) && defualtTotalQuantity) {
      if (part && !_isEmpty(part.pricingAvailability)) {
        handleInputChange();
      }
    }
  }, []);

  const onclick = (tempData) => {
    setStoreData(tempData)
    setShowAvailabilityStoreView(true)
  }

  const checkIfMobileEnabled = (tempData, text, linkColor) => {
    return (
      ismobileEnabled ?
        <u className={linkColor ? "cursor-pointer": "text-color"} onClick={() => { onclick(tempData) }}>{text}{" "}</u> :
        <u>{text}</u>
    )
  }

  const getDistributedAvailability = (partDetails) => {
    let availabilities = partDetails.pricingAvailability.availability;
    // eslint-disable-next-line no-lone-blocks
    let returnArray = [];
    availabilities.map((singleAvailability, key) => {
      if (singleAvailability.availableQty > 0) {
        if (
          singleAvailability.availableDuration === "Next Business Day" ||
          singleAvailability.availableDuration === "Next 2 + Business Days" ||
          singleAvailability.availableDuration === "Next 2 Weeks"
        ) {
          returnArray.push(
            <ul>
              <li>
                <span className="text-white">
                  <span className="store-maroon-text">
                    {singleAvailability.availableQty}
                  </span>
                  &nbsp;
                  {checkIfMobileEnabled(singleAvailability?.storeAvailabilities, singleAvailability.availableDuration, "link-color")}
                </span>
              </li>
            </ul>
          );
        } else {
          singleAvailability.storeAvailabilities.map((storeData, storeKey) => {
            if (key != 0) {
              returnArray.push(
                <ul>
                  <li>
                    <span className="text-white">
                      <b>{storeData.storeQty}</b>
                    </span>
                    <span className="text-white">
                      &nbsp;{` at Store #`}
                      <b>{storeData.store}</b>
                      <br />
                      {singleAvailability.availableDuration ===
                        "Direct Order Store" ||
                      singleAvailability.availableDuration ==
                        "Pick Up Store" ? (
                        <b>
                          ({singleAvailability.availableDuration},
                          {storeData.storeQualifier
                            ? storeData.storeQualifier
                            : ""}
                          )
                        </b>
                      ) : null}
                    </span>
                  </li>
                </ul>
              );
            }
          });
        }
      }
    });
    let fullDiv = [];
    fullDiv = <span>{returnArray}</span>;
    return fullDiv;
  }

  const renderAvailability = () => {
    let partDetails = !_isEmpty(props.part) && props.part;
    let totalAvailqty = 0;
    let defualtTotalQuantity = setDefalutProductQuantity();
    // Temporary fix -- Should be removed
    if (
      !_isEmpty(partDetails) &&
      partDetails.pricingAvailability &&
      partDetails.pricingAvailability.availability.length > 0
    ) {
      let singleAvailability = partDetails.pricingAvailability.availability[0];
      partDetails.pricingAvailability.availability.map((singleAvailability, key) => {
        if (!_isEmpty(singleAvailability) && singleAvailability.availableQty > 0) {
          totalAvailqty += singleAvailability.availableQty;
      } });
      let isDefaultUser  = getSellerInfo(userDetails) ? false : true;
      //totalAvailqty += singleAvailability.availableQty;
      return partDetails.description != "" && partDetails.mfgName != "" ? (
        <React.Fragment>
          <Row>
           {isDefaultUser && (<div className="ml-3 express_section mb-3">
              <span className="mr-5"><Links nodeData={props.nodeData} part={!_isEmpty(props.part) && props.part} shippingInfo={shippingInformation} /></span>
              <div className="availability-container express-availability-container">
                <div className="availability-subdiv">
                  <ul>
                    <li>
                      {!_isEmpty(singleAvailability) &&
                       singleAvailability.availableTime === null ||
                        singleAvailability.availableTime === "null" ||
                        singleAvailability.availableDuration === "Same Day" || singleAvailability.availableDuration === "Store"
                        (singleAvailability.availableDuration === "Immediately" &&
                          singleAvailability.code === 0) || (singleAvailability.code === -50) ? (
                        <span className="text-black">
                          { singleAvailability.availableQty == 0 ? (
                          <span className="store-maroon-text compact-text-grey-Zero">
                            <b>{ singleAvailability.availableQty}</b>
                          </span>
                        ) : (
                          <span className="store-maroon-text compact-text-grey">
                            <b>{ singleAvailability.availableQty}</b>
                          </span>
                        )}
                         &nbsp;at &nbsp;
                          {singleAvailability.availableDuration }&nbsp;
                          {singleAvailability.storeAvailabilities.length > 0 &&
                          singleAvailability.storeAvailabilities[0].store &&
                          singleAvailability.storeAvailabilities[0].store.length > 13 ? (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 1500 }}
                            overlay={
                              <Tooltip
                                id="toggle-view-full-compact"
                                className="custom-tooltip-availability"
                              >
                                <b>#{singleAvailability.storeAvailabilities[0].store}</b>
                              </Tooltip>
                            }
                          >
                            <span>
                              {!_isEmpty(singleAvailability) &&
                              singleAvailability.storeAvailabilities.length > 0 &&
                                singleAvailability.storeAvailabilities[0].store ? (
                                <span>
                                  <b>
                                    #
                                    {singleAvailability.storeAvailabilities[0].store &&
                                      singleAvailability.storeAvailabilities[0].store.length >
                                      13
                                      ? singleAvailability.storeAvailabilities[0].store.substring(
                                        0,
                                        11
                                      ) + ".."
                                      : singleAvailability.storeAvailabilities[0].store}
                                  </b>
                                </span>
                              ) : null}
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <span>
                            {!_isEmpty(singleAvailability) && singleAvailability.storeAvailabilities.length > 0 &&
                              singleAvailability.storeAvailabilities[0].store ? (
                              <span>
                                <b>
                                  #
                                  {singleAvailability.storeAvailabilities[0].store &&
                                    singleAvailability.storeAvailabilities[0].store.length >
                                    14
                                    ? singleAvailability.storeAvailabilities[0].store.substring(
                                      0,
                                      11
                                    ) + ".."
                                    : singleAvailability.storeAvailabilities[0].store}
                                </b>
                              </span>
                            ) : null}
                          </span>
                        )}
                        </span>
                      ) : (
                        <span className="text-black">
                          <span className="maroonColorTxt">
                            <b>{singleAvailability.availableQty}</b>{" "}
                          </span>{" "}
                          {checkIfMobileEnabled(singleAvailability?.storeAvailabilities, "Within", "link-color")}{" "}
                          {formatTimeAvb(singleAvailability.availableDuration)}{" "}
                        </span>
                      )}
                    </li>
                    {singleAvailability.availableQty == 0 ? (<li>
                    <span className="text-black">
                    <span className="store-maroon-text compact-text-green">
                      <b>{totalAvailqty}</b>
                    </span>
                    &nbsp;at Other Stores &nbsp;{" "}
                  </span>
                   </li>) : null}
                  </ul>
                </div>
                {partDetails.pricingAvailability.availability.length > 1 ? (
                  <React.Fragment>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 100, hide: 1500 }}
                      overlay={
                        <Tooltip
                          id="toggle-view-full-compact"
                          className="custom-tooltip-availability"
                        >
                          {getDistributedAvailability(partDetails)}
                        </Tooltip>
                      }
                    >
                      <a className="maroonColorTxt info-icon">
                        <i class="fa fa-info-circle"></i>
                      </a>
                    </OverlayTrigger>
                  </React.Fragment>
                ) : (
                  ""
                )}
                <div>
                </div>
              </div>
           </div>)}
           {!isDefaultUser && (  <div className="ml-3 express_section mb-3">
              <span className="mr-5"><Links nodeData={props.nodeData} part={props.part} shippingInfo={shippingInformation} /></span>
              <div className="availability-container express-availability-container">
                <div className="availability-subdiv">
                  <ul>
                    <li>
                      {singleAvailability.availableTime === null ||
                        singleAvailability.availableTime === "null" ||
                        singleAvailability.availableDuration === "Same Day" ||
                        (singleAvailability.availableDuration === "Immediately" &&
                          singleAvailability.code === 0) ? (
                        <span className="text-black">
                          <span className="maroonColorTxt">
                            <b>{singleAvailability.availableQty}</b>{" "}
                          </span>
                          {checkIfMobileEnabled(singleAvailability?.storeAvailabilities, singleAvailability.availableDuration, "link-color")}
                        </span>
                      ) : (
                        <span className="text-black">
                          <span className="maroonColorTxt">
                            <b>{singleAvailability.availableQty}</b>{" "}
                          </span>{" "}
                          {checkIfMobileEnabled(singleAvailability?.storeAvailabilities, "Within", "link-color")}{" "}
                          {formatTimeAvb(singleAvailability.availableDuration)}{" "}
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
                {partDetails.pricingAvailability.availability.length > 1 ? (
                  <React.Fragment>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 100, hide: 1500 }}
                      overlay={
                        <Tooltip
                          id="toggle-view-full-compact"
                          className="custom-tooltip-availability"
                        >
                          {partDetails.pricingAvailability.availability.map((singleAvailability, key) => {
                            if (singleAvailability.availableQty > 0) {
                              totalAvailqty += singleAvailability.availableQty;
                              return (
                                <ul>
                                  {key !== 0 ? (
                                    <li>
                                      {singleAvailability.availableTime === null ||
                                        singleAvailability.availableTime === "null" ||
                                        singleAvailability.availableDuration ===
                                        "Same Day" ||
                                        (singleAvailability.availableDuration ===
                                          "Immediately" &&
                                          singleAvailability.code === 0) ? (
                                        <span className="text-white">
                                          <span className="font-weight-bold">
                                            {singleAvailability.availableQty}{" "}
                                          </span>
                                          {checkIfMobileEnabled(singleAvailability?.storeAvailabilities, singleAvailability.availableDuration)}
                                        </span>
                                      ) : (
                                        <span className="text-white">
                                          <span className="font-weight-bold">
                                            {singleAvailability.availableQty}{" "}
                                          </span>{" "}
                                          {checkIfMobileEnabled(singleAvailability?.storeAvailabilities, "Within")}
                                          {formatTimeAvb(singleAvailability.availableDuration)}{" "}
                                        </span>
                                      )}
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              );
                            }
                          })}
                        </Tooltip>
                      }
                    >
                      <a className="maroonColorTxt info-icon">
                        <i class="fa fa-info-circle"></i>
                      </a>
                    </OverlayTrigger>
                  </React.Fragment>
                ) : (
                  ""
                )}
                <div>
                </div>
              </div>
            </div>)}
          </Row>
          <Row>
            <div className="add_to_cart_section left-margin">
              <span>
                <span>Total </span>
                <span>QTY:</span>
              </span>
              <input
                type="text"
                name="totalQty"
                defaultValue={
                  defualtTotalQuantity ? partDetails?.pricingAvailability?.qtyBuyInc > 1 ? partDetails?.pricingAvailability?.qtyBuyInc : partDetails?.perCarQty : ''
                }
                disabled={totalAvailqty === 0}
                value={totalOrderQty}
                className="qty_input"
                onChange={(e) => handleInputChangeValue(e)}
                onBlur={(e) => handleInputChange(e)}
                onMouseLeave={(e) => handleInputChange(e)}
              // onFocus={(e) => this.handleFocusChange(e)}
              // onKeyDown={(event) => {
              //   this.handleKeyDown(event);
              // }}
              />
              <div className="m-1">

                <AddToCart
                  description={props.part.description}
                  disabled={
                    _isEmpty(props.part.pricingAvailability.availability) ||
                    !_every(validity) ||
                    totalAvailqty === 0
                  }
                  selectedAvailabilityHours={prepareOrderRequestData(
                    singlePartData
                  )}
                  validity={validity}
                  groupNumber={props.part.groupNumber}
                  source={"partCategory"}
                  buttonStyle={"express-add-to-cart-btn"}
                  fromPage="express"
                />
              </div>
              {!hideAllPrice  && (
                <div>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 100, hide: 400 }}
                    overlay={
                      <Tooltip
                        id="extended-price-tooltip"
                        className="tooltip-description-custom-corePrice"
                      >
                        <span className="font-weight-bold">
                          Total Extended Price = Qty * (Your Price + Core Price)
                        </span>
                      </Tooltip>
                    }
                  >
                    <span>Total Extended Price : </span>
                  </OverlayTrigger>
                  <span
                    className={""}
                  >
                    $
                    {formatAmount(
                      getExtendedTotalPrice(
                        props.part.pricingAvailability.corePrice,
                        props.part.pricingAvailability.costPrice,
                        totalOrderQty
                      )
                    )}
                  </span>
                </div>
              )}
            </div>
          </Row>
          <FormValidationErrorMessage
            condition={
              !validity.isQtyLessThanAvailable &&
              validity.doesQtyExist &&
              validity.isQtyMoreThanOne
            }
            errorMessage={`Please enter a quantity less than ${partsQtySum + 1}`}
          />
          <span className="express-view-cartText maroonColorTxt">
            {checkIsPartInCart(partDetails.partNumber)}
          </span>
        </React.Fragment>
      ) : (
        <span></span>
      );
    } else {
      return partDetails.mfgName != "" || partDetails.description != "" ? (
        <div>
          <div className="add_to_cart_out">
            <span>
              <b>Currently out of stock</b>
            </span>
          </div>
        </div>
      ) : (
        <span></span>
      );
    }
  };

  const qtyByINCUpdateSuccessMessage = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = displayQtyChangeMsg();
    statusMessageProps.type = "success";
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  };


  const checkIsPartInCart = (partNum) => {
    let str = "";
    let qty = 0;
    let count = 0;
    cart.cartAllDetails.map((item) => {
      if (item.partNum === partNum) {
        qty += item.qty;
        count = qty;
        str = qty + ` in Cart`;
      }
    });
    return str;
  };

  const validateQty = (quantity, availableQuantity, validity) => {
    validity.doesQtyExist = !isInputBlank(quantity);
    validity.isQtyMoreThanOne = isValidPositiveNumber(quantity);
    validity.isQtyLessThanAvailable = isValidAvailableQty(
      quantity,
      availableQuantity
    );
    return validity;
  }

  const handleInputChangeValue = (event) => {
    let value;
    if (!_isEmpty(event)) {
      value = event.target.value;
    }
    setTotalOrderQty(Number(value));
    preveousOrderQty = Number(value);
    setValidity(validateQty(value, partsQtySum, validity));
  }

  const handleInputChange = (event) => {
    let {
      part
    } = props;

    let value;

    if (!_isEmpty(event)) {
      value = event.target.value;
    } else {
      value =
        part && part.pricingAvailability.qtyBuyInc > 1
          ? part.pricingAvailability.qtyBuyInc
          : part.perCarQty;
      value = Number(value);
    }
    setTotalOrderQty(value)
    if (!_isEmpty(event)) {
      if (!_isEmpty(value)) {
        let packQty = getPartPackQty(
          part.pricingAvailability.qtyBuyInc,
          Number(value),
          partsQtySum
        );
        setTotalOrderQty(packQty)
        if (
          packQty != totalOrderQty &&
          part.pricingAvailability.qtyBuyInc > 1
        ) {
          qtyByINCUpdateSuccessMessage();
          //preveousOrderQty = totalOrderQty;
        }
      } else {
        setTotalOrderQty(Number(value))
        //preveousOrderQty = Number(value);
      }
    }
    setValidity(validateQty(value, partsQtySum, validity));
    let distributionData = {};
    let selectedAvailabilityHours = [];
    let selectedAvailabilityHoursForPropack = [];
    let singlePartData = [];
    let clonePart = part;
    clonePart.qty = value;
    singlePartData.push(clonePart);
    //checking for unique availability duration as the availability is repeating. temporary fix

    let orderArray;
    if (part.pricingAvailability.qtyBuyInc > 1) {
      distributionData = getDistributionForVehicle(
        part.partNumber,
        part,
        part.pricingAvailability &&
        part.pricingAvailability.availability,
        value,
        selectedVehicle,
        cart
      );
      orderArray = distributeAvailability2(
        Number(distributionData.totalQty),
        distributionData.filteredAvailability
      );
    } else {
      distributionData = getDistributionForVehicle(
        part.partNumber,
        part,
        part.pricingAvailability &&
        part.pricingAvailability.availability,
        value,
        selectedVehicle,
        cart
      );
      orderArray = distributeAvailability2(
        Number(distributionData.totalQty),
        distributionData.filteredAvailability
      );
    }
    orderArray.map((item) => {
      selectedAvailabilityHours.push(
        getAvailabilityObject(part.pricingAvailability, item)
      );
    });
    selectedAvailabilityHours.push(...distributionData.alreadyAddedItem); // this will aplicable only for catalog
    selectedAvailabilityHoursForPropack.push(...selectedAvailabilityHours);
    /* filtering only propack item */
    selectedAvailabilityHoursForPropack =
      selectedAvailabilityHoursForPropack.filter(
        (value) => value.isCartItem === false
      );
    setSinglePartData(singlePartData);
  }

  const prepareOrderRequestData = (selectedPart) => {
    let selectedAVailabilityHourPayload = [];
    if (selectedPart.length > 0) {
      // let { shipMethod } = this.state;
      let { part } = props;


      let tempObj = getAvailabilityObject(part.pricingAvailability);
      let orderArray = [];
      let distributionData = {};
      let cloneCart = _cloneDeep(cart);
      //  if (shipMethod !== getShipInfo(userDetails, parts).shipMethod) {
      //    cloneCart.checkoutDetails = [];
      //  }
      distributionData = getDistributionForVehicle(
        part.partNumber,
        part,
        part.pricingAvailability.availability,
        Number(selectedPart[0].qty),
        selectedVehicle,
        cloneCart
      );
      distributionData.alreadyAddedItem =
        //  shipMethod !== getShipInfo(userDetails, parts).shipMethod
        false ? cart.checkoutDetails
          : distributionData.alreadyAddedItem;
      orderArray = distributeAvailability2(
        Number(distributionData.totalQty),
        distributionData.filteredAvailability
      );
      selectedAVailabilityHourPayload.push(
        ...distributionData.alreadyAddedItem
      );
      orderArray.map((availabilityData) => {
        tempObj = {
          ...tempObj,
          availabilityHour: availabilityData.availableDuration,
          availableQty: availabilityData.availableQty,
          qty: !_isEmpty(availabilityData.orderQty.toString())
            ? Number(availabilityData.orderQty.toString())
            : Number("1"),
          distStatus: "Completed",
          orderType: "REGULAR"
        };
        selectedAVailabilityHourPayload.push(tempObj);
      });
      return selectedAVailabilityHourPayload;
    }
    dispatch({
      payload: {
        selectedAddCartData: _cloneDeep(selectedAVailabilityHourPayload),
      },
      type: actionTypes.SELECTED_ADDCART_DATA,
    });
  };

  const getAvailabilityObject = (pricingAvailability, item = {}) => {
    let { part, proPack } =
      props;
    return {
      ...(proPack ? { availability: pricingAvailability.availability } : {}),
      ...(proPack ? { selectedVehicle: selectedVehicle } : {}),
      availabilityHour: !_isEmpty(item) ? item.availableDuration : "",
      availableQty: !_isEmpty(item) ? item.availableQty : "",
      cartFlag: "no",
      categoryId: Number(
        _find(categoryGroupMap, (c) => {
          return _includes(
            _map(c.groups, "code"),
            Number(part.groupNumber)
          );
        })?.code
      ),
      costPrice: pricingAvailability.costPrice,
      corePrice: pricingAvailability.corePrice,
      coreListPrice: pricingAvailability.coreListPrice,
      desc: part.description,
      descId: part.descriptionID,
      engineId: selectedVehicle.engineId,
      engine: selectedVehicle.engine,
      groupId: Number(part.groupNumber),
      listPrice: pricingAvailability.listPrice,
      makeId: selectedVehicle.makeId,
      internalMakeId: selectedVehicle.internalMakeId,
      make: selectedVehicle.make,
      modelId: selectedVehicle.modelId,
      model: selectedVehicle.model,
      mfgCode: part.lineCodes ? part.lineCodes[0] : null,
      packQty: pricingAvailability.packQty,
      partNum: part.partNumber,
      partType: "Regular",
      qty: !_isEmpty(item) ? item.orderQty : "",
      qtyPerVeh: part.perCarQty,
      qtyBuyInc: pricingAvailability.qtyBuyInc,
      toolTip: JSON.stringify(pricingAvailability.toolTips),
      vehicleInfo: selectedVehicle
        ? `${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model
        } ${selectedVehicle.engine.replace(/\s{2,}/g, " ").trim()}`
        : "",
      yearId: selectedVehicle ? selectedVehicle.year : "",
      sellPartnerId: shippingInformation.sellerPartnerId,
      shipMethod: shippingInformation.shipMethod,
      store: shippingInformation.storeNumber,
      distStatus: "Completed",
      isCartItem: false,
      orderType: "REGULAR"
    };
  };

  return (
    <React.Fragment>
      {showImagesSlider ? (
        <ImagesSlider
          openPopup={showImagesSlider}
          closePopup={() => {
            setShowImagesSlider(false);
          }}
          partDetails={props.part}
          setShowImagesSlider={setShowImagesSlider}
        />
      ) : null}
      {!_isEmpty(props.part) ? (
        <React.Fragment>
          <Box
            sx={{
              display: 'flex',
              margin: '10px',
              width: "100%",
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: "100%",
                height: "100%",
              },
            }}
          >
            <Paper elevation={5}>
              <Container>
                <Row className="p-2">
                  <Col lg={2}>
                    <Card style={styles.card} sx={{ width: "100%", height: "100%", maxHeight: "200px" }}>
                      {isAltType && (
                        <div className="text-center"><span className="maroonColorTxt alt-sub-part"><strong>
                          Alternate</strong>
                        </span></div>
                      )}
                      {isSubType && (
                        <div className="text-center"><span className="maroonColorTxt alt-sub-part"><strong>
                          Superseded</strong>
                        </span></div>
                      )}
                      <CardMedia
                        component="img"
                        height="90"
                        image={props.part.image ? `${props.part.image}?${imageURLCookie}` : props.part.thumbnail ? `data:image/png;base64,${props.part.thumbnail}`
                          : "/noImage.jpg"}
                        alt=""
                        style={styles.media} // specify styles
                      />
                      <div class="text-center">
                        <Button onClick={() => {
                          if (props.part.images && props.part.images.length) {
                            setShowImagesSlider(true);
                          }
                        }}>
                          {props.part.images && props.part.image ? `${props.part.images.length} ${props.part.images.length > 1 ? `images` : `image`}` : ""}
                        </Button>
                      </div>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Row>
                      <span>
                        <strong>{props.part.description}</strong>
                      </span>
                    </Row>
                    <Row>
                      <span>
                        Brand: <strong>{props.part.mfgName}</strong>
                      </span>
                    </Row>
                    {!hideAllPrice && (
                    <Row>
                      <span>Your Price: &nbsp;</span>
                      <span className="maroonColorTxt">
                        <strong>${formatAmount(props.part.pricingAvailability.costPrice)}</strong>
                      </span>
                    </Row>)}
                    {!hideAllPrice && (
                    <Row>
                      <span>Core Price: &nbsp;</span>
                      <span className="maroonColorTxt">
                        <strong>${formatAmount(props.part.pricingAvailability.corePrice)}</strong>
                      </span>
                    </Row>)}
                    {!hideAllPrice && (
                    <Row>
                      <span>List Price: &nbsp;&nbsp;</span>
                      <span className="maroonColorTxt">
                        <strong>${formatAmount(props.part.pricingAvailability.listPrice)}</strong>
                      </span>
                    </Row>)}
                    <Row>
                      <span>
                        Part#: <strong>{props.part.partNumber}</strong>
                      </span>
                    </Row>
                    {!_isEmpty(props.part.altPartNums) && (
                      <React.Fragment>
                        <Row>
                          <span className="maroonColorTxt alt-sub-part"><strong>
                            Alternate Part(s): {props.part.altPartNums}</strong>
                          </span>
                        </Row>
                      </React.Fragment>
                    )}
                    {!_isEmpty(props.part.subPartNums) && (
                      <React.Fragment>
                        <Row>
                          <span className="maroonColorTxt alt-sub-part"><strong>
                            Superseded Part(s): {props.part.subPartNums}</strong>
                          </span>
                        </Row>
                      </React.Fragment>
                    )}
                  </Col>
                  <Col lg={6}>
                    {renderAvailability()}
                  </Col>
                </Row>
                {!_isEmpty(props.part.miscellaneousTexts) && (
                  <React.Fragment>
                    <Row className="pl-4">
                      <span>Description: &nbsp;
                        <span>
                          <strong>{props.part.miscellaneousTexts}</strong>
                        </span>
                      </span>
                    </Row>
                  </React.Fragment>
                )}
              </Container>
            </Paper>
          </Box>
          {showAvailabilityStoreView ?
            (<AvailabilityStoreView
              openPopup={showAvailabilityStoreView}
              closePopup={() => setShowAvailabilityStoreView(false)}
              storeData={storeData}
            />
            )
            : null}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

Part.propTypes = {};

export default Part;
