/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./styles.scss";
import { Col } from "react-bootstrap";

const AccountChangeTab = (props) => {
  const history = useHistory();

  const accountConfigDetails = useSelector(
    (state) => state.userManagement.accountConfigDetails
  );
  const userDetails = useSelector((state) => state?.user?.userDetails);

  const handleTextClick = (item) => {
    const queries = {
      AccountName: encodeURIComponent(userDetails?.billTo?.name) || "",
      AccountNumber: encodeURIComponent(userDetails?.xlPartAccountNumber) || "",
      StoreNumber: encodeURIComponent(userDetails?.shipTos[0]?.location?.storeNumber) || "",
      OSR: encodeURIComponent(`${userDetails?.billTo?.salesId +'-' +userDetails?.shipTos[0]?.salesman}`),
      EmailAddress: encodeURIComponent(userDetails?.x2userName) || "",
      // Add any additional query parameters here
    };
    const queryStr = new URLSearchParams(queries).toString();
    // const fullUrl = `${item?.linkUrl?.url}?${queryStr}`;
    const urlWithReplacedPlaceholders = item?.linkUrl?.url.replace(
      /\{(\w+)\}/g,
      (match, paramName) => queries[paramName] || ""
    );
    const updatedUrl = new URL(urlWithReplacedPlaceholders);
    // for (const paramName in queries) {
    //   if (!updatedUrl.searchParams.has(paramName)) {
    //     updatedUrl.searchParams.append(paramName, queries[paramName]);
    //   }
    // }
    console.log('updatedUrl',updatedUrl)
    console.log('updatedUrl',updatedUrl.toString())
    history.push({
      pathname: `${item?.linkUrl?.text}`,
      state: { url: encodeURI(updatedUrl.toString()) },
    });
    props.handleCloseOverlay();
  };

  console.log("account ==> ", accountConfigDetails);
  return (
    <Fragment>
      <div className="usermanagement_menu">
        <div className="mygarage__content">
          {accountConfigDetails && accountConfigDetails.length > 0 ? (
            accountConfigDetails.map((item, i) => (
              <>
                <div
                  className="mygarage__account__textLinks1  mygarage__account"
                  key={`${item.description}-${i}`}
                  onClick={() => handleTextClick(item)}
                >
                  <Col md="12">
                    <span className="title">{item?.title}</span>
                  </Col>
                </div>

                <div style={{ marginLeft: "23px" }}>
                  <span
                    className="description"
                    style={{ textDecoration: "none" }}
                  >
                    {item?.description}
                  </span>
                </div>
              </>

            ))
          ) : (
            <div className="no-data-message">No data available.</div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AccountChangeTab;
