import React from "react";
import { Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { laborSkillHoverValues } from "../../../helpers/constants";

    const LaborPreference = ({value,prefDetail,handleTextChange,handleKeyDown}) => {
        const tooltip = (value) => {
            return(
            <OverlayTrigger  placement="left" delay={{ show: 100, hide: 400 }}
            overlay={
            <Tooltip>
            <div className="skill_hover_prefcontent">
            <span>{laborSkillHoverValues[value.code.slice(-1)]?.heading} </span>
            <p>{laborSkillHoverValues[value.code.slice(-1)]?.description} </p>
             </div>
             </Tooltip>
            }
            >
             <label className="ml-3 mr-2">{value.code}</label>
            </OverlayTrigger>);
        }

    return (<Form autocomplete="off">
    <div className="hour-text" data-placeholder="$ per hour">
    {tooltip(value)}                                                    
         <div className="d-flex position-relative ml-5">
           <Form.Control
               type="text"
               name={value.code}
               placeholder="Enter amount"
               defaultValue={(prefDetail.answer && prefDetail.answer.text && (value.text = prefDetail.answer.text)) || value.text}
               onChange={(e) => {
                   handleTextChange(e, prefDetail);
               }}
               onKeyDown={handleKeyDown}
               className="skill__width"
           />
       </div> 
    </div>
   </Form> ) ;
};
export default LaborPreference;