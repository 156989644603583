import React from "react";
import { Modal, Button, Nav } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { useSelector, useDispatch } from "react-redux";
import { getVehicleByVinNumber } from "../../../actions/search";

function ThankyouPopup(props) {
  let { openPopup, closePopup, orderConfirmationNumber } = props;
  const dispatch = useDispatch();
  const candk = useSelector((state) => state.candk);
  const user = useSelector((state) => state.user);
  const { userDetails } = user;

  const { ckEnteredDetails = {}, placeOrderData = {} } = candk;

  const handleLinkClick = () => {
    getVehicleByVinNumber(ckEnteredDetails?.VIN, userDetails, dispatch);
    closePopup();
  };

  return (
    <Modal size="lg" show={openPopup} className="thank_you_modal">
      <Modal.Body>
        <span className="bold">thank you for your order</span>
        <p>
          Confirmation number for this order is{" "}
          <span className="orderconfirmationnumber">
          {placeOrderData?.confNum}
          </span>
          , please use this number in any communication with The Parts House regarding
          this order.
        </p>
        <Button onClick={handleLinkClick}>ok</Button>
        <div style={{ color: "white", textAlign: "left" }}>
          {orderConfirmationNumber}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ThankyouPopup;
