import React,{useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import moment from "moment";
import _isEmpty from 'lodash/isEmpty';
import _ from "lodash";
import ResetIcon from "../../static/images/reset.png";
import { triggerCustomUxCriticalError } from '../../actions/error';
import { createOrUpdateStoreUser } from "./storeDashboard.thunks";
// import ApproveDecline from './popUp/ApproveDecline';
import { actionTypes } from './../DriverManagement/index';

const HeaderLineItem = (props) => {
  let {
    OPERATIONS_MANAGER,
    STORE_REVIEWED,
    rowValues,
    showPopUpInfo,
    DRIVER_DELIVERED,
    STORE_USER,
    userType,
    onAddBtnClick,
    rowdata,
    isSaveAndPostEnabled,
    headerSaveAndPostId,
    creditLoader,
    setManualProcessId,
    setHeadderSaveAndPostId,
    setCreditLoader,
    handlePageChange,
    setshowModal,
    setConfirmNumber, clearPayloadData, setshowModalID,
    setConfirmData, prepareData, setShowManualModal,
    displayErrorMsg, clearHeaderId, AM_DECLINED, STORE_DECLINED,ExpandRows,lineItemEditedData,handleExpandButtonInSpecificHeader } = props;

  const dispatch = useDispatch();
  const parts = useSelector((state) => state.parts);
  let lineItemData = _.cloneDeep(parts.lineItemData);
  const[escalateModal,SetEscalateModal]=useState(false);
  const[actionType,SetActionType]=useState("");
  const[rowData,SetRowData]=useState({});
  // const [multiPartValue, setMultiPartValue] = useState('');

  const handleResetLineItems = (row) => {
    row.refreshAllLineItem = true;
    setHeadderSaveAndPostId("");
    clearPayloadData();
    setTimeout(() => {
      prepareData();
    }, 250)
  }

  const callHandleSaveButton=(row,actionDataType)=>{
    // let row=rowData;
    // let actionDataType=actionType;
    setHeadderSaveAndPostId(row.id);
    dispatch({
      payload: {
        headerId: row.id,
      },
      type: 'STORE_HEADERID',
    });

    let data = _.cloneDeep(lineItemData);

    let returnPayload = [];
    let finalPayloadToSave = [];
    if (!_isEmpty(data) && data.length > 0) {
      data.map((v) => {
        if (v.headerId === row.id) {
          finalPayloadToSave.push(v)
        }
      })
    }
    returnPayload = {
      return_id: row.id,
      action: actionDataType,
      payload: {
        lineItems: finalPayloadToSave,
        // reviewComment: multiPartValue || "",
      }
    }
    if (actionDataType === "post") {
      setCreditLoader(true);
    }
    if ((finalPayloadToSave.length > 0 && actionDataType === "save") || actionDataType === "post") {
      dispatch(createOrUpdateStoreUser(returnPayload)).then((action) => {
        let statusMessageProps = {
          heading: "",
          message: "",
          type: "",
        };
        if (action.type === "createOrUpdateStoreUser/fulfilled") {
          if (actionDataType === "save") {
            statusMessageProps.heading = 'Updated successfully';
            statusMessageProps.type = 'success';
            triggerCustomUxCriticalError(statusMessageProps, dispatch);
            handlePageChange("", false);
          }
          if (actionDataType === "post") {
            setCreditLoader(false);
            setHeadderSaveAndPostId("");
            if (!_isEmpty(action.payload) && !_isEmpty(action.payload.lineItems)) {
              let filteredData = action.payload.lineItems.filter((v) => (!(v.status === STORE_DECLINED || v.status === AM_DECLINED)));
              setshowModal(true);
              saveDataToVision(filteredData);
              let confirmNumb = action.payload && action.payload.oneStepConfNumber ? action.payload.oneStepConfNumber : "";
              setConfirmNumber(confirmNumb);
              setshowModalID(row.id);
            }
          }
          clearPayloadData();
        } else
          if (action.payload?.errorCode === "E4104" && actionDataType === "post") {
            dispatch({
              payload: {
                manualProcessData: finalPayloadToSave,
              },
              type: 'STORE_MANUAL_PROCESS',
            });
            setManualProcessId(row.id);
            setShowManualModal(true);
            setCreditLoader(false);
          }
          else {
            prepareData();
            clearHeaderId();
            setCreditLoader(false);
            displayErrorMsg();
          }
      })
    }
  }

  const handleSaveButton = (row, actionDataType) => {
    SetRowData(row);
    SetActionType(actionDataType);
    SetEscalateModal(true);
    // callHandleSaveButton(row, actionDataType);

  }

  const saveDataToVision = (creditAmountDetails) => {
    let confirmRows = [];
    !_isEmpty(creditAmountDetails) && creditAmountDetails.map((value, index) => {
      confirmRows.push({
        partNum: value.storePartNumber ? value.storePartNumber : value.pickupPartNumber,
        mfgCode: value.storeMfgCode ? value.storeMfgCode : value.pickupMfgCode,
        qty: value.storeQty,
        returnType: value.returnType,
        description: value.partDesc,
        creditAmt: value.creditAmount,
        msg: value.visionStatus,
      });
    });
    setConfirmData(confirmRows);
  }

  return (
    <>
    <div class="row row-cols-auto store_dashBoard_delivery_detail" style={{ whiteSpace: "nowrap" }}>
    <div class="col">
          <span className="marron_text customer-name-headerline">
            Customer Name:{" "}
          </span>
          <span className="text_fontsize">
            {rowValues.customerId ? rowValues.customerId : ""}-
            {rowValues.customerName
              ? rowValues.customerName.length > 13
                ? rowValues.customerName.substring(0, 13) + "..."
                : rowValues.customerName
              : ""}
          </span>
        </div>
      {/* <div class="col">
        <span className="marron_text" style={{ marginLeft: "17px" }}>Store # : {" "}</span>
        <span className='text_fontsize'>{rowValues.location ? rowValues.location : ""}</span>
      </div>
      <div class="col" style={{ marginLeft: "-10px" }}>
        <span className="marron_text" >ACX # : {" "}</span>
        {(rowValues.acxConfNumber && rowValues.status !== STORE_REVIEWED) ?
          (<span className='text_fontsize'>{rowValues.acxConfNumber}</span>) :
          (rowValues.oneStepConfNumber && rowValues.status === STORE_REVIEWED) ?
            (<span className='text_fontsize'>{rowValues.oneStepConfNumber}</span>) :
            <span>N/A</span>
        }</div> */}
      <div class="col"><OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 400 }}
        overlay={<Tooltip> Click here to see  the Return Details </Tooltip>} >
            <div className="return-details-icon">
          <span className="marron_text" style={{ marginLeft: "-32px" }} onClick={() => showPopUpInfo(rowValues.id)}>
            <i className="fa fa-info-circle" aria-hidden="true">
            </i></span></div>
      </OverlayTrigger></div>
      {/* <div className="col status-cust">
        <span className="marron_text"> Cust #: {" "}</span>
        <span className='text_fontsize'>{rowValues.customerId}</span>
      </div> */}
      <div className="col custDate"  >
        <span className="marron_text "> STATUS: {" "}</span>
        <span className={!_isEmpty(rowValues.displayStatus) && rowValues.displayStatus.length <= 10 ? "store-management-text text_fontsize" : "store-management-text storeStatus"}>{!_isEmpty(rowValues.displayStatus) ? rowValues.displayStatus.toUpperCase() : ""}</span>
      </div>
      <div class="col" style={{ marginLeft: "-1px", marginRight: "-14px" }}> <span className="store-marron_text"> RETURN  DATE: {" "}</span>
        {!_isEmpty(rowValues.createdAt) && (<span className='text_fontsize'>{moment(rowValues.createdAt).format('MM/DD/YYYY')}</span>)}</div>
      <div class="col"> {(rowValues.status === DRIVER_DELIVERED) && (
        <>
            <div style={{ marginRight: "0px" }}>
            {userType === STORE_USER && (
            <span>
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 400 }}
                overlay={<Tooltip>Click here to add more rows</Tooltip>}>
                <span className='store-dashBoard-add_items' onClick={() => onAddBtnClick(rowValues.id)}>+</span>
              </OverlayTrigger>
            </span>)} </div>
          {userType !== OPERATIONS_MANAGER && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 100, hide: 400 }}
              overlay={<Tooltip>Click here to refresh </Tooltip>}>
              <button className={"store-header-reset-button"}
                onClick={(e) => handleResetLineItems(rowValues, rowdata)}>
                <img
                  src={ResetIcon}
                  alt="reset" />
              </button>
            </OverlayTrigger>)}
          {userType !== OPERATIONS_MANAGER && (
            <Button
              className="store_savePost_button"
              disabled={(rowValues.saveButtonEnabled === rowValues.id && (rowValues.status === DRIVER_DELIVERED)) ? false : true}
              onClick={() => callHandleSaveButton(rowValues, "save")}
            >
              Save
            </Button>)}
          {creditLoader && userType !== OPERATIONS_MANAGER && (rowValues.id === headerSaveAndPostId) ? (
            <Button
              className="store_button"
              variant="primary"
              disabled
            > <Spinner
                as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
              Loading..
            </Button>
          ) : (userType !== OPERATIONS_MANAGER && (<Button
            className="store_button"
            disabled={isSaveAndPostEnabled(rowValues.lineItems)}
            onClick={() => callHandleSaveButton(rowValues, "post")}
          >
            Save & Post
          </Button>))
          }</>
      )}</div>
      <span onClick={() => handleExpandButtonInSpecificHeader(rowValues)}>
      {
         rowValues.isExpand?
        <i class="fa fa-angle-up expandArrowForDashboard" aria-hidden="true"></i>:
        <i class="fa fa-angle-down expandArrowForDashboard" aria-hidden="true"></i>
      }
      </span>

    </div>
    {/* <ApproveDecline
    escalateModal={escalateModal}
    SetEscalateModal={SetEscalateModal}
    callHandleSaveButton={callHandleSaveButton}
    multiPartValue={multiPartValue}
    setMultiPartValue={setMultiPartValue}

    /> */}
    </>

  );
};

export default HeaderLineItem;
