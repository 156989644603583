import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";
import _map from "lodash/map";
import _intersectionWith from "lodash/intersectionWith";
import _head from "lodash/head";
import _sumBy from "lodash/sumBy";
import _split from "lodash/split";
import _flatten from "lodash/flatten";
import _includes from "lodash/includes";

import { Redirect } from "react-router-dom";
import { cartPricing } from "../../../actions/parts";
import "./styles.scss";
import VechicleBread from "../../shared/VechicleBread";
import VehicleBreadCrumb from "../../shared/VehicleBreadCrumb";
import PropackView from "./PropackView";
import { getstoreName } from "../../../helpers/utils";
import { getPartsByMultiGroupOrchestrator } from "../../../actions/parts";
import ShipInfoHeader from "components/shared/ShipInfoHeader";

class ProPacksListing extends React.Component {
  state = {
    partAllDetails: [],
    proPackListData: this.props.selectedProPack
      ? _cloneDeep(this.props.selectedProPack)
      : [],
    partsCount:
      this.props.selectedProPack && this.props.selectedProPack.partJobItems
        ? this.props.selectedProPack.partJobItems.length
        : 0,
    chemCount:
      this.props.selectedProPack && this.props.selectedProPack.chemicalJobItems
        ? this.props.selectedProPack.chemicalJobItems.length
        : 0,
    totalPartsCount:
      this.props.selectedProPack &&
      this.props.selectedProPack.partJobItems &&
      this.props.selectedProPack.chemicalJobItems
        ? this.props.selectedProPack.partJobItems.length +
          this.props.selectedProPack.chemicalJobItems.length
        : 0,
    partIdMap: [],
    redirectToHomePage: false,
    cartPrice: [],
    openAccTab: 0,
    activeAccordion: 0,
    isExpress: this.props.isExpress,
  };

  componentDidUpdate(prevProps) {
    if (!_isEqual(this.props, prevProps)) {
      let { selectedProPack, partsAllDetails, suppliesAllDetails, isExpress } =
        this.props;
      let { proPackListData } = this.state;
      if (!_isEqual(this.props.partsAllDetails, prevProps.partsAllDetails)) {
        // proPackListData = _cloneDeep(selectedProPack);
        selectedProPack.partJobItems &&
          selectedProPack.partJobItems.map((jI, key) => {
            let bundleCodeArr = _map(selectedProPack.bundlePriceCodes, "name");

            let descIdArr = _map(
              _flatten(_map(jI.partGroup, "partType")),
              "code"
            );
            let partFilteredDetails = [];

            if (!_isEmpty(descIdArr)) {
              partFilteredDetails = _filter(
                partsAllDetails,
                (p) =>
                  _includes(descIdArr, p.descriptionID) &&
                  _.find(jI.partGroup, { code: p.groupNumber })
              );
            } else {
              partFilteredDetails = [];
            }

            if (!_isEmpty(bundleCodeArr) && !_isEmpty(descIdArr)) {
              proPackListData.partJobItems[key].partDetails = _filter(
                partFilteredDetails,
                (p) =>
                  !_isEmpty(
                    _intersectionWith(
                      bundleCodeArr,
                      _map(
                        _split(
                          p.pricingAvailability.bundleToolTip
                            ? p.pricingAvailability.bundleToolTip.code
                            : "",
                          "|"
                        ),
                        (s) => s.trim()
                      ),
                      _isEqual
                    )
                  )
              );
              // Takes the array with bundle codes and compare it with the bundle codes in each product of part all details.
              // bundleToolTip will have all the bundle codes as a string and needed to be split into an array and then should be trimmed for spaces
            } else {
              if (
                _isEmpty(bundleCodeArr) &&
                proPackListData.enableBundleLookup
              ) {
                proPackListData.partJobItems[key].partDetails = _filter(
                  partFilteredDetails,
                  (p) => !_isEmpty(p.pricingAvailability.bundleToolTip)
                );
              } else {
                proPackListData.partJobItems[key].partDetails =
                  partFilteredDetails;
              }
            }
          });
      }
      if (
        !_isEqual(this.props.suppliesAllDetails, prevProps.suppliesAllDetails)
      ) {
        selectedProPack.chemicalJobItems.map((cJ, k) => {
          let bundleCodeArr = _map(selectedProPack.bundlePriceCodes, "name");

          let suppliesFilteredDetails = [];
          if (!_isEmpty(cJ.chemicalPart)) {
            let descIdArrChem = _map(cJ.chemicalPart, "partDescriptionID");
            if (!_isEmpty(descIdArrChem)) {
              suppliesFilteredDetails = _filter(suppliesAllDetails, (c) =>
                _includes(descIdArrChem, c.partDescriptionID)
              );
            }
          } else {
            suppliesFilteredDetails = [];
          }

          if (!_isEmpty(bundleCodeArr) && !_isEmpty(suppliesFilteredDetails)) {
            proPackListData.chemicalJobItems[k].partDetails = _filter(
              suppliesFilteredDetails,
              (p) =>
                !_isEmpty(
                  _intersectionWith(
                    bundleCodeArr,
                    _map(
                      _split(
                        p.pricingAvailability.bundleToolTip
                          ? p.pricingAvailability.bundleToolTip.code
                          : "",
                        "|"
                      ),
                      (s) => s.trim()
                    ),
                    _isEqual
                  )
                )
            );
          } else {
            if (_isEmpty(bundleCodeArr) && proPackListData.enableBundleLookup) {
              proPackListData.chemicalJobItems[k].partDetails = _filter(
                suppliesFilteredDetails,
                (p) => !_isEmpty(p.pricingAvailability.bundleToolTip)
              );
            } else {
              proPackListData.chemicalJobItems[k].partDetails =
                suppliesFilteredDetails;
            }
          }
        });
      }

      this.setState({
        proPackListData,
      });
    }
  }

  handlePartSelect = (
    selected,
    totalOrdrQty,
    descId,
    costPrice,
    proPackKey,
    partCount,
    part = true
  ) => {
    let { partIdMap, proPackListData } = this.state;
    let { dispatch } = this.props;
    partIdMap[partCount] = {
      totalOrdrQty,
      costPrice,
      extendedPrice: costPrice * totalOrdrQty,
      descriptionId: descId,
      minQtyAdded: part
        ? totalOrdrQty >= proPackListData.partJobItems[proPackKey].pminSKU
        : totalOrdrQty >= proPackListData.chemicalJobItems[proPackKey].cminSKU,
      selectedPartAvb: selected,
    };
    let count =
      proPackListData.partJobItems.length +
      proPackListData.chemicalJobItems.length;

    if (partIdMap.length === count) {
      let requestItems = [];
      partIdMap.map((p) => {
        requestItems.push({
          mfgCode: _head(p.selectedPartAvb).mfgCode,
          partNum: _head(p.selectedPartAvb).partNum,
          qty: Number(p.totalOrdrQty),
          unitCost: _head(p.selectedPartAvb).costPrice,
        });
      });
      dispatch(cartPricing({ requestItems }))
        .then((res) => {
          let totalPrice = _sumBy(res, "extendedPrice");
          this.setState({
            newTotalPrice: totalPrice,
            cartPrice: res,
          });
        })
        .catch((error) => {});
    }

    this.setState({
      ...this.state,
      partIdMap,
    });
  };

  handleAccordionExpand = (event, key, ref) => {
    let { activeAccordion } = this.state;
    activeAccordion = event ? key : key + 1;
    if (
      ref &&
      ref.current[key] &&
      ref.current[key].parentNode &&
      ref.current[key].parentNode.className &&
      ref.current[key].parentNode.className.includes("show")
    ) {
      activeAccordion = -1;
    }
    this.setState({
      ...this.state,
      activeAccordion,
    });
  };

  handlePageChange = (clickedPage) => {
    let {
      catalogFilter,
      catalogOrder,
      catalogSortBy,
      catalogPageSize,
      catalogRequestGroups,
      dispatch,
      selectedVehicle: { year, makeId, modelId, engineId },
    } = this.props;
    window.scrollTo(0, 0);
    dispatch(
      getPartsByMultiGroupOrchestrator(
        year,
        makeId,
        modelId,
        engineId,
        catalogRequestGroups,
        clickedPage,
        catalogPageSize,
        catalogSortBy,
        catalogOrder,
        catalogFilter,
        this.props.userDetails.billTo.mclSupplier
      )
    );
  };

  render() {
    let {
      partsApiCall,
      selectedProPack,
      cartStore,
      cartShipMethod,
      userDetails,
      allStores,
    } = this.props;
    let {
      newTotalPrice,
      proPackListData,
      partIdMap,
      redirectToHomePage,
      partsCount,
      totalPartsCount,
      cartPrice,
      openAccTab,
      activeAccordion,
      isExpress,
    } = this.state;
    if (_isEmpty(selectedProPack) || redirectToHomePage) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { vehicleSelectionDone: false },
          }}
        />
      );
    }
    return (
      <Container>
        {!isExpress && (
          <VechicleBread
            clearLists={() => {
              this.setState({
                ...this.state,
                redirectToHomePage: true,
              });
            }}
          />
        )}
        <div className="wc-ship-info mx-auto">
        <ShipInfoHeader/>
        </div>
        {/* {userDetails &&
        userDetails.shipTos &&
        userDetails.shipTos.length > 0 &&
        userDetails.shipTos[0].shipMethod ? (
          <div className="wc-ship-info mx-auto maroonColorTxt"> */}
            {/* <span>
                  {"All items are Pickup - please pick up at store # " +
                    userDetails.shipTos[0].storeNumber}
                </span> */}
            {/* <span>
              Ship Method:
              <span className="text-black">
                {cartShipMethod && cartShipMethod != null
                  ? cartShipMethod == "0002"
                    ? " Pickup "
                    : " Delivery "
                  : (userDetails.shipTos[0] &&
                      userDetails.shipTos[0].shipMethod === "0002") ||
                    userDetails.shipTos[0].shipMethod === "0001"
                  ? " Pickup "
                  : " Delivery "}{" "}
              </span>
              From TPH Parts Location :{" "}
              <span className="text-black">
                {cartStore
                  ? cartStore +
                    " - " +
                    getstoreName(
                      allStores,
                      cartStore,
                      cartShipMethod,
                      userDetails
                    )
                  : userDetails.shipTos[0].storeNumber +
                    " - " +
                    userDetails.shipTos[0].location.storeName}
              </span>
            </span>
          </div>
        ) : (
          ""
        )} */}
        <PropackView
          chemicalJobItems={proPackListData.chemicalJobItems}
          handlePartSelect={(
            selected,
            totalOrdrQty,
            descId,
            costPrice,
            proPackKey,
            partCount,
            part
          ) =>
            this.handlePartSelect(
              selected,
              totalOrdrQty,
              descId,
              costPrice,
              proPackKey,
              partCount,
              part
            )
          }
          handleAccordionExpand={(event, key, ref) =>
            this.handleAccordionExpand(event, key, ref)
          }
          partsApiCall={partsApiCall}
          partsCount={partsCount}
          partIdMap={partIdMap}
          partJobItems={proPackListData.partJobItems}
          propackName={selectedProPack.jobName}
          totalPartsCount={totalPartsCount}
          newTotalPrice={newTotalPrice}
          cartPrice={cartPrice}
          openAccTab={openAccTab}
          activeAccordion={activeAccordion}
          isExpress={isExpress}
        />
      </Container>
    );
  }
}
function mapStateToProps(state, ownProps) {
  let selectedProPackDetails = state.parts.selectedProPack;
  let selectedVechicleDetails = state.search.selectedVehicle;
  let partsDetails = state.parts.partsAllDetails;
  let suppliesDetails = state.parts.suppliesAllDetails;
  let catalogReqGroups = state.parts.catalogRequestGroups;

  if (ownProps && ownProps.isExpress) {
    const nodeData = ownProps.expressNodeData || {};
    selectedProPackDetails = nodeData.selectedExpressProPack;
    selectedVechicleDetails = nodeData.selectedVehicle;
    partsDetails = ownProps?.filteredPropackParts?.partsAllDetails || [];
    suppliesDetails = ownProps?.filteredPropackParts?.suppliesAllDetails || [];
    catalogReqGroups =
      ownProps?.filteredPropackParts?.catalogRequestGroups || {};
  }

  return {
    partsAllDetails: partsDetails,
    partsApiCall: state.parts.partsApiCall,
    selectedGroupDetails: state.parts.selectedGroupDetails,
    selectedProPack: selectedProPackDetails,
    selectedVehicle: selectedVechicleDetails,
    suppliesAllDetails: suppliesDetails,
    userDetails: state.user.userDetails,
    extraSpecificsForParts: state.parts.extraSpecificsForParts,
    catalogPageSize: state.parts.catalogPageSize,
    catalogRequestGroups: catalogReqGroups,
    catalogOrder: state.parts.catalogOrder,
    catalogSortBy: state.parts.catalogSortBy,
    cartStore: state.parts.cartStore,
    cartShipMethod: state.parts.cartShipMethod,
    allStores: state.userManagement.allStores,
  };
}
export default connect(mapStateToProps)(ProPacksListing);
