/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Image,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  getAllStoreNumberForManager,
  getAllUserRoles,
} from "../../StoreDashboard/storeDashboard.thunks";
import {
  getCart,
  getItemsFromCart,
  getItemsFromCart2,
  getCheckoutItems,
} from "../../../actions/cart";
import {
  getChemicalCategories,
  getSuppliesCategoryByBrand,
  getAllPartCategories,
} from "../../../actions/parts";
import StoreHeader from "../../StoreDashboard/storeProfile/Header";
import { isMobileOnly, isTablet } from "react-device-detect";
import { connect, useSelector } from "react-redux";
import { logOut, softLogout } from "../../../actions/auth";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import logo from "../../../static/images/TPH Rapid.png";
import MyGarage from "../MyGarage";
import cart from "../../../static/images/icons/red/cart.png";
import userIcon from "../../../static/images/icons/red/user.png";
import SiteNotification from "../SiteNotification";
import HelpMenu from "../HelpMenu";
import hideIcon from "../../../static/images/hideIcon.png";
import showIcon from "../../../static/images/show.png";
import fluidsAndFiltersIcon from "../../../static/images/fluidsAndFilters.png";
import vehicleMaintIcon from "../../../static/images/vehicleMaintenance.png";
import FluidsAndFiltersPopup from "../FluidsAndFilters";
import VehicleMaintenancePopup from "../VehicleMaintenance";
import CartPreview from "../../shared/CartPreview";
import OrderPreview from "../../shared/OrderPreview";
import XModal from "../XModal";
import { addFeedBack } from "../Header/header.thunks";
import {
  triggerUxCritialErrors,
  triggerCustomUxCriticalError,
} from "../../../actions/error";
import SpecificConditionsModal from "../../Homepage/SpecificConditions";
import {
  getDeviceInfo,
  isNonHomePage,
  hidePriceUsingUserType,
} from "../../../helpers/utils";
import AlertBrowser from "../../Alert";
import VehicleSpecAndMaintananceIcons from "../../../components/shared/VehicleSpecAndMaintananceIcon";
import {
  getPreferenceDataFromStorage,
  isStockOrder,
} from "../../../helpers/utils";
import {
  getAllStores,
  getAllStoreNumber,
} from "../../../components/shared/MyGarage/mygarage.thunks";
import {
  getDelveryPickupData,
  getUserPgmAccess,
  getDSWAccounts,
} from "../../../components/User/UserManagement/usermanagement.thunk";
import { Auth } from "aws-amplify";
import { setExpressCheckoutClicked } from "../../../components/Homepage/SimpleHomepage/simplehomepage.slice";
import {
  isChrome,
  isFirefox,
  isSafari,
  isEdge,
  browserVersion,
  isIE,
} from "react-device-detect";
import pickupIcon from "../../../static/images/pickupIcon.png";
import deliveryIcon from "../../../static/images/cargo_truck.png";
import _ from "lodash";
import MyAccount from "./MyAccount";
import CartHeader from "./CartHeader";
import FeedBackMenu from "./FeedBackMenu";
import Logout from "./Logout";
import { fetchUserShipToIds } from "../../shared/ShipTo/shipTo.thunk";
import Reports from "../../../components/shared/MyGarage/Reports";
import AccountToggle from "./AccountToggle";
import clsx from "clsx";
import WarningPopup from "../../../components/shared/DSW/WarningPopup";
import { Menu, MenuItem } from "@mui/material";
import { oemBuilderPartsMenu } from "helpers/constants";
import dsw from "../../../static/images/TPH_dsw.png";
import { PowerTrainWarningPopup } from '../../../pages/c_and_k/warning/PowerTrainWarningPopup';

const actionTypes = {
  CART_RESET_TEMER: "CART_RESET_TEMER",
};

function AuthHeader(props) {
  let { dispatch, userDetails, resetCartTimer, user, userAdditionInfo  } = props;
  const mounted = useRef();
  const refreshTokenStatus = useSelector(
    (state) => state.app.refreshTokenStatus
  );
  const [showOrderPreview, setShowOrderPreview] = useState(false);
  const [timeRun, setTimeRun] = useState(0);
  const [enableCartRefreshTime, setEnableCartRefreshTime] = useState(false);
  const [showScrollToTopBtn, setShowScrollToTopBtn] = useState(false);
  const [showFeedBackModal, setFeedBackModal] = useState(false);
  const [comment, setComment] = useState("");
  let [counter, setCounter] = useState(0);
  let [counterInterval, setCounterInterval] = useState(null);
  const cartState = useSelector((state) => state.cart);
  const [UploadFile, setUploadFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const isNonHomePageCall =
    !_isEmpty(window.location) &&
    !_isEmpty(userDetails.userType) &&
    isNonHomePage(window.location.pathname);
  const [canAccessStatement, setCanAccessStatement] = useState(false);
  const userType =
    userDetails && userDetails.userType ? userDetails.userType : null;
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [showYmmeOnly, setShowYmmeOnly] = useState(
    window.location &&
      window.location.pathname &&
      window.location.pathname === "/YMMEOnly"
      ? true
      : false
  );
  const history = useHistory();
  const location = useLocation();
  const app = useSelector((state) => state.app);
  const isExpress = useSelector(
    (state) => state.expressCart.expressToggleEnabled
  );
  const isExpressCheckout = useSelector(
    (state) => state.expressCart.isExpressCheckoutClicked
  );
  const parts = useSelector((state) => state.parts);
  const hideAllPrice = parts && parts.hidePrice;
  const globalLoader = useSelector((state) => state.app.globalLoader);
  const orderType = useSelector((state) => state.cart.orderType);
  const [showDSWLogo, setShowDSWLogo] = useState(false);
  const [oemAnchorEl, setOemAnchorEl] = React.useState(null);
  const [isBillToEmulation, setIsBillToEmulation] = useState(false);
  const [defaultillTo, setDefaultillTo] = useState(null);
  const oemOpen = Boolean(oemAnchorEl);
  const [isWarningForPowerTrain, setIsWarningForPowerTrain] = React.useState(false);

  const handleClick = (event) => {
    setOemAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOemAnchorEl(null);
  };

  const handleMenuItemClick = (oemItem) => {
    let { checkoutDetails } = props;
    if (!_isEmpty(checkoutDetails)) {
      setIsWarningForPowerTrain(true);
    }
    handleClose();
  };

  const dispatchPromotionToggle = () => {
    dispatch({
      payload: { show: false },
      type: "VIEWPROMOTIONS_TOGGLE",
    });
  };

  useEffect(() => {
    setShowOrderPreview(isExpressCheckout);
  }, [isExpressCheckout]);

  const checkTabletMobileDevice = () => {
    if (window.outerWidth < 991) {
      dispatchPromotionToggle();
    }
  };

  useEffect(() => {
    if (app && app.userAuthenticated) {
      if (!isChrome || !isFirefox || !isSafari || !isEdge || isIE) {
        if ((isChrome || isEdge || isFirefox) && browserVersion < 79) {
          setShowAlert(true);
        } else if (isSafari && browserVersion < 12) {
          setShowAlert(true);
        } else if (isIE) {
          setShowAlert(true);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (!_isEmpty(userDetails.userType)) {
      let isValidUserType = hidePriceUsingUserType(userDetails.userType);
      if (isValidUserType) {
        dispatch({
          payload: {
            hidePrice: isValidUserType,
          },
          type: "HIDE_ALL_PRICE",
        });
      }
      if (
        !_isEmpty(userDetails) &&
        (userDetails.userType === "LOCATION_BUYER" ||
          userDetails.userType === "CUSTOMER_BUYER")
      ) {
        dispatch(fetchUserShipToIds());
      }
    }
  }, [userDetails.userType]);

  useEffect(() => {
    dispatch(getAllStoreNumberForManager());
  }, []);

  useEffect(() => {
    if (props.smsUser && app.userAuthenticated && isNonHomePageCall) {
      getCheckoutItems(dispatch);
      getChemicalCategories(dispatch);
      // getSuppliesCategoryByBrand(dispatch);
      getAllPartCategories(dispatch);
      // getCart(dispatch);
    }
    //checkPhoneNumberVerified();
  }, [userDetails.userType, app.userAuthenticated]);

  /*useEffect(() => {
		if(mfaEnabled){
			if (!mounted.current) {
				// do componentDidMount logic
				mounted.current = true;
			} else {
				getCart(dispatch);
			}

			window.onresize = checkTabletMobileDevice;
			const deviceInfo = getDeviceInfo();
			// Promotions close by default in mobile and tablet devices.
			if (isMobileOnly || isTablet || deviceInfo.os.name === 'iPad') {
				dispatchPromotionToggle();
			}
			getItemsFromCart(dispatch);
			getChemicalCategories(dispatch);
			// getSuppliesCategoryByBrand(dispatch);
			getAllPartCategories(dispatch);
			getCart(dispatch);
		}
	},[mfaEnabled]);*/

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else if (
      !props.smsUser &&
      isNonHomePageCall &&
      !_isEmpty(userDetails.userType)
    ) {
      //getCart(dispatch);
    }

    window.onresize = checkTabletMobileDevice;
    const deviceInfo = getDeviceInfo();
    // Promotions close by default in mobile and tablet devices.
    if (isMobileOnly || isTablet || deviceInfo.os.name === "iPad") {
      dispatchPromotionToggle();
    }
    setTimeout(() => {
      if (
        !props.smsUser &&
        isNonHomePage(window.location.pathname) &&
        !_isEmpty(userDetails.userType)
      ) {
        getCheckoutItems(dispatch);
        getChemicalCategories(dispatch);
        // getSuppliesCategoryByBrand(dispatch);
        getAllPartCategories(dispatch);
        // getCart(dispatch);
      }
    }, 2000);
  }, [userDetails.userType]);

  const checkPhoneNumberVerified = async () => {
    let user = await Auth.currentAuthenticatedUser();
    console.log(user);
    let preferredMFA = await Auth.getPreferredMFA(user);
    if (preferredMFA === "NOMFA") {
      softLogout(dispatch);
      setMfaEnabled(false);
    } else {
      setMfaEnabled(true);
    }
  };

  useEffect(() => {
    userType !== null &&
    (userType === "CUSTOMER_ADMIN" ||
      userType === "IT_ADMIN" ||
      userType === "OSR" ||
      userType === "DSM" ||
      userType === "SUPER_ADMIN" ||
      userType === "SMS_MITCHELL1")
      ? setCanAccessStatement(true)
      : setCanAccessStatement(false);
    if (userType !== null) {
      let userPreferenceDetail = getPreferenceDataFromStorage();
      let grandAccessItemSet =
        !_isEmpty(userPreferenceDetail) &&
        userPreferenceDetail.find((item) => {
          return (
            item.text === "Grant Access To" && item.code === "User Management"
          );
        });

      if (grandAccessItemSet && grandAccessItemSet.multiAnswer) {
        grandAccessItemSet.multiAnswer.map((singleAnswer) => {
          if (singleAnswer.code == "stamentInvoice") {
            setCanAccessStatement(true);
          }
        });
      }

      if (!_isEmpty(userPreferenceDetail)) {
        let grandAccessItems = userPreferenceDetail.find((item) => {
          return item.text === "smsWebUser" && item.code === "User Management";
        });

        if (grandAccessItems && grandAccessItems.possibleAnswer) {
          grandAccessItems.possibleAnswer.map((singleAnswer) => {
            if (singleAnswer.default && userType === "SMS_WEB") {
              setCanAccessStatement(true);
            }
          });
        }
      }
    }
    if (
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeNumber &&
      isNonHomePageCall
    ) {
      let payload = {
        storeNumber: userDetails.shipTos[0].location.storeNumber,
      };
      dispatch(getAllStores(payload));
    }
    if (
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos.length > 0 &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeNumber &&
      isNonHomePageCall
    ) {
      let payload = {
        storeNumber: userDetails.shipTos[0].location.storeNumber,
      };
      dispatch(getDelveryPickupData());
      let programpayload = {
        billToId: userDetails.xlPartAccountNumber,
      };
      let getAccountsPayload = {
        x2userName: userDetails?.x2userName,
      };
      dispatch(getUserPgmAccess(programpayload));
      dispatch(getDSWAccounts(getAccountsPayload));
    }
  }, [userDetails]);

  useEffect(() => {
    dispatch(getAllStoreNumber());
  }, []);

  useEffect(() => {
    if (counter >= 2) {
      setShowOrderPreview(true);
      clearInterval(counterInterval);
      setCounterInterval(null);
      setCounter(0);
    }
  }, [counter]);

  useEffect(() => {
    if (!_isEmpty(userDetails) && !showYmmeOnly) {
      if (typeof lhc_var === "undefined") {
        // This variable has to be defined before Live Helper Chat embed script.
        window.lhc_var = {}; // Window is required to define variable in global scope
      }
      if (window.lhc_var) {
        window.lhc_var.status_sub_arg = `${userDetails.xlPartAccountNumber}`;
        window.lhc_var.email = `${userDetails.x2userName}`;
      }
      window.LHC_API = window.LHC_API || {};

      window.LHCChatOptions = {};
      window.LHCChatOptions.attr_prefill = new Array();

      window.LHCChatOptions.attr_prefill.push({
        name: "username",
        value: `${userDetails.firstName} ${userDetails.lastName} `,
      });
      window.LHC_API.args = {
        mode: "widget",
        lhc_base_url: `${process.env.REACT_APP_LHC_BASE}/index.php/`,
        wheight: 450,
        wwidth: 350,
        pheight: 520,
        pwidth: 500,
        leaveamessage: true,
        check_messages: false,
      };
      (function () {
        var po = document.createElement("script");
        po.type = "text/javascript";
        po.setAttribute("crossorigin", "anonymous");
        po.async = true;
        var date = new Date();
        po.src =
          `${process.env.REACT_APP_LHC_BASE}/design/defaulttheme/js/widgetv2/index.js?` +
          ("" + date.getFullYear() + date.getMonth() + date.getDate());
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(po, s);
      })();
    }
  }, [userDetails]);

  useEffect(() => {
    // When the user scrolls down 200px from the top of the document, show the button
    const handleScroll = () => {
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        setShowScrollToTopBtn(true);
      } else {
        setShowScrollToTopBtn(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [showScrollToTopBtn]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isNonHomePage(window.location.pathname) && !isStockOrder(orderType)) {
        getCart(dispatch);
        getCheckoutItems(dispatch);
        // getItemsFromCart2(dispatch);
        setEnableCartRefreshTime(false);
      }
    }, 300000);
    if (resetCartTimer) {
      dispatch({
        payload: {
          resetcarttimer: false,
        },
        type: actionTypes.CART_RESET_TEMER,
      });
      return () => clearInterval(interval);
    } else {
      return () => clearInterval(interval);
    }
  }, [resetCartTimer]);

  const isUserAccountDSW = () => {
    let bool = false;
    if (
      userAdditionInfo &&
      userAdditionInfo.accounts &&
      userAdditionInfo.accounts.length > 0
    ) {
      var fReg = /^F[0-9A-Za-z]/g; // F Accounts Regex
      var gReg = /^G[0-9A-Za-z]/g; // G Accounts Regex
      const current = [
        {
          customerId: userDetails.billTo.billToId,
          customerName: userDetails.billTo.name,
        },
      ];
      bool = [...userAdditionInfo.accounts, ...current].some(
        (account) =>
          account.customerId.match(fReg) || account.customerId.match(gReg)
      );
    }

    return bool;
  };

  useEffect(() => {
    let bool = isUserAccountDSW();

    dispatch({
      payload: bool,
      type: "SET_USER_DSW",
    });
  }, [userAdditionInfo.accounts]);

  useEffect(() => {
    if (userDetails && userDetails.billTo && userDetails.billTo.billToId) {
      const currentBillToId = userDetails.billTo.billToId;
      var fReg = /^F[0-9A-Za-z]/g; // F Accounts Regex
      var gReg = /^G[0-9A-Za-z]/g; // G Accounts Regex
      if (
        (currentBillToId.match(fReg) || currentBillToId.match(gReg)) &&
        user &&
        user.isDSW
      ) {
        setShowDSWLogo(true);
      } else {
        setShowDSWLogo(false);
      }
    }
  }, [userDetails.billTo.billToId, user.isDSW]);

  useEffect(() => {
    let timesRun = 0;
    const interval = setInterval(() => {
      timesRun++;
      if (timesRun === 4 || timesRun % 5 === 4) {
        countDown(resetCartTimer);
        setEnableCartRefreshTime(true);
      } else {
        setTimeRun(0);
        setEnableCartRefreshTime(false);
      }
    }, 60000);
    if (resetCartTimer) {
      setEnableCartRefreshTime(false);
      dispatch({
        payload: {
          resetcarttimer: false,
        },
        type: actionTypes.CART_RESET_TEMER,
      });
      //clearInterval(interval);
      return () => clearInterval(interval);
    } else {
      return () => clearInterval(interval);
    }
  }, [resetCartTimer]);

  const countDown = () => {
    let reverseTime = 60;
    const interval = setInterval(() => {
      reverseTime--;
      setTimeRun(reverseTime);
      if (reverseTime === 0) {
        reverseTime = 60;
        if (resetCartTimer) {
          setEnableCartRefreshTime(false);
          clearInterval(interval);
          dispatch({
            payload: {
              resetcarttimer: false,
            },
            type: actionTypes.CART_RESET_TEMER,
          });
        } else {
          clearInterval(interval);
        }
      }
    }, 1000);
  };

  const [showVehicleMaintenance, setShowVehicleMaintenance] = useState(false);
  const [showFluidsAndFilters, setShowFluidsAndFilters] = useState(false);
  const [toggleTotalAmt, setToggleTotalAmt] = useState(props.hideCostPrice);

  if (window.location.pathname.includes("/tracking-order")) {
    return null;
  }

  if (window.location.pathname.includes("/order-delivery-status/")) {
    return null;
  }

  if (window.location.pathname.includes("/driver-management")) {
    return null;
  }
  if (window.location.pathname.includes("/store-management")) {
    return null;
  }
  if (window.location.pathname.includes("/store-user-dashboard")) {
    return null;
  }
  if (
    !_isEmpty(userType) &&
    (userType === "AREA_MANAGER" || userType === "CC_MANAGER")
  ) {
    if (window.location.pathname.includes("/manager-dashboard")) {
      return null;
    }
  }
  if (!_isEmpty(userType) &&(userType === "STORE_USER" || userType === "CC_MANAGER")) {
    if (window.location.pathname.includes("/create-return")) {
      return (
        <>
          <StoreHeader /> <br></br>
        </>
      );
    }
  }

  if (window.location.pathname.includes("/cart-order-details/")) {
    return null;
  }

  const handleVehicleMaintenance = () => {
    setShowVehicleMaintenance(!showVehicleMaintenance);
  };

  const handleFluidSpecs = () => {
    setShowFluidsAndFilters(!showFluidsAndFilters);
  };

  const handleHideButton = () => {
    dispatch({
      payload: {
        hideCostPrice: !toggleTotalAmt,
      },
      type: "HIDE_COST_PRICE",
    });
    setToggleTotalAmt(!toggleTotalAmt);
  };
  const handleMouseEnter = () => {
    const __counterInterval = setInterval(() => {
      counter += 1;
      setCounter(counter);
    }, 1000);
    setCounterInterval(__counterInterval);
  };

  const handleMouseLeave = () => {
    if (counter < 2) {
      setCounter(0);
      setCounterInterval(null);
      clearInterval(counterInterval);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleRecommendedJobCreate = () => {
    RecommendedJobSateData(true);
    history.push("/job-create");
  };

  const RecommendedJobSateData = (flag) => {
    dispatch({
      payload: {
        recommendedJobEditJob: [],
      },
      type: "RECOMMENDEDJOB_EDIT_JOB",
    });
    dispatch({
      payload: {
        createRecommendedJob: flag,
      },
      type: "CREATE_RECOMMENDEDJOB",
    });
  };

  const showFeedBack = () => {
    setFeedBackModal(true);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const submitFeedBack = () => {
    const { x2userName, firstName, lastName, billTo, shipTos } = userDetails;
    const shipToId = !_isEmpty(shipTos) && shipTos.map((item) => item.shipToId);
    const payload = {
      email: x2userName,
      firstName: firstName,
      lastName: lastName,
      customerId: billTo.billToId,
      shipTo: shipToId.toString(),
      comment: comment,
    };
    const str_payload = JSON.stringify(payload);
    const blob_payload = new Blob([str_payload], {
      type: "application/json",
    });
    const formData = new FormData();
    if (UploadFile) {
      formData.append("file", UploadFile, UploadFile.name);
    }
    formData.append("feedback", blob_payload);
    // const modified_payload = {
    // 	formData,
    // 	dispatch,
    // };
    try {
      const addFeedBackAction = dispatch(addFeedBack(formData));
      addFeedBackAction.then((action) => {
        if (action.type && action.type === "addFeedBack/fulfilled") {
          const statusMessageProps = {
            heading: `Thank you for your Feedback`,
            message: "",
            type: "success",
          };
          triggerCustomUxCriticalError(statusMessageProps, dispatch);
          setComment("");
          setFeedBackModal(false);
        } else if (action.type && action.type === "addFeedBack/rejected") {
          setComment("");
          setFeedBackModal(false);
        }
      });
    } catch (error) {
      triggerUxCritialErrors(error, dispatch);
    } finally {
      setUploadFile(null);
    }
  };

  const commentTextLimit = () => {
    let limit = 500;
    let commentLength = comment.length;
    const commentCharLeft = limit - commentLength;
    if (commentCharLeft >= 0) {
      const commentText = `${commentCharLeft}/${limit}`;
      return commentText;
    }
    return "";
  };

  const handleUploadFile = (event) => {
    setUploadFile(event.target.files[0]);
  };

  const printCartValueInPasswordFormat = (cartValue) => {
    if (cartValue != 0) {
      let formattedNum = "";
      let cartValueInString = cartValue.toString();
      for (let i = 0; i < cartValueInString.length; i++) {
        if (cartValueInString[i] == ".") {
          formattedNum += ".";
        } else {
          formattedNum += "*";
        }
      }
      return formattedNum;
    } else {
      return "*.**";
    }
  };
  const handleDSWModal = (e) => {
    if (!_isEmpty(e.currentTarget.innerText)) {
      let billTo = e.currentTarget.innerText.split("-");
      setDefaultillTo(billTo[0].replace(/\s+/g, ""));
      setIsBillToEmulation(!isBillToEmulation);
    }
  };

  const handleIsEmulated = () => {
    setIsBillToEmulation(!isBillToEmulation);
  };

  const routesWithNoHeaders = ["/payments-print", "/preview-rop"];
  return (
    <Fragment>
      {!routesWithNoHeaders.includes(location.pathname) && (
        <Fragment>
          <SpecificConditionsModal />
          {!showYmmeOnly ? (
            <div className="fixed-nav-wrappers">
              <section className=" fixed-top-nav">
                <section className="sectionHeader">
                  <Container fluid className="">
                    <div
                      className={clsx(
                        "section__chat--notification-message text-center d-flex align-items-center",
                        {
                          "justify-content-end": !user.isDSW,
                          "justify-content-between": user.isDSW,
                        }
                      )}
                    >
                      {user && user.isDSW && (
                        <AccountToggle handleDSW={handleDSWModal} />
                      )}
                      {(process.env.REACT_APP_ENV === "UAT" ||
                        process.env.REACT_APP_ENV === "DEV") && (
                        <div className="production_msg_margin">
                          <p
                            className={clsx({
                              production_navigation_msg_switchAcc: user.isDSW,
                              production_navigation_msg: !user.isDSW,
                            })}
                          >
                            YOU SHOULD NOT BE ON THIS PAGE!!!! THIS IS TEST
                            ENVIRONMENT ONLY.{" "}
                            <a
                              href="https://rapid.thepartshouse.com/"
                              target="_blank"
                              className="production_navigation_link"
                              rel="noreferrer"
                            >
                              Click here
                            </a>{" "}
                            to visit PRODUCTION
                          </p>
                        </div>
                      )}
                      <Navbar expand="lg" className="p-0">
                        {/* <SiteNotification />
                    <Navbar.Collapse className="xl-main-navigation loggedIn-header help-feedback-icons">
                      <Nav className="ml-auto pseudo-nav">
                        <HelpMenu />
                        <Nav.Item>
                          <span
                            onClick={(event) => {
                              showFeedBack(event);
                            }}
                            className="material-icons feedback_icon"
                          >
                            feedback
                          </span>
                          {showFeedBackModal && (
                            <XModal
                              title="Feedback"
                              show={showFeedBackModal}
                              className="custom_xmodal feedBackModal"
                              handleClose={() => {
                                setFeedBackModal(false);
                                setComment("");
                              }}
                              handleAction={() => {
                                submitFeedBack();
                              }}
                              yesButtonText="Ok"
                              noButtonText="Cancel"
                              isValidValidity={
                                !_isEmpty(comment) ? true : false
                              }
                            >
                              <section>
                                <Container>
                                  <p className="comment_subheading">
                                    We value your feedback..
                                  </p>
                                  <Form.Control
                                    as="textarea"
                                    className="input__control"
                                    type="text"
                                    name="comment"
                                    onChange={handleCommentChange}
                                    row={5}
                                    maxLength="500"
                                    placeholder="Please enter your comments"
                                  />
                                  <p className="comment_text">
                                    {commentTextLimit()}
                                  </p>
                                  <div className="feedback_upload_file">
                                    <span>
                                      Upload file to support your feedback
                                    </span>
                                    <br />
                                    <input
                                      type="file"
                                      onChange={handleUploadFile}
                                      accept=".xlsx, .xls , .jpg, .jpeg, .png, .doc"
                                    />
                                  </div>
                                </Container>
                              </section>
                            </XModal>
                          )}
                        </Nav.Item>
                      </Nav>
                    </Navbar.Collapse> */}
                        <SiteNotification showNotification={false} />
                        <Navbar.Collapse className="xl-main-navigation loggedIn-header help-feedback-icons">
                          <Nav className="ml-auto pseudo-nav top-header-nav">
                            <CartHeader
                              printCartValueInPasswordFormat={
                                printCartValueInPasswordFormat
                              }
                              checkoutDetails={props.checkoutDetails}
                              cartState={cartState}
                              handleMouseLeave={handleMouseLeave}
                              handleMouseEnter={handleMouseEnter}
                              qtyInCart={props.qtyInCart}
                              hideCostPrice={props.hideCostPrice}
                              cartCost={props.cartCost}
                              handleHideButton={handleHideButton}
                              hideAllPrice={hideAllPrice}
                              showOrderPreview={showOrderPreview}
                              enableCartRefreshTime={enableCartRefreshTime}
                              timeRun={timeRun}
                              setShowOrderPreview={setShowOrderPreview}
                              isExpressCheckout={isExpressCheckout}
                              cartAllDetails={props.checkoutDetails}
                              isStockOrder={isStockOrder(orderType)}
                            />
                            <div className="section__chat_helperMenu">
                              <HelpMenu />
                              <FeedBackMenu />
                            </div>
                            <Logout userType={userType} />
                          </Nav>
                        </Navbar.Collapse>
                      </Navbar>
                    </div>
                  </Container>
                </section>
                <section className="section__navigation">
                  <Container fluid>
                    <Navbar expand="lg" className="p-0">
                      <Navbar.Brand href="#">
                        <Link to="/" className="navbar-brand">
                          {showDSWLogo ? (
                            <Image src={dsw} className="dsw-logo logowidth" />
                          ) : (
                            <Image src={logo} className="logowidth" />
                          )}
                        </Link>
                      </Navbar.Brand>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      {userDetails && (
                        <Navbar.Collapse className="xl-main-navigation loggedIn-header">
                          <Nav className="centeralign AuthHeader_resolution">
                            {(userDetails.userSecurityGroup === null ||
                              (userDetails.userSecurityGroup &&
                                userDetails.userSecurityGroup.interchange)) && (
                              <Nav.Link className="part-interchange-Padding">
                                <Link to="/interchange">Parts Interchange</Link>
                              </Nav.Link>
                            )}
                            {(userDetails.userSecurityGroup === null ||
                              (userDetails.userSecurityGroup &&
                                userDetails.userSecurityGroup.buyersGuide)) && (
                              <Nav.Link>
                                <Link to="/buyersguide">Buyers Guide</Link>
                              </Nav.Link>
                            )}
                            <Nav.Link className="" href="#">
                              <Link to="/stock-check">Stock Check</Link>
                            </Nav.Link>
                            <Nav.Link className="chemical-and-supplies-Padding">
                              <Link to="/supplies-catalog">
                                Chemicals & Supplies
                              </Link>
                            </Nav.Link>
                            <React.Fragment>
                              <Nav.Link
                                href="/ck_partsearch?page=Engine"
                                onMouseOver={handleClick}
                              >
                                Powertrain Catalog
                              </Nav.Link>
                              <Menu
                                anchorEl={oemAnchorEl}
                                id="oem-menu"
                                className="oem-menu"
                                open={oemOpen}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  onMouseLeave: handleClose,
                                  elevation: 0,
                                  sx: {
                                    overflow: "visible",
                                    filter:
                                      "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      left: 25,
                                      top: 0,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                              >
                                {oemBuilderPartsMenu &&
                                  oemBuilderPartsMenu.map((oemItem, i) => {
                                    return (
                                      <MenuItem
                                        key={`oem-menu-${i}`}
                                        className="oem-menu-item"
                                        onClick={() => handleMenuItemClick(oemItem)}
                                      >
                                        <Link
                                          to={`${oemItem.link}?page=${
                                            oemItem.type || oemItem.label
                                          }`}
                                          className="oem-link"
                                        >
                                          {oemItem.label}
                                        </Link>
                                      </MenuItem>
                                    );
                                  })}
                              </Menu>
                            </React.Fragment>
                            {!_.isEmpty(userDetails) &&
                              ((userDetails.userType &&
                                userDetails.userType === "IT_ADMIN") ||
                                userDetails.userType === "IT_INTERNAL" ||
                                userDetails.userType === "OPERATIONS_MANAGER" ||
                                (!_.isEmpty(userDetails.roles) &&
                                  !_.isEmpty(userDetails.roles[0]) &&
                                  (userDetails.roles[0] === "AREA_MANAGER" ||
                                    userDetails.roles[0] ===
                                      "CC_MANAGER"))) && (
                                <Dropdown className="ml-0 dropdown admin-dropdown">
                                  <Dropdown.Toggle as="a">
                                    <div>
                                      <span>Admin</span>
                                    </div>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {userDetails &&
                                      userDetails.userType === "IT_ADMIN" && (
                                        <Dropdown.Item>
                                          <Link to="/timedout-txns">
                                            Timed-Out Transactions
                                          </Link>
                                        </Dropdown.Item>
                                      )}
                                    {((!_.isEmpty(userDetails) &&
                                      userDetails.userType ===
                                        "OPERATIONS_MANAGER") ||
                                      (!_.isEmpty(userDetails.roles) &&
                                        !_.isEmpty(userDetails.roles[0]) &&
                                        (userDetails.roles[0] ===
                                          "AREA_MANAGER" ||
                                          userDetails.roles[0] ===
                                            "CC_MANAGER"))) && (
                                      <Dropdown.Item>
                                        <Link to="/manager-dashboard">
                                          Part Returns DashBoard
                                        </Link>
                                      </Dropdown.Item>
                                    )}
                                    {userDetails.userType === "IT_INTERNAL" && (
                                      <Dropdown.Item>
                                        <a className="tph-cursor"
                                          onClick={(e) =>
                                            handleRecommendedJobCreate()
                                          }
                                        >
                                          Recommended Jobs
                                        </a>
                                      </Dropdown.Item>
                                    )}
                                    {userDetails &&
                                      (userDetails.userType === "IT_ADMIN" ||
                                        userDetails.userType ===
                                          "AR_MANAGER") && (
                                        <Dropdown.Item>
                                          <div className="reports-links-container">
                                            <Link>Reports</Link>
                                            <Reports
                                              userDetails={userDetails}
                                            />
                                          </div>
                                        </Dropdown.Item>
                                      )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                          </Nav>
                          <MyGarage />
                          <Nav className="pseudo-nav">
                            {!isExpress && <VehicleSpecAndMaintananceIcons />}

                            <Dropdown className="dropdown user-dropdown">
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <Link to="/user-management">
                                    User Management
                                  </Link>
                                </Dropdown.Item>
                                {((!_isEmpty(userDetails) &&
                                  userDetails.userType ===
                                    "OPERATIONS_MANAGER") ||
                                  (!_isEmpty(userDetails.roles) &&
                                    !_isEmpty(userDetails.roles[0]) &&
                                    (userDetails.roles[0] === "AREA_MANAGER" ||
                                      userDetails.roles[0] ===
                                        "CC_MANAGER"))) && (
                                  <Dropdown.Item>
                                    <Link to="/manager-dashboard">
                                      Part Returns DashBoard{" "}
                                    </Link>
                                  </Dropdown.Item>
                                )}
                                {canAccessStatement && (
                                  <Dropdown.Item>
                                    <Link to="/statements">
                                      Statements & Invoices
                                    </Link>
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Nav>
                          <MyAccount />
                        </Navbar.Collapse>
                      )}
                    </Navbar>
                  </Container>
                </section>
              </section>
            </div>
          ) : null}
          {showScrollToTopBtn ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="button-tooltip">Back To Top</Tooltip>}
            >
              <a className="scroll_to_top" onClick={() => handleScrollToTop()}>
                <i className="fa fa-arrow-circle-up"></i>
              </a>
            </OverlayTrigger>
          ) : null}

          {showAlert && (
            <AlertBrowser
              show={showAlert}
              message="Our website has been optimized for viewing in a modern web browser like Windows Edge, Chrome, Safari & Firefox. Please upgrade your Browser."
              handleClose={() => setShowAlert(false)}
            />
          )}
          {globalLoader && (
            <div className="custom_spinner_container catalog_spinner">
              <Spinner className="custom_spinner" animation="border" />
            </div>
          )}
          {isBillToEmulation && (
            <WarningPopup
              userDetails={userDetails}
              checkoutDetails={props.checkoutDetails}
              isBillToEmulation={isBillToEmulation}
              isEmulated={handleIsEmulated}
              defaultBillTo={defaultillTo}
            />
          )}
          {isWarningForPowerTrain && (
            <PowerTrainWarningPopup
            openPopup={isWarningForPowerTrain}
            closePopup={() => {
              setIsWarningForPowerTrain(false)
            }}
            />
          )}

          {/* <div className="right-fixed-buttons">
				<div>
					{userDetails && (userDetails.userSecurityGroup ===
						null ||
						userDetails.userSecurityGroup
							.maintenanceSchedules) && (!window.location.pathname.includes('/shopping-cart')) && (
							<OverlayTrigger
								placement="left"
								overlay={
									<Tooltip id="button-tooltip">
										Vehicle Maintenance
							</Tooltip>
								}
							>
								<input
									type="image"
									className="vehicle-maint-icon"
									disabled={!props.selectedVehicle}
									onClick={() => handleVehicleMaintenance()}
									src={vehicleMaintIcon}
									alt="Vehicle Maintenance Icon"
								/>
							</OverlayTrigger>
						)}
					{showVehicleMaintenance ? (
						<VehicleMaintenancePopup
							openPopup={showVehicleMaintenance}
							selectedVehicle={props.selectedVehicle}
							closePopup={() => handleVehicleMaintenance()}
						/>
					) : null}
					{userDetails && (userDetails.userSecurityGroup ===
						null ||
						userDetails.userSecurityGroup
							.fluidsFilters) && (!window.location.pathname.includes('/shopping-cart')) && (
							<OverlayTrigger
								placement="left"
								overlay={
									<Tooltip id="button-tooltip">
										Vehicle Specs
									</Tooltip>
								}
							>
								<input
									type="image"
									className="fluids-filters-icon"
									disabled={!props.selectedVehicle}
									onClick={() => handleFluidSpecs()}
									src={fluidsAndFiltersIcon}
									alt="Fluids and Filters Icon"
								/>
							</OverlayTrigger>
						)}


					{showFluidsAndFilters ? (
						<FluidsAndFiltersPopup
							openPopup={showFluidsAndFilters}
							selectedVehicle={props.selectedVehicle}
							closePopup={() => handleFluidSpecs()}
						/>
					) : null}
				</div>
			</div> */}
        </Fragment>
      )}
    </Fragment>
  );
}
function mapStateToProps(state) {
  return {
    qtyInCart: state.cart.qtyInCart,
    cartCost: state.cart.cartCost,
    userDetails: state.user.userDetails,
    selectedVehicle: state.mygarage.selectedVehicle,
    cartAllDetails: state.cart.cartAllDetails,
    checkoutDetails: state.cart.checkoutDetails,
    hideCostPrice: state.cart.hideCostPrice,
    refreshTokenStatus: state.app.refreshTokenStatus,
    cartShipMethod: state.parts.cartShipMethod,
    smsUser: state.app.smsUser,
    resetCartTimer: state.cart.resetCartTimer,
    user: state.user,
    userAdditionInfo: state.userManagement,
  };
}
export default connect(mapStateToProps)(AuthHeader);
