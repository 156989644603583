import React, { Fragment, useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { getPreferenceDataFromStorage, themeColor } from "../../../helpers/utils";
import { fetchShipToIds } from "../../shared/ShipTo/shipTo.thunk";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { triggerCustomUxCriticalError } from "../../../actions/error";

const StoreInitiatedReturn = (props) => {
    let {
        setStoreShipTo,
        userDetails,
        setCustNumber,
        custNum,
        setShowReturns,
        setShowLoader,
        setLocation,
        clearAll,
        setShipValue,
        shipValue } = props;

    const [showError, setShowError] = useState(false);
    const [shipToOptions, setShipToOptions] = useState([]);
    const [shipTo, setShipTo] = useState("");
    const [validateCustNum, setValidateCustNum] = useState("");

    let userPreferenceDetail = getPreferenceDataFromStorage();
    const dispatch = useDispatch();

    const handleCustmNum = (event) => {
        setShipToOptions([]);
        setCustNumber(event.target.value);
        setShipTo("");
        setStoreShipTo("");
        setLocation("");
        setShowReturns(false);
        setShowError(false);
    }

    const handleShipToChange = (event) => {
        if (event.label !== shipTo) {
            clearAll();
        }
        Object.assign(shipValue,{value:event.value})
        setStoreShipTo(event.value);
        setShipTo(event.label);
        setLocation(event.storeNumber);
        handleShipTo(event.value);
    }
    const handleAPICall = (e) => {
        let value = e.target.value;
        setCustNumber(custNum);
        setValidateCustNum(value);
        if (!_.isEmpty(userPreferenceDetail)) {
            let isValidReturnUser = userPreferenceDetail.find((item) => {
                return (item.text === "return-customer" && item.code === "User Management")
            })
            if (isValidReturnUser && isValidReturnUser.possibleAnswer) {
                isValidReturnUser.possibleAnswer.map((singleAnswer) => {
                    const arr = singleAnswer.text.split(';')
                    if (!_.isEmpty(userDetails)) {
                        let foundId = arr.find((item) => {
                            return item === custNum;
                        })
                        //if (userDetails.shipTos[0]?.storeNumber === foundId) {
                        if(true) {
                            setShipToOptions([]);
                            getShipToData()
                        } else {
                            setShowError(true);
                        }
                    }
                })
            }
        }
    }
    const handleShipTo = (shipTo) => {
        setStoreShipTo(shipTo);
        Object.assign(shipValue,{value:shipTo})
        setShowReturns(true);
    };

    const prepareOptions = (shipData) => {
        let shipToArry = [];
        !_.isEmpty(shipData) && shipData.map((shipTo) => {
            shipToArry.push({
                value: shipTo.shipToId,
                label: shipTo.shipToId + "-" + shipTo.address,
                storeNumber: shipTo.storeNumber,
            })
        })
        let result = _.sortBy(shipToArry, "value");
        return result;
    }
    const getShipToData = () => {
        setShowLoader(true);
        setShipToOptions([]);
        let payload = {
            billtoid: custNum,
        };
        dispatch(fetchShipToIds(payload)).then((action) => {
            if (action.type === 'fetchShipToIds/fulfilled') {
                if (!_.isEmpty(action.payload) && action.payload.length > 1) {
                    let shipToArry = prepareOptions(action.payload)
                    setShipToOptions(shipToArry);
                } else if (!_.isEmpty(action.payload) && action.payload.length === 1) {
                    setStoreShipTo(action.payload[0].shipToId);
                    Object.assign(shipValue,{value:action.payload[0].shipToId})
                    setShipValue(action.payload[0].shipToId);
                    let shipToArry = prepareOptions(action.payload);
                    setShipToOptions(shipToArry);
                    setShipTo(shipToArry[0].label);
                    setLocation(shipToArry[0].storeNumber);
                    handleShipTo(action.payload[0].shipToId)
                }
                setShowLoader(false);
            } else {
                let statusMessageProps = {
                    heading: "",
                    message: "",
                    type: "",
                };
                statusMessageProps.heading = "Unable to read location for  user";
                statusMessageProps.type = 'error';
                triggerCustomUxCriticalError(statusMessageProps, dispatch);
                setShowLoader(false);
            }
        });
    }

    return (
        <>
            <Row>
                <Col md="auto">
                    <div> <span className="shipTo_heading store_create_return">Please enter Customer Number</span></div>
                </Col>
            </Row><br></br>
            <Row className="store_create_return">
                <Col md="auto">
                    <Fragment>
                        <Form.Control
                            type="text"
                            className="input__storedashboard_text input_CustNum_width"
                            onChange={(event) => { handleCustmNum(event) }}
                            onBlur={(e) =>{if(validateCustNum !== e.target.value){
                                handleAPICall(e)}}}
                            name="newMobile"
                            id="newMobile"
                            placeholder="Enter Customer #"
                            value={custNum}
                        />
                        {showError ? (<p className='error-message error' >Customer number not found</p>) : ""}
                    </Fragment>
                </Col>

                <Col md="auto">
                    <Select
                        class="form-control"
                        className="custom-dropdown-storeshipTo"
                        name="Description"
                        placeholder="Select ShipTO "
                        options={shipToOptions}
                        value={shipToOptions.filter((v) => v.label === shipTo)}
                        isDisabled={_.isEmpty(shipToOptions)}
                        onChange={handleShipToChange}
                        theme={themeColor}
                    />
                </Col>
            </Row></>
    );
};

export default StoreInitiatedReturn;