import React from 'react';
import './styles.scss';
import storage from '../../../../helpers/storage';
import { Fragment, useState } from 'react';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAllReports } from '../mygarage.thunks';
import _isEmpty from 'lodash/isEmpty';

const Reports = (props) => {
	const dispatch = useDispatch();
	const [ userId, setuserID ] = useState(null);
	const [ reportName, setReportName ] = useState(null);
	const { userDetails } = props;
	const [ reportData, setReportData ] = useState([]);
	const history = useHistory();
	useEffect(() => {
		initialFetchReport();
	}, []);

	const initialFetchReport = () => {
		try {
			const reportAction = dispatch(getAllReports());
			reportAction.then((action) => {
				if (action.payload) {
					setReportData(action.payload.data);
				}
			});
		} catch (error) {}
	};

	const setReportDetail = (reportName) => {
		setReportName(reportName);
		let configData = null;
		reportData.map((data) => {
			if (data.report.replace(/ +/g, '') === reportName) {
				data.config.reportName = data.reportName;
				configData = data;
			}
		});
		storage.set(reportName, getReportDates(reportName, configData), 'local');
		viewReport(reportName);
	};
	const getReportDates = (reportName, config) => {
		return {
			userId: userId,
			loginUserType: userDetails.userType,
			configData: config
		};
	};

	const viewReport = (reportname) => {
		let val = '/report/' + reportname;
		history.push(val);
		// closeOverlay();
		//window.open('/report/' + reportname);
	};
	console.log('reproorororr', reportData);
	return (
		<React.Fragment>
			<Dropdown.Menu className="reports">
				{!_isEmpty(reportData) &&
					reportData.map((data) =>
						data.question.map(
							(sub) =>
								userDetails &&
								userDetails.userType &&
								data.user_types &&
								data.user_types.some((item) => item === userDetails.userType) ? (
									<Dropdown.Item>
										<a
											className=""
											onClick={() => {
												setReportDetail(data.report.replace(/ +/g, ''), data.enabled);
											}}
										>
											{data.reportName}
										</a>
									</Dropdown.Item>
								) : null
						)
					)}
			</Dropdown.Menu>
		</React.Fragment>
	);
};
export default Reports;
