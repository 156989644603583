/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import T from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Button } from "ui-library";

import "./styles.scss";
import { IconButton, Radio, RadioGroup } from "@mui/material";
import PaymentIcon from "components/PaymentIcons";
import { Delete } from "@mui/icons-material";
import { deletePaymentCard, payerDetails as getPayerDetails } from "./payments.thunk";
import XModal from "components/shared/XModal";

const ZERO_ECHECK_MSG =
  "Please select one Open Invoice with positive balance to proceed further. ";

function Savedcards(props) {
  const {
    handleConfirmPay,
    selectPayerDetails,
    profileType,
    hideMethods = false,
    subTotal = 0,
    selected,
  } = props;

  const dispatch = useDispatch();

  const payments = useSelector((state) => state.payments);
  const user = useSelector(state => state.user);

  const payerDetails = payments.payerDetails;
  const userDetails = user.userDetails;
  const billTo = userDetails.billTo;
  const billToId = billTo.billToId;

  const [activePaymentToken, setActivePaymentToken] = useState("");
  const [setupPayment, setSetupPayment] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [activeDeleteItem, setActiveDeleteItem] = useState(null);

  const cardOnly = [{ type: "card", label: "Credit/Debit Card", value: 1 }];
  const eCheckOnly = [
    { type: "echeck", label: "eCheck from your bank", value: 2 },
  ];

  const bothPaymentsMethods = [...eCheckOnly, ...cardOnly];

  useEffect(() => {
    const isECheck = payerDetails.find((p) => p.paymentMethodType === "echeck");

    if (payerDetails && payerDetails.length > 0 && isECheck && !hideMethods) {
      const detail = payerDetails[0];
      const token = detail.paymentToken;
      const id = detail.id;
      setActivePaymentToken(`${token}-${id}`);
      handleAvailablePaymentChange(token);
      selectPayerDetails(token, profileType);

      const methods = renderPaymentTypesOnProfile();

      if (methods && methods.length > 0) {
        const topActive = methods[0];
        setSetupPayment(Number(topActive.value).toString());
      }
    } else if (hideMethods) {
      const val = "2"; //echeck profileType value;
      setSetupPayment(val); // echeck default for setup payment section
      setupPaymentValueChange(val);
    } else {
      const methods = renderPaymentTypesOnProfile();

      if (methods && methods.length > 0) {
        const topActive = methods[0];
        setSetupPayment(Number(topActive.value).toString());
        const pToken = {
          paymentToken: null,
        };
        selectPayerDetails(pToken, topActive.value.toString());
      }
    }
  }, [payerDetails]);

  const renderPaymentTypesOnProfile = () => {
    switch (profileType) {
      case 1:
        return cardOnly;
      case 2:
        return eCheckOnly;
      default:
        return bothPaymentsMethods;
    }
  };

  const formatCard = (ccNumber) => {
    const cc = [...ccNumber];
    let count = 0;
    const formatted = [];

    cc.forEach((c) => {
      if (count === 4) {
        formatted.push("-");
        formatted.push(c);
        count = 0;
      } else {
        formatted.push(c);
      }

      count += 1;
    });

    return formatted.join("");
  };

  const handleAvailablePaymentChange = (value) => {
    const pToken = _.find(
      payments.payerDetails,
      (p) => p.paymentToken === value
    );

    selectPayerDetails(pToken, profileType);
  };

  const handleChange = (e) => {
    const value = e.target.value;

    const token = value.split("-")[0];

    setActivePaymentToken(value);
    handleAvailablePaymentChange(token);
  };

  const setupPaymentValueChange = (value) => {
    const pToken = {
      paymentToken: null,
    };
    selectPayerDetails(pToken, value);

    // Disable pay button and clear the object is any selected
    if (hideMethods) {
      setActivePaymentToken("");
    }
  };

  const handleSetupPaymentChange = (e) => {
    const value = e.target.value;
    setSetupPayment(value);

    setupPaymentValueChange(value);
  };

  const parsedToken = (token_with_id) => {
    return token_with_id.split("-")[0];
  };

  const checkIsEcheckDisabled = () => {
    if (
      (setupPayment === "1" && Number(subTotal) >= 0) ||
      (setupPayment === "2" && selected.length > 0 && Number(subTotal) > 0)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleDeletePaymentCard = (card) => {
    setActiveDeleteItem(card);

    setConfirmDelete(true);
  }

  const handleConfirmClose = () => {
    setConfirmDelete(false);
  }

  const handleConfirmDeleteCard = () => {
    const payload = {
      billTo: billToId,
      paymentToken: activeDeleteItem.paymentToken
    };

    dispatch(deletePaymentCard(payload));
    setConfirmDelete(false);
  }

  return (
    <React.Fragment>
      <div className="savedcards-wrapper">
        <div>
          <div className="header">
            <div className="title">Available Payment Methods</div>
            <div hidden={hideMethods}>
              <Button
                variant="contained"
                fullWidth
                disabled={!activePaymentToken}
                onClick={() =>
                  handleConfirmPay(parsedToken(activePaymentToken))
                }
              >
                Proceed to Pay
              </Button>
            </div>
          </div>

          <div className="content">
            <div className="row">
              <div className="col col-12">
                {payerDetails && payerDetails.length > 0 ? (
                  <TableContainer className="table-container">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell hidden={hideMethods}>Select</TableCell>
                          <TableCell align="right">
                            Card/Bank Account #
                          </TableCell>
                          <TableCell align="right">Payment Type</TableCell>
                          <TableCell align="right">Expires On</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={activePaymentToken}
                          onChange={handleChange}
                          style={{ display: "contents" }}
                        >
                          {payerDetails.map((card) => {
                            return (
                              <TableRow
                                className="card-type-num"
                                key={card.cardNumber}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  hidden={hideMethods}
                                >
                                  <Radio
                                    className="card-radio"
                                    name="card-radio"
                                    value={`${card.paymentToken}-${card.id}`}
                                    id={card.id}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  {" "}
                                  <span className="card-number">
                                    {" "}
                                    {formatCard(card.accountNumber || "")}
                                  </span>
                                </TableCell>
                                <TableCell align="right">
                                  {card &&
                                    card.paymentMethodType &&
                                    card.paymentMethodType === "card" ? (
                                    <PaymentIcon
                                      style={{ width: 45 }}
                                      id={
                                        card &&
                                          card.paymentMethodType &&
                                          card.paymentMethodType === "card"
                                          ? card.cardTypeName &&
                                          card.cardTypeName
                                            .toString()
                                            .toLowerCase()
                                          : card.paymentMethodType
                                      }
                                      icon={
                                        card &&
                                          card.paymentMethodType &&
                                          card.paymentMethodType === "card"
                                          ? card.cardTypeName &&
                                          card.cardTypeName
                                            .toString()
                                            .toLowerCase()
                                          : card.paymentMethodType
                                      }
                                      className="card-type"
                                    />
                                  ) : (
                                    "Bank E-check"
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {card.cardExpiryDate
                                    ? card.cardExpiryDate
                                    : " - "}
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton aria-label="delete-payment-card" onClick={() => handleDeletePaymentCard(card)}>
                                    <Delete color="primary" />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </RadioGroup>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div className="no-payment-line">
                    There are no card payments done, please select below to make
                    the payment
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="separator" hidden={hideMethods}>
          <hr />
          <div className="or">OR</div>
        </div>

        <div>
          <div className="header">
            <div className="title">Add a new Payment Method</div>
            <div>
              <Button
                variant="contained"
                fullWidth
                disabled={!!_.isEmpty(setupPayment) || checkIsEcheckDisabled()}
                onClick={() =>
                  handleConfirmPay(parsedToken(activePaymentToken), true)
                }
              >
                Setup
              </Button>
            </div>
          </div>
          {checkIsEcheckDisabled() && (
            <div className="error bolder">{ZERO_ECHECK_MSG}</div>
          )}

          <div className="content">
            <div className="row">
              <div className="col col-12">
                <RadioGroup
                  aria-labelledby="setUpNewPaymentMethod"
                  name="setUpNewPaymentMethod"
                  value={setupPayment}
                  onChange={handleSetupPaymentChange}
                >
                  {renderPaymentTypesOnProfile().map((card, i) => {
                    return (
                      <div
                        className="card cc-card"
                        key={`add-new-payment-${i}`}
                      >
                        <Radio
                          className="card-radio"
                          name="card-radio"
                          value={card.value}
                          disabled={
                            setupPayment === card.value &&
                            checkIsEcheckDisabled()
                          }
                        />
                        <div className="card-type-num">{card.label}</div>
                      </div>
                    );
                  })}
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
      </div>

      {activeDeleteItem && <XModal title="Are you sure?" show={confirmDelete} isValidValidity={true} handleAction={handleConfirmDeleteCard} handleClose={handleConfirmClose} className="confirm-delete-card">
        <div className="align-center confirm-text">You want to delete the {`${activeDeleteItem.paymentMethodType}`} <span className="uppercase">{`${formatCard(activeDeleteItem.accountNumber)} `}</span></div>
      </XModal>}
    </React.Fragment>
  );
}

Savedcards.propTypes = {
  handleConfirmPay: T.func,
  selectPayerDetails: T.func,
};

export default Savedcards;
