/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBatteryStockDetails } from "../programbenefits.thunk";
import BootstrapTable from "react-bootstrap-table-next";
import Moment from "react-moment";
import _ from "lodash";

import { currencyFormatter, renderInvoiceLink } from "helpers/utils";

const PAGESIZE = 25;

const StockingBatteries = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userDetails = user.userDetails;
  const programsnbenefits = useSelector((state) => state.ProgramAndBenefits);

  const getPayload = () => {
    const payload = {
      customerId: userDetails.billTo.customerCode,
      categories: ["Batteries"],
    };

    return payload;
  };

  useEffect(() => {
    const payload = getPayload();
    dispatch(getBatteryStockDetails({ payload, PAGESIZE, pageNumber: 0 }));
  }, []);

  const columns = [
    {
      dataField: "partNumber",
      text: "Part #",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "partDescription",
      text: "Description",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => {
        return _.isEmpty(cell) ? " - " : cell;
      },
    },
    {
      dataField: "qty",
      text: "Qty",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "lastInvoiceDate",
      text: "Date",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => {
        return <Moment date={cell} format="MM-DD-YYYY" />;
      },
    },
    {
      dataField: "value",
      text: "Value",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => {
        return currencyFormatter(Number(cell));
      },
    },
    {
      dataField: "lastInvoiceNumber",
      text: "Transaction #",
      align: "center",
      headerAlign: "center",
      formatter: (cell, row) => {
        const invoiceLink = renderInvoiceLink({
          customerNumber: userDetails.billTo.customerCode,
          invoiceDate: row.lastInvoiceDate,
          invoiceNumber: row.lastInvoiceNumber,
        });
        return _.isEmpty(row.lastInvoiceNumber) ? (
          row.error
        ) : (
          <a href={invoiceLink} target="_blank" rel="noreferrer">
            {cell}
          </a>
        );
      },
    },
  ];

  return (
    <div className="stocking-batteries">
      {programsnbenefits.stockingBatteries &&
      programsnbenefits.stockingBatteries.length > 0 ? (
        <BootstrapTable
          columns={columns}
          keyField="part#"
          data={programsnbenefits.stockingBatteries}
        />
      ) : (
        <div>No Data for Stocking Batteries</div>
      )}
    </div>
  );
};

StockingBatteries.propTypes = {};

export default StockingBatteries;
