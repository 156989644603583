import partsApi from "../services/partsApi";
import _sortBy from "lodash/sortBy";

import { setVehicles } from "../components/shared/MyGarage/mygarage.thunks";
import { setVehicleSelected } from "../components/shared/MyGarage/mygarage.slice";
import { triggerCustomUxCriticalError } from "../actions/error";
import _isEmpty from "lodash/isEmpty";

import { timeInUTC } from "../helpers/utils";

export const actionTypes = {
  YEAR_LIST_ALL: "YEAR_LIST_ALL",
  MAKES_FOR_YEAR: "MAKES_FOR_YEAR",
  VIN_NUMBER_SEARCH: "VIN_NUMBER_SEARCH",
  MODEL_FOR_MAKE_AND_YEAR: "MODEL_FOR_MAKE_AND_YEAR",
  ENGINE_FOR_YEAR_MAKE_MODEL: "ENGINE_FOR_YEAR_MAKE_MODEL",
};

export const getAllYears = (dispatch) => {
  const fullParams = {
    params: {
      dispatch,
    },
  };
  partsApi
    .get(`/vehicle/year`, {
      ...fullParams,
    })
    .then((result) => {
      dispatch({
        payload: {
          yearList: result.data,
        },
        type: actionTypes.YEAR_LIST_ALL,
      });
      const isNotValidData = !_isEmpty(result.data) && result.data.some(item => item.code === 2023) ? false : true;
      if (isNotValidData) {       
        let errorMessageProps = {
          heading: "We are experiencing intermittent System problems. Please try after 5 minutes.",
          message: "",
          type: "info",
        };
        triggerCustomUxCriticalError(errorMessageProps, dispatch);
      }
    })
    .catch((error) => {
      console.log(error, " error in api");
    });
};
export const getMakesForYear = (year, dispatch) => {
  const fullParams = {
    params: {
      dispatch,
      year,
    },
  };
  partsApi
    .get(`/vehicle/make/internal`, {
      ...fullParams,
    })
    .then((result) => {
      dispatch({
        payload: {
          makeListForYear: result.data,
        },
        type: actionTypes.MAKES_FOR_YEAR,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getModelsForMake = (year, make, dispatch) => {
  partsApi
    .get(`/vehicle/make/internal/model?make=${make}&year=${year}`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          modelListForYearAndMake: _sortBy(result.data, ["name"]),
        },
        type: actionTypes.MODEL_FOR_MAKE_AND_YEAR,
      });
    });
};

export const getEngineForYearMakeModel = (year, make, model, dispatch) => {
  partsApi
    .get(`vehicle/engine?make=${make}&model=${model}&year=${year}`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      dispatch({
        payload: {
          engineForYearMakeModel: result.data,
        },
        type: actionTypes.ENGINE_FOR_YEAR_MAKE_MODEL,
      });
    });
};

export const getVehicleByVinNumber = (vin, user, dispatch) => {
  const { x2userName, shipTos, billTo } = user;
  return new Promise((resolve, reject) => {
  partsApi
    .get(`/vehicle/vin/${vin}`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      let errorMessageProps = {
        heading: "",
        message: "",
        type: "",
      };
      if (result.data === null) {
        errorMessageProps.heading =
          "Entered VIN number is invalid, please provide valid VIN number.";
        errorMessageProps.type = "info";
        triggerCustomUxCriticalError(errorMessageProps, dispatch);
      }
      let selectedVehicle = {
        year: result.data.yearText,
        make: result.data.makeText,
        makeId: result.data.makeCode,
        internalMakeId: result.data.internalMakeCode,
        model: result.data.vehicleModels[0].name,
        modelId: result.data.vehicleModels[0].code,
        engine: result.data.vehicleEngines[0].name,
        engineId: result.data.vehicleEngines[0].code,
        vin: result.data.vinCode,
      };
      dispatch({
        payload: {
          selectedVehicle,
        },
        type: "VEHICLE_SELECTION_COMPLETED",
      });
      dispatch({
				payload: {
					smsVin: null,
				},
				type: 'SMS_VIN',
			});
      // Dispatch My Garage Vehicles
      const {
        engine,
        make,
        model,
        year,
        makeId,
        modelId,
        engineId,
        internalMakeId,
      } = selectedVehicle;
      const payload = {
        createdBy: x2userName,
        createdDate: timeInUTC(),
        customerId: billTo.billToId,
        engine: engine.trim(),
        engineId,
        favoriteFlag: "N",
        favoriteText: "",
        favoriteUpdatedDate: "",
        lastOrderedDate: "",
        make: make.trim(),
        makeId,
        internalMakeId,
        model: model.trim(),
        modelId,
        orderedFlag: "N",
        searchId: "",
        shipToId: shipTos[0].shipToId,
        updatedBy: x2userName,
        updatedDate: timeInUTC(),
        year: year.trim(),
        vin: vin,
      };
      dispatch(setVehicles(payload));
      dispatch(setVehicleSelected(payload));
      resolve(result);

    })
    .catch((error) => {
      console.log(error, "error in api");
      reject(error);
    });
    });
};

export const getVehicleByLicensePlate = (state, plate, user, dispatch) => {
  const { x2userName, shipTos, billTo } = user;
  const fullParams = state + "-" + plate;
  return new Promise((resolve, reject) => {
  partsApi
    .get(`vehicle/p2v?plateNumber=${plate}&state=${state}`, {
      params: {
        dispatch,
      },
    })
    .then((result) => {
      if (result && result.data) {
        let selectedVehicle = {
          year: result.data[0].yearText,
          make: result.data[0].makeText,
          makeId: result.data[0].makeCode,
          internalMakeId: result.data[0].internalMakeCode,
          model: result.data[0].vehicleModels[0].name,
          modelId: result.data[0].vehicleModels[0].code,
          engine: result.data[0].vehicleEngines[0].name,
          engineId: result.data[0].vehicleEngines[0].code,
          plate:fullParams,
        };
        dispatch({
          payload: {
            selectedVehicle,
          },
          type: "VEHICLE_SELECTION_COMPLETED",
        });

        // Dispatch My Garage Vehicles
        const {
          engine,
          make,
          model,
          year,
          makeId,
          modelId,
          engineId,
          internalMakeId,
          plate
        } = selectedVehicle;
        const payload = {
          createdBy: x2userName,
          createdDate: timeInUTC(),
          customerId: billTo.billToId,
          engine: engine.trim(),
          engineId,
          favoriteFlag: "N",
          favoriteText: "",
          favoriteUpdatedDate: "",
          lastOrderedDate: "",
          make: make.trim(),
          makeId,
          internalMakeId,
          model: model.trim(),
          modelId,
          orderedFlag: "N",
          searchId: "",
          shipToId: shipTos[0].shipToId,
          updatedBy: x2userName,
          updatedDate: timeInUTC(),
          year: year.trim(),
          plate:plate,
        };
        dispatch(setVehicles(payload));
        dispatch(setVehicleSelected(payload));
        resolve(result);

      }
    })
    .catch((error) => {
      console.log(error, "error in api");
      reject(error);
    });

    });
};
