import { Box, Checkbox, TableCell, tableCellClasses, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { visuallyHidden } from '@mui/utils';
import styled from '@emotion/styled';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        fontWeight: 'bold'
    },
}));

const EnhancedTableHead = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (rowCount > 0 && numSelected === rowCount) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [rowCount, numSelected])

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        className='custom-checkbox'
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={checked}
                        onClick={(event) => onSelectAllClick(event, !checked)}
                        inputProps={{
                            'aria-label': 'controlled',
                        }}
                    />
                </StyledTableCell>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        hidden={headCell.hidden}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <div className='head-label'>
                                <div className='label'>{headCell.label}</div>
                                <div className='caption'>{headCell.caption}</div>
                            </div>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    headCells: PropTypes.array
};

export default EnhancedTableHead