import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
    getHelpMenuItems
} from './helpmenu.service';

export const fetchHelpMenuItems = createAsyncThunk(
  "fetchHelpMenuItems",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getHelpMenuItems(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);
