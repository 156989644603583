/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { stockOrderPreviewROP } from './stockorder.thunk';
import queryString from 'query-string';

import './styles.scss';
import { Spinner } from 'react-bootstrap';

const PreviewROP = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const params = queryString.parse(location.search);
	const [ pdfData, setPdfData ] = useState(null);
	const stockOrder = useSelector((state) => state.stockOrder);
	const globalLoader = useSelector((state) => state.app.globalLoader);

	const actionTypes = { GLOBAL_LOADER: 'GLOBAL_LOADER' };

	const initialLoad = () => {
		const payload = {
			customerCode: params.customerCode,
			storeNum: params.storeNum,
			partSKU: [], // clean is any null or undefined values in partSKUs
			categoryDetails: [],
			lineCodeDetails: [],
			filter: {
				customerROP: true,
				customerDemands: false,
				promotionParts: false
			},
			isSummary: false
		};

		dispatch(stockOrderPreviewROP(payload));
	};

	useEffect(() => {
		initialLoad();
	}, []);

	useEffect(
		() => {
			dispatch({
				payload: {
					globalLoader: stockOrder.previewROPloading
				},
				type: actionTypes.GLOBAL_LOADER
			});
		},
		[ stockOrder.previewROPloading ]
	);

	useEffect(
		() => {
			if (stockOrder.previewROP) {
				const body = 'data:application/pdf;base64,' + encodeURI(stockOrder.previewROP);

				setPdfData(body);
			}
		},
		[ stockOrder.previewROP ]
	);

	return (
		<React.Fragment>
			{globalLoader && (
				<div className="custom_spinner_container catalog_spinner">
					<Spinner className="custom_spinner" animation="border" />
				</div>
			)}
			<iframe
				name={`${'Preview_ROP'}.pdf`}
				download={`${'Preview_ROP'}.pdf`}
				className="print-frame"
				width="100%"
				src={pdfData}
			/>
		</React.Fragment>
	);
};

export default PreviewROP;
