// selectors.js
import { useSelector } from 'react-redux';

export const useCandKSelectors = () => {
  const candk = useSelector((state) => state.candk);
  const catalogFilter = useSelector((state) => state.parts.catalogFilter);
  const userDetails = useSelector((state) => state.user.userDetails);
  const selectedVehicle = useSelector((state) =>state.mygarage.selectedVehicle);
  const laborGuideData = useSelector((state) => state.parts.laborGuideDetails);
  const laborApiCall = useSelector((state) => state.parts.laborApiCall);
  const catalogPageNumber = useSelector((state) => state.parts.catalogPageNumber);
  const catalogTotalPage = useSelector((state) => state.parts.catalogTotalPage);
  const buyersGuideBrandData = useSelector((state) => state.parts.buyersGuideBrandDetails);
  const buyersGuideData = useSelector((state) => state.parts.buyersGuideDetails);
  const partsApiCall = useSelector((state) => state.parts.partsApiCall);
  const partsAllDetails = useSelector((state) => state.parts.partsAllDetails);
  const checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  const candkCheckoutDetails = useSelector((state) => state.cart.candkCheckoutDetails);
  const catalogOrder = useSelector((state) => state.parts.catalogOrder);
  const catalogSortBy = useSelector((state) => state.parts.catalogSortBy);
  const catalogPageSize = useSelector((state) => state.parts.catalogPageSize);
  const catalogRequestGroups = useSelector((state) => state.parts.catalogRequestGroups);
  const isPartsNotFound = useSelector((state) => state.parts.isPartsNotFound);
  const suppliesAllDetails = useSelector((state) => state.parts.suppliesAllDetails);
  const currentPage = useSelector((state) => state.parts.suppliesPageNumber);
  const totalPages = useSelector((state) => state.parts.suppliesTotalPages);
  const suppliesReqAvailability = useSelector((state) => state.parts.suppliesReqAvailability);
  const brandsSelected = useSelector((state) => state.parts.suppliesReqBrands);
  const pageSize = useSelector((state) => state.parts.suppliesPageSize);
  const order = useSelector((state) => state.parts.suppliesOrder);
  const sortBy = useSelector((state) => state.parts.suppliesSortBy);
  const suppliesOffers = useSelector((state) => state.parts.suppliesOffers);
  const suppliesReqGrpIds = useSelector((state) => state.parts.suppliesReqGrpIds);
  const reqSpecs = useSelector((state) => state.parts.suppliesReqSpecs);
  const reqSizeQty = useSelector((state) => state.parts.suppliesReqSizeQty);
  const reqCaseQty = useSelector((state) => state.parts.suppliesReqCaseQty);
  const hidePrice = useSelector((state) => state.parts.hidePrice);
  const globalLoader = useSelector((state) => state.app.globalLoader);

  return {
    candk,
    catalogFilter,
    userDetails,
    selectedVehicle,
    laborGuideData,
    laborApiCall,
    catalogPageNumber,
    catalogTotalPage,
    buyersGuideBrandData,
    buyersGuideData,
    partsApiCall,
    partsAllDetails,
    checkoutDetails,
    candkCheckoutDetails,
    catalogOrder,
    catalogSortBy,
    catalogPageSize,
    catalogRequestGroups,
    isPartsNotFound,
    suppliesAllDetails,
    currentPage,
    totalPages,
    suppliesReqAvailability,
    brandsSelected,
    pageSize,
    order,
    sortBy,
    suppliesOffers,
    suppliesReqGrpIds,
    reqSpecs,
    reqSizeQty,
    reqCaseQty,
    hidePrice,
    globalLoader
  };
};
