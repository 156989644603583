import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  getAllFailedOrders,
  retryFailedOrders,
  updateFailedOrder
} from "./timedouttxns.service";

export const getAllFailedOrderTxns = createAsyncThunk(
  "getAllFailedOrderTxns",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getAllFailedOrders(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const retryFailedOrderTxns = createAsyncThunk(
  "retryFailedOrderTxns",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await retryFailedOrders(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const updateFailedOrderTxns = createAsyncThunk(
  "updateFailedOrderTxns",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await updateFailedOrder(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);
