import _ from "lodash";
const { createSlice } = require("@reduxjs/toolkit");

const {
  getAllLineCodes,
  getStockOrderPartDetails,
  getInvoiceDetails,
  getCategories,
  getROPDetails,
  getPartNumbers,
  getPartImages,
  stockOrderUpload,
  stockOrderPartAvailability,
  stockOrderEmailROPToMe,
  stockOrderHelpDocuments,
  stockOrderPreviewROP,
} = require("./stockorder.thunk");

let initialState = {
  loading: false,
  lineCodes: [],
  invoicesData: [],
  stockOrders: [],
  categories: [],
  ropData: [],
  partNumbers: [],
  isUpload: false,
  totalPages: 0,
  totalElements: 0,
  stockRecords: {
    matchedCount: 0,
    partDetails: [],
    totalCount: 0,
    unmatchedCount: 0,
    unmatchedRecords: [],
  },
  availabilities: [],
  documents: [],
  EmailROPToMe: null,
  previewROP: null,
  previewROPloading: false,
  partSKUsLoading: false,
  message: false,
};

const stockOrder = createSlice({
  name: "stockorders",
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.stockOrders = [];
    },
    setUploadFlag: (state, action) => {
      const { payload } = action;
      state.isUpload = payload.isUpload;
    },
  },
  extraReducers: {
    [getCategories.pending]: (state, action) => {
      state.loading = true;
    },
    [getCategories.fulfilled]: (state, action) => {
      const { payload } = action;

      let categories = payload.map((p) => {
        return { label: p.groupsName, value: p.groupsName };
      });

      categories = _.sortBy(categories, (c) => c.label);

      const all = [{ label: "All", value: null }];
      state.categories = [...all, ...categories];
      state.loading = false;
    },
    [getCategories.rejected]: (state, action) => {
      state.loading = false;
    },
    [getAllLineCodes.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllLineCodes.fulfilled]: (state, action) => {
      const payload = action.payload;
      const codes = payload.map((p) => {
        return {
          label: `${p.lineCode} - ${p.lineCodeOpDesc}${
            p.catalogMfgDesc ? `(${p.catalogMfgDesc})` : ""
          }`,
          value: p.lineCode,
          mfgDesc: p.catalogMfgDesc,
        };
      });
      const all = [{ label: "All", value: null }];
      state.lineCodes = [...all, ...codes];
      state.loading = false;
    },
    [getAllLineCodes.rejected]: (state, action) => {
      state.lineCodes = [];
      state.loading = false;
    },

    [getInvoiceDetails.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = false;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getInvoiceDetails.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.invoicesData.push(action.payload);
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getInvoiceDetails.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading && state.currentRequestId === requestId) {
        state.loading = false;
        state.error = action.error;
        state.currentRequestId = null;
      }
    },

    [getStockOrderPartDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getStockOrderPartDetails.fulfilled]: (state, action) => {
      const { payload } = action;
      const { partDetails, totalPages, totalElements, message } = payload;

      state.totalPages = totalPages;
      state.stockOrders = partDetails;
      state.totalElements = totalElements;
      state.warningMessage = message === "partial" ? true : false;
      state.loading = false;
    },
    [getStockOrderPartDetails.rejected]: (state, action) => {
      state.stockOrders = [];
      state.loading = false;
    },
    [getROPDetails.pending]: (state, action) => {
      if (!state.loading) {
        state.loading = true;
      }
    },
    [getROPDetails.fulfilled]: (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.stockOrders = [];
        state.ropData = action.payload;
      }
    },
    [getROPDetails.rejected]: (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.error = action.error;
      }
    },
    [getPartNumbers.pending]: (state) => {
      state.partSKUsLoading = true;
    },
    [getPartNumbers.fulfilled]: (state, action) => {
      state.partNumbers = action.payload;
      state.partSKUsLoading = false;
    },
    [getPartNumbers.rejected]: (state, action) => {
      state.partNumbers = [];
      if (
        action.error &&
        action.error.message ===
          "Operation is canceled due to new search request"
      ) {
        state.partSKUsLoading = true;
      } else {
        state.partSKUsLoading = false;
      }
    },
    [getPartImages.pending]: (state) => {
      state.loading = false;
    },
    [getPartImages.fulfilled]: (state, action) => {
      const pImages = action.payload;
      const sOrders = state.stockOrders;
      const newSOrders = sOrders.map((order) => {
        pImages.forEach((i) => {
          if (i.partNumber === order.partSKU) {
            if (i.partImages && i.partImages.length > 0) {
              order.image = i.partImages[0].url;
              order.images = i.partImages;
            } else {
              order.image = i.imageUrl;
              order.images = i.partImages;
            }
          }
        });

        return order;
      });

      state.stockOrders = newSOrders;
      state.partImages = action.payload;
      state.loading = false;
    },
    [getPartImages]: (state) => {
      state.loading = false;
    },
    [stockOrderUpload.pending]: (state) => {
      state.stockOrders = [];
      state.loading = true;
    },
    [stockOrderUpload.fulfilled]: (state, action) => {
      const res = action.payload;

      state.stockOrders = res.partDetails || [];
      state.stockRecords = res || {};
      state.totalElements = res.totalElements;
      state.loading = false;
      state.isUpload = true;
    },
    [stockOrderUpload.rejected]: (state, action) => {
      state.loading = false;
      state.stockRecords = initialState.stockRecords;
    },
    [stockOrderPartAvailability.pending]: (state, action) => {
      state.loading = false;
    },
    [stockOrderPartAvailability.fulfilled]: (state, action) => {
      state.availabilities = action.payload.availability;
      state.loading = false;
    },
    [stockOrderPartAvailability.rejected]: (state, action) => {
      state.loading = false;
    },
    [stockOrderEmailROPToMe.pending]: (state, action) => {
      state.loading = false;
    },
    [stockOrderEmailROPToMe.fulfilled]: (state, action) => {
      setTimeout(() => {
        state.EmailROPToMe = null;
      }, 1000 * 3);
      state.EmailROPToMe = action.payload;
      state.loading = false;
    },
    [stockOrderEmailROPToMe.rejected]: (state, action) => {
      state.loading = false;
    },
    [stockOrderHelpDocuments.pending]: (state, action) => {
      state.loading = false;
    },
    [stockOrderHelpDocuments.fulfilled]: (state, action) => {
      state.documents = action.payload;
      state.loading = false;
    },
    [stockOrderHelpDocuments.rejected]: (state, action) => {
      state.documents = [];
      state.loading = false;
    },
    [stockOrderPreviewROP.pending]: (state, action) => {
      state.previewROPloading = true;
    },
    [stockOrderPreviewROP.fulfilled]: (state, action) => {
      state.previewROP = action.payload;
      state.previewROPloading = false;
    },
    [stockOrderPreviewROP.rejected]: (state, action) => {
      state.previewROPloading = false;
    },
  },
});

const { actions, reducer } = stockOrder;

export const { reset, setUploadFlag } = actions;

export default reducer;
