import React, { Fragment } from 'react';
import { Col, Accordion, Card, InputGroup, Form, Button, OverlayTrigger, Tooltip  } from 'react-bootstrap';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';
import _map from 'lodash/map';
import _intersection from 'lodash/intersection';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _find from 'lodash/find'
import _uniq from "lodash/uniq";

import {
	getPreferenceDataFromStorage,
	getChemicalsPreferredBrands,
	getDefaultDataBasedOnChemicalUSerPref,
	getDefaultPreference,
	timeInUTC
} from '../../../helpers/utils';

import { getChemPartByGrp, getChemPartByCat } from '../../../actions/parts';
import { addSearchedValue } from '../../../components/Homepage/Search/globalSearch.thunk';
import { logUserStockActivity } from "../../../actions/user";

const actionTypes = { SEARCHED_ADDTOCART_DATA: 'SEARCHED_ADDTOCART_DATA' };
class ChemicalFilters extends React.Component {
	constructor(props) {
		super(props)
		this.chemicalAvailFilterRef = React.createRef();
		this.chemicalAvailFilterRef.current = [];
		this.defaultAvailFilter = false;
	}
	state = {
		suppliesReqGrpIds: !_isEmpty(this.props.suppliesReqGrpIds)
			? _cloneDeep(this.props.suppliesReqGrpIds)
			: [],
		brandCodes: _cloneDeep(this.props.brandCodes),
		pimCategoryIds: _cloneDeep(this.props.pimCategoryIds),
		avbAccExp: true,
		catAccExp: true,
		bAccExp: true,
		brandsViewAccExp: true,
		brandsAccExp: true,
		casAccExp: false,
		csQtyAccExp: false,
		szQtyAccExp: false,
		categorySuppliesMap: _sortBy(
			_cloneDeep(this.props.categorySuppliesMap),
			['name']
		),
		allAvailability: this.props.allAvailability
			? this.props.allAvailability
			: [],
		reqBrands: this.props.suppliesSelectedGroups
			? _cloneDeep(this.props.suppliesSelectedGroups)
			: [],
		selectedAvailability: [],
		selectedAvailabilityRequest: [],
		selectedCaseQty: [],
		selectedSizeQty: [],
		suppliesAllDetails: [],
		selectedOffers: [],
		selectedSpecs: [],
		selectedBrands: [],
		suppliesCBMap: _cloneDeep(this.props.suppliesCBMap) || [],
		isChemicalCategoryChanged: this.props.isChemicalCategoryChanged,
		brandFilterChanged: false
	};

	getPrefBrands() {
		const prefBrands = getChemicalsPreferredBrands();
		if (prefBrands && prefBrands.multiAnswer && prefBrands.multiAnswer.length > 0) {
			const _selBrands = prefBrands.multiAnswer.map((s) => {
				if (typeof s !== 'string') {
					return s.text;
				} else {
					return s;
				}
			});
			const selBrands = _selBrands;

			return selBrands;
		}
	}

	componentDidMount() {
		this.setState({
			filtersLoaded: true
		});
	}


	componentDidUpdate(prevProps) {
		if (!_isEqual(this.props, prevProps)) {
			this.state.categorySuppliesMap.forEach((category) => {
				let { categorySuppliesMap, suppliesReqGrpIds } = this.state;

				let matchingCategory = categorySuppliesMap.find((data) => {
					return data.code === category.code;
				});
				const matched = _size(
					_intersection(
						suppliesReqGrpIds,
						_map(category.groups, 'id')
					)
				);
				matched > 0
					? (matchingCategory.active = true)
					: (matchingCategory.active = false);
				this.setState({
					categorySuppliesMap,
				});
			});
		}

		// On Chemical Global Search, fill the selected brand from the search to chemical filters.
		if (!_isEqual(this.props, prevProps)) {
			if (this.props.chemicalsGS.brand) {
				const _selectedBrands = this.state.selectedBrands;
				_selectedBrands.push(this.props.chemicalsGS.brand.name);

				this.setState({
					selectedBrands: _selectedBrands,
					brandsAccExp: true,
				});
			}
		}

		if (!_isEqual(this.props, prevProps)) {
			let {
				brandCodes,
				pimCategoryIds,
				suppliesReqGrpIds,
				allAvailability,
				suppliesAllDetails,
				selectedBrands
			} = this.state;
			if (
				!_isEqual(this.props.pimCategoryIds, prevProps.pimCategoryIds)
			) {
				pimCategoryIds = _cloneDeep(this.props.pimCategoryIds);
				brandCodes = _cloneDeep(this.props.brandCodes);
			}
			if (
				(!_isEqual(this.props.suppliesAllBrands, prevProps.suppliesAllBrands)  || _isEqual(this.props.suppliesAllBrands, prevProps.suppliesAllBrands))
			) {
				if (
					(!_isEqual(this.props.isChemicalCategoryChanged, prevProps.isChemicalCategoryChanged) || _isEqual(this.props.isChemicalCategoryChanged, prevProps.isChemicalCategoryChanged))
				) {

					if(!_isEmpty(this.props.suppliesAllBrands) && (this.props.isChemicalCategoryChanged == true || this.props.isChemicalCategoryChanged == null)) {
						selectedBrands = [];
						selectedBrands = _cloneDeep(this.props.suppliesAllBrands);
						selectedBrands.push('all');
					}
				}
			}

			if (
				!_isEqual(
					this.props.suppliesReqGrpIds,
					prevProps.suppliesReqGrpIds
				)
			) {
				suppliesReqGrpIds = _cloneDeep(this.props.suppliesReqGrpIds);
			}
			if (
				!_isEqual(this.props.allAvailability, prevProps.allAvailability)
			) {
				allAvailability = _sortBy(
					_cloneDeep(this.props.allAvailability)
				);
			}
			if (
				!_isEqual(
					this.props.suppliesAllDetails,
					prevProps.suppliesAllDetails
				)
			) {
				suppliesAllDetails = _cloneDeep(this.props.suppliesAllDetails);
			}
			
			// Debug
			const preferredBrandsSelected = this.getPrefBrands();
			console.log('chemical filters ==> ', preferredBrandsSelected);
			if (preferredBrandsSelected && !preferredBrandsSelected.includes('All')) {
				if (!this.state.brandFilterChanged) {
					selectedBrands = this.getPrefBrands();
				}
			}

			console.log('chemical filters after ==> ', selectedBrands);
			this.setState({
				...this.state,
				allAvailability,
				brandCodes,
				pimCategoryIds,
				suppliesAllDetails,
				suppliesReqGrpIds,
				selectedBrands,
				filtersLoaded: false
			});
		}

		let { allAvailability, selectedAvailability } = this.state;
		if (this.chemicalAvailFilterRef && Object.keys(this.chemicalAvailFilterRef.current).length > 0) {
			let defualtFilterValue = this.setDefaultAvailFilter();
			if (!this.defaultAvailFilter && !_isEmpty(defualtFilterValue) && this.chemicalAvailFilterRef.current && allAvailability && allAvailability.length > 0) {
				this.defaultAvailFilter = true;
				selectedAvailability = [];
				if(defualtFilterValue == 'all'){
					defualtFilterValue = null;	
					let defaultVal = allAvailability.length > 0 && allAvailability.find(p => Number(p.code) == -1000);
					selectedAvailability = !_isEmpty(defaultVal) ? [defaultVal.code] : [];
					this.setState({
						...this.state,
						selectedAvailability
					});
				}
				setTimeout(() => {
					Object.keys(this.chemicalAvailFilterRef.current).forEach(
						(index) => {
							if (defualtFilterValue != null && index.toString().toLowerCase() === defualtFilterValue.toLowerCase()) {
								if (!this.chemicalAvailFilterRef.current[index].checked) {
									this.chemicalAvailFilterRef.current[index].click();
								}
							}
						}
					);
				}, 1000);
			}
		}
	}


	setDefaultAvailFilter = () => {
		const userPreferenceDetail = getPreferenceDataFromStorage();
		let selectedFilter = {};
		if (!_isEmpty(userPreferenceDetail)) {
			selectedFilter = _find(userPreferenceDetail, {
				code: 'Product Information',
				text: 'Stock Availability'
			});
		}
		return selectedFilter && selectedFilter.answer && selectedFilter.answer.code;
	};

	handleExpansionPanel = (event, singleItem) => {
		let { categorySuppliesMap } = this.state;
		let matchingCategory = categorySuppliesMap.find((data) => {
			return data.code === singleItem.code;
		});
		matchingCategory.active = !matchingCategory.active;
		this.setState({
			categorySuppliesMap,
		});
	};

	handleCBExpansionPanel = (event, singleItem, key) => {
		let { suppliesCBMap } = this.state;
		suppliesCBMap[key].active = !suppliesCBMap[key].active;
		this.setState({
			suppliesCBMap,
		});
	};

	handleBrandsChange = (c, s) => {
		let {
			dispatch,
			order,
			sortBy,
			pageNumber,
			pageSize,
			reqBrands,
			suppliesReqAvailability,
			suppliesOffers,
		} = this.props;
		console.log('change brand ==> ', c, s);

		let { brandCodes, pimCategoryIds, suppliesReqGrpIds } = this.state;
		if (
			pimCategoryIds.includes(c.chemicalCategory.pimID) &&
			brandCodes.includes(s.code)
		) {
			_remove(pimCategoryIds, (p) => p === c.chemicalCategory.pimID);
			_remove(brandCodes, (b) => b === s.code);
		} else {
			pimCategoryIds.push(c.chemicalCategory.pimID);
			brandCodes.push(s.code);
		}
		dispatch(
			getChemPartByCat(
				pimCategoryIds,
				brandCodes,
				suppliesReqGrpIds,
				pageNumber,
				pageSize,
				sortBy,
				order,
				reqBrands,
				suppliesReqAvailability,
				suppliesOffers,
				null,
				null,
				null,
				null,
				null,
				null,
				{},
				false
			)
		);
	};

	handleSearchError = (query,resultfound) => {
		const sv= this.props.selectedVehicle;
		const parts = this.props.parts;
	   const errorData = {
			 source: "Chemicals - Default",
			 searchvalue: query,
			 resultFound: resultfound === "resultFound"? true : false,
			 selectedVehicle  : sv ? `${sv?.year} ${sv?.make} ${sv?.model} ${sv?.engine}`: null,	
		   filters:{
		 groupCode: this.props.suppliesReqGrpIds,
		 brand:this.props.brandsSelected,
		 // position:parts?.catalogFilter?.position,
		 availablity:this.props.suppliesReqAvailability,
	 
		 },
	
	   };
	   this.logUserSearchFailureActivity(errorData,resultfound)
	 };
	   logUserSearchFailureActivity = (storedErrors,resultfound) => {
			  const { x2userName, shipTos, billTo } = this.props.userDetails;
			  if (!_isEmpty(this.props.userDetails) && !_isEmpty(billTo)) {
				let loginId = "";
				let shipToId = shipTos[0]?.shipToId;
				let userData = JSON.stringify(storedErrors);
				let type=resultfound === "resultFound"? "Search Result Found - Not Added To Cart"  :"Search - No Result";
			  // const {searchValue}= sourceAlreadyCalled;
				// if(searchValue !== storedErrors.searchvalue ){
				//   setSourceAlreadyCalled({source:storedErrors.source,searchValue:storedErrors.searchvalue})
				this.setState({
				  sourceAlreadyCalled: {
					source: storedErrors.source,
					searchValue: storedErrors.searchvalue,
				  },
				});
				
				  this.setState({})
				this.props.dispatch(
				logUserStockActivity(
				  timeInUTC(),
				  billTo.billToId,
				  loginId,
				  shipToId,
				  type,
				  userData,
				  x2userName
				)
				);
				// }
			  }
	   };

	handleFilter = (value, type) => {
		const selectedData = this.props.reqBrands && this.props.reqBrands[0];
		let {
			allAvailability,
			selectedBrands,
			suppliesReqGrpIds,
			selectedAvailability,
			selectedAvailabilityRequest,
			selectedOffers,
			brandCodes,
			pimCategoryIds,
			selectedSizeQty,
			selectedCaseQty,
			selectedSpecs,
		} = this.state;
		let {
			brandsView,
			dispatch,
			pageSize,
			pageNumber,
			sortBy,
			order,
			reqBrands,
			shipMethod,
			selectedStore,
			sellPartnerId
		} = this.props;
		let apiRequest = true; // api will call only if the existing request has any modification
		if (type === 'brand') {
			selectedBrands.includes(value)
				? _remove(selectedBrands, (b) => b === value)
				: selectedBrands.push(value);
		} else if (type === 'availability') {
			if (selectedAvailability.includes(value.code)) {
				apiRequest = selectedAvailability.length > 1 ? true : false;
				if (value.code === 'inStock' &&  selectedAvailability.length > 1) {
					selectedAvailability = [];
				} else if (selectedAvailability.length > 1) {
					_remove(
						selectedAvailability,
						(a) => a === value.code || a === 'inStock'
					);
				}
			} else {
				if (value.code === 'inStock') {
					const excludeOutOfStock = _filter(
						_map(allAvailability, 'code'),
						(a) => Number(a) !== -100
					);
					selectedAvailability = [value.code];
					selectedAvailabilityRequest = [...excludeOutOfStock];
				} else {
					const excludeOutOfStock = this.state.allAvailability.filter(
						(p) => p !== 'Out of stock'
					);
					if (
						_isEqual(
							excludeOutOfStock.sort(),
							[...selectedAvailabilityRequest, value].sort()
						)
					) {
						selectedAvailability = [value];
						selectedAvailabilityRequest = [
							...selectedAvailabilityRequest,
							value,
							'inStock',
						];
					} else {
						selectedAvailability = [
							value.code
						];
						selectedAvailabilityRequest = [
							value.code,
						];
					}
				}
			}
		} else if (type === 'cas') {
			selectedOffers.includes(value)
				? _remove(selectedOffers, (s) => s === value)
				: selectedOffers.push(value);
		} else if (type === 'sizeQty') {
			selectedSizeQty.includes(value)
				? _remove(selectedSizeQty, (s) => s === value)
				: selectedSizeQty.push(value);
		} else if (type === 'caseQty') {
			selectedCaseQty.includes(value)
				? _remove(selectedCaseQty, (s) => s === value)
				: selectedCaseQty.push(value);
		} else if (type === 'specs') {
			selectedSpecs.includes(value)
				? _remove(selectedSpecs, (s) => s === value)
				: selectedSpecs.push(value);
		}
		if (brandsView && apiRequest) {
			dispatch(
				getChemPartByCat(
					pimCategoryIds,
					brandCodes,
					suppliesReqGrpIds,
					pageNumber,
					pageSize,
					sortBy,
					order,
					selectedBrands,
					_filter(selectedAvailabilityRequest, (a) => a !== 'inStock'),
					selectedOffers,
					selectedSpecs,
					selectedSizeQty,
					selectedCaseQty,
					sellPartnerId,
					selectedStore,
					shipMethod,
					{},
					true
				)
			);
		} else if(apiRequest) {
			setTimeout(async()=>{
				try{
					const response = await dispatch(
				getChemPartByGrp(
					suppliesReqGrpIds,
					pageNumber,
					pageSize,
					sortBy,
					order,
					selectedBrands,
					_filter(selectedAvailabilityRequest, (a) => a !== 'inStock'),
					selectedOffers,
					selectedSpecs,
					selectedSizeQty,
					selectedCaseQty,
					[],
					sellPartnerId,
					selectedStore,
					shipMethod,
					{},
					true
					));	
					const totalItems = response?.data?.totalItems || 0;	
					setTimeout(()=>{	
					  if (totalItems <= 0) {	
						this.handleSearchError(JSON.stringify(selectedData,"resultNotFound"));	
					  }else{	
						this.props.dispatch(addSearchedValue(selectedData))	
						// this.isSearchValueAddedToCart(this.props.suppliesSelectedGroups);	
					  }	
					},[1000])	
				  }catch(error){	
					this.handleSearchError(selectedData,"resultNotFound");	
				  }},1500);

		}
		this.setState({
			...this.state,
			selectedAvailability,
			selectedAvailabilityRequest,
			selectedBrands,
			selectedCaseQty,
			selectedSizeQty,
			selectedSpecs,
			reqBrands,
			selectedOffers,
		});
	};

	handleGroupChange = (g, c, e) => {
		let { suppliesReqGrpIds } = this.state;
	
		if (g === "all") {
		  if (e.target.checked === false) {
			let grpIdFilter = c.groups.map((g) => {
			  return g.id;
			});
			suppliesReqGrpIds = suppliesReqGrpIds.filter(
			  (item) => !grpIdFilter.includes(item)
			);
		  } else {
			c.groups.map((g) => suppliesReqGrpIds.push(g.id));
		  }
		} else {
		  if (suppliesReqGrpIds.includes(g.id))
			suppliesReqGrpIds = suppliesReqGrpIds.filter((item) => item !== g.id);
		  else suppliesReqGrpIds.push(g.id);
		}
	
		this.setState({
		  ...this.state,
		  suppliesReqGrpIds,
		});
	  };
	
	handleApplyEnable = (c, suppliesReqGrpIds) => {
		let contains = false;
		let grpIdFilter = c.groups.map((g) => {
		  return g.id;
		});
		contains = suppliesReqGrpIds.some((element) => {
		  return grpIdFilter.includes(element);
		});
		return contains;
	  };
	  applyGroupSelect = () => {
		let { shipMethod, selectedStore, sellPartnerId } = this.props;
		let { suppliesReqGrpIds, selectedBrands } = this.state;
		let { dispatch, sortBy, order, pageSize, suppliesReqAvailability } =
		  this.props;
		  const selectedData =this.props.reqBrands  || this.props.selectedPartType
		this.setState({
		  ...this.state,
		  selectedBrands,
		});
		// dispatch({
		//   payload: {
		//     bool: true,
		//   },
		//   type: "CHEMICAL_CATEGORY_CHANGED",
		// });
		setTimeout(async()=>{
			try{
				const response = await dispatch(getChemPartByGrp(
			suppliesReqGrpIds,
			1,
			pageSize,
			sortBy,
			order,
			selectedBrands.toString(),
			suppliesReqAvailability,
			null,
			null,
			null,
			null,
			[],
			sellPartnerId,
			selectedStore,
			shipMethod,
			{},
			false
		  )
		);
		const totalItems = response?.data?.totalItems || 0;
		setTimeout(()=>{
		  if (totalItems <= 0) {
			this.handleSearchError(JSON.stringify(selectedData,"resultNotFound"));
		}else{
			this.props.dispatch(addSearchedValue(selectedData))
			// this.isSearchValueAddedToCart(this.props.suppliesReqGrpIds);
		  }
		},[1000])
	  }catch(error){
		this.handleSearchError(selectedData,"resultNotFound");
	  }},1500);

	}
	  handleGroupAllCheck = (c, suppliesReqGrpIds) => {
		let tempArray = [];
		suppliesReqGrpIds = _uniq(suppliesReqGrpIds);
		suppliesReqGrpIds.forEach((_sg) => {
		  c.groups.map((g) => {
			if (g.id === _sg) {
			  tempArray.push(g.id);
			}
		  });
		});
		if (tempArray.length === c.groups.length) {
		  return true;
		}
		return false;
	  };

	  renderGroupFilter = (c) => {
		let { suppliesReqGrpIds } = this.state;
		return (
		  <ul>
			<li>
			  <OverlayTrigger
				placement="right"
				delay={{ show: 100, hide: 400 }}
				overlay={<Tooltip>Apply your changes</Tooltip>}
			  >
				<Button
				  variant="outline-secondary"
				  className={
					this.handleApplyEnable(c, suppliesReqGrpIds)
					  ? "brand-apply-button"
					  : "disable-brand-apply"
				  }
				  onClick={(e) => {
					this.applyGroupSelect(e);
				  }}
				>
				  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
				</Button>
			  </OverlayTrigger>
			</li>
			<li>
			  <InputGroup>
				<InputGroup.Checkbox
				  id="all"
				  name="all"
				  value="all"
				  onChange={(e) => {
					this.handleGroupChange("all", c, e);
				  }}
				  checked={this.handleGroupAllCheck(c, suppliesReqGrpIds)}
				/>
				<label for={"All"}>All</label>
			  </InputGroup>
			</li>
			{!_isEmpty(c.groups) &&
			  c.groups.map((g, kc) => {
				return (
				  <li>
					<InputGroup>
					  <InputGroup.Checkbox
						id={g.code}
						name={g.code}
						value={g.code}
						onClick={() => {
						  this.handleGroupChange(g);
						}}
						checked={
						  suppliesReqGrpIds
							? suppliesReqGrpIds.includes(g.id)
							: false
						}
					  />
					  <label for={g.id}>{g.name}</label>
					</InputGroup>
				  </li>
				);
			  })}
		  </ul>
		);
	  };
	renderAvailabilityFilter = () => {
		let { avbAccExp, allAvailability, selectedAvailability } = this.state;
		allAvailability = _filter(allAvailability, (p) => Number(p.code) !== Number(-1000));
		let { userDetails } = this.props;
		let customer = userDetails && userDetails.billTo && userDetails.billTo.mclSupplier;
		return (
			<Card>
				<Accordion.Toggle
					as={Card.Header}
					eventKey="2"
					onClick={() =>
						this.setState({ avbAccExp: !this.state.avbAccExp })
					}
					className={
						selectedAvailability.length > 0 ? 'active' : null
					}
				>
					Availability
					<span
						class={`${avbAccExp ? 'filterSignMinus' : 'filterSignPlus'
							}`}
					/>
				</Accordion.Toggle>
				{!_isEmpty(allAvailability) && (
					<Accordion.Collapse eventKey="2">
						<Card.Body className="chemical-availfilter">
							<ul>
								{/* <li>
								  <label class="customized-radio-button-avail-filter">
										<input
											ref={el => this.chemicalAvailFilterRef.current['InStock'] = el}
											type='checkbox'
											label={'In Stock'}
											id={`inStock`}
											name={`inStock`}
											value={`Out of stock`}
											onClick={(e) => {
												this.handleFilter(
													{ code: 'inStock' },
													'availability',
													e
												);
											}}
											checked={selectedAvailability.includes(
												'inStock'
											)}
										/>
									 <span>In Stock</span>
					                </label>
								</li> */}
								{customer !== 'CD' &&
								allAvailability.map((a, index) => {
									return (
										a.availabilityText.toUpperCase() !==
										'OUT OF STOCK' && (
											<li>
												<label class="customized-radio-button-avail-filter">
								                        <input
														ref={el => this.chemicalAvailFilterRef.current[a.availabilityText.replace(/ +/g, "").toLowerCase()] = el}
														type='checkbox'
														label={a.availabilityText}
														id={a.availabilityText}
														name={
															a.availabilityText
														}
														value={
															a.availabilityText
														}
														onClick={() => {
															this.handleFilter(
																a,
																'availability'
															);
														}}
														checked={selectedAvailability.includes(
															a.code
														)}
													/>
												 <span>{a.availabilityText}</span>
							                   </label>
											</li>
										)
									);
								})}
								{customer == 'CD' &&
									allAvailability.map((a, index) => {
										return (
											a.availabilityText.toUpperCase() !== 'OUT OF STOCK' && (
												<li>
													<label class="customized-radio-button-avail-filter">
														<input
															ref={(el) =>
															(this.chemicalAvailFilterRef.current[
																a.availabilityText.replace(/ +/g, '').toLowerCase()
															] = el)}
															id={a.availabilityText}
															type="radio"
															label={a.availabilityText}
															name={a.availabilityText}
															value={a.availabilityText}
															onClick={() => {
																this.handleFilter(a, 'availability');
															}}
															checked={selectedAvailability.includes(a.code)}
														/>
														<span>{a.availabilityText}</span>
													</label>
												</li>
											)
										);
									})}
							</ul>
						</Card.Body>
					</Accordion.Collapse>
				)}
			</Card>
		);
	}

	renderCategoryFilter = () => {
		let { catAccExp, categorySuppliesMap, suppliesReqGrpIds } = this.state;
		return (
			<Card>
				<Accordion.Toggle
					as={Card.Header}
					eventKey="1"
					onClick={() =>
						this.setState({ catAccExp: !this.state.catAccExp })
					}
					className={suppliesReqGrpIds.length > 0 ? 'active' : null}
				>
					Categories
					<span
						class={`${catAccExp ? 'filterSignMinus' : 'filterSignPlus'
							}`}
					/>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="1">
					<Card.Body>
						{!_isEmpty(categorySuppliesMap) &&
							categorySuppliesMap.map((c, k) => {
								return (
									<Accordion
										className="accordion xlp-accordian"
										activeKey={c.active ? c.code : ''}
									>
										<Accordion.Toggle
											as={Card.Header}
											eventKey={c.code}
											onClick={(e) =>
												this.handleExpansionPanel(e, c)
											}
											className={
												_size(
													_intersection(
														suppliesReqGrpIds,
														_map(c.groups, 'id')
													)
												) > 0
													? 'active'
													: null
											}
										>
											{c.name}
											<span
												// onClick={(e) =>
												// 	this.handleExpansionPanel(
												// 		e,
												// 		c
												// 	)
												// }
												class={`${c.active
														? 'filterSignMinus'
														: c.active ===
															undefined &&
															_size(
																_intersection(
																	suppliesReqGrpIds,
																	_map(
																		c.groups,
																		'id'
																	)
																)
															) > 0
															? 'filterSignMinus'
															: 'filterSignPlus'
													}`}
											/>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey={c.code}>
											<Card.Body>
												{this.renderGroupFilter(c)}
											</Card.Body>
										</Accordion.Collapse>
									</Accordion>
								);
							})}
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		);
	};

	renderBrandsViewBrandsFilter = (c) => {
		let { brandCodes } = this.state;
		return (
			<ul>
				{!_isEmpty(c.suppliers) &&
					c.suppliers.map((s, kc) => {
						return (
							<li>
								<InputGroup>
									<InputGroup.Checkbox
										id={s.code}
										name={s.code}
										value={s.code}
										onClick={() => {
											this.handleBrandsChange(c, s);
										}}
										checked={
											brandCodes
												? brandCodes.includes(s.code)
												: false
										}
									/>
									<label for={s.code}>{s.name}</label>
								</InputGroup>
							</li>
						);
					})}
			</ul>
		);
	};

	renderBrandsViewCatFilter = () => {
		let { brandsViewAccExp, suppliesCBMap, brandCodes } = this.state;
		return (
			<Card>
				<Accordion.Toggle
					as={Card.Header}
					eventKey="0"
					onClick={() =>
						this.setState({
							brandsViewAccExp: !this.state.brandsViewAccExp,
						})
					}
				>
					Categories
					<span
						class={`${brandsViewAccExp
								? 'filterSignMinus'
								: 'filterSignPlus'
							}`}
					/>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						{!_isEmpty(suppliesCBMap) &&
							suppliesCBMap.map((c, k) => {
								return (
									<Accordion className="accordion xlp-accordian">
										<Accordion.Toggle
											as={Card.Header}
											eventKey={c.chemicalCategory.id}
											onClick={(e) =>
												this.handleCBExpansionPanel(
													e,
													c,
													k
												)
											}
											className={
												_size(
													_intersection(
														brandCodes,
														_map(
															c.suppliers,
															'code'
														)
													)
												) > 0
													? 'active'
													: null
											}
										>
											{c.chemicalCategory.name}
											<span
												// onClick={(e) =>
												// 	this.handleCBExpansionPanel(
												// 		e,
												// 		c,
												// 		k
												// 	)
												// }
												class={`${c.active
														? 'filterSignMinus'
														: 'filterSignPlus'
													}`}
											/>
										</Accordion.Toggle>
										<Accordion.Collapse
											eventKey={c.chemicalCategory.id}
										>
											<Card.Body>
												{this.renderBrandsViewBrandsFilter(
													c
												)}
											</Card.Body>
										</Accordion.Collapse>
									</Accordion>
								);
							})}
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		);
	};

	renderCaseQtyFilter = () => {
		let { csQtyAccExp, selectedCaseQty } = this.state;
		let { caseQty } = this.props;
		return (
			<Card>
				<Accordion.Toggle
					onClick={(e) =>
						this.setState({ csQtyAccExp: !csQtyAccExp })
					}
					as={Card.Header}
					eventKey="6"
					className={
						selectedCaseQty && selectedCaseQty.length > 0
							? 'active'
							: null
					}
				>
					Case Quantity
					<span
						onClick={(e) =>
							this.setState({ csQtyAccExp: !csQtyAccExp })
						}
						class={`${csQtyAccExp ? 'filterSignMinus' : 'filterSignPlus'
							}`}
					></span>
				</Accordion.Toggle>
				{!_isEmpty(caseQty) && (
					<Accordion.Collapse eventKey="6">
						<Card.Body>
							<ul>
								{caseQty.map((c) => {
									return (
										<li>
											<InputGroup>
												<InputGroup.Checkbox
													id={c}
													name={c}
													value={c}
													onClick={() => {
														this.handleFilter(
															c,
															'caseQty'
														);
													}}
													checked={selectedCaseQty.includes(
														c
													)}
												/>
												<label for={c}>{c}</label>
											</InputGroup>
										</li>
									);
								})}
							</ul>
						</Card.Body>
					</Accordion.Collapse>
				)}
			</Card>
		);
	};
	renderSizeQtyFilter = () => {
		let { szQtyAccExp, selectedSizeQty } = this.state;
		let { sizeQty } = this.props;
		return (
			<Card>
				<Accordion.Toggle
					onClick={(e) =>
						this.setState({ szQtyAccExp: !szQtyAccExp })
					}
					as={Card.Header}
					eventKey="6"
					className={
						selectedSizeQty && selectedSizeQty.length > 0
							? 'active'
							: null
					}
				>
					Size Quantity
					<span
						onClick={(e) =>
							this.setState({ szQtyAccExp: !szQtyAccExp })
						}
						class={`${szQtyAccExp ? 'filterSignMinus' : 'filterSignPlus'
							}`}
					></span>
				</Accordion.Toggle>
				{!_isEmpty(sizeQty) && (
					<Accordion.Collapse eventKey="6">
						<Card.Body>
							<ul>
								{sizeQty.map((c) => {
									return (
										<li>
											<InputGroup>
												<InputGroup.Checkbox
													id={c}
													name={c}
													value={c}
													onClick={() => {
														this.handleFilter(
															c,
															'sizeQty'
														);
													}}
													checked={selectedSizeQty.includes(
														c
													)}
												/>
												<label for={c}>{c}</label>
											</InputGroup>
										</li>
									);
								})}
							</ul>
						</Card.Body>
					</Accordion.Collapse>
				)}
			</Card>
		);
	};

	renderSpecsFilter = () => {
		let { spcsAccExp, selectedSpecs } = this.state;
		let { specsList } = this.props;
		return (
			<Card>
				<Accordion.Toggle
					onClick={(e) => this.setState({ spcsAccExp: !spcsAccExp })}
					as={Card.Header}
					eventKey="6"
					className={
						selectedSpecs && selectedSpecs.length > 0
							? 'active'
							: null
					}
				>
					Specifications
					<span
						onClick={(e) =>
							this.setState({ spcsAccExp: !spcsAccExp })
						}
						class={`${spcsAccExp ? 'filterSignMinus' : 'filterSignPlus'
							}`}
					></span>
				</Accordion.Toggle>
				{!_isEmpty(specsList) && (
					<Accordion.Collapse eventKey="6">
						<Card.Body>
							<ul>
								{specsList.map((c) => {
									return (
										<li>
											<InputGroup>
												<InputGroup.Checkbox
													id={c}
													name={c}
													value={c}
													onClick={() => {
														this.handleFilter(
															c,
															'specs'
														);
													}}
													checked={selectedSpecs.includes(
														c
													)}
												/>
												<label for={c}>{c}</label>
											</InputGroup>
										</li>
									);
								})}
							</ul>
						</Card.Body>
					</Accordion.Collapse>
				)}
			</Card>
		);
	};

	handleBrandSelect = (value) => {
		let { selectedBrands } = this.state;
		let {
		  dispatch,
		  pageSize,
		  pageNumber,
		  sortBy,
		  order,
		  suppliesReqGrpIds,
		  suppliesAllBrands,
		} = this.props;
		dispatch({
		  payload: {
			bool: false,
		  },
		  type: "CHEMICAL_CATEGORY_CHANGED",
		});
		let supplyBrands = _cloneDeep(suppliesAllBrands);
		if (value === "all") {
		  if (selectedBrands.includes(value)) {
			selectedBrands = [];
		  } else {
			selectedBrands = supplyBrands;
			selectedBrands.push(value);
		  }
		} else if (selectedBrands.includes(value)) {
		  if (_filter(selectedBrands, (b) => b !== "all").length == 1) {
			selectedBrands = supplyBrands;
			selectedBrands.push("all");
		  } else {
			_remove(selectedBrands, (b) => b === value);
			_remove(selectedBrands, (b) => b === "all");
		  }
		} else {
		  selectedBrands.push(value);
		  if (
			supplyBrands.length ===
			_filter(selectedBrands, (b) => b !== "all").length
		  ) {
			selectedBrands.push("all");
		  }
		}
		let selectedBrandsRequestArray = _cloneDeep(selectedBrands);
	
		this.setState({
		  ...this.state,
		  selectedBrands,
		  brandFilterChanged: true
		});
	  };

	applyBrandSelect = (e) => {
	  		let {
				dispatch,
				pageSize,
				pageNumber,
				sortBy,
				order,
				suppliesReqGrpIds,
				suppliesAllBrands,
				shipMethod,
			    selectedStore,
			    sellPartnerId
			} = this.props;
			let {
				selectedBrands,
				selectedAvailabilityRequest
			} = this.state;
			const selectedData =this.props.suppliesSelectedGroups|| this.props.selectedPartType
			setTimeout(async()=>{
				try{
					const response = await dispatch(getChemPartByGrp(	
					suppliesReqGrpIds,
					pageNumber,
					pageSize,
					sortBy,
					order,
					selectedBrands.toString(),
					_filter(selectedAvailabilityRequest, (a) => a !== 'inStock'),
					null,
					null,
					null,
					null,
					[],
					sellPartnerId,
                    selectedStore,
                    shipMethod,
					{},
				    false

				)
			);
			const totalItems = response?.data?.totalItems || 0;
		setTimeout(()=>{
		  if (totalItems <= 0) {
			this.handleSearchError(JSON.stringify(selectedData,"resultNotFound"));
		}else{
			this.props.dispatch(addSearchedValue(selectedData))
			// this.isSearchValueAddedToCart(this.props.suppliesReqGrpIds);
		  }
		},[1000])
	  }catch(error){
		this.handleSearchError(selectedData,"resultNotFound");
		  }},1500);

			this.setState({
				...this.state,
				selectedBrands,
			});
	}
	isSearchValueAddedToCart=(option)=>{
		const searchedValues = this.props?.searchedValues ;
			if (searchedValues.length === 0 ) {
				// Handle empty arrays as needed
				return false;
			  }
		if(searchedValues.length >= 1){
			if(this.props.parts.isSearchedValuesAddedToCart){
				this.props.dispatch({
					payload: {selectedAddCartData:this.props.parts.selectedAddCartData,
	
						isSearchedValuesAddedToCart: false,
					},
					type: actionTypes.SEARCHED_ADDTOCART_DATA,})
			}else{
				this.handleSearchError(JSON.stringify(searchedValues[searchedValues.length-1]),"resultFound");
			}
		 }
	
		}

	renderBrands = () => {
		let { selectedBrands } = this.state;
		console.log('', _sortBy(this.props.suppliesAllBrands));
		return (
			<Accordion
				className="accordion xlp-accordian"
				defaultActiveKey="-1"
			>
				<Card>
					<Accordion.Toggle
						as={Card.Header}
						eventKey="-1"
						onClick={(e) =>
							this.setState({
								brandsAccExp: !this.state.brandsAccExp,
							})
						}
						className={
							this.state.selectedBrands &&
								this.state.selectedBrands.length > 0
								? 'active'
								: null
						}
					>
						Brands
						<span
							class={`${this.state.brandsAccExp
									? 'filterSignMinus'
									: 'filterSignPlus'
								}`}
						></span>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="-1">
						<Card.Body>
							{!_isEmpty(this.props.suppliesAllBrands) && (
								<ul>
									<li>
										<OverlayTrigger
											placement="right"
											delay={{ show: 100, hide: 400 }}
											overlay={
												<Tooltip>
													Apply your changes
											</Tooltip>
											}
										>
											<Button variant="outline-secondary" className={this.state.selectedBrands.length == 0 ? 'disable-brand-apply' : 'brand-apply-button'} onClick={(e) => { this.applyBrandSelect(e); }}>
												<i class="fa fa-angle-double-right" aria-hidden="true"></i>
											</Button>
										</OverlayTrigger>
									</li>
									<li>
										<InputGroup>
											<InputGroup.Checkbox
												id="all"
												name="all"
												value="all"
												onClick={() => {
													this.handleBrandSelect(
														"all",
														'brand'
													);
												}}
												checked={this.state.selectedBrands.includes(
													"all"
												)}
											/>
											<label for="all">
												All
											</label>
										</InputGroup>
									</li>
									{_sortBy(this.props.suppliesAllBrands).map(
										(brand) => {
											return (
												<li>
													<InputGroup>
														<InputGroup.Checkbox
															id={brand}
															name={brand}
															value={brand}
															onClick={() => {
																this.handleBrandSelect(
																	brand,
																	'brand'
																);
															}}
															checked={this.state.selectedBrands.includes(
																brand
															)}
														/>
														<label for={brand}>
															{brand}
														</label>
													</InputGroup>
												</li>
											);
										}
									)}
								</ul>
							)}
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		);
	};

	render() {
		let { brandsView, sizeQty, caseQty, specsList } = this.props;
		let {
			casAccExp,
			selectedOffers,
			suppliesCBMap,
			suppliesAllDetails,
		} = this.state;

		return (
			<Col md={3} lg={2} className="left-section filters__panel">
				{!_isEmpty(suppliesAllDetails) && (
					<Fragment>
						{this.renderBrands()}
						{/* Availability filter */}
						<Accordion
							className="accordion xlp-accordian"
							defaultActiveKey="2"
						>
							{this.renderAvailabilityFilter()}
						</Accordion>
						{/* Combos and special filter */}
						<Accordion className="accordion xlp-accordian">
							<Card>
								<Accordion.Toggle
									onClick={(e) =>
										this.setState({ casAccExp: !casAccExp })
									}
									as={Card.Header}
									eventKey="5"
									className={
										selectedOffers &&
											selectedOffers.length > 0
											? 'active'
											: null
									}
								>
									Combos & Specials
									<span
										onClick={(e) =>
											this.setState({
												casAccExp: !casAccExp,
											})
										}
										class={`${casAccExp
												? 'filterSignMinus'
												: 'filterSignPlus'
											}`}
									></span>
								</Accordion.Toggle>
								<Accordion.Collapse eventKey="5">
									<Card.Body>
										<ul>
											<li>
												<InputGroup>
													<InputGroup.Checkbox
														id={`Bundle`}
														name={`Bundle`}
														value={`Bundle`}
														onClick={() => {
															this.handleFilter(
																'Bundle',
																'cas'
															);
														}}
														checked={selectedOffers.includes(
															'Bundle'
														)}
													/>
													<label
														for={`Bundle`}
													>{`Bundle Pricing`}</label>
												</InputGroup>
											</li>
											<li>
												<InputGroup>
													<InputGroup.Checkbox
														id={`Family`}
														name={`Family`}
														value={`Family`}
														onClick={() => {
															this.handleFilter(
																'Family/Quantity',
																'cas'
															);
														}}
														checked={selectedOffers.includes(
															'Family/Quantity'
														)}
													/>
													<label
														for={`Family`}
													>{`Family/Quantity Pricing`}</label>
												</InputGroup>
											</li>
										</ul>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
						{/* Case Qty */}
						{caseQty && caseQty.length > 0 && (
							<Accordion className="accordion xlp-accordian">
								{this.renderCaseQtyFilter()}
							</Accordion>
						)}
						{/* Size Qty */}
						{sizeQty && sizeQty.length > 0 && (
							<Accordion className="accordion xlp-accordian">
								{this.renderSizeQtyFilter()}
							</Accordion>
						)}
						{/* SPecs accordion */}
						{specsList && specsList.length > 0 && (
							<Accordion className="accordion xlp-accordian">
								{this.renderSpecsFilter()}
							</Accordion>
						)}
					</Fragment>
				)}
				{/* {!_isEmpty(suppliesCBMap) && brandsView && (
					<Accordion
						className="accordion xlp-accordian"
						defaultActiveKey="0"
					>
						{this.renderBrandsViewCatFilter()}
					</Accordion>
				)} */}
				{/* Category View cateory filter */}

				<Accordion
					className="accordion xlp-accordian"
					defaultActiveKey="1"
				>
					{this.renderCategoryFilter()}
				</Accordion>
			</Col>
		);
	}
}
function mapStateToProps(state) {
	return {
		chemicalsGS: state.chemicalsGlobalSearch,
		allAvailability: state.parts.suppliesPartsAllAvailability,
		reqBrands: state.parts.suppliesReqBrands,
		brandCodes: state.parts.suppliesReqBrandCodes,
		brandsView: state.parts.brandsView,
		categorySuppliesMap: state.parts.categorySuppliesMap,
		order: state.parts.suppliesOrder,
		pageNumber: state.parts.suppliesPageNumber,
		pageSize: state.parts.suppliesPageSize,
		pimCategoryIds: state.parts.suppliesReqPimCategoryIds,
		sortBy: state.parts.suppliesSortBy,
		suppliesCBMap: state.parts.suppCBMap,
		suppliesAllBrands: state.parts.suppliesAllBrands,
		suppliesAllDetails: state.parts.suppliesAllDetails,
		suppliesReqGrpIds: state.parts.suppliesReqGrpIds,
		suppliesSelectedGroups: state.parts.suppliesSelectedGroups,
		suppliesOffers: state.parts.suppliesOffers,
		suppliesReqAvailability: state.parts.suppliesReqAvailability,
		specsList: state.parts.suppliesAllSpecs,
		caseQty: state.parts.suppliesAllCaseQty,
		sizeQty: state.parts.suppliesAllSizeQty,
		isChemicalCategoryChanged: state.parts.isChemicalCategoryChanged,
		userDetails: state.user.userDetails,
		searchedValues: state.globalSearch.searchedValues,
		selectedVehicle:state.mygarage.selectedVehicle

	};
}
export default connect(mapStateToProps)(ChemicalFilters);
