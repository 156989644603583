import React, { Fragment, useState, useEffect } from "react";
import { useSelector ,useDispatch} from "react-redux";
import { Overlay, Tab, TabContainer, Row, Col, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useRef } from "react";
import { getPreferenceDataFromStorage } from "../../../helpers/utils";
import userIcon from "../../../static/images/icons/red/user.png";
import Account from "../MyGarage/Account";
import UserManagementTab from "./UserManagementTab";
import ProgramsAndBenefitsTab from './ProgramsAndBenefitsTab';
import AccountChangeTab from "./AccountChangeTab";
import { getAllAccountConfigInfo } from "../../../components/User/UserManagement/usermanagement.thunk";

const MyAccount = (props) => {
  const userDetails = useSelector((state) => state.user.userDetails);
  const mygarage = useSelector((state) => state.mygarage);
  const sv = mygarage.selectedVehicle;
  const userType =
    userDetails && userDetails.userType ? userDetails.userType : null;
  const [isRootClose, setIsRootClose] = useState(true);
  const [show, setShow] = useState(false);
  const [canAccessAccount, setCanAccessAccount] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const target = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCloseOverlay = () => {
    setShow(false);
  };

  useEffect(() => {
    userType !== null &&
    (userType === "CUSTOMER_ADMIN" ||
      userType === "IT_ADMIN" ||
      userType === "OSR" ||
      userType === "DSM" ||
      userType === "SUPER_ADMIN" ||
      userType === "SMS_MITCHELL1")
      ? setCanAccessAccount(true)
      : setCanAccessAccount(false);
    if (userType !== null) {
      let userPreferenceDetail = getPreferenceDataFromStorage();
      if (!_.isEmpty(userPreferenceDetail)) {
        let grandAccessItemSet = userPreferenceDetail.find((item) => {
          return (
            item.text === "Grant Access To" && item.code === "User Management"
          );
        });

        if (grandAccessItemSet && grandAccessItemSet.multiAnswer) {
          grandAccessItemSet.multiAnswer.map((singleAnswer) => {
            if (singleAnswer.code == "account") {
              setCanAccessAccount(true);
            }
          });
        }
      }

      if (!_.isEmpty(userPreferenceDetail)) {
        let grandAccessItems = userPreferenceDetail.find((item) => {
          return item.text === "smsWebUser" && item.code === "User Management";
        });

        if (grandAccessItems && grandAccessItems.possibleAnswer) {
          grandAccessItems.possibleAnswer.map((singleAnswer) => {
            if (singleAnswer.default && userType === "SMS_WEB") {
              setCanAccessAccount(true);
            }
          });
        }
      }
    }
  }, [userDetails]);
  useEffect(() => {
    // Fetch data and update the state
    console.log("salesId",userDetails?.billTo.salesId)
    const queries = {
      // Define your query parameters here
      AccountName: userDetails?.billTo?.name || "",
      AccountNumber: userDetails?.xlPartAccountNumber || "",
      StoreNumber: userDetails?.shipTos[0]?.location?.storeNumber || "",
      OSR: `${userDetails?.billTo?.salesId +'-' +userDetails?.shipTos[0]?.salesman}`,
      EmailAddress: userDetails?.x2userName || "",
    };
    console.log("getAccountConfig", queries);
    const fetchData = async () => {
      try {
        dispatch(getAllAccountConfigInfo(queries)).then((action) => {
          if (action.payload) {
            setShowLoader(false);
          }
        });
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the fetchData function
  }, [dispatch, userDetails]);

  const handleRootClose = (e) => {
		const target = e.target;
		const isModalOpen = document.querySelectorAll('.modal.show');
		const isModalElement = target.parentElement.className.includes('modal');
		if (e && e.path) {
			for (let i = 0; i < e.path.length; i++) {
				const path = e.path[i];
				const className = path.className;

				if (className && className.includes('daterangepicker')) {
					return false;
				}
			}
		}

		if (isModalOpen.length === 0 && !isModalElement) {
			setShow(false);
		}
	};

  return (
    <Fragment>
      <div
        className="ml-auto my-garage-btn-wrapper"
        onClick={() => setShow(!show)}
        ref={target}
      >
        <div className="my-garage-btn-img-wrapper">
          <img src={userIcon} alt="UserIcon" />
        </div>
        <div className="my-garage-btn-text">
          <div className="title__line">
            <span className="user_fontsize">MY ACCOUNT </span>
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>

          <span className="userDetails_inmyaccount">
            {userDetails &&
            userDetails.shipTos &&
            userDetails.shipTos.length > 0 &&
            userDetails.shipTos[0]
              ? userDetails.shipTos[0].shipToId +
                " - " +
                userDetails.shipTos[0].name
              : ""}
          </span>
        </div>
      </div>
      <div className={true ? "active" : ""}>
        <Overlay
          target={target.current}
          show={show}
          placement="bottom"
          rootClose={isRootClose}
          onHide={handleRootClose}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              className="header_myaccount"
              {...props}
              style={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                color: "black",
                borderRadius: 0,
                minWidth: "40%",
                maxWidth: "45%",
                marginTop: "8px",
                boxShadow: "0 0 3px 0 #000",
                zIndex: 999,
                ...props.style,
              }}
            >
              <div className="v__tabs">
                <TabContainer
                  defaultActiveKey={
                    canAccessAccount ? "account" : "userManagement"
                  }
                >
                  <Row>
                    <Col md={12}>
                      <Nav variant="pills" className="flex-row">
                        {canAccessAccount ? (
                          <Nav.Item>
                            <Nav.Link eventKey="account">
                              Account Details
                            </Nav.Link>
                          </Nav.Item>
                        ) : null}
                        <Nav.Item>
                          <Nav.Link eventKey="userManagement">
                            User Management
                          </Nav.Link>
                        </Nav.Item>
                        {(userType === "CUSTOMER_ADMIN" ||
                          userType === "IT_ADMIN" ||
                          userType === "SUPER_ADMIN") && (
                            <Nav.Item>
                              <Nav.Link eventKey="accountChange">
                                Account Change Request
                              </Nav.Link>
                            </Nav.Item>
                          )}
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="account">
                          <Account closeOverlay={handleCloseOverlay} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="userManagement">
                          <UserManagementTab
                            handleCloseOverlay={handleCloseOverlay}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="programsBenefits">
													<ProgramsAndBenefitsTab handleCloseOverlay={handleCloseOverlay} />
												</Tab.Pane>
                        {(userType === "CUSTOMER_ADMIN" ||
                          userType === "IT_ADMIN" ||
                          userType === "SUPER_ADMIN") && (
                            <Tab.Pane eventKey="accountChange">
                              <AccountChangeTab
                                handleCloseOverlay={handleCloseOverlay}
                              />
                            </Tab.Pane>
                          )}
                      </Tab.Content>
                    </Col>
                  </Row>
                </TabContainer>
              </div>
            </div>
          )}
        </Overlay>
      </div>
    </Fragment>
  );
};

export default MyAccount;
