// IFramePage.js
import React,{useState} from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { Spinner } from 'react-bootstrap';

const AccountDynamicIFramePage = () => {
  const { url } = useParams();
  const location = useLocation();
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const link = location.state?.url || "";
  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };
  return (
    <div style={{ height: "100vh",marginTop:'9px' }}>
      <div className="container">
      {!iframeLoaded ? (
          <div className="custom_spinner_container">
            <Spinner className="custom_spinner" animation="border" />
          </div>
        ) : null} 
        <iframe
          className="accountchangeiframe mt-2"
          title="Embedded Content"
          src={decodeURIComponent(link)}
          width="100%"
          height="550"
          onLoad={handleIframeLoad}
        ></iframe>
      </div>
    </div>
  );
};

export default AccountDynamicIFramePage;
