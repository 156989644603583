import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  getVehiclePartImages,
  getVehicleServiceTypes,
  getVehicleServiceDrivingConditions,
  getVehicleServiceIntervals,
  getVechicleAvailableServiceJobs,
  getVechicleServiceJobsData,
  getVehicleModernServiceSpecificConditions,
  getVehicleModernServiceData,
  getVehicleCatalogPartData,
} from "./vehiclemaintenance.service";

export const getServiceTypes = createAsyncThunk(
  "getServiceTypes",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getVehicleServiceTypes(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getDrivingConditions = createAsyncThunk(
  "getDrivingConditions",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getVehicleServiceDrivingConditions(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getServiceIntervals = createAsyncThunk(
  "getServiceIntervals",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getVehicleServiceIntervals(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getAvailableServiceJobs = createAsyncThunk(
  "getServiceIntervals",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getVechicleAvailableServiceJobs(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getServiceJobsData = createAsyncThunk(
  "getServiceJobsData",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getVechicleServiceJobsData(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getModernServiceSpecificConditions = createAsyncThunk(
  "getModernServiceSpecificConditions",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getVehicleModernServiceSpecificConditions(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getModernServiceData = createAsyncThunk(
  "getModernServiceData",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getVehicleModernServiceData(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getPartCatlogData = createAsyncThunk(
  "getPartCatlogData",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getVehicleCatalogPartData(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

export const getPartImages = createAsyncThunk(
  "getPartImages",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await getVehiclePartImages(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);

