import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { triggerCustomUxCriticalError } from "../../actions/error";
import { addItemToCart2 } from "../../actions/cart";
import {
  distributeAvailability,
  formatAmount, formatTimeAvb, getExtendedTotalPrice,
  getShipInfo, getPartPackQty, displayQtyChangeMsg,
  getDistributionForNonVehicle, distributeAvailability2, handleKeyDown,
  enableBlackColorBasedOnStorePref,
  getCartShipDetails
} from "../../helpers/utils";
import { getStockProductListFromSkuNumber } from "./stockcheck.thunk";
import _ from "lodash";
import _isEmpty from "lodash/isEmpty";
import MagicWordTable from "./MagicWordTable";

const MagicWord = (props) => {
  let { handleSelectedPart, sourceName, shipInfoForMagic, checkoutDetails } = props;
  const dispatch = useDispatch();
  const [showMagicModal, setshowMagicModal] = useState(false);
  const [magicWordsColumns, setmagicWordsColumns] = useState([]);
  const [rowDataMagic, setrowData] = useState([]);
  const [showLoadermagic, setShowLoadermagic] = useState(true);
  const [magicTitle, setmagicTitle] = useState(null);
  const [enableOk, setEnableOk] = useState(true);
  const [partMagicRows, setPartMagicRows] = useState([]);
  const [partNumerEntered, setpartNumerEntered] = useState("");
  const userDetails = useSelector((state) => state.user.userDetails);
  const parts = useSelector((state) => state.parts);
  const hideAllPrice = parts && parts.hidePrice;
  const shipInfo = shipInfoForMagic ? shipInfoForMagic : getCartShipDetails(userDetails, checkoutDetails);
  let stockDetails = useSelector((state) => state.stockCheck.selectedProductList);
  const magicWordOptions = useSelector((state) => state.stockCheck.magicWordOptions);
  const cart = useSelector((state) => state.cart);
  const addItemcartFetching = useSelector((state) => state.cart.addItemcartFetching);
  const cartFetching = useSelector((state) => state.cart.cartFetching);
  let ismobileEnabled = userDetails?.shipTos[0]?.isMobileEnabled;
  const [selectedAvailabilityHours, setSelectedAvailabilityHours] = useState([]);
  const [magicOptions, setMagicWordOptions] = useState([]);
  const [preveousOrderQty, setPreveousOrderQty] = useState("");
  const [qtyValue, setQtyValue] = useState("");
  const [showWithinPopup, setShowWithinPopup] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const magicRef = useRef([]);

  useEffect(() => {
    const options = _.cloneDeep(magicWordOptions);
    setMagicWordOptions(options);
  }, [magicWordOptions]);

  useEffect(() => {
    constructMagicWordData();
  }, []);


  const headerStyle = {
    backgroundColor: "#0F5283",
    color: "white",
    "z-index": "999",
    width: "100px",
  };
  const headerExtendedStyle = {
    backgroundColor: "#0F5283",
    color: "white",
    "z-index": "999",
    width: "130px",
  };
  const headerAvailabilityStyle = {
    backgroundColor: "#0F5283",
    color: "white",
    "z-index": "999",
    width: "210px",
  };
  const QtyOrderStyle = {
    backgroundColor: "#0F5283",
    color: "white",
    "z-index": "999",
  };

  const descriptionHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    width: "210px",
  };


  const prepareRowData = (magicArray) => {
    partMagicRows.length = 0;
    let autofocusIndexNumber = 0;
    let iterator = true;
    magicArray.map((magicValue, index) => {
      if (iterator && magicValue.availability.length > 0) {
        autofocusIndexNumber = index; iterator = false;
      }
    });
    magicArray.map((magicValue, index) => {
      // if (magicValue.availability && magicValue.availability.length > 0) {
        partMagicRows.push({
          status: magicValue.status,
          isSub: magicValue.status === "3" ? true : false,
          isAlt: magicValue.status === "6" ? true : false,
          mfgCode: magicValue.mfgCode,
          partNum: magicValue.partNum,
          itemSpecific: magicValue.itemSpecific,
          costPrice: magicValue.costPrice,
          listPrice: magicValue.listPrice,
          availability: magicValue.availability,
          availabilityAll: magicValue.availability,
          corePrice: magicValue.corePrice,
          uniqId: Math.random(),
          coreListPrice: magicValue.coreListPrice,
          packQty: magicValue.packQty,
          selectedAvl: null,
          qtyBuyInc: magicValue.qtyBuyInc,
          qty: magicValue.availability.length > 0 ? 1 : "",
          addToOrder: magicValue.availability.length > 0 ? true : false,
          uniquedata: magicValue.partNum + magicValue.mfgCode + magicValue.status + index,
          totalAvailable: magicValue.totalAvailable,
          autoFocus: magicValue.availability.length > 0 && index === autofocusIndexNumber ? true : false,
        });
      // }
    });
    setPartMagicRows(partMagicRows);
    constructMagicWordData();
  };

  const handleMagicWordSelection = (partNumerEntered) => {
    setShowLoadermagic(true);
    setshowMagicModal(true);
    setmagicTitle(JSON.stringify(partNumerEntered.label));
    setpartNumerEntered(partNumerEntered);
    try {
      const payload = {
        request: {
          requestItems: [
            {
              mfgCode: "*",
              partNum: partNumerEntered.label,
              qty: 1,
            },
          ],
          sellPartnerId: shipInfo.sellerPartnerId ? shipInfo.sellerPartnerId : "",
          shipMethod: shipInfo.shipMethod ? shipInfo.shipMethod : "",
          store: shipInfo.storeNumber ? shipInfo.storeNumber : "",
          dispatch: dispatch,
        },
        uniqId: stockDetails.length,
      };

      dispatch(getStockProductListFromSkuNumber(payload)).then((result) => {
        setShowLoadermagic(false);
        if(!_.isEmpty(result.payload) && !_.isEmpty(result.payload.responseData)){
        let data = _.sortBy(result.payload.responseData,"mfgCode")
        prepareRowData(data);
        setpartNumerEntered("");
    }})
        .catch((error) => {
          console.log("error" + error);
        });
    } catch (error) { }
  };
  const availabilityColFormatter = (cell, row) => {
    if (row.qty >= 1 && row.availabilityAll.length !== 0) {
      let defaultTotalValue = !_.isEmpty(row.qty.toString()) ? row.qty : "";
      let orderArray = distributeAvailability(defaultTotalValue,row.availabilityAll);

      orderArray.map((item) => {
        selectedAvailabilityHours.push({
          availabilityHour: item.availableDuration,
          availableQty: item.availableQty,
          orderQty: item.orderQty,
        });
      });
      let availabilityOptions = [];
      orderArray.map((item) => {
        let time = item.availableDuration.split("");
        const optionText = (
          <li><span className={enableBlackColorBasedOnStorePref(shipInfo?.storeNumber) ? "" : "maroonColorTxt"}>
            <b>{item.orderQty}</b>
          </span>   
            {" "}{_.head(time) === "0" ? checkIfMobileEnabled(item, "Within") : ""}{" "}
            {_.head(time) === "0" ? formatTimeAvb(item.availableDuration) : checkIfMobileEnabled(item, item.availableDuration)}
          </li>);
        availabilityOptions.push(optionText);
      });
      return availabilityOptions;
    }
    let availabilityOptns = [];

    if (row.availabilityAll.length === 0) {
      return (
        <span>
          Currently out of stock
        </span>
      );
    }

    if (row.selectedAvl !== null) {
      if (row.selectedAvl.length > 0) {
        if (row.qty > 0) {
          row.selectedAvl.map((item) => {
            availabilityOptns.push(<li><b>{item}</b></li>);
          });
          return availabilityOptns;
        }
        else return <span></span>
      }
      if (row.qty !== null && row.qty > 0 && row.selectedAvl.length === 0) {
        return <span>Not Available</span>;
      }
    }
    return <span></span>;
  };

  const onclick = (tempData) => {
    setStoreData(tempData?.storeAvailability)
    setShowWithinPopup(true)
  }

  const checkIfMobileEnabled = (tempData, text) => {
    return (
      ismobileEnabled ?
        <u className="text-color" onClick={() => { onclick(tempData) }}>{text}</u> :
        <u>{text}</u>
    )
  }

  const availabilityColFormatterForCD = (cell, row) => {
    if (row.availabilityAll) {
      if (row.availabilityAll?.length === 0) {
        return <div className="out-of-stock"> <span>Currently out of stock</span> </div>;
      }
      let uniqueData = _.uniqBy(row.availabilityAll, "availableDuration");
      let enableOtherAvailabilities = uniqueData.length > 0 && uniqueData[0].availableQty && uniqueData[0].availableQty >= 1 ? false : true;
      let otherStoreAvailability = 0;
      uniqueData.map((unique, index) => {
        if (index != 0) {
          otherStoreAvailability += unique.availableQty;
        }
      });

      return (
        <div className="availability-container">
          <div className="availability-subdiv">
            {uniqueData.map((unique, index) => {
              return (
                (unique.availableDuration === "Your Store" ||
                  unique.availableDuration === "Store") && (
                  <ul>
                    <li>
                      <span className="availability-text">
                        {unique.availableQty == 0 ? (
                          <span className="store-maroon-text compact-text-grey-Zero">
                            <b>{unique.availableQty}</b>
                          </span>
                        ) : (
                          <span className="store-maroon-text compact-text-grey">
                            <b>{unique.availableQty}</b>
                          </span>
                        )}
                        &nbsp;at &nbsp;
                        {unique.availableDuration}{" "}
                        {unique.storeAvailabilities.length > 0 &&
                          unique.storeAvailabilities[0]?.store ? (
                          <b>#{unique.storeAvailabilities[0]?.store}</b>
                        ) : null}
                      </span>
                      {true && !enableOtherAvailabilities ? (
                        row.selectedQuantity &&
                          row.selectedQuantity > uniqueData[0].availableQty ? (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 4000 }}
                            show={row.selectedQuantity && row.selectedQuantity > uniqueData[0]?.availableQty}
                            overlay={
                              <Tooltip id="toggle-view-full-compact" className="custom-tooltip-availability apply-max-height">
                                {prepareTooltipAvailability(row.availabilityAll)}
                              </Tooltip>
                            }
                          >
                            <a className="i_icon-tooltip info-icon"> <i class="fa fa-info-circle"></i> </a>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 4000 }}
                            overlay={
                              <Tooltip
                                id="toggle-view-full-compact"
                                className="custom-tooltip-availability apply-max-height"
                              >
                                {prepareTooltipAvailability(row.availabilityAll)}
                              </Tooltip>
                            }
                          >
                            <a className="i_icon-tooltip info-icon">
                              <i class="fa fa-info-circle"></i>
                            </a>
                          </OverlayTrigger>
                        )
                      ) : null}
                    </li>
                  </ul>
                )
              );
            })}
            {true && enableOtherAvailabilities ? (
              <ul>
                <li>
                  <span className="availability-text">
                    <span className="store-maroon-text compact-text-green">
                      <b>{otherStoreAvailability}</b>
                    </span>
                    &nbsp;at Other Stores &nbsp;{" "}
                  </span>
                  {true && enableOtherAvailabilities ? (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 100, hide: 4000 }}
                      overlay={
                        <Tooltip
                          id="toggle-view-full-compact"
                          className="custom-tooltip-availability apply-max-height"
                        >
                          {prepareTooltipAvailability(row.availabilityAll)}
                        </Tooltip>
                      }
                    >
                      <a className="i_icon-tooltip info-icon">
                        <i class="fa fa-info-circle"></i>
                      </a>
                    </OverlayTrigger>
                  ) : null}
                </li>
              </ul>
            ) : null}
          </div>
        </div>
      );
    }
  };


  const prepareTooltipAvailability = (availabilityData) => {
    let availabilities = availabilityData;
    let returnArray = [];
    availabilities?.map((singleAvailability, key) => {
      if (singleAvailability.availableQty > 0) {
        if (
          singleAvailability.availableDuration === "Next Business Day" ||
          singleAvailability.availableDuration === "Next 2 + Business Days" ||
          singleAvailability.availableDuration === "Next 2 Weeks"
        ) {
          returnArray.push(
            <ul>
              <li>
                <span className="text-white">
                  <span className="store-maroon-text">
                    {singleAvailability.availableQty}
                  </span>
                  &nbsp; &nbsp;
                  {singleAvailability.availableDuration}{" "}
                </span>
              </li>
            </ul>
          );
        } else {
          singleAvailability.storeAvailabilities.map((storeData, storeKey) => {
            if (key != 0) {
              returnArray.push(
                <ul>
                  <li>
                    <span className="text-white">
                      <b>{storeData.storeQty}</b>
                    </span>
                    <span className="text-white">
                      &nbsp;{` at Store #`}
                      <b>{storeData.store}</b>
                      <br />
                      {singleAvailability.availableDuration ===
                        "Direct Order Store" ||
                        singleAvailability.availableDuration ==
                        "Pick Up Store" ? (
                        <b>
                          ({singleAvailability.availableDuration},
                          {storeData.storeQualifier
                            ? storeData.storeQualifier
                            : ""}
                          )
                        </b>
                      ) : null}
                    </span>
                  </li>
                </ul>
              );
            }
          });
        }
      }
    });
    let fullDiv = [];
    fullDiv = <div>{returnArray}</div>;
    return fullDiv;
  };

  const getProductQtyBasedonQtyByInc = (allAvailability, qty, qtyBuyInc) => {
    let totalAvailqty = 0;
    allAvailability.map((item) => { totalAvailqty += item.availableQty; });
    return getPartPackQty(qtyBuyInc, qty, totalAvailqty)
  }

  const closeAll = () => {
    setPartMagicRows([]);
    setrowData([]);
    setshowMagicModal(false);
    if (magicRef && magicRef.current) {
      magicRef.current.focus();
    }
  }

  useEffect(() => {
    if (sourceName === "chemical" && !addItemcartFetching && !cartFetching) {
      closeAll()
    }
  }, [addItemcartFetching, cartFetching]);

  const handleAddToCart = () => {
    let requestPayload = [];
    let cloneCart = _.cloneDeep(cart);

    if (!_.isEmpty(rowDataMagic)) {
      rowDataMagic.map((product) => {
        const allAvailabilityData = !_.isEmpty(product?.availability) ? product?.availability : [];
        let qty = getProductQtyBasedonQtyByInc(allAvailabilityData, product?.qty, product?.qtyBuyInc);
        if (product) {
          let tempObj = {
            availabilityHour: "",
            availableQty: 0,
            cartFlag: "no",
            corePrice: product.corePrice,
            coreListPrice: product.coreListPrice,
            costPrice: product.costPrice,
            desc: product.itemSpecific,
            listPrice: product.listPrice,
            mfgCode: product.mfgCode,
            qtyBuyInc: product.qtyBuyInc,
            packQty: product.packQty,
            partNum: product.partNum,
            qty: qty,
            vehicleInfo: "Non-Vehicle Specific",
            sellPartnerId: shipInfo.sellerPartnerId ? shipInfo.sellerPartnerId : "",
            shipMethod: shipInfo.shipMethod ? shipInfo.shipMethod : "",
            store: shipInfo.storeNumber ? shipInfo.storeNumber : "",
            orderType: "REGULAR"
          };
          let orderArray = [];
          cloneCart.checkoutDetails = requestPayload.length > 0 ? requestPayload : cloneCart.checkoutDetails;
          let distributionData = getDistributionForNonVehicle(product.partNum, product, product.availability, qty, cloneCart);
          orderArray = distributeAvailability2(Number(distributionData.totalQty), distributionData.filteredAvailability);
          if (product.addToOrder) {
            requestPayload = [];
            requestPayload.push(...distributionData.alreadyAddedItem);
            orderArray.map((availabilityData) => {
              tempObj = {
                ...tempObj,
                availabilityHour: availabilityData.availableDuration,
                availableQty: availabilityData.availableQty,
                qty: !_.isEmpty(availabilityData.orderQty.toString()) ? availabilityData.orderQty.toString() : "1",
                distStatus: "Completed",
                orderType: "REGULAR"
              };
              requestPayload.push(tempObj);
            });
          }
        }
      });
      let uniqueData = requestPayload.filter((ele, ind) => ind === requestPayload.findIndex(elem => elem.partNum === ele.partNum && elem.mfgCode === ele.mfgCode && (elem.vehicleInfo === ele.vehicleInfo) && (elem.availabilityHour === ele.availabilityHour)))
      addItemToCart2(uniqueData, dispatch);
    }
  };

  const handleQtySelection = (event, row, rowIndex) => {
    if (
      partMagicRows[rowIndex].qty > partMagicRows[rowIndex].totalAvailable) {
      partMagicRows[rowIndex].enteredQty = partMagicRows[rowIndex].qty;
      partMagicRows[rowIndex].qty = partMagicRows[rowIndex].totalAvailable
      setEnableOk(false);
    }
    constructMagicWordData();
  }

  const handleAvailabilitySelection = (event, row, rowIndex) => {
    row.enteredQty = 0;
    selectedAvailabilityHours.length = 0;
    let {
      target: { value },
    } = event;

    let defaultTotalValue = !_.isEmpty(value.toString()) ? value : "";
    let orderArray = distributeAvailability(
      defaultTotalValue,
      row.availabilityAll
    );

    orderArray.map((item) => {
      selectedAvailabilityHours.push({
        availabilityHour: item.availableDuration,
        availableQty: item.availableQty,
        orderQty: item.orderQty,
      });
    });
    let availabilityOptions = [];
    orderArray.map((item) => {
      let time = item.availableDuration.split("");
      const optionText = `${item.orderQty} ${_.head(time) === "0" ? "Within" : ""} ${formatTimeAvb(item.availableDuration)}`;
      availabilityOptions.push(optionText);
    });
    row.extendedPrice = value * row.yourprice;
    let totalAvailqty = 0;
    orderArray.map((item) => {
      const optionText = item.availableQty;
      totalAvailqty += optionText;
    });
    let previous = preveousOrderQty;
    if (!_.isEmpty(value)) {
      if (!_.isEmpty(value) && Number(value) !== 0) {
        let totalValue = getPartPackQty(row.qtyBuyInc, value, totalAvailqty);
        value = totalValue;
        setQtyValue(totalValue);
        if (previous != value && row.qtyBuyInc > 1) {
          qtyByINCUpdateSuccessMessage();
          previous = value;
        }
      } else {
        value = Number(value);
        previous = Number(value);
      }
      setPreveousOrderQty(previous);
    }
    row.qty = value;
    partMagicRows[rowIndex].qty = row.qty;
    partMagicRows[rowIndex].selectedAvl = availabilityOptions;
    partMagicRows[rowIndex].extendedPrice = getExtendedTotalPrice(row.corePrice, row.costPrice, row.qty);
    partMagicRows[rowIndex].autoFocus = true;
    partMagicRows[rowIndex].totalAvailable = totalAvailqty;
    if (partMagicRows[rowIndex].qty > partMagicRows[rowIndex].totalAvailable || partMagicRows[rowIndex].qty == "") {
      setEnableOk(true);
    } else {
      setEnableOk(false);
    }
    constructMagicWordData(partMagicRows);
    setSelectedAvailabilityHours(selectedAvailabilityHours);
  };

  const qtyByINCUpdateSuccessMessage = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = displayQtyChangeMsg();
    statusMessageProps.type = "success";
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  };

  const constructMagicWordData = () => {
    const columns = [
      {
        dataField: "autoFocus",
        text: "autoFocus",
        hidden: true,
      },
      {
        dataField: "mfgCode",
        text: "P/L",
        sort: true,
        editable: false,
        headerAlign: "center",
        headerStyle: QtyOrderStyle,
        formatter: function (cell, row) {
          return <span className="tooltip-add-to-cart">{row.mfgCode}</span>;
        },
      },

      {
        dataField: "partNum",
        text: "Item #",
        sort: true,
        editable: false,
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        headerStyle: QtyOrderStyle,
        formatter: function (cell, row) {
          return <span className="">{row.partNum}</span>;
        },
      },

      {
        dataField: "totalAvailable",
        text: "totalAvailable",
        hidden: true,
        formatter: function (cell, row, index) { },
      },
      {
        dataField: "qty",
        text: "Qty",
        classes: "bootstrap-table-bold-cell",
        align: "center",
        sort: true,
        headerAlign: "center",
        headerStyle: QtyOrderStyle,
        formatter: function (cell, row, index) {
          if (row.qty == 1) {
            setEnableOk(false);
          }
          let totalQty= 0;
           if(!_isEmpty(row.availability)){
           totalQty= row.availability.map(item => item.availableQty).reduce((prev, next) => prev + next)}
          return (
            <Fragment>
              <div className="tableview-order">
                <OverlayTrigger
                  style={{ "z-index": "999" }}
                  placement="right"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    row.qty > row.totalAvailable ? (
                      <Tooltip
                        id="description-part-catalog-tooltip"
                        className="tooltip-description"
                      >
                        <span className="font-weight-bold">
                          Please enter a quantity less than {row.totalAvailable}
                        </span>
                      </Tooltip>
                    ) : (
                      <span></span>
                    )
                  }
                >
                  <span className="tableview-order">
                    <input
                      autoFocus={row.availabilityAll.length > 0 && row.autoFocus ? true : false}
                      className=""
                      type="text"
                      name="Qty"
                      size="4"
                      value={row.qty}
                      disabled={row.availabilityAll.length === 0 || totalQty<=0}
                      onChange={(e) => {
                        handleAvailabilitySelection(e, row, index);
                      }}
                      onBlur={(e) => {
                        handleAvailabilitySelection(e, row, index);
                        handleQtySelection(e, row, index);
                      }}
                      onMouseLeave={(e) =>
                        handleAvailabilitySelection(e, row, index)
                      }
                      onKeyDown={(event) => {
                        handleKeyDown(event);
                      }}
                    />
                  </span>
                </OverlayTrigger>
                <br></br>
                {row.enteredQty !== 0 && !_.isEmpty(row.enteredQty) && (
                  <span className="bold">
                    <span className="maroonColorTxt">{row.totalAvailable}{" "}</span> Available out of
                    {" "}<span className="maroonColorTxt">{row.enteredQty}{" "}</span>{" "}  requested
                  </span>
                )}
              </div>
            </Fragment>
          );
        },
      },
      {
        dataField: "itemSpecific",
        text: "Description",
        sort: true,
        editable: false,
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        headerStyle: descriptionHeaderStyle,
        formatter: function (cell, row) {
          return (
            <div className="col-one">
              {(row.status === "6" || row.status === "3") && (
                <span className="maroonColorTxt pr-1">{row.isSub ? "<SUPERSEDED>" : row.isAlt ? "<ALTERNATE>" : ""} </span>)}
              <span className="tooltip-add-to-cart">{row.itemSpecific}</span>
            </div>
          );
        },
      },
      {
        dataField: "costPrice",
        text: "Your Price",
        hidden: hideAllPrice,
        headerAlign: "center",
        sort: true,
        headerStyle: headerStyle,
        classes: "bootstrap-table-bold-cell",
        formatter: function (cell, row) {
          return (<span className="maroonColorTxt"> ${formatAmount(row.costPrice)}</span>);
        },
      },
      {
        dataField: "corePrice",
        text: "Core Price",
        hidden: hideAllPrice,
        headerAlign: "center",
        sort: true,
        headerStyle: headerStyle,
        classes: "bootstrap-table-bold-cell",
        formatter: function (cell, row) {
          return (<span className="maroonColorTxt">${formatAmount(row.corePrice)}</span>);
        },
      },
      {
        dataField: "listPrice",
        hidden: hideAllPrice,
        text: "List Price",
        headerAlign: "center",
        sort: true,
        classes: "bootstrap-table-bold-cell",
        headerStyle: headerStyle,
        formatter: function (cell, row) {
          return (<span className="maroonColorTxt">${formatAmount(row.listPrice)} </span>);
        },
      },
      {
        dataField: "extendedPrice",
        text: "Total Extended Price",
        hidden: hideAllPrice,
        headerStyle: headerExtendedStyle,
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        formatter: function (cell, row, index) {
          if (row.qty > 0) {
            return (<div className="" id={`extendedprice-${index}`}>
              <span className="maroonColorTxt" id={`extendedpriceText-${index}`}> ${formatAmount(getExtendedTotalPrice(row.corePrice, row.costPrice, row.qty))}</span>
            </div>);
          }
          return <span></span>;
        },
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <span>Total Extended Price {""}</span>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 400 }}
                overlay={<Tooltip id="extended-price-tooltip" className="tooltip-description-custom-corePrice">
                  <span className="font-weight-bold"> Total Extended Price = Qty * (Your Price + Core Price) </span>
                </Tooltip>}>
                <a className="whiteColorTxt info-icon"> <i class="fa fa-info-circle"></i></a>
              </OverlayTrigger>
            </div>
          );
        },
      },
      {
        dataField: "availability",
        text: "Availability",
        sort: true,
        editable: true,
        hidden: true,
        formatter: userDetails?.billTo?.mclSupplier == 'CD' ? availabilityColFormatterForCD : availabilityColFormatter,
      },
      {
        dataField: "availabilityAll",
        text: "Availability",
        hidden: true,
      },
      {
        dataField: "selectedAvailabilityHours",
        text: "Selected Availability",
        hidden: true,
      },
      {
        dataField: "selectedAvl",
        text: "Availability",
        headerAlign: "center",
        headerStyle: headerAvailabilityStyle,
        formatter: (userDetails?.billTo && userDetails?.billTo?.mclSupplier == 'CD') ? availabilityColFormatterForCD : availabilityColFormatter,
      },
      {
        dataField: "addToOrder",
        text: "addToOrder",
        headerAlign: "center",
        hidden: true,
      },

      {
        dataField: "uniquedata",
        text: "uniq",
        hidden: true,
      },
    ];
    setmagicWordsColumns(columns);
  };

  const handleSubmit = () => {
    if (sourceName == "chemical") {
      handleAddToCart();
    } else
      if (sourceName == "stockCheck") {
        handleSelectedPart(rowDataMagic);
        closeAll();
      }
  }

  return (
    <MagicWordTable
      sourceName={sourceName}
      magicOptions={magicOptions}
      partNumerEntered={partNumerEntered}
      showMagicModal={showMagicModal}
      magicTitle={magicTitle}
      closeAll={closeAll}
      magicRef={magicRef}
      setrowData={setrowData}
      magicWordsColumns={magicWordsColumns}
      partMagicRows={partMagicRows}
      handleMagicWordSelection={handleMagicWordSelection}
      rowDataMagic={rowDataMagic}
      showLoadermagic={showLoadermagic}
      handleSubmit={handleSubmit}
      enableOk={enableOk}
      showWithinPopup={showWithinPopup}
      setShowWithinPopup={setShowWithinPopup}
      storeData={storeData}
    />
  );
};



export default MagicWord;
