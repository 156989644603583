/* eslint-disable no-lone-blocks */
import React, { useEffect, useState, Fragment } from "react";
import {
  Row,
  Col,
  Image,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner
} from "react-bootstrap";
import _find from "lodash/find";
import _toString from "lodash/toString";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import _uniqBy from "lodash/uniqBy";
import _sumBy from "lodash/sumBy";
import _remove from "lodash/remove";
import _includes from "lodash/includes";
import _cloneDeep from "lodash/cloneDeep";
import _, { isEmpty } from "lodash";
import XModal from "../../../shared/XModal";
import Highlighter from "react-highlight-words";
import { isProgrammable, searchWords } from "../../../../helpers/constants";
import { triggerCustomUxCriticalError } from "../../../../actions/error";
import { connect } from "react-redux";
import { addItemToCart, addItemToCart2 } from "../../../../actions/cart";
import { useDispatch, useSelector } from "react-redux";
import {
  isInputBlank,
  isValidAvailableQty,
  isValidPositiveNumber,
  distributeAvailability,
  formatAmount,
  formatTimeAvb,
  numbersOnlyRegExp,
  timeInUTC,
  getExtendedTotalPrice,
  getImageURLCookieDetails,
  getPartPackQty,
  displayQtyChangeMsg,
  hidePrice,
  distributeAvailability2,
  getDistributionForNonVehicle,
  getShipInfo,
  getSellerInfo,
  isStockOrder,
  enableBlackColorBasedOnStorePref
} from "../../../../helpers/utils";
import { setPartHistory } from "../../../shared/MyGarage/mygarage.thunks";
import BootstrapTable from "react-bootstrap-table-next";
import linkIcon from "../../../../static/images/linkIcon.png";
import LostSales from "../../../ShoppingCart/LostSales";
import PricingIcons from "../../../Listing/PricingIcons";
import AvailabilityStoreView from "../../../shared/AvailabilityStoreView";
const actionTypes = {
  SELECTED_ADDCART_DATA: "SELECTED_ADDCART_DATA",
  ADDTO_CART_WARNING: "ADDTO_CART_WARNING",
  CNK_CART_CHECKOUT_FETCHED: 'CNK_CART_CHECKOUT_FETCHED'
};

function TableViewWrapper(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const addItemcartFetching = useSelector((state) => state.cart.addItemcartFetching);
  const parts = useSelector((state) => state.parts);
  const orderType = useSelector((state) => state.cart.orderType);
  const mygarage = useSelector((state) => state.mygarage);
  let categorySuppliesMp = useSelector(
    (state) => state.parts.categorySuppliesMap
  );
  let cart = useSelector((state) => state.cart);
  const candkCheckoutDetails = useSelector((state) =>state.cart.candkCheckoutDetails);
  let [selectedAvailabilityHourss, setselectedAvailabilityHourss] = useState(
    []
  );
  const [orderArray, setOrderArray] = useState([]);
  let [showImagesSlider, setShowImagesSlider] = useState(false);
  let [suplyListData, setSuplyListData] = useState(null);
  let [rowData, setProductData] = useState({});
  const [supplyListTableRows, setsupplyListTableRows] = useState([]);
  const [supplyListTableColumns, setsupplyListTableColumns] = useState([]);
  let [indexValue, setIndex] = useState(null);
  let [showLostSales, setshowLostSales] = useState(false);
  let [partsQySum, setpartsQtySum] = useState(0);
  let [totalOrderQy, settotalOrderQty] = useState(0);
  const [openWarning, setOpenWarning] = useState(false);
  const [currentTottalAvailable, setCurrentTotalAvailable] = useState(0);
  const ismobileEnabled = user?.userDetails?.shipTos[0]?.isMobileEnabled;
  const [showAvailabilityStoreView, setShowAvailabilityStoreView] = useState(false);
  const [storeData, setStoreData] = useState([]);
  let [validity, setValidity] = useState({
    doesQtyExist: true,
    isQtyMoreThanOne: true,
    isQtyLessThanAvailable: true,
  });

  let { rows, rowsMoreDetails, isForCandK } = props;
  let hideListPrice = hidePrice("Catalog", "listPrice");
  let hideCostPrice = hidePrice("Catalog", "costPrice");
  const hideAllPrice =  parts  && parts.hidePrice;

  useEffect(() => {
    constructTableData(rows);
  }, [cart]);

  const validateQty = (quantity, availableQuantity, validity) => {
    validity.doesQtyExist = !isInputBlank(quantity);
    validity.isQtyMoreThanOne = isValidPositiveNumber(quantity);
    validity.isQtyLessThanAvailable = isValidAvailableQty(
      quantity,
      availableQuantity
    );
    return validity;
  };

  const handleWarningClose = () => {
    setOpenWarning(false);
  };

  const altSupPart = (row) => {
    let part = [];
    row.map((subPart, index) => {
      part.push(subPart.partSKU);
    });
    return part.join(" ,");
  };

  const altSupData = (row) => {
    if (!_isEmpty(row.part.alternate)) {
      return `Alternate Part ${altSupPart(row.part.alternate)}`;
    }

    if (!_isEmpty(row.part.substitute)) {
      return `Superseded Part ${altSupPart(row.part.substitute)}`;
    }

    if (_isEmpty(row.part.alternate)) {
      return "";
    }
  };

  const checkIsPartInCart = (partNum) => {
    let str = "";
    let qty = 0;
    cart.cartAllDetails.map((item) => {
      if (item.partNum === partNum) {
        qty += item.qty;
        str = qty + ` in Cart`;
      }
    });
    return str;
  };

  const constructTableData = (rows) => {
    setsupplyListTableRows([]);
    setsupplyListTableColumns([]);
    const columns = [
      {
        dataField: "validationRed",
        text: "validation",
        hidden: true,
      },
      {
        dataField: "totalAvailable",
        text: "totalAvailable",
        hidden: true,
      },
      {
        dataField: "part",
        text: "Part#",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        classes: "bootstrap-table-bold-cell",
        formatter: partNumberFormatter,
      },
      {
        dataField: "description",
        text: "Description",
        headerStyle: descriptionHeaderStyle,
        align: "center",
        classes: "bootstrap-table-bold-cell",
        headerAlign: "center",
        formatter: function (cell, row, index) {
          return row.items != "" &&
            row.brand[0].name == "" &&
            row.description == "" ? (
            <span>
              <p className="part-item">{row.items} Item(s)</p>
            </span>
          ) : (
            <div className="compact-table-description-row">
              <div className="col-one">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    <Tooltip
                      id="description-chemical-catalog-tooltip"
                      className="tooltip-description"
                    >
                      <Highlighter
                        caseSensitive={false}
                        highlightClassName="program-highlightTxt"
                        searchWords={searchWords}
                        autoEscape={true}
                        textToHighlight={row.description}
                      ></Highlighter>
                      {/* <span>{row.description}</span> */}
                      {(!_isEmpty(row.part.alternate) ||
                        !_isEmpty(row.part.substitute)) && <br></br>}
                      <span>{altSupData(row)}</span>
                    </Tooltip>
                  }
                >
                  <span className="alt-sub-text">
                    {/* {(rowsMoreDetails[index].isSubType || rowsMoreDetails[index].isAltType) &&
													<span className="maroonColorTxt pr-1">{rowsMoreDetails[index].isSubType ? "<SUPERSEDED>" : (rowsMoreDetails[index].isAltType ? "<ALTERNATE>" : "")}</span>
												} */}
                    {((row.rowMoreInfo && row.rowMoreInfo.isSubType) ||
                      (row.rowMoreInfo && row.rowMoreInfo.isAltType)) && (
                        <span className="maroonColorTxt pr-1">
                          {row.rowMoreInfo && row.rowMoreInfo.isSubType
                            ? "<SUPERSEDED>"
                            : row.rowMoreInfo && row.rowMoreInfo.isAltType
                              ? "<ALTERNATE>"
                              : ""}
                        </span>
                      )}
                    <span className="">{row.description}</span>
                    {!_isEmpty(row.part.alternate) && (
                      <span className="alt-sup-highlightTxt">
                        ;Alternate Part {altSupPart(row.part.alternate)}
                      </span>
                    )}
                    {!_isEmpty(row.part.substitute) && (
                      <span className="alt-sup-highlightTxt">
                        ;Superseded Part {altSupPart(row.part.substitute)}
                      </span>
                    )}
                  </span>
                </OverlayTrigger>
              </div>
              <div className="col-two">
                <PricingIcons
                  toolTips={row.part.pricingAvailability.toolTips}
                />
                {isProgrammable(row.description.toString() || "") && (
                  <div className="col-two-icon">
                    <img alt="" src="/public_images/programmable.png" />
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        dataField: "brand",
        text: "Brand",
        headerStyle: brandHeaderStyle,
        align: "center",
        classes: "bootstrap-table-bold-cell",
        headerAlign: "center",
        formatter: function (cell, row) {
          return row.brand[0].name != "" ? (
            <div>
              <OverlayTrigger
                placement="right"
                delay={{ show: 100, hide: 400 }}
                overlay={
                  <Tooltip
                    id="brand-chemical-catalog-tooltip"
                    className="tooltip-description"
                  >
                    <span>{row.brand[0].name}</span>
                  </Tooltip>
                }
              >
                {row.brand.length > 0 && row.brand[0].name && (
                  <span className="brand-description">
                    {row.brand[0].name.length > 8
                      ? `${row.brand[0].name.substring(0, 8)}...`
                      : `${row.brand[0].name}`}
                  </span>
                )}
              </OverlayTrigger>
            </div>
          ) : (
            <span></span>
          );
        },
      },
      {
        dataField: "links",
        text: "Links",
        headerStyle: linksHeaderStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row, index) {
          return row.links != "" ? (
            <div className="table-view-links_Container">
              <Fragment>
                <img src={linkIcon} alt="Link" />
                <div>
                  {true && (
                    <ul>
                      {true && (
                        <li>
                          <a
                            href="#"
                            onClick={() => {
                              togglePopup("lostSales", index);
                            }}
                            title="Lost Sales"
                          >
                            Lost Sales
                          </a>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              </Fragment>
            </div>
          ) : (
            <span></span>
          );
        },
      },
      {
        dataField: "yourprice",
        text: "Your Price",
        hidden: hideCostPrice || hideAllPrice,
        headerStyle: cart.hideCostPrice
          ? { display: "none" }
          : yourPriceHeaderStyle,
        align: "center",
        headerAlign: "center",
        classes: cart.hideCostPrice ? "d-none" : "",
        formatter: function (cell, row) {
          return row.yourprice != "" ? (
            <div className="">
              {!cart.hideCostPrice && (
                <span className="tphblueColorTxt font-weight-bold">
                  ${formatAmount(row.yourprice)}
                </span>
              )}
            </div>
          ) : (
            <span></span>
          );
        },
      },
      {
        dataField: "corePrice",
        text: "Core Price",
        hidden: hideCostPrice || hideAllPrice,
        headerStyle: cart.hideCostPrice
          ? { display: "none" }
          : listPriceHeaderStyle,
        align: "center",
        headerAlign: "center",
        classes: cart.hideCostPrice ? "d-none" : "",
        formatter: function (cell, row) {
          return row.listPrice != "" && row.yourprice != "" ? (
            <div className="brand_costPrice_listPrice">
              <span className="maroonColorTxt">
                ${formatAmount(row.corePrice)}
              </span>
            </div>
          ) : (
            <span></span>
          );
        },
      },
      {
        dataField: "listprice",
        text: "List Price",
        hidden: hideListPrice || hideAllPrice,
        headerStyle: listPriceHeaderStyle,
        align: "center",
        headerAlign: "center",
        formatter: function (cell, row) {
          return row.listPrice != "" && row.yourprice != "" ? (
            <div className="brand_costPrice_listPrice">
              <span className="maroonColorTxt">
                ${formatAmount(row.listprice)}
              </span>
            </div>
          ) : (
            <span></span>
          );
        },
      },

      {
        dataField: "qtypervehicle",
        text: "Size Qty",
        headerStyle: qtyHeaderStyle,
        align: "center",
        classes: "bootstrap-table-bold-cell",
        headerAlign: "center",
      },
      {
        dataField: "qtytoorder",
        text: "Qty to Order",
        headerStyle: QtyOrderheaderStyle,
        align: "center",
        headerAlign: "center",
        //	classes :"qty-to-order",
        formatter: function (cell, row, index) {
          let totalAvailqty = 0;
          let uniqueData = _uniqBy(
            row.part.pricingAvailability.availability,
            "availableDuration"
          );
          uniqueData.map((singleAvailability, key) => {
            if (singleAvailability.availableQty > 0) {
              singleAvailability.availableDuration =
                singleAvailability.availableDuration === "00:00:00" &&
                  singleAvailability.code === 0
                  ? "Immediately"
                  : singleAvailability.availableDuration;
              totalAvailqty += singleAvailability.availableQty;
            }
          });

          return row.description != "" || row.brand[0].name != "" ? (
            <Fragment>
              <div className="tableview-order">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    row.validationRed ? (
                      <Tooltip
                        id="description-part-catalog-tooltip"
                        className="tooltip-description"
                      >
                        <span className="font-weight-bold">
                          Please enter a quantity less than or equal to{" "}
                          {row.totalAvailable}
                        </span>
                      </Tooltip>
                    ) : (
                      <span></span>
                    )
                  }
                >
                  <input
                    type="text"
                    name="totalQt"
                    autocomplete="off"
                    defaultValue={row.qtytoorder ? row.qtytoorder : ""}
                    className={
                      row.validationRed
                        ? "table_qty_order boder-red"
                        : "table_qty_order"
                    }
                    disabled={totalAvailqty == 0}
                    //autoFocus={row.autoFocus ? true : false}
                    onChange={(e) => {
                      handleInputChange(e, totalAvailqty, index, row)
                    }}
                    onMouseLeave={(e) => {
                      handleInputChangeOnMouseLeave(e, totalAvailqty, index, row)
                    }}
                    onBlur={(e) => {
                      handleInputChangeOnMouseLeave(e, totalAvailqty, index, row)
                    }}
                    onKeyDown={(event) => {
                      handleKeyDown(event);
                    }}
                  //onFocus={(e) => hideAvailabilityonNextPartFocus(e, index, row)}
                  />
                </OverlayTrigger>
              </div>

              <span class="table-add-to-cart-button">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    <Tooltip
                      id="add-to-cart-tooltip-chemical"
                      className="tooltip-add-to-cart"
                    >
                      {`Add to Cart`}
                    </Tooltip>
                  }
                >
                  {!isForCandK ?<Button
                    type="button"
                    disabled={totalAvailqty == 0 || row.validationRed}
                    onClick={(e) => addToCart(e, index)}
                    class="btn btn-primary"
                  >
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                  </Button>:
                  <Button
                    type="button"
                    disabled={totalAvailqty == 0 || row.validationRed}
                    onClick={(e) => addToCartForCandK(e, index)}
                    class="btn btn-primary"
                  >
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                  </Button>}
                </OverlayTrigger>
              </span>
              <span class="addcart-text">
                {" "}
                {!isForCandK && checkIsPartInCart(row.part.partSKU.trim())}{" "}
              </span>
            </Fragment>
          ) : (
            <span></span>
          );
        },
      },
      {
        dataField: "autoFocus",
        text: "autoFocus",
        hidden: true,
      },
      {
        dataField: "extendedPrice",
        text: "Total Extended Price",
        hidden: hideCostPrice || hideAllPrice,
        align: "center",
        headerAlign: "center",
        headerStyle: cart.hideCostPrice
          ? { display: "none" }
          : listPriceHeaderStyle,
        classes: cart.hideCostPrice ? "d-none" : "",
        formatter: function (cell, row, index) {
          return row.description != "" || row.brand[0].name != "" ? (
            <div
              className="brand_costPrice_listPrice mb-0"
              id={`extendedprice-${index}`}
            >
              <p className="extdprice" id={`extendedpriceText-${index}`}>
                ${formatAmount(row.extendedPrice)}
              </p>
            </div>
          ) : (
            <span></span>
          );
        },
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <span>Total Extended Price </span>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 400 }}
                overlay={
                  <Tooltip
                    id="extended-price-tooltip"
                    className="tooltip-description-custom-corePrice"
                  >
                    <span className="font-weight-bold">
                      Total Extended Price = Qty * (Your Price + Core Price)
                    </span>
                  </Tooltip>
                }
              >
                <a className="whiteColorTxt info-icon">
                  <i class="fa fa-info-circle"></i>
                </a>
              </OverlayTrigger>
            </div>
          );
        },
      },
      {
        dataField: "availabilities",
        text: "Availability",
        headerStyle: availabilityHeaderStyle,
        align: "center",
        headerAlign: "center",
        formatter: renderAvailabilityFormatter,
      },
      {
        dataField: "groupby",
        hidden: true,
      },
      {
        dataField: "items",
        hidden: true,
      },
      {
        dataField: "sortAvailability",
        hidden: true,
      },
    ];

    setsupplyListTableColumns(columns);
    setsupplyListTableRows(rows);
  };

  const headerStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "150px",
  };

  const linksHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "30px",
  };

  const qtyHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "35px",
  };

  const descriptionHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "210px",
  };

  const brandHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "40px",
  };

  const listPriceHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "55px",
  };

  const yourPriceHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "20px",
  };

  const QtyOrderheaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "85px",
  };

  const availabilityHeaderStyle = {
    backgroundColor: "#0F5283",
    color: "#FFFFFF",
    "border-color": "#0F5283",
    "font-size": "12px",
    width: "120px",
  };

  const handleInputChangeOnMouseLeave = (event, totalAvailqty, index, row) => {
    if (row.qtyBuyInc > 1) {
      let value;
      value = getPartPackQty(row.qtyBuyInc, event.target.value, totalAvailqty);
      if (!_isEmpty(event) && row.qtyBuyInc > 1 && event.target.value != value) {
        value = getPartPackQty(row.qtyBuyInc, event.target.value, totalAvailqty);
        rows[index].extendedPrice = (getExtendedTotalPrice(row.corePrice, row.yourprice, value));
        qtyByINCUpdateSuccessMessage();
      }
      setTimeout(() => {
        rows[index].qtytoorder = value;
        constructTableData(rows)
      });

      rows[index].qtytoorder = value;
      setsupplyListTableRows(rows);
      if (value > totalAvailqty) {
        setCurrentTotalAvailable(totalAvailqty + 1);
        rows[index].totalAvailable = totalAvailqty + 1
        rows[index].validationRed = true;
        setTimeout(() => {
          constructTableData(rows)
        })
      } else {
        if (row.availabilities.length > 1 && event.target.value > row.availabilities[0].availableQty && !(rows[index].hiddenAvail)) {
          rows[index].hiddenAvail = true;
          setTimeout(() => {
            constructTableData(rows)
          })
        } else if (row.availabilities.length > 1 && event.target.value < row.availabilities[0].availableQty && (rows[index].hiddenAvail)) {
          rows[index].hiddenAvail = false;
          setTimeout(() => {
            constructTableData(rows)
          })
        }
        else if ((event.target.value < totalAvailqty || _isEmpty(event.target.value)) && (rows[index].validationRed)) {
          rows[index].validationRed = false;
          setTimeout(() => {
            constructTableData(rows)
          })
        }
      }
      rows[index].extendedPrice = getExtendedTotalPrice(row.corePrice, row.yourprice, value);
      let extendedPriceData = rows[index].extendedPrice;
      if (!(row.qtyBuyInc > 1)) {
        if (document.querySelector("#extendedprice-" + index)) {
          let extendedPrice = "$" + formatAmount(extendedPriceData);
          document.querySelector("#extendedpriceText-" + index).style.fontSize = "20px";
          document.querySelector("#extendedpriceText-" + index).innerHTML = extendedPrice;
          setTimeout(() => {
            document.querySelector("#extendedpriceText-" + index).style.fontSize = "12px";
          }, 2000)
        }
      }
    }
  }

  const qtyByINCUpdateSuccessMessage = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = displayQtyChangeMsg();
    statusMessageProps.type = "success";
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  };

  const handleInputChange = (event, totalAvailqty, index, row) => {
    let selectedAvailabilityHours = _cloneDeep(selectedAvailabilityHourss);
    selectedAvailabilityHours = [];
    let details = rows.map((v) => v.rowMoreInfo);
    let totalOrderQty = totalOrderQy;
    //let partDetails = rowsMoreDetails[index].partDetails && (((rowsMoreDetails[index].partDetails.altPart && rowsMoreDetails[index].partDetails.altPart) || rowsMoreDetails[index].partDetails.part) || rowsMoreDetails[index].partDetails);
    let partDetails =
      details[index].partDetails &&
      ((details[index].partDetails.altPart &&
        details[index].partDetails.altPart) ||
        details[index].partDetails.part ||
        details[index].partDetails);
    let pricingAvailability = partDetails.pricingAvailability;
    let categorySuppliesMap = categorySuppliesMp;
    let value;
    if (!_isEmpty(event)) {
      value = event.target.value;
    } else {
      value = partDetails && partDetails.perCarQty ? partDetails.perCarQty : "";
    }
    rows[index].qtytoorder = value;
    setsupplyListTableRows(rows);
    if (value > totalAvailqty) {
      setCurrentTotalAvailable(totalAvailqty + 1);
      rows[index].totalAvailable = totalAvailqty + 1;
      rows[index].validationRed = true;
      setTimeout(() => {
        constructTableData(rows);
      });
    } else {
      row.availabilities[0].availableQty =
        row.availabilities[0] &&
          row.availabilities[0].availableDuration &&
          (row.availabilities[0].availableDuration !== "Next Business Day" ||
            row.availabilities[0].availableDuration !==
            "Next 2 + Business Days" ||
            row.availabilities[0].availableDuration !== "Next 2 Weeks")
          ? row.availabilities[0].storeAvailabilities[0].storeQty
          : row.availabilities[0].availableQty;
      if (
        row.availabilities.length > 1 &&
        event.target.value > row.availabilities[0].availableQty &&
        !rows[index].hiddenAvail
      ) {
        rows[index].hiddenAvail = true;
        setTimeout(() => {
          constructTableData(rows);
        });
      } else if (
        row.availabilities.length > 1 &&
        event.target.value < row.availabilities[0].availableQty &&
        rows[index].hiddenAvail
      ) {
        rows[index].hiddenAvail = false;
        setTimeout(() => {
          constructTableData(rows);
        });
      } else if (
        (event.target.value < totalAvailqty || _isEmpty(event.target.value)) &&
        rows[index].validationRed
      ) {
        rows[index].validationRed = false;
        setTimeout(() => {
          constructTableData(rows);
        });
      }
      // rows[index].autoFocus = true;
    }
    rows[index].extendedPrice = getExtendedTotalPrice(
      row.corePrice,
      row.yourprice,
      value
    );
    let extendedPriceData = rows[index].extendedPrice;
    //if (!(row.qtyBuyInc > 1)) {
    if (document.querySelector("#extendedprice-" + index)) {
      let extendedPrice = "$" + formatAmount(extendedPriceData);
      document.querySelector("#extendedpriceText-" + index).style.fontSize =
        "20px";
      document.querySelector("#extendedpriceText-" + index).innerHTML =
        extendedPrice;
      setTimeout(() => {
        document.querySelector("#extendedpriceText-" + index).style.fontSize =
          "12px";
      }, 2000);
      //}
    }
    rows[index].autoFocus = true;
  };


  const getWhichCart = () => {
    let orginalCart = {};
    if(isForCandK) {
      orginalCart.checkoutDetails = _cloneDeep(candkCheckoutDetails);
    } else {
      orginalCart = _cloneDeep(cart);
    }  
    return orginalCart
  }

  const constructCartAvailability = (index) => {
    const { selectedStore, sellPartnerId, shipMethod } = props;
    let selectedAvailabilityHours = _cloneDeep(selectedAvailabilityHourss);
    let { userDetails } = user;
    selectedAvailabilityHours = [];
    let partsQtySum =
      !_isEmpty(rows[index].part) &&
        !_isEmpty(rows[index].part.pricingAvailability) &&
        !_isEmpty(rows[index].part.pricingAvailability.availability)
        ? _sumBy(
          _uniqBy(
            rows[index].part.pricingAvailability.availability,
            "availableDuration"
          ),
          "availableQty"
        )
        : 0;
    let totalOrderQty = totalOrderQy;
    let details = rows.map((v) => v.rowMoreInfo);
    let partDetails =
      details[index].partDetails &&
      ((details[index].partDetails.altPart &&
        details[index].partDetails.altPart) ||
        details[index].partDetails.part ||
        details[index].partDetails);
    //let partDetails = rowsMoreDetails[index].partDetails && (((rowsMoreDetails[index].partDetails.altPart && rowsMoreDetails[index].partDetails.altPart) || rowsMoreDetails[index].partDetails.part) || rowsMoreDetails[index].partDetails);
    let pricingAvailability = partDetails.pricingAvailability;
    let categorySuppliesMap = categorySuppliesMp;
    partsQtySum = _sumBy(
      _uniqBy(pricingAvailability.availability, "availableDuration"),
      "availableQty"
    );
          
    let distributionData = {};
    let cloneCart = getWhichCart();
    if(!isForCandK && shipMethod !== getShipInfo(userDetails, parts).shipMethod) {
        cloneCart.checkoutDetails = [];
    } 
    distributionData = getDistributionForNonVehicle(
      partDetails.partSKU, partDetails, pricingAvailability.availability, Number(rows[index].qtytoorder), cloneCart
    );
    distributionData.alreadyAddedItem =  !isForCandK && shipMethod !== getShipInfo(userDetails, parts).shipMethod ?  cart.checkoutDetails : distributionData.alreadyAddedItem;
    selectedAvailabilityHourss = [];
    let qtyOrder = Number(rows[index].qtytoorder);
    if (qtyOrder > 0) {
      // let orderAray = distributeAvailability(
      //   rows[index].qtytoorder,
      //   pricingAvailability.availability
      // );
      let orderAray = distributeAvailability2(
        Number(distributionData.totalQty),
        distributionData.filteredAvailability
      );
      selectedAvailabilityHourss.push(...distributionData.alreadyAddedItem);
      orderAray.map((item) => {
        selectedAvailabilityHourss.push({
          availabilityHour: item.availableDuration,
          availableQty: item.availableQty,
          cartFlag: "no",
          categoryId:
            Number(
              _find(categorySuppliesMap, (c) => {
                return _includes(
                  _map(c.groups, "id"),
                  Number(partDetails.groupID)
                );
              }).code
            ) || undefined,
          costPrice: pricingAvailability.costPrice,
          corePrice: pricingAvailability.corePrice,
          coreListPrice: pricingAvailability.coreListPrice,
          desc: partDetails.partDescription,
          descId: partDetails.partDescriptionID,
          groupId: partDetails.groupID,
          listPrice: pricingAvailability.listPrice,
          mfgCode: partDetails.brands ? partDetails.brands[0].code : null,
          packQty: pricingAvailability.packQty,
          partNum: pricingAvailability.partNum,
          partType: "Chemical",
          vehicleInfo: `Non-Vehicle Specific`,
          qty: item.orderQty,
          store: selectedStore,
          sellPartnerId: sellPartnerId,
          shipMethod: shipMethod,
          distStatus: "Completed",
          orderType: "REGULAR"
        });
      });
    }
    return selectedAvailabilityHourss;
  };

  const hideAvailabilityonNextPartFocus = (event, index, row) => {
    rows.map((value, key) => {
      if (key != index && rows[key].hiddenAvail === true) {
        rows[key].hiddenAvail = false;
        setTimeout(() => {
          constructTableData(rows);
        });
      }
    });
  };

  const handleKeyDown = (event) => {
    const qtyValue = event.target.value;
    if (
      (numbersOnlyRegExp(event.key) === false ||
        (qtyValue && qtyValue.length >= 4) ||
        event.keyCode === 32) &&
      handleOtherKeys(event.keyCode)
    ) {
      event.preventDefault();
    }
  };

  const handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };
  
  const checkIfMobileEnabled = (storeData,text,isTooltip) =>{
    return(
      ismobileEnabled ? 
    <u className={isTooltip ?"cursor-pointer": "text-color"} onClick={()=>{storeBreakup(storeData)}}>{text}&nbsp;</u> :
    <u>{text}&nbsp;</u>
    )
  }

  const storeBreakup =(storeData) => {
    let storeArr = []
    storeData?.map((s,i)=>{
      storeArr.push({
        store:s.store,
        storeQty:s.storeQty,
      })
    })
    
    return onclick(storeArr);
  }

  const onclick = (tempData) => {
    setStoreData(tempData)
    setShowAvailabilityStoreView(true)
  }

  const renderAvailabilityFormatter = (cell, row, index) => {
    let { selectedStore } = props;
    let image = row.part.imageUrl
      ? `${row.part.imageUrl}?${getImageURLCookieDetails()}`
      : "/noImage.jpg";
    // if (_isEmpty(image)) {
    //     image = rowData.thumbnail
    //         ? `data:image/png;base64,${rowData.thumbnail}`
    //         : '/noImage.jpg';
    // }
    let proPack, handlePartSelect, proPackKey;
    let partDetails = rows[index].part;
    let partsQtySum = !_isEmpty(
      rows[index].part.pricingAvailability.availability
    )
      ? _sumBy(
        _uniqBy(
          rows[index].part.pricingAvailability.availability,
          "availableDuration"
        ),
        "availableQty"
      )
      : 0;
    let defualtTotalQuantity = true; //this.setDefalutProductQuantity();
    let availabilitySection = 0;
    let totalAvailqty = 0;

    let uniqueData = _uniqBy(
      partDetails.pricingAvailability.availability,
      "availableDuration"
    );
    let enableOtherAvailabilities =
      uniqueData.length > 0 &&
        uniqueData[0].availableQty &&
        uniqueData[0].availableQty > 0 &&
        (uniqueData[0].code == -38 || uniqueData[0].code == -50)
        ? false
        : true;
    let hightlightAvailability = 0;
    let otherStoreAvailability = 0;
    uniqueData.map((unique, index) => {
      if (unique.code && (unique.code != -38 || unique.code != -50)) {
        otherStoreAvailability += unique.availableQty;
      }
      if (unique.code && (unique.code == -38 || unique.code == -50)) {
        hightlightAvailability += unique.availableQty;
      }
    });
    enableOtherAvailabilities =
      otherStoreAvailability > 0 ? enableOtherAvailabilities : false;
    enableOtherAvailabilities =
      otherStoreAvailability > 0 &&
        row.qtypervehicle == "" &&
        hightlightAvailability == 0
        ? true
        : enableOtherAvailabilities;
    let availabilityLength = 0;
    if (!isEmpty(uniqueData)) {
      if (row.availabilities.length === 0) {
        row.availabilities = uniqueData;
        availabilityLength = row.availabilities.length;
        row.firstCode =
          uniqueData.length > 0 ? uniqueData[0].code : uniqueData[0].code;
        row.secondCode =
          uniqueData.length > 1 ? uniqueData[1].code : uniqueData[0].code;
      } else {
        availabilityLength = row.availabilities.length;
        row.firstCode =
          row.availabilities.length > 0
            ? row.availabilities[0].code
            : row.availabilities[0].code;
        row.secondCode =
          row.availabilities.length > 1
            ? row.availabilities[1].code
            : row.availabilities[0].code;
      }
    }
    let {
      userDetails
    } = user;
    // Temporary fix -- Should be removed
    if (getSellerInfo(userDetails)) {
      if (
        !isEmpty(partDetails) &&
        partDetails.pricingAvailability &&
        partDetails.pricingAvailability.availability.length > 0
      ) {
        let singleAvailability = row.availabilities[0];
        // singleAvailability.availableDuration = (singleAvailability.availableDuration === '00:00:00' && singleAvailability.code === 0) ? 'Immediately' : singleAvailability.availableDuration;
        totalAvailqty += singleAvailability.availableQty;
        return row.description != "" && row.brand[0].name != "" ? (
          <div className="xlp-way-availability-container">
            <div className="availability-subdiv">
              <ul>
                <li className="display-revert">
                  {singleAvailability.availableTime === null ||
                  singleAvailability.availableTime === "null" ||
                  singleAvailability.availableDuration === "Same Day" ||
                  (singleAvailability.availableDuration === "Immediately" &&
                    singleAvailability.code === 0) ? (
                    <span className="text-black">
                      <span className={enableBlackColorBasedOnStorePref(selectedStore)? "text-black" : "maroonColorTxt"}>
                        <b>{singleAvailability.availableQty}</b>{" "}
                      </span>
                      {checkIfMobileEnabled(singleAvailability?.storeAvailabilities,singleAvailability.availableDuration)}{" "}
                    </span>
                  ) : (
                    <span className="text-black">
                     <span className={enableBlackColorBasedOnStorePref(selectedStore) ? "text-black" : "maroonColorTxt"}>
                        <b>{singleAvailability.availableQty}</b>{" "}
                      </span>{" "}
                      {checkIfMobileEnabled(singleAvailability?.storeAvailabilities,"Within")}
                      {formatTimeAvb(singleAvailability.availableDuration)}{" "}
                    </span>
                  )}
                </li>
              </ul>
            </div>
            {row.availabilities.length > 1 ? (
              row.hiddenAvail ? (
                <Fragment>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 100, hide: 3000 }}
                    show={row.hiddenAvail}
                    overlay={
                      <Tooltip
                        id="toggle-view-full-compact"
                        className="custom-tooltip-availability"
                      >
                        {row.availabilities.map((singleAvailability, key) => {
                          if (singleAvailability.availableQty > 0) {
                            //row.availability =
                            // singleAvailability.availableDuration = (singleAvailability.availableDuration === '00:00:00' && singleAvailability.code === 0) ? 'Immediately' : singleAvailability.availableDuration;
                            totalAvailqty += singleAvailability.availableQty;
                            return (
                              <ul>
                                {key !== 0 ? (
                                  <li>
                                    {singleAvailability.availableTime === null ||
                                    singleAvailability.availableTime === "null" ||
                                    singleAvailability.availableDuration ===
                                      "Same Day" ||
                                    (singleAvailability.availableDuration ===
                                      "Immediately" &&
                                      singleAvailability.code === 0) ? (
                                      <span className="text-white">
                                        <span className="font-weight-bold">
                                          {singleAvailability.availableQty}{" "}
                                        </span>
                                        {checkIfMobileEnabled(singleAvailability?.storeAvailabilities,singleAvailability.availableDuration,true)}{" "}
                                      </span>
                                    ) : (
                                      <span className="text-white">
                                        <span className="font-weight-bold">
                                          {singleAvailability.availableQty}{" "}
                                        </span>{" "}
                                        {checkIfMobileEnabled(singleAvailability?.storeAvailabilities,"Within",true)}
                                        {formatTimeAvb(
                                          singleAvailability.availableDuration
                                        )}{" "}
                                      </span>
                                    )}
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            );
                          }
                        })}
                      </Tooltip>
                    }
                  >
                    <a className="maroonColorTxt info-icon">
                      <i class="fa fa-info-circle"></i>
                    </a>
                  </OverlayTrigger>
                </Fragment>
              ) : (
                <Fragment>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 100, hide: 3000 }}
                    overlay={
                      <Tooltip
                        id="toggle-view-full-compact"
                        className="custom-tooltip-availability"
                      >
                        {row.availabilities.map((singleAvailability, key) => {
                          if (singleAvailability.availableQty > 0) {
                            //row.availability =
                            // singleAvailability.availableDuration = (singleAvailability.availableDuration === '00:00:00' && singleAvailability.code === 0) ? 'Immediately' : singleAvailability.availableDuration;
                            totalAvailqty += singleAvailability.availableQty;
                            return (
                              <ul>
                                {key !== 0 ? (
                                  <li>
                                    {singleAvailability.availableTime === null ||
                                    singleAvailability.availableTime === "null" ||
                                    singleAvailability.availableDuration ===
                                      "Same Day" ||
                                    (singleAvailability.availableDuration ===
                                      "Immediately" &&
                                      singleAvailability.code === 0) ? (
                                      <span className="text-white">
                                        <span className="font-weight-bold">
                                          {singleAvailability.availableQty}{" "}
                                        </span>
                                        {checkIfMobileEnabled(singleAvailability?.storeAvailabilities,singleAvailability.availableDuration,true)}{" "}                                     
                                      </span>
                                    ) : (
                                      <span className="text-white">
                                        <span className="font-weight-bold">
                                          {singleAvailability.availableQty}{" "}
                                        </span>{" "}
                                        {checkIfMobileEnabled(singleAvailability?.storeAvailabilities,"Within",true)}
                                        {formatTimeAvb(
                                          singleAvailability.availableDuration
                                        )}{" "}
                                      </span>
                                    )}
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            );
                          }
                        })}
                      </Tooltip>
                    }
                  >
                    <a className="maroonColorTxt info-icon">
                      <i class="fa fa-info-circle"></i>
                    </a>
                  </OverlayTrigger>
                </Fragment>
              )
            ) : (
              ""
            )}
          </div>
        ) : (
          <span></span>
        );
      }  else {
        return row.brand[0].name != "" || row.description != "" ? (
          <div>
            <div className="add_to_cart_out">
              <span>
                <b>Currently out of stock</b>
              </span>
            </div>
          </div>
        ) : (
          <span></span>
        );
      }

    } else {
     if (
      (!isEmpty(partDetails) &&
        partDetails.pricingAvailability &&
        partDetails.pricingAvailability.availability.length > 0) ||
      (row.availabilities[0] &&
        row.availabilities[0].availableDuration &&
        row.availabilities[0].availableDuration === "Your Store") ||
      (row.availabilities[0] &&
        row.availabilities[0].availableDuration &&
        row.availabilities[0].availableDuration === "Store")
    ) {
      let singleAvailability = row.availabilities[0];
      singleAvailability.availableDuration =
        singleAvailability.availableDuration &&
          singleAvailability.availableDuration === "00:00:00" &&
          singleAvailability.code === 0
          ? "Immediately"
          : singleAvailability.availableDuration;
      totalAvailqty += singleAvailability.availableQty;
      return row.description != "" && row.brand[0].name != "" ? (
        <div className="availability-container">
          <div className="availability-subdiv">
            {/* {(singleAvailability.availableDuration && singleAvailability.availableDuration === 'Next Business Day' || singleAvailability.availableDuration === "2 + Business Days") ?
							(<ul><li>
								<span className="text-black">
									<span className="store-maroon-text">
										<b>{singleAvailability.availableQty}</b>
									</span>
									&nbsp;Available
									&nbsp;
									{singleAvailability.availableDuration}{' '}
								</span>
							</li></ul>
							) : (singleAvailability.availableDuration && singleAvailability.availableDuration === 'Your Store' && singleAvailability.storeAvailabilities[0].storeQty == 0) ?
								(<ul>
									<li>
										<span className="store-maroon-text"><b>{`${singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities[0] && singleAvailability.storeAvailabilities[0].storeQty}`}</b></span>
										<span className="text-black">&nbsp;{singleAvailability.availableDuration === "Your Store" ? `  Available at Your Store #` : `  Available at Store #`}<b>{singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities[0] && singleAvailability.storeAvailabilities[0].store}</b>
										</span>
									</li>
									{(singleAvailability.availableDuration === 'Your Store' && singleAvailability.storeAvailabilities[0].storeQty == 0 && row.availabilities.length > 1 && row.availabilities[1] && (row.availabilities[1].availableDuration === 'Next Business Day' || row.availabilities[1].availableDuration === '2 + Business Days')) ?
										(<li>
											<span className="text-black">
												<span className="store-maroon-text">
													<b>{row.availabilities[1].availableQty}</b>
												</span>
												&nbsp;Available
												&nbsp;
												{row.availabilities[1].availableDuration}{' '}
											</span>
										</li>) :
										(<li>
											<span className="store-maroon-text"><b>{`${row.availabilities.length > 1 && row.availabilities[1] && row.availabilities[1].storeAvailabilities && row.availabilities[1].storeAvailabilities[0] && row.availabilities[1].storeAvailabilities[0].storeQty}`}</b></span>
											<span className="text-black">&nbsp;{row.availabilities[1].availableDuration === "Your Store" ? `  Available at Your Store #` : `  Available at Store #`}<b>{row.availabilities[1].storeAvailabilities && row.availabilities[1].storeAvailabilities[0] && row.availabilities[1].storeAvailabilities[0].store}</b>
											</span>
										</li>)
									}

								</ul>
								) : (<ul>
									<li>
										<span className="store-maroon-text"><b>{`${singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities[0] && singleAvailability.storeAvailabilities[0].storeQty}`}</b></span>
										<span className="text-black">&nbsp;{singleAvailability.availableDuration === "Your Store" ? `  Available at Your Store #` : `  Available at Store #`}<b>{singleAvailability.storeAvailabilities && singleAvailability.storeAvailabilities[0] && singleAvailability.storeAvailabilities[0].store}</b>
										</span>
									</li>
								</ul>

								)
						} */}

            {uniqueData.map((unique, index) => {
              return (
                unique.code &&
                (unique.code == -38 || unique.code == -50) && (
                  <ul>
                    <li>
                      <span className="text-black">
                        {unique.availableQty == 0 ? (
                          <span className="store-maroon-text compact-text-grey-Zero">
                            <b>{unique.availableQty}</b>
                          </span>
                        ) : (
                          <span className="store-maroon-text compact-text-grey">
                            <b>{unique.availableQty}</b>
                          </span>
                        )}
                        &nbsp;at &nbsp;
                        {unique.storeAvailabilities.length > 0 &&
                          unique.storeAvailabilities[0].store &&
                          unique.storeAvailabilities[0].store.length > 13 ? (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 100 }}
                            overlay={
                              <Tooltip
                                id="toggle-view-full-compact"
                                className="custom-tooltip-availability"
                              >
                                <b>#{unique.storeAvailabilities[0].store}</b>
                              </Tooltip>
                            }
                          >
                            <span>
                              {unique.availableDuration}{" "}
                              {unique.storeAvailabilities.length > 0 &&
                                unique.storeAvailabilities[0].store ? (
                                <span>
                                  <b>
                                    #
                                    {unique.storeAvailabilities[0].store &&
                                      unique.storeAvailabilities[0].store.length >
                                      13
                                      ? unique.storeAvailabilities[0].store.substring(
                                        0,
                                        13
                                      ) + ".."
                                      : unique.storeAvailabilities[0].store}
                                  </b>
                                </span>
                              ) : null}
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <span>
                            {unique.availableDuration}{" "}
                            {unique.storeAvailabilities.length > 0 &&
                              unique.storeAvailabilities[0].store ? (
                              <span>
                                <b>
                                  #
                                  {unique.storeAvailabilities[0].store &&
                                    unique.storeAvailabilities[0].store.length >
                                    14
                                    ? unique.storeAvailabilities[0].store.substring(
                                      0,
                                      14
                                    ) + ".."
                                    : unique.storeAvailabilities[0].store}
                                </b>
                              </span>
                            ) : null}
                          </span>
                        )}
                      </span>
                    </li>
                  </ul>
                )
              );
            })}
            {enableOtherAvailabilities ? (
              <ul>
                <li>
                  <span className="text-black">
                    <span className="store-maroon-text compact-text-green">
                      <b>{otherStoreAvailability}</b>
                    </span>
                    &nbsp;at Other Stores &nbsp;{" "}
                  </span>
                </li>
              </ul>
            ) : null}
          </div>
          {row.availabilities.length > 0 && otherStoreAvailability > 0 ? (
            row.hiddenAvail ? (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 4000 }}
                show={row.hiddenAvail}
                overlay={
                  <Tooltip
                    id="toggle-view-full-compact"
                    className="custom-tooltip-availability"
                  >
                    {prepareTooltipAvailability(row.availabilities)}
                  </Tooltip>
                }
              >
                <a className="maroonColorTxt info-icon">
                  <i class="fa fa-info-circle"></i>
                </a>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 4000 }}
                overlay={
                  <Tooltip
                    id="toggle-view-full-compact"
                    className="custom-tooltip-availability"
                  >
                    {prepareTooltipAvailability(row.availabilities)}
                  </Tooltip>
                }
              >
                <a className="maroonColorTxt info-icon">
                  <i class="fa fa-info-circle"></i>
                </a>
              </OverlayTrigger>
            )
          ) : (
            ""
          )}
        </div>
      ) : (
        <span></span>
      );
    } else {
      return row.brand[0].name != "" || row.description != "" ? (
        <div>
          <div className="add_to_cart_out">
            <span>
              <b>Currently out of stock</b>
            </span>
          </div>
        </div>
      ) : (
        <span></span>
      );
    }
   }
  };

  const renderAvailability = (cell, row, index) => {
    let proPack, handlePartSelect, proPackKey;
    let partDetails = rows[index].part;
    let partsQtySum = !_isEmpty(
      rows[index].part.pricingAvailability.availability
    )
      ? _sumBy(
        _uniqBy(
          rows[index].part.pricingAvailability.availability,
          "availableDuration"
        ),
        "availableQty"
      )
      : 0;
    let defualtTotalQuantity = true; //this.setDefalutProductQuantity();
    let availabilitySection = [];
    let totalAvailqty = 0;
    let uniqueData = _uniqBy(
      partDetails.pricingAvailability.availability,
      "availableDuration"
    );
    // Temporary fix -- Should be removed
    uniqueData.map((singleAvailability, key) => {
      if (singleAvailability.availableQty > 0) {
        singleAvailability.availableDuration =
          singleAvailability.availableDuration === "00:00:00" &&
            singleAvailability.code === 0
            ? "Immediately"
            : singleAvailability.availableDuration;
        totalAvailqty += singleAvailability.availableQty;
        availabilitySection.push(
          <div className="catalog-availability-section">
            <span>QTY:</span>
            <div>
              <input
                type="text"
                className="mr-2"
                name={partDetails.partNumber}
                disabled
                value={
                  !_isEmpty(orderArray) && !_isEmpty(orderArray[key])
                    ? orderArray[key].orderQty
                    : 0
                }
              />
              {singleAvailability.availableTime === null ||
                singleAvailability.availableTime === "null" ||
                singleAvailability.availableDuration === "Stores in Your Area" ||
                (singleAvailability.availableDuration === "Immediately" &&
                  singleAvailability.code === 0) ? (
                <span className="text-black">
                  {singleAvailability.availableDuration} -&nbsp;
                  <span className="maroonColorTxt">
                    <b>{singleAvailability.availableQty}</b>
                  </span>
                  &nbsp;Available
                </span>
              ) : (
                <span className="text-black">
                  {" "}
                  <u>Within</u>{" "}
                  {formatTimeAvb(singleAvailability.availableDuration)}{" "}
                  <span className="maroonColorTxt">
                    <b>{singleAvailability.availableQty}</b>{" "}
                  </span>
                  &nbsp;Available
                </span>
              )}
            </div>
          </div>
        );
      }
    });
    if (availabilitySection.length > 0) {
      availabilitySection.push(
        <div>
          <p className="maroonColorTxt mb-0">
            Enter the quantity below in <b>Total Qty</b> field
          </p>
          <div className="add_to_cart_section">
            <span>
              <span>Total </span>
              <span>QTY:</span>
            </span>
            <input
              type="text"
              name="totalQty"
              defaultValue={
                defualtTotalQuantity && partDetails && partDetails.perCarQty
                  ? partDetails.perCarQty
                  : ""
              }
              disabled={totalAvailqty === 0}
              onChange={(e) => this.handleInputChange(e)}
              onKeyDown={(event) => {
                this.handleKeyDown(event);
              }}
            />
            {proPack ? (
              <Button
                onClick={() => {
                  handlePartSelect(
                    selectedAvailabilityHourss,
                    totalOrderQy,
                    partDetails.descriptionID,
                    partDetails.pricingAvailability.costPrice,
                    proPackKey,
                    proPackKey
                  );
                }}
              >
                Select
              </Button>
            ) : (
              <div></div>
            )}
            {!this.props.cart.hideCostPrice && (
              <div>
                <span>Extended Price:</span>
                <span>
                  $
                  {formatAmount(
                    totalOrderQy * partDetails.pricingAvailability.costPrice
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="mt-2">
            {/* <FormValidationErrorMessage
							condition={
								!validity.doesQtyExist ||
								!validity.isQtyMoreThanOne
							}
							errorMessage={'Please enter a quantity'}
						/> */}
            {/* <FormValidationErrorMessage
							condition={
								!validity.isQtyLessThanAvailable &&
								validity.doesQtyExist &&
								validity.isQtyMoreThanOne
							}
							errorMessage={`Please enter a quantity less than ${
								partsQtySum + 1
							}`}
						/> */}
          </div>
        </div>
      );
    } else {
      availabilitySection.push(
        <div>
          <div className="add_to_cart_out">
            <span>
              <b>Currently out of stock</b>
            </span>
          </div>
        </div>
      );
    }

    return availabilitySection;
  };

  const handleImageClick = (row, rowData, index) => {
    if (row.part.images.length) {
      setIndex(index);
      setSuplyListData(row.part);
      setProductData(rowData);
      setShowImagesSlider(true);
    }
  };

  const prepareTooltipAvailability = (availabilities) => {
    let returnArray = [];
    availabilities.map((singleAvailability, key) => {
      if (singleAvailability.availableQty > 0) {
        if (
          singleAvailability.availableDuration === "Next Business Day" ||
          singleAvailability.availableDuration === "Next 2 + Business Days" ||
          singleAvailability.availableDuration === "Next 2 Weeks"
        ) {
          returnArray.push(
            <ul>
              <li>
                <span className="text-white">
                  <span className="store-maroon-text">
                    <b>{singleAvailability.availableQty}</b>
                  </span>
                  &nbsp; &nbsp;
                  {checkIfMobileEnabled(singleAvailability?.storeAvailabilities, singleAvailability.availableDuration, true)}
                </span>
              </li>
            </ul>
          );
        } else {
          singleAvailability.storeAvailabilities.map((storeData, storeKey) => {
            if (
              singleAvailability.code &&
              (singleAvailability.code != -38 || singleAvailability.code != -50)
            ) {
              returnArray.push(
                <ul>
                  <li>
                    <span className="text-white">
                      <b>{storeData.storeQty}</b>
                    </span>
                    <span className="text-white">
                      &nbsp;{` at Store #`}
                      <b>{storeData.store}</b>
                      <br />
                      {singleAvailability.availableDuration ===
                        "Direct Order Store" ||
                        singleAvailability.availableDuration ==
                        "Pick Up Store" ? (
                        <b>
                          ({singleAvailability.availableDuration},
                          {storeData.storeQualifier
                            ? storeData.storeQualifier
                            : ""}
                          )
                        </b>
                      ) : null}
                    </span>
                  </li>
                </ul>
              );
            }
          });
        }
      }
    });
    //returnArray.shift();
    let fullDiv = [];
    fullDiv = <div>{returnArray}</div>;
    return fullDiv;
  };

  const addToCartForCandK = (e, index) => {
    let selectedAvailabilityHourss = constructCartAvailability(index);
    dispatch({
      payload: {
        candkCheckoutDetails: selectedAvailabilityHourss
      },
      type: actionTypes.CNK_CART_CHECKOUT_FETCHED,
    });
    const statusMessageProps = {
      heading: "Item added to cart",
      message: "",
      type: "success",
    };
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  }

  const addToCart = (e, index) => {
    let selectedAvailabilityHourss = constructCartAvailability(index);
    dispatch({
      payload: {
        selectedAddCartData: _cloneDeep(selectedAvailabilityHourss),
      },
      type: actionTypes.SELECTED_ADDCART_DATA,
    });
    if (isStockOrder(orderType)) {
      dispatch({
        payload: {
          addToCartWarning: true,
        },
        type: actionTypes.ADDTO_CART_WARNING,
      });
    } else {
    let {
      userDetails: { x2userName, userId, shipTos, billTo },
    } = user;
    selectedAvailabilityHourss = _remove(selectedAvailabilityHourss, (a) => {
      return Number(a.qty) >= 0;
    });

    if (!_isEmpty(selectedAvailabilityHourss)) {
      addItemToCart2(selectedAvailabilityHourss, dispatch, true, user, "chemicaltableview");
      let uniqSAH = _uniqBy(selectedAvailabilityHourss, "descId");
      uniqSAH.map((s, k) => {
        if (!!s.categoryId && !!s.groupId) {
          const payload = {
            categoryId: s.categoryId,
            createdBy: x2userName,
            createdDate: timeInUTC(),
            customerId: billTo.billToId,
            engine: "",
            engineId: "",
            groupId: s.groupId,
            lastOrderedDate: timeInUTC(),
            make: "",
            makeId: "",
            model: "",
            modelId: "",
            orderedFlag: "N",
            partTypeDesc: s.desc,
            partTypeDescId: s.descId,
            searchPartId: "",
            shipToId: shipTos[0].shipToId,
            source: "chemicals",
            updatedBy: x2userName,
            updatedDate: timeInUTC(),
            userFilter: "selected",
            year: "",
          };
          dispatch(setPartHistory(payload));
        }
      });
    }
  }
  };

  const partNumberFormatter = (cell, row, index) => {
    let image = row.part.imageUrl
      ? `${row.part.imageUrl}?${getImageURLCookieDetails()}`
      : "/noImage.jpg";
    let imagesLength = 0;
    // if (_isEmpty(image)) {
    //     image = rowData.thumbnail
    //         ? `data:image/png;base64,${rowData.thumbnail}`
    //         : '/noImage.jpg';
    // }
    return (
      <Fragment>
        <div className="product-image-container float-left">
          {row.part != "" && row.description != "" ? (
            <div className="image-thumbnail-container-table">
              <Image
                className={
                  "image-thumbnail"
                }
                src={image}
              />
            </div>
          ) : (
            <span></span>
          )}
        </div>
        <div className="product_img">
          {row.items != "" &&
            row.brand[0].name == "" &&
            row.description == "" ? (
            <div className="product-image-container table-view-margin">
              {row.part && row.part.partSKU && row.part.partSKU.length > 0 && (
                <span onClick="">
                  {row.part.partSKU}
                  <br></br>
                </span>
              )}
            </div>
          ) : (
            <div className="product-image-container table-view-margin">
              {row.part && row.part.partSKU && row.part.partSKU.length > 0 && (
                <span onClick="">{row.part.partSKU}</span>
              )}
            </div>
          )}
        </div>
      </Fragment>
    );
  };

  const togglePopup = (popupName, index) => {
    let details = rows.map((v) => v.rowMoreInfo);
    setIndex(index);
    setSuplyListData(rows[index].part);
    setProductData(details[index]);
    switch (popupName) {
      case "lostSales":
        setshowLostSales(!showLostSales);
        break;
      default:
        break;
    }
  };
  const rowClasses = (row, rowIndex) => {
    let classes = null;

    if (row.groupby) {
      classes = "rowStyleCss";
    }
    return classes;
  };

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.groupby) {
      style.backgroundColor = "#e3e3e3";
      style.border = "solid 2px #8c99a6";
    }

    return style;
  };
  return (
    <div className="supply-list-custom-table">
      {supplyListTableRows.length > 0 ? (
        <Fragment>
          <BootstrapTable
            keyField="custId"
            data={supplyListTableRows}
            columns={supplyListTableColumns}
            rowClasses={rowClasses}
            rowStyle={rowStyle}
          />
          {addItemcartFetching && (
                    <div className="custom_spinner_container fixed">
                    <Spinner className="custom_spinner" animation="border" />
                 </div>
          )}
          {showLostSales ? (
            <LostSales
              openPopup={showLostSales}
              closePopup={() => {
                togglePopup("lostSales", indexValue);
              }}
              selectedPartDetails={[
                {
                  availabilityHour:
                    suplyListData.pricingAvailability.availability &&
                      suplyListData.pricingAvailability.availability.length > 0
                      ? suplyListData.pricingAvailability.availability[0]
                        .availableDuration
                      : null,
                  availableQty:
                    suplyListData.pricingAvailability.availability &&
                      suplyListData.pricingAvailability.availability.length > 0
                      ? suplyListData.pricingAvailability.availability[0]
                        .availableQty
                      : 0,
                  mfgCode: suplyListData.pricingAvailability.mfgCode,
                  costPrice: suplyListData.pricingAvailability.costPrice,
                  desc: suplyListData.partDescription,
                  partNum: suplyListData.partSKU,
                  qty: 0,
                  vehicleInfo: "Non-Vehicle Specific",
                },
              ]}
              dispatch={rowData && rowData.dispatch}
              userId={user.userDetails.x2userName}
              source="catalogPage"
            />
          ) : null}
          {openWarning && (
            <XModal
              show={openWarning}
              handleClose={handleWarningClose}
              handleAction={handleWarningClose}
              title="Warning"
              className="custom_xmodal"
              isValidValidity={true}
              yesButtonText="Ok"
              noButtonText="Cancel"
            >
              <div className="delete__text text__center">
                <span className="bold">
                  Please enter a quantity less than or equal to{" "}
                  {currentTottalAvailable}
                </span>
              </div>
            </XModal>
          )}
          {showAvailabilityStoreView ?
          (<AvailabilityStoreView
          openPopup={showAvailabilityStoreView}
          closePopup={() => setShowAvailabilityStoreView(false)}
          storeData={storeData}
          />
          )
          : null }
        </Fragment>
      ) : null}
    </div>
  );
}

export default TableViewWrapper;
