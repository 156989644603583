import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  UpdateUserMobileNumber,
  getUsersEmailList,
  deleteUser,
  getModifyUser,
  deleteUserOSR,
  getUserPreference,
  updateUserPreference,
  updateUserBillTo,
  impersonateBillTo,
  getPartImageCookie,
  getCompaniesList,
  getDeleteDriver,
  getUsersDriverList,
  UpdateDriverDetailsInformation,
  getCompanyDataBasedOnCompyAdmin,
  deleteDriver,
  modifyCompny,
  getListOfUsersByType,
  getJwtTockenUsingSession,
  getGUIDusingUserInfo,
  getDelveryPickupDropdownData,
  getCustomers,
  getUserProgramAccess,
  getAccountConfig,
  getUserDSWAccounts,
} from "./usermanagement.service";
import { fetchUserDetails } from "../../../actions/auth";

export const updateUserDetails = createAsyncThunk(
  "updateUserDetails",
  async (payload) => {
    const response = await UpdateUserMobileNumber(payload);
  }
);

export const updateDriverDetails = createAsyncThunk(
  "updateDriverDetails",
  async (payload) => {
    const response = await UpdateDriverDetailsInformation(payload);
  }
);


export const getCompanyDataBasedOnCompanyAdmin = createAsyncThunk(
  "getCompanyDataBasedOnCompanyAdmin",
  async (payload) => {
    const response = await getCompanyDataBasedOnCompyAdmin(payload);
    return response.data;
  }
);

export const updateBillTo = createAsyncThunk(
  "updateBillTo",
  async (payload) => {
    const response = await updateUserBillTo(payload);
  }
);

export const impersonateUserBillTo = createAsyncThunk(
  "impersonateUserBillTo",
  async (payload, { signal, rejectWithValue }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await impersonateBillTo(payload, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUsersList = createAsyncThunk(
  "getUsersList",
  async (payload) => {
    const response = await getUsersEmailList(payload);
    return response.data;
  }
);

export const getCompanyList = createAsyncThunk(
  "getCompanyList",
  async (payload) => {
    const response = await getCompaniesList();
    return response.data;
  }
);

export const deleteUserEmailOSR = createAsyncThunk(
  "deleteUserEmailOSR",
  async (payload, { signal, rejectWithValue }) => {
      const source = axios.CancelToken.source();
      signal.addEventListener("abort", () => {
        source.cancel();
      });
      try {
        const response = await deleteUserOSR(payload, {
          cancelToken: source.token,
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
  }
);

export const getAllCustomers = createAsyncThunk(
  "getAllCustomers",
  async (payload) => {
      const response = await getCustomers(payload);
      return response.data;
  }
);

export const deleteUserEmail = createAsyncThunk(
  "deleteUserEmail",
  async (payload) => {
    const response = await deleteUser(payload);
    return response;
  }
);

export const getModifyUserDetails = createAsyncThunk(
  "getModifyUserDetails",
  async (payload) => {
    const response = await getModifyUser(payload);
    return response.data;
  }
);

export const getUserPreferenceDetails = createAsyncThunk(
  "getUserPreferenceDetails",
  async (payload) => {
    const response = await getUserPreference(payload);
    return response.data;
  }
);

export const updateUserPreferenceDetails = createAsyncThunk(
  "updateUserPreferenceDetails",
  async (payload) => {
    const response = await updateUserPreference(payload);
    return response.data;
  }
);

export const getPartImageURLCookie = createAsyncThunk(
  "getPartImageURLCookie",
  async (payload) => {
    const response = await getPartImageCookie();
    return response.data;
  }
);

export const getDriverList = createAsyncThunk(
  "getDriversList",
  async (payload) => {
    const response = await getUsersDriverList(payload);
    return response.data;
  }
);

export const deleteDriverEmail = createAsyncThunk(
  "deleteDriverEmail",
  async (payload) => {
    const response = await deleteDriver(payload);
    return response;
  }
);

export const getDeleteDriverList = createAsyncThunk(
  "getDeleteDriversList",
  async (payload) => {
    const response = await getDeleteDriver(payload);
    return response.data;
  }
);

export const modifyCompany = createAsyncThunk(
  "modifyComapany",
  async (payload) => {
    const response = await modifyCompny(payload);
    return response;
  }
);

export const getListOfUsersByUserType = createAsyncThunk(
  "getListOfUsersByUserType",
  async (payload) => {
    const response = await getListOfUsersByType(payload);
    return response.data;
  }
);

export const getJwtTocken = createAsyncThunk(
    "getJwtTocken",
    async (payload) => {
        const response = await getJwtTockenUsingSession(payload);
        console.log(response);
        return response.data;
    }
);
// ------should remove after iframe development get complete----------------
export const getGUID = createAsyncThunk(
    "getGUID",
    async (payload) => {
        const response = await getGUIDusingUserInfo(payload);
        console.log(response);
        return response.data;
    }
);
// -----------------------------------------------------------

//get delivery pikcup stores
export const getDelveryPickupData = createAsyncThunk(
  "getDelveryPickupData",
  async () => {
    const response = await getDelveryPickupDropdownData();
    return response.data;
  }
);

export const getUserPgmAccess = createAsyncThunk(
  "getUserPgmAccess",
  async (payload) => {
      const response = await getUserProgramAccess(payload);
      console.log(response);
      return response.data;
  }
);

export const getAllAccountConfigInfo = createAsyncThunk(
  "getAllAccountConfigInfo",
  async (payload) => {
      const response = await getAccountConfig(payload);
      return response.data;
  }
);
export const getDSWAccounts = createAsyncThunk(
  "getDSWAccounts",
  async (payload) => {
    const response = await getUserDSWAccounts(payload);
    return response.data;
  }
);

