import React from 'react'
import T from 'prop-types'
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from "@mui/icons-material";

function Accordian(props) {
    const { title, children } = props;
    return (
        <MuiAccordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                {title}
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </MuiAccordion>
    )
}

Accordian.propTypes = {
    title: T.string | T.element
}

export default Accordian
