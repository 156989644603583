import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {getAllWarrantyDetails} from "../Warranty/warranty.service";


//get AllWarrantyDetails
export const getAllWarranty = createAsyncThunk(
    "getAllWarrantyDetails",
    async (payload) => {
      const response = await getAllWarrantyDetails();
      return response.data;
    }
  )
 