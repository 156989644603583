import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuItem
} from "react-bootstrap-typeahead";

const ITEM_HEIGHT = 32;

const OptionCard = (props) => {
  const {
    results,
    getGroupLabels,
    setOption,
    getValue,
    isVehicleSelected,
    handleChange,
    ...menuProps
  } = props;

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (isVehicleSelected) {
      setGroups(results);
    } else {
      const __r = {};
      if (!isVehicleSelected) {
        if (results && results[0]) {
          Object.keys(results[0]).forEach((key) => {
            if (results[0][key].length > 0) {
              __r[key] = results[0][key];
            }
          });
        }

        setGroups([__r]);
      }
    }
  }, [results]);

  const [expanded, setExpanded] = useState(false);

  const handleExpandToggle = (label, bool) => {
    if (bool) {
      setGroups(results);
      setExpanded(!expanded);
    } else {
      const mGroups = groups.map((g) => {
        const o = {};
        Object.keys(g).forEach((k) => {
          if (k === label) {
            return (o[label] = g[label]);
          }
        });

        return o;
      });

      setExpanded(!expanded);
      setGroups(mGroups);
    }
  };

  const onOptionsLoad = (e, self) => {
    const options = document.querySelectorAll(".gs-options-card");
    const len = options.length;
    const lastPrev = options[len - 2];
    const last = options[len - 1];

    let calcHeight = 0;

    options.forEach((o, i) => {
      const height = o.offsetHeight;
      const lastItemHeight = last.offsetHeight;

      if (i < len - 2) {
        calcHeight = calcHeight + height;

        if (calcHeight + 12 + 16 >= last.offsetHeight) {
          calcHeight = 0;
        }
      } else if (i === len - 2) {
        if (lastItemHeight < 100) {
          // lastPrev.style.height = `${lastPrevItemHeight - calcHeight - 12}px`;
        } else {
          lastPrev.style.height = `${last.offsetHeight - calcHeight - 12}px`;
        }
      }
    });
    return { display: "flex" };
  };

  const handleKeyDown = (e) => {
    console.log("key down ==> ", e);
    e.preventDefault();
    const activeElement = e.target;
    const currTarget = e.target;
    const dataset = currTarget.dataset;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (activeElement.nextSibling) {
        activeElement.nextSibling?.focus();
      } else {
        const grandParent = activeElement.parentNode.parentNode;
        const grandParentSibling = grandParent.nextSibling;

        if (!grandParentSibling) {
          const greatGrandParent = grandParent.parentNode;
          const firstChild = greatGrandParent.firstChild;

          const item = firstChild?.querySelector(".dropdown-item");
          if (item) {
            item.focus();
          }
        }

        const listItem = grandParentSibling?.querySelector(".dropdown-item");
        if (listItem) {
          listItem.focus();
        }
      }
    } else if (e.key === "ArrowUp") {
      if (activeElement.previousSibling) {
        activeElement.previousSibling.focus();
      } else {
        const grandParent = activeElement.parentNode.parentNode;
        const grandParentSibling = grandParent.previousSibling;

        if (!grandParentSibling) {
          const greatGrandParent = grandParent.parentNode;
          const firstChild = greatGrandParent.firstChild;

          const items = firstChild?.querySelectorAll(".dropdown-item");
          const lastItem = items[items.length - 1];

          if (lastItem) {
            lastItem.focus();
          }
        }

        const items = grandParentSibling?.querySelectorAll(".dropdown-item");
        const lastItem = items[items.length - 1];

        if (lastItem) {
          lastItem.focus();
        }
      }
    } else if (e.key === "ArrowRight") {
      const currId = Number(dataset.id);
      const parentElement = currTarget.parentElement;

      const height = parentElement.scrollHeight;

      const ITEM_PER_ROW = parseInt(height / ITEM_HEIGHT);
      const nextId = currId + ITEM_PER_ROW;
      const list = parentElement.querySelector(`[data-id="${nextId}"]`);
      if (list) {
        list.focus();
      }
    } else if (e.key === "ArrowLeft") {
      const currId = Number(dataset.id);
      const parentElement = currTarget.parentElement;

      const height = parentElement.scrollHeight;

      const ITEM_PER_ROW = parseInt(height / ITEM_HEIGHT);
      const nextId = currId - ITEM_PER_ROW;
      const list = parentElement.querySelector(`[data-id="${nextId}"]`);
      if (list) {
        list.focus();
      }
    } else if (e.key === "Enter") {
      const option = JSON.parse(dataset?.option);

      handleChange([option]);
    } else if (e.key === "Tab") {
      const parentNode = currTarget.parentNode;
      const grandParent = parentNode.parentNode;
      const grandParentSibling = grandParent.nextSibling;

      if (!grandParentSibling) {
        const greatGrandParent = grandParent.parentNode;
        const firstChild = greatGrandParent.firstChild;

        const item = firstChild?.querySelector(".dropdown-item");
        if (item) {
          item.focus();
        }
      }

      const listItem = grandParentSibling?.querySelector(".dropdown-item");
      if (listItem) {
        listItem.focus();
      }
    }
  };

  return (
    <Menu {...menuProps} onKeyDown={handleKeyDown}>
      {groups.map((result, index) => {
        return Object.keys(result).map((label, i) => {
          return (
            (result[label].length > 0 || label === "parts" ? true : false) && (
              <div
                key={`header-${i}`}
                className="gs-options-card"
                style={onOptionsLoad(label, this)}
                tabIndex={i}
              >
                <Menu.Header>
                  <h6>{getGroupLabels(label)}</h6>
                  {result[label].length > 20 && (
                    <span
                      className="material-icons icon"
                      onClick={() => handleExpandToggle(label, expanded)}
                    >
                      {" "}
                      {expanded ? "fullscreen_exit" : "fullscreen"}
                    </span>
                  )}
                </Menu.Header>
                <div className="gs-options">
                  {label === "parts" && isVehicleSelected ? (
                    Array.isArray(result[label]) ? (
                      result[label].map((value, oIndex) => {
                        return (
                          <MenuItem
                            tabIndex={0}
                            key={`${index}_${i}_${label}_${oIndex}`}
                            option={setOption(value, label)}
                            data-option={JSON.stringify(
                              setOption(value, label)
                            )}
                            data-id={oIndex}
                          >
                            {getValue(value, label)}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <div>
                        {result[label]["likelyMatches"] &&
                          result[label]["likelyMatches"].length > 0 && (
                            <React.Fragment>
                              {" "}
                              <div className="parts-title">Likely Matches</div>
                              {result[label]["likelyMatches"]?.map(
                                (value, oIndex) => {
                                  return (
                                    <MenuItem
                                      key={`${index}_${i}_${label}_${oIndex}`}
                                      option={setOption(value, label)}
                                      tabIndex={0}
                                      data-option={JSON.stringify(
                                        setOption(value, label)
                                      )}
                                      data-id={oIndex}
                                    >
                                      {getValue(value, label)}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </React.Fragment>
                          )}
                        {result[label]["allMatches"] &&
                          result[label]["allMatches"].length > 0 && (
                            <React.Fragment>
                              <div className="parts-title">All Matches</div>
                              {result[label]["allMatches"]?.map(
                                (value, oIndex) => {
                                  return (
                                    <MenuItem
                                      key={`${index}_${i}_${label}_${oIndex}`}
                                      option={setOption(value, label)}
                                      tabIndex={0}
                                      data-option={JSON.stringify(
                                        setOption(value, label)
                                      )}
                                      data-id={oIndex}
                                    >
                                      {getValue(value, label)}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </React.Fragment>
                          )}
                      </div>
                    )
                  ) : (
                    result[label].map((value, oIndex) => {
                      return (
                        <MenuItem
                          key={`${index}_${i}_${label}_${oIndex}`}
                          option={setOption(value, label)}
                          tabIndex={0}
                          data-option={JSON.stringify(setOption(value, label))}
                          data-id={oIndex}
                        >
                          {getValue(value, label)}
                        </MenuItem>
                      );
                    })
                  )}
                </div>
              </div>
            )
          );
        });
      })}
    </Menu>
  );
};

export default OptionCard
