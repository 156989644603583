import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import {
  sendEmail
} from "./email.service";

export const sendUtilServiceEmail = createAsyncThunk(
  "sendUtilServiceEmail",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await sendEmail(payload, {
      cancelToken: source.token,
    });
    return response.data;
  }
);
