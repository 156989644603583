import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import {
  InputGroup,
  Form,
  Button,
  Tabs,
  Tab,
  Row,
  Col,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { triggerCustomUxCriticalError } from "../../../actions/error";
import _cloneDeep from "lodash/cloneDeep";
import { updateUserPreferenceDetails } from "./usermanagement.thunk";
import { getAllStoreNumber } from "../../../components/shared/MyGarage/mygarage.thunks";
import {
  validateOnlineIdForm,
  getPreferenceDataFromStorage,
  updateUserPreferenceToStore,
  handleKeyDown,
} from "../../../helpers/utils";
import FormValidationErrorMessage from "../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import { updateToken } from "../../../actions/auth";
import XSelect from "../../../components/shared/XSelect";
import _sortBy from "lodash/sortBy";
import LaborPreference from "./LaborPreference";
import MultiSelectPreference from "./MultiSelectPreference";
import _ from "lodash";

const SetUserPreference = () => {
  const dispatch = useDispatch();
  let userPreferenceDetail = getPreferenceDataFromStorage();
  userPreferenceDetail = _sortBy(_cloneDeep(userPreferenceDetail), [
    "displayOrder",
    "displayItemOrder",
  ]);
  const [userPrefCode, setUSerPref] = useState([]);
  const [brandEnabled, setbrandEnabled] = useState([]);
  const [allText, setAllText] = useState([]);
  let userPreferenceDetail_Filter = userPreferenceDetail.filter((item) => {
    return item.code !== "Vehicle Specs";
  });
  const [userPreferenceChangedData, setUserPreferenceChanged] = useState(
    userPreferenceDetail_Filter
  );
  const [updatePreference, setupdatePreference] = useState(false);
  const userDetails = useSelector((state) => state.user.userDetails);
  const userType =
    userDetails && userDetails.userType ? userDetails.userType : null;
  const [validity, setValidity] = useState({
    isEmailIdNotNull: true,
    isEmailIdLengthBelowLimit: true,
    isEmailIdMinLength: true,
    isEmailValidFormat: true,
    isValidEmail: true,
  });

  useEffect(() => {
    dispatch(getAllStoreNumber());
  }, []);

  useEffect(() => {
    let selectedUniqueItems = [];
    selectedUniqueItems = [
      ...new Set(userPreferenceDetail.map((item) => item.code)),
    ];
    setStockAvailabilityFilter();
    // setExpressPreferenceData();
    setBrandsPreferenceFilter();
    setUSerPref(selectedUniqueItems);
  }, []);

  useEffect(() => {
    let selectedUniqueItems = [];
    selectedUniqueItems = [
      ...new Set(userPreferenceDetail.map((item) => item.code)),
    ];
    setStockAvailabilityFilter();
    //setExpressPreferenceData();
    setBrandsPreferenceFilter();
    setUSerPref(selectedUniqueItems);
  }, [userDetails]);

  const setBrandsPreferenceFilter = () => {
    let filterBrandsList = [];
    if (userPreferenceChangedData && userPreferenceChangedData.length > 0) {
      if (!_isEmpty(userPreferenceDetail)) {
        userPreferenceChangedData.map((item) => {
          if (
            item.code === "Chemicals Catalog" &&
            item.text === "Prefered Brands"
          ) {
            item.multiAnswer.map((answer) => {
              filterBrandsList = answer;
              return null;
            });
          }
        });

        setbrandEnabled(filterBrandsList);
      }
    }

    return filterBrandsList;
  };

  const setStockAvailabilityFilter = () => {
    if (userPreferenceChangedData && userPreferenceChangedData.length > 0) {
      let within45Mins = "Within45Mins";
      let immediately = "Immediately";
      userPreferenceChangedData.map((item) => {
        if (
          item.code == "Product Information" &&
          item.text == "Stock Availability"
        ) {
          if (
            userDetails &&
            userDetails.shipTos &&
            userDetails.shipTos.length > 0 &&
            userDetails.shipTos[0].shipMethod &&
            (userDetails.shipTos[0].shipMethod === "0001" || userDetails.shipTos[0].shipMethod === "0002")
          ) {
            item.possibleAnswer.map((answer) => {
              if (answer.code === within45Mins) {
                answer.display = false;
              } else {
                answer.display = true;
              }
              if (item.answer && item.answer.code === within45Mins) {
                item.answer = { code: "all", default: true, text: "All" };
              }
            });
          } else {
            item.possibleAnswer.map((answer) => {
              if (answer.code == immediately) {
                answer.display = false;
              } else {
                answer.display = true;
              }
              if (item.answer && item.answer.code == immediately) {
                item.answer = { code: "all", default: true, text: "All" };
              }
            });
          }
        }
      });
      setUserPreferenceChanged(userPreferenceChangedData);
    }
  };

  const setExpressPreferenceData = () => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let isExpress = false;
    if (
      userPreferenceChangedData &&
      userPreferenceChangedData.length > 0 &&
      !_isEmpty(userDetails)
    ) {
      let grandAccessExpress = userPreferenceDetail.find((item) => {
        return (
          item.text === "express-customer" && item.code === "User Management"
        );
      });
      if (grandAccessExpress && grandAccessExpress.possibleAnswer) {
        grandAccessExpress.possibleAnswer.map((singleAnswer) => {
          const arr = singleAnswer.text.split(";");
          let foundExpressId = arr.find((item) => {
            return item === userDetails?.xlPartAccountNumber;
          });
          if (
            foundExpressId !== undefined &&
            singleAnswer.default &&
            foundExpressId === userDetails?.xlPartAccountNumber
          ) {
            isExpress = true;
          }
        });
      }
    }
    if (userPreferenceChangedData && userPreferenceChangedData.length > 0) {
      userPreferenceChangedData.map((item) => {
        if (item.code == "Parts Catalog" && item.text == "Presentation Mode") {
          item.possibleAnswer.map((answer) => {
            answer.display = true;
            if (answer.code === "express" && !isExpress) {
              answer.display = false;
              if (item.answer && item.answer.code === "express") {
                item.answer = {
                  code: "illustrated",
                  default: true,
                  text: "Illustrated",
                };
              }
            }
          });
        }
      });
      setUserPreferenceChanged(userPreferenceChangedData);
    }
  };

  const getDropDownList = (data) => {
    let options = [];
    if (data && data.possibleAnswer && data.possibleAnswer.length > 0) {
      data.possibleAnswer.map((item) => {
        if (
          data.code == "Product Information" &&
          data.text == "Stock Availability"
        ) {
          if (item.display && item.display == true) {
            const eachOption = {
              id: item.code,
              value: item.code,
              label: item.text,
            };
            options.push(eachOption);
          }
        } else {
          const eachOption = {
            id: item.code,
            value: item.code,
            label: item.text,
          };
          options.push(eachOption);
        }
      });
    }
    return options;
  };

  const handlePreferenceUpdate = () => {
    if (validity.isValidEmail || !validity.isEmailIdNotNull) {
      setupdatePreference(false);
      let payload = [];
      userPreferenceChangedData.forEach((item) => {
        item.possibleAnswer.forEach((answerItem) => {
          if (item.preferenceType === "text") {
            payload.push({
              id: item.id,
              code: item.code,
              answer: _isEmpty(answerItem?.text)
                ? item?.answer?.text
                : answerItem?.text,
              multiAnswer: [],
            });
          }
          if (answerItem.default && item.preferenceType === "select") {
            payload.push({
              id: item.id,
              code: item.code,
              answer: answerItem?.code,
              multiAnswer: [],
            });
          }
        });
        if (item.default && item.preferenceType === "multi-select") {
          payload.push({
            id: item.id,
            code: item.code,
            answer: null,
            multiAnswer: item.multiAnswer,
          });
          if (item.multiAnswer?.[0] === "All") {
            let option = {
              value: "All",
              label: "All",
            };
            allText.length = 0;
            allText.push(option);
          }
        }
      });
      let userprefData = _cloneDeep(userPreferenceChangedData);
      userprefData.map((item) => {
        item.possibleAnswer.map((answerItem) => {
          if (answerItem.default && !(item.preferenceType == "text")) {
            item.answer = {
              code: answerItem.code,
              default: answerItem.default,
              text: answerItem.text,
            };
          }
          if (item.preferenceType == "text") {
            item.answer = {
              code: answerItem.code,
              default: answerItem.default,
              text: answerItem.text,
            };
          }
        });
      });
      updateUserPreferenceToStore(userprefData || {});
      const statusMessageProps = {
        heading: "Success",
        message: "User preferences have been changed successfully.",
        type: "success",
      };
      payload = !_isEmpty(payload) && _.uniqBy(payload, "id");
      dispatch(updateUserPreferenceDetails(payload)).then(() => {
        updateToken(dispatch).then(() => {
          triggerCustomUxCriticalError(statusMessageProps, dispatch);
        });
      });
    }
  };

  const handlePreferenceSelectChange = (value, event) => {
    let userPreferenceData = [...userPreferenceChangedData];
    setupdatePreference(true);
    let matchingArray;
    userPreferenceData.map((item) => {
      if (item && item.preferenceType && item.preferenceType === "select") {
        item.answer = {};
      }
    });
    matchingArray = userPreferenceData.find((item) => {
      return item.code == event.name.code && item.text == event.name.text;
    });
    matchingArray.possibleAnswer.map((item) => {
      if (item.text === value.label) {
        item.default = true;
        return item;
      } else {
        item.default = false;
        return item;
      }
    });
    setUserPreferenceChanged(userPreferenceData);
  };

  const handlePreferenceMultiSelectChange = (value, event) => {
    let userPreferenceData = [...userPreferenceChangedData];
    setupdatePreference(true);
    let matchingArray = userPreferenceData.find((item) => {
      return item.code == event.name.code && item.text == event.name.text;
    });
    let multiAnswer = [];
    if (value) {
      value.map((option) => {
        multiAnswer.push(option.value);
      });
    } else if (
      event.name.code === "Price" ||
      "Chemicals Catalog" ||
      "Parts Catalog"
    ) {
      multiAnswer.push("All");
    }
    setAllText([]);
    matchingArray.answer = {};
    matchingArray.default = true;
    matchingArray.multiAnswer = multiAnswer;
    setUserPreferenceChanged(userPreferenceData);
  };

  const handleTextChange = (e, prefDetail) => {
    let userPreferenceData = [...userPreferenceChangedData];
    setupdatePreference(true);
    let matchingArray;
    userPreferenceData.map((item) => {
      item.answer = {};
    });
    matchingArray = userPreferenceData.find((item) => {
      return item.code == prefDetail.code && item.text == prefDetail.text;
    });

    matchingArray.possibleAnswer.map((item) => {
      item.text = e.target.value;
      item.default = false;
    });
    if (prefDetail.code !== "Labor") {
      let validityEvaluated = validateOnlineIdForm(
        "email",
        e.target.value,
        validity
      );
      setValidity({ ...validityEvaluated });
    }
    setUserPreferenceChanged(userPreferenceData);
  };

  const findValue = (data) => {
    let matchValue = null;
    let userPrefAnswserData = data.answer;
    userPreferenceChangedData.map((item) => {
      if (item.code == data.code) {
        if (
          !_isEmpty(data) &&
          data.possibleAnswer &&
          data.possibleAnswer.length > 0
        ) {
          if (!_isEmpty(userPrefAnswserData)) {
            data.possibleAnswer.map((item) => {
              if (
                userPrefAnswserData.text.toLowerCase() ===
                item.text.toLowerCase()
              ) {
                item.default = true;
                return item;
              } else {
                item.default = false;
                return item;
              }
            });
          }
          matchValue = data.possibleAnswer.find((item) => {
            return item.default === true;
          });
        }
      }
    });
    if (matchValue == null) {
      matchValue = null;
    } else {
      matchValue =
        matchValue && matchValue.default === true ? matchValue.text : null;
    }
    return {
      label: !_isEmpty(matchValue) ? matchValue : "",
      value: !_isEmpty(matchValue) ? matchValue.toLowerCase() : "",
    };
  };

  return (
    <Form>
      <Fragment>
        <div class="tab-wrapper">
          <Tabs id="controlled-tab-example" className="vertical-tabs">
            {userPrefCode &&
              userPrefCode.length > 0 &&
              userPrefCode.map((prefDetail, index) => {
                let code = prefDetail;
                return (
                  code !== "User Management" &&
                  code !== "Vehicle Specs" &&
                  (code === "Labor"
                    ? userType === "CUSTOMER_ADMIN"
                      ? true
                      : false
                    : true) &&
                  (code === "Locations"
                    ? userType === "DSM"
                      ? true
                      : false
                    : true) && (
                    <Tab
                      className="user-preference-tabs"
                      eventKey={prefDetail}
                      title={prefDetail}
                    >
                      {userPreferenceChangedData.map((prefDetail, index) => {
                        return (
                          code === prefDetail.code && (
                            <div
                              className={
                                prefDetail.code !== "Labor"
                                  ? "label-input-container_preference mb-0"
                                  : ""
                              }
                            >
                              {prefDetail.preferenceType == "text" &&
                              prefDetail.code !== "Labor"
                                ? prefDetail.possibleAnswer.map(
                                    (value, index) => {
                                      return (
                                        <Fragment>
                                          <div className="label-input-container-input-wrapper">
                                            <div className="label-input-container-input">
                                              <label
                                                className="label-name"
                                                htmlFor="user-preference"
                                              >
                                                {userPreferenceChangedData &&
                                                  userPreferenceChangedData.length >
                                                    0 &&
                                                  value.code}
                                              </label>
                                              <div>
                                                <Form.Control
                                                  type="text"
                                                  name="username"
                                                  placeholder="Enter email for Order Notifications"
                                                  defaultValue={
                                                    (prefDetail.answer &&
                                                      prefDetail.answer.text) ||
                                                    value.text
                                                  }
                                                  onChange={(e) => {
                                                    handleTextChange(
                                                      e,
                                                      prefDetail
                                                    );
                                                  }}
                                                />
                                                <FormValidationErrorMessage
                                                  condition={
                                                    !validity.isValidEmail &&
                                                    validity.isEmailIdNotNull
                                                  }
                                                  errorMessage="Please enter valid email"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </Fragment>
                                      );
                                    }
                                  )
                                : prefDetail.preferenceType === "select" && (
                                    <Fragment>
                                      <label htmlFor="user-preference">
                                        {userPreferenceChangedData &&
                                          userPreferenceChangedData.length >
                                            0 &&
                                          prefDetail.text}
                                      </label>
                                      <XSelect
                                        options={getDropDownList(prefDetail)}
                                        onChange={(value, e, prefDetail) => {
                                          handlePreferenceSelectChange(
                                            value,
                                            e
                                          );
                                        }}
                                        defaultValue={{
                                          label: !_isEmpty(prefDetail)
                                            ? findValue(prefDetail).label
                                            : "",
                                          value: !_isEmpty(prefDetail)
                                            ? findValue(prefDetail).value
                                            : "",
                                        }}
                                        value={{
                                          label: !_isEmpty(prefDetail)
                                            ? findValue(prefDetail).label
                                            : "",
                                          value: !_isEmpty(prefDetail)
                                            ? findValue(prefDetail).value
                                            : "",
                                        }}
                                        name={prefDetail}
                                        placeholder="Select View Type"
                                        className="custom-dropdown"
                                      />
                                    </Fragment>
                                  )}
                              {prefDetail.preferenceType === "text" &&
                                prefDetail.code === "Labor" &&
                                prefDetail.possibleAnswer.map(
                                  (value, index) => {
                                    return (
                                      <LaborPreference
                                        value={value}
                                        prefDetail={prefDetail}
                                        handleTextChange={handleTextChange}
                                        handleKeyDown={handleKeyDown}
                                      />
                                    );
                                  }
                                )}
                              {(prefDetail.preferenceType === "multi-select" ||
                                prefDetail.preferenceType ===
                                  "multiselect") && (
                                <MultiSelectPreference
                                  userPreferenceChangedData={
                                    userPreferenceChangedData
                                  }
                                  prefDetail={prefDetail}
                                  allText={allText}
                                  handlePreferenceMultiSelectChange={
                                    handlePreferenceMultiSelectChange
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}
                    </Tab>
                  )
                );
              })}
          </Tabs>
        </div>
      </Fragment>
      <Button
        onClick={handlePreferenceUpdate}
        className="user-management-submit-btn"
        disabled={!updatePreference}
      >
        ok
      </Button>
    </Form>
  );
};

export default SetUserPreference;
