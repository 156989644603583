import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import moment from "moment";

import XLLogo from "../../static/images/logo.png";
import { Row, Tabs, Tab, Col } from "react-bootstrap";
import {
	isInputBlank,
	isNumberLessthan,
	hasNumberMinLength,
	isNumeric,
	numbersOnlyRegExp,
  } from "../../helpers/utils";
  import {
	Container,
	Navbar,
	Nav,
	NavDropdown,
	Image,
	Dropdown,
	OverlayTrigger,
	Tooltip,
	Form,
} from 'react-bootstrap';
import userIcon from '../../static/images/icons/red/user.png';
import { logOut } from '../../actions/auth';
import DriverManager from "../User/Admin/DriverManager";
import { getCompanyDataBasedOnCompanyAdmin} from "../User/UserManagement/usermanagement.thunk";

const DriverManagement = ({ props }) => {
	const [key, setKey] = useState("userProfile");
	const userDetailsArr = useSelector((state) => state.user);
	const dispatch = useDispatch();
	let userType = "";
	let userDetails = "";
	let [companyData, setCompanyData] = useState([])
	if (!_isEmpty(userDetailsArr)) {
		userType = userDetailsArr.userDetails.userType;
		userDetails = userDetailsArr.userDetails;
	}

	useEffect(() => {
		if(!_isEmpty(userDetails) && userDetails.x2userName) {
			getCompanyDetails();
		}
	}, [userDetails]);


	const getCompanyDetails = () => {
		if (!_isEmpty(userDetails) && userDetails.x2userName) {
			try {
				const payload = {
					x2UserName: userDetails.x2userName
				};
				const companyAction = dispatch(getCompanyDataBasedOnCompanyAdmin(payload));
				companyAction.then((action) => {
					if (action.payload) {
						setCompanyData(action.payload);
					}
				});
			} catch (error) { }
		}
	};


	const initializeFields = () => {
		const fieldObj = {
		  currentPassword: "",
		  newPassword: "",
		  confirmPassword: "",
		};
		return fieldObj;
	  };
	  const initializeValidity = () => {
		const validity = {
		  hasValidLength: true,
		  isPasswordMatched: true,
		  isValidPassword: true,
		};
		return validity;
	  };
	  const initializeMobValidation = () => {
		const mobValidity = {
		  isMobileNoNotNull: true,
		  isMobileNoLengthBelowLimit: true,
		  isMobileNoMinLength: true,
		  isMobileNoNumeric: true,
		};
		return mobValidity;
	  };
	
	  const [fields, setFields] = useState(initializeFields);
	  const [validity, setvalidity] = useState(initializeValidity);
	  const [mobValidity, setMobValidity] = useState(initializeMobValidation);
	  const [mobileNumber, setMobileNumber] = useState("");
	
	  const handleSelect = (key) => {
		let validityData = initializeValidity();
		if (key === "manageUsers") {
		  setvalidity(validityData);
		  setFields(initializeFields);
		  setMobValidity(initializeMobValidation);
		  setMobileNumber("");
		}
		setKey(key);
	  };

	  const handleInputChange = (event) => {
		const passwordType = event.target.name;
		const { value } = event.target;
		const PasswordRegEx = RegExp(
		  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
		);
		let validityData = initializeValidity();
		switch (passwordType) {
		  case "confirmPassword":
			validityData.isPasswordMatched = fields.newPassword === value;
			setvalidity(validityData);
			break;
		  case "newPassword":
			validityData.hasValidLength = value.length >= 8;
			validityData.isValidPassword = PasswordRegEx.test(value);
			validityData.isPasswordMatched = fields.confirmPassword === value;
			setvalidity(validityData);
			break;
		  default:
			break;
		}
		setFields({
		  ...fields,
		  [event.target.id]: event.target.value,
		});
	  };
	
	  const handleMobNumberOnChange = (data) => {
		const number = data.currentTarget.value;
		const mobNumber = number.charAt(0);
		let value;
		if (mobNumber === " ") {
		  value = number.trim();
		} else {
		  value = number;
		  validateMobileNumber(value);
		}
	  };
	
	  const validateMobileNumber = (value) => {
		let validity = initializeMobValidation();
		validity.isMobileNoNotNull = !isInputBlank(value);
		validity.isMobileNoLengthBelowLimit = isNumberLessthan(value, 10);
		validity.isMobileNoMinLength = hasNumberMinLength(value, 10);
		validity.isMobileNoNumeric = isNumeric(value);
		setMobValidity(validity);
		if (validity) {
		  setMobileNumber(value);
		}
	  };
	
	  const handleOtherKeys = (code) => {
		// KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
		return (
		  code !== 8 &&
		  code !== 9 &&
		  code !== 37 &&
		  code !== 38 &&
		  code !== 39 &&
		  code !== 40 &&
		  code !== 46
		);
	  };
	
	  const handleKeyDown = (event) => {
		if (
		  (numbersOnlyRegExp(event.key) === false ||
			event.keyCode === 32 ||
			(mobileNumber && mobileNumber.length >= 10)) &&
		  handleOtherKeys(event.keyCode)
		) {
		  event.preventDefault();
		}
	  };
	
	if (true) {
		if (true) {
			return (
				<div className="driver_management_view_container">
					<div className="wrapper">
						<div className="driver_management_view_header">
							<img src={XLLogo} alt="XL Parts" />
							<div className="driver_management_detail">
								<span>welcome to the parts house</span>
								<div>
									<div>
										<span className="marron_text">Driver Management</span>
									</div>
								</div>
							</div>
							<Dropdown className="dropdown user-dropdown">
												<Dropdown.Toggle
													as="a"
													className="user-name-driver-mgnt-detail"
												>
													<img
														src={userIcon}
														alt="UserIcon"
													/>
													<div>
														<span>
															{userDetails
																? userDetails.firstName +
																' ' +
																userDetails.lastName
																: ''}
														</span>
													</div>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													{!_isEmpty(userDetails) && (
														<React.Fragment>
															<Dropdown.Item
																disabled
															>
																<span>Hi,
																{` ${userDetails.firstName}`}</span>
																<span className="mobie_show_shipTo_number">
																	{userDetails && userDetails.shipTos && userDetails.shipTos[0]
																		? "Company name"
																		: ''}
																</span>
															</Dropdown.Item>{' '}
															<Dropdown.Divider />
														</React.Fragment>
													)}
													<Dropdown.Item
														onClick={() =>
															logOut(dispatch)
														}
													>
														Logout
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
						</div>
						<div className="user-management-container driver-manager">
							<div className="container">
								{!_isEmpty(companyData)&& companyData.length > 0 && (
									<div className="driver-user_management_user_details">
										<Row>
											<Col md="5">
												<div>
													<span className="user_management_label_color">
														First Name:
							                        </span>
													<span className="">{userDetails.firstName}
							                        </span>
												</div>
												<div>
													<span className="user_management_label_color">
														Last Name:
							                         </span>{" "}
													<span>{userDetails.lastName}</span>
												</div>
												<div>
													<span className="user_management_label_color">
														Mobile Number:
							                        </span>{" "}
													<span>{userDetails.mobileNumber}</span>
												</div>
												<div>
													<span className="user_management_label_color">
														User Type:
							                        </span>{" "}
													<span>{userDetails.userType}</span>
												</div>
												<div>
													<span className="user_management_label_color">Email:</span>{" "}
													<span>{userDetails.x2userName}</span>
												</div>
											</Col>
											<Col md="7">
												<div>
													<span className="user_management_label_color">
														Comapany Name:
							                        </span>{" "}
													<span className="">{companyData && companyData[0].companyName}
							                        </span>
												</div>
												{true && (
													<Fragment>
														<div>
															<span className="user_management_label_color">
																Company Code:
								                            </span>{" "}
															<span>{companyData && companyData[0].companyCode}</span>
														</div>
													</Fragment>
												)}
												{true && (
													<Fragment>
														<div>
															<span className="user_management_label_color">
																Address 1:
								                            </span>{" "}
															<span>{companyData && companyData[0].address1}</span>
														</div>
													</Fragment>
												)}
												{true && (
													<Fragment>
														<div>
															<span className="user_management_label_color">
																Address 2:
								                            </span>{" "}
															<span>{companyData && companyData[0].address2}</span>
														</div>
													</Fragment>
												)}
												{true && (
													<Fragment>
														<div>
															<span className="user_management_label_color">
																City:
								                            </span>{" "}
															<span>{companyData && companyData[0].city}</span>
														</div>
													</Fragment>
												)}
												{true && (
													<Fragment>
														<div>
															<span className="user_management_label_color">
																State:
								                            </span>{" "}
															<span>{companyData && companyData[0].state}</span>
														</div>
													</Fragment>
												)}
											</Col>
										</Row>
									</div>
								)}
								<Tabs
									id="controlled-tab-example"
									activeKey={'driverUsers'}
									onSelect={handleSelect}
									className="vertical-tabs"
								>
									{!_isEmpty(userType) && (userType === 'COMPANY_ADMIN')
										&& (
											<Tab eventKey="driverUsers" title="Driver Manager">
												<DriverManager
												  companyData={companyData}
												/>
											</Tab>

										)}
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default DriverManagement;
