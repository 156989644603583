import React, { useEffect, useState, Fragment, } from 'react';
import { useDispatch, useSelector } from "react-redux";
import TPHLogo from "../../../static/images/TPH Rapid.png";
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { Container, Navbar, Row, Col, Dropdown, Nav, } from 'react-bootstrap';
import SiteNotification from '../../shared/SiteNotification';
import userIcon from '../../../static/images/icons/red/user.png';
import { logOut } from '../../../actions/auth';
import { useHistory } from "react-router-dom";


function Header(props) {

	let { storeURL, storeName ,STORE_USER} = props;
	const dispatch = useDispatch();
	const userDetailsArr = useSelector((state) => state.user);
	const history = useHistory();
	let userDetails = "";
	if (!_isEmpty(userDetailsArr)) {
		userDetails = userDetailsArr.userDetails;
	}
	const handleImage =()=>{
		if(userDetailsArr.userDetails && userDetailsArr.userDetails.userType === "STORE_USER"){
		history.push({
			pathname: '/store-user-dashboard'
		  })
		}else{
			history.push({
				pathname: '/manager-dashboard'
			  })			
		}
	}
	return (
		<Fragment>
			<div className="fixed-nav-wrapper">
				<div className="fixed-top-nav">
					<section className="section__navigation">
						{/* <section className="section__chat_DashBorad">
							<Container className="py-2 container-fluid">
								<div className="section__chat--notification-message text-center ">
									<Navbar expand="lg" className="p-0">
										<SiteNotification 
										showNotification={true}
										/>

									</Navbar>
								</div>
							</Container>
						</section> */}
						<div className="container-fluid store_management_view_header">
							<img className="logo-img-store" src={TPHLogo} alt="XL Parts" 
							onClick={() =>  handleImage() }/>
							<div className="driver_management_detail">
								<span>welcome to the parts house</span>
								<div>
									<div>
										<span className="marron_text sub-head">Store Dashboard</span>
									</div>
								</div>
							</div>
							<Navbar expand="lg" className="p-0">
								<Navbar.Toggle aria-controls="basic-navbar-nav" />
								{userDetails && (
									<Navbar.Collapse className="xl-main-navigation loggedIn-header">
										<Nav className="ml-auto pseudo-nav">
											<Dropdown className="dropdown user-dropdown">
												<Dropdown.Toggle
													as="a"
													className="user-name-ship_detail"
													style={{ marginTop: "33px", textTransform: "capitalize" }}
												>
													<img
														src={userIcon}
														alt="UserIcon"
													/>
													<div>
														<span>
															{userDetails
																? (userDetails.firstName
																	? userDetails.firstName
																	: "") +
																" " +
																(userDetails.lastName
																	? userDetails.lastName
																	: "")
																: ""}
														</span>

													</div>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													{!_isEmpty(userDetails) && (
														<React.Fragment>
															<Dropdown.Item disabled>
																<span>
																	Hi,
																	{` ${userDetails.firstName}`}
																</span>
															</Dropdown.Item>{" "}
															<Dropdown.Divider />
														</React.Fragment>
													)}
													<Dropdown.Item>
													<Link 	
														to={storeURL ?  storeURL : "/store-user-dashboard"}>	
														 {storeName ? storeName : "Store Management"}
														</Link>
													</Dropdown.Item>
													<Dropdown.Item onClick={() => logOut(dispatch)}>
														Logout
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>

										</Nav>
									</Navbar.Collapse>
								)}
							</Navbar>
						</div>
					</section>
				</div>
			</div>
		</Fragment>


	)
}

export default Header;
