import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { unwrapResult } from "@reduxjs/toolkit";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";

import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  Accordion,
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./styles.scss";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import _, { isEmpty } from "lodash";
import _uniqBy from "lodash//uniqBy";
import PaginationComponent from "../../Pagination";

import { getPartsByMultiGroupOrchestrator } from "../../../../actions/parts";
import { setVehicleSelected } from "../mygarage.slice";
import {
  setVehicles,
  setFavoriteVehicle,
  fetchVehicles,
  searchVehicles,
  deleteVehicles,
} from "../mygarage.thunks";
import Placeholder from "../../Placeholder";
import XModal from "../../XModal";
import Remove from "../Components/Remove";
import XSelect from "../../XSelect";
import editIcon from "../../../../static/images/icons/red/edit.png"
import { ReactComponent as EditIcon } from "../../../../static/images/icons/edit.svg";
import favoriteIcon from "../../../../static/images/icons/red/Select_Deselect_Favorite_1.png";
import { ReactComponent as FavoriteIcon } from "../../../../static/images/icons/Select_Deselect_Favorite_1.svg";
import orderedIcon from "../../../../static/images/icons/red/order.png";
import {
  hasAlphaSpecialIntChar,
  hasMinLength,
  isLengthLessthan,
  timeInUTC,
  getDefaultDataBasedOnUSerPref,
  getPreferenceDataFromStorage,
} from "../../../../helpers/utils";
import _every from "lodash/every";
import _pick from "lodash/pick";
import FormValidationErrorMessage from "../../../shared/FormValidationErrorMessage/FormValidationErrorMessage";
import _cloneDeep from "lodash/cloneDeep";
import ShipTo from "components/shared/ShipTo";
import { triggerCustomUxCriticalError } from "../../../../actions/error";

const Vehicles = (props) => {
  const { closeOverlay } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const searchInput = useRef();
  const isVehicleSelectionToastDisplayed = useSelector((state) => state.app.isToastVisible);
  const options = [
    { label: "Previously Selected", value: "selected" },
    { label: "Previously Ordered", value: "ordered" },
    { label: "Favorite", value: "favorite" },
  ];

  const initializeValidity = () => {
    const validity = {
      isFavTextAlphanumeric: true,
      isFavTextMinLimit: true,
      isFavTextValid: true,
      isFavTextMaxLimit: true,
    };
    return validity;
  };

  const initializeSearchValidity = () => {
    const searchValidity = {
      isSearchAlphanumericSpecial: true,
      isSearchMinLimit: true,
    };
    return searchValidity;
  };

  const [openWarning, setOpenWarning] = useState(false);
  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const [favoriteText, setFavoriteText] = useState(null);
  const [filter, setFilter] = useState(options[0]);
  const [defaultSelectChanged, setDefaultSelectChanged] = useState(false);
  const [showPageWarning, setShowPageWarning] = useState(false);
  const [vehicleSearchArray, setVehicleSearchArray] = useState([]);
  const [initialDSWVehicleData, setInitialDSWVehicleData] = useState([]);
  const all = [{ label: "ALL", value: 'ALL' }];
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [validity, setvalidity] = useState(initializeValidity);
  const [searchValidity, setSearchValidity] = useState(
    initializeSearchValidity
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dates, setDates] = useState(null);
  const [customerIdFilter, setCustomerIdFilter] = useState(all);
  const parts = useSelector((state) => state.parts);
  const user = useSelector((state) => state.user);
  const userAdditionInfo = useSelector((state) => state.userManagement);
  const mygarage = useSelector((state) => state.mygarage);

  const sv = mygarage.selectedVehicle;

  let vehicles = mygarage.vehicles;
  let totalPages = mygarage.totalPages;
  let totalItems = mygarage.totalItems;

  const handleVehicleSearchChange = (event) => {
    const searchData = event.target.value;
    let searchValidity = initializeSearchValidity();
    searchValidity.isSearchAlphanumericSpecial =
      hasAlphaSpecialIntChar(searchData);
    searchValidity.isSearchMinLimit = hasMinLength(searchData, 1);
    setSearchValidity(searchValidity);
  };

  const handleVehicleSearchBlur = () => {
    let searchValidity = initializeSearchValidity();
    setSearchValidity(searchValidity);
  };

  const handleFavoriteTextChange = (event) => {
    const favText = event.target.value.toUpperCase();
    const isValid = /^\S[ A-Za-z0-9_@./#&!()$*%/<>?,;:`~{}|^"+='-]*$/.test(
      favText
    );
    let validity = initializeValidity();
    validity.isFavTextAlphanumeric = isValid;
    validity.isFavTextMinLimit = hasMinLength(favText, 1);
    validity.isFavTextMaxLimit = isLengthLessthan(favText, 50);
    validity.isFavTextValid = _every(
      _pick(validity, [
        "isFavTextAlphanumeric",
        "isFavTextMinLimit",
        "isFavTextValid",
        "isFavTextMaxLimit",
      ])
    );
    setvalidity(validity);
    setFavoriteText(favText);
  };
  const preparePayLoad = () => {
    let customerNumber = [];
    if (user && user.isDSW === true && !_isEmpty(userAdditionInfo) && !_isEmpty(userAdditionInfo.accounts)) {
      let obj = !_isEmpty(user.userDetails) && !_isEmpty(userAdditionInfo.accounts) &&
      userAdditionInfo.accounts.map((d) => ({ label: d.customerId }));

      customerNumber =
        user &&
        user.userDetails &&
        user.userDetails.billTo &&
        user.userDetails.billTo.billToId;

      let storeArr = [];
      obj?.map((s, i) => {
        storeArr.push({
          customerID: s.label,
        });
      });
      storeArr.map((data, i) => {
        if (!_isEmpty(data) && data !== customerNumber) {
          storeArr.push({
            customerID: customerNumber,
          });
        }
      });
      const uniqueCustomerPayload = Object.values(
        storeArr.reduce((a, c) => {
          a[c.customerID + "|" + c.customerID] = c;
          return a;
        }, {})
      );
      return uniqueCustomerPayload;
    }
  };

  const getSelectOptions = (data) => {
    let { xlPartAccountNumber, x2userName } = user.userDetails;
    let { name } = user.userDetails.billTo;
    let obj =
    !_isEmpty(userAdditionInfo) && !_isEmpty(userAdditionInfo.accounts) &&
    userAdditionInfo.accounts.map((d) => ({
      label: d.customerId + " - " + d.customerName,
      value: d.customerId,
    }));
    if (xlPartAccountNumber != data.customerId) {
      obj.unshift({
        label: xlPartAccountNumber + "-" + name,
        value: xlPartAccountNumber,
      });
    }
    obj.unshift({ label: "ALL", value: "ALL" });

    return obj;
  };

  const constructRequestItems = () => {
    let requestItems = [{}];
    let payloadData = preparePayLoad();
    if (!_isEmpty(payloadData)&&!_isEmpty(customerIdFilter[0]) && customerIdFilter[0].value === 'ALL'&&!_isEmpty(user)&& user.isDSW === true) {
      requestItems =
        !_isEmpty(payloadData) &&
        payloadData.map((d) => ({
          customerID: d.customerID,
          shipTo:
            !_isEmpty(user.userDetails) && user.userDetails.shipTos[0].shipToId,
        }));
    }
    else if(!_isEmpty(customerIdFilter[0]) && customerIdFilter[0].value !== 'ALL' &&!_isEmpty(user)&& user.isDSW === true){
      requestItems = [{
        customerID:
        customerIdFilter[0].value,
        shipTo: user.userDetails.shipTos[0].shipToId,
      }]
    }
    else if(!_isEmpty(user)&& user.isDSW === false) {
      requestItems = [
        {
          customerID:
            user &&
            user.userDetails &&
            user.userDetails.billTo &&
            user.userDetails.billTo.billToId,
          shipTo: user.userDetails.shipTos[0].shipToId,
        },
      ];
    }
    return requestItems;
  }
  const initialFetchVehicles = (clickedPage) => {
    let requestItems = [{}];
    let pageNum = clickedPage ? clickedPage : currentPage;
    let preferenceFilter = handleFilterBasedOnUserPref();
    const byCustomerList = {
      pageNo: pageNum,
      pageSize: pageSize,
      userFilter: preferenceFilter.value,
    };
   requestItems = constructRequestItems();
   const payload = {requestItems , params: byCustomerList };

    // const payload = {
    //   customerId:
    //     user &&
    //     user.userDetails &&
    //     user.userDetails.billTo &&
    //     user.userDetails.billTo.billToId,
    //   shipToId: user.userDetails && user.userDetails.shipTos[0].shipToId,
    //   pageNo: pageNum,
    //   pageSize: pageSize,
    //   userFilter: preferenceFilter.value,
    // };
    try {
      const vehiclesAction = dispatch(fetchVehicles(payload));
      vehiclesAction.then((action) => {
        if (action.payload) {
          let arrData = _cloneDeep(action.payload);
          Object.values(arrData).map((item) => {
            setCurrentPage(item.pageNumber);
            setVehicleSearchArray(arrData);
            setInitialDSWVehicleData(arrData);
           })
        }
      });
    } catch (error) {}
  };

  // On Component Mount - meaning on initial Load of the component.
  useEffect(() => {
    initialFetchVehicles();
  }, []);

  const handleVehicleSelect = (v) => {
    if (isVehicleSelectionToastDisplayed) {
      let errorMessageProps = {
        heading: '',
        message: "manualDismiss",
        type: "info"
      };
      triggerCustomUxCriticalError(errorMessageProps, dispatch);
    }
    let __v = selectedVehicle || v;
    dispatch(setVehicleSelected(__v));
    dispatch({
      payload: {
        selectedVehicle: __v,
      },
      type: "VEHICLE_SELECTION_COMPLETED",
    });

    const payload = { ...__v, updatedDate: timeInUTC() };
    dispatch(setVehicles(payload));

    // On Vehicle Select clear the specific conditions for a vehicle
    dispatch({
      payload: {},
      type: "RESET_SPECIFIC_CONDITIONS",
    });

    if (parts.selectedGroupDetails && parts.selectedGroupDetails.length > 0) {
      let requestItems = parts.selectedGroupDetails.map((singleItem) => {
        return { groupCode: singleItem.code, specificCondition: [] };
      });
      let filters = parts.catalogFilter ? parts.catalogFilter : {};
      let groupsSelected = { requestItems };
      const userPreferenceDetail = getPreferenceDataFromStorage();
      let userPrefDetail = getDefaultDataBasedOnUSerPref(userPreferenceDetail);
      history.push("/product-list");

      dispatch(
        getPartsByMultiGroupOrchestrator(
          __v.year,
          __v.makeId,
          __v.modelId,
          __v.engineId,
          groupsSelected,
          userPrefDetail.pageNumber,
          userPrefDetail.pageSize,
          userPrefDetail.catalogSortBy,
          userPrefDetail.catalogOrder,
          filters
        )
      );
    } else {
      history.push("/");
    }
    closeOverlay();
  };

  const showSelect = (v) => {
    let isVisible = true;
    if (
      sv &&
      sv.make.trim() === v.make.trim() &&
      sv.model.trim() === v.model.trim() &&
      sv.year.toString().trim() === v.year.toString().trim() &&
      sv.engine.trim() === v.engine.trim()
    ) {
      isVisible = false;
    }

    return isVisible;
  };

  const deleteVehicleHandler = () => {
    const { searchId } = selectedVehicle;
    const removeAction = dispatch(deleteVehicles({ searchId: searchId }));
    setOpenWarning(false);

    removeAction.then(() => {
      initialFetchVehicles();
    });
  };

  const handleWarningOpen = (v) => {
    setSelectedVehicle(v);
    setOpenWarning(true);
  };

  const handlePageWarningOpen = (v) => {
    setSelectedVehicle(v);
    handleVehicleSelect(v);
  };

  const handleWarningClose = () => {
    let validity = initializeValidity();
    setOpenWarning(false);
    setShowFavoriteModal(false);
    setShowPageWarning(false);
    setvalidity(validity);
  };

  const handleSearch = (searchText) => {
    let requestItems = [{}];
    requestItems = constructRequestItems();
    const payload = { requestItems, searchKey: searchText }
    if (searchText && searchText.length > 1) {
      const vehiclesAction = dispatch(searchVehicles(payload));
      vehiclesAction.then((action) => {
        if (action.payload) {
          setVehicleSearchArray(action.payload);

        }
      });
      // dispatch(
      //   searchVehicles({
      //     customerId: user.userDetails && user.userDetails.billTo.billToId,
      //     shipToId: user.userDetails && user.userDetails.shipTos[0].shipToId,
      //     searchKey: searchText,
      //   })
      // );
    } else if (_.isEmpty(searchText)) {
      initialFetchVehicles();
    }
  };

  const handleFilterBy = (filter) => {
    let requestItems = [{}];
    searchInput.current.value = "";
    let vehicleSelectedValue = options.find(
      (option) => option.value === filter.value
    );
    setFilter(vehicleSelectedValue);
    setDefaultSelectChanged(true);
    const byCustomerList = {
      pageNo: currentPage,
      pageSize: pageSize,
      userFilter: filter.value,
      startDate: dates ? dates.startDate : null,
      endDate: dates ? dates.endDate : null,
    };
    // const payload = {
    //   customerId:
    //     user &&
    //     user.userDetails &&
    //     user.userDetails.billTo &&
    //     user.userDetails.billTo.billToId,
    //   shipToId: user.userDetails.shipTos[0].shipToId,
    //   pageNo: currentPage,
    //   pageSize: pageSize,
    //   userFilter: filter.value,
    //   startDate: dates ? dates.startDate : null,
    //   endDate: dates ? dates.endDate : null,
    // };
    requestItems = constructRequestItems();
    const payload = { requestItems, params: byCustomerList };
    Object.keys(payload).forEach(
      (k) => payload[k] == null && delete payload[k]
    );

    try {
      const vehiclesAction = dispatch(fetchVehicles(payload));
      vehiclesAction.then((action) => {
        if (action.payload) {
          let arrData = _cloneDeep(action.payload);
          Object.values(arrData).map((item) => {
           setCurrentPage(item.pageNumber);
           setVehicleSearchArray(arrData);
           setInitialDSWVehicleData(arrData);
          })
          // setCurrentPage(action.payload.pageNumber);

        }
      });
    } catch (error) {}
  };

  const handleFavorite = () => {
    const updated = {
      ...selectedVehicle,
      favoriteText,
      favoriteUpdatedDate: timeInUTC(),
    };

    dispatch(setFavoriteVehicle(updated))
      .then(unwrapResult)
      .then((veh) => {
        initialFetchVehicles();
      })
      .catch((err) => {
        console.log("Error in setting vehicle name fav ==> ", err);
      });
    setShowFavoriteModal(false);
  };

  const handleFavoriteOpen = (v) => {
    setSelectedVehicle(v);

    setShowFavoriteModal(true);
  };

  const handleFavoriteText = (fText) => {
    setFavoriteText(fText);
  };

  const handleToggleFavorite = (v, flag) => {
    const updated = {
      ...v,
      favoriteText: "",
      favoriteFlag: flag,
      favoriteUpdatedDate: timeInUTC(),
    };

    const favAction = dispatch(setFavoriteVehicle(updated));

    //TODO This is for temporary, need to be FIXED
    favAction.then(() => {
      initialFetchVehicles();
    });
  };

  const handleDateRange = (e, picker) => {
    let requestItems = [{}];
    searchInput.current.value = "";
    const { startDate, endDate } = picker;

    const _startDate = startDate.toISOString();
    const _endDate = endDate.toISOString();

    setDates({ startDate: _startDate, endDate: _endDate });

    setDateFilter(
      `${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`
    );
    const byCustomerList = {
      pageNo: currentPage,
      pageSize: pageSize,
      startDate: _startDate,
      endDate: _endDate,
      userFilter: filter.value,
    };
    // const payload = {
    //   customerId:
    //     user &&
    //     user.userDetails &&
    //     user.userDetails.billTo &&
    //     user.userDetails.billTo.billToId,
    //   shipToId: user.userDetails.shipTos[0].shipToId,
    //   pageNo: currentPage,
    //   pageSize: pageSize,
    //   startDate: _startDate,
    //   endDate: _endDate,
    //   userFilter: filter.value,
    // };
    requestItems = constructRequestItems();
    const payload = { requestItems, params: byCustomerList };
    try {
      const vehiclesAction = dispatch(fetchVehicles(payload));
      vehiclesAction.then((action) => {
        if (action.payload) {
          let arrData = _cloneDeep(action.payload);
          Object.values(arrData).map((item) => {
           setCurrentPage(item.pageNumber);
         setVehicleSearchArray(arrData);
         setInitialDSWVehicleData(arrData);
          })
          // setCurrentPage(action.payload.pageNumber);
        }
      });
    } catch (error) {}
  };

  const clearDateFilter = () => {
    setDates(null);
    setDateFilter("");
    initialFetchVehicles();
  };

  const handlePageChange = (clickedPage) => {
    setCurrentPage(clickedPage);
    initialFetchVehicles(clickedPage);
  };

  const handleKeyDown = (event) => {
    const favoriteText = event.target.value;
    event.stopPropagation();
    if (
      favoriteText &&
      favoriteText.length >= 50 &&
      event.keyCode !== 8 &&
      event.keyCode !== 9 &&
      event.keyCode !== 37 &&
      event.keyCode !== 38 &&
      event.keyCode !== 39 &&
      event.keyCode !== 40 &&
      event.keyCode !== 46
    ) {
      event.preventDefault();
    }
  };

  const handleFilterBasedOnUserPref = () => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let filterData = filter;
    if (!_isEmpty(userPreferenceDetail)) {
      let selectedViewType = {};
      selectedViewType = _find(userPreferenceDetail, {
        code: "My Garage",
        text: "Default Vehicle",
      });
      let currentlySelectedValue, currentlySelectedText;
      if (!defaultSelectChanged) {
        currentlySelectedValue =
          selectedViewType &&
          selectedViewType.answer &&
          selectedViewType.answer.code &&
          selectedViewType.answer.code;
        currentlySelectedText =
          selectedViewType &&
          selectedViewType.answer &&
          selectedViewType.answer.text &&
          selectedViewType.answer.text;

        if (currentlySelectedText && currentlySelectedValue) {
          setFilter({
            label: currentlySelectedText,
            value: currentlySelectedValue,
          });
          filterData = {
            label: currentlySelectedText,
            value: currentlySelectedValue,
          };
        }
      }
    }
    return filterData;
  };

  useEffect(() => {
    initialFetchVehicles();
  }, [customerIdFilter]);

  const handleCustomerFilter = (e) => {
    let billTo = e.value;
    setCustomerIdFilter([{ label: e.value, value: e.value }]);
  };

  const sortEntriesByActiveBillTo = () => {
    let entries = Object.entries(vehicleSearchArray);
    if (customerIdFilter[0].value === 'ALL') {
      const currentBillToId = user.userDetails.billTo.billToId;
      let foundIndex = null;
      let foundItem = null;
      entries.forEach((vehicle, i) => {
        const bId = vehicle[0];
        if (bId === currentBillToId) {
          foundIndex = i;
          foundItem = vehicle;
        }
      });

      entries.splice(foundIndex, 1);
      entries.unshift(foundItem);
      entries = entries.filter((v) => v); // filter out undefined or null values if any;
    }
    return entries;
  };
    return (
      <React.Fragment>
        <div className="vehicles-wrapper">
          {!_isEmpty(vehicleSearchArray) && (
          // {/* {vehicles && ( */}
          <Row>
            <Col md="4" className="mygarage_pagination_wrapper">
              <PaginationComponent
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </Col>
          </Row>
          )
        }
          <Row>
            {user.isDSW === true && (
              <Col className="select-container-mygarage">
                <XSelect
                  options={getSelectOptions(userAdditionInfo.accounts)}
                  isSearchable={false}
                  value={!_isEmpty(customerIdFilter) && customerIdFilter}
                  defaultValue={customerIdFilter}
                  onChange={(e) => handleCustomerFilter(e)}
                />
              </Col>
            )}
            {user.isDSW === true && (
              <Col md="5">
                <Form.Group>
                  <Form.Control
                    ref={searchInput}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    onFocus={(e) => e.stopPropagation()}
                    onMouseOver={(e) => e.stopPropagation()}
                    type="text"
                    placeholder="Search Vehicle"
                    className="input__search input__control"
                    onKeyUp={(e) => handleSearch(e.target.value)}
                    onChange={handleVehicleSearchChange}
                    onBlur={handleVehicleSearchBlur}
                  />
                  <FormValidationErrorMessage
                    condition={
                    !searchValidity.isSearchAlphanumericSpecial &&
                    searchValidity.isSearchMinLimit
                    }
                    errorMessage="Search key must be alphanumeric or special characters"
                  />
                  <FormValidationErrorMessage
                    condition={!searchValidity.isSearchMinLimit}
                    errorMessage="Search key should have atleast 1 character"
                  />
                </Form.Group>
              </Col>
            )}
            {user.isDSW === false && (
              <Col md="7">
                <Form.Group>
                  <Form.Control
                    ref={searchInput}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    onFocus={(e) => e.stopPropagation()}
                    onMouseOver={(e) => e.stopPropagation()}
                    type="text"
                    placeholder="Search Vehicle"
                    className="input__search input__control"
                    onKeyUp={(e) => handleSearch(e.target.value)}
                    onChange={handleVehicleSearchChange}
                    onBlur={handleVehicleSearchBlur}
                  />
                  <FormValidationErrorMessage
                    condition={
                      !searchValidity.isSearchAlphanumericSpecial &&
                      searchValidity.isSearchMinLimit
                    }
                    errorMessage="Search key must be alphanumeric or special characters"
                  />
                  <FormValidationErrorMessage
                    condition={!searchValidity.isSearchMinLimit}
                    errorMessage="Search key should have atleast 1 character"
                  />
                </Form.Group>
              </Col>
            )}
            <Col md="4" className="padding-left-0">
              <XSelect
                options={options}
                isSearchable={false}
                defaultValue={{ label: "Previously Selected", value: "selected" }}
                // isClearable
                value={filter}
                placeholder="Filter By"
                onChange={handleFilterBy}
              />
            </Col>
            <Col md="1"  className="Date__filter__wrapper">
              <DateRangePicker onApply={handleDateRange}>
                <span className="material-icons date__filter">date_range</span>
              </DateRangePicker>
            </Col>
          </Row>
          {dateFilter && (
            <Row>
              <Col md="12">
                <div className="filter__wrapper">
                  <span>{dateFilter}</span>
                  {dateFilter && (
                    <span
                      className="material-icons clear__filter_btn"
                      onClick={clearDateFilter}
                    >
                    highlight_off
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          )}
          {mygarage.loading ? (
            <Placeholder rows={1} />
            ) : user.isDSW === true  && !_isEmpty(vehicleSearchArray) &&  (
                <React.Fragment>
                  <div className="mygarage__content inerchange-box-myGarage interchange_card_my">
                    <Accordion allowZeroExpanded={true} preExpanded={[0]}>
                      {sortEntriesByActiveBillTo().map((pageData, i) => {
                      return (
                        <AccordionItem uuid={i}>
                          {!_isEmpty(pageData) && (
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                {`${pageData[0]}`}
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          )}
                          <AccordionItemPanel>
                            {(!_isEmpty(pageData[1].pagedItems)) &&
                              pageData[1].pagedItems.map((v, i) => {
                                return (
                                  v.customerId && (
                                    <div className="mygarage__vehicle" key={i}>
                                      <div className="v__title">
                                        <div className="v__icons_wrapper">
                                          <div style={{ fontWeight: "normal" }}>
                                            {v.favoriteFlag === "Y" &&
                                              v.favoriteText.length > 0 ? (
                                              `${v.favoriteText}`
                                            ) : (
                                              <span>
                                                {v.year} {v.make} {v.model}{" "}
                                                {v.engine}
                                              </span>
                                            )}
                                          </div>
                                          <div className="v__icons">
                                            {v.favoriteFlag === "N" && (
                                              <FavoriteIcon
                                                className="non__favorite"
                                                onClick={() =>
                                                  handleToggleFavorite(v, "Y")
                                                }
                                              />
                                            )}
                                            {v.favoriteFlag === "Y" && (
                                              <img
                                                src={favoriteIcon}
                                                alt="favorite"
                                                className="v__icon action"
                                                onClick={() =>
                                                  handleToggleFavorite(v, "N")
                                                }
                                              />
                                            )}
                                            {filter.value === "favorite" && (
                                              <img
                                                src={editIcon}
                                                alt="edit"
                                                className="v__icon action"
                                                onClick={() =>
                                                  handleFavoriteOpen(v)
                                                }
                                              />
                                            )}

                                            {v.orderedFlag === "Y" && (
                                              <img
                                                src={orderedIcon}
                                                alt="favorite"
                                                className="v__icon"
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="operation__links">
                                          {showSelect(v) && (
                                            <a
                                              className="b__link tph-cursor"
                                              onClick={() =>
                                                handlePageWarningOpen(v)
                                              }
                                            >
                                            Select
                                          </a>
                                          )}
                                          <Remove
                                            handleRemove={(e) =>
                                              handleWarningOpen(v)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="v__date">
                                        <label
                                          style={{
                                            color: "#aa0d10",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          Added:{" "}
                                        </label>{" "}
                                        <label style={{ fontWeight: "normal" }}>
                                          <Moment
                                            date={v.updatedDate}
                                            format="MM-DD-YYYY"
                                          />
                                        </label>
                                      </div>
                                      <div className="v__mechanic">
                                        <label
                                          style={{
                                            color: "#aa0d10",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          User:{" "}
                                        </label>{" "}
                                        <label style={{ fontWeight: "normal" }}>
                                          {v.createdBy}
                                        </label>
                                      </div>
                                    </div>
                                  )
                                );
                              })
                            }
                            {dateFilter &&
                              !_isEmpty(pageData) &&
                              _isEmpty(pageData[1].pagedItems) && <div className="no-quotes">
                                "No vehicles found on this date, please select a different date."
                              </div>
                            }
                            {dateFilter === null &&
                              !_isEmpty(pageData) &&
                              _isEmpty(pageData[1].pagedItems) && <div className="no-quotes">
                                "You have logged in for the first time, please start with Product Category/Group search"
                              </div>
                            }
                          </AccordionItemPanel>
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                  </div>
                </React.Fragment>
          )}
          {mygarage.loading ? (
                <Placeholder rows={1} />
                  ) : user && user.isDSW === false && !_isEmpty(vehicleSearchArray) && (
                    <React.Fragment>
                      {sortEntriesByActiveBillTo().map((pageData, i) => {
                        return (
                          <div className="mygarage__content">
                            {(pageData[1].pagedItems) &&
                            pageData[1].pagedItems.map((v, i) => {
                            return (
                              <div className="mygarage__vehicle" key={i}>
                                <div className="v__title">
                                  <div className="v__icons_wrapper">
                                    <div style={{ fontWeight: "normal" }}>
                                      {v.favoriteFlag === "Y" &&
                                      v.favoriteText.length > 0 ? (
                                      `${v.favoriteText}`
                                      ) : (
                                      <span>
                                        {v.year} {v.make} {v.model} {" "}
                                        {v.engine}
                                      </span>
                                      )}
                                    </div>
                                    <div className="v__icons">
                                      {v.favoriteFlag === "N" && (
                                        <FavoriteIcon
                                          className="non__favorite"
                                          onClick={() => handleToggleFavorite(v, "Y")}
                                        />
                                      )}
                                      {v.favoriteFlag === "Y" && (
                                        <img
                                          src={favoriteIcon}
                                          alt="favorite"
                                          className="v__icon action"
                                          onClick={() => handleToggleFavorite(v, "N")}
                                        />
                                        // <FavoriteIcon
                                        //   className="favorite"
                                        //   onClick={() => handleToggleFavorite(v, "N")}
                                        // />
                                      )}

                                      {filter.value === "favorite" && (
                                        <img
                                          src={editIcon}
                                          alt="edit"
                                          className="v__icon action"
                                          onClick={() => handleFavoriteOpen(v)}
                                        />
                                        // <EditIcon
                                        //   className="v__icon action"
                                        //   onClick={() => handleFavoriteOpen(v)}
                                        // />
                                      )}

                                      {v.orderedFlag === "Y" && (
                                        <img
                                           src={orderedIcon}
                                          alt="favorite"
                                          className="v__icon"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="operation__links">
                                    {showSelect(v) && (
                                      <a
                                        className="b__link tph-cursor"
                                        onClick={() => handlePageWarningOpen(v)}
                                      >
                                      Select
                                      </a>
                                    )}
                                    <Remove handleRemove={(e) => handleWarningOpen(v)} />
                                  </div>
                                </div>
                                <div className="v__date">
                                  <label
                                    style={{
                                    color: "#aa0d10",
                                    fontWeight: "normal",
                                    }}
                                  >
                                    Added:{" "}
                                  </label>{" "}
                                  <label style={{ fontWeight: "normal" }}>
                                    <Moment
                                      date={v.updatedDate}
                                      format="MM-DD-YYYY"
                                    />
                                  </label>
                                </div>
                                <div className="v__mechanic">
                                  <label
                                    style={{
                                    color: "#aa0d10",
                                    fontWeight: "normal",
                                    }}
                                  >
                                    User: {" "}
                                  </label>{" "}
                                  <label style={{ fontWeight: "normal" }}>
                                    {v.createdBy}
                                  </label>
                                </div>
                              </div>
                            )
                            })}

                            {dateFilter &&
                              !_isEmpty(pageData) &&
                              _isEmpty(pageData[1].pagedItems) && <div className="no-quotes">
                              "No vehicles found on this date, please select a different date."
                            </div>}
                            {dateFilter === null &&
                              !_isEmpty(pageData) &&
                              _isEmpty(pageData[1].pagedItems) && <div className="no-quotes">
                              "You have logged in for the first time, please start with Product Category/Group search"
                            </div>}
                          </div>
                        );
                      })}
                    </React.Fragment>
                  )
              }

              {/* // <div className="no-quotes"> */}
                {/* // {dateFilter */}
              {/* //     ? "No vehicles found on this date, please select a different date." */}
              {/* //     : "You have logged in for the first time or there are no Previously Selected or favorite vehicles, please select a vehicle using YMME or VIN or License Plate search."} */}
              {/* // </div> */}
                  {/* // )} */}
          </div>
          {selectedVehicle && (
            <XModal
              show={openWarning}
              handleClose={handleWarningClose}
              handleAction={deleteVehicleHandler}
              className="custom_xmodal"
              isValidValidity={true}
            >
              <div className="delete__text text__center">
                You want to delete the vehicle <br />
                <span className="normal">
                  {`${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`}
                </span>
              </div>
            </XModal>
          )}

          {showFavoriteModal && (
            <XModal
              title="Name the Vehicle"
              show={showFavoriteModal}
              handleClose={handleWarningClose}
              handleAction={handleFavorite}
              submitType
              className="custom_xmodal"
              submitButtonText="Save"
              favoriteTextValidity={_every(validity) && !isEmpty(favoriteText)}
            >
              <div>
                <div className="text__center">
                  Name the vehicle for <br />
                  <span className="bold vehicle__name">
                    {`${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`}
                  </span>
                </div>
                <Form.Control
                  type="text"
                  placeholder="Favorite Text"
                  className="input__default"
                  onChange={handleFavoriteTextChange}
                  onBlur={handleFavoriteTextChange}
                  value={favoriteText}
                  onKeyUp={(e) => handleFavoriteText(e.target.value)}
                  onKeyDown={(event) => {
                    handleKeyDown(event);
                  }}
                  onClick={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                  onMouseOver={(e) => e.stopPropagation()}
                  maxLength="51"
                />
                <FormValidationErrorMessage
                  condition={
                    !validity.isFavTextAlphanumeric && validity.isFavTextMinLimit
                  }
                  errorMessage={"Favorite text must be alphanumeric only"}
                />
                <FormValidationErrorMessage
                  condition={!validity.isFavTextMinLimit}
                  errorMessage={"Favorite text should have atleast 1 character"}
                />
                <FormValidationErrorMessage
                  condition={
                    !validity.isFavTextMaxLimit && validity.isFavTextMinLimit
                  }
                  errorMessage={"Favorite text should not exceed 50 character"}
                />
              </div>
            </XModal>
          )}

          {showPageWarning && (
            <XModal
              show={showPageWarning}
              handleClose={handleWarningClose}
              handleAction={handleVehicleSelect}
              className="custom_xmodal"
              isValidValidity={true}
            >
              <div>
                <div className="text__center">
                  you want to move to category page?
            </div>
          </div>
        </XModal>
      )}
    </React.Fragment>
  );
};

export default Vehicles;
