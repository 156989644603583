import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Card } from "react-bootstrap";
import _isEmpty from "lodash/isEmpty";
import SingleProduct from "../../Listing/SingleProduct";

function ProPackAccSingleCard(props) {
  let {
    singleItem,
    pKey,
    partIdMap,
    accHeader,
    handlePartSelect,
    accClass,
    handleAccordionExpand,
    activeAccordion,
  } = props;
  const dispatch = useDispatch();
  const [activeAccordionExpanded, setActiveAccordionExpanded] = useState(false);
  const mygarage = useSelector((state) => state.mygarage);
  const selectedGroupDetails = useSelector(
    (state) => state.parts.selectedGroupDetails
  );
  const buyersGuideData = useSelector(
    (state) => state.parts.buyersGuideDetails
  );
  const buyersGuideBrandData = useSelector(
    (state) => state.parts.buyersGuideBrandDetails
  );
  const userDetails = useSelector((state) => state.user.userDetails);
  const laborGuideData = useSelector((state) => state.parts.laborGuideDetails);
  const accordionHeadersRef = useRef([]);

  const selectedVehicle = mygarage.selectedVehicle;
  const user = useSelector((state) => state.user);
  const checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  const customerStore = user.userDetails && user.userDetails.shipTos && user.userDetails.shipTos[0] &&
                       user.userDetails.shipTos[0].location && user.userDetails.shipTos[0].location.storeNumber ? user.userDetails.shipTos[0].location.storeNumber : "";
  const selectedstore = checkoutDetails && checkoutDetails.length > 0 ?  checkoutDetails[0].store : customerStore;
  let { partDetails } = singleItem;
  let accClassName =
    accClass && accClass.length
      ? accClass
      : !_isEmpty(partIdMap) && partIdMap[pKey] && partIdMap[pKey].minQtyAdded
      ? `min-qty-selected`
      : "";

  useEffect(() => {
    if (
      !_isEmpty(partIdMap) &&
      partIdMap[pKey] &&
      partIdMap[pKey].minQtyAdded &&
      accordionHeadersRef.current.length > 0
    ) {
      handleAccordionExpand(null, pKey);
    }
  }, partIdMap[pKey]);

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={`${pKey}accordion`}
        className={accClassName}
        onClick={(e) => {
          handleAccordionExpand(e, pKey, accordionHeadersRef);
        }}
      >
        {accHeader}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={`${pKey}accordion`}>
        <Card.Body
          className="propacks_list_body"
          ref={(el) => (accordionHeadersRef.current[pKey] = el)}
        >
          {partDetails.map((part, k) => {
            let productArray = [];
            let appendPart =
  						(part.alternate && part.alternate.length > 0) ||
  						(part.substitute && part.substitute.length > 0);
  					let altPartNumsArr = [];
  					let subPartNumsArr = [];
  					const altPartNums = (part.alternate && part.alternate.length > 0) ?
  																	part.alternate.map((alt) => {
  																		altPartNumsArr.push(alt.partNumber)
  																	}) : '';
  					const subPartNums = (part.substitute && part.substitute.length > 0) ?
  																	part.substitute.map((sub) => {
  																		subPartNumsArr.push(sub.partNumber)
  																	}) : '';
            productArray.push(
              <SingleProduct
                buyersGuideData={buyersGuideData}
                buyersGuideBrandData={buyersGuideBrandData}
                selectedVehicle={selectedVehicle || {}}
                laborGuideData={laborGuideData}
                partDetails={part}
                dispatch={dispatch}
                selectedGroupDetails={selectedGroupDetails}
                userDetails={userDetails}
                laborApiCall={false}
                proPack={true}
                handlePartSelect={handlePartSelect}
                proPackKey={pKey}
                appendPart={appendPart}
  							altPartNums={`${altPartNumsArr.join(',')}`}
  							subPartNums={`${subPartNumsArr.join(',')}`}
                partType= 'part'
                selectedStore={selectedstore}
              />
            );
            //alternate parts
  					if (part.alternate && part.alternate.length > 0) {
  						part.alternate.map((altPart, index) => {
  							let appendAltPart =
  								index !== part.alternate.length - 1 ||
  								(part.substitute && part.substitute.length > 0);
  							productArray.push(
                  <SingleProduct
                    buyersGuideData={buyersGuideData}
                    buyersGuideBrandData={buyersGuideBrandData}
                    selectedVehicle={selectedVehicle || {}}
                    laborGuideData={laborGuideData}
                    partDetails={altPart}
                    dispatch={dispatch}
                    selectedGroupDetails={selectedGroupDetails}
                    userDetails={userDetails}
                    laborApiCall={false}
                    proPack={true}
                    handlePartSelect={handlePartSelect}
                    proPackKey={pKey}
                    isAltType={true}
                    appendPart={appendAltPart}
                    selectedStore={selectedstore}
                  />
  							);
  						});
  					}
            //substitute parts
  					if (part.substitute && part.substitute.length > 0) {
  						part.substitute.map((subPart, index) => {
  							let appendSubPart =
  								index !== part.substitute.length - 1;
  							productArray.push(
                  <SingleProduct
                    buyersGuideData={buyersGuideData}
                    buyersGuideBrandData={buyersGuideBrandData}
                    selectedVehicle={selectedVehicle || {}}
                    laborGuideData={laborGuideData}
                    partDetails={subPart}
                    dispatch={dispatch}
                    selectedGroupDetails={selectedGroupDetails}
                    userDetails={userDetails}
                    laborApiCall={false}
                    proPack={true}
                    handlePartSelect={handlePartSelect}
                    proPackKey={pKey}
                    isSubType={true}
                    appendPart={appendSubPart}
                    selectedStore={selectedstore}
                  />
  							);
  						});
  					}
            return productArray;
          })}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
export default ProPackAccSingleCard;
