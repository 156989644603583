import httpClient from "../../../services/http";
import { buildQueryUrl } from "../../../helpers/utils";

export const getVehicleServiceTypes = (data) =>
  httpClient.get(`/catalog/service-interval/types?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const getVehicleServiceDrivingConditions = (data) =>
  httpClient.get(`/catalog/service-interval/drive-conditions?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const getVehicleServiceIntervals = (data) =>
  httpClient.get(`/catalog/service-interval/intervals?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const getVechicleAvailableServiceJobs = (data) =>
  httpClient.get(`/catalog/service-interval/jobs?${buildQueryUrl(data)}`);

export const getVechicleServiceJobsData = (data) =>
  httpClient.get(`/catalog/service-interval/jobs-data?${buildQueryUrl(data)}`);

export const getVehicleModernServiceSpecificConditions = (data) =>
  httpClient.get(`/catalog/service-interval/specific-conditions?${buildQueryUrl(data)}`, { dispatch: data.dispatch });

export const getVehicleModernServiceData = (data) =>
  httpClient.post(`/catalog/service-interval/modern-service-data`, data);

export const getVehicleCatalogPartData = (data) =>
  httpClient.post(`/orchestrator-service/part/partByDesc`,data.requestItems,{ params: data.params });

export const getVehiclePartImages = (data) =>
  httpClient.post(`/catalog/part/image`,data);

export default httpClient;
