import React, { useRef, useEffect, useState, Fragment } from "react";
import {
  Container,
  Tab,
  Row,
  Col,
  Form,
  Accordion,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import _cloneDeep from "lodash/cloneDeep";
import _sortBy from "lodash/sortBy";
import XModal from "../XModal";
import {
  getPreferenceDataFromStorage,
  getDefaultDataBasedOnChemicalUSerPref,
  getImageURLCookieDetails,
} from "../../../helpers/utils";
import { getChemPartByGrp } from "../../../actions/parts";
import {
  getVehicleMap,
  getVehicleSpec,
  getVehicleSpecInfo,
} from "./fluidsandfilters.thunks";

import "./styles.scss";
import { isEmpty } from "lodash";

const headerStyle = {
  backgroundColor: "#0F5283",
  color: "#FFFFFF",
  "z-index": "999",
};

const FluidsAndFiltersPopup = (props) => {
  let { openPopup, closePopup, selectedVehicle } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [vehicleMap, setVehicleMap] = useState([]);
  const [category, setCategory] = useState([]);
  const [groups, setGroups] = useState([]);

  const [selectedVehicleMap, setSelectedVehicleMap] = useState({
    motorEngineCodeEC: "",
    motorMakeCodeMC: "",
    motorModelCodeDC: "",
    motorYear: null,
  });
  const [selectedSpec, setSelectedSpec] = useState({
    tableDescription: "",
    qualifiers: "",
    tableName: "",
    notes: "",
  });

  const env = process.env.REACT_APP_ENV === "UAT" ? "uat-" : "";
  const company =
    process.env.REACT_APP_COMPANY_NAME === "TPH" ? "thepartshouse" : "xlparts";

  const imageURLCookie = getImageURLCookieDetails();
  const [categoryIcons, setCategoryIcons] = useState([
    {
      categoryName: "Brake Chemicals",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/BrakeChemicals/BrakeChemicals.jpg?${imageURLCookie}`,
    },
    {
      categoryName: "AntiFreeze",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/Antifreeze/ProductAntifreeze.jpg?${imageURLCookie}`,
    },
    {
      categoryName: "Lubricants & Greases, Cleaners",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/LubricantsGreasesCleaners/ProductLubricantsGreasesCleaners.jpg?${imageURLCookie}`,
    },
    {
      categoryName: "Motor Oils",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/MotorOils/ProductMotorOils.jpg?${imageURLCookie}`,
    },
    {
      categoryName: "Power Steering Fluids",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/PowerSteeringFluids/ProductPowerSteeringFluids.jpg?${imageURLCookie}`,
    },
  ]);

  const [groupIcons, setGroupIcons] = useState([
    {
      groupName: "Brake Fluids",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/BrakeChemicals/BrakeFluid.jpg?${imageURLCookie}`,
    },
    {
      groupName: "Refrigerant",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/ACChemicalsandAdditives/Refrigerant.jpg?${imageURLCookie}`,
    },
    {
      groupName: "Refrigerant(Freon)",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/ACChemicalsandAdditives/RefrigerantFreon.jpg?${imageURLCookie}`,
    },
    {
      groupName: "Pag Oils",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/ACChemicalsandAdditives/PagOils.jpg?${imageURLCookie}`,
    },
    {
      groupName: "Automatic",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/TransmissionFluids/Automatic.jpg?${imageURLCookie}`,
    },
    {
      groupName: "Manual",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/TransmissionFluids/manual.jpg?${imageURLCookie}`,
    },
    {
      groupName: "Transmission Additives",
      iconPath: `https://${env}assets.${company}.com/cepassets/ChemicalCategories/TransmissionFluids/TransmissionAdditives.jpg?${imageURLCookie}`,
    },
  ]);

  const [isSpecSelected, setIsSpecSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [vehicleSpecGroup, setVehicleSpecGroup] = useState([]);
  const [vehicleSpec, setVehicleSpec] = useState([]);

  const [modelListKm, setModelListKm] = useState([]);
  const [engineListKm, setEngineListKm] = useState([]);

  const [viewTypes, setViewTypes] = useState([
    { id: "table", text: "Table" },
    { id: "list", text: "List" },
  ]);
  const [selectedView, setSelectedView] = useState("table");
  const [specTableColumns, setSpecTableColumns] = useState([]);
  const [specTableRows, setSpecTableRows] = useState([]);

  const accordionHeadersRef = useRef([]);
  const accordionItemsRef = useRef([]);
  const tableSpecItemsRef = useRef([]);

  const removeDuplicates = (arrOfObj) => {
    const setObj = new Set();
    const result = arrOfObj.reduce((acc, item) => {
      if (!setObj.has(item.value)) {
        setObj.add(item.value, item);
        acc.push(item);
      }
      return acc;
    }, []);
    return result;
  };

  const handleVehicleMapChange = (value, name) => {
    selectedVehicleMap[name] = value;
  };

  const initialGetVehicleMap = () => {
    const payload = {
      engineCode: selectedVehicle.engineId,
      makeCode: selectedVehicle.makeId,
      modelCode: selectedVehicle.modelId,
      yearCode: selectedVehicle.year,
      dispatch: dispatch,
    };
    try {
      const vehicleMapAction = dispatch(getVehicleMap(payload));
      vehicleMapAction.then((action) => {
        setIsLoading(false);
        if (action.payload) {
          if (action.payload.km) {
            setVehicleMap(action.payload.km);

            const mdls = action.payload.km.map((kmObj) => ({
              value: kmObj.motorModelCodeDC,
              label: kmObj.modelText,
            }));
            const uniqueMdls = removeDuplicates(mdls);
            setModelListKm(uniqueMdls);

            const engns = action.payload.km.map((kmObj) => ({
              value: kmObj.motorEngineCodeEC,
              label: kmObj.et,
            }));
            const uniqueEngns = removeDuplicates(engns);
            setEngineListKm(uniqueEngns);

            setSelectedVehicleMap({
              motorEngineCodeEC: uniqueEngns[0].value,
              motorMakeCodeMC: action.payload.km[0].motorMakeCodeMC,
              motorModelCodeDC: uniqueMdls[0].value,
              motorYear: action.payload.km[0].motorYear,
            });
          }

          if (action.payload.k1) {
            const k1Arr = action.payload.k1.map((k1Obj) => ({
              ...k1Obj,
              active: false,
            }));
            setVehicleSpecGroup(k1Arr);
            setVehicleSpecGroup(action.payload.k1);
            setSelectedVehicleMap({
              motorEngineCodeEC: action.payload.km[0].motorEngineCodeEC,
              motorMakeCodeMC: action.payload.km[0].motorMakeCodeMC,
              motorModelCodeDC: action.payload.km[0].motorModelCodeDC,
              motorYear: action.payload.km[0].motorYear,
            });
            if (
              selectedView === "table" &&
              tableSpecItemsRef.current.length > 0
            ) {
              tableSpecItemsRef.current[0].click();
            }
            if (
              selectedView === "list" &&
              accordionHeadersRef.current.length > 0
            ) {
              accordionHeadersRef.current[0].click();
            }
          }
        } else {
          closePopup();
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    initialGetVehicleMap();
  }, []);

  const getVehicleSpecHandler = () => {
    const payload = {
      motorsEngine: selectedVehicleMap.motorEngineCodeEC,
      motorsMake: selectedVehicleMap.motorMakeCodeMC,
      motorsModel: selectedVehicleMap.motorModelCodeDC,
      motorsYear: selectedVehicle.year,
      dispatch: dispatch,
    };
    try {
      const vehicleSpecGroupAction = dispatch(getVehicleSpec(payload));
      vehicleSpecGroupAction.then((action) => {
        const k1Arr = action.payload.k1.map((k1Obj) => ({
          ...k1Obj,
          active: false,
        }));
        setVehicleSpecGroup(k1Arr);
        if (selectedView === "table" && tableSpecItemsRef.current.length > 0) {
          tableSpecItemsRef.current[0].click();
        }
        if (selectedView === "list" && accordionHeadersRef.current.length > 0) {
          accordionHeadersRef.current[0].click();
        }
      });
    } catch (error) {}
  };

  const setSelectedSpecGroupActive = (specGroup) => {
    const specGroupTemp = vehicleSpecGroup.map((k1Obj) => ({
      ...k1Obj,
      active: false,
    }));
    let matchingSpecGroup = specGroupTemp.find((data) => {
      return data.tableCode === specGroup.tableCode;
    });
    if (matchingSpecGroup === undefined) {
      matchingSpecGroup.active = true;
    } else {
      matchingSpecGroup.active = !specGroup.active;
    }
    setVehicleSpecGroup(specGroupTemp);
  };

  const viewVehicleSpecInfoHandler = (e, specGroup) => {
    setSelectedSpecGroupActive(specGroup);
    setVehicleSpec([]);
    const payload = {
      motorsEngine: selectedVehicleMap.motorEngineCodeEC,
      motorsMake: selectedVehicleMap.motorMakeCodeMC,
      motorsModel: selectedVehicleMap.motorModelCodeDC,
      motorsYear: selectedVehicle.year,
      tableCode: specGroup.tableCode,
      dispatch: dispatch,
    };
    try {
      const vehicleSpecInfoAction = dispatch(getVehicleSpecInfo(payload));
      vehicleSpecInfoAction.then((action) => {
        if (action.payload) {
          setVehicleSpec(action.payload.k2);
          if (selectedView === "list" && accordionItemsRef.current.length > 0) {
            accordionItemsRef.current[0].click();
          }
          if (selectedView === "table") {
            constructGridData(action.payload.k2, specGroup.tableDescription);
          }
        }
      });
    } catch (error) {}
  };

  const handleAccordionItemClick = (e, selectedSpec) => {
    setSelectedSpec(selectedSpec);
  };

  const handleViewTypeChange = (id) => {
    setSelectedView(id);
    const specGroupTemp = vehicleSpecGroup.map((k1Obj) => ({
      ...k1Obj,
      active: false,
    }));
    setVehicleSpecGroup(specGroupTemp);
    setTimeout(() => {
      if (id === "table" && tableSpecItemsRef.current.length > 0) {
        tableSpecItemsRef.current[0].click();
      }
      if (id === "list" && accordionHeadersRef.current.length > 0) {
        accordionHeadersRef.current[0].click();
      }
    }, 500);
  };

  const onCategoryGrpClickHandler = (e, type, attrs) => {
    const category = attrs.category;
    const groups = attrs.groups;
    let selectedGroupDetails = [];
    if (type === "group") {
      let grp = groups.find((group) => group.code === e.target.id);
      let singleItem = {
        categoryId: Number(category.code),
        name: grp.name,
        id: Number(grp.code),
      };
      selectedGroupDetails.push(singleItem);
    }

    if (type === "category" || (type === "group-all" && !isEmpty(groups))) {
      groups.map((grp, index) => {
        let singleItem = {
          categoryId: Number(category.code),
          name: grp.name,
          id: Number(grp.code),
        };
        selectedGroupDetails.push(singleItem);
      });
    }

    dispatch({
      payload: {
        selectedGroupDetails,
      },
      type: "SUPPLIES_GROUP_SELECTED",
    });
    dispatch({
      payload: {
        bool: true,
      },
      type: "CHEMICAL_CATEGORY_CHANGED",
    });
    const userPreferenceDetail = getPreferenceDataFromStorage();
    let userPrefDetail =
      getDefaultDataBasedOnChemicalUSerPref(userPreferenceDetail);

    let groupIds = selectedGroupDetails.map((single) => single.id); //["502"];
    dispatch(
      getChemPartByGrp(
        groupIds,
        userPrefDetail.pageNumber,
        userPrefDetail.pageSize,
        userPrefDetail.catalogSortBy,
        userPrefDetail.catalogOrder
      )
    );
    dispatch({
      payload: {
        brandsView: false,
      },
      type: "SUPPLIES_BRAND_VIEW",
    });
    closePopup();
    history.push("/supplies-list");
  };

  const constructGridData = (vehicleSpecList, tableDesc) => {
    const userPreferenceDetail = getPreferenceDataFromStorage();
    const vehicleSpecPref = userPreferenceDetail.find(
      (pref) => pref.code === "Vehicle Specs" && pref.text === tableDesc
    );
    let userPref = [];
    let grps = [];
    let categoryData = {};
    let categoryType = {};

    if (!isEmpty(vehicleSpecPref)) {
      userPref = vehicleSpecPref.possibleAnswer[0];
      let data = userPref.text.split(";");
      categoryType = userPref.code;
      categoryData = {
        code: data[0].split("-")[0],
        name: data[0].split("-")[1],
      };
      // setCategory(categoryData);

      grps = [];
      for (let i = 1; i < data.length; i++) {
        grps.push({
          code: data[i].split("-")[0],
          name: data[i].split("-")[1],
        });
      }
    }
    // setGroups(grps);

    // setNoTableData(false);
    setSpecTableColumns([]);
    setSpecTableRows([]);

    const rowData = [];
    const columns = [
      {
        dataField: "specDesc",
        text: "Description",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        "z-index":"999"
      },
      {
        dataField: "qualifier",
        text: "Qualifier",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        "z-index":"999"
      },
      {
        dataField: "otherText",
        text: "Type",
        headerStyle: headerStyle,
        align: "center",
        headerAlign: "center",
        "z-index":"999"
      },
      {
        dataField: "notes",
        "z-index":"999",
        text: "Notes",
        headerStyle: headerStyle,
        align: "top",
        headerAlign: "center",
        width: "80",
        attrs: {
          category: categoryData,
          groups: grps,
          categoryType: categoryType,
        },
        headerFormatter: (column, colIndex) => {
          if (!isEmpty(vehicleSpecPref)) {
            let categoryIconObj = categoryIcons.find(
              (category) => category.categoryName === column.attrs.category.name
            );
            let categoryIconPath = categoryIconObj
              ? categoryIconObj.iconPath
              : "";
            let ctgryTooltip = column.attrs.category.name;

            return (
              <div>
                <span>Notes</span>
                <br></br>
                <span className="notesHeader">
                  {column.attrs.categoryType === "Category" && (
                    //category icon  (category only)
                    <OverlayTrigger
                     style={{ "z-index": "999" }}
                     placement="right"
                     overlay={<Tooltip id={`tooltip`}>{ctgryTooltip}</Tooltip>}
                    >
                      <img
                        className="iconSize"
                        src={categoryIconPath}
                        alt=""
                        onClick={(e) =>
                          onCategoryGrpClickHandler(e, "category", column.attrs)
                        }
                        id={column.attrs.category.code}
                      />
                    </OverlayTrigger>
                  )}

                  {column.attrs.categoryType === "Category-Group" &&
                    column.attrs.groups.map((grp, index) => {
                      let groupIconObj = groupIcons.find(
                        (group) => group.groupName === grp.name
                      );
                      let groupIconPath = groupIconObj
                        ? groupIconObj.iconPath
                        : "";
                      let grpTooltip = grp.name;
                      //group icons
                      return (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip
                              id={`tooltip`}
                            >{`Show ${grpTooltip} items in catalog`}</Tooltip>
                          }
                        >
                          <img
                            title={""}
                            className="iconSize"
                            src={groupIconPath}
                            alt=""
                            onClick={(e) =>
                              onCategoryGrpClickHandler(
                                e,
                                "group",
                                column.attrs
                              )
                            }
                            id={grp.code}
                          />
                        </OverlayTrigger>
                      );
                    })}

                  {column.attrs.categoryType === "Category-Group" &&
                    column.attrs.groups.length > 1 && (
                      //Select ALL - make it a check box, instead of label
                      <div className="vehiclespec-select">
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id={`tooltip`}>
                              {"Show all items in catalog"}
                            </Tooltip>
                          }
                        >
                          <span title={""} className="select-all-label">
                            All
                          </span>
                        </OverlayTrigger>
                        <input
                          title={""}
                          type="checkbox"
                          id="myCheck"
                          onClick={(e) =>
                            onCategoryGrpClickHandler(
                              e,
                              "group-all",
                              column.attrs
                            )
                          }
                        ></input>
                      </div>
                    )}
                </span>
              </div>
            );
          } else {
            return <span>Notes</span>;
          }
        },
      },
    ];

    let oilVisTableDescrption = {};
    vehicleSpecList.map((data, index) => {
      let notesTxt = <span>{data.specText} {data.value} {data.unit} <span className="notes_data">{data.notes}</span>  </span>;
      if (data.tableName === 'OILVIS') {
        const grpId = grps.find((grp) => 
          grp.name === data.value.replace(/-/g, '')
        )?.code;
        if (!isEmpty(grpId)) {
          notesTxt = <a href="#" onClick={(e) =>
            onCategoryGrpClickHandler(
              e,
              "group",
              {
                category: categoryData,
                groups: grps,
                categoryType: categoryType
              }
            )
          }
          id={grpId}><span id={grpId}>{data.specText} {data.value} {data.unit} <span id={grpId}className="notes_data">{data.notes}</span>  </span></a>; 
        }        
      }
      // if (data.id === 7 && data.tableName === 'OILVIS') {
      //   oilVisTableDescrption = {
      //     specDesc: data.tableDescription,
      //     qualifier: data.qualifiers,
      //     otherText: data.tableName,
      //     notes: data.notes
      //   }
      // } else {
      rowData.push({
        specDesc: data.tableDescription,
        qualifier: data.qualifiers,
        otherText: data.tableName,
        notes: notesTxt,
      });
      // }
    });
    // if(!isEmpty(oilVisTableDescrption)) {
    //   rowData.splice(0, 0, oilVisTableDescrption)
    // }
    setSpecTableColumns(columns);
    setSpecTableRows(rowData);
    // if(rowData.length == 0) {
    //   setNoTableData(true);
    // }
  };

  return (
    <React.Fragment>
      {!isLoading && vehicleSpecGroup.length > 0 ? (
        <XModal
          title={
            "VEHICLE SPECS: " +
            selectedVehicle.year +
            " " +
            selectedVehicle.make +
            " " +
            selectedVehicle.model +
            " " +
            selectedVehicle.engine
          }
          show={openPopup}
          handleClose={closePopup}
          showButtons={false}
          dialogClassName="vehicle-spec-modal"
        >
          <div className="">
            <Row>
              <Col sm={4}>
                <Row>
                  <Col>
                    <div className="viewTypeWrapper">
                      {viewTypes.map((viewType, index) => {
                        return (
                          <Form.Check
                            className="bold"
                            inline
                            type="radio"
                            label={viewType.text}
                            id={viewType.id}
                            name="viewTypes"
                            onChange={(e) => {
                              handleViewTypeChange(e.target.id);
                            }}
                            checked={viewType.id === selectedView}
                          />
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {!isLoading && selectedView === "list" ? (
            <Row className="view-container">
              <Col sm={5} className="spec-categories p-0">
                <div className="specs_accordion_container">
                  <Accordion className="main_accordion">
                    {vehicleSpecGroup ? (
                      _sortBy(vehicleSpecGroup,"tableDescription").map((specGroup, index) => {
                        return (
                          <Card>
                            <Accordion.Toggle
                              as={Card.Header}
                              class={`accordion_header ${
                                specGroup.active ? "expanded" : "collapsed"
                              }`}
                              eventKey={index + 1}
                              ref={(el) =>
                                (accordionHeadersRef.current[index] = el)
                              }
                              onClick={(e) =>
                                viewVehicleSpecInfoHandler(e, specGroup)
                              }
                            >
                              <span>{specGroup.tableDescription}</span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index + 1}>
                              <Card.Body className="accordion_body">
                                <Accordion className="child_accordion">
                                  {vehicleSpec.map((specDescription, index) => {
                                    return (
                                      <Card>
                                        <Accordion.Toggle
                                          as={Card.Header}
                                          eventKey={index + 1}
                                          ref={(el) =>
                                            (accordionItemsRef.current[index] =
                                              el)
                                          }
                                          className={
                                            specDescription.qualifiers ===
                                              selectedSpec.qualifiers &&
                                            specDescription.notes ===
                                              selectedSpec.notes
                                              ? "selected_spec"
                                              : "un_selected_spec"
                                          }
                                          onClick={(e) =>
                                            handleAccordionItemClick(
                                              e,
                                              specDescription
                                            )
                                          }
                                        >
                                          {specDescription.qualifiers.length ? (
                                            specDescription.tableName.length ? (
                                              <span>
                                                {specDescription.tableDescription +
                                                  "-" +
                                                  specDescription.qualifiers +
                                                  "-" +
                                                  specDescription.tableName}
                                              </span>
                                            ) : (
                                              <span>
                                                {specDescription.tableDescription +
                                                  "-" +
                                                  specDescription.qualifiers}
                                              </span>
                                            )
                                          ) : specDescription.tableName
                                              .length ? (
                                            <span>
                                              {specDescription.tableDescription +
                                                "-" +
                                                specDescription.tableName}
                                            </span>
                                          ) : (
                                            <span>
                                              {specDescription.tableDescription}
                                            </span>
                                          )}
                                        </Accordion.Toggle>
                                      </Card>
                                    );
                                  })}
                                </Accordion>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      })
                    ) : (
                      <span>No Data Found</span>
                    )}
                  </Accordion>
                </div>
              </Col>
              <Col sm={7} className="spec_description_container">
                <Form className="spec_description_form">
                  <Form.Group className="spec_description_input_container">
                    <Row className="py-0">
                      <Col sm={2}>
                        <Form.Label className="bold">Desc</Form.Label>
                      </Col>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          value={selectedSpec.tableDescription}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="spec_description_input_container">
                    <Row className="py-0">
                      <Col sm={2}>
                        <Form.Label className="bold">Qualifier</Form.Label>
                      </Col>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          value={selectedSpec.qualifiers}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="spec_description_input_container">
                    <Row className="py-0">
                      <Col sm={2}>
                        <Form.Label className="bold">Other Text</Form.Label>
                      </Col>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          value={selectedSpec.tableName}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row className="py-0">
                      <Col sm={2}>
                        <Form.Label className="bold">Notes</Form.Label>
                      </Col>
                      <Col sm={10}>
                        <Form.Control
                          as="textarea"
                          rows="10"
                          placeholder={`${selectedSpec.specText} ${selectedSpec.value}  ${selectedSpec.unit} \n${selectedSpec.notes}  `}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          ) : null}

          {!isLoading && selectedView === "table" ? (
            <Row className="view-container">
              <Col sm={3} className="spec-categories p-0">
                <div className="specs_accordion_container">
                  <ul className="table-spec-categories">
                    {vehicleSpecGroup &&
                      _sortBy(vehicleSpecGroup, 'tableDescription').map((specGroup,index) => {
                        return (
                          <li
                            className={
                              specGroup.active
                                ? "table_selected_spec"
                                : "table_un_selected_spec"
                            }
                            ref={(el) =>
                              (tableSpecItemsRef.current[index] = el)
                            }
                            onClick={(e) =>
                              viewVehicleSpecInfoHandler(e, specGroup)
                            }
                          >
                            <span>{(specGroup.tableDescription)}</span>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </Col>
              {specTableColumns.length > 0 && specTableRows.length > 0 ? (
                <React.Fragment>
                  <Col sm={9}>
                    <Row className="table-row">
                      <Col>
                        <BootstrapTable
                          keyField="specDesc"
                          data={specTableRows}
                          columns={specTableColumns}
                          wrapperClasses="spec-table-responsive"
                          // rowClasses="custom-row-class"
                        />
                      </Col>
                    </Row>
                  </Col>
                </React.Fragment>
              ) : null}
            </Row>
          ) : null}
        </XModal>
      ) : null}
      {!isLoading && vehicleSpecGroup.length == 0 ? (
        <XModal
          show={vehicleSpecGroup}
          title="Please select a specific item to continue"
          handleClose={closePopup}
          handleAction={getVehicleSpecHandler}
          submitType
          dialogClassName="vehicle-map-modal"
          favoriteTextValidity={true}
        >
          <Container>
            <Row>
              <Col md={{ span: 4, offset: 1 }}>
                <span className="bold">Model</span>
                <br />
                {modelListKm.map((model, key) => {
                  return (
                    <Form.Check
                      type="radio"
                      label={model.label}
                      id={model.value}
                      name="motorModelCodeDC"
                      defaultChecked={key === 0}
                      onChange={(e) => {
                        handleVehicleMapChange(e.target.id, "motorModelCodeDC");
                      }}
                    />
                  );
                })}
              </Col>
              <Col md={{ span: 6, offset: 1 }}>
                <span className="bold">Engine</span>
                <br />
                {engineListKm.map((engine, key) => {
                  return (
                    <Form.Check
                      type="radio"
                      label={engine.label}
                      id={engine.value}
                      name="motorEngineCodeEC"
                      defaultChecked={key === 0}
                      onChange={(e) => {
                        handleVehicleMapChange(
                          e.target.id,
                          "motorEngineCodeEC"
                        );
                      }}
                    />
                  );
                })}
              </Col>
            </Row>
          </Container>
        </XModal>
      ) : null}
    </React.Fragment>
  );
};

export default FluidsAndFiltersPopup;
