const search = (state = {
    yearListAll: [],
    makesListForYear: [],
    modelListForYearAndMake: [],
    engineForYearMakeModel:[],
}, action) => {
    let newState = {
        ...state,
    };
    switch (action.type) {
        case 'YEAR_LIST_ALL':
            newState = {
                ...state,
                yearListAll: Object.assign([], action.payload.yearList)
            }
            break;
        case 'MAKES_FOR_YEAR':
            newState = {
                ...state,
                makesListForYear: Object.assign([], action.payload.makeListForYear),
            }
            break;
        case 'MODEL_FOR_MAKE_AND_YEAR':
            newState = {
                ...state,
                modelListForYearAndMake: Object.assign([], action.payload.modelListForYearAndMake),
            };
            break;
        case 'ENGINE_FOR_YEAR_MAKE_MODEL':
            newState = {
                ...state,
                engineForYearMakeModel: Object.assign([], action.payload.engineForYearMakeModel),
            };
            break;
        case 'VIN_NUMBER_SEARCH':
            newState = {
                ...state,
                vinNumberSearchResult: action.payload.vehicleModel
            };
            break;
        case 'VEHICLE_SELECTION_COMPLETED':
            newState = {
                ...state,
                selectedVehicle: action.payload.selectedVehicle,
            };
            break;
        case 'CLEAR_MAKE_MODEL_AND_ENGINE':
            newState = {
                ...state,
                selectedVehicle: {
                    year: action.payload.selectedVehicle.year,
                    make: '',
                    makeId: '',
                    model: '',
                    modelId: '',
                    engine: '',
                    engineId: '',
                    trim: '',
                },
            };
            break;
        case 'CLEAR_MODEL_AND_ENGINE':
            newState = {
                ...state,
                selectedVehicle: {
                    year: action.payload.selectedVehicle.year,
                    make: action.payload.selectedVehicle.make,
                    makeId: action.payload.selectedVehicle.makeId,
                    model: '',
                    modelId: '',
                    engine: '',
                    engineId: '',
                    trim: '',
                },
            };
            break;
        case 'CLEAR_ENGINE':
            newState = {
                ...state,
                selectedVehicle: {
                    year: action.payload.selectedVehicle.year,
                    make: action.payload.selectedVehicle.make,
                    makeId: action.payload.selectedVehicle.makeId,
                    model: action.payload.selectedVehicle.model,
                    modelId: action.payload.selectedVehicle.modelId,
                    engine: '',
                    engineId: '',
                    trim: '',
                },
            };
            break;
        case 'CLEAR_YMME_LISTS':
            newState = {
                ...state,
                yearListAll: action.payload.yearList,
                makesListForYear: action.payload.makeList,
                modelListForYearAndMake: action.payload.modelList,
                engineForYearMakeModel: action.payload.engineList,
            }
            break;
        default:
            break;
    }
    return newState;
}
export default search;