import React from 'react';
import { Container } from 'react-bootstrap';
import SingleProPack from './SingleProPack';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

function ProPackContainer(props) {
	const user = useSelector((state) => state.user);
	const proPacksData = user.proPacksData;
	// console.log(props, 'props propack', proPacksData);
	const renderCarousel = () => {
		let { selectedProPack } = props;
		let carouselRows = [];
		if (!_isEmpty(proPacksData)) {
			proPacksData.map((single, index) => {
				carouselRows.push(
					<SingleProPack
						key={index}
						proPack={single}
						handleClick={props.handleClick}
						index={index}
						selectedBundle={selectedProPack}
					/>
				);
			});
		}
		return carouselRows;
	};
	return (
		<section className="section__products">
			<Container fluid className="pl-5 pr-5">{renderCarousel()}</Container>
		</section>
	);
}
export default ProPackContainer;
