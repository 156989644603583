import React, { useRef, useEffect, useState } from "react";
import { Form, Accordion } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from 'lodash/isEmpty';

function ClassicProPackContainer(props) {
  let {
		handleClick,
		selectedProPack
	} = props;
  const user = useSelector((state) => state.user);
  const [proPacksData, setProPacksData]  = useState([]);
  const propackCategoriesRef = useRef([]);
  const propackBundlesRef = useRef([]);
  useEffect(() => {
    if(user.proPacksData) {
      let propPacks = user.proPacksData.map(proPack => ({...proPack, selected: false, active: false}));
      setProPacksData(propPacks);
      setTimeout(() => {
        if(propackCategoriesRef.current.length > 0 && propackCategoriesRef.current[0] !== null) {
            if(!propackCategoriesRef.current[0].checked) {
                propackCategoriesRef.current[0].click();
            }
        }
      }, 2000);
    }
  }, [user.proPacksData]);

  const handleProPackSelect = (event, single) => {
    const isChecked = event.target.checked;
    let matchingProPack = proPacksData.find((data) => {
			return data.name === single.name;
		});
		matchingProPack.selected = isChecked;
    matchingProPack.active = isChecked;
		setProPacksData([...proPacksData]);
    if(!isChecked) {
      handleClick(null, {id:selectedProPack.id, name:selectedProPack.jobName});
    }
  }

  const handleBundleClick = (e, bundleObj, index) => {
    propackBundlesRef.current.map((bundleRef, refIndex) => {
      if(bundleRef.checked && refIndex !== index) {
        bundleRef.checked = false;
      }
    });
    handleClick(e, bundleObj);
  }

  const togglePropackBundle = (event, single) => {
    let matchingProPack = proPacksData.find((data) => {
			return data.name === single.name;
		});
    matchingProPack.active = !matchingProPack.active;
    setProPacksData([...proPacksData]);
  }

  const renderProPackCategories = () => {
  	return proPacksData.map((single, index) => {
      return (
        single.jobs && single.jobs.length > 0 && (
          <Form.Group controlId={`ProPacksCheckbox${index+1}`}>
            <Form.Check
              type="checkbox"
              label={single.name}
              onChange={(e) => handleProPackSelect(e, single)}
              ref={el => propackCategoriesRef.current[index] = el}
            />
          </Form.Group>
	      )
      );
		});
	};

  const renderProPackBundles = () => {
  	return proPacksData.map((single, cIndex) => {
      return (
        single.selected && (
          <Accordion defaultActiveKey="0">
            <div className="classicListWrapper">
              <Accordion.Toggle
                as="div"
                className="accordionHead"
                variant="link"
                eventKey="0"
                onClick={(e) => togglePropackBundle(e, single)}
              >
                <div class={`pre-defined-jobs classicListWrapperHead ${single.active ? 'expanded' : 'collapsed'}`}>
                  {single.name} - Select one
                </div>
              </Accordion.Toggle>
    					<Accordion.Collapse eventKey="0">
    						<div className="classicListWrapperBody">
                  {single.jobs.map((bundle, bIndex) => {
                    return (
                      <Form.Group controlId={`BundlesCheckbox${cIndex+1}${bIndex+1}`}>
                        <Form.Check
                          type="checkbox"
                          label={bundle.jobName}
                          ref={el => propackBundlesRef.current[bIndex] = el}
                          onClick={(e) => handleBundleClick(e, {id:bundle.id, name:bundle.name}, bIndex)}
                       />
                      </Form.Group>
                    );
                  })}
    						</div>
    					</Accordion.Collapse>
            </div>
  				</Accordion>
	      )
      );
		});
	};

  return (
    <section className="classicSection classicSection_parts">
      <Container>
       <div class="containerHead">Pro Packs Catalog</div>
        <div className="classicWrapperPro">
          <div className="classicCategoriesPro">
            {/* <div className="classicMainHead">Select a Pro Pack</div> */}
            <div className="classicListWrapper">
              <div className="classicListWrapperBody">
                {renderProPackCategories()}
              </div>
            </div>
          </div>
          <div className="classicGroupsPro">
            <Accordion defaultActiveKey="0">
              <div className="classicListWrapper">
                {renderProPackBundles()}
              </div>
            </Accordion>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ClassicProPackContainer;
