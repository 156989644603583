import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from 'lodash/isEmpty';
import PaginationComponent from "../../components/shared/Pagination";
import {Row, Col} from 'react-bootstrap';
import Filter from './storeFilter/Filter';
import { fetchReturn } from './storeDashboard.thunks';
import HomePage from './HomePage';
import { triggerCustomUxCriticalError } from '../../actions/error';
import Header from './storeProfile/Header';
import storage from "../../helpers/storage";
import {themeColor} from "../../helpers/utils";
import {getPartImageURLCookie} from "../../../src/components/User/UserManagement/usermanagement.thunk";
import _cloneDeep from 'lodash/cloneDeep';

const url ="/store-management";
const urlName ="Store Management";
const STORE_USER =  "STORE_USER";

function StoreDashboard(props) {
  const dispatch = useDispatch(); 
  const userDetails = useSelector((state) => state.user.userDetails);
  const storeBoard = useSelector((state) => state.storeDashBoard);
  const returnList = !_isEmpty(storeBoard) && storeBoard.returnList.partReturns;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [rowData, setRows] = useState([]);
  const returnsLoader = !_isEmpty(storeBoard) && storeBoard.returnsLoading;
  const [dates, setDates] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customerNum, setCustomerNum] = useState("");
  const [totalpages ,setTotalPages] = useState("");
  const [partNum, setPartNum] = useState("");
  const [ACX, setACX] = useState("");
  const [REF, setREF] = useState("");
  const [status, setStatus] = useState("");
  const [ExpandRows, setExpandRows] = useState(true);
  const [APIloader, setAPILoader] = useState(false);
  const [customerName, setCustomerName] = useState("");

  let option =
  [
    { label: "Picked Up", value: "Picked Up" }
  ]

  useEffect(() => {
    dispatch(getPartImageURLCookie()).then((resp) => {
      storage.set("imageURLCookie", resp.payload || {}, "local");
    });
  }, [])

    useEffect(() => {
     initialFetchStoreData();     
    }, []);

    const displayErrorMsg = ()=>{
      let statusMessageProps = {
        heading: "",
        message: "",
        type: "",
      };
      statusMessageProps.heading = "Internal System Error";
      statusMessageProps.type = 'error';
      triggerCustomUxCriticalError(statusMessageProps, dispatch);
    }

    
      const initialFetchStoreData =()=>{  
        const payload = {
          page: 1,
          status: option[0].value,
          pageSize: pageSize,
        }
        setAPILoader(true)
        dispatch(fetchReturn(payload)).then((action) => {
          if(action.type === "fetchReturn/fulfilled"){
          if(!_isEmpty(action.payload)){
            setCurrentPage(action.payload.page);
            setStatus(option[0]);
            setTotalPages(action.payload.totalPages);     
            let oldArray = action.payload.partReturns;
            var newArray = Object.assign([], oldArray);
            setRows(newArray); 
            setAPILoader(false) ;  
          } 
        }else{
          setAPILoader(false) ;  
          displayErrorMsg(); }      
        }) 
      }

      const handleOnApplyButn = () => {  
        const payload = {
          startTime: startDate ? startDate : "",
          endTime: endDate ? endDate : "",
          page:  1,
          pageSize: pageSize,
          customerId: customerNum ? customerNum : "",
          confNum : ACX ? ACX : "",
          refNum :  REF ? REF  : "",
          partNumber : partNum ? partNum : "",
          status:  status.value ? status.value : option[0].value,
          customerName: customerName ? customerName : "",

        }
        setAPILoader(true) ;  
       dispatch(fetchReturn(payload)).then((action) => {
        if(!_isEmpty(action.payload)){
           setCurrentPage(action.payload.page);
          setTotalPages(action.payload.totalPages);
          let oldArray = action.payload.partReturns;
          setRows(oldArray);
          setAPILoader(false) ;      
        }else{
          setAPILoader(false) ; 
        }
         
      }) 

      };
      const handleStatusSelection =(statusSelected)=>{
        setStatus(statusSelected);
      }
      const handleExpandButton =()=>{
        let data = _cloneDeep(rowData);

        setExpandRows(!ExpandRows);
        !_isEmpty(data) && data.map((v, key) => {
            if(ExpandRows){

              v.isExpand=false;
            }
            else{
              v.isExpand=true;

            }
          }
        )
        console.log("🚀 ~ file: index.js:140 ~ handleExpandButton ~ ...rowData", ...data)
        setRows([...data])
      }
          const handleDateRange = (e, picker) => {
            const { startDate, endDate } = picker;
            const _startDate = startDate.toISOString();
            const _endDate = endDate.toISOString();
            setDates({ start_date: _startDate, end_date: _endDate });
            setDateFilter(
            `${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`);
            setStartDate(startDate.format("YYYY-MM-DD"));
            setEndDate(endDate.format("YYYY-MM-DD"));
          };
        
          const clearDateFilter = () => {
            setDates(null);
            setStartDate("");
            setEndDate("");
            setDateFilter("");
          };

          const clearHeaderId =() =>{
            dispatch({
              payload: {
                headerId: "",
              },
              type: 'STORE_HEADERID',
            });
          } 
          
  const handlePageChange = (clickedPage , flag) => {
    if(flag !== false){
      setAPILoader(true) ;  
    }
    let pageNum = clickedPage > totalpages ? "1" : clickedPage;
    let startTime = storeBoard && storeBoard.returnList.startTime ? storeBoard.returnList.startTime :"";
    let endTime  = storeBoard && storeBoard.returnList.endTime ? storeBoard.returnList.endTime :"";
    let customerId = storeBoard && storeBoard.returnList.customerId ? storeBoard.returnList.customerId :""; 
    let status = storeBoard && storeBoard.returnList.status ? storeBoard.returnList.status : "All" ; 
    let confNum = storeBoard && storeBoard.returnList.confNum ?  storeBoard.returnList.confNum  :"" ;
    let refNum = storeBoard && storeBoard.returnList.refNum ? storeBoard.returnList.refNum :"";
    let partNumber = storeBoard && storeBoard.returnList.partNumber ? storeBoard.returnList.partNumber :"";

    const payload = {
      startTime:startTime,
      endTime:endTime,
      page: pageNum ? pageNum : storeBoard.returnList.page ,
      pageSize:pageSize,
      customerId:customerId,
      status:status,
      confNum :confNum,
      refNum : refNum,
      partNumber :partNumber
    } 
    dispatch(fetchReturn(payload)).then((action) => {
     if(!_isEmpty(action.payload)){
      setCurrentPage(action.payload.page);
      setTotalPages(action.payload.totalPages);
       let oldArray = action.payload.partReturns;
       setRows(oldArray);
       if(returnsLoader === false){
        clearHeaderId();
        setAPILoader(false);
       }
     } else{
      if(returnsLoader === false){
        clearHeaderId();
        setAPILoader(false);
       }
      clearHeaderId()
     }
    }) 
  };

const handleReset = () =>{
setCustomerName("");
setCustomerNum("");
setPartNum("");
setStartDate("");
setEndDate("");
setREF("");
setACX("");
setStatus(option[0]);
clearDateFilter();
initialFetchStoreData();
}

    return (
   (!_isEmpty(userDetails) && userDetails.userType === STORE_USER) && (
    <div className='store-dashboard-width-adjustment'>
    <Header
        storeURL={url}
        storeName={urlName}
        STORE_USER={STORE_USER}
        />
    <div className="store_searchBox">
    <Row>
    <Col >
    <Filter
    setCustomerNum ={ setCustomerNum}
    handleStatusSelection={handleStatusSelection}
    setACX={setACX}
    setREF={setREF}
    setPartNum={setPartNum}
    handleDateRange={handleDateRange}
    clearDateFilter={clearDateFilter}
    handleOnApplyButn={handleOnApplyButn}
    dateFilter={dateFilter}
    handleReset={handleReset}
    customerNum={customerNum}
    partNum={partNum}
    status={status}
    ACX={ACX}
    REF={REF}
    ExpandRows={ExpandRows}
    handleExpandButton={handleExpandButton}
    rowData={rowData}
    userDetails={userDetails}
    STORE_USER={STORE_USER}
    setCustomerName={setCustomerName}
    customerName={customerName}
     />
    </Col>
     </Row>
    </div>
    <HomePage
    returnsLoading={APIloader}
     partLineItemRows={rowData}
     initialFetchStoreData={initialFetchStoreData}
     handlePageChange={handlePageChange}
     ExpandRows={ExpandRows}
     displayErrorMsg={displayErrorMsg}
     themeColor={themeColor}
     STORE_USER={STORE_USER}
     clearHeaderId={clearHeaderId}
     userType={userDetails && userDetails.userType}
     />
          <Col className="store_pagination_wrapper">
            { (!_isEmpty(returnList)  && !APIloader ) && (
              <PaginationComponent
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                totalPages={totalpages}
              />)}
            </Col>
     
   </div>)
    )
    }
    
export default StoreDashboard
