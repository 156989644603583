import httpClient from "../../../services/http";
import { buildQueryUrl } from "../../../helpers/utils";

export const getAvailableGFXFilter = (data) =>
  httpClient.get(
    `catalog/part/category/group/${
      data["group"]
    }/illustration/filter?${buildQueryUrl(data)}`,
    { dispatch: data.dispatch }
  );

export const getGFXData = (data) =>
  httpClient.get(
    `catalog/part/category/group/${
      data["group"]
    }/illustration/asset?${buildQueryUrl(data)}`,
    { dispatch: data.dispatch }
  );

export const getAllPartByGroup = (data) =>
  httpClient.post(
    `catalog/part/category/group/parts/noPage`,
    data.requestItems,
    { params: data.params }
  );

// TODO: Need to change GET to POST request with below payload
/* GET -> POST: /catalog/oe-part/category/group/{​​​​​​​​​​​​group}​​​​​​​​​​​​/parts
{​​​​​​​​​​​​
  "requestItems": [
      {​​​​​​​​​​​​
          "groupCode": 2,
          "specificCondition": [
              {​​​​​​​​​​​​
                  "code": 168,
                  "answer": "?/Y/N/78/79"
              }​​​​​​​​​​​​,
                              {​​​​​​​​​​​​
                  "code": "?",
                  "answer": "?/Y/N"
              }​​​​​​​​​​​​
          ]
      }​​​​​​​​​​​​
  ]
} */
export const getOEPartByGroup = (data) =>
  httpClient.get(
    `catalog/oe-part/category/group/${data["group"]}/parts?${buildQueryUrl(
      data
    )}`,
    { dispatch: data.dispatch }
  );

export const getPricingAvailabilityData = (data) =>
  httpClient.post(`/order-service/pricing-availability`, data);

export default httpClient;
