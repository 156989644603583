import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPaymentsPDFService,
  sendPaymentInvoiceEmailService,
} from "./payments-print.service";

export const getPaymentsPrintPDF = createAsyncThunk(
  "getPaymentsPrintPDF",
  async (payload) => {
    const response = await getPaymentsPDFService(payload);
    return response;
  }
);

export const getPaymentsInvoiceByEmail = createAsyncThunk(
  "getPaymentsInvoiceByEmail",
  async (payload) => {
    const response = await sendPaymentInvoiceEmailService(payload);
    return response;
  }
);
