import React, { useEffect, useState, Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useReactToPrint } from "react-to-print-nonce";
import { useRef } from "react";
import _head from "lodash/head";
import {
  OverlayTrigger,
  Tooltip,
  Popover,
  Container,
  Table,
  Row,
  Col,
  Form,
  Pagination,
  Button,
  Image,
  Spinner
} from "react-bootstrap";
import PricingIcons from "../../Listing/PricingIcons";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import XModal from "../XModal";
import _findIndex from "lodash/findIndex";
import _isEmpty from "lodash/isEmpty";
import isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import AddToCart from "../../shared/AddToCart/AddToCart";
import { triggerCustomUxCriticalError } from "../../../actions/error";
import {
  getPartImages,
  getPartCatlogData,
  getServiceTypes,
  getDrivingConditions,
  getServiceIntervals,
  getModernServiceSpecificConditions,
  getModernServiceData,
} from "./vehiclemaintenance.thunks";
import { setPartHistory } from "../../shared/MyGarage/mygarage.thunks";
import { addItemToCart, addItemToCart2 } from "../../../actions/cart";
import _uniqBy from "lodash/uniqBy";
import _find from "lodash/find";
import _map from "lodash/map";
import _includes from "lodash/includes";
import "./styles.scss";

import {
  getImageURLCookieDetails,
  numbersOnlyRegExp,
  formatAmount,
  formatTimeAvb,
  distributeAvailability,
  timeInUTC,
  distributeAvailabilityByStore,
  deliveryPickupWarning,
  getShipInfo,
  getstoreName,
  getExtendedTotalPrice,
  timeInCST,
  getPartPackQty,
  displayQtyChangeMsg,
  getDistributionForVehicle,
  distributeAvailability2,
  isStockOrder,
  getCartShipDetails
} from "../../../helpers/utils";
import _ from "lodash";
import AddToCartWarningPopup from "../../shared/AddToCartWarningPopup/AddToCartWarningPopup";
import AvailabilityStoreView from "../../shared/AvailabilityStoreView";
import ShipInfoHeader from "../ShipInfoHeader";
import { getAvailability } from "../../../helpers/availabilityService"
const VehicleMaintenancePopup = (props) => {
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const user = useSelector((state) => state.user);
  let categoryGroupMp = useSelector((state) => state.parts.categoryGroupMap);
  let checkoutDetails = useSelector((state) => state.cart.checkoutDetails);
  const cartData = useSelector((state) => state.cart);
  const orderType = useSelector((state) => state.cart.orderType);
  const cartApiFetching = useSelector((state) => state.cart.cartApiFetching);
  let { openPopup, closePopup, selectedVehicle } = props;
  const dispatch = useDispatch();
  const [selectedAvailabilityHours, setSelectedAvailabilityHours] = useState(
    []
  );
  const imageURLCookie = getImageURLCookieDetails();
  const search = useSelector((state) => state.search);
  const mygarage = useSelector((state) => state.mygarage);
  const sv = mygarage.selectedVehicle || search.selectedVehicle;
  const [partCatalogTypes, setPartCatalogTypes] = useState([]);
  const [partCatalogExpandRows, setPartCatalogExpandRows] = useState([]);
  const [partCatalogExpandColumns, setPartCatalogExpandColumns] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
   /*due to react bootstrap next direct state updated value not able to access inside the formatter so reconstructing again*/
   const [updatedCart, setUpdatedCart] = useState(false);
  const [intervalTypeId, setIntervalTypeId] = useState(null);
  const [drivingConditions, setDrivingConditions] = useState([]);
  const [specificConditions, setSpecificConditions] = useState([]);
  const [ctlgSpecificConditions, setCtlgSpecificConditions] = useState([]);
  const [showSpecificConditions, setShowSpecificConditions] = useState(false);
  const [enableservicetable, setenableservicetable] = useState(false);

  const [showCtlgSpecificConditions, setShowCtlgSpecificConditions] =
    useState(false);
  const [noTableData, setNoTableData] = useState(false);
  const [selectedSpecificConditions, setSelectedSpecificConditions] = useState(
    []
  );
  const [enableOk, setEnableOk] = useState(true);
  const [selectedCtlgSpecificConditions, setSelectedCtlgSpecificConditions] =
    useState([]);
  const [ctlgRequestItems, setCtlgRequestItems] = useState([]);
  const [nonExpandleRows, setNonExpandableRows] = useState([]);
  const [
    selectedSpecificConditionsCached,
    setSelectedSpecificConditionsCached,
  ] = useState([]);
  const [defaultValues, setDefaultValues] = useState({});
  const [selectedDriveCond, setSelectedDriveCond] = useState({
    driveCondId: null,
    driveCondIdText: "",
  });
  const [intervalListOptions, setIntervalListOptions] = useState([]);
  const [intervalList, setIntervalList] = useState([]);
  const [intervalId, setIntervalId] = useState(null);

  const [serviceData, setServiceData] = useState([]);
  const [serviceTableColumns, setServiceTableColumns] = useState([]);
  const [serviceTableRows, setServiceTableRows] = useState([]);
  const [uniqueServiceLegends, setUniqueServiceLegends] = useState(new Set());
  const [serviceLegends, setServiceLegends] = useState([]);

  const [previousInterval, setPreviousInterval] = useState(null);
  const [nextInterval, setNextInterval] = useState(null);
  const [preveousOrderQty, setPreveousOrderQty] = useState("");
  const [cart, setCartData] = useState(cartData);
  const [addCartTitle, setAddCartPopupTitle] = useState(null);
  const [groupProductInfo, seGroupProductInfo] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [partCatalogRows, setPartCatalogRows] = useState([]);
  const [addToCartWarningPopup, setaddToCartWarningPopup] = useState(false);
  const [addToCartPayload, setAddToCartPayload] = useState(null);
  const parts = useSelector((state) => state.parts);
  const userDetails = useSelector((state) => state.user.userDetails);
  const allStores = useSelector((state) => state.userManagement.allStores);
  const [showAvailabilityStoreView, setShowAvailabilityStoreView] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const hideAllPrice =  parts  && parts.hidePrice;
  let ismobileEnabled = user?.userDetails?.shipTos[0]?.isMobileEnabled;
  const initialGetServiceTypes = () => {
    const payload = {
      engineCode: selectedVehicle.engineId,
      makeCode: selectedVehicle.makeId,
      modelCode: selectedVehicle.modelId,
      year: selectedVehicle.year,
      subModel: 123, // TODO
      dispatch: dispatch,
    };
    try {
      const serviceTypesAction = dispatch(getServiceTypes(payload));
      serviceTypesAction.then((action) => {
        let types = [];
        if (action.payload && action.payload.length > 0) {
          types = action.payload.filter(
            (type) => type.id === 63 || type.id === 64
          );
          setServiceTypes(types);
          if (types.length > 0) {
            handleServiceTypeChange(types[0].id);
          } else {
            let errorMessageProps = {
              heading: "Error",
              message:
                "Service Types for selected vehicle does not include TIME-BASED SERVICES or MILEAGE INTERVALS.",
              type: "error",
            };
            triggerCustomUxCriticalError(errorMessageProps, dispatch);
            closePopup();
          }
        } else {
          closePopup();
        }
      });
    } catch (error) {}
  };

   /*due to react bootstrap next direct state updated value not able to access inside the formatter so reconstructing again*/
   useEffect(() => {
    if(!cartApiFetching) {
      setTimeout(() => {
        if(!_isEmpty(cartData) && !_isEmpty(cartData.checkoutDetails)) {
          setCartData(cartData);
          constructCatalogData(partCatalogExpandRows, cartData);
        }
      })
    }
  }, [cartApiFetching, cartData.checkoutDetails]);

  useEffect(() => {
    initialGetServiceTypes();
  }, []);

  useEffect(() => {
    return () => {
      console.log("useEffect cleaned up");
    };
  }, []);
  useEffect(() => {
    if(updatedCart) {
      setTimeout(() => {
        if(!_isEmpty(cartData) && !_isEmpty(cartData.checkoutDetails)) {
          setCartData(cartData);
          constructCatalogData(partCatalogExpandRows, cartData);
        }
      })
    }
  }, [updatedCart]);

  const resetSelections = () => {
    uniqueServiceLegends.clear();
    setUniqueServiceLegends(new Set([]));
    setServiceTableColumns([]);
    setServiceTableRows([]);
    setServiceLegends([]);
    setIntervalListOptions([]);
    setDrivingConditions([]);
    setShowSpecificConditions(false);
    setShowCtlgSpecificConditions(false);
    setSelectedSpecificConditions([]);
    setSelectedCtlgSpecificConditions([]);
    setCtlgRequestItems([]);
    setNoTableData(false);
    setDefaultValues({});
    setPreviousInterval(null);
    setNextInterval(null);
  };

  const handleServiceTypeChange = (id) => {
    resetSelections();
    setIntervalTypeId(id);
    const payload = {
      engineCode: selectedVehicle.engineId,
      makeCode: selectedVehicle.makeId,
      modelCode: selectedVehicle.modelId,
      year: selectedVehicle.year,
      subModel: 123, // TODO
      serviceTypeId: id,
      dispatch: dispatch,
    };
    try {
      const drivingConditionsAction = dispatch(getDrivingConditions(payload));
      drivingConditionsAction.then((action) => {
        if (action.payload && action.payload.length > 0) {
          const drivingCndns = action.payload.map((cndtn) => ({
            value: cndtn.id,
            label: cndtn.text,
          }));
          setDrivingConditions(drivingCndns);
          if (drivingCndns.length > 0) {
            defaultValues.intervalTypeId = id;
            defaultValues.serviceDriveConditionId = drivingCndns[0].value;
            defaultValues.driveCondId = drivingCndns[0];
            handleDrivingConditionsChange(null, null);
          }
        } else {
          closePopup();
        }
      });
    } catch (error) {}
  };

  const findIndexOfClosestInterval = (intervalValue) => {
    let intervalIndex = defaultValues.intrvlLst.findIndex(
      (intvl) => parseInt(intvl.label) === intervalValue
    );
    return intervalIndex;
  };

  const getTimeIntervalDefault = () => {
    let date = new Date();
    let sixMonths = 6;
    let indexFound = -1;
    const ageOfVehicleInMonths =
      (date.getFullYear() - parseInt(selectedVehicle.year)) * 12;

    indexFound = findIndexOfClosestInterval(ageOfVehicleInMonths);
    if (indexFound >= 0) {
      return indexFound;
    }

    let later = ageOfVehicleInMonths + sixMonths;
    let previous = ageOfVehicleInMonths - sixMonths;

    while (previous > 0) {
      indexFound = findIndexOfClosestInterval(later);
      later += sixMonths;
      if (indexFound >= 0) {
        break;
      }
      indexFound = findIndexOfClosestInterval(previous);
      previous -= sixMonths;
      if (indexFound >= 0) {
        break;
      }
    }
    return indexFound;
  };

  const handleDrivingConditionsChange = (selectedOption, selected) => {
    const { value, label } =
      defaultValues.driveCondId != null
        ? defaultValues.driveCondId
        : selectedOption;
    setSelectedDriveCond({
      driveCondId: value,
      driveCondIdText: label,
    });

    const payload = {
      engineCode: selectedVehicle.engineId,
      makeCode: selectedVehicle.makeId,
      modelCode: selectedVehicle.modelId,
      year: selectedVehicle.year,
      subModel: 123, // TODO
      serviceTypeId: defaultValues.intervalTypeId
        ? defaultValues.intervalTypeId
        : intervalTypeId,
      serviceDriveConditionId: value,
      kilometerFlag: "true", // TODO
      dispatch: dispatch,
    };
    try {
      const serviceIntervalsAction = dispatch(getServiceIntervals(payload));
      serviceIntervalsAction.then((action) => {
        let intrvlLst = [];
        if (action.payload && action.payload.length > 0) {
          intrvlLst = action.payload.map((intrvl) => ({
            value: intrvl.serviceIntervalId,
            label: intrvl.serviceIntervalText,
          }));
          setIntervalListOptions(intrvlLst);
          setIntervalList(action.payload);
        }

        if (intrvlLst.length > 0) {
          defaultValues.intervalId = intrvlLst[0].value;
          defaultValues.intrvlLst = intrvlLst;
          let intervalIndex = getTimeIntervalDefault();
          if (intervalIndex >= 0) {
            handleIntervalChange(intrvlLst[intervalIndex], null);
          } else {
            handleIntervalChange(intrvlLst[0], null);
          }
        }
      });
    } catch (error) {}
  };

  const handleIntervalChangePrev = () => {
    handleIntervalChange(null, previousInterval);
  };

  const handleIntervalChangeNext = () => {
    handleIntervalChange(null, nextInterval);
  };

  const handleIntervalChange = (selectedOption, navSelectedOption) => {
    const { value, label } = navSelectedOption
      ? navSelectedOption
      : selectedOption;
    setIntervalId(value);

    let intvlList = defaultValues.intrvlLst
      ? defaultValues.intrvlLst
      : intervalListOptions;
    let intervalIndex = _findIndex(intvlList, {
      value: value,
    });

    const prevInterval =
      intervalIndex === 0 ? null : intvlList[intervalIndex - 1];
    setPreviousInterval(prevInterval);
    const nextInterval =
      intervalIndex === intvlList.length - 1
        ? null
        : intvlList[intervalIndex + 1];
    setNextInterval(nextInterval);

    const specificCondtnsPayload = {
      engineCode: selectedVehicle.engineId,
      makeCode: selectedVehicle.makeId,
      modelCode: selectedVehicle.modelId,
      year: selectedVehicle.year,
      subModel: 123, // TODO
      serviceTypeId:
        defaultValues.intervalTypeId != null
          ? defaultValues.intervalTypeId
          : intervalTypeId,
      serviceDriveConditionId:
        defaultValues.serviceDriveConditionId != null
          ? defaultValues.serviceDriveConditionId
          : selectedDriveCond.driveCondId,
      dispatch: dispatch,
    };
    try {
      const specificCondtnsAction = dispatch(
        getModernServiceSpecificConditions(specificCondtnsPayload)
      );
      specificCondtnsAction.then((action) => {
        if (action.payload && action.payload.length > 0) {
          setDefaultSpecificConditions(action.payload, value);
        } else {
          getServiceDataHandler(null, value);
        }
      });
    } catch (error) {}
  };

  const closeSpecificConditionsPopup = () => {
    setShowSpecificConditions(false);
  };

  const getRowDataFromSubOperation = (data) => {
    let serviceItem = "";
    let keyword = "";
    const items = data.subOperation.split(" ");
    keyword = items[0];
    let index = 0;
    if (items[1] && items[2] && items[1] === "&") {
      // TODO : use iteration
      keyword += " " + items[1] + " " + items[2];
      index = 2;
    }
    if (items.length - 1 > index) {
      serviceItem = items.slice(index + 1, items.length).join(" ");
    }
    uniqueServiceLegends.add(keyword);
    console.log(
      "uniqueServiceLegends : " + JSON.stringify(uniqueServiceLegends)
    );
    setUniqueServiceLegends(uniqueServiceLegends);
    return {
      serviceItem: serviceItem,
      recordType: data.type ? data.type : "",
      [keyword]: true,
      description: data.description ? data.description : "",
    };
  };

  const getRowDataFromCombinationObject = (data) => {
    const serviceItem = data.combinationObject;
    const keyword = data.combinationVerb;
    uniqueServiceLegends.add(keyword);
    console.log(
      "uniqueServiceLegends : " + JSON.stringify(uniqueServiceLegends)
    );
    setUniqueServiceLegends(uniqueServiceLegends);
    return {
      serviceItem: serviceItem,
      recordType: data.type ? data.type : "",
      [keyword]: true,
      description: data.description ? data.description : "",
    };
  };

  const getRowDataFromSellText = (data) => {
    let serviceItem = "";
    let keyword = "";
    const items = data.sellText.split(" ");
    keyword = items[0];
    let index = 0;
    if (items[1] && items[2] && items[1] === "&") {
      // TODO : use iteration
      keyword += " " + items[1] + " " + items[2];
      index = 2;
    }
    if (items.length - 1 > index) {
      serviceItem = items.slice(index + 1, items.length).join(" ");
    }
    uniqueServiceLegends.add(keyword);
    console.log(
      "uniqueServiceLegends : " + JSON.stringify(uniqueServiceLegends)
    );
    setUniqueServiceLegends(uniqueServiceLegends);
    return {
      serviceItem: serviceItem,
      recordType: data.type ? data.type : "",
      [keyword]: true,
      description: data.description ? data.description : "",
    };
  };

  const getRowData = (data) => {
    if (data.subOperation) {
      return getRowDataFromSubOperation(data);
    }
    if (data.combinationObject && data.combinationVerb) {
      return getRowDataFromCombinationObject(data);
    }
    if (data.sellText && !data.perCarQtyText) {
      return getRowDataFromSellText(data);
    }
    return null;
  };

  const getServiceDataHandler = (e, selectedInterval) => {
    const modernServicePayload = {
      engineCode: selectedVehicle.engineId,
      makeCode: selectedVehicle.makeId,
      modelCode: selectedVehicle.modelId,
      year: selectedVehicle.year,
      subModelCode: 123, // TODO
      serviceTypeId:
        defaultValues.intervalTypeId !== undefined
          ? defaultValues.intervalTypeId
          : intervalTypeId,
      serviceDriveConditionId:
        defaultValues.serviceDriveConditionId !== undefined
          ? defaultValues.serviceDriveConditionId
          : selectedDriveCond.driveCondId,
      serviceIntervalId: selectedInterval ? selectedInterval : intervalId,
      specificConditions:
        selectedSpecificConditions.length > 0 ? selectedSpecificConditions : [],
      dispatch: dispatch,
    };
    try {
      const modernServiceDataAction = dispatch(
        getModernServiceData(modernServicePayload)
      );
      setApiCall(true);
      modernServiceDataAction.then((action) => {
        setApiCall(false);
        setNonExpandableRows([]);
        if (action.payload) {
          const requestItems = [];
          ctlgRequestItems.length = 0;
          action.payload.map((data) => {
            if (data.type == "CatalogPartRecItem") {
              let singleItem = {
                descName: data.description,
              };
              requestItems.push(singleItem);
              ctlgRequestItems.push(singleItem);
            }
          });

          if (!isEmpty(requestItems)) {
            setCtlgRequestItems(requestItems);
            getCatalogDataHandler(requestItems);
            setenableservicetable(false);
          } else {
            setenableservicetable(true);
          }

          setServiceData(action.payload);
          constructGridAndLegendsData(action.payload);
          setShowSpecificConditions(false);
        }
      });
    } catch (error) {
      setApiCall(false);
    }
  };

  const setDefaultSpecificConditions = (
    specificConditionsFull,
    intervalValue
  ) => {
    const specificConditionsList = [];
    specificConditionsFull.map((qstn, index) => {
      let isSelectedConditnExistsInCache =
        selectedSpecificConditionsCached.find((condn) => {
          return condn.questionCode === qstn.code;
        });

      if (isSelectedConditnExistsInCache) {
        let selectedAnswer = qstn.sipossibleAnswers.find((answer) => {
          return answer.code === isSelectedConditnExistsInCache.answerCode;
        });
        handleSelectedSpecificConditions(null, qstn, selectedAnswer);
      } else {
        specificConditionsList.push(qstn);
        let defaultAnswer = qstn.sipossibleAnswers.find((answer) => {
          return answer.code === "N";
        });
        handleSelectedSpecificConditions(null, qstn, defaultAnswer);
      }
    });
    if (!_isEmpty(specificConditionsList)) {
      setShowSpecificConditions(true);
      setSpecificConditions(specificConditionsList);
    } else {
      getServiceDataHandler(null, intervalValue);
    }
  };

  const setCtlgDefaultSpecificConditions = (ctlgSpecificConditionsFull) => {
    ctlgSpecificConditionsFull.map((qstn, index) => {
      let defaultAnswer = qstn.partPossibleAnswers.find((answer) => {
        return answer.code === "N";
      });
      handleSelectedCtlgSpecificConditions(null, qstn, defaultAnswer);
    });
    if (!_isEmpty(ctlgSpecificConditionsFull)) {
      setShowCtlgSpecificConditions(true);
      setCtlgSpecificConditions(ctlgSpecificConditionsFull);
    }
  };

  const handleSelectedSpecificConditions = (event, question, answer) => {
    const selectedCondtn = {
      answerCode: answer.code,
      answerText: answer.text,
      questionCode: question.code,
      questionText: question.text,
    };

    let isSelectedConditnExists = selectedSpecificConditions.find((condn) => {
      return condn.questionCode === question.code;
    });

    if (isSelectedConditnExists) {
      isSelectedConditnExists.answerCode = answer.code;
      isSelectedConditnExists.answerText = answer.text;
    } else {
      selectedSpecificConditions.push(selectedCondtn);
    }
    let isSelectedConditnExistsInCache = selectedSpecificConditionsCached.find(
      (condn) => {
        return condn.questionCode === question.code;
      }
    );

    if (isSelectedConditnExistsInCache) {
      isSelectedConditnExistsInCache.answerCode = answer.code;
      isSelectedConditnExistsInCache.answerText = answer.text;
    } else {
      selectedSpecificConditionsCached.push(selectedCondtn);
    }
  };

  const handleSelectedCtlgSpecificConditions = (event, question, answer) => {
    const selectedCondtn = {
      code: question.code,
      answer: answer.code,
    };
    let selSpecCndtns = _cloneDeep(selectedCtlgSpecificConditions);
    let isSelectedConditnExists = selSpecCndtns.find((condn) => {
      return condn.code === question.code;
    });

    if (isSelectedConditnExists) {
      isSelectedConditnExists.answer = answer.code;
    } else {
      selSpecCndtns.push(selectedCondtn);
    }
    setSelectedCtlgSpecificConditions(selSpecCndtns);
  };

  const constructGridAndLegendsData = (serviceDataFull, existingLegends) => {
    setNonExpandableRows([]);
    uniqueServiceLegends.clear();
    setNoTableData(false);
    setUniqueServiceLegends(new Set([]));
    setServiceTableColumns([]);
    setServiceTableRows([]);
    if (!existingLegends) {
      setServiceLegends([]);
    }
    const rowData = [];
    const columns = [
      {
        dataField: "id",
        text: "id",
        hidden: true,
      },
      {
        dataField: "description",
        text: "description",
        hidden: true,
      },
      {
        dataField: "serviceItem",
        text: "Required Maintenance",
        formatter: requiredMaintColFormatter,
        headerStyle: {
          backgroundColor: "#0F5283",
          color: "#FFFFFF",
          "border-color": "#0F5283",
        },
      },
      {
        dataField: "recordType",
        text: "Record Type",
        formatter: recordTypeColFormatter,
        headerStyle: {
          backgroundColor: "#0F5283",
          color: "#FFFFFF",
          "border-color": "#0F5283",
        },
      },
    ];
    const legends = [];
    serviceDataFull.map((data, index) => {
      if (data.operationVerb !== "Perform") {
        const rData = getRowData(data);
        if (rData !== null) {
          rData.id = index;
          rowData.push(rData);
        }
      }
    });

    Array.from(uniqueServiceLegends).map((serviceLegend, index) => {
      console.log(serviceLegend);
      let legendColor;
      let isLegendChecked;
      if (!existingLegends) {
        legendColor = getRandomColor(30);
        isLegendChecked = true;
        legends.push({
          text: serviceLegend,
          color: legendColor,
          checked: isLegendChecked,
        });
      } else {
        console.log("existingLegends : " + JSON.stringify(existingLegends));
        console.log("text" + serviceLegend);
        let matchingLegend = existingLegends.find((item) => {
          return item.text === serviceLegend;
        });
        console.log("matchingLegend : " + JSON.stringify(matchingLegend));

        legendColor = matchingLegend.color;
        isLegendChecked = matchingLegend.checked;
      }
      columns.push({
        dataField: serviceLegend,
        text: serviceLegend,
        formatter: colFormatter,
        hidden: !isLegendChecked,
        headerStyle: {
          backgroundColor: "#0F5283",
          color: "#FFFFFF",
          "border-color": "#0F5283",
        },
        align: "center",
        headerAlign: "center",
      });

      rowData.map((row, index) => {
        if (!row[serviceLegend]) {
          row[serviceLegend] = false;
        } else {
          row["color"] = legendColor;
        }
      });
    });
    let nonExpRowArray = [];
    rowData.map((row, index) => {
      if (row.recordType != "CatalogPartRecItem") {
        nonExpRowArray.push(row.id);
        setNonExpandableRows(nonExpRowArray);
      }
    });
    setServiceTableColumns(columns);
    setServiceTableRows(rowData);
    if (rowData.length == 0) {
      setNoTableData(true);
    }
    if (!existingLegends) {
      setServiceLegends(legends);
    }
    console.log(JSON.stringify(columns));
    console.log(JSON.stringify(rowData));
    console.log(JSON.stringify(legends));
  };

  const handleLegendAddRemoval = (event, index) => {
    serviceLegends[index].checked = event.target.checked;
    let colsData = [...serviceTableColumns];
    setServiceTableColumns([]);
    const timer = setTimeout(() => {
      let serviceTableColumn = colsData.find((column) => {
        return column.text === serviceLegends[index].text;
      });
      serviceTableColumn.hidden = !serviceLegends[index].checked;
      setServiceTableColumns(colsData);
    }, 100);
  };

  //we may need to change later
  const getStoreAvailibility = (val, availability) => {
    let { userDetails } = user;
    let defaultStore =
      userDetails &&
      userDetails.shipTos &&
      userDetails.shipTos[0] &&
      userDetails.shipTos[0].location &&
      userDetails.shipTos[0].location.storeNumber
        ? "20" + userDetails.shipTos[0].location.storeNumber
        : "";
    let dataArr = [];
    if (val == null) {
      return dataArr;
    }
    const defaultTotalValue = !_isEmpty(val.toString()) ? val : "1";
    let availabilityByStore = [];
    availability &&
      availability.map((singleAvailability) => {
        if (
          singleAvailability.availableDuration == "Your Store" ||
          singleAvailability.availableDuration == "Stores in Your Area"
        ) {
          singleAvailability.storeAvailabilities &&
            singleAvailability.storeAvailabilities.map(
              (singleStoreAvailability) => {
                availabilityByStore.push(singleStoreAvailability);
              }
            );
        } else {
          availabilityByStore.push(singleAvailability);
        }
      });
    let data = distributeAvailabilityByStore(
      defaultTotalValue,
      availabilityByStore
    );
    data.map((tempData) => {
      if (tempData.isStoreAvailability) {
        dataArr.push(
          <Fragment>
            <span>
              <span className="store-maroon-text avail-qty-width">
                {tempData.orderQty}
              </span>
              <span className="text-black tph-store-avail-align">
                {defaultStore == tempData.store
                  ? "Available at Your Store #"
                  : "Available at Store #"}
                <b>{tempData.store}</b>
              </span>
            </span>
          </Fragment>
        );
      } else {
        dataArr.push(
          <Fragment>
            <span>
              <span className="store-maroon-text avail-qty-width">
                {tempData.orderQty}
              </span>
              <span className="text-black tph-store-avail-align">
                {" "}
                Available <b>{tempData.availableDuration}</b>
              </span>
            </span>
          </Fragment>
        );
      }
    });
    return dataArr;
  };

  const handleInputChange = (event, row, rowIndex) => {
    let value;
    let previous;
    if (!_isEmpty(event)) {
      value = event.target.value;
    }
    value = Number(value);
    previous = Number(value);
    partCatalogExpandRows[rowIndex].extendedPrice = getExtendedTotalPrice(
      row.corePrice,
      row.yourPrice,
      value
    );
    partCatalogExpandRows[rowIndex].selectedQuantity = value;
    setTimeout(() => {
      setPreveousOrderQty(previous);
      constructCatalogData(partCatalogExpandRows);
    });
  };

  const qtyByINCUpdateSuccessMessage = () => {
    let statusMessageProps = {
      heading: "",
      message: "",
      type: "",
    };
    statusMessageProps.heading = displayQtyChangeMsg();
    statusMessageProps.type = "success";
    triggerCustomUxCriticalError(statusMessageProps, dispatch);
  };

  const getShipDetails = () => {
    let shipMethod = getCartShipDetails(user.userDetails, checkoutDetails).shipMethod
    let storeNumber = getCartShipDetails(user.userDetails, checkoutDetails).storeNumber
    let sellerPartnerId = getCartShipDetails(user.userDetails, checkoutDetails).sellerPartnerId;

        return {
          shipMethod: shipMethod,
          storeNumber: storeNumber,
          sellerPartnerId: sellerPartnerId,
        };
      };

  const handleAvailabilitySelection = (event, row, rowIndex) => {
    selectedAvailabilityHours.length = 0;
    let shipInfo = getShipInfo(user.userDetails);
    let {
      target: { value },
    } = event;
    let { shipTos } = user.userDetails;
    let shipMethod = getShipInfo(user.userDetails, parts).shipMethod
      ? getShipInfo(user.userDetails, parts).shipMethod
      : shipTos && shipTos[0] && shipTos[0].shipMethod && shipTos[0].shipMethod;
    let storeNumber = getShipInfo(user.userDetails, parts).storeNumber
      ? getShipInfo(user.userDetails, parts).storeNumber
      : shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.storeNumber &&
        shipTos[0].location.storeNumber;
    let sellerPartnerId = getShipInfo(user.userDetails, parts).sellerPartnerId
      ? getShipInfo(user.userDetails, parts).sellerPartnerId
      : shipTos &&
        shipTos[0] &&
        shipTos[0].location &&
        shipTos[0].location.sellerPartnerId &&
        shipTos[0].location.sellerPartnerId;

    let dataArr = [];
    let selectedAvailabilityHrs = [];

    let totalAvailqty = 0;
    let uniqueData = _uniqBy(row.availabilityAll, "availableDuration");
    uniqueData.map((singleAvailability, key) => {
      if (singleAvailability.availableQty > 0) {
        totalAvailqty += singleAvailability.availableQty;
      }
    });

    let previous = preveousOrderQty;
    if (!_isEmpty(value)) {
      if (!_isEmpty(value) && row.qtyBuyInc > 1 && Number(value) !== "") {
        value = getPartPackQty(row.qtyBuyInc, value, totalAvailqty);
        if (
          previous != value &&
          row.qtyBuyInc > 1 &&
          !(Number(event.target.value) === value) &&
          Number(value) !== "" &&
          row.totalAvailable > event.target.value
        ) {
          qtyByINCUpdateSuccessMessage();
          previous = value;
        }
      } else {
        value = Number(value);
        previous = Number(value);
      }
      setPreveousOrderQty(previous);
    }
    let defaultTotalValue = !_isEmpty(value.toString()) ? value : null;
    let availabilityArray = distributeAvailability(
      defaultTotalValue,
      row.availabilityAll
    );

    let distributionData = getDistributionForVehicle(
      row.partNumber,
      row,
      row.availabilityAll,
      defaultTotalValue,
      sv,
      cart
    );
    let orderArray = distributeAvailability2(
      Number(distributionData.totalQty),
      distributionData.filteredAvailability
    );
    selectedAvailabilityHours.push(...distributionData.alreadyAddedItem);

    orderArray.map((item) => {
      selectedAvailabilityHours.push({
        availabilityHour: item.availableDuration,
        availableQty: item.availableQty,
        cartFlag: "no",
        // categoryId: Number(
        // 	_find(categoryGroupMap, (c) => {
        // 		return _includes(
        // 			_map(c.groups, 'code'),
        // 			Number(partDetails.groupNumber)
        // 		);
        // 	}).code
        // ),
        //categoryId: 10,
        costPrice: row.yourPrice,
        corePrice: row.corePrice,
        coreListPrice: row.coreListPrice,
        desc: row.description,
        groupId: row.groupNumber,
        listPrice: row.listPrice,
        //mfgCode: row.lineCodes,
        mfgCode: row.pricingAvailability.mfgCode,
        packQty: row.pricingAvailability.packQty,
        partNum: row.partNumber,
        qty: item.orderQty,
        vehicleInfo: `${sv.year} ${sv.make} ${sv.model} ${sv.engine}`,
        shipMethod: shipMethod,
        store: storeNumber,
        sellPartnerId: sellerPartnerId,
        distStatus: "Completed",
        orderType: "REGULAR"
      });
    });

    let availabilityOptions = [];
    availabilityArray.map((item) => {
      const optionText = `${item.availableQty} Within ${formatTimeAvb(
        item.availableDuration
      )}`;
      availabilityOptions.push(optionText);
    });

    // let totalAvailqty = 0;
    // orderArray.map((item) => {
    //   const optionText = item.availableQty;
    //   totalAvailqty += optionText;
    // });
    availabilityOptions = [];
    availabilityOptions = getStoreAvailibility(
      defaultTotalValue,
      row.pricingAvailability && row.pricingAvailability.availability
    );

    row.selectedQuantity = value;
    partCatalogExpandRows[rowIndex].selectedQuantity = value;
    partCatalogExpandRows[rowIndex].availabilityForOrderQty = availabilityArray;
    partCatalogExpandRows[rowIndex].totalAvailable = totalAvailqty;
    partCatalogExpandRows[rowIndex].extendedPrice = getExtendedTotalPrice(
      row.corePrice,
      row.yourPrice,
      value
    );
    constructCatalogData(partCatalogExpandRows);
    setUpdatedCart(true);
    setSelectedAvailabilityHours(selectedAvailabilityHours);
  };

  const handleKeyDown = (event) => {
    const qtyValue = event.target.value;
    if (
      (numbersOnlyRegExp(event.key) === false ||
        (qtyValue && qtyValue.length >= 4) ||
        event.keyCode === 32) &&
      handleOtherKeys(event.keyCode)
    ) {
      event.preventDefault();
    }
  };

  const handleOtherKeys = (code) => {
    // KeyCode- Backspace, Tab, Delete, Arrow Up, Arrow Down, Arrow Left, Arrow Right
    return (
      code !== 8 &&
      code !== 9 &&
      code !== 37 &&
      code !== 38 &&
      code !== 39 &&
      code !== 40 &&
      code !== 46
    );
  };

  const onclick = (tempData) => {
    setStoreData(tempData)
    setShowAvailabilityStoreView(true)
  }

  const availabilityColFormatter = (cell, row) => {
    let shipDetails = getShipDetails();
    let availabilitySection = [];
    if (row.availabilityAll.length === 0) {
      return (
        <span className="availability_section">Currently out of stock</span>
      );
    }
    availabilitySection = getAvailability(row, row.availabilityAll,{}, {}, userDetails, "TablePop",onclick, row.selectedQuantity, shipDetails.storeNumber)
    return availabilitySection;
  };

  const headerStyle = {
    backgroundColor: "#0f5283",
    color: "white",
  };

  const QtyOrderStyle = {
    backgroundColor: "#0f5283",
    color: "white",
    "z-index": "999",
  };

  const descriptionHeaderStyle = {
    backgroundColor: "#0f5283",
    color: "#FFFFFF",
    width: "210px",
  };

  const requiredMaintColFormatter = (cell, row) => {
    let tooltipText = "Click here to see parts for this maintenance activity";
    if (row.recordType == "CatalogPartRecItem")
      return (
        <div>
          <span>{row.serviceItem}</span>
          <span title={``} className="popup-catalog-page">
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id={`tooltip`}>{tooltipText}</Tooltip>}
            >
              <img
                src="/public_images/invoice.png"
                alt="invoice"
                width="20"
                height="20"
                onClick={() => catalogPartLinkClick(row, cartData)}
              />
            </OverlayTrigger>
          </span>
        </div>
      );
    return row.serviceItem;
  };

  const constructCatalogData = (rows, cart = {}) => {
    // setPartCatalogExpandColumns([]);
    //setPartCatalogExpandRows([]);
    setUpdatedCart(true);
    let columns = [
      {
        dataField: "lineCodes",
        text: "P/L",
        sort: true,
        headerAlign: "center",
        headerStyle: headerStyle,
      },
      {
        dataField: "partImages",
        text: "partImages",
        hidden: true,
      },
      {
        dataField: "totalAvailable",
        text: "totalAvailable",
        hidden: true,
        formatter: function (cell, row, index) {},
      },
      {
        dataField: "qtyBuyInc",
        text: "Qty Buy Inc",
        hidden: true,
      },
      {
        dataField: "partNumber",
        headerAlign: "center",
        text: "Item #",
        sort: true,
        classes: "bootstrap-table-bold-cell",
        headerStyle: headerStyle,
        formatter: function (cell, row, index) {
          let image =
            row.partImages && row.partImages[0] && row.partImages[0].url
              ? row.partImages
                ? `${row.partImages[0].url}?${imageURLCookie}`
                : "/noImage.jpg"
              : "/noImage.jpg";

          return (
            <div className="product-image-container float-left d-flex">
              <div>
                <span className="product-image-container table-view-margin">
                  <div className="image-thumbnail-container-vehicle-maint">
                    <Image
                      className={
                        row.partImages
                          ? "image-thumbnail image-cursor vehiclemaintenance-img"
                          : ""
                      }
                      src={image}
                    />
                  </div>
                  {
                    row.partNumber &&
                      row.partNumber.length > 0 &&
                      row.partNumber
                    // <p>
                    //   {row.partNumber}
                    // </p>
                  }
                </span>
              </div>
            </div>
          );
        },
      },
      {
        dataField: "description",
        text: "Description",
        headerAlign: "center",
        sort: true,
        classes: "bootstrap-table-bold-cell",
        headerStyle: descriptionHeaderStyle,
        formatter: function (cell, row, index) {
          return (
            <div className="compact-table-description-row">
              <div className="col-one">
                {(row.isSubType || row.isAltType) && (
                  <span className="maroonColorTxt pr-1">
                    {row.isSubType
                      ? "<SUPERSEDED>"
                      : row.isAltType
                      ? "<ALTERNATE>"
                      : ""}
                  </span>
                )}
                <span className="">{`${row.description.substring(
                  0,
                  20
                )}`}</span>
              </div>
              <div className="col-two family-price-icon-hide">
                <PricingIcons toolTips={row.pricingAvailability.toolTips} />
              </div>
            </div>
          );
        },
      },
      {
        dataField: "pricingAvailability",
        text: "pricingAvailability",
        hidden: true,
      },
      {
        dataField: "groupNumber",
        text: "groupNumber",
        hidden: true,
      },
      {
        dataField: "isSubType",
        text: "isSubType",
        hidden: true,
      },
      {
        dataField: "isAltType",
        text: "isAltType",
        hidden: true,
      },
      {
        dataField: "yourPrice",
        text: "Your Price",
        hidden: hideAllPrice,
        headerAlign: "center",
        sort: true,
        classes: "bootstrap-table-bold-cell",
        headerStyle: headerStyle,
        formatter: function (cell, row) {
          return (
            <span className="maroonColorTxt">
              ${formatAmount(row.yourPrice)}
            </span>
          );
        },
      },
      {
        dataField: "corePrice",
        text: "Core Price",
        hidden: hideAllPrice,
        headerAlign: "center",
        sort: true,
        classes: "bootstrap-table-bold-cell",
        headerStyle: headerStyle,
        formatter: function (cell, row) {
          return (
            <span className="maroonColorTxt">
              ${formatAmount(row.corePrice)}
            </span>
          );
        },
      },
      {
        dataField: "listPrice",
        headerAlign: "center",
        text: "List Price",
        hidden: hideAllPrice,
        sort: true,
        headerStyle: headerStyle,
        classes: "bootstrap-table-bold-cell",
        formatter: function (cell, row) {
          return (
            <span className="maroonColorTxt">
              ${formatAmount(row.listPrice)}
            </span>
          );
        },
      },
      {
        dataField: "selectedQuantity",
        text: "Qty to Order",
        classes: "bootstrap-table-bold-cell",
        align: "center",
        sort: true,
        headerAlign: "center",
        headerStyle: QtyOrderStyle,
        //formatter:orderQtyColFormatter
        formatter: function (cell, row, index) {
          if (row.qty == 1) {
            setEnableOk(false);
          }
          return (
            <Fragment>
              <span className="tableview-order">
                <OverlayTrigger
                  style={{ "z-index": "999" }}
                  placement="right"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    row.selectedQuantity > row.totalAvailable ? (
                      <Tooltip
                        id="description-part-catalog-tooltip"
                        className="tooltip-description"
                      >
                        <span className="font-weight-bold">
                          Please enter a quantity less than or equal to{" "}
                          {row.totalAvailable}
                        </span>
                      </Tooltip>
                    ) : (
                      <span></span>
                    )
                  }
                >
                  <span className="tableview-order">
                    <input
                      autoFocus="autofocus"
                      className=""
                      type="text"
                      name="selectedQuantity"
                      size="3"
                      value={row.selectedQuantity}
                      disabled={row.availabilityAll.length === 0}
                      onChange={(e) =>  handleInputChange(e, row, index)}
                      onBlur={(e) => handleAvailabilitySelection(e, row, index)}
                      onMouseLeave={(e) =>
                        handleAvailabilitySelection(e, row, index)
                      }
                      onKeyDown={(event) => {
                        handleKeyDown(event);
                      }}
                    />
                  </span>
                </OverlayTrigger>
              </span>
              <span class="table-add-to-cart-button vehicle-maintanace">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 100, hide: 400 }}
                  overlay={
                    <Tooltip
                      id="add-to-cart-tooltip-chemical"
                      className="tooltip-add-to-cart"
                    >
                      {`Add to Cart`}
                    </Tooltip>
                  }
                >
                  <Button
                    type="button"
                    disabled={
                      row.selectedQuantity === 0 ||
                      row.availabilityAll.length === 0 ||
                      row.selectedQuantity === "" ||
                      row.selectedQuantity == undefined ||
                      row.selectedQuantity > row.totalAvailable
                    }
                    onClick={(e) => addToCart(e, index, row)}
                    class="btn btn-primary vehicle-maintanace-addtocart-btn"
                  >
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                  </Button>
                </OverlayTrigger>
              </span>
            </Fragment>
          );
        },
      },
      {
        dataField: "extendedPrice",
        text: "Extended\n Total\n Price",
        headerStyle: headerStyle,
        headerAlign: "center",
        hidden: hideAllPrice,
        align: "center",
        classes: "bootstrap-table-bold-cell",
        headerFormatter: (column, colIndex) => {
          return (
            <div>
              <span>Total Extended Price</span>
              &nbsp;&nbsp;
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 400 }}
                overlay={
                  <Tooltip
                    id="extended-price-tooltip"
                    className="custom-tooltip-availability"
                  >
                    <span className="font-weight-bold">
                      Total Extended Price = Qty * (Your Price + Core Price)
                    </span>
                  </Tooltip>
                }
              >
                <i class="fa fa-info-circle" style={{ fontSize: "19px" }}></i>
              </OverlayTrigger>
            </div>
          );
        },
        formatter: function (cell, row, index) {
          if (row.selectedQuantity > 0) {
            return (
              <div className="" id={`extendedprice-${index}`}>
                <span
                  className="maroonColorTxt"
                  id={`extendedpriceText-${index}`}
                >
                  $
                  {getExtendedTotalPrice(
                    row.corePrice,
                    row.yourPrice,
                    row.selectedQuantity
                  )}
                </span>
              </div>
            );
          }

          return <span></span>;
        },
      },
      {
        dataField: "availabilityAll",
        text: "Availability",
        hidden: true,
      },
      {
        dataField: "availabilityForOrderQty",
        text: "Availability",
        headerStyle: headerStyle,
        headerAlign: "center",
        formatter: availabilityColFormatter,
      },
      {
        dataField: "selectedAvailabilityHours",
        text: "Selected Availability",
        hidden: true,
      },
      {
        dataField: "descId",
        hidden: true
      },
      {
        dataField: "cartData",
        hidden: true
      }
    ];
    rows.map((value) => {
      value.cartData = cart;
   })
    setPartCatalogExpandColumns(columns);
    setPartCatalogExpandRows(rows);
  };

  const preparePartCatalogSingleRows = (rows) => {
    partCatalogExpandRows.length = 0;
    rows.map((value, index) => {
      value.selectedQuantity = "";
      value.availabilityForOrderQty = [];
      partCatalogExpandRows.push(value);
    });
  };

  const catalogPartLinkClick = (row, cartData) => {
    let ProductInfo = _.groupBy(partCatalogRows, function (value) {
      return value.genericName;
    });
    if (ProductInfo && !_isEmpty(ProductInfo[row.description])) {
      partCatalogExpandRows.length = 0;
      setTimeout(() => {
        preparePartCatalogSingleRows(ProductInfo[row.description]);
        constructCatalogData(ProductInfo[row.description], cartData);
        setUpdatedCart(true);
      });
      setAddCartPopupTitle(row.serviceItem);
      setshowDeleteModal(true);
    }
  };

  const addToCart = (e, index, row) => {
    let shipDetails = getShipDetails();
    let cartInfo = row.cartData &&   row.cartData.checkoutDetails && row.cartData.checkoutDetails.length > 0 ? row.cartData : cartData;
    selectedAvailabilityHours.length = 0;
    let distributionData = getDistributionForVehicle(
      row.partNumber,
      row,
      row.availabilityAll,
      row.selectedQuantity,
      sv,
      cartInfo
    );
    let orderArray = distributeAvailability2(
      Number(distributionData.totalQty),
      distributionData.filteredAvailability
    );
    selectedAvailabilityHours.push(...distributionData.alreadyAddedItem);
    orderArray.map((item) => {
      selectedAvailabilityHours.push({
        availabilityHour: item.availableDuration,
        availableQty: item.availableQty,
        cartFlag: "no",
        costPrice: row.yourPrice,
        corePrice: row.corePrice,
        coreListPrice: row.coreListPrice,
        desc: row.description,
        descId: row.descId ? row.descId : "",
        groupId: row.groupNumber,
        listPrice: row.listPrice,
        qtyBuyInc: row.qtyBuyInc,
        mfgCode: row.pricingAvailability.mfgCode,
        packQty: row.pricingAvailability.packQty,
        qtyPerVeh: row.pricingAvailability.qtyPerVeh,
        partNum: row.partNumber,
        qty: item.orderQty,
        categoryId: Number(
          _find(categoryGroupMp, (c) => {
            return _includes(_map(c.groups, "code"), Number(row.groupNumber));
          }).code
        ),
        engineId: sv.engineId,
        engine: sv.engine,
        yearId: sv ? sv.year : "",
        makeId: sv.makeId,
        internalMakeId: sv.internalMakeId,
        make: sv.make,
        modelId: sv.modelId,
        model: sv.model,
        vehicleInfo: `${sv.year} ${sv.make} ${sv.model} ${sv.engine}`,
        shipMethod: shipDetails.shipMethod,
        store: shipDetails.storeNumber,
        sellPartnerId: shipDetails.sellerPartnerId,
        distStatus: "Completed",
        orderType: "REGULAR"
      });
    });
    let selectedAvailabilityHourss = _cloneDeep(selectedAvailabilityHours);
    setAddToCartPayload(selectedAvailabilityHourss);
    let { model, modelId, make, makeId, engine, engineId, year } =
      mygarage.selectedVehicle || {};
    let {
      userDetails: { x2userName, userId, shipTos, billTo },
    } = user;
    let source = "partCategory";
    if (isStockOrder(orderType) || deliveryPickupWarning(user.userDetails, parts)) {
      setaddToCartWarningPopup(true);
    } else {
    if (!_isEmpty(selectedAvailabilityHourss)) {
      addItemToCart2(selectedAvailabilityHourss, dispatch, true, user, "vehiclemaintance");
      let uniqsah = _uniqBy(selectedAvailabilityHourss, "descId");
      uniqsah.map((s, k) => {
        const payload = {
          categoryId: Number(
            _find(categoryGroupMp, (c) => {
              return _includes(_map(c.groups, "code"), Number(s.groupId));
            }).code
          ),
          createdBy: x2userName,
          createdDate: timeInUTC(),
          customerId: billTo.billToId,
          engine: engine.trim(),
          engineId,
          groupId: s.groupId,
          lastOrderedDate: timeInUTC(),
          make: make.trim(),
          makeId,
          model: model.trim(),
          modelId,
          orderedFlag: "N",
          partTypeDesc: s.desc,
          partTypeDescId: s.descId,
          searchPartId: "",
          shipToId: shipTos[0].shipToId,
          source,
          updatedBy: x2userName,
          updatedDate: timeInUTC(),
          userFilter: "selected",
          year: year.trim(),
        };
        dispatch(setPartHistory(payload));
      });
    }
    }
    //selectedAvailabilityHours.length = 0;
  };

  const getCatalogDataHandler = (requestItems) => {
    const requestParams = {
      year: selectedVehicle.year,
      make: selectedVehicle.makeId,
      model: selectedVehicle.modelId,
      engine: selectedVehicle.engineId,
      dispatch: dispatch,
    };

    let specConditions = !_isEmpty(selectedCtlgSpecificConditions)
      ? selectedCtlgSpecificConditions
      : [];
    let reqItems = !_isEmpty(ctlgRequestItems)
      ? ctlgRequestItems
      : requestItems;
    const payload = {
      requestItems: {
        requestItems: reqItems,
        sellPartnerId: getCartShipDetails(userDetails, checkoutDetails).sellerPartnerId,
        shipMethod: getCartShipDetails(userDetails, checkoutDetails).shipMethod,
        store: getCartShipDetails(userDetails, checkoutDetails).storeNumber,
        specificCondition: specConditions,
      },
      params: requestParams,
    };

    setApiCall(true);
    try {
      const PartCatlogDataAction = dispatch(getPartCatlogData(payload));
      PartCatlogDataAction.then((action) => {
        setApiCall(false);
        if (
          action.payload &&
          action.payload.partItems &&
          !_isEmpty(action.payload.partItems)
        ) {
          getCatalogPartImages(action.payload.partItems);

          console.log("response: " + JSON.stringify(action.payload));
        }
        if (
          action.payload &&
          action.payload.specificConditions &&
          !_isEmpty(action.payload.specificConditions)
        ) {
          setCtlgDefaultSpecificConditions(action.payload.specificConditions);
        } else {
          setenableservicetable(true);
        }
      });
    } catch (error) {
      setApiCall(false);
    }
  };
  const getCatalogPartImages = (catalogPartDetails) => {
    let payload = [];
    try {
      catalogPartDetails.map((items, index) => {
        payload.push({
          partNumber: items.partNumber,
          supplier: items.mfgName,
        });
      });

      const PartImageDataAction = dispatch(getPartImages(payload));
      setApiCall(true);
      PartImageDataAction.then((action) => {
        setApiCall(false);
        if (action.payload) {
          console.log(action.payload);

          catalogPartDetails.map((catalogItem, index) => {
            action.payload.map((imageItem, index) => {
              if (imageItem.partNumber === catalogItem.partNumber) {
                catalogItem.images = imageItem.partImages;
              }
            });
          });
          prepareExpadRowDataForCatalogPart(catalogPartDetails);
          setShowCtlgSpecificConditions(false);
        }
      });
    } catch (error) {
      setApiCall(false);
    }
  };

  const prepareExpadRowDataForCatalogPart = (partItems) => {
    partCatalogRows.length = 0;
    let rows = [];
    partItems.map((value, index) => {
      partCatalogRows.push({
        isSubType: false,
        isAltType: false,
        genericName: value.genericDescription,
        partImages: value.images,
        lineCodes: value.lineCodes,
        partNumber: value.partNumber,
        pricingAvailability: value.pricingAvailability,
        groupNumber: value.groupNumber,
        description: value.description,
        yourPrice: value.pricingAvailability.costPrice,
        listPrice: value.pricingAvailability.listPrice,
        corePrice: value.pricingAvailability.corePrice,
        coreListPrice: value.pricingAvailability.coreListPrice,
        qtyBuyInc: value.pricingAvailability.qtyBuyInc,
        availabilityForOrderQty: null,
        availabilityAll: value.pricingAvailability.availability,
      });

      if (value.substitute.length > 0) {
        let substitutePart = value.substitute;
        value.substitute.map((sub) => {
          partCatalogRows.push({
            isSubType: true,
            isAltType: false,
            genericName: value.genericDescription,
            partImages: value.images,
            lineCodes: sub.lineCodes,
            partNumber: sub.partNumber,
            pricingAvailability: sub.pricingAvailability,
            groupNumber: sub.groupNumber,
            description: sub.description,
            corePrice: sub.pricingAvailability.corePrice,
            coreListPrice: sub.pricingAvailability.coreListPrice,
            yourPrice: sub.pricingAvailability.costPrice,
            listPrice: sub.pricingAvailability.listPrice,
            qtyBuyInc: value.pricingAvailability.qtyBuyInc,
            availabilityForOrderQty: null,
            availabilityAll: sub.pricingAvailability.availability,
          });
        });
      }

      if (value.alternate.length > 0) {
        let alternatePart = value.alternate;
        value.alternate.map((alt) => {
          partCatalogRows.push({
            isSubType: false,
            isAltType: true,
            genericName: value.genericDescription,
            partImages: value.images,
            lineCodes: alt.lineCodes,
            partNumber: alt.partNumber,
            pricingAvailability: alt.pricingAvailability,
            groupNumber: alt.groupNumber,
            description: alt.description,
            corePrice: alt.pricingAvailability.corePrice,
            coreListPrice: alt.pricingAvailability.coreListPrice,
            yourPrice: alt.pricingAvailability.costPrice,
            listPrice: alt.pricingAvailability.listPrice,
            qtyBuyInc: value.pricingAvailability.qtyBuyInc,
            availabilityForOrderQty: null,
            availabilityAll: alt.pricingAvailability.availability,
          });
        });
      }
    });
    setPartCatalogRows(partCatalogRows);
  };

  const getRecordTypeDesc = (recordType) => {
    let txt = "";
    switch (recordType) {
      case "CatalogPartRecItem":
        txt = "Generic catalog parts that could be looked up for a vehicle.";
        break;
      case "IncludedRecItem":
        txt = "Labor Items not related to replacement of parts.";
        break;
      case "InspectionRecItem":
        txt = "Items that should be inspected during the service.";
        break;
      case "NonCatalogPartRecItem":
        txt =
          "Generic non catalog parts ( chemicals and suppliers) that could be looked up for any vehicle.";
        break;
      case "NonReplaceRecItem":
        txt =
          "Labor items related to parts that are not for replacement. Ex: Rotate Tires.";
        break;
      case "ReplacementRecItem":
        txt = "Labor items related to replacement of parts.";
        break;
      case "ServiceRecItem":
        txt = "Gives the information about this service.";
        break;
      default:
        txt = "";
    }
    return txt;
  };

  const recordTypeColFormatter = (cell, row) => {
    let tooltipText = getRecordTypeDesc(row.recordType);
    let data = row.recordType
      .replace(/RecItem/g, "")
      .replace(/([a-z])([A-Z])/g, "$1 $2");
    return (
      <OverlayTrigger overlay={<Tooltip id={`tooltip`}>{tooltipText}</Tooltip>}>
        <span>{data}</span>
      </OverlayTrigger>
    );
  };

  const descColFormatter = (cell, row) => {
    return (
      <span>
        <b>{row.description}</b>
      </span>
    );
  };

  const colFormatter = (cell, row) => {
    if (cell) {
      const legendColor = row.color;
      return (
        <span
          style={{ background: legendColor, color: "white" }}
          className="material-icons"
        >
          done
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const randomChannel = (brightness) => {
    const r = 255 - brightness;
    const n = 0 | (Math.random() * r + brightness);
    const s = n.toString(16);
    return s.length == 1 ? "0" + s : s;
  };

  const getRandomColor = (brightness) => {
    return (
      "#" +
      randomChannel(brightness) +
      randomChannel(brightness) +
      randomChannel(brightness)
    );
  };

  // const getRandomColor = () => {
  //   const characters = "0123456789ABCDEF";
  //   let color = '#';
  //
  //   for (let i = 0; i < 6; i++) {
  //     color += characters[Math.floor(Math.random() * (15 - 0 + 1)) + 0];
  //   }
  //
  //   return color;
  // }
  const printComponent = useRef();
  const handlePrint = useReactToPrint({
    content: () => printComponent.current,
  });

  const closeProductInfoPopup = () => {
    selectedAvailabilityHours.length = 0;
    setshowDeleteModal(false);
  };

  return (
    <React.Fragment>
      {serviceTypes && serviceTypes.length ? (
        <XModal
          title={`VEHICLE MAINTENANCE - ${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`}
          show={openPopup}
          handleClose={closePopup}
          // handleAction={deleteQuoteHandler}ss
          showButtons={false}
          dialogClassName="vehicle-maintenance-modal "
        >
          <div className="print_icon d-flex">
            <span className="material-icons" onClick={handlePrint}>
              print
            </span>
            <span onClick={handlePrint}>Print</span>
          </div>
          <div
            className=" estimate_popup_container print_hide_container"
            ref={printComponent}
          >
            <div className="">
              <div className="print__logo">
                <img
                  align="center"
                  alt=""
                  src="/public_images/Print_Logo.png"
                  // src="public_images/XLPart_RAPID_Combined.png"
                />
              </div>
            </div>
            <div className="print__header">
              {`VEHICLE MAINTENANCE - ${selectedVehicle.year} ${selectedVehicle.make} ${selectedVehicle.model} ${selectedVehicle.engine}`}
            </div>

            <div className="">
              <Row>
                <Col sm={4}>
                  <h4 className="maintenance-sub-head">Service Types</h4>
                  <Row>
                    <Col>
                      <div className="radioWrapper">
                        {serviceTypes.map((serviceType, index) => {
                          return (
                            <Form.Check
                              className="bold"
                              inline
                              type="radio"
                              label={serviceType.text}
                              id={serviceType.id}
                              name="serviceTypes"
                              onChange={(e) => {
                                handleServiceTypeChange(e.target.id);
                              }}
                              checked={
                                serviceType.id === parseInt(intervalTypeId)
                              }
                              // defaultChecked={key === 0}
                            />
                          );
                        })}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      {drivingConditions && drivingConditions.length > 0 ? (
                        <div className="select-wrapper">
                          <div className="">
                            <Form.Label className="bold">
                              Driving Conditions
                            </Form.Label>
                            <Select
                              options={drivingConditions}
                              name="driveConds"
                              defaultValue={drivingConditions[0]}
                              onChange={handleDrivingConditionsChange}
                              value={drivingConditions.filter(
                                (drivingCond) =>
                                  drivingCond.value ===
                                  selectedDriveCond.driveCondId
                              )}
                            />
                          </div>
                        </div>
                      ) : null}

                      {intervalListOptions && intervalListOptions.length > 0 ? (
                        <div className="select-wrapper">
                          <div className="">
                            <Form.Label className="bold">
                              Service Intervals
                            </Form.Label>
                            <Select
                              options={intervalListOptions}
                              name="serviceInterval"
                              onChange={(e) => {
                                handleIntervalChange(e);
                              }}
                              value={intervalListOptions.filter(
                                (intervalOption) =>
                                  intervalOption.value === intervalId
                              )}
                            />
                          </div>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <Col sm={8}>
                  {serviceLegends && serviceLegends.length ? (
                    <Row>
                      <Col className="legends p-2">
                        <div className="legends div">
                          <dl>
                            {serviceLegends.map((serviceLegend, index) => {
                              const serviceLegendColor = serviceLegend.color;
                              return (
                                <React.Fragment>
                                  <div class="custom-control custom-checkbox custom-control-inline">
                                    <input
                                      type="checkbox"
                                      defaultChecked
                                      id={serviceLegend.text}
                                      class="custom-control-input"
                                      onChange={(e) => {
                                        handleLegendAddRemoval(e, index);
                                      }}
                                    />
                                    <label
                                      for={serviceLegend.text}
                                      title=""
                                      class="custom-check"
                                      style={{
                                        backgroundColor: serviceLegend.color,
                                      }}
                                    ></label>
                                    <label for={serviceLegend.text}>
                                      {serviceLegend.text}
                                    </label>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </dl>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  <div className="page-footer" id="footer">
                    {userDetails.x2userName}
                    <div className="page-footer_time" id="footer">
                      {timeInCST()}
                    </div>
                  </div>
                  <Row>
                    {showSpecificConditions &&
                    specificConditions &&
                    specificConditions.length > 0 ? (
                      <Col className="specific-cond-wrapper">
                        <Col>
                          <Row>
                            <h4>Please select a specific item to continue</h4>
                          </Row>
                          {specificConditions.map((question, key) => {
                            return (
                              <Row>
                                <span className="bold">{question.text}</span>
                                <div className="radioWrapper condition">
                                  {question.sipossibleAnswers.map(
                                    (answer, key) => {
                                      return (
                                        <Form.Check
                                          type="radio"
                                          className="bold"
                                          inline
                                          label={answer.text}
                                          id={answer.code}
                                          name={question.code}
                                          defaultChecked={answer.code === "N"}
                                          onChange={(e) => {
                                            handleSelectedSpecificConditions(
                                              e,
                                              question,
                                              answer
                                            );
                                          }}
                                        />
                                      );
                                    }
                                  )}
                                </div>
                              </Row>
                            );
                          })}
                          <Row>
                            <a
                              href="#"
                              className="btn btn-xlp"
                              onClick={() => {
                                getServiceDataHandler();
                              }}
                            >
                              Submit
                            </a>
                          </Row>
                        </Col>
                      </Col>
                    ) : null}

                    {showCtlgSpecificConditions &&
                    ctlgSpecificConditions &&
                    ctlgSpecificConditions.length > 0 ? (
                      <Col className="specific-cond-wrapper">
                        <Col>
                          <Row>
                            <h4>
                              Please select a specific catalog item to continue
                            </h4>
                          </Row>
                          {ctlgSpecificConditions.map((question, key) => {
                            return (
                              <Row>
                                <span className="bold">{question.text}</span>
                                <div className="radioWrapper condition">
                                  {question.partPossibleAnswers.map(
                                    (answer, key) => {
                                      return (
                                        <Form.Check
                                          type="radio"
                                          className="bold"
                                          inline
                                          label={answer.text}
                                          id={answer.code}
                                          name={question.code}
                                          defaultChecked={answer.code === "N"}
                                          onChange={(e) => {
                                            handleSelectedCtlgSpecificConditions(
                                              e,
                                              question,
                                              answer
                                            );
                                          }}
                                        />
                                      );
                                    }
                                  )}
                                </div>
                              </Row>
                            );
                          })}
                          <Row>
                            <a
                              href="#"
                              className="btn btn-xlp"
                              onClick={() => {
                                getCatalogDataHandler();
                              }}
                            >
                              Submit
                            </a>
                          </Row>
                        </Col>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </div>
            <Col>
              <Row className="navigation-row">
                <Pagination className="maintenance-pagination">
                  {previousInterval && previousInterval != null ? (
                    <Pagination.Item
                      className={`prev${
                        intervalListOptions[0].value === previousInterval.value
                          ? " first-navitem"
                          : ""
                      }`}
                      onClick={() => {
                        handleIntervalChangePrev();
                      }}
                    >
                      {previousInterval.label}
                      {intervalListOptions[0].value !==
                      previousInterval.value ? (
                        <i className="fa fa-angle-left"></i>
                      ) : null}
                    </Pagination.Item>
                  ) : null}
                  {nextInterval && nextInterval != null ? (
                    <Pagination.Item
                      className={`next${
                        intervalListOptions[intervalListOptions.length - 1]
                          .value === nextInterval.value
                          ? " last-navitem"
                          : ""
                      }`}
                      onClick={() => {
                        handleIntervalChangeNext();
                      }}
                    >
                      {nextInterval.label}
                      {intervalListOptions[intervalListOptions.length - 1]
                        .value !== nextInterval.value ? (
                        <i className="fa fa-angle-right"></i>
                      ) : null}
                    </Pagination.Item>
                  ) : null}
                </Pagination>
              </Row>
            </Col>
            {enableservicetable &&
            serviceTableColumns.length > 0 &&
            serviceTableRows.length > 0 ? (
              <React.Fragment>
                <Row className="table-row">
                  <Col>
                    <BootstrapTable
                      bootstrap4
                      keyField="id"
                      data={serviceTableRows}
                      columns={serviceTableColumns}
                      wrapperClasses="table-responsive "
                      // rowClasses="custom-row-class"
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : null}

            {noTableData === true ? (
              <div className="noResultWrapper">
                <div className="noResult">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  Sorry, we couldn't find any results
                </div>
              </div>
            ) : null}
          </div>
        </XModal>
      ) : null}
      {showDeleteModal &&
        partCatalogExpandRows.length > 0 &&
        partCatalogExpandColumns.length > 0 && (
          <XModal
            show={showDeleteModal}
            title={
              addCartTitle != null
                ? `Product Information for ${addCartTitle}`
                : "Product Information"
            }
            className="vehicle-maintance-addcart-modal"
            //isValidValidity={true}
            favoriteTextValidity={true}
            submitType={true}
            handleClose={() => closeProductInfoPopup()}
            handleAction={() => {
              closeProductInfoPopup();
            }}
            submitButtonText="OK"
            //noButtonText="Cancel"
          >
          <div className="wc-ship-info mx-auto">
          <ShipInfoHeader /></div>
            {/* {userDetails &&
            userDetails.shipTos &&
            userDetails.shipTos.length > 0 &&
            userDetails.shipTos[0].shipMethod ? (
              <div className="wc-ship-info mx-auto maroonColorTxt"> */}
                {/* <span>
                  {"All items are Pickup - please pick up at store # " +
                    userDetails.shipTos[0].storeNumber}
                </span> */}
                {/* <span>
                  Ship Method:
                  <span className="text-black">
                    {parts.cartShipMethod && parts.cartShipMethod != null
                      ? parts.cartShipMethod == "0002"
                        ? " Pickup "
                        : " Delivery "
                      : (userDetails.shipTos[0] &&
                          userDetails.shipTos[0].shipMethod === "0002") ||
                        userDetails.shipTos[0].shipMethod === "0001"
                      ? " Pickup "
                      : " Delivery "}{" "}
                  </span>
                  From TPH Parts Location :{" "}
                  <span className="text-black">
                    {parts.cartStore
                      ? parts.cartStore +
                        " - " +
                        getstoreName(
                          allStores,
                          parts.cartStore,
                          parts.cartShipMethod,
                          userDetails
                        )
                      : userDetails.shipTos[0].storeNumber +
                        " - " +
                        userDetails.shipTos[0].location.storeName}
                  </span>
                </span>
              </div>
            ) : (
              ""
            )} */}
            <Row className="table-row popup-vehicle-maintance-addtocart">
              <Col>
                <div className="">
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={partCatalogExpandRows}
                    columns={partCatalogExpandColumns}
                    wrapperClasses="table-responsive"
                  />
                </div>
              </Col>
              {cartApiFetching && (
                 <div className="custom_spinner_container">
                   <Spinner className="custom_spinner" animation="border" />
                 </div>
               )}
            </Row>
          </XModal>
        )}
      {addToCartWarningPopup ? (
        <AddToCartWarningPopup
          openPopup={addToCartWarningPopup}
          selectedAvailabilityHours={addToCartPayload}
          source={"partCategory"}
          orderType="REGULAR"
          DefaultAddItemToCart={false}
          closePopup={() => {
            setaddToCartWarningPopup(false);
          }}
        />
      ) : null}
      {showAvailabilityStoreView ?
        (<AvailabilityStoreView
        openPopup={showAvailabilityStoreView}
        closePopup={() => setShowAvailabilityStoreView(false)}
        storeData={storeData}
        />
        )
        : null }
    </React.Fragment>
  );
};

export default VehicleMaintenancePopup;
